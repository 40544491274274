import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { constant } from './constant';
import { CommonService } from './services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class FormHandlerService {

  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private ngxLoader: NgxUiLoaderService,
  ) { }
  base_url: string;

  async handleFormSubmission(formData: any, componentName: string) {
    // Prepare the message and post data
    const message = `Received an enquiry with details below: <br>
      <b>Name: </b> ${formData.name} <br>
      <b>Email: </b> ${formData.email} <br>
      <b>Phone number:</b> ${formData.phoneNumber} <br>
      <b>Date:</b> ${new Date()} <br>
      <b>Insurance Type:</b> ${componentName}`;

    const postData = {
        "subject": "Enquiry received",
        "email": constant.enquiryEmailIds,
        "text": message
    };

    // Display a success message
    this.ngxLoader.start();

    // Define the URL for the API endpoint
    this.base_url = `${constant.api_endpoint}/email/sendMail`;

    // Make the HTTP POST request
    await this.commonService.post(this.base_url, postData).subscribe(
      res => {
          // Stop the loader on success
          this.ngxLoader.stop();
          // Show success toast message
          this.toastr.success('Thank you for reaching out to PolicyMall. We have received your request and will get back to you soon.', 'Success');
      },
      err => {
          // Stop the loader on error
          this.ngxLoader.stop();
          // Get the error message and show error toast message
          const errorMessage = err && err.message ? err.message : 'Something went wrong';
          this.toastr.error(errorMessage, 'Error');
      }
    );
  }
}
