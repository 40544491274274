const icici_occupation = [
    'Accountant',
    'Accounts Assistant',
    'Actor/Actress (no stunts)',
    'Admin Assistant',
    'Administrator',
    'Agent/Outdoor Sales Persons',
    'Aircraft Crew',
    'Aircraft Engineer',
    'Aircraft Mechanic',
    'Aircraft Pilot',
    'Architect',
    'Artist',
    'Auditor',
    'Author',
    'Baker',
    'Banker',
    'Bar Waiter/Waitress',
    'Barber',
    'Bartender',
    'Beautician',
    'Blacksmith',
    'Boat Builder',
    'Bookkeeper',
    'Bouncer',
    'Bus Driver',
    'Bus Inspector',
    'Business Executive',
    'Butcher',
    'Butcher in supermarket',
    'Canteen Assistant',
    'Car Park Attendant',
    'Carpenter',
    'Cashier',
    'Chauffeur',
    'Chef/Cook',
    'Clerical Officer',
    'Clerk',
    'Clerks-of-Works',
    'Coach (Sports)',
    'Computer Engineer',
    'Computer Specialist',
    'Construction Foreman',
    'Construction Foremen',
    'Consultant',
    'Craftsman',
    'Crane Operators',
    'Cruise Liner - Crew',
    'Customer Service Officer',
    'Dance Hostess',
    'Dancer (Professional)',
    'Dealer Explosives',
    'Defence forces',
    'Designer',
    'Despatch/Delivery Man',
    'Detective',
    'Diplomat',
    'Director',
    'Dock Worker',
    'Doctor',
    'Domestic Servant/Maid',
    'Draughtsman',
    'Driver (Private Car)',
    'Driving Instructor',
    'Economist',
    'Editor',
    'Engineer (Office duties only)',
    'Enterpreneur',
    'FX Dealers',
    'Factory Worker (non-hazardous)',
    'Farmer (manual labour)',
    'Fashion Model (Females)',
    'Fashion Model (males)',
    'Financial Controller',
    'Fireman',
    'Fireworks worker',
    'Fisherman',
    'Fishmonger',
    'Fitter',
    'Flight Steward/Stewardess',
    'Florist',
    'Forklift Driver',
    'Gardener',
    'General Manager',
    'Goldsmith',
    'Hairstylist/Hairdresser',
    'Harbour Pilot',
    'Hawker',
    'Heavy Vehicles Driver',
    'Hospital Attendants',
    'IT professional',
    'Indoor Sales Person',
    'Insurance Agent',
    'Interior Designer',
    'Janitor',
    'Jeweller',
    'Jockey',
    'Juvenile',
    'Land Surveyor',
    'Lawyer',
    'Lecturer',
    'Librarian',
    'Locksmith',
    'Lorry/Truck Driver',
    'Loss Adjuster',
    'Machine Operator',
    'Machinist',
    'Manager',
    'Managing Director',
    'Masseur/Masseuse',
    'Mechanic (Car)',
    'Merchant Navy',
    'Metallurgical Surveyor',
    'Miners',
    'Nuclear reactor workers',
    'Nurse (except psychiatric)',
    'Nurse (psychiatric)',
    'Odd Job Labourer',
    'Office Executive',
    'Oil Refinery Admin Personnel',
    'Oil Refinery Plant Operator',
    'Operator Explosives',
    'Optician',
    'Painter (Non artistic)',
    'Parking Enforcement Officer',
    'Pastor',
    'Personal Security',
    'Pharmacist',
    'Photographer',
    'Plumber',
    'Police',
    'Politician',
    'Port Chemist',
    'Postman (with driving)',
    'Postman (without driving)',
    'President (Company)',
    'Principal (School)',
    'Printer',
    'Professor',
    'Promoter (Department store)',
    'Railway/MRT driver',
    'Real Estate Agent',
    'Receptionist',
    'Remiser/Stockbroker',
    'Restaurant Waiter/Waitress',
    'Retailer',
    'Retiree',
    'Sales Personnel',
    'Seamstress',
    'Secretary',
    'Security Guard',
    'Ship crew',
    'Shipping Admin Personnel',
    'Shop Keeper',
    'Site Supervisors',
    'Statistician',
    'Student',
    'Tailor',
    'Tax Officer',
    'Taxi Driver',
    'Teacher',
    'Technical Officer',
    'Technician',
    'Telephone Operator',
    'Timber Industry Sawyer',
    'Tour Guide',
    'Trainee Technician',
    'Tutor (Private)',
    'Typist',
    'Underwriter',
    'Veterinary Surgeons',
    'Wholesale Merchant',
    'Zoo Keeper'
]


export {icici_occupation};  