import { Component, OnInit } from '@angular/core';
import { FormHandlerService } from '../form-handler.service';
import { constant } from '../constant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  contactForm = {
    name: '',
    email: '',
    mobile: '',
    insurance_type: '',
    pin_code: ''
  };
  formData: any;

  constructor(private formhandleservice: FormHandlerService, private toastr: ToastrService) {}

  ngOnInit() {}

  submit() {
    var regex = constant.emailvalidateregex;
    var mobilevalidateregex = constant.mobilevalidateregex;
    if (!this.contactForm.name) {
      this.toastr.error('Please enter your name', 'Error');
    } else if (!this.contactForm.email) {
      this.toastr.error('Please enter your valid email', 'Error');
    } else if (!regex.test(this.contactForm.email)) {
      this.toastr.error('Please enter a valid email address', 'Error');
    } else if (!mobilevalidateregex.test(this.contactForm.mobile)) {
      this.toastr.error('Please enter your valid mobile number', 'Error');
    } else if (!this.contactForm.pin_code) {
      this.toastr.error('Please enter Pin', 'Error');
    } else {
      this.formData = {
        name: this.contactForm.name,
        phoneNumber: this.contactForm.mobile,
        email: this.contactForm.email
      };
      this.formhandleservice.handleFormSubmission(this.formData, 'Contact Us Form - ' + this.contactForm.insurance_type);
    }
    return;
  }
}
