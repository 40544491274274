 export const  bankMaster = [
    {
      "NUM_FINANCIER_CD": 114,
      "TXT_FINANCIER_NAME": "Punjab Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 115,
      "TXT_FINANCIER_NAME": "Malwa Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 116,
      "TXT_FINANCIER_NAME": "Baroda Rajasthan Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 117,
      "TXT_FINANCIER_NAME": "Rajasthan Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 118,
      "TXT_FINANCIER_NAME": "Hodoti Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 119,
      "TXT_FINANCIER_NAME": "Pandyan Grama Bank"
    },
    {
      "NUM_FINANCIER_CD": 120,
      "TXT_FINANCIER_NAME": "Purvanchal Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 121,
      "TXT_FINANCIER_NAME": "Uttar Pradesh Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 122,
      "TXT_FINANCIER_NAME": "Lucknow Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 123,
      "TXT_FINANCIER_NAME": "Triveni Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 124,
      "TXT_FINANCIER_NAME": "Kisan Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 125,
      "TXT_FINANCIER_NAME": "Etawah Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 126,
      "TXT_FINANCIER_NAME": "Baroda Western Uttar Pradesh Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 127,
      "TXT_FINANCIER_NAME": "Prathama Bank"
    },
    {
      "NUM_FINANCIER_CD": 128,
      "TXT_FINANCIER_NAME": "Uttaranchal Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 129,
      "TXT_FINANCIER_NAME": "Bangiya Gramin Vikash Bank"
    },
    {
      "NUM_FINANCIER_CD": 130,
      "TXT_FINANCIER_NAME": "Uttar Banga Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 131,
      "TXT_FINANCIER_NAME": "Assam Gramin Vikash Bank"
    },
    {
      "NUM_FINANCIER_CD": 132,
      "TXT_FINANCIER_NAME": "Tripura Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 133,
      "TXT_FINANCIER_NAME": "Arunachal Pradesh Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 134,
      "TXT_FINANCIER_NAME": "Ka Bank Nogkyndong Ri Khasi- Jaintia"
    },
    {
      "NUM_FINANCIER_CD": 135,
      "TXT_FINANCIER_NAME": "Manipur Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 136,
      "TXT_FINANCIER_NAME": "Nagaland Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 137,
      "TXT_FINANCIER_NAME": "Mizoram Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 138,
      "TXT_FINANCIER_NAME": "Andhra Pragathi Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 139,
      "TXT_FINANCIER_NAME": "Chaitanya Godavari Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 140,
      "TXT_FINANCIER_NAME": "Bihar Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 142,
      "TXT_FINANCIER_NAME": "Surguja Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 143,
      "TXT_FINANCIER_NAME": "Baroda Gujarat Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 144,
      "TXT_FINANCIER_NAME": "Gurgaon Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 145,
      "TXT_FINANCIER_NAME": "Parvatiya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 146,
      "TXT_FINANCIER_NAME": "Ellaquai Dehati Bank"
    },
    {
      "NUM_FINANCIER_CD": 147,
      "TXT_FINANCIER_NAME": "Vananchal Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 148,
      "TXT_FINANCIER_NAME": "Pragathi Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 149,
      "TXT_FINANCIER_NAME": "Krishna Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 150,
      "TXT_FINANCIER_NAME": "Visveshvaraya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 151,
      "TXT_FINANCIER_NAME": "North Malabar Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 152,
      "TXT_FINANCIER_NAME": "Satpura Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 153,
      "TXT_FINANCIER_NAME": "Chambal-Gwalior Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 154,
      "TXT_FINANCIER_NAME": "Sharda Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 155,
      "TXT_FINANCIER_NAME": "Vidisha Bhopal Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 156,
      "TXT_FINANCIER_NAME": "Jhabua Dhar Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 157,
      "TXT_FINANCIER_NAME": "Aurangabad -Jalna Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 158,
      "TXT_FINANCIER_NAME": "Vidharbha Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 159,
      "TXT_FINANCIER_NAME": "Thane Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 160,
      "TXT_FINANCIER_NAME": "Utkal Gramya Bank"
    },
    {
      "NUM_FINANCIER_CD": 161,
      "TXT_FINANCIER_NAME": "Neelachal Gramya Bank"
    },
    {
      "NUM_FINANCIER_CD": 162,
      "TXT_FINANCIER_NAME": "Faridkot-Bhatinda Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 163,
      "TXT_FINANCIER_NAME": "Marwar Ganganagar Bikaner Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 164,
      "TXT_FINANCIER_NAME": "Jaipur Thar Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 165,
      "TXT_FINANCIER_NAME": "Mewar Anchalik Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 166,
      "TXT_FINANCIER_NAME": "Pallavan Grama Bank"
    },
    {
      "NUM_FINANCIER_CD": 167,
      "TXT_FINANCIER_NAME": "Kashi Gomti Samyut Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 168,
      "TXT_FINANCIER_NAME": "Shreyas Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 169,
      "TXT_FINANCIER_NAME": "Ballia Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 170,
      "TXT_FINANCIER_NAME": "Aryavart Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 171,
      "TXT_FINANCIER_NAME": "Kshetriya Kisan Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 172,
      "TXT_FINANCIER_NAME": "Rani Laxmi Bai Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 173,
      "TXT_FINANCIER_NAME": "Devipatan Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 174,
      "TXT_FINANCIER_NAME": "Baroda Eastern Uttar Pradesh Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 175,
      "TXT_FINANCIER_NAME": "Nainital Almora Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 176,
      "TXT_FINANCIER_NAME": "Paschim Banga Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 177,
      "TXT_FINANCIER_NAME": "Langpi Dehangi Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 178,
      "TXT_FINANCIER_NAME": "The Andaman and Nicobar State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 179,
      "TXT_FINANCIER_NAME": "The Arunachal Pradesh State co-operative Apex Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 180,
      "TXT_FINANCIER_NAME": "The Bihar State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 181,
      "TXT_FINANCIER_NAME": "The Delhi State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 182,
      "TXT_FINANCIER_NAME": "The Gujarat State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 183,
      "TXT_FINANCIER_NAME": "The Himachal Pradesh State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 184,
      "TXT_FINANCIER_NAME": "The Karnataka State Co-operative Apex Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 185,
      "TXT_FINANCIER_NAME": "The Madhya Pradesh Rajya Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 186,
      "TXT_FINANCIER_NAME": "The Manipur State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 187,
      "TXT_FINANCIER_NAME": "The Mizoram Co-operative Apex Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 188,
      "TXT_FINANCIER_NAME": "The Orissa State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 189,
      "TXT_FINANCIER_NAME": "The Punjab State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 190,
      "TXT_FINANCIER_NAME": "The Sikkim State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 191,
      "TXT_FINANCIER_NAME": "The Tripura State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 192,
      "TXT_FINANCIER_NAME": "The West Bengal State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 193,
      "TXT_FINANCIER_NAME": "The Uttaranchal Rajya Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 194,
      "TXT_FINANCIER_NAME": "The Andhra Pradesh State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 195,
      "TXT_FINANCIER_NAME": "The Assam Co-operative Apex Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 196,
      "TXT_FINANCIER_NAME": "The Chandigarh State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 197,
      "TXT_FINANCIER_NAME": "The Goa State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 198,
      "TXT_FINANCIER_NAME": "The Haryana State Co-opertive Apex Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 199,
      "TXT_FINANCIER_NAME": "The Jammu and Kashmir State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 200,
      "TXT_FINANCIER_NAME": "The Kerala State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 201,
      "TXT_FINANCIER_NAME": "The Maharashtra State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 202,
      "TXT_FINANCIER_NAME": "The Meghalaya Co-operative Apex Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 203,
      "TXT_FINANCIER_NAME": "The Nagaland State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 204,
      "TXT_FINANCIER_NAME": "The Pondichery State Co-opertive Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 205,
      "TXT_FINANCIER_NAME": "The Rajasthan State Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 206,
      "TXT_FINANCIER_NAME": "The Tamil Nadu State Apex Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 207,
      "TXT_FINANCIER_NAME": "The Uttar Pradesh Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 208,
      "TXT_FINANCIER_NAME": "The Chhattisgarh RajyaSahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 425,
      "TXT_FINANCIER_NAME": "Parshwanath Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 426,
      "TXT_FINANCIER_NAME": "Patan Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 427,
      "TXT_FINANCIER_NAME": "Patdi Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 428,
      "TXT_FINANCIER_NAME": "People's Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 429,
      "TXT_FINANCIER_NAME": "Pij People's Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 430,
      "TXT_FINANCIER_NAME": "Porbandar Commercial Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 431,
      "TXT_FINANCIER_NAME": "Porbandar Vibhagiya Nagarik Sahakari"
    },
    {
      "NUM_FINANCIER_CD": 432,
      "TXT_FINANCIER_NAME": "Pragati Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 433,
      "TXT_FINANCIER_NAME": "Pragati Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 434,
      "TXT_FINANCIER_NAME": "Prantij Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 435,
      "TXT_FINANCIER_NAME": "Prime Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 436,
      "TXT_FINANCIER_NAME": "Progressive Mercantile Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 437,
      "TXT_FINANCIER_NAME": "Rajkot Commercial Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 438,
      "TXT_FINANCIER_NAME": "Rajkot Mahila Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 439,
      "TXT_FINANCIER_NAME": "Rajkot Peoples Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 440,
      "TXT_FINANCIER_NAME": "Rajpipla Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 441,
      "TXT_FINANCIER_NAME": "Rajula Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 442,
      "TXT_FINANCIER_NAME": "Rander People's Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 443,
      "TXT_FINANCIER_NAME": "Randheja Commercial Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 444,
      "TXT_FINANCIER_NAME": "Ranuj Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 445,
      "TXT_FINANCIER_NAME": "ROYAL BANK OF SCOTLAND N V"
    },
    {
      "NUM_FINANCIER_CD": 446,
      "TXT_FINANCIER_NAME": "Royale Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 447,
      "TXT_FINANCIER_NAME": "Rushika Mahila Nagarik Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 448,
      "TXT_FINANCIER_NAME": "Sachin Industrial Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 449,
      "TXT_FINANCIER_NAME": "Salal Sarvodaya Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 450,
      "TXT_FINANCIER_NAME": "Sanand Urban Co-Op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 451,
      "TXT_FINANCIER_NAME": "Sankheda Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 452,
      "TXT_FINANCIER_NAME": "Santrampur Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 453,
      "TXT_FINANCIER_NAME": "Sarangpur Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 454,
      "TXT_FINANCIER_NAME": "Saraspur Nagarik Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 455,
      "TXT_FINANCIER_NAME": "Sardar Vallabhbhai Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 456,
      "TXT_FINANCIER_NAME": "Sardarganj Mercantile Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 457,
      "TXT_FINANCIER_NAME": "Sardargunj Mercantile Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 458,
      "TXT_FINANCIER_NAME": "Sarsa People's Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 459,
      "TXT_FINANCIER_NAME": "Sarvodaya Commercial Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 460,
      "TXT_FINANCIER_NAME": "Sarvodaya Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 461,
      "TXT_FINANCIER_NAME": "Sarvodaya Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 462,
      "TXT_FINANCIER_NAME": "Sarvodaya Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 463,
      "TXT_FINANCIER_NAME": "Sarvodaya Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 464,
      "TXT_FINANCIER_NAME": "Sathamba People's Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 465,
      "TXT_FINANCIER_NAME": "Saurashtra Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 466,
      "TXT_FINANCIER_NAME": "Savarkundla Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 467,
      "TXT_FINANCIER_NAME": "Sevalia Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 468,
      "TXT_FINANCIER_NAME": "Shihori Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 469,
      "TXT_FINANCIER_NAME": "Shree Baria Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 470,
      "TXT_FINANCIER_NAME": "Shree Bhadran Mercantile Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 471,
      "TXT_FINANCIER_NAME": "Shree Bharat Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 472,
      "TXT_FINANCIER_NAME": "Shree Bhavnagar Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 473,
      "TXT_FINANCIER_NAME": "Shree Botad Mercantile Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 474,
      "TXT_FINANCIER_NAME": "Shree Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 475,
      "TXT_FINANCIER_NAME": "Shree Deesa Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 476,
      "TXT_FINANCIER_NAME": "Shree Dhandhuka Janta Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 477,
      "TXT_FINANCIER_NAME": "Shree Dharati Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 478,
      "TXT_FINANCIER_NAME": "Shree Kadi Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 479,
      "TXT_FINANCIER_NAME": "Shree Kamdar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 480,
      "TXT_FINANCIER_NAME": "Shree Krishna Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 481,
      "TXT_FINANCIER_NAME": "Shree Lathi Vibhagiya Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 482,
      "TXT_FINANCIER_NAME": "Shree Laxmi Mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 483,
      "TXT_FINANCIER_NAME": "Shree Lodhra Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 484,
      "TXT_FINANCIER_NAME": "Shree Mahalaxmi Mercantile Coop Bk Ltd."
    },
    {
      "NUM_FINANCIER_CD": 485,
      "TXT_FINANCIER_NAME": "Shree Mahuva Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 486,
      "TXT_FINANCIER_NAME": "Shree Parswanth Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 487,
      "TXT_FINANCIER_NAME": "Shree Savli Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 488,
      "TXT_FINANCIER_NAME": "Shree Swaminarayan Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 489,
      "TXT_FINANCIER_NAME": "Shree Talaja Nagarik Sahakari Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 490,
      "TXT_FINANCIER_NAME": "Shree Vanthali Vibhagiya Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 491,
      "TXT_FINANCIER_NAME": "Shree Vardhaman Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 492,
      "TXT_FINANCIER_NAME": "Shree Vardhaman Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 493,
      "TXT_FINANCIER_NAME": "Shree Yugprabhav Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 494,
      "TXT_FINANCIER_NAME": "Shreenath Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 495,
      "TXT_FINANCIER_NAME": "Shreyas Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 496,
      "TXT_FINANCIER_NAME": "Shri Vinayak Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 497,
      "TXT_FINANCIER_NAME": "Shri Bhagasara Nagrik Sahakari Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 498,
      "TXT_FINANCIER_NAME": "Shri Bhailalbhai Contractor Smarak Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 499,
      "TXT_FINANCIER_NAME": "Shri Chhani Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 500,
      "TXT_FINANCIER_NAME": "Shri Chital Nagarik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 501,
      "TXT_FINANCIER_NAME": "Shri Janata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 502,
      "TXT_FINANCIER_NAME": "Shri Limbdi Vibhagiya Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 503,
      "TXT_FINANCIER_NAME": "Shri Mahila Sewa Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 504,
      "TXT_FINANCIER_NAME": "Shri Morbi Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 505,
      "TXT_FINANCIER_NAME": "Siddhi Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 506,
      "TXT_FINANCIER_NAME": "Siddhpur Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 507,
      "TXT_FINANCIER_NAME": "Sihor Mercantile Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 508,
      "TXT_FINANCIER_NAME": "Sihor Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 509,
      "TXT_FINANCIER_NAME": "Sind Mercantile Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 510,
      "TXT_FINANCIER_NAME": "Sinor Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 511,
      "TXT_FINANCIER_NAME": "Social Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 512,
      "TXT_FINANCIER_NAME": "Sojitra Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 513,
      "TXT_FINANCIER_NAME": "Standard Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 514,
      "TXT_FINANCIER_NAME": "Standared Bank House"
    },
    {
      "NUM_FINANCIER_CD": 515,
      "TXT_FINANCIER_NAME": "State Transport Employees' Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 516,
      "TXT_FINANCIER_NAME": "Suleimani Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 517,
      "TXT_FINANCIER_NAME": "Surat Mahila Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 518,
      "TXT_FINANCIER_NAME": "Surat Mercantile Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 519,
      "TXT_FINANCIER_NAME": "Surat Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 520,
      "TXT_FINANCIER_NAME": "Surat National Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 521,
      "TXT_FINANCIER_NAME": "Surat Textile Traders Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 522,
      "TXT_FINANCIER_NAME": "Surendranagar Mercantile Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 523,
      "TXT_FINANCIER_NAME": "Surendranagar People's Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 524,
      "TXT_FINANCIER_NAME": "Suvikas Peoples Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 525,
      "TXT_FINANCIER_NAME": "Talod Janata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 526,
      "TXT_FINANCIER_NAME": "Talod Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 527,
      "TXT_FINANCIER_NAME": "Tapi Co-Operative bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 528,
      "TXT_FINANCIER_NAME": "Tarapur Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 529,
      "TXT_FINANCIER_NAME": "Textile Co-op.Bank of Surat Ltd."
    },
    {
      "NUM_FINANCIER_CD": 530,
      "TXT_FINANCIER_NAME": "TEXTILE TRADERS COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 1,
      "TXT_FINANCIER_NAME": "Allahabad Bank"
    },
    {
      "NUM_FINANCIER_CD": 2,
      "TXT_FINANCIER_NAME": "Bank of Baroda"
    },
    {
      "NUM_FINANCIER_CD": 3,
      "TXT_FINANCIER_NAME": "Bank of Maharashtra"
    },
    {
      "NUM_FINANCIER_CD": 4,
      "TXT_FINANCIER_NAME": "Central Bank of India"
    },
    {
      "NUM_FINANCIER_CD": 5,
      "TXT_FINANCIER_NAME": "Dena Corporate Centre"
    },
    {
      "NUM_FINANCIER_CD": 6,
      "TXT_FINANCIER_NAME": "Indian Overseas Bank"
    },
    {
      "NUM_FINANCIER_CD": 7,
      "TXT_FINANCIER_NAME": "Punjab National Bank"
    },
    {
      "NUM_FINANCIER_CD": 8,
      "TXT_FINANCIER_NAME": "Union Bank of India"
    },
    {
      "NUM_FINANCIER_CD": 9,
      "TXT_FINANCIER_NAME": "Punjab & Sind Bank"
    },
    {
      "NUM_FINANCIER_CD": 10,
      "TXT_FINANCIER_NAME": "Vijaya Bank"
    },
    {
      "NUM_FINANCIER_CD": 11,
      "TXT_FINANCIER_NAME": "Andhra Bank"
    },
    {
      "NUM_FINANCIER_CD": 12,
      "TXT_FINANCIER_NAME": "Bank of India"
    },
    {
      "NUM_FINANCIER_CD": 13,
      "TXT_FINANCIER_NAME": "Canara Bank"
    },
    {
      "NUM_FINANCIER_CD": 14,
      "TXT_FINANCIER_NAME": "CORPORATION BANK"
    },
    {
      "NUM_FINANCIER_CD": 15,
      "TXT_FINANCIER_NAME": "Indian Bank Building"
    },
    {
      "NUM_FINANCIER_CD": 16,
      "TXT_FINANCIER_NAME": "Oriental Bank of Commerce"
    },
    {
      "NUM_FINANCIER_CD": 17,
      "TXT_FINANCIER_NAME": "Syndicate Bank"
    },
    {
      "NUM_FINANCIER_CD": 18,
      "TXT_FINANCIER_NAME": "UCO Bank"
    },
    {
      "NUM_FINANCIER_CD": 19,
      "TXT_FINANCIER_NAME": "ABN AMRO Bank"
    },
    {
      "NUM_FINANCIER_CD": 20,
      "TXT_FINANCIER_NAME": "American Express Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 21,
      "TXT_FINANCIER_NAME": "Bank of Bahrain & Kuwait BSC"
    },
    {
      "NUM_FINANCIER_CD": 22,
      "TXT_FINANCIER_NAME": "Bank of Nova Scotia"
    },
    {
      "NUM_FINANCIER_CD": 23,
      "TXT_FINANCIER_NAME": "Citi Bank N.A."
    },
    {
      "NUM_FINANCIER_CD": 24,
      "TXT_FINANCIER_NAME": "HSBC Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 25,
      "TXT_FINANCIER_NAME": "Sonali Bank"
    },
    {
      "NUM_FINANCIER_CD": 26,
      "TXT_FINANCIER_NAME": "Barclays Bank p.l.c."
    },
    {
      "NUM_FINANCIER_CD": 27,
      "TXT_FINANCIER_NAME": "Bank Internasional Indonesia"
    },
    {
      "NUM_FINANCIER_CD": 28,
      "TXT_FINANCIER_NAME": "Standard Chartered Bank"
    },
    {
      "NUM_FINANCIER_CD": 29,
      "TXT_FINANCIER_NAME": "Bank of Ceylon"
    },
    {
      "NUM_FINANCIER_CD": 30,
      "TXT_FINANCIER_NAME": "Chinatrust Commercial Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 31,
      "TXT_FINANCIER_NAME": "Antwerp Diamond Bank N.V."
    },
    {
      "NUM_FINANCIER_CD": 32,
      "TXT_FINANCIER_NAME": "Mizuho Corporate Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 33,
      "TXT_FINANCIER_NAME": "Calyon Bank"
    },
    {
      "NUM_FINANCIER_CD": 34,
      "TXT_FINANCIER_NAME": "Abu Dhabi Commercial Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 35,
      "TXT_FINANCIER_NAME": "Bank of America N.T. S.A."
    },
    {
      "NUM_FINANCIER_CD": 36,
      "TXT_FINANCIER_NAME": "Mashreq Bank PSC"
    },
    {
      "NUM_FINANCIER_CD": 37,
      "TXT_FINANCIER_NAME": "Bank of Tokyo Mitsubishi UFJ Ltd."
    },
    {
      "NUM_FINANCIER_CD": 38,
      "TXT_FINANCIER_NAME": "Deutsche Bank"
    },
    {
      "NUM_FINANCIER_CD": 39,
      "TXT_FINANCIER_NAME": "Societe Generale"
    },
    {
      "NUM_FINANCIER_CD": 40,
      "TXT_FINANCIER_NAME": "BNP Paribas"
    },
    {
      "NUM_FINANCIER_CD": 41,
      "TXT_FINANCIER_NAME": "DBS Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 42,
      "TXT_FINANCIER_NAME": "Arab Bangladesh Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 43,
      "TXT_FINANCIER_NAME": "State Bank of Mauritius Ltd"
    },
    {
      "NUM_FINANCIER_CD": 44,
      "TXT_FINANCIER_NAME": "Cho Hung Bank"
    },
    {
      "NUM_FINANCIER_CD": 45,
      "TXT_FINANCIER_NAME": "Krung Thai Bank plc"
    },
    {
      "NUM_FINANCIER_CD": 46,
      "TXT_FINANCIER_NAME": "Mafatlal Centre"
    },
    {
      "NUM_FINANCIER_CD": 47,
      "TXT_FINANCIER_NAME": "Oman International Bank SAOG"
    },
    {
      "NUM_FINANCIER_CD": 48,
      "TXT_FINANCIER_NAME": "State Bank of India"
    },
    {
      "NUM_FINANCIER_CD": 49,
      "TXT_FINANCIER_NAME": "State Bank of Hyderabad"
    },
    {
      "NUM_FINANCIER_CD": 50,
      "TXT_FINANCIER_NAME": "State Bank of Mysore"
    },
    {
      "NUM_FINANCIER_CD": 51,
      "TXT_FINANCIER_NAME": "State Bank of Travancore"
    },
    {
      "NUM_FINANCIER_CD": 52,
      "TXT_FINANCIER_NAME": "State Bank of Bikaner and Jaipur"
    },
    {
      "NUM_FINANCIER_CD": 53,
      "TXT_FINANCIER_NAME": "State Bank of Indore"
    },
    {
      "NUM_FINANCIER_CD": 54,
      "TXT_FINANCIER_NAME": "State Bank of Patiala"
    },
    {
      "NUM_FINANCIER_CD": 55,
      "TXT_FINANCIER_NAME": "Bank of Rajasthan Ltd"
    },
    {
      "NUM_FINANCIER_CD": 56,
      "TXT_FINANCIER_NAME": "Catholic Syrian Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 57,
      "TXT_FINANCIER_NAME": "Dhanalakshmi Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 58,
      "TXT_FINANCIER_NAME": "Karnataka Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 59,
      "TXT_FINANCIER_NAME": "City Union Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 60,
      "TXT_FINANCIER_NAME": "Nainital Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 61,
      "TXT_FINANCIER_NAME": "Sangli Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 62,
      "TXT_FINANCIER_NAME": "Tamilnad Mercantile Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 63,
      "TXT_FINANCIER_NAME": "Lord Krishna Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 64,
      "TXT_FINANCIER_NAME": "UTI Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 65,
      "TXT_FINANCIER_NAME": "Yes Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 66,
      "TXT_FINANCIER_NAME": "Centurion Bank of Punjab"
    },
    {
      "NUM_FINANCIER_CD": 67,
      "TXT_FINANCIER_NAME": "Development Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 68,
      "TXT_FINANCIER_NAME": "Bharat Overseas Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 69,
      "TXT_FINANCIER_NAME": "Federal Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 70,
      "TXT_FINANCIER_NAME": "Jammu & Kashmir Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 71,
      "TXT_FINANCIER_NAME": "Karur Vysya Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 72,
      "TXT_FINANCIER_NAME": "Lakshmi Vilas Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 73,
      "TXT_FINANCIER_NAME": "RATNAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 74,
      "TXT_FINANCIER_NAME": "South Indian Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 75,
      "TXT_FINANCIER_NAME": "ING Vysya Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 76,
      "TXT_FINANCIER_NAME": "ICICI Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 77,
      "TXT_FINANCIER_NAME": "IndusInd Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 78,
      "TXT_FINANCIER_NAME": "SBI Commercial and International Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 79,
      "TXT_FINANCIER_NAME": "HDFC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 80,
      "TXT_FINANCIER_NAME": "Kotak Mahindra Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 81,
      "TXT_FINANCIER_NAME": "IDBI Bank"
    },
    {
      "NUM_FINANCIER_CD": 83,
      "TXT_FINANCIER_NAME": "Andhra Pradesh Grameena Vikas Bank"
    },
    {
      "NUM_FINANCIER_CD": 84,
      "TXT_FINANCIER_NAME": "Deccan Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 85,
      "TXT_FINANCIER_NAME": "Saptagiri Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 86,
      "TXT_FINANCIER_NAME": "Madhya Bihar Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 87,
      "TXT_FINANCIER_NAME": "Uttar Bihar Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 88,
      "TXT_FINANCIER_NAME": "Samastipur Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 89,
      "TXT_FINANCIER_NAME": "Chhattisgarh Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 90,
      "TXT_FINANCIER_NAME": "Durg-Rajnandgaon Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 91,
      "TXT_FINANCIER_NAME": "Dena Gujarat Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 92,
      "TXT_FINANCIER_NAME": "Saurashtra Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 93,
      "TXT_FINANCIER_NAME": "Harayana Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 94,
      "TXT_FINANCIER_NAME": "Himachal Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 95,
      "TXT_FINANCIER_NAME": "Jammu Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 96,
      "TXT_FINANCIER_NAME": "Kamraz Rural Bank"
    },
    {
      "NUM_FINANCIER_CD": 97,
      "TXT_FINANCIER_NAME": "Jharkhand Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 98,
      "TXT_FINANCIER_NAME": "Karnataka Vikas Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 99,
      "TXT_FINANCIER_NAME": "Cauvery Kalpatharu Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 100,
      "TXT_FINANCIER_NAME": "Chikmagalur-Kodagu Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 101,
      "TXT_FINANCIER_NAME": "Narmada Malwa Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 103,
      "TXT_FINANCIER_NAME": "Madhya Bharath Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 104,
      "TXT_FINANCIER_NAME": "Rewa-Sidhi Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 105,
      "TXT_FINANCIER_NAME": "Ratlam- Mandsaur Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 106,
      "TXT_FINANCIER_NAME": "Mahakaushal Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 107,
      "TXT_FINANCIER_NAME": "Marathwada Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 108,
      "TXT_FINANCIER_NAME": "Wainganga Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 109,
      "TXT_FINANCIER_NAME": "Solapur Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 110,
      "TXT_FINANCIER_NAME": "Ratnagiri-Sindhudurg Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 111,
      "TXT_FINANCIER_NAME": "Kalinga Gramya Bank"
    },
    {
      "NUM_FINANCIER_CD": 112,
      "TXT_FINANCIER_NAME": "Baitarani Gramya Bank"
    },
    {
      "NUM_FINANCIER_CD": 113,
      "TXT_FINANCIER_NAME": "Rushikulya Gramya Bank"
    },
    {
      "NUM_FINANCIER_CD": 209,
      "TXT_FINANCIER_NAME": "Abhyudaya Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 210,
      "TXT_FINANCIER_NAME": "Ahmedabad Mercantile Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 211,
      "TXT_FINANCIER_NAME": "Amanath Co-operative Bank Ltd. Bangalore"
    },
    {
      "NUM_FINANCIER_CD": 212,
      "TXT_FINANCIER_NAME": "Andhra Pradesh Mahesh Co-Op Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 213,
      "TXT_FINANCIER_NAME": "Bassein Catholic Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 214,
      "TXT_FINANCIER_NAME": "Bharat Co-Operative Bank (Mumbai) Ltd"
    },
    {
      "NUM_FINANCIER_CD": 215,
      "TXT_FINANCIER_NAME": "Bharati Sahakari Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 216,
      "TXT_FINANCIER_NAME": "Bombay Mercantile Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 217,
      "TXT_FINANCIER_NAME": "Charminar Coop.Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 218,
      "TXT_FINANCIER_NAME": "CitizenCredit Coop. Bank Ltd.Dadar"
    },
    {
      "NUM_FINANCIER_CD": 219,
      "TXT_FINANCIER_NAME": "Coop. Bank Of Ahmedabad Ltd"
    },
    {
      "NUM_FINANCIER_CD": 220,
      "TXT_FINANCIER_NAME": "Cosmos Coop. Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 221,
      "TXT_FINANCIER_NAME": "Dombivli Nagari Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 222,
      "TXT_FINANCIER_NAME": "Goa Urban Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 223,
      "TXT_FINANCIER_NAME": "Greater Bombay Co op bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 224,
      "TXT_FINANCIER_NAME": "Ichalkaranji Janata Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 225,
      "TXT_FINANCIER_NAME": "Indian Mercantile Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 226,
      "TXT_FINANCIER_NAME": "Jalgaon Janata sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 227,
      "TXT_FINANCIER_NAME": "Janakalyan Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 228,
      "TXT_FINANCIER_NAME": "Janalaxmi Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 229,
      "TXT_FINANCIER_NAME": "Janata Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 230,
      "TXT_FINANCIER_NAME": "Kalupur Commercial Coop.Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 231,
      "TXT_FINANCIER_NAME": "KALYAN JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 232,
      "TXT_FINANCIER_NAME": "Kapole Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 233,
      "TXT_FINANCIER_NAME": "Karad Urban Co-op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 234,
      "TXT_FINANCIER_NAME": "Madhavpura Mercantile Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 235,
      "TXT_FINANCIER_NAME": "Mahanagar Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 236,
      "TXT_FINANCIER_NAME": "Mandvi Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 237,
      "TXT_FINANCIER_NAME": "Mapusa Urban Co-Op. Bank Of Goa Ltd"
    },
    {
      "NUM_FINANCIER_CD": 238,
      "TXT_FINANCIER_NAME": "Mehsana Urban Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 239,
      "TXT_FINANCIER_NAME": "Nagar Urban Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 240,
      "TXT_FINANCIER_NAME": "Nagpur Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 241,
      "TXT_FINANCIER_NAME": "NASIK MERCHANTS COOP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 242,
      "TXT_FINANCIER_NAME": "New India Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 243,
      "TXT_FINANCIER_NAME": "North Kanara Goud Saraswat Brahmins Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 244,
      "TXT_FINANCIER_NAME": "Nutan Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 245,
      "TXT_FINANCIER_NAME": "Parsik Janata Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 246,
      "TXT_FINANCIER_NAME": "Pravara Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 247,
      "TXT_FINANCIER_NAME": "Punjab & Maharashtra Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 248,
      "TXT_FINANCIER_NAME": "Rajkot Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 249,
      "TXT_FINANCIER_NAME": "Rupee Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 250,
      "TXT_FINANCIER_NAME": "Sangli Urban Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 251,
      "TXT_FINANCIER_NAME": "Saraswat Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 252,
      "TXT_FINANCIER_NAME": "Sardar Bhiladwala Pardi Peoples Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 253,
      "TXT_FINANCIER_NAME": "Shamrao Vithal Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 254,
      "TXT_FINANCIER_NAME": "Shikshak Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 255,
      "TXT_FINANCIER_NAME": "Solapur Janata Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 256,
      "TXT_FINANCIER_NAME": "Surat Peoples Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 257,
      "TXT_FINANCIER_NAME": "Thane Bharat Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 258,
      "TXT_FINANCIER_NAME": "Thane Janata Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 259,
      "TXT_FINANCIER_NAME": "The Akola Janata Commercial Co-op. Bank"
    },
    {
      "NUM_FINANCIER_CD": 260,
      "TXT_FINANCIER_NAME": "The Akola Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 261,
      "TXT_FINANCIER_NAME": "The Khamgaon Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 262,
      "TXT_FINANCIER_NAME": "Vasavi Coop Urban Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 263,
      "TXT_FINANCIER_NAME": "Zoroastrian Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 264,
      "TXT_FINANCIER_NAME": "HDFC ERGO GIC"
    },
    {
      "NUM_FINANCIER_CD": 265,
      "TXT_FINANCIER_NAME": "HDFC CREDIT CARD COLLECTION AC"
    },
    {
      "NUM_FINANCIER_CD": 266,
      "TXT_FINANCIER_NAME": "HDFC STANDING INST BANK ACCOUNT A&H"
    },
    {
      "NUM_FINANCIER_CD": 267,
      "TXT_FINANCIER_NAME": "HDFC DIRECT DEBIT"
    },
    {
      "NUM_FINANCIER_CD": 268,
      "TXT_FINANCIER_NAME": "HDFC CLAIMS PAYMENT BANK A/C"
    },
    {
      "NUM_FINANCIER_CD": 269,
      "TXT_FINANCIER_NAME": "HDFC AGENT COMMISSION A/C"
    },
    {
      "NUM_FINANCIER_CD": 270,
      "TXT_FINANCIER_NAME": "HDFC GENERAL EXPENSE BANK A/C"
    },
    {
      "NUM_FINANCIER_CD": 271,
      "TXT_FINANCIER_NAME": "CITIBANK CLAIM PAYMENT ACC"
    },
    {
      "NUM_FINANCIER_CD": 272,
      "TXT_FINANCIER_NAME": "CITI ECS COMMISION"
    },
    {
      "NUM_FINANCIER_CD": 273,
      "TXT_FINANCIER_NAME": "BARCLAYS BANK"
    },
    {
      "NUM_FINANCIER_CD": 274,
      "TXT_FINANCIER_NAME": "HDFC BANK LTD (DO NOT USE)"
    },
    {
      "NUM_FINANCIER_CD": 275,
      "TXT_FINANCIER_NAME": "ICICI BANK"
    },
    {
      "NUM_FINANCIER_CD": 276,
      "TXT_FINANCIER_NAME": "Adajan Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 277,
      "TXT_FINANCIER_NAME": "Adarsh Mahila Co-Operative Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 278,
      "TXT_FINANCIER_NAME": "Ahmedabad Peoples Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 279,
      "TXT_FINANCIER_NAME": "Akhand Anand Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 280,
      "TXT_FINANCIER_NAME": "Alavi Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 281,
      "TXT_FINANCIER_NAME": "Amarnath Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 282,
      "TXT_FINANCIER_NAME": "Amod Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 283,
      "TXT_FINANCIER_NAME": "Amreli Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 284,
      "TXT_FINANCIER_NAME": "Anand Mercantile Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 285,
      "TXT_FINANCIER_NAME": "Anand Urban Co-Op.Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 286,
      "TXT_FINANCIER_NAME": "Ankaleshwar Udyognagar Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 287,
      "TXT_FINANCIER_NAME": "Ankleshwar Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 288,
      "TXT_FINANCIER_NAME": "Ankleshwar Taluka Mahila Nagrik Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 289,
      "TXT_FINANCIER_NAME": "Anyonya Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 290,
      "TXT_FINANCIER_NAME": "Apani Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 291,
      "TXT_FINANCIER_NAME": "Bahadarpur Urban co-op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 292,
      "TXT_FINANCIER_NAME": "Balsinor Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 293,
      "TXT_FINANCIER_NAME": "Banaskantha Mercantile co-op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 294,
      "TXT_FINANCIER_NAME": "Bapunagar Maahilaa Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 295,
      "TXT_FINANCIER_NAME": "Bardoli Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 296,
      "TXT_FINANCIER_NAME": "Baroda city co-op bank limited"
    },
    {
      "NUM_FINANCIER_CD": 297,
      "TXT_FINANCIER_NAME": "Baroda District Indutrial co-op bank limited"
    },
    {
      "NUM_FINANCIER_CD": 298,
      "TXT_FINANCIER_NAME": "Baroda Mercantile Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 299,
      "TXT_FINANCIER_NAME": "Baroda Trader's Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 300,
      "TXT_FINANCIER_NAME": "Bavla Nagarik Sahakari Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 301,
      "TXT_FINANCIER_NAME": "Bayad Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 302,
      "TXT_FINANCIER_NAME": "Bechraji Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 303,
      "TXT_FINANCIER_NAME": "Bhabhar Vibhag Nagarik sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 304,
      "TXT_FINANCIER_NAME": "Bhadran People's Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 305,
      "TXT_FINANCIER_NAME": "Bhagyodaya co-op Bank limited"
    },
    {
      "NUM_FINANCIER_CD": 306,
      "TXT_FINANCIER_NAME": "Bharuch Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 307,
      "TXT_FINANCIER_NAME": "Bhavnagar Mahila Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 308,
      "TXT_FINANCIER_NAME": "Bhavnagar Mercantile Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 309,
      "TXT_FINANCIER_NAME": "Bhuj Commercial Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 310,
      "TXT_FINANCIER_NAME": "Bhuj Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 311,
      "TXT_FINANCIER_NAME": "Bodeli Urban Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 312,
      "TXT_FINANCIER_NAME": "Boriavi People's Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 313,
      "TXT_FINANCIER_NAME": "Borsad Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 314,
      "TXT_FINANCIER_NAME": "Botad Peoples Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 315,
      "TXT_FINANCIER_NAME": "Cambay Hindu Merchants Coop. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 316,
      "TXT_FINANCIER_NAME": "Century Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 317,
      "TXT_FINANCIER_NAME": "Chanasma Commercial Coop. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 318,
      "TXT_FINANCIER_NAME": "Chanasma Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 319,
      "TXT_FINANCIER_NAME": "Charda Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 320,
      "TXT_FINANCIER_NAME": "Chhapi Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 321,
      "TXT_FINANCIER_NAME": "Citizen Coop Bank Limited Rajkot"
    },
    {
      "NUM_FINANCIER_CD": 322,
      "TXT_FINANCIER_NAME": "City Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 323,
      "TXT_FINANCIER_NAME": "Colour Merchants Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 324,
      "TXT_FINANCIER_NAME": "Commercial Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 325,
      "TXT_FINANCIER_NAME": "Commercial Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 326,
      "TXT_FINANCIER_NAME": "Coop.Bank Of Baroda Ltd"
    },
    {
      "NUM_FINANCIER_CD": 327,
      "TXT_FINANCIER_NAME": "Coop.Bank Of Rajkot Ltd"
    },
    {
      "NUM_FINANCIER_CD": 328,
      "TXT_FINANCIER_NAME": "Co-operative bank of Mehsana Ltd"
    },
    {
      "NUM_FINANCIER_CD": 329,
      "TXT_FINANCIER_NAME": "Dahod Mercantile Co-op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 330,
      "TXT_FINANCIER_NAME": "Dahod Urban Co-op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 331,
      "TXT_FINANCIER_NAME": "Dakor Mahila Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 332,
      "TXT_FINANCIER_NAME": "Dakor Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 333,
      "TXT_FINANCIER_NAME": "Dhanera Mercantile Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 334,
      "TXT_FINANCIER_NAME": "Dharmaj Peoples' Co-op.Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 335,
      "TXT_FINANCIER_NAME": "Dhinoj Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 336,
      "TXT_FINANCIER_NAME": "Dhrangadhra Peoples Co-op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 337,
      "TXT_FINANCIER_NAME": "Financial Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 338,
      "TXT_FINANCIER_NAME": "Gandevi People's Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 339,
      "TXT_FINANCIER_NAME": "Gandhidham Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 340,
      "TXT_FINANCIER_NAME": "Gandhidham Mercantile Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 341,
      "TXT_FINANCIER_NAME": "Gandhinagar Nagarik Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 342,
      "TXT_FINANCIER_NAME": "Gandhinagar Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 343,
      "TXT_FINANCIER_NAME": "Ghoghamba Vibhag Nagarik Sahakari Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 344,
      "TXT_FINANCIER_NAME": "Godhra City Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 345,
      "TXT_FINANCIER_NAME": "Godhra Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 346,
      "TXT_FINANCIER_NAME": "Gondal Nagarik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 347,
      "TXT_FINANCIER_NAME": "Gozaria Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 348,
      "TXT_FINANCIER_NAME": "Gujarat Ambuja Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 349,
      "TXT_FINANCIER_NAME": "Gujarat Industrial Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 350,
      "TXT_FINANCIER_NAME": "Gujarat Mercantile Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 351,
      "TXT_FINANCIER_NAME": "Halol Mercantile Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 352,
      "TXT_FINANCIER_NAME": "Halol Urban Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 353,
      "TXT_FINANCIER_NAME": "Hansot Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 354,
      "TXT_FINANCIER_NAME": "Harij Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 355,
      "TXT_FINANCIER_NAME": "Himatnagar Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 356,
      "TXT_FINANCIER_NAME": "Idar Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 357,
      "TXT_FINANCIER_NAME": "Jambusar People's Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 358,
      "TXT_FINANCIER_NAME": "Jamnagar mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 359,
      "TXT_FINANCIER_NAME": "Jamnagar People's Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 360,
      "TXT_FINANCIER_NAME": "Janata Coop. Bank Ltd Godhra."
    },
    {
      "NUM_FINANCIER_CD": 361,
      "TXT_FINANCIER_NAME": "Jhalod Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 362,
      "TXT_FINANCIER_NAME": "Jivan Commercial Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 363,
      "TXT_FINANCIER_NAME": "Junagadh Commercial Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 364,
      "TXT_FINANCIER_NAME": "Junagadh Vibhagiya Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 365,
      "TXT_FINANCIER_NAME": "Kalol Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 366,
      "TXT_FINANCIER_NAME": "Kalol Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 367,
      "TXT_FINANCIER_NAME": "Kalyan Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 368,
      "TXT_FINANCIER_NAME": "Kankaria Maninagar Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 369,
      "TXT_FINANCIER_NAME": "Kapadwanj Peoples' Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 370,
      "TXT_FINANCIER_NAME": "Karamsad Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 371,
      "TXT_FINANCIER_NAME": "Karjan Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 372,
      "TXT_FINANCIER_NAME": "Karmachari Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 373,
      "TXT_FINANCIER_NAME": "Karnavathi Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 374,
      "TXT_FINANCIER_NAME": "Khambhat Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 375,
      "TXT_FINANCIER_NAME": "Kheda Peoples' Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 376,
      "TXT_FINANCIER_NAME": "Khedbrahma Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 377,
      "TXT_FINANCIER_NAME": "Kheralu Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 378,
      "TXT_FINANCIER_NAME": "Kodinar Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 379,
      "TXT_FINANCIER_NAME": "Kosamba Mercantile Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 380,
      "TXT_FINANCIER_NAME": "Kuber Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 381,
      "TXT_FINANCIER_NAME": "Kukarwada Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 382,
      "TXT_FINANCIER_NAME": "Kutch Mercantile Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 383,
      "TXT_FINANCIER_NAME": "Lakhvad Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 384,
      "TXT_FINANCIER_NAME": "Lalbaug Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 385,
      "TXT_FINANCIER_NAME": "Limbasi Urban Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 386,
      "TXT_FINANCIER_NAME": "Limdi Urban Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 387,
      "TXT_FINANCIER_NAME": "Lunawada Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 388,
      "TXT_FINANCIER_NAME": "Lunawada People's Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 389,
      "TXT_FINANCIER_NAME": "M.S. Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 390,
      "TXT_FINANCIER_NAME": "Mahila Co-Operative Nagrik Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 391,
      "TXT_FINANCIER_NAME": "Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 392,
      "TXT_FINANCIER_NAME": "Mahila Utkarsh Nagarik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 393,
      "TXT_FINANCIER_NAME": "Mahudha Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 394,
      "TXT_FINANCIER_NAME": "Makarpura Industrial Estate Co-op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 395,
      "TXT_FINANCIER_NAME": "Malpur Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 396,
      "TXT_FINANCIER_NAME": "Mandal Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 397,
      "TXT_FINANCIER_NAME": "Mandvi Mercantile Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 398,
      "TXT_FINANCIER_NAME": "Mandvi Nagrik Sahakari Bank LTd."
    },
    {
      "NUM_FINANCIER_CD": 399,
      "TXT_FINANCIER_NAME": "Manek Chowk Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 400,
      "TXT_FINANCIER_NAME": "Maninagar Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 401,
      "TXT_FINANCIER_NAME": "Mansa Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 402,
      "TXT_FINANCIER_NAME": "Marketyard Commercial Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 403,
      "TXT_FINANCIER_NAME": "Megharaj Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 404,
      "TXT_FINANCIER_NAME": "Mehmadabad Urban People's Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 405,
      "TXT_FINANCIER_NAME": "Mehsana Jilla Panchayat Karmachari Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 406,
      "TXT_FINANCIER_NAME": "Mehsana Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 407,
      "TXT_FINANCIER_NAME": "Mehsana Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 408,
      "TXT_FINANCIER_NAME": "Mercantile Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 409,
      "TXT_FINANCIER_NAME": "Modasa Nagrik Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 410,
      "TXT_FINANCIER_NAME": "Municipal Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 411,
      "TXT_FINANCIER_NAME": "Nadiad People's Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 412,
      "TXT_FINANCIER_NAME": "Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 413,
      "TXT_FINANCIER_NAME": "Naroda Industrial Co-Operative bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 414,
      "TXT_FINANCIER_NAME": "Naroda Nagrik Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 415,
      "TXT_FINANCIER_NAME": "National Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1275,
      "TXT_FINANCIER_NAME": "AVB Employees' Co-operative Credit Society & Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1276,
      "TXT_FINANCIER_NAME": "Balitikuri Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1277,
      "TXT_FINANCIER_NAME": "Bally Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1278,
      "TXT_FINANCIER_NAME": "Baranagar Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1279,
      "TXT_FINANCIER_NAME": "Bhatpara Naihati Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1280,
      "TXT_FINANCIER_NAME": "Budge-Budge Nangi Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1281,
      "TXT_FINANCIER_NAME": "Citizens' Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1282,
      "TXT_FINANCIER_NAME": "Contai Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1283,
      "TXT_FINANCIER_NAME": "Dhakuria Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1284,
      "TXT_FINANCIER_NAME": "Durgapur Mahila Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1285,
      "TXT_FINANCIER_NAME": "Durgapur Steel Peoples' Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1286,
      "TXT_FINANCIER_NAME": "Eastern & North East Frontier Railway Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1287,
      "TXT_FINANCIER_NAME": "Kasundia Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1288,
      "TXT_FINANCIER_NAME": "Khardah Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1289,
      "TXT_FINANCIER_NAME": "Kolikata Mahila Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1290,
      "TXT_FINANCIER_NAME": "Kolkata Police Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1291,
      "TXT_FINANCIER_NAME": "Konnagar Samabaya Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1292,
      "TXT_FINANCIER_NAME": "Liluah Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1293,
      "TXT_FINANCIER_NAME": "Nabagram People's Co-operative Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1294,
      "TXT_FINANCIER_NAME": "National Insurance Emp. Co-operative Cr.& Banking Soc.Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1295,
      "TXT_FINANCIER_NAME": "Ramkrishnapur Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1296,
      "TXT_FINANCIER_NAME": "Ranaghat People's Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1297,
      "TXT_FINANCIER_NAME": "Raniganj Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1298,
      "TXT_FINANCIER_NAME": "Samata Co-operative Development Bank"
    },
    {
      "NUM_FINANCIER_CD": 1299,
      "TXT_FINANCIER_NAME": "Sree Chaitanya Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1300,
      "TXT_FINANCIER_NAME": "State Bank Of India Staff Association Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1301,
      "TXT_FINANCIER_NAME": "The Baidyabati Sheoraphuli Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1302,
      "TXT_FINANCIER_NAME": "The Bank Employees' Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1303,
      "TXT_FINANCIER_NAME": "The Bankura Town Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1304,
      "TXT_FINANCIER_NAME": "The Bantra Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1305,
      "TXT_FINANCIER_NAME": "The Bishnupur Town Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1306,
      "TXT_FINANCIER_NAME": "The Boral Union Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1307,
      "TXT_FINANCIER_NAME": "The Eastern Railway Employees'Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1308,
      "TXT_FINANCIER_NAME": "The Ghatal Peoples' Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1309,
      "TXT_FINANCIER_NAME": "The Hooghly Co-operative Credit Bank"
    },
    {
      "NUM_FINANCIER_CD": 1310,
      "TXT_FINANCIER_NAME": "The Jaynagar Mozilpur Peoples' Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1311,
      "TXT_FINANCIER_NAME": "The Khatra Peoples' Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1312,
      "TXT_FINANCIER_NAME": "The Kalna Town Credit Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1313,
      "TXT_FINANCIER_NAME": "The Krishnagar City Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1314,
      "TXT_FINANCIER_NAME": "The Midnapore People's Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1315,
      "TXT_FINANCIER_NAME": "The Nabadwip Co-operative Credit Bank"
    },
    {
      "NUM_FINANCIER_CD": 1316,
      "TXT_FINANCIER_NAME": "The Nabapalli Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1317,
      "TXT_FINANCIER_NAME": "The Panihati Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1318,
      "TXT_FINANCIER_NAME": "The Rahuta Union Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1319,
      "TXT_FINANCIER_NAME": "The Santragachi Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1320,
      "TXT_FINANCIER_NAME": "The Shibpur Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1321,
      "TXT_FINANCIER_NAME": "The Suri Friends' Union Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1322,
      "TXT_FINANCIER_NAME": "The Union Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1323,
      "TXT_FINANCIER_NAME": "The Uttarpara Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1324,
      "TXT_FINANCIER_NAME": "United Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1325,
      "TXT_FINANCIER_NAME": "W.B.Govt.Press Employees' CooperativeThrift & Cr.Society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1326,
      "TXT_FINANCIER_NAME": "(510)Army Base Work-Shop Credit Cooperative Primary Bank Ltd.Meerut"
    },
    {
      "NUM_FINANCIER_CD": 1327,
      "TXT_FINANCIER_NAME": "Adarsh Mahila Urban Co-operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1328,
      "TXT_FINANCIER_NAME": "Almora Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1329,
      "TXT_FINANCIER_NAME": "Banaras Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1330,
      "TXT_FINANCIER_NAME": "Banda Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1331,
      "TXT_FINANCIER_NAME": "Bhadohi Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1332,
      "TXT_FINANCIER_NAME": "Brahmawart Commercial Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1333,
      "TXT_FINANCIER_NAME": "Bijnor Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1334,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 1335,
      "TXT_FINANCIER_NAME": "Dayalbagh Mahila Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1336,
      "TXT_FINANCIER_NAME": "Development Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1337,
      "TXT_FINANCIER_NAME": "Doon Valley Urban Co-operative (SC/ST)Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1338,
      "TXT_FINANCIER_NAME": "Etah Urban Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1339,
      "TXT_FINANCIER_NAME": "Etawah Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1340,
      "TXT_FINANCIER_NAME": "Ganga Mercantile Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1341,
      "TXT_FINANCIER_NAME": "Garhwal Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1342,
      "TXT_FINANCIER_NAME": "Ghaziabad Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1343,
      "TXT_FINANCIER_NAME": "Ghazipur Urban Co-operative Bank Ltd.."
    },
    {
      "NUM_FINANCIER_CD": 1344,
      "TXT_FINANCIER_NAME": "Gomthi Nagariya Sahkari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1345,
      "TXT_FINANCIER_NAME": "Gulshan Mercantile Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1346,
      "TXT_FINANCIER_NAME": "Hardoi Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1347,
      "TXT_FINANCIER_NAME": "Hindustan Co-operative bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1348,
      "TXT_FINANCIER_NAME": "Imperial Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1349,
      "TXT_FINANCIER_NAME": "Kashipur Urban Co-opeerative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1350,
      "TXT_FINANCIER_NAME": "Khalilabad Nagar Sahkari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1351,
      "TXT_FINANCIER_NAME": "Kurmanchal Nagar Sahkari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1352,
      "TXT_FINANCIER_NAME": "Lucknow University Adm.Staff Primary Cooperative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1353,
      "TXT_FINANCIER_NAME": "Lucknow Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1354,
      "TXT_FINANCIER_NAME": "Mahamedha Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1355,
      "TXT_FINANCIER_NAME": "Mahoba Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1356,
      "TXT_FINANCIER_NAME": "Mansarovar Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1357,
      "TXT_FINANCIER_NAME": "Mechanical Department Primary Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1358,
      "TXT_FINANCIER_NAME": "Mercantile Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1359,
      "TXT_FINANCIER_NAME": "Mirzapur Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1360,
      "TXT_FINANCIER_NAME": "N.E. Rly.Emp.Multi State Pri.Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1361,
      "TXT_FINANCIER_NAME": "Nagar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1362,
      "TXT_FINANCIER_NAME": "Nagar Vikas Sahkari Bank ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1363,
      "TXT_FINANCIER_NAME": "Nagina Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1364,
      "TXT_FINANCIER_NAME": "NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 1365,
      "TXT_FINANCIER_NAME": "National Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1366,
      "TXT_FINANCIER_NAME": "National Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1367,
      "TXT_FINANCIER_NAME": "National Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1368,
      "TXT_FINANCIER_NAME": "New Agra Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1369,
      "TXT_FINANCIER_NAME": "New Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1370,
      "TXT_FINANCIER_NAME": "Noble co-operative bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1371,
      "TXT_FINANCIER_NAME": "Noida Commercial Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1372,
      "TXT_FINANCIER_NAME": "Northern Railway Employees' Primary Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1373,
      "TXT_FINANCIER_NAME": "Omkar Nagriya Sahkari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1374,
      "TXT_FINANCIER_NAME": "Ordinance Equip. Factory Prarambhik Sahkari Bk Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1375,
      "TXT_FINANCIER_NAME": "Peoples Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1376,
      "TXT_FINANCIER_NAME": "Pioneer Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1377,
      "TXT_FINANCIER_NAME": "Progressive Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1378,
      "TXT_FINANCIER_NAME": "R.B.I.Employees' Co-op Credit Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1379,
      "TXT_FINANCIER_NAME": "Radhasoami Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1380,
      "TXT_FINANCIER_NAME": "Rajdhani Nagar Sahkari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1381,
      "TXT_FINANCIER_NAME": "Ranilaxmibai Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1382,
      "TXT_FINANCIER_NAME": "Shivalik Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1383,
      "TXT_FINANCIER_NAME": "Sonbhadra Nagar Sahkari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1384,
      "TXT_FINANCIER_NAME": "U.P.Civil Secretriat Primary Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1385,
      "TXT_FINANCIER_NAME": "U.P.Postal Primary Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1386,
      "TXT_FINANCIER_NAME": "United India Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1387,
      "TXT_FINANCIER_NAME": "United Commercial Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1388,
      "TXT_FINANCIER_NAME": "United Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 531,
      "TXT_FINANCIER_NAME": "Thasra Peoples'Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 532,
      "TXT_FINANCIER_NAME": "The Adinath Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 533,
      "TXT_FINANCIER_NAME": "The Amruta Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 534,
      "TXT_FINANCIER_NAME": "THE ASSOCIATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 535,
      "TXT_FINANCIER_NAME": "The Mahila Vikas Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 536,
      "TXT_FINANCIER_NAME": "The Vardhman Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 537,
      "TXT_FINANCIER_NAME": "Udhana Citizen Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 538,
      "TXT_FINANCIER_NAME": "Uma Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 539,
      "TXT_FINANCIER_NAME": "Umreth People's Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 540,
      "TXT_FINANCIER_NAME": "Umreth Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 541,
      "TXT_FINANCIER_NAME": "Una Peoples' Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 542,
      "TXT_FINANCIER_NAME": "Unava Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 543,
      "TXT_FINANCIER_NAME": "Union Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 544,
      "TXT_FINANCIER_NAME": "Union Commercial Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 545,
      "TXT_FINANCIER_NAME": "United Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 546,
      "TXT_FINANCIER_NAME": "UNITED MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 547,
      "TXT_FINANCIER_NAME": "Unjha Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 548,
      "TXT_FINANCIER_NAME": "Unnati Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 549,
      "TXT_FINANCIER_NAME": "Uttarsanda Peoples Coop Bank Ltd;"
    },
    {
      "NUM_FINANCIER_CD": 550,
      "TXT_FINANCIER_NAME": "Vadali Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 551,
      "TXT_FINANCIER_NAME": "Vadnagar Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 552,
      "TXT_FINANCIER_NAME": "Vallabh Vidhyanagar Commercial Co op Bank"
    },
    {
      "NUM_FINANCIER_CD": 553,
      "TXT_FINANCIER_NAME": "Valsad Mahila Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 554,
      "TXT_FINANCIER_NAME": "Varachha Co-operative bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 555,
      "TXT_FINANCIER_NAME": "Vaso Coop Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 556,
      "TXT_FINANCIER_NAME": "Vejalpur Nagarik Sahakari bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 557,
      "TXT_FINANCIER_NAME": "Vepar Udhyog Vikas Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 558,
      "TXT_FINANCIER_NAME": "Vepar Vikas Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 559,
      "TXT_FINANCIER_NAME": "Veraval Mercantile Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 560,
      "TXT_FINANCIER_NAME": "Veraval Peoples Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 561,
      "TXT_FINANCIER_NAME": "Vijapur Nagrik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 562,
      "TXT_FINANCIER_NAME": "Vijay Commercial Coop Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 563,
      "TXT_FINANCIER_NAME": "Vijay Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 564,
      "TXT_FINANCIER_NAME": "Viramgam Mercantile Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 565,
      "TXT_FINANCIER_NAME": "Virpur Urban Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 566,
      "TXT_FINANCIER_NAME": "Waghodia Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 567,
      "TXT_FINANCIER_NAME": "Abhivriddhi Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 568,
      "TXT_FINANCIER_NAME": "Abhyudaya Mahila Urban Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 569,
      "TXT_FINANCIER_NAME": "Adarsha Pattana Sahakara Bank"
    },
    {
      "NUM_FINANCIER_CD": 570,
      "TXT_FINANCIER_NAME": "Akkamahadevi Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 571,
      "TXT_FINANCIER_NAME": "Ananda Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 572,
      "TXT_FINANCIER_NAME": "Ankalgi Urban Co-operative Credit Bank"
    },
    {
      "NUM_FINANCIER_CD": 573,
      "TXT_FINANCIER_NAME": "Ankola Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 574,
      "TXT_FINANCIER_NAME": "Anubhav Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 575,
      "TXT_FINANCIER_NAME": "Aroodh Jyoti Pattan Sahakara Bank"
    },
    {
      "NUM_FINANCIER_CD": 576,
      "TXT_FINANCIER_NAME": "Arsikere Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 577,
      "TXT_FINANCIER_NAME": "Aruna Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 578,
      "TXT_FINANCIER_NAME": "Arya Vaishya Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 579,
      "TXT_FINANCIER_NAME": "Ashoknagar Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 580,
      "TXT_FINANCIER_NAME": "Azad Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 581,
      "TXT_FINANCIER_NAME": "Azad Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 582,
      "TXT_FINANCIER_NAME": "Bagalkot Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 583,
      "TXT_FINANCIER_NAME": "Bailhongal Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 584,
      "TXT_FINANCIER_NAME": "Bangalore Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 585,
      "TXT_FINANCIER_NAME": "Bapuji Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 586,
      "TXT_FINANCIER_NAME": "Basavakalyan Pattana Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 587,
      "TXT_FINANCIER_NAME": "Basaveshwar Sahakar Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 588,
      "TXT_FINANCIER_NAME": "Belgaum Zilla Rani Channamma Mahila"
    },
    {
      "NUM_FINANCIER_CD": 589,
      "TXT_FINANCIER_NAME": "Bellad Bagewadi Urban Souharda"
    },
    {
      "NUM_FINANCIER_CD": 590,
      "TXT_FINANCIER_NAME": "Belur Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 591,
      "TXT_FINANCIER_NAME": "Bharathiya Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 592,
      "TXT_FINANCIER_NAME": "Bhavsara Kshatriya Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 593,
      "TXT_FINANCIER_NAME": "Bidar Mahila Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 594,
      "TXT_FINANCIER_NAME": "Bijapur District Mahila Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 595,
      "TXT_FINANCIER_NAME": "Bijapur Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 596,
      "TXT_FINANCIER_NAME": "Bijapur Zilla Sarkari Naukarara Sahakari"
    },
    {
      "NUM_FINANCIER_CD": 597,
      "TXT_FINANCIER_NAME": "Bilagi Pattan Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 598,
      "TXT_FINANCIER_NAME": "Chaitanya Mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 599,
      "TXT_FINANCIER_NAME": "Chartered Sahakari Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 600,
      "TXT_FINANCIER_NAME": "Chetana Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 601,
      "TXT_FINANCIER_NAME": "Chikmagalur Jilla Mahila Sahakara Bank"
    },
    {
      "NUM_FINANCIER_CD": 602,
      "TXT_FINANCIER_NAME": "Chikmagalur Pattana Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 603,
      "TXT_FINANCIER_NAME": "City Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 604,
      "TXT_FINANCIER_NAME": "City Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 605,
      "TXT_FINANCIER_NAME": "City Co-operative Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 606,
      "TXT_FINANCIER_NAME": "Comptroller's Office Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 607,
      "TXT_FINANCIER_NAME": "Daivadnya Sahakara Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 608,
      "TXT_FINANCIER_NAME": "Dakshina Kannada Zilla Mahilla Cooperative"
    },
    {
      "NUM_FINANCIER_CD": 609,
      "TXT_FINANCIER_NAME": "Davangere Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 610,
      "TXT_FINANCIER_NAME": "Deccan Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 611,
      "TXT_FINANCIER_NAME": "Deepak Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 612,
      "TXT_FINANCIER_NAME": "Dhanashree Urban Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 613,
      "TXT_FINANCIER_NAME": "Government Employees Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 614,
      "TXT_FINANCIER_NAME": "Grain Merchants' Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 615,
      "TXT_FINANCIER_NAME": "Guardian Souharda Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 616,
      "TXT_FINANCIER_NAME": "Gulbarga Janata Sahakari Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 617,
      "TXT_FINANCIER_NAME": "Hadgali Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 618,
      "TXT_FINANCIER_NAME": "Hanamasagar Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 619,
      "TXT_FINANCIER_NAME": "Harugeri Urban Co-operative Credit Bank"
    },
    {
      "NUM_FINANCIER_CD": 620,
      "TXT_FINANCIER_NAME": "Hospet Co-operative City Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 621,
      "TXT_FINANCIER_NAME": "Hubli Urban Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 622,
      "TXT_FINANCIER_NAME": "Ilkal Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 623,
      "TXT_FINANCIER_NAME": "Janatha Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 819,
      "TXT_FINANCIER_NAME": "The Primary Teachers' Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 820,
      "TXT_FINANCIER_NAME": "The Puttur Co-operative Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 821,
      "TXT_FINANCIER_NAME": "The Railway Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 822,
      "TXT_FINANCIER_NAME": "The Rajajinagar Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 823,
      "TXT_FINANCIER_NAME": "The Ron Taluka Primary Teachers Coop Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 824,
      "TXT_FINANCIER_NAME": "The Sahasrarjun Seva Kalyana Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 825,
      "TXT_FINANCIER_NAME": "The Savanur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 826,
      "TXT_FINANCIER_NAME": "The Shedbal Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 827,
      "TXT_FINANCIER_NAME": "The Shiggaon Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 828,
      "TXT_FINANCIER_NAME": "The Sindgi Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 829,
      "TXT_FINANCIER_NAME": "The Sirsi Urban Souharda Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 830,
      "TXT_FINANCIER_NAME": "The South Kanara Government Officers Bank"
    },
    {
      "NUM_FINANCIER_CD": 831,
      "TXT_FINANCIER_NAME": "The Talikoti Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 832,
      "TXT_FINANCIER_NAME": "The Textile Manufacturers'Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 833,
      "TXT_FINANCIER_NAME": "The Town Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 834,
      "TXT_FINANCIER_NAME": "The Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 835,
      "TXT_FINANCIER_NAME": "The Vardhaman Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 836,
      "TXT_FINANCIER_NAME": "The Yaragatti Urban Co-operative Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 837,
      "TXT_FINANCIER_NAME": "Tumkur Grain Merchants Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 838,
      "TXT_FINANCIER_NAME": "Tumkur Pattana Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 839,
      "TXT_FINANCIER_NAME": "Tumkur Veerashaiva Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 840,
      "TXT_FINANCIER_NAME": "Udupi Co-operative Town Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 841,
      "TXT_FINANCIER_NAME": "Varada Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 842,
      "TXT_FINANCIER_NAME": "Veershaiva Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 843,
      "TXT_FINANCIER_NAME": "Vikas Souharda Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 844,
      "TXT_FINANCIER_NAME": "Vikas Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 845,
      "TXT_FINANCIER_NAME": "Virajpet Pattana sahakara Bank"
    },
    {
      "NUM_FINANCIER_CD": 846,
      "TXT_FINANCIER_NAME": "Vishvesvaraya Iron & Steel Employees Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 847,
      "TXT_FINANCIER_NAME": "Vishwakalyan Sahakara Bank Niyamit,"
    },
    {
      "NUM_FINANCIER_CD": 848,
      "TXT_FINANCIER_NAME": "Vishwakarma Sahakara Bank Niyamita,"
    },
    {
      "NUM_FINANCIER_CD": 849,
      "TXT_FINANCIER_NAME": "Vysya Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 850,
      "TXT_FINANCIER_NAME": "Anandeshwari Nagrik Sahakarti bank"
    },
    {
      "NUM_FINANCIER_CD": 851,
      "TXT_FINANCIER_NAME": "Arihant Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 852,
      "TXT_FINANCIER_NAME": "Astha Mahila Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 853,
      "TXT_FINANCIER_NAME": "Basoda Nagrik Sahakari Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 854,
      "TXT_FINANCIER_NAME": "Betul Nagarik Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 855,
      "TXT_FINANCIER_NAME": "Bhilai Nagarik Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 856,
      "TXT_FINANCIER_NAME": "Bhind Nagarik Sahakari Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 857,
      "TXT_FINANCIER_NAME": "Bhoj Nagarik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 858,
      "TXT_FINANCIER_NAME": "Bhopal Nagarik SahakariBank Limited."
    },
    {
      "NUM_FINANCIER_CD": 859,
      "TXT_FINANCIER_NAME": "Bilasa Mahila Nagarik Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 860,
      "TXT_FINANCIER_NAME": "Bilaspur Nagarik Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 861,
      "TXT_FINANCIER_NAME": "Citizen Coop. Bank Ltd.Burhanpur."
    },
    {
      "NUM_FINANCIER_CD": 862,
      "TXT_FINANCIER_NAME": "Deendayal Nagarik Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 863,
      "TXT_FINANCIER_NAME": "Dr.Ambedkar Nagarik Sah.Bank.Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 864,
      "TXT_FINANCIER_NAME": "Garha Co-Operative Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 865,
      "TXT_FINANCIER_NAME": "Gujarat Commercial Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 866,
      "TXT_FINANCIER_NAME": "Guna Nagarik sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 867,
      "TXT_FINANCIER_NAME": "Hindu Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 868,
      "TXT_FINANCIER_NAME": "Indira Mahila Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 869,
      "TXT_FINANCIER_NAME": "Indira Priyadarshini Mahila Nag Sah BANK"
    },
    {
      "NUM_FINANCIER_CD": 870,
      "TXT_FINANCIER_NAME": "Indore Cloth Market Coop. Bank Ltd.."
    },
    {
      "NUM_FINANCIER_CD": 871,
      "TXT_FINANCIER_NAME": "Indore Paraspar Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 872,
      "TXT_FINANCIER_NAME": "Indore Swayamsidha Mahila Co-Operative Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 873,
      "TXT_FINANCIER_NAME": "Jabalpur Mahila Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 874,
      "TXT_FINANCIER_NAME": "Jharneswar Nagarik Sah Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 875,
      "TXT_FINANCIER_NAME": "Khargon Nagrik Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 876,
      "TXT_FINANCIER_NAME": "Krishna Mercantile Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 877,
      "TXT_FINANCIER_NAME": "Lakshmibai Mahila Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 878,
      "TXT_FINANCIER_NAME": "Laxmi Mahila Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 879,
      "TXT_FINANCIER_NAME": "Maa Sharda Mahila Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 880,
      "TXT_FINANCIER_NAME": "Mahanagar Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 881,
      "TXT_FINANCIER_NAME": "Mahila Nagrik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 882,
      "TXT_FINANCIER_NAME": "Mahila Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 883,
      "TXT_FINANCIER_NAME": "Mahismati Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 884,
      "TXT_FINANCIER_NAME": "Nagarik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 885,
      "TXT_FINANCIER_NAME": "Paraspar Sahayak Coop Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 886,
      "TXT_FINANCIER_NAME": "Praghati Mahila Nagarik sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 887,
      "TXT_FINANCIER_NAME": "Raigarh Nagarik Sahakari Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 888,
      "TXT_FINANCIER_NAME": "Raj Rajeshwari Mahila Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 889,
      "TXT_FINANCIER_NAME": "Renuka Nagarik Sahakari Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 890,
      "TXT_FINANCIER_NAME": "Sadbhav Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 891,
      "TXT_FINANCIER_NAME": "SADGURU NAGRIK SAHAKARI BANK MARYADIT."
    },
    {
      "NUM_FINANCIER_CD": 892,
      "TXT_FINANCIER_NAME": "SAMATA SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 893,
      "TXT_FINANCIER_NAME": "Sanawad Nagrik Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 894,
      "TXT_FINANCIER_NAME": "Sardar Singh Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 895,
      "TXT_FINANCIER_NAME": "Sehore Nagrik Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 896,
      "TXT_FINANCIER_NAME": "Sher Nagarik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 897,
      "TXT_FINANCIER_NAME": "Shajapur Nagrik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 898,
      "TXT_FINANCIER_NAME": "Shramik Nagrik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 899,
      "TXT_FINANCIER_NAME": "Shri Balaji Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 900,
      "TXT_FINANCIER_NAME": "Shri Satya Sai Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 901,
      "TXT_FINANCIER_NAME": "Shri Vaibhav Lakshmi Mahila Nagarik"
    },
    {
      "NUM_FINANCIER_CD": 902,
      "TXT_FINANCIER_NAME": "Shubhalaxmi Mahila Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 903,
      "TXT_FINANCIER_NAME": "Smriti Nagrik Sahakari Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 904,
      "TXT_FINANCIER_NAME": "Suvidha Mahila nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 905,
      "TXT_FINANCIER_NAME": "The Nagarik Shakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 906,
      "TXT_FINANCIER_NAME": "The Raipur Urban Mercantile Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 907,
      "TXT_FINANCIER_NAME": "Transport Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 908,
      "TXT_FINANCIER_NAME": "Ujjain Audhyogik Vikas Nagrik Sahkari Bank"
    },
    {
      "NUM_FINANCIER_CD": 909,
      "TXT_FINANCIER_NAME": "Ujjain Nagarik Sahakari Bank Maryadit,"
    },
    {
      "NUM_FINANCIER_CD": 910,
      "TXT_FINANCIER_NAME": "Ujjain Paraspar Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 911,
      "TXT_FINANCIER_NAME": "Vikramaditya Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 912,
      "TXT_FINANCIER_NAME": "Vivekanada Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 913,
      "TXT_FINANCIER_NAME": "Vyaparik Audhyogik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 914,
      "TXT_FINANCIER_NAME": "Vyavasayik Evam Audhyogik Sah.Bk.Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 915,
      "TXT_FINANCIER_NAME": "VYAVSAIK SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 916,
      "TXT_FINANCIER_NAME": "Balasore Coop.Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 917,
      "TXT_FINANCIER_NAME": "Baripada Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 918,
      "TXT_FINANCIER_NAME": "Berhampur Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 919,
      "TXT_FINANCIER_NAME": "Chatrapur Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 920,
      "TXT_FINANCIER_NAME": "Cooperative Urban Bank Ltd,"
    },
    {
      "NUM_FINANCIER_CD": 921,
      "TXT_FINANCIER_NAME": "Jeypore coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 922,
      "TXT_FINANCIER_NAME": "Kendrapara Urban Coop Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1035,
      "TXT_FINANCIER_NAME": "Sri Kamalambika Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1036,
      "TXT_FINANCIER_NAME": "Sri Laxminarayana Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1037,
      "TXT_FINANCIER_NAME": "Srirangam Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1038,
      "TXT_FINANCIER_NAME": "Srivilliputtur Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1039,
      "TXT_FINANCIER_NAME": "Subramanianagar Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1040,
      "TXT_FINANCIER_NAME": "Tambaram Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1041,
      "TXT_FINANCIER_NAME": "Tamilnadu Circle Postal Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1042,
      "TXT_FINANCIER_NAME": "Thanjavur Public Servants Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1043,
      "TXT_FINANCIER_NAME": "Thiruvaikuntam Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1044,
      "TXT_FINANCIER_NAME": "Thiruvalluvar Town Co-Op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1045,
      "TXT_FINANCIER_NAME": "Thyagarayanagar Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1046,
      "TXT_FINANCIER_NAME": "Tindivanam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1047,
      "TXT_FINANCIER_NAME": "Tinnevelly Junction Co-Op Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1048,
      "TXT_FINANCIER_NAME": "Tiruchendoor Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1049,
      "TXT_FINANCIER_NAME": "Tiruchengode Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1050,
      "TXT_FINANCIER_NAME": "Tiruchirapalli City Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1051,
      "TXT_FINANCIER_NAME": "Tiruchirapalli Hirudayapuram Co-Op Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1052,
      "TXT_FINANCIER_NAME": "Tirukoilur Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1053,
      "TXT_FINANCIER_NAME": "Tirumangalam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1054,
      "TXT_FINANCIER_NAME": "Tirupattur Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1055,
      "TXT_FINANCIER_NAME": "Tiruppur Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1056,
      "TXT_FINANCIER_NAME": "Tiruturaipundi Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1057,
      "TXT_FINANCIER_NAME": "Tiruvallur Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1058,
      "TXT_FINANCIER_NAME": "Tiruvannamalai Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1059,
      "TXT_FINANCIER_NAME": "Tiruvathipuram Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1060,
      "TXT_FINANCIER_NAME": "Tuticorin Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1061,
      "TXT_FINANCIER_NAME": "Tuticorin Melur Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1062,
      "TXT_FINANCIER_NAME": "Udamalpet Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1063,
      "TXT_FINANCIER_NAME": "Udhagmandlam Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1064,
      "TXT_FINANCIER_NAME": "Usilampatti Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1065,
      "TXT_FINANCIER_NAME": "Uthamapalayam Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1066,
      "TXT_FINANCIER_NAME": "Valparai Co-Operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1067,
      "TXT_FINANCIER_NAME": "Vaniyambadi Town Coop bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1068,
      "TXT_FINANCIER_NAME": "Varaganeri Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1069,
      "TXT_FINANCIER_NAME": "Vellala Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1070,
      "TXT_FINANCIER_NAME": "Vellore Coop Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1071,
      "TXT_FINANCIER_NAME": "Velur Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1072,
      "TXT_FINANCIER_NAME": "Villupuram Coop Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1073,
      "TXT_FINANCIER_NAME": "Viravanallur Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1074,
      "TXT_FINANCIER_NAME": "Virudhunagar Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1075,
      "TXT_FINANCIER_NAME": "Vriddhachalam Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1076,
      "TXT_FINANCIER_NAME": "Bhagyodaya Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1077,
      "TXT_FINANCIER_NAME": "Citizen Co op Bank Limited New Delhi."
    },
    {
      "NUM_FINANCIER_CD": 1078,
      "TXT_FINANCIER_NAME": "Delhi Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1079,
      "TXT_FINANCIER_NAME": "Indraprasth Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1080,
      "TXT_FINANCIER_NAME": "Jain Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1081,
      "TXT_FINANCIER_NAME": "Innovative Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1082,
      "TXT_FINANCIER_NAME": "JAMIA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1083,
      "TXT_FINANCIER_NAME": "Janata Co op Urban bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1084,
      "TXT_FINANCIER_NAME": "Kangra Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1085,
      "TXT_FINANCIER_NAME": "Keshav Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1086,
      "TXT_FINANCIER_NAME": "Khattri Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1087,
      "TXT_FINANCIER_NAME": "Ramgarhia Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1088,
      "TXT_FINANCIER_NAME": "Vaish Coop Adarsh Bank LImited."
    },
    {
      "NUM_FINANCIER_CD": 1089,
      "TXT_FINANCIER_NAME": "Vaish Coop Commercial Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1090,
      "TXT_FINANCIER_NAME": "Agartala Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1091,
      "TXT_FINANCIER_NAME": "VAISH CO OP NEW BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 1092,
      "TXT_FINANCIER_NAME": "Cooperative City Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1093,
      "TXT_FINANCIER_NAME": "Gauhati Co-op. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1094,
      "TXT_FINANCIER_NAME": "Golaghat Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1095,
      "TXT_FINANCIER_NAME": "Imphal Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1096,
      "TXT_FINANCIER_NAME": "Industrial Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1097,
      "TXT_FINANCIER_NAME": "Jowai Co-Operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1098,
      "TXT_FINANCIER_NAME": "Konoklota Mahila Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1099,
      "TXT_FINANCIER_NAME": "Mahabhairab Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1100,
      "TXT_FINANCIER_NAME": "Manipur Women's Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1101,
      "TXT_FINANCIER_NAME": "Mangaldai Nagar Samabai Bank"
    },
    {
      "NUM_FINANCIER_CD": 1102,
      "TXT_FINANCIER_NAME": "Mizoram Urban Co-Op Development Bank"
    },
    {
      "NUM_FINANCIER_CD": 1103,
      "TXT_FINANCIER_NAME": "Moirang Primary Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1104,
      "TXT_FINANCIER_NAME": "Nagarik Samabay Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1105,
      "TXT_FINANCIER_NAME": "Nalbari Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1106,
      "TXT_FINANCIER_NAME": "Shillong Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1107,
      "TXT_FINANCIER_NAME": "Tura Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1108,
      "TXT_FINANCIER_NAME": "Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1109,
      "TXT_FINANCIER_NAME": "A.P. Mahajans co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1110,
      "TXT_FINANCIER_NAME": "Agroha Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1111,
      "TXT_FINANCIER_NAME": "Amalapuram Co-Operative Town Bank"
    },
    {
      "NUM_FINANCIER_CD": 1112,
      "TXT_FINANCIER_NAME": "Anantpur co-op Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1113,
      "TXT_FINANCIER_NAME": "ANDHRA BANK EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 1114,
      "TXT_FINANCIER_NAME": "Apna Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1115,
      "TXT_FINANCIER_NAME": "Aryapuram Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1116,
      "TXT_FINANCIER_NAME": "Balaji Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1117,
      "TXT_FINANCIER_NAME": "Bapatla co-operative urban bank limited."
    },
    {
      "NUM_FINANCIER_CD": 1118,
      "TXT_FINANCIER_NAME": "Bhadradri Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1119,
      "TXT_FINANCIER_NAME": "Bharat Mercantile Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1120,
      "TXT_FINANCIER_NAME": "Bhavana Rishi Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1121,
      "TXT_FINANCIER_NAME": "BHIMAVARAM CO-OP URBAN BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 1122,
      "TXT_FINANCIER_NAME": "Bobbili co-op urban bank limited."
    },
    {
      "NUM_FINANCIER_CD": 1123,
      "TXT_FINANCIER_NAME": "Catholic Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1124,
      "TXT_FINANCIER_NAME": "Chittoor Coop.Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1125,
      "TXT_FINANCIER_NAME": "Coop Bank Limited Salur."
    },
    {
      "NUM_FINANCIER_CD": 1126,
      "TXT_FINANCIER_NAME": "Cumbum Coop.Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1127,
      "TXT_FINANCIER_NAME": "Darus Salam Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1128,
      "TXT_FINANCIER_NAME": "Deccan Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1129,
      "TXT_FINANCIER_NAME": "Durga Co-op. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1130,
      "TXT_FINANCIER_NAME": "Eenadu Co-Operative Urban bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1131,
      "TXT_FINANCIER_NAME": "ELURI CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1132,
      "TXT_FINANCIER_NAME": "Eluru Co-op. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1133,
      "TXT_FINANCIER_NAME": "G.P.R. Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1134,
      "TXT_FINANCIER_NAME": "Gandhi Coop Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1135,
      "TXT_FINANCIER_NAME": "Gayatri Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1136,
      "TXT_FINANCIER_NAME": "Guntur Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1137,
      "TXT_FINANCIER_NAME": "Gokul Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1138,
      "TXT_FINANCIER_NAME": "Guntur Women Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1139,
      "TXT_FINANCIER_NAME": "Hindustan Shipyard Staff Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1140,
      "TXT_FINANCIER_NAME": "Innespeta Coop urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1141,
      "TXT_FINANCIER_NAME": "Jagruti Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1142,
      "TXT_FINANCIER_NAME": "Jubilee Hills Mercantile Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 923,
      "TXT_FINANCIER_NAME": "Puri Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 924,
      "TXT_FINANCIER_NAME": "Urban Co op Bank Limited Cuttack."
    },
    {
      "NUM_FINANCIER_CD": 925,
      "TXT_FINANCIER_NAME": "Urban Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 926,
      "TXT_FINANCIER_NAME": "Utkal Coop Banking Soc Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 927,
      "TXT_FINANCIER_NAME": "BAGHAT URBAN COOP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 928,
      "TXT_FINANCIER_NAME": "Chamba Urban Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 929,
      "TXT_FINANCIER_NAME": "Citizen Urban Co opBank Limited"
    },
    {
      "NUM_FINANCIER_CD": 930,
      "TXT_FINANCIER_NAME": "Hindu Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 931,
      "TXT_FINANCIER_NAME": "Hissar Urban Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 932,
      "TXT_FINANCIER_NAME": "Imperial Urban cooprative Bank"
    },
    {
      "NUM_FINANCIER_CD": 933,
      "TXT_FINANCIER_NAME": "Karan Urban Cooperative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 934,
      "TXT_FINANCIER_NAME": "Kurali Urban Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 935,
      "TXT_FINANCIER_NAME": "Kurukshetra Urban Co-op. Bank"
    },
    {
      "NUM_FINANCIER_CD": 936,
      "TXT_FINANCIER_NAME": "Mandi Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 937,
      "TXT_FINANCIER_NAME": "Nakodar Hindu Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 938,
      "TXT_FINANCIER_NAME": "Panchkula Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 939,
      "TXT_FINANCIER_NAME": "Panipat Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 940,
      "TXT_FINANCIER_NAME": "Parwanoo Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 941,
      "TXT_FINANCIER_NAME": "POSTAL & R.M.S.EMPLOYEES COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 942,
      "TXT_FINANCIER_NAME": "Shimla Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 943,
      "TXT_FINANCIER_NAME": "The Sonepat urban Co-op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 944,
      "TXT_FINANCIER_NAME": "Abiramam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 945,
      "TXT_FINANCIER_NAME": "Ambasamudram Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 946,
      "TXT_FINANCIER_NAME": "Ammapet Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 947,
      "TXT_FINANCIER_NAME": "Arantangi Co-Op Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 948,
      "TXT_FINANCIER_NAME": "Arcot Cooperative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 949,
      "TXT_FINANCIER_NAME": "Ariyalur Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 950,
      "TXT_FINANCIER_NAME": "Arkonam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 951,
      "TXT_FINANCIER_NAME": "Arni Co-Operative Bank Ltd., Arni"
    },
    {
      "NUM_FINANCIER_CD": 952,
      "TXT_FINANCIER_NAME": "Attur Town Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 953,
      "TXT_FINANCIER_NAME": "B.Komarapalayam Co op Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 954,
      "TXT_FINANCIER_NAME": "Batlagundu Co op Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 955,
      "TXT_FINANCIER_NAME": "Bharat Heavy Electricals Employees Co op"
    },
    {
      "NUM_FINANCIER_CD": 956,
      "TXT_FINANCIER_NAME": "Bhavanikudal Co op Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 957,
      "TXT_FINANCIER_NAME": "Bodinayakanur Co op Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 958,
      "TXT_FINANCIER_NAME": "Big Kancheepuram Co op Town Bank"
    },
    {
      "NUM_FINANCIER_CD": 959,
      "TXT_FINANCIER_NAME": "Chengalpattu Coop.Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 960,
      "TXT_FINANCIER_NAME": "Chennimalai Cooperative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 961,
      "TXT_FINANCIER_NAME": "Chidambaram Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 962,
      "TXT_FINANCIER_NAME": "Chintadripet Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 963,
      "TXT_FINANCIER_NAME": "Coimbatore City Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 964,
      "TXT_FINANCIER_NAME": "Coonoor Cooperative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 965,
      "TXT_FINANCIER_NAME": "Cordite Factory Coop. Bank Ltd. (SES)."
    },
    {
      "NUM_FINANCIER_CD": 966,
      "TXT_FINANCIER_NAME": "Cuddalore & Villipuram DCCB Employees Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 967,
      "TXT_FINANCIER_NAME": "Dalmiapuram Empl.s'Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 968,
      "TXT_FINANCIER_NAME": "Dharapuram Coop Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 969,
      "TXT_FINANCIER_NAME": "Dharmapuri Co-op. Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 970,
      "TXT_FINANCIER_NAME": "Dindigul Urban Coop Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 971,
      "TXT_FINANCIER_NAME": "Erode Co-op.Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 972,
      "TXT_FINANCIER_NAME": "George Town Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 973,
      "TXT_FINANCIER_NAME": "Gobichettipalyam Co op Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 974,
      "TXT_FINANCIER_NAME": "Gudiyatham Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 975,
      "TXT_FINANCIER_NAME": "Ilayngudi Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 976,
      "TXT_FINANCIER_NAME": "Jolarpet Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 977,
      "TXT_FINANCIER_NAME": "Kamaraj coop Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 978,
      "TXT_FINANCIER_NAME": "Kallidaikurichi Coop Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 979,
      "TXT_FINANCIER_NAME": "Kamuthi Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 980,
      "TXT_FINANCIER_NAME": "Karaikudi Coop. Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 981,
      "TXT_FINANCIER_NAME": "Karuntattankudi Dravidian Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 982,
      "TXT_FINANCIER_NAME": "Karur Town Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 983,
      "TXT_FINANCIER_NAME": "Kaveripatnam Coop Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 984,
      "TXT_FINANCIER_NAME": "Kodaikanal Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 985,
      "TXT_FINANCIER_NAME": "Kotagiri Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 986,
      "TXT_FINANCIER_NAME": "Kovilpatti Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 987,
      "TXT_FINANCIER_NAME": "Krishnagiri Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 988,
      "TXT_FINANCIER_NAME": "Kulitalai Coop Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 989,
      "TXT_FINANCIER_NAME": "Kumbakonam Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 990,
      "TXT_FINANCIER_NAME": "L.I.C.Employees' Coop Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 991,
      "TXT_FINANCIER_NAME": "Lalgudi Coop Urban Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 992,
      "TXT_FINANCIER_NAME": "Little Conjeevaram Coop Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 993,
      "TXT_FINANCIER_NAME": "Madras Port Trust Employees Co-Op Bank"
    },
    {
      "NUM_FINANCIER_CD": 994,
      "TXT_FINANCIER_NAME": "Madura Sourashtra Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 995,
      "TXT_FINANCIER_NAME": "Madurantakam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 996,
      "TXT_FINANCIER_NAME": "Manapparai Town Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 997,
      "TXT_FINANCIER_NAME": "Mannargudi Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 998,
      "TXT_FINANCIER_NAME": "Mayuram Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 999,
      "TXT_FINANCIER_NAME": "Melur Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1000,
      "TXT_FINANCIER_NAME": "Mettupalayam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1001,
      "TXT_FINANCIER_NAME": "Mukkuperi Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1002,
      "TXT_FINANCIER_NAME": "Musiri Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1003,
      "TXT_FINANCIER_NAME": "Namakkal Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1004,
      "TXT_FINANCIER_NAME": "Nazareth Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1005,
      "TXT_FINANCIER_NAME": "Nellai Nagar Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1006,
      "TXT_FINANCIER_NAME": "Nicholson Coop. Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1007,
      "TXT_FINANCIER_NAME": "Omalur Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1008,
      "TXT_FINANCIER_NAME": "Palamcottah Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1009,
      "TXT_FINANCIER_NAME": "Palani Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1010,
      "TXT_FINANCIER_NAME": "Pallikonda Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1011,
      "TXT_FINANCIER_NAME": "Papanasam Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1012,
      "TXT_FINANCIER_NAME": "Paramakudi Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1013,
      "TXT_FINANCIER_NAME": "Pattukottai Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1014,
      "TXT_FINANCIER_NAME": "Peoples Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1015,
      "TXT_FINANCIER_NAME": "Periyakulam Coop.Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1016,
      "TXT_FINANCIER_NAME": "Pollachi Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1017,
      "TXT_FINANCIER_NAME": "Pondicherry Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1018,
      "TXT_FINANCIER_NAME": "Prakasapuram Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1019,
      "TXT_FINANCIER_NAME": "Pudukottai Coop.Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1020,
      "TXT_FINANCIER_NAME": "Purasawalkam Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1021,
      "TXT_FINANCIER_NAME": "Rajapalayam Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1022,
      "TXT_FINANCIER_NAME": "Ramnathpuram Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1023,
      "TXT_FINANCIER_NAME": "Ranipet Town Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1024,
      "TXT_FINANCIER_NAME": "Rasipuram Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1025,
      "TXT_FINANCIER_NAME": "Reserve Bank Employees'Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1026,
      "TXT_FINANCIER_NAME": "Saidapet Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1027,
      "TXT_FINANCIER_NAME": "Salem Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1028,
      "TXT_FINANCIER_NAME": "Sankari Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1029,
      "TXT_FINANCIER_NAME": "Satyamangalam Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1030,
      "TXT_FINANCIER_NAME": "Shevapet Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1031,
      "TXT_FINANCIER_NAME": "Sholavandan Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1032,
      "TXT_FINANCIER_NAME": "Sholinghur Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1033,
      "TXT_FINANCIER_NAME": "Sirkali Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1034,
      "TXT_FINANCIER_NAME": "Sivakasi Co op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1143,
      "TXT_FINANCIER_NAME": "Jyothi Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1144,
      "TXT_FINANCIER_NAME": "KAKATIYA CO OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1145,
      "TXT_FINANCIER_NAME": "Kakinada Co-op. Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1146,
      "TXT_FINANCIER_NAME": "Kanyaka Parameshwari Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1147,
      "TXT_FINANCIER_NAME": "Karimnagar Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1148,
      "TXT_FINANCIER_NAME": "Koilkuntla Co-Operative Town Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1149,
      "TXT_FINANCIER_NAME": "Kovvur Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1150,
      "TXT_FINANCIER_NAME": "Kranti Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1151,
      "TXT_FINANCIER_NAME": "Kuppam Coop.Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1152,
      "TXT_FINANCIER_NAME": "L.I.C.Employees' Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1153,
      "TXT_FINANCIER_NAME": "Madanapalle Co-Op.Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1154,
      "TXT_FINANCIER_NAME": "Maharana Pratap Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1155,
      "TXT_FINANCIER_NAME": "Mahaveer Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1156,
      "TXT_FINANCIER_NAME": "Manasa Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1157,
      "TXT_FINANCIER_NAME": "Navabharat Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1158,
      "TXT_FINANCIER_NAME": "National Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1159,
      "TXT_FINANCIER_NAME": "Neelakanta Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1160,
      "TXT_FINANCIER_NAME": "Netha Urban Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1161,
      "TXT_FINANCIER_NAME": "Ongole Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1162,
      "TXT_FINANCIER_NAME": "Padmaavati Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1163,
      "TXT_FINANCIER_NAME": "Pochampally Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1164,
      "TXT_FINANCIER_NAME": "Premier Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1165,
      "TXT_FINANCIER_NAME": "Priyadarshini Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 416,
      "TXT_FINANCIER_NAME": "THE NAVNIRMAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 417,
      "TXT_FINANCIER_NAME": "Navsarjan Industrial Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 418,
      "TXT_FINANCIER_NAME": "Nawanagar Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 419,
      "TXT_FINANCIER_NAME": "Nidhi Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 420,
      "TXT_FINANCIER_NAME": "Nutan Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 421,
      "TXT_FINANCIER_NAME": "Ode Urban Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 422,
      "TXT_FINANCIER_NAME": "Padra Nagar Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 423,
      "TXT_FINANCIER_NAME": "Palanpur People's Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 424,
      "TXT_FINANCIER_NAME": "Panchsheel Mercantile Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1173,
      "TXT_FINANCIER_NAME": "Seven Hills Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1174,
      "TXT_FINANCIER_NAME": "Shree Mahayogi Lakshmamma Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1175,
      "TXT_FINANCIER_NAME": "Sircilla Co op Urban bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1176,
      "TXT_FINANCIER_NAME": "Sree Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1177,
      "TXT_FINANCIER_NAME": "Sreenivasa Padmavatthi Co-Operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1178,
      "TXT_FINANCIER_NAME": "Sri Bharathi Co-op. Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1179,
      "TXT_FINANCIER_NAME": "Sri Gayatri Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1180,
      "TXT_FINANCIER_NAME": "Sri Kakulam Co-op.Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1181,
      "TXT_FINANCIER_NAME": "Sri Kalahasti Co op Town Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1182,
      "TXT_FINANCIER_NAME": "Sri Sharadamba Mahila Cooperative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1183,
      "TXT_FINANCIER_NAME": "Stambhadri Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1184,
      "TXT_FINANCIER_NAME": "Sudha Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1185,
      "TXT_FINANCIER_NAME": "Tadpatri Coop Town Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1186,
      "TXT_FINANCIER_NAME": "Tandur Mahila Co--operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1187,
      "TXT_FINANCIER_NAME": "Tenali Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1188,
      "TXT_FINANCIER_NAME": "The Sanghamitra Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1189,
      "TXT_FINANCIER_NAME": "The A.P. Vardhaman (mahila) Co-Op Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1190,
      "TXT_FINANCIER_NAME": "The A.P.Janata Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1191,
      "TXT_FINANCIER_NAME": "The A.P.RajaRajeswari Mahila Coop Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1192,
      "TXT_FINANCIER_NAME": "The Adarsh Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1193,
      "TXT_FINANCIER_NAME": "The Agrasen Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1194,
      "TXT_FINANCIER_NAME": "The Annapurna Mahila Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1195,
      "TXT_FINANCIER_NAME": "The Chaitanya Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1196,
      "TXT_FINANCIER_NAME": "The Dharmavaram Co-op. Town Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 1197,
      "TXT_FINANCIER_NAME": "The Gooty Coop Town Bank Ltd.Gooty"
    },
    {
      "NUM_FINANCIER_CD": 1198,
      "TXT_FINANCIER_NAME": "The Gudiwada Co-op. Urban bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1199,
      "TXT_FINANCIER_NAME": "The Jampeta Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1200,
      "TXT_FINANCIER_NAME": "The Kalinga Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1201,
      "TXT_FINANCIER_NAME": "The Kanaka Mahalakshmi Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1202,
      "TXT_FINANCIER_NAME": "The Mahanagar Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1203,
      "TXT_FINANCIER_NAME": "The Maharaja Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1204,
      "TXT_FINANCIER_NAME": "The Mandapeta Co-Operative Town Bank"
    },
    {
      "NUM_FINANCIER_CD": 1205,
      "TXT_FINANCIER_NAME": "The Masulipatanam Co-Op Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1206,
      "TXT_FINANCIER_NAME": "The Milli Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1207,
      "TXT_FINANCIER_NAME": "The Model Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1208,
      "TXT_FINANCIER_NAME": "The Navanirman Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1209,
      "TXT_FINANCIER_NAME": "The Neela Krishna co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1210,
      "TXT_FINANCIER_NAME": "The Nellore Coop. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1211,
      "TXT_FINANCIER_NAME": "The Palakol Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1212,
      "TXT_FINANCIER_NAME": "The Palamoor Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1213,
      "TXT_FINANCIER_NAME": "The Proddatur Co-Op Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1214,
      "TXT_FINANCIER_NAME": "The Repalle Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1215,
      "TXT_FINANCIER_NAME": "The Secunderabad mercantile Coop Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1216,
      "TXT_FINANCIER_NAME": "The Sind Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1217,
      "TXT_FINANCIER_NAME": "The Swarna Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1218,
      "TXT_FINANCIER_NAME": "The Swasakthi Mercantile Coop Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1219,
      "TXT_FINANCIER_NAME": "The Uravakonda Coop Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1220,
      "TXT_FINANCIER_NAME": "The Vani Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1221,
      "TXT_FINANCIER_NAME": "The Veera Shaiva Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1222,
      "TXT_FINANCIER_NAME": "Tirumala Co-op. Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1223,
      "TXT_FINANCIER_NAME": "Tirupati Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1224,
      "TXT_FINANCIER_NAME": "Twin Cities Co-Operative Urban Bank Ltd.."
    },
    {
      "NUM_FINANCIER_CD": 1225,
      "TXT_FINANCIER_NAME": "Universal Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1226,
      "TXT_FINANCIER_NAME": "Vasundhara Mahila Co-op. Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1227,
      "TXT_FINANCIER_NAME": "Visakhapatnam Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1228,
      "TXT_FINANCIER_NAME": "Vizianagaram Coop Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1229,
      "TXT_FINANCIER_NAME": "Warangal Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1230,
      "TXT_FINANCIER_NAME": "Yadagiri Lakshmi Narasimha Swamy Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1231,
      "TXT_FINANCIER_NAME": "Yemmiganur Co-op.Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1232,
      "TXT_FINANCIER_NAME": "Ajmer Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1233,
      "TXT_FINANCIER_NAME": "Alwar Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1234,
      "TXT_FINANCIER_NAME": "Balotra Urban Coop.Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1235,
      "TXT_FINANCIER_NAME": "Baran Nagarik Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1236,
      "TXT_FINANCIER_NAME": "Beawar Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1237,
      "TXT_FINANCIER_NAME": "Bharatpur Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1238,
      "TXT_FINANCIER_NAME": "Bhilwara Mahila Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1239,
      "TXT_FINANCIER_NAME": "Bhilwara Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1240,
      "TXT_FINANCIER_NAME": "Bundi Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1241,
      "TXT_FINANCIER_NAME": "Chittorgarh Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1242,
      "TXT_FINANCIER_NAME": "Churu Zilla Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1243,
      "TXT_FINANCIER_NAME": "Dausa Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1244,
      "TXT_FINANCIER_NAME": "Dholpur Urban Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1245,
      "TXT_FINANCIER_NAME": "Integral Urban co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 624,
      "TXT_FINANCIER_NAME": "Janatha Seva Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 625,
      "TXT_FINANCIER_NAME": "Jijamatha Mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 626,
      "TXT_FINANCIER_NAME": "Kalpavruksha Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 627,
      "TXT_FINANCIER_NAME": "Kanaka Pattana Sahakara Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 628,
      "TXT_FINANCIER_NAME": "Karnataka State Industrial Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 629,
      "TXT_FINANCIER_NAME": "Katkol Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 630,
      "TXT_FINANCIER_NAME": "Kaujalgi Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 631,
      "TXT_FINANCIER_NAME": "Kempegowda Pattana Souharda Sahakari"
    },
    {
      "NUM_FINANCIER_CD": 632,
      "TXT_FINANCIER_NAME": "Kittur Channamma Mahila Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 633,
      "TXT_FINANCIER_NAME": "Kittur Rani Chnannamma Mahila Sahakari"
    },
    {
      "NUM_FINANCIER_CD": 634,
      "TXT_FINANCIER_NAME": "Kodagu Mahila Pattana Sahakara Bank"
    },
    {
      "NUM_FINANCIER_CD": 635,
      "TXT_FINANCIER_NAME": "Koteshwara Sahakari Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 636,
      "TXT_FINANCIER_NAME": "Krishna Pattana Sahakar Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 637,
      "TXT_FINANCIER_NAME": "Kushtagi Pattana Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 638,
      "TXT_FINANCIER_NAME": "Laxmeshwar Urban Co-operative Credit"
    },
    {
      "NUM_FINANCIER_CD": 639,
      "TXT_FINANCIER_NAME": "Laxmi Sahakara Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 640,
      "TXT_FINANCIER_NAME": "Laxmi Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 641,
      "TXT_FINANCIER_NAME": "Lokapavani Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 642,
      "TXT_FINANCIER_NAME": "Madikeri Town Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 643,
      "TXT_FINANCIER_NAME": "Mahalakshmi Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 644,
      "TXT_FINANCIER_NAME": "Mahalaxmi Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 645,
      "TXT_FINANCIER_NAME": "Mahalingpur Urban Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 646,
      "TXT_FINANCIER_NAME": "Mahila Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 647,
      "TXT_FINANCIER_NAME": "Mahila Shakthi Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 648,
      "TXT_FINANCIER_NAME": "Mangalore Co-operative Town Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 649,
      "TXT_FINANCIER_NAME": "Manipal Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 650,
      "TXT_FINANCIER_NAME": "Manjra Mahila Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 651,
      "TXT_FINANCIER_NAME": "Manvi Pattana Souharda Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 652,
      "TXT_FINANCIER_NAME": "Maratha Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 653,
      "TXT_FINANCIER_NAME": "Maratha Co-operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 654,
      "TXT_FINANCIER_NAME": "Merchants' Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 655,
      "TXT_FINANCIER_NAME": "Merchants' Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 656,
      "TXT_FINANCIER_NAME": "Millath Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 657,
      "TXT_FINANCIER_NAME": "Mudgal Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 658,
      "TXT_FINANCIER_NAME": "Mudhol Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 659,
      "TXT_FINANCIER_NAME": "Muslim Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 660,
      "TXT_FINANCIER_NAME": "Mysore Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 661,
      "TXT_FINANCIER_NAME": "Mysore Merchant's Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 662,
      "TXT_FINANCIER_NAME": "Mysore Silk Cloth Merchant's Co-operative BANK"
    },
    {
      "NUM_FINANCIER_CD": 663,
      "TXT_FINANCIER_NAME": "Mysore Zilla Mahila Sahakara Bank"
    },
    {
      "NUM_FINANCIER_CD": 664,
      "TXT_FINANCIER_NAME": "Narayan Guru Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 665,
      "TXT_FINANCIER_NAME": "National Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 666,
      "TXT_FINANCIER_NAME": "Navanagara Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 667,
      "TXT_FINANCIER_NAME": "Nehru Nagar Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 668,
      "TXT_FINANCIER_NAME": "Nyayamitra Sahakari Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 669,
      "TXT_FINANCIER_NAME": "Onake Obavva Mahila Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 670,
      "TXT_FINANCIER_NAME": "Pragathi Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 671,
      "TXT_FINANCIER_NAME": "Pragathi Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 672,
      "TXT_FINANCIER_NAME": "Priyadarshini Mahila Urban Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 673,
      "TXT_FINANCIER_NAME": "Rabkavi Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 674,
      "TXT_FINANCIER_NAME": "Raddi Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 675,
      "TXT_FINANCIER_NAME": "Raichur City Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 676,
      "TXT_FINANCIER_NAME": "Raichur Zilla Mahila Pattana Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 677,
      "TXT_FINANCIER_NAME": "Ramanagaram Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 678,
      "TXT_FINANCIER_NAME": "Reserve Bank Employees' Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 679,
      "TXT_FINANCIER_NAME": "Sadalga Urban Souharda Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 680,
      "TXT_FINANCIER_NAME": "Sahyadri Mahila Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 681,
      "TXT_FINANCIER_NAME": "Sandur Pattana souharda Sahakari Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 682,
      "TXT_FINANCIER_NAME": "Shimsha Sahakara Bank Niyamitha,"
    },
    {
      "NUM_FINANCIER_CD": 683,
      "TXT_FINANCIER_NAME": "Shimoga Arecanut Mandi Merchants Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 684,
      "TXT_FINANCIER_NAME": "Shiva Sahakari Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 685,
      "TXT_FINANCIER_NAME": "Shree Basaveshwar Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 686,
      "TXT_FINANCIER_NAME": "Shree Gajanan Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 687,
      "TXT_FINANCIER_NAME": "Shree Mahabaleshwar Co operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 688,
      "TXT_FINANCIER_NAME": "Shree Mahalaxmi Urban Co-operative Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 689,
      "TXT_FINANCIER_NAME": "Shree Ram Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 690,
      "TXT_FINANCIER_NAME": "Shree Tukaram Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 691,
      "TXT_FINANCIER_NAME": "Shri Basaveshwar Pattana Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 692,
      "TXT_FINANCIER_NAME": "Shri Basaveshwar Sahakari Bank Niyamit,"
    },
    {
      "NUM_FINANCIER_CD": 693,
      "TXT_FINANCIER_NAME": "Shri Gurudev Brahmanand Pattana Sahakara Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 694,
      "TXT_FINANCIER_NAME": "Shri Kadasiddeshwar Pattan Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 695,
      "TXT_FINANCIER_NAME": "Shri Laxminarayana Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 696,
      "TXT_FINANCIER_NAME": "Shri Mahalaxmi Pattan Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 697,
      "TXT_FINANCIER_NAME": "Shri Mahant Shivayogi Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 698,
      "TXT_FINANCIER_NAME": "Shri Shadakshari Shivayogi Siddharameshwar Sahakara Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 699,
      "TXT_FINANCIER_NAME": "Shri Shantappanna Mirji Urban Cooperative Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 700,
      "TXT_FINANCIER_NAME": "Shri Sharavana Veereshwar Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 701,
      "TXT_FINANCIER_NAME": "Shri Shiddheshwar Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 702,
      "TXT_FINANCIER_NAME": "Shri Shivayogi Murughendra Swami Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 703,
      "TXT_FINANCIER_NAME": "Shri Siddeshwar Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 704,
      "TXT_FINANCIER_NAME": "Shri Veer Pulikeshi Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 705,
      "TXT_FINANCIER_NAME": "Shri Vijay Mahantesh Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 706,
      "TXT_FINANCIER_NAME": "Shushruti Souharda Sahakara Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 707,
      "TXT_FINANCIER_NAME": "Siddaganga Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 708,
      "TXT_FINANCIER_NAME": "Sindhanur Urban Souharda Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 709,
      "TXT_FINANCIER_NAME": "Sir M Vishweshwaraiah Sahakar Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 710,
      "TXT_FINANCIER_NAME": "Sir M.Visvesvaraya Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 711,
      "TXT_FINANCIER_NAME": "Soubhagya Mahila Souharda Sahakar Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 712,
      "TXT_FINANCIER_NAME": "Sree Anjaneya Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 713,
      "TXT_FINANCIER_NAME": "Sree Banashankari Mahila Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 714,
      "TXT_FINANCIER_NAME": "Sree Bhyraveshwara Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 715,
      "TXT_FINANCIER_NAME": "Sree Charan Souharda Co operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 716,
      "TXT_FINANCIER_NAME": "Sree Harihareshwara Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 717,
      "TXT_FINANCIER_NAME": "Sree Thyagaraja Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 718,
      "TXT_FINANCIER_NAME": "Sreenidhi Souharda Sahakari Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 719,
      "TXT_FINANCIER_NAME": "Sri Amba Bhavani Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 720,
      "TXT_FINANCIER_NAME": "Sri Basaveshwar Sahakar Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 721,
      "TXT_FINANCIER_NAME": "Sri Basaveshwara Pattana Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 722,
      "TXT_FINANCIER_NAME": "Sri Basaveswar Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 723,
      "TXT_FINANCIER_NAME": "Sri Bhagavathi Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 724,
      "TXT_FINANCIER_NAME": "Sri Channabasavaswamy Souharda Pattana Sahakari Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 725,
      "TXT_FINANCIER_NAME": "Sri Durgadevi Mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 726,
      "TXT_FINANCIER_NAME": "Sri Ganapathi Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 727,
      "TXT_FINANCIER_NAME": "Sri Ganesh Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 728,
      "TXT_FINANCIER_NAME": "Sri Gavisiddeshwar Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 729,
      "TXT_FINANCIER_NAME": "Sri Gokarnanath Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 730,
      "TXT_FINANCIER_NAME": "Sri Guru Raghavendra Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 731,
      "TXT_FINANCIER_NAME": "Sri Gurusiddheshwar Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 732,
      "TXT_FINANCIER_NAME": "Sri Kalidasa Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 733,
      "TXT_FINANCIER_NAME": "Sri Kalmeshwar Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 734,
      "TXT_FINANCIER_NAME": "Sri Kannikaparameshwari Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 735,
      "TXT_FINANCIER_NAME": "Sri Krishnarajendra Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 736,
      "TXT_FINANCIER_NAME": "Sri Kanyakaparameshwari Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 737,
      "TXT_FINANCIER_NAME": "Sri Lakshmi Mahila Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 738,
      "TXT_FINANCIER_NAME": "Sri Mahatma Basaveshwar Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 739,
      "TXT_FINANCIER_NAME": "Sri Mallikarjuna Pattana Sahakari Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 740,
      "TXT_FINANCIER_NAME": "Sri Parshwanatha Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 741,
      "TXT_FINANCIER_NAME": "Sri Rama Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 742,
      "TXT_FINANCIER_NAME": "Sri Revana Siddeshwar Pattana Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 743,
      "TXT_FINANCIER_NAME": "Sri Sampige Siddeswara Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 744,
      "TXT_FINANCIER_NAME": "Sri Seetharaghava Souharda Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 745,
      "TXT_FINANCIER_NAME": "Sri Sharada Mahila Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 746,
      "TXT_FINANCIER_NAME": "Sri Sharanabasaveshwara Pattana Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 747,
      "TXT_FINANCIER_NAME": "Sri Siddarameshwara Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 748,
      "TXT_FINANCIER_NAME": "Sri Subramanyeswara Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 749,
      "TXT_FINANCIER_NAME": "Sri Sudha Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 750,
      "TXT_FINANCIER_NAME": "Sri Vasavamba Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 751,
      "TXT_FINANCIER_NAME": "Sri Veerabhadreshwar Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 752,
      "TXT_FINANCIER_NAME": "Srimatha Mahila Sahakari Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 753,
      "TXT_FINANCIER_NAME": "Sriramnagar Pattana Sahakari Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 754,
      "TXT_FINANCIER_NAME": "Suvarna Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 755,
      "TXT_FINANCIER_NAME": "Swami Vivekanand Urban Co-Operative Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 756,
      "TXT_FINANCIER_NAME": "Swarna Bharathi Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 757,
      "TXT_FINANCIER_NAME": "Tavaragera Pattana Souharda Sahakar Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 758,
      "TXT_FINANCIER_NAME": "Teachers' Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 759,
      "TXT_FINANCIER_NAME": "Textile Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 760,
      "TXT_FINANCIER_NAME": "The Abhinandan Sahakari Bank Niyamit,"
    },
    {
      "NUM_FINANCIER_CD": 761,
      "TXT_FINANCIER_NAME": "The Accountant General's Office Employees Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 762,
      "TXT_FINANCIER_NAME": "The Akki-Alur Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 763,
      "TXT_FINANCIER_NAME": "The Almel Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 764,
      "TXT_FINANCIER_NAME": "The Alnavar Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 765,
      "TXT_FINANCIER_NAME": "The Bailhongal Merchants Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 766,
      "TXT_FINANCIER_NAME": "The Bangalore City Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 767,
      "TXT_FINANCIER_NAME": "The Bangalore Souharda Central Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 768,
      "TXT_FINANCIER_NAME": "The Bedkihal Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 769,
      "TXT_FINANCIER_NAME": "The Belgaum Catholic Co-operative Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 770,
      "TXT_FINANCIER_NAME": "The Belgaum District Revenue Employee's Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 771,
      "TXT_FINANCIER_NAME": "The Belgaum Industrial Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 772,
      "TXT_FINANCIER_NAME": "The Belgaum Pioneer Urban Co-operative Credit Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 773,
      "TXT_FINANCIER_NAME": "The Bellary Souharda Urban Co-operative Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 774,
      "TXT_FINANCIER_NAME": "The Bharath Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 775,
      "TXT_FINANCIER_NAME": "The Bhatkal Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 776,
      "TXT_FINANCIER_NAME": "The Bhavasar Kshatriya Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 777,
      "TXT_FINANCIER_NAME": "The Bijapur Mahalaxmi Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 778,
      "TXT_FINANCIER_NAME": "The Cardamom Merchants Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 779,
      "TXT_FINANCIER_NAME": "The Chadchan Shree Sangameshwar Urban Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 780,
      "TXT_FINANCIER_NAME": "The Challakere Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 781,
      "TXT_FINANCIER_NAME": "The Citizen Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 782,
      "TXT_FINANCIER_NAME": "The Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 783,
      "TXT_FINANCIER_NAME": "The Davangere-Harihar Urban Sahakara Bank Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 784,
      "TXT_FINANCIER_NAME": "The Gandhi Gunj Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 785,
      "TXT_FINANCIER_NAME": "The Gokak Urban Co-operative Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 786,
      "TXT_FINANCIER_NAME": "The Graduates' Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 787,
      "TXT_FINANCIER_NAME": "The Haliyal Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 788,
      "TXT_FINANCIER_NAME": "The Hangal Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 789,
      "TXT_FINANCIER_NAME": "The Hanumantha Nagar Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 790,
      "TXT_FINANCIER_NAME": "The Haveri Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 791,
      "TXT_FINANCIER_NAME": "The Hira Sugar Employees Co-operative Credit Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 792,
      "TXT_FINANCIER_NAME": "The Hirekerur Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 793,
      "TXT_FINANCIER_NAME": "The Hiriyur Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 794,
      "TXT_FINANCIER_NAME": "The Honavar Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 795,
      "TXT_FINANCIER_NAME": "The Hotel Industrialists Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 796,
      "TXT_FINANCIER_NAME": "The Hukeri Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 797,
      "TXT_FINANCIER_NAME": "The Jagruti Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 798,
      "TXT_FINANCIER_NAME": "The Jamkhandi Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 799,
      "TXT_FINANCIER_NAME": "The Janatha Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 800,
      "TXT_FINANCIER_NAME": "The Kalghatgi Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 801,
      "TXT_FINANCIER_NAME": "The Karnataka Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 802,
      "TXT_FINANCIER_NAME": "The Karwar Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 803,
      "TXT_FINANCIER_NAME": "The Khanapur Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 804,
      "TXT_FINANCIER_NAME": "The Kumta Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 805,
      "TXT_FINANCIER_NAME": "The Liberal Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 806,
      "TXT_FINANCIER_NAME": "The Mahaveer Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 807,
      "TXT_FINANCIER_NAME": "The Mallapur Urban Co-operative Credit"
    },
    {
      "NUM_FINANCIER_CD": 808,
      "TXT_FINANCIER_NAME": "The Malleswaram Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 809,
      "TXT_FINANCIER_NAME": "The Mandya City Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 810,
      "TXT_FINANCIER_NAME": "The Mangalore Catholic Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 811,
      "TXT_FINANCIER_NAME": "The Merchants' Liberal Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 812,
      "TXT_FINANCIER_NAME": "The Merchants' Souhardha Sahakara Bank Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 813,
      "TXT_FINANCIER_NAME": "The Mudalgi Co operative Bank Ltd,"
    },
    {
      "NUM_FINANCIER_CD": 814,
      "TXT_FINANCIER_NAME": "The Mulgund Urban Souharda Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 815,
      "TXT_FINANCIER_NAME": "The Navakalyan Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 816,
      "TXT_FINANCIER_NAME": "The Nesargi Urban Co-operative Credit Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 817,
      "TXT_FINANCIER_NAME": "The Nipani Urban Souharda Sahakari Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 818,
      "TXT_FINANCIER_NAME": "The Pachhapur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 2138,
      "TXT_FINANCIER_NAME": "EXCELLENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2139,
      "TXT_FINANCIER_NAME": "KANCHEEPURAM CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2140,
      "TXT_FINANCIER_NAME": "KODUNGALLUR TOWN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2141,
      "TXT_FINANCIER_NAME": "TEHRI GARHWAL ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2142,
      "TXT_FINANCIER_NAME": "ROHTAK CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2143,
      "TXT_FINANCIER_NAME": "JAMUNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2144,
      "TXT_FINANCIER_NAME": "HISSAR DISTT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2145,
      "TXT_FINANCIER_NAME": "ALLAHABAD DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2146,
      "TXT_FINANCIER_NAME": "BHAVNAGAR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2147,
      "TXT_FINANCIER_NAME": "SCOTIABANK"
    },
    {
      "NUM_FINANCIER_CD": 2148,
      "TXT_FINANCIER_NAME": "SHUBHAM PARASPAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 2149,
      "TXT_FINANCIER_NAME": "MANJIRA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 2150,
      "TXT_FINANCIER_NAME": "VEERASHAIVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2151,
      "TXT_FINANCIER_NAME": "SUCO SOUHARDA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 2152,
      "TXT_FINANCIER_NAME": "MEHSANA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2153,
      "TXT_FINANCIER_NAME": "SULTANPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2154,
      "TXT_FINANCIER_NAME": "ADARSH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2155,
      "TXT_FINANCIER_NAME": "THE SUTEX COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2156,
      "TXT_FINANCIER_NAME": "CHANDRAPUR DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2157,
      "TXT_FINANCIER_NAME": "THE KARNAL CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2158,
      "TXT_FINANCIER_NAME": "INDORE PREMIER COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2159,
      "TXT_FINANCIER_NAME": "SUTEX COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2160,
      "TXT_FINANCIER_NAME": "CAPITAL LOCAL AREA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2161,
      "TXT_FINANCIER_NAME": "NATTIKA FIRKA COOPERATIVE RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2162,
      "TXT_FINANCIER_NAME": "VAISHYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2163,
      "TXT_FINANCIER_NAME": "GURDASPUR AMRITSAR KSHETRIYA GRAMIN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 2164,
      "TXT_FINANCIER_NAME": "J P MORGAN CHASE BANK"
    },
    {
      "NUM_FINANCIER_CD": 1544,
      "TXT_FINANCIER_NAME": "Lasalgaon Merchant's Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1545,
      "TXT_FINANCIER_NAME": "Laxmi Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1546,
      "TXT_FINANCIER_NAME": "Laxmi Vishnu Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1547,
      "TXT_FINANCIER_NAME": "Lokmangal Urban Co-Operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1548,
      "TXT_FINANCIER_NAME": "Lokneta Dattaji Patil Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1549,
      "TXT_FINANCIER_NAME": "Lokseva Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1550,
      "TXT_FINANCIER_NAME": "Lonavla Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1551,
      "TXT_FINANCIER_NAME": "M.D.Pawar Peoples Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1552,
      "TXT_FINANCIER_NAME": "Madgaum Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1553,
      "TXT_FINANCIER_NAME": "Madheshwari Urban Development Co_operative BankLtd"
    },
    {
      "NUM_FINANCIER_CD": 1554,
      "TXT_FINANCIER_NAME": "Maha.Mantralaya & Allied Offices Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1555,
      "TXT_FINANCIER_NAME": "Mahabaleshwar Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1556,
      "TXT_FINANCIER_NAME": "Mahad Co-op. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1557,
      "TXT_FINANCIER_NAME": "Mahesh Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1558,
      "TXT_FINANCIER_NAME": "Mahesh Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1559,
      "TXT_FINANCIER_NAME": "MALAD SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1560,
      "TXT_FINANCIER_NAME": "Malegaon Merchants Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1561,
      "TXT_FINANCIER_NAME": "Mandeshi Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1562,
      "TXT_FINANCIER_NAME": "Mangal Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1563,
      "TXT_FINANCIER_NAME": "Mangalwedha Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1564,
      "TXT_FINANCIER_NAME": "Manmad Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1565,
      "TXT_FINANCIER_NAME": "Manmandhir Co-Op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1566,
      "TXT_FINANCIER_NAME": "Manorama Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1567,
      "TXT_FINANCIER_NAME": "Mansingh Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1568,
      "TXT_FINANCIER_NAME": "Maratha Mandir Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1569,
      "TXT_FINANCIER_NAME": "Maratha Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1570,
      "TXT_FINANCIER_NAME": "Matoshri Mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1571,
      "TXT_FINANCIER_NAME": "Mayani Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1572,
      "TXT_FINANCIER_NAME": "Memon Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1573,
      "TXT_FINANCIER_NAME": "Merchant's Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1574,
      "TXT_FINANCIER_NAME": "Miraj Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1575,
      "TXT_FINANCIER_NAME": "Model co-op. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1576,
      "TXT_FINANCIER_NAME": "Modern Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1577,
      "TXT_FINANCIER_NAME": "MOGAVEERA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1578,
      "TXT_FINANCIER_NAME": "Mohol Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1579,
      "TXT_FINANCIER_NAME": "Moti Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1580,
      "TXT_FINANCIER_NAME": "Muktai Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1581,
      "TXT_FINANCIER_NAME": "Mula Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1582,
      "TXT_FINANCIER_NAME": "Mumbai Mahanager Palika Shikshan VibhagSahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1583,
      "TXT_FINANCIER_NAME": "Municipal Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1584,
      "TXT_FINANCIER_NAME": "Murgharajendra Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1585,
      "TXT_FINANCIER_NAME": "Muslim Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1586,
      "TXT_FINANCIER_NAME": "Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1587,
      "TXT_FINANCIER_NAME": "Nandani Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1588,
      "TXT_FINANCIER_NAME": "Nandgaon Urban Co-Operative Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1589,
      "TXT_FINANCIER_NAME": "Nandurbar Merchants' Co-op Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1590,
      "TXT_FINANCIER_NAME": "Nasik District Industrial & Mercantile Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1591,
      "TXT_FINANCIER_NAME": "Nasik Jilha Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1592,
      "TXT_FINANCIER_NAME": "Nasik People's Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1593,
      "TXT_FINANCIER_NAME": "Nasik Raod Deolali Vyapari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1594,
      "TXT_FINANCIER_NAME": "Nasik Zilla Girna Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1595,
      "TXT_FINANCIER_NAME": "Nasik Zilla Mahila Vikas Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1596,
      "TXT_FINANCIER_NAME": "Nasik Zilla Sar & Par Karmachari Sah Bank Niyamit"
    },
    {
      "NUM_FINANCIER_CD": 1597,
      "TXT_FINANCIER_NAME": "National Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1598,
      "TXT_FINANCIER_NAME": "Nav Jeevan Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1599,
      "TXT_FINANCIER_NAME": "Naval Dockyard Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1600,
      "TXT_FINANCIER_NAME": "Navapur Mercantile Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1601,
      "TXT_FINANCIER_NAME": "Navi Mumbai Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1602,
      "TXT_FINANCIER_NAME": "Navjivan Nagari Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 1603,
      "TXT_FINANCIER_NAME": "Needs of Life Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1604,
      "TXT_FINANCIER_NAME": "Nilkanth Urban Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1605,
      "TXT_FINANCIER_NAME": "Niphad Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1606,
      "TXT_FINANCIER_NAME": "Nishigandha Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1607,
      "TXT_FINANCIER_NAME": "Nutan Nagari Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1608,
      "TXT_FINANCIER_NAME": "Ojhar Merchants Coop Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 1609,
      "TXT_FINANCIER_NAME": "Pachora People's Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1610,
      "TXT_FINANCIER_NAME": "Padmashri Dr.Vithalrao Vikhe Patil Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1611,
      "TXT_FINANCIER_NAME": "Palus Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1612,
      "TXT_FINANCIER_NAME": "Pandharpur Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1613,
      "TXT_FINANCIER_NAME": "Pandharpur Marchant's Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1614,
      "TXT_FINANCIER_NAME": "Panvel Co-op. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1615,
      "TXT_FINANCIER_NAME": "Parivartan Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1616,
      "TXT_FINANCIER_NAME": "Parner Taluka Sainik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1617,
      "TXT_FINANCIER_NAME": "Parshwanath Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1618,
      "TXT_FINANCIER_NAME": "Patan Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1619,
      "TXT_FINANCIER_NAME": "Patan Urban Co_operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1620,
      "TXT_FINANCIER_NAME": "Pavana Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1621,
      "TXT_FINANCIER_NAME": "Pen Co-op. Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1622,
      "TXT_FINANCIER_NAME": "Phaltan Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1623,
      "TXT_FINANCIER_NAME": "Pimpalgaon Merchants'Co-op. BAnk Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1624,
      "TXT_FINANCIER_NAME": "Pimpri Chinchwad Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1625,
      "TXT_FINANCIER_NAME": "Poona Marchant's Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1626,
      "TXT_FINANCIER_NAME": "Pragati Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1627,
      "TXT_FINANCIER_NAME": "Pratap Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1628,
      "TXT_FINANCIER_NAME": "Prathamik Shikshak Sahakari bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 1629,
      "TXT_FINANCIER_NAME": "Prathamik Shikshak Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1630,
      "TXT_FINANCIER_NAME": "Premier Automobile Employees'Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1631,
      "TXT_FINANCIER_NAME": "Prerna Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1632,
      "TXT_FINANCIER_NAME": "Pritisangam Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1633,
      "TXT_FINANCIER_NAME": "Priyadarshini Mahila Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1634,
      "TXT_FINANCIER_NAME": "Progressive Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1635,
      "TXT_FINANCIER_NAME": "Priyadarshini Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1636,
      "TXT_FINANCIER_NAME": "Pune Cantonment Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1637,
      "TXT_FINANCIER_NAME": "Pune Municipal Corporation servants Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1638,
      "TXT_FINANCIER_NAME": "PUNE PEOPLE S CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1639,
      "TXT_FINANCIER_NAME": "Pune Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1640,
      "TXT_FINANCIER_NAME": "Pune Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1641,
      "TXT_FINANCIER_NAME": "R.S.Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1642,
      "TXT_FINANCIER_NAME": "Rahimatpur Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1643,
      "TXT_FINANCIER_NAME": "Rahuri People's Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1644,
      "TXT_FINANCIER_NAME": "Raigad Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1645,
      "TXT_FINANCIER_NAME": "Rajapur Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1646,
      "TXT_FINANCIER_NAME": "Rajapur Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1647,
      "TXT_FINANCIER_NAME": "Rajarambapu Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1648,
      "TXT_FINANCIER_NAME": "Rajarshi Shahu Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1649,
      "TXT_FINANCIER_NAME": "Rajeshwar Yuwak Vikas Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1650,
      "TXT_FINANCIER_NAME": "Rajgurunagar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1651,
      "TXT_FINANCIER_NAME": "Rajlakshmi Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1652,
      "TXT_FINANCIER_NAME": "Rajlaxmi Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1653,
      "TXT_FINANCIER_NAME": "Rajmata Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1654,
      "TXT_FINANCIER_NAME": "Rajwade Mandal People's Co-op. Bank"
    },
    {
      "NUM_FINANCIER_CD": 1655,
      "TXT_FINANCIER_NAME": "Rameshwar Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1656,
      "TXT_FINANCIER_NAME": "Ramrajya Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1657,
      "TXT_FINANCIER_NAME": "Ratnagiri Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1658,
      "TXT_FINANCIER_NAME": "Raver People's Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1659,
      "TXT_FINANCIER_NAME": "Ravi Co-Operative bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1660,
      "TXT_FINANCIER_NAME": "Rayat Sevak Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1661,
      "TXT_FINANCIER_NAME": "Rendal Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1662,
      "TXT_FINANCIER_NAME": "Revdanda Co-op. Urban bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1663,
      "TXT_FINANCIER_NAME": "Roha Ashtami Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1664,
      "TXT_FINANCIER_NAME": "Rukhmini Nagari Sahakati Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1665,
      "TXT_FINANCIER_NAME": "Sadguru Gadhininath Urban Co-op. Bank"
    },
    {
      "NUM_FINANCIER_CD": 1666,
      "TXT_FINANCIER_NAME": "Sadhana Co_operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1667,
      "TXT_FINANCIER_NAME": "Sadhana Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1668,
      "TXT_FINANCIER_NAME": "Safe Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1669,
      "TXT_FINANCIER_NAME": "Sahyadri Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1670,
      "TXT_FINANCIER_NAME": "Sahyadri Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1671,
      "TXT_FINANCIER_NAME": "Sainik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1672,
      "TXT_FINANCIER_NAME": "Samarth Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1673,
      "TXT_FINANCIER_NAME": "Sangamner Merchant's Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1674,
      "TXT_FINANCIER_NAME": "Sangli District Primary Teacher's Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1675,
      "TXT_FINANCIER_NAME": "Sangli Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1676,
      "TXT_FINANCIER_NAME": "Sangola Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1677,
      "TXT_FINANCIER_NAME": "Sanmathi Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1678,
      "TXT_FINANCIER_NAME": "Sanmitra Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1679,
      "TXT_FINANCIER_NAME": "Sanmitra Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1680,
      "TXT_FINANCIER_NAME": "Saraswathi Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1681,
      "TXT_FINANCIER_NAME": "Sarvodaya Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1682,
      "TXT_FINANCIER_NAME": "Satana Merchants' Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1683,
      "TXT_FINANCIER_NAME": "Satara District Merchants' Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1684,
      "TXT_FINANCIER_NAME": "Satara Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1685,
      "TXT_FINANCIER_NAME": "Satyashodhak Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1686,
      "TXT_FINANCIER_NAME": "Sawantwadi Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1687,
      "TXT_FINANCIER_NAME": "Seva Vikas Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1688,
      "TXT_FINANCIER_NAME": "Shahada People's Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1689,
      "TXT_FINANCIER_NAME": "Shahupuri Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1690,
      "TXT_FINANCIER_NAME": "Shalini Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1691,
      "TXT_FINANCIER_NAME": "Shankerrao Mohite-Patil Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1692,
      "TXT_FINANCIER_NAME": "Sharad Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1693,
      "TXT_FINANCIER_NAME": "Sharad Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1694,
      "TXT_FINANCIER_NAME": "Shatabdi Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1695,
      "TXT_FINANCIER_NAME": "Shirola Taluka Urban Co-Operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1696,
      "TXT_FINANCIER_NAME": "Shirpur Merchants Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1697,
      "TXT_FINANCIER_NAME": "Shirpur Peoples Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1698,
      "TXT_FINANCIER_NAME": "Shivajirao Bhosale Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1699,
      "TXT_FINANCIER_NAME": "Shivam Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1700,
      "TXT_FINANCIER_NAME": "Shivdaulat Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1701,
      "TXT_FINANCIER_NAME": "Shivneri Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1702,
      "TXT_FINANCIER_NAME": "Shivshakti Urban Co-op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1703,
      "TXT_FINANCIER_NAME": "Sholapur Nagri Audhyogik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1704,
      "TXT_FINANCIER_NAME": "Sholapur Social Urban Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1705,
      "TXT_FINANCIER_NAME": "Shramik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1706,
      "TXT_FINANCIER_NAME": "Shree Arihant Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1707,
      "TXT_FINANCIER_NAME": "Shree Gajanan Lokseva Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1708,
      "TXT_FINANCIER_NAME": "Shree Govardhansingji raghuvashi sahakari bnk ltd"
    },
    {
      "NUM_FINANCIER_CD": 1709,
      "TXT_FINANCIER_NAME": "Shree Laxmi Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1710,
      "TXT_FINANCIER_NAME": "Shree Laxmi Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1711,
      "TXT_FINANCIER_NAME": "Shree Mahavir Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1712,
      "TXT_FINANCIER_NAME": "Shree Mahesh Co-Operative Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1713,
      "TXT_FINANCIER_NAME": "Shree Mahesh Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1714,
      "TXT_FINANCIER_NAME": "Shree Panchaganga Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1715,
      "TXT_FINANCIER_NAME": "Shree Sadguru Jangli Maharaj Sah Bank"
    },
    {
      "NUM_FINANCIER_CD": 1716,
      "TXT_FINANCIER_NAME": "Shree Samarth Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1717,
      "TXT_FINANCIER_NAME": "Shree Siddhi Venkatesh Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1718,
      "TXT_FINANCIER_NAME": "Shree Sidhhi Vinayak Nagari Sah Bank"
    },
    {
      "NUM_FINANCIER_CD": 1719,
      "TXT_FINANCIER_NAME": "Shree Suvarna Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1720,
      "TXT_FINANCIER_NAME": "Shree Vyas Dhanvarsha Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1721,
      "TXT_FINANCIER_NAME": "Shree Warana Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1722,
      "TXT_FINANCIER_NAME": "Shreeji Bhatia Coopbank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1723,
      "TXT_FINANCIER_NAME": "Shreeram Kapus Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1724,
      "TXT_FINANCIER_NAME": "Shri Adinath Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1725,
      "TXT_FINANCIER_NAME": "Shri Anand Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1726,
      "TXT_FINANCIER_NAME": "Shri B.J.Khatal Janata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1727,
      "TXT_FINANCIER_NAME": "Shri Babasaheb Deshmukh Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1728,
      "TXT_FINANCIER_NAME": "Shri Balaji Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1729,
      "TXT_FINANCIER_NAME": "Shri Balasaheb Satbhai Merchants Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1730,
      "TXT_FINANCIER_NAME": "Shri Balbhim Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1731,
      "TXT_FINANCIER_NAME": "Shri Bharat Urban Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1732,
      "TXT_FINANCIER_NAME": "Shri Chhatrapati Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1733,
      "TXT_FINANCIER_NAME": "Shri Ganesh Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1734,
      "TXT_FINANCIER_NAME": "Shri Ganesh Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1839,
      "TXT_FINANCIER_NAME": "Janata Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1840,
      "TXT_FINANCIER_NAME": "Janata Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1841,
      "TXT_FINANCIER_NAME": "Jankalyan Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1842,
      "TXT_FINANCIER_NAME": "Janseva Nagari Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1843,
      "TXT_FINANCIER_NAME": "Jansewa Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1844,
      "TXT_FINANCIER_NAME": "Jay Kalimata Mahila Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1845,
      "TXT_FINANCIER_NAME": "Jay Tuljabhavani Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1846,
      "TXT_FINANCIER_NAME": "Jayprakash Narayan Nagari Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1847,
      "TXT_FINANCIER_NAME": "Jijamata Mahila Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1848,
      "TXT_FINANCIER_NAME": "Jijamata Mahila Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1849,
      "TXT_FINANCIER_NAME": "Jijamata Mahila Urban Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 1850,
      "TXT_FINANCIER_NAME": "Jijau Commercial Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1851,
      "TXT_FINANCIER_NAME": "Kalyansagar Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1852,
      "TXT_FINANCIER_NAME": "Kedarnath Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1853,
      "TXT_FINANCIER_NAME": "Kisan Nagri Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 1854,
      "TXT_FINANCIER_NAME": "Krishna Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1855,
      "TXT_FINANCIER_NAME": "Latur Peoples Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1856,
      "TXT_FINANCIER_NAME": "Laxmi Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1857,
      "TXT_FINANCIER_NAME": "Loha Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1858,
      "TXT_FINANCIER_NAME": "Lokvikas Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1859,
      "TXT_FINANCIER_NAME": "Maharashtra Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1860,
      "TXT_FINANCIER_NAME": "Mahatma Fule District Urban Co-operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1861,
      "TXT_FINANCIER_NAME": "Mahatma Fule Urban Co-oprative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1862,
      "TXT_FINANCIER_NAME": "Mahesh Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1863,
      "TXT_FINANCIER_NAME": "Mantha Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1864,
      "TXT_FINANCIER_NAME": "Marathwada Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1865,
      "TXT_FINANCIER_NAME": "Markandey Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1866,
      "TXT_FINANCIER_NAME": "Nagnath Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1867,
      "TXT_FINANCIER_NAME": "NAGPUR MAHANAGARPALIKA KARMACHARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1868,
      "TXT_FINANCIER_NAME": "Nirmal Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1869,
      "TXT_FINANCIER_NAME": "Omerga Janata Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1870,
      "TXT_FINANCIER_NAME": "Osmanabad Janata Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1871,
      "TXT_FINANCIER_NAME": "Peoples Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1872,
      "TXT_FINANCIER_NAME": "Parmatma Ek Sewak Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1873,
      "TXT_FINANCIER_NAME": "People's Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1874,
      "TXT_FINANCIER_NAME": "Poornawadi Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1875,
      "TXT_FINANCIER_NAME": "Priyadarshani Mahila Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1876,
      "TXT_FINANCIER_NAME": "Priyadarshani Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1877,
      "TXT_FINANCIER_NAME": "Priyadarshani Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1878,
      "TXT_FINANCIER_NAME": "Priyadarshini Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1879,
      "TXT_FINANCIER_NAME": "Purna Nagri Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1880,
      "TXT_FINANCIER_NAME": "Pusad Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1881,
      "TXT_FINANCIER_NAME": "Rajiv Gandhi Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1882,
      "TXT_FINANCIER_NAME": "Ravi Commercial Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1883,
      "TXT_FINANCIER_NAME": "Sadhana Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1884,
      "TXT_FINANCIER_NAME": "Sai Nagari Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1885,
      "TXT_FINANCIER_NAME": "Saibaba Janata Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1886,
      "TXT_FINANCIER_NAME": "Saibaba Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1887,
      "TXT_FINANCIER_NAME": "Samarth Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1888,
      "TXT_FINANCIER_NAME": "Samarth Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1889,
      "TXT_FINANCIER_NAME": "Samruddhi Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1890,
      "TXT_FINANCIER_NAME": "Sanjivani Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1891,
      "TXT_FINANCIER_NAME": "Sanmitra Mahila Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1892,
      "TXT_FINANCIER_NAME": "Sanmitra Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1893,
      "TXT_FINANCIER_NAME": "Sant Janabai Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1894,
      "TXT_FINANCIER_NAME": "Sant Motiram Maharaj Nagari Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1895,
      "TXT_FINANCIER_NAME": "Shankar Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1896,
      "TXT_FINANCIER_NAME": "Shankarrao Chavan Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1897,
      "TXT_FINANCIER_NAME": "Shivaji Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1898,
      "TXT_FINANCIER_NAME": "Shivparvati Mahila Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1899,
      "TXT_FINANCIER_NAME": "Shree Agrasen Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1900,
      "TXT_FINANCIER_NAME": "Shree Gajanan Maharaj Urban Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1901,
      "TXT_FINANCIER_NAME": "Shri Anand Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1902,
      "TXT_FINANCIER_NAME": "Shri Chhatrapati Rajarshi Shahu Urban Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1903,
      "TXT_FINANCIER_NAME": "Shri Gajanan Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1904,
      "TXT_FINANCIER_NAME": "Shri Mahesh Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1905,
      "TXT_FINANCIER_NAME": "Shri Kanyaka Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1906,
      "TXT_FINANCIER_NAME": "Shri Sai Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1907,
      "TXT_FINANCIER_NAME": "Shri Shiveshwar Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1908,
      "TXT_FINANCIER_NAME": "Shri Swami Samarth Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1909,
      "TXT_FINANCIER_NAME": "Shrikrishna Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1910,
      "TXT_FINANCIER_NAME": "Shriram Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1911,
      "TXT_FINANCIER_NAME": "Siddheshwar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1912,
      "TXT_FINANCIER_NAME": "Siddheshwar Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1913,
      "TXT_FINANCIER_NAME": "Sinhagad Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1914,
      "TXT_FINANCIER_NAME": "Sonpeth Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1915,
      "TXT_FINANCIER_NAME": "Sundarlal Sawji Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1916,
      "TXT_FINANCIER_NAME": "Suvarna Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1917,
      "TXT_FINANCIER_NAME": "Terna Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1918,
      "TXT_FINANCIER_NAME": "The Achalpur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1919,
      "TXT_FINANCIER_NAME": "The Agrasen Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1920,
      "TXT_FINANCIER_NAME": "The Akot Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1921,
      "TXT_FINANCIER_NAME": "The Amravati Merchants' Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1922,
      "TXT_FINANCIER_NAME": "The Amravati People's Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1923,
      "TXT_FINANCIER_NAME": "The Amravati Zilla Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1924,
      "TXT_FINANCIER_NAME": "The Amravati Zilla-Parishad Shikshak Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1925,
      "TXT_FINANCIER_NAME": "The Anjangaon Surji Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1926,
      "TXT_FINANCIER_NAME": "The Aurangabad District Industrial & Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1927,
      "TXT_FINANCIER_NAME": "The Bhagyalakshmi Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1928,
      "TXT_FINANCIER_NAME": "The Bhandara Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1929,
      "TXT_FINANCIER_NAME": "The Chikhli Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1930,
      "TXT_FINANCIER_NAME": "The Chitnavispura Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1931,
      "TXT_FINANCIER_NAME": "DR PANJABRAO DESHMUKH URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 1932,
      "TXT_FINANCIER_NAME": "The Ghadchiroli Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1933,
      "TXT_FINANCIER_NAME": "The Jalna People's Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1934,
      "TXT_FINANCIER_NAME": "The Janata Commercial Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 1935,
      "TXT_FINANCIER_NAME": "The Latur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1936,
      "TXT_FINANCIER_NAME": "The Mahila Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1937,
      "TXT_FINANCIER_NAME": "The Malkapur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1938,
      "TXT_FINANCIER_NAME": "The Mehkar Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1939,
      "TXT_FINANCIER_NAME": "The Nagpur Mahila Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1940,
      "TXT_FINANCIER_NAME": "The Nanded Merchant's Co-operative"
    },
    {
      "NUM_FINANCIER_CD": 1941,
      "TXT_FINANCIER_NAME": "The Nandura Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1942,
      "TXT_FINANCIER_NAME": "The Navodaya Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1507,
      "TXT_FINANCIER_NAME": "Janata Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1735,
      "TXT_FINANCIER_NAME": "Shri Ganesh Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1736,
      "TXT_FINANCIER_NAME": "Shri Jyotiba Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1737,
      "TXT_FINANCIER_NAME": "Shri Lakshmi Krupa Urban Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1738,
      "TXT_FINANCIER_NAME": "Shri Laxmi Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1739,
      "TXT_FINANCIER_NAME": "Shri Laxmi Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1740,
      "TXT_FINANCIER_NAME": "Shri Mahalaxmi Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1741,
      "TXT_FINANCIER_NAME": "Shri Mahaveer Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1166,
      "TXT_FINANCIER_NAME": "Rajadhani Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1167,
      "TXT_FINANCIER_NAME": "Ramakrishna Mutually Aided Co-operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1168,
      "TXT_FINANCIER_NAME": "Ramchandrapuram Co-Op Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1169,
      "TXT_FINANCIER_NAME": "Ranga Reddy Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1170,
      "TXT_FINANCIER_NAME": "Samatha Mahila Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1171,
      "TXT_FINANCIER_NAME": "Secunderabad Co-Operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1172,
      "TXT_FINANCIER_NAME": "Seva Co-Operative Urban Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1742,
      "TXT_FINANCIER_NAME": "Shri Mahavir Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1743,
      "TXT_FINANCIER_NAME": "Shri Nrusingh Saraswati sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1744,
      "TXT_FINANCIER_NAME": "Shri Parola Urban Co op bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1745,
      "TXT_FINANCIER_NAME": "Shri P.K.Anna Patil Janata Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1746,
      "TXT_FINANCIER_NAME": "Shri Parshwanath Co op Bank"
    },
    {
      "NUM_FINANCIER_CD": 1747,
      "TXT_FINANCIER_NAME": "Shri Patneshwar Urban cooprative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1748,
      "TXT_FINANCIER_NAME": "Shri Rukmini Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1749,
      "TXT_FINANCIER_NAME": "Shri S.K.Patil Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1750,
      "TXT_FINANCIER_NAME": "Shri Saptashringi Urban Co-op. Bank"
    },
    {
      "NUM_FINANCIER_CD": 1751,
      "TXT_FINANCIER_NAME": "Shri Satyavijay Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1752,
      "TXT_FINANCIER_NAME": "Shri Shahu Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1753,
      "TXT_FINANCIER_NAME": "Shri Sharada Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1754,
      "TXT_FINANCIER_NAME": "Shri Shivaji Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1755,
      "TXT_FINANCIER_NAME": "Shri Shivneri Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1756,
      "TXT_FINANCIER_NAME": "Shri Swami Samarth Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1757,
      "TXT_FINANCIER_NAME": "Shri Sinner Vyapari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1758,
      "TXT_FINANCIER_NAME": "Shri Veershaiv Co op Bank"
    },
    {
      "NUM_FINANCIER_CD": 1759,
      "TXT_FINANCIER_NAME": "Shri Vyankatesh Co-operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1760,
      "TXT_FINANCIER_NAME": "Shri Yashwant Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1761,
      "TXT_FINANCIER_NAME": "Shrimant Malojiraje Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1762,
      "TXT_FINANCIER_NAME": "Shripatrao Dada Sahakari bank"
    },
    {
      "NUM_FINANCIER_CD": 1763,
      "TXT_FINANCIER_NAME": "Shriram Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1764,
      "TXT_FINANCIER_NAME": "Shriram Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1765,
      "TXT_FINANCIER_NAME": "Shrirampur People's Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1766,
      "TXT_FINANCIER_NAME": "Siddharth Sahakari Bank Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 1767,
      "TXT_FINANCIER_NAME": "Sindhudurg Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1768,
      "TXT_FINANCIER_NAME": "Solapur Siddheshwar Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1769,
      "TXT_FINANCIER_NAME": "Sopankaka Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 1770,
      "TXT_FINANCIER_NAME": "South Indian Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1771,
      "TXT_FINANCIER_NAME": "Sree Narayana Guru Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1772,
      "TXT_FINANCIER_NAME": "Sushil Kumar Nahata Urban Co op Bank"
    },
    {
      "NUM_FINANCIER_CD": 1773,
      "TXT_FINANCIER_NAME": "State Transport Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1774,
      "TXT_FINANCIER_NAME": "Suvarna Mangal Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1775,
      "TXT_FINANCIER_NAME": "Suvarnayug Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1776,
      "TXT_FINANCIER_NAME": "Swami Samarth Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1777,
      "TXT_FINANCIER_NAME": "Swaraj Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1778,
      "TXT_FINANCIER_NAME": "Tasgaon Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1779,
      "TXT_FINANCIER_NAME": "Udyam Vikas Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1780,
      "TXT_FINANCIER_NAME": "Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1781,
      "TXT_FINANCIER_NAME": "Vaishya Coop Bank ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1782,
      "TXT_FINANCIER_NAME": "Vani Merchants Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1783,
      "TXT_FINANCIER_NAME": "Vasai Janata sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1784,
      "TXT_FINANCIER_NAME": "Vasai Vikas Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1785,
      "TXT_FINANCIER_NAME": "Vasantdada Shetkari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1786,
      "TXT_FINANCIER_NAME": "Vidya Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1787,
      "TXT_FINANCIER_NAME": "Veershaiva Co-op Bank"
    },
    {
      "NUM_FINANCIER_CD": 1788,
      "TXT_FINANCIER_NAME": "Vidyanand Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1789,
      "TXT_FINANCIER_NAME": "Vikas Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1790,
      "TXT_FINANCIER_NAME": "Vima Kamgar Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1791,
      "TXT_FINANCIER_NAME": "Vishwanathrao Patil Murgud Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1792,
      "TXT_FINANCIER_NAME": "Vishwas Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1793,
      "TXT_FINANCIER_NAME": "Vishweshwar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1794,
      "TXT_FINANCIER_NAME": "Vita Merchants Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1795,
      "TXT_FINANCIER_NAME": "Vita Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1796,
      "TXT_FINANCIER_NAME": "Vyankateshwara Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1797,
      "TXT_FINANCIER_NAME": "Vyapari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1798,
      "TXT_FINANCIER_NAME": "Wai Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1799,
      "TXT_FINANCIER_NAME": "Walchandnagar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1800,
      "TXT_FINANCIER_NAME": "Women's Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1801,
      "TXT_FINANCIER_NAME": "Yadrav Co-operative bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1802,
      "TXT_FINANCIER_NAME": "Yashwant Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1803,
      "TXT_FINANCIER_NAME": "Yashwant Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1804,
      "TXT_FINANCIER_NAME": "YAWAL PEOPLE'S CBL"
    },
    {
      "NUM_FINANCIER_CD": 1805,
      "TXT_FINANCIER_NAME": "Yeola Merchants Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1806,
      "TXT_FINANCIER_NAME": "Youth Development Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1807,
      "TXT_FINANCIER_NAME": "Abhinandan Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1808,
      "TXT_FINANCIER_NAME": "Adarsh Mahila Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1809,
      "TXT_FINANCIER_NAME": "Ajantha Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1810,
      "TXT_FINANCIER_NAME": "Akola Merchant Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1811,
      "TXT_FINANCIER_NAME": "Ambajogai Peoples Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1812,
      "TXT_FINANCIER_NAME": "Ameer Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1813,
      "TXT_FINANCIER_NAME": "Annasaheb Patil Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1814,
      "TXT_FINANCIER_NAME": "Anuradha Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1815,
      "TXT_FINANCIER_NAME": "Arvind Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1816,
      "TXT_FINANCIER_NAME": "Bhagyodaya Friends Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1817,
      "TXT_FINANCIER_NAME": "Bhausaheb Birajdar Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1818,
      "TXT_FINANCIER_NAME": "Bhavani Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1819,
      "TXT_FINANCIER_NAME": "Bhimashankar Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1820,
      "TXT_FINANCIER_NAME": "Bramhapuri Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1821,
      "TXT_FINANCIER_NAME": "Champavati Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1822,
      "TXT_FINANCIER_NAME": "Chetak Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1823,
      "TXT_FINANCIER_NAME": "Deendayal Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1824,
      "TXT_FINANCIER_NAME": "Deogiri Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1825,
      "TXT_FINANCIER_NAME": "Desaiganj Nagari Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1826,
      "TXT_FINANCIER_NAME": "Dnyanopasak Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1827,
      "TXT_FINANCIER_NAME": "Dr. Babasaheb Ambedkar Nagari Sahakari"
    },
    {
      "NUM_FINANCIER_CD": 1828,
      "TXT_FINANCIER_NAME": "Dr. Babasaheb Ambedkar Urban Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1829,
      "TXT_FINANCIER_NAME": "Dr. Jaiprakash Mundada Urban Cooprative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1830,
      "TXT_FINANCIER_NAME": "Dr. Shivajirao Patil Nilangekar Urban Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1831,
      "TXT_FINANCIER_NAME": "Dwarakadas Mantri Nagari Sahakari Bank."
    },
    {
      "NUM_FINANCIER_CD": 1832,
      "TXT_FINANCIER_NAME": "Gandhibag Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1833,
      "TXT_FINANCIER_NAME": "Ganraj Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1834,
      "TXT_FINANCIER_NAME": "Godavari Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1835,
      "TXT_FINANCIER_NAME": "Hina Shahin Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1836,
      "TXT_FINANCIER_NAME": "Indira Mahila Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1837,
      "TXT_FINANCIER_NAME": "Jai Shivrai Nagri Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1838,
      "TXT_FINANCIER_NAME": "Jalna Merchants Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1943,
      "TXT_FINANCIER_NAME": "The Parbhani People's Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1944,
      "TXT_FINANCIER_NAME": "The Sahyog Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1945,
      "TXT_FINANCIER_NAME": "The Samata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1946,
      "TXT_FINANCIER_NAME": "The Udgir Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1947,
      "TXT_FINANCIER_NAME": "The Vaidyanath Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1948,
      "TXT_FINANCIER_NAME": "The Vaijapur Merchants Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1949,
      "TXT_FINANCIER_NAME": "The Vidarbha Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1950,
      "TXT_FINANCIER_NAME": "The Washim Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1951,
      "TXT_FINANCIER_NAME": "The Yavatmal Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1952,
      "TXT_FINANCIER_NAME": "The Yavatmal Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1953,
      "TXT_FINANCIER_NAME": "Tirupati Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1954,
      "TXT_FINANCIER_NAME": "Umiya Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1955,
      "TXT_FINANCIER_NAME": "Vaishya Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1956,
      "TXT_FINANCIER_NAME": "Valmiki Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1957,
      "TXT_FINANCIER_NAME": "Vasantdada Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1958,
      "TXT_FINANCIER_NAME": "Vasundhara Mahila Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1959,
      "TXT_FINANCIER_NAME": "Vidharbha Merchants Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1960,
      "TXT_FINANCIER_NAME": "Vikas Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1961,
      "TXT_FINANCIER_NAME": "Vishwakarma Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1962,
      "TXT_FINANCIER_NAME": "Vitthal Nagari Sahari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1963,
      "TXT_FINANCIER_NAME": "Wana Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1964,
      "TXT_FINANCIER_NAME": "Wardha Nagari Sahakari Adhikosh Bank"
    },
    {
      "NUM_FINANCIER_CD": 1965,
      "TXT_FINANCIER_NAME": "Wani Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1966,
      "TXT_FINANCIER_NAME": "Wardhaman Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1967,
      "TXT_FINANCIER_NAME": "Wardha District Ashirwad Mahila Nagari Sah Bank"
    },
    {
      "NUM_FINANCIER_CD": 1968,
      "TXT_FINANCIER_NAME": "Wardha Zilla Parishad Employees (Urban) Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1969,
      "TXT_FINANCIER_NAME": "Warud Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1970,
      "TXT_FINANCIER_NAME": "Yashwant Nagari Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1971,
      "TXT_FINANCIER_NAME": "Yeshwant Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1972,
      "TXT_FINANCIER_NAME": "Yeshwant Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1973,
      "TXT_FINANCIER_NAME": "The Bihar Awami Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1974,
      "TXT_FINANCIER_NAME": "The Jamshedpur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1975,
      "TXT_FINANCIER_NAME": "The Tapindu Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1976,
      "TXT_FINANCIER_NAME": "The Koylanchal Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1977,
      "TXT_FINANCIER_NAME": "Adhyapaka Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1978,
      "TXT_FINANCIER_NAME": "The V.S.V.Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1979,
      "TXT_FINANCIER_NAME": "Adoor Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1980,
      "TXT_FINANCIER_NAME": "Alleppey Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1981,
      "TXT_FINANCIER_NAME": "Ananthasayanam Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1982,
      "TXT_FINANCIER_NAME": "Alwaye Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1983,
      "TXT_FINANCIER_NAME": "Balusery Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1984,
      "TXT_FINANCIER_NAME": "Calicut Co-operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1985,
      "TXT_FINANCIER_NAME": "Cannanore Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1986,
      "TXT_FINANCIER_NAME": "Changanacherry Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1987,
      "TXT_FINANCIER_NAME": "Cherpalcheri Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1988,
      "TXT_FINANCIER_NAME": "Feroke Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1989,
      "TXT_FINANCIER_NAME": "Coastal Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1990,
      "TXT_FINANCIER_NAME": "Guruvayur Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1991,
      "TXT_FINANCIER_NAME": "Karamana Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1992,
      "TXT_FINANCIER_NAME": "Kaduthuruthy Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1993,
      "TXT_FINANCIER_NAME": "Irinjalakuda Town Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1994,
      "TXT_FINANCIER_NAME": "Karunagapalli Taluk Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1995,
      "TXT_FINANCIER_NAME": "Kasargod Co-operative Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1996,
      "TXT_FINANCIER_NAME": "Kerala Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1997,
      "TXT_FINANCIER_NAME": "Kattappana Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1998,
      "TXT_FINANCIER_NAME": "Kodungallur Town Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 1999,
      "TXT_FINANCIER_NAME": "Kottakal Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2000,
      "TXT_FINANCIER_NAME": "Kottarakkara Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2001,
      "TXT_FINANCIER_NAME": "Kottayam Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2002,
      "TXT_FINANCIER_NAME": "Kuttyadi Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2003,
      "TXT_FINANCIER_NAME": "L.I.C.Staff Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2004,
      "TXT_FINANCIER_NAME": "Manjeri Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2005,
      "TXT_FINANCIER_NAME": "Mattancherry Mahajanik Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 2006,
      "TXT_FINANCIER_NAME": "Mattancherry Sarvajanik Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 2007,
      "TXT_FINANCIER_NAME": "Meenachil East Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 2008,
      "TXT_FINANCIER_NAME": "Muvattupuzha Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 2009,
      "TXT_FINANCIER_NAME": "Nadapuram Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2010,
      "TXT_FINANCIER_NAME": "Nedumangadu Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2011,
      "TXT_FINANCIER_NAME": "Nemmara Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2012,
      "TXT_FINANCIER_NAME": "Neyyattinkara Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2013,
      "TXT_FINANCIER_NAME": "Nilambur Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2014,
      "TXT_FINANCIER_NAME": "Nileshwar Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2015,
      "TXT_FINANCIER_NAME": "Ottapalam Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2016,
      "TXT_FINANCIER_NAME": "Pala Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2017,
      "TXT_FINANCIER_NAME": "Palghat Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2018,
      "TXT_FINANCIER_NAME": "Payangadi Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2019,
      "TXT_FINANCIER_NAME": "Payyanur Co-operative Town Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2020,
      "TXT_FINANCIER_NAME": "People's Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 2021,
      "TXT_FINANCIER_NAME": "Payyoli Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2022,
      "TXT_FINANCIER_NAME": "Perintalmanna Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2023,
      "TXT_FINANCIER_NAME": "Ponnani Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2024,
      "TXT_FINANCIER_NAME": "Quilon Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2025,
      "TXT_FINANCIER_NAME": "Shoranur Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2026,
      "TXT_FINANCIER_NAME": "Sultan's Battery Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2027,
      "TXT_FINANCIER_NAME": "Taliparamba Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2028,
      "TXT_FINANCIER_NAME": "Tanur Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2029,
      "TXT_FINANCIER_NAME": "Tellicherry Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2030,
      "TXT_FINANCIER_NAME": "Tirur Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2031,
      "TXT_FINANCIER_NAME": "Thodupuzha Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2032,
      "TXT_FINANCIER_NAME": "Tiruvalla East Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2033,
      "TXT_FINANCIER_NAME": "Tiruvalla Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2034,
      "TXT_FINANCIER_NAME": "Trichur Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2035,
      "TXT_FINANCIER_NAME": "Trivandrum Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 2036,
      "TXT_FINANCIER_NAME": "Vadakara Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 2037,
      "TXT_FINANCIER_NAME": "Vaikom Urban Co-operative Bank LImited."
    },
    {
      "NUM_FINANCIER_CD": 1389,
      "TXT_FINANCIER_NAME": "Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1390,
      "TXT_FINANCIER_NAME": "Uttarakhand C-operative bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1391,
      "TXT_FINANCIER_NAME": "Abhinav Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1392,
      "TXT_FINANCIER_NAME": "Agrasen Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1393,
      "TXT_FINANCIER_NAME": "Ahilyadevi Mahila Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1394,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR MERCHANTS COOP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1395,
      "TXT_FINANCIER_NAME": "Ahmednagar Shahar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1396,
      "TXT_FINANCIER_NAME": "Ahmednagar Zilla Prathamik Shikshak Saha.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1397,
      "TXT_FINANCIER_NAME": "Air Corporation Employees'Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1398,
      "TXT_FINANCIER_NAME": "Ajara Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1399,
      "TXT_FINANCIER_NAME": "Ajinkyatara Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1400,
      "TXT_FINANCIER_NAME": "Ajinkyatara Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1401,
      "TXT_FINANCIER_NAME": "Ajit co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1402,
      "TXT_FINANCIER_NAME": "Alibag Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1403,
      "TXT_FINANCIER_NAME": "Amalner Co-Op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1404,
      "TXT_FINANCIER_NAME": "Aman Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1405,
      "TXT_FINANCIER_NAME": "Ambica Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1406,
      "TXT_FINANCIER_NAME": "Andersul Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1407,
      "TXT_FINANCIER_NAME": "Amrutvahini Urban Co-Op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1408,
      "TXT_FINANCIER_NAME": "Anna Sahaeb Nagar Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1409,
      "TXT_FINANCIER_NAME": "Annasaheb Karale Janata Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1410,
      "TXT_FINANCIER_NAME": "APNA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 1411,
      "TXT_FINANCIER_NAME": "Appasaheb Birnale sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1412,
      "TXT_FINANCIER_NAME": "Arjun Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1413,
      "TXT_FINANCIER_NAME": "Ashok Nagri Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1414,
      "TXT_FINANCIER_NAME": "Ashok Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1415,
      "TXT_FINANCIER_NAME": "Ashta People's Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1416,
      "TXT_FINANCIER_NAME": "Ashta Urban Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1417,
      "TXT_FINANCIER_NAME": "Bajirao Appa Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1418,
      "TXT_FINANCIER_NAME": "Baramati Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1419,
      "TXT_FINANCIER_NAME": "Bhadgaon Peoples Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1420,
      "TXT_FINANCIER_NAME": "Bhagini Nivedita Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1421,
      "TXT_FINANCIER_NAME": "Bhandari Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1422,
      "TXT_FINANCIER_NAME": "Bharat Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1423,
      "TXT_FINANCIER_NAME": "Bhavani Sahakari Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1424,
      "TXT_FINANCIER_NAME": "Bhinger Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1425,
      "TXT_FINANCIER_NAME": "Bhusawal People's Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1426,
      "TXT_FINANCIER_NAME": "Bicholim Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1427,
      "TXT_FINANCIER_NAME": "Birdeo Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1428,
      "TXT_FINANCIER_NAME": "Brahmadeodada Mane Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1429,
      "TXT_FINANCIER_NAME": "Business Co-Operative Bank Maryadit.,"
    },
    {
      "NUM_FINANCIER_CD": 1430,
      "TXT_FINANCIER_NAME": "Care Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1431,
      "TXT_FINANCIER_NAME": "Chaitanya Urban Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1432,
      "TXT_FINANCIER_NAME": "Chalisgaon People's Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1433,
      "TXT_FINANCIER_NAME": "Chandgad Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1434,
      "TXT_FINANCIER_NAME": "Chandraseniya Kayastha Prabhu Coop Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1435,
      "TXT_FINANCIER_NAME": "Chandwad Merchant's Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1436,
      "TXT_FINANCIER_NAME": "Chembur Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1437,
      "TXT_FINANCIER_NAME": "Chhopda Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1438,
      "TXT_FINANCIER_NAME": "Chiplun Urban Coop.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1439,
      "TXT_FINANCIER_NAME": "Chopda People's Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1440,
      "TXT_FINANCIER_NAME": "Choudeshwari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1441,
      "TXT_FINANCIER_NAME": "Citizen Coop. Bank Ltd. Vasco-De-Gama."
    },
    {
      "NUM_FINANCIER_CD": 1442,
      "TXT_FINANCIER_NAME": "Citizen Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1443,
      "TXT_FINANCIER_NAME": "City Coop.Bank Ltd.,Dadar,Bombay."
    },
    {
      "NUM_FINANCIER_CD": 1444,
      "TXT_FINANCIER_NAME": "Commercial Coop Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1445,
      "TXT_FINANCIER_NAME": "Dadasaheb Dr.N.M.Kabre Nagrik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1446,
      "TXT_FINANCIER_NAME": "Dadasaheb Gajmal Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1447,
      "TXT_FINANCIER_NAME": "Dadasaheb Ramrao Patil Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1448,
      "TXT_FINANCIER_NAME": "Dadasaheb Raval Coop Bank of"
    },
    {
      "NUM_FINANCIER_CD": 1449,
      "TXT_FINANCIER_NAME": "Dahanu Road Janata Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1450,
      "TXT_FINANCIER_NAME": "Dapoli Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1451,
      "TXT_FINANCIER_NAME": "Dattatraya Maharaj Kalambi Jaoli Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1452,
      "TXT_FINANCIER_NAME": "Daund Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1453,
      "TXT_FINANCIER_NAME": "Deccan Merchants Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1454,
      "TXT_FINANCIER_NAME": "Defence Accounts Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1455,
      "TXT_FINANCIER_NAME": "Deogad Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1456,
      "TXT_FINANCIER_NAME": "Deola Merchants'Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1457,
      "TXT_FINANCIER_NAME": "Devyani Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1458,
      "TXT_FINANCIER_NAME": "Dhanshri Mahila Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1459,
      "TXT_FINANCIER_NAME": "Dharamvir Sambhaji Urban Co-Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1460,
      "TXT_FINANCIER_NAME": "Dhule and Nandurbar jilha Sarkari Nokaranchi Sahakari bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1461,
      "TXT_FINANCIER_NAME": "Dhule Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1462,
      "TXT_FINANCIER_NAME": "Dhule Vikas Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1463,
      "TXT_FINANCIER_NAME": "Dilip Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1464,
      "TXT_FINANCIER_NAME": "Dr. Babasaheb Ambedkar Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1465,
      "TXT_FINANCIER_NAME": "Dr.Annasahab Chaugule Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1466,
      "TXT_FINANCIER_NAME": "Faiz Mercantile Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1467,
      "TXT_FINANCIER_NAME": "Faizpur Janta Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1468,
      "TXT_FINANCIER_NAME": "Gadhinglaj Urban Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1469,
      "TXT_FINANCIER_NAME": "Ganesh Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1470,
      "TXT_FINANCIER_NAME": "Gautam Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1471,
      "TXT_FINANCIER_NAME": "Ghoti Marchants Co op Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1472,
      "TXT_FINANCIER_NAME": "Goa Peoples Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1473,
      "TXT_FINANCIER_NAME": "Godavari Laxmi Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1474,
      "TXT_FINANCIER_NAME": "Godavari Urban Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1475,
      "TXT_FINANCIER_NAME": "Goregaon Co op Urban Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1476,
      "TXT_FINANCIER_NAME": "Government Servants' Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1477,
      "TXT_FINANCIER_NAME": "Harihareshwar Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1478,
      "TXT_FINANCIER_NAME": "Hasti Co op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1479,
      "TXT_FINANCIER_NAME": "Haveli Sahakari Bank Maryadit."
    },
    {
      "NUM_FINANCIER_CD": 1480,
      "TXT_FINANCIER_NAME": "Hindustan Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 1481,
      "TXT_FINANCIER_NAME": "Hutatma Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1482,
      "TXT_FINANCIER_NAME": "Ichalkaranji Jiveshwar Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1483,
      "TXT_FINANCIER_NAME": "Ichalkaranji Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1484,
      "TXT_FINANCIER_NAME": "Ichalkaranji Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1485,
      "TXT_FINANCIER_NAME": "Ichalkarnji Merchants Co-op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1486,
      "TXT_FINANCIER_NAME": "Income Tax Department Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1487,
      "TXT_FINANCIER_NAME": "Indapur Urban Co-Operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1488,
      "TXT_FINANCIER_NAME": "Independence Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1489,
      "TXT_FINANCIER_NAME": "Indira Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1490,
      "TXT_FINANCIER_NAME": "Indira Mahila Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1491,
      "TXT_FINANCIER_NAME": "Indira Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1492,
      "TXT_FINANCIER_NAME": "Indira Shramik Mahila Nagri Saha.Bank"
    },
    {
      "NUM_FINANCIER_CD": 1493,
      "TXT_FINANCIER_NAME": "Indrayani Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1494,
      "TXT_FINANCIER_NAME": "Islampur Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1495,
      "TXT_FINANCIER_NAME": "Jai Bhawani Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1496,
      "TXT_FINANCIER_NAME": "Jai Hind Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1497,
      "TXT_FINANCIER_NAME": "Jai Hind Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1498,
      "TXT_FINANCIER_NAME": "Jain Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1499,
      "TXT_FINANCIER_NAME": "Jalgaon Merchants'Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1500,
      "TXT_FINANCIER_NAME": "JALGAON PEOPLES COOP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 1501,
      "TXT_FINANCIER_NAME": "Janakalyan Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1502,
      "TXT_FINANCIER_NAME": "Jamkhed Merchants Co-Operative bank"
    },
    {
      "NUM_FINANCIER_CD": 1503,
      "TXT_FINANCIER_NAME": "Janaseva Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1504,
      "TXT_FINANCIER_NAME": "Janaseva Sahakari (Borivli) Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1505,
      "TXT_FINANCIER_NAME": "Janata Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1506,
      "TXT_FINANCIER_NAME": "Janata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1246,
      "TXT_FINANCIER_NAME": "Jalore Nagarik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1247,
      "TXT_FINANCIER_NAME": "Jhalawar Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1248,
      "TXT_FINANCIER_NAME": "Jodhpur Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1249,
      "TXT_FINANCIER_NAME": "Kota Mahila Nagarik Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1250,
      "TXT_FINANCIER_NAME": "Kota Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1251,
      "TXT_FINANCIER_NAME": "Madhav Nagarik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1252,
      "TXT_FINANCIER_NAME": "Malviya Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1253,
      "TXT_FINANCIER_NAME": "Nagaur Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1254,
      "TXT_FINANCIER_NAME": "Pali Urban Cooperative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1255,
      "TXT_FINANCIER_NAME": "Railway Employees' Coop Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1256,
      "TXT_FINANCIER_NAME": "Railway Employees'Coop Banking Soc Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1257,
      "TXT_FINANCIER_NAME": "Railway Shramik Sahakari Bank"
    },
    {
      "NUM_FINANCIER_CD": 1258,
      "TXT_FINANCIER_NAME": "Raj Laxmi Mahila Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1259,
      "TXT_FINANCIER_NAME": "Rajasthan Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1260,
      "TXT_FINANCIER_NAME": "Rajputana Mahila Urban Co-op Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1261,
      "TXT_FINANCIER_NAME": "Rajsamand Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1262,
      "TXT_FINANCIER_NAME": "Sawai Madhopur Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1263,
      "TXT_FINANCIER_NAME": "Sikar Urban Co Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1264,
      "TXT_FINANCIER_NAME": "Sumerpur Mercantile Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1265,
      "TXT_FINANCIER_NAME": "Sterling Urban Co-Operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1266,
      "TXT_FINANCIER_NAME": "Udaipur Mahila Samridhhi Urban Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1267,
      "TXT_FINANCIER_NAME": "Udaipur Mahila Urban Coop. Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1268,
      "TXT_FINANCIER_NAME": "Udaipur Urban Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1269,
      "TXT_FINANCIER_NAME": "Urban Co op Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 1270,
      "TXT_FINANCIER_NAME": "Vaishali Urban Co-op. Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1271,
      "TXT_FINANCIER_NAME": "The Citizens' Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1272,
      "TXT_FINANCIER_NAME": "Devika Urban Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1273,
      "TXT_FINANCIER_NAME": "Kashmir Mercantile Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1274,
      "TXT_FINANCIER_NAME": "The Urban Co-operative Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 2039,
      "TXT_FINANCIER_NAME": "AXIS BANK"
    },
    {
      "NUM_FINANCIER_CD": 2040,
      "TXT_FINANCIER_NAME": "INDIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2070,
      "TXT_FINANCIER_NAME": "PUNE DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2071,
      "TXT_FINANCIER_NAME": "KSHETRIYA GRAMIN BANK HOSHANGABAD"
    },
    {
      "NUM_FINANCIER_CD": 2072,
      "TXT_FINANCIER_NAME": "THE SANGRUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2073,
      "TXT_FINANCIER_NAME": "POST OFFICE SAVINGS BANK"
    },
    {
      "NUM_FINANCIER_CD": 2074,
      "TXT_FINANCIER_NAME": "THE THANE DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2075,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2076,
      "TXT_FINANCIER_NAME": "JILA SAHAKARI KENDRIYA BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 2077,
      "TXT_FINANCIER_NAME": "THE PUDUPPARIYARAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2078,
      "TXT_FINANCIER_NAME": "THE SAHEBRAO DESHMUKH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2079,
      "TXT_FINANCIER_NAME": "THE BARAMATI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2080,
      "TXT_FINANCIER_NAME": "THE NAGPUR DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2081,
      "TXT_FINANCIER_NAME": "THE NAGPUR DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2082,
      "TXT_FINANCIER_NAME": "THE POOTHADY SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2083,
      "TXT_FINANCIER_NAME": "THE EACHOME SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2084,
      "TXT_FINANCIER_NAME": "DISTRICT CO OP BANK LTD SAHARANPUR"
    },
    {
      "NUM_FINANCIER_CD": 2085,
      "TXT_FINANCIER_NAME": "SHINHAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2086,
      "TXT_FINANCIER_NAME": "CITIZEN CREDIT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2087,
      "TXT_FINANCIER_NAME": "AMBARNATH JAI HIND CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2088,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2089,
      "TXT_FINANCIER_NAME": "UTTARKASHI ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2090,
      "TXT_FINANCIER_NAME": "JAIPUR NAGAUR ANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2091,
      "TXT_FINANCIER_NAME": "JAIPUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2092,
      "TXT_FINANCIER_NAME": "SHREE SHARADA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2093,
      "TXT_FINANCIER_NAME": "THE FARIDABAD CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2094,
      "TXT_FINANCIER_NAME": "BARODA UTTAR PRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2095,
      "TXT_FINANCIER_NAME": "MUMBAI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2096,
      "TXT_FINANCIER_NAME": "C G RAJYA SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 2097,
      "TXT_FINANCIER_NAME": "THE KODINAR TALUKA CO OP BANKING UNION LTD"
    },
    {
      "NUM_FINANCIER_CD": 2098,
      "TXT_FINANCIER_NAME": "THAR AANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2099,
      "TXT_FINANCIER_NAME": "VALSAD DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2100,
      "TXT_FINANCIER_NAME": "NAGROTA BAGWAN CO OPERATIVE NON AGRI TH AND CR SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 2101,
      "TXT_FINANCIER_NAME": "THE SHAHADA PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2102,
      "TXT_FINANCIER_NAME": "BHOPAL CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2103,
      "TXT_FINANCIER_NAME": "THE SHOLAPUR DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2104,
      "TXT_FINANCIER_NAME": "THE LATUR DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2105,
      "TXT_FINANCIER_NAME": "THE BROACH DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2106,
      "TXT_FINANCIER_NAME": "MAHARASHTRA GODAVARI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2107,
      "TXT_FINANCIER_NAME": "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2108,
      "TXT_FINANCIER_NAME": "THE SABARKANTHA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2109,
      "TXT_FINANCIER_NAME": "RAIGAD DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2110,
      "TXT_FINANCIER_NAME": "ANNASAHEB MAGAR SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 2111,
      "TXT_FINANCIER_NAME": "PUNE MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2112,
      "TXT_FINANCIER_NAME": "AGRA ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2114,
      "TXT_FINANCIER_NAME": "THE DISTRICT CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2115,
      "TXT_FINANCIER_NAME": "MANDLA BALAGHAT KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2116,
      "TXT_FINANCIER_NAME": "AVADH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2117,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2119,
      "TXT_FINANCIER_NAME": "KANPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2120,
      "TXT_FINANCIER_NAME": "THE HYDERABAD DIST COOP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2121,
      "TXT_FINANCIER_NAME": "ACE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2123,
      "TXT_FINANCIER_NAME": "CHENAI CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2124,
      "TXT_FINANCIER_NAME": "DISTRICT CO OP BANK LTD DEHRADUN"
    },
    {
      "NUM_FINANCIER_CD": 2125,
      "TXT_FINANCIER_NAME": "UDHNA CITIZEN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2127,
      "TXT_FINANCIER_NAME": "SHREE WARANA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2128,
      "TXT_FINANCIER_NAME": "JODHPUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2129,
      "TXT_FINANCIER_NAME": "SATARA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2130,
      "TXT_FINANCIER_NAME": "KRISHNA DISTRICT CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2132,
      "TXT_FINANCIER_NAME": "INDRAYANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2133,
      "TXT_FINANCIER_NAME": "THE KAPOL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2134,
      "TXT_FINANCIER_NAME": "YAVATMAL DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2136,
      "TXT_FINANCIER_NAME": "HOUSING AND URBAN DEVELOPMENT CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2137,
      "TXT_FINANCIER_NAME": "ANNASAHEB SAVANT CO OP URBAN BANK MAHAD LTD"
    },
    {
      "NUM_FINANCIER_CD": 1508,
      "TXT_FINANCIER_NAME": "Janseva Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1509,
      "TXT_FINANCIER_NAME": "Jarandeshwar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1510,
      "TXT_FINANCIER_NAME": "Jath Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1511,
      "TXT_FINANCIER_NAME": "Jawahar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1512,
      "TXT_FINANCIER_NAME": "Jawahar Urban Coop Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1513,
      "TXT_FINANCIER_NAME": "Jaysingpur-Udgaon Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 1514,
      "TXT_FINANCIER_NAME": "Jijamata Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1515,
      "TXT_FINANCIER_NAME": "Jivhaji Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1516,
      "TXT_FINANCIER_NAME": "Kagal Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1517,
      "TXT_FINANCIER_NAME": "Kalwan Marchants' Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1518,
      "TXT_FINANCIER_NAME": "Kamala Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1519,
      "TXT_FINANCIER_NAME": "Karad Janata Sahakari Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 1520,
      "TXT_FINANCIER_NAME": "Karmala Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1521,
      "TXT_FINANCIER_NAME": "Karnala Nagari Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1522,
      "TXT_FINANCIER_NAME": "Kavita Urban Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1523,
      "TXT_FINANCIER_NAME": "Kodoli Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1524,
      "TXT_FINANCIER_NAME": "Kohinoor Sahakari Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 1525,
      "TXT_FINANCIER_NAME": "Kokan Mercantile Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1526,
      "TXT_FINANCIER_NAME": "Kokan Prant Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1527,
      "TXT_FINANCIER_NAME": "Kolhapur Janata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1528,
      "TXT_FINANCIER_NAME": "Kolhapur Mahila Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1529,
      "TXT_FINANCIER_NAME": "Kolhapur Maratha Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1530,
      "TXT_FINANCIER_NAME": "Kolhapur Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1531,
      "TXT_FINANCIER_NAME": "Kopargaon Peoples' Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1532,
      "TXT_FINANCIER_NAME": "Koregaon Co-op. Peoples'Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1533,
      "TXT_FINANCIER_NAME": "Koyana Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1534,
      "TXT_FINANCIER_NAME": "Krishna Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1535,
      "TXT_FINANCIER_NAME": "Krishna Valley Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 1536,
      "TXT_FINANCIER_NAME": "Krushiseva Urban cooprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1537,
      "TXT_FINANCIER_NAME": "Kumbhi Kasari Sahakari Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1538,
      "TXT_FINANCIER_NAME": "Kunbi Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1539,
      "TXT_FINANCIER_NAME": "Kurla Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1540,
      "TXT_FINANCIER_NAME": "Kupwad Urban Co-Operative Bank ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 1541,
      "TXT_FINANCIER_NAME": "Kurundwad Urban Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 1542,
      "TXT_FINANCIER_NAME": "Kutch Cooperative bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 1543,
      "TXT_FINANCIER_NAME": "Lala Urban Coop Bank Limited."
    },
    {
      "NUM_FINANCIER_CD": 2038,
      "TXT_FINANCIER_NAME": "THE MUSLIM CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2041,
      "TXT_FINANCIER_NAME": "DENA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2042,
      "TXT_FINANCIER_NAME": "SHRI PAISAFUND SHETKARI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2043,
      "TXT_FINANCIER_NAME": "JUNAGADH JILLA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2044,
      "TXT_FINANCIER_NAME": "THE BANASKANTHA DIST CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2045,
      "TXT_FINANCIER_NAME": "THE COSMOS COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2046,
      "TXT_FINANCIER_NAME": "THE AURANGABAD DISTRICT CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2047,
      "TXT_FINANCIER_NAME": "STATE BANK OF SAURASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 2048,
      "TXT_FINANCIER_NAME": "UNITED BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 2049,
      "TXT_FINANCIER_NAME": "THE JALANDHAR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2050,
      "TXT_FINANCIER_NAME": "PURI GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 2051,
      "TXT_FINANCIER_NAME": "GHAZIABAD ZILA SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 2052,
      "TXT_FINANCIER_NAME": "THE TAMILNADU INDUSTRIAL COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2053,
      "TXT_FINANCIER_NAME": "SAMPADA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2054,
      "TXT_FINANCIER_NAME": "THE KOTTAYAM DISTRICT COOPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 2055,
      "TXT_FINANCIER_NAME": "THE SINGBHUM DISTRICT CENTRAL CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2056,
      "TXT_FINANCIER_NAME": "THE SURAT DISTRICT COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2057,
      "TXT_FINANCIER_NAME": "SHREE RAJKOT DISTRICT COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2058,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2059,
      "TXT_FINANCIER_NAME": "SOUTH MALABAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2060,
      "TXT_FINANCIER_NAME": "BIHAR CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2061,
      "TXT_FINANCIER_NAME": "THE PALAKKAD DISTRICT COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2062,
      "TXT_FINANCIER_NAME": "NASHIK DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2063,
      "TXT_FINANCIER_NAME": "COASTAL LOCAL AREA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2064,
      "TXT_FINANCIER_NAME": "THIRUVANANTHAPURAM DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2065,
      "TXT_FINANCIER_NAME": "VAYALAR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 2066,
      "TXT_FINANCIER_NAME": "THE BARMER CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2067,
      "TXT_FINANCIER_NAME": "ZILA SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2068,
      "TXT_FINANCIER_NAME": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 2069,
      "TXT_FINANCIER_NAME": "KOZHIKODE DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2195,
      "TXT_FINANCIER_NAME": "WARDHA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2214,
      "TXT_FINANCIER_NAME": "CALICUT CITY SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2173,
      "TXT_FINANCIER_NAME": "PRAVARA PEOPLE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2193,
      "TXT_FINANCIER_NAME": "JIND CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2166,
      "TXT_FINANCIER_NAME": "C K P CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2184,
      "TXT_FINANCIER_NAME": "GONDIA DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2187,
      "TXT_FINANCIER_NAME": "SATPURA NARMADA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2201,
      "TXT_FINANCIER_NAME": "JANASEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2204,
      "TXT_FINANCIER_NAME": "KANNUR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2168,
      "TXT_FINANCIER_NAME": "BILASPUR RAIPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2202,
      "TXT_FINANCIER_NAME": "PANIPAT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2167,
      "TXT_FINANCIER_NAME": "SAGAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2189,
      "TXT_FINANCIER_NAME": "NKGSB CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2169,
      "TXT_FINANCIER_NAME": "KKUMBHAGOM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2170,
      "TXT_FINANCIER_NAME": "GWALIOR DATIA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2171,
      "TXT_FINANCIER_NAME": "SOUTH CANARA DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2172,
      "TXT_FINANCIER_NAME": "THE AMRAVATI DISTRICT CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2175,
      "TXT_FINANCIER_NAME": "THE GURGAON CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2176,
      "TXT_FINANCIER_NAME": "COIMBATORE DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2178,
      "TXT_FINANCIER_NAME": "AMRITSAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2179,
      "TXT_FINANCIER_NAME": "KOLHAPUR DISTRICT CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2180,
      "TXT_FINANCIER_NAME": "CENTURION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2182,
      "TXT_FINANCIER_NAME": "INDUSTRIAL DEVELOPMENT BANK OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 2183,
      "TXT_FINANCIER_NAME": "AKOLA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2185,
      "TXT_FINANCIER_NAME": "SIKAR KENDRIYA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2186,
      "TXT_FINANCIER_NAME": "MUZAFFARNAGAR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2190,
      "TXT_FINANCIER_NAME": "CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2196,
      "TXT_FINANCIER_NAME": "UDAIPUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2198,
      "TXT_FINANCIER_NAME": "PATIALA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2200,
      "TXT_FINANCIER_NAME": "DUNGARPUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2165,
      "TXT_FINANCIER_NAME": "MUZAFFARNAGAR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2191,
      "TXT_FINANCIER_NAME": "ALIGARH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2192,
      "TXT_FINANCIER_NAME": "KOLLAM DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2194,
      "TXT_FINANCIER_NAME": "JALNA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2203,
      "TXT_FINANCIER_NAME": "ERNAKULAM DT CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2205,
      "TXT_FINANCIER_NAME": "FARRUKHABAD GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2206,
      "TXT_FINANCIER_NAME": "FIROZABAD DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2207,
      "TXT_FINANCIER_NAME": "SUBHADA LOCAL AREA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2208,
      "TXT_FINANCIER_NAME": "VEERASHAIVA CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2209,
      "TXT_FINANCIER_NAME": "KODIYATHOOR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2210,
      "TXT_FINANCIER_NAME": "ANJARAKANDY FARMERS SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2211,
      "TXT_FINANCIER_NAME": "SARVA U P GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2212,
      "TXT_FINANCIER_NAME": "BULDANA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 2213,
      "TXT_FINANCIER_NAME": "AXIS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2216,
      "TXT_FINANCIER_NAME": "KANNUR TOWN SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2222,
      "TXT_FINANCIER_NAME": "KAPURTHALA CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2226,
      "TXT_FINANCIER_NAME": "SARJERAODADA NAIK SHIRALA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2227,
      "TXT_FINANCIER_NAME": "CHOUNDESHWARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2263,
      "TXT_FINANCIER_NAME": "KEEZHALLUR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2264,
      "TXT_FINANCIER_NAME": "BULDANA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2272,
      "TXT_FINANCIER_NAME": "MGB GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2280,
      "TXT_FINANCIER_NAME": "RANCHI KHUNTI CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2281,
      "TXT_FINANCIER_NAME": "BURDWAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2282,
      "TXT_FINANCIER_NAME": "KEONJHAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2289,
      "TXT_FINANCIER_NAME": "MANJALI SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2299,
      "TXT_FINANCIER_NAME": "TIRUCHIRAPALLI DT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2327,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH LAND DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 2352,
      "TXT_FINANCIER_NAME": "JSC VTB BANK"
    },
    {
      "NUM_FINANCIER_CD": 2361,
      "TXT_FINANCIER_NAME": "SHREE BANASKANTHA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2362,
      "TXT_FINANCIER_NAME": "KOTA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2370,
      "TXT_FINANCIER_NAME": "THE GADCHIROLI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2509,
      "TXT_FINANCIER_NAME": "AYARKUNNAM SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 2218,
      "TXT_FINANCIER_NAME": "LAND DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 2241,
      "TXT_FINANCIER_NAME": "GOPINATH PATIL PARSIK JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2246,
      "TXT_FINANCIER_NAME": "LAKSHMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2247,
      "TXT_FINANCIER_NAME": "RAJGUUNAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2271,
      "TXT_FINANCIER_NAME": "R S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2275,
      "TXT_FINANCIER_NAME": "CHHINDWARA SEONI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2286,
      "TXT_FINANCIER_NAME": "TELLICHERRY CO OP TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2287,
      "TXT_FINANCIER_NAME": "THE PANCHKULA CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2328,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2334,
      "TXT_FINANCIER_NAME": "THE GULBARGA DISTRICT CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2335,
      "TXT_FINANCIER_NAME": "LOKMANGAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2337,
      "TXT_FINANCIER_NAME": "SHANKARRAO MOHITE PATIL SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2340,
      "TXT_FINANCIER_NAME": "UNITED OVERSEAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2343,
      "TXT_FINANCIER_NAME": "THE NAWANSHAHR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2344,
      "TXT_FINANCIER_NAME": "WOMENS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2347,
      "TXT_FINANCIER_NAME": "PRAGATI MAHILA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2351,
      "TXT_FINANCIER_NAME": "FIRSTRAND BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2355,
      "TXT_FINANCIER_NAME": "THE SALEM DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2356,
      "TXT_FINANCIER_NAME": "THE JALGAON DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2113,
      "TXT_FINANCIER_NAME": "THE JAMNAGAR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2118,
      "TXT_FINANCIER_NAME": "SANGLI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2122,
      "TXT_FINANCIER_NAME": "SAHAYOG URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2126,
      "TXT_FINANCIER_NAME": "VADGAON URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2131,
      "TXT_FINANCIER_NAME": "RAMPUR ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2135,
      "TXT_FINANCIER_NAME": "HOOGHLY DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2473,
      "TXT_FINANCIER_NAME": "FINGROWTH COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2219,
      "TXT_FINANCIER_NAME": "THRISSUR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2230,
      "TXT_FINANCIER_NAME": "PONANI CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2236,
      "TXT_FINANCIER_NAME": "BURDWAN CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2255,
      "TXT_FINANCIER_NAME": "RATNAGIRI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2256,
      "TXT_FINANCIER_NAME": "SHREE NARAYAN GURU CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2277,
      "TXT_FINANCIER_NAME": "JHAJJAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2278,
      "TXT_FINANCIER_NAME": "CUTTACK CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2283,
      "TXT_FINANCIER_NAME": "SOLAPUR DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2296,
      "TXT_FINANCIER_NAME": "THE ALAKODE SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2303,
      "TXT_FINANCIER_NAME": "CHAMBAL KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2304,
      "TXT_FINANCIER_NAME": "KARNALA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2331,
      "TXT_FINANCIER_NAME": "CHAVAKKAD SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2332,
      "TXT_FINANCIER_NAME": "SINDHUDURG DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2345,
      "TXT_FINANCIER_NAME": "MAPUCA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2346,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2358,
      "TXT_FINANCIER_NAME": "MADURAI DISTRICT CENTRAL CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2217,
      "TXT_FINANCIER_NAME": "JAMMU CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2225,
      "TXT_FINANCIER_NAME": "MORADABAD ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2232,
      "TXT_FINANCIER_NAME": "BEED DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2234,
      "TXT_FINANCIER_NAME": "REWARI CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2238,
      "TXT_FINANCIER_NAME": "ERNAKULAM DISTRICT COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 2239,
      "TXT_FINANCIER_NAME": "OCHANTHURUTH SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2240,
      "TXT_FINANCIER_NAME": "NAGAUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2242,
      "TXT_FINANCIER_NAME": "BANGALORE DISTRICT AND BANGALORE RURAL DISTRICT CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2243,
      "TXT_FINANCIER_NAME": "SOLAPUR NAGARI AUDYOGIK SAHAKARI BANK NIY"
    },
    {
      "NUM_FINANCIER_CD": 2244,
      "TXT_FINANCIER_NAME": "BHATINDA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2249,
      "TXT_FINANCIER_NAME": "PAYYANUR CO OP RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2258,
      "TXT_FINANCIER_NAME": "DANDA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 2265,
      "TXT_FINANCIER_NAME": "PANNIYANNUR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2269,
      "TXT_FINANCIER_NAME": "VYAVSAYIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2270,
      "TXT_FINANCIER_NAME": "RATANCHAND SHAH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2274,
      "TXT_FINANCIER_NAME": "LUDHIANA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2284,
      "TXT_FINANCIER_NAME": "SHRI GANESH SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 2291,
      "TXT_FINANCIER_NAME": "ALLAHABAD U P GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2292,
      "TXT_FINANCIER_NAME": "OSMANABAD DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2293,
      "TXT_FINANCIER_NAME": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 2297,
      "TXT_FINANCIER_NAME": "JALORE CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2302,
      "TXT_FINANCIER_NAME": "JANTA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2308,
      "TXT_FINANCIER_NAME": "THE HINDUSTAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2312,
      "TXT_FINANCIER_NAME": "THE KOLACHERI SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2316,
      "TXT_FINANCIER_NAME": "THE CKP CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2318,
      "TXT_FINANCIER_NAME": "GLOBAL TRUST BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2320,
      "TXT_FINANCIER_NAME": "THE DHARMAPURI DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2325,
      "TXT_FINANCIER_NAME": "KARTHEDOM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2336,
      "TXT_FINANCIER_NAME": "JALGAON DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2339,
      "TXT_FINANCIER_NAME": "THANE JILHA MADHYWARTI SARKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 2342,
      "TXT_FINANCIER_NAME": "THE TAMILNADU STATE APEX CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2369,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2197,
      "TXT_FINANCIER_NAME": "SUNDARGARH DISCRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2174,
      "TXT_FINANCIER_NAME": "BIZDIRECT"
    },
    {
      "NUM_FINANCIER_CD": 2177,
      "TXT_FINANCIER_NAME": "HINDON GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2181,
      "TXT_FINANCIER_NAME": "THE KAIRA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2188,
      "TXT_FINANCIER_NAME": "ALIGARH ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2199,
      "TXT_FINANCIER_NAME": "CHIKMAGALUR DISTRICT CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2215,
      "TXT_FINANCIER_NAME": "ROYAL BANK OF SCOTLAND"
    },
    {
      "NUM_FINANCIER_CD": 2220,
      "TXT_FINANCIER_NAME": "PARBHANI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2229,
      "TXT_FINANCIER_NAME": "THE SATARA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2233,
      "TXT_FINANCIER_NAME": "KHURDA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2248,
      "TXT_FINANCIER_NAME": "HOWRAH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2250,
      "TXT_FINANCIER_NAME": "PAPPINIVATTAM SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2257,
      "TXT_FINANCIER_NAME": "SHIVRAJ SAHAKARI PATSANSTHA MARYADIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 2262,
      "TXT_FINANCIER_NAME": "PUDUVAI BHARATHIAR GRAMA BANK"
    },
    {
      "NUM_FINANCIER_CD": 2267,
      "TXT_FINANCIER_NAME": "CHERUTHAZHAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2268,
      "TXT_FINANCIER_NAME": "DHANLAXMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2273,
      "TXT_FINANCIER_NAME": "SHIMOGA DIST CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2279,
      "TXT_FINANCIER_NAME": "HARYANA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2295,
      "TXT_FINANCIER_NAME": "PERUMBAVOOR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2300,
      "TXT_FINANCIER_NAME": "PUNE PEOPLES CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 2305,
      "TXT_FINANCIER_NAME": "THE ICHALKARANJI MARCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2306,
      "TXT_FINANCIER_NAME": "THE KONARK URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2309,
      "TXT_FINANCIER_NAME": "THE PADIYOTCHAL SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2311,
      "TXT_FINANCIER_NAME": "NAGRIK SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 2313,
      "TXT_FINANCIER_NAME": "GANGA YAMUNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2315,
      "TXT_FINANCIER_NAME": "ALAPPUZHA DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2317,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2329,
      "TXT_FINANCIER_NAME": "THE AKOLA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2338,
      "TXT_FINANCIER_NAME": "DHULE AND NANDURBAR DISTRICT CENTRAL SHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2348,
      "TXT_FINANCIER_NAME": "VYAVSAYAK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 2373,
      "TXT_FINANCIER_NAME": "UTTRANCHAL STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2498,
      "TXT_FINANCIER_NAME": "KERALA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2223,
      "TXT_FINANCIER_NAME": "ALLAHABAD KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2228,
      "TXT_FINANCIER_NAME": "COL RD NIKAM SAINIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2231,
      "TXT_FINANCIER_NAME": "THE KOZHIKODE DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2237,
      "TXT_FINANCIER_NAME": "MAHARASHTRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2245,
      "TXT_FINANCIER_NAME": "SHREE SIDDHIVINAYAK NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2252,
      "TXT_FINANCIER_NAME": "CHIRAKKAL SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2253,
      "TXT_FINANCIER_NAME": "SAKTHI PRIMARY AGRICULTURAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2254,
      "TXT_FINANCIER_NAME": "SIROHI CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2260,
      "TXT_FINANCIER_NAME": "S A S NAGAR CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2261,
      "TXT_FINANCIER_NAME": "AMRELI JILLA MADHYASTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2266,
      "TXT_FINANCIER_NAME": "EDATHAL SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2276,
      "TXT_FINANCIER_NAME": "VALAPATTANAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2285,
      "TXT_FINANCIER_NAME": "BHIWANI CENTAL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 2288,
      "TXT_FINANCIER_NAME": "THE MANJALY SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2290,
      "TXT_FINANCIER_NAME": "SHRIRAM EQUIPMENT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 2298,
      "TXT_FINANCIER_NAME": "UDHAM SINGH NAGAR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2301,
      "TXT_FINANCIER_NAME": "ROPAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2310,
      "TXT_FINANCIER_NAME": "AMBICA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2321,
      "TXT_FINANCIER_NAME": "PRIME LAND DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 2322,
      "TXT_FINANCIER_NAME": "MANNAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2323,
      "TXT_FINANCIER_NAME": "THE NEW AGRA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2324,
      "TXT_FINANCIER_NAME": "WAINGANGA KRISHNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2357,
      "TXT_FINANCIER_NAME": "ANZ GRINDLAYS BANK"
    },
    {
      "NUM_FINANCIER_CD": 2360,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2363,
      "TXT_FINANCIER_NAME": "PANCHMAHAL VADODARA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2368,
      "TXT_FINANCIER_NAME": "WOORI BANK"
    },
    {
      "NUM_FINANCIER_CD": 2489,
      "TXT_FINANCIER_NAME": "ODISHA GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 2221,
      "TXT_FINANCIER_NAME": "THENMELPAKKAM PRIMARY AGRICULTURAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2224,
      "TXT_FINANCIER_NAME": "MATTANCHERRY SARVAJANIK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2235,
      "TXT_FINANCIER_NAME": "DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2251,
      "TXT_FINANCIER_NAME": "RESERVE BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 2259,
      "TXT_FINANCIER_NAME": "KACHCHH DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2294,
      "TXT_FINANCIER_NAME": "PRATAPGARH KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2307,
      "TXT_FINANCIER_NAME": "PRAGJYOTISH GAONLIA BANK"
    },
    {
      "NUM_FINANCIER_CD": 2314,
      "TXT_FINANCIER_NAME": "DEVI GAYATRI CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2319,
      "TXT_FINANCIER_NAME": "THE JOGINDRA CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 2326,
      "TXT_FINANCIER_NAME": "JANTA COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2330,
      "TXT_FINANCIER_NAME": "CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2333,
      "TXT_FINANCIER_NAME": "JIVAJI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2341,
      "TXT_FINANCIER_NAME": "THE TARAN TARAN CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2349,
      "TXT_FINANCIER_NAME": "AMERICAN EXPRESS BANKING CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 2350,
      "TXT_FINANCIER_NAME": "COMMON WEALTH BANK OF AUSTRALIA"
    },
    {
      "NUM_FINANCIER_CD": 2364,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2372,
      "TXT_FINANCIER_NAME": "STATE BANK OF SIKKIM"
    },
    {
      "NUM_FINANCIER_CD": 2380,
      "TXT_FINANCIER_NAME": "BELGAUM DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2527,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIME LTD"
    },
    {
      "NUM_FINANCIER_CD": 2528,
      "TXT_FINANCIER_NAME": "IDFC BANK"
    },
    {
      "NUM_FINANCIER_CD": 2534,
      "TXT_FINANCIER_NAME": "BANDHAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2367,
      "TXT_FINANCIER_NAME": "NEELKANTH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2377,
      "TXT_FINANCIER_NAME": "SAMARTH URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 2383,
      "TXT_FINANCIER_NAME": "D Y PATIL SAHAKARI BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 2359,
      "TXT_FINANCIER_NAME": "THE IDUKKI DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2366,
      "TXT_FINANCIER_NAME": "SWATANTRYA SENANI LATE SHRIPAL ALASE KAKA KURUNDWAD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2375,
      "TXT_FINANCIER_NAME": "UTTARANCHAL GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 2378,
      "TXT_FINANCIER_NAME": "THE BANSWARA CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2379,
      "TXT_FINANCIER_NAME": "THE BELGAUM DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2354,
      "TXT_FINANCIER_NAME": "UNITED WESTERN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2382,
      "TXT_FINANCIER_NAME": "RAMESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2365,
      "TXT_FINANCIER_NAME": "THE BHARUCH DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2371,
      "TXT_FINANCIER_NAME": "SHRI LAXMIKRUPA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2376,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA NAVANAGAR"
    },
    {
      "NUM_FINANCIER_CD": 2381,
      "TXT_FINANCIER_NAME": "THE GUJARAT STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 2353,
      "TXT_FINANCIER_NAME": "THE DEVELOPMENT BANK OF SINGAPORE LTD"
    },
    {
      "NUM_FINANCIER_CD": 2374,
      "TXT_FINANCIER_NAME": "UTTRANCHAL STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4187,
      "TXT_FINANCIER_NAME": "ETTUMANOOR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4188,
      "TXT_FINANCIER_NAME": "PADMASHRI GRAMIN BIGAR SHETI S.P.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4189,
      "TXT_FINANCIER_NAME": "SURAT DISTRICT CO.OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 4190,
      "TXT_FINANCIER_NAME": "SURAT DIST CO.OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4191,
      "TXT_FINANCIER_NAME": "NORTH KANARA G.S.B.CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4192,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPEL S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4193,
      "TXT_FINANCIER_NAME": "N.H.D.C. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4194,
      "TXT_FINANCIER_NAME": "STATE BANK OF CRABANCORE"
    },
    {
      "NUM_FINANCIER_CD": 4195,
      "TXT_FINANCIER_NAME": "SHRI SHANI SAMARTHA GRAMIN B.S.S.P.S.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4196,
      "TXT_FINANCIER_NAME": "KATTAPPANA SERVICE CO OPERATIVE BANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 4197,
      "TXT_FINANCIER_NAME": "SRI KRISHNA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4198,
      "TXT_FINANCIER_NAME": "VAIKOM TOWN SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4199,
      "TXT_FINANCIER_NAME": "THE BANGALORE DISTRICT AND BANGALORE RURAL DISTRICT COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4200,
      "TXT_FINANCIER_NAME": "NANITAL DISTT. COPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4201,
      "TXT_FINANCIER_NAME": "EDAPPALLY SERVICE CO OP: BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4202,
      "TXT_FINANCIER_NAME": "VIDARBHA MERCHANT URBAN CO.BANK"
    },
    {
      "NUM_FINANCIER_CD": 4203,
      "TXT_FINANCIER_NAME": "THE UTTIRAMERUR PRIMARY AGRICULTURAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4204,
      "TXT_FINANCIER_NAME": "SAMYUT KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4205,
      "TXT_FINANCIER_NAME": "SUPRABHATA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4206,
      "TXT_FINANCIER_NAME": "DE-NOVO LEASING & INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 4207,
      "TXT_FINANCIER_NAME": "Kalpataru Hire Purchase & Leasing Pvt Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4208,
      "TXT_FINANCIER_NAME": "Lakhimi Gaonlia Bank"
    },
    {
      "NUM_FINANCIER_CD": 4209,
      "TXT_FINANCIER_NAME": "EX- SERVICEMEN HIRE PURCHASE FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4210,
      "TXT_FINANCIER_NAME": "EXCELLENT FIN - INVEST LTD"
    },
    {
      "NUM_FINANCIER_CD": 4211,
      "TXT_FINANCIER_NAME": "FAIR DEAL GEN. FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 4212,
      "TXT_FINANCIER_NAME": "GILL & VIRK FINANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4213,
      "TXT_FINANCIER_NAME": "GOETZE (INDIA) FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 4214,
      "TXT_FINANCIER_NAME": "THE JINTUR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4215,
      "TXT_FINANCIER_NAME": "MAHINDRA &ampMAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 4216,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4217,
      "TXT_FINANCIER_NAME": "THE BARODA CITY CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4218,
      "TXT_FINANCIER_NAME": "CENTRAL CO-OP BANK, YAMUNANAGAR"
    },
    {
      "NUM_FINANCIER_CD": 4219,
      "TXT_FINANCIER_NAME": "THE PEOPLES CO-OP-BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4220,
      "TXT_FINANCIER_NAME": "JODHPUR NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4221,
      "TXT_FINANCIER_NAME": "THE C.K.P CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4222,
      "TXT_FINANCIER_NAME": "CHHATRASAL GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4223,
      "TXT_FINANCIER_NAME": "GUJARAT BACKWARD CLASSES DEVELOPMENT CORPORATION - GANDHINAGAR"
    },
    {
      "NUM_FINANCIER_CD": 4224,
      "TXT_FINANCIER_NAME": "B P C L"
    },
    {
      "NUM_FINANCIER_CD": 4225,
      "TXT_FINANCIER_NAME": "B.O.B."
    },
    {
      "NUM_FINANCIER_CD": 4226,
      "TXT_FINANCIER_NAME": "THE N.K.G.S.B.CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4227,
      "TXT_FINANCIER_NAME": "RLBK Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 4228,
      "TXT_FINANCIER_NAME": "R.L.B.K. GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4229,
      "TXT_FINANCIER_NAME": "JODHPUR NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 4230,
      "TXT_FINANCIER_NAME": "JODHPUR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4231,
      "TXT_FINANCIER_NAME": "FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 4232,
      "TXT_FINANCIER_NAME": "BOTH"
    },
    {
      "NUM_FINANCIER_CD": 4233,
      "TXT_FINANCIER_NAME": "USJ BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4234,
      "TXT_FINANCIER_NAME": "GENERAL INSURANCE EMPLOYEES CO-OPERATIVE CREDTI SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4235,
      "TXT_FINANCIER_NAME": "THE MEMON CO.OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4236,
      "TXT_FINANCIER_NAME": "A. J. FINANCE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4237,
      "TXT_FINANCIER_NAME": "THE AHMMEDABAD DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4238,
      "TXT_FINANCIER_NAME": "ADD. CHIEF ENGG. -TPS SIKKA"
    },
    {
      "NUM_FINANCIER_CD": 4239,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP. CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4240,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4241,
      "TXT_FINANCIER_NAME": "SRI GURUKULA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 4242,
      "TXT_FINANCIER_NAME": "H B HALLI TALUK SAHAKARA KRUSHI AND GRAMEENA ABHIVRUDHI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 4243,
      "TXT_FINANCIER_NAME": "M/S ING VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4244,
      "TXT_FINANCIER_NAME": "LAXMI GENERAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4245,
      "TXT_FINANCIER_NAME": "NATIONAL SEEDS CORPORATION BANK"
    },
    {
      "NUM_FINANCIER_CD": 4246,
      "TXT_FINANCIER_NAME": "PERINGANDOOR SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4247,
      "TXT_FINANCIER_NAME": "THE PERINGANDOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4248,
      "TXT_FINANCIER_NAME": "WEST BENGAL STATE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4249,
      "TXT_FINANCIER_NAME": "THE WOMENS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4250,
      "TXT_FINANCIER_NAME": "SHREE BHAILALBHAI CONTRACTOR SMARAK CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4251,
      "TXT_FINANCIER_NAME": "GMAC 20TH CENT LTD."
    },
    {
      "NUM_FINANCIER_CD": 4252,
      "TXT_FINANCIER_NAME": "SHREENATH CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4253,
      "TXT_FINANCIER_NAME": "THE RATNAKER BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4254,
      "TXT_FINANCIER_NAME": "SHREE GANESH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4012,
      "TXT_FINANCIER_NAME": "A.P STATE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4013,
      "TXT_FINANCIER_NAME": "LAXMIBAI MAHILA NAGARIK SAHKARI BANK MYDT,"
    },
    {
      "NUM_FINANCIER_CD": 4014,
      "TXT_FINANCIER_NAME": "LAXMIBAI MAHILA NAGARIK SAHKARI BANK MYTD"
    },
    {
      "NUM_FINANCIER_CD": 4015,
      "TXT_FINANCIER_NAME": "THE SUVIKAS PEOPLE S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4016,
      "TXT_FINANCIER_NAME": "PUNJAB TRACTOR LTD"
    },
    {
      "NUM_FINANCIER_CD": 4017,
      "TXT_FINANCIER_NAME": "ZORASTRIAN"
    },
    {
      "NUM_FINANCIER_CD": 4018,
      "TXT_FINANCIER_NAME": "BALASINOR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4019,
      "TXT_FINANCIER_NAME": "JANA SEVA SAHAKARI BANK (BORIVALI) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4020,
      "TXT_FINANCIER_NAME": "THE KATTOOR SERIVCE C B LTD NO. 426"
    },
    {
      "NUM_FINANCIER_CD": 4021,
      "TXT_FINANCIER_NAME": "THE GODAVRI ARBAN CO-OP.BANK."
    },
    {
      "NUM_FINANCIER_CD": 4022,
      "TXT_FINANCIER_NAME": "BHANDARA GRAMIN BANK."
    },
    {
      "NUM_FINANCIER_CD": 4023,
      "TXT_FINANCIER_NAME": "SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4024,
      "TXT_FINANCIER_NAME": "KAMREJ VIBHAG NAGRIK BANK"
    },
    {
      "NUM_FINANCIER_CD": 4025,
      "TXT_FINANCIER_NAME": "THE A.P.MAHESH CO-OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4026,
      "TXT_FINANCIER_NAME": "CMD, ENGINEERS INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4027,
      "TXT_FINANCIER_NAME": "TRANSAFE SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4028,
      "TXT_FINANCIER_NAME": "THIRUMAGAL AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 4029,
      "TXT_FINANCIER_NAME": "HIGHGROW ENTERPRISES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4030,
      "TXT_FINANCIER_NAME": "Ramakrishnapur Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4031,
      "TXT_FINANCIER_NAME": "ARCOT FINANCE & AGENCY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4032,
      "TXT_FINANCIER_NAME": "ARUNA LEASING & FINANCE (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4033,
      "TXT_FINANCIER_NAME": "B.D.CREDITS PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4034,
      "TXT_FINANCIER_NAME": "B.R.M. LEASE & CREDIT PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 4035,
      "TXT_FINANCIER_NAME": "SATGURU SAKH SAHKARITA MYDT DHAR"
    },
    {
      "NUM_FINANCIER_CD": 4036,
      "TXT_FINANCIER_NAME": "A P POLLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 4037,
      "TXT_FINANCIER_NAME": "S.B.I.KASTURBA HOSPITAL BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 4038,
      "TXT_FINANCIER_NAME": "KANOOR PATHAR PAT SANSTHA.,AHMEDNAGAR"
    },
    {
      "NUM_FINANCIER_CD": 4039,
      "TXT_FINANCIER_NAME": "H.P.C.L."
    },
    {
      "NUM_FINANCIER_CD": 4040,
      "TXT_FINANCIER_NAME": "AP MAHESH CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4041,
      "TXT_FINANCIER_NAME": "AP MAHESH CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4042,
      "TXT_FINANCIER_NAME": "GMAC TCFL LTD"
    },
    {
      "NUM_FINANCIER_CD": 4043,
      "TXT_FINANCIER_NAME": "G MAC TCFC"
    },
    {
      "NUM_FINANCIER_CD": 4044,
      "TXT_FINANCIER_NAME": "G-MAC TCFC FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4045,
      "TXT_FINANCIER_NAME": "N.D.R.I."
    },
    {
      "NUM_FINANCIER_CD": 4046,
      "TXT_FINANCIER_NAME": "KANDLA PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 4047,
      "TXT_FINANCIER_NAME": "ACCERDIT DISTRIBUTORS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4048,
      "TXT_FINANCIER_NAME": "JANKALYAN NAG.SAH.PATH SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 4049,
      "TXT_FINANCIER_NAME": "JANKALYAN NAG. SAH.PATH SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 4050,
      "TXT_FINANCIER_NAME": "Mukesh General Finance Company"
    },
    {
      "NUM_FINANCIER_CD": 4051,
      "TXT_FINANCIER_NAME": "JAMNAGAR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4052,
      "TXT_FINANCIER_NAME": "KAMALCHAND JAIN ,CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 4053,
      "TXT_FINANCIER_NAME": "THE KARNAVATI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4054,
      "TXT_FINANCIER_NAME": "BARDHAMAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4055,
      "TXT_FINANCIER_NAME": "URBAN DEVELOPMENT CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4056,
      "TXT_FINANCIER_NAME": "THE DELHI NAGRIK SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4057,
      "TXT_FINANCIER_NAME": "U.H.B.V.N."
    },
    {
      "NUM_FINANCIER_CD": 4058,
      "TXT_FINANCIER_NAME": "RKGB"
    },
    {
      "NUM_FINANCIER_CD": 4059,
      "TXT_FINANCIER_NAME": "CANARA SARASWATI CREDIT SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 4060,
      "TXT_FINANCIER_NAME": "GVM LEASING AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 4061,
      "TXT_FINANCIER_NAME": "SHRIPATRAODADA SAHAKARI BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 4062,
      "TXT_FINANCIER_NAME": "BRAHMAVART COMMERCIAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4063,
      "TXT_FINANCIER_NAME": "ANDARSUL URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4064,
      "TXT_FINANCIER_NAME": "GUJRAT COMMERCIAL CO-0P. BANK LTD., INDORE"
    },
    {
      "NUM_FINANCIER_CD": 4065,
      "TXT_FINANCIER_NAME": "THE KUKARWADA NAGARIK SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4066,
      "TXT_FINANCIER_NAME": "SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4067,
      "TXT_FINANCIER_NAME": "TMB"
    },
    {
      "NUM_FINANCIER_CD": 4068,
      "TXT_FINANCIER_NAME": "SHUBHLAKSHMI MAHILA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4069,
      "TXT_FINANCIER_NAME": "BEHR INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4070,
      "TXT_FINANCIER_NAME": "THE SANLGI DIST CENTARL CO-OP BANK LTD,SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 4071,
      "TXT_FINANCIER_NAME": "THE SANGLI DIST CENTRAL CO-OP BANK LTD,SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 4072,
      "TXT_FINANCIER_NAME": "THE SUVIKAS PEOPLE S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4073,
      "TXT_FINANCIER_NAME": "THE VIKAS PEOPLES CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4074,
      "TXT_FINANCIER_NAME": "KHATTI CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4075,
      "TXT_FINANCIER_NAME": "KUZHUPILLY"
    },
    {
      "NUM_FINANCIER_CD": 4076,
      "TXT_FINANCIER_NAME": "KUZHUPPILLY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4077,
      "TXT_FINANCIER_NAME": "Vailhongal urban Co-operative bank limited"
    },
    {
      "NUM_FINANCIER_CD": 4078,
      "TXT_FINANCIER_NAME": "ALVI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4079,
      "TXT_FINANCIER_NAME": "PANAVALLY VILLAGE SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4080,
      "TXT_FINANCIER_NAME": "THE DADHOL G.S. CO-OP SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4081,
      "TXT_FINANCIER_NAME": "AMRITVAHINI URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4082,
      "TXT_FINANCIER_NAME": "DINKAR ABA NAGARI SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4083,
      "TXT_FINANCIER_NAME": "SHRI KRISHNA CREDIT CO SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4084,
      "TXT_FINANCIER_NAME": "SARVODAYA COMERCIAL CO-OPERATIVE BANK LTD.MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 4085,
      "TXT_FINANCIER_NAME": "THE MUNCIPAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4086,
      "TXT_FINANCIER_NAME": "LOKVIKAS"
    },
    {
      "NUM_FINANCIER_CD": 4087,
      "TXT_FINANCIER_NAME": "THE JANALAXIMI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4088,
      "TXT_FINANCIER_NAME": "THE SIRSI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4089,
      "TXT_FINANCIER_NAME": "SANGALI URBAN CO-OP. BANK LTD.,SANGALI"
    },
    {
      "NUM_FINANCIER_CD": 4090,
      "TXT_FINANCIER_NAME": "P G B, NURPUR"
    },
    {
      "NUM_FINANCIER_CD": 4091,
      "TXT_FINANCIER_NAME": "SAI PRIYANKA AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 4092,
      "TXT_FINANCIER_NAME": "SNVRP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4093,
      "TXT_FINANCIER_NAME": "KALLARA SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4094,
      "TXT_FINANCIER_NAME": "M VISVESVARAYA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4095,
      "TXT_FINANCIER_NAME": "MAHARSHTRA CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4096,
      "TXT_FINANCIER_NAME": "CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4097,
      "TXT_FINANCIER_NAME": "MATHURA DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4098,
      "TXT_FINANCIER_NAME": "MARADU SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4099,
      "TXT_FINANCIER_NAME": "KOSI KSHETRIYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4100,
      "TXT_FINANCIER_NAME": "SAHYADRI GRAMIN BANK, BRANCH ANAVATTI-571413"
    },
    {
      "NUM_FINANCIER_CD": 4101,
      "TXT_FINANCIER_NAME": "AMRELI NAGARIK SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4102,
      "TXT_FINANCIER_NAME": "SHREE BHAILALBHAI CONTRACTOR SMARAK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4103,
      "TXT_FINANCIER_NAME": "G.E.B."
    },
    {
      "NUM_FINANCIER_CD": 4104,
      "TXT_FINANCIER_NAME": "THE DADASAHEB RAWAL CO-OP BANK OF DONDAICHA LTD."
    },
    {
      "NUM_FINANCIER_CD": 4105,
      "TXT_FINANCIER_NAME": "THE PEN CO-OP URAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4106,
      "TXT_FINANCIER_NAME": "THE DADASAHEB RAWAL CO.OP. BANK OF DONDAICHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4107,
      "TXT_FINANCIER_NAME": "THE LAXMI VILAS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4108,
      "TXT_FINANCIER_NAME": "Thazhekad Service Co-Op:Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 4109,
      "TXT_FINANCIER_NAME": "N K G S B CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4110,
      "TXT_FINANCIER_NAME": "Jain Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4111,
      "TXT_FINANCIER_NAME": "THE MAHEMADAVAD URBAN PEOPLES CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4112,
      "TXT_FINANCIER_NAME": "OLLUR FINANCE AND HIRE PURCHASE COMPANY P LTD"
    },
    {
      "NUM_FINANCIER_CD": 4113,
      "TXT_FINANCIER_NAME": "THE MODEL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4114,
      "TXT_FINANCIER_NAME": "MICRO NOVA IMPEX PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4115,
      "TXT_FINANCIER_NAME": "Sri Arihant Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4116,
      "TXT_FINANCIER_NAME": "BATHLA AND CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4117,
      "TXT_FINANCIER_NAME": "BRR LEAFIN PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4118,
      "TXT_FINANCIER_NAME": "CHARTERED LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4119,
      "TXT_FINANCIER_NAME": "CHENAI FINANCECO LTD."
    },
    {
      "NUM_FINANCIER_CD": 4120,
      "TXT_FINANCIER_NAME": "DANI TRADING & INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 4121,
      "TXT_FINANCIER_NAME": "PARAS JAIN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 3600,
      "TXT_FINANCIER_NAME": "TELANGANA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4122,
      "TXT_FINANCIER_NAME": "THE SIBSAGAR DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4123,
      "TXT_FINANCIER_NAME": "SULAIMANI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4124,
      "TXT_FINANCIER_NAME": "DR.PUNJABRAO DESHMUKH URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4125,
      "TXT_FINANCIER_NAME": "SHRI SWAMINARAYAN CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4126,
      "TXT_FINANCIER_NAME": "AOANI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4127,
      "TXT_FINANCIER_NAME": "BASTAR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4128,
      "TXT_FINANCIER_NAME": "SIDDHI CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4129,
      "TXT_FINANCIER_NAME": "COASTAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4130,
      "TXT_FINANCIER_NAME": "CHANDRAPUR GADCHIROLI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4131,
      "TXT_FINANCIER_NAME": "THE BARODA CITY CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4132,
      "TXT_FINANCIER_NAME": "BHAGAT URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4133,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4134,
      "TXT_FINANCIER_NAME": "LESSEE:LEASE PLAN INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4135,
      "TXT_FINANCIER_NAME": "THE SAURASHTRA CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4136,
      "TXT_FINANCIER_NAME": "SHREE BHARAT C-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4137,
      "TXT_FINANCIER_NAME": "VAISHALI URNAB CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4138,
      "TXT_FINANCIER_NAME": "M/S BHARTI TELEVENTURES LTD."
    },
    {
      "NUM_FINANCIER_CD": 4139,
      "TXT_FINANCIER_NAME": "THE AP MAHESH CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4140,
      "TXT_FINANCIER_NAME": "THE SARANGPUR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4141,
      "TXT_FINANCIER_NAME": "KALOL NSB LTD"
    },
    {
      "NUM_FINANCIER_CD": 4142,
      "TXT_FINANCIER_NAME": "NIDHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4143,
      "TXT_FINANCIER_NAME": "GOVT. OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 4144,
      "TXT_FINANCIER_NAME": "OIL & NATURAL GAS COMMISSIONED"
    },
    {
      "NUM_FINANCIER_CD": 4145,
      "TXT_FINANCIER_NAME": "NEW INDIA ASSURANCE CO.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4146,
      "TXT_FINANCIER_NAME": "UNNATI CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4147,
      "TXT_FINANCIER_NAME": "THE GANESH BANKOF KURUNDWAD"
    },
    {
      "NUM_FINANCIER_CD": 4148,
      "TXT_FINANCIER_NAME": "YAVATMAL GRAMIN BANK,"
    },
    {
      "NUM_FINANCIER_CD": 4149,
      "TXT_FINANCIER_NAME": "GURLHOSUR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4150,
      "TXT_FINANCIER_NAME": "THE DELHI CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4151,
      "TXT_FINANCIER_NAME": "THE UMERATH URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4152,
      "TXT_FINANCIER_NAME": "THE COONOOR CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4153,
      "TXT_FINANCIER_NAME": "THE RANUJ NAGARIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4154,
      "TXT_FINANCIER_NAME": "MAHILA VIKAS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4155,
      "TXT_FINANCIER_NAME": "RAJKOT NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4156,
      "TXT_FINANCIER_NAME": "OIL INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 4157,
      "TXT_FINANCIER_NAME": "GEORGE TOWN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4158,
      "TXT_FINANCIER_NAME": "JAIN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4159,
      "TXT_FINANCIER_NAME": "INDERPRASTHA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4160,
      "TXT_FINANCIER_NAME": "ACEC BANK"
    },
    {
      "NUM_FINANCIER_CD": 4161,
      "TXT_FINANCIER_NAME": "SHREE PARSHWANATH CO-OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 4162,
      "TXT_FINANCIER_NAME": "MAHILA URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4163,
      "TXT_FINANCIER_NAME": "M/S G MAC TCFC FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4164,
      "TXT_FINANCIER_NAME": "Thingalur Primary Agricultural Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 4165,
      "TXT_FINANCIER_NAME": "BILASPUR-RAIPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4166,
      "TXT_FINANCIER_NAME": "THE ANKLESHWARUDYOGNAGAR CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4167,
      "TXT_FINANCIER_NAME": "THE PANIPAT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4168,
      "TXT_FINANCIER_NAME": "PALAMU KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4169,
      "TXT_FINANCIER_NAME": "VAISH NAGARI SAH.PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 4170,
      "TXT_FINANCIER_NAME": "SHREE CHHANI NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4171,
      "TXT_FINANCIER_NAME": "SREE SUBRAMANYESWARA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4172,
      "TXT_FINANCIER_NAME": "THE HASTI COOP BANK LTD.DONDAICHA"
    },
    {
      "NUM_FINANCIER_CD": 4173,
      "TXT_FINANCIER_NAME": "AIE CORPORATIONS EMPLOYEES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4174,
      "TXT_FINANCIER_NAME": "AGASTI SANGAM SAHAKARI PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 4175,
      "TXT_FINANCIER_NAME": "DR D S AHER PATHSANSTHA KALWAN"
    },
    {
      "NUM_FINANCIER_CD": 4176,
      "TXT_FINANCIER_NAME": "THE CO OPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4177,
      "TXT_FINANCIER_NAME": "URVASHI MOTOR AND GEN FIN"
    },
    {
      "NUM_FINANCIER_CD": 4178,
      "TXT_FINANCIER_NAME": "THE THODUPUZHA SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4179,
      "TXT_FINANCIER_NAME": "THE CHAMPAKARA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4180,
      "TXT_FINANCIER_NAME": "AP STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4181,
      "TXT_FINANCIER_NAME": "ARYANAD SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4182,
      "TXT_FINANCIER_NAME": "THE PARUR CO-OP:BANK LTD;"
    },
    {
      "NUM_FINANCIER_CD": 4183,
      "TXT_FINANCIER_NAME": "THE KANJOOR SERVICE CO-OPERATIVE BANK LTD., NO.454"
    },
    {
      "NUM_FINANCIER_CD": 4184,
      "TXT_FINANCIER_NAME": "ASHOK LELYAND FINANCE A DIVISION OF INDUSIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 4185,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANDILE COP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4186,
      "TXT_FINANCIER_NAME": "THE HSBC"
    },
    {
      "NUM_FINANCIER_CD": 4359,
      "TXT_FINANCIER_NAME": "THE HARAYANA STATE COOPERATIVE APEX BANK"
    },
    {
      "NUM_FINANCIER_CD": 4360,
      "TXT_FINANCIER_NAME": "THE GRGAON CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4361,
      "TXT_FINANCIER_NAME": "THE BARODA TRADERS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4362,
      "TXT_FINANCIER_NAME": "THE NORTH KANAR G.S.B.CO-OP.BANK.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4363,
      "TXT_FINANCIER_NAME": "LG ELECTRONICS INDIA PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4364,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP. CENTRALBANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4365,
      "TXT_FINANCIER_NAME": "SURYA FIN COMPAMY"
    },
    {
      "NUM_FINANCIER_CD": 4366,
      "TXT_FINANCIER_NAME": "THE CHAPI NAGARIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4367,
      "TXT_FINANCIER_NAME": "THE FEROZPUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4368,
      "TXT_FINANCIER_NAME": "GURUVAYOOR CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4369,
      "TXT_FINANCIER_NAME": "SRI KANNIK PARAMESHWARI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4370,
      "TXT_FINANCIER_NAME": "JANAKALYAN CO.OP BANK LTD NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 4371,
      "TXT_FINANCIER_NAME": "UNITED BANK"
    },
    {
      "NUM_FINANCIER_CD": 4372,
      "TXT_FINANCIER_NAME": "PONANI CO-OP. URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4373,
      "TXT_FINANCIER_NAME": "PONNANI CO-OP. URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4374,
      "TXT_FINANCIER_NAME": "THE SATARA MERCHANTS COOPERATIVE BANK LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 4375,
      "TXT_FINANCIER_NAME": "THE KUTTAMASSERY SERVICE CO-OP: BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4376,
      "TXT_FINANCIER_NAME": "CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4377,
      "TXT_FINANCIER_NAME": "THE CITIZENS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4378,
      "TXT_FINANCIER_NAME": "VIJAYA SAHAKARI BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 4379,
      "TXT_FINANCIER_NAME": "SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 4380,
      "TXT_FINANCIER_NAME": "PRAWARA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4381,
      "TXT_FINANCIER_NAME": "JANSEVA CO-OP. BANK LTD, NASHIK."
    },
    {
      "NUM_FINANCIER_CD": 4382,
      "TXT_FINANCIER_NAME": "THE LIMDI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4383,
      "TXT_FINANCIER_NAME": "THE DOMBIVILI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4384,
      "TXT_FINANCIER_NAME": "CO-OP. AGRICULTURAL & RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 4385,
      "TXT_FINANCIER_NAME": "NATIONAL BANK OF AGRICULTURE AND RURAL DEPTT."
    },
    {
      "NUM_FINANCIER_CD": 4386,
      "TXT_FINANCIER_NAME": "NAGNATH BANK"
    },
    {
      "NUM_FINANCIER_CD": 4387,
      "TXT_FINANCIER_NAME": "BANGALORE DIST. & BANGALORE RURAL DIST. CO-OP. CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4388,
      "TXT_FINANCIER_NAME": "MAHINDRA&ampMAHINDRA FIN&ampSER.LTD"
    },
    {
      "NUM_FINANCIER_CD": 4389,
      "TXT_FINANCIER_NAME": "ALF A DIV.OF INDUSIND BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4390,
      "TXT_FINANCIER_NAME": "THE ALAPPUZHA DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4391,
      "TXT_FINANCIER_NAME": "THE PARUR MAHILA CO-OP: SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4392,
      "TXT_FINANCIER_NAME": "MALLAPPALLY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4393,
      "TXT_FINANCIER_NAME": "THE PAYYOLI CO OP URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4394,
      "TXT_FINANCIER_NAME": "SIDDA SHREE SAUHARDHAN PATTINA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4395,
      "TXT_FINANCIER_NAME": "WEST CHALAKUDY SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4396,
      "TXT_FINANCIER_NAME": "JU -JU FINANCE & LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 4397,
      "TXT_FINANCIER_NAME": "K. C. FINCAP PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4398,
      "TXT_FINANCIER_NAME": "K. R. T. FINANCE PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 4399,
      "TXT_FINANCIER_NAME": "K. S. CAPITAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 4400,
      "TXT_FINANCIER_NAME": "K.Z.LEASING AND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 4401,
      "TXT_FINANCIER_NAME": "KARIMNAGAR VIJAY HIRE PURCHASE & LEASING (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4402,
      "TXT_FINANCIER_NAME": "L.M.B. FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4403,
      "TXT_FINANCIER_NAME": "L.R.N. FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 4404,
      "TXT_FINANCIER_NAME": "LIBRA AUTO & GENERAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4405,
      "TXT_FINANCIER_NAME": "M.G.F. (INDIA) LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4406,
      "TXT_FINANCIER_NAME": "M.G.F. SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4407,
      "TXT_FINANCIER_NAME": "MAGGY FINANCE (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4408,
      "TXT_FINANCIER_NAME": "SABARKANTHA-GANDHINAGAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4409,
      "TXT_FINANCIER_NAME": "KANGRA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4410,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OPERATIVE COMMERCIAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4411,
      "TXT_FINANCIER_NAME": "ALIGARH ZILA SHAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4412,
      "TXT_FINANCIER_NAME": "RAJKOT MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4413,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FINANCIAL SERVICES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4414,
      "TXT_FINANCIER_NAME": "DHANLAKSHMI BANK"
    },
    {
      "NUM_FINANCIER_CD": 4415,
      "TXT_FINANCIER_NAME": "WARNA SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4416,
      "TXT_FINANCIER_NAME": "BILASPUR & RAIPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4417,
      "TXT_FINANCIER_NAME": "M.P. BANK RAJYA SAHAKARI BANK MARYADIT (APEX)"
    },
    {
      "NUM_FINANCIER_CD": 4418,
      "TXT_FINANCIER_NAME": "M.P.RAJYA SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4419,
      "TXT_FINANCIER_NAME": "ASHISH FINACIERS"
    },
    {
      "NUM_FINANCIER_CD": 4420,
      "TXT_FINANCIER_NAME": "THE SARVODAYA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4421,
      "TXT_FINANCIER_NAME": "BHARAT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4422,
      "TXT_FINANCIER_NAME": "THE A.P MAHESH CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4423,
      "TXT_FINANCIER_NAME": "THE A.P. MAHESH CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4424,
      "TXT_FINANCIER_NAME": "THE C.K.P. CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4425,
      "TXT_FINANCIER_NAME": "THE PALI URBAN CO-OPRETIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4426,
      "TXT_FINANCIER_NAME": "THE PALI URBAN CO-OPRETIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4427,
      "TXT_FINANCIER_NAME": "THE UDUPI COOPERATIVE TOWN BANK, LTD"
    },
    {
      "NUM_FINANCIER_CD": 4428,
      "TXT_FINANCIER_NAME": "THE UDUPI CO-OP. TOWN BANK,LTD."
    },
    {
      "NUM_FINANCIER_CD": 4429,
      "TXT_FINANCIER_NAME": "THE PEN CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4430,
      "TXT_FINANCIER_NAME": "JODHPUR NAGRIK SAHAKARI BAND LTD."
    },
    {
      "NUM_FINANCIER_CD": 4431,
      "TXT_FINANCIER_NAME": "A P CO OP OIL SEEDS GROWERS FEDERATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 4432,
      "TXT_FINANCIER_NAME": "BABANRAO SHINDE GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4433,
      "TXT_FINANCIER_NAME": "THE CHANDRAPUR DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4434,
      "TXT_FINANCIER_NAME": "SHRI BASAVAESHWAR URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4435,
      "TXT_FINANCIER_NAME": "MALAPRABHA GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4436,
      "TXT_FINANCIER_NAME": "VIMA KAMGAR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4437,
      "TXT_FINANCIER_NAME": "STANDARD CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4438,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO OPBANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4439,
      "TXT_FINANCIER_NAME": "PADMASHREE DR. VITTHALRAO VIKHE PATIL CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4440,
      "TXT_FINANCIER_NAME": "JIJAU COMM. CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 4441,
      "TXT_FINANCIER_NAME": "KAI.ADV. D.Y.VANE PATIL RAHURI TALUKA N.S.P.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4442,
      "TXT_FINANCIER_NAME": "BHAVNAGAR NAGRIK SAHAKARI BANKLTD."
    },
    {
      "NUM_FINANCIER_CD": 4443,
      "TXT_FINANCIER_NAME": "NIDHI CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4444,
      "TXT_FINANCIER_NAME": "GONDAL NAGRIK SAHAKARI BANK - GONDAL"
    },
    {
      "NUM_FINANCIER_CD": 4445,
      "TXT_FINANCIER_NAME": "CITIZENS CO-OP. BANK LTD.PUNE"
    },
    {
      "NUM_FINANCIER_CD": 4446,
      "TXT_FINANCIER_NAME": "JANATA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4447,
      "TXT_FINANCIER_NAME": "ASSOCIATES BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4448,
      "TXT_FINANCIER_NAME": "The Padmavathi Co-op Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4449,
      "TXT_FINANCIER_NAME": "AMT. DISTRICT CENTRAL CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4450,
      "TXT_FINANCIER_NAME": "AHMEDABAD DIST. CO-OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 4451,
      "TXT_FINANCIER_NAME": "THE MALLESHWARAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4452,
      "TXT_FINANCIER_NAME": "BANGALORE SOUHARDHA CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4453,
      "TXT_FINANCIER_NAME": "KADEPATHAR GRAMIN BIGARSHETI SAH PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 4454,
      "TXT_FINANCIER_NAME": "RATNATRAYA GRAMIN BIGARSHETI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 4455,
      "TXT_FINANCIER_NAME": "THE SIND CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4456,
      "TXT_FINANCIER_NAME": "GMAC TCFC FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 4457,
      "TXT_FINANCIER_NAME": "THE AP MAHESH CO-OPRATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4458,
      "TXT_FINANCIER_NAME": "THE A.P.VARDHAMAN (MAHILA) CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4874,
      "TXT_FINANCIER_NAME": "SHREEKRISHNA NAGRIK SAHKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 4875,
      "TXT_FINANCIER_NAME": "THE NEW GLOBE FINANCERS (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4876,
      "TXT_FINANCIER_NAME": "TIGER FINANCE & LEASING CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4877,
      "TXT_FINANCIER_NAME": "TIMES LEASING (INDIA) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4878,
      "TXT_FINANCIER_NAME": "TWIN CITIES INVESTMENTS AND FINANCES LTD."
    },
    {
      "NUM_FINANCIER_CD": 4879,
      "TXT_FINANCIER_NAME": "UMANG LEASING & CREDIT CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4880,
      "TXT_FINANCIER_NAME": "UTTAM LEASING & CAPITAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 4881,
      "TXT_FINANCIER_NAME": "VBC FINANCE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 4882,
      "TXT_FINANCIER_NAME": "VIMAL INFIN (PVT) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4883,
      "TXT_FINANCIER_NAME": "WARRIOR (INVESTMENT ) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4884,
      "TXT_FINANCIER_NAME": "GURDASPUR CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4885,
      "TXT_FINANCIER_NAME": "BAJAJ AUTO LTD."
    },
    {
      "NUM_FINANCIER_CD": 4886,
      "TXT_FINANCIER_NAME": "GOA URBAN CORPORATION BANK"
    },
    {
      "NUM_FINANCIER_CD": 4887,
      "TXT_FINANCIER_NAME": "NIDHI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4888,
      "TXT_FINANCIER_NAME": "THEKUKARWADA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4889,
      "TXT_FINANCIER_NAME": "BHAGYODHAYA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4890,
      "TXT_FINANCIER_NAME": "SUKHSHANTI CO-OP. CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 4891,
      "TXT_FINANCIER_NAME": "THE RALNAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4892,
      "TXT_FINANCIER_NAME": "R.K.G.BANK"
    },
    {
      "NUM_FINANCIER_CD": 4893,
      "TXT_FINANCIER_NAME": "VISHWAS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4894,
      "TXT_FINANCIER_NAME": "SHRI VITRAG CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4895,
      "TXT_FINANCIER_NAME": "THE MANKCHOWK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4896,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4897,
      "TXT_FINANCIER_NAME": "RANCHI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4898,
      "TXT_FINANCIER_NAME": "THE SONEPAT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4899,
      "TXT_FINANCIER_NAME": "THE LADOL SAH.MULTIPURPOSE CO-OP SOCI. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4900,
      "TXT_FINANCIER_NAME": "THE LADOL SAH. MULTIPURPOSE CO-OP. SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4901,
      "TXT_FINANCIER_NAME": "RANCHI KHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4902,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE NAGRI S.P.S.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4903,
      "TXT_FINANCIER_NAME": "ZILA SHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4904,
      "TXT_FINANCIER_NAME": "STANDARD CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4905,
      "TXT_FINANCIER_NAME": "LAXMI MAHILANAGRIK SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 4906,
      "TXT_FINANCIER_NAME": "SHREE VARDHMAN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4907,
      "TXT_FINANCIER_NAME": "TEXTILE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4908,
      "TXT_FINANCIER_NAME": "SHRI BAGAWANT SAHAKARI PATH SANSTHA BARSHI"
    },
    {
      "NUM_FINANCIER_CD": 4909,
      "TXT_FINANCIER_NAME": "LALITHA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 4910,
      "TXT_FINANCIER_NAME": "THE VISHWAKARMA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4911,
      "TXT_FINANCIER_NAME": "SHRI BANASHANKARI GRAMIN BIGARSHETI SAH SANSTHA MARY MOHOL"
    },
    {
      "NUM_FINANCIER_CD": 4912,
      "TXT_FINANCIER_NAME": "SARANGPUR NAGRIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4913,
      "TXT_FINANCIER_NAME": "IDUKKI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4914,
      "TXT_FINANCIER_NAME": "SRI LAKSHMINARAYANA CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4915,
      "TXT_FINANCIER_NAME": "IBP CO. LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4916,
      "TXT_FINANCIER_NAME": "JANAHEETHA CREDIT CO-OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 4917,
      "TXT_FINANCIER_NAME": "THE BHAGYODAY CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4918,
      "TXT_FINANCIER_NAME": "THE SALEM DIST.CENTRAL CO-OP.BANK LTD,SALEM"
    },
    {
      "NUM_FINANCIER_CD": 4919,
      "TXT_FINANCIER_NAME": "THE TRITHALA SERVICE CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4920,
      "TXT_FINANCIER_NAME": "KRISHNA MERCHANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4921,
      "TXT_FINANCIER_NAME": "UP COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4922,
      "TXT_FINANCIER_NAME": "UP COOPERATIVA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4923,
      "TXT_FINANCIER_NAME": "THE TEXTILECO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4924,
      "TXT_FINANCIER_NAME": "MARGDARSHAK FIN. PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 4925,
      "TXT_FINANCIER_NAME": "THE COOPERATIVE BANK OF RAJKOT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4926,
      "TXT_FINANCIER_NAME": "THE A.P. MAHESH CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4927,
      "TXT_FINANCIER_NAME": "THE CARE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4928,
      "TXT_FINANCIER_NAME": "SHREE SAPTASHRUNGI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4929,
      "TXT_FINANCIER_NAME": "JANATHA CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4930,
      "TXT_FINANCIER_NAME": "AMRAVATI DIST. MAHILA SAH. BANK AMRAVATI"
    },
    {
      "NUM_FINANCIER_CD": 4931,
      "TXT_FINANCIER_NAME": "NASHIK JILAH MAHIL VIKAS SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4932,
      "TXT_FINANCIER_NAME": "THE AZAD URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4933,
      "TXT_FINANCIER_NAME": "SHATAVAHANA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4934,
      "TXT_FINANCIER_NAME": "CITIFINANCIAL CONSUMER FIN.IND.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4935,
      "TXT_FINANCIER_NAME": "G.E.COUNTRYWIDE AUTO FIN.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4936,
      "TXT_FINANCIER_NAME": "G.E.COUNTRYWIDE AUTO FIN.LTD"
    },
    {
      "NUM_FINANCIER_CD": 4937,
      "TXT_FINANCIER_NAME": "AKSHAYA CO-OPERATIVE CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4938,
      "TXT_FINANCIER_NAME": "SHAMRAO VITTHAL CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4939,
      "TXT_FINANCIER_NAME": "THE ASR CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4940,
      "TXT_FINANCIER_NAME": "DAVANGERE- HARIHAR URBAN CO OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 4941,
      "TXT_FINANCIER_NAME": "LG ELECTRIC INDIA"
    },
    {
      "NUM_FINANCIER_CD": 4942,
      "TXT_FINANCIER_NAME": "PATLIPUTRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4943,
      "TXT_FINANCIER_NAME": "THE SHIHORI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4944,
      "TXT_FINANCIER_NAME": "PADMAVATHI BANK"
    },
    {
      "NUM_FINANCIER_CD": 4945,
      "TXT_FINANCIER_NAME": "THE GANDEVI PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4946,
      "TXT_FINANCIER_NAME": "KOSHI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4947,
      "TXT_FINANCIER_NAME": "JAI LAKSHMI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4948,
      "TXT_FINANCIER_NAME": "THE A B E CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4949,
      "TXT_FINANCIER_NAME": "SHRI SHRIDHARSWAMI CREDIT SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 4950,
      "TXT_FINANCIER_NAME": "CHAITHANYA CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 4951,
      "TXT_FINANCIER_NAME": "BANK OF CR. & COMM. INTERNL.(OVERSEAS)LTD"
    },
    {
      "NUM_FINANCIER_CD": 4952,
      "TXT_FINANCIER_NAME": "ANYONYA SAH. MANDALI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4953,
      "TXT_FINANCIER_NAME": "BARODA CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4954,
      "TXT_FINANCIER_NAME": "BARODA TRADERS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4955,
      "TXT_FINANCIER_NAME": "VARDHAMAN SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4956,
      "TXT_FINANCIER_NAME": "BARODA DIST. INDL.CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4957,
      "TXT_FINANCIER_NAME": "THE MUSLIM CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4958,
      "TXT_FINANCIER_NAME": "VIDYA SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4959,
      "TXT_FINANCIER_NAME": "MAHESH SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4960,
      "TXT_FINANCIER_NAME": "TRIVANDRUM DIST. CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4961,
      "TXT_FINANCIER_NAME": "NAGPUR NAG. SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4962,
      "TXT_FINANCIER_NAME": "RAJASTHAN STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4963,
      "TXT_FINANCIER_NAME": "PRUDENTIAL CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4964,
      "TXT_FINANCIER_NAME": "AMANATH CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4965,
      "TXT_FINANCIER_NAME": "UNNATI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4966,
      "TXT_FINANCIER_NAME": "NAGPUR DIST. CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4967,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4968,
      "TXT_FINANCIER_NAME": "SANWA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4969,
      "TXT_FINANCIER_NAME": "BIHAR STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4970,
      "TXT_FINANCIER_NAME": "MAHILA UTKARSHA NAGRIK SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4971,
      "TXT_FINANCIER_NAME": "ORISSA STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4972,
      "TXT_FINANCIER_NAME": "URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4973,
      "TXT_FINANCIER_NAME": "UTKAL CO-OP BANKING SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 4974,
      "TXT_FINANCIER_NAME": "A.P. VARDHAMAN (MAHILA) CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4975,
      "TXT_FINANCIER_NAME": "CHARMINAR CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4459,
      "TXT_FINANCIER_NAME": "THE NAMAKKAL CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4460,
      "TXT_FINANCIER_NAME": "PEOPLES CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4461,
      "TXT_FINANCIER_NAME": "NIMAR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4462,
      "TXT_FINANCIER_NAME": "GHAZIABAD JILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4463,
      "TXT_FINANCIER_NAME": "BHAGYA URVAN COOP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4464,
      "TXT_FINANCIER_NAME": "THE AP(VARDHAMAN (MAHILA) CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4465,
      "TXT_FINANCIER_NAME": "THE A B E CO.OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4466,
      "TXT_FINANCIER_NAME": "AMANATH CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4467,
      "TXT_FINANCIER_NAME": "U F J BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4468,
      "TXT_FINANCIER_NAME": "THE NASHIK DISTRICT INDUSTRIAL AND MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4469,
      "TXT_FINANCIER_NAME": "SARVODAYA MAHILA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4470,
      "TXT_FINANCIER_NAME": "ALF, A DIVISION OF INDUS IND BANK"
    },
    {
      "NUM_FINANCIER_CD": 4471,
      "TXT_FINANCIER_NAME": "THE MERCHANT CO OPERATIVE BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 4472,
      "TXT_FINANCIER_NAME": "UP COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4473,
      "TXT_FINANCIER_NAME": "THE BANK OF RAJSTHAN LTD."
    },
    {
      "NUM_FINANCIER_CD": 4474,
      "TXT_FINANCIER_NAME": "SANGALI BANK"
    },
    {
      "NUM_FINANCIER_CD": 4475,
      "TXT_FINANCIER_NAME": "THE SANGALI"
    },
    {
      "NUM_FINANCIER_CD": 4476,
      "TXT_FINANCIER_NAME": "AMBALA KURUKSHETRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4477,
      "TXT_FINANCIER_NAME": "THE PRAGATI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4478,
      "TXT_FINANCIER_NAME": "ALIBAG CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4479,
      "TXT_FINANCIER_NAME": "VARASHBHANATH MINARITY CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 4480,
      "TXT_FINANCIER_NAME": "RATIONAL SOFTWARE CORPORATION INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4481,
      "TXT_FINANCIER_NAME": "SREE KADERI AMBAL CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 4482,
      "TXT_FINANCIER_NAME": "MAP CREDIT & CAPITAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4483,
      "TXT_FINANCIER_NAME": "MOHTA FINANCE & LEASING CO. LTD"
    },
    {
      "NUM_FINANCIER_CD": 4484,
      "TXT_FINANCIER_NAME": "MOTOR & GENERAL FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4485,
      "TXT_FINANCIER_NAME": "N. P. AUTOMOBILE & FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4486,
      "TXT_FINANCIER_NAME": "N. S. HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4487,
      "TXT_FINANCIER_NAME": "NAU-NIDH FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4488,
      "TXT_FINANCIER_NAME": "SHRI SWAMINARAYAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4489,
      "TXT_FINANCIER_NAME": "L.G. HARIA SCHOOL"
    },
    {
      "NUM_FINANCIER_CD": 4490,
      "TXT_FINANCIER_NAME": "UDUPI COOPERATIVE TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4491,
      "TXT_FINANCIER_NAME": "TN STATE APEX COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4492,
      "TXT_FINANCIER_NAME": "ANYONYA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4493,
      "TXT_FINANCIER_NAME": "THE AP RAJA RAJESWARI MAHILA CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4494,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FIN. SER. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4495,
      "TXT_FINANCIER_NAME": "BHOPAL NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4496,
      "TXT_FINANCIER_NAME": "COMMERICAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4497,
      "TXT_FINANCIER_NAME": "NORTH MALABAR BANK"
    },
    {
      "NUM_FINANCIER_CD": 4498,
      "TXT_FINANCIER_NAME": "THE PATHANKOT HINDU URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4499,
      "TXT_FINANCIER_NAME": "BHARATH OVERSEAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4500,
      "TXT_FINANCIER_NAME": "S.B.P.P.CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4501,
      "TXT_FINANCIER_NAME": "JILLA PANCHAYAT - JAMNAGAR"
    },
    {
      "NUM_FINANCIER_CD": 4502,
      "TXT_FINANCIER_NAME": "J N S BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4503,
      "TXT_FINANCIER_NAME": "THE COOPERATIVE BANK OF RAJKOT LTD."
    },
    {
      "NUM_FINANCIER_CD": 4504,
      "TXT_FINANCIER_NAME": "BHOPAL NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4505,
      "TXT_FINANCIER_NAME": "JALANDHAR CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4506,
      "TXT_FINANCIER_NAME": "JODHPUR NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4507,
      "TXT_FINANCIER_NAME": "HITKARI SAHAKARI SAMITI"
    },
    {
      "NUM_FINANCIER_CD": 4508,
      "TXT_FINANCIER_NAME": "DOMBIVLI SAHAKARI BANK LTD DOMBIVLI"
    },
    {
      "NUM_FINANCIER_CD": 4509,
      "TXT_FINANCIER_NAME": "THE NANITAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4510,
      "TXT_FINANCIER_NAME": "HARYANA AGRO INDUSTIRES CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4511,
      "TXT_FINANCIER_NAME": "HARYANA AGRO INDUSTRIES CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4512,
      "TXT_FINANCIER_NAME": "KANGRA CO-OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4513,
      "TXT_FINANCIER_NAME": "PEOPLES COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4514,
      "TXT_FINANCIER_NAME": "UMA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4515,
      "TXT_FINANCIER_NAME": "THE A.B.E. CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4516,
      "TXT_FINANCIER_NAME": "THE GUJARAT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4517,
      "TXT_FINANCIER_NAME": "THE PALI URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4518,
      "TXT_FINANCIER_NAME": "THE N.A.A.DT. CENTRAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4519,
      "TXT_FINANCIER_NAME": "ALMORE URBAN CO OPERATIVE BANK UTTRA KHAND"
    },
    {
      "NUM_FINANCIER_CD": 4520,
      "TXT_FINANCIER_NAME": "UMABAI DABHADE PATH SANSTHA MARYADIT PUNE"
    },
    {
      "NUM_FINANCIER_CD": 4521,
      "TXT_FINANCIER_NAME": "MUBARAK CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 4522,
      "TXT_FINANCIER_NAME": "SHRI KULASWAMI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 4523,
      "TXT_FINANCIER_NAME": "INDRAPRASTHA SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4524,
      "TXT_FINANCIER_NAME": "THE MIRAJ URBAN CO-OP.BANK LTD.,MIRAJ"
    },
    {
      "NUM_FINANCIER_CD": 4525,
      "TXT_FINANCIER_NAME": "ZOROASTRIAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4526,
      "TXT_FINANCIER_NAME": "RANGA REDDY BANK"
    },
    {
      "NUM_FINANCIER_CD": 4527,
      "TXT_FINANCIER_NAME": "THE EENADU CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4528,
      "TXT_FINANCIER_NAME": "TREASURY SAVING BANK"
    },
    {
      "NUM_FINANCIER_CD": 4529,
      "TXT_FINANCIER_NAME": "GOLCONDA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4530,
      "TXT_FINANCIER_NAME": "PEPOLE S CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4531,
      "TXT_FINANCIER_NAME": "Shree Parswanath Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4532,
      "TXT_FINANCIER_NAME": "DHARMADAM SERVICE COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4533,
      "TXT_FINANCIER_NAME": "THE DIST.CO-OP.CENTAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4534,
      "TXT_FINANCIER_NAME": "C.K.G BANK"
    },
    {
      "NUM_FINANCIER_CD": 4535,
      "TXT_FINANCIER_NAME": "ADB FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 4536,
      "TXT_FINANCIER_NAME": "JANTA COMM. CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4537,
      "TXT_FINANCIER_NAME": "THE THANE DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4538,
      "TXT_FINANCIER_NAME": "CENTRAL WARE HOUSING CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 4539,
      "TXT_FINANCIER_NAME": "GOZARIYA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4540,
      "TXT_FINANCIER_NAME": "STATE CITIZEN SAHAKARI MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 4541,
      "TXT_FINANCIER_NAME": "ALAVI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4542,
      "TXT_FINANCIER_NAME": "NAVANIRMAN CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4543,
      "TXT_FINANCIER_NAME": "SHRI ANAND NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4544,
      "TXT_FINANCIER_NAME": "SUNDARLALJI SAWAJI UR. CO.OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4545,
      "TXT_FINANCIER_NAME": "THE R.S. CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4546,
      "TXT_FINANCIER_NAME": "THE PANGODE SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4547,
      "TXT_FINANCIER_NAME": "K.D.C.C. BANK LTD. SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 4548,
      "TXT_FINANCIER_NAME": "PUNNAYOORKULAM SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4549,
      "TXT_FINANCIER_NAME": "AIR COROPORATION EMPLOYEES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4550,
      "TXT_FINANCIER_NAME": "GONDAL NAGRIK SAHAKARI BANK - RAJKOT"
    },
    {
      "NUM_FINANCIER_CD": 4551,
      "TXT_FINANCIER_NAME": "KCCB BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4552,
      "TXT_FINANCIER_NAME": "NMGB MOGRALPUTHUR"
    },
    {
      "NUM_FINANCIER_CD": 4553,
      "TXT_FINANCIER_NAME": "EDANAD KANNUR SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4554,
      "TXT_FINANCIER_NAME": "EDNAD KANNUR SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4555,
      "TXT_FINANCIER_NAME": "KADIRUR CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4556,
      "TXT_FINANCIER_NAME": "WARDHA NAGRI SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 4557,
      "TXT_FINANCIER_NAME": "The Mahendergarh Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4558,
      "TXT_FINANCIER_NAME": "THE MAHENDERGARH CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4559,
      "TXT_FINANCIER_NAME": "AG PUNJAB"
    },
    {
      "NUM_FINANCIER_CD": 4560,
      "TXT_FINANCIER_NAME": "RAJGARH-SEHORE KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4561,
      "TXT_FINANCIER_NAME": "DISTT. CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4562,
      "TXT_FINANCIER_NAME": "SHIVALIK MARCANTILE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4563,
      "TXT_FINANCIER_NAME": "SHREE JYOTIBA SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 4564,
      "TXT_FINANCIER_NAME": "POONA PEOPLES CO OP BANK LTD TALEGAON PUNE"
    },
    {
      "NUM_FINANCIER_CD": 4565,
      "TXT_FINANCIER_NAME": "INDEPENDENCE"
    },
    {
      "NUM_FINANCIER_CD": 4566,
      "TXT_FINANCIER_NAME": "GMACTCFC FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4567,
      "TXT_FINANCIER_NAME": "VISHAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 4568,
      "TXT_FINANCIER_NAME": "SAMRAT ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 4569,
      "TXT_FINANCIER_NAME": "THE POSTAL AND RMS EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4570,
      "TXT_FINANCIER_NAME": "CHHATISHGARH STATE POWER HOLD CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 4571,
      "TXT_FINANCIER_NAME": "NEW INDIA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 4572,
      "TXT_FINANCIER_NAME": "NIMBA FINANCE & LEASING PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4573,
      "TXT_FINANCIER_NAME": "PAL CREDITS & CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 4574,
      "TXT_FINANCIER_NAME": "PONDICHERRY NIDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 4575,
      "TXT_FINANCIER_NAME": "R B CREDITS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4576,
      "TXT_FINANCIER_NAME": "RAASI FINANCE & INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4577,
      "TXT_FINANCIER_NAME": "RAJASTHAN LEASING PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 4578,
      "TXT_FINANCIER_NAME": "RAM NATH & CO. FINANCIERS (P)LTD"
    },
    {
      "NUM_FINANCIER_CD": 4579,
      "TXT_FINANCIER_NAME": "TNSTATE APEX CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4580,
      "TXT_FINANCIER_NAME": "THE MANSA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4581,
      "TXT_FINANCIER_NAME": "MAHRASTRA BRAHMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4582,
      "TXT_FINANCIER_NAME": "S.V.UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 4583,
      "TXT_FINANCIER_NAME": "SIDBI"
    },
    {
      "NUM_FINANCIER_CD": 4584,
      "TXT_FINANCIER_NAME": "UP-COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4585,
      "TXT_FINANCIER_NAME": "THE NUTAN NAGRIK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4586,
      "TXT_FINANCIER_NAME": "MRB"
    },
    {
      "NUM_FINANCIER_CD": 4587,
      "TXT_FINANCIER_NAME": "THE RAJ. STATE CO.OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 4588,
      "TXT_FINANCIER_NAME": "THE JAOLI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4589,
      "TXT_FINANCIER_NAME": "THE CHD.ST.COOP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4590,
      "TXT_FINANCIER_NAME": "S.J.V.N. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4591,
      "TXT_FINANCIER_NAME": "THE A.P.STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4592,
      "TXT_FINANCIER_NAME": "BAREILLY KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4593,
      "TXT_FINANCIER_NAME": "THE VARACHHA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4594,
      "TXT_FINANCIER_NAME": "N.H.PC."
    },
    {
      "NUM_FINANCIER_CD": 4595,
      "TXT_FINANCIER_NAME": "Hubli Co-operative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 4596,
      "TXT_FINANCIER_NAME": "THE HINDUSTHAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4597,
      "TXT_FINANCIER_NAME": "SHRI SWAMINARAYAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4598,
      "TXT_FINANCIER_NAME": "BHARTI SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4599,
      "TXT_FINANCIER_NAME": "THE KONKAN MERCANTILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4600,
      "TXT_FINANCIER_NAME": "THE GOA STATE URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4601,
      "TXT_FINANCIER_NAME": "BUSSINESS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4602,
      "TXT_FINANCIER_NAME": "GHOTI MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4603,
      "TXT_FINANCIER_NAME": "BHINGAR URBAN CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4604,
      "TXT_FINANCIER_NAME": "LGB"
    },
    {
      "NUM_FINANCIER_CD": 4605,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 4606,
      "TXT_FINANCIER_NAME": "PRIME CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4607,
      "TXT_FINANCIER_NAME": "THE TEXTILE CO OP BANK OF SURAT LTD."
    },
    {
      "NUM_FINANCIER_CD": 4608,
      "TXT_FINANCIER_NAME": "PANCHAGANGA RAITHARA SEVA SAHAKARI BANK LTD HEMMADY"
    },
    {
      "NUM_FINANCIER_CD": 4609,
      "TXT_FINANCIER_NAME": "BALAJI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 4610,
      "TXT_FINANCIER_NAME": "BANK OF SOURASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 4611,
      "TXT_FINANCIER_NAME": "MARWAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4612,
      "TXT_FINANCIER_NAME": "ADARASH MAHILA NAGARI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4613,
      "TXT_FINANCIER_NAME": "SAHYADRI NAGRI SAHAKARI PAT SANSTHA LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4614,
      "TXT_FINANCIER_NAME": "SADGURU BNAGRIK SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4615,
      "TXT_FINANCIER_NAME": "THE NORTH CANARA GSB CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4616,
      "TXT_FINANCIER_NAME": "THE KANAKAMAHALAKSHMI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4617,
      "TXT_FINANCIER_NAME": "THE NASHIK DISTRICT CENTRAL CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4618,
      "TXT_FINANCIER_NAME": "SHRI SIDHDI VENKATESH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4619,
      "TXT_FINANCIER_NAME": "NUTANNGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4620,
      "TXT_FINANCIER_NAME": "THE CHATHANNOOR CO-OP:URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4621,
      "TXT_FINANCIER_NAME": "KARUMALLOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4622,
      "TXT_FINANCIER_NAME": "AYROOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4623,
      "TXT_FINANCIER_NAME": "ASSOCIATE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4624,
      "TXT_FINANCIER_NAME": "MODEL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4625,
      "TXT_FINANCIER_NAME": "NAVELIM TELAULIM SERVICE CO-OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 4626,
      "TXT_FINANCIER_NAME": "NAVELIM TELAULIM CO-OP SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 4627,
      "TXT_FINANCIER_NAME": "THE FARMERS SERVICE CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4628,
      "TXT_FINANCIER_NAME": "THE EKM DIST CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4629,
      "TXT_FINANCIER_NAME": "MEENACHIL URBAN CO OPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4630,
      "TXT_FINANCIER_NAME": "JAIHIND URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4631,
      "TXT_FINANCIER_NAME": "urban co oporative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 4632,
      "TXT_FINANCIER_NAME": "BALTIKURI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4633,
      "TXT_FINANCIER_NAME": "DNYANSANVARDHINI GRAMIN BIGARSHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 4634,
      "TXT_FINANCIER_NAME": "S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 4635,
      "TXT_FINANCIER_NAME": "HUNTSMAN INTERNATIONAL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4636,
      "TXT_FINANCIER_NAME": "SRI CHANDRAMOULESWARA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4637,
      "TXT_FINANCIER_NAME": "DEENDAYAL NAGRIK SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4638,
      "TXT_FINANCIER_NAME": "The khattri co-operative Urban bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 4639,
      "TXT_FINANCIER_NAME": "FFF"
    },
    {
      "NUM_FINANCIER_CD": 4640,
      "TXT_FINANCIER_NAME": "THE PRERNA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4641,
      "TXT_FINANCIER_NAME": "DAIVADNYA SAHARAKAR BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 4642,
      "TXT_FINANCIER_NAME": "VELLALA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4643,
      "TXT_FINANCIER_NAME": "SARASWAT"
    },
    {
      "NUM_FINANCIER_CD": 4644,
      "TXT_FINANCIER_NAME": "The Palghat Co-Operative UrbanBank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 4645,
      "TXT_FINANCIER_NAME": "THE VARAPUZHA SERVICE CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4646,
      "TXT_FINANCIER_NAME": "THE DAVANGERE HARIHAR URBAN SAHAKARA BANK NIYAMITH DAVANGERE"
    },
    {
      "NUM_FINANCIER_CD": 4647,
      "TXT_FINANCIER_NAME": "THE DAVANGERE HARIHAR URBAN SAHAKAR BANK NIYAMITHA DAVANGERE"
    },
    {
      "NUM_FINANCIER_CD": 4648,
      "TXT_FINANCIER_NAME": "THE DAVANGERE HARIHAR URBAN SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 4649,
      "TXT_FINANCIER_NAME": "NATIONAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4650,
      "TXT_FINANCIER_NAME": "BABUJI COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4651,
      "TXT_FINANCIER_NAME": "KECHERY SERVICE CO-OPERATIVE BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 4652,
      "TXT_FINANCIER_NAME": "SHREE CHHATRAPATI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4653,
      "TXT_FINANCIER_NAME": "AMBARNATH JAI-HIND CO-OP. BANK LTD AMBARNATH"
    },
    {
      "NUM_FINANCIER_CD": 4654,
      "TXT_FINANCIER_NAME": "CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4655,
      "TXT_FINANCIER_NAME": "LALA ARBAN CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4656,
      "TXT_FINANCIER_NAME": "YESHWANT SAHAKARI KAMGAR BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 4657,
      "TXT_FINANCIER_NAME": "TUNGABADRA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4658,
      "TXT_FINANCIER_NAME": "SHRI P.K. ANNA PATIL JANTA SHAKARI BANK LTD.NANDURBAR"
    },
    {
      "NUM_FINANCIER_CD": 4659,
      "TXT_FINANCIER_NAME": "THE ILLAHABAD BANK"
    },
    {
      "NUM_FINANCIER_CD": 4660,
      "TXT_FINANCIER_NAME": "ANGADI SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4661,
      "TXT_FINANCIER_NAME": "NEELAKHANTA CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4662,
      "TXT_FINANCIER_NAME": "GMAC 20TH CENTURY FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4663,
      "TXT_FINANCIER_NAME": "TUNGBHADRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4664,
      "TXT_FINANCIER_NAME": "SAIRAJ NAGRI SAHAKARI P.S.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4665,
      "TXT_FINANCIER_NAME": "CHHINDWARA-SEONI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4666,
      "TXT_FINANCIER_NAME": "Junaghadh JillaSahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4667,
      "TXT_FINANCIER_NAME": "GRAM SEVA SAHKARI SOCSITY LIMITED MINI BANK"
    },
    {
      "NUM_FINANCIER_CD": 4668,
      "TXT_FINANCIER_NAME": "M NARENDRA KUMAR BOKDIA"
    },
    {
      "NUM_FINANCIER_CD": 4669,
      "TXT_FINANCIER_NAME": "IL AND FS INVESTMENT MANAGERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 4670,
      "TXT_FINANCIER_NAME": "RANNI GENERALFINANCE & HIRE PURCHASE(P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4671,
      "TXT_FINANCIER_NAME": "RESMI HIREPURCHASE & LEASING FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4672,
      "TXT_FINANCIER_NAME": "S.A.SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4673,
      "TXT_FINANCIER_NAME": "S.E. INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 4674,
      "TXT_FINANCIER_NAME": "S.G.R. FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 4675,
      "TXT_FINANCIER_NAME": "S.M.L. FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4676,
      "TXT_FINANCIER_NAME": "S.N.FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4677,
      "TXT_FINANCIER_NAME": "S.P.R. FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 4678,
      "TXT_FINANCIER_NAME": "S.V.R. FINANCE & LEASING (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4679,
      "TXT_FINANCIER_NAME": "SAGAR LEASEFIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 4680,
      "TXT_FINANCIER_NAME": "THE CHANDRAPUR C.CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4681,
      "TXT_FINANCIER_NAME": "THE SURAT TEXTILES TRADERS CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4682,
      "TXT_FINANCIER_NAME": "VALSAD-DANGS GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4683,
      "TXT_FINANCIER_NAME": "THE ROPAR CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4684,
      "TXT_FINANCIER_NAME": "THE ARVIND MILLS LTD."
    },
    {
      "NUM_FINANCIER_CD": 4685,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4686,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO. OPERATIVE BANKLTD."
    },
    {
      "NUM_FINANCIER_CD": 4687,
      "TXT_FINANCIER_NAME": "BHAGYODAYA CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4688,
      "TXT_FINANCIER_NAME": "LANGPI DEHANG RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4689,
      "TXT_FINANCIER_NAME": "bhopal cop central bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 4690,
      "TXT_FINANCIER_NAME": "BHOPAL COOP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4691,
      "TXT_FINANCIER_NAME": "SHRI KANYKA NAGRI SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4692,
      "TXT_FINANCIER_NAME": "DEUTCHE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4693,
      "TXT_FINANCIER_NAME": "GOA URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4694,
      "TXT_FINANCIER_NAME": "NASHIK DIST. CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4695,
      "TXT_FINANCIER_NAME": "BUSSINESS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4696,
      "TXT_FINANCIER_NAME": "THE NASHIK MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4697,
      "TXT_FINANCIER_NAME": "RCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 4698,
      "TXT_FINANCIER_NAME": "THE SAVALIA CO.OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 4699,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LYD"
    },
    {
      "NUM_FINANCIER_CD": 4700,
      "TXT_FINANCIER_NAME": "UFJ BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4701,
      "TXT_FINANCIER_NAME": "DEVLOPMENT CREDIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 4702,
      "TXT_FINANCIER_NAME": "S.B.P.P. CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4703,
      "TXT_FINANCIER_NAME": "GUJRAT COMM. CO.OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 4704,
      "TXT_FINANCIER_NAME": "SARVODAYA COMM. CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4705,
      "TXT_FINANCIER_NAME": "UTI"
    },
    {
      "NUM_FINANCIER_CD": 4706,
      "TXT_FINANCIER_NAME": "KARAD URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4707,
      "TXT_FINANCIER_NAME": "BANK OF HYDRABAD"
    },
    {
      "NUM_FINANCIER_CD": 4708,
      "TXT_FINANCIER_NAME": "VAPAR UDHOG VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4709,
      "TXT_FINANCIER_NAME": "SONEBHADRA NAGAR SAHAKARI BANK LTD SONEBHADRA"
    },
    {
      "NUM_FINANCIER_CD": 4710,
      "TXT_FINANCIER_NAME": "COMPEER SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 4711,
      "TXT_FINANCIER_NAME": "KCC,BANK,LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4712,
      "TXT_FINANCIER_NAME": "THE NASHIKROAD DEOLALI VYAPARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4713,
      "TXT_FINANCIER_NAME": "THE THENHIPALAM SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4714,
      "TXT_FINANCIER_NAME": "THE SALEM URBAN CO-OPERATIVE BANK LTD,SALEM"
    },
    {
      "NUM_FINANCIER_CD": 4715,
      "TXT_FINANCIER_NAME": "THE DARUSSALAM CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4716,
      "TXT_FINANCIER_NAME": "LOCAL NIC OFFICE"
    },
    {
      "NUM_FINANCIER_CD": 4717,
      "TXT_FINANCIER_NAME": "KARUR VAYSAY BANK"
    },
    {
      "NUM_FINANCIER_CD": 4718,
      "TXT_FINANCIER_NAME": "THE KALUPUR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4719,
      "TXT_FINANCIER_NAME": "Baltikuri Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 4720,
      "TXT_FINANCIER_NAME": "MERCANTILE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4721,
      "TXT_FINANCIER_NAME": "SERVICE CO OPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4722,
      "TXT_FINANCIER_NAME": "CAPITAL LOCA AREA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4723,
      "TXT_FINANCIER_NAME": "M.S. CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4724,
      "TXT_FINANCIER_NAME": "YESHWANT NAGRI S. P.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4725,
      "TXT_FINANCIER_NAME": "YESHWANT NAGRI S. P. LTD.,JAMKHED"
    },
    {
      "NUM_FINANCIER_CD": 4726,
      "TXT_FINANCIER_NAME": "THE BARODA TRADERS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4727,
      "TXT_FINANCIER_NAME": "ALVI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4728,
      "TXT_FINANCIER_NAME": "THE CORDIAL CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4729,
      "TXT_FINANCIER_NAME": "ASHOKANAGAR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4730,
      "TXT_FINANCIER_NAME": "THE SAMASTA NAGAR CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4731,
      "TXT_FINANCIER_NAME": "DHAR.MAH.AR.CR.COP.SO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 4732,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4733,
      "TXT_FINANCIER_NAME": "SHRI RUKHMINI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4734,
      "TXT_FINANCIER_NAME": "MAHILA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4735,
      "TXT_FINANCIER_NAME": "THE CHANASMA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4736,
      "TXT_FINANCIER_NAME": "BHEE CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 4737,
      "TXT_FINANCIER_NAME": "VASAI URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 4738,
      "TXT_FINANCIER_NAME": "NALLAMPATTY PRIMARY AGRICULTURAL CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4739,
      "TXT_FINANCIER_NAME": "MARUDHAR KHESHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4740,
      "TXT_FINANCIER_NAME": "LALA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4741,
      "TXT_FINANCIER_NAME": "THE NAMAKKAL CO-OPERATIVE URBAN BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4742,
      "TXT_FINANCIER_NAME": "ORIX AUTO & BUSINESS SOLUTIONS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4743,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4744,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4745,
      "TXT_FINANCIER_NAME": "MUDAVANMUKAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4746,
      "TXT_FINANCIER_NAME": "DIRECTOR,L.P.S.C"
    },
    {
      "NUM_FINANCIER_CD": 4747,
      "TXT_FINANCIER_NAME": "DIRECTOR,V.S.S.C"
    },
    {
      "NUM_FINANCIER_CD": 4748,
      "TXT_FINANCIER_NAME": "THE KOREGAON CO-OPERATIVE PEOPLES BANK LTD, KOREGAON"
    },
    {
      "NUM_FINANCIER_CD": 4749,
      "TXT_FINANCIER_NAME": "The Namakkal Cooperative Urban Bank"
    },
    {
      "NUM_FINANCIER_CD": 4750,
      "TXT_FINANCIER_NAME": "MALLESWARAM COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4751,
      "TXT_FINANCIER_NAME": "VIPAR UDHYOG VIKAS SAHAKRI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4752,
      "TXT_FINANCIER_NAME": "PEOPLES CO OP BANK LTD HINGOLI"
    },
    {
      "NUM_FINANCIER_CD": 4753,
      "TXT_FINANCIER_NAME": "THE NAINITAL DISTT. CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4754,
      "TXT_FINANCIER_NAME": "THE PATHANAMTHITTA DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4755,
      "TXT_FINANCIER_NAME": "BANK OF BAHARAIN& KUWAIT"
    },
    {
      "NUM_FINANCIER_CD": 4756,
      "TXT_FINANCIER_NAME": "THANE JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4757,
      "TXT_FINANCIER_NAME": "ZILA SAHKARI KENDRYA BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 4758,
      "TXT_FINANCIER_NAME": "TOWN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4759,
      "TXT_FINANCIER_NAME": "THRIKKALATHOOR SERVICE CO-OP: BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4760,
      "TXT_FINANCIER_NAME": "SRVICE CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4761,
      "TXT_FINANCIER_NAME": "THE SALEM DT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4762,
      "TXT_FINANCIER_NAME": "K.D.C.C. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4763,
      "TXT_FINANCIER_NAME": "SINNAR VYAPARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4764,
      "TXT_FINANCIER_NAME": "KARUKUTTY SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4765,
      "TXT_FINANCIER_NAME": "THE SOLAPUR DISTRICT CENTRAL CO-OP BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 4766,
      "TXT_FINANCIER_NAME": "KARUKUTTY SERVICE CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4767,
      "TXT_FINANCIER_NAME": "THE VELLOOR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4768,
      "TXT_FINANCIER_NAME": "DIRECTOR LOCAL AUDIT HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 4769,
      "TXT_FINANCIER_NAME": "NAVAKARNATAKA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4770,
      "TXT_FINANCIER_NAME": "THE COTTON CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4771,
      "TXT_FINANCIER_NAME": "SEEMA NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 4772,
      "TXT_FINANCIER_NAME": "SIDHU LEASING & FINANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4773,
      "TXT_FINANCIER_NAME": "SIMPSON & GENERAL FINANCE CO LTD."
    },
    {
      "NUM_FINANCIER_CD": 4774,
      "TXT_FINANCIER_NAME": "SINGH FINANCE (PVT)LTD."
    },
    {
      "NUM_FINANCIER_CD": 4775,
      "TXT_FINANCIER_NAME": "SRI GANESH ANAND LEASING & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4776,
      "TXT_FINANCIER_NAME": "SUGAL & DAMANI FINLEASE LTD."
    },
    {
      "NUM_FINANCIER_CD": 4777,
      "TXT_FINANCIER_NAME": "SUPER FINANCE (CBE) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4778,
      "TXT_FINANCIER_NAME": "SUSEE FINANCE & LEASING (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4779,
      "TXT_FINANCIER_NAME": "THE DELHI SAFE DEPOSIT COMPANY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4780,
      "TXT_FINANCIER_NAME": "THE INDUSTRIAL CREDIT & INVESTMENTS CORPN. OF INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 4781,
      "TXT_FINANCIER_NAME": "GODAVARI LEASING & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 4782,
      "TXT_FINANCIER_NAME": "M.P. RAJYASAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4783,
      "TXT_FINANCIER_NAME": "ASSAM CO-OP.APEX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4784,
      "TXT_FINANCIER_NAME": "THE VARACHHA CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4785,
      "TXT_FINANCIER_NAME": "THE ERNAKULM DISTRICT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4786,
      "TXT_FINANCIER_NAME": "THE TEXTILE PROCESSORS CO. OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4787,
      "TXT_FINANCIER_NAME": "THE TEXTILE PROCESSORS CO. OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4788,
      "TXT_FINANCIER_NAME": "ANYONYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4789,
      "TXT_FINANCIER_NAME": "THE EKM DT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4790,
      "TXT_FINANCIER_NAME": "THE CO- OPERATIVE BANK OF RAJKOT"
    },
    {
      "NUM_FINANCIER_CD": 4791,
      "TXT_FINANCIER_NAME": "THE C K P CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4792,
      "TXT_FINANCIER_NAME": "EDUCATION DEPTT. GOVT. OF HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 4793,
      "TXT_FINANCIER_NAME": "SANGLI UR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4794,
      "TXT_FINANCIER_NAME": "NASHIK JILHA MAHILA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4795,
      "TXT_FINANCIER_NAME": "INDRAPRASTHA SEHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4796,
      "TXT_FINANCIER_NAME": "BHANDARI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4797,
      "TXT_FINANCIER_NAME": "SCOTIA BANK"
    },
    {
      "NUM_FINANCIER_CD": 4798,
      "TXT_FINANCIER_NAME": "SHRI WARNA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4799,
      "TXT_FINANCIER_NAME": "THE A.B.E.CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4800,
      "TXT_FINANCIER_NAME": "M.P. RAJYA SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4801,
      "TXT_FINANCIER_NAME": "LICE Co-op Bank"
    },
    {
      "NUM_FINANCIER_CD": 4802,
      "TXT_FINANCIER_NAME": "KANKRIA MANINAGAR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4803,
      "TXT_FINANCIER_NAME": "AURANGABAD-JALNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4804,
      "TXT_FINANCIER_NAME": "MUKESH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 4805,
      "TXT_FINANCIER_NAME": "SPK BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 4806,
      "TXT_FINANCIER_NAME": "VENNALA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4807,
      "TXT_FINANCIER_NAME": "UMABAI DABHADE PAT SANSTHA MARYADIT PUNE"
    },
    {
      "NUM_FINANCIER_CD": 4808,
      "TXT_FINANCIER_NAME": "PRABHAT PATSANSTHA OSMANABAD"
    },
    {
      "NUM_FINANCIER_CD": 4809,
      "TXT_FINANCIER_NAME": "GYLOGICAL SURVEY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 4810,
      "TXT_FINANCIER_NAME": "GEOLICAL SURVEY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 4811,
      "TXT_FINANCIER_NAME": "MIBL A/c"
    },
    {
      "NUM_FINANCIER_CD": 4812,
      "TXT_FINANCIER_NAME": "STATE BANK OK MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 4813,
      "TXT_FINANCIER_NAME": "SURAT DISTIC CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4814,
      "TXT_FINANCIER_NAME": "A.J.FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4815,
      "TXT_FINANCIER_NAME": "KRISHNA CO-OPERATIVE CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 4816,
      "TXT_FINANCIER_NAME": "CEEJAY FINACE,NADIAD"
    },
    {
      "NUM_FINANCIER_CD": 4817,
      "TXT_FINANCIER_NAME": "THE PRATAP CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4818,
      "TXT_FINANCIER_NAME": "ABHINANDAN UR. CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 4819,
      "TXT_FINANCIER_NAME": "THE KANAKAMAHALAKSHMI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4820,
      "TXT_FINANCIER_NAME": "CHITRADURGA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4821,
      "TXT_FINANCIER_NAME": "THRISSUR DIST CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4822,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMERCIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4823,
      "TXT_FINANCIER_NAME": "CATHOLIC BANK(THE CATHOLIC CO-OPERATIVE URBAN BANK LTD.)"
    },
    {
      "NUM_FINANCIER_CD": 4824,
      "TXT_FINANCIER_NAME": "S.B.P.PCO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4825,
      "TXT_FINANCIER_NAME": "FEDERAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4826,
      "TXT_FINANCIER_NAME": "KANKARIYA MANINAGAR NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4827,
      "TXT_FINANCIER_NAME": "PEOPLES CO.OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4828,
      "TXT_FINANCIER_NAME": "THE RADDI CO-OPERATIVE CREDIT BANK LTD.,DHARWAD"
    },
    {
      "NUM_FINANCIER_CD": 4829,
      "TXT_FINANCIER_NAME": "THE RADDI CO-OPERATIVE CREDIT BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4830,
      "TXT_FINANCIER_NAME": "THE JANLAXMI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4831,
      "TXT_FINANCIER_NAME": "SHRI MURUGHARAJENDRA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4832,
      "TXT_FINANCIER_NAME": "ADARSH BANK(ADARSH CO-OPERATIVE URBAN BANK LTD)"
    },
    {
      "NUM_FINANCIER_CD": 4833,
      "TXT_FINANCIER_NAME": "DEVLOPMENT CRADIT BANK."
    },
    {
      "NUM_FINANCIER_CD": 4834,
      "TXT_FINANCIER_NAME": "KOLAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4835,
      "TXT_FINANCIER_NAME": "THE MADHUPURA MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4836,
      "TXT_FINANCIER_NAME": "DR. PUNJABRAO DESHMUKH URBAN CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 4837,
      "TXT_FINANCIER_NAME": "THE KUMBI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4838,
      "TXT_FINANCIER_NAME": "CHINA TRUST COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4839,
      "TXT_FINANCIER_NAME": "GHAZAL FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 4840,
      "TXT_FINANCIER_NAME": "AGASHI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 4841,
      "TXT_FINANCIER_NAME": "VIMAL MAHILA SOUHARD CREDIT COOPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4842,
      "TXT_FINANCIER_NAME": "NIKHARA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 4843,
      "TXT_FINANCIER_NAME": "BANK OF TOKYO LTD."
    },
    {
      "NUM_FINANCIER_CD": 4844,
      "TXT_FINANCIER_NAME": "GRINDLAYS BANK"
    },
    {
      "NUM_FINANCIER_CD": 4845,
      "TXT_FINANCIER_NAME": "BANK OF MADURA LTD."
    },
    {
      "NUM_FINANCIER_CD": 4846,
      "TXT_FINANCIER_NAME": "KUMBAKONAM CITY UNION BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4847,
      "TXT_FINANCIER_NAME": "UNITED INDUSTRIAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4848,
      "TXT_FINANCIER_NAME": "ALEN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4849,
      "TXT_FINANCIER_NAME": "MUMBAI DIST.CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4850,
      "TXT_FINANCIER_NAME": "CO-OP BANK OF AHMEDABAD"
    },
    {
      "NUM_FINANCIER_CD": 4851,
      "TXT_FINANCIER_NAME": "JANATA SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4852,
      "TXT_FINANCIER_NAME": "NEW INDIA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4853,
      "TXT_FINANCIER_NAME": "N.K.G.S.B.CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4854,
      "TXT_FINANCIER_NAME": "SAHYADRI SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4855,
      "TXT_FINANCIER_NAME": "DELHI STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4856,
      "TXT_FINANCIER_NAME": "GREATER MUMBAI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4857,
      "TXT_FINANCIER_NAME": "BANTRA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4858,
      "TXT_FINANCIER_NAME": "THE SAFE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4859,
      "TXT_FINANCIER_NAME": "CITY CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4860,
      "TXT_FINANCIER_NAME": "ASSAM CO-OP APEX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4861,
      "TXT_FINANCIER_NAME": "AHMEDABAD DIST. CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4862,
      "TXT_FINANCIER_NAME": "HARISIDDH CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4863,
      "TXT_FINANCIER_NAME": "SOCIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4864,
      "TXT_FINANCIER_NAME": "VIJAY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4865,
      "TXT_FINANCIER_NAME": "AHMEDABAD URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4866,
      "TXT_FINANCIER_NAME": "CITI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4867,
      "TXT_FINANCIER_NAME": "PRAGATI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4868,
      "TXT_FINANCIER_NAME": "HYDERABAD DIST. CO-OP CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4869,
      "TXT_FINANCIER_NAME": "THE BIKANER AND JAIPUR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4870,
      "TXT_FINANCIER_NAME": "NARMADA HYDROELECTRIC DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 4871,
      "TXT_FINANCIER_NAME": "AMBRENI VIDHYESHA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 4872,
      "TXT_FINANCIER_NAME": "K B RAGHURAJA SREEDEVI KRUPA FIN CORP"
    },
    {
      "NUM_FINANCIER_CD": 4873,
      "TXT_FINANCIER_NAME": "SUM AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 4255,
      "TXT_FINANCIER_NAME": "PRERANA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4256,
      "TXT_FINANCIER_NAME": "VARADA GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4257,
      "TXT_FINANCIER_NAME": "LIC EMPLOYEES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4258,
      "TXT_FINANCIER_NAME": "M.P. RAJYA SAHAKARI BANL MYDT."
    },
    {
      "NUM_FINANCIER_CD": 4259,
      "TXT_FINANCIER_NAME": "AMARNATH CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4260,
      "TXT_FINANCIER_NAME": "SREE NARAYAN GURU CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4261,
      "TXT_FINANCIER_NAME": "AMANATH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4262,
      "TXT_FINANCIER_NAME": "BIJAPUR GRAMIN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4263,
      "TXT_FINANCIER_NAME": "AMANATH CO-OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4264,
      "TXT_FINANCIER_NAME": "THE KURLA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4265,
      "TXT_FINANCIER_NAME": "VASI VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4266,
      "TXT_FINANCIER_NAME": "BANK OF RAJSTHAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 4267,
      "TXT_FINANCIER_NAME": "THE DHARAMPETH MAHILA UR.CREDIRT CO-OP.SOC.LTD."
    },
    {
      "NUM_FINANCIER_CD": 4268,
      "TXT_FINANCIER_NAME": "RITIKA MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 4269,
      "TXT_FINANCIER_NAME": "MUNGSAJI MAHARAJ NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 4270,
      "TXT_FINANCIER_NAME": "SRI RAMANAGAR PATTANA SAHAKAR BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 4271,
      "TXT_FINANCIER_NAME": "SUMITHRA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4272,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4273,
      "TXT_FINANCIER_NAME": "Rajgarh Sehore Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 4274,
      "TXT_FINANCIER_NAME": "S S URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4275,
      "TXT_FINANCIER_NAME": "MAHINDRA&ampMAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 4276,
      "TXT_FINANCIER_NAME": "THE APNA CO-OP.URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4277,
      "TXT_FINANCIER_NAME": "THE IDUKKI CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4278,
      "TXT_FINANCIER_NAME": "KOTTAYAM DT CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4279,
      "TXT_FINANCIER_NAME": "THE NADUVANNOOR REGIONAL CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4280,
      "TXT_FINANCIER_NAME": "PALLIPURAM SERVICE CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4281,
      "TXT_FINANCIER_NAME": "ANANDA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4282,
      "TXT_FINANCIER_NAME": "CHOPDA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4283,
      "TXT_FINANCIER_NAME": "FARKA CO-OPERATIVE RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4284,
      "TXT_FINANCIER_NAME": "anand coopera"
    },
    {
      "NUM_FINANCIER_CD": 4285,
      "TXT_FINANCIER_NAME": "VAZHAPPALLY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4286,
      "TXT_FINANCIER_NAME": "KRISHNA MERCHANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4287,
      "TXT_FINANCIER_NAME": "The Chitanvispura Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 4288,
      "TXT_FINANCIER_NAME": "HINDUSTHAN PETROLIUM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 4289,
      "TXT_FINANCIER_NAME": "kccb bank baijnath"
    },
    {
      "NUM_FINANCIER_CD": 4290,
      "TXT_FINANCIER_NAME": "KCCB BAIJNATH"
    },
    {
      "NUM_FINANCIER_CD": 4291,
      "TXT_FINANCIER_NAME": "HIMACHAL CO -OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4292,
      "TXT_FINANCIER_NAME": "KANGRA CENTARL BANK LTD. BAIJNATH"
    },
    {
      "NUM_FINANCIER_CD": 4293,
      "TXT_FINANCIER_NAME": "KCCB BANK LTD BAIJNATH"
    },
    {
      "NUM_FINANCIER_CD": 4294,
      "TXT_FINANCIER_NAME": "KCCB KANGRA"
    },
    {
      "NUM_FINANCIER_CD": 4295,
      "TXT_FINANCIER_NAME": "SHRI LAKSHMI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4296,
      "TXT_FINANCIER_NAME": "BHARAT PETROLIUN CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 4297,
      "TXT_FINANCIER_NAME": "KANGRA CNTRAL CO-OP BANK LTD PATTA"
    },
    {
      "NUM_FINANCIER_CD": 4298,
      "TXT_FINANCIER_NAME": "SMALL SCALE INDUSTRIES"
    },
    {
      "NUM_FINANCIER_CD": 4299,
      "TXT_FINANCIER_NAME": "PEOPLE S CO-OPERATIVE BANK LTD. DHOLKA."
    },
    {
      "NUM_FINANCIER_CD": 4300,
      "TXT_FINANCIER_NAME": "PAWAN KUMAR MEHTA AND SONS CHANNAI"
    },
    {
      "NUM_FINANCIER_CD": 4301,
      "TXT_FINANCIER_NAME": "AMAR HOLDING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4302,
      "TXT_FINANCIER_NAME": "BHAGYASHREE NAGRI SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 4303,
      "TXT_FINANCIER_NAME": "GOWRA LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 4304,
      "TXT_FINANCIER_NAME": "GVK CAPITAL & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 4305,
      "TXT_FINANCIER_NAME": "HBC FINANCE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 4306,
      "TXT_FINANCIER_NAME": "HINDUSTAN AUTO FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4307,
      "TXT_FINANCIER_NAME": "HINDUSTAN COMMERCIAL INVESTMENT TRUST LTD."
    },
    {
      "NUM_FINANCIER_CD": 4308,
      "TXT_FINANCIER_NAME": "INDUS-IND LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 4309,
      "TXT_FINANCIER_NAME": "J. C. MOTOR & GENERAL FINANCE CO (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4310,
      "TXT_FINANCIER_NAME": "J. S. HIRE PURCHASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 4311,
      "TXT_FINANCIER_NAME": "JAI SAVINGS & HIRE PURCHASE CO.(INDIA) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4312,
      "TXT_FINANCIER_NAME": "JENSON & NICHOLSON FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4313,
      "TXT_FINANCIER_NAME": "MADRAS CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4314,
      "TXT_FINANCIER_NAME": "JIVAN COMM. COOP. BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4315,
      "TXT_FINANCIER_NAME": "RAJE SHIVAJI NAGRI S. PATH S.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4316,
      "TXT_FINANCIER_NAME": "RAJKOT PEOPLE\"S CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4317,
      "TXT_FINANCIER_NAME": "The Nav Jeevan Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 4318,
      "TXT_FINANCIER_NAME": "THE MEGRAJ NAGRIK SAHKARI BANK LTD., MEGRAJ"
    },
    {
      "NUM_FINANCIER_CD": 4319,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FINANCIAL SERVICES (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 4320,
      "TXT_FINANCIER_NAME": "THE LAXMI VILAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4321,
      "TXT_FINANCIER_NAME": "THE A.P. MAHESH CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4322,
      "TXT_FINANCIER_NAME": "SREENATH CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4323,
      "TXT_FINANCIER_NAME": "K & Z LEASEING & FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 4324,
      "TXT_FINANCIER_NAME": "I.B.P. CO LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4325,
      "TXT_FINANCIER_NAME": "H.S.K.G.B"
    },
    {
      "NUM_FINANCIER_CD": 4326,
      "TXT_FINANCIER_NAME": "HSKGB"
    },
    {
      "NUM_FINANCIER_CD": 4327,
      "TXT_FINANCIER_NAME": "M/S JAISI FINANCE & INVESTMENT (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 4328,
      "TXT_FINANCIER_NAME": "JANA SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4329,
      "TXT_FINANCIER_NAME": "THE ROYAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4330,
      "TXT_FINANCIER_NAME": "THE HONNAVARA URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4331,
      "TXT_FINANCIER_NAME": "THE HONNAVARA URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4332,
      "TXT_FINANCIER_NAME": "MAHAMEHDA URBAN CO-OPREATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4333,
      "TXT_FINANCIER_NAME": "VAISHYA COOPERATIVE ADARSH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4334,
      "TXT_FINANCIER_NAME": "GURDASPUR CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4335,
      "TXT_FINANCIER_NAME": "AMBALA KURUKSHETRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 4336,
      "TXT_FINANCIER_NAME": "THE BAVLA NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4337,
      "TXT_FINANCIER_NAME": "KUNBI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 4338,
      "TXT_FINANCIER_NAME": "UNITED MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 4339,
      "TXT_FINANCIER_NAME": "CHOLAMDALAM"
    },
    {
      "NUM_FINANCIER_CD": 4340,
      "TXT_FINANCIER_NAME": "PIMPRI-CHICNHWAD SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4341,
      "TXT_FINANCIER_NAME": "SOCIETY GENERAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 4342,
      "TXT_FINANCIER_NAME": "SHREE VARDHMAN CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 4343,
      "TXT_FINANCIER_NAME": "BANGALORE DIST CO-OP BANK CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4344,
      "TXT_FINANCIER_NAME": "CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4345,
      "TXT_FINANCIER_NAME": "PA"
    },
    {
      "NUM_FINANCIER_CD": 4346,
      "TXT_FINANCIER_NAME": "PARADISE FIN.TRADE"
    },
    {
      "NUM_FINANCIER_CD": 4347,
      "TXT_FINANCIER_NAME": "SIDDHI CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4348,
      "TXT_FINANCIER_NAME": "GHAZIABAD URBEN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4349,
      "TXT_FINANCIER_NAME": "THE KARAD CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4350,
      "TXT_FINANCIER_NAME": "THE SINOR NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4351,
      "TXT_FINANCIER_NAME": "DR. PUNJABRAO DESHMUKH UR. CO. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4352,
      "TXT_FINANCIER_NAME": "THE AKOLA UR. CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4353,
      "TXT_FINANCIER_NAME": "THE WASHIM UR. CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4354,
      "TXT_FINANCIER_NAME": "DR. PUNJABRAO DESHMUKH UR. CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4355,
      "TXT_FINANCIER_NAME": "KESHAV SEHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4356,
      "TXT_FINANCIER_NAME": "DISTRICT COOPERATIVE BANK LTD,HARIDWAR"
    },
    {
      "NUM_FINANCIER_CD": 4357,
      "TXT_FINANCIER_NAME": "AKSHAY CO-OPERATIVE SOCIETY LTD.SIDDAPUR"
    },
    {
      "NUM_FINANCIER_CD": 4358,
      "TXT_FINANCIER_NAME": "BALITIKURI COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5681,
      "TXT_FINANCIER_NAME": "THE MUDHOL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5682,
      "TXT_FINANCIER_NAME": "CENTRAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5683,
      "TXT_FINANCIER_NAME": "SARDARGUNJ CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5684,
      "TXT_FINANCIER_NAME": "ELURI BANK"
    },
    {
      "NUM_FINANCIER_CD": 5685,
      "TXT_FINANCIER_NAME": "LOKJVAN CO OP CREDIT SOCIETY LTD,NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 5686,
      "TXT_FINANCIER_NAME": "TELLICHERY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5687,
      "TXT_FINANCIER_NAME": "DEVYANI SAHAKARI BANK LIMITED, KOPARGAON"
    },
    {
      "NUM_FINANCIER_CD": 5688,
      "TXT_FINANCIER_NAME": "THE KUTTIPPURAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5689,
      "TXT_FINANCIER_NAME": "THE KRANTHI CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5690,
      "TXT_FINANCIER_NAME": "PUNE PEOPLE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5691,
      "TXT_FINANCIER_NAME": "THE NEDUPUZHA SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5692,
      "TXT_FINANCIER_NAME": "JANSEVA SHAKHARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5693,
      "TXT_FINANCIER_NAME": "THE MERCHANT S URBAN O-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5694,
      "TXT_FINANCIER_NAME": "THE KALPETTA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5695,
      "TXT_FINANCIER_NAME": "SRI GANAPATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5696,
      "TXT_FINANCIER_NAME": "ASHISH CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5697,
      "TXT_FINANCIER_NAME": "RUKMINI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5698,
      "TXT_FINANCIER_NAME": "AVADH-GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5699,
      "TXT_FINANCIER_NAME": "KAKAKIYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5700,
      "TXT_FINANCIER_NAME": "KUTTIADI CO-OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5701,
      "TXT_FINANCIER_NAME": "{(( MAGMA LEASING LTD PNB AND ALL OTHERS CONSORTIUM MEMBERS BANKS, ))}"
    },
    {
      "NUM_FINANCIER_CD": 5702,
      "TXT_FINANCIER_NAME": "M.G.V.C.L."
    },
    {
      "NUM_FINANCIER_CD": 5703,
      "TXT_FINANCIER_NAME": "BALUSSERY URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5704,
      "TXT_FINANCIER_NAME": "THE BALUSSERY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5705,
      "TXT_FINANCIER_NAME": "THE SATARA MERCHANTS CO.OP.BANKLTD."
    },
    {
      "NUM_FINANCIER_CD": 5706,
      "TXT_FINANCIER_NAME": "VALAPATANAM SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5707,
      "TXT_FINANCIER_NAME": "BHATKAL AGRICULTURAL & RURAL DEVELOPMENT CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5708,
      "TXT_FINANCIER_NAME": "BHATKAL AGRICULTURAL & RURAL DEVELOPMENT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5709,
      "TXT_FINANCIER_NAME": "BHATKAL AGRUCULTURAL & RURAL DEVELOPMENT CO-OP BANK LTD. BHATKAL"
    },
    {
      "NUM_FINANCIER_CD": 5710,
      "TXT_FINANCIER_NAME": "The Devgad Urban Co-op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5711,
      "TXT_FINANCIER_NAME": "CHALAKUDY HEAD POST OFFICE"
    },
    {
      "NUM_FINANCIER_CD": 5712,
      "TXT_FINANCIER_NAME": "THE DHULIA DISTRICT CENTRAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5713,
      "TXT_FINANCIER_NAME": "SHRI VYAS DHANVARSHA SAH.BABK LTD.YAVAL."
    },
    {
      "NUM_FINANCIER_CD": 5714,
      "TXT_FINANCIER_NAME": "THE UPPARPARA COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5715,
      "TXT_FINANCIER_NAME": "AKSHAYA CO-OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5716,
      "TXT_FINANCIER_NAME": "AMRAVATI DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5717,
      "TXT_FINANCIER_NAME": "NAINITAL DISTT. CO. BANK"
    },
    {
      "NUM_FINANCIER_CD": 5718,
      "TXT_FINANCIER_NAME": "PITHORAGARH DISTT CO. BANK"
    },
    {
      "NUM_FINANCIER_CD": 5719,
      "TXT_FINANCIER_NAME": "THE SANGALI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5720,
      "TXT_FINANCIER_NAME": "THE BEED DIST.CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5721,
      "TXT_FINANCIER_NAME": "UTTRANCHAL GRAMIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5722,
      "TXT_FINANCIER_NAME": "AMANATH CO-OP.BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5723,
      "TXT_FINANCIER_NAME": "INDUSTRIA FINANACE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5724,
      "TXT_FINANCIER_NAME": "VADAVUCODE FARMERS SERVICE CO.BANK"
    },
    {
      "NUM_FINANCIER_CD": 5725,
      "TXT_FINANCIER_NAME": "VADAVUCODE FARMERS SERVICE CO - OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5726,
      "TXT_FINANCIER_NAME": "KOLAVALUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5727,
      "TXT_FINANCIER_NAME": "THE KODIYATHOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5728,
      "TXT_FINANCIER_NAME": "THE KONDOTTY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5729,
      "TXT_FINANCIER_NAME": "ANDRA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5730,
      "TXT_FINANCIER_NAME": "THE MARKANDEYA MAHILA MULTIPURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5731,
      "TXT_FINANCIER_NAME": "JAI YOGESHWAR NAGARI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5732,
      "TXT_FINANCIER_NAME": "G MAC INDIA FIANCE SERVIECE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5733,
      "TXT_FINANCIER_NAME": "JUBILEE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5734,
      "TXT_FINANCIER_NAME": "jaipur nagore aanchalik gramin bank"
    },
    {
      "NUM_FINANCIER_CD": 5735,
      "TXT_FINANCIER_NAME": "SADHBHAV NAGRIC BANK"
    },
    {
      "NUM_FINANCIER_CD": 5736,
      "TXT_FINANCIER_NAME": "SHIVPRATAP NAG SAH PAT SANSTHLTD"
    },
    {
      "NUM_FINANCIER_CD": 5737,
      "TXT_FINANCIER_NAME": "JILA ANTYAVYASAYI SAHAKARI VIKAS SAMITI MYDT"
    },
    {
      "NUM_FINANCIER_CD": 5738,
      "TXT_FINANCIER_NAME": "JILA ANTYAVYAVASAYI SAHAKARI VIKAS SAMITI MYDT"
    },
    {
      "NUM_FINANCIER_CD": 5739,
      "TXT_FINANCIER_NAME": "CITY PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 5740,
      "TXT_FINANCIER_NAME": "CITY CORP MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5741,
      "TXT_FINANCIER_NAME": "CHALLAKERE TOWN CONSUMER CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5742,
      "TXT_FINANCIER_NAME": "DANDAPANI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5743,
      "TXT_FINANCIER_NAME": "DAVANAGERE HARIHARA URBAN SAHAKARA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5744,
      "TXT_FINANCIER_NAME": "SHRI SAI SAHSTRARJUN NAGRI SAHAKARI PATHSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 5745,
      "TXT_FINANCIER_NAME": "SHABARI MAHILA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5746,
      "TXT_FINANCIER_NAME": "SHABARI MAHILA PATINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 5747,
      "TXT_FINANCIER_NAME": "SHABARI MAHILA PATINA SAHAKARA SANGA NIYAMITHA TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 5748,
      "TXT_FINANCIER_NAME": "DR BABA SAHEB AMBEDKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5749,
      "TXT_FINANCIER_NAME": "CHIKO BANK"
    },
    {
      "NUM_FINANCIER_CD": 5750,
      "TXT_FINANCIER_NAME": "THE NAWANSHAHAR CENTRAL COPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5751,
      "TXT_FINANCIER_NAME": "A U FINANERS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5752,
      "TXT_FINANCIER_NAME": "Assam Co operative Apex Bank."
    },
    {
      "NUM_FINANCIER_CD": 5753,
      "TXT_FINANCIER_NAME": "SYED SHARIAT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5754,
      "TXT_FINANCIER_NAME": "VISHWESHWARAIAH CO OPARETIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5755,
      "TXT_FINANCIER_NAME": "BAPUJI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5756,
      "TXT_FINANCIER_NAME": "Sitapur Financer"
    },
    {
      "NUM_FINANCIER_CD": 5757,
      "TXT_FINANCIER_NAME": "Sree Thyagaaj Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5758,
      "TXT_FINANCIER_NAME": "SAIL RSP"
    },
    {
      "NUM_FINANCIER_CD": 5759,
      "TXT_FINANCIER_NAME": "GURLAPUR URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5760,
      "TXT_FINANCIER_NAME": "BHEL EDN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5761,
      "TXT_FINANCIER_NAME": "lessor ge money financial service ltd"
    },
    {
      "NUM_FINANCIER_CD": 5762,
      "TXT_FINANCIER_NAME": "GOLD LINE FINANCE AND LEASING"
    },
    {
      "NUM_FINANCIER_CD": 5763,
      "TXT_FINANCIER_NAME": "MAHA LAKSHMI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5764,
      "TXT_FINANCIER_NAME": "BANK OF NANITAL"
    },
    {
      "NUM_FINANCIER_CD": 5765,
      "TXT_FINANCIER_NAME": "MEGHALAYA COOPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5766,
      "TXT_FINANCIER_NAME": "SHRI MALAI DEVI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5767,
      "TXT_FINANCIER_NAME": "MAKHAMALABAD VIVIDH KARYAKARI SAHAKARI VIKAS SEVA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5768,
      "TXT_FINANCIER_NAME": "KANCHANGANGA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5769,
      "TXT_FINANCIER_NAME": "Spurti Mahila Nagari Pat Sanstha Marydit Vita"
    },
    {
      "NUM_FINANCIER_CD": 5770,
      "TXT_FINANCIER_NAME": "Chintamani Nagari Sah Path San Marydit Chintamani Vita"
    },
    {
      "NUM_FINANCIER_CD": 5771,
      "TXT_FINANCIER_NAME": "KANCHANGANGA NAGARI SAH PATSANSTH MARYADIT NASIK"
    },
    {
      "NUM_FINANCIER_CD": 5772,
      "TXT_FINANCIER_NAME": "UDUPI TQ PROTESTANT CHRISTIAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5773,
      "TXT_FINANCIER_NAME": "SHAHU NAGARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5774,
      "TXT_FINANCIER_NAME": "VARDHAMAN GRAMIN BIGAR SHETI SAH PAT SAN"
    },
    {
      "NUM_FINANCIER_CD": 5775,
      "TXT_FINANCIER_NAME": "SANMOTRA URBAN CO OP BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 5776,
      "TXT_FINANCIER_NAME": "J ANATA URBAN CO OP BANK LTD WAI"
    },
    {
      "NUM_FINANCIER_CD": 5777,
      "TXT_FINANCIER_NAME": "C AND MD ENGINEERS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4976,
      "TXT_FINANCIER_NAME": "BANGALORE CITY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4977,
      "TXT_FINANCIER_NAME": "MALLESWARAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 4978,
      "TXT_FINANCIER_NAME": "INDUS-IND BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4979,
      "TXT_FINANCIER_NAME": "VISNAGAR NAGRIK SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4980,
      "TXT_FINANCIER_NAME": "SURAT DISTRICT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4981,
      "TXT_FINANCIER_NAME": "INDRAPRASTHA SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4982,
      "TXT_FINANCIER_NAME": "SHRI M. VISVESVASRAYA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4983,
      "TXT_FINANCIER_NAME": "VEERASHAIVA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 4984,
      "TXT_FINANCIER_NAME": "THE TEXTILE CO-OP BANK OF SURAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4985,
      "TXT_FINANCIER_NAME": "THE NATIONAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4986,
      "TXT_FINANCIER_NAME": "HINDU NAG.SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4987,
      "TXT_FINANCIER_NAME": "NAMRATA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 4988,
      "TXT_FINANCIER_NAME": "SHRI BHAVKESHWARI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 4989,
      "TXT_FINANCIER_NAME": "VEENA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 4990,
      "TXT_FINANCIER_NAME": "THE PUNNAYOOR PANCHAYATH SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4991,
      "TXT_FINANCIER_NAME": "AAR KAY FINLESS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 4992,
      "TXT_FINANCIER_NAME": "THE CHARADA NAG SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 4993,
      "TXT_FINANCIER_NAME": "RITES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4994,
      "TXT_FINANCIER_NAME": "DEVELOPMENT COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 4995,
      "TXT_FINANCIER_NAME": "INDUSTRIAL FINANCIAL CORPORATION OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 4996,
      "TXT_FINANCIER_NAME": "INDUSTRIAL RECONSTRUCTION BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 4997,
      "TXT_FINANCIER_NAME": "INDUSTRIAL VENTURE CAPITAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 4998,
      "TXT_FINANCIER_NAME": "RAJASTHAN STATE INDUSTRIAL & INVESTMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 4999,
      "TXT_FINANCIER_NAME": "THE ANDAMAN & NICOBAR ISLANDS INTEGRATED INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5000,
      "TXT_FINANCIER_NAME": "THE MEGHALAYA INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5001,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5002,
      "TXT_FINANCIER_NAME": "ONGC Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5003,
      "TXT_FINANCIER_NAME": "Kalahandi Anchalik Gramya Bank"
    },
    {
      "NUM_FINANCIER_CD": 5004,
      "TXT_FINANCIER_NAME": "Maruti Udyog Limited"
    },
    {
      "NUM_FINANCIER_CD": 5005,
      "TXT_FINANCIER_NAME": "GMAC TCFC FIN LTD."
    },
    {
      "NUM_FINANCIER_CD": 5006,
      "TXT_FINANCIER_NAME": "Oshram India Pvt Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5007,
      "TXT_FINANCIER_NAME": "ANYONYA CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5008,
      "TXT_FINANCIER_NAME": "THE GOA URBAN CO-OP BANJ LTD"
    },
    {
      "NUM_FINANCIER_CD": 5009,
      "TXT_FINANCIER_NAME": "BRAKES INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5010,
      "TXT_FINANCIER_NAME": "THE BARODA CITY CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5011,
      "TXT_FINANCIER_NAME": "NIDHI CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5012,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5013,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO OPP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5014,
      "TXT_FINANCIER_NAME": "THE BHARAT CO OP BANK MUMBAI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5015,
      "TXT_FINANCIER_NAME": "THE BHGYODAY CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5016,
      "TXT_FINANCIER_NAME": "CHANDRAPUR-GADCHIROLI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5017,
      "TXT_FINANCIER_NAME": "SIDDHI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5018,
      "TXT_FINANCIER_NAME": "APOLLO FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 5019,
      "TXT_FINANCIER_NAME": "M.P. RAJYA SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 5020,
      "TXT_FINANCIER_NAME": "BUNDELKHAND KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5021,
      "TXT_FINANCIER_NAME": "ASAAM FRONTIER TEA"
    },
    {
      "NUM_FINANCIER_CD": 5022,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CO-OP BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 5023,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LTD GAON BHAG BRANCH SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 5024,
      "TXT_FINANCIER_NAME": "THE HINDU CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5025,
      "TXT_FINANCIER_NAME": "THE MEMON CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5026,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LTD CIVIL HOSPITAL BRANCH SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 5027,
      "TXT_FINANCIER_NAME": "BELVE VYAVASAYA SEVA SAHAKARI BANK BELVE"
    },
    {
      "NUM_FINANCIER_CD": 5028,
      "TXT_FINANCIER_NAME": "IND MERCHAND BANKING SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 5029,
      "TXT_FINANCIER_NAME": "SRI KAMAKSHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5030,
      "TXT_FINANCIER_NAME": "KOLAYAD SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5031,
      "TXT_FINANCIER_NAME": "PAWAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5032,
      "TXT_FINANCIER_NAME": "THE PACHAPUR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5033,
      "TXT_FINANCIER_NAME": "VARADA GRAMEENA"
    },
    {
      "NUM_FINANCIER_CD": 5034,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OPERATIVE CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5035,
      "TXT_FINANCIER_NAME": "ALAVI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5036,
      "TXT_FINANCIER_NAME": "SIRSI URBAN BANK LTD, HUBLI"
    },
    {
      "NUM_FINANCIER_CD": 5037,
      "TXT_FINANCIER_NAME": "HANUMANTH NAGAR CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5038,
      "TXT_FINANCIER_NAME": "THE JINTUR URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5039,
      "TXT_FINANCIER_NAME": "SHIKSHAK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5040,
      "TXT_FINANCIER_NAME": "M D PAWAR PEOPLES CO-OP BANK LTD URUN ISLAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 5041,
      "TXT_FINANCIER_NAME": "JENASEVA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5042,
      "TXT_FINANCIER_NAME": "AMRAVATI MERCHANT CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5043,
      "TXT_FINANCIER_NAME": "THE SAURASHTRA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5044,
      "TXT_FINANCIER_NAME": "BAHUUDESHIYA NAGARI PATHSANSATHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5045,
      "TXT_FINANCIER_NAME": "THE SLM URBON CO-OP"
    },
    {
      "NUM_FINANCIER_CD": 5046,
      "TXT_FINANCIER_NAME": "THE KOPARGAON TALUKA MERCHANT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5047,
      "TXT_FINANCIER_NAME": "A&ampN STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5048,
      "TXT_FINANCIER_NAME": "THE A P VARDHAMAN (MAHILA) CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5049,
      "TXT_FINANCIER_NAME": "CHITRADURGA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5050,
      "TXT_FINANCIER_NAME": "CHITRADURGA GRAMEENA BANK, DAVANGERE"
    },
    {
      "NUM_FINANCIER_CD": 5051,
      "TXT_FINANCIER_NAME": "THE BAGALKOT DISTRICT CENTRAL CO-OP BANK LTD BAGALKOT"
    },
    {
      "NUM_FINANCIER_CD": 5052,
      "TXT_FINANCIER_NAME": "THE MEHSANA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5053,
      "TXT_FINANCIER_NAME": "YADRAV CO-OP.BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5054,
      "TXT_FINANCIER_NAME": "BASAVESWAR URBAN CO-OP BANK LTD, BAGALKOT"
    },
    {
      "NUM_FINANCIER_CD": 5055,
      "TXT_FINANCIER_NAME": "ADARSH MALHAR NAGARI SAHKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5056,
      "TXT_FINANCIER_NAME": "SHRI MAHAVIR NAGRI SAHAKARI PATPEDHI MARYADIT SHAHADA"
    },
    {
      "NUM_FINANCIER_CD": 5057,
      "TXT_FINANCIER_NAME": "MILAT CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 5058,
      "TXT_FINANCIER_NAME": "KALLIYOOR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5059,
      "TXT_FINANCIER_NAME": "CHHATRAPATI NASHIK JILHA NAGARI SAHAKARI PATHASANTHA"
    },
    {
      "NUM_FINANCIER_CD": 5060,
      "TXT_FINANCIER_NAME": "M.P.RAJYA SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5061,
      "TXT_FINANCIER_NAME": "SHUBH-LAXMI MAHILA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5062,
      "TXT_FINANCIER_NAME": "TRANSPORT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5063,
      "TXT_FINANCIER_NAME": "THE VARACHHA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5064,
      "TXT_FINANCIER_NAME": "THE SHIVAJI-NAGAR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5065,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5066,
      "TXT_FINANCIER_NAME": "MANGALORE CATHOLIC CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5067,
      "TXT_FINANCIER_NAME": "KANGRA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5068,
      "TXT_FINANCIER_NAME": "KHATRI CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5069,
      "TXT_FINANCIER_NAME": "PUNE URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5070,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5071,
      "TXT_FINANCIER_NAME": "SADGURU NAG.SAH.BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 5072,
      "TXT_FINANCIER_NAME": "THE CITY CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5073,
      "TXT_FINANCIER_NAME": "SHRI SWAMINARAYAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5074,
      "TXT_FINANCIER_NAME": "UMA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5075,
      "TXT_FINANCIER_NAME": "DABHOI NAG.SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5076,
      "TXT_FINANCIER_NAME": "SULAIMANI CO-OP BANKING SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 5077,
      "TXT_FINANCIER_NAME": "JAIN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5078,
      "TXT_FINANCIER_NAME": "VIDISHA-BHOPAL KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5079,
      "TXT_FINANCIER_NAME": "APEX CO-OP BANK OF URBAN BANKS OF MAHARASHTRA AND GOA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5080,
      "TXT_FINANCIER_NAME": "BHOPAL NAGRIK SAHAKARI BANK LTD., BHOPAL"
    },
    {
      "NUM_FINANCIER_CD": 5081,
      "TXT_FINANCIER_NAME": "SHREE SHARDA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5082,
      "TXT_FINANCIER_NAME": "JANATA CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5083,
      "TXT_FINANCIER_NAME": "PARMATMA EK SEVAK NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5084,
      "TXT_FINANCIER_NAME": "MITRA-MANDAL SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5085,
      "TXT_FINANCIER_NAME": "NASIK PEOPLES CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5086,
      "TXT_FINANCIER_NAME": "SHREE SINNAR VYAPARI SAHAKARI BANK, LTD."
    },
    {
      "NUM_FINANCIER_CD": 5087,
      "TXT_FINANCIER_NAME": "SAPTSHRUNGI MAHILA NAGARI SHAKARI PATHSANTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5088,
      "TXT_FINANCIER_NAME": "MAHA RISHI DAYA NAND UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 5089,
      "TXT_FINANCIER_NAME": "BEEKAY FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 5090,
      "TXT_FINANCIER_NAME": "GIRIDHAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5091,
      "TXT_FINANCIER_NAME": "ADMANUAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5092,
      "TXT_FINANCIER_NAME": "MANGLAM ENTERPRISES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5093,
      "TXT_FINANCIER_NAME": "MADHAV SARAFI SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5094,
      "TXT_FINANCIER_NAME": "The Visnagar Nagrik Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5095,
      "TXT_FINANCIER_NAME": "The Market Yard Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5096,
      "TXT_FINANCIER_NAME": "Sarvoday Comm. Co-operative Bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 5097,
      "TXT_FINANCIER_NAME": "The Kalol Nagrik Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5098,
      "TXT_FINANCIER_NAME": "The Mehsana District Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5099,
      "TXT_FINANCIER_NAME": "Bhagyalaxmi Credit Co-operative Society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5100,
      "TXT_FINANCIER_NAME": "G.E.B"
    },
    {
      "NUM_FINANCIER_CD": 5101,
      "TXT_FINANCIER_NAME": "KERELA STATE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5102,
      "TXT_FINANCIER_NAME": "Kukuda Lamps Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5103,
      "TXT_FINANCIER_NAME": "The Nedungadi Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 5104,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OPERATIVE NEW BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5105,
      "TXT_FINANCIER_NAME": "SHREE CHHANI PATIDAR CO-OP CREDIT SOC. LTD."
    },
    {
      "NUM_FINANCIER_CD": 5106,
      "TXT_FINANCIER_NAME": "A.NAGAR SHAHAR S. BANK LTD.,A.NAGAR"
    },
    {
      "NUM_FINANCIER_CD": 5107,
      "TXT_FINANCIER_NAME": "NLC LTD"
    },
    {
      "NUM_FINANCIER_CD": 5108,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA"
    },
    {
      "NUM_FINANCIER_CD": 5109,
      "TXT_FINANCIER_NAME": "SHIV CHAITANYA NAGARI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5110,
      "TXT_FINANCIER_NAME": "SHREE JANTA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5111,
      "TXT_FINANCIER_NAME": "Tata Info Media"
    },
    {
      "NUM_FINANCIER_CD": 5112,
      "TXT_FINANCIER_NAME": "THE DABHOI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5113,
      "TXT_FINANCIER_NAME": "THE DABHOI NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5114,
      "TXT_FINANCIER_NAME": "NAGAR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5115,
      "TXT_FINANCIER_NAME": "NAGAR URBAN CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 5116,
      "TXT_FINANCIER_NAME": "HSBC"
    },
    {
      "NUM_FINANCIER_CD": 5117,
      "TXT_FINANCIER_NAME": "GOA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5118,
      "TXT_FINANCIER_NAME": "MAHINRDRA & MAHINDRA FIN.SER.LTD"
    },
    {
      "NUM_FINANCIER_CD": 5119,
      "TXT_FINANCIER_NAME": "DHANASHREE COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5120,
      "TXT_FINANCIER_NAME": "SALIL LEASING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 5121,
      "TXT_FINANCIER_NAME": "PUSHPRAJ CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5122,
      "TXT_FINANCIER_NAME": "Mahendra & Mahendra Financial Service Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5123,
      "TXT_FINANCIER_NAME": "GOVT OF MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 5124,
      "TXT_FINANCIER_NAME": "BANK OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 5125,
      "TXT_FINANCIER_NAME": "THE GANESH BANK OF KURUNDWAD LTD"
    },
    {
      "NUM_FINANCIER_CD": 5126,
      "TXT_FINANCIER_NAME": "MADHAV NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5127,
      "TXT_FINANCIER_NAME": "M & M FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5128,
      "TXT_FINANCIER_NAME": "THE KARNAVATI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5129,
      "TXT_FINANCIER_NAME": "THE JODHPUR CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5130,
      "TXT_FINANCIER_NAME": "VASANTRAO CHOUGULE PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5131,
      "TXT_FINANCIER_NAME": "RUCHI LEASING & FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5132,
      "TXT_FINANCIER_NAME": "THE DECCAN CO-OP.URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5133,
      "TXT_FINANCIER_NAME": "MD ENGINEERING I LTD"
    },
    {
      "NUM_FINANCIER_CD": 5134,
      "TXT_FINANCIER_NAME": "THE VAISH COOPERATIVE NEW BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5135,
      "TXT_FINANCIER_NAME": "THE MADHVPURA MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5136,
      "TXT_FINANCIER_NAME": "BOBMAY MERCANTILE COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5137,
      "TXT_FINANCIER_NAME": "SHREE DHARTI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5138,
      "TXT_FINANCIER_NAME": "THE BHARAT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5139,
      "TXT_FINANCIER_NAME": "THE VIJAY CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5140,
      "TXT_FINANCIER_NAME": "MANGAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5141,
      "TXT_FINANCIER_NAME": "RUPEE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5142,
      "TXT_FINANCIER_NAME": "AKRUSHAK NAGRI SAHAKARI PATSANSTHA MARY."
    },
    {
      "NUM_FINANCIER_CD": 5143,
      "TXT_FINANCIER_NAME": "SOUTHERN IRON AND STEEL COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5144,
      "TXT_FINANCIER_NAME": "IRINJALAKKUDA TOWN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5145,
      "TXT_FINANCIER_NAME": "SHIVASHAKTI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5146,
      "TXT_FINANCIER_NAME": "GUARDIAN SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 5147,
      "TXT_FINANCIER_NAME": "s.b.i"
    },
    {
      "NUM_FINANCIER_CD": 5148,
      "TXT_FINANCIER_NAME": "BANGALORE SOUTHARDHA CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5149,
      "TXT_FINANCIER_NAME": "THE MANJOOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5150,
      "TXT_FINANCIER_NAME": "ILKAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5151,
      "TXT_FINANCIER_NAME": "SIRSI SOUHARDA SAHAKARI BANK, SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 5152,
      "TXT_FINANCIER_NAME": "ANYONYA SAHAYAKARI MANDALI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5153,
      "TXT_FINANCIER_NAME": "SHRI SAI NAGRI S.P.S. LTD.,SHIRDI"
    },
    {
      "NUM_FINANCIER_CD": 5154,
      "TXT_FINANCIER_NAME": "SIDDHI CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5155,
      "TXT_FINANCIER_NAME": "N.H.P.C LTD."
    },
    {
      "NUM_FINANCIER_CD": 5156,
      "TXT_FINANCIER_NAME": "THE NASHIK JILHA MAHILA SAHAKARI BANK LTD. NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 5157,
      "TXT_FINANCIER_NAME": "NAV BHARAT BANK"
    },
    {
      "NUM_FINANCIER_CD": 5158,
      "TXT_FINANCIER_NAME": "THE NASHIK ROAD DEOLALI VYAPARI SAHAKARI BANK LID."
    },
    {
      "NUM_FINANCIER_CD": 5159,
      "TXT_FINANCIER_NAME": "SHETKARI SAHAKARI BANK LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 5160,
      "TXT_FINANCIER_NAME": "BHOJPUR GRAMIN"
    },
    {
      "NUM_FINANCIER_CD": 5161,
      "TXT_FINANCIER_NAME": "M/S.CITISORP FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5162,
      "TXT_FINANCIER_NAME": "JANKALYAN CO-OP. BANK LTD. NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 5163,
      "TXT_FINANCIER_NAME": "JANKALYA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5164,
      "TXT_FINANCIER_NAME": "THE POSTAL & RMS EMPLOYEES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5165,
      "TXT_FINANCIER_NAME": "PERUMBAVOOR URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5166,
      "TXT_FINANCIER_NAME": "NAVI MUMBAI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5167,
      "TXT_FINANCIER_NAME": "UBI CHANDGANJ, LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 5168,
      "TXT_FINANCIER_NAME": "THE MARATHA MANOIR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5169,
      "TXT_FINANCIER_NAME": "NANGAVALLI PRIMARY AGRICULTURAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5170,
      "TXT_FINANCIER_NAME": "INDRAYANI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5171,
      "TXT_FINANCIER_NAME": "REGIONAL MEDICAL RESEARCH CENTRE (ICMR)"
    },
    {
      "NUM_FINANCIER_CD": 5172,
      "TXT_FINANCIER_NAME": "K D C C BANK"
    },
    {
      "NUM_FINANCIER_CD": 5173,
      "TXT_FINANCIER_NAME": "KD C C BANK"
    },
    {
      "NUM_FINANCIER_CD": 5174,
      "TXT_FINANCIER_NAME": "LUCKY AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 5175,
      "TXT_FINANCIER_NAME": "PALANIMURUGAN FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5176,
      "TXT_FINANCIER_NAME": "SHRI SHAHU CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5177,
      "TXT_FINANCIER_NAME": "THE RAVI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5178,
      "TXT_FINANCIER_NAME": "SHREE PANCHGANGA NAGARI SAHA. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5179,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5180,
      "TXT_FINANCIER_NAME": "THE NATIONAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5181,
      "TXT_FINANCIER_NAME": "GOA URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5182,
      "TXT_FINANCIER_NAME": "UNITED CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5183,
      "TXT_FINANCIER_NAME": "ROYALE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5184,
      "TXT_FINANCIER_NAME": "ADINATH CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5185,
      "TXT_FINANCIER_NAME": "ROYAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5186,
      "TXT_FINANCIER_NAME": "CITY CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5187,
      "TXT_FINANCIER_NAME": "Hissar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5188,
      "TXT_FINANCIER_NAME": "Kapurthala District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5189,
      "TXT_FINANCIER_NAME": "Nawanshahar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5190,
      "TXT_FINANCIER_NAME": "APANA MOTORS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5191,
      "TXT_FINANCIER_NAME": "SRI VENKATALAXMI MACST LTD"
    },
    {
      "NUM_FINANCIER_CD": 5192,
      "TXT_FINANCIER_NAME": "TIPUSULTAN URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5193,
      "TXT_FINANCIER_NAME": "PHOENIX YULE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5194,
      "TXT_FINANCIER_NAME": "SHRI SAI ARIHANT NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5195,
      "TXT_FINANCIER_NAME": "RAYALASEEMA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5196,
      "TXT_FINANCIER_NAME": "UMIYA NAGAR SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5197,
      "TXT_FINANCIER_NAME": "A.J.FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5198,
      "TXT_FINANCIER_NAME": "THE ORIENTAL INSURANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 5199,
      "TXT_FINANCIER_NAME": "ORISSA STATE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5200,
      "TXT_FINANCIER_NAME": "HSBC MARUTI"
    },
    {
      "NUM_FINANCIER_CD": 5201,
      "TXT_FINANCIER_NAME": "CUTTACK GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5202,
      "TXT_FINANCIER_NAME": "DHENKANAL GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5203,
      "TXT_FINANCIER_NAME": "C.S.E.B."
    },
    {
      "NUM_FINANCIER_CD": 5204,
      "TXT_FINANCIER_NAME": "A.G.B"
    },
    {
      "NUM_FINANCIER_CD": 5205,
      "TXT_FINANCIER_NAME": "BANK"
    },
    {
      "NUM_FINANCIER_CD": 5206,
      "TXT_FINANCIER_NAME": "THE OZAR MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5207,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5208,
      "TXT_FINANCIER_NAME": "CACHAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5209,
      "TXT_FINANCIER_NAME": "L.G.BANK"
    },
    {
      "NUM_FINANCIER_CD": 5210,
      "TXT_FINANCIER_NAME": "MAHENDERA & MAHENDERA FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5211,
      "TXT_FINANCIER_NAME": "SHRI NAVDURUA V.K.S.SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5212,
      "TXT_FINANCIER_NAME": "MANGANESE ORE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5213,
      "TXT_FINANCIER_NAME": "GANESH VYAPARI GRAMIN BIGAR SHETI SAH. J"
    },
    {
      "NUM_FINANCIER_CD": 5214,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FINANCIAL SERVICES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5215,
      "TXT_FINANCIER_NAME": "GANESH VYAPARI GRAMIN BIGAR SHETI SAH.J"
    },
    {
      "NUM_FINANCIER_CD": 5216,
      "TXT_FINANCIER_NAME": "ERNAKULAM DIST.CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5217,
      "TXT_FINANCIER_NAME": "MP RAJYA SHAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5218,
      "TXT_FINANCIER_NAME": "CO- OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5219,
      "TXT_FINANCIER_NAME": "g.c. finance"
    },
    {
      "NUM_FINANCIER_CD": 5220,
      "TXT_FINANCIER_NAME": "THE KUKARWADA NAGARIK BANK"
    },
    {
      "NUM_FINANCIER_CD": 5221,
      "TXT_FINANCIER_NAME": "THE NAGPURDISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5222,
      "TXT_FINANCIER_NAME": "THE NAWANAGAR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5223,
      "TXT_FINANCIER_NAME": "THE KARUR VAISHYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5224,
      "TXT_FINANCIER_NAME": "THE NEW URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5225,
      "TXT_FINANCIER_NAME": "SAPTASHRUNGI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5226,
      "TXT_FINANCIER_NAME": "JAI BHAWANI AUTO LEASING"
    },
    {
      "NUM_FINANCIER_CD": 5227,
      "TXT_FINANCIER_NAME": "KAI NARAYANRAO PAWAR NAG SAH PAT"
    },
    {
      "NUM_FINANCIER_CD": 5228,
      "TXT_FINANCIER_NAME": "THE WAI URBAN CO-OP BANK LTD WAI"
    },
    {
      "NUM_FINANCIER_CD": 5229,
      "TXT_FINANCIER_NAME": "SULAIMANI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5230,
      "TXT_FINANCIER_NAME": "MAHARAJA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5231,
      "TXT_FINANCIER_NAME": "NTPC, AURAIYA"
    },
    {
      "NUM_FINANCIER_CD": 5232,
      "TXT_FINANCIER_NAME": "Pune Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5233,
      "TXT_FINANCIER_NAME": "Saitomo Financial Services Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5234,
      "TXT_FINANCIER_NAME": "SHRI ANAND NAGRI SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5235,
      "TXT_FINANCIER_NAME": "SANGALI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 5236,
      "TXT_FINANCIER_NAME": "THE KALOL URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5237,
      "TXT_FINANCIER_NAME": "ASHOKNAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5238,
      "TXT_FINANCIER_NAME": "ASHOK NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5239,
      "TXT_FINANCIER_NAME": "TPUCB"
    },
    {
      "NUM_FINANCIER_CD": 5240,
      "TXT_FINANCIER_NAME": "SARASWAD"
    },
    {
      "NUM_FINANCIER_CD": 5241,
      "TXT_FINANCIER_NAME": "THE KANCHEEPURAM CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5242,
      "TXT_FINANCIER_NAME": "LAKHIMIGAONLIA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5243,
      "TXT_FINANCIER_NAME": "MHILA UTKARSH NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5244,
      "TXT_FINANCIER_NAME": "SREE VEERSHAIV CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5245,
      "TXT_FINANCIER_NAME": "M/S.K P T C L"
    },
    {
      "NUM_FINANCIER_CD": 5246,
      "TXT_FINANCIER_NAME": "THE A.P.MAHESH CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5247,
      "TXT_FINANCIER_NAME": "THE ADINATH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5248,
      "TXT_FINANCIER_NAME": "M/S.ING-VYSYA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5249,
      "TXT_FINANCIER_NAME": "THE KALOL NAGRIK SAHARIK BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5250,
      "TXT_FINANCIER_NAME": "A.G.I.F NEW DELHI"
    },
    {
      "NUM_FINANCIER_CD": 5251,
      "TXT_FINANCIER_NAME": "BALAJI BANK"
    },
    {
      "NUM_FINANCIER_CD": 5252,
      "TXT_FINANCIER_NAME": "GMAC-TCFC FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5253,
      "TXT_FINANCIER_NAME": "MAHIMA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 5254,
      "TXT_FINANCIER_NAME": "CHANDAN INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 5255,
      "TXT_FINANCIER_NAME": "BARRY WEHMILLER INTERNATIONAL RESOURSEC"
    },
    {
      "NUM_FINANCIER_CD": 5256,
      "TXT_FINANCIER_NAME": "SAMRUDHI CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5257,
      "TXT_FINANCIER_NAME": "THE KARMACHARI CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5258,
      "TXT_FINANCIER_NAME": "THE RAJKOT MERCANTILE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5259,
      "TXT_FINANCIER_NAME": "shree sai auto finance"
    },
    {
      "NUM_FINANCIER_CD": 5260,
      "TXT_FINANCIER_NAME": "UTTRAKHAND CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5261,
      "TXT_FINANCIER_NAME": "FARMERS CO OPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5262,
      "TXT_FINANCIER_NAME": "THE LAXMI CO -OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5263,
      "TXT_FINANCIER_NAME": "The Laxmi Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5264,
      "TXT_FINANCIER_NAME": "THE BELGAUM URBAN CO-OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5265,
      "TXT_FINANCIER_NAME": "SHRI SIDHDI VENKATESH SAHAKARI BANK LTD.JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 5266,
      "TXT_FINANCIER_NAME": "PSSsppppp`1"
    },
    {
      "NUM_FINANCIER_CD": 5267,
      "TXT_FINANCIER_NAME": "THE LIC OF INDIA STAFF CO-OPERATIVE BANK LTD NO.3314"
    },
    {
      "NUM_FINANCIER_CD": 5268,
      "TXT_FINANCIER_NAME": "SULAIMANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5269,
      "TXT_FINANCIER_NAME": "THE CHENGAROOR SERVICE CO. OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5270,
      "TXT_FINANCIER_NAME": "THE CHENGAROOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5271,
      "TXT_FINANCIER_NAME": "THE OTTOOR SERVICE CO-OPERATIVE BANK LTD NO.T.976"
    },
    {
      "NUM_FINANCIER_CD": 5272,
      "TXT_FINANCIER_NAME": "FIROZPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5273,
      "TXT_FINANCIER_NAME": "THE AJRA URBAN CO-OP. BANK LTD., AJRA"
    },
    {
      "NUM_FINANCIER_CD": 5274,
      "TXT_FINANCIER_NAME": "KRANTHI BANK"
    },
    {
      "NUM_FINANCIER_CD": 5275,
      "TXT_FINANCIER_NAME": "BHAVASARA KSHATRIYA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5276,
      "TXT_FINANCIER_NAME": "THE MEHSANA DISTRICT CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5277,
      "TXT_FINANCIER_NAME": "SHREE SAVARKUNDLA NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5278,
      "TXT_FINANCIER_NAME": "ELUMALAIYAN MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5279,
      "TXT_FINANCIER_NAME": "SRI KALIKAMBA PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 5280,
      "TXT_FINANCIER_NAME": "NADAKKUTHAZHA SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5281,
      "TXT_FINANCIER_NAME": "Dungarpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5282,
      "TXT_FINANCIER_NAME": "Jhunjhunu District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5283,
      "TXT_FINANCIER_NAME": "Banaskantha District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5284,
      "TXT_FINANCIER_NAME": "Ahmednagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5285,
      "TXT_FINANCIER_NAME": "Osmanabad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5286,
      "TXT_FINANCIER_NAME": "Mizoram State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5287,
      "TXT_FINANCIER_NAME": "Balasore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5288,
      "TXT_FINANCIER_NAME": "Berhampur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5289,
      "TXT_FINANCIER_NAME": "Sundargarh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5290,
      "TXT_FINANCIER_NAME": "United Puri & Nimtara District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5291,
      "TXT_FINANCIER_NAME": "KAILAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5292,
      "TXT_FINANCIER_NAME": "SREE SIVABALAN MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5293,
      "TXT_FINANCIER_NAME": "M P STATE COOPERATIVE AGRICULTURE AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 5294,
      "TXT_FINANCIER_NAME": "RELIABLE INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 5295,
      "TXT_FINANCIER_NAME": "SHRI BALRAM GRAMIN BIGARSHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5296,
      "TXT_FINANCIER_NAME": "MEWAR AANCHLIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5297,
      "TXT_FINANCIER_NAME": "THE JYOTI CO-OP. , NTC SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5298,
      "TXT_FINANCIER_NAME": "KANGRA CO-OPRATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5299,
      "TXT_FINANCIER_NAME": "ACROHA CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5300,
      "TXT_FINANCIER_NAME": "SARVODAY COMMERCIAL CO- OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5301,
      "TXT_FINANCIER_NAME": "SUBANSIRI GAONLIA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5302,
      "TXT_FINANCIER_NAME": "GOVT. TREASURY BANK"
    },
    {
      "NUM_FINANCIER_CD": 5303,
      "TXT_FINANCIER_NAME": "Farukhabad Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 5304,
      "TXT_FINANCIER_NAME": "SOLAPUR JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5305,
      "TXT_FINANCIER_NAME": "THE CHNASAMA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5306,
      "TXT_FINANCIER_NAME": "Bhagalpur Banka Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 5307,
      "TXT_FINANCIER_NAME": "ASHOKA LEYLAND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5308,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 5309,
      "TXT_FINANCIER_NAME": "SECRETARY I.C.A.R."
    },
    {
      "NUM_FINANCIER_CD": 5310,
      "TXT_FINANCIER_NAME": "THE KAROOR VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5311,
      "TXT_FINANCIER_NAME": "JANATA SAH. BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 5312,
      "TXT_FINANCIER_NAME": "BEML"
    },
    {
      "NUM_FINANCIER_CD": 5313,
      "TXT_FINANCIER_NAME": "ARMY GROUP INS."
    },
    {
      "NUM_FINANCIER_CD": 5314,
      "TXT_FINANCIER_NAME": "SULAIMANI CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5315,
      "TXT_FINANCIER_NAME": "THE SUVIKAS PEOPLE S CO OF BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5316,
      "TXT_FINANCIER_NAME": "THE SUVIKAS PEOPLE S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5317,
      "TXT_FINANCIER_NAME": "THE BARODA TRADERS CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5318,
      "TXT_FINANCIER_NAME": "NAGAR SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5319,
      "TXT_FINANCIER_NAME": "UMA CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5320,
      "TXT_FINANCIER_NAME": "ONGC TRIPURA ASSET"
    },
    {
      "NUM_FINANCIER_CD": 5321,
      "TXT_FINANCIER_NAME": "AP MAHESH CO-OP"
    },
    {
      "NUM_FINANCIER_CD": 5322,
      "TXT_FINANCIER_NAME": "ASHOKA LEYLAND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 5323,
      "TXT_FINANCIER_NAME": "THE N.K.G.S.B BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5324,
      "TXT_FINANCIER_NAME": "THE SANJELI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5325,
      "TXT_FINANCIER_NAME": "THE REVDANDA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5326,
      "TXT_FINANCIER_NAME": "ISMAILIYA URBAN CO BANK"
    },
    {
      "NUM_FINANCIER_CD": 5327,
      "TXT_FINANCIER_NAME": "SHREE SANT EKNATH MAHARAJ G.B.S.S.P.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 5328,
      "TXT_FINANCIER_NAME": "ANYONYA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5329,
      "TXT_FINANCIER_NAME": "THE KUKERWADA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5330,
      "TXT_FINANCIER_NAME": "Maharashtra Brahman Sahakari Bank Ltd., Indore"
    },
    {
      "NUM_FINANCIER_CD": 5331,
      "TXT_FINANCIER_NAME": "THE VISHWESHAR SAHAKARI BANK LTD .PUNE"
    },
    {
      "NUM_FINANCIER_CD": 5332,
      "TXT_FINANCIER_NAME": "THE NORTH KANARA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5333,
      "TXT_FINANCIER_NAME": "THE NORTH KANARA G S B CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5334,
      "TXT_FINANCIER_NAME": "THE SAFE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5335,
      "TXT_FINANCIER_NAME": "JANAVIKAS URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5336,
      "TXT_FINANCIER_NAME": "RAHURI TALUKA VYAPATI NAGRI S.P.S LTD."
    },
    {
      "NUM_FINANCIER_CD": 5337,
      "TXT_FINANCIER_NAME": "SHREE VARDHMAN SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5338,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OPCENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5339,
      "TXT_FINANCIER_NAME": "THE MAHAD CO-OP URBAN BANK LTD.,MAHAD"
    },
    {
      "NUM_FINANCIER_CD": 5340,
      "TXT_FINANCIER_NAME": "THE MAHAD CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5341,
      "TXT_FINANCIER_NAME": "SAMTA SAHAKARI BANK SEEMIT"
    },
    {
      "NUM_FINANCIER_CD": 5342,
      "TXT_FINANCIER_NAME": "GANESH FINSTOCK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5343,
      "TXT_FINANCIER_NAME": "SHRI BHARAT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5344,
      "TXT_FINANCIER_NAME": "KHAMGAON UR.CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5345,
      "TXT_FINANCIER_NAME": "HINDUSTAN CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5346,
      "TXT_FINANCIER_NAME": "RENUKA GRAMIN BIGER SHETI SAHAKATI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5347,
      "TXT_FINANCIER_NAME": "ANCHAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5348,
      "TXT_FINANCIER_NAME": "DR.FERNADES CO OP CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5349,
      "TXT_FINANCIER_NAME": "DR FERNANDES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5350,
      "TXT_FINANCIER_NAME": "THE BHULDHANA DISTRICT CENTRAL CO-OP BANK LIMITED BHULDHANA"
    },
    {
      "NUM_FINANCIER_CD": 5351,
      "TXT_FINANCIER_NAME": "The Buldana Distrit Central C0- Op. Bank Ltd.Buldana"
    },
    {
      "NUM_FINANCIER_CD": 5352,
      "TXT_FINANCIER_NAME": "THE VALAVOOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5353,
      "TXT_FINANCIER_NAME": "GANGA YAMMUNA GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5354,
      "TXT_FINANCIER_NAME": "DHANALEKSHMI BANK"
    },
    {
      "NUM_FINANCIER_CD": 5355,
      "TXT_FINANCIER_NAME": "SHREE ADINATH CO-OP BANK LTD ICHALKARANJI"
    },
    {
      "NUM_FINANCIER_CD": 5356,
      "TXT_FINANCIER_NAME": "THIRUVANTHAPURAM DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5357,
      "TXT_FINANCIER_NAME": "PROGRESSIVE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5358,
      "TXT_FINANCIER_NAME": "Maharashtra Bahmin Sah. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5359,
      "TXT_FINANCIER_NAME": "SHUSHRUTHI SOUHARDA SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 5360,
      "TXT_FINANCIER_NAME": "THE SHOLAPUR CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5361,
      "TXT_FINANCIER_NAME": "SHREE JADADGURU RENUKACHARYA CO-OP CREDIT SOCIETY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5362,
      "TXT_FINANCIER_NAME": "SHREE JAGADGURU RENUKACHARYA CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5363,
      "TXT_FINANCIER_NAME": "KANJIRAPPALLY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5364,
      "TXT_FINANCIER_NAME": "JAIN CO-OPRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5365,
      "TXT_FINANCIER_NAME": "The Eenadu Co-operative Urban Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5366,
      "TXT_FINANCIER_NAME": "THE PATTAM COLONY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5367,
      "TXT_FINANCIER_NAME": "SUVERNAYUG SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5368,
      "TXT_FINANCIER_NAME": "THE PACHORA PEOPLES CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5369,
      "TXT_FINANCIER_NAME": "MAAHTMA FULE DIST. UR. CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5370,
      "TXT_FINANCIER_NAME": "THANE PRERNA SAHAKARI PATPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 5371,
      "TXT_FINANCIER_NAME": "MAJALI SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5372,
      "TXT_FINANCIER_NAME": "THE VARANGA CO OPERATIVE AGRICULTURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5373,
      "TXT_FINANCIER_NAME": "Vidyasagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5374,
      "TXT_FINANCIER_NAME": "Kakinada(E.G District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5375,
      "TXT_FINANCIER_NAME": "Visakhapatnam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5376,
      "TXT_FINANCIER_NAME": "Shimoga District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5377,
      "TXT_FINANCIER_NAME": "Cuddalore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5378,
      "TXT_FINANCIER_NAME": "Kumbakonam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5379,
      "TXT_FINANCIER_NAME": "Thanjavur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5380,
      "TXT_FINANCIER_NAME": "Ramnathpuram District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5381,
      "TXT_FINANCIER_NAME": "Virudhunagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5382,
      "TXT_FINANCIER_NAME": "Export - Import Bank of India"
    },
    {
      "NUM_FINANCIER_CD": 5383,
      "TXT_FINANCIER_NAME": "RAHIMATPUR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5384,
      "TXT_FINANCIER_NAME": "SHRI SIDDHIVINAYAK NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5385,
      "TXT_FINANCIER_NAME": "VEERA SECURITIES AND FINLEASE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5386,
      "TXT_FINANCIER_NAME": "THE BANHATTI MINORITY URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5387,
      "TXT_FINANCIER_NAME": "GOODWILL FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5388,
      "TXT_FINANCIER_NAME": "G E FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 5389,
      "TXT_FINANCIER_NAME": "VITTA VINIMOY VINIYOG PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5390,
      "TXT_FINANCIER_NAME": "ANYONYA CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5391,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FINANCIAL SERVICES INDIA PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 5392,
      "TXT_FINANCIER_NAME": "URBAN CO OP CREDIT SOC (VARADHARI)"
    },
    {
      "NUM_FINANCIER_CD": 5393,
      "TXT_FINANCIER_NAME": "J.N.S BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 5394,
      "TXT_FINANCIER_NAME": "JNS BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 5395,
      "TXT_FINANCIER_NAME": "ALIGARH GRAMEN. BANK."
    },
    {
      "NUM_FINANCIER_CD": 5396,
      "TXT_FINANCIER_NAME": "THE CO-OP. CITY BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5397,
      "TXT_FINANCIER_NAME": "TH CO-OPERATIVE BANK OF MEHSANA LTD."
    },
    {
      "NUM_FINANCIER_CD": 5398,
      "TXT_FINANCIER_NAME": "ap coop apex banl ltd"
    },
    {
      "NUM_FINANCIER_CD": 5399,
      "TXT_FINANCIER_NAME": "AP COOP APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5400,
      "TXT_FINANCIER_NAME": "ALAVI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5401,
      "TXT_FINANCIER_NAME": "ANYONYA CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5402,
      "TXT_FINANCIER_NAME": "GE CORP MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5403,
      "TXT_FINANCIER_NAME": "THE UNITED CO.OPERATIVE BANK LTDK"
    },
    {
      "NUM_FINANCIER_CD": 5404,
      "TXT_FINANCIER_NAME": "NATIONAL CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5405,
      "TXT_FINANCIER_NAME": "M/S.POWERGRID CORP OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 5406,
      "TXT_FINANCIER_NAME": "BANK MUSCAT"
    },
    {
      "NUM_FINANCIER_CD": 5407,
      "TXT_FINANCIER_NAME": "M.S.CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5408,
      "TXT_FINANCIER_NAME": "KARNAWATI CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5409,
      "TXT_FINANCIER_NAME": "The Oriental Insurance Co.Ltd."
    },
    {
      "NUM_FINANCIER_CD": 5410,
      "TXT_FINANCIER_NAME": "Indore-Ujjain Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 5411,
      "TXT_FINANCIER_NAME": "KANHOOR PATHARI CO-OP SOCI. LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 5412,
      "TXT_FINANCIER_NAME": "KANCHAN KANWAR BAGHMAR,CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 5413,
      "TXT_FINANCIER_NAME": "HPCL BR.BHILAWADI"
    },
    {
      "NUM_FINANCIER_CD": 5414,
      "TXT_FINANCIER_NAME": "THE MANSA NAG.SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5415,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MER. CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5416,
      "TXT_FINANCIER_NAME": "THE DABHOI NAG.SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5417,
      "TXT_FINANCIER_NAME": "M.K.G. BANK"
    },
    {
      "NUM_FINANCIER_CD": 5418,
      "TXT_FINANCIER_NAME": "KOPERGAON PEOPLES CO-OP BANK LTD., KOPERGAON"
    },
    {
      "NUM_FINANCIER_CD": 5419,
      "TXT_FINANCIER_NAME": "REVENUE DEPT HR CHD"
    },
    {
      "NUM_FINANCIER_CD": 5420,
      "TXT_FINANCIER_NAME": "EVERGLOW INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5421,
      "TXT_FINANCIER_NAME": "Dr. ANNASAHEB CHOUGLE URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5422,
      "TXT_FINANCIER_NAME": "BHANDARI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5423,
      "TXT_FINANCIER_NAME": "LOKNETE DATTAJI PATIL SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5424,
      "TXT_FINANCIER_NAME": "ASSOCIATED FINANCE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 5425,
      "TXT_FINANCIER_NAME": "ASSOCIATED FINANCE SERVICES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5426,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES FIN SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5427,
      "TXT_FINANCIER_NAME": "NIRMAL UJWAL CREDIT CO-OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5428,
      "TXT_FINANCIER_NAME": "HARYANA GOVT."
    },
    {
      "NUM_FINANCIER_CD": 5429,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OPERATIVE ADARSH BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5430,
      "TXT_FINANCIER_NAME": "THE A.P.MAHESH CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5431,
      "TXT_FINANCIER_NAME": "The Amravati Central Co-Op Bank"
    },
    {
      "NUM_FINANCIER_CD": 5432,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FIN. SERV. LTD"
    },
    {
      "NUM_FINANCIER_CD": 5433,
      "TXT_FINANCIER_NAME": "THE ASHTA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5434,
      "TXT_FINANCIER_NAME": "THE KALWAN MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5435,
      "TXT_FINANCIER_NAME": "THE DR.PUNJABRAO DESHMUKH URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5436,
      "TXT_FINANCIER_NAME": "THE DELHI NAGRIK SEHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5437,
      "TXT_FINANCIER_NAME": "SAITOMO FIN SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 5438,
      "TXT_FINANCIER_NAME": "KRISHNAI NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5439,
      "TXT_FINANCIER_NAME": "THE NATIONAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5440,
      "TXT_FINANCIER_NAME": "THE NAINI TAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5441,
      "TXT_FINANCIER_NAME": "THE SARAGPUR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5442,
      "TXT_FINANCIER_NAME": "SHREE KADI NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5443,
      "TXT_FINANCIER_NAME": "THE HONKONG AND SHANGHAI BANKING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 5444,
      "TXT_FINANCIER_NAME": "NIDHI CO.OP.BANK LIMLTED"
    },
    {
      "NUM_FINANCIER_CD": 5445,
      "TXT_FINANCIER_NAME": "NALCO"
    },
    {
      "NUM_FINANCIER_CD": 5446,
      "TXT_FINANCIER_NAME": "SHUBHLAKSHMI MAHILA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5447,
      "TXT_FINANCIER_NAME": "PRESIDENT BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 5448,
      "TXT_FINANCIER_NAME": "RAMANI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5449,
      "TXT_FINANCIER_NAME": "GURUWARYA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5450,
      "TXT_FINANCIER_NAME": "VIRACHARYA BABASAHEB KACHNURE ZILLA NAGARI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5451,
      "TXT_FINANCIER_NAME": "SHRI MAHAVIR SAKH SAH MARYADIT KHETIYA"
    },
    {
      "NUM_FINANCIER_CD": 5452,
      "TXT_FINANCIER_NAME": "THE SURENDRANGAR MERCANTILE CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5453,
      "TXT_FINANCIER_NAME": "ISRO,SDSC"
    },
    {
      "NUM_FINANCIER_CD": 5454,
      "TXT_FINANCIER_NAME": "CHAUNDESHWARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5455,
      "TXT_FINANCIER_NAME": "SHRI MAHESH CO OP BANK AURANGABAD LTD"
    },
    {
      "NUM_FINANCIER_CD": 5456,
      "TXT_FINANCIER_NAME": "sarad finance"
    },
    {
      "NUM_FINANCIER_CD": 5457,
      "TXT_FINANCIER_NAME": "KERALA GAZATTED OFFICERS CO-OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5458,
      "TXT_FINANCIER_NAME": "KUMBAZHA SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5459,
      "TXT_FINANCIER_NAME": "GMAC 20TH CENTURY FINANCE CORPN.LTD."
    },
    {
      "NUM_FINANCIER_CD": 5460,
      "TXT_FINANCIER_NAME": "MALAYALA MANORAMA CO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 5461,
      "TXT_FINANCIER_NAME": "THEK UNITED WESTERN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5462,
      "TXT_FINANCIER_NAME": "BOMBAY MARCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5463,
      "TXT_FINANCIER_NAME": "TAMBARAM CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5464,
      "TXT_FINANCIER_NAME": "THE MENON CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5465,
      "TXT_FINANCIER_NAME": "THE AZAD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5466,
      "TXT_FINANCIER_NAME": "CENTRAL COOPERATIVE BANK REWARI"
    },
    {
      "NUM_FINANCIER_CD": 5467,
      "TXT_FINANCIER_NAME": "JAI HIND CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5468,
      "TXT_FINANCIER_NAME": "MUNICIPAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5469,
      "TXT_FINANCIER_NAME": "THE A.P. MAHESH CO-OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5470,
      "TXT_FINANCIER_NAME": "CANACONA URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5471,
      "TXT_FINANCIER_NAME": "THE SAURASHTRA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5472,
      "TXT_FINANCIER_NAME": "M S CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5473,
      "TXT_FINANCIER_NAME": "SUBRAMANIA NAGAR CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5474,
      "TXT_FINANCIER_NAME": "THE KHATTRI CO-OPERATION"
    },
    {
      "NUM_FINANCIER_CD": 5475,
      "TXT_FINANCIER_NAME": "SHAMRAO VITAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5476,
      "TXT_FINANCIER_NAME": "PAMPAKUDA SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5477,
      "TXT_FINANCIER_NAME": "ALAKNANDA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5478,
      "TXT_FINANCIER_NAME": "JAI-HIND CO-OPERATIVE BANK LTD., AMBARNATH"
    },
    {
      "NUM_FINANCIER_CD": 5479,
      "TXT_FINANCIER_NAME": "SHREE GANESH SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5480,
      "TXT_FINANCIER_NAME": "THE NEDUMKANDAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5481,
      "TXT_FINANCIER_NAME": "THE A.P. VARDHMAN (MAHILA) CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5482,
      "TXT_FINANCIER_NAME": "CHIRAKADAVU SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5483,
      "TXT_FINANCIER_NAME": "NAGPUR NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5484,
      "TXT_FINANCIER_NAME": "NAGPUR NAGARIK SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5485,
      "TXT_FINANCIER_NAME": "THE MADASANA GROUP SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5486,
      "TXT_FINANCIER_NAME": "LAXMI DURGA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5487,
      "TXT_FINANCIER_NAME": "VAIRAMS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5488,
      "TXT_FINANCIER_NAME": "MANIAM FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 5489,
      "TXT_FINANCIER_NAME": "THERADHASOAMIURBANCOOPBANK"
    },
    {
      "NUM_FINANCIER_CD": 5490,
      "TXT_FINANCIER_NAME": "GMAC FIN SER INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5491,
      "TXT_FINANCIER_NAME": "Glaxo Smithkline Consumer Healthcare"
    },
    {
      "NUM_FINANCIER_CD": 5492,
      "TXT_FINANCIER_NAME": "P C A R D BANK"
    },
    {
      "NUM_FINANCIER_CD": 5493,
      "TXT_FINANCIER_NAME": "AKSHAYA ENTERPRISES REGD"
    },
    {
      "NUM_FINANCIER_CD": 5494,
      "TXT_FINANCIER_NAME": "SBI NAWADA VIHAR"
    },
    {
      "NUM_FINANCIER_CD": 5495,
      "TXT_FINANCIER_NAME": "GOVT OF BIHAR"
    },
    {
      "NUM_FINANCIER_CD": 5496,
      "TXT_FINANCIER_NAME": "AMCO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5497,
      "TXT_FINANCIER_NAME": "The Oriental Insurance Co Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5498,
      "TXT_FINANCIER_NAME": "MANANJE VYAVASAYA SEVA SAHAKARI BANK MANANJE"
    },
    {
      "NUM_FINANCIER_CD": 5499,
      "TXT_FINANCIER_NAME": "THE PRESIDENT GOVTOFINDIAISROSDSC"
    },
    {
      "NUM_FINANCIER_CD": 5500,
      "TXT_FINANCIER_NAME": "JSW STEEL EMPLOYEES CO OP THIEFT AND CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5501,
      "TXT_FINANCIER_NAME": "JSW STEEL EMPLOYEES CO OP THRIFT AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5502,
      "TXT_FINANCIER_NAME": "MADGAON URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5503,
      "TXT_FINANCIER_NAME": "AMPAR CO OP AGRICULTURAL BANK LTD AMPAR"
    },
    {
      "NUM_FINANCIER_CD": 5504,
      "TXT_FINANCIER_NAME": "SHRI NAVADURGA V K S S SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5505,
      "TXT_FINANCIER_NAME": "HARDOI ZILA SAHKARI"
    },
    {
      "NUM_FINANCIER_CD": 5506,
      "TXT_FINANCIER_NAME": "KANNIKA PARAMESHWARI CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5507,
      "TXT_FINANCIER_NAME": "THE SHIRODA URBAN COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 5508,
      "TXT_FINANCIER_NAME": "CARGILL GLOBAL TRADING INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5509,
      "TXT_FINANCIER_NAME": "A P GEN CO"
    },
    {
      "NUM_FINANCIER_CD": 5510,
      "TXT_FINANCIER_NAME": "BALOTRA SHAKARI BHUMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 5511,
      "TXT_FINANCIER_NAME": "SAUNSHI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5512,
      "TXT_FINANCIER_NAME": "SHIRUR GRAMIN BIGERSHITI PATSANSTHA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5513,
      "TXT_FINANCIER_NAME": "K D C C BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5514,
      "TXT_FINANCIER_NAME": "CHHATRAPATI SHIVAJI VAHAN DHARAK NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5515,
      "TXT_FINANCIER_NAME": "SRI NETHAJI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5516,
      "TXT_FINANCIER_NAME": "THE PB STATE CO OP AGRICULTURE DEV BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5517,
      "TXT_FINANCIER_NAME": "SHIROOR CO OP CREDIT SOCIETY LTD SHIROOR"
    },
    {
      "NUM_FINANCIER_CD": 5518,
      "TXT_FINANCIER_NAME": "SHIROOR CO OP CRDIT SOCITY LTD SHIROOR"
    },
    {
      "NUM_FINANCIER_CD": 5519,
      "TXT_FINANCIER_NAME": "THE KODAGU DISTRICT CO OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5520,
      "TXT_FINANCIER_NAME": "HASANAGI GROUP GRAMAGALA SEVA SAHAKARI SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 5521,
      "TXT_FINANCIER_NAME": "SHREE MATA COOP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5522,
      "TXT_FINANCIER_NAME": "C S I CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5523,
      "TXT_FINANCIER_NAME": "PWD B AND R HAYANA"
    },
    {
      "NUM_FINANCIER_CD": 5524,
      "TXT_FINANCIER_NAME": "H S I I D C LTD"
    },
    {
      "NUM_FINANCIER_CD": 5525,
      "TXT_FINANCIER_NAME": "BASSIEN CATHOLIC CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5526,
      "TXT_FINANCIER_NAME": "BAPUJIBUWA NAGARI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5527,
      "TXT_FINANCIER_NAME": "LAXMI KRUPA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5528,
      "TXT_FINANCIER_NAME": "The Khatri Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 5529,
      "TXT_FINANCIER_NAME": "BIBLE SOCIETY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 5530,
      "TXT_FINANCIER_NAME": "VAPAR UDHYOG VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5531,
      "TXT_FINANCIER_NAME": "NARAYANAGURU URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5532,
      "TXT_FINANCIER_NAME": "COSMOS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5533,
      "TXT_FINANCIER_NAME": "PAPPINISSERY VANITHA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5534,
      "TXT_FINANCIER_NAME": "THE GREATER BOMABY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5535,
      "TXT_FINANCIER_NAME": "THE KUNHIMANGALAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5536,
      "TXT_FINANCIER_NAME": "NEDUMATTOM SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5537,
      "TXT_FINANCIER_NAME": "M & M FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 5538,
      "TXT_FINANCIER_NAME": "CHIRAYINKIL SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5539,
      "TXT_FINANCIER_NAME": "THE ANJARAKANDY FARMERS SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5540,
      "TXT_FINANCIER_NAME": "MARUTI CO-OPERATIVE CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 5541,
      "TXT_FINANCIER_NAME": "THE VEISH CO- OP NEW BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5542,
      "TXT_FINANCIER_NAME": "THE IRINAVE SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5543,
      "TXT_FINANCIER_NAME": "GOVERMENT OF GUJARAT"
    },
    {
      "NUM_FINANCIER_CD": 5544,
      "TXT_FINANCIER_NAME": "SHRI VIJAYALAKSHMI SOUDHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 5545,
      "TXT_FINANCIER_NAME": "punnayoorkulam service co-operative bank limited"
    },
    {
      "NUM_FINANCIER_CD": 5546,
      "TXT_FINANCIER_NAME": "Co-operation bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 5547,
      "TXT_FINANCIER_NAME": "ITHITHANAM SERVICE CO-OPERATIVE BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 5548,
      "TXT_FINANCIER_NAME": "THE DIST CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5549,
      "TXT_FINANCIER_NAME": "PCMC"
    },
    {
      "NUM_FINANCIER_CD": 5550,
      "TXT_FINANCIER_NAME": "LIC AGENTS CO OPORATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5551,
      "TXT_FINANCIER_NAME": "KELAKAM SERVICE CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5552,
      "TXT_FINANCIER_NAME": "DR.ANNASAHEB CHOUGULE URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5553,
      "TXT_FINANCIER_NAME": "MAVOOR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5554,
      "TXT_FINANCIER_NAME": "ILKAL CO - OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 5555,
      "TXT_FINANCIER_NAME": "BHAVANI BANK"
    },
    {
      "NUM_FINANCIER_CD": 5556,
      "TXT_FINANCIER_NAME": "THE PANG SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5557,
      "TXT_FINANCIER_NAME": "THE THOTGARS CO OPERATIVE SALE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5558,
      "TXT_FINANCIER_NAME": "G P O"
    },
    {
      "NUM_FINANCIER_CD": 5559,
      "TXT_FINANCIER_NAME": "SAMATA CO-OP DEV BANK"
    },
    {
      "NUM_FINANCIER_CD": 5560,
      "TXT_FINANCIER_NAME": "THE ATHIRAMPUZHA REGIONAL SERVICE CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5561,
      "TXT_FINANCIER_NAME": "PUSHPAK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5562,
      "TXT_FINANCIER_NAME": "VERDHMAN GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5563,
      "TXT_FINANCIER_NAME": "SAHAKARNAGAR NAGARI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5564,
      "TXT_FINANCIER_NAME": "SUPER ROYAL FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 5565,
      "TXT_FINANCIER_NAME": "PITHORAGARH DISTT. CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5566,
      "TXT_FINANCIER_NAME": "KOTTAKKAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5567,
      "TXT_FINANCIER_NAME": "SHREE CHATRAPATI RAJSHREE SHAHU URBAN CO-OP. BANK LTD.BEED"
    },
    {
      "NUM_FINANCIER_CD": 5568,
      "TXT_FINANCIER_NAME": "BASTI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5569,
      "TXT_FINANCIER_NAME": "DAK GHAR BACHAT BANK"
    },
    {
      "NUM_FINANCIER_CD": 5570,
      "TXT_FINANCIER_NAME": "THE THANALAKSHMI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5571,
      "TXT_FINANCIER_NAME": "CHANGARAMKULAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5572,
      "TXT_FINANCIER_NAME": "MAHAMEDHA URBEN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5573,
      "TXT_FINANCIER_NAME": "THE KOTTAKKAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5574,
      "TXT_FINANCIER_NAME": "THE GURGAON CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5575,
      "TXT_FINANCIER_NAME": "THE PAYYAVOOR SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5576,
      "TXT_FINANCIER_NAME": "AIRPOTS AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 5577,
      "TXT_FINANCIER_NAME": "indian bank."
    },
    {
      "NUM_FINANCIER_CD": 5578,
      "TXT_FINANCIER_NAME": "THE AP VARDHAMAN (MAHILA) CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5579,
      "TXT_FINANCIER_NAME": "THE HYD. DIST. CO-OP CENTRAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 5580,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYABANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5581,
      "TXT_FINANCIER_NAME": "THE DADASAHEB RAWAL CO-OP BANK OF DONDAI"
    },
    {
      "NUM_FINANCIER_CD": 5582,
      "TXT_FINANCIER_NAME": "THE JAWHAR URBAN CO.-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5583,
      "TXT_FINANCIER_NAME": "KASHI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5584,
      "TXT_FINANCIER_NAME": "ARYAVRART GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5585,
      "TXT_FINANCIER_NAME": "THE SARVODAYA CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5586,
      "TXT_FINANCIER_NAME": "PADINHARATHARA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5587,
      "TXT_FINANCIER_NAME": "THE KASARAGOD DISTRICT CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5588,
      "TXT_FINANCIER_NAME": "Jankalyan Nagari Sahakari Patsanstha Marya; Karad"
    },
    {
      "NUM_FINANCIER_CD": 5589,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP.CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5590,
      "TXT_FINANCIER_NAME": "THE VATTAMKULAM PANCHAYATH SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5591,
      "TXT_FINANCIER_NAME": "CHITTORGARH SAHAKARI BHUMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5592,
      "TXT_FINANCIER_NAME": "SREERAM TRANSPORT FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 5593,
      "TXT_FINANCIER_NAME": "WADA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5594,
      "TXT_FINANCIER_NAME": "KUMAR FINANCIERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 5595,
      "TXT_FINANCIER_NAME": "ERUMAD PRIMARY AGRI CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5596,
      "TXT_FINANCIER_NAME": "MCF"
    },
    {
      "NUM_FINANCIER_CD": 5597,
      "TXT_FINANCIER_NAME": "MCF FINLEASE PVT LRD"
    },
    {
      "NUM_FINANCIER_CD": 5598,
      "TXT_FINANCIER_NAME": "MAMTHA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 5599,
      "TXT_FINANCIER_NAME": "HASIMAL SISODIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 5600,
      "TXT_FINANCIER_NAME": "KERI URBAN COOPERATIVE CREDIT STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5601,
      "TXT_FINANCIER_NAME": "GHIYA MAMODIA FIN AND INVESTMENT CONS P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5602,
      "TXT_FINANCIER_NAME": "DURGAPARAMESHWARI CREDIT COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5603,
      "TXT_FINANCIER_NAME": "KERI URBAN CREDIT COOPRATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5604,
      "TXT_FINANCIER_NAME": "MEENACHIL TALUK CO OP EMPLOYEES CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5605,
      "TXT_FINANCIER_NAME": "SREE CHARAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5606,
      "TXT_FINANCIER_NAME": "Ashtavinayak Nagari Sahakari Path Sanstha"
    },
    {
      "NUM_FINANCIER_CD": 5607,
      "TXT_FINANCIER_NAME": "THE SAVALIA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5608,
      "TXT_FINANCIER_NAME": "DNAYANDEEP CO-OP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 5609,
      "TXT_FINANCIER_NAME": "KAMRAJ RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 5610,
      "TXT_FINANCIER_NAME": "M/S RAMESH CHAND"
    },
    {
      "NUM_FINANCIER_CD": 5611,
      "TXT_FINANCIER_NAME": "MAHATMA FULE BIGAR SHETI SAHAKAR PATSANTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 5612,
      "TXT_FINANCIER_NAME": "ETERNAL MOTORS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5613,
      "TXT_FINANCIER_NAME": "URBEN DEVELOPMENT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5614,
      "TXT_FINANCIER_NAME": "PNB GILTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 5615,
      "TXT_FINANCIER_NAME": "CHETAK NAGRI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5616,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 5617,
      "TXT_FINANCIER_NAME": "SRI LAL BAHADUR SHASHRI NAGARI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5618,
      "TXT_FINANCIER_NAME": "PIRNA URBAN COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 5619,
      "TXT_FINANCIER_NAME": "BHAIRAVESHWARA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5620,
      "TXT_FINANCIER_NAME": "BASAVESHWARA PATHINA SAHAKARA SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5621,
      "TXT_FINANCIER_NAME": "C S I CREDIT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5622,
      "TXT_FINANCIER_NAME": "CHIKKAMAGALUR JILLA MAHILA SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5623,
      "TXT_FINANCIER_NAME": "THE PIRNA URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5624,
      "TXT_FINANCIER_NAME": "CHIRANJEEVI CREDIT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5625,
      "TXT_FINANCIER_NAME": "CHELLAKERE TALUK KANAKA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5626,
      "TXT_FINANCIER_NAME": "SHIVSHAKTI NAG SAHPATSANSTHA MYDT JUNNER"
    },
    {
      "NUM_FINANCIER_CD": 5627,
      "TXT_FINANCIER_NAME": "SREE PATHANJALI SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5628,
      "TXT_FINANCIER_NAME": "GIFTED BY DLA PUBLISHERS"
    },
    {
      "NUM_FINANCIER_CD": 5629,
      "TXT_FINANCIER_NAME": "MALSYA FED DISTRICT OFFICE"
    },
    {
      "NUM_FINANCIER_CD": 5630,
      "TXT_FINANCIER_NAME": "HOUSAI GRAMIN BIG SHETI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5631,
      "TXT_FINANCIER_NAME": "THE TARAPUR NAGARIK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5632,
      "TXT_FINANCIER_NAME": "KRISHANA FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 5633,
      "TXT_FINANCIER_NAME": "Office of the Chief Engineer, Irrigation South"
    },
    {
      "NUM_FINANCIER_CD": 5634,
      "TXT_FINANCIER_NAME": "Bharath Earth Movers Limited"
    },
    {
      "NUM_FINANCIER_CD": 5635,
      "TXT_FINANCIER_NAME": "TAMILNAD MARCANTILE BANK LTD GUNTUR"
    },
    {
      "NUM_FINANCIER_CD": 5636,
      "TXT_FINANCIER_NAME": "NPOL"
    },
    {
      "NUM_FINANCIER_CD": 5637,
      "TXT_FINANCIER_NAME": "SHRI AHMEDNAGAR ZILLA JAIN OSWAL PANCHAYAT NAGARI PATHSANSHTA"
    },
    {
      "NUM_FINANCIER_CD": 5638,
      "TXT_FINANCIER_NAME": "CHITRADURGA CREDIT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5639,
      "TXT_FINANCIER_NAME": "NETAJI SUBHASH CHANDRA BOSE COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5640,
      "TXT_FINANCIER_NAME": "AP GRAMEENA VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 5641,
      "TXT_FINANCIER_NAME": "INSTITUTE OF INTEGRATED RURAL DEVELOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 5642,
      "TXT_FINANCIER_NAME": "NEDFI"
    },
    {
      "NUM_FINANCIER_CD": 5643,
      "TXT_FINANCIER_NAME": "SHANKAR RAO MOHITE PATIL SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5644,
      "TXT_FINANCIER_NAME": "DATTA PATIL URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5645,
      "TXT_FINANCIER_NAME": "AHAMADNAGAR DISTRECT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5646,
      "TXT_FINANCIER_NAME": "NAVODAYA LEASING R"
    },
    {
      "NUM_FINANCIER_CD": 5647,
      "TXT_FINANCIER_NAME": "SIRSI URBAN CO OP BANK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5648,
      "TXT_FINANCIER_NAME": "SHRE CHEERUMBA CREDIT CO OP SOCIETY LTD URWASTORE"
    },
    {
      "NUM_FINANCIER_CD": 5649,
      "TXT_FINANCIER_NAME": "AALD AUTOMOTIVE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5650,
      "TXT_FINANCIER_NAME": "SREE SEETHARAMA MANDIRAM SOUHARDHA SAHA"
    },
    {
      "NUM_FINANCIER_CD": 5651,
      "TXT_FINANCIER_NAME": "SRI PANDURANGA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5652,
      "TXT_FINANCIER_NAME": "V M FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 5653,
      "TXT_FINANCIER_NAME": "NIRMAL AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5654,
      "TXT_FINANCIER_NAME": "ASHTA GAM SEVA SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 5655,
      "TXT_FINANCIER_NAME": "VIMAL INVESTMENT SOWCARPET CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 5656,
      "TXT_FINANCIER_NAME": "Dr Jayprakash Mundhada Urban Co Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5657,
      "TXT_FINANCIER_NAME": "MULA MUTHA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5658,
      "TXT_FINANCIER_NAME": "THODUPUZHA PRIMARY CO OPERATIVE AGRICULTURAL RURAL DEVOLOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5659,
      "TXT_FINANCIER_NAME": "RAJASTHAN STATE MINES AND MINRALS"
    },
    {
      "NUM_FINANCIER_CD": 5660,
      "TXT_FINANCIER_NAME": "THE TIRUPUR COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5661,
      "TXT_FINANCIER_NAME": "SACRED HEART SOUHARDA CREDIT CO OPERATIVE LTD MAIN ROAD HORAPET TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 5662,
      "TXT_FINANCIER_NAME": "MAHAVIR URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5663,
      "TXT_FINANCIER_NAME": "MAHAVIR URBAN COOP CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5664,
      "TXT_FINANCIER_NAME": "KOKAN BHUSHAN NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5665,
      "TXT_FINANCIER_NAME": "SAHYADRI SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5666,
      "TXT_FINANCIER_NAME": "SANDUR PATTINA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 5667,
      "TXT_FINANCIER_NAME": "BANER NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5668,
      "TXT_FINANCIER_NAME": "SRI ANJANEYA VIVIDODHESHA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5669,
      "TXT_FINANCIER_NAME": "DHILLON FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 5670,
      "TXT_FINANCIER_NAME": "BRAMHALING COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5671,
      "TXT_FINANCIER_NAME": "KASHI SAYUNKT GOMTI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5672,
      "TXT_FINANCIER_NAME": "GANGOLLI TOWN SOUHARDHA CO OP LTD GANGULI"
    },
    {
      "NUM_FINANCIER_CD": 5673,
      "TXT_FINANCIER_NAME": "KUNNAM SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5674,
      "TXT_FINANCIER_NAME": "VILAPPIL SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5675,
      "TXT_FINANCIER_NAME": "R.K.G.B"
    },
    {
      "NUM_FINANCIER_CD": 5676,
      "TXT_FINANCIER_NAME": "THE JANKALYAN CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5677,
      "TXT_FINANCIER_NAME": "AP STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5678,
      "TXT_FINANCIER_NAME": "SHREE VEERSHAIV CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5679,
      "TXT_FINANCIER_NAME": "ABYUDAYA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5680,
      "TXT_FINANCIER_NAME": "THE BULDHANA DISTRICT CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5875,
      "TXT_FINANCIER_NAME": "THE BIJPAUR DIST.MAHILA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5876,
      "TXT_FINANCIER_NAME": "THE NILAMBUR CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5877,
      "TXT_FINANCIER_NAME": "SMT CHIT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5878,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5879,
      "TXT_FINANCIER_NAME": "SHRI P K ANNA PATIL JANTA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5880,
      "TXT_FINANCIER_NAME": "THE CHERPALCHERY CO-OP URBEN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5881,
      "TXT_FINANCIER_NAME": "THE B.M.C.BANK"
    },
    {
      "NUM_FINANCIER_CD": 5882,
      "TXT_FINANCIER_NAME": "ANANTNAG CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5883,
      "TXT_FINANCIER_NAME": "KAY KAY HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5884,
      "TXT_FINANCIER_NAME": "THE BAYAD NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5885,
      "TXT_FINANCIER_NAME": "JANAKALYAN NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5886,
      "TXT_FINANCIER_NAME": "JANKALYAN NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5887,
      "TXT_FINANCIER_NAME": "GANESH GRAMIN BIGARSHETI SAH PATHSANSTHA MARYADIT BR SALGAR"
    },
    {
      "NUM_FINANCIER_CD": 5888,
      "TXT_FINANCIER_NAME": "VASANT DADA SAHAKARI NAGARI BANK LTD OSMANABAD"
    },
    {
      "NUM_FINANCIER_CD": 5889,
      "TXT_FINANCIER_NAME": "KUTCH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5890,
      "TXT_FINANCIER_NAME": "KALIKADEVI NAG SAH PTS"
    },
    {
      "NUM_FINANCIER_CD": 5891,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSAPORT FINANCE CO LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 5892,
      "TXT_FINANCIER_NAME": "MYNAKAM GENERAL FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5893,
      "TXT_FINANCIER_NAME": "raja ram bapu patil coop bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 5894,
      "TXT_FINANCIER_NAME": "RAJA RAM BAPU PATIL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5895,
      "TXT_FINANCIER_NAME": "JAY LAXMI CREDIT COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5896,
      "TXT_FINANCIER_NAME": "SRI NETAJI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5897,
      "TXT_FINANCIER_NAME": "A U FINANCER INDIA P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5898,
      "TXT_FINANCIER_NAME": "CDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 5899,
      "TXT_FINANCIER_NAME": "MURARI MOTOR FINANCE COMMERCIAL"
    },
    {
      "NUM_FINANCIER_CD": 5900,
      "TXT_FINANCIER_NAME": "SUVARNA CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5901,
      "TXT_FINANCIER_NAME": "CANARA CREDIT CO OP SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 5902,
      "TXT_FINANCIER_NAME": "THE AGS OFFICE EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5903,
      "TXT_FINANCIER_NAME": "SWAMY VIVEKANANDA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5904,
      "TXT_FINANCIER_NAME": "DHANWARDHINI GRAMIN BIGARSETI SAH PATPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 5905,
      "TXT_FINANCIER_NAME": "SHIVRAY GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5906,
      "TXT_FINANCIER_NAME": "BELGAUM TALUKA RURAL INDUSTRIAL COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5907,
      "TXT_FINANCIER_NAME": "SOLAPUR JANTA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5908,
      "TXT_FINANCIER_NAME": "MAHILA VIVIDODDESHA SAHAKARI SANGHA N KIRIMANJESHWARA"
    },
    {
      "NUM_FINANCIER_CD": 5909,
      "TXT_FINANCIER_NAME": "JAGRUTI COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5910,
      "TXT_FINANCIER_NAME": "CARGIL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5911,
      "TXT_FINANCIER_NAME": "SREENIVASA TRADERS"
    },
    {
      "NUM_FINANCIER_CD": 5912,
      "TXT_FINANCIER_NAME": "JANAVIKASA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5913,
      "TXT_FINANCIER_NAME": "KARAN AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 5914,
      "TXT_FINANCIER_NAME": "AMBAJOGAI URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5915,
      "TXT_FINANCIER_NAME": "DARUSALEM CO OP URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5916,
      "TXT_FINANCIER_NAME": "HSBC PRIMIER"
    },
    {
      "NUM_FINANCIER_CD": 5917,
      "TXT_FINANCIER_NAME": "Entrepreneurs Associates"
    },
    {
      "NUM_FINANCIER_CD": 5918,
      "TXT_FINANCIER_NAME": "INDASIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 5919,
      "TXT_FINANCIER_NAME": "NIIDHI KRUPA"
    },
    {
      "NUM_FINANCIER_CD": 5920,
      "TXT_FINANCIER_NAME": "Satluj Jal Vidut Nigam Ltd"
    },
    {
      "NUM_FINANCIER_CD": 5921,
      "TXT_FINANCIER_NAME": "SHREE SHAKTI SARAFI SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5922,
      "TXT_FINANCIER_NAME": "KUTCH RAILWAY COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5923,
      "TXT_FINANCIER_NAME": "JAIPUR NAGAURE AANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5924,
      "TXT_FINANCIER_NAME": "MANGALMURTI NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5925,
      "TXT_FINANCIER_NAME": "SHRI SAI SAHASHRAJUN SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 5926,
      "TXT_FINANCIER_NAME": "BANK OF INDIA SALAL"
    },
    {
      "NUM_FINANCIER_CD": 5927,
      "TXT_FINANCIER_NAME": "KURANSHETTI COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5928,
      "TXT_FINANCIER_NAME": "THE KORANGRAPADY C A BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5929,
      "TXT_FINANCIER_NAME": "RELIANCE"
    },
    {
      "NUM_FINANCIER_CD": 5930,
      "TXT_FINANCIER_NAME": "KOTHAMANGALAM SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5931,
      "TXT_FINANCIER_NAME": "INDRAYANI FERROCOST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 5932,
      "TXT_FINANCIER_NAME": "RAILWAY COOPERATIVE CONSUMER STORES LTD"
    },
    {
      "NUM_FINANCIER_CD": 5933,
      "TXT_FINANCIER_NAME": "VYAVASAYA SEVA SAHAKARA"
    },
    {
      "NUM_FINANCIER_CD": 5934,
      "TXT_FINANCIER_NAME": "BELVAI CO OP AGREECULTURAL BANK BELVAI"
    },
    {
      "NUM_FINANCIER_CD": 5935,
      "TXT_FINANCIER_NAME": "SRI GAJANANA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5936,
      "TXT_FINANCIER_NAME": "S SEKAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5937,
      "TXT_FINANCIER_NAME": "KALPATARU VIVIDHODDESH SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5938,
      "TXT_FINANCIER_NAME": "UMA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5939,
      "TXT_FINANCIER_NAME": "KERALA GOVT SECRETARIAT STAFF HOUSING COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5940,
      "TXT_FINANCIER_NAME": "SIDHI VINAYAK NAGARI SAH PATHSANTHA MARYADIT OZAR"
    },
    {
      "NUM_FINANCIER_CD": 5941,
      "TXT_FINANCIER_NAME": "THE NASIK DIST CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5942,
      "TXT_FINANCIER_NAME": "JOHNSON AND JOHNSON"
    },
    {
      "NUM_FINANCIER_CD": 5943,
      "TXT_FINANCIER_NAME": "NAVHIND COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5944,
      "TXT_FINANCIER_NAME": "PAVAGADA SAUHARDA CREDIT CO OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 5945,
      "TXT_FINANCIER_NAME": "OMNKAR COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5946,
      "TXT_FINANCIER_NAME": "APEJAY FINANCIAL CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 5947,
      "TXT_FINANCIER_NAME": "C A BANK"
    },
    {
      "NUM_FINANCIER_CD": 5948,
      "TXT_FINANCIER_NAME": "SBI RACPC DEHRADUN"
    },
    {
      "NUM_FINANCIER_CD": 5949,
      "TXT_FINANCIER_NAME": "GOVT OF GUJARAT EXECUTIVE ENGG NPCQC DIV"
    },
    {
      "NUM_FINANCIER_CD": 5950,
      "TXT_FINANCIER_NAME": "SAMARTH NAGARI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5951,
      "TXT_FINANCIER_NAME": "NITESH SURANA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 5952,
      "TXT_FINANCIER_NAME": "RAJ SINGH COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 5953,
      "TXT_FINANCIER_NAME": "KOTA CO OPERATIVE AGRICULTURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5954,
      "TXT_FINANCIER_NAME": "KOTA CO OPERATIVE AGRICULTURAL BANK LTD KOTA"
    },
    {
      "NUM_FINANCIER_CD": 5955,
      "TXT_FINANCIER_NAME": "GOUTAM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5956,
      "TXT_FINANCIER_NAME": "SHREE VINAYAK VYAVSAIKANCHI SAHAKARI PATSANASTHA"
    },
    {
      "NUM_FINANCIER_CD": 5957,
      "TXT_FINANCIER_NAME": "HULASH FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 5958,
      "TXT_FINANCIER_NAME": "THE VAIKOM PALLIPRETHUSSERY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5959,
      "TXT_FINANCIER_NAME": "SAIL VISL"
    },
    {
      "NUM_FINANCIER_CD": 5960,
      "TXT_FINANCIER_NAME": "SHREE SHRAMJIVI NAGRIK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5961,
      "TXT_FINANCIER_NAME": "YOGIRAJ SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5962,
      "TXT_FINANCIER_NAME": "SRI ANANTHAPADMANABHA CREDIT COOP SOCIETY LTD HEBRI"
    },
    {
      "NUM_FINANCIER_CD": 5963,
      "TXT_FINANCIER_NAME": "CHANDOR GUIRDOLIM CAVORIM URBAN COOPERATIVE CRD SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5964,
      "TXT_FINANCIER_NAME": "VEJALPUR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5965,
      "TXT_FINANCIER_NAME": "PKPS BANK"
    },
    {
      "NUM_FINANCIER_CD": 5966,
      "TXT_FINANCIER_NAME": "SOUHARDHA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 5967,
      "TXT_FINANCIER_NAME": "LATE SHYAMRAO PATIL NAGARI SAHAKARI PATSANSTHA MARYADIT UNDALE"
    },
    {
      "NUM_FINANCIER_CD": 5968,
      "TXT_FINANCIER_NAME": "THE VIJAY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5969,
      "TXT_FINANCIER_NAME": "the kadavoor service co-operative bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 5970,
      "TXT_FINANCIER_NAME": "KUTTIADY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5971,
      "TXT_FINANCIER_NAME": "THE GAJANAN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT AJARA"
    },
    {
      "NUM_FINANCIER_CD": 5972,
      "TXT_FINANCIER_NAME": "THE KASARAGOD DISTRICT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5973,
      "TXT_FINANCIER_NAME": "THE ULIYACOVIL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5974,
      "TXT_FINANCIER_NAME": "ANYONYA SAHAYAKARI MANDALI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6367,
      "TXT_FINANCIER_NAME": "Samta Nagari Sahakari Pat Santha Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 6368,
      "TXT_FINANCIER_NAME": "DSP SAIL"
    },
    {
      "NUM_FINANCIER_CD": 6369,
      "TXT_FINANCIER_NAME": "RATNA KUBER TRADERS AND JEWELLERY"
    },
    {
      "NUM_FINANCIER_CD": 6370,
      "TXT_FINANCIER_NAME": "The Bansakantha Vibhagiy Road Transport Credit coop society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6371,
      "TXT_FINANCIER_NAME": "R UTTAMCHAND BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 6372,
      "TXT_FINANCIER_NAME": "HANUMAN GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MARYADIT ASHTA"
    },
    {
      "NUM_FINANCIER_CD": 6373,
      "TXT_FINANCIER_NAME": "SBBP CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6374,
      "TXT_FINANCIER_NAME": "SHARADRAO PAWAR NAGARI SAHAKARI PATASANSTHA PARYADIT DEOLA"
    },
    {
      "NUM_FINANCIER_CD": 6375,
      "TXT_FINANCIER_NAME": "VARSHA CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 6376,
      "TXT_FINANCIER_NAME": "MANVIPATTANA SOUHARDA SAHAKARI BANK NI MANVI"
    },
    {
      "NUM_FINANCIER_CD": 6377,
      "TXT_FINANCIER_NAME": "ADHI SAKTHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6378,
      "TXT_FINANCIER_NAME": "VE COMMERCIAL VEHICLE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6379,
      "TXT_FINANCIER_NAME": "EKTA SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6380,
      "TXT_FINANCIER_NAME": "AMBIKA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 6381,
      "TXT_FINANCIER_NAME": "RIDHKARAN JITENDRA KUMAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6382,
      "TXT_FINANCIER_NAME": "RAGAVENDRA SWAMY PATHANA SAHAKARA CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6383,
      "TXT_FINANCIER_NAME": "SHIVASHAKTI URBAN CO OP BANK LTD BARSHI"
    },
    {
      "NUM_FINANCIER_CD": 6384,
      "TXT_FINANCIER_NAME": "SHREERAM TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6385,
      "TXT_FINANCIER_NAME": "MINAKSHI FINANCE AWAGARH ETAH"
    },
    {
      "NUM_FINANCIER_CD": 6386,
      "TXT_FINANCIER_NAME": "HINDUSTAN NEWSPRINT EMPLOYEES COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6387,
      "TXT_FINANCIER_NAME": "AP MAHESH COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6388,
      "TXT_FINANCIER_NAME": "CENTURY AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6389,
      "TXT_FINANCIER_NAME": "SAPTASHRUNGI NAGRI SAHAKARI PATSANSTH YEOLA"
    },
    {
      "NUM_FINANCIER_CD": 6390,
      "TXT_FINANCIER_NAME": "MS AWANTHI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 6391,
      "TXT_FINANCIER_NAME": "PUNE MADHYAVARTI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6392,
      "TXT_FINANCIER_NAME": "TVC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6393,
      "TXT_FINANCIER_NAME": "SANCHIT SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 6394,
      "TXT_FINANCIER_NAME": "THE VASO CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6395,
      "TXT_FINANCIER_NAME": "ARANTHODU THODIKANA VYAVASAYA SEVA SAHAKARI BANKA"
    },
    {
      "NUM_FINANCIER_CD": 6396,
      "TXT_FINANCIER_NAME": "THE AMALNER URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6397,
      "TXT_FINANCIER_NAME": "NADIAD PEOPLES CO.OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6398,
      "TXT_FINANCIER_NAME": "G H U V C CO SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6399,
      "TXT_FINANCIER_NAME": "SHRI CHAWDESHWARI KSHATRIYA NAG SAH PATHPEDHI MARYADIT SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 6400,
      "TXT_FINANCIER_NAME": "GORAV FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 6401,
      "TXT_FINANCIER_NAME": "SURAMA MULTITRADE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6402,
      "TXT_FINANCIER_NAME": "mulakulam service co-op: bank ltd;no.1097"
    },
    {
      "NUM_FINANCIER_CD": 6403,
      "TXT_FINANCIER_NAME": "BUNDELKHAND GRAMEN BANK BAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 6404,
      "TXT_FINANCIER_NAME": "FARIDABAD CERTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6405,
      "TXT_FINANCIER_NAME": "THE MAHENDERGARH CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6406,
      "TXT_FINANCIER_NAME": "PCNDT"
    },
    {
      "NUM_FINANCIER_CD": 6407,
      "TXT_FINANCIER_NAME": "SRI SARASWATHI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6408,
      "TXT_FINANCIER_NAME": "KATHIROOR CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6409,
      "TXT_FINANCIER_NAME": "THE KADIRUR CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6410,
      "TXT_FINANCIER_NAME": "ABABSAHEB POL GRAMIN BIGRESHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6411,
      "TXT_FINANCIER_NAME": "THE WAYANAD DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6412,
      "TXT_FINANCIER_NAME": "SUNITHA KANWAR JAIL"
    },
    {
      "NUM_FINANCIER_CD": 6413,
      "TXT_FINANCIER_NAME": "BULDHANA GRAMIN BANK, BULDHANA"
    },
    {
      "NUM_FINANCIER_CD": 6414,
      "TXT_FINANCIER_NAME": "PRIME CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6415,
      "TXT_FINANCIER_NAME": "Dr.Babasaheb Ambedkar Urban Co-operative Bank Ltd.,Nagpur"
    },
    {
      "NUM_FINANCIER_CD": 6416,
      "TXT_FINANCIER_NAME": "MARATHWADA GARMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6417,
      "TXT_FINANCIER_NAME": "THE NADIAD PEOPLES CO-OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6418,
      "TXT_FINANCIER_NAME": "BANK NAME"
    },
    {
      "NUM_FINANCIER_CD": 6419,
      "TXT_FINANCIER_NAME": "THE NADIAD PEOPLES CO.OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6420,
      "TXT_FINANCIER_NAME": "MUKESH GENERAL FIN.CO."
    },
    {
      "NUM_FINANCIER_CD": 6421,
      "TXT_FINANCIER_NAME": "KOKAN MERCHANTILE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6422,
      "TXT_FINANCIER_NAME": "NAVODAYA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6423,
      "TXT_FINANCIER_NAME": "THE PUTHUPPALLY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6424,
      "TXT_FINANCIER_NAME": "THE BHAVANI GOUNDER AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6425,
      "TXT_FINANCIER_NAME": "MANGALAM SERVICE CO. OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6426,
      "TXT_FINANCIER_NAME": "SMARTI NAGRIC SHAKARI BANK MANDSAUR"
    },
    {
      "NUM_FINANCIER_CD": 6427,
      "TXT_FINANCIER_NAME": "PANOOR MERCANTILECO-OPERATIVE SOCITY"
    },
    {
      "NUM_FINANCIER_CD": 6428,
      "TXT_FINANCIER_NAME": "MAGMA SARACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6429,
      "TXT_FINANCIER_NAME": "JANSEVA CO-OP.BANK LTD.,NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 6430,
      "TXT_FINANCIER_NAME": "ANANTHA COPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6431,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 6432,
      "TXT_FINANCIER_NAME": "SHER NAGRIK SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 6433,
      "TXT_FINANCIER_NAME": "THE SOUTH CANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6434,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN CO-OPERATIVE BANK LTD HONNAVAR"
    },
    {
      "NUM_FINANCIER_CD": 6435,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6436,
      "TXT_FINANCIER_NAME": "GOVT. OF HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 6437,
      "TXT_FINANCIER_NAME": "THE POCHAMPALLY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6438,
      "TXT_FINANCIER_NAME": "THE KANNUR DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6439,
      "TXT_FINANCIER_NAME": "BHOJ SAHAKARI NAGRIK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6440,
      "TXT_FINANCIER_NAME": "BHOJ NAGRIK SAHAKARI BANK, DHAR"
    },
    {
      "NUM_FINANCIER_CD": 6441,
      "TXT_FINANCIER_NAME": "MAHISHMATI NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6442,
      "TXT_FINANCIER_NAME": "THE MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6443,
      "TXT_FINANCIER_NAME": "STANDARD TAGAGE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6444,
      "TXT_FINANCIER_NAME": "NAGARIK BANK BARIYA"
    },
    {
      "NUM_FINANCIER_CD": 6445,
      "TXT_FINANCIER_NAME": "UJJAIN NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6446,
      "TXT_FINANCIER_NAME": "N"
    },
    {
      "NUM_FINANCIER_CD": 6447,
      "TXT_FINANCIER_NAME": "THE MUMBAI DIST. CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6448,
      "TXT_FINANCIER_NAME": "THE BAGALKOT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6449,
      "TXT_FINANCIER_NAME": "MAHILA CO-OP. NAGARIK BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6450,
      "TXT_FINANCIER_NAME": "THE VALAVANNUR SERVICE CO. OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6451,
      "TXT_FINANCIER_NAME": "SANTRAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 6452,
      "TXT_FINANCIER_NAME": "RAVUGODLU VYAVASAYA SEVA SAHAKARA BANK NIYAMITHA THARALU"
    },
    {
      "NUM_FINANCIER_CD": 6453,
      "TXT_FINANCIER_NAME": "UMESHCHAND NAHARSON AUTOMOBILE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6454,
      "TXT_FINANCIER_NAME": "KRONES INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6455,
      "TXT_FINANCIER_NAME": "PATIDAR AUTO FIN"
    },
    {
      "NUM_FINANCIER_CD": 6456,
      "TXT_FINANCIER_NAME": "THE JUBILEE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6457,
      "TXT_FINANCIER_NAME": "GUARDIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6458,
      "TXT_FINANCIER_NAME": "HAVYAKA CREDIT SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 6459,
      "TXT_FINANCIER_NAME": "PCA AND RD BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6460,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF BIHAR FINANCE DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 6461,
      "TXT_FINANCIER_NAME": "C S I CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6462,
      "TXT_FINANCIER_NAME": "SIDDASIRI PATTIN SAUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6463,
      "TXT_FINANCIER_NAME": "HELPLINE FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 6464,
      "TXT_FINANCIER_NAME": "KURUHINSHETTY COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6465,
      "TXT_FINANCIER_NAME": "PRAGATI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5975,
      "TXT_FINANCIER_NAME": "THE VALIYAPARAMBA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5976,
      "TXT_FINANCIER_NAME": "THE AP VARDHAMAN CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5977,
      "TXT_FINANCIER_NAME": "THE SHORNUR CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5978,
      "TXT_FINANCIER_NAME": "BANKERS TRAINING COLLEGE"
    },
    {
      "NUM_FINANCIER_CD": 5979,
      "TXT_FINANCIER_NAME": "KURUPPAMPADY SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5980,
      "TXT_FINANCIER_NAME": "THE PUNNAYURKULAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5981,
      "TXT_FINANCIER_NAME": "B I I BANK INTERNASIONAL INDONESIA"
    },
    {
      "NUM_FINANCIER_CD": 5982,
      "TXT_FINANCIER_NAME": "AYYANTHOLE SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5983,
      "TXT_FINANCIER_NAME": "THE QUILANDY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5984,
      "TXT_FINANCIER_NAME": "SHRI VIKRANT GRAMIN BIGARSHETI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5985,
      "TXT_FINANCIER_NAME": "THE SHERTALLAY CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5986,
      "TXT_FINANCIER_NAME": "PEROORKADA SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5987,
      "TXT_FINANCIER_NAME": "VEPAR VIKAS CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5988,
      "TXT_FINANCIER_NAME": "THE RAJPIPLA NAGRIC SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5989,
      "TXT_FINANCIER_NAME": "INDUS IND"
    },
    {
      "NUM_FINANCIER_CD": 5990,
      "TXT_FINANCIER_NAME": "SUNDERLAL SAWJI URBAN CO-OP BANK LTD JINTUR"
    },
    {
      "NUM_FINANCIER_CD": 5991,
      "TXT_FINANCIER_NAME": "DELHI CO OPREATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5992,
      "TXT_FINANCIER_NAME": "KERALA STATE AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 5993,
      "TXT_FINANCIER_NAME": "ICHALKARNAJI VYAPARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5994,
      "TXT_FINANCIER_NAME": "THE UDAIPUR CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5995,
      "TXT_FINANCIER_NAME": "THE RANCHI-KHUNTI CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5996,
      "TXT_FINANCIER_NAME": "M.P. RAJYA SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 5997,
      "TXT_FINANCIER_NAME": "THE JINTUR URBAN CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5998,
      "TXT_FINANCIER_NAME": "KARURVYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5999,
      "TXT_FINANCIER_NAME": "SANGLI ZILLA MADHYAWARTI SAH BANK LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 6000,
      "TXT_FINANCIER_NAME": "THE TOWN COPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6001,
      "TXT_FINANCIER_NAME": "THE R.S.CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6002,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE SOCIETY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6003,
      "TXT_FINANCIER_NAME": "ACE CO-OPERATEIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6004,
      "TXT_FINANCIER_NAME": "THE NASIK MERCHANTS CO-OP. BANK LTD NASIK(SCHEDULED BANK)"
    },
    {
      "NUM_FINANCIER_CD": 6005,
      "TXT_FINANCIER_NAME": "AKKMAHADEVI NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6006,
      "TXT_FINANCIER_NAME": "THE MANGALPADY SERVICE CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6007,
      "TXT_FINANCIER_NAME": "SADGURU JANGLI MAHARAJ SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6008,
      "TXT_FINANCIER_NAME": "THE WAI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6009,
      "TXT_FINANCIER_NAME": "BARAMATI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6010,
      "TXT_FINANCIER_NAME": "PRERANA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6011,
      "TXT_FINANCIER_NAME": "HINDUSTHAN PETROLIUM CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6012,
      "TXT_FINANCIER_NAME": "Dadasaheb Dr. N.M. Kabre Nag. Sah. Bank Ltd., Erandol"
    },
    {
      "NUM_FINANCIER_CD": 6013,
      "TXT_FINANCIER_NAME": "VADODARA GUJARAT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6014,
      "TXT_FINANCIER_NAME": "SINDICATE BANK L"
    },
    {
      "NUM_FINANCIER_CD": 6015,
      "TXT_FINANCIER_NAME": "THE GANGOLLI SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6016,
      "TXT_FINANCIER_NAME": "LODHI ROAD HEAD POST OFFICE"
    },
    {
      "NUM_FINANCIER_CD": 6017,
      "TXT_FINANCIER_NAME": "G.E.MONEY FIN.SER.LTD"
    },
    {
      "NUM_FINANCIER_CD": 6018,
      "TXT_FINANCIER_NAME": "THE JALGAON JANTA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6019,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6020,
      "TXT_FINANCIER_NAME": "LAXMI MAHILA GRAMIN BIGAR SHETI SAHKARI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 6021,
      "TXT_FINANCIER_NAME": "DNYAN SANVARDHINI GRAMIN BIGAR SHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6022,
      "TXT_FINANCIER_NAME": "MOGUL FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6023,
      "TXT_FINANCIER_NAME": "ABASO GRAMIN BIGARSHETI SAH PATHA"
    },
    {
      "NUM_FINANCIER_CD": 6024,
      "TXT_FINANCIER_NAME": "ERIYAD SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6025,
      "TXT_FINANCIER_NAME": "T M L FINACIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6026,
      "TXT_FINANCIER_NAME": "SHIV KRUPA SAHAKARI PATPHETI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6027,
      "TXT_FINANCIER_NAME": "MEGMA SHRACHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6028,
      "TXT_FINANCIER_NAME": "KINGS INDIA SHOPCE"
    },
    {
      "NUM_FINANCIER_CD": 6029,
      "TXT_FINANCIER_NAME": "SUBHADRHRA LOCAL AREA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6030,
      "TXT_FINANCIER_NAME": "RIDHI SIDHI"
    },
    {
      "NUM_FINANCIER_CD": 6031,
      "TXT_FINANCIER_NAME": "SHYAM FIN COMPANY AGRA"
    },
    {
      "NUM_FINANCIER_CD": 6032,
      "TXT_FINANCIER_NAME": "BHE THRIFT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6033,
      "TXT_FINANCIER_NAME": "MAHARASTRA STATE HANDICAP FINANCE AND DEVLOPMENT CORPORATION DHULE"
    },
    {
      "NUM_FINANCIER_CD": 6034,
      "TXT_FINANCIER_NAME": "KALYANI MAHILA NAGARI SAHAKARI PATSANSTHA LTD NASIK"
    },
    {
      "NUM_FINANCIER_CD": 6035,
      "TXT_FINANCIER_NAME": "PADMASHREE Dr VITHALRAO VIKHE PATIL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6036,
      "TXT_FINANCIER_NAME": "KANCHANGANGA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6037,
      "TXT_FINANCIER_NAME": "THE KARNATAKA MINORITIES DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 6038,
      "TXT_FINANCIER_NAME": "KRUSHI PANDIT MOHANLAL LODHA GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MARYADIT PAHUR KASBE"
    },
    {
      "NUM_FINANCIER_CD": 6039,
      "TXT_FINANCIER_NAME": "MANOJ KUMAR ANCHALIA"
    },
    {
      "NUM_FINANCIER_CD": 6040,
      "TXT_FINANCIER_NAME": "DEVLOPMENT CRADITCONS COOP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 6041,
      "TXT_FINANCIER_NAME": "JAI BHAWANI NAGARI SAHSKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6042,
      "TXT_FINANCIER_NAME": "KALIKA URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6043,
      "TXT_FINANCIER_NAME": "MUKTAI GARAMIN BIGARSHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6044,
      "TXT_FINANCIER_NAME": "P D C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6045,
      "TXT_FINANCIER_NAME": "VYAVASAYA SEVA SAHAKARA BANK NIYAMITH HERURU"
    },
    {
      "NUM_FINANCIER_CD": 6046,
      "TXT_FINANCIER_NAME": "NITIN SOGANI"
    },
    {
      "NUM_FINANCIER_CD": 6047,
      "TXT_FINANCIER_NAME": "GOA STATE SCHEDULED CASTES AND OTHER BACKWARD CLASSES FIN AND DEVPT CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 6048,
      "TXT_FINANCIER_NAME": "NITINSOGANI"
    },
    {
      "NUM_FINANCIER_CD": 6049,
      "TXT_FINANCIER_NAME": "CET SAIL"
    },
    {
      "NUM_FINANCIER_CD": 6050,
      "TXT_FINANCIER_NAME": "MS SUNIL BAJORIA HUF"
    },
    {
      "NUM_FINANCIER_CD": 6051,
      "TXT_FINANCIER_NAME": "BDCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6052,
      "TXT_FINANCIER_NAME": "SHRI SANTAJI MAHARAJ NAGARI SAHAKARI PATHASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6053,
      "TXT_FINANCIER_NAME": "THE JAGMER GROUP SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6054,
      "TXT_FINANCIER_NAME": "NUNHEMS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6055,
      "TXT_FINANCIER_NAME": "DAIWAJNA CREDIT CO OP SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 6056,
      "TXT_FINANCIER_NAME": "MARUTI CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6057,
      "TXT_FINANCIER_NAME": "SHREE SWAMI SAMARTH GRAMIN BIGARSHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6058,
      "TXT_FINANCIER_NAME": "THE BHARATH CO OP BANK MUMBAI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6059,
      "TXT_FINANCIER_NAME": "THE RAMANATHAPURAM DISTRICT CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6060,
      "TXT_FINANCIER_NAME": "THE MAHAD COOP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6061,
      "TXT_FINANCIER_NAME": "BARC"
    },
    {
      "NUM_FINANCIER_CD": 6062,
      "TXT_FINANCIER_NAME": "THE DHANAKALSHMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6063,
      "TXT_FINANCIER_NAME": "AGRICULTURE DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 6064,
      "TXT_FINANCIER_NAME": "THE BELGAUM TALUKA RURAL INDUSTRIAL COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6065,
      "TXT_FINANCIER_NAME": "UDYOG SADHANA NSPS LTD"
    },
    {
      "NUM_FINANCIER_CD": 6066,
      "TXT_FINANCIER_NAME": "THE BADAGUBETTU CREDIT CO OPERATIVE SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 6067,
      "TXT_FINANCIER_NAME": "Malwa Advance And Deposite Regd"
    },
    {
      "NUM_FINANCIER_CD": 6068,
      "TXT_FINANCIER_NAME": "CITICROP MARUTI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 6069,
      "TXT_FINANCIER_NAME": "BHAGYASHRI MAHILA NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6070,
      "TXT_FINANCIER_NAME": "THE CO OPRATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6071,
      "TXT_FINANCIER_NAME": "CHIKKA MAGALUR JILLA MAHILA SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6072,
      "TXT_FINANCIER_NAME": "PERFECT URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6073,
      "TXT_FINANCIER_NAME": "KEDARESHWAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6074,
      "TXT_FINANCIER_NAME": "THE VEZHOOR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6075,
      "TXT_FINANCIER_NAME": "VIKASH FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 6076,
      "TXT_FINANCIER_NAME": "MAA SAHEB GRAMIN BIGAR SATHI SAHAKARI PATH SANSTHA MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6077,
      "TXT_FINANCIER_NAME": "CHERPU SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6078,
      "TXT_FINANCIER_NAME": "THE CHERPU SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6079,
      "TXT_FINANCIER_NAME": "TOTAGAR RURAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6080,
      "TXT_FINANCIER_NAME": "THE SAHA UDDHAR URBAN CREDIT COOPSOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6081,
      "TXT_FINANCIER_NAME": "THE KHATRI COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6082,
      "TXT_FINANCIER_NAME": "SHRI BASHWESHWARA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6083,
      "TXT_FINANCIER_NAME": "BHE EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6084,
      "TXT_FINANCIER_NAME": "JANSEVA COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6085,
      "TXT_FINANCIER_NAME": "SHAHI EXPORTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6086,
      "TXT_FINANCIER_NAME": "THE SAGAMNER MERCHANTS COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6087,
      "TXT_FINANCIER_NAME": "NAVLAUKIK CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 6088,
      "TXT_FINANCIER_NAME": "A V FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6089,
      "TXT_FINANCIER_NAME": "ABASAHEB POL GRAMIN BIGAR SHETI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6090,
      "TXT_FINANCIER_NAME": "PO SAVING BANK"
    },
    {
      "NUM_FINANCIER_CD": 6091,
      "TXT_FINANCIER_NAME": "THE NEW INDIA COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6092,
      "TXT_FINANCIER_NAME": "SHARADHA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6093,
      "TXT_FINANCIER_NAME": "NAVJIVA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6094,
      "TXT_FINANCIER_NAME": "FEROZEPUR CENTRAL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6095,
      "TXT_FINANCIER_NAME": "KAMALAI MAHILA GRAMIN BIGAR SAHAKRI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6096,
      "TXT_FINANCIER_NAME": "INFO MEDIA INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6097,
      "TXT_FINANCIER_NAME": "SRI J VAARI BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 6098,
      "TXT_FINANCIER_NAME": "KEWALCHAND RAJESHKUMAR HUF"
    },
    {
      "NUM_FINANCIER_CD": 6099,
      "TXT_FINANCIER_NAME": "SPECTRA MIOTORS LTD"
    },
    {
      "NUM_FINANCIER_CD": 6100,
      "TXT_FINANCIER_NAME": "ARAKULLAM FARMERS SERV COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6101,
      "TXT_FINANCIER_NAME": "NADIAD PEOPLES CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6102,
      "TXT_FINANCIER_NAME": "CHIEF MANGER LIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 6103,
      "TXT_FINANCIER_NAME": "SHRI UDAYARAVI SOWHARDA CREDIT CO OP SOCITY TIPTUR"
    },
    {
      "NUM_FINANCIER_CD": 6104,
      "TXT_FINANCIER_NAME": "PREMCHAND SINGHVI AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 6105,
      "TXT_FINANCIER_NAME": "NETAJI CREDIT CO OP SOCIETY TIPTUR"
    },
    {
      "NUM_FINANCIER_CD": 6106,
      "TXT_FINANCIER_NAME": "YADUVANSHI LEASE AND HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6107,
      "TXT_FINANCIER_NAME": "SARDA RESOURCES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 6108,
      "TXT_FINANCIER_NAME": "THE COTTON GREEN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6109,
      "TXT_FINANCIER_NAME": "RATNAGIRI JILHA PRATHMIK SHIKSHAK PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6110,
      "TXT_FINANCIER_NAME": "THE CHEERANCHIRA SERVICE CO-OPERATIVE BANK LTD.NO1626"
    },
    {
      "NUM_FINANCIER_CD": 6111,
      "TXT_FINANCIER_NAME": "THE CHEERANCHIRA SERVICE CO-OPERATIVE BANK LTD NO.1626"
    },
    {
      "NUM_FINANCIER_CD": 6112,
      "TXT_FINANCIER_NAME": "THE PERUMANNA KLARI SERVICE CO-OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 6113,
      "TXT_FINANCIER_NAME": "THE CHERTHALA GOVT SERVANTS CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6114,
      "TXT_FINANCIER_NAME": "SANMATI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6115,
      "TXT_FINANCIER_NAME": "UMA CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6116,
      "TXT_FINANCIER_NAME": "SHREE PARSHWANATH CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6117,
      "TXT_FINANCIER_NAME": "THE KOWAD MERCHANT GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6118,
      "TXT_FINANCIER_NAME": "ANGAMALY SERVICE CO-OPERATIVE BANK LTD.NO.714"
    },
    {
      "NUM_FINANCIER_CD": 6119,
      "TXT_FINANCIER_NAME": "PALAMAU KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6120,
      "TXT_FINANCIER_NAME": "NEHRU GRAMIN BIGAR SHETI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6121,
      "TXT_FINANCIER_NAME": "THE INDIA ASSURANCE CO.LTD."
    },
    {
      "NUM_FINANCIER_CD": 6122,
      "TXT_FINANCIER_NAME": "KUTHUPARAMBA CO OPERATIVE RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 6123,
      "TXT_FINANCIER_NAME": "THE ARIYUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6124,
      "TXT_FINANCIER_NAME": "THE AMARAVATHY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6125,
      "TXT_FINANCIER_NAME": "keechery service co-operative bank ltd. no.668"
    },
    {
      "NUM_FINANCIER_CD": 6126,
      "TXT_FINANCIER_NAME": "THE THIRUVAMBADY SERVICE CO-OP.BANK LTD.NO.L-56"
    },
    {
      "NUM_FINANCIER_CD": 6127,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OPREATIVE ADARSH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6128,
      "TXT_FINANCIER_NAME": "GURU RAJ VIDHYA SAKH SAHAKARITA MYDT."
    },
    {
      "NUM_FINANCIER_CD": 6129,
      "TXT_FINANCIER_NAME": "NAGARJUNA GRMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6130,
      "TXT_FINANCIER_NAME": "THE AGRASEN CO OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6131,
      "TXT_FINANCIER_NAME": "GPR BANK"
    },
    {
      "NUM_FINANCIER_CD": 6132,
      "TXT_FINANCIER_NAME": "THE NSR CEN.CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6133,
      "TXT_FINANCIER_NAME": "THE CENTRAL CO-OP BANK NAWANSHAHR"
    },
    {
      "NUM_FINANCIER_CD": 6134,
      "TXT_FINANCIER_NAME": "THE CHENGALA SERVICE CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6135,
      "TXT_FINANCIER_NAME": "KRISHI & GRAMIN BHUMI VIKAS BANK MYD."
    },
    {
      "NUM_FINANCIER_CD": 6136,
      "TXT_FINANCIER_NAME": "VINOD FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6137,
      "TXT_FINANCIER_NAME": "MANAPURAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6138,
      "TXT_FINANCIER_NAME": "SHRI DHANLAXMI NAGARI SAHAKARI PTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 6139,
      "TXT_FINANCIER_NAME": "THE KAVANOOR SERVICE CO-OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6140,
      "TXT_FINANCIER_NAME": "NKGSB CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6141,
      "TXT_FINANCIER_NAME": "SRIRAM FINANCELTD"
    },
    {
      "NUM_FINANCIER_CD": 6142,
      "TXT_FINANCIER_NAME": "SRIRAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6143,
      "TXT_FINANCIER_NAME": "LESSE- CITI CORP FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6144,
      "TXT_FINANCIER_NAME": "THE LAXMI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6145,
      "TXT_FINANCIER_NAME": "MUZAFFARNAGAR URBAN CO-OPERATIVE BANK LTD, MUZAFFAR NAGAR"
    },
    {
      "NUM_FINANCIER_CD": 6146,
      "TXT_FINANCIER_NAME": "SHRI.P.K.ANNA PATIL JANATA SAHAKARI BANK LTD.,NANDURBAR"
    },
    {
      "NUM_FINANCIER_CD": 6147,
      "TXT_FINANCIER_NAME": "deuach"
    },
    {
      "NUM_FINANCIER_CD": 6148,
      "TXT_FINANCIER_NAME": "THE PUNNAYURKULAM SERVICE CO.OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6149,
      "TXT_FINANCIER_NAME": "THE HALOL URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6150,
      "TXT_FINANCIER_NAME": "THE PORUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6151,
      "TXT_FINANCIER_NAME": "THE MANKADA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6152,
      "TXT_FINANCIER_NAME": "KARASSERY SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6153,
      "TXT_FINANCIER_NAME": "SUND"
    },
    {
      "NUM_FINANCIER_CD": 6154,
      "TXT_FINANCIER_NAME": "THE POKOOTUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6155,
      "TXT_FINANCIER_NAME": "THE RAYAT SEVAK CO-OPERATIVE BANK LTD,SATARA"
    },
    {
      "NUM_FINANCIER_CD": 6156,
      "TXT_FINANCIER_NAME": "ACE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6157,
      "TXT_FINANCIER_NAME": "JUBILEEHILLS MERCANTILE CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6158,
      "TXT_FINANCIER_NAME": "THE NIZAMBAD DIST CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6159,
      "TXT_FINANCIER_NAME": "JANAKALYAN NAG.SAH.PTS."
    },
    {
      "NUM_FINANCIER_CD": 6160,
      "TXT_FINANCIER_NAME": "AJABPUR KISAN SEWA SEHKARI SAMITI LTD."
    },
    {
      "NUM_FINANCIER_CD": 6161,
      "TXT_FINANCIER_NAME": "MALLAPUR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6162,
      "TXT_FINANCIER_NAME": "THE OSMANABAD DIST CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6163,
      "TXT_FINANCIER_NAME": "THE FEDRAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6164,
      "TXT_FINANCIER_NAME": "THE SAHYOG CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6165,
      "TXT_FINANCIER_NAME": "THE VAISHYA CO-OPRATIVE ADARSH BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6166,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6167,
      "TXT_FINANCIER_NAME": "NILAMEL SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6168,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPRATIVE BANK LTD NOIDA"
    },
    {
      "NUM_FINANCIER_CD": 6169,
      "TXT_FINANCIER_NAME": "CITI BANK,"
    },
    {
      "NUM_FINANCIER_CD": 6170,
      "TXT_FINANCIER_NAME": "SHREE UMIYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6171,
      "TXT_FINANCIER_NAME": "SUDHA COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6172,
      "TXT_FINANCIER_NAME": "GOD GREY PHILLIPS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6173,
      "TXT_FINANCIER_NAME": "SHIVPRATAP NAGARI SAHAKARI PAT SANSTHA LTD."
    },
    {
      "NUM_FINANCIER_CD": 6174,
      "TXT_FINANCIER_NAME": "MAHATMA FULE BIGER SHETI SAHAKARI PATH SANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 6175,
      "TXT_FINANCIER_NAME": "CHANDGAD CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6176,
      "TXT_FINANCIER_NAME": "SHIPYARD"
    },
    {
      "NUM_FINANCIER_CD": 6177,
      "TXT_FINANCIER_NAME": "SHRI BHAGWANT SAHAKARI PATH SANSTHABARSHI"
    },
    {
      "NUM_FINANCIER_CD": 6178,
      "TXT_FINANCIER_NAME": "SANJAYMAMA SHINDE GBS PATHSANSTHA MADHA"
    },
    {
      "NUM_FINANCIER_CD": 6179,
      "TXT_FINANCIER_NAME": "Ratna Kuber Traders and Jewellers"
    },
    {
      "NUM_FINANCIER_CD": 6180,
      "TXT_FINANCIER_NAME": "Bhagwanrao Bharne Nagari Ptsantha"
    },
    {
      "NUM_FINANCIER_CD": 6181,
      "TXT_FINANCIER_NAME": "SHREE RENUKA URBAN CO OP CREADIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6182,
      "TXT_FINANCIER_NAME": "L I C OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 6183,
      "TXT_FINANCIER_NAME": "RAGHUBAR AUTOMOBILES GENERAL FINANC CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6184,
      "TXT_FINANCIER_NAME": "NAFED"
    },
    {
      "NUM_FINANCIER_CD": 6185,
      "TXT_FINANCIER_NAME": "Janakalyan Hire Purchase Company Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6186,
      "TXT_FINANCIER_NAME": "VITTAL VYAVASAYA SEEVA SAHAKARI BANK NIYAMITHA VITTAL"
    },
    {
      "NUM_FINANCIER_CD": 6187,
      "TXT_FINANCIER_NAME": "SANGAMESHWAR GRAMIN BIGARSHETI SAHAKARI PATASANSTHA MARYADIT NIPHAD"
    },
    {
      "NUM_FINANCIER_CD": 6188,
      "TXT_FINANCIER_NAME": "THE CITY COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6189,
      "TXT_FINANCIER_NAME": "UDUPI TQ INDUSTRIAL CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6190,
      "TXT_FINANCIER_NAME": "SHREE LAXMI NARAYAN NAGRI SAHAKARI PATSANSTHA LTD KHED"
    },
    {
      "NUM_FINANCIER_CD": 6191,
      "TXT_FINANCIER_NAME": "KESAR INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 6192,
      "TXT_FINANCIER_NAME": "PONNANI COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6193,
      "TXT_FINANCIER_NAME": "MEGHALAYA INDUSTRIAL DEVP CORP"
    },
    {
      "NUM_FINANCIER_CD": 6194,
      "TXT_FINANCIER_NAME": "KALIKADEVI NAGARI SAHAKARI PATH SANSTHA LTD KARAD"
    },
    {
      "NUM_FINANCIER_CD": 6195,
      "TXT_FINANCIER_NAME": "MASIDDA URBAN CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6196,
      "TXT_FINANCIER_NAME": "THE DHJANALAKSHMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6197,
      "TXT_FINANCIER_NAME": "MATORI VIVIDHYA KARYAKARI SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6198,
      "TXT_FINANCIER_NAME": "COMPEEAR LEASING FINANCE AND INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6199,
      "TXT_FINANCIER_NAME": "SHRI LALITAMBA PATTIN SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6200,
      "TXT_FINANCIER_NAME": "BARODA RAJSASTHAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6201,
      "TXT_FINANCIER_NAME": "MICO BOSCH LTD"
    },
    {
      "NUM_FINANCIER_CD": 6202,
      "TXT_FINANCIER_NAME": "AERONAUTICAL DEVELOPMENT ESTABLISHMENT"
    },
    {
      "NUM_FINANCIER_CD": 6203,
      "TXT_FINANCIER_NAME": "THE PAVAGADA SOUHARDA CREDIT COOPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6204,
      "TXT_FINANCIER_NAME": "THE BHAVASKAR KSHATRIYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6205,
      "TXT_FINANCIER_NAME": "DEVNADI KHORE GRAMIN BIGARSHETI SAHAKARI PATH SANSTHA MARY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6206,
      "TXT_FINANCIER_NAME": "AJINKYA NAGARI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6207,
      "TXT_FINANCIER_NAME": "CAPITAL MOTORS INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 6208,
      "TXT_FINANCIER_NAME": "RAJURA NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6209,
      "TXT_FINANCIER_NAME": "VIJAYA PRECISION DIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6210,
      "TXT_FINANCIER_NAME": "THE SALEM URBUN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6211,
      "TXT_FINANCIER_NAME": "MAHARASTRA GODAVARI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6212,
      "TXT_FINANCIER_NAME": "SUN INDIA FIFANCE"
    },
    {
      "NUM_FINANCIER_CD": 6213,
      "TXT_FINANCIER_NAME": "Sudarshan Kaliya Chennai"
    },
    {
      "NUM_FINANCIER_CD": 6214,
      "TXT_FINANCIER_NAME": "K S F E"
    },
    {
      "NUM_FINANCIER_CD": 6215,
      "TXT_FINANCIER_NAME": "SHABARI MAHILA CREDIT COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6216,
      "TXT_FINANCIER_NAME": "MEENACHIL TALUK URBAN CO-OPERATIVE EMPLOYEES CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6217,
      "TXT_FINANCIER_NAME": "Samta Nagari Sahakari Pat Santha Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 6218,
      "TXT_FINANCIER_NAME": "EPIL"
    },
    {
      "NUM_FINANCIER_CD": 6219,
      "TXT_FINANCIER_NAME": "THE DAVANGERE HARIHAR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6220,
      "TXT_FINANCIER_NAME": "SVS FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 6221,
      "TXT_FINANCIER_NAME": "NAGINI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6222,
      "TXT_FINANCIER_NAME": "MAHIMA FINANCE SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 6223,
      "TXT_FINANCIER_NAME": "THE YELLAPUR URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6224,
      "TXT_FINANCIER_NAME": "SAHAYOG URBEN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6225,
      "TXT_FINANCIER_NAME": "SANTHOS SURANA"
    },
    {
      "NUM_FINANCIER_CD": 6226,
      "TXT_FINANCIER_NAME": "KARGIL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6227,
      "TXT_FINANCIER_NAME": "SANCHIT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6228,
      "TXT_FINANCIER_NAME": "ORIENTAL INS CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6229,
      "TXT_FINANCIER_NAME": "URBAN COPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6230,
      "TXT_FINANCIER_NAME": "SPACE APPLICATION CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 6231,
      "TXT_FINANCIER_NAME": "OM SAI SAVING AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6232,
      "TXT_FINANCIER_NAME": "SRI KRISHNA INDUSTRIAL CO OP SOCIETY UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 6233,
      "TXT_FINANCIER_NAME": "LIFE AND GENERAL ASSOCIATES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6234,
      "TXT_FINANCIER_NAME": "BHUMI CREDIT CO OP BANK MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 6235,
      "TXT_FINANCIER_NAME": "KANNIKA VIVIDHODDESHA SAHAKARA SANGHA N"
    },
    {
      "NUM_FINANCIER_CD": 6236,
      "TXT_FINANCIER_NAME": "SALCETE FINANCE COP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6237,
      "TXT_FINANCIER_NAME": "THE SASTAN CO OP AGRICULTURAL BANK LTD SASTAN"
    },
    {
      "NUM_FINANCIER_CD": 6238,
      "TXT_FINANCIER_NAME": "MAHESH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6239,
      "TXT_FINANCIER_NAME": "AMBERNATH JAIHIND CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6240,
      "TXT_FINANCIER_NAME": "AWB GOI CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 6241,
      "TXT_FINANCIER_NAME": "VENKATRAMANA CREDIT CO OPERATING SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6242,
      "TXT_FINANCIER_NAME": "PRIMARY CO OPERTIVE AGRICULTRE AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 6243,
      "TXT_FINANCIER_NAME": "SWATHY INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6244,
      "TXT_FINANCIER_NAME": "NAVJIVAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6245,
      "TXT_FINANCIER_NAME": "THE MAHAD COOPARBAN BANK LTD MAHAD"
    },
    {
      "NUM_FINANCIER_CD": 6246,
      "TXT_FINANCIER_NAME": "SHREE SAIBABA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6247,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWLLA PARDI PEOPLE S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6248,
      "TXT_FINANCIER_NAME": "KALLAMUNDKAR VYAVASAYA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6249,
      "TXT_FINANCIER_NAME": "SP INDUSTRIAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6250,
      "TXT_FINANCIER_NAME": "MARWAR BIKANER GARMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6251,
      "TXT_FINANCIER_NAME": "GADCHIROLI CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6252,
      "TXT_FINANCIER_NAME": "REDDI COPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6253,
      "TXT_FINANCIER_NAME": "IFCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6254,
      "TXT_FINANCIER_NAME": "THE BASSIEN CATHOLIC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6255,
      "TXT_FINANCIER_NAME": "UNIQUE WIRES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6256,
      "TXT_FINANCIER_NAME": "NAVAKARNATAKA SOUHARDA CRDIT CO OPERTIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6257,
      "TXT_FINANCIER_NAME": "UMABAI DABADE PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6258,
      "TXT_FINANCIER_NAME": "JANA LAXMI CO OPP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6259,
      "TXT_FINANCIER_NAME": "THE AP VARDHAMAN MAHILA CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6260,
      "TXT_FINANCIER_NAME": "THE PONANI CO-OP. URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6261,
      "TXT_FINANCIER_NAME": "PRAGATHI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6262,
      "TXT_FINANCIER_NAME": "PUNYANAGARI SAHAKARI SANSTHE"
    },
    {
      "NUM_FINANCIER_CD": 6263,
      "TXT_FINANCIER_NAME": "SHRI AARYA CHANAKYA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6264,
      "TXT_FINANCIER_NAME": "SHRI AARYA CHANKYA PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6265,
      "TXT_FINANCIER_NAME": "SHRI AARYA CHANAKYA N S PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6266,
      "TXT_FINANCIER_NAME": "the vazhakulam service co-operative bank ltd:"
    },
    {
      "NUM_FINANCIER_CD": 6267,
      "TXT_FINANCIER_NAME": "THE BANK OF NAINITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 6268,
      "TXT_FINANCIER_NAME": "SURAT-BHARUCH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6269,
      "TXT_FINANCIER_NAME": "KODIYERI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6270,
      "TXT_FINANCIER_NAME": "KOYANA SAHKARI BANK LTD ., KARAD"
    },
    {
      "NUM_FINANCIER_CD": 6271,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6272,
      "TXT_FINANCIER_NAME": "THE ANAND MER.CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6273,
      "TXT_FINANCIER_NAME": "IFFCO KALOL UNIT"
    },
    {
      "NUM_FINANCIER_CD": 6274,
      "TXT_FINANCIER_NAME": "THE JAY MAADI CO-OP. CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6275,
      "TXT_FINANCIER_NAME": "THE PERAVOOR CO-OP:URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6276,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN CO-OPERATIVE BANK LTD., HONNAVAR"
    },
    {
      "NUM_FINANCIER_CD": 6277,
      "TXT_FINANCIER_NAME": "KODIYERI SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6278,
      "TXT_FINANCIER_NAME": "CHAYA CORNER NA.SAH.PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6279,
      "TXT_FINANCIER_NAME": "THE GRATER BOMBAY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6280,
      "TXT_FINANCIER_NAME": "SHIVNERI NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6281,
      "TXT_FINANCIER_NAME": "FOR THE KARUR VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6282,
      "TXT_FINANCIER_NAME": "SHYAMRAO VITTHAL CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6283,
      "TXT_FINANCIER_NAME": "GD FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 6284,
      "TXT_FINANCIER_NAME": "THE KANODAR CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6285,
      "TXT_FINANCIER_NAME": "KARUR VAISYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6286,
      "TXT_FINANCIER_NAME": "THE HARIJ NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6287,
      "TXT_FINANCIER_NAME": "AMCO BANK"
    },
    {
      "NUM_FINANCIER_CD": 6288,
      "TXT_FINANCIER_NAME": "THE NATIONAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6289,
      "TXT_FINANCIER_NAME": "LESSOR SWAMI HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6290,
      "TXT_FINANCIER_NAME": "SHRI.P.K.ANNA PATIL JANTA SAHAKARI BANK LTD.,NANDURBAR"
    },
    {
      "NUM_FINANCIER_CD": 6291,
      "TXT_FINANCIER_NAME": "NILAMBUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6292,
      "TXT_FINANCIER_NAME": "KANNAMBAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 6293,
      "TXT_FINANCIER_NAME": "NABADWIP CO-OPERATIVE CREDIT BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6294,
      "TXT_FINANCIER_NAME": "K D C C HALIYAL"
    },
    {
      "NUM_FINANCIER_CD": 6295,
      "TXT_FINANCIER_NAME": "THE NORTH KANARA G S B CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6296,
      "TXT_FINANCIER_NAME": "REPCO BANK"
    },
    {
      "NUM_FINANCIER_CD": 6297,
      "TXT_FINANCIER_NAME": "SHRI DADASAHEB GAJMAL CO OP BANK LTD.PACHORA"
    },
    {
      "NUM_FINANCIER_CD": 6298,
      "TXT_FINANCIER_NAME": "PONNANI URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6299,
      "TXT_FINANCIER_NAME": "L S M P SEVA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6300,
      "TXT_FINANCIER_NAME": "NIRMALA CO-OPERATIVE CREDIT SOCIETY LTD SIDDAPUR"
    },
    {
      "NUM_FINANCIER_CD": 6301,
      "TXT_FINANCIER_NAME": "GOLKONDA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6302,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6303,
      "TXT_FINANCIER_NAME": "VIKAS GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6304,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6305,
      "TXT_FINANCIER_NAME": "THE MUMBAI MAHANAGARPALIKA SHIKSHAN VIBHAG SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6306,
      "TXT_FINANCIER_NAME": "VAINGANGA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6307,
      "TXT_FINANCIER_NAME": "MUDALGI CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6308,
      "TXT_FINANCIER_NAME": "THE NASIK CO-OP BANK LTD NASIK"
    },
    {
      "NUM_FINANCIER_CD": 6309,
      "TXT_FINANCIER_NAME": "THE KRISHNA DIST. CO-OPERATIVE CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6310,
      "TXT_FINANCIER_NAME": "THE KRISHNA DIST.CO-OPERATIVE CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6311,
      "TXT_FINANCIER_NAME": "THE ANDATHODE SERVICE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6312,
      "TXT_FINANCIER_NAME": "MADINA CO-OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6313,
      "TXT_FINANCIER_NAME": "CENTURIAN"
    },
    {
      "NUM_FINANCIER_CD": 6314,
      "TXT_FINANCIER_NAME": "THE TELLICHERRY CO-OP.TOWN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6315,
      "TXT_FINANCIER_NAME": "KRISHNAKATH NAGARI SAHAKARIPAT SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 6316,
      "TXT_FINANCIER_NAME": "NAVPRABHA MAHELA NAGARI SAH PATH SANSTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6317,
      "TXT_FINANCIER_NAME": "PARASHAR SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6318,
      "TXT_FINANCIER_NAME": "GOODLUCK FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 6319,
      "TXT_FINANCIER_NAME": "KOTA KARMCHARI SAHAKARI SAMITI NO 108"
    },
    {
      "NUM_FINANCIER_CD": 6320,
      "TXT_FINANCIER_NAME": "BHARATH EARTH MOVERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 6321,
      "TXT_FINANCIER_NAME": "MEENACHIL TALUK COOP EMPLOYEES COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6322,
      "TXT_FINANCIER_NAME": "Kothari Motor Finance Company"
    },
    {
      "NUM_FINANCIER_CD": 6323,
      "TXT_FINANCIER_NAME": "SRI KANNAVINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6324,
      "TXT_FINANCIER_NAME": "THE CENT ADOP RES AGENCY"
    },
    {
      "NUM_FINANCIER_CD": 6325,
      "TXT_FINANCIER_NAME": "CHAITANYA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6326,
      "TXT_FINANCIER_NAME": "MAHILA VIVIDODDESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 6327,
      "TXT_FINANCIER_NAME": "PRAVEEN INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 6328,
      "TXT_FINANCIER_NAME": "INDIRA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6329,
      "TXT_FINANCIER_NAME": "Hon Yashavantravaji Chavan Gr Sh Sah PTS"
    },
    {
      "NUM_FINANCIER_CD": 6330,
      "TXT_FINANCIER_NAME": "AU FINANCER INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6331,
      "TXT_FINANCIER_NAME": "BHARTI MAIND NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6332,
      "TXT_FINANCIER_NAME": "NIPHAD NAGARI SAHAKARI PATASANSTHA MARY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6333,
      "TXT_FINANCIER_NAME": "VIMAL HAIRPURCHAS AND INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 6334,
      "TXT_FINANCIER_NAME": "JRB SARORE"
    },
    {
      "NUM_FINANCIER_CD": 6335,
      "TXT_FINANCIER_NAME": "VSSN"
    },
    {
      "NUM_FINANCIER_CD": 6336,
      "TXT_FINANCIER_NAME": "VASANTDADA GRAMIN BIGARSHETI SAHAKRI PATHSANSTHA JUNNAR"
    },
    {
      "NUM_FINANCIER_CD": 6337,
      "TXT_FINANCIER_NAME": "MAKINO INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6338,
      "TXT_FINANCIER_NAME": "P N FINANCE CUDDALORE"
    },
    {
      "NUM_FINANCIER_CD": 6339,
      "TXT_FINANCIER_NAME": "THE DHANERA NAGRIK CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6340,
      "TXT_FINANCIER_NAME": "GURU NITYANANDA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6341,
      "TXT_FINANCIER_NAME": "G SANTRAMPUR ARBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6342,
      "TXT_FINANCIER_NAME": "SHRI AHIMSA CREDIT SOUHARDA SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6343,
      "TXT_FINANCIER_NAME": "THE ARALAM SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6344,
      "TXT_FINANCIER_NAME": "THE ARLAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6345,
      "TXT_FINANCIER_NAME": "HIND VIJAY NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6346,
      "TXT_FINANCIER_NAME": "KAMARI VRUSHALI JANJIREE GRAMIN BIJORSHERI SAH PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 6347,
      "TXT_FINANCIER_NAME": "KUMARI VRUSHALI JANJIRE GRAMIN BIJ SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6348,
      "TXT_FINANCIER_NAME": "SATTARIURBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6349,
      "TXT_FINANCIER_NAME": "BRM LEASE CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6350,
      "TXT_FINANCIER_NAME": "THE BABASAHEB DESHMUKH SAH BANK LTD ATPADI"
    },
    {
      "NUM_FINANCIER_CD": 6351,
      "TXT_FINANCIER_NAME": "SHREE GANESH NAGARI SAHAKARI PAT SANSANTH MARYDIT ASHTA"
    },
    {
      "NUM_FINANCIER_CD": 6352,
      "TXT_FINANCIER_NAME": "Government of Bihar"
    },
    {
      "NUM_FINANCIER_CD": 6353,
      "TXT_FINANCIER_NAME": "TRISHAKTI NAGARI SAHAKRI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6354,
      "TXT_FINANCIER_NAME": "A P STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 6355,
      "TXT_FINANCIER_NAME": "INDIAN COUNCIL OF AGRICULTURE RESEARCH"
    },
    {
      "NUM_FINANCIER_CD": 6356,
      "TXT_FINANCIER_NAME": "PACIFIC FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6357,
      "TXT_FINANCIER_NAME": "SHREE ARADHNA ARBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6358,
      "TXT_FINANCIER_NAME": "RELIGARE SECURITIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6359,
      "TXT_FINANCIER_NAME": "A P I I C LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6360,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERICAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6361,
      "TXT_FINANCIER_NAME": "SHARACHANDRA GRAMIN B S S CO SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 6362,
      "TXT_FINANCIER_NAME": "THE SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6363,
      "TXT_FINANCIER_NAME": "god frey phillips india ltd"
    },
    {
      "NUM_FINANCIER_CD": 6364,
      "TXT_FINANCIER_NAME": "MAHATMA FULE BIGAR SHATI SAHAKARI PATSHANTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 6365,
      "TXT_FINANCIER_NAME": "KAILASWASI DEVENDRA SARADA NAGRI SAHAKARI PATHSANSTHA MARYADIT TRYMBAKESHWAR"
    },
    {
      "NUM_FINANCIER_CD": 6366,
      "TXT_FINANCIER_NAME": "Yuth Foundation Nagari Sahakari Pat Santha Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 5778,
      "TXT_FINANCIER_NAME": "SRI CHANNA BASAVASWAMY URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5779,
      "TXT_FINANCIER_NAME": "HOUSEFED PUNJAB"
    },
    {
      "NUM_FINANCIER_CD": 5780,
      "TXT_FINANCIER_NAME": "THE MAHAD CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5781,
      "TXT_FINANCIER_NAME": "THE BHARAT"
    },
    {
      "NUM_FINANCIER_CD": 5782,
      "TXT_FINANCIER_NAME": "HAL EMPLOYEES CO OP CREDIT SO LTD OZAR TOWNSHIP NASIK"
    },
    {
      "NUM_FINANCIER_CD": 5783,
      "TXT_FINANCIER_NAME": "bhabhar nagrik sahakari bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 5784,
      "TXT_FINANCIER_NAME": "KALLAPPAGOUDA AWAD ICHALKARANJI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5785,
      "TXT_FINANCIER_NAME": "INDIAN RENEWAL ENERGY DEVELOPMENT AGENCY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5786,
      "TXT_FINANCIER_NAME": "NAKODA BHERAV FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5787,
      "TXT_FINANCIER_NAME": "AGRASEN HRBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5788,
      "TXT_FINANCIER_NAME": "PERUMBAVOOR URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5789,
      "TXT_FINANCIER_NAME": "IRINJALAKKUDA CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD NO R"
    },
    {
      "NUM_FINANCIER_CD": 5790,
      "TXT_FINANCIER_NAME": "NATIONAL HYDRO POWER CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 5791,
      "TXT_FINANCIER_NAME": "APPA SAHEB SUKHLAL VEDU CHAUDHARI PATSANSTHA LAMKANI"
    },
    {
      "NUM_FINANCIER_CD": 5792,
      "TXT_FINANCIER_NAME": "APPA SAHEB SUKLAL VEDU CHAUDHARI PATSANSTHA LAMKANI"
    },
    {
      "NUM_FINANCIER_CD": 5793,
      "TXT_FINANCIER_NAME": "JAIN V BHANIRAM NANDURBAR"
    },
    {
      "NUM_FINANCIER_CD": 5794,
      "TXT_FINANCIER_NAME": "APPA SAHEB SUKLAL VEDU CHAUDHARI PATSANSTHA LAMKANI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5795,
      "TXT_FINANCIER_NAME": "VARDHAMAN JAIN GRAMIN BIGARSHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 5796,
      "TXT_FINANCIER_NAME": "VARDHAMAN JAIN GRA BIGAR SHETI PATSANSTA"
    },
    {
      "NUM_FINANCIER_CD": 5797,
      "TXT_FINANCIER_NAME": "THE OMKAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5798,
      "TXT_FINANCIER_NAME": "VYAPARI NAG SAH PTS LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 5799,
      "TXT_FINANCIER_NAME": "SHRI BELLUR GURUBASAV PATTIN SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 5800,
      "TXT_FINANCIER_NAME": "SHRI SHRAMDIP URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 5801,
      "TXT_FINANCIER_NAME": "NHPC HE PROJECT KISHTWAR"
    },
    {
      "NUM_FINANCIER_CD": 5802,
      "TXT_FINANCIER_NAME": "Fast Track Finance Co"
    },
    {
      "NUM_FINANCIER_CD": 5803,
      "TXT_FINANCIER_NAME": "THE LAKHWAD NAG SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5804,
      "TXT_FINANCIER_NAME": "HARYANA GOVERNMENT"
    },
    {
      "NUM_FINANCIER_CD": 5805,
      "TXT_FINANCIER_NAME": "LAKSHME AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 5806,
      "TXT_FINANCIER_NAME": "VADGAON JANATA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 5807,
      "TXT_FINANCIER_NAME": "NATIONAL FERTILIZER LTD"
    },
    {
      "NUM_FINANCIER_CD": 5808,
      "TXT_FINANCIER_NAME": "NASHIK MUNCIPAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 5809,
      "TXT_FINANCIER_NAME": "KHETI VIKAS SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 5810,
      "TXT_FINANCIER_NAME": "POTHANICADU FARMERS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5811,
      "TXT_FINANCIER_NAME": "KUTTOOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5812,
      "TXT_FINANCIER_NAME": "ASHIRWAD NAGRI SAHKARI PATSANSTHA LTD SANGAMNER"
    },
    {
      "NUM_FINANCIER_CD": 5813,
      "TXT_FINANCIER_NAME": "MALANAD CO OPERATIVE AGRI AND RURAL DVPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5814,
      "TXT_FINANCIER_NAME": "RELIANCE CAMMUNICATION"
    },
    {
      "NUM_FINANCIER_CD": 5815,
      "TXT_FINANCIER_NAME": "MD EIL"
    },
    {
      "NUM_FINANCIER_CD": 5816,
      "TXT_FINANCIER_NAME": "MYSORE LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5817,
      "TXT_FINANCIER_NAME": "BHATKAL AGRIL RURAL DEVELOPMENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5818,
      "TXT_FINANCIER_NAME": "PARAVUR VADAKKEKARA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5819,
      "TXT_FINANCIER_NAME": "THE TOTAGARS RURAL CO OPERATIVE CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5820,
      "TXT_FINANCIER_NAME": "ST MILEGERS CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 5821,
      "TXT_FINANCIER_NAME": "THE MONARCH"
    },
    {
      "NUM_FINANCIER_CD": 5822,
      "TXT_FINANCIER_NAME": "THE KARIPUZHA SERVICE CO OPERATIVE BANK LTD NO 74"
    },
    {
      "NUM_FINANCIER_CD": 5823,
      "TXT_FINANCIER_NAME": "THE KARAD MERCHANTS SAH CREDIT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 5824,
      "TXT_FINANCIER_NAME": "ANDOORKONAM SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5825,
      "TXT_FINANCIER_NAME": "AAR KAY FIN- LEASE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 5826,
      "TXT_FINANCIER_NAME": "THE KHAMBHAT NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5827,
      "TXT_FINANCIER_NAME": "THE JHAJJAR CENTRAL CO-OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5828,
      "TXT_FINANCIER_NAME": "THE JHAJJAR CENTRAL CO-OPRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5829,
      "TXT_FINANCIER_NAME": "ABE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5830,
      "TXT_FINANCIER_NAME": "BHARTIYA NAGARI SAHKARI PAT SANSTHA MARYADIT , ISLAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 5831,
      "TXT_FINANCIER_NAME": "MERCHENT CO. OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5832,
      "TXT_FINANCIER_NAME": "MAHINDRA& MAHINDRA FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 5833,
      "TXT_FINANCIER_NAME": "THE ELAMKULAM SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5834,
      "TXT_FINANCIER_NAME": "THE CANARA DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5835,
      "TXT_FINANCIER_NAME": "THE CANARA DISTRICT CENTRAL CO OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 5836,
      "TXT_FINANCIER_NAME": "VELINALLOOR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5837,
      "TXT_FINANCIER_NAME": "THE VAZHOOR FARMERS SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5838,
      "TXT_FINANCIER_NAME": "THE BARODA CITY CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5839,
      "TXT_FINANCIER_NAME": "THE PALGHAT CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5840,
      "TXT_FINANCIER_NAME": "ANYONYA SAHAYAKARI MANDALI CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5841,
      "TXT_FINANCIER_NAME": "GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 5842,
      "TXT_FINANCIER_NAME": "THE NSR.CENT.CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 5843,
      "TXT_FINANCIER_NAME": "Shree Thyagarara co-operative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 5844,
      "TXT_FINANCIER_NAME": "DABHOI DASALAD CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 5845,
      "TXT_FINANCIER_NAME": "SHAKTHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5846,
      "TXT_FINANCIER_NAME": "NAVJEEVAN NAGARI SAHAKARI BANK MARYADIT PUNE"
    },
    {
      "NUM_FINANCIER_CD": 5847,
      "TXT_FINANCIER_NAME": "THE KHEDA JILLA MADHYA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5848,
      "TXT_FINANCIER_NAME": "SHRI BARIA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5849,
      "TXT_FINANCIER_NAME": "CITIZENS CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5850,
      "TXT_FINANCIER_NAME": "CITIZENS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5851,
      "TXT_FINANCIER_NAME": "UP CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 5852,
      "TXT_FINANCIER_NAME": "THE BULDANA JILLHA KENDRIYA SAHAKAI BANK LTD ."
    },
    {
      "NUM_FINANCIER_CD": 5853,
      "TXT_FINANCIER_NAME": "SHREE SAINATH GRAMIN BIGARSHETI SAHAKARI PATSANSTHA LTD., MANCHAR"
    },
    {
      "NUM_FINANCIER_CD": 5854,
      "TXT_FINANCIER_NAME": "INDERPRASTHA SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5855,
      "TXT_FINANCIER_NAME": "ALLANNAVAR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5856,
      "TXT_FINANCIER_NAME": "SRIRAMTRANSPORT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 5857,
      "TXT_FINANCIER_NAME": "DCB DEVELOPMENT CREDIT BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5858,
      "TXT_FINANCIER_NAME": "SOLAPUR SIDDESHWAR SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5859,
      "TXT_FINANCIER_NAME": "R.S.C.B.LTD."
    },
    {
      "NUM_FINANCIER_CD": 5860,
      "TXT_FINANCIER_NAME": "NTPC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 5861,
      "TXT_FINANCIER_NAME": "THE RAYAT CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 5862,
      "TXT_FINANCIER_NAME": "SAHYOG CO-OP.CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 5863,
      "TXT_FINANCIER_NAME": "ANDHRA BANK."
    },
    {
      "NUM_FINANCIER_CD": 5864,
      "TXT_FINANCIER_NAME": "SITPURA SKETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 5865,
      "TXT_FINANCIER_NAME": "THE LIMDI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5866,
      "TXT_FINANCIER_NAME": "THE AMOD NAGRIC CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5867,
      "TXT_FINANCIER_NAME": "THE BANGALORE DISTRICT AND BANGALORE RURAL DISTRICT CO-OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5868,
      "TXT_FINANCIER_NAME": "ADCB ABU DHABI COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 5869,
      "TXT_FINANCIER_NAME": "MCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5870,
      "TXT_FINANCIER_NAME": "SHREE OM FINVEST LTD"
    },
    {
      "NUM_FINANCIER_CD": 5871,
      "TXT_FINANCIER_NAME": "SIDDESHWAR SAHAKARI BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 5872,
      "TXT_FINANCIER_NAME": "THE KARJAN NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5873,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 5874,
      "TXT_FINANCIER_NAME": "THE KARUVARAKUNDU CO-OPERATIVE SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7164,
      "TXT_FINANCIER_NAME": "BARODAA UTTAR PRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7165,
      "TXT_FINANCIER_NAME": "SENIOR DIVISIONAL MANAGER"
    },
    {
      "NUM_FINANCIER_CD": 7166,
      "TXT_FINANCIER_NAME": "THE NAVHIND CO OP SOCY LTD BR WADGAON BELGAUM"
    },
    {
      "NUM_FINANCIER_CD": 7167,
      "TXT_FINANCIER_NAME": "SRI BASAVESWARA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7168,
      "TXT_FINANCIER_NAME": "SHRI DATTA NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7169,
      "TXT_FINANCIER_NAME": "Shree Bhairavnath Coop Credit Soc Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7170,
      "TXT_FINANCIER_NAME": "SRIGURU RAGHAVENDRA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7171,
      "TXT_FINANCIER_NAME": "VIJYA HEMANT FINANCE ESTATES LTD"
    },
    {
      "NUM_FINANCIER_CD": 7172,
      "TXT_FINANCIER_NAME": "THE C.K.P.CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7173,
      "TXT_FINANCIER_NAME": "LAXMI SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 7174,
      "TXT_FINANCIER_NAME": "KNS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7175,
      "TXT_FINANCIER_NAME": "THE SHAMARAO VITHAL CO-OP.BANK.LTD."
    },
    {
      "NUM_FINANCIER_CD": 7176,
      "TXT_FINANCIER_NAME": "THE PATHIRIYAD SERVICE CO,OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7177,
      "TXT_FINANCIER_NAME": "THE NEW ARBAN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7178,
      "TXT_FINANCIER_NAME": "THE KAKATIYA CO. OP. URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7179,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR ZILHA MARATHA SEVA S.P.S.LTD"
    },
    {
      "NUM_FINANCIER_CD": 7180,
      "TXT_FINANCIER_NAME": "THALASSERY CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7181,
      "TXT_FINANCIER_NAME": "THALASSERY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7182,
      "TXT_FINANCIER_NAME": "THALASSERY CO-OPRATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7183,
      "TXT_FINANCIER_NAME": "THE PATTOM COLONY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7184,
      "TXT_FINANCIER_NAME": "DAPOLI URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7185,
      "TXT_FINANCIER_NAME": "THE ERAMALA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7186,
      "TXT_FINANCIER_NAME": "PALLICKAL SERVICE COOPERATIVE BANK LTD.NO.722"
    },
    {
      "NUM_FINANCIER_CD": 7187,
      "TXT_FINANCIER_NAME": "THE BADAGARA CO-OP RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7188,
      "TXT_FINANCIER_NAME": "THE ALAKODESERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7189,
      "TXT_FINANCIER_NAME": "The Ratnakar Bank.ltd."
    },
    {
      "NUM_FINANCIER_CD": 7190,
      "TXT_FINANCIER_NAME": "POONA CANTONMENT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7191,
      "TXT_FINANCIER_NAME": "KADUNGALUR SERVICE CO -OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7192,
      "TXT_FINANCIER_NAME": "TRANSPORT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7193,
      "TXT_FINANCIER_NAME": "SHIRODA URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7194,
      "TXT_FINANCIER_NAME": "DEENDAYAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 7195,
      "TXT_FINANCIER_NAME": "THE NEHRU NAGAR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7196,
      "TXT_FINANCIER_NAME": "THE KERELA STATE CO - OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7197,
      "TXT_FINANCIER_NAME": "AMBERNATH JAIN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7198,
      "TXT_FINANCIER_NAME": "THE KOSAMBA MERCATILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7199,
      "TXT_FINANCIER_NAME": "YESHASWINI MAHILA REDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7200,
      "TXT_FINANCIER_NAME": "NIGHOJ GRAMIN SAHAKARI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 7201,
      "TXT_FINANCIER_NAME": "SHREE ARIHANT GRAMIN BIGAR SHETI SAHAKARI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 7202,
      "TXT_FINANCIER_NAME": "THE KASARAGOD DISTRICT CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7203,
      "TXT_FINANCIER_NAME": "NEDUMKANDAM CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7204,
      "TXT_FINANCIER_NAME": "SANGAMESHWAR GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7205,
      "TXT_FINANCIER_NAME": "BUSINESS VANTAGE"
    },
    {
      "NUM_FINANCIER_CD": 7206,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SHA. BANK LTD. PETH"
    },
    {
      "NUM_FINANCIER_CD": 7207,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SHA. BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 7208,
      "TXT_FINANCIER_NAME": "NARNADA MALWA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7209,
      "TXT_FINANCIER_NAME": "SUSHILKUMAR NAHATA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7210,
      "TXT_FINANCIER_NAME": "BHIRDEV SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7211,
      "TXT_FINANCIER_NAME": "DEUTSHE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7212,
      "TXT_FINANCIER_NAME": "DR.BABASAHEB AMBEDKAR NAGARI SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7213,
      "TXT_FINANCIER_NAME": "RAEBAERELI KSHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7214,
      "TXT_FINANCIER_NAME": "SIRSI URBAN CO OPEATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7215,
      "TXT_FINANCIER_NAME": "THCB"
    },
    {
      "NUM_FINANCIER_CD": 7216,
      "TXT_FINANCIER_NAME": "PLEASE ADD NEW BANK"
    },
    {
      "NUM_FINANCIER_CD": 7217,
      "TXT_FINANCIER_NAME": "SHIBPUR CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7218,
      "TXT_FINANCIER_NAME": "THE THIMIRI SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7219,
      "TXT_FINANCIER_NAME": "PATNESHWER URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7220,
      "TXT_FINANCIER_NAME": "VADNAGAR NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7221,
      "TXT_FINANCIER_NAME": "THE KADI NAGARIK SAHAKARI BANKLTD."
    },
    {
      "NUM_FINANCIER_CD": 7222,
      "TXT_FINANCIER_NAME": "BAYAD NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7223,
      "TXT_FINANCIER_NAME": "DISTT COPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7224,
      "TXT_FINANCIER_NAME": "COPERAT"
    },
    {
      "NUM_FINANCIER_CD": 7225,
      "TXT_FINANCIER_NAME": "BHEL CO-OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 7226,
      "TXT_FINANCIER_NAME": "ACE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7227,
      "TXT_FINANCIER_NAME": "VITA URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7228,
      "TXT_FINANCIER_NAME": "RAMNATHAPURAM DISTRIC CENERAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7229,
      "TXT_FINANCIER_NAME": "THE RAMNAD DIST.CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7230,
      "TXT_FINANCIER_NAME": "PEASE ADD NEW BANK"
    },
    {
      "NUM_FINANCIER_CD": 7231,
      "TXT_FINANCIER_NAME": "THE TARAPUR CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7232,
      "TXT_FINANCIER_NAME": "SURAT DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7233,
      "TXT_FINANCIER_NAME": "MATTANNUR CO-OP RURAL MANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7234,
      "TXT_FINANCIER_NAME": "SHRI KRISHNA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7235,
      "TXT_FINANCIER_NAME": "NIDHI CO.OP.BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7236,
      "TXT_FINANCIER_NAME": "NATIONAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7237,
      "TXT_FINANCIER_NAME": "THE NANITAL DISTT. CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7238,
      "TXT_FINANCIER_NAME": "RVB ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 7239,
      "TXT_FINANCIER_NAME": "R V B ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 7240,
      "TXT_FINANCIER_NAME": "SHREE KULASWAMI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7241,
      "TXT_FINANCIER_NAME": "THE SHEGAON SHRI AGRASEN SAH PAT SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 7242,
      "TXT_FINANCIER_NAME": "EDATHALA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7243,
      "TXT_FINANCIER_NAME": "NANDANPUR SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7244,
      "TXT_FINANCIER_NAME": "DISTT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7245,
      "TXT_FINANCIER_NAME": "THE VALANCHERY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7246,
      "TXT_FINANCIER_NAME": "THE KOTTAKKAL CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7247,
      "TXT_FINANCIER_NAME": "THE EDAVANNA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7248,
      "TXT_FINANCIER_NAME": "THE PARAPPANANGADI CO. OPERATIVE SERVICE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7249,
      "TXT_FINANCIER_NAME": "THE BELGAUM BAKERS CO-OP CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7250,
      "TXT_FINANCIER_NAME": "SRI RAM CITY UNION FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7251,
      "TXT_FINANCIER_NAME": "UTTRANCHAL GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7252,
      "TXT_FINANCIER_NAME": "ELAPULLY SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7253,
      "TXT_FINANCIER_NAME": "ALIBUAG CO-OP. URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7254,
      "TXT_FINANCIER_NAME": "DOMBAVALI NAGARI SAHAKARI BANK LTD DOMBIVALI (SCHEDULED BANK)"
    },
    {
      "NUM_FINANCIER_CD": 7255,
      "TXT_FINANCIER_NAME": "DDDOMBIVALI NAGARI SAHAKARI BANK LTD DOMBIVALI(SCHDULED BANK)"
    },
    {
      "NUM_FINANCIER_CD": 7256,
      "TXT_FINANCIER_NAME": "DOMBIVALI NAGARI SAHAKARI BANK LTD DOMBIVALI (SCHEDLED BANK)"
    },
    {
      "NUM_FINANCIER_CD": 7257,
      "TXT_FINANCIER_NAME": "PEOPLE URBAN COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7258,
      "TXT_FINANCIER_NAME": "JANATA SAHKARI BANK,LTD"
    },
    {
      "NUM_FINANCIER_CD": 7259,
      "TXT_FINANCIER_NAME": "THE NADIAD PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7260,
      "TXT_FINANCIER_NAME": "CHUNGATHARA SERVICE CO-OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 7261,
      "TXT_FINANCIER_NAME": "CHUNGATHARA SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7262,
      "TXT_FINANCIER_NAME": "CCETRAL BANK OF PUNJAB"
    },
    {
      "NUM_FINANCIER_CD": 6466,
      "TXT_FINANCIER_NAME": "HAL EMPLOYEES CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6467,
      "TXT_FINANCIER_NAME": "THE ARALAM SERVICE CO OPERATIVE BANK BANK"
    },
    {
      "NUM_FINANCIER_CD": 6468,
      "TXT_FINANCIER_NAME": "NOBEL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6469,
      "TXT_FINANCIER_NAME": "NOBLE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6470,
      "TXT_FINANCIER_NAME": "COMPTROLLER U A S"
    },
    {
      "NUM_FINANCIER_CD": 6471,
      "TXT_FINANCIER_NAME": "SANGAM CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6472,
      "TXT_FINANCIER_NAME": "SERICULTURISTS CUM FARMER SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6473,
      "TXT_FINANCIER_NAME": "PRAGATHI BHAVAN BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 6474,
      "TXT_FINANCIER_NAME": "SHREE DATTA KRUPA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6475,
      "TXT_FINANCIER_NAME": "NIRMAL UJWAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6476,
      "TXT_FINANCIER_NAME": "DYANDEEP CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6477,
      "TXT_FINANCIER_NAME": "S YOGESH KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 6478,
      "TXT_FINANCIER_NAME": "SHREE GURUDEVDATTA NAGARI PATHASANATHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6479,
      "TXT_FINANCIER_NAME": "Mand Hire Purchase Pvt Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6480,
      "TXT_FINANCIER_NAME": "BHARAT COOPERATIVE BANK MUMBAI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6481,
      "TXT_FINANCIER_NAME": "PAZHAYAKUNNINMEL SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 6482,
      "TXT_FINANCIER_NAME": "DHANASHRI MAHILA PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6483,
      "TXT_FINANCIER_NAME": "ERATTUPETTA AIDED SCHOOL TEACHERS COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6484,
      "TXT_FINANCIER_NAME": "THE KATLARY KARIYANA SAHKARI SARAFI MANDLI LTD MODASA"
    },
    {
      "NUM_FINANCIER_CD": 6485,
      "TXT_FINANCIER_NAME": "Sahayog Sahakari Path Sanstha"
    },
    {
      "NUM_FINANCIER_CD": 6486,
      "TXT_FINANCIER_NAME": "ERATTUPETTA AIDED SCHOOL TEACHERS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6487,
      "TXT_FINANCIER_NAME": "ERATTUPETTA AIDED SCHOOL TEACHERS COOPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6488,
      "TXT_FINANCIER_NAME": "THE ROPAR CENTRAL COPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6489,
      "TXT_FINANCIER_NAME": "SUDARSAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6490,
      "TXT_FINANCIER_NAME": "MINISTRY OF EARTH SERVICES GOI"
    },
    {
      "NUM_FINANCIER_CD": 6491,
      "TXT_FINANCIER_NAME": "MINISTRY OF EARTH SCIENCES GOI"
    },
    {
      "NUM_FINANCIER_CD": 6492,
      "TXT_FINANCIER_NAME": "MINISTRY OF EARTH SCIENCES GOI NEW DELHI"
    },
    {
      "NUM_FINANCIER_CD": 6493,
      "TXT_FINANCIER_NAME": "A P I I CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 6494,
      "TXT_FINANCIER_NAME": "Nirmal Ujjwal Urban Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6495,
      "TXT_FINANCIER_NAME": "SAHYOG SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6496,
      "TXT_FINANCIER_NAME": "Govt of Bihar"
    },
    {
      "NUM_FINANCIER_CD": 6497,
      "TXT_FINANCIER_NAME": "SRI LAKSHMI MULTIPURPOSE CO OPERATIVE SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 6498,
      "TXT_FINANCIER_NAME": "GOKUL URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6499,
      "TXT_FINANCIER_NAME": "SRI M VISHWESHWARAIAH SAHAKAR BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 6500,
      "TXT_FINANCIER_NAME": "GANESH GRAMIN BIGARSHETTI SAHAKARI PATHSANSTHA MARYADIT SALGAR"
    },
    {
      "NUM_FINANCIER_CD": 6501,
      "TXT_FINANCIER_NAME": "THE MERCHANTS SOUHARDA SAHAKAR BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 6502,
      "TXT_FINANCIER_NAME": "SHREE BALAJI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT OTUR"
    },
    {
      "NUM_FINANCIER_CD": 6503,
      "TXT_FINANCIER_NAME": "KOTTAKKAL CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6504,
      "TXT_FINANCIER_NAME": "KAMREJ VIBHAG NAGRIK BACHAT AND DHIRAN KARNARI SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6505,
      "TXT_FINANCIER_NAME": "LAMP SOCIETY KARKALA"
    },
    {
      "NUM_FINANCIER_CD": 6506,
      "TXT_FINANCIER_NAME": "SINCERE SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 6507,
      "TXT_FINANCIER_NAME": "THE TARN TARAN CENTRAL COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6508,
      "TXT_FINANCIER_NAME": "DARRU SALAM URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6509,
      "TXT_FINANCIER_NAME": "AD MENUM FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6510,
      "TXT_FINANCIER_NAME": "BANK OF INDAI BRANCH GHAZIABAD"
    },
    {
      "NUM_FINANCIER_CD": 6511,
      "TXT_FINANCIER_NAME": "SHRAM DEEP CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 6512,
      "TXT_FINANCIER_NAME": "SANMATHI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6513,
      "TXT_FINANCIER_NAME": "KANDALA SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6514,
      "TXT_FINANCIER_NAME": "B H E L COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6515,
      "TXT_FINANCIER_NAME": "SHI RAM TRADE FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6516,
      "TXT_FINANCIER_NAME": "LT DEVENDRA SARDA NAGRI SAHAKARI PATHSANSTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6517,
      "TXT_FINANCIER_NAME": "DHAMAN VIBHAG SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6518,
      "TXT_FINANCIER_NAME": "O P G C LTD BANHARPALI"
    },
    {
      "NUM_FINANCIER_CD": 6519,
      "TXT_FINANCIER_NAME": "PRIMARY CO OPERATIVE AGRICULTRE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6520,
      "TXT_FINANCIER_NAME": "MOODABIDRI CO OP SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6521,
      "TXT_FINANCIER_NAME": "KOLHAPUR PARSHWANATH NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6522,
      "TXT_FINANCIER_NAME": "GOVERMENT OF TAMILNADU"
    },
    {
      "NUM_FINANCIER_CD": 6523,
      "TXT_FINANCIER_NAME": "THE KHANAPUR URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6524,
      "TXT_FINANCIER_NAME": "MAHAVEER COOP CREDIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 6525,
      "TXT_FINANCIER_NAME": "CHATRAPATI SHIVRAI NAGRI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6526,
      "TXT_FINANCIER_NAME": "THE KARKALA CO OP SERVICE BANK LTD KARKALA"
    },
    {
      "NUM_FINANCIER_CD": 6527,
      "TXT_FINANCIER_NAME": "RASHTRAMATA JIJAU NAGARI SAHKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6528,
      "TXT_FINANCIER_NAME": "Indian Metals and Ferro Alloys Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6529,
      "TXT_FINANCIER_NAME": "VAZHOOR FARMERS SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6530,
      "TXT_FINANCIER_NAME": "RDCIS"
    },
    {
      "NUM_FINANCIER_CD": 6531,
      "TXT_FINANCIER_NAME": "PRAGJIBHAI PURSHOTAMDAS SHROFF"
    },
    {
      "NUM_FINANCIER_CD": 6532,
      "TXT_FINANCIER_NAME": "SHREE MATA COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6533,
      "TXT_FINANCIER_NAME": "RATNAGIRI JILHA PRATHMIK SHIKSHAN PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6534,
      "TXT_FINANCIER_NAME": "The Samta Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 6535,
      "TXT_FINANCIER_NAME": "THE THONDIYIL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6536,
      "TXT_FINANCIER_NAME": "BAIDYABATI SHEORAPHALI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6537,
      "TXT_FINANCIER_NAME": "SARDAR GANJ MERCANTILE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6538,
      "TXT_FINANCIER_NAME": "M.S.CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6539,
      "TXT_FINANCIER_NAME": "THE SARSWAT COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6540,
      "TXT_FINANCIER_NAME": "THE SARSWAT COOPERATIVE LTD."
    },
    {
      "NUM_FINANCIER_CD": 6541,
      "TXT_FINANCIER_NAME": "NSR CENT. CO-OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 6542,
      "TXT_FINANCIER_NAME": "NAWANSHAHR CENTRAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6543,
      "TXT_FINANCIER_NAME": "CHYA NAGARI SAHAKARI PATHA SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6544,
      "TXT_FINANCIER_NAME": "KARIMPAN SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6545,
      "TXT_FINANCIER_NAME": "DR.ANNASAHEB CHOUGULE URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6546,
      "TXT_FINANCIER_NAME": "NAVABHARAT BANK"
    },
    {
      "NUM_FINANCIER_CD": 6547,
      "TXT_FINANCIER_NAME": "THE VALLABH VIDYANAGAR COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6548,
      "TXT_FINANCIER_NAME": "EKVEERA NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6549,
      "TXT_FINANCIER_NAME": "EKVEERA NAGARI SAHAKARI PAT SANSTHA LTD."
    },
    {
      "NUM_FINANCIER_CD": 6550,
      "TXT_FINANCIER_NAME": "D.G. GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6551,
      "TXT_FINANCIER_NAME": "kvb"
    },
    {
      "NUM_FINANCIER_CD": 6552,
      "TXT_FINANCIER_NAME": "KOTTAKKAL CO-OP. URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6553,
      "TXT_FINANCIER_NAME": "THE THALAPPALAM SERVICE CO-OPERATIVE BANKLIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6554,
      "TXT_FINANCIER_NAME": "SHRI SANT NAMDEV NAGARI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6555,
      "TXT_FINANCIER_NAME": "SHRI SANT NAMDEV N.S.P.S.LTD"
    },
    {
      "NUM_FINANCIER_CD": 6556,
      "TXT_FINANCIER_NAME": "THE KURUKSHETRA CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6557,
      "TXT_FINANCIER_NAME": "SHAHUPURI VYAPARI NAG SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6558,
      "TXT_FINANCIER_NAME": "MAHINDRA&ampMAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6559,
      "TXT_FINANCIER_NAME": "PRAGATI GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6560,
      "TXT_FINANCIER_NAME": "BASSIN CATHOLIC CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6561,
      "TXT_FINANCIER_NAME": "THE MADAVOOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6562,
      "TXT_FINANCIER_NAME": "HARI PRIYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6563,
      "TXT_FINANCIER_NAME": "HYDERABAD DISTRICT CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6564,
      "TXT_FINANCIER_NAME": "SHRIU LAXMI SAHAKARI BANK LTD., SATARA"
    },
    {
      "NUM_FINANCIER_CD": 6565,
      "TXT_FINANCIER_NAME": "BHEL"
    },
    {
      "NUM_FINANCIER_CD": 6566,
      "TXT_FINANCIER_NAME": "PITHORAGARH JILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6567,
      "TXT_FINANCIER_NAME": "PUNYASHLOK AHILYADEVI HOLKAR NAGARI SAH.PAT.MDT."
    },
    {
      "NUM_FINANCIER_CD": 6568,
      "TXT_FINANCIER_NAME": "THE SEDERAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6569,
      "TXT_FINANCIER_NAME": "BHOPAL CO-OP. CENTRAL BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 6570,
      "TXT_FINANCIER_NAME": "THE KOLARI SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6571,
      "TXT_FINANCIER_NAME": "THE KANGRA CEN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6572,
      "TXT_FINANCIER_NAME": "SHEDBAL URBAN CO-OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6573,
      "TXT_FINANCIER_NAME": "KAMALA CO-OP BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 6574,
      "TXT_FINANCIER_NAME": "THE ZILAH MADYAVARTI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6575,
      "TXT_FINANCIER_NAME": "BHAGYODYA"
    },
    {
      "NUM_FINANCIER_CD": 6576,
      "TXT_FINANCIER_NAME": "MAGMA SARACI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6577,
      "TXT_FINANCIER_NAME": "canturion bank of pujab"
    },
    {
      "NUM_FINANCIER_CD": 6578,
      "TXT_FINANCIER_NAME": "GE MONEY FIN. SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 6579,
      "TXT_FINANCIER_NAME": "THE ZALOD URBAN CO.OP BNK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6580,
      "TXT_FINANCIER_NAME": "THE PERAMBRA REGIONAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6581,
      "TXT_FINANCIER_NAME": "Magma Shranchi Fin.Tld"
    },
    {
      "NUM_FINANCIER_CD": 6582,
      "TXT_FINANCIER_NAME": "Magma Shranchi Fin.Ltd."
    },
    {
      "NUM_FINANCIER_CD": 6583,
      "TXT_FINANCIER_NAME": "THE KASARAGOD DISTRICT CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6584,
      "TXT_FINANCIER_NAME": "GE MONEY FINANICAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6585,
      "TXT_FINANCIER_NAME": "J&ampJ BUYING SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 6586,
      "TXT_FINANCIER_NAME": "PANNIVIZHA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6587,
      "TXT_FINANCIER_NAME": "SHREE SHANTESHWARA PATTINA SHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 6588,
      "TXT_FINANCIER_NAME": "SUNDADARAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6589,
      "TXT_FINANCIER_NAME": "Shree Lodra Nagrik Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6590,
      "TXT_FINANCIER_NAME": "SRI NETAJI CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6591,
      "TXT_FINANCIER_NAME": "THE RAMOL NAGRIK CREDIT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6592,
      "TXT_FINANCIER_NAME": "ALIBAUG CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6593,
      "TXT_FINANCIER_NAME": "KOSI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6594,
      "TXT_FINANCIER_NAME": "NATIONAL BANK OF AGRICULTURAL AND RURAL DEVELOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 6595,
      "TXT_FINANCIER_NAME": "PRINCE MOTOR FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6596,
      "TXT_FINANCIER_NAME": "BARCLAYS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6597,
      "TXT_FINANCIER_NAME": "Gadhinglaj Taluka Vyapari Nag Sah Patha Sanstha"
    },
    {
      "NUM_FINANCIER_CD": 6598,
      "TXT_FINANCIER_NAME": "BRAMHAN HITAWARDHINI GRAMIN BIGARSHETI SAH PATHASANATHA"
    },
    {
      "NUM_FINANCIER_CD": 6599,
      "TXT_FINANCIER_NAME": "UDUPI MUDRAKARA SOUHARDA SAHAKARI NIYAMITHA UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 6600,
      "TXT_FINANCIER_NAME": "SHARADA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6601,
      "TXT_FINANCIER_NAME": "SDCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6602,
      "TXT_FINANCIER_NAME": "SHRI RAGHAVENDRA COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6603,
      "TXT_FINANCIER_NAME": "SASKA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6604,
      "TXT_FINANCIER_NAME": "BHARAT DYNAMICS LTD"
    },
    {
      "NUM_FINANCIER_CD": 6605,
      "TXT_FINANCIER_NAME": "RMSI"
    },
    {
      "NUM_FINANCIER_CD": 6606,
      "TXT_FINANCIER_NAME": "HIMATSINGKA MOTOR WORKS LTD"
    },
    {
      "NUM_FINANCIER_CD": 6607,
      "TXT_FINANCIER_NAME": "JAI FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6608,
      "TXT_FINANCIER_NAME": "PRAKRUTHI CHITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6609,
      "TXT_FINANCIER_NAME": "IRINAVU SERVICE COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6610,
      "TXT_FINANCIER_NAME": "AGASTYA CREDIT COOPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6611,
      "TXT_FINANCIER_NAME": "LUCAS INDIAN SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6612,
      "TXT_FINANCIER_NAME": "LUCAS INDIAN SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6613,
      "TXT_FINANCIER_NAME": "TOTALFINAELF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6614,
      "TXT_FINANCIER_NAME": "DHANASHRI MAHILA GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MANGALWEDHAMARYADIT SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 6615,
      "TXT_FINANCIER_NAME": "CHAMARAO VITTAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6616,
      "TXT_FINANCIER_NAME": "ANIIDCO"
    },
    {
      "NUM_FINANCIER_CD": 6617,
      "TXT_FINANCIER_NAME": "GUTHIGEDARARA CREDIT CO OPT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6618,
      "TXT_FINANCIER_NAME": "GURLAPUR URBAN C OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6619,
      "TXT_FINANCIER_NAME": "DISTRICT ANTYAVAVSAI COOPERATIVE DEVELOPMENT SOCIETY MARYADIT DEVELOPMENT SOCIETY MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6620,
      "TXT_FINANCIER_NAME": "JEEVAN REKHA NAGARI SAHAKARI PATSANASTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6621,
      "TXT_FINANCIER_NAME": "UDAYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6622,
      "TXT_FINANCIER_NAME": "THE DOMAVAMSHA SAHASRARJUNA KSHATRIYA COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6623,
      "TXT_FINANCIER_NAME": "BASAVESHWARA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6624,
      "TXT_FINANCIER_NAME": "SPACE APPLICATIONS CENTER"
    },
    {
      "NUM_FINANCIER_CD": 6625,
      "TXT_FINANCIER_NAME": "HOYSALA CREDIT SOUDHARDHA COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6626,
      "TXT_FINANCIER_NAME": "SRIRAM FRANSPORT FINANCIAL CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 6627,
      "TXT_FINANCIER_NAME": "SRI VENUGOPA LAKRISHNA CREDIT CO OPETATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6628,
      "TXT_FINANCIER_NAME": "SHREE SAPATSHRUNGA GRAMIN BIGARSETI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6629,
      "TXT_FINANCIER_NAME": "THE KUMBAKONAM CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6630,
      "TXT_FINANCIER_NAME": "JAIPUR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6631,
      "TXT_FINANCIER_NAME": "BARODA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6632,
      "TXT_FINANCIER_NAME": "SHRI RAM SAHAKARI PATH SANSHTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6633,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6634,
      "TXT_FINANCIER_NAME": "STANDARD SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6635,
      "TXT_FINANCIER_NAME": "INDIAN RESERVE ENERGY DEVELOPMENT AUTHORITY"
    },
    {
      "NUM_FINANCIER_CD": 6636,
      "TXT_FINANCIER_NAME": "MANN DESHI MAHILA SAHAKARI BANK LTD MHASWAD"
    },
    {
      "NUM_FINANCIER_CD": 6637,
      "TXT_FINANCIER_NAME": "LATE DEVENDRA SARADA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6638,
      "TXT_FINANCIER_NAME": "CHIKKAMAGALUR KODAGU GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6639,
      "TXT_FINANCIER_NAME": "CHIKKAMAGALUR KODAGU GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6640,
      "TXT_FINANCIER_NAME": "BHAGYODAY COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6641,
      "TXT_FINANCIER_NAME": "NANESH CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 6642,
      "TXT_FINANCIER_NAME": "THE MERCHANTS SOUHARDA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 6643,
      "TXT_FINANCIER_NAME": "Akash Finance Company LTD"
    },
    {
      "NUM_FINANCIER_CD": 6644,
      "TXT_FINANCIER_NAME": "UNDER SECRETARY GENERAL GOVERNMENT OF HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 6645,
      "TXT_FINANCIER_NAME": "SHREE CHEERUMBA CREDIT CO OP SOCIETY LTD URWASTORE"
    },
    {
      "NUM_FINANCIER_CD": 6646,
      "TXT_FINANCIER_NAME": "THE TIRUPUR COOPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6647,
      "TXT_FINANCIER_NAME": "SAPTHAGIRI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6648,
      "TXT_FINANCIER_NAME": "SAINIK FINANCE AND INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6649,
      "TXT_FINANCIER_NAME": "ASSISTANT ADMINISTRATIVE OFFICER"
    },
    {
      "NUM_FINANCIER_CD": 6650,
      "TXT_FINANCIER_NAME": "KRUSHNAI GRAMIN BIGAR SHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6651,
      "TXT_FINANCIER_NAME": "CHOLAMANDALM D B S FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6652,
      "TXT_FINANCIER_NAME": "UTKRANTI NAG SAH PAT SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 6653,
      "TXT_FINANCIER_NAME": "PARSWANATHA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6654,
      "TXT_FINANCIER_NAME": "MALAKAPUR NAGAR SAH PATH SANSTHA MARADIT MALKAPUR"
    },
    {
      "NUM_FINANCIER_CD": 6655,
      "TXT_FINANCIER_NAME": "KARMAVIR BHAURAO PATIL Z S P S L KUMBHOJ"
    },
    {
      "NUM_FINANCIER_CD": 6656,
      "TXT_FINANCIER_NAME": "LT DEVENDRA SARDA NAGRI SAHAKARI PATHSANSTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6657,
      "TXT_FINANCIER_NAME": "SANT DNYANESHWAR NAGARI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6658,
      "TXT_FINANCIER_NAME": "NAGESHWAR NAGARI SAHKARI PATSANSTHA KHANDALA"
    },
    {
      "NUM_FINANCIER_CD": 6659,
      "TXT_FINANCIER_NAME": "MULLAKODY SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6660,
      "TXT_FINANCIER_NAME": "SHIVA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6661,
      "TXT_FINANCIER_NAME": "ROYAL SUNDERM FIANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6662,
      "TXT_FINANCIER_NAME": "THE BAJPE VYAVASAYA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6663,
      "TXT_FINANCIER_NAME": "REDDI COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6664,
      "TXT_FINANCIER_NAME": "KARANJAMATA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6665,
      "TXT_FINANCIER_NAME": "THE COOPREATIVE BNAK OF MEHSANA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6666,
      "TXT_FINANCIER_NAME": "ABHUDAYA COOP BANK LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 6667,
      "TXT_FINANCIER_NAME": "sree charan bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 6668,
      "TXT_FINANCIER_NAME": "SHREE MATA MAHILA MULTIPURPOSE COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6669,
      "TXT_FINANCIER_NAME": "NAINITAL DIST CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6670,
      "TXT_FINANCIER_NAME": "THE GOA STATE AGRICULTURAL MARKETING BOARD"
    },
    {
      "NUM_FINANCIER_CD": 6671,
      "TXT_FINANCIER_NAME": "THE KANNUR CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6672,
      "TXT_FINANCIER_NAME": "TIRUMALA CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6673,
      "TXT_FINANCIER_NAME": "THE-POOVARANY SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6674,
      "TXT_FINANCIER_NAME": "THE KARNAVATI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6675,
      "TXT_FINANCIER_NAME": "ASKHAYA CO-OPERATIVE CREDIT SOCIETY LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6676,
      "TXT_FINANCIER_NAME": "AKSHAYA CO-OP SOCIETY LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6677,
      "TXT_FINANCIER_NAME": "NANITAL DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6678,
      "TXT_FINANCIER_NAME": "NAINITAL DISTT.CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6679,
      "TXT_FINANCIER_NAME": "ALMORA-URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6680,
      "TXT_FINANCIER_NAME": "THE SHERTALLAY CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6681,
      "TXT_FINANCIER_NAME": "SHRI LAXMI GR.BIG.S.S.P.S.LTD; DATTAWAD"
    },
    {
      "NUM_FINANCIER_CD": 6682,
      "TXT_FINANCIER_NAME": "THE VALLIKUNNU SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6683,
      "TXT_FINANCIER_NAME": "ORIENTAL COMMERCE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6684,
      "TXT_FINANCIER_NAME": "KARIMANNOOR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6685,
      "TXT_FINANCIER_NAME": "NEDUMKUNNAM SERVICE CO-OPERATIVE BANK LT."
    },
    {
      "NUM_FINANCIER_CD": 6686,
      "TXT_FINANCIER_NAME": "CHOLAMANDLAM D B S FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6687,
      "TXT_FINANCIER_NAME": "THE OSMANABAD D.C.C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6688,
      "TXT_FINANCIER_NAME": "THE FEROZEPUR CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6689,
      "TXT_FINANCIER_NAME": "THE FEROZEPUR CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6690,
      "TXT_FINANCIER_NAME": "ANAND MER.CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6691,
      "TXT_FINANCIER_NAME": "THE DARUSSALAM CO-OP. URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6692,
      "TXT_FINANCIER_NAME": "THE KUNNIRIKKA SERVICE CO-OP: BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6693,
      "TXT_FINANCIER_NAME": "T D C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6694,
      "TXT_FINANCIER_NAME": "SHREE SAMARTH GRAMIN BIGGAR SHETI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6695,
      "TXT_FINANCIER_NAME": "SHRI SHARAN VEERESHWAR SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 6696,
      "TXT_FINANCIER_NAME": "THE PARAVUR SNV REGIONAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6697,
      "TXT_FINANCIER_NAME": "KUTTIADI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6698,
      "TXT_FINANCIER_NAME": "KAYAKKODI SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6699,
      "TXT_FINANCIER_NAME": "THE SIVAKASI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6700,
      "TXT_FINANCIER_NAME": "JANATA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6701,
      "TXT_FINANCIER_NAME": "QUILON COOPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6702,
      "TXT_FINANCIER_NAME": "UTTRANCHAL GRAMIN BANK, PITHORAGARH"
    },
    {
      "NUM_FINANCIER_CD": 6703,
      "TXT_FINANCIER_NAME": "BHARTIYA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6704,
      "TXT_FINANCIER_NAME": "THE AMALNER CO-OP.URBAN BANK LTD.,AMALNER.DIST, JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 6705,
      "TXT_FINANCIER_NAME": "MAGMA SRACHI FIN. LTD."
    },
    {
      "NUM_FINANCIER_CD": 6706,
      "TXT_FINANCIER_NAME": "THE NANDURBAR MARCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6707,
      "TXT_FINANCIER_NAME": "THE GURUVAYUR CO-OP. URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6708,
      "TXT_FINANCIER_NAME": "THE BAGALKOT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6709,
      "TXT_FINANCIER_NAME": "SHREE CHHATRAPATI URBAN CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6710,
      "TXT_FINANCIER_NAME": "SUVARNAYUG SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6711,
      "TXT_FINANCIER_NAME": "THE KUTTIADI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6712,
      "TXT_FINANCIER_NAME": "DISTT. CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6713,
      "TXT_FINANCIER_NAME": "DHARMADAM SERVICE CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6714,
      "TXT_FINANCIER_NAME": "D.D.C.C. BANK"
    },
    {
      "NUM_FINANCIER_CD": 6715,
      "TXT_FINANCIER_NAME": "THE COCHIN CO-OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6716,
      "TXT_FINANCIER_NAME": "shubhlakshmi mahila co-operative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 6717,
      "TXT_FINANCIER_NAME": "MAGMA SHARCHI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 6718,
      "TXT_FINANCIER_NAME": "gfd"
    },
    {
      "NUM_FINANCIER_CD": 6719,
      "TXT_FINANCIER_NAME": "G E MONEY FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6720,
      "TXT_FINANCIER_NAME": "CO-OP BANK OF BARODALTD"
    },
    {
      "NUM_FINANCIER_CD": 6721,
      "TXT_FINANCIER_NAME": "SBM"
    },
    {
      "NUM_FINANCIER_CD": 6722,
      "TXT_FINANCIER_NAME": "THE A.B.E. CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6723,
      "TXT_FINANCIER_NAME": "RAMESHWAR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6724,
      "TXT_FINANCIER_NAME": "THDC LTD."
    },
    {
      "NUM_FINANCIER_CD": 6725,
      "TXT_FINANCIER_NAME": "ETAH DISTT. CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6726,
      "TXT_FINANCIER_NAME": "THE MAHEMDAVAD ARBAN PEOPLE COOP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6727,
      "TXT_FINANCIER_NAME": "AGRO CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6728,
      "TXT_FINANCIER_NAME": "ADARSH CO-OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6729,
      "TXT_FINANCIER_NAME": "N.T.P.C. LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6730,
      "TXT_FINANCIER_NAME": "BARCLAYS"
    },
    {
      "NUM_FINANCIER_CD": 6731,
      "TXT_FINANCIER_NAME": "G E MONEY SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6732,
      "TXT_FINANCIER_NAME": "RENUKAMBA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6733,
      "TXT_FINANCIER_NAME": "BARODA EASTER UP GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6734,
      "TXT_FINANCIER_NAME": "SHRI DURGA MAHILA CO OP CREDIT VIVIDODDESHAGALA SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 6735,
      "TXT_FINANCIER_NAME": "BHAGOYADHA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6736,
      "TXT_FINANCIER_NAME": "SETHI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 6737,
      "TXT_FINANCIER_NAME": "CHIC KO BANK"
    },
    {
      "NUM_FINANCIER_CD": 6738,
      "TXT_FINANCIER_NAME": "SRI UDAYARAVI SOUHARDHA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6739,
      "TXT_FINANCIER_NAME": "KASARAGOD CO OP TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6740,
      "TXT_FINANCIER_NAME": "PANDHURLI VIVIDH SEVA SAH SOC"
    },
    {
      "NUM_FINANCIER_CD": 6741,
      "TXT_FINANCIER_NAME": "THE DAIVADNYA MAHILA COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6742,
      "TXT_FINANCIER_NAME": "THE MANDYA DIST CO OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6743,
      "TXT_FINANCIER_NAME": "PALSU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6744,
      "TXT_FINANCIER_NAME": "ST ADMIN"
    },
    {
      "NUM_FINANCIER_CD": 6745,
      "TXT_FINANCIER_NAME": "SREE AGNI BANNIRAYASWAMY CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6746,
      "TXT_FINANCIER_NAME": "GOA SHIPYARD LIMITED VASCO"
    },
    {
      "NUM_FINANCIER_CD": 6747,
      "TXT_FINANCIER_NAME": "DWARAKA SOUHARDA CREDIT SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 6748,
      "TXT_FINANCIER_NAME": "RAJDHANI MOTOR FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 6749,
      "TXT_FINANCIER_NAME": "PRINCIPAL GZSCET BATHINDA"
    },
    {
      "NUM_FINANCIER_CD": 6750,
      "TXT_FINANCIER_NAME": "THE HOSHIARPUR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6751,
      "TXT_FINANCIER_NAME": "SAINI SAKH SEHKARITA"
    },
    {
      "NUM_FINANCIER_CD": 6752,
      "TXT_FINANCIER_NAME": "GAYATHRI CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6753,
      "TXT_FINANCIER_NAME": "RISHAB INVESMENT"
    },
    {
      "NUM_FINANCIER_CD": 6754,
      "TXT_FINANCIER_NAME": "NOVELL SOFTAWRE DEVELOPMENT I PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6755,
      "TXT_FINANCIER_NAME": "GAYATRI URBAN COOP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6756,
      "TXT_FINANCIER_NAME": "NOVELL SOFTWARE DEVELOPMENT I PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6757,
      "TXT_FINANCIER_NAME": "BILARA SAHKARI BHUMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6758,
      "TXT_FINANCIER_NAME": "THE PIJ PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6759,
      "TXT_FINANCIER_NAME": "THE RUPEE CO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6760,
      "TXT_FINANCIER_NAME": "SHRI BHAGWANT SAHAKARI PATHSANSTHA BARSHI"
    },
    {
      "NUM_FINANCIER_CD": 6761,
      "TXT_FINANCIER_NAME": "KALIKADEVI NAGARI SAH PATH SANSTHA KARAD"
    },
    {
      "NUM_FINANCIER_CD": 6762,
      "TXT_FINANCIER_NAME": "KALIKADEVI NAGARI SAHKARI PATH SANSTHA KARAD"
    },
    {
      "NUM_FINANCIER_CD": 6763,
      "TXT_FINANCIER_NAME": "JAI BAJARANG GRAMIN BIGAR SHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6764,
      "TXT_FINANCIER_NAME": "SHRIVEERA PULIKESHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6765,
      "TXT_FINANCIER_NAME": "PRICEWATERHOUSE COOPERS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6766,
      "TXT_FINANCIER_NAME": "THE SOUTH CANARA DISTRICT CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6767,
      "TXT_FINANCIER_NAME": "SHREENATH KRUPA NAGARI SAH PATHASANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6768,
      "TXT_FINANCIER_NAME": "BAJIPRABHU NAGARI SAH PATH SANSTHA MARYADIT ICHALKARANJI"
    },
    {
      "NUM_FINANCIER_CD": 6769,
      "TXT_FINANCIER_NAME": "THE HINDUSTHAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6770,
      "TXT_FINANCIER_NAME": "NATIONAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 6771,
      "TXT_FINANCIER_NAME": "MP MANN"
    },
    {
      "NUM_FINANCIER_CD": 6772,
      "TXT_FINANCIER_NAME": "THE REWARI CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6773,
      "TXT_FINANCIER_NAME": "DYANDEEP CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6774,
      "TXT_FINANCIER_NAME": "ANNASAHEB KUCHNURE N.S.PAT.SAN.SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 6775,
      "TXT_FINANCIER_NAME": "SHRI MAHAVIR URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6776,
      "TXT_FINANCIER_NAME": "OMKAR NAGREEYA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6777,
      "TXT_FINANCIER_NAME": "SANGA MITHRA CO OP URBAN BANJ"
    },
    {
      "NUM_FINANCIER_CD": 6778,
      "TXT_FINANCIER_NAME": "SANGA MITHRA CO OP URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6779,
      "TXT_FINANCIER_NAME": "MAHA NAGAR CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6780,
      "TXT_FINANCIER_NAME": "SHRIKRISHANA CO-OPERATIVE BANK LTD, UMRER"
    },
    {
      "NUM_FINANCIER_CD": 6781,
      "TXT_FINANCIER_NAME": "SAIDAPET CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6782,
      "TXT_FINANCIER_NAME": "THE PANDHARPUR MERCHANTS CO-OP BANK LTD,PANDHARPUR."
    },
    {
      "NUM_FINANCIER_CD": 6783,
      "TXT_FINANCIER_NAME": "M.P. RAJYA SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 6784,
      "TXT_FINANCIER_NAME": "CENTRAL COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6785,
      "TXT_FINANCIER_NAME": "THE SANGLI COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6786,
      "TXT_FINANCIER_NAME": "SERVICE CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6787,
      "TXT_FINANCIER_NAME": "THE KODINAR NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6788,
      "TXT_FINANCIER_NAME": "MAHILA MARCHANT NAGARI SAHAKARI PAT SANSTHA MARYADIT,KARAD"
    },
    {
      "NUM_FINANCIER_CD": 6789,
      "TXT_FINANCIER_NAME": "DT CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6790,
      "TXT_FINANCIER_NAME": "THE GONDIA DISTRICT CENTRAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6791,
      "TXT_FINANCIER_NAME": "THE ALAPUZHA DISTRICT CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6792,
      "TXT_FINANCIER_NAME": "DISTRICT CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6793,
      "TXT_FINANCIER_NAME": "KANGRA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6794,
      "TXT_FINANCIER_NAME": "BALANGIR ANCHALIK GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6795,
      "TXT_FINANCIER_NAME": "THE AGRA DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6796,
      "TXT_FINANCIER_NAME": "AJIT SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6797,
      "TXT_FINANCIER_NAME": "YHE BANASKANTHA DIST. CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6798,
      "TXT_FINANCIER_NAME": "SARYU GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6799,
      "TXT_FINANCIER_NAME": "SONBHADRA N S BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6800,
      "TXT_FINANCIER_NAME": "VIJAYASHREE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 6801,
      "TXT_FINANCIER_NAME": "INDO SINDH BANK"
    },
    {
      "NUM_FINANCIER_CD": 6802,
      "TXT_FINANCIER_NAME": "The Abdul Rahiman Nagar Service Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6803,
      "TXT_FINANCIER_NAME": "NAINITAL DISTT. CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6804,
      "TXT_FINANCIER_NAME": "VIDHUR GRAHMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6805,
      "TXT_FINANCIER_NAME": "THE ODE URBEN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6806,
      "TXT_FINANCIER_NAME": "B.P.C.L"
    },
    {
      "NUM_FINANCIER_CD": 6807,
      "TXT_FINANCIER_NAME": "JATH URBAN CO.-OP. BANK LTD., JATH"
    },
    {
      "NUM_FINANCIER_CD": 6808,
      "TXT_FINANCIER_NAME": "GRAMEENA SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6809,
      "TXT_FINANCIER_NAME": "Babasaheb Deshmukh Sahakari Bank Ltd., Atpadi"
    },
    {
      "NUM_FINANCIER_CD": 6810,
      "TXT_FINANCIER_NAME": "PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6811,
      "TXT_FINANCIER_NAME": "m m"
    },
    {
      "NUM_FINANCIER_CD": 6812,
      "TXT_FINANCIER_NAME": "AP MAHAJANS CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6813,
      "TXT_FINANCIER_NAME": "BHEL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6814,
      "TXT_FINANCIER_NAME": "MANAKULAM SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6815,
      "TXT_FINANCIER_NAME": "SHOLAPUR NAGARI AUDYOGIK SAHAKARI BANK NIYAMIT, SHOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 6816,
      "TXT_FINANCIER_NAME": "THE BANK OF TOKYO-MITSUBISHI UFG, LTD."
    },
    {
      "NUM_FINANCIER_CD": 6817,
      "TXT_FINANCIER_NAME": "SHREE BHAIRAVNATH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6818,
      "TXT_FINANCIER_NAME": "SHREE BHAIRAVNATH NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 6819,
      "TXT_FINANCIER_NAME": "KRUSHNA SAH BANK RETRE BUDRUK"
    },
    {
      "NUM_FINANCIER_CD": 6820,
      "TXT_FINANCIER_NAME": "SURAT BHARUCH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6821,
      "TXT_FINANCIER_NAME": "NMDC LTD."
    },
    {
      "NUM_FINANCIER_CD": 6822,
      "TXT_FINANCIER_NAME": "NAGRI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 6823,
      "TXT_FINANCIER_NAME": "THE KURUKSHETRA CENTRAL CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6824,
      "TXT_FINANCIER_NAME": "ALLEPPEY DIST. CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6825,
      "TXT_FINANCIER_NAME": "THE VAZHIKKADAVU SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6826,
      "TXT_FINANCIER_NAME": "DEVGIRI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6827,
      "TXT_FINANCIER_NAME": "SURAT DISTRICT CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6828,
      "TXT_FINANCIER_NAME": "Mahaveer Co-op Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6829,
      "TXT_FINANCIER_NAME": "THE Veerashaiva Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 6830,
      "TXT_FINANCIER_NAME": "JAI BHAVANI CO OP CREDIT AND INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6831,
      "TXT_FINANCIER_NAME": "THE JUBLI COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6832,
      "TXT_FINANCIER_NAME": "ERNAKULAM CO OP AGRICULTURAL RURAL DEVP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6833,
      "TXT_FINANCIER_NAME": "BHAGYALAXMI MAHILA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6834,
      "TXT_FINANCIER_NAME": "DUTCH BANK"
    },
    {
      "NUM_FINANCIER_CD": 6835,
      "TXT_FINANCIER_NAME": "CHIKHALI URBAN CO OPP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6836,
      "TXT_FINANCIER_NAME": "ARVIND SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6837,
      "TXT_FINANCIER_NAME": "PARAPPANAGADI CO-OPERATIVE SREVICE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6838,
      "TXT_FINANCIER_NAME": "THE KEEZHOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6839,
      "TXT_FINANCIER_NAME": "NYAYAMITA SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 6840,
      "TXT_FINANCIER_NAME": "BIKANER KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6841,
      "TXT_FINANCIER_NAME": "SHREE JANTA SAHAKARI BANK LTD.HALOL"
    },
    {
      "NUM_FINANCIER_CD": 6842,
      "TXT_FINANCIER_NAME": "THE A.P. RAJA RAJESWARI MAHILA CO-OP.URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6843,
      "TXT_FINANCIER_NAME": "THE KALOL C-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6844,
      "TXT_FINANCIER_NAME": "The Samrao Vithal Sahakari Bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 6845,
      "TXT_FINANCIER_NAME": "THE SAMRAO VITHAL CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6846,
      "TXT_FINANCIER_NAME": "THE DHARMAJ PEOPLE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6847,
      "TXT_FINANCIER_NAME": "LUCKNOW KSHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6848,
      "TXT_FINANCIER_NAME": "THE VADALI NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6849,
      "TXT_FINANCIER_NAME": "MAGMA SRACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6850,
      "TXT_FINANCIER_NAME": "THE D CATHOLIC SYRIAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6851,
      "TXT_FINANCIER_NAME": "THE VENGAD SERVICE CO- OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6852,
      "TXT_FINANCIER_NAME": "GANDHIBAGH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6853,
      "TXT_FINANCIER_NAME": "BHUJ MERC. CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6854,
      "TXT_FINANCIER_NAME": "uutar pradesh gramin bank"
    },
    {
      "NUM_FINANCIER_CD": 6855,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6856,
      "TXT_FINANCIER_NAME": "ELAMADU CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6857,
      "TXT_FINANCIER_NAME": "BMCB LTD"
    },
    {
      "NUM_FINANCIER_CD": 6858,
      "TXT_FINANCIER_NAME": "MAGMA SHARCHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6859,
      "TXT_FINANCIER_NAME": "BELGAUM CATHOLIC CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6860,
      "TXT_FINANCIER_NAME": "JAGRUTI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6861,
      "TXT_FINANCIER_NAME": "KARANATAKA STATE INDUSTRIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6862,
      "TXT_FINANCIER_NAME": "NARDAN RAILWAY PRAIMARMY CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6863,
      "TXT_FINANCIER_NAME": "MSN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6864,
      "TXT_FINANCIER_NAME": "KISAN NAGARI SAHAKARI BANK LTD., PARBHANI"
    },
    {
      "NUM_FINANCIER_CD": 6865,
      "TXT_FINANCIER_NAME": "THE KAVERI GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6866,
      "TXT_FINANCIER_NAME": "LPSC"
    },
    {
      "NUM_FINANCIER_CD": 6867,
      "TXT_FINANCIER_NAME": "UTTARANCHAL STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6868,
      "TXT_FINANCIER_NAME": "THE NANDIPULAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6869,
      "TXT_FINANCIER_NAME": "C0-OPERATIVE RURAL BANK EVENING BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 6870,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE RURAL BANK VATAKARA"
    },
    {
      "NUM_FINANCIER_CD": 6871,
      "TXT_FINANCIER_NAME": "SATE BANK OF MODASA"
    },
    {
      "NUM_FINANCIER_CD": 6872,
      "TXT_FINANCIER_NAME": "THRISSUR URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6873,
      "TXT_FINANCIER_NAME": "HOUSING BOARD HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 6874,
      "TXT_FINANCIER_NAME": "MASCOT PROJECTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6875,
      "TXT_FINANCIER_NAME": "GREEN VELLY FINANCE LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6876,
      "TXT_FINANCIER_NAME": "ALMORA ZILA SHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6877,
      "TXT_FINANCIER_NAME": "SANMITRA URBANK CO OP BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 6878,
      "TXT_FINANCIER_NAME": "HP STATE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 6879,
      "TXT_FINANCIER_NAME": "SATKAR MOTOR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 6880,
      "TXT_FINANCIER_NAME": "MUMBAI ZILLA MADHYAWATI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6881,
      "TXT_FINANCIER_NAME": "MADHYA BHARAT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6882,
      "TXT_FINANCIER_NAME": "OJHAR NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6883,
      "TXT_FINANCIER_NAME": "VASUDHA COOPCREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 6884,
      "TXT_FINANCIER_NAME": "THE C D COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6885,
      "TXT_FINANCIER_NAME": "BASAVA SRI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6886,
      "TXT_FINANCIER_NAME": "Bajiprabhu Nagari Sah Path Sanstha Maryadit Ichalkaranji"
    },
    {
      "NUM_FINANCIER_CD": 6887,
      "TXT_FINANCIER_NAME": "DRANNASAHEB CHOUGULEARBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6888,
      "TXT_FINANCIER_NAME": "CHANDRAPRABHU GRAMIN BIGAR SHETI SAHAKARI PATSANSHA"
    },
    {
      "NUM_FINANCIER_CD": 6889,
      "TXT_FINANCIER_NAME": "HDFC LTD"
    },
    {
      "NUM_FINANCIER_CD": 6890,
      "TXT_FINANCIER_NAME": "COCACOLA INDIA INC"
    },
    {
      "NUM_FINANCIER_CD": 6891,
      "TXT_FINANCIER_NAME": "SANT GAJANAN MAHARAJ GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6892,
      "TXT_FINANCIER_NAME": "CAPHOLIC CYRIAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6893,
      "TXT_FINANCIER_NAME": "KODAGU DIST CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6894,
      "TXT_FINANCIER_NAME": "THE RAMPUR PEOPLES CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6895,
      "TXT_FINANCIER_NAME": "RK FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6896,
      "TXT_FINANCIER_NAME": "JANHEET NAGARI SAHAKARI PATSANSTHA MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 6897,
      "TXT_FINANCIER_NAME": "ZILHA VYAVASTHAPAK MAHARASHTRA RAJYA APANG VITT AND VIKAS"
    },
    {
      "NUM_FINANCIER_CD": 6898,
      "TXT_FINANCIER_NAME": "THE NEEZHOOR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 6899,
      "TXT_FINANCIER_NAME": "K T D F C LTD"
    },
    {
      "NUM_FINANCIER_CD": 6900,
      "TXT_FINANCIER_NAME": "SHREE VINAYAK SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 6901,
      "TXT_FINANCIER_NAME": "JAIHIND SOUHARD PATTIN SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 6902,
      "TXT_FINANCIER_NAME": "SRI SIDDESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6903,
      "TXT_FINANCIER_NAME": "AUTO MOTIVE AXLES LTD"
    },
    {
      "NUM_FINANCIER_CD": 6904,
      "TXT_FINANCIER_NAME": "THE JANA UTKARSH URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6905,
      "TXT_FINANCIER_NAME": "BASTIMAL AND SONS FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 6906,
      "TXT_FINANCIER_NAME": "THE PUNE POSTS TELECOM COOP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 6907,
      "TXT_FINANCIER_NAME": "patneshwar urban coop bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 6908,
      "TXT_FINANCIER_NAME": "THE PAVAGADA SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6909,
      "TXT_FINANCIER_NAME": "ASHWINI PATTINA SOUHARATA SAHAKARA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 6910,
      "TXT_FINANCIER_NAME": "NEIMNATH FINANCE BRANCH AMARVATI"
    },
    {
      "NUM_FINANCIER_CD": 6911,
      "TXT_FINANCIER_NAME": "PARTAPSHREE FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 6912,
      "TXT_FINANCIER_NAME": "JAGRATI URBAN CREDIT SOUHARDA COOP LTD"
    },
    {
      "NUM_FINANCIER_CD": 6913,
      "TXT_FINANCIER_NAME": "MAFCO INDIA"
    },
    {
      "NUM_FINANCIER_CD": 6914,
      "TXT_FINANCIER_NAME": "MANDARTHI SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6915,
      "TXT_FINANCIER_NAME": "SHREE SAI ANAND NAGARI SAH PAT SAN MARYA"
    },
    {
      "NUM_FINANCIER_CD": 6916,
      "TXT_FINANCIER_NAME": "LILUAH COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6917,
      "TXT_FINANCIER_NAME": "SARDAR MER.CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6918,
      "TXT_FINANCIER_NAME": "THE AIR CORP EMPLOYEE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6919,
      "TXT_FINANCIER_NAME": "THE AIR CORP EMP CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6920,
      "TXT_FINANCIER_NAME": "PUNE JILLA MADHYAWARTI SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 6921,
      "TXT_FINANCIER_NAME": "BHAGIRATH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6922,
      "TXT_FINANCIER_NAME": "AMBAJOGAI"
    },
    {
      "NUM_FINANCIER_CD": 6923,
      "TXT_FINANCIER_NAME": "REGISTRAR, SV.UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 6924,
      "TXT_FINANCIER_NAME": "THE PANVEL CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6925,
      "TXT_FINANCIER_NAME": "AKSHYA CO-OPERATIVE SOCIETY LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6926,
      "TXT_FINANCIER_NAME": "AKSHAYA CO-OPERATIVE SOCIETY LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6927,
      "TXT_FINANCIER_NAME": "GULSHAN MERCNTILE URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6928,
      "TXT_FINANCIER_NAME": "SREE SUBRAMANYESWARA CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6929,
      "TXT_FINANCIER_NAME": "THE THALAPPALAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6930,
      "TXT_FINANCIER_NAME": "THE ROYAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6931,
      "TXT_FINANCIER_NAME": "CITIBANK N.A."
    },
    {
      "NUM_FINANCIER_CD": 6932,
      "TXT_FINANCIER_NAME": "The Varachha Coop. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 6933,
      "TXT_FINANCIER_NAME": "PNB BANK"
    },
    {
      "NUM_FINANCIER_CD": 6934,
      "TXT_FINANCIER_NAME": "BHILWARA-AJMER KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6935,
      "TXT_FINANCIER_NAME": "BAHRAICH DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6936,
      "TXT_FINANCIER_NAME": "THE JIND CENTRAL CO-OPERATIVE BANK LTD.JIND"
    },
    {
      "NUM_FINANCIER_CD": 6937,
      "TXT_FINANCIER_NAME": "THE CATHOLIC SYRAIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6938,
      "TXT_FINANCIER_NAME": "EXCELLENT CO-OP BANK LTD. MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 6939,
      "TXT_FINANCIER_NAME": "EXCELLENT CO-OP. BANK LTD. MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 6940,
      "TXT_FINANCIER_NAME": "MAHAVEER JEWELLERS"
    },
    {
      "NUM_FINANCIER_CD": 6941,
      "TXT_FINANCIER_NAME": "RANKA FINANCIER S"
    },
    {
      "NUM_FINANCIER_CD": 6942,
      "TXT_FINANCIER_NAME": "BHARAT PETROLIUME CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 6943,
      "TXT_FINANCIER_NAME": "ABHUDAYA CO -OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6944,
      "TXT_FINANCIER_NAME": "THE EDAVANNA SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6945,
      "TXT_FINANCIER_NAME": "CHAITANYA GRAMIN BIGAR SHETI SAHAKARI PAT SANSTHA LTD,VADUJ"
    },
    {
      "NUM_FINANCIER_CD": 6946,
      "TXT_FINANCIER_NAME": "mahendra & mahendra"
    },
    {
      "NUM_FINANCIER_CD": 6947,
      "TXT_FINANCIER_NAME": "THE FARRUKHABAD DISTT CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6948,
      "TXT_FINANCIER_NAME": "GMAC FIN. SER. LTD"
    },
    {
      "NUM_FINANCIER_CD": 6949,
      "TXT_FINANCIER_NAME": "PIONEER URBAN OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6950,
      "TXT_FINANCIER_NAME": "SADHANA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6951,
      "TXT_FINANCIER_NAME": "CHOLAMANDLAM DBS FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 6952,
      "TXT_FINANCIER_NAME": "KUNDAPUR VYAVASAYA SEVA SAHAKARI BANK N,"
    },
    {
      "NUM_FINANCIER_CD": 6953,
      "TXT_FINANCIER_NAME": "SRI SIDDAPARAVATHA PATTINA SOUHARDA SAHAKARI N"
    },
    {
      "NUM_FINANCIER_CD": 6954,
      "TXT_FINANCIER_NAME": "THE MATTANUR CO-OPERATIVE RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6955,
      "TXT_FINANCIER_NAME": "THE PAPPINISSRI CO-OP:RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6956,
      "TXT_FINANCIER_NAME": "THE PAPPINISSERI CO-OP:RURAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6957,
      "TXT_FINANCIER_NAME": "VIKAS SOUHARD CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 6958,
      "TXT_FINANCIER_NAME": "THE PATAN URBAN CO OP BANK LTD,PATAN"
    },
    {
      "NUM_FINANCIER_CD": 6959,
      "TXT_FINANCIER_NAME": "THE CALICUT CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6960,
      "TXT_FINANCIER_NAME": "THE THAVANUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6961,
      "TXT_FINANCIER_NAME": "THE KOLHAPUR UR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6962,
      "TXT_FINANCIER_NAME": "SHREE SHARDHA SAHAKARI BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 6963,
      "TXT_FINANCIER_NAME": "THE VISHVESWAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6964,
      "TXT_FINANCIER_NAME": "MATHURA JILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6965,
      "TXT_FINANCIER_NAME": "NAVABHARATH CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6966,
      "TXT_FINANCIER_NAME": "P.N.B"
    },
    {
      "NUM_FINANCIER_CD": 6967,
      "TXT_FINANCIER_NAME": "THE SEVALIYA URBANCO.OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 6968,
      "TXT_FINANCIER_NAME": "JANLAXMI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6969,
      "TXT_FINANCIER_NAME": "Parasik Janata Sahakari Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 6970,
      "TXT_FINANCIER_NAME": "Parasik Janata Sahakari Bank.Ltd"
    },
    {
      "NUM_FINANCIER_CD": 6971,
      "TXT_FINANCIER_NAME": "THE A.P STATE CO-OPPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6972,
      "TXT_FINANCIER_NAME": "SRI AMARJOTHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 6973,
      "TXT_FINANCIER_NAME": "EMPOWER INDIA CAPITAL INVESTMENTS PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6974,
      "TXT_FINANCIER_NAME": "ARUL SAKTHI FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 6975,
      "TXT_FINANCIER_NAME": "THE SANKHEDA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6976,
      "TXT_FINANCIER_NAME": "CO-OP BANK OF BARODA LTD"
    },
    {
      "NUM_FINANCIER_CD": 6977,
      "TXT_FINANCIER_NAME": "TARN TARAN CENTRAL CO-OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 6978,
      "TXT_FINANCIER_NAME": "MAHARASHTRA ELECTROMELT LTD"
    },
    {
      "NUM_FINANCIER_CD": 6979,
      "TXT_FINANCIER_NAME": "MAGMA SCHARACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6980,
      "TXT_FINANCIER_NAME": "surya chandra finance limited"
    },
    {
      "NUM_FINANCIER_CD": 6981,
      "TXT_FINANCIER_NAME": "NTPC"
    },
    {
      "NUM_FINANCIER_CD": 6982,
      "TXT_FINANCIER_NAME": "AKHAND AANAND CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6983,
      "TXT_FINANCIER_NAME": "SHREE ANAND GRAMIN BIGAR SHETI SAHKARI PATSANSTHA LTD."
    },
    {
      "NUM_FINANCIER_CD": 6984,
      "TXT_FINANCIER_NAME": "SREYUS GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 6985,
      "TXT_FINANCIER_NAME": "HAMIRPUR DISTRICT COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6986,
      "TXT_FINANCIER_NAME": "ALMORA ZILLA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6987,
      "TXT_FINANCIER_NAME": "THE DHULIA DISTRICT CENTRAL CO-OP BANK LTD DHULIA"
    },
    {
      "NUM_FINANCIER_CD": 6988,
      "TXT_FINANCIER_NAME": "DEUTSCHE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6989,
      "TXT_FINANCIER_NAME": "PULIKKAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6990,
      "TXT_FINANCIER_NAME": "THE KUNDAPURA TALUK AGRICULTURAL PRODUCE CO-OP MARKETING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 6991,
      "TXT_FINANCIER_NAME": "NEELA KRISHNA BANK"
    },
    {
      "NUM_FINANCIER_CD": 6992,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 6993,
      "TXT_FINANCIER_NAME": "THE ZOROASTRAIN CO-OPERATION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6994,
      "TXT_FINANCIER_NAME": "BARODA PANCHMAHAL GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 6995,
      "TXT_FINANCIER_NAME": "MAGMA SRACHI FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 6996,
      "TXT_FINANCIER_NAME": "BADAGARA TOWN CO-OPERATIVE URBAN SOCIETY BANK"
    },
    {
      "NUM_FINANCIER_CD": 6997,
      "TXT_FINANCIER_NAME": "M/S MAGMA SHRACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 6998,
      "TXT_FINANCIER_NAME": "THE MAHEMADAVAD URBAN PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 6999,
      "TXT_FINANCIER_NAME": "J&ampK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7000,
      "TXT_FINANCIER_NAME": "AMBERNATH JAI-HIND CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7001,
      "TXT_FINANCIER_NAME": "DOMBIBILI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7002,
      "TXT_FINANCIER_NAME": "YASWANT NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7003,
      "TXT_FINANCIER_NAME": "THE KOLLAM DISTRICTCO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7004,
      "TXT_FINANCIER_NAME": "RELINCE CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 7005,
      "TXT_FINANCIER_NAME": "ANANDAPURAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7006,
      "TXT_FINANCIER_NAME": "LORD FINCAP LTD"
    },
    {
      "NUM_FINANCIER_CD": 7007,
      "TXT_FINANCIER_NAME": "SHIVKUNJ NAGARI PATH SANSHTA"
    },
    {
      "NUM_FINANCIER_CD": 7008,
      "TXT_FINANCIER_NAME": "ODE NAGARIK CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7009,
      "TXT_FINANCIER_NAME": "S NIRMALA BAI"
    },
    {
      "NUM_FINANCIER_CD": 7010,
      "TXT_FINANCIER_NAME": "BHSIRO INVESTMENTS AUTOMAOTIVE FINANCIAL"
    },
    {
      "NUM_FINANCIER_CD": 7011,
      "TXT_FINANCIER_NAME": "NIDC"
    },
    {
      "NUM_FINANCIER_CD": 7012,
      "TXT_FINANCIER_NAME": "I.S.P. / SAIL"
    },
    {
      "NUM_FINANCIER_CD": 7013,
      "TXT_FINANCIER_NAME": "BASAVESHWARA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7014,
      "TXT_FINANCIER_NAME": "DHANYA VYAPARIGALA PATTINA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 7015,
      "TXT_FINANCIER_NAME": "RAMESHWARLALRATHISONS"
    },
    {
      "NUM_FINANCIER_CD": 7016,
      "TXT_FINANCIER_NAME": "H K SHANGHAI BANKING COR LTD"
    },
    {
      "NUM_FINANCIER_CD": 7017,
      "TXT_FINANCIER_NAME": "THE RAJDEEP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7018,
      "TXT_FINANCIER_NAME": "SHALIWAHAN NAGRI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7019,
      "TXT_FINANCIER_NAME": "THE TOWN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7020,
      "TXT_FINANCIER_NAME": "CONTROLLER NRSA"
    },
    {
      "NUM_FINANCIER_CD": 7021,
      "TXT_FINANCIER_NAME": "THE APEX BANK STAFF CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7022,
      "TXT_FINANCIER_NAME": "CHALLAKERE NAGARA BALAKEDARARA HAGU PATTINA SAHAKARA SANGHA NIYAMITHA CHALLAKERE"
    },
    {
      "NUM_FINANCIER_CD": 7023,
      "TXT_FINANCIER_NAME": "RAJHUNS SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7024,
      "TXT_FINANCIER_NAME": "JOHNSON AND JOHNSON MEDICAL INDIA"
    },
    {
      "NUM_FINANCIER_CD": 7025,
      "TXT_FINANCIER_NAME": "MARATHA NAGARI SAHAKARI PATSANSTHAMARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7026,
      "TXT_FINANCIER_NAME": "SHIVA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7027,
      "TXT_FINANCIER_NAME": "DAHIWADI CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7028,
      "TXT_FINANCIER_NAME": "SHRI PARVATI SHANKAR NAGARI SAH PATH SANSTHA UTTUR"
    },
    {
      "NUM_FINANCIER_CD": 7029,
      "TXT_FINANCIER_NAME": "KAMAL NAGARI CO OPERATIVE PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7030,
      "TXT_FINANCIER_NAME": "MEGMA SHRACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7031,
      "TXT_FINANCIER_NAME": "KAMAL NAGARI CO OP PATASANSTA LTD ALIBAG RAIGAD"
    },
    {
      "NUM_FINANCIER_CD": 7032,
      "TXT_FINANCIER_NAME": "SISCO BANK"
    },
    {
      "NUM_FINANCIER_CD": 7033,
      "TXT_FINANCIER_NAME": "The Nashik District Central Co operative Bank Ltd Nashik"
    },
    {
      "NUM_FINANCIER_CD": 7034,
      "TXT_FINANCIER_NAME": "JATANRAJ AMITKUMAR"
    },
    {
      "NUM_FINANCIER_CD": 7035,
      "TXT_FINANCIER_NAME": "THE SANGLI SALARY EARNERS CO OP CREDIT SOCIETY LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 7036,
      "TXT_FINANCIER_NAME": "VASAI TALUKA DEVNDY SAHAKARI PATPETI MARYADIT SOPARA"
    },
    {
      "NUM_FINANCIER_CD": 7037,
      "TXT_FINANCIER_NAME": "HUMAN WELFERE KURIES AND LOANS PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7038,
      "TXT_FINANCIER_NAME": "HUMAN WELFARE KURIES AND PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7039,
      "TXT_FINANCIER_NAME": "HUMAN WELFARE KURIES AND LOANS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7040,
      "TXT_FINANCIER_NAME": "S DINESH KUMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 7041,
      "TXT_FINANCIER_NAME": "BASSIEIN CATHOLIC CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7042,
      "TXT_FINANCIER_NAME": "SHREERAM TRANSPORT FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7043,
      "TXT_FINANCIER_NAME": "UTKARSH NAGARI SAHAKRI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7044,
      "TXT_FINANCIER_NAME": "MARVANTHE BADKERE VYAVASAYA SEVA SAHAKARI BANK NAVUNDA"
    },
    {
      "NUM_FINANCIER_CD": 7045,
      "TXT_FINANCIER_NAME": "SURAKSHA SOUHARDA SAHAKARI N BRAHMAVARA"
    },
    {
      "NUM_FINANCIER_CD": 7046,
      "TXT_FINANCIER_NAME": "KARAKULAM SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7047,
      "TXT_FINANCIER_NAME": "BOB"
    },
    {
      "NUM_FINANCIER_CD": 7048,
      "TXT_FINANCIER_NAME": "SUNDERLAL SAWAJI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7049,
      "TXT_FINANCIER_NAME": "POST OFFICE SABINGS BANK"
    },
    {
      "NUM_FINANCIER_CD": 7050,
      "TXT_FINANCIER_NAME": "NETRAVATI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7051,
      "TXT_FINANCIER_NAME": "N.T.P.C-FARAKKA"
    },
    {
      "NUM_FINANCIER_CD": 7052,
      "TXT_FINANCIER_NAME": "JANSEVA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7053,
      "TXT_FINANCIER_NAME": "THE KALOL MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7054,
      "TXT_FINANCIER_NAME": "MALAKAPUR BANK"
    },
    {
      "NUM_FINANCIER_CD": 7055,
      "TXT_FINANCIER_NAME": "THE VARACHHA CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7056,
      "TXT_FINANCIER_NAME": "BHATPARA-NAIHATI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7057,
      "TXT_FINANCIER_NAME": "WARUD UR.CO.OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 7058,
      "TXT_FINANCIER_NAME": "THE VALAVOOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7059,
      "TXT_FINANCIER_NAME": "THE A.P. RAJA RAJESWARI MAHILA CO-OP. URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7060,
      "TXT_FINANCIER_NAME": "MAHINDRA &ampMAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 7061,
      "TXT_FINANCIER_NAME": "THE A.P.STATE CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7062,
      "TXT_FINANCIER_NAME": "RUPEE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7063,
      "TXT_FINANCIER_NAME": "M. & M. FIN. SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 7064,
      "TXT_FINANCIER_NAME": "SHREE MARUTI CO-OP. CREDIT SOCIETY LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 7065,
      "TXT_FINANCIER_NAME": "STANDARD CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7066,
      "TXT_FINANCIER_NAME": "THE MUMBAI DIST.CENTRAL CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 7067,
      "TXT_FINANCIER_NAME": "GMAC FINACIAL SERVICES INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7068,
      "TXT_FINANCIER_NAME": "INDUSIND BANL LTD."
    },
    {
      "NUM_FINANCIER_CD": 7069,
      "TXT_FINANCIER_NAME": "UTI BANK A/C APEEJAY FINANCE GROUP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7070,
      "TXT_FINANCIER_NAME": "BHEE CO-OP CREDIT SOCIETY LTD EDN"
    },
    {
      "NUM_FINANCIER_CD": 7071,
      "TXT_FINANCIER_NAME": "PALLURUTHY MANDALAM SERVICE CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7072,
      "TXT_FINANCIER_NAME": "SECRETARIAT GOVERNMENT OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 7073,
      "TXT_FINANCIER_NAME": "fsdf nbe"
    },
    {
      "NUM_FINANCIER_CD": 7074,
      "TXT_FINANCIER_NAME": "JAISALMER CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7075,
      "TXT_FINANCIER_NAME": "BONGAIGAON REFINARY & PETROCAMICALS LTD."
    },
    {
      "NUM_FINANCIER_CD": 7076,
      "TXT_FINANCIER_NAME": "BRPL"
    },
    {
      "NUM_FINANCIER_CD": 7077,
      "TXT_FINANCIER_NAME": "TALIKOTI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7078,
      "TXT_FINANCIER_NAME": "SHREE VYANKTESH CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7079,
      "TXT_FINANCIER_NAME": "KA BANK NONGKYNDONG RI KHASI JAINTIA."
    },
    {
      "NUM_FINANCIER_CD": 7080,
      "TXT_FINANCIER_NAME": "THE ARNI CO-OPERATIVE TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7081,
      "TXT_FINANCIER_NAME": "HANUMANTRAO CHAUDHARI SAHAKARI KALBHARAVNATH NAGARI SAHAKARI PATSANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 7082,
      "TXT_FINANCIER_NAME": "ZILA SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7083,
      "TXT_FINANCIER_NAME": "LION FIN SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 7084,
      "TXT_FINANCIER_NAME": "THE EXCELLENT CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7085,
      "TXT_FINANCIER_NAME": "AMANAT CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7086,
      "TXT_FINANCIER_NAME": "ARUN SAHAKAR BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7087,
      "TXT_FINANCIER_NAME": "GOKAK URBAN CO-OPERATIVE CREDIT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7088,
      "TXT_FINANCIER_NAME": "THE MALPUR NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7089,
      "TXT_FINANCIER_NAME": "ASSOCIATE INDIA FIN SER PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7090,
      "TXT_FINANCIER_NAME": "DHARTHI CHITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7091,
      "TXT_FINANCIER_NAME": "NATIONAL BANK STAFF COLLEGE"
    },
    {
      "NUM_FINANCIER_CD": 7092,
      "TXT_FINANCIER_NAME": "THE GOA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7093,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OPERATIVE BANK (MUMBAI). LTD"
    },
    {
      "NUM_FINANCIER_CD": 7094,
      "TXT_FINANCIER_NAME": "THE RAHURI PEOPLES CO. OP. BANK LIMITED,RAHURI"
    },
    {
      "NUM_FINANCIER_CD": 7095,
      "TXT_FINANCIER_NAME": "ANANDESHWARI SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7096,
      "TXT_FINANCIER_NAME": "AJIT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7097,
      "TXT_FINANCIER_NAME": "THE MANJERI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7098,
      "TXT_FINANCIER_NAME": "KANNUR DISTRIC CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7099,
      "TXT_FINANCIER_NAME": "RATNAKAR CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7100,
      "TXT_FINANCIER_NAME": "SHREE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7101,
      "TXT_FINANCIER_NAME": "CATHOLIC CYRIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7102,
      "TXT_FINANCIER_NAME": "JANLAXMI NAGARIK SAHAKARI PATHPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 7103,
      "TXT_FINANCIER_NAME": "THALUK CO OP AGRICULTURE AND RURAL DEVP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7104,
      "TXT_FINANCIER_NAME": "PANDIAN AUTO FINANACE"
    },
    {
      "NUM_FINANCIER_CD": 7105,
      "TXT_FINANCIER_NAME": "SPIC FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 7106,
      "TXT_FINANCIER_NAME": "NASHIK DIST.GIRANA CO-OP.BANK LTD.,NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 7107,
      "TXT_FINANCIER_NAME": "THE NORTH KANARA G.S.B.CO-OP. BANK.LTD"
    },
    {
      "NUM_FINANCIER_CD": 7108,
      "TXT_FINANCIER_NAME": "KARUVANNUR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7109,
      "TXT_FINANCIER_NAME": "SMRITI NAGARIK SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 7110,
      "TXT_FINANCIER_NAME": "NAINITAL DISTT-CO-OPRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7111,
      "TXT_FINANCIER_NAME": "DISTT.CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7112,
      "TXT_FINANCIER_NAME": "THE PEN CO-OP URBAN BANK PEN RAIGAD"
    },
    {
      "NUM_FINANCIER_CD": 7113,
      "TXT_FINANCIER_NAME": "THE VATTAMKULAM PANCHAYATH SERVICE CO. OPERATIVE LTD."
    },
    {
      "NUM_FINANCIER_CD": 7114,
      "TXT_FINANCIER_NAME": "THE VATTAMKULAM PANCHAYATH SERVICE CO. OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7115,
      "TXT_FINANCIER_NAME": "LALA URBAN CO-OP. BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 7116,
      "TXT_FINANCIER_NAME": "THE DARUSSALAM CO-OP.URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7117,
      "TXT_FINANCIER_NAME": "BALTIKURI CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7118,
      "TXT_FINANCIER_NAME": "BHEL EMPLO,S CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7119,
      "TXT_FINANCIER_NAME": "AGRSEN URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7120,
      "TXT_FINANCIER_NAME": "PURNAWADI NAGARI SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7121,
      "TXT_FINANCIER_NAME": "HYDERABAD CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7122,
      "TXT_FINANCIER_NAME": "VAZHAYOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7123,
      "TXT_FINANCIER_NAME": "THE KASARAGOD SERVICE CO-OPERATIVE BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 7124,
      "TXT_FINANCIER_NAME": "VASANTDADA NAGRI SAHKARI.BANK.LTD"
    },
    {
      "NUM_FINANCIER_CD": 7125,
      "TXT_FINANCIER_NAME": "FAMILYCREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7126,
      "TXT_FINANCIER_NAME": "MOORKKANIKKARA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7127,
      "TXT_FINANCIER_NAME": "FATEHPUR DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7128,
      "TXT_FINANCIER_NAME": "JUNAGADH AMRELI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7129,
      "TXT_FINANCIER_NAME": "EDARIKODE SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7130,
      "TXT_FINANCIER_NAME": "DELHI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7131,
      "TXT_FINANCIER_NAME": "THE BHATKAL AGRICULURAL & RURAL DEVELOPMENT CO-O BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7132,
      "TXT_FINANCIER_NAME": "THE SHIMOGA DIST.CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7133,
      "TXT_FINANCIER_NAME": "BAID LEASING"
    },
    {
      "NUM_FINANCIER_CD": 7134,
      "TXT_FINANCIER_NAME": "SIVA CAPITAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7135,
      "TXT_FINANCIER_NAME": "ADARSHA NAGRI SAHAKARI PAT SAISATHA"
    },
    {
      "NUM_FINANCIER_CD": 7136,
      "TXT_FINANCIER_NAME": "BHEL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7137,
      "TXT_FINANCIER_NAME": "BANK OF DAMAN"
    },
    {
      "NUM_FINANCIER_CD": 7138,
      "TXT_FINANCIER_NAME": "state bank of daman"
    },
    {
      "NUM_FINANCIER_CD": 7139,
      "TXT_FINANCIER_NAME": "VARACHHA ROAD URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7140,
      "TXT_FINANCIER_NAME": "H P State Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7141,
      "TXT_FINANCIER_NAME": "ABHISHEK AND CO"
    },
    {
      "NUM_FINANCIER_CD": 7142,
      "TXT_FINANCIER_NAME": "SERVA UP GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7143,
      "TXT_FINANCIER_NAME": "WIRELESS PLANNING AND CONSULTANCY INDIA"
    },
    {
      "NUM_FINANCIER_CD": 7144,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN COOPEARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7145,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7146,
      "TXT_FINANCIER_NAME": "RAMLAL BETALA"
    },
    {
      "NUM_FINANCIER_CD": 7147,
      "TXT_FINANCIER_NAME": "Grewal Brothers Finance Pvt Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7148,
      "TXT_FINANCIER_NAME": "The Ayali Kalan CAMS Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7149,
      "TXT_FINANCIER_NAME": "PAZHAYAKUNNUMMEL SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7150,
      "TXT_FINANCIER_NAME": "SHRI LAXMI MAHILA SAHAKARI BANK LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 7151,
      "TXT_FINANCIER_NAME": "BETALA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 7152,
      "TXT_FINANCIER_NAME": "JIVHALA SAHAKARI PATHPEDI"
    },
    {
      "NUM_FINANCIER_CD": 7153,
      "TXT_FINANCIER_NAME": "KHADAKI NAGARI PAT SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 7154,
      "TXT_FINANCIER_NAME": "SHREE NARSINHA GRAMIN BIGARSHETI SAHAKARI PATASANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7155,
      "TXT_FINANCIER_NAME": "KALYAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7156,
      "TXT_FINANCIER_NAME": "SANMATHI CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7157,
      "TXT_FINANCIER_NAME": "KHADKI NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7158,
      "TXT_FINANCIER_NAME": "THE THANE DIST MADHURI SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7159,
      "TXT_FINANCIER_NAME": "GANDHI GUNJ CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7160,
      "TXT_FINANCIER_NAME": "SHANTIKA CREDIT SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 7161,
      "TXT_FINANCIER_NAME": "DHANALAXMI GRAMIN BIGARSHETI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7162,
      "TXT_FINANCIER_NAME": "HSIIDC"
    },
    {
      "NUM_FINANCIER_CD": 7163,
      "TXT_FINANCIER_NAME": "AGIFS"
    },
    {
      "NUM_FINANCIER_CD": 7362,
      "TXT_FINANCIER_NAME": "VATTAMKULAM PANCHAYATH SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7363,
      "TXT_FINANCIER_NAME": "ANYONYA SAHYAKARI MANDALI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7364,
      "TXT_FINANCIER_NAME": "MUNDOOR SERVICE CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7365,
      "TXT_FINANCIER_NAME": "THE THIDANAD SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7366,
      "TXT_FINANCIER_NAME": "VADASERIKARA SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7367,
      "TXT_FINANCIER_NAME": "THE MADAI CO-OPERATIVE RURAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7368,
      "TXT_FINANCIER_NAME": "HITACHI HOME AND LIFE SOLUTIONS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7369,
      "TXT_FINANCIER_NAME": "KAMAL NAGRI SAHKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7370,
      "TXT_FINANCIER_NAME": "SARSWAT CO-OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 7371,
      "TXT_FINANCIER_NAME": "THE CO OP BANK OF MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 7372,
      "TXT_FINANCIER_NAME": "THE NADIAD PEOPLES CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7373,
      "TXT_FINANCIER_NAME": "SRINIVASA PADMAVATHI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7374,
      "TXT_FINANCIER_NAME": "THE KARJAN NAGRIC SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7375,
      "TXT_FINANCIER_NAME": "SUGAN KANWAR"
    },
    {
      "NUM_FINANCIER_CD": 7376,
      "TXT_FINANCIER_NAME": "BARODA GUJRAT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7377,
      "TXT_FINANCIER_NAME": "DHANALEKSHMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7378,
      "TXT_FINANCIER_NAME": "SERVICE CO-OPERATIVE BANK LTD 4011"
    },
    {
      "NUM_FINANCIER_CD": 7379,
      "TXT_FINANCIER_NAME": "ABHUDAYA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7380,
      "TXT_FINANCIER_NAME": "MURARI FINANCE COMMERCIAL"
    },
    {
      "NUM_FINANCIER_CD": 7381,
      "TXT_FINANCIER_NAME": "RAJLAXMI NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7382,
      "TXT_FINANCIER_NAME": "THE GUJARATINDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7383,
      "TXT_FINANCIER_NAME": "THE GUJARATINDUSTRIAL CO-OP LTD."
    },
    {
      "NUM_FINANCIER_CD": 7384,
      "TXT_FINANCIER_NAME": "SURAT DIST CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7385,
      "TXT_FINANCIER_NAME": "THE SALAL NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7386,
      "TXT_FINANCIER_NAME": "VIDARBHA URBAN CO-OP BANK LTD, AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 7387,
      "TXT_FINANCIER_NAME": "THE GANDHIDHAM COOPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7388,
      "TXT_FINANCIER_NAME": "FEDERAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7389,
      "TXT_FINANCIER_NAME": "URBAN COPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7390,
      "TXT_FINANCIER_NAME": "SKRIRAM TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 7391,
      "TXT_FINANCIER_NAME": "ASHOKRAO BANKAR NAGRI SAHAKARI PATH MARYADIT PIMPALGAON"
    },
    {
      "NUM_FINANCIER_CD": 7392,
      "TXT_FINANCIER_NAME": "ZILA SHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7393,
      "TXT_FINANCIER_NAME": "HE KARNATAKA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7394,
      "TXT_FINANCIER_NAME": "AGRIM FINANCE CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 7395,
      "TXT_FINANCIER_NAME": "THE GANGULI SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7396,
      "TXT_FINANCIER_NAME": "ABC"
    },
    {
      "NUM_FINANCIER_CD": 7397,
      "TXT_FINANCIER_NAME": "PRAVEEN KUMAR NAHAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 7398,
      "TXT_FINANCIER_NAME": "SINDHU CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7399,
      "TXT_FINANCIER_NAME": "SINDH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7400,
      "TXT_FINANCIER_NAME": "NIVEDITA MAHILA BIGAR SHETI SAHKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7401,
      "TXT_FINANCIER_NAME": "THE LAXMIVILAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7402,
      "TXT_FINANCIER_NAME": "THE PEOPLES CREDIT CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7403,
      "TXT_FINANCIER_NAME": "SHRI DURGA PARAMESHWARI CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7404,
      "TXT_FINANCIER_NAME": "GURUNITHYANANDA CREDIT CO OP SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 7405,
      "TXT_FINANCIER_NAME": "Soniya Nagari VW Sah Path Sanstha Ichalkaranji"
    },
    {
      "NUM_FINANCIER_CD": 7406,
      "TXT_FINANCIER_NAME": "PAVAN INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 7407,
      "TXT_FINANCIER_NAME": "BRAHMAVARA AGRICULTURAL SERVICE COOP BANK LTD BRAHMAVAR"
    },
    {
      "NUM_FINANCIER_CD": 7408,
      "TXT_FINANCIER_NAME": "BRAHMAVARA AGRICULTUREL SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7409,
      "TXT_FINANCIER_NAME": "GUJRAT STATE ELECTRICITY CORPO LTD"
    },
    {
      "NUM_FINANCIER_CD": 7410,
      "TXT_FINANCIER_NAME": "AVIOIL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7411,
      "TXT_FINANCIER_NAME": "BANK OF NAINITAL"
    },
    {
      "NUM_FINANCIER_CD": 7412,
      "TXT_FINANCIER_NAME": "SHREE TUKARAM COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7413,
      "TXT_FINANCIER_NAME": "BHARATH HEAVY ELECTRICALS EMPLOYEES CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7414,
      "TXT_FINANCIER_NAME": "LALITHA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 7415,
      "TXT_FINANCIER_NAME": "LALITA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 7416,
      "TXT_FINANCIER_NAME": "NEW AADARSH CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 7417,
      "TXT_FINANCIER_NAME": "RAJARAM BAPU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7418,
      "TXT_FINANCIER_NAME": "VISAKHA URBAN DEVELOPMENT AUTHORITY"
    },
    {
      "NUM_FINANCIER_CD": 7419,
      "TXT_FINANCIER_NAME": "SADHANA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7420,
      "TXT_FINANCIER_NAME": "MAHINDRA ANA MAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 7421,
      "TXT_FINANCIER_NAME": "SHIVA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7422,
      "TXT_FINANCIER_NAME": "CHIKMAGLUR ZILLA MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7423,
      "TXT_FINANCIER_NAME": "The Lakhvad Nag Sah Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7424,
      "TXT_FINANCIER_NAME": "SHRI SISODARA VIBHAG COOP SOC"
    },
    {
      "NUM_FINANCIER_CD": 7425,
      "TXT_FINANCIER_NAME": "CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7426,
      "TXT_FINANCIER_NAME": "THE MAYYIL SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7427,
      "TXT_FINANCIER_NAME": "THE BHADRAN PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7428,
      "TXT_FINANCIER_NAME": "BHAVANI ANNAI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7429,
      "TXT_FINANCIER_NAME": "HINGLAJ CRE CO OP GRAHAK DHIRAN MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 7430,
      "TXT_FINANCIER_NAME": "KRSHAK BHARATI CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 7431,
      "TXT_FINANCIER_NAME": "agastya credit co opt society ltd"
    },
    {
      "NUM_FINANCIER_CD": 7432,
      "TXT_FINANCIER_NAME": "UNDER LEASE AGREEMENT WITH PEBCO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7433,
      "TXT_FINANCIER_NAME": "THE ARACKAL SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7434,
      "TXT_FINANCIER_NAME": "CHINAWAL VIVIDH KARYAKARI SAHAKARI SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7435,
      "TXT_FINANCIER_NAME": "THE BHAGYA LAXMI MAHILA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7436,
      "TXT_FINANCIER_NAME": "MADANTHYAR SEVA SAHAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7437,
      "TXT_FINANCIER_NAME": "SHREE SIDDESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7438,
      "TXT_FINANCIER_NAME": "HALANDURU VYAVASAYA SEVA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7439,
      "TXT_FINANCIER_NAME": "SHREE VEMANA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 7440,
      "TXT_FINANCIER_NAME": "MAHALSA URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7441,
      "TXT_FINANCIER_NAME": "UMA CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7442,
      "TXT_FINANCIER_NAME": "ASHOK TRADING CO"
    },
    {
      "NUM_FINANCIER_CD": 7443,
      "TXT_FINANCIER_NAME": "THDC LTD TEHRI"
    },
    {
      "NUM_FINANCIER_CD": 7444,
      "TXT_FINANCIER_NAME": "MAHUVA VIBHAG PEOPLES COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7445,
      "TXT_FINANCIER_NAME": "RASAMAND DISTT SAHKARI BHUMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 7446,
      "TXT_FINANCIER_NAME": "BAGSARA NAGRIK SAHAKARI SHARAFI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 7447,
      "TXT_FINANCIER_NAME": "SAMARTH NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7448,
      "TXT_FINANCIER_NAME": "MANDVI KINARA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7449,
      "TXT_FINANCIER_NAME": "UTI BANK LTD A/C APEEJAY FINANCE GROUP LTD"
    },
    {
      "NUM_FINANCIER_CD": 7450,
      "TXT_FINANCIER_NAME": "PUNA MERCHANT CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7451,
      "TXT_FINANCIER_NAME": "THE MAHUDHA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7452,
      "TXT_FINANCIER_NAME": "THE BAPUNAGAR MAHILA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7453,
      "TXT_FINANCIER_NAME": "GNFC LTD."
    },
    {
      "NUM_FINANCIER_CD": 7454,
      "TXT_FINANCIER_NAME": "THODUPUZHA CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7455,
      "TXT_FINANCIER_NAME": "IOB"
    },
    {
      "NUM_FINANCIER_CD": 7456,
      "TXT_FINANCIER_NAME": "GUJRAT MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7457,
      "TXT_FINANCIER_NAME": "SHRI LAXMI MAHILA SAHAKARI BANK LIMITED,SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 7458,
      "TXT_FINANCIER_NAME": "MAGNUM"
    },
    {
      "NUM_FINANCIER_CD": 7459,
      "TXT_FINANCIER_NAME": "THE PUNNOL SERVICE CO OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7460,
      "TXT_FINANCIER_NAME": "PUNE MADHYAVARTI SAH BABK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7461,
      "TXT_FINANCIER_NAME": "THE VALAVANNUR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7462,
      "TXT_FINANCIER_NAME": "THE MEMON CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7863,
      "TXT_FINANCIER_NAME": "VASANTDADA NAGRI SAHAKARI BANK LTD.OSMANABAD"
    },
    {
      "NUM_FINANCIER_CD": 7864,
      "TXT_FINANCIER_NAME": "SHRAMSAFALYA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7865,
      "TXT_FINANCIER_NAME": "kannur block agriculture improvement co ooperative society ltd"
    },
    {
      "NUM_FINANCIER_CD": 7866,
      "TXT_FINANCIER_NAME": "THE CITIZENS WELFARE SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7867,
      "TXT_FINANCIER_NAME": "KANAYANNOOR SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7868,
      "TXT_FINANCIER_NAME": "SHREE AVDHUT SWAMI NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7869,
      "TXT_FINANCIER_NAME": "VIGHNAHARTA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7870,
      "TXT_FINANCIER_NAME": "THE ASODAR PRADESH COOP CREDIT CONSUMER SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 7871,
      "TXT_FINANCIER_NAME": "KUNTALA PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7872,
      "TXT_FINANCIER_NAME": "SIDDHIVINAYAK NAGARI SAH PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7873,
      "TXT_FINANCIER_NAME": "LAXMI LEASING AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7874,
      "TXT_FINANCIER_NAME": "SIDDHIVINAYAK NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7875,
      "TXT_FINANCIER_NAME": "SHANKARANARAYANA SAHAKARI VYAVASAYIKA BANK N"
    },
    {
      "NUM_FINANCIER_CD": 7876,
      "TXT_FINANCIER_NAME": "BHICHAND HIRACHAND RAISONI PATH SANSTHA JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 7877,
      "TXT_FINANCIER_NAME": "KURMANCHAL SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7878,
      "TXT_FINANCIER_NAME": "RUBCO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7879,
      "TXT_FINANCIER_NAME": "SHANKARANARAYANA SAHAKARI VYAVASAYIKA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7880,
      "TXT_FINANCIER_NAME": "HARYANA VIDYUT PRASARAN NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 7881,
      "TXT_FINANCIER_NAME": "NEW AUTO FINANCIAL"
    },
    {
      "NUM_FINANCIER_CD": 7882,
      "TXT_FINANCIER_NAME": "MAGMA FIN CORPN"
    },
    {
      "NUM_FINANCIER_CD": 7883,
      "TXT_FINANCIER_NAME": "SHEGAON URBAN CO OP CREDIT SOCITY SHEGAON"
    },
    {
      "NUM_FINANCIER_CD": 7884,
      "TXT_FINANCIER_NAME": "SREE CHITRA TIRUNAL INSTITUTE FOR MEDICAL SCIENCE AND TECHNOLOGY"
    },
    {
      "NUM_FINANCIER_CD": 7885,
      "TXT_FINANCIER_NAME": "DHANALAXMI BIGARSHETI GRAMIN SAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7886,
      "TXT_FINANCIER_NAME": "KHALINI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7887,
      "TXT_FINANCIER_NAME": "ABDU RAHIMAN NAGAR CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7888,
      "TXT_FINANCIER_NAME": "TEHRI GARHWAL SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7889,
      "TXT_FINANCIER_NAME": "THE KASARAGOD CO OP TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7890,
      "TXT_FINANCIER_NAME": "NORTH MAHARASHTRA UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 7891,
      "TXT_FINANCIER_NAME": "RAJARSHEE SHAHU CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7892,
      "TXT_FINANCIER_NAME": "SUVARNA CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7893,
      "TXT_FINANCIER_NAME": "SANTOSH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7894,
      "TXT_FINANCIER_NAME": "AMEER"
    },
    {
      "NUM_FINANCIER_CD": 7895,
      "TXT_FINANCIER_NAME": "GOA POSTAL EMPLOYEES COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7896,
      "TXT_FINANCIER_NAME": "SHRI SAHASRARJUN NAGARI SAHAKARI PATHPHEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7897,
      "TXT_FINANCIER_NAME": "THE SARASPUR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7898,
      "TXT_FINANCIER_NAME": "SHREE SAHASRAARJUN NAGARI SAH PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7899,
      "TXT_FINANCIER_NAME": "SHRI SAHASTRARJUN NAGARI SAHAKARI PATHPHEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7900,
      "TXT_FINANCIER_NAME": "SHRI SAHASRARJUNNAGARI SAH PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7901,
      "TXT_FINANCIER_NAME": "SHRI SAHASRARJUN NAGARI SAH PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7902,
      "TXT_FINANCIER_NAME": "KERUR VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7903,
      "TXT_FINANCIER_NAME": "INDIAN POTASH LTD"
    },
    {
      "NUM_FINANCIER_CD": 7904,
      "TXT_FINANCIER_NAME": "RISHIK INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 7905,
      "TXT_FINANCIER_NAME": "JANATA SEVA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7906,
      "TXT_FINANCIER_NAME": "AAY DEE FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 7907,
      "TXT_FINANCIER_NAME": "AERONAUTICAL DEVELOPMENT AGENCY"
    },
    {
      "NUM_FINANCIER_CD": 7908,
      "TXT_FINANCIER_NAME": "SADHU VASWANI CREDIT COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7909,
      "TXT_FINANCIER_NAME": "KHEDA LEASING FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7910,
      "TXT_FINANCIER_NAME": "EDUCATIONAL CONSULTANT INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7911,
      "TXT_FINANCIER_NAME": "THE SAPHAMBA PEOPLPS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7912,
      "TXT_FINANCIER_NAME": "THE PUDUKAD COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7913,
      "TXT_FINANCIER_NAME": "INDIAN RENEWADLE ENERGY DEVELOPMENT AGENCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7914,
      "TXT_FINANCIER_NAME": "MALLABHUM GRAMIN BIKASH BANK"
    },
    {
      "NUM_FINANCIER_CD": 7915,
      "TXT_FINANCIER_NAME": "PURI BROTHERS FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 7916,
      "TXT_FINANCIER_NAME": "SHREE ARADHANA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7917,
      "TXT_FINANCIER_NAME": "LOKNETE DATTAJI PATIL SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7918,
      "TXT_FINANCIER_NAME": "AHILYADEVI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7919,
      "TXT_FINANCIER_NAME": "THE TALOD ARBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7920,
      "TXT_FINANCIER_NAME": "KANGRA CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7921,
      "TXT_FINANCIER_NAME": "KAPOLE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7922,
      "TXT_FINANCIER_NAME": "Karimnagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 7923,
      "TXT_FINANCIER_NAME": "KARMVIR BHAURAO PATIL ZILLA SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7924,
      "TXT_FINANCIER_NAME": "KARUVANTHIRUTHY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7925,
      "TXT_FINANCIER_NAME": "KARUVARAKUNDU CO-OPERATIVE SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7926,
      "TXT_FINANCIER_NAME": "KELAKAM SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7927,
      "TXT_FINANCIER_NAME": "Kheralu Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 7928,
      "TXT_FINANCIER_NAME": "KODIYATHUR CO-OPERATIVE URBAN SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7929,
      "TXT_FINANCIER_NAME": "KODIYERY CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7930,
      "TXT_FINANCIER_NAME": "KOKAN MARCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7931,
      "TXT_FINANCIER_NAME": "KOLAVALURE SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7932,
      "TXT_FINANCIER_NAME": "KONGORPILLY FARMERS CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7933,
      "TXT_FINANCIER_NAME": "KURLA NAGARIK SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7934,
      "TXT_FINANCIER_NAME": "Kurukshetra District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 7935,
      "TXT_FINANCIER_NAME": "KUTCH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7936,
      "TXT_FINANCIER_NAME": "KUZHIPILLY SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7937,
      "TXT_FINANCIER_NAME": "LAXMI NAGARI SAH. PATHSANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 7938,
      "TXT_FINANCIER_NAME": "LIC EMPLOYEES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7939,
      "TXT_FINANCIER_NAME": "LILUAH CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7940,
      "TXT_FINANCIER_NAME": "Lodhra Nagrik Sahkari Bank"
    },
    {
      "NUM_FINANCIER_CD": 7941,
      "TXT_FINANCIER_NAME": "LOKJIVAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7942,
      "TXT_FINANCIER_NAME": "SHREE MALEKARNI CO OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7943,
      "TXT_FINANCIER_NAME": "PAN PACIFIC FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7944,
      "TXT_FINANCIER_NAME": "GIRNARE VIVIDH KARYAKARI SAHKARI SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7945,
      "TXT_FINANCIER_NAME": "THE SHAMARAO CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7946,
      "TXT_FINANCIER_NAME": "MURTHEDARARA SAVA SAHAKARI SANGHA UPPUNDA"
    },
    {
      "NUM_FINANCIER_CD": 7947,
      "TXT_FINANCIER_NAME": "KAMDHENU SYNDICATE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 7948,
      "TXT_FINANCIER_NAME": "SAHJANAND CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7949,
      "TXT_FINANCIER_NAME": "BHAGYADOYA GRAMIN BIGAR SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7950,
      "TXT_FINANCIER_NAME": "RAJAT LEASING FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7951,
      "TXT_FINANCIER_NAME": "SRI CHINNAMALAI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7952,
      "TXT_FINANCIER_NAME": "KALYANI MAHILA NAGARI SAHAKARI PAT SANSTHA MARYADIT NASIK"
    },
    {
      "NUM_FINANCIER_CD": 7953,
      "TXT_FINANCIER_NAME": "MOGHULFINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7954,
      "TXT_FINANCIER_NAME": "THE DARMADAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7955,
      "TXT_FINANCIER_NAME": "LAXMIVILAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 7956,
      "TXT_FINANCIER_NAME": "SRI AMMAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7957,
      "TXT_FINANCIER_NAME": "THE BHAVASARA KSHATRIYA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7463,
      "TXT_FINANCIER_NAME": "ELANTHOOR SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7464,
      "TXT_FINANCIER_NAME": "THE HASTI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7465,
      "TXT_FINANCIER_NAME": "SHAMRAO BANK"
    },
    {
      "NUM_FINANCIER_CD": 7466,
      "TXT_FINANCIER_NAME": "SRI STAYA SAI NAGRIK SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 7467,
      "TXT_FINANCIER_NAME": "REDDY SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7468,
      "TXT_FINANCIER_NAME": "REDDY SAHAKRI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7469,
      "TXT_FINANCIER_NAME": "NIDHI CO. OP. BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7470,
      "TXT_FINANCIER_NAME": "SUCO BANK"
    },
    {
      "NUM_FINANCIER_CD": 7471,
      "TXT_FINANCIER_NAME": "J N S BANK MGDT"
    },
    {
      "NUM_FINANCIER_CD": 7472,
      "TXT_FINANCIER_NAME": "J N S BANK MGDT"
    },
    {
      "NUM_FINANCIER_CD": 7473,
      "TXT_FINANCIER_NAME": "VADASERIKARA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7474,
      "TXT_FINANCIER_NAME": "ASHOK LELYAND FINANCE A DIVISION OF INDUSIND BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7475,
      "TXT_FINANCIER_NAME": "KARASSERY SERVICE CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7476,
      "TXT_FINANCIER_NAME": "BANK OF TOKYO AND MITSUBISH LTD"
    },
    {
      "NUM_FINANCIER_CD": 7477,
      "TXT_FINANCIER_NAME": "THE SIRSI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7478,
      "TXT_FINANCIER_NAME": "THE MEGHRAJ NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7479,
      "TXT_FINANCIER_NAME": "THE RADDI CO-OPERATIVE CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7480,
      "TXT_FINANCIER_NAME": "PARSIK JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7481,
      "TXT_FINANCIER_NAME": "KARASSERY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7482,
      "TXT_FINANCIER_NAME": "THE NASHIK MAHILA VIKASO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7483,
      "TXT_FINANCIER_NAME": "JANGIPUR URBAN CO-OPERATIVE CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 7484,
      "TXT_FINANCIER_NAME": "THE IDUKKI-KANJIKUZHI SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7485,
      "TXT_FINANCIER_NAME": "IPSA CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7486,
      "TXT_FINANCIER_NAME": "BALDAWA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 7487,
      "TXT_FINANCIER_NAME": "THE SEVA CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7488,
      "TXT_FINANCIER_NAME": "CITIZEN COPRATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7489,
      "TXT_FINANCIER_NAME": "VBNVBN"
    },
    {
      "NUM_FINANCIER_CD": 7490,
      "TXT_FINANCIER_NAME": "THE COSTAL URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7491,
      "TXT_FINANCIER_NAME": "THE SHIMOGA DIST CO-OP CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7492,
      "TXT_FINANCIER_NAME": "FATEHPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7493,
      "TXT_FINANCIER_NAME": "DR.BABASAHEB AMBEDKAR SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7494,
      "TXT_FINANCIER_NAME": "POSTAL SAVING BANK"
    },
    {
      "NUM_FINANCIER_CD": 7495,
      "TXT_FINANCIER_NAME": "BAREILLY KSHETRIYA GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7496,
      "TXT_FINANCIER_NAME": "TDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 7497,
      "TXT_FINANCIER_NAME": "BHEL EMPLOYE CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7498,
      "TXT_FINANCIER_NAME": "THE VADAKARA SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7499,
      "TXT_FINANCIER_NAME": "canar bank"
    },
    {
      "NUM_FINANCIER_CD": 7500,
      "TXT_FINANCIER_NAME": "NJARACKAL SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7501,
      "TXT_FINANCIER_NAME": "ETAWAH DISTT. CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7502,
      "TXT_FINANCIER_NAME": "THE MALLESHWARAM CO OPARATIVE BANK LIT"
    },
    {
      "NUM_FINANCIER_CD": 7503,
      "TXT_FINANCIER_NAME": "INDIAN OVERSES BANK,"
    },
    {
      "NUM_FINANCIER_CD": 7504,
      "TXT_FINANCIER_NAME": "THE KUTTIKOL SERVICE CO-OP.BANK LTD;NO.FF.91"
    },
    {
      "NUM_FINANCIER_CD": 7505,
      "TXT_FINANCIER_NAME": "SHRI BALAJI NAGARI SAHAKARI PATSANSTHA LTD JAYSINGPUR"
    },
    {
      "NUM_FINANCIER_CD": 7506,
      "TXT_FINANCIER_NAME": "KOOTATTUKULAM SERVANTS CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7507,
      "TXT_FINANCIER_NAME": "BHAVANI RISHI CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7508,
      "TXT_FINANCIER_NAME": "SHRI DADASAHEB GAJMAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7509,
      "TXT_FINANCIER_NAME": "THE PAPPINISSERI SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7510,
      "TXT_FINANCIER_NAME": "MAHENDRA&ampMAHENDRA"
    },
    {
      "NUM_FINANCIER_CD": 7511,
      "TXT_FINANCIER_NAME": "HIMATNAGAR NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7512,
      "TXT_FINANCIER_NAME": "PUTHENCHIRA SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7513,
      "TXT_FINANCIER_NAME": "BIJNOR ZILA SAHKARI BANK LTD., BIJNOR"
    },
    {
      "NUM_FINANCIER_CD": 7514,
      "TXT_FINANCIER_NAME": "KHATRI GRAMIEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7515,
      "TXT_FINANCIER_NAME": "NANITAL ALMORA KHETRI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7516,
      "TXT_FINANCIER_NAME": "THE CHHAPI NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7517,
      "TXT_FINANCIER_NAME": "ALMORA DISTT. SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7518,
      "TXT_FINANCIER_NAME": "SUPRABHATHA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7519,
      "TXT_FINANCIER_NAME": "SREE SADGURU GRAMIN BIGARSHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7520,
      "TXT_FINANCIER_NAME": "SKI INSURANCE MARKETTING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7521,
      "TXT_FINANCIER_NAME": "SREE BASAVESWARA CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7522,
      "TXT_FINANCIER_NAME": "GOODLUCKFINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 7523,
      "TXT_FINANCIER_NAME": "SRI R I FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7524,
      "TXT_FINANCIER_NAME": "Osmanabad Janta Co-Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7525,
      "TXT_FINANCIER_NAME": "VIMAL FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 7526,
      "TXT_FINANCIER_NAME": "THE RAMPUR PEOPLE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 7527,
      "TXT_FINANCIER_NAME": "THE RAMPUR PEOPLE COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 7528,
      "TXT_FINANCIER_NAME": "danalakshmi bank l t d"
    },
    {
      "NUM_FINANCIER_CD": 7529,
      "TXT_FINANCIER_NAME": "PEOPLES COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7530,
      "TXT_FINANCIER_NAME": "THE ASSAM CO OPERATIVE APEX BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7531,
      "TXT_FINANCIER_NAME": "MAHILA CO OPNAGARIK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7532,
      "TXT_FINANCIER_NAME": "ERIYAD SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7533,
      "TXT_FINANCIER_NAME": "Chickmagalur Zilla Mahila Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7534,
      "TXT_FINANCIER_NAME": "THE KRANTHI CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7535,
      "TXT_FINANCIER_NAME": "PRATHAMIK SHISHAK SAHAKRI BANK LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 7536,
      "TXT_FINANCIER_NAME": "Herga Sahakari Vyavasaya bank"
    },
    {
      "NUM_FINANCIER_CD": 7537,
      "TXT_FINANCIER_NAME": "CITYCORP MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7538,
      "TXT_FINANCIER_NAME": "SHIVPRATAP NAG SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7539,
      "TXT_FINANCIER_NAME": "SARASWATI INDUSTRIAL SYNDICATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7540,
      "TXT_FINANCIER_NAME": "MMTC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7541,
      "TXT_FINANCIER_NAME": "the jammu central coopbank ltd"
    },
    {
      "NUM_FINANCIER_CD": 7542,
      "TXT_FINANCIER_NAME": "THE SARASWATI INDUSTRIAL SYNDICATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7543,
      "TXT_FINANCIER_NAME": "THE FAZILKA CENTAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7544,
      "TXT_FINANCIER_NAME": "The Fazilka Central Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7545,
      "TXT_FINANCIER_NAME": "SUN INDIA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7546,
      "TXT_FINANCIER_NAME": "LITETRONICS VIJAY INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7547,
      "TXT_FINANCIER_NAME": "LATE ASHOKRAO BANKAR NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7548,
      "TXT_FINANCIER_NAME": "MANSING CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7549,
      "TXT_FINANCIER_NAME": "TRISTAR MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 7550,
      "TXT_FINANCIER_NAME": "FEDERAL BANK EDAMON"
    },
    {
      "NUM_FINANCIER_CD": 7551,
      "TXT_FINANCIER_NAME": "Havyaka Credit Souharda Sahakari Niyamita"
    },
    {
      "NUM_FINANCIER_CD": 7552,
      "TXT_FINANCIER_NAME": "The Tiara Agriculture"
    },
    {
      "NUM_FINANCIER_CD": 7553,
      "TXT_FINANCIER_NAME": "BHAIRAV NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7554,
      "TXT_FINANCIER_NAME": "PRAGATI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7555,
      "TXT_FINANCIER_NAME": "NGIFINDIAN NAVY"
    },
    {
      "NUM_FINANCIER_CD": 7556,
      "TXT_FINANCIER_NAME": "COMTRLLER UAS GKVK"
    },
    {
      "NUM_FINANCIER_CD": 7557,
      "TXT_FINANCIER_NAME": "SHREERAMA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7558,
      "TXT_FINANCIER_NAME": "SHREE CHHANI LEUVA PATIDAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7559,
      "TXT_FINANCIER_NAME": "AMRIT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7560,
      "TXT_FINANCIER_NAME": "THE CO OP URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7561,
      "TXT_FINANCIER_NAME": "MEENACHIL TALUK CO OPERATIVE EMPLOYEES CO OPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7562,
      "TXT_FINANCIER_NAME": "ADARSHA NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7563,
      "TXT_FINANCIER_NAME": "SHREE SANTH NAGEBABA GRAMIN BIGAR SHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7564,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE MAGASVARGA VIKAS MAHAMANDAL MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7565,
      "TXT_FINANCIER_NAME": "MUGBERIA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7566,
      "TXT_FINANCIER_NAME": "VAKIL NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7567,
      "TXT_FINANCIER_NAME": "INDIRA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7568,
      "TXT_FINANCIER_NAME": "JAIPUR NAGORE AANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7569,
      "TXT_FINANCIER_NAME": "THE FAZILKA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7570,
      "TXT_FINANCIER_NAME": "Progressive Motors"
    },
    {
      "NUM_FINANCIER_CD": 7571,
      "TXT_FINANCIER_NAME": "GHIYA MANODIYA FINANCE AND INVESTMENT CONS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7572,
      "TXT_FINANCIER_NAME": "god reey phillips india ltd"
    },
    {
      "NUM_FINANCIER_CD": 7573,
      "TXT_FINANCIER_NAME": "A G AUDIT"
    },
    {
      "NUM_FINANCIER_CD": 7574,
      "TXT_FINANCIER_NAME": "FOOD CORPORATION OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 7575,
      "TXT_FINANCIER_NAME": "DHARESHWAR NAGARI SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7576,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN COOPEARATIVE BANK LTD HONNAVAR"
    },
    {
      "NUM_FINANCIER_CD": 7577,
      "TXT_FINANCIER_NAME": "N T P C LTD"
    },
    {
      "NUM_FINANCIER_CD": 7578,
      "TXT_FINANCIER_NAME": "SHRI SHANKAR NAGARI SAH PATH SANSTHA UTTUR"
    },
    {
      "NUM_FINANCIER_CD": 7579,
      "TXT_FINANCIER_NAME": "HITANSHI FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 7580,
      "TXT_FINANCIER_NAME": "MANANJE VYAVASAYA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7581,
      "TXT_FINANCIER_NAME": "JANKALYAN NAGARI SAH PAT SANSTHA LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 7582,
      "TXT_FINANCIER_NAME": "HONAVAAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7583,
      "TXT_FINANCIER_NAME": "KALIKA URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7584,
      "TXT_FINANCIER_NAME": "THE CHERPU COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7585,
      "TXT_FINANCIER_NAME": "SADHNA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7586,
      "TXT_FINANCIER_NAME": "DENSO HARYANA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7587,
      "TXT_FINANCIER_NAME": "ASP SAIL"
    },
    {
      "NUM_FINANCIER_CD": 7588,
      "TXT_FINANCIER_NAME": "MADHYA GUJARAT VIJ CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 7589,
      "TXT_FINANCIER_NAME": "SHRI MAHAVIRJI FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7590,
      "TXT_FINANCIER_NAME": "HONNALI URBAN CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7591,
      "TXT_FINANCIER_NAME": "MODANTHYAR SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7592,
      "TXT_FINANCIER_NAME": "SBOP GHARAUNDA DISTT KARNAL"
    },
    {
      "NUM_FINANCIER_CD": 7593,
      "TXT_FINANCIER_NAME": "Right Leasing Hire Purchase Co"
    },
    {
      "NUM_FINANCIER_CD": 7594,
      "TXT_FINANCIER_NAME": "BANTWAL CATHOLIC CREDIT CO OP SOCIETY LTD JODUMARGA"
    },
    {
      "NUM_FINANCIER_CD": 7595,
      "TXT_FINANCIER_NAME": "LT JANOJIRAO MALOJIRAO BHOITE GRAMIN BIGAR SHETI PTS"
    },
    {
      "NUM_FINANCIER_CD": 7596,
      "TXT_FINANCIER_NAME": "CD BLOCK CHACHYOT CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7597,
      "TXT_FINANCIER_NAME": "The Kasaragod District Co Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7598,
      "TXT_FINANCIER_NAME": "THE ENERGY AND RESOURCES INSTITUTE"
    },
    {
      "NUM_FINANCIER_CD": 7599,
      "TXT_FINANCIER_NAME": "THE KAZARCODE DISTRICT COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7600,
      "TXT_FINANCIER_NAME": "NISHA AUTO FINANCE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 7601,
      "TXT_FINANCIER_NAME": "JANKLYAN SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7602,
      "TXT_FINANCIER_NAME": "GRAMYALAXMI MURCHANT CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7603,
      "TXT_FINANCIER_NAME": "M KAMLESH CHAND MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 7604,
      "TXT_FINANCIER_NAME": "SHREE MAHARUDRA HANUMAN NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7605,
      "TXT_FINANCIER_NAME": "MATTANCHERY SARVAJANIK CO-OPERATAIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7606,
      "TXT_FINANCIER_NAME": "KALANGIYAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7607,
      "TXT_FINANCIER_NAME": "THE KANNUR DIST CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7608,
      "TXT_FINANCIER_NAME": "SUNDERLAL SAWJI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7609,
      "TXT_FINANCIER_NAME": "RAI BARELI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7610,
      "TXT_FINANCIER_NAME": "THE HONNAVAR URBAN CO OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 7611,
      "TXT_FINANCIER_NAME": "THE MOGAVEERA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7612,
      "TXT_FINANCIER_NAME": "SHRI BILUR GURUBASAVA PATIN SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 7613,
      "TXT_FINANCIER_NAME": "PULLUKULANGARA SERVICE CO-OPERATIVE BANK LTD.NO.2992"
    },
    {
      "NUM_FINANCIER_CD": 7614,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OP. BANK (MUMBAI)LTD."
    },
    {
      "NUM_FINANCIER_CD": 7615,
      "TXT_FINANCIER_NAME": "REDDY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7616,
      "TXT_FINANCIER_NAME": "oushadi finance"
    },
    {
      "NUM_FINANCIER_CD": 7617,
      "TXT_FINANCIER_NAME": "THE PATDI NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7618,
      "TXT_FINANCIER_NAME": "rtr"
    },
    {
      "NUM_FINANCIER_CD": 7619,
      "TXT_FINANCIER_NAME": "ABE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7620,
      "TXT_FINANCIER_NAME": "KANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7621,
      "TXT_FINANCIER_NAME": "SHREE TUKARAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7622,
      "TXT_FINANCIER_NAME": "THE PATTANAKKAD SERVICE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7623,
      "TXT_FINANCIER_NAME": "SRISARASWATI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7624,
      "TXT_FINANCIER_NAME": "MAHAJAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7625,
      "TXT_FINANCIER_NAME": "THE THOKAKARS CO-OPERATIVE SALE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7626,
      "TXT_FINANCIER_NAME": "THE AB E CO-OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7627,
      "TXT_FINANCIER_NAME": "PULIYOOR SERVICE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7628,
      "TXT_FINANCIER_NAME": "NARAKAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7629,
      "TXT_FINANCIER_NAME": "DHANALAXMI VILAS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7630,
      "TXT_FINANCIER_NAME": "THE AP CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7631,
      "TXT_FINANCIER_NAME": "KERALA STATE DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7632,
      "TXT_FINANCIER_NAME": "L.I.C EMPLOYEES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7633,
      "TXT_FINANCIER_NAME": "JANTA SHAKHARI BANK LTD PUNE."
    },
    {
      "NUM_FINANCIER_CD": 7634,
      "TXT_FINANCIER_NAME": "LOKMANGAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7635,
      "TXT_FINANCIER_NAME": "THE SARSWAT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7636,
      "TXT_FINANCIER_NAME": "MELOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7637,
      "TXT_FINANCIER_NAME": "BHAGNI NIVEDITA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7638,
      "TXT_FINANCIER_NAME": "KARUMBALAI SRI BHARATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7639,
      "TXT_FINANCIER_NAME": "SHRI VIJAYALAKSHMI SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 7640,
      "TXT_FINANCIER_NAME": "BANDA DISTRICT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7641,
      "TXT_FINANCIER_NAME": "THE AYARKUNNAM SERCICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7642,
      "TXT_FINANCIER_NAME": "ETAWAH KSHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7643,
      "TXT_FINANCIER_NAME": "SHRIMAN SUGANMALAJI SURANA VYAPARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7644,
      "TXT_FINANCIER_NAME": "GOVT SERVANTS CO-OP:BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7645,
      "TXT_FINANCIER_NAME": "FIROZABAD ZILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7646,
      "TXT_FINANCIER_NAME": "THE RAJPIPLA NAGRIC SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7647,
      "TXT_FINANCIER_NAME": "THE KEEZHILLAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7648,
      "TXT_FINANCIER_NAME": "BIRDEV SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7649,
      "TXT_FINANCIER_NAME": "LONAWALA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7650,
      "TXT_FINANCIER_NAME": "Eruvessy Service Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 7651,
      "TXT_FINANCIER_NAME": "BHARATH HEAVY ELECTRICALS EMPLOYEES CO-OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7652,
      "TXT_FINANCIER_NAME": "IRINGAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7653,
      "TXT_FINANCIER_NAME": "RUPEE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7654,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SHA. BANK LTD.PETH"
    },
    {
      "NUM_FINANCIER_CD": 7655,
      "TXT_FINANCIER_NAME": "HSBC BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7656,
      "TXT_FINANCIER_NAME": "MAHILA CO.OP.NAGARIK BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7657,
      "TXT_FINANCIER_NAME": "The Thidanad Service Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7658,
      "TXT_FINANCIER_NAME": "NARANAMMOOZHY SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7659,
      "TXT_FINANCIER_NAME": "THE JHALOD URBEN CO. OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7660,
      "TXT_FINANCIER_NAME": "marchant"
    },
    {
      "NUM_FINANCIER_CD": 7661,
      "TXT_FINANCIER_NAME": "SUMATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7662,
      "TXT_FINANCIER_NAME": "SUMATHY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7663,
      "TXT_FINANCIER_NAME": "ALMORA DISTT. CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7664,
      "TXT_FINANCIER_NAME": "Induslnd Bank"
    },
    {
      "NUM_FINANCIER_CD": 7665,
      "TXT_FINANCIER_NAME": "CHOLAMANDLAM DBS FIN CO. LTD"
    },
    {
      "NUM_FINANCIER_CD": 7666,
      "TXT_FINANCIER_NAME": "SHRI DILIP KUMAR VERMA"
    },
    {
      "NUM_FINANCIER_CD": 7667,
      "TXT_FINANCIER_NAME": "JINDAL SAW LTD"
    },
    {
      "NUM_FINANCIER_CD": 7668,
      "TXT_FINANCIER_NAME": "MENTOR INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7669,
      "TXT_FINANCIER_NAME": "MADIKAI SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7670,
      "TXT_FINANCIER_NAME": "IBL VEHICLE FINANCE DIVISION CV-II"
    },
    {
      "NUM_FINANCIER_CD": 7671,
      "TXT_FINANCIER_NAME": "GOD FREY PHILLIPS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7672,
      "TXT_FINANCIER_NAME": "BHAIRA INVESTMENTS AUTOMOTIVE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 7673,
      "TXT_FINANCIER_NAME": "THE MEENACHIL TALUK CO OP EMPLOYEES CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7674,
      "TXT_FINANCIER_NAME": "the meenachil taluk cooperative employees cooperative society ltd"
    },
    {
      "NUM_FINANCIER_CD": 7675,
      "TXT_FINANCIER_NAME": "MANGLAM MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 7676,
      "TXT_FINANCIER_NAME": "THE MUNDUR SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7677,
      "TXT_FINANCIER_NAME": "HP STATE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7678,
      "TXT_FINANCIER_NAME": "THE MUNDUR COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7679,
      "TXT_FINANCIER_NAME": "DISTT COOPERATIVE BANK CHAMOLI"
    },
    {
      "NUM_FINANCIER_CD": 7680,
      "TXT_FINANCIER_NAME": "Potters Cottage Industrial CO OP Society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7681,
      "TXT_FINANCIER_NAME": "SCDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 7682,
      "TXT_FINANCIER_NAME": "N P O L"
    },
    {
      "NUM_FINANCIER_CD": 7683,
      "TXT_FINANCIER_NAME": "MANGALAM MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 7684,
      "TXT_FINANCIER_NAME": "SRI SHARADA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7685,
      "TXT_FINANCIER_NAME": "THE VAISHYA URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7686,
      "TXT_FINANCIER_NAME": "CHITTOR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7687,
      "TXT_FINANCIER_NAME": "MANGALMURTI NAGARI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7688,
      "TXT_FINANCIER_NAME": "MAHIDHARPURA ARBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7689,
      "TXT_FINANCIER_NAME": "THE NAMAKKAL COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7690,
      "TXT_FINANCIER_NAME": "CHIWA SAHAKARI PATHASANSTHA MARYADIT DOMBIWALI"
    },
    {
      "NUM_FINANCIER_CD": 7691,
      "TXT_FINANCIER_NAME": "VIZAG STEEL PLANT"
    },
    {
      "NUM_FINANCIER_CD": 7692,
      "TXT_FINANCIER_NAME": "THE UNION FARMERS SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7693,
      "TXT_FINANCIER_NAME": "THE YAMAKANMARDI LAXMI URBAN CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7694,
      "TXT_FINANCIER_NAME": "Sarswati Mahila Nagari path santha"
    },
    {
      "NUM_FINANCIER_CD": 7695,
      "TXT_FINANCIER_NAME": "Sarvodya Nagari sahkari path santha."
    },
    {
      "NUM_FINANCIER_CD": 7696,
      "TXT_FINANCIER_NAME": "Swami smartha bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 7697,
      "TXT_FINANCIER_NAME": "Jain ostwal nagari path santha"
    },
    {
      "NUM_FINANCIER_CD": 7698,
      "TXT_FINANCIER_NAME": "Goreshwar nagari path santha"
    },
    {
      "NUM_FINANCIER_CD": 7699,
      "TXT_FINANCIER_NAME": "Bhirvnath path santha"
    },
    {
      "NUM_FINANCIER_CD": 7700,
      "TXT_FINANCIER_NAME": "Nagebaba nagari path santha."
    },
    {
      "NUM_FINANCIER_CD": 7701,
      "TXT_FINANCIER_NAME": "Pravra nagari sahkari path santha."
    },
    {
      "NUM_FINANCIER_CD": 7702,
      "TXT_FINANCIER_NAME": "Vasantrao naike nagari sahkari path santha"
    },
    {
      "NUM_FINANCIER_CD": 7703,
      "TXT_FINANCIER_NAME": "Rahat merchant co-oprative path santha"
    },
    {
      "NUM_FINANCIER_CD": 7704,
      "TXT_FINANCIER_NAME": "MALAI DEVI NAGARI SAH. PATH SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 7705,
      "TXT_FINANCIER_NAME": "V S S N BANK"
    },
    {
      "NUM_FINANCIER_CD": 7706,
      "TXT_FINANCIER_NAME": "GOVERNMENT TOOL ROOM AND TRAINING CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 7707,
      "TXT_FINANCIER_NAME": "AGROTECHNOLOGIST CO OP CREDIT SOCIETY LTD ISLAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 7708,
      "TXT_FINANCIER_NAME": "VISHWESHARAYA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7709,
      "TXT_FINANCIER_NAME": "KISSAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7710,
      "TXT_FINANCIER_NAME": "SHIVPRATAP NAG SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7711,
      "TXT_FINANCIER_NAME": "CHIKAMAGALUR KODAGU GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7712,
      "TXT_FINANCIER_NAME": "SAMCO BANK"
    },
    {
      "NUM_FINANCIER_CD": 7713,
      "TXT_FINANCIER_NAME": "SHRIMAN SUMAN MALJI SURANA VYAPARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7714,
      "TXT_FINANCIER_NAME": "THE AKOLA ARBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7715,
      "TXT_FINANCIER_NAME": "HIMACHAL CULTURAL ASSOCIATION COOP CREDIT SOCIETY LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 7716,
      "TXT_FINANCIER_NAME": "CITY UNOIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7717,
      "TXT_FINANCIER_NAME": "INDIRA NAGARI SAH PATSANSTHA M"
    },
    {
      "NUM_FINANCIER_CD": 7718,
      "TXT_FINANCIER_NAME": "MARUTI CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7719,
      "TXT_FINANCIER_NAME": "MENCHUS FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7720,
      "TXT_FINANCIER_NAME": "JAI BAJRANG GRAMIN BEGARSHETI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7721,
      "TXT_FINANCIER_NAME": "THE SARVODAY SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7722,
      "TXT_FINANCIER_NAME": "DHANESH KUMAR CHOUDARY"
    },
    {
      "NUM_FINANCIER_CD": 7723,
      "TXT_FINANCIER_NAME": "SHRI SAMARATH SAINATH SAKH SAHAKARI SANSTHA MDYT"
    },
    {
      "NUM_FINANCIER_CD": 7724,
      "TXT_FINANCIER_NAME": "KARAVALI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7725,
      "TXT_FINANCIER_NAME": "MOTI FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 7726,
      "TXT_FINANCIER_NAME": "BULDANA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7727,
      "TXT_FINANCIER_NAME": "UNDER SECRETARY TO GOVT HOME DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 7728,
      "TXT_FINANCIER_NAME": "LOHGAON NAGRI SAHAKARI PATHSANSTHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7729,
      "TXT_FINANCIER_NAME": "RAJGURU NAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7730,
      "TXT_FINANCIER_NAME": "ST GONSALO GARCIA CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 7731,
      "TXT_FINANCIER_NAME": "PADMUNJA C A BANK PUTTUR"
    },
    {
      "NUM_FINANCIER_CD": 7732,
      "TXT_FINANCIER_NAME": "AGASHI CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 7733,
      "TXT_FINANCIER_NAME": "SAGAR CREDIT CO OPERATIVE SOCIETY LTD BYNDOOR"
    },
    {
      "NUM_FINANCIER_CD": 7734,
      "TXT_FINANCIER_NAME": "THE THINDALMALAI PRIMARY AGRICULTURAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7735,
      "TXT_FINANCIER_NAME": "DOMBIVALI NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7736,
      "TXT_FINANCIER_NAME": "SAGAR CREDIT COOP SOCIETY LTD BYNDOOR"
    },
    {
      "NUM_FINANCIER_CD": 7737,
      "TXT_FINANCIER_NAME": "SAIL RMD BIN"
    },
    {
      "NUM_FINANCIER_CD": 7738,
      "TXT_FINANCIER_NAME": "THE PRESIDENT GOVT OF INDIA ISRO SDSC"
    },
    {
      "NUM_FINANCIER_CD": 7739,
      "TXT_FINANCIER_NAME": "VASAVI PATHINA SAHAKAR SANGA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 7740,
      "TXT_FINANCIER_NAME": "JILA AANTVYAWSAI SAHKARI VIKAS SAMITI MYDT RAJGARHMYDT"
    },
    {
      "NUM_FINANCIER_CD": 7741,
      "TXT_FINANCIER_NAME": "SHREE KADASIDDESHWAR PATTAN SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 7742,
      "TXT_FINANCIER_NAME": "THE LAXMI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7743,
      "TXT_FINANCIER_NAME": "PARSHWANATH NAGARI SAHAKRI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7744,
      "TXT_FINANCIER_NAME": "THE PARRA VERLA CANCA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7745,
      "TXT_FINANCIER_NAME": "SHRI DATTA V K S S SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7746,
      "TXT_FINANCIER_NAME": "ALBRIGHT AND WILSON CHEMICALS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7747,
      "TXT_FINANCIER_NAME": "A P GRAMEENA VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 7748,
      "TXT_FINANCIER_NAME": "SRI VEERAMAHESHWARA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7749,
      "TXT_FINANCIER_NAME": "THE THALASSERY PRIMARY CO OP AGRIL AND RURAL DEVPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7750,
      "TXT_FINANCIER_NAME": "SHRI VISHWAKARMA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7751,
      "TXT_FINANCIER_NAME": "DNAYANADEEP CO OPERATIVE CREDIT SOCIETY LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 7752,
      "TXT_FINANCIER_NAME": "SARJERAO BHAU GRAMEN BIGAR SHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7753,
      "TXT_FINANCIER_NAME": "SAMPADA NAGRI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7754,
      "TXT_FINANCIER_NAME": "THANE DIST MADHYAVARTI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7755,
      "TXT_FINANCIER_NAME": "IDKIDU SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7756,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI GRAMIN BIGARSHETI SAHKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7757,
      "TXT_FINANCIER_NAME": "SIDHARTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7758,
      "TXT_FINANCIER_NAME": "EKTA CO OP PATPEDI"
    },
    {
      "NUM_FINANCIER_CD": 7759,
      "TXT_FINANCIER_NAME": "M K INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 7760,
      "TXT_FINANCIER_NAME": "BHARATH PETROLEUM CORP"
    },
    {
      "NUM_FINANCIER_CD": 7761,
      "TXT_FINANCIER_NAME": "DSP SAIL"
    },
    {
      "NUM_FINANCIER_CD": 7762,
      "TXT_FINANCIER_NAME": "E.P.I.L."
    },
    {
      "NUM_FINANCIER_CD": 7763,
      "TXT_FINANCIER_NAME": "EDC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7764,
      "TXT_FINANCIER_NAME": "eternal motors pvt ltd"
    },
    {
      "NUM_FINANCIER_CD": 7765,
      "TXT_FINANCIER_NAME": "FINOLEX CABLES LTD"
    },
    {
      "NUM_FINANCIER_CD": 7766,
      "TXT_FINANCIER_NAME": "G B NAHAR HIRE PURCHASE SYSTEMS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7767,
      "TXT_FINANCIER_NAME": "GENERAL MANAGER HRM GOVT TOOL ROOM AND TRAINING CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 7768,
      "TXT_FINANCIER_NAME": "GENERAL POSTOFFICE"
    },
    {
      "NUM_FINANCIER_CD": 7769,
      "TXT_FINANCIER_NAME": "GIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 7770,
      "TXT_FINANCIER_NAME": "AMAR FINANCIERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 7771,
      "TXT_FINANCIER_NAME": "THE NORTH KANARA B S B CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7772,
      "TXT_FINANCIER_NAME": "M C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7773,
      "TXT_FINANCIER_NAME": "THE MERCHANTS LIBERAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7774,
      "TXT_FINANCIER_NAME": "JYOTHI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7775,
      "TXT_FINANCIER_NAME": "RAMDEV BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 7776,
      "TXT_FINANCIER_NAME": "VOLTAS LTD"
    },
    {
      "NUM_FINANCIER_CD": 7777,
      "TXT_FINANCIER_NAME": "INDIRA GANDHI NATIONAL OPEN UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 7778,
      "TXT_FINANCIER_NAME": "SHREE PARSHWANATH MINORITY SOUHARD CO OP CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7779,
      "TXT_FINANCIER_NAME": "SMALL IND DEV BANK"
    },
    {
      "NUM_FINANCIER_CD": 7780,
      "TXT_FINANCIER_NAME": "SRI NATHAJI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7781,
      "TXT_FINANCIER_NAME": "CITI CORPORATION MARUTI FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7782,
      "TXT_FINANCIER_NAME": "A P STATE HOUSING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 7783,
      "TXT_FINANCIER_NAME": "THE MARKANDEY MAHILA MULTIPURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7784,
      "TXT_FINANCIER_NAME": "THE GRADUATES COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7785,
      "TXT_FINANCIER_NAME": "SAHAYOG URBEN CO OPP BANK LTD UDGIR"
    },
    {
      "NUM_FINANCIER_CD": 7786,
      "TXT_FINANCIER_NAME": "SHREE AVDHOOT SWAMI NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7787,
      "TXT_FINANCIER_NAME": "THE WHEELS"
    },
    {
      "NUM_FINANCIER_CD": 7788,
      "TXT_FINANCIER_NAME": "KANKUR PATHAR PAT SANSTHA SHIRUR"
    },
    {
      "NUM_FINANCIER_CD": 7789,
      "TXT_FINANCIER_NAME": "BHARUCH DIST"
    },
    {
      "NUM_FINANCIER_CD": 7790,
      "TXT_FINANCIER_NAME": "DINESH INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 7791,
      "TXT_FINANCIER_NAME": "LESSOR GE FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 7792,
      "TXT_FINANCIER_NAME": "IPCL"
    },
    {
      "NUM_FINANCIER_CD": 7793,
      "TXT_FINANCIER_NAME": "THE KOTTAYAM AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7794,
      "TXT_FINANCIER_NAME": "HANUMANTRAO CHAUDHARI KALBHARAVNATH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7795,
      "TXT_FINANCIER_NAME": "HINGOLI PEPOLE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7796,
      "TXT_FINANCIER_NAME": "INDPENDEMCE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7797,
      "TXT_FINANCIER_NAME": "INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7798,
      "TXT_FINANCIER_NAME": "JAMIA CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7799,
      "TXT_FINANCIER_NAME": "JANALAXMI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7800,
      "TXT_FINANCIER_NAME": "JANALAXMI CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7801,
      "TXT_FINANCIER_NAME": "JANLAXMI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7802,
      "TXT_FINANCIER_NAME": "JAY MATADI CO-OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7803,
      "TXT_FINANCIER_NAME": "JODHPUR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7804,
      "TXT_FINANCIER_NAME": "Kancheepuram District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 7805,
      "TXT_FINANCIER_NAME": "GANGOTHRI SOUHARDHA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7806,
      "TXT_FINANCIER_NAME": "DHANAWARDHINI GRAMIN BANK SAH PATPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 7807,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI SHIVAJIRAO KALE MAL VAHATUK SAHAKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7808,
      "TXT_FINANCIER_NAME": "MAHAJAN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 7809,
      "TXT_FINANCIER_NAME": "SRI BHAGAVAN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 7810,
      "TXT_FINANCIER_NAME": "RAVALNATH CO OP HOUSING FINANCE SOC LTD AJARA"
    },
    {
      "NUM_FINANCIER_CD": 7811,
      "TXT_FINANCIER_NAME": "THE GHOGHAMBA NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 7812,
      "TXT_FINANCIER_NAME": "CHHATTISHGARH KHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7813,
      "TXT_FINANCIER_NAME": "SANJAY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7814,
      "TXT_FINANCIER_NAME": "CMDEIL"
    },
    {
      "NUM_FINANCIER_CD": 7815,
      "TXT_FINANCIER_NAME": "THE INDUSTRIAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7816,
      "TXT_FINANCIER_NAME": "SUGAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7817,
      "TXT_FINANCIER_NAME": "SANJEEVANI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7818,
      "TXT_FINANCIER_NAME": "TOTAL LUBRICANTS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7819,
      "TXT_FINANCIER_NAME": "THE MUNAVALLI URBAN CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7820,
      "TXT_FINANCIER_NAME": "AMBAD KAMTWADE VIVIDH KARYAKARI SA VIKAS SEVA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7821,
      "TXT_FINANCIER_NAME": "CHANDRAPRABHA GRAMIN BIGARSHETI SAH PATHSANSTHA NATEPUTE"
    },
    {
      "NUM_FINANCIER_CD": 7822,
      "TXT_FINANCIER_NAME": "THE BHADASARA KSHATRIYA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7823,
      "TXT_FINANCIER_NAME": "THE BHAGYODAY CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7824,
      "TXT_FINANCIER_NAME": "THE BHAGYODAY CO OPP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7825,
      "TXT_FINANCIER_NAME": "THE BHAGYODAY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7826,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7827,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7828,
      "TXT_FINANCIER_NAME": "The Bharat Co Op Bank (Mumbai) Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7829,
      "TXT_FINANCIER_NAME": "THE BHARAT CO.OP. BANK (MUMBAI) LTD."
    },
    {
      "NUM_FINANCIER_CD": 7830,
      "TXT_FINANCIER_NAME": "THE BHARAT COOP BANK (MUMBAI) LTD"
    },
    {
      "NUM_FINANCIER_CD": 7831,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OP BANK (MUMBAI) LTD"
    },
    {
      "NUM_FINANCIER_CD": 7832,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7833,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7834,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OP BANK(MUMBAI) LTD"
    },
    {
      "NUM_FINANCIER_CD": 7835,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OP. BANK (MUMBAI) LTD."
    },
    {
      "NUM_FINANCIER_CD": 7836,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OPERATIVE BANK (MUMBAI) LTD"
    },
    {
      "NUM_FINANCIER_CD": 7837,
      "TXT_FINANCIER_NAME": "THE BHATKAL AGRICULTURAL & RURAL DEVELOPMENT CO-OP BANK LTD BHATKAL"
    },
    {
      "NUM_FINANCIER_CD": 7838,
      "TXT_FINANCIER_NAME": "THE BHATKAL PLD BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7839,
      "TXT_FINANCIER_NAME": "SHRI ANK FINHOLD PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7840,
      "TXT_FINANCIER_NAME": "ASHOK NAGARI SAHAKARI BANK LTD PIMPRI PUNE"
    },
    {
      "NUM_FINANCIER_CD": 7841,
      "TXT_FINANCIER_NAME": "THE SHIRODA PROGRESSIVE URB COOP CR STY"
    },
    {
      "NUM_FINANCIER_CD": 7842,
      "TXT_FINANCIER_NAME": "SHRI GAYATRI CO OP CRIDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7843,
      "TXT_FINANCIER_NAME": "DHOLPUR SAHAKARI BHOOMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 7844,
      "TXT_FINANCIER_NAME": "KANCHIPURAM COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7845,
      "TXT_FINANCIER_NAME": "OTTAPALAM COOP URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7846,
      "TXT_FINANCIER_NAME": "OTTAPALAM COOP URBAN BANK L"
    },
    {
      "NUM_FINANCIER_CD": 7847,
      "TXT_FINANCIER_NAME": "UGCDAE CONSORTIUM FOR SCIENTIFIC RESEARCH"
    },
    {
      "NUM_FINANCIER_CD": 7848,
      "TXT_FINANCIER_NAME": "SANT GAJANAN GRAMIN BIGAR SHETI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7849,
      "TXT_FINANCIER_NAME": "T SAMPATHRAJ FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 7850,
      "TXT_FINANCIER_NAME": "THE SUBRAMANIA NAGAR COOP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7851,
      "TXT_FINANCIER_NAME": "ULIKKAL SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7852,
      "TXT_FINANCIER_NAME": "ULLIKAL SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7853,
      "TXT_FINANCIER_NAME": "UMA CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7854,
      "TXT_FINANCIER_NAME": "UNNATI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7855,
      "TXT_FINANCIER_NAME": "UO COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7856,
      "TXT_FINANCIER_NAME": "UP CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7857,
      "TXT_FINANCIER_NAME": "UP CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7858,
      "TXT_FINANCIER_NAME": "URABN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7859,
      "TXT_FINANCIER_NAME": "URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7860,
      "TXT_FINANCIER_NAME": "URBEN DEVELOPMENT COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7861,
      "TXT_FINANCIER_NAME": "VAISH CO-OP ADARSH BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7862,
      "TXT_FINANCIER_NAME": "VALANCHERY SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7263,
      "TXT_FINANCIER_NAME": "SABARKHANTHA DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7264,
      "TXT_FINANCIER_NAME": "THE SABARKHANTHA DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7265,
      "TXT_FINANCIER_NAME": "THE MAHALAXMI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7266,
      "TXT_FINANCIER_NAME": "DISTT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7267,
      "TXT_FINANCIER_NAME": "SHRI GOVARDHAN SINHJI RAGHUWANSHI SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7268,
      "TXT_FINANCIER_NAME": "JALAUN DISTRICT CO-OP.BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7269,
      "TXT_FINANCIER_NAME": "THE DADASAHEB RAWAL CO-OP BANK OF DONDIACHA LTD."
    },
    {
      "NUM_FINANCIER_CD": 7270,
      "TXT_FINANCIER_NAME": "GODAVARI GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7271,
      "TXT_FINANCIER_NAME": "KRISHANA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7272,
      "TXT_FINANCIER_NAME": "MAGMA SARACHI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 7273,
      "TXT_FINANCIER_NAME": "ISMALIYA URBAN CO BANK"
    },
    {
      "NUM_FINANCIER_CD": 7274,
      "TXT_FINANCIER_NAME": "RAWATMAL BHIKAMCHAND PAGARIA"
    },
    {
      "NUM_FINANCIER_CD": 7275,
      "TXT_FINANCIER_NAME": "BHAGYASHRI MAHILA NAGRI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7276,
      "TXT_FINANCIER_NAME": "SRI KENGAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7277,
      "TXT_FINANCIER_NAME": "SHRI CHOUDESHWARI KSHATRIYA NAGARI SAH PATHPEDHI MARY"
    },
    {
      "NUM_FINANCIER_CD": 7278,
      "TXT_FINANCIER_NAME": "firstsource sollutions limited"
    },
    {
      "NUM_FINANCIER_CD": 7279,
      "TXT_FINANCIER_NAME": "GANAPATHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7280,
      "TXT_FINANCIER_NAME": "MAGMA SHARANCHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7281,
      "TXT_FINANCIER_NAME": "SHREERAM CREDIT CO OP SOCIETY LTD KUNDAPURA"
    },
    {
      "NUM_FINANCIER_CD": 7282,
      "TXT_FINANCIER_NAME": "THE NAVHIND CO OP CREDIT SOCY LTD BR WADGAON BELGAUM"
    },
    {
      "NUM_FINANCIER_CD": 7283,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER OPERATION MAINTENANCE KPCL BTPS"
    },
    {
      "NUM_FINANCIER_CD": 7284,
      "TXT_FINANCIER_NAME": "LATE RADHESHYAM SOMANI MERCHANT GRAMIN BIGAR SHETI SAH PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 7285,
      "TXT_FINANCIER_NAME": "KANARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7286,
      "TXT_FINANCIER_NAME": "TGMC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7287,
      "TXT_FINANCIER_NAME": "CHICKO BANK"
    },
    {
      "NUM_FINANCIER_CD": 7288,
      "TXT_FINANCIER_NAME": "SRI VITTAL SOWHARDA PATTINA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 7289,
      "TXT_FINANCIER_NAME": "PEERMADE TALUK CO OP AGRICULTURAL AND RURAL DEVOLOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 7290,
      "TXT_FINANCIER_NAME": "SUDARSHAN NAG SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7291,
      "TXT_FINANCIER_NAME": "SHANKAR GRAMIN BIGAR SHETI SAH PATSANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7292,
      "TXT_FINANCIER_NAME": "KRIBHKO HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 7293,
      "TXT_FINANCIER_NAME": "JANKALYAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7294,
      "TXT_FINANCIER_NAME": "THE LATUR URBEN CO OPP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7295,
      "TXT_FINANCIER_NAME": "SRI SHIVAJI SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7296,
      "TXT_FINANCIER_NAME": "VARACHHA ROAD URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7297,
      "TXT_FINANCIER_NAME": "THE LAKSHMI VILLAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7298,
      "TXT_FINANCIER_NAME": "SAHAKARI URBAN COOPERATIVE CREDIT SOCIETY VASCO"
    },
    {
      "NUM_FINANCIER_CD": 7299,
      "TXT_FINANCIER_NAME": "SAHAKARI URBAN CREDIT COOPERATIVE SOCIETY VASCO"
    },
    {
      "NUM_FINANCIER_CD": 7300,
      "TXT_FINANCIER_NAME": "CHENDAMANGALAM SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7301,
      "TXT_FINANCIER_NAME": "SHIVKRUPA SAH PATPEDHI LTD PIMPRI"
    },
    {
      "NUM_FINANCIER_CD": 7302,
      "TXT_FINANCIER_NAME": "MILAN AUTO DEALES"
    },
    {
      "NUM_FINANCIER_CD": 7303,
      "TXT_FINANCIER_NAME": "WAI URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7304,
      "TXT_FINANCIER_NAME": "MAGMA FINCORT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7305,
      "TXT_FINANCIER_NAME": "CHIEF EBGINER ADMIN HVPN"
    },
    {
      "NUM_FINANCIER_CD": 7306,
      "TXT_FINANCIER_NAME": "GOKARNANATHA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7307,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MAHARASHTRIAN CO OP CREDIT AND SUPPLY SOCI LTD"
    },
    {
      "NUM_FINANCIER_CD": 7308,
      "TXT_FINANCIER_NAME": "SHREEJIT BHATIA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7309,
      "TXT_FINANCIER_NAME": "SHRI DATTAKRUPA SOLAPUR DIST NAGARI SAH PATHSANSTHA MARY JEUR"
    },
    {
      "NUM_FINANCIER_CD": 7310,
      "TXT_FINANCIER_NAME": "THE RANCHI-KHUNTI CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7311,
      "TXT_FINANCIER_NAME": "SHIVAM SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7312,
      "TXT_FINANCIER_NAME": "ORISSA STATE CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 7313,
      "TXT_FINANCIER_NAME": "sir m visvesvaraya co operative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 7314,
      "TXT_FINANCIER_NAME": "THE NEDUMON SERVICE CO-OPEARTIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7315,
      "TXT_FINANCIER_NAME": "Export Credit Guarantee Corporation Ltd"
    },
    {
      "NUM_FINANCIER_CD": 7316,
      "TXT_FINANCIER_NAME": "ABREPOSE"
    },
    {
      "NUM_FINANCIER_CD": 7317,
      "TXT_FINANCIER_NAME": "MANSING CO-OP BANK LTD.,DUDHONDI"
    },
    {
      "NUM_FINANCIER_CD": 7318,
      "TXT_FINANCIER_NAME": "VIDYA SHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7319,
      "TXT_FINANCIER_NAME": "ANDATHODE SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7320,
      "TXT_FINANCIER_NAME": "G.E.COUNTRYWIDE"
    },
    {
      "NUM_FINANCIER_CD": 7321,
      "TXT_FINANCIER_NAME": "THE KAKKOOR SERVICE CO-OP: BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7322,
      "TXT_FINANCIER_NAME": "KANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7323,
      "TXT_FINANCIER_NAME": "RAJKOT NAGARIK SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7324,
      "TXT_FINANCIER_NAME": "SHRI MAHAVEER COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7325,
      "TXT_FINANCIER_NAME": "PEOPLE`S URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7326,
      "TXT_FINANCIER_NAME": "CENTRAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 7327,
      "TXT_FINANCIER_NAME": "THE RAIGANJ CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7328,
      "TXT_FINANCIER_NAME": "I B P COMPONY LTD."
    },
    {
      "NUM_FINANCIER_CD": 7329,
      "TXT_FINANCIER_NAME": "VALLAPUZHA SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7330,
      "TXT_FINANCIER_NAME": "D C B DEVELOPMENT CREDIT BANK .NANDED"
    },
    {
      "NUM_FINANCIER_CD": 7331,
      "TXT_FINANCIER_NAME": "PANAVALLY VILLAGE SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7332,
      "TXT_FINANCIER_NAME": "THE SIROHI CENTRAL COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7333,
      "TXT_FINANCIER_NAME": "RAIBARELI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7334,
      "TXT_FINANCIER_NAME": "LAXMI BAI MAHILA NAGRIK SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7335,
      "TXT_FINANCIER_NAME": "DUGAR FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 7336,
      "TXT_FINANCIER_NAME": "SAMANVAYA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7337,
      "TXT_FINANCIER_NAME": "NESTLE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7338,
      "TXT_FINANCIER_NAME": "BHANDARI CO-OP.BANK LTD.MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 7339,
      "TXT_FINANCIER_NAME": "The Cheruthazham Service Co-operativeBank Limited"
    },
    {
      "NUM_FINANCIER_CD": 7340,
      "TXT_FINANCIER_NAME": "MANASA BANK"
    },
    {
      "NUM_FINANCIER_CD": 7341,
      "TXT_FINANCIER_NAME": "TEHRI HYDRO DEVELOPMENT CORPORATION LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 7342,
      "TXT_FINANCIER_NAME": "JILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7343,
      "TXT_FINANCIER_NAME": "THE-CHENKARA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7344,
      "TXT_FINANCIER_NAME": "MANORAMA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7345,
      "TXT_FINANCIER_NAME": "CHANGANASSER CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7346,
      "TXT_FINANCIER_NAME": "SHRINATH CO-OPARATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7347,
      "TXT_FINANCIER_NAME": "SARASWATI INDUS SYN LTD"
    },
    {
      "NUM_FINANCIER_CD": 7348,
      "TXT_FINANCIER_NAME": "THE SHIBPUR CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7349,
      "TXT_FINANCIER_NAME": "PITHORAGARH KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 7350,
      "TXT_FINANCIER_NAME": "HOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 7351,
      "TXT_FINANCIER_NAME": "SHRI VIJAYALAXMI SOUHARDA SAHAKARI NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 7352,
      "TXT_FINANCIER_NAME": "THE KAYAN JANTA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7353,
      "TXT_FINANCIER_NAME": "THE KALYAN JANTA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7354,
      "TXT_FINANCIER_NAME": "CO OP BANK OF BARODA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7355,
      "TXT_FINANCIER_NAME": "THE Muthalapuram Service Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 7356,
      "TXT_FINANCIER_NAME": "PLS ADD NEW BANK"
    },
    {
      "NUM_FINANCIER_CD": 7357,
      "TXT_FINANCIER_NAME": "KARJAN NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7358,
      "TXT_FINANCIER_NAME": "THE CO.OPERATIVE BANK OF MAHESANA LTD."
    },
    {
      "NUM_FINANCIER_CD": 7359,
      "TXT_FINANCIER_NAME": "THE CO.OPARATIVE.BANK OF MAHESANA LTD."
    },
    {
      "NUM_FINANCIER_CD": 7360,
      "TXT_FINANCIER_NAME": "THE PALLIKKAL SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7361,
      "TXT_FINANCIER_NAME": "THE MOWANCHERY CO-OPERATIVE RURAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8661,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWKCO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8662,
      "TXT_FINANCIER_NAME": "THE MANGALPADY SERVICE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8663,
      "TXT_FINANCIER_NAME": "THE MANSA CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8664,
      "TXT_FINANCIER_NAME": "THE MARKANDEY CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8665,
      "TXT_FINANCIER_NAME": "THE MEH URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8666,
      "TXT_FINANCIER_NAME": "THE MEHSANA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8667,
      "TXT_FINANCIER_NAME": "THE MERCHANTS LIBERAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8668,
      "TXT_FINANCIER_NAME": "THE METRO CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8669,
      "TXT_FINANCIER_NAME": "THE MOGAVEERA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8670,
      "TXT_FINANCIER_NAME": "THE MUSLIM CO-OPERATIVE BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 8671,
      "TXT_FINANCIER_NAME": "THE MUTHALAPURAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8672,
      "TXT_FINANCIER_NAME": "THE NADUVIL SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8673,
      "TXT_FINANCIER_NAME": "THE NAJARAKANDY FARMERS SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8674,
      "TXT_FINANCIER_NAME": "THE NAKODAR HINDU URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8675,
      "TXT_FINANCIER_NAME": "THE NAMAKKAL CO-OP URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8676,
      "TXT_FINANCIER_NAME": "SRI VISHNAVI AUTO FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 8677,
      "TXT_FINANCIER_NAME": "GOVT EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8678,
      "TXT_FINANCIER_NAME": "VAISHYA URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8679,
      "TXT_FINANCIER_NAME": "SARVODAYA NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8680,
      "TXT_FINANCIER_NAME": "NYAYAMITHRA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8681,
      "TXT_FINANCIER_NAME": "KALRA H P AND FINANCE COMPANY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8682,
      "TXT_FINANCIER_NAME": "RAMAKRISHNA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8683,
      "TXT_FINANCIER_NAME": "MAHENDRA & MAHENDRA FIN."
    },
    {
      "NUM_FINANCIER_CD": 8684,
      "TXT_FINANCIER_NAME": "MAHINRA & MAHINDRA FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 8685,
      "TXT_FINANCIER_NAME": "MAHINDERA & MAHINDERA FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8686,
      "TXT_FINANCIER_NAME": "MAHENDRA &ampMAHENDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8687,
      "TXT_FINANCIER_NAME": "TIMES BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8688,
      "TXT_FINANCIER_NAME": "VISHVESHWAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8689,
      "TXT_FINANCIER_NAME": "SARASWAT MAHILA NAGARI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8690,
      "TXT_FINANCIER_NAME": "UNIVERSITY OF AGRICULTURAL SCIENCES"
    },
    {
      "NUM_FINANCIER_CD": 8691,
      "TXT_FINANCIER_NAME": "MRF LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8692,
      "TXT_FINANCIER_NAME": "Traiyogana Prabhandak Anuja Nigam"
    },
    {
      "NUM_FINANCIER_CD": 8693,
      "TXT_FINANCIER_NAME": "KUNNATHU FINANCIAL ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 8694,
      "TXT_FINANCIER_NAME": "SAHASRARJUN NAGARI SAHAKARI PETHPEDHI MARY"
    },
    {
      "NUM_FINANCIER_CD": 8695,
      "TXT_FINANCIER_NAME": "BADAGARA CO OPERATIVE RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 8696,
      "TXT_FINANCIER_NAME": "UNDER SECURITY GAUHBVN"
    },
    {
      "NUM_FINANCIER_CD": 8697,
      "TXT_FINANCIER_NAME": "M M T C LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8698,
      "TXT_FINANCIER_NAME": "SANDESH SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8699,
      "TXT_FINANCIER_NAME": "SATE BANK OF INDORE,"
    },
    {
      "NUM_FINANCIER_CD": 8700,
      "TXT_FINANCIER_NAME": "sbppcbl"
    },
    {
      "NUM_FINANCIER_CD": 8701,
      "TXT_FINANCIER_NAME": "UCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 8702,
      "TXT_FINANCIER_NAME": "ALWAR BHARATPUR ANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8703,
      "TXT_FINANCIER_NAME": "ASSAM GRAMIN BIKASH BANK"
    },
    {
      "NUM_FINANCIER_CD": 8704,
      "TXT_FINANCIER_NAME": "BAGALKOT KARNATAKA VIKAS GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8705,
      "TXT_FINANCIER_NAME": "Banaskantha- Mehsana gramin bank"
    },
    {
      "NUM_FINANCIER_CD": 8706,
      "TXT_FINANCIER_NAME": "Banaskantha-Mehsana Gramin Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8707,
      "TXT_FINANCIER_NAME": "BARABANKI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8708,
      "TXT_FINANCIER_NAME": "Bhagalpur Banka Kshetriya Bank"
    },
    {
      "NUM_FINANCIER_CD": 8709,
      "TXT_FINANCIER_NAME": "BHANDARA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8710,
      "TXT_FINANCIER_NAME": "BHOJPUR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8711,
      "TXT_FINANCIER_NAME": "BHOJPUR ROHTAS GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8712,
      "TXT_FINANCIER_NAME": "BIDUR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8713,
      "TXT_FINANCIER_NAME": "BIJAPUR GARMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8714,
      "TXT_FINANCIER_NAME": "BIJAPUR GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8715,
      "TXT_FINANCIER_NAME": "KONAMBE MAHILA VIVIDH KARYAKARI SAHAKARI SERVA SEVA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8716,
      "TXT_FINANCIER_NAME": "SREE CAUVERY SOUHARDA CREDIT CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8717,
      "TXT_FINANCIER_NAME": "PUBLIC MOTOR AND GENERAL FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 8718,
      "TXT_FINANCIER_NAME": "GILLETTE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8719,
      "TXT_FINANCIER_NAME": "PAGARIA FINANCE SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 8720,
      "TXT_FINANCIER_NAME": "MCF FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8721,
      "TXT_FINANCIER_NAME": "BALAJI INVESTMENT AND FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8722,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER ELECTRICAL CONSTN KPCL"
    },
    {
      "NUM_FINANCIER_CD": 8723,
      "TXT_FINANCIER_NAME": "SHREE VEJALPORE VIBHAG SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8724,
      "TXT_FINANCIER_NAME": "DR DAULATRAO SONUJI AHER GRAMIN BIGARSHETI SAH PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 8725,
      "TXT_FINANCIER_NAME": "SHRI GURUSIDDESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8726,
      "TXT_FINANCIER_NAME": "SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8727,
      "TXT_FINANCIER_NAME": "SHANKARAO MOHITE PATIL SAHAKARI BANK AKLUJ"
    },
    {
      "NUM_FINANCIER_CD": 8728,
      "TXT_FINANCIER_NAME": "Sharadha Credit Cooperative Society"
    },
    {
      "NUM_FINANCIER_CD": 8729,
      "TXT_FINANCIER_NAME": "SHARD SAHAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8730,
      "TXT_FINANCIER_NAME": "SHIDDHESHWAR URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8731,
      "TXT_FINANCIER_NAME": "SHIKSHAK SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8732,
      "TXT_FINANCIER_NAME": "SHIV SHAKTI COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8733,
      "TXT_FINANCIER_NAME": "SHIWAI NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8734,
      "TXT_FINANCIER_NAME": "SHREE AMBIKA URBAN COOP CREDIT SOCIETY LTD CHIKHALI"
    },
    {
      "NUM_FINANCIER_CD": 8735,
      "TXT_FINANCIER_NAME": "SHREE ANAND CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 8736,
      "TXT_FINANCIER_NAME": "SHREE BHAIRAVNATH NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8737,
      "TXT_FINANCIER_NAME": "SHREE CHHANI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8738,
      "TXT_FINANCIER_NAME": "SHREE CHHANI PATIDAR CO. OP. CREDIT SOC. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8739,
      "TXT_FINANCIER_NAME": "SHREE CHHATRAPATI URBAN CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8740,
      "TXT_FINANCIER_NAME": "SHREE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8741,
      "TXT_FINANCIER_NAME": "SHREE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8742,
      "TXT_FINANCIER_NAME": "SHREE JAIN CHAKAN BIGAR SHETI SAH PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8743,
      "TXT_FINANCIER_NAME": "SHREE LAXMI CO-OP. BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 8744,
      "TXT_FINANCIER_NAME": "SHREE MAHILA SEWA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8745,
      "TXT_FINANCIER_NAME": "THE W Z O TRUST FUND"
    },
    {
      "NUM_FINANCIER_CD": 8746,
      "TXT_FINANCIER_NAME": "NAMDEV LEASING AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8747,
      "TXT_FINANCIER_NAME": "VIJAYSHREE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8748,
      "TXT_FINANCIER_NAME": "PONNAMPET TOWN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8749,
      "TXT_FINANCIER_NAME": "PARIJAT CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8750,
      "TXT_FINANCIER_NAME": "THE GUJARAT HSG UMARWADA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8751,
      "TXT_FINANCIER_NAME": "MAHESH GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8752,
      "TXT_FINANCIER_NAME": "SAMPADA NAGARI SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8753,
      "TXT_FINANCIER_NAME": "KOLLAMOGRU HARIHARA COOP AGRL BANK NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 8754,
      "TXT_FINANCIER_NAME": "SHABARI MAHILA CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8755,
      "TXT_FINANCIER_NAME": "THE IDAR NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8756,
      "TXT_FINANCIER_NAME": "PADAMCHAND SINGHVI SONS"
    },
    {
      "NUM_FINANCIER_CD": 8757,
      "TXT_FINANCIER_NAME": "MADAPPALLY SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8758,
      "TXT_FINANCIER_NAME": "INFRA LEASING AND FIN SERV LTD"
    },
    {
      "NUM_FINANCIER_CD": 8759,
      "TXT_FINANCIER_NAME": "PRIYDARSHNI NAGRI BIGAR SHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 7958,
      "TXT_FINANCIER_NAME": "THE BHAVASARA KSHATRIYA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7959,
      "TXT_FINANCIER_NAME": "THE BHAVASARA KSHATRIYA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7960,
      "TXT_FINANCIER_NAME": "THE BHUJ COMERCIAL CORPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7961,
      "TXT_FINANCIER_NAME": "THE BIJAPUR DIST.MAHILA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7962,
      "TXT_FINANCIER_NAME": "THE BURDWAN CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7963,
      "TXT_FINANCIER_NAME": "THE C D COOPERATED CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7964,
      "TXT_FINANCIER_NAME": "THE C K P CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7965,
      "TXT_FINANCIER_NAME": "THE C K P CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7966,
      "TXT_FINANCIER_NAME": "THE CALICUT CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7967,
      "TXT_FINANCIER_NAME": "THE CARDMAM MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7968,
      "TXT_FINANCIER_NAME": "THE CENTURY CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7969,
      "TXT_FINANCIER_NAME": "THE CHALAKUDYTOWN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7970,
      "TXT_FINANCIER_NAME": "THE CHANGARAMKULAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7971,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 7972,
      "TXT_FINANCIER_NAME": "DEORIA SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7973,
      "TXT_FINANCIER_NAME": "SAKLECHA MOTORS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 7974,
      "TXT_FINANCIER_NAME": "JHALAWAR NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7975,
      "TXT_FINANCIER_NAME": "THAI LAXMI FINANCIAL SERVICE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7976,
      "TXT_FINANCIER_NAME": "SHRI DASMASH FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 7977,
      "TXT_FINANCIER_NAME": "SOCIETY GENERAL GLOBAL SOLUTION CENTRE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 7978,
      "TXT_FINANCIER_NAME": "SAMPADA NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7979,
      "TXT_FINANCIER_NAME": "KASTURI NAGARI SAHAKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7980,
      "TXT_FINANCIER_NAME": "SHRI NEELAKANTESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7981,
      "TXT_FINANCIER_NAME": "CITI CORPS MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 7982,
      "TXT_FINANCIER_NAME": "NAV HIND MULTIPURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7983,
      "TXT_FINANCIER_NAME": "YOUTCH DEVLOPMENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 7984,
      "TXT_FINANCIER_NAME": "VASANTDADA NAGRI SAHKARI BANK.LTD"
    },
    {
      "NUM_FINANCIER_CD": 7985,
      "TXT_FINANCIER_NAME": "VASAVI CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7986,
      "TXT_FINANCIER_NAME": "VASUDHA CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 7987,
      "TXT_FINANCIER_NAME": "VASUDHA COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 7988,
      "TXT_FINANCIER_NAME": "VATAKARA TOWN CO-OPERATIVE URBAN SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 7989,
      "TXT_FINANCIER_NAME": "VAZHATHOPE SERVICE CO.OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 7990,
      "TXT_FINANCIER_NAME": "VEPAR VIKAS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7991,
      "TXT_FINANCIER_NAME": "Villupuram District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 7992,
      "TXT_FINANCIER_NAME": "VISHAL PURANDHAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 7993,
      "TXT_FINANCIER_NAME": "VISHWESHWAR CO OP BANK BARSHI"
    },
    {
      "NUM_FINANCIER_CD": 7994,
      "TXT_FINANCIER_NAME": "VSPCOOPBANK"
    },
    {
      "NUM_FINANCIER_CD": 7995,
      "TXT_FINANCIER_NAME": "VYAVASAYA SEVA SAHAKARA BANKNIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 7996,
      "TXT_FINANCIER_NAME": "WARDHA NAGRIK SAHARAKI ADHIKOSH BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 7997,
      "TXT_FINANCIER_NAME": "WEST BENGAL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 7998,
      "TXT_FINANCIER_NAME": "WEST BENGAL STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 7999,
      "TXT_FINANCIER_NAME": "ZILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8000,
      "TXT_FINANCIER_NAME": "ZORASTRIAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8001,
      "TXT_FINANCIER_NAME": "ZOROASTRIAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8002,
      "TXT_FINANCIER_NAME": "MAHILA URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8003,
      "TXT_FINANCIER_NAME": "Raje Shivaji Gramin Bigarsheti Sahakari Patsanstha"
    },
    {
      "NUM_FINANCIER_CD": 8004,
      "TXT_FINANCIER_NAME": "HINJEWADI NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8005,
      "TXT_FINANCIER_NAME": "SHIVAJIRAO PATIL NILANGEKER URBEN CO OPP BANK LTD NILANGA"
    },
    {
      "NUM_FINANCIER_CD": 8006,
      "TXT_FINANCIER_NAME": "THE KANGRA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8007,
      "TXT_FINANCIER_NAME": "THE PUNNAYOORKULAM SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8008,
      "TXT_FINANCIER_NAME": "ROBERT BOSCH INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8009,
      "TXT_FINANCIER_NAME": "VAIJAYANTHI ANCHALIA FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 8010,
      "TXT_FINANCIER_NAME": "SARVODAY COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8011,
      "TXT_FINANCIER_NAME": "A P C P D C L"
    },
    {
      "NUM_FINANCIER_CD": 8012,
      "TXT_FINANCIER_NAME": "SHREE PRAGATI PATTAN SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 8013,
      "TXT_FINANCIER_NAME": "PATNA SAHAKER BANK"
    },
    {
      "NUM_FINANCIER_CD": 8014,
      "TXT_FINANCIER_NAME": "THE NUTAN NAGRIK CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 8015,
      "TXT_FINANCIER_NAME": "THE KOVVUR CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8016,
      "TXT_FINANCIER_NAME": "THE KOVVUR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8017,
      "TXT_FINANCIER_NAME": "MANDVI KINARA GRAMIN BIGARSHETI SAH PATSANSTHA MYTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 8018,
      "TXT_FINANCIER_NAME": "RAJASHREE SHAHU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8019,
      "TXT_FINANCIER_NAME": "THE MAHILA NAGRIC CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8020,
      "TXT_FINANCIER_NAME": "MAHARASTRA GODAWARI GARMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8021,
      "TXT_FINANCIER_NAME": "SHREE ADINATH CO OP SAVING AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8022,
      "TXT_FINANCIER_NAME": "D SANTHOSH KAWAR"
    },
    {
      "NUM_FINANCIER_CD": 8023,
      "TXT_FINANCIER_NAME": "BNP PARBAS"
    },
    {
      "NUM_FINANCIER_CD": 8024,
      "TXT_FINANCIER_NAME": "B DHARAM CHAND BOKDIA"
    },
    {
      "NUM_FINANCIER_CD": 8025,
      "TXT_FINANCIER_NAME": "SHRI HARI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8026,
      "TXT_FINANCIER_NAME": "SELVAMURUGAN"
    },
    {
      "NUM_FINANCIER_CD": 8027,
      "TXT_FINANCIER_NAME": "JAWALI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8028,
      "TXT_FINANCIER_NAME": "SELVAMURUGAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8029,
      "TXT_FINANCIER_NAME": "VIJALPORE VIBHAG SHEVA SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 8030,
      "TXT_FINANCIER_NAME": "ADARSH GRAMIN BIGAR SHETI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8031,
      "TXT_FINANCIER_NAME": "VIJETA NAGARI SAH PATH SANSTHA MARY SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 8032,
      "TXT_FINANCIER_NAME": "SAMPADA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8033,
      "TXT_FINANCIER_NAME": "VIJETA NAGARI SAHAKARI PATH SANSTHA LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 8034,
      "TXT_FINANCIER_NAME": "SARV UTTERPRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8035,
      "TXT_FINANCIER_NAME": "PATHANAPURAM PRIMARY AGRI AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 8036,
      "TXT_FINANCIER_NAME": "DEVENDRA SARDA NAGRI SAHAKARI PATSANTHA TRIMBAKESHWAR"
    },
    {
      "NUM_FINANCIER_CD": 8037,
      "TXT_FINANCIER_NAME": "GUNAWAND CHAND KHARIWAL SOWCARPET"
    },
    {
      "NUM_FINANCIER_CD": 8038,
      "TXT_FINANCIER_NAME": "BHANDARA MAHILA NAG PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8039,
      "TXT_FINANCIER_NAME": "SAMARPANA VIVIDHODDESHALA SEVA SAHAKARI SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 8040,
      "TXT_FINANCIER_NAME": "S AGARWAL AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 8041,
      "TXT_FINANCIER_NAME": "GURUKRUPA URBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8042,
      "TXT_FINANCIER_NAME": "B MAHENDRAKUMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8043,
      "TXT_FINANCIER_NAME": "B MAHENDRA KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 8044,
      "TXT_FINANCIER_NAME": "SHRI SAI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8045,
      "TXT_FINANCIER_NAME": "DYSTAR INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8046,
      "TXT_FINANCIER_NAME": "THE ODE NAGRIK CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8047,
      "TXT_FINANCIER_NAME": "BIHAR AWAMI COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8048,
      "TXT_FINANCIER_NAME": "SRI KANNIKA PARAMESHWARI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8049,
      "TXT_FINANCIER_NAME": "UDUPI HOUSE BUILDING COOP SOCIETY LYD"
    },
    {
      "NUM_FINANCIER_CD": 8050,
      "TXT_FINANCIER_NAME": "MADAYI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8051,
      "TXT_FINANCIER_NAME": "SRIRAMA TRANSPORT FINANCE COMPANY LTD TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 8052,
      "TXT_FINANCIER_NAME": "THE VENGAD SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8053,
      "TXT_FINANCIER_NAME": "JAI GANESH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8054,
      "TXT_FINANCIER_NAME": "SHREENATHJI MAHAPRABHU"
    },
    {
      "NUM_FINANCIER_CD": 8055,
      "TXT_FINANCIER_NAME": "DHANVANTARI GBSSAHAKARI PATPEDHI LTD SHAHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 8056,
      "TXT_FINANCIER_NAME": "YANTRANIRMAN NAG SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8057,
      "TXT_FINANCIER_NAME": "NAVDURGA NAGRI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8058,
      "TXT_FINANCIER_NAME": "M D PAWAR PEOPLES COOP BANK LTD URUN ISLAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 8059,
      "TXT_FINANCIER_NAME": "M P RAJYA SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8060,
      "TXT_FINANCIER_NAME": "M S CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8061,
      "TXT_FINANCIER_NAME": "M.P. CO-OP. APEX BANK"
    },
    {
      "NUM_FINANCIER_CD": 8062,
      "TXT_FINANCIER_NAME": "M.P. RAJYA SAHAKARI BANK MARYADIT (APEX BANK)"
    },
    {
      "NUM_FINANCIER_CD": 8063,
      "TXT_FINANCIER_NAME": "M.S.CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8064,
      "TXT_FINANCIER_NAME": "MAAN SHARDA MAHILA NAGRIK SAHKARI BANK MYDT. MAIHAR"
    },
    {
      "NUM_FINANCIER_CD": 8065,
      "TXT_FINANCIER_NAME": "MADHUPURA MERC.CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8066,
      "TXT_FINANCIER_NAME": "MAHARASHTRA BRAHMAN SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8067,
      "TXT_FINANCIER_NAME": "MAHAVIR URBAN CO -OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8068,
      "TXT_FINANCIER_NAME": "MAHILA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8069,
      "TXT_FINANCIER_NAME": "PREMCHAND KOTHARI AUTOMOBILE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8070,
      "TXT_FINANCIER_NAME": "SAIL RMD BOLANI ORES MINES"
    },
    {
      "NUM_FINANCIER_CD": 8071,
      "TXT_FINANCIER_NAME": "PALAMOOR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8072,
      "TXT_FINANCIER_NAME": "KASARAGOD PRIMARY CO OP AGRICULTURAL AND RURAL DEVOLOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 8073,
      "TXT_FINANCIER_NAME": "SHREE RAM CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8074,
      "TXT_FINANCIER_NAME": "S K GOLDSMITHS INDUSTRIAL CO OP SOCIETY LTD PERDOOR"
    },
    {
      "NUM_FINANCIER_CD": 8075,
      "TXT_FINANCIER_NAME": "SAHAKARI VYAVASAYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8076,
      "TXT_FINANCIER_NAME": "THE MEHSANA DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8077,
      "TXT_FINANCIER_NAME": "REDDY CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8078,
      "TXT_FINANCIER_NAME": "SANJAY CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8079,
      "TXT_FINANCIER_NAME": "HARYANA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8080,
      "TXT_FINANCIER_NAME": "CHOWDESHWARI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8081,
      "TXT_FINANCIER_NAME": "SANTAJI MAHARAJ NAGRI PATASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8082,
      "TXT_FINANCIER_NAME": "THE ASODAR PRADESH CO OP CREDIT CONSUMER SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8083,
      "TXT_FINANCIER_NAME": "THE CHAVASSERY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8084,
      "TXT_FINANCIER_NAME": "THE CHELANNUR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8085,
      "TXT_FINANCIER_NAME": "THE CHERANELLOOR SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8086,
      "TXT_FINANCIER_NAME": "The Chikmagalur District Co Operative Central Bank"
    },
    {
      "NUM_FINANCIER_CD": 8087,
      "TXT_FINANCIER_NAME": "The Chikmagalur District Co Operative Central Bank LTD"
    },
    {
      "NUM_FINANCIER_CD": 8088,
      "TXT_FINANCIER_NAME": "THE CHIRAKKAL SERVICE CO-OPERATIVE BANK LIMITED,CHIRAKKAL"
    },
    {
      "NUM_FINANCIER_CD": 8089,
      "TXT_FINANCIER_NAME": "THE CHOPDA PEOPLES CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8090,
      "TXT_FINANCIER_NAME": "THE CHOPDA PEPOLES CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8091,
      "TXT_FINANCIER_NAME": "THE CIIZENS URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8092,
      "TXT_FINANCIER_NAME": "THE CITI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8093,
      "TXT_FINANCIER_NAME": "THE CO.OPERAITVE BANK OF MAHESANA LTD."
    },
    {
      "NUM_FINANCIER_CD": 8094,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8095,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8096,
      "TXT_FINANCIER_NAME": "The Comptollers Office Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8097,
      "TXT_FINANCIER_NAME": "The Damavas group seva sahkari bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 8098,
      "TXT_FINANCIER_NAME": "THE DHULIA DISTRICT CENTRAL CO-OP.BANK LTD,DHULIA"
    },
    {
      "NUM_FINANCIER_CD": 8099,
      "TXT_FINANCIER_NAME": "THE DHULIA DISTRICT CENTRAL CO-OP.BANK LTD.,DHULIA"
    },
    {
      "NUM_FINANCIER_CD": 8100,
      "TXT_FINANCIER_NAME": "THE DT CO OPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8101,
      "TXT_FINANCIER_NAME": "SAPHALA PARISHAD BIGER SHETY"
    },
    {
      "NUM_FINANCIER_CD": 8102,
      "TXT_FINANCIER_NAME": "JAIMUNI SAHAKARI PATHPETI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8103,
      "TXT_FINANCIER_NAME": "H P SC/ST DEV CORP"
    },
    {
      "NUM_FINANCIER_CD": 8104,
      "TXT_FINANCIER_NAME": "SWAPNASAKAR GRAMIN BEEGAR SETHI SAHAKARI PATHPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8105,
      "TXT_FINANCIER_NAME": "SRI LAXMI NARASIMA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8106,
      "TXT_FINANCIER_NAME": "SHREE SAHASTRARJUN CO OP CRIDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8107,
      "TXT_FINANCIER_NAME": "RAM KRISHNA ARUN KUMAR SARAF"
    },
    {
      "NUM_FINANCIER_CD": 8108,
      "TXT_FINANCIER_NAME": "FRIENDS AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8109,
      "TXT_FINANCIER_NAME": "THE AGRI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8110,
      "TXT_FINANCIER_NAME": "CABOT INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8111,
      "TXT_FINANCIER_NAME": "CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8112,
      "TXT_FINANCIER_NAME": "BANK MUSCAT S A O G"
    },
    {
      "NUM_FINANCIER_CD": 8113,
      "TXT_FINANCIER_NAME": "BANQUE NATIONALE DE PARIS"
    },
    {
      "NUM_FINANCIER_CD": 8114,
      "TXT_FINANCIER_NAME": "HONGKONG SHANGHAI BKG.CORP."
    },
    {
      "NUM_FINANCIER_CD": 8115,
      "TXT_FINANCIER_NAME": "HSBC BANK"
    },
    {
      "NUM_FINANCIER_CD": 8116,
      "TXT_FINANCIER_NAME": "THE BANK OF TOKYO-MITSUBISHI LTD."
    },
    {
      "NUM_FINANCIER_CD": 8117,
      "TXT_FINANCIER_NAME": "TOKOYO MITSUBISHI BANK"
    },
    {
      "NUM_FINANCIER_CD": 8118,
      "TXT_FINANCIER_NAME": "AGROHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8119,
      "TXT_FINANCIER_NAME": "AMCO BANK"
    },
    {
      "NUM_FINANCIER_CD": 8120,
      "TXT_FINANCIER_NAME": "AMCO BANK."
    },
    {
      "NUM_FINANCIER_CD": 8121,
      "TXT_FINANCIER_NAME": "ASSOCIATES (INDIA) FINANCIAL SERVICES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8122,
      "TXT_FINANCIER_NAME": "SHREEJI SHROFF"
    },
    {
      "NUM_FINANCIER_CD": 8123,
      "TXT_FINANCIER_NAME": "BABA HARI SINGH EDUCATIONAL AND VOCATIONAL SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8124,
      "TXT_FINANCIER_NAME": "HARTHI CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8125,
      "TXT_FINANCIER_NAME": "SHUBHASHREE ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 8126,
      "TXT_FINANCIER_NAME": "DATATEC METHODEX PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8127,
      "TXT_FINANCIER_NAME": "MAHATMA GANDHI NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8128,
      "TXT_FINANCIER_NAME": "THE ORIENTAL INSURANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8129,
      "TXT_FINANCIER_NAME": "PRIYDARSHANI NAGRI BIGAR SHETI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8130,
      "TXT_FINANCIER_NAME": "I R E D A LTD"
    },
    {
      "NUM_FINANCIER_CD": 8131,
      "TXT_FINANCIER_NAME": "THE LOLIEM URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8132,
      "TXT_FINANCIER_NAME": "THE BHIKHIWIND PRIMERY COOP DEVOLPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8133,
      "TXT_FINANCIER_NAME": "THE KOTTAYAM AGRICULTURAL AND RURAL DEVELOPEMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8134,
      "TXT_FINANCIER_NAME": "NHDC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8135,
      "TXT_FINANCIER_NAME": "RAJKUMAR BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 8136,
      "TXT_FINANCIER_NAME": "GUTTHIGEDARARA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8137,
      "TXT_FINANCIER_NAME": "SATLUJ JAL VIDYUT NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 8138,
      "TXT_FINANCIER_NAME": "THE CHERTHALA SOUTH SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8139,
      "TXT_FINANCIER_NAME": "ANIJAVAR GROUP MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8140,
      "TXT_FINANCIER_NAME": "SHRI VITTHAL RUKMINI GRAH BIGAR SHETI SAH PATH SANSTHAMAR"
    },
    {
      "NUM_FINANCIER_CD": 8141,
      "TXT_FINANCIER_NAME": "THE MUVATTUPUZHA KOTHAMANGALAM PRIMARY CO OPERATIVE AGRI AND RURAL DVPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8142,
      "TXT_FINANCIER_NAME": "THE CHIEF PERSONAL MANAGER"
    },
    {
      "NUM_FINANCIER_CD": 8143,
      "TXT_FINANCIER_NAME": "AAR KAY FINLEASE PVT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8144,
      "TXT_FINANCIER_NAME": "DEVKI LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8145,
      "TXT_FINANCIER_NAME": "SUNITHA KANWAR JAIN"
    },
    {
      "NUM_FINANCIER_CD": 8146,
      "TXT_FINANCIER_NAME": "DEVKI LEASING AND FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 8147,
      "TXT_FINANCIER_NAME": "DEVKI LEASING AND FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 8148,
      "TXT_FINANCIER_NAME": "KOTHARI AND CO"
    },
    {
      "NUM_FINANCIER_CD": 8149,
      "TXT_FINANCIER_NAME": "KULSWAMINI"
    },
    {
      "NUM_FINANCIER_CD": 8150,
      "TXT_FINANCIER_NAME": "THE MUKATSAR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8151,
      "TXT_FINANCIER_NAME": "SHAILJA GHADGE NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8152,
      "TXT_FINANCIER_NAME": "SRISHARADA CREDIT CO OPERATIVE SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 8153,
      "TXT_FINANCIER_NAME": "RHINO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 8154,
      "TXT_FINANCIER_NAME": "PALANI MURUGAN NITHIYAGAM"
    },
    {
      "NUM_FINANCIER_CD": 8155,
      "TXT_FINANCIER_NAME": "S K KATYAL FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8156,
      "TXT_FINANCIER_NAME": "CANTEEN STORES DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 8157,
      "TXT_FINANCIER_NAME": "MOTOR INSURANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8158,
      "TXT_FINANCIER_NAME": "MARATHA CHAMBERS OF COMMERCE INDUSTRIES AND AGRICULTURE"
    },
    {
      "NUM_FINANCIER_CD": 8159,
      "TXT_FINANCIER_NAME": "MAHANAGAR SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8160,
      "TXT_FINANCIER_NAME": "JANSHAKTI NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8161,
      "TXT_FINANCIER_NAME": "KAVERI KALPATHARU GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8162,
      "TXT_FINANCIER_NAME": "JANTA URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8163,
      "TXT_FINANCIER_NAME": "KODAGU DCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8164,
      "TXT_FINANCIER_NAME": "REGISTRAR ANNA UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 8165,
      "TXT_FINANCIER_NAME": "THE ROPAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8166,
      "TXT_FINANCIER_NAME": "TO CMD EIL"
    },
    {
      "NUM_FINANCIER_CD": 8167,
      "TXT_FINANCIER_NAME": "CMD EIL"
    },
    {
      "NUM_FINANCIER_CD": 8168,
      "TXT_FINANCIER_NAME": "ESWAR AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8169,
      "TXT_FINANCIER_NAME": "RASHTRA SANT TUKDOJI URBAN CREDIT CO SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8170,
      "TXT_FINANCIER_NAME": "CHAIRMAN AIRPORT AUTHORITIES OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 8171,
      "TXT_FINANCIER_NAME": "SHIVNATH GRAMIN BIGARSHETI SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8172,
      "TXT_FINANCIER_NAME": "KALBADEVI POST OFFICE SAVING BANK"
    },
    {
      "NUM_FINANCIER_CD": 8173,
      "TXT_FINANCIER_NAME": "MAINPURI JILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8174,
      "TXT_FINANCIER_NAME": "MAKARPURA INDL.EST.CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8175,
      "TXT_FINANCIER_NAME": "MALAYALAMANORAMA CO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 8176,
      "TXT_FINANCIER_NAME": "malwa commercial co-operative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 8177,
      "TXT_FINANCIER_NAME": "MANEKCHOWK CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8178,
      "TXT_FINANCIER_NAME": "MARAMPALLY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8179,
      "TXT_FINANCIER_NAME": "MATTANNUR CO-OP RURAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8180,
      "TXT_FINANCIER_NAME": "MUKKAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8181,
      "TXT_FINANCIER_NAME": "Murshidabad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8182,
      "TXT_FINANCIER_NAME": "NADAPURAM SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8183,
      "TXT_FINANCIER_NAME": "NAGAR URBAN CO-OP BANK LTD.,A.NAGAR"
    },
    {
      "NUM_FINANCIER_CD": 8184,
      "TXT_FINANCIER_NAME": "NAGPUR MAHILA NAG. SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8185,
      "TXT_FINANCIER_NAME": "NAGRIK CO.OP.CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8186,
      "TXT_FINANCIER_NAME": "NAGRIK SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8187,
      "TXT_FINANCIER_NAME": "NAVHIND MULTI PURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8188,
      "TXT_FINANCIER_NAME": "BARODA BHARUCH GRAMYA VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 8189,
      "TXT_FINANCIER_NAME": "KURLA NAGRIC SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8190,
      "TXT_FINANCIER_NAME": "TSTPS"
    },
    {
      "NUM_FINANCIER_CD": 8191,
      "TXT_FINANCIER_NAME": "ANMOL ZIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8192,
      "TXT_FINANCIER_NAME": "MAHATMA FULE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8193,
      "TXT_FINANCIER_NAME": "NYAYAMITHRA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8194,
      "TXT_FINANCIER_NAME": "SRI MANJUNATH CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8195,
      "TXT_FINANCIER_NAME": "MAGAMA FINCROP LTD"
    },
    {
      "NUM_FINANCIER_CD": 8196,
      "TXT_FINANCIER_NAME": "SHANTHI DEVI FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 8197,
      "TXT_FINANCIER_NAME": "A G O E CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8198,
      "TXT_FINANCIER_NAME": "THE EDAMULACKAL SERVICE CO-OPERATIVE BANK LTD.NO.2047"
    },
    {
      "NUM_FINANCIER_CD": 8199,
      "TXT_FINANCIER_NAME": "THE EDNAD KANNUR SERVICE COOPBANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8200,
      "TXT_FINANCIER_NAME": "The Fazilka Central Co operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8201,
      "TXT_FINANCIER_NAME": "THE FIANANCIAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8202,
      "TXT_FINANCIER_NAME": "THE GANDEVI PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8203,
      "TXT_FINANCIER_NAME": "THE GANDHIDHAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8204,
      "TXT_FINANCIER_NAME": "THE GHOTI MERCHANTS CO- OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8205,
      "TXT_FINANCIER_NAME": "THE GOA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8206,
      "TXT_FINANCIER_NAME": "THE GOREGAON CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8207,
      "TXT_FINANCIER_NAME": "THE HANSOT NAGRIC SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8208,
      "TXT_FINANCIER_NAME": "THE HASTI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8209,
      "TXT_FINANCIER_NAME": "THE HINDU COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8210,
      "TXT_FINANCIER_NAME": "THE HONNAAR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8211,
      "TXT_FINANCIER_NAME": "THE HOOGHLY CO OPERATIVE CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8212,
      "TXT_FINANCIER_NAME": "THE HUBLI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8213,
      "TXT_FINANCIER_NAME": "ORDANCE FACTORY AMBARNATH"
    },
    {
      "NUM_FINANCIER_CD": 8214,
      "TXT_FINANCIER_NAME": "SUSHEEL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 8215,
      "TXT_FINANCIER_NAME": "SHIV KIRPA MOTOR AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 8216,
      "TXT_FINANCIER_NAME": "SANGRAM NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8217,
      "TXT_FINANCIER_NAME": "THE SANJIVANI GRAMIN BGR SHETI SHR PAT STA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8218,
      "TXT_FINANCIER_NAME": "INDIAN VETERNERY RESEARCH INSTITUTE"
    },
    {
      "NUM_FINANCIER_CD": 8219,
      "TXT_FINANCIER_NAME": "INDUR PARASPAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8220,
      "TXT_FINANCIER_NAME": "SRIMAHARSHI VALMEEKI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8221,
      "TXT_FINANCIER_NAME": "YTL DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8222,
      "TXT_FINANCIER_NAME": "ARJUN TALUKA SAHAKARI PATH SANSTHA MARY OSMANABAD"
    },
    {
      "NUM_FINANCIER_CD": 8223,
      "TXT_FINANCIER_NAME": "THE RAICHUR DIST CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8224,
      "TXT_FINANCIER_NAME": "SHREENIDHI SOUHARDA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 8225,
      "TXT_FINANCIER_NAME": "THE MANNARKKAD RURAL SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8226,
      "TXT_FINANCIER_NAME": "BAGMAR CARE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8227,
      "TXT_FINANCIER_NAME": "GREEN LAND FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8228,
      "TXT_FINANCIER_NAME": "TUMKUR TALUK INDUSTRIAL CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8229,
      "TXT_FINANCIER_NAME": "KAI JAISING APPA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8230,
      "TXT_FINANCIER_NAME": "KULABA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8231,
      "TXT_FINANCIER_NAME": "L I C EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8232,
      "TXT_FINANCIER_NAME": "S S U C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8233,
      "TXT_FINANCIER_NAME": "THE UDGIR"
    },
    {
      "NUM_FINANCIER_CD": 8234,
      "TXT_FINANCIER_NAME": "BHAGYODAY BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8235,
      "TXT_FINANCIER_NAME": "CELEBRITY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8236,
      "TXT_FINANCIER_NAME": "CHOHUNG BANK"
    },
    {
      "NUM_FINANCIER_CD": 8237,
      "TXT_FINANCIER_NAME": "CHLAMANDLA DBS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8238,
      "TXT_FINANCIER_NAME": "CITI CROP MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8239,
      "TXT_FINANCIER_NAME": "DEEPIKA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8240,
      "TXT_FINANCIER_NAME": "FEDARAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 8241,
      "TXT_FINANCIER_NAME": "FINANCE HOUSE (INDIA) PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8242,
      "TXT_FINANCIER_NAME": "FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8243,
      "TXT_FINANCIER_NAME": "FINANCIERS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8244,
      "TXT_FINANCIER_NAME": "G.E.B"
    },
    {
      "NUM_FINANCIER_CD": 8245,
      "TXT_FINANCIER_NAME": "THE DADASAHEB CO OP BANK LTD PIMPALNER"
    },
    {
      "NUM_FINANCIER_CD": 8246,
      "TXT_FINANCIER_NAME": "SATARA JHILHA GRAMSEVAK SAHAKARI SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8247,
      "TXT_FINANCIER_NAME": "A P P GENCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 8248,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN MAHARAJ URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8249,
      "TXT_FINANCIER_NAME": "CHETAK NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8250,
      "TXT_FINANCIER_NAME": "THRISSUR AUTO HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 8251,
      "TXT_FINANCIER_NAME": "THE PALAKKAD COOPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8252,
      "TXT_FINANCIER_NAME": "LAXMI URBEN CO OPP BANK LTD LATUR"
    },
    {
      "NUM_FINANCIER_CD": 8253,
      "TXT_FINANCIER_NAME": "MAHATMA BASWESHWER NAGARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8254,
      "TXT_FINANCIER_NAME": "HINDUSTAN PAPER CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 8255,
      "TXT_FINANCIER_NAME": "SHRIMANT THORALE BAJIRAO PESHAVE PAT MARY"
    },
    {
      "NUM_FINANCIER_CD": 8256,
      "TXT_FINANCIER_NAME": "BHANECHAND NAHAR AND SONS AUTOMOBILES FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 8257,
      "TXT_FINANCIER_NAME": "DAUSA KENDRIYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8258,
      "TXT_FINANCIER_NAME": "BHARAT AUTOMOBILES"
    },
    {
      "NUM_FINANCIER_CD": 8259,
      "TXT_FINANCIER_NAME": "BESCL"
    },
    {
      "NUM_FINANCIER_CD": 8260,
      "TXT_FINANCIER_NAME": "NATIONAL REMOTE SENSING AGENCY"
    },
    {
      "NUM_FINANCIER_CD": 8261,
      "TXT_FINANCIER_NAME": "GIRNAI NAGARI SHAKARI PATH SANSTHA MARYADIT PACHORA"
    },
    {
      "NUM_FINANCIER_CD": 8262,
      "TXT_FINANCIER_NAME": "GOA STATE SC & OBC FIN DEVPT CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 8263,
      "TXT_FINANCIER_NAME": "GOVERNER OF HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 8264,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF ANDHRA PRADESH"
    },
    {
      "NUM_FINANCIER_CD": 8265,
      "TXT_FINANCIER_NAME": "GSFC LTD."
    },
    {
      "NUM_FINANCIER_CD": 8266,
      "TXT_FINANCIER_NAME": "HCB"
    },
    {
      "NUM_FINANCIER_CD": 8267,
      "TXT_FINANCIER_NAME": "IL & FS VENTURE CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8268,
      "TXT_FINANCIER_NAME": "INDIAN RENEWAL ENERGY DEVELOPMENT AGENCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8269,
      "TXT_FINANCIER_NAME": "IOC LTD.(M D)"
    },
    {
      "NUM_FINANCIER_CD": 8270,
      "TXT_FINANCIER_NAME": "kccb ltd"
    },
    {
      "NUM_FINANCIER_CD": 8271,
      "TXT_FINANCIER_NAME": "KTDFC LTD."
    },
    {
      "NUM_FINANCIER_CD": 8272,
      "TXT_FINANCIER_NAME": "LAKSHMIGENERAL"
    },
    {
      "NUM_FINANCIER_CD": 8273,
      "TXT_FINANCIER_NAME": "LIC OF INDIA NORTH BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 8274,
      "TXT_FINANCIER_NAME": "M S RAMESHCHAND PAVANCHAND"
    },
    {
      "NUM_FINANCIER_CD": 8275,
      "TXT_FINANCIER_NAME": "MANGANESE ORE (I) LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8276,
      "TXT_FINANCIER_NAME": "MANGENESE ORE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8277,
      "TXT_FINANCIER_NAME": "MATORI CHANDSHI VIVIDHYA KARYAKARI SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8278,
      "TXT_FINANCIER_NAME": "N M D C LTD"
    },
    {
      "NUM_FINANCIER_CD": 8279,
      "TXT_FINANCIER_NAME": "NAGARJUNA CREDITS AND CAPITALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 8280,
      "TXT_FINANCIER_NAME": "BELMAN VYAVASAYA SEVA SAHAKARI BANK NIYAMITH KEDINJE"
    },
    {
      "NUM_FINANCIER_CD": 8281,
      "TXT_FINANCIER_NAME": "kherva credit soc LTD"
    },
    {
      "NUM_FINANCIER_CD": 8282,
      "TXT_FINANCIER_NAME": "Hinjewadi nagari Sahakari Pathsanstha"
    },
    {
      "NUM_FINANCIER_CD": 8283,
      "TXT_FINANCIER_NAME": "Vyvasaya Seva Sahakara Sangha Niyamitha"
    },
    {
      "NUM_FINANCIER_CD": 8284,
      "TXT_FINANCIER_NAME": "PRAIYOGANA PRABHANDAK ANUJA NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 8285,
      "TXT_FINANCIER_NAME": "MOGHUL FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8286,
      "TXT_FINANCIER_NAME": "THE CHERUVARANAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8287,
      "TXT_FINANCIER_NAME": "BAJWA URBAN COOPBANK"
    },
    {
      "NUM_FINANCIER_CD": 8288,
      "TXT_FINANCIER_NAME": "NARMADA MALVA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8289,
      "TXT_FINANCIER_NAME": "SHREE LAXMI KRUPA URBAN CO OPERTIVE BANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 8290,
      "TXT_FINANCIER_NAME": "CHAINA TRUST COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 8291,
      "TXT_FINANCIER_NAME": "NAINITAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 8292,
      "TXT_FINANCIER_NAME": "NAMAKKAL CO-OPERATIVE URBAN BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 8293,
      "TXT_FINANCIER_NAME": "NARANAMMOOZHY SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8294,
      "TXT_FINANCIER_NAME": "NASHIK DIST. INDUSTRIAL & MERCENTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8295,
      "TXT_FINANCIER_NAME": "NASHIK JILHA MAHILA VIKAS SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8296,
      "TXT_FINANCIER_NAME": "NATIONAL CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8297,
      "TXT_FINANCIER_NAME": "NAVJIVAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8298,
      "TXT_FINANCIER_NAME": "NAVNIRMAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8299,
      "TXT_FINANCIER_NAME": "NEHARUNAGAR CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8300,
      "TXT_FINANCIER_NAME": "NEW URBANCO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8301,
      "TXT_FINANCIER_NAME": "NIDHI CO.OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8302,
      "TXT_FINANCIER_NAME": "NUTEN NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8303,
      "TXT_FINANCIER_NAME": "Panchkula District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8304,
      "TXT_FINANCIER_NAME": "Panchmahal District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8305,
      "TXT_FINANCIER_NAME": "PANDIT DEENDAYAL NAGRI SAHAKARI PAT S. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8306,
      "TXT_FINANCIER_NAME": "MAMSUKH FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 8307,
      "TXT_FINANCIER_NAME": "THE HASSAN DISTRICT CO OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8308,
      "TXT_FINANCIER_NAME": "BALIRAJA BIGAR SHETI SAHAKARI PATH SANSTHA BEGAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 8309,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER THERMAL DESIGNS KARNATAKA POWER CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 8310,
      "TXT_FINANCIER_NAME": "GOVT EMPLOYEES CO OPERATIVE BANK LTDGOVT EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8311,
      "TXT_FINANCIER_NAME": "LAING LAMPS LTD"
    },
    {
      "NUM_FINANCIER_CD": 8312,
      "TXT_FINANCIER_NAME": "R E D"
    },
    {
      "NUM_FINANCIER_CD": 8313,
      "TXT_FINANCIER_NAME": "RVR INVEST"
    },
    {
      "NUM_FINANCIER_CD": 8314,
      "TXT_FINANCIER_NAME": "CREDIT ANALYSIS AND RESEARCH LTD"
    },
    {
      "NUM_FINANCIER_CD": 8315,
      "TXT_FINANCIER_NAME": "PETERONET LNG LTD"
    },
    {
      "NUM_FINANCIER_CD": 8316,
      "TXT_FINANCIER_NAME": "LT ASHOKRAO BANKAR NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8317,
      "TXT_FINANCIER_NAME": "A G I F"
    },
    {
      "NUM_FINANCIER_CD": 8318,
      "TXT_FINANCIER_NAME": "KANCHAN CREDIT SAUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8319,
      "TXT_FINANCIER_NAME": "RAMAKRISHNA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 8320,
      "TXT_FINANCIER_NAME": "THE INCOME TAX DEPT. CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8321,
      "TXT_FINANCIER_NAME": "THE IRITTY CO-OPERATIVE RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8322,
      "TXT_FINANCIER_NAME": "THE JHAJJAR CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8323,
      "TXT_FINANCIER_NAME": "THE KAGAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8324,
      "TXT_FINANCIER_NAME": "THE KAKATIYA CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8325,
      "TXT_FINANCIER_NAME": "THE KALOL NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8326,
      "TXT_FINANCIER_NAME": "THE KALOL NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8327,
      "TXT_FINANCIER_NAME": "THE KANGRA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8328,
      "TXT_FINANCIER_NAME": "THE KANGRA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8329,
      "TXT_FINANCIER_NAME": "GOKARNATHA CO OPR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8330,
      "TXT_FINANCIER_NAME": "THE MOGA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8331,
      "TXT_FINANCIER_NAME": "THE SADA URBAN COOP CREDIT STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8332,
      "TXT_FINANCIER_NAME": "THE YASHODEV NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8333,
      "TXT_FINANCIER_NAME": "MS BHE EMP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8334,
      "TXT_FINANCIER_NAME": "SANCHIT INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 8335,
      "TXT_FINANCIER_NAME": "SUBHAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8336,
      "TXT_FINANCIER_NAME": "RAJESHWAR YUVAK VIKAS SAH BANK LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 8337,
      "TXT_FINANCIER_NAME": "CHAITANYA GOVADAVARI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8338,
      "TXT_FINANCIER_NAME": "CAHAKAN GRAMIN BIGAR SHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8339,
      "TXT_FINANCIER_NAME": "GANDHINGLAJ URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8340,
      "TXT_FINANCIER_NAME": "GE MONEY AUTO FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 8341,
      "TXT_FINANCIER_NAME": "GEB"
    },
    {
      "NUM_FINANCIER_CD": 8342,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF KERALA TREASURY SAVINGS BANK"
    },
    {
      "NUM_FINANCIER_CD": 8343,
      "TXT_FINANCIER_NAME": "GUJRAT COMMERCIAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8344,
      "TXT_FINANCIER_NAME": "HDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 8345,
      "TXT_FINANCIER_NAME": "IFCI LIMITED(FORMERLY THE INDUSTRIAL FINANCE CORPORATION OF INDIA LTD)"
    },
    {
      "NUM_FINANCIER_CD": 8346,
      "TXT_FINANCIER_NAME": "INFRO STRUCTURE & LEASING FIN. SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8347,
      "TXT_FINANCIER_NAME": "JINTUR BANK"
    },
    {
      "NUM_FINANCIER_CD": 8348,
      "TXT_FINANCIER_NAME": "KA BANK NONGKYNDONG RI KHASI JAINTIA"
    },
    {
      "NUM_FINANCIER_CD": 8349,
      "TXT_FINANCIER_NAME": "Kalpataru Hire Purchase & Leasing Pvt Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8350,
      "TXT_FINANCIER_NAME": "KNALANGZIAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8351,
      "TXT_FINANCIER_NAME": "YASHODIP SAHAKARI PATPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8352,
      "TXT_FINANCIER_NAME": "ADHAR GRAMIN BIGARSHETI SAHAKARI PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 8353,
      "TXT_FINANCIER_NAME": "THE SAHU CO OP URBAN T AND C SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8354,
      "TXT_FINANCIER_NAME": "SHRI RAM SATGUR FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 8355,
      "TXT_FINANCIER_NAME": "THE WOMEN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8356,
      "TXT_FINANCIER_NAME": "CHIPLUN NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8357,
      "TXT_FINANCIER_NAME": "ANKATESH NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8358,
      "TXT_FINANCIER_NAME": "G N F C LTD"
    },
    {
      "NUM_FINANCIER_CD": 8359,
      "TXT_FINANCIER_NAME": "TANDALWADI GRAMIN BIGAR SHETI SAH PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 8360,
      "TXT_FINANCIER_NAME": "KALLUR CHITS FINANCE PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8361,
      "TXT_FINANCIER_NAME": "J AND K STATE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8362,
      "TXT_FINANCIER_NAME": "SHRIMANT THORALE BAJIRAO PESHAVE NAGARI SAHAKARI PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8363,
      "TXT_FINANCIER_NAME": "K KK AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8364,
      "TXT_FINANCIER_NAME": "GUJRAT NARMADA FERTILIZER CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 8365,
      "TXT_FINANCIER_NAME": "VYANKATESH NAGARI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8366,
      "TXT_FINANCIER_NAME": "MS ORIENTAL INSC CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 8367,
      "TXT_FINANCIER_NAME": "THE KANNURTOWN SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8368,
      "TXT_FINANCIER_NAME": "PATHARDI VIVIDH KARYAKARI SAHAKARI VIKAS SEVA SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8369,
      "TXT_FINANCIER_NAME": "AP HOUSING BOARD"
    },
    {
      "NUM_FINANCIER_CD": 8370,
      "TXT_FINANCIER_NAME": "J K MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 8371,
      "TXT_FINANCIER_NAME": "HULGOL SEVA SAHAKARI SANGH NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 8372,
      "TXT_FINANCIER_NAME": "PEPSI FOODS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8373,
      "TXT_FINANCIER_NAME": "PGB SUNDLA"
    },
    {
      "NUM_FINANCIER_CD": 8374,
      "TXT_FINANCIER_NAME": "R RAKESHKUMAR DOSHI"
    },
    {
      "NUM_FINANCIER_CD": 8375,
      "TXT_FINANCIER_NAME": "RAJIV GANDHI NAGRI S. P. S. LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 8376,
      "TXT_FINANCIER_NAME": "RANBAXY LABORATORIES LTD., DEWAS"
    },
    {
      "NUM_FINANCIER_CD": 8377,
      "TXT_FINANCIER_NAME": "RASECC (10015)"
    },
    {
      "NUM_FINANCIER_CD": 8378,
      "TXT_FINANCIER_NAME": "sbppc bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 8379,
      "TXT_FINANCIER_NAME": "TATTVALOKA"
    },
    {
      "NUM_FINANCIER_CD": 8380,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES FIN. SERVICES P.LTD.,PUNE"
    },
    {
      "NUM_FINANCIER_CD": 8381,
      "TXT_FINANCIER_NAME": "THE INSTALMENT SUPPLY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8382,
      "TXT_FINANCIER_NAME": "United India Assurance Company Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8383,
      "TXT_FINANCIER_NAME": "URMILA INVESTMENT CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 8384,
      "TXT_FINANCIER_NAME": "BANK OF PUNJAB"
    },
    {
      "NUM_FINANCIER_CD": 8385,
      "TXT_FINANCIER_NAME": "BANK OF SAURASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 8386,
      "TXT_FINANCIER_NAME": "CITY MOTOR FIN-INVEST PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8387,
      "TXT_FINANCIER_NAME": "THE KARUR VYASA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8388,
      "TXT_FINANCIER_NAME": "MUTHOOR VEHICLE AND ASSET FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8389,
      "TXT_FINANCIER_NAME": "PASCHIM GUJRAT VEEJ COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8390,
      "TXT_FINANCIER_NAME": "FEMILY CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8391,
      "TXT_FINANCIER_NAME": "LOKNETE DATTAJI PATIL SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8392,
      "TXT_FINANCIER_NAME": "V S S B N MUTHUR"
    },
    {
      "NUM_FINANCIER_CD": 8393,
      "TXT_FINANCIER_NAME": "CIDCO NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8394,
      "TXT_FINANCIER_NAME": "THE GRAMEENA VYAVASAYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8395,
      "TXT_FINANCIER_NAME": "THE LOLIEM"
    },
    {
      "NUM_FINANCIER_CD": 8396,
      "TXT_FINANCIER_NAME": "THE CO OPERATIVE CITY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8397,
      "TXT_FINANCIER_NAME": "RAJENDRA KUMAR JAIN"
    },
    {
      "NUM_FINANCIER_CD": 8398,
      "TXT_FINANCIER_NAME": "THIROOR SER COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8399,
      "TXT_FINANCIER_NAME": "JYOTHI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8400,
      "TXT_FINANCIER_NAME": "MAHARASHTRA VYAPARI MITRA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8401,
      "TXT_FINANCIER_NAME": "KARASSERY SER COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8402,
      "TXT_FINANCIER_NAME": "PAPPINISSERI CO-OP:RURAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8403,
      "TXT_FINANCIER_NAME": "PAREKH FINANCE CO."
    },
    {
      "NUM_FINANCIER_CD": 8404,
      "TXT_FINANCIER_NAME": "PATHANKOT HINDU CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8405,
      "TXT_FINANCIER_NAME": "PATNI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8406,
      "TXT_FINANCIER_NAME": "PAZHAYAKUNNINMEL SERVICE CO OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 8407,
      "TXT_FINANCIER_NAME": "PEOPLE S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8408,
      "TXT_FINANCIER_NAME": "PEOPLE`S CO-OPRATIVE BANK LTD.,DHOLKA"
    },
    {
      "NUM_FINANCIER_CD": 8409,
      "TXT_FINANCIER_NAME": "PEOPLES CO-POPERATIVE BANK LTD., HINGOLI"
    },
    {
      "NUM_FINANCIER_CD": 8410,
      "TXT_FINANCIER_NAME": "PEOPLES URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8411,
      "TXT_FINANCIER_NAME": "PERINTHALMANNA CO-OP.URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8412,
      "TXT_FINANCIER_NAME": "PEROORKADA SERVICE CO- OPERATIVE BANK LTD. NO.1412"
    },
    {
      "NUM_FINANCIER_CD": 8413,
      "TXT_FINANCIER_NAME": "PONANI CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8414,
      "TXT_FINANCIER_NAME": "Pondicherry Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8415,
      "TXT_FINANCIER_NAME": "POOTHADI SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8416,
      "TXT_FINANCIER_NAME": "PRIME CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8417,
      "TXT_FINANCIER_NAME": "PRIYADARSHNI MAHILA NAGARI SAHAKARI BANK LTD BEED"
    },
    {
      "NUM_FINANCIER_CD": 8418,
      "TXT_FINANCIER_NAME": "Pudukottai District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8419,
      "TXT_FINANCIER_NAME": "PUNNAYOORKULAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8420,
      "TXT_FINANCIER_NAME": "PUNYASHLOK AHILYADEVI HOLKAR NAGARI SAH.PAT.MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8421,
      "TXT_FINANCIER_NAME": "PURASAIWAKKAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8422,
      "TXT_FINANCIER_NAME": "MANOHAR CREDIT AND GROWTH FUND LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8423,
      "TXT_FINANCIER_NAME": "THE BATALA CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8424,
      "TXT_FINANCIER_NAME": "HIRE BAGEWADI URBAN CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8425,
      "TXT_FINANCIER_NAME": "DHANASHRI GRAHMIN BIGAR SHETI SAH PATH SANS MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8426,
      "TXT_FINANCIER_NAME": "KOYLANCHAL URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8427,
      "TXT_FINANCIER_NAME": "FEMILY CREDIT LT"
    },
    {
      "NUM_FINANCIER_CD": 8428,
      "TXT_FINANCIER_NAME": "WRIGLEY INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8429,
      "TXT_FINANCIER_NAME": "SHREE ADINATH CO OP SAV CR SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8430,
      "TXT_FINANCIER_NAME": "MEHTA P JAIN FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8431,
      "TXT_FINANCIER_NAME": "INFRASTRUSTURE LEASING AND SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8432,
      "TXT_FINANCIER_NAME": "LAKSHMI INVESTORS"
    },
    {
      "NUM_FINANCIER_CD": 8433,
      "TXT_FINANCIER_NAME": "SUNDARLALJI SAWJI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8434,
      "TXT_FINANCIER_NAME": "AGRICULTURAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8435,
      "TXT_FINANCIER_NAME": "THE KANGRA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8436,
      "TXT_FINANCIER_NAME": "THE KANGRA CO-OPREATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8437,
      "TXT_FINANCIER_NAME": "THE KAPOL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8438,
      "TXT_FINANCIER_NAME": "THE KAPOL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8439,
      "TXT_FINANCIER_NAME": "THE KAPOL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8440,
      "TXT_FINANCIER_NAME": "THE KARARINAKAM SERVICE CO.OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8441,
      "TXT_FINANCIER_NAME": "THE KARIKODE SERVICE CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8442,
      "TXT_FINANCIER_NAME": "THE KARKALA CO OP SERVICE BANK LTD KARKALA"
    },
    {
      "NUM_FINANCIER_CD": 8443,
      "TXT_FINANCIER_NAME": "THE KARNAVATI CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8444,
      "TXT_FINANCIER_NAME": "THE KASARAGOD DIST.CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8445,
      "TXT_FINANCIER_NAME": "THE KASARAGOD DISTRICT CO-PO.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8446,
      "TXT_FINANCIER_NAME": "THE KEEZHMAD SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8447,
      "TXT_FINANCIER_NAME": "THE KHATTRI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8448,
      "TXT_FINANCIER_NAME": "THE KHEDBRAHMA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8449,
      "TXT_FINANCIER_NAME": "THE KODAGU DISTRICT COOP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8450,
      "TXT_FINANCIER_NAME": "GANPAT RAJ AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 8451,
      "TXT_FINANCIER_NAME": "PATTABHIRAMANAGARA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8452,
      "TXT_FINANCIER_NAME": "RAJA GOPAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8453,
      "TXT_FINANCIER_NAME": "PALA URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8454,
      "TXT_FINANCIER_NAME": "O N G C FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8455,
      "TXT_FINANCIER_NAME": "THE SOUTH CANARA DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8456,
      "TXT_FINANCIER_NAME": "DAIVADNYA COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8457,
      "TXT_FINANCIER_NAME": "SHREENATH CO CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8458,
      "TXT_FINANCIER_NAME": "TERDAL MINORITY COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8459,
      "TXT_FINANCIER_NAME": "KRISHNA AUTO"
    },
    {
      "NUM_FINANCIER_CD": 8460,
      "TXT_FINANCIER_NAME": "AU FINANCERS I PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8461,
      "TXT_FINANCIER_NAME": "THE HOOSEINI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8462,
      "TXT_FINANCIER_NAME": "COMPEER ASSOCIATETS"
    },
    {
      "NUM_FINANCIER_CD": 8463,
      "TXT_FINANCIER_NAME": "KPK FINANCE AND TRANSPORTS"
    },
    {
      "NUM_FINANCIER_CD": 8464,
      "TXT_FINANCIER_NAME": "THE MOWANCHERY CO OP RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8465,
      "TXT_FINANCIER_NAME": "SHRI SUVARN LAXMI COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8466,
      "TXT_FINANCIER_NAME": "DHANVARDHINI G B S SAHAKARI PATPEDHI SHAHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 8467,
      "TXT_FINANCIER_NAME": "UNIVERSITY OF AGRICULTURAL SCIENCE"
    },
    {
      "NUM_FINANCIER_CD": 8468,
      "TXT_FINANCIER_NAME": "LALWANI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 8469,
      "TXT_FINANCIER_NAME": "Land Bank"
    },
    {
      "NUM_FINANCIER_CD": 8470,
      "TXT_FINANCIER_NAME": "LARSAN AND TOUBRO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8471,
      "TXT_FINANCIER_NAME": "LARSON AND TURBO FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 8472,
      "TXT_FINANCIER_NAME": "LEASE PLAN IND LTD"
    },
    {
      "NUM_FINANCIER_CD": 8473,
      "TXT_FINANCIER_NAME": "LEASE PLAN OF INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 8474,
      "TXT_FINANCIER_NAME": "LESSOR- CITI CORP FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8475,
      "TXT_FINANCIER_NAME": "LESSOR L AND FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8476,
      "TXT_FINANCIER_NAME": "LORDKRISHNA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8477,
      "TXT_FINANCIER_NAME": "M C C BANK"
    },
    {
      "NUM_FINANCIER_CD": 8478,
      "TXT_FINANCIER_NAME": "M K G BANK."
    },
    {
      "NUM_FINANCIER_CD": 8479,
      "TXT_FINANCIER_NAME": "M.P.RAJAYA SHAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8480,
      "TXT_FINANCIER_NAME": "M/S G E CAPITAL TRANSPORTATION FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8481,
      "TXT_FINANCIER_NAME": "THE KACHCHH DISTRICT CENTRAL CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8482,
      "TXT_FINANCIER_NAME": "THE MALAYIDAMTHURUTH SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8483,
      "TXT_FINANCIER_NAME": "PRERNA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8484,
      "TXT_FINANCIER_NAME": "EUREKA FORBES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8485,
      "TXT_FINANCIER_NAME": "Birla Sun Life Insurance Co Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8486,
      "TXT_FINANCIER_NAME": "THE GARADUATE S CO OPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8487,
      "TXT_FINANCIER_NAME": "THE BASAVESHWAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8488,
      "TXT_FINANCIER_NAME": "BANAGANGA NAG SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8489,
      "TXT_FINANCIER_NAME": "SLR STEELS LTD"
    },
    {
      "NUM_FINANCIER_CD": 8490,
      "TXT_FINANCIER_NAME": "SREE SUBRAMANYESWARA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8491,
      "TXT_FINANCIER_NAME": "SHREE RAM SAMARTH NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8492,
      "TXT_FINANCIER_NAME": "ICHALKARAJI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8493,
      "TXT_FINANCIER_NAME": "OFFICE OF THE COMMISSIONER OF CUSTOMS"
    },
    {
      "NUM_FINANCIER_CD": 8494,
      "TXT_FINANCIER_NAME": "DHANALKSHMI"
    },
    {
      "NUM_FINANCIER_CD": 8495,
      "TXT_FINANCIER_NAME": "SRI DURGAPARMESHWARI CREDIT CO OP SOCIETY LTD PARKALA"
    },
    {
      "NUM_FINANCIER_CD": 8496,
      "TXT_FINANCIER_NAME": "NATIONAL INSTITUTE OF BUSINESS MANAGEMENT"
    },
    {
      "NUM_FINANCIER_CD": 8497,
      "TXT_FINANCIER_NAME": "THE SHAMARAO VITHAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8498,
      "TXT_FINANCIER_NAME": "RAVI FINANCIALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 8499,
      "TXT_FINANCIER_NAME": "SHRI SAI SAMATH NAGARI SAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8500,
      "TXT_FINANCIER_NAME": "MUNICIPAL CORPORATION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8501,
      "TXT_FINANCIER_NAME": "KUNNAMKULAM CORPORATION BANK"
    },
    {
      "NUM_FINANCIER_CD": 8502,
      "TXT_FINANCIER_NAME": "ILAHABAD BANK"
    },
    {
      "NUM_FINANCIER_CD": 8503,
      "TXT_FINANCIER_NAME": "INDIANBANK.."
    },
    {
      "NUM_FINANCIER_CD": 8504,
      "TXT_FINANCIER_NAME": "PANJAB AND SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 8505,
      "TXT_FINANCIER_NAME": "SYARIAD FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8506,
      "TXT_FINANCIER_NAME": "SHRI MURLI MANOHAR NAGARI SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8507,
      "TXT_FINANCIER_NAME": "SHREE KEDARESHWAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8508,
      "TXT_FINANCIER_NAME": "SAVATAMALI NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8509,
      "TXT_FINANCIER_NAME": "DNYADEEP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8510,
      "TXT_FINANCIER_NAME": "CHAITANYA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8511,
      "TXT_FINANCIER_NAME": "SWAMY VIVEKANANDA PATTINA SAHAKARA SANGA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 8512,
      "TXT_FINANCIER_NAME": "MAHESH INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 8513,
      "TXT_FINANCIER_NAME": "VITTAL GRAMEENA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 8514,
      "TXT_FINANCIER_NAME": "SHRI NAGALINGESHWAR URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8515,
      "TXT_FINANCIER_NAME": "SURU UP GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8516,
      "TXT_FINANCIER_NAME": "THE NASHIK MERCHANTS CO OP BANK LTD NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 8517,
      "TXT_FINANCIER_NAME": "RANI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8518,
      "TXT_FINANCIER_NAME": "SUNITHA JAIN"
    },
    {
      "NUM_FINANCIER_CD": 8519,
      "TXT_FINANCIER_NAME": "FOOD LUCK FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 8520,
      "TXT_FINANCIER_NAME": "THA KANARA DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8521,
      "TXT_FINANCIER_NAME": "PUZHAVATHU SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8522,
      "TXT_FINANCIER_NAME": "QUILANDY SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8523,
      "TXT_FINANCIER_NAME": "RAJAJINAGAR CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8524,
      "TXT_FINANCIER_NAME": "RAJASTHAN STATE INDL. CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8525,
      "TXT_FINANCIER_NAME": "RAJKOT MERCANTILE COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8526,
      "TXT_FINANCIER_NAME": "RAJSAMAND DISTT SAHKARI BHUMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 8527,
      "TXT_FINANCIER_NAME": "RAMKRISHANA URBAN CREDIT CO-OP SOCI.,"
    },
    {
      "NUM_FINANCIER_CD": 8528,
      "TXT_FINANCIER_NAME": "RASHTRA MATA JIJAU NAGRI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8529,
      "TXT_FINANCIER_NAME": "RUPI CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8530,
      "TXT_FINANCIER_NAME": "Sabarkantha District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 8531,
      "TXT_FINANCIER_NAME": "SABARMATI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8532,
      "TXT_FINANCIER_NAME": "SADGURU GAHININATH URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8533,
      "TXT_FINANCIER_NAME": "SADGURU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8534,
      "TXT_FINANCIER_NAME": "SURAMA MULTI TRADE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8535,
      "TXT_FINANCIER_NAME": "AP STATE HOUSING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 8536,
      "TXT_FINANCIER_NAME": "DNYANRAJ GRAMIN BIGAR SHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8537,
      "TXT_FINANCIER_NAME": "GOMATA GARMIN BIGER SHETHI SAHAKARI PATSHANTHA"
    },
    {
      "NUM_FINANCIER_CD": 8538,
      "TXT_FINANCIER_NAME": "MAHALAXMI NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8539,
      "TXT_FINANCIER_NAME": "DNYANPRABODHINI GRAMIN BIGAR SHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8540,
      "TXT_FINANCIER_NAME": "TERDAL MINORITY CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8541,
      "TXT_FINANCIER_NAME": "THE PATTANAKKAD SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8542,
      "TXT_FINANCIER_NAME": "BHARATH CR SOWHARDA SAHAKARI LTD UCHILA"
    },
    {
      "NUM_FINANCIER_CD": 8543,
      "TXT_FINANCIER_NAME": "CHHATRAPATI SAMBHAJI MAHARAJ PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8544,
      "TXT_FINANCIER_NAME": "SHREE SAINATH NAGRIK SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8545,
      "TXT_FINANCIER_NAME": "BDA HOUSING COLONY CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8546,
      "TXT_FINANCIER_NAME": "KIM INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8547,
      "TXT_FINANCIER_NAME": "MAGMA FIN CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 8548,
      "TXT_FINANCIER_NAME": "THE KOLAPUR COMMCRCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8549,
      "TXT_FINANCIER_NAME": "THE KOLLAM DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8550,
      "TXT_FINANCIER_NAME": "THE KONKAN PRANT SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8551,
      "TXT_FINANCIER_NAME": "THE KOTAYAM DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8552,
      "TXT_FINANCIER_NAME": "THE KOTTYAM DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8553,
      "TXT_FINANCIER_NAME": "THE KUKARWADA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8554,
      "TXT_FINANCIER_NAME": "THE KUKARWADA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8555,
      "TXT_FINANCIER_NAME": "THE KUMBLA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8556,
      "TXT_FINANCIER_NAME": "THE KUMBLA SERVICECO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8557,
      "TXT_FINANCIER_NAME": "THE LAXMI CO-OPERATIVE BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 8558,
      "TXT_FINANCIER_NAME": "THE LIC OF INDIA STAFF CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8559,
      "TXT_FINANCIER_NAME": "THE MADAI SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8560,
      "TXT_FINANCIER_NAME": "THE MADEENA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8561,
      "TXT_FINANCIER_NAME": "THE MAHENDERGARG CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8562,
      "TXT_FINANCIER_NAME": "THE MAHESANA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8563,
      "TXT_FINANCIER_NAME": "THE MALLESHWARAM CO -OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8564,
      "TXT_FINANCIER_NAME": "ANANTKRUPA GRAMIN BIGARSHETI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8565,
      "TXT_FINANCIER_NAME": "JANAHIT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8566,
      "TXT_FINANCIER_NAME": "SHRI MAHAVEER CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8567,
      "TXT_FINANCIER_NAME": "PETRONET LNG"
    },
    {
      "NUM_FINANCIER_CD": 8568,
      "TXT_FINANCIER_NAME": "K S C APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8569,
      "TXT_FINANCIER_NAME": "SHRI KHERVA COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8570,
      "TXT_FINANCIER_NAME": "MANOJ KUMAR ANCHALIA AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 8571,
      "TXT_FINANCIER_NAME": "DAUSA URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8572,
      "TXT_FINANCIER_NAME": "PARAVOOR COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8573,
      "TXT_FINANCIER_NAME": "BHARUCH DIST"
    },
    {
      "NUM_FINANCIER_CD": 8574,
      "TXT_FINANCIER_NAME": "THE UDGIR URBEN CO OPP BANK LTD UDGIR"
    },
    {
      "NUM_FINANCIER_CD": 8575,
      "TXT_FINANCIER_NAME": "M/S. CITICORP FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8576,
      "TXT_FINANCIER_NAME": "M/S.THE ASSOCIATED FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8577,
      "TXT_FINANCIER_NAME": "MAEMA SHRACHI"
    },
    {
      "NUM_FINANCIER_CD": 8578,
      "TXT_FINANCIER_NAME": "( MAGMA LEASING LTD PNB AND ALL OTHERS CONSORTIUM BANKS, BBSR)"
    },
    {
      "NUM_FINANCIER_CD": 8579,
      "TXT_FINANCIER_NAME": "MEGMA FINCORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 8580,
      "TXT_FINANCIER_NAME": "MAGMA SHARCHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8581,
      "TXT_FINANCIER_NAME": "magmagma shrachi finance ltd."
    },
    {
      "NUM_FINANCIER_CD": 8582,
      "TXT_FINANCIER_NAME": "MEGMA LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 8583,
      "TXT_FINANCIER_NAME": "MAGMfA SHRACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8584,
      "TXT_FINANCIER_NAME": "MAHESH BANK"
    },
    {
      "NUM_FINANCIER_CD": 8585,
      "TXT_FINANCIER_NAME": "MAHIDNRA AND MAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8586,
      "TXT_FINANCIER_NAME": "MAHINDRA MAHINDRA FINANCIAL SERVICE LTDM"
    },
    {
      "NUM_FINANCIER_CD": 8587,
      "TXT_FINANCIER_NAME": "KRANTI JYOTI SAVITRI FULE SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8588,
      "TXT_FINANCIER_NAME": "EXECUTIVE DIRECTOR NMDC"
    },
    {
      "NUM_FINANCIER_CD": 8589,
      "TXT_FINANCIER_NAME": "THE VARDHMAN NAGRIK CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8590,
      "TXT_FINANCIER_NAME": "SIDDARAMESHWARA CREDIT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8591,
      "TXT_FINANCIER_NAME": "LAWRENCE KRISHNA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8592,
      "TXT_FINANCIER_NAME": "TRIKAL LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8593,
      "TXT_FINANCIER_NAME": "TUMKUR TALUK INDUSTRIAL COOP SOCIETY PVT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8594,
      "TXT_FINANCIER_NAME": "SANCHIT ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 8595,
      "TXT_FINANCIER_NAME": "SHRI SANGAM NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8596,
      "TXT_FINANCIER_NAME": "ELLAQUI DEHATHI BANK"
    },
    {
      "NUM_FINANCIER_CD": 8597,
      "TXT_FINANCIER_NAME": "ST MILAGRES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8598,
      "TXT_FINANCIER_NAME": "BRAHMAVARA AGRICULTURAL SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8599,
      "TXT_FINANCIER_NAME": "PUNJAB AND SING BANK"
    },
    {
      "NUM_FINANCIER_CD": 8600,
      "TXT_FINANCIER_NAME": "PANJAP NATIONAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 8601,
      "TXT_FINANCIER_NAME": "PNB"
    },
    {
      "NUM_FINANCIER_CD": 8602,
      "TXT_FINANCIER_NAME": "S.B.I. A.D.B."
    },
    {
      "NUM_FINANCIER_CD": 8603,
      "TXT_FINANCIER_NAME": "SBI"
    },
    {
      "NUM_FINANCIER_CD": 8604,
      "TXT_FINANCIER_NAME": "S B I"
    },
    {
      "NUM_FINANCIER_CD": 8605,
      "TXT_FINANCIER_NAME": "SBI MAIN BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 8606,
      "TXT_FINANCIER_NAME": "SBI PBB"
    },
    {
      "NUM_FINANCIER_CD": 8607,
      "TXT_FINANCIER_NAME": "SBI ASHOK MARG LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 8608,
      "TXT_FINANCIER_NAME": "SBI CHANDER NAGAR LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 8609,
      "TXT_FINANCIER_NAME": "SBI NAYA NANGAL PUNJAB"
    },
    {
      "NUM_FINANCIER_CD": 8610,
      "TXT_FINANCIER_NAME": "SBI RASMECCC BHILAI"
    },
    {
      "NUM_FINANCIER_CD": 8611,
      "TXT_FINANCIER_NAME": "SBI SWARGASHRAM RISHIKESH"
    },
    {
      "NUM_FINANCIER_CD": 8612,
      "TXT_FINANCIER_NAME": "SBI TOHANA"
    },
    {
      "NUM_FINANCIER_CD": 8613,
      "TXT_FINANCIER_NAME": "SBI,MAIN BRANCH,CHANDRAPUR"
    },
    {
      "NUM_FINANCIER_CD": 8614,
      "TXT_FINANCIER_NAME": "THE NAVHIND MULTI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8615,
      "TXT_FINANCIER_NAME": "THE GRADUATE CO OPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8616,
      "TXT_FINANCIER_NAME": "BHAIROON PAGARIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 8617,
      "TXT_FINANCIER_NAME": "THANA DISTRICT CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8618,
      "TXT_FINANCIER_NAME": "R MAHAVEERCHAND BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 8619,
      "TXT_FINANCIER_NAME": "PRAVEEN CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 8620,
      "TXT_FINANCIER_NAME": "THE PRINTERS MYSORE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8621,
      "TXT_FINANCIER_NAME": "VISHAL MOTORS FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 8622,
      "TXT_FINANCIER_NAME": "MEENACHIL CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8623,
      "TXT_FINANCIER_NAME": "ADARSH SOUHARD SAHAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8624,
      "TXT_FINANCIER_NAME": "WIPRO GE HEALTHCARE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8625,
      "TXT_FINANCIER_NAME": "KITTUR RANI CHANNAMMA URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8626,
      "TXT_FINANCIER_NAME": "SARDAR BIRALWALA PARDI PEOPLE CO OP BANL LTD"
    },
    {
      "NUM_FINANCIER_CD": 8627,
      "TXT_FINANCIER_NAME": "KUBERAN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8628,
      "TXT_FINANCIER_NAME": "SHRI KRISHNAM FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 8629,
      "TXT_FINANCIER_NAME": "SAIRAJ NAGRI SAHAKARI P.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 8630,
      "TXT_FINANCIER_NAME": "samastnagar Co-operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 8631,
      "TXT_FINANCIER_NAME": "SAMATA COOPERATIVE DEVELOPMENT BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8632,
      "TXT_FINANCIER_NAME": "SAMATA SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8633,
      "TXT_FINANCIER_NAME": "SANGAMITRA CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8634,
      "TXT_FINANCIER_NAME": "SANJEEVANI NAG SAH PAT SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 8635,
      "TXT_FINANCIER_NAME": "SANKHEDA NAG.SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8636,
      "TXT_FINANCIER_NAME": "SANMIRA MAHILA NAGARI SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 8637,
      "TXT_FINANCIER_NAME": "SARANGPURA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8638,
      "TXT_FINANCIER_NAME": "SARDAR BHILAD WALLA PARDI PEOPLE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8639,
      "TXT_FINANCIER_NAME": "SECRETARY RBI EMPLOYEES CO.OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8640,
      "TXT_FINANCIER_NAME": "SERVICE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8641,
      "TXT_FINANCIER_NAME": "HEMKUNT FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 8642,
      "TXT_FINANCIER_NAME": "DIMCO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8643,
      "TXT_FINANCIER_NAME": "RAVISAGARAUTO FINANCE CO LTD SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 8644,
      "TXT_FINANCIER_NAME": "THE COTTON GREEN SAHAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8645,
      "TXT_FINANCIER_NAME": "SHREE PREM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8646,
      "TXT_FINANCIER_NAME": "KLM CREDIT SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 8647,
      "TXT_FINANCIER_NAME": "SRI VEERBHADRESHWAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8648,
      "TXT_FINANCIER_NAME": "K L M CREDIT SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 8649,
      "TXT_FINANCIER_NAME": "MOTOR INSUTRIES COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 8650,
      "TXT_FINANCIER_NAME": "SHRIRAM NAGARI SAHAKRI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8651,
      "TXT_FINANCIER_NAME": "VIJAYA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 8652,
      "TXT_FINANCIER_NAME": "KITTUR RANI CHANNAMMA URBAN CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8653,
      "TXT_FINANCIER_NAME": "KENDRIYA VIDALAYA SANGATHAN"
    },
    {
      "NUM_FINANCIER_CD": 8654,
      "TXT_FINANCIER_NAME": "AP TRANS CO"
    },
    {
      "NUM_FINANCIER_CD": 8655,
      "TXT_FINANCIER_NAME": "THE SANTHIGRAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8656,
      "TXT_FINANCIER_NAME": "G S B CO OPERATIVE CREDIT SOCIRTY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8657,
      "TXT_FINANCIER_NAME": "THE MANDVI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8658,
      "TXT_FINANCIER_NAME": "THE MANEED SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8659,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8660,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8856,
      "TXT_FINANCIER_NAME": "SHREE VIKAS CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8857,
      "TXT_FINANCIER_NAME": "SHREENATH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8858,
      "TXT_FINANCIER_NAME": "SHRI AARYA CHANAKYA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8859,
      "TXT_FINANCIER_NAME": "SHRI BARIA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8860,
      "TXT_FINANCIER_NAME": "SHRI DATTAKRUPA SOLAPUR DIST NAGARI SAH PATHSANSTHA MARYADIT JEUR"
    },
    {
      "NUM_FINANCIER_CD": 8861,
      "TXT_FINANCIER_NAME": "SHRI DATTAKRUPA SOLAPUR NAGRI SAH PATHSANSHA MARYADIT,JEUR"
    },
    {
      "NUM_FINANCIER_CD": 8862,
      "TXT_FINANCIER_NAME": "SHRI GOVARDHAN SINHJI RAGHUWANSHI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8863,
      "TXT_FINANCIER_NAME": "KARAN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 8864,
      "TXT_FINANCIER_NAME": "PRATHAM CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8865,
      "TXT_FINANCIER_NAME": "SARANGPUR NAGRIK COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8866,
      "TXT_FINANCIER_NAME": "SHRI SHARDA SAH NAGARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 8867,
      "TXT_FINANCIER_NAME": "SURABI AND CO INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 8868,
      "TXT_FINANCIER_NAME": "CITIZEN NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8869,
      "TXT_FINANCIER_NAME": "SHRI DURDUNDESHWAR URBAN CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 8870,
      "TXT_FINANCIER_NAME": "SRI SARASWATHI CREDIT SOWHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8871,
      "TXT_FINANCIER_NAME": "SHRI GANESH COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8872,
      "TXT_FINANCIER_NAME": "SHIVA CREDIT CO OPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8873,
      "TXT_FINANCIER_NAME": "M D C MOTOR VEHICLES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8874,
      "TXT_FINANCIER_NAME": "SIR M VISVESWARAYA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8875,
      "TXT_FINANCIER_NAME": "SHRI GANESH NON AGRICULTURE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8876,
      "TXT_FINANCIER_NAME": "SAHYADRY COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8877,
      "TXT_FINANCIER_NAME": "THE OZAR MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8878,
      "TXT_FINANCIER_NAME": "THE PACHORA PEOPLES CO-OP BANK LTD.PACHORA"
    },
    {
      "NUM_FINANCIER_CD": 8879,
      "TXT_FINANCIER_NAME": "THE PACHORA PEOPLES CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8880,
      "TXT_FINANCIER_NAME": "THE PACHSHEEL MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8881,
      "TXT_FINANCIER_NAME": "THE PALLIKUNNU SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8882,
      "TXT_FINANCIER_NAME": "THE PARAYAKAD SERVICE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8883,
      "TXT_FINANCIER_NAME": "THE PATTOM COLONY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8884,
      "TXT_FINANCIER_NAME": "THE PAZHAYANGADI URBAN CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8885,
      "TXT_FINANCIER_NAME": "THE PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8886,
      "TXT_FINANCIER_NAME": "The Perinthalmanna Co-operative Agricultural & Rural Development Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8887,
      "TXT_FINANCIER_NAME": "THE PERINTHALMANNA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8888,
      "TXT_FINANCIER_NAME": "THE PHALTAN CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8889,
      "TXT_FINANCIER_NAME": "THE PONKUNNAM DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8890,
      "TXT_FINANCIER_NAME": "THE PONNANI CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8891,
      "TXT_FINANCIER_NAME": "THE POOKKOOTTUR SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8892,
      "TXT_FINANCIER_NAME": "PURANDAR NAGARI SAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8893,
      "TXT_FINANCIER_NAME": "CHOLA MADALAM FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8894,
      "TXT_FINANCIER_NAME": "VKINVESTMENT AND SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8895,
      "TXT_FINANCIER_NAME": "SHREE KASHINATH SAJAN PATIL SAHAKARI PATAPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8896,
      "TXT_FINANCIER_NAME": "SARGUJA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8897,
      "TXT_FINANCIER_NAME": "HARYANA LAND RECLAMATION AND DEVELOPMENT CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 8898,
      "TXT_FINANCIER_NAME": "A P TRANS CO"
    },
    {
      "NUM_FINANCIER_CD": 8899,
      "TXT_FINANCIER_NAME": "OTTAPALAM COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8900,
      "TXT_FINANCIER_NAME": "APEX ABISHEK FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8901,
      "TXT_FINANCIER_NAME": "THE KALMESHWAR COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8902,
      "TXT_FINANCIER_NAME": "INDIRA GANDHI NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8903,
      "TXT_FINANCIER_NAME": "BEIERSDORF INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8904,
      "TXT_FINANCIER_NAME": "PEOPLES BANK"
    },
    {
      "NUM_FINANCIER_CD": 8905,
      "TXT_FINANCIER_NAME": "POST OFFICE BACHAT BANK"
    },
    {
      "NUM_FINANCIER_CD": 8906,
      "TXT_FINANCIER_NAME": "RAJKOT NAGRIK BANK"
    },
    {
      "NUM_FINANCIER_CD": 8907,
      "TXT_FINANCIER_NAME": "RIDHKARAN JITENDRA KUMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8908,
      "TXT_FINANCIER_NAME": "SANKARI URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8909,
      "TXT_FINANCIER_NAME": "SHIV SHAKTI SOCIETY CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8910,
      "TXT_FINANCIER_NAME": "SHRI LAXMI SHAKARI BANK LTD., SATARA"
    },
    {
      "NUM_FINANCIER_CD": 8911,
      "TXT_FINANCIER_NAME": "SRIRAM CITY UNION FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8912,
      "TXT_FINANCIER_NAME": "SHRI LAXMI NARCINVA URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8913,
      "TXT_FINANCIER_NAME": "DHANERA MARKANTILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8914,
      "TXT_FINANCIER_NAME": "DIVAN HOUSING FIN CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 8915,
      "TXT_FINANCIER_NAME": "SRI RAM CHITS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8916,
      "TXT_FINANCIER_NAME": "SHREE LAXMI GRAMIN BIGARSHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8917,
      "TXT_FINANCIER_NAME": "COMPANION CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8918,
      "TXT_FINANCIER_NAME": "KESHAV SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8919,
      "TXT_FINANCIER_NAME": "MATOSHRI GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8920,
      "TXT_FINANCIER_NAME": "SRI RAMAKRISHNA CREDIT CO OP SOCIETY LTD BYNDOOR"
    },
    {
      "NUM_FINANCIER_CD": 8921,
      "TXT_FINANCIER_NAME": "SHRI SAIBABA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8922,
      "TXT_FINANCIER_NAME": "SAIL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8923,
      "TXT_FINANCIER_NAME": "VINAYAKA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 8924,
      "TXT_FINANCIER_NAME": "THE CAR SHOPPE"
    },
    {
      "NUM_FINANCIER_CD": 8925,
      "TXT_FINANCIER_NAME": "THE UDGIR URBEN CO OP BANK LTD UDGIR"
    },
    {
      "NUM_FINANCIER_CD": 8926,
      "TXT_FINANCIER_NAME": "M SANJAY SHANKLA HUF"
    },
    {
      "NUM_FINANCIER_CD": 8927,
      "TXT_FINANCIER_NAME": "KRISHNA GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8928,
      "TXT_FINANCIER_NAME": "MALAPRABHA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8929,
      "TXT_FINANCIER_NAME": "MALPRABHA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8930,
      "TXT_FINANCIER_NAME": "MALPRABHA GRAMEENA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8931,
      "TXT_FINANCIER_NAME": "MANJEERA GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8932,
      "TXT_FINANCIER_NAME": "MARWAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8933,
      "TXT_FINANCIER_NAME": "NAGARJUNA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8934,
      "TXT_FINANCIER_NAME": "NAINITAL-ALMORA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8935,
      "TXT_FINANCIER_NAME": "NATIONAL BANK OF AGRICULTURE"
    },
    {
      "NUM_FINANCIER_CD": 8936,
      "TXT_FINANCIER_NAME": "NATIONAL BANK OF INDIA &ampRURAL DEV. SIMLA"
    },
    {
      "NUM_FINANCIER_CD": 8937,
      "TXT_FINANCIER_NAME": "Nilachal Gramya Bank khurda"
    },
    {
      "NUM_FINANCIER_CD": 8938,
      "TXT_FINANCIER_NAME": "PACHIMBANGA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8939,
      "TXT_FINANCIER_NAME": "PANDIAN GRAMA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8940,
      "TXT_FINANCIER_NAME": "BESC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8941,
      "TXT_FINANCIER_NAME": "SERVICE SAHAKARANA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8942,
      "TXT_FINANCIER_NAME": "SHREE BHAIRAVNATH VIKAS SHA PATHPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8943,
      "TXT_FINANCIER_NAME": "SAPHALYA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8944,
      "TXT_FINANCIER_NAME": "SANT VIVEKANAND EDCATIONAL AND WELFARE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8945,
      "TXT_FINANCIER_NAME": "METAL SCRAP TRADE CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8946,
      "TXT_FINANCIER_NAME": "THE SAHAYOG URBEN CO OPP BANK LTD UDGIR"
    },
    {
      "NUM_FINANCIER_CD": 8947,
      "TXT_FINANCIER_NAME": "CITYCORP FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8948,
      "TXT_FINANCIER_NAME": "DEEPA INVESEMENTS"
    },
    {
      "NUM_FINANCIER_CD": 8949,
      "TXT_FINANCIER_NAME": "BISHNUPUR TOWN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8950,
      "TXT_FINANCIER_NAME": "ICO STAR SECURITIES AND CREDITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8951,
      "TXT_FINANCIER_NAME": "KANGOD GROUP VIVIDHODDESAGALA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8952,
      "TXT_FINANCIER_NAME": "SHRI HITKARI VIDHYALAYA SAHKARI SHIKSHA SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8953,
      "TXT_FINANCIER_NAME": "SHRI HOLE HUCHESHWAR CO-OP, CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 9352,
      "TXT_FINANCIER_NAME": "THE A.P. MAHESH CO-OP. URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9353,
      "TXT_FINANCIER_NAME": "THE A.P. MAHESH CO-OPRATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9354,
      "TXT_FINANCIER_NAME": "THE A.P.E.CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9355,
      "TXT_FINANCIER_NAME": "THE A.P.RAJA RAJESWARI MAHILA CO-OP.URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9356,
      "TXT_FINANCIER_NAME": "THE A.P.STATE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9357,
      "TXT_FINANCIER_NAME": "THE AGS OFFICE EMPLOYEES COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9358,
      "TXT_FINANCIER_NAME": "THE AHAMADNAGAR SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9359,
      "TXT_FINANCIER_NAME": "THE KATUKUKKE SERVICE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9360,
      "TXT_FINANCIER_NAME": "BHINGAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9361,
      "TXT_FINANCIER_NAME": "SHAYAMRAO VITHAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9362,
      "TXT_FINANCIER_NAME": "THE PAVAGADA SOUHARDA CREDIT CO OPERATIVE LIMITED TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 9363,
      "TXT_FINANCIER_NAME": "KHADKI NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9364,
      "TXT_FINANCIER_NAME": "SRI JAGADGURU PANCHACHARYAHA CREDIT CO OPERATIVE SOCIETY LTD SIRA"
    },
    {
      "NUM_FINANCIER_CD": 9365,
      "TXT_FINANCIER_NAME": "NBCFDC"
    },
    {
      "NUM_FINANCIER_CD": 9366,
      "TXT_FINANCIER_NAME": "CENTRE FOR NUNO SCIENCE AND TECHNOLOGIES ANNA UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 9367,
      "TXT_FINANCIER_NAME": "SHREE JAGADAMBAMATA GRIMIN BIGARSHETI SHAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9368,
      "TXT_FINANCIER_NAME": "SPOT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9369,
      "TXT_FINANCIER_NAME": "SHREE KRUSHNA GRAMIN BIGARSHETI SAHKARI PATSANSTHA MARYADIT MOHADI"
    },
    {
      "NUM_FINANCIER_CD": 9370,
      "TXT_FINANCIER_NAME": "KOCHI REFINARIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 9371,
      "TXT_FINANCIER_NAME": "M K INVEST MENT"
    },
    {
      "NUM_FINANCIER_CD": 9372,
      "TXT_FINANCIER_NAME": "THE UNAVA NAGARIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9373,
      "TXT_FINANCIER_NAME": "THE UNITED CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9374,
      "TXT_FINANCIER_NAME": "THE Urangattirir Service Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9375,
      "TXT_FINANCIER_NAME": "THE UTARSANDA PEOPLE S CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9376,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OP NEW BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9377,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OPERATIVE NEW BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9378,
      "TXT_FINANCIER_NAME": "THE VALAPATANAM SERVICE CO-OPERATIVE BANK."
    },
    {
      "NUM_FINANCIER_CD": 9379,
      "TXT_FINANCIER_NAME": "THE VALAPATTANAM SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9380,
      "TXT_FINANCIER_NAME": "THE VALLABH VIDYANAGAR COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9381,
      "TXT_FINANCIER_NAME": "THE VALSAD DIST SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 9382,
      "TXT_FINANCIER_NAME": "THE VARACHHA URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9383,
      "TXT_FINANCIER_NAME": "THE VATANAPPALLY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9384,
      "TXT_FINANCIER_NAME": "THE VEERASHAIVA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9385,
      "TXT_FINANCIER_NAME": "THE VELLIKUNNU SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9386,
      "TXT_FINANCIER_NAME": "THE VIJAY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9387,
      "TXT_FINANCIER_NAME": "THE VIJAY CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9388,
      "TXT_FINANCIER_NAME": "THE VIJAY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9389,
      "TXT_FINANCIER_NAME": "D KAMALA FINANCIERS CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 9390,
      "TXT_FINANCIER_NAME": "CIDCO NAGRI SAHAKARI PATHSANSTHA CIDCO NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 9391,
      "TXT_FINANCIER_NAME": "VIJAYABANK"
    },
    {
      "NUM_FINANCIER_CD": 9392,
      "TXT_FINANCIER_NAME": "BAHGINI NIVEDITA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9393,
      "TXT_FINANCIER_NAME": "THE RASIPURAM COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9394,
      "TXT_FINANCIER_NAME": "THE JOWAI COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9395,
      "TXT_FINANCIER_NAME": "CHICO BANK"
    },
    {
      "NUM_FINANCIER_CD": 9396,
      "TXT_FINANCIER_NAME": "SHIRIYARA AGRICULTURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9397,
      "TXT_FINANCIER_NAME": "PATALESHWAR GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9398,
      "TXT_FINANCIER_NAME": "CSI CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9399,
      "TXT_FINANCIER_NAME": "MAGMA FINCORP FINANCE LIMITTED"
    },
    {
      "NUM_FINANCIER_CD": 9400,
      "TXT_FINANCIER_NAME": "G S S S CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9401,
      "TXT_FINANCIER_NAME": "KEHSAV SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9402,
      "TXT_FINANCIER_NAME": "MOHAN LAL SUKHADIA UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 9403,
      "TXT_FINANCIER_NAME": "KOTEK MAHINDRA PRIME"
    },
    {
      "NUM_FINANCIER_CD": 9404,
      "TXT_FINANCIER_NAME": "C M P L"
    },
    {
      "NUM_FINANCIER_CD": 9405,
      "TXT_FINANCIER_NAME": "PRATAP GRAMIN BIGAR SHETHI SAHAKARI PAT SANSTHA BULDHANA"
    },
    {
      "NUM_FINANCIER_CD": 9406,
      "TXT_FINANCIER_NAME": "BNP PARRIBAS"
    },
    {
      "NUM_FINANCIER_CD": 9407,
      "TXT_FINANCIER_NAME": "C and MD Engineers India Ltd"
    },
    {
      "NUM_FINANCIER_CD": 9408,
      "TXT_FINANCIER_NAME": "C S E B"
    },
    {
      "NUM_FINANCIER_CD": 9409,
      "TXT_FINANCIER_NAME": "C.R.P.F."
    },
    {
      "NUM_FINANCIER_CD": 9410,
      "TXT_FINANCIER_NAME": "CAPITAL CREDIT SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 9411,
      "TXT_FINANCIER_NAME": "CARZON RENT INDIA PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9412,
      "TXT_FINANCIER_NAME": "CEERI PILANI"
    },
    {
      "NUM_FINANCIER_CD": 9413,
      "TXT_FINANCIER_NAME": "CHAIR MAN AIRPORT AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9414,
      "TXT_FINANCIER_NAME": "CHAIRMAN AIR PORT AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9415,
      "TXT_FINANCIER_NAME": "CHAIRMAN AIRPORT AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9416,
      "TXT_FINANCIER_NAME": "CHAIRMAN PRESS COUNCIL OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9417,
      "TXT_FINANCIER_NAME": "CHAIRMAN TNEB CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 9418,
      "TXT_FINANCIER_NAME": "CHARAT SINGH AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9419,
      "TXT_FINANCIER_NAME": "CHARTERED MERCANTILE M.B. LTD."
    },
    {
      "NUM_FINANCIER_CD": 9420,
      "TXT_FINANCIER_NAME": "CHARTHI CHITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9421,
      "TXT_FINANCIER_NAME": "CHATTISGARH STATE ELECTRICITY BOARD"
    },
    {
      "NUM_FINANCIER_CD": 9422,
      "TXT_FINANCIER_NAME": "CHERUVARAKONAM"
    },
    {
      "NUM_FINANCIER_CD": 9423,
      "TXT_FINANCIER_NAME": "CHHATTISHGARH STATE ELECTRICITY BOARD"
    },
    {
      "NUM_FINANCIER_CD": 9424,
      "TXT_FINANCIER_NAME": "CHIEF ENGINER ADMIN HVPN"
    },
    {
      "NUM_FINANCIER_CD": 9425,
      "TXT_FINANCIER_NAME": "CIBA SPECIALTY CHEMICALS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9426,
      "TXT_FINANCIER_NAME": "CKB"
    },
    {
      "NUM_FINANCIER_CD": 9427,
      "TXT_FINANCIER_NAME": "BHIMASHANKAR GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9428,
      "TXT_FINANCIER_NAME": "BHAIROON PARIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 9429,
      "TXT_FINANCIER_NAME": "SHRI KHERVA CO OP CREDIT SOC BANK"
    },
    {
      "NUM_FINANCIER_CD": 9430,
      "TXT_FINANCIER_NAME": "PRAVATIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9431,
      "TXT_FINANCIER_NAME": "FINANCIAL COMMISSIONER REVENUE HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 9432,
      "TXT_FINANCIER_NAME": "JAY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9433,
      "TXT_FINANCIER_NAME": "HULGOL SEVA SAHAKARI SANGH"
    },
    {
      "NUM_FINANCIER_CD": 9434,
      "TXT_FINANCIER_NAME": "VAIJAYANTHI ANCHALIA FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 9435,
      "TXT_FINANCIER_NAME": "VAIJAYANTHI ANCHALIA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9436,
      "TXT_FINANCIER_NAME": "TOWN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9437,
      "TXT_FINANCIER_NAME": "SHANTUSINDHU NAGARI SAHAKARI PATSANSTHA MARYADIT INGALI"
    },
    {
      "NUM_FINANCIER_CD": 9438,
      "TXT_FINANCIER_NAME": "CH SHIVAJI VAHANDHARAK NAGARI SAHAKARI PAT SANSTHA MARYADIT KAGAL TAL KAGAL DIST KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 9439,
      "TXT_FINANCIER_NAME": "ECONOMIC DEVE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 9440,
      "TXT_FINANCIER_NAME": "DATTA NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9441,
      "TXT_FINANCIER_NAME": "BALBIR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 9442,
      "TXT_FINANCIER_NAME": "NAVYUG NAGARI SAHKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9443,
      "TXT_FINANCIER_NAME": "MEPPAYUR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9444,
      "TXT_FINANCIER_NAME": "SHARDA NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9445,
      "TXT_FINANCIER_NAME": "GOPAL KRISHNA PANCHAKROSHI GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9446,
      "TXT_FINANCIER_NAME": "DIST MANAGAR LOKSHAHIR ANNABHAU SATHE DEVELOPMENT CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9447,
      "TXT_FINANCIER_NAME": "VAIN GANGA KRISHNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9448,
      "TXT_FINANCIER_NAME": "CHIEF ACCOUNTS OFFICER VUDA"
    },
    {
      "NUM_FINANCIER_CD": 9449,
      "TXT_FINANCIER_NAME": "KASARAGOD CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8954,
      "TXT_FINANCIER_NAME": "SHRI JAY BHAVANI URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8955,
      "TXT_FINANCIER_NAME": "SHRI KADI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8956,
      "TXT_FINANCIER_NAME": "SHREE LAXMI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8957,
      "TXT_FINANCIER_NAME": "SHRI MAHESH CO OP BANK LTD NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 8958,
      "TXT_FINANCIER_NAME": "SHRI SADGURU CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 8959,
      "TXT_FINANCIER_NAME": "SHRI SAMARTH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8960,
      "TXT_FINANCIER_NAME": "SHRI SANT GOROBA KAKA NAGARI SAHAKARI PATHASANSTHA MARYADIT SINNER"
    },
    {
      "NUM_FINANCIER_CD": 8961,
      "TXT_FINANCIER_NAME": "SHRI SHIDDESHWARA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8962,
      "TXT_FINANCIER_NAME": "SHRI SHUBHLAXMI CREDIT CO OP SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 8963,
      "TXT_FINANCIER_NAME": "SHRI SIDHESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8964,
      "TXT_FINANCIER_NAME": "SHRI SWAMINARAYAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8965,
      "TXT_FINANCIER_NAME": "SHRI VEERAPULAKESHI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8966,
      "TXT_FINANCIER_NAME": "SIVAPRIYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8967,
      "TXT_FINANCIER_NAME": "PARIJAT CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8968,
      "TXT_FINANCIER_NAME": "TERANA NAGARI SAHAKARI BANK LTD OSMANABAD"
    },
    {
      "NUM_FINANCIER_CD": 8969,
      "TXT_FINANCIER_NAME": "INSTITUTE OF HOTEL MANAGEMENT CATERING TECHNOLOGIES"
    },
    {
      "NUM_FINANCIER_CD": 8970,
      "TXT_FINANCIER_NAME": "GOA STATE SCHEDULED TRIBES FINANCE AND DEV CORP"
    },
    {
      "NUM_FINANCIER_CD": 8971,
      "TXT_FINANCIER_NAME": "MANIBHAI DESAI GRAMIN BIGARSHETI SAHKARI PATHASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8972,
      "TXT_FINANCIER_NAME": "THE KARAD MEDICOJ SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8973,
      "TXT_FINANCIER_NAME": "VIJAY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8974,
      "TXT_FINANCIER_NAME": "PANKAJ AUTO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 8975,
      "TXT_FINANCIER_NAME": "MAHATMA BASWESHWER NAGARI SAHAKARI PAT SANSTHA LTD OSMANABAD"
    },
    {
      "NUM_FINANCIER_CD": 8976,
      "TXT_FINANCIER_NAME": "NAVBHARAT COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8977,
      "TXT_FINANCIER_NAME": "VALARPIRAI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8978,
      "TXT_FINANCIER_NAME": "CHERUKUNNU CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 8979,
      "TXT_FINANCIER_NAME": "GODAWARI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8980,
      "TXT_FINANCIER_NAME": "SETH MADAN LALL PALRIWALA FOUNDATION"
    },
    {
      "NUM_FINANCIER_CD": 8981,
      "TXT_FINANCIER_NAME": "INDIAN OIL BLENDING LTD"
    },
    {
      "NUM_FINANCIER_CD": 8982,
      "TXT_FINANCIER_NAME": "B H R MULTISTATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8983,
      "TXT_FINANCIER_NAME": "B RAJESH NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 8984,
      "TXT_FINANCIER_NAME": "B R M LEASE AND CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8985,
      "TXT_FINANCIER_NAME": "THE POYYA SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8986,
      "TXT_FINANCIER_NAME": "THE PUNNOL CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 8987,
      "TXT_FINANCIER_NAME": "THE PURAMERI SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8988,
      "TXT_FINANCIER_NAME": "THE RAMANATTUKARA SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8989,
      "TXT_FINANCIER_NAME": "THE RAMPUR PEOPLE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8990,
      "TXT_FINANCIER_NAME": "THE REWARI CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8991,
      "TXT_FINANCIER_NAME": "THE ROHASTAMI SAH URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8992,
      "TXT_FINANCIER_NAME": "THE ROYAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8993,
      "TXT_FINANCIER_NAME": "THE ROYALE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8994,
      "TXT_FINANCIER_NAME": "The Samrao Vithal co-op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8995,
      "TXT_FINANCIER_NAME": "The Sarvoday Nagrik Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8996,
      "TXT_FINANCIER_NAME": "THE SASTHANA COOP AGRICULTURAL BANK LTD SASTHAN"
    },
    {
      "NUM_FINANCIER_CD": 8997,
      "TXT_FINANCIER_NAME": "BOTHRA FINANCE MADRAS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 8998,
      "TXT_FINANCIER_NAME": "SHRI AGRASEN MAHARAJ NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8999,
      "TXT_FINANCIER_NAME": "VAIBHAV CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9000,
      "TXT_FINANCIER_NAME": "SHREE SAHAJANAND CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9001,
      "TXT_FINANCIER_NAME": "DOMBIWALI NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9002,
      "TXT_FINANCIER_NAME": "bhagyodya urban coopretive bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 9003,
      "TXT_FINANCIER_NAME": "POWER GRID COPRORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9004,
      "TXT_FINANCIER_NAME": "CATHOLIC UNION CHITTIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 9005,
      "TXT_FINANCIER_NAME": "THE TOWN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9006,
      "TXT_FINANCIER_NAME": "THE LOLIEM URABN COOP CRD SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9007,
      "TXT_FINANCIER_NAME": "DAVANGERE HARIHAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9008,
      "TXT_FINANCIER_NAME": "THE TALIKOTI SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9009,
      "TXT_FINANCIER_NAME": "SINCERE SYNDICATE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9010,
      "TXT_FINANCIER_NAME": "SONALI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9011,
      "TXT_FINANCIER_NAME": "SPECIALISED PERSONAL BANKING BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 9012,
      "TXT_FINANCIER_NAME": "STATE BANK OF DAMAN"
    },
    {
      "NUM_FINANCIER_CD": 9013,
      "TXT_FINANCIER_NAME": "SUBHADHRA LOCAL AREA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9014,
      "TXT_FINANCIER_NAME": "SWISS BANK"
    },
    {
      "NUM_FINANCIER_CD": 9015,
      "TXT_FINANCIER_NAME": "T.M.L. FINANCIAL SERVICE LTD."
    },
    {
      "NUM_FINANCIER_CD": 9016,
      "TXT_FINANCIER_NAME": "TAMILNAD MARCANTILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9017,
      "TXT_FINANCIER_NAME": "TAMILNADU MERCHANTILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9018,
      "TXT_FINANCIER_NAME": "THE BAGALKOT C-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9019,
      "TXT_FINANCIER_NAME": "THE BHARAT FINANCE &ampINDUSTRIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9020,
      "TXT_FINANCIER_NAME": "THE DR PANJABRAO DESHMUKH URBAN CO BANK"
    },
    {
      "NUM_FINANCIER_CD": 9021,
      "TXT_FINANCIER_NAME": "THE KAPUR VYSYA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9022,
      "TXT_FINANCIER_NAME": "THE KCC BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 9023,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9024,
      "TXT_FINANCIER_NAME": "U F J BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9025,
      "TXT_FINANCIER_NAME": "SRI REVANSIDDESHWAR CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9026,
      "TXT_FINANCIER_NAME": "JANLAXMI SAHAKARI BANK LTD NASIK"
    },
    {
      "NUM_FINANCIER_CD": 9027,
      "TXT_FINANCIER_NAME": "SHREE TULJA BHAVANI NAGRI SAH PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9028,
      "TXT_FINANCIER_NAME": "SHRI BHUTAL FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9029,
      "TXT_FINANCIER_NAME": "SRI MANJUNATH CREDIT COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9030,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9031,
      "TXT_FINANCIER_NAME": "DAKSHIN HARYANA BIJLI VITRAN NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 9032,
      "TXT_FINANCIER_NAME": "CHIEF ADMINISTRATOR HUDA"
    },
    {
      "NUM_FINANCIER_CD": 9033,
      "TXT_FINANCIER_NAME": "AJAY FINANCE CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 9034,
      "TXT_FINANCIER_NAME": "B M R LEASE AND CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9035,
      "TXT_FINANCIER_NAME": "PINAKINI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9036,
      "TXT_FINANCIER_NAME": "PRAGATHI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9037,
      "TXT_FINANCIER_NAME": "PRAGATHI GRAMEENA BANK JAGALUR DAVANAGERE DIST"
    },
    {
      "NUM_FINANCIER_CD": 9038,
      "TXT_FINANCIER_NAME": "PRAGATHI GRAMEENA BANK JAGALUT DAVANAGERE DIST"
    },
    {
      "NUM_FINANCIER_CD": 9039,
      "TXT_FINANCIER_NAME": "PRERNA GRAMIN BIGAR SHETI S. P.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 9040,
      "TXT_FINANCIER_NAME": "R.R.B."
    },
    {
      "NUM_FINANCIER_CD": 9041,
      "TXT_FINANCIER_NAME": "REGIONAL RURALBANK"
    },
    {
      "NUM_FINANCIER_CD": 9042,
      "TXT_FINANCIER_NAME": "SABARKHANTHA-GANDHINAGAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9043,
      "TXT_FINANCIER_NAME": "SAHYADRI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9044,
      "TXT_FINANCIER_NAME": "SATPUDA NARMDA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9045,
      "TXT_FINANCIER_NAME": "SHAHDOL KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9046,
      "TXT_FINANCIER_NAME": "SHAHJAHANPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9047,
      "TXT_FINANCIER_NAME": "SHATHAVAHAN GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9048,
      "TXT_FINANCIER_NAME": "SHRI VENKATESWARA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9049,
      "TXT_FINANCIER_NAME": "SRAVASTHI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9050,
      "TXT_FINANCIER_NAME": "SRI ANANTHA PRAGATHI GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9051,
      "TXT_FINANCIER_NAME": "SRI VISAKHA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9052,
      "TXT_FINANCIER_NAME": "THE TALIPARAMBA PRIMARY CO OP AGRIL AND RURAL DEVPT BANK"
    },
    {
      "NUM_FINANCIER_CD": 9053,
      "TXT_FINANCIER_NAME": "SUPRABATH CREDIT SOUHARDA SAHAKARI NIYAMITHA ANKOLA"
    },
    {
      "NUM_FINANCIER_CD": 9054,
      "TXT_FINANCIER_NAME": "MYLAPRA SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9055,
      "TXT_FINANCIER_NAME": "MALHOTRA AUTO SALES"
    },
    {
      "NUM_FINANCIER_CD": 9056,
      "TXT_FINANCIER_NAME": "JYOTI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9057,
      "TXT_FINANCIER_NAME": "ASHOK LAYLAND DIVISION OF INDUSIND BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9058,
      "TXT_FINANCIER_NAME": "MANGALAN ENTERPRISE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9059,
      "TXT_FINANCIER_NAME": "BAPUJI PATTINA SOUHARDHA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 9060,
      "TXT_FINANCIER_NAME": "THE GUJARAT HOUSING UMARWADA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9061,
      "TXT_FINANCIER_NAME": "JAIN INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9062,
      "TXT_FINANCIER_NAME": "RREETU INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9063,
      "TXT_FINANCIER_NAME": "UDHAWA COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9064,
      "TXT_FINANCIER_NAME": "DEPOSIT INSURANCE AND CREDIT GURANTEE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 9065,
      "TXT_FINANCIER_NAME": "KARPAGAM"
    },
    {
      "NUM_FINANCIER_CD": 9066,
      "TXT_FINANCIER_NAME": "SHREE YASHODEV NAGARI SAHKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9067,
      "TXT_FINANCIER_NAME": "SANCHIT ENTERPRICES"
    },
    {
      "NUM_FINANCIER_CD": 9068,
      "TXT_FINANCIER_NAME": "MEMUNDA COOPERATIVE URBAN SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9069,
      "TXT_FINANCIER_NAME": "SINDAGI URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9070,
      "TXT_FINANCIER_NAME": "MUMBAI PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 9071,
      "TXT_FINANCIER_NAME": "THE NASIK MERCHANTS CO OP BANK LTD NASIK"
    },
    {
      "NUM_FINANCIER_CD": 9072,
      "TXT_FINANCIER_NAME": "REGIONAL RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 9073,
      "TXT_FINANCIER_NAME": "SUNDERLAL SAUJI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9074,
      "TXT_FINANCIER_NAME": "SIDDESHWAR URBAN CO OPP BANK LTD.SILLOD."
    },
    {
      "NUM_FINANCIER_CD": 9075,
      "TXT_FINANCIER_NAME": "SIDDHI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9076,
      "TXT_FINANCIER_NAME": "SIDDHI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9077,
      "TXT_FINANCIER_NAME": "SIDDI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9078,
      "TXT_FINANCIER_NAME": "SIR M VISVERVARAYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9079,
      "TXT_FINANCIER_NAME": "SIR M VISVESVARAYA CO -OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9080,
      "TXT_FINANCIER_NAME": "SIR M VISVESVARAYA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9081,
      "TXT_FINANCIER_NAME": "SIR M. VISVESVARAYA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9082,
      "TXT_FINANCIER_NAME": "SIRSI SOUHARDA SAHAKARI BANK.SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 9083,
      "TXT_FINANCIER_NAME": "Solapur Dist District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9084,
      "TXT_FINANCIER_NAME": "SOPANKAKA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9085,
      "TXT_FINANCIER_NAME": "SOUTH ZONE SALES TAX EMPLOYEES COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9086,
      "TXT_FINANCIER_NAME": "SREE NIDHI SOUHARDA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9087,
      "TXT_FINANCIER_NAME": "SRI BHAGAVTI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9088,
      "TXT_FINANCIER_NAME": "SRI GAJANAN URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9089,
      "TXT_FINANCIER_NAME": "SRI JAGADGURU PANCHACHARYAHA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9090,
      "TXT_FINANCIER_NAME": "SIDDHNATH SAHKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9091,
      "TXT_FINANCIER_NAME": "SUMITRA GRAMIN NAGARI PATH SANSTHA AKLUJ"
    },
    {
      "NUM_FINANCIER_CD": 9092,
      "TXT_FINANCIER_NAME": "KAVATHEMAHANKAL CREDIT CO OP SOC LTD KAVATHEMAHANKAL"
    },
    {
      "NUM_FINANCIER_CD": 9093,
      "TXT_FINANCIER_NAME": "THE ANNASAHEB SAVANT CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9094,
      "TXT_FINANCIER_NAME": "ALMEL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9095,
      "TXT_FINANCIER_NAME": "ESTER INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 9096,
      "TXT_FINANCIER_NAME": "ARTI FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 9097,
      "TXT_FINANCIER_NAME": "SISODRA ARAK VIBHAG VIVIDH KARYAKARI SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9098,
      "TXT_FINANCIER_NAME": "BDA HOUSING COLONY CREDIT CO-OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9099,
      "TXT_FINANCIER_NAME": "GOVENOR OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 9100,
      "TXT_FINANCIER_NAME": "THIROOR SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9101,
      "TXT_FINANCIER_NAME": "OSRAM INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9102,
      "TXT_FINANCIER_NAME": "S RAJENDRA KUMAR SONS"
    },
    {
      "NUM_FINANCIER_CD": 9103,
      "TXT_FINANCIER_NAME": "ADGAON VIVIDH KARYAKARI SAH V SEVA SNTH MARYA"
    },
    {
      "NUM_FINANCIER_CD": 9104,
      "TXT_FINANCIER_NAME": "GIRNAI NAGARI SHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9105,
      "TXT_FINANCIER_NAME": "SARSA PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9106,
      "TXT_FINANCIER_NAME": "THE SHAMALPUR GROUP SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9107,
      "TXT_FINANCIER_NAME": "THE SHAMARO CO-OP.BANK.LTD."
    },
    {
      "NUM_FINANCIER_CD": 9108,
      "TXT_FINANCIER_NAME": "THE SHANTAPPANNA MIRJI URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9109,
      "TXT_FINANCIER_NAME": "THE SHIVAMOGGA ARECANUT MANDI MERCHANTS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9110,
      "TXT_FINANCIER_NAME": "The Shoranur Co-op.Service Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 9111,
      "TXT_FINANCIER_NAME": "the shri suvarna sahakari bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 9112,
      "TXT_FINANCIER_NAME": "THE SIDHPUR CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9113,
      "TXT_FINANCIER_NAME": "THE SIDHPUR COMMERCIAL CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9114,
      "TXT_FINANCIER_NAME": "THE SLM URBUN CO-OP"
    },
    {
      "NUM_FINANCIER_CD": 9115,
      "TXT_FINANCIER_NAME": "THE SOCIAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9116,
      "TXT_FINANCIER_NAME": "THE SONEPAT CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9117,
      "TXT_FINANCIER_NAME": "THE SOUTH CANARA DIST.CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9118,
      "TXT_FINANCIER_NAME": "KAI SUBHASHCHANDJI PARAKH NAGARI SAHKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9119,
      "TXT_FINANCIER_NAME": "SHALIWAHAN NAGARI SAHAKARI PAT SANSTHA MARYADIT PAITHAN"
    },
    {
      "NUM_FINANCIER_CD": 9120,
      "TXT_FINANCIER_NAME": "NAVAGAM VVS MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9121,
      "TXT_FINANCIER_NAME": "PIJ PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9122,
      "TXT_FINANCIER_NAME": "THE BAJWA URBAN CO OP CREDIT SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9123,
      "TXT_FINANCIER_NAME": "SRINIDHI CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9124,
      "TXT_FINANCIER_NAME": "KODUNGALLOOR TOWN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9125,
      "TXT_FINANCIER_NAME": "PANANGAD SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9126,
      "TXT_FINANCIER_NAME": "PADMASHREE DR VITTHALRAO VIKHE PATIL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9127,
      "TXT_FINANCIER_NAME": "GRAMIN BIGARI SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9128,
      "TXT_FINANCIER_NAME": "PEERMADE TALUK CO OP AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9129,
      "TXT_FINANCIER_NAME": "KALRA H P AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9130,
      "TXT_FINANCIER_NAME": "ROCKMAN INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 9131,
      "TXT_FINANCIER_NAME": "THE PUNCHKULA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9132,
      "TXT_FINANCIER_NAME": "ARAYA VYSYA SRI RAMA COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9133,
      "TXT_FINANCIER_NAME": "CITY INDUSTRIAL DEVELOPMENT CORPORATION OF MAHARASHTRA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9134,
      "TXT_FINANCIER_NAME": "A P GENCO VIDYUT SOUDHA"
    },
    {
      "NUM_FINANCIER_CD": 9135,
      "TXT_FINANCIER_NAME": "VARADA GRMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9136,
      "TXT_FINANCIER_NAME": "VIMAL FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 9137,
      "TXT_FINANCIER_NAME": "VIMAL FINANCES"
    },
    {
      "NUM_FINANCIER_CD": 9138,
      "TXT_FINANCIER_NAME": "VSSN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9139,
      "TXT_FINANCIER_NAME": "YEDAPADAVU V S S BANK LTD YEDAPADAVU"
    },
    {
      "NUM_FINANCIER_CD": 9140,
      "TXT_FINANCIER_NAME": "YELLAPUR URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9141,
      "TXT_FINANCIER_NAME": "ZUARI LEASING & FINANCE CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 9142,
      "TXT_FINANCIER_NAME": "A M FINANCE CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 9143,
      "TXT_FINANCIER_NAME": "AGRICULTURE"
    },
    {
      "NUM_FINANCIER_CD": 9144,
      "TXT_FINANCIER_NAME": "AIRPORT AUTHURITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9145,
      "TXT_FINANCIER_NAME": "AIRPORT DIRECTOR AAI AGI AIRPORT"
    },
    {
      "NUM_FINANCIER_CD": 9146,
      "TXT_FINANCIER_NAME": "AKSHAYA ENTERPRISESS CHICKPET TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 9147,
      "TXT_FINANCIER_NAME": "ANANT KRUPA PATSANSTHA CHAKAN"
    },
    {
      "NUM_FINANCIER_CD": 9148,
      "TXT_FINANCIER_NAME": "APIDC - VENTURE CAPITAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9149,
      "TXT_FINANCIER_NAME": "APIICL"
    },
    {
      "NUM_FINANCIER_CD": 9150,
      "TXT_FINANCIER_NAME": "APOLLO TYRES LTD."
    },
    {
      "NUM_FINANCIER_CD": 9151,
      "TXT_FINANCIER_NAME": "ARMY GROUP INS. FUND"
    },
    {
      "NUM_FINANCIER_CD": 9152,
      "TXT_FINANCIER_NAME": "CENTURIOB BANK OF PUNJAB LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9153,
      "TXT_FINANCIER_NAME": "GET CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 9154,
      "TXT_FINANCIER_NAME": "THE ALDONA URBAN COOP CREDIT STYLTD"
    },
    {
      "NUM_FINANCIER_CD": 9155,
      "TXT_FINANCIER_NAME": "HP STATE CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9156,
      "TXT_FINANCIER_NAME": "SHREE PARSHWANTHA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9157,
      "TXT_FINANCIER_NAME": "LT ASHOKRAO BANKAR NAGARI SAHKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9158,
      "TXT_FINANCIER_NAME": "SAHYOG SAH PATH SAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9159,
      "TXT_FINANCIER_NAME": "MALNAD CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9160,
      "TXT_FINANCIER_NAME": "SRIRAMA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9161,
      "TXT_FINANCIER_NAME": "SUMITRA GRAMIN NAGARI PATHSANSTHA AKLUJ"
    },
    {
      "NUM_FINANCIER_CD": 9162,
      "TXT_FINANCIER_NAME": "SURENDRANAGAR BHAVNAGAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9163,
      "TXT_FINANCIER_NAME": "THE GSP ASR GRAMIN KVB"
    },
    {
      "NUM_FINANCIER_CD": 9164,
      "TXT_FINANCIER_NAME": "THE TUNGBADRA GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9165,
      "TXT_FINANCIER_NAME": "TUNGABHADRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9166,
      "TXT_FINANCIER_NAME": "TUNGABHANDRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9167,
      "TXT_FINANCIER_NAME": "UTTAR PARDESH GRAMIN BANK DHAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 9168,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH HINDON GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9169,
      "TXT_FINANCIER_NAME": "VARADA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9170,
      "TXT_FINANCIER_NAME": "VARDHAMAN JAIN GRAMIN BIGAR SHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9171,
      "TXT_FINANCIER_NAME": "VIDISHA-BHOPAL KSHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9172,
      "TXT_FINANCIER_NAME": "VIDUR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9173,
      "TXT_FINANCIER_NAME": "ABDURAHIMAN NAGAR CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9174,
      "TXT_FINANCIER_NAME": "MAHARATRA BANK EMPLOYES CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9175,
      "TXT_FINANCIER_NAME": "MAHARASTRA BANK EMPLOYES CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9176,
      "TXT_FINANCIER_NAME": "SANKHALI URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9177,
      "TXT_FINANCIER_NAME": "TPCAR"
    },
    {
      "NUM_FINANCIER_CD": 9178,
      "TXT_FINANCIER_NAME": "C AND MD EIL"
    },
    {
      "NUM_FINANCIER_CD": 9179,
      "TXT_FINANCIER_NAME": "SMALL INDUSTRIAL DEVELOPMENT BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9180,
      "TXT_FINANCIER_NAME": "PARODA VKSS SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9181,
      "TXT_FINANCIER_NAME": "KULSWAMINI CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9182,
      "TXT_FINANCIER_NAME": "RAMKRISHNA HARI GRAMIN BIGARSHETI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9183,
      "TXT_FINANCIER_NAME": "SAVTHAMALI NAGARI SAHKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9184,
      "TXT_FINANCIER_NAME": "THRIFT AND CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9185,
      "TXT_FINANCIER_NAME": "SHRIRAM CHITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9186,
      "TXT_FINANCIER_NAME": "HINGLAJMATA NAGARI SAHA PATH SANSTHA SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 9187,
      "TXT_FINANCIER_NAME": "SANT TUKARAM NAGARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9188,
      "TXT_FINANCIER_NAME": "SANT TUKARAM NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9189,
      "TXT_FINANCIER_NAME": "UMA SARAFI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 9190,
      "TXT_FINANCIER_NAME": "CSD FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9191,
      "TXT_FINANCIER_NAME": "R PRAKASH CHAND BAGMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 9192,
      "TXT_FINANCIER_NAME": "SHRI GANESH NAGARI SAH PATH SANSTHA MARYADIT MURGUD"
    },
    {
      "NUM_FINANCIER_CD": 9193,
      "TXT_FINANCIER_NAME": "J AMIT KUMAR BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 9194,
      "TXT_FINANCIER_NAME": "KOYANA NAGARI SAHAKARI PATSANNTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9195,
      "TXT_FINANCIER_NAME": "BAKAJIRAO PATIL GRAMIN BIGAR SHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9196,
      "TXT_FINANCIER_NAME": "THE KASARAGOD CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9197,
      "TXT_FINANCIER_NAME": "SUDEER CHAND"
    },
    {
      "NUM_FINANCIER_CD": 9198,
      "TXT_FINANCIER_NAME": "UTKRANTI NAGARI SAHAKARI PAT SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9199,
      "TXT_FINANCIER_NAME": "JAGDAMBA GRAMIN BIGAR SHETI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9200,
      "TXT_FINANCIER_NAME": "THE VETTAKKAL SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9201,
      "TXT_FINANCIER_NAME": "BHW HOME FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9202,
      "TXT_FINANCIER_NAME": "LAWRENCE KRISHAN COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9203,
      "TXT_FINANCIER_NAME": "COMPEER HP FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9204,
      "TXT_FINANCIER_NAME": "KISHORE INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9205,
      "TXT_FINANCIER_NAME": "PRASANTH INVESMANT"
    },
    {
      "NUM_FINANCIER_CD": 9206,
      "TXT_FINANCIER_NAME": "BHARATH FINAACE CORPARATION"
    },
    {
      "NUM_FINANCIER_CD": 9207,
      "TXT_FINANCIER_NAME": "ASSOCIATED CAPSULES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9208,
      "TXT_FINANCIER_NAME": "SRI KANIKA PARAMESHWARI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9209,
      "TXT_FINANCIER_NAME": "SRI LAKSHMI MULTIPURPOSE CO OP SOCIETY UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 9210,
      "TXT_FINANCIER_NAME": "SRI M VISHWESHWARAIAH BANK SAHAKAR BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9211,
      "TXT_FINANCIER_NAME": "SRI MATHA MAHILA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9212,
      "TXT_FINANCIER_NAME": "SRI YAGNAVALKYA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9213,
      "TXT_FINANCIER_NAME": "SRINATH COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9214,
      "TXT_FINANCIER_NAME": "SRISHARADA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9215,
      "TXT_FINANCIER_NAME": "SRISHARADA CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9216,
      "TXT_FINANCIER_NAME": "ST MILAGRES CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9217,
      "TXT_FINANCIER_NAME": "STANDARD CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9218,
      "TXT_FINANCIER_NAME": "STATE TRANSPOERT CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9219,
      "TXT_FINANCIER_NAME": "SUDARSHAN NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9220,
      "TXT_FINANCIER_NAME": "SUDARSHAN NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9221,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAVJI URBAN CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 9222,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAWAJI UR CO OP BANK LTD JINTUR"
    },
    {
      "NUM_FINANCIER_CD": 9223,
      "TXT_FINANCIER_NAME": "SURAT DISTRICT CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9224,
      "TXT_FINANCIER_NAME": "SURYAPUR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9225,
      "TXT_FINANCIER_NAME": "Sushilkumar Nahata Urban Co-op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9226,
      "TXT_FINANCIER_NAME": "PRISM CEMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9227,
      "TXT_FINANCIER_NAME": "HEERA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9228,
      "TXT_FINANCIER_NAME": "POHEGAON GRAMIN BIGARSHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9229,
      "TXT_FINANCIER_NAME": "JAYWANTRAO BHOSALE NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9230,
      "TXT_FINANCIER_NAME": "S R I VENKATESHWARA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9231,
      "TXT_FINANCIER_NAME": "MAHAVIR GRAMIN BIGARSHETI SAHAKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9232,
      "TXT_FINANCIER_NAME": "SARVODAY COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9233,
      "TXT_FINANCIER_NAME": "GANESH CAPFIN LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9234,
      "TXT_FINANCIER_NAME": "DHANVANTARI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9235,
      "TXT_FINANCIER_NAME": "SHRIKRUPA NAGARI SAHAKARI PATHPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9236,
      "TXT_FINANCIER_NAME": "CITIZEN FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 9237,
      "TXT_FINANCIER_NAME": "APEX ABISHEK FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9238,
      "TXT_FINANCIER_NAME": "BHAGAD BANK"
    },
    {
      "NUM_FINANCIER_CD": 9239,
      "TXT_FINANCIER_NAME": "COCHIN REFINARY"
    },
    {
      "NUM_FINANCIER_CD": 9240,
      "TXT_FINANCIER_NAME": "NESARGI URBAN CO OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9241,
      "TXT_FINANCIER_NAME": "C M F L"
    },
    {
      "NUM_FINANCIER_CD": 9242,
      "TXT_FINANCIER_NAME": "THE MAPUSA MERCHANTS URBAN COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9243,
      "TXT_FINANCIER_NAME": "THE STSTE TRANSPORT (E) CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9244,
      "TXT_FINANCIER_NAME": "THE SURAT MERKANTILE CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9245,
      "TXT_FINANCIER_NAME": "THE TALOD NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9246,
      "TXT_FINANCIER_NAME": "THE TARAPUR CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9247,
      "TXT_FINANCIER_NAME": "THE TELLICHERRY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9248,
      "TXT_FINANCIER_NAME": "THE TEXTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9249,
      "TXT_FINANCIER_NAME": "THE TEXTILE PROCESSORS CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9250,
      "TXT_FINANCIER_NAME": "The Thane Dist. Central Co-op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 9251,
      "TXT_FINANCIER_NAME": "THE THIDANADU SERCICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9252,
      "TXT_FINANCIER_NAME": "THE THSNE JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9253,
      "TXT_FINANCIER_NAME": "THE TUMKUR DIST CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9254,
      "TXT_FINANCIER_NAME": "Vizianagaram District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9255,
      "TXT_FINANCIER_NAME": "SURAT NAGRIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9256,
      "TXT_FINANCIER_NAME": "SHRI SHIVALINGESHWAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9257,
      "TXT_FINANCIER_NAME": "SENIOR PRINCIPAL BSNL RTTC"
    },
    {
      "NUM_FINANCIER_CD": 9258,
      "TXT_FINANCIER_NAME": "NAV SHAKTI FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 9259,
      "TXT_FINANCIER_NAME": "NAVAKARNATKA PATTINA SOUHARDA SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9260,
      "TXT_FINANCIER_NAME": "VISHWA NAGARI SAHAKARI PATSASHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9261,
      "TXT_FINANCIER_NAME": "vyankatesh nagari sah path sanstha ltd kop"
    },
    {
      "NUM_FINANCIER_CD": 9262,
      "TXT_FINANCIER_NAME": "THE SARASWATI INDUSTRIAL SYNDICATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9263,
      "TXT_FINANCIER_NAME": "THE BHATKAL AGRIL AND RURAL DEVELOPMENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9264,
      "TXT_FINANCIER_NAME": "SRI KANNIKA PARMESHWARI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9265,
      "TXT_FINANCIER_NAME": "THE BINOR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9266,
      "TXT_FINANCIER_NAME": "COMPTOLLER UNIVERSITY OF AGRICULTURAL SCIENCES DHARWAD"
    },
    {
      "NUM_FINANCIER_CD": 9267,
      "TXT_FINANCIER_NAME": "DHANDAPABNI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9268,
      "TXT_FINANCIER_NAME": "SRI LAKSHMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9269,
      "TXT_FINANCIER_NAME": "CARRIER AIRCONDITIONING AND REFRIGERATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 9270,
      "TXT_FINANCIER_NAME": "SHREE SANT GOROBA KAKA NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9271,
      "TXT_FINANCIER_NAME": "TULJBHAVANI GRAMIN BIGAR SAHAKARI PAT"
    },
    {
      "NUM_FINANCIER_CD": 9272,
      "TXT_FINANCIER_NAME": "VISVESWARA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9273,
      "TXT_FINANCIER_NAME": "ADGAON VIVIDH KARYAKARI SAHAKARI VIKAS SEVA SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 9274,
      "TXT_FINANCIER_NAME": "SHRINIDHI CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9275,
      "TXT_FINANCIER_NAME": "M SANJAY SHANKLA HUF"
    },
    {
      "NUM_FINANCIER_CD": 9276,
      "TXT_FINANCIER_NAME": "ARVIND CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 9277,
      "TXT_FINANCIER_NAME": "ARVIND PRODUCTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 9278,
      "TXT_FINANCIER_NAME": "ATTUR THULUVA VELALAR SANGAM"
    },
    {
      "NUM_FINANCIER_CD": 9279,
      "TXT_FINANCIER_NAME": "avhpc"
    },
    {
      "NUM_FINANCIER_CD": 9280,
      "TXT_FINANCIER_NAME": "B MAHAVEERCHAND SHANKLA"
    },
    {
      "NUM_FINANCIER_CD": 9281,
      "TXT_FINANCIER_NAME": "BACARDI MARTINI INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9282,
      "TXT_FINANCIER_NAME": "BAHUUDESHIYA NAGARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9283,
      "TXT_FINANCIER_NAME": "BAJAJ HIRERS & LESSORS LTD"
    },
    {
      "NUM_FINANCIER_CD": 9284,
      "TXT_FINANCIER_NAME": "BALMER LAWRIE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 9285,
      "TXT_FINANCIER_NAME": "BANK OF GOA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9286,
      "TXT_FINANCIER_NAME": "BHANDARI LEAFIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 9287,
      "TXT_FINANCIER_NAME": "BHARAT PETROLIUM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 9288,
      "TXT_FINANCIER_NAME": "BHEL ECCS LTD"
    },
    {
      "NUM_FINANCIER_CD": 9289,
      "TXT_FINANCIER_NAME": "M/S I C I C I BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9290,
      "TXT_FINANCIER_NAME": "THE KAMRAN VIKAS CO OP CREDIT SOCIETY LTD ILOL"
    },
    {
      "NUM_FINANCIER_CD": 9291,
      "TXT_FINANCIER_NAME": "SAHAKARI VYAVASAYIKA BANK LTD SIDDAPURA"
    },
    {
      "NUM_FINANCIER_CD": 9292,
      "TXT_FINANCIER_NAME": "S S AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9293,
      "TXT_FINANCIER_NAME": "PRATAP GRAMIN BIGAR SHETE PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9294,
      "TXT_FINANCIER_NAME": "ANAND NAGRIK CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9295,
      "TXT_FINANCIER_NAME": "SARVODAY COM CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9296,
      "TXT_FINANCIER_NAME": "MAGMA FINCROP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9297,
      "TXT_FINANCIER_NAME": "THE CONTROLLERS OFFICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9298,
      "TXT_FINANCIER_NAME": "BAJWA URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9299,
      "TXT_FINANCIER_NAME": "SHRI SIDHESHWAR GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9300,
      "TXT_FINANCIER_NAME": "VAISHNAVI NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9301,
      "TXT_FINANCIER_NAME": "VIJAYA NAGAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9302,
      "TXT_FINANCIER_NAME": "SHREE SIDDHESHWAR GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9303,
      "TXT_FINANCIER_NAME": "SHRI ADINATH ALPASANKHYATARA CREDIT SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9304,
      "TXT_FINANCIER_NAME": "THUNGABHADRA CRIEDIT CO OP SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9305,
      "TXT_FINANCIER_NAME": "SIR M VISVESHVARAYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9306,
      "TXT_FINANCIER_NAME": "GURUMAULI PARAMPUJYA ANNASAHEB MORE NAG SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 9307,
      "TXT_FINANCIER_NAME": "SHARDAMAI NAGRI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9308,
      "TXT_FINANCIER_NAME": "PONNANI CO OP AGRICULTURAL AND RUARAL DEVP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9309,
      "TXT_FINANCIER_NAME": "MARMAGAO PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 9310,
      "TXT_FINANCIER_NAME": "J RATAN CHAND BOTHRA"
    },
    {
      "NUM_FINANCIER_CD": 9311,
      "TXT_FINANCIER_NAME": "THE ANANT SAHAKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9312,
      "TXT_FINANCIER_NAME": "SRI BHAIRAVESHWARA SAHAKARA BANK NIYAMITHA TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 9313,
      "TXT_FINANCIER_NAME": "DELMIA SOLUTIONS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9314,
      "TXT_FINANCIER_NAME": "SREE BHAIRAVESHWARA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9315,
      "TXT_FINANCIER_NAME": "A KRUSHNARAO BHEGDE NAG SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9316,
      "TXT_FINANCIER_NAME": "AVRAL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9317,
      "TXT_FINANCIER_NAME": "SHARAD GRAMIN BIGARSHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 9318,
      "TXT_FINANCIER_NAME": "EQUITAS MICRO FINANCE INDIA P LTD"
    },
    {
      "NUM_FINANCIER_CD": 9319,
      "TXT_FINANCIER_NAME": "THE SAHORA COOP AGRI SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9320,
      "TXT_FINANCIER_NAME": "SIR M VISVESWARAYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9321,
      "TXT_FINANCIER_NAME": "AASHIRWAD ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 9322,
      "TXT_FINANCIER_NAME": "RAMESH CHAND BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 9323,
      "TXT_FINANCIER_NAME": "DUDHERIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9324,
      "TXT_FINANCIER_NAME": "THANA MAHESH NAGRI CO OP CRD PATHPEDI"
    },
    {
      "NUM_FINANCIER_CD": 9325,
      "TXT_FINANCIER_NAME": "S KARTHICK FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9326,
      "TXT_FINANCIER_NAME": "S J M CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9327,
      "TXT_FINANCIER_NAME": "SRI VITTLA GRAMEENA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9328,
      "TXT_FINANCIER_NAME": "K MADAN CHAND"
    },
    {
      "NUM_FINANCIER_CD": 9329,
      "TXT_FINANCIER_NAME": "PADUKONE VYAVASAYA SEVA SAHAKARI BANK N NADA KUNDAPURA"
    },
    {
      "NUM_FINANCIER_CD": 9330,
      "TXT_FINANCIER_NAME": "SANJAY M MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 9331,
      "TXT_FINANCIER_NAME": "RATNATRAY GR BG SH SAH PTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 9332,
      "TXT_FINANCIER_NAME": "DURGA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9333,
      "TXT_FINANCIER_NAME": "SARASWATI SUGAR MILLS LTD"
    },
    {
      "NUM_FINANCIER_CD": 9334,
      "TXT_FINANCIER_NAME": "DHANESH"
    },
    {
      "NUM_FINANCIER_CD": 9335,
      "TXT_FINANCIER_NAME": "BASVESHWAR GRAMIN BIGAR SHETI SAH PAT SANSTHA JATH"
    },
    {
      "NUM_FINANCIER_CD": 9336,
      "TXT_FINANCIER_NAME": "GEMAC"
    },
    {
      "NUM_FINANCIER_CD": 9337,
      "TXT_FINANCIER_NAME": "MARCHANT PANDHARPUR CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9338,
      "TXT_FINANCIER_NAME": "MANGALORE CATHOLIC CREDIT COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9339,
      "TXT_FINANCIER_NAME": "NAGAR VIKAS SHAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 9340,
      "TXT_FINANCIER_NAME": "INFORMATICS STAFF WELFARE TRUST"
    },
    {
      "NUM_FINANCIER_CD": 9341,
      "TXT_FINANCIER_NAME": "NEW INDIA INSURANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9342,
      "TXT_FINANCIER_NAME": "KSFC"
    },
    {
      "NUM_FINANCIER_CD": 9343,
      "TXT_FINANCIER_NAME": "MANVI PATTINA SOUHARDA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9344,
      "TXT_FINANCIER_NAME": "VIJAYA SOUHARD CREDIT SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9345,
      "TXT_FINANCIER_NAME": "SWARNA SWARNA BHARATHI SAHAKARA BANK NYMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9346,
      "TXT_FINANCIER_NAME": "TAPI CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9347,
      "TXT_FINANCIER_NAME": "THADIKADAVU SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9348,
      "TXT_FINANCIER_NAME": "Thane Baharat Sahakari Bank.Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9349,
      "TXT_FINANCIER_NAME": "THE PONKUNNAM DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9350,
      "TXT_FINANCIER_NAME": "THE A P MAHESH CO-OP. URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9351,
      "TXT_FINANCIER_NAME": "THE A.B.E CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8760,
      "TXT_FINANCIER_NAME": "THE CHALTHAN DIST CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8761,
      "TXT_FINANCIER_NAME": "THE MARCEL URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8762,
      "TXT_FINANCIER_NAME": "THE PRAGATI YUVAK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8763,
      "TXT_FINANCIER_NAME": "COLRD NIKAM SAINIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8764,
      "TXT_FINANCIER_NAME": "SUVARNA AUTO FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8765,
      "TXT_FINANCIER_NAME": "KARMAYOGI MURLIDHAR KHATOD JANLAXMI GR BI SHETI SHA PAT MARY"
    },
    {
      "NUM_FINANCIER_CD": 8766,
      "TXT_FINANCIER_NAME": "KAILASH NAGARI SAHAKARI PATASANSTHA TRAMBAK"
    },
    {
      "NUM_FINANCIER_CD": 8767,
      "TXT_FINANCIER_NAME": "PARAS MAHAVIR COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 8768,
      "TXT_FINANCIER_NAME": "AKANSHA NAGAR SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8769,
      "TXT_FINANCIER_NAME": "THE G S S M AND K DIS CENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 8770,
      "TXT_FINANCIER_NAME": "THE NAMAKKAL CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8771,
      "TXT_FINANCIER_NAME": "The Namakkal Cooperative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 8772,
      "TXT_FINANCIER_NAME": "THE NANDURBAR MARCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8773,
      "TXT_FINANCIER_NAME": "THE NARANAMMOOZHY SERVICE CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8774,
      "TXT_FINANCIER_NAME": "THE NASHIK DISTRICT GIRNA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8775,
      "TXT_FINANCIER_NAME": "THE NASHIK PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8776,
      "TXT_FINANCIER_NAME": "THE NASIK MARCHANT CO-OP BANK LTD NASIK"
    },
    {
      "NUM_FINANCIER_CD": 8777,
      "TXT_FINANCIER_NAME": "THE NASIK MARCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8778,
      "TXT_FINANCIER_NAME": "THE NATIONAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8779,
      "TXT_FINANCIER_NAME": "THE NAV JEEVAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8780,
      "TXT_FINANCIER_NAME": "THE NEW INDIA ASSURANCE CO.LTD."
    },
    {
      "NUM_FINANCIER_CD": 8781,
      "TXT_FINANCIER_NAME": "THE NEW URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8782,
      "TXT_FINANCIER_NAME": "THE NEW URBAN CO-OPRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8783,
      "TXT_FINANCIER_NAME": "THE NILAMBUR CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8784,
      "TXT_FINANCIER_NAME": "THE NIRMAL UJWAL CREDIT CO-OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 8785,
      "TXT_FINANCIER_NAME": "THE NORTH KANARA G.S.B CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8786,
      "TXT_FINANCIER_NAME": "CHENNAI PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 8787,
      "TXT_FINANCIER_NAME": "SAHAKARI PATTINA SANGH NIYANIT"
    },
    {
      "NUM_FINANCIER_CD": 8788,
      "TXT_FINANCIER_NAME": "CHALLANI MAHAVEER CHAND"
    },
    {
      "NUM_FINANCIER_CD": 8789,
      "TXT_FINANCIER_NAME": "DEFENCE ACC CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8790,
      "TXT_FINANCIER_NAME": "LOKSEVA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 8791,
      "TXT_FINANCIER_NAME": "LANJHA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8792,
      "TXT_FINANCIER_NAME": "AMBEDINDORI VIVIDH KARYAKARI SAHKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8793,
      "TXT_FINANCIER_NAME": "CITY INDUSTRIAL DEVELOPMENT CORPORATION OF MAHARASHTRA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8794,
      "TXT_FINANCIER_NAME": "DNYANDEEP CREDIT CO OPSOCIETY LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 8795,
      "TXT_FINANCIER_NAME": "MAHINDRA& MAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 8796,
      "TXT_FINANCIER_NAME": "M/S MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 8797,
      "TXT_FINANCIER_NAME": "MAHINDRA&ampMAHINDRA FINANCIAL SERVICES LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 8798,
      "TXT_FINANCIER_NAME": "MAHINDR & MAHINDRA FIN. SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8799,
      "TXT_FINANCIER_NAME": "MAHINDRA MAHINDRA FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 8800,
      "TXT_FINANCIER_NAME": "mcf finlease pvt ltd"
    },
    {
      "NUM_FINANCIER_CD": 8801,
      "TXT_FINANCIER_NAME": "MHAJAN FINANCE ALIGARH"
    },
    {
      "NUM_FINANCIER_CD": 8802,
      "TXT_FINANCIER_NAME": "MOHAN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 8803,
      "TXT_FINANCIER_NAME": "MONEY MASTERS, LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 8804,
      "TXT_FINANCIER_NAME": "NEELAM CHAND FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 8805,
      "TXT_FINANCIER_NAME": "AXIX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8806,
      "TXT_FINANCIER_NAME": "UTI AMC PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 8807,
      "TXT_FINANCIER_NAME": "THE SISODRA VIBHAG VIVIDH KARYAKARI COOPMANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8808,
      "TXT_FINANCIER_NAME": "SIRA TALUK SRI KANAKA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8809,
      "TXT_FINANCIER_NAME": "ROSARY CREDIT CO OPERATIVE SOCIETY LTD KUNDAPURA"
    },
    {
      "NUM_FINANCIER_CD": 8810,
      "TXT_FINANCIER_NAME": "LADURAM DUNGARWAL FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 8811,
      "TXT_FINANCIER_NAME": "THE MOGA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8812,
      "TXT_FINANCIER_NAME": "ARIHANT NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8813,
      "TXT_FINANCIER_NAME": "LAKSHMI SOUHARDA CREDIT CO OPERATIV BANK"
    },
    {
      "NUM_FINANCIER_CD": 8814,
      "TXT_FINANCIER_NAME": "THE ADARSH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8815,
      "TXT_FINANCIER_NAME": "THE YAVATMAL DIST CENTRAL CO OP BANK LTD YAVATMAL"
    },
    {
      "NUM_FINANCIER_CD": 8816,
      "TXT_FINANCIER_NAME": "VEJALPORE SEVA SAHKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 8817,
      "TXT_FINANCIER_NAME": "AAKAR SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 8818,
      "TXT_FINANCIER_NAME": "UGCDAE CONSORTIUM FOR SCIENTIFIC RESEARCH"
    },
    {
      "NUM_FINANCIER_CD": 8819,
      "TXT_FINANCIER_NAME": "NASHIK DIST GIRANA CO OP BANK LTD NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 8820,
      "TXT_FINANCIER_NAME": "BIJAPUR GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8821,
      "TXT_FINANCIER_NAME": "BOLANGIR ANCHALIKA GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8822,
      "TXT_FINANCIER_NAME": "BUNDELKHAND KESHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8823,
      "TXT_FINANCIER_NAME": "CAUVERY GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 8824,
      "TXT_FINANCIER_NAME": "Chickmagalur Kodagu Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 8825,
      "TXT_FINANCIER_NAME": "DAMOH PANNA SAGAR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8826,
      "TXT_FINANCIER_NAME": "ELLAQAI DEHATI BANK"
    },
    {
      "NUM_FINANCIER_CD": 8827,
      "TXT_FINANCIER_NAME": "ELLQUAI DEHATI BANK"
    },
    {
      "NUM_FINANCIER_CD": 8828,
      "TXT_FINANCIER_NAME": "FAIZABAD KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8829,
      "TXT_FINANCIER_NAME": "GORAKHPUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8830,
      "TXT_FINANCIER_NAME": "GORAKHPUR KSHETYRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8831,
      "TXT_FINANCIER_NAME": "GURDASPUR-AMRITSAR KSHETRIYA GRAMIN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 8832,
      "TXT_FINANCIER_NAME": "Himachal Grahim Bank"
    },
    {
      "NUM_FINANCIER_CD": 8833,
      "TXT_FINANCIER_NAME": "JAI BAJARANG GRAMIHN BIGAR SHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8834,
      "TXT_FINANCIER_NAME": "JAMNAGAR RAJKOT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8835,
      "TXT_FINANCIER_NAME": "Kalpatharu Grameena Bank"
    },
    {
      "NUM_FINANCIER_CD": 8836,
      "TXT_FINANCIER_NAME": "KHETRAI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 8837,
      "TXT_FINANCIER_NAME": "SRI SWAMY VIVEKANANDA CREDIT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8838,
      "TXT_FINANCIER_NAME": "ASTRA ZENECA PHARMA INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 8839,
      "TXT_FINANCIER_NAME": "SHREE SHIVAJI MAHARAJ CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8840,
      "TXT_FINANCIER_NAME": "THE AG OFFICE EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8841,
      "TXT_FINANCIER_NAME": "MS THE HARYANA STATE CO OPERATIVE AGRICULTURE AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 8842,
      "TXT_FINANCIER_NAME": "CHHANI LEUWA PATIDAR CO SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8843,
      "TXT_FINANCIER_NAME": "AMIRTHAM LEASING"
    },
    {
      "NUM_FINANCIER_CD": 8844,
      "TXT_FINANCIER_NAME": "JAI LAXMI AUTO FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 8845,
      "TXT_FINANCIER_NAME": "SHIRIRAM TRANSPORT FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 8846,
      "TXT_FINANCIER_NAME": "DHARAM CHAND BOKADIA"
    },
    {
      "NUM_FINANCIER_CD": 8847,
      "TXT_FINANCIER_NAME": "THE DHARMADAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 8848,
      "TXT_FINANCIER_NAME": "SHIVAI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 8849,
      "TXT_FINANCIER_NAME": "SHREE MORBI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8850,
      "TXT_FINANCIER_NAME": "Shree Parshwanath Co-op. Bank Ltd. Sangli."
    },
    {
      "NUM_FINANCIER_CD": 8851,
      "TXT_FINANCIER_NAME": "Shree Parshwanath Nagari Sah. Patsanstha Marya., Karad"
    },
    {
      "NUM_FINANCIER_CD": 8852,
      "TXT_FINANCIER_NAME": "SHREE SAIGANESH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 8853,
      "TXT_FINANCIER_NAME": "SHREE SATYA SAI NAGRIK SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 8854,
      "TXT_FINANCIER_NAME": "SHREE SAVLI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 8855,
      "TXT_FINANCIER_NAME": "SHREE SWAMI SAMARTH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 10137,
      "TXT_FINANCIER_NAME": "AMRELI GAJERAPARA REVA SAHKARI MANDAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10138,
      "TXT_FINANCIER_NAME": "TALUK AGRICULTURAL COOP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10139,
      "TXT_FINANCIER_NAME": "THE NAGRIK SARAFI SAHKARIMANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10140,
      "TXT_FINANCIER_NAME": "THE BORSAD PEOPLES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10141,
      "TXT_FINANCIER_NAME": "SAIL CHANDRAPUR FERROALLOY PLANT"
    },
    {
      "NUM_FINANCIER_CD": 10142,
      "TXT_FINANCIER_NAME": "SHIV JYOTI SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10143,
      "TXT_FINANCIER_NAME": "THE MEHLAV PEOPLES CO OP CRE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10144,
      "TXT_FINANCIER_NAME": "DASH LEASING AND FINANCE PVT"
    },
    {
      "NUM_FINANCIER_CD": 10145,
      "TXT_FINANCIER_NAME": "BANGALORE CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10146,
      "TXT_FINANCIER_NAME": "MUTHA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10147,
      "TXT_FINANCIER_NAME": "ULLUR MACHCHATTU VYAVASAYA SEVA SAHAKARI SANGA VARAHI"
    },
    {
      "NUM_FINANCIER_CD": 10148,
      "TXT_FINANCIER_NAME": "ADITYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10149,
      "TXT_FINANCIER_NAME": "KASABA TARALE VYAPARI GRAMIN BIGAR SHETI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10150,
      "TXT_FINANCIER_NAME": "AKSHAYA CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10151,
      "TXT_FINANCIER_NAME": "ECHO SHREE ANK FINHOLD PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10152,
      "TXT_FINANCIER_NAME": "U K SAHYADRI CR CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10153,
      "TXT_FINANCIER_NAME": "M K CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10154,
      "TXT_FINANCIER_NAME": "SHRI DATTA DIGAMBAR NAGARI SAH PATH"
    },
    {
      "NUM_FINANCIER_CD": 10155,
      "TXT_FINANCIER_NAME": "TOTSOL FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 10156,
      "TXT_FINANCIER_NAME": "SUBODH SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10157,
      "TXT_FINANCIER_NAME": "VEERA MAHESHWARA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10158,
      "TXT_FINANCIER_NAME": "PRASHIK JANATA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10159,
      "TXT_FINANCIER_NAME": "THE CARDAMAM MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10160,
      "TXT_FINANCIER_NAME": "AGRICULTUR RURAL DEVLOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10161,
      "TXT_FINANCIER_NAME": "DEEN BANDHU CHOTURAM UNIVERSITY OF SCIENCE AND TECHNOLOGY"
    },
    {
      "NUM_FINANCIER_CD": 10162,
      "TXT_FINANCIER_NAME": "MAHAVEER CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10163,
      "TXT_FINANCIER_NAME": "PARFIK JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10164,
      "TXT_FINANCIER_NAME": "ORISSA POWER GENERATION CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10165,
      "TXT_FINANCIER_NAME": "BILASPUR NAGRIK SHAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 10166,
      "TXT_FINANCIER_NAME": "JAI TULJABHAVANI SAHAKARI PATHAPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10167,
      "TXT_FINANCIER_NAME": "SANE GURUJI GRAMIN BIGAR SHETI SAH PATSANTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10168,
      "TXT_FINANCIER_NAME": "AMAN NAGRI SAHAKRI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10169,
      "TXT_FINANCIER_NAME": "SRI RAMAJAYAM"
    },
    {
      "NUM_FINANCIER_CD": 10170,
      "TXT_FINANCIER_NAME": "THE LONAR URBAN CO OP CREDIT SOCEITY LTE"
    },
    {
      "NUM_FINANCIER_CD": 10171,
      "TXT_FINANCIER_NAME": "AP STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10172,
      "TXT_FINANCIER_NAME": "JCT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10173,
      "TXT_FINANCIER_NAME": "P CHAINRAJ LALWANI"
    },
    {
      "NUM_FINANCIER_CD": 10174,
      "TXT_FINANCIER_NAME": "NANESH CORPORATION AUTOMOBILE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 10175,
      "TXT_FINANCIER_NAME": "SADVABNA NAGRIK SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10176,
      "TXT_FINANCIER_NAME": "GAURAV AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10177,
      "TXT_FINANCIER_NAME": "SNEHA SANGAM PATHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10178,
      "TXT_FINANCIER_NAME": "NIR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10179,
      "TXT_FINANCIER_NAME": "KHANJAN FINANCE AND LEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10180,
      "TXT_FINANCIER_NAME": "SHRI MATHA AUTO FIN"
    },
    {
      "NUM_FINANCIER_CD": 10181,
      "TXT_FINANCIER_NAME": "CHHATTISHGARH STATE POWER DISTRIBUTION COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10182,
      "TXT_FINANCIER_NAME": "MARKANDEY NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10183,
      "TXT_FINANCIER_NAME": "VAIKOM TALUK MOTOR WORKERS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10184,
      "TXT_FINANCIER_NAME": "DINDAYAL NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10185,
      "TXT_FINANCIER_NAME": "KOSHAL ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 10186,
      "TXT_FINANCIER_NAME": "YESH INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 10187,
      "TXT_FINANCIER_NAME": "SRI RAM SOUHARDHA CREDIT CO OP SOCIETY BYNDOOR"
    },
    {
      "NUM_FINANCIER_CD": 10188,
      "TXT_FINANCIER_NAME": "THE NANDIGADDA SEVA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10189,
      "TXT_FINANCIER_NAME": "SHRI YASHODHAN NAGARI SAHAKARI PATHSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10190,
      "TXT_FINANCIER_NAME": "ENDEKA CERAMICS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10191,
      "TXT_FINANCIER_NAME": "SRI ANDAVAR INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 10192,
      "TXT_FINANCIER_NAME": "JAIKISAN GRAMIN BIGAR SHETI SAH SAN"
    },
    {
      "NUM_FINANCIER_CD": 10193,
      "TXT_FINANCIER_NAME": "AUNDHESHWAR GRAMIN BIGAR SHETI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10194,
      "TXT_FINANCIER_NAME": "RAJENDRA CHORDIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 10195,
      "TXT_FINANCIER_NAME": "BADI GRAM SEVA SAHKARI SAMETI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10196,
      "TXT_FINANCIER_NAME": "LLOYDS LINE PIPES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10197,
      "TXT_FINANCIER_NAME": "GERA LEASING"
    },
    {
      "NUM_FINANCIER_CD": 10198,
      "TXT_FINANCIER_NAME": "GHOGRAI NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10199,
      "TXT_FINANCIER_NAME": "SHRI CHITNYA NAGRI SAHKAKRI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 10200,
      "TXT_FINANCIER_NAME": "SREE JENUKAL SOUHARDA PATHINA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 10201,
      "TXT_FINANCIER_NAME": "GOVINDA BANK"
    },
    {
      "NUM_FINANCIER_CD": 10202,
      "TXT_FINANCIER_NAME": "EKM COOP AGRL AND RURAL DEV BANK"
    },
    {
      "NUM_FINANCIER_CD": 10203,
      "TXT_FINANCIER_NAME": "SHREE BANSHANKARI GRAMIN BIGARSHETI SAHAKARI SANSTHA MARY MOHOL"
    },
    {
      "NUM_FINANCIER_CD": 10204,
      "TXT_FINANCIER_NAME": "SHRI BANSHANKARI GRAMIN BIGARSHETI SAHAKARI SANSTHA MARY MOHOL"
    },
    {
      "NUM_FINANCIER_CD": 10205,
      "TXT_FINANCIER_NAME": "PANJAB NATIONAL"
    },
    {
      "NUM_FINANCIER_CD": 10206,
      "TXT_FINANCIER_NAME": "THE GOA URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10207,
      "TXT_FINANCIER_NAME": "SHRI JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10208,
      "TXT_FINANCIER_NAME": "JANTA SARAFI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 10209,
      "TXT_FINANCIER_NAME": "R S S BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10210,
      "TXT_FINANCIER_NAME": "P JOHAR AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10211,
      "TXT_FINANCIER_NAME": "KEERAMBUR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10212,
      "TXT_FINANCIER_NAME": "AP INDUSTRIAL INFRAUCTURE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10213,
      "TXT_FINANCIER_NAME": "SHRI GANPATI NAGARI COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10214,
      "TXT_FINANCIER_NAME": "GANGA CO OPERATIVE CREDIT SOCEITY"
    },
    {
      "NUM_FINANCIER_CD": 10215,
      "TXT_FINANCIER_NAME": "NEW SATARA ZILLHA NAGARIK SAHAKARI PATHSANSTHA MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 10216,
      "TXT_FINANCIER_NAME": "M G AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10217,
      "TXT_FINANCIER_NAME": "SATARA Z P PARISHAD SEVAK SAH PAT PEDHI MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10218,
      "TXT_FINANCIER_NAME": "OM GORAI NSP MARYADIT PAT PEDI"
    },
    {
      "NUM_FINANCIER_CD": 10219,
      "TXT_FINANCIER_NAME": "G G NAHAR AND CO AUTO FIN"
    },
    {
      "NUM_FINANCIER_CD": 10220,
      "TXT_FINANCIER_NAME": "TAMIL NADU HOUSING BOARD"
    },
    {
      "NUM_FINANCIER_CD": 10221,
      "TXT_FINANCIER_NAME": "SRI KURUNCHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10222,
      "TXT_FINANCIER_NAME": "DNYANSAGAR NAG SAH P LTD"
    },
    {
      "NUM_FINANCIER_CD": 10223,
      "TXT_FINANCIER_NAME": "KALPAHAVRUKSHA CREDIT SOUHARDHA COPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10224,
      "TXT_FINANCIER_NAME": "BANGALORE BANGALORE GRAMANTARA MATTU RAMANAGARA JILLA SAHAKARA KENDRA BANK NAUKARARA SAHAKARA SANGA"
    },
    {
      "NUM_FINANCIER_CD": 10225,
      "TXT_FINANCIER_NAME": "KARMVEER BAHURAO PATIL NAGARI SAH PATH SANSTHA MAR SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 10226,
      "TXT_FINANCIER_NAME": "SREERAJ FINACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10227,
      "TXT_FINANCIER_NAME": "DETROJ SEVA SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 10228,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10229,
      "TXT_FINANCIER_NAME": "KARUR AMMAN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10230,
      "TXT_FINANCIER_NAME": "STATE BANK OF AMRICA"
    },
    {
      "NUM_FINANCIER_CD": 10231,
      "TXT_FINANCIER_NAME": "YADEGERE SOUHARDA CREDIT CO OPARATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10232,
      "TXT_FINANCIER_NAME": "B MAHENDRA JAIN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10233,
      "TXT_FINANCIER_NAME": "SHRI DATTAKRUPA NAGARI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10234,
      "TXT_FINANCIER_NAME": "KESHAV MADHAV SAHAKARI NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9450,
      "TXT_FINANCIER_NAME": "THE PRIVATE SCHOOL"
    },
    {
      "NUM_FINANCIER_CD": 9451,
      "TXT_FINANCIER_NAME": "VALSAD BHILAD CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9452,
      "TXT_FINANCIER_NAME": "ARYAVYSYA SRI RAMA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9453,
      "TXT_FINANCIER_NAME": "THE GIRISH URBAN CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9454,
      "TXT_FINANCIER_NAME": "KURUMANCHAL NAGAR SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9455,
      "TXT_FINANCIER_NAME": "SHARDA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9456,
      "TXT_FINANCIER_NAME": "SREE ANJANEYA VIVIDODDESHA SRIHARDE SAHAKARA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9457,
      "TXT_FINANCIER_NAME": "KUMON URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9458,
      "TXT_FINANCIER_NAME": "NIRMAL UJJWAL SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9459,
      "TXT_FINANCIER_NAME": "DAUND JANTA NAGRI SAHKARI PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 9460,
      "TXT_FINANCIER_NAME": "SS AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 9461,
      "TXT_FINANCIER_NAME": "AP STATE POLICE HOUSING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 9462,
      "TXT_FINANCIER_NAME": "CITY FINANCIAL CONSUMER FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9463,
      "TXT_FINANCIER_NAME": "YASHOMATI SAHAKARI PATPEDHI MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 9464,
      "TXT_FINANCIER_NAME": "THE GOA STATE SC OBC FIN DEV CORP"
    },
    {
      "NUM_FINANCIER_CD": 9465,
      "TXT_FINANCIER_NAME": "AINEKEDU PRIMARY AGRICULTURAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9466,
      "TXT_FINANCIER_NAME": "AGNI BANNI RAYASWAMY CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9467,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER THERMAL DESIGNS KARNATAKA POWER CORPOATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 9468,
      "TXT_FINANCIER_NAME": "GOVT SEN SEC EDUCATION HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 9469,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9470,
      "TXT_FINANCIER_NAME": "BHEL CREDIT COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9471,
      "TXT_FINANCIER_NAME": "JAI SHRIRAM NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9472,
      "TXT_FINANCIER_NAME": "ITAGULI SEVA SAHAKARI SANGH"
    },
    {
      "NUM_FINANCIER_CD": 9473,
      "TXT_FINANCIER_NAME": "PREM INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9474,
      "TXT_FINANCIER_NAME": "THE BAGALKOT DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9475,
      "TXT_FINANCIER_NAME": "THE AKOLA COMM.CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9476,
      "TXT_FINANCIER_NAME": "THE AKOLA DIST. CENT. CO.OP. BANK LTD. AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 9477,
      "TXT_FINANCIER_NAME": "THE AKOLA JANTA COMMERCIAL CO-OP.BANK LTD.,AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 9478,
      "TXT_FINANCIER_NAME": "THE AMALNER CO-OP URBAN BANK LTD AMALNER"
    },
    {
      "NUM_FINANCIER_CD": 9479,
      "TXT_FINANCIER_NAME": "THE AMARAVATHY SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9480,
      "TXT_FINANCIER_NAME": "THE AMBALA CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9481,
      "TXT_FINANCIER_NAME": "THE ANANTAPUR DIST CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9482,
      "TXT_FINANCIER_NAME": "THE AP MAHESH CO-OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9483,
      "TXT_FINANCIER_NAME": "THE AP RAJA RAJESHWARI MAHILA CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9484,
      "TXT_FINANCIER_NAME": "THE AP VARDHAMAN(MAHILA) CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9485,
      "TXT_FINANCIER_NAME": "THE ARALAM SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9486,
      "TXT_FINANCIER_NAME": "THE ASHTA PEOPLES CO-OP BANK LTD,ASHTA"
    },
    {
      "NUM_FINANCIER_CD": 9487,
      "TXT_FINANCIER_NAME": "THE ASSAM CO OPERATIVE APEX BANKLIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9488,
      "TXT_FINANCIER_NAME": "THE ASSAM COOPERATIVE APEX BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9489,
      "TXT_FINANCIER_NAME": "THE TRIMURTI CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9490,
      "TXT_FINANCIER_NAME": "SURYA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9491,
      "TXT_FINANCIER_NAME": "BHARATH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9492,
      "TXT_FINANCIER_NAME": "SHIRIYARA SAHAKARI VYAVASAYIKA BANK NIYAMITHA SAIBRAKATTE BANK NIYAMITHA SAIBRAKATTE"
    },
    {
      "NUM_FINANCIER_CD": 9493,
      "TXT_FINANCIER_NAME": "DANDELAPPA K P S BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9494,
      "TXT_FINANCIER_NAME": "PRASHANT INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9495,
      "TXT_FINANCIER_NAME": "RACHARDA GRAHMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9496,
      "TXT_FINANCIER_NAME": "GUJRAT HOUSING URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9497,
      "TXT_FINANCIER_NAME": "GMAC FINANCALSEL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9498,
      "TXT_FINANCIER_NAME": "SHREE VALLABH NAGARI PATHASANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9499,
      "TXT_FINANCIER_NAME": "PADMASHREE CREDIT COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9500,
      "TXT_FINANCIER_NAME": "HOTEL INDUSTRIALISTS COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9501,
      "TXT_FINANCIER_NAME": "SRI RAMA CHITS AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9502,
      "TXT_FINANCIER_NAME": "FAIMLY CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9503,
      "TXT_FINANCIER_NAME": "G MEK"
    },
    {
      "NUM_FINANCIER_CD": 9504,
      "TXT_FINANCIER_NAME": "S V SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 9505,
      "TXT_FINANCIER_NAME": "THE VIVIDHA URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9506,
      "TXT_FINANCIER_NAME": "THE YELLAPURURBAN CO OPERATIVE CREDIT SOCIETYLTD"
    },
    {
      "NUM_FINANCIER_CD": 9507,
      "TXT_FINANCIER_NAME": "THEDECCAN MERCHANTS CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9508,
      "TXT_FINANCIER_NAME": "THIRUNNAVAYA SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9509,
      "TXT_FINANCIER_NAME": "THRISSUR DIST.CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9510,
      "TXT_FINANCIER_NAME": "TRIVANDRUM DISTRICT CO-OPERTIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9511,
      "TXT_FINANCIER_NAME": "tTHE HASSAN DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9512,
      "TXT_FINANCIER_NAME": "TUKARAM URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9513,
      "TXT_FINANCIER_NAME": "U.P. CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9514,
      "TXT_FINANCIER_NAME": "UJJAIN NAGRIK SAHAKARI PEDHI MYDT."
    },
    {
      "NUM_FINANCIER_CD": 9515,
      "TXT_FINANCIER_NAME": "S T CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9516,
      "TXT_FINANCIER_NAME": "SUNDER AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 9517,
      "TXT_FINANCIER_NAME": "SRI DURGA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9518,
      "TXT_FINANCIER_NAME": "KRUSHI AND GRAMIN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 9519,
      "TXT_FINANCIER_NAME": "SHRI SARASWATI CREDIT SOUHARD SAHAKARA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9520,
      "TXT_FINANCIER_NAME": "VYTHIRI CO OP AGRICULTURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 9521,
      "TXT_FINANCIER_NAME": "SRI SRISHAILA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9522,
      "TXT_FINANCIER_NAME": "CHIKKMAGALUR KODAGU GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9523,
      "TXT_FINANCIER_NAME": "PITAMBAR FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9524,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN SAH BANK"
    },
    {
      "NUM_FINANCIER_CD": 9525,
      "TXT_FINANCIER_NAME": "TUMKUR TALUK INDUSTRIAL CO OP SOCIETY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9526,
      "TXT_FINANCIER_NAME": "B H E E EDN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9527,
      "TXT_FINANCIER_NAME": "R DILIP KUMAR JAIN HUF"
    },
    {
      "NUM_FINANCIER_CD": 9528,
      "TXT_FINANCIER_NAME": "SHRI SHWETAMBER JAIN CREDIT CO OPP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9529,
      "TXT_FINANCIER_NAME": "BHAIRODAN PAGARIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 9530,
      "TXT_FINANCIER_NAME": "KANARA DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9531,
      "TXT_FINANCIER_NAME": "DHANALXMI GRAMIN BIGAR SHETI SAHAKARI PATHASANTHA MATRYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9532,
      "TXT_FINANCIER_NAME": "VIVEKANAND NAG SAH PRATYAY SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9533,
      "TXT_FINANCIER_NAME": "SAMARTHA GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9534,
      "TXT_FINANCIER_NAME": "SPECIAL SECRETARY LEGISLATURE SECRETARIAT"
    },
    {
      "NUM_FINANCIER_CD": 9535,
      "TXT_FINANCIER_NAME": "THE RAJSTHAN STATE CORPORATION BANK"
    },
    {
      "NUM_FINANCIER_CD": 9536,
      "TXT_FINANCIER_NAME": "COCHIN"
    },
    {
      "NUM_FINANCIER_CD": 9537,
      "TXT_FINANCIER_NAME": "COCHIN REFINERIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 9538,
      "TXT_FINANCIER_NAME": "COCOCOLA INDIA PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 9539,
      "TXT_FINANCIER_NAME": "COLGATE PLAMOLIVE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9540,
      "TXT_FINANCIER_NAME": "COMPEER INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 9541,
      "TXT_FINANCIER_NAME": "COMPTROLLER UNIVERSITY OF AGRICULTURAL SCIENCES"
    },
    {
      "NUM_FINANCIER_CD": 9542,
      "TXT_FINANCIER_NAME": "COMPTROLLER UNIVERSITY OF AGRICULTURE SCIENCES"
    },
    {
      "NUM_FINANCIER_CD": 9543,
      "TXT_FINANCIER_NAME": "Concord Credit Limited"
    },
    {
      "NUM_FINANCIER_CD": 9544,
      "TXT_FINANCIER_NAME": "CORPORATION BK"
    },
    {
      "NUM_FINANCIER_CD": 9545,
      "TXT_FINANCIER_NAME": "CPRI"
    },
    {
      "NUM_FINANCIER_CD": 9546,
      "TXT_FINANCIER_NAME": "CSB"
    },
    {
      "NUM_FINANCIER_CD": 9547,
      "TXT_FINANCIER_NAME": "D G H S HARYANA PANCHKULA"
    },
    {
      "NUM_FINANCIER_CD": 9548,
      "TXT_FINANCIER_NAME": "D.I.P.R."
    },
    {
      "NUM_FINANCIER_CD": 9549,
      "TXT_FINANCIER_NAME": "D.S.M. CAPITALS LTD."
    },
    {
      "NUM_FINANCIER_CD": 9550,
      "TXT_FINANCIER_NAME": "DAR CREDIT & CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 9551,
      "TXT_FINANCIER_NAME": "DCB"
    },
    {
      "NUM_FINANCIER_CD": 9552,
      "TXT_FINANCIER_NAME": "DHANASHRI MAHILA PATSANSHTAMANGALWEDHA"
    },
    {
      "NUM_FINANCIER_CD": 9553,
      "TXT_FINANCIER_NAME": "DHARMAVIR SADASHIVRAO MANE PATIL PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9554,
      "TXT_FINANCIER_NAME": "DINANATH NAGARI PATSANSTHA."
    },
    {
      "NUM_FINANCIER_CD": 9555,
      "TXT_FINANCIER_NAME": "PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9556,
      "TXT_FINANCIER_NAME": "K D C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9557,
      "TXT_FINANCIER_NAME": "VIKRAM UMESH NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 9558,
      "TXT_FINANCIER_NAME": "KAVIVARYA MOROPANTH NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9559,
      "TXT_FINANCIER_NAME": "SUREKHA RAJESH NAHAR AUTOMOBILES FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9560,
      "TXT_FINANCIER_NAME": "SUSHIL INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 9561,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9562,
      "TXT_FINANCIER_NAME": "BAGYODAYA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9563,
      "TXT_FINANCIER_NAME": "Bharatpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9564,
      "TXT_FINANCIER_NAME": "Chittorgarh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 9565,
      "TXT_FINANCIER_NAME": "CO-OP. BANK OF RAJKOT LTD."
    },
    {
      "NUM_FINANCIER_CD": 9566,
      "TXT_FINANCIER_NAME": "COSMOS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9567,
      "TXT_FINANCIER_NAME": "DISTT CO-OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9568,
      "TXT_FINANCIER_NAME": "DOMBIVLI NAG. SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9569,
      "TXT_FINANCIER_NAME": "FAZILAKA CENTERAL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9570,
      "TXT_FINANCIER_NAME": "GADCHIROLI NAGRI SAHKARI BANK MARYA"
    },
    {
      "NUM_FINANCIER_CD": 9571,
      "TXT_FINANCIER_NAME": "GENERAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9572,
      "TXT_FINANCIER_NAME": "GOA STATE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9573,
      "TXT_FINANCIER_NAME": "Govt:SERVANTS Co-op:BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9574,
      "TXT_FINANCIER_NAME": "GUWAHATI CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9575,
      "TXT_FINANCIER_NAME": "JHARGRAM CO OP AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9576,
      "TXT_FINANCIER_NAME": "SRI SENTHOOR VELAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9577,
      "TXT_FINANCIER_NAME": "PRABHAKAR ANNA KORE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9578,
      "TXT_FINANCIER_NAME": "UNNATI GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9579,
      "TXT_FINANCIER_NAME": "MALABAR REGIONAL CO OPERATIVE MILK PRODUCERS UNION LTD"
    },
    {
      "NUM_FINANCIER_CD": 9580,
      "TXT_FINANCIER_NAME": "SHARNIRA NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9581,
      "TXT_FINANCIER_NAME": "THE YOGESHWAR CREDIT SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9582,
      "TXT_FINANCIER_NAME": "JANATA GRA BIGAR SHETI SAH PANT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9583,
      "TXT_FINANCIER_NAME": "FOR M SAMPATRAJ BOTHRA"
    },
    {
      "NUM_FINANCIER_CD": 9584,
      "TXT_FINANCIER_NAME": "GOKUL COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9585,
      "TXT_FINANCIER_NAME": "SHRI VEER TRANSPORT OPERATORS CO OP CREDIT AND SERV SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9586,
      "TXT_FINANCIER_NAME": "LESSOR L AND T FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9587,
      "TXT_FINANCIER_NAME": "SHRINATH NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9588,
      "TXT_FINANCIER_NAME": "CHHANI LEUWA PATIDAR CO SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9589,
      "TXT_FINANCIER_NAME": "AMBARNATH JAIHIND COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9590,
      "TXT_FINANCIER_NAME": "THE SATYANARAYAN NAGARI BIGERSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9591,
      "TXT_FINANCIER_NAME": "JANKALYAN NAGARIK SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9592,
      "TXT_FINANCIER_NAME": "SOURAV MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9593,
      "TXT_FINANCIER_NAME": "M SANJAYSHAHNKLA HUF"
    },
    {
      "NUM_FINANCIER_CD": 9594,
      "TXT_FINANCIER_NAME": "THE AYARKUNNAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9595,
      "TXT_FINANCIER_NAME": "THE BAGALKOT DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9596,
      "TXT_FINANCIER_NAME": "THE BAJVA ARBAN CO OP CREDIT SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 9597,
      "TXT_FINANCIER_NAME": "THE BANTRA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9598,
      "TXT_FINANCIER_NAME": "THE BAPUNAGAR MAHILA CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9599,
      "TXT_FINANCIER_NAME": "THE BAPUNAGAR MAHILA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9600,
      "TXT_FINANCIER_NAME": "THE BARODA TRADERS CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9601,
      "TXT_FINANCIER_NAME": "THE BELGAUM WESTERN ZONE COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9602,
      "TXT_FINANCIER_NAME": "THE BELGAUM-ZILLA RANI CHANNAMMA MAHILA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9603,
      "TXT_FINANCIER_NAME": "THE BELGUAM ZILLA RANI CHANNAMMA MAHILA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9604,
      "TXT_FINANCIER_NAME": "THE BELLARY DISTRICT CO-OP.CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 9605,
      "TXT_FINANCIER_NAME": "THE BEST CO OPREATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9606,
      "TXT_FINANCIER_NAME": "YASHODHAN MAHILA GRAHMIN BIGAR S SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9607,
      "TXT_FINANCIER_NAME": "AATHIRAI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9608,
      "TXT_FINANCIER_NAME": "THE GABAT NAGARIK SARAFI SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9609,
      "TXT_FINANCIER_NAME": "TODI MOTOR FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9610,
      "TXT_FINANCIER_NAME": "DRK INVESTMENT RAMALAKHSMAN CHEMBERS"
    },
    {
      "NUM_FINANCIER_CD": 9611,
      "TXT_FINANCIER_NAME": "HOERBIGER INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9612,
      "TXT_FINANCIER_NAME": "VENUGRAM MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9613,
      "TXT_FINANCIER_NAME": "KRIBHCO KRISHAK BHARTI CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9614,
      "TXT_FINANCIER_NAME": "MAYYANADU SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9615,
      "TXT_FINANCIER_NAME": "SUDARSHAN MOTOR INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 9616,
      "TXT_FINANCIER_NAME": "TOWN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9617,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER ELE VARAHI VARAHI HYDRO ELECTRIC KARNATAKA POWER CORPORATION LTD HOSANGADI"
    },
    {
      "NUM_FINANCIER_CD": 9618,
      "TXT_FINANCIER_NAME": "PARAKODE SER COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9619,
      "TXT_FINANCIER_NAME": "MADHUR MOTOR"
    },
    {
      "NUM_FINANCIER_CD": 9620,
      "TXT_FINANCIER_NAME": "KNANHA GYAN FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 9621,
      "TXT_FINANCIER_NAME": "CAPITAL ONE MULTIPURPOSE COOPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9622,
      "TXT_FINANCIER_NAME": "SHIRIYAR VYAVASAYA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 9623,
      "TXT_FINANCIER_NAME": "SENHA SANGAMA PATHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9624,
      "TXT_FINANCIER_NAME": "SAPTAGIRI PATTINA SOUHARDHA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 9625,
      "TXT_FINANCIER_NAME": "JANAKLAYAN NAGRI PATH SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 9626,
      "TXT_FINANCIER_NAME": "JINDAL STEEL AND POWER LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9627,
      "TXT_FINANCIER_NAME": "SOURTHERN STAR CO OP URBAN THRIFT AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9628,
      "TXT_FINANCIER_NAME": "OTP PRIMARY CO OP AGRI AND RURAL DEV BANK"
    },
    {
      "NUM_FINANCIER_CD": 9629,
      "TXT_FINANCIER_NAME": "PARAS HIRE PURCHASE CO INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9630,
      "TXT_FINANCIER_NAME": "GREATER LYCA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9631,
      "TXT_FINANCIER_NAME": "THE ANNASAHEB SAVANT CO OP URBAN BANK MAHAD LTD"
    },
    {
      "NUM_FINANCIER_CD": 9632,
      "TXT_FINANCIER_NAME": "AP STATE CIVIL SUPPLIES CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9633,
      "TXT_FINANCIER_NAME": "KODOLI NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9634,
      "TXT_FINANCIER_NAME": "EX SERVICEMEN MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9635,
      "TXT_FINANCIER_NAME": "NARSINGH GRAMIN BIGAR SETHI SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9636,
      "TXT_FINANCIER_NAME": "KISAN UNION GRAMIN BIGAR SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9637,
      "TXT_FINANCIER_NAME": "BHAIRAVNATH MALTI STATE CO OP CR SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9638,
      "TXT_FINANCIER_NAME": "RAJ KHATRI FIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9639,
      "TXT_FINANCIER_NAME": "CREATIVE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9640,
      "TXT_FINANCIER_NAME": "GERA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9641,
      "TXT_FINANCIER_NAME": "FEDERAL BANK INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9642,
      "TXT_FINANCIER_NAME": "ULTRATECH CEMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9643,
      "TXT_FINANCIER_NAME": "ROOPAMATA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9644,
      "TXT_FINANCIER_NAME": "THE MORBI CITIZENS CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9645,
      "TXT_FINANCIER_NAME": "COUNSIL OF ARCHITECTURE"
    },
    {
      "NUM_FINANCIER_CD": 9646,
      "TXT_FINANCIER_NAME": "THE SUBHLAXMI URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9647,
      "TXT_FINANCIER_NAME": "VIJAYA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9648,
      "TXT_FINANCIER_NAME": "RADHEY GAIR SHETAKI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9649,
      "TXT_FINANCIER_NAME": "MANASI MAHILA NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9650,
      "TXT_FINANCIER_NAME": "SUMEET MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 9651,
      "TXT_FINANCIER_NAME": "THE ARBUDA SHARAFI SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9652,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN LOKSEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9653,
      "TXT_FINANCIER_NAME": "AIDED PRIMARY TEACHERES CO OPARATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9654,
      "TXT_FINANCIER_NAME": "THE CHEVAYUR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9655,
      "TXT_FINANCIER_NAME": "THE CHAIRMAN AIRPORTS AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9656,
      "TXT_FINANCIER_NAME": "WIPRA VIVIDODDESHA SOWHARDA SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9657,
      "TXT_FINANCIER_NAME": "KARUNAGAPPALLY TALUK URBA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9658,
      "TXT_FINANCIER_NAME": "DANDA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9659,
      "TXT_FINANCIER_NAME": "KINARA CREDIT CO OP SOCIETY LTD SALIGRAMA"
    },
    {
      "NUM_FINANCIER_CD": 9660,
      "TXT_FINANCIER_NAME": "SADAR BHILADWALA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9661,
      "TXT_FINANCIER_NAME": "SARDA BHILAD CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9662,
      "TXT_FINANCIER_NAME": "BHARAT SARDA HUF"
    },
    {
      "NUM_FINANCIER_CD": 9663,
      "TXT_FINANCIER_NAME": "KINGS INDIA SHOPEE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9664,
      "TXT_FINANCIER_NAME": "MANMOHAN GRAMIN BIGARSHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 9665,
      "TXT_FINANCIER_NAME": "MADHNI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9666,
      "TXT_FINANCIER_NAME": "CHHANI LEVA PATIDAR CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9667,
      "TXT_FINANCIER_NAME": "DHANU ROAD JANTA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9668,
      "TXT_FINANCIER_NAME": "THE KATLARY KARIYANA SAHAKARI SARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9669,
      "TXT_FINANCIER_NAME": "R S FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9670,
      "TXT_FINANCIER_NAME": "SRI INGLE AUTOMOBILE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9671,
      "TXT_FINANCIER_NAME": "SUNNY FINANCE AND LEASING CO"
    },
    {
      "NUM_FINANCIER_CD": 9672,
      "TXT_FINANCIER_NAME": "DNHDDSC ST OBC AND MINO FDC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9673,
      "TXT_FINANCIER_NAME": "THE BRAMASHI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9674,
      "TXT_FINANCIER_NAME": "SUBH LAXMI MAHILA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9675,
      "TXT_FINANCIER_NAME": "SR KALIDASA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9676,
      "TXT_FINANCIER_NAME": "MONEY LINE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9677,
      "TXT_FINANCIER_NAME": "MARAVANTHURUTHU SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9678,
      "TXT_FINANCIER_NAME": "KUNDARGI SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9679,
      "TXT_FINANCIER_NAME": "DEPT OF ANIMAL HUSBANDRY DARYING"
    },
    {
      "NUM_FINANCIER_CD": 9680,
      "TXT_FINANCIER_NAME": "NAVRATNA GR BIG SAH PAT SANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 9681,
      "TXT_FINANCIER_NAME": "VADAKKUMPAD SER CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9682,
      "TXT_FINANCIER_NAME": "LOKMANGAL KARMACHARI SAH PATHSANSTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 9683,
      "TXT_FINANCIER_NAME": "JAY SHREERAM NAGARI SAHKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9684,
      "TXT_FINANCIER_NAME": "INDRA AND JAI FOUNDATION"
    },
    {
      "NUM_FINANCIER_CD": 9685,
      "TXT_FINANCIER_NAME": "WASSON MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 9686,
      "TXT_FINANCIER_NAME": "ENGINEERING INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 9687,
      "TXT_FINANCIER_NAME": "THE HASSAN DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9688,
      "TXT_FINANCIER_NAME": "NEW MUMBAI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9689,
      "TXT_FINANCIER_NAME": "THE MODHESWARI BACHAT ANDDHIRAN MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 9690,
      "TXT_FINANCIER_NAME": "GOPURAM FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9691,
      "TXT_FINANCIER_NAME": "VIVEKANAND NAGRIK SAHKARI BANK MYDT SHUJALPUR"
    },
    {
      "NUM_FINANCIER_CD": 9692,
      "TXT_FINANCIER_NAME": "ALAFATAH CO OPERATIVE CREDIT SOC LTD MIRAJ"
    },
    {
      "NUM_FINANCIER_CD": 9693,
      "TXT_FINANCIER_NAME": "HANUMAN VIKAS SOCIETY MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9694,
      "TXT_FINANCIER_NAME": "SURYA TEJA FINANCE COOPERATION"
    },
    {
      "NUM_FINANCIER_CD": 9695,
      "TXT_FINANCIER_NAME": "SIDDHI VINAYAKA SOUHARDHA PATTINA SAHAKARI N"
    },
    {
      "NUM_FINANCIER_CD": 9696,
      "TXT_FINANCIER_NAME": "ASHWINI GRAMIN BIGERSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9697,
      "TXT_FINANCIER_NAME": "SRI J H PATEL VIVIDHODDESHA SAHAKARA SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 9698,
      "TXT_FINANCIER_NAME": "SHIVNER NAGARI SAHAKARI PATSHANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9699,
      "TXT_FINANCIER_NAME": "MARAGODU PRIMARY AGRICULTURE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9700,
      "TXT_FINANCIER_NAME": "M B NAHAR AND CO AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9701,
      "TXT_FINANCIER_NAME": "SHIVNER NAG SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9702,
      "TXT_FINANCIER_NAME": "COFFEE BOARD MINISTRY OF COMMERCE AND INDUSTRY"
    },
    {
      "NUM_FINANCIER_CD": 9703,
      "TXT_FINANCIER_NAME": "SRI LAKSHMI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9704,
      "TXT_FINANCIER_NAME": "POKHARNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9705,
      "TXT_FINANCIER_NAME": "SUPA KUREL VIBHAG SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9706,
      "TXT_FINANCIER_NAME": "MEWAR AANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9707,
      "TXT_FINANCIER_NAME": "SHRI LAXMI CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9708,
      "TXT_FINANCIER_NAME": "KATTOOR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9709,
      "TXT_FINANCIER_NAME": "THE UMARWADA URBAN CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9710,
      "TXT_FINANCIER_NAME": "LAL BAHADUR SHASTRI ACADEMY"
    },
    {
      "NUM_FINANCIER_CD": 9711,
      "TXT_FINANCIER_NAME": "VINO AND VIMAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9712,
      "TXT_FINANCIER_NAME": "VARRI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9713,
      "TXT_FINANCIER_NAME": "SADGURU HARIBHAU MAHARAJ NAGARI PATSHANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9714,
      "TXT_FINANCIER_NAME": "SHREE UMA CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 9715,
      "TXT_FINANCIER_NAME": "KHETIVIKAS SEVA SAHAKARIA MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9716,
      "TXT_FINANCIER_NAME": "VIPUL MOTORS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9717,
      "TXT_FINANCIER_NAME": "SHIVKRUPA PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9718,
      "TXT_FINANCIER_NAME": "THE CCI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9719,
      "TXT_FINANCIER_NAME": "SIMRAN ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 9720,
      "TXT_FINANCIER_NAME": "KUNDARANAD MAHATMA GANDHI URBAN COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9721,
      "TXT_FINANCIER_NAME": "KARNI AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9722,
      "TXT_FINANCIER_NAME": "MATA VAISHNO FIN SEC PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9723,
      "TXT_FINANCIER_NAME": "SRI SUBATHRA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9724,
      "TXT_FINANCIER_NAME": "UMED FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9725,
      "TXT_FINANCIER_NAME": "SPRINGER INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9726,
      "TXT_FINANCIER_NAME": "ANAND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9727,
      "TXT_FINANCIER_NAME": "KAMDHENU GRAMIN BIGAR SHETI SAH PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9728,
      "TXT_FINANCIER_NAME": "BALRAJ HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9729,
      "TXT_FINANCIER_NAME": "R K JAIN AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 9730,
      "TXT_FINANCIER_NAME": "CHIVA SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9731,
      "TXT_FINANCIER_NAME": "RAMAKRISHNA CO"
    },
    {
      "NUM_FINANCIER_CD": 9732,
      "TXT_FINANCIER_NAME": "PAWNA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9733,
      "TXT_FINANCIER_NAME": "ARTHIK VIKASH SAMITI"
    },
    {
      "NUM_FINANCIER_CD": 9734,
      "TXT_FINANCIER_NAME": "ALTHAN BHATAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9735,
      "TXT_FINANCIER_NAME": "VIPIN FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9736,
      "TXT_FINANCIER_NAME": "SRI SHARADA VIVIDODDESHA SOUHARDASAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9737,
      "TXT_FINANCIER_NAME": "NIRMAL PANWAR FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 9738,
      "TXT_FINANCIER_NAME": "RAJENDRA KUMAR SURENDRA KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 9739,
      "TXT_FINANCIER_NAME": "DHARMVEER SAMBHAJI NGARI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9740,
      "TXT_FINANCIER_NAME": "THE AZMANE URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9741,
      "TXT_FINANCIER_NAME": "VANKAL KANAM RAJ CREDIT AND GRAHAK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9742,
      "TXT_FINANCIER_NAME": "SHREE UMA BAVAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9743,
      "TXT_FINANCIER_NAME": "RANKA AUTO FINANCE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9744,
      "TXT_FINANCIER_NAME": "SRI SIVASAKTHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9745,
      "TXT_FINANCIER_NAME": "ARVIND FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 9746,
      "TXT_FINANCIER_NAME": "SHRIPAD VALLABH GR BIGAR SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9747,
      "TXT_FINANCIER_NAME": "THE MANANJE VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9748,
      "TXT_FINANCIER_NAME": "SHRI SAIRAM CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9749,
      "TXT_FINANCIER_NAME": "THE ORGAO URBAN COOP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9750,
      "TXT_FINANCIER_NAME": "MARANGATTUPILLY SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9751,
      "TXT_FINANCIER_NAME": "ST MILAGRES CREDIT SOUHARDHA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9752,
      "TXT_FINANCIER_NAME": "ISGEC HEAVY ENGINEERING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9753,
      "TXT_FINANCIER_NAME": "SEERA TOWN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9754,
      "TXT_FINANCIER_NAME": "SRI PARAVATI PARMESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9755,
      "TXT_FINANCIER_NAME": "SRI PARVATI PARMESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9756,
      "TXT_FINANCIER_NAME": "SHRI TRISHAKTI SAINIK NAG SAH PTS KARAD"
    },
    {
      "NUM_FINANCIER_CD": 9757,
      "TXT_FINANCIER_NAME": "PUNJAB AND MAYSURE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9758,
      "TXT_FINANCIER_NAME": "PUNJAB AND MYSORE STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9759,
      "TXT_FINANCIER_NAME": "SARASWATI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9760,
      "TXT_FINANCIER_NAME": "THE NAVSARJAN IND CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9761,
      "TXT_FINANCIER_NAME": "THE LALOD MALTIPURPOSE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9762,
      "TXT_FINANCIER_NAME": "LOYAL HIRE PURCHASING CO"
    },
    {
      "NUM_FINANCIER_CD": 9763,
      "TXT_FINANCIER_NAME": "THE CURCHOREM URBAN COOP CREDIT STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9764,
      "TXT_FINANCIER_NAME": "THE A G S OFFICE EMPLOYEE S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9765,
      "TXT_FINANCIER_NAME": "RAJPOOT ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 9766,
      "TXT_FINANCIER_NAME": "THE POONA P AND T CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9767,
      "TXT_FINANCIER_NAME": "BASUDEBPUR UTTARBAR S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 9768,
      "TXT_FINANCIER_NAME": "HARTEX RUBBER PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9769,
      "TXT_FINANCIER_NAME": "SHREE VIVIDH KARYAKARI SAH SEVA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9770,
      "TXT_FINANCIER_NAME": "ATMASHAKTI GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MAR PETH"
    },
    {
      "NUM_FINANCIER_CD": 9771,
      "TXT_FINANCIER_NAME": "GIRRAJ GRAMIN BIGAR SHETI SAHAKARI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 9772,
      "TXT_FINANCIER_NAME": "B DILIP CHAND JAIN"
    },
    {
      "NUM_FINANCIER_CD": 9773,
      "TXT_FINANCIER_NAME": "THE HAJIPUR GAMNI SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9774,
      "TXT_FINANCIER_NAME": "AMAN TRADING CO"
    },
    {
      "NUM_FINANCIER_CD": 9775,
      "TXT_FINANCIER_NAME": "SHRI SANT NAGEBABA GRAMIN B SHETI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9776,
      "TXT_FINANCIER_NAME": "IGGUTHAPPA SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9777,
      "TXT_FINANCIER_NAME": "SHRI CHOUDESHWARI TOGATVEER KSHATRYA NAGARI NAGARI SAHAKARI PATSANSHTHA"
    },
    {
      "NUM_FINANCIER_CD": 9778,
      "TXT_FINANCIER_NAME": "EXPORT CREDIT GUARANTEE CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9779,
      "TXT_FINANCIER_NAME": "SHRIRAM AUTO LOAN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9780,
      "TXT_FINANCIER_NAME": "THE PARWANNO URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9781,
      "TXT_FINANCIER_NAME": "SAI BABA SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9782,
      "TXT_FINANCIER_NAME": "SIDDESHWAR NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9783,
      "TXT_FINANCIER_NAME": "PARAM PUJYA SWAMI SAMARTHA GRAMIN BIGAR SHETI SAH PAT SAN"
    },
    {
      "NUM_FINANCIER_CD": 9784,
      "TXT_FINANCIER_NAME": "NAVIN SUBHEDAR NAGARI SAHAKARI PATHSANSTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9785,
      "TXT_FINANCIER_NAME": "THE SHEGAON SHREE AGRASEN CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 9786,
      "TXT_FINANCIER_NAME": "KARNATAKA JUDICIAL OFFICER CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9787,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9788,
      "TXT_FINANCIER_NAME": "JANKALYAN GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9789,
      "TXT_FINANCIER_NAME": "BHIKAJIRAO PATIL SAH PATH SANSTH"
    },
    {
      "NUM_FINANCIER_CD": 9790,
      "TXT_FINANCIER_NAME": "S P SONS FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9791,
      "TXT_FINANCIER_NAME": "DARODI UTKARSHA SAHAKARI PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 9792,
      "TXT_FINANCIER_NAME": "NATIONAL BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 9793,
      "TXT_FINANCIER_NAME": "BHAGIRATHPURA SAHKARI SAKH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9794,
      "TXT_FINANCIER_NAME": "MALIK FIN CAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9795,
      "TXT_FINANCIER_NAME": "AT AND S INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9796,
      "TXT_FINANCIER_NAME": "UMBRAJ MERCHANT CO OP CR SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 9797,
      "TXT_FINANCIER_NAME": "MANIDHARI AND MANIDHARI FINANCE NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 9798,
      "TXT_FINANCIER_NAME": "SHRI BRAMHANAND SWAMI GRAMIN BIGAR SHETI SAHKARI PATHSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 9799,
      "TXT_FINANCIER_NAME": "DHANWARDHINI GRAMIN BIGHAR SHETI SAHAKARI PATHPADI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9800,
      "TXT_FINANCIER_NAME": "SHIKARIPURA URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9801,
      "TXT_FINANCIER_NAME": "MANDAVI KINARA GRAMIN BIGAR SHE SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9802,
      "TXT_FINANCIER_NAME": "SHREE JAGADGURU PANCHACHARYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9803,
      "TXT_FINANCIER_NAME": "KANHURPATHAR MULTYSTATE CO OP CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 9804,
      "TXT_FINANCIER_NAME": "RATAN FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9805,
      "TXT_FINANCIER_NAME": "SPL P B B"
    },
    {
      "NUM_FINANCIER_CD": 9806,
      "TXT_FINANCIER_NAME": "THE KASARAGOD CO OPERATIVE URBAN SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9807,
      "TXT_FINANCIER_NAME": "SHIVAM GROUP"
    },
    {
      "NUM_FINANCIER_CD": 9808,
      "TXT_FINANCIER_NAME": "AMBRINI CO OPARATIVE SOCITY"
    },
    {
      "NUM_FINANCIER_CD": 9809,
      "TXT_FINANCIER_NAME": "RATAN DEVI BOKADIA"
    },
    {
      "NUM_FINANCIER_CD": 9810,
      "TXT_FINANCIER_NAME": "LARSON AND TURBO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9811,
      "TXT_FINANCIER_NAME": "KHATRA PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9812,
      "TXT_FINANCIER_NAME": "NEW LOKNATH MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9813,
      "TXT_FINANCIER_NAME": "J B S INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 9814,
      "TXT_FINANCIER_NAME": "SIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9815,
      "TXT_FINANCIER_NAME": "TUMSAR NAGRI SAH PAT SAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9816,
      "TXT_FINANCIER_NAME": "ANDAVAR GROUP MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9817,
      "TXT_FINANCIER_NAME": "DAIVAGNA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9818,
      "TXT_FINANCIER_NAME": "THE VIJAYNAGAR NAGARIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 9819,
      "TXT_FINANCIER_NAME": "PRABHAT GENERAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9820,
      "TXT_FINANCIER_NAME": "THE DHARMAJ CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9821,
      "TXT_FINANCIER_NAME": "THE PARSHIK JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9822,
      "TXT_FINANCIER_NAME": "PANJAB AND MAHARASHTRA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9823,
      "TXT_FINANCIER_NAME": "O N G C CAUVERY ASSET"
    },
    {
      "NUM_FINANCIER_CD": 9824,
      "TXT_FINANCIER_NAME": "RASHTRASANT ANANDKRUSHI NAGARI SAHAKARI PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 9825,
      "TXT_FINANCIER_NAME": "QUICK INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 9826,
      "TXT_FINANCIER_NAME": "AGRO INDUS FIN LEASING INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9827,
      "TXT_FINANCIER_NAME": "THE HINDU URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9828,
      "TXT_FINANCIER_NAME": "GAYATRI BANK"
    },
    {
      "NUM_FINANCIER_CD": 9829,
      "TXT_FINANCIER_NAME": "DAMODAR VALLEY CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 9830,
      "TXT_FINANCIER_NAME": "JAY LAXMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9831,
      "TXT_FINANCIER_NAME": "SHRI SAIDATTA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9832,
      "TXT_FINANCIER_NAME": "UTI MUTUAL FUND"
    },
    {
      "NUM_FINANCIER_CD": 9833,
      "TXT_FINANCIER_NAME": "SHREE BHAMASHA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9834,
      "TXT_FINANCIER_NAME": "VAKIL NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9835,
      "TXT_FINANCIER_NAME": "SHRI MAULI DEVI MULTI PURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9836,
      "TXT_FINANCIER_NAME": "AMARDEVI NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9837,
      "TXT_FINANCIER_NAME": "PNB RAB VMLG GHAZIABZD"
    },
    {
      "NUM_FINANCIER_CD": 9838,
      "TXT_FINANCIER_NAME": "SHRI OM MOTOR"
    },
    {
      "NUM_FINANCIER_CD": 9839,
      "TXT_FINANCIER_NAME": "SREE DURGA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 9840,
      "TXT_FINANCIER_NAME": "SRI KARUNYAA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9841,
      "TXT_FINANCIER_NAME": "MANSAA ENTERPRSES"
    },
    {
      "NUM_FINANCIER_CD": 9842,
      "TXT_FINANCIER_NAME": "ANDHRA BANK RETAIL CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 9843,
      "TXT_FINANCIER_NAME": "SHRI SAI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9844,
      "TXT_FINANCIER_NAME": "CONTROLER K V A S S U"
    },
    {
      "NUM_FINANCIER_CD": 9845,
      "TXT_FINANCIER_NAME": "HEG LTD"
    },
    {
      "NUM_FINANCIER_CD": 9846,
      "TXT_FINANCIER_NAME": "NEHRU GRAMIN BIGER SHETI SAH PATH SANSTHA LTD ASHTA"
    },
    {
      "NUM_FINANCIER_CD": 9847,
      "TXT_FINANCIER_NAME": "KALPATARU NAGARI SAHAKARI PATHASANTH"
    },
    {
      "NUM_FINANCIER_CD": 9848,
      "TXT_FINANCIER_NAME": "WEST BENGAL SCHEDULED CASTES AND SCHEDULED TRIBES DEVELOPMENT AND FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 9849,
      "TXT_FINANCIER_NAME": "MS ALGJ FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9850,
      "TXT_FINANCIER_NAME": "RAGHU RAJA FIN CORP"
    },
    {
      "NUM_FINANCIER_CD": 9851,
      "TXT_FINANCIER_NAME": "GANLAXMI NAG SAH PAT SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9852,
      "TXT_FINANCIER_NAME": "ANITHA"
    },
    {
      "NUM_FINANCIER_CD": 9853,
      "TXT_FINANCIER_NAME": "RALIGARE FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 9854,
      "TXT_FINANCIER_NAME": "SAMRTH SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9855,
      "TXT_FINANCIER_NAME": "PUNE POST AND TELEGRAPH CO OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 9856,
      "TXT_FINANCIER_NAME": "SHRI LAXMI VANKATESH FINANCE COMANY"
    },
    {
      "NUM_FINANCIER_CD": 9857,
      "TXT_FINANCIER_NAME": "SREE MAHESHWARI SAHAKARI PEDHI"
    },
    {
      "NUM_FINANCIER_CD": 9858,
      "TXT_FINANCIER_NAME": "PARIWAR CREDIT CO OPARETIVE SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9859,
      "TXT_FINANCIER_NAME": "MANISHA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 9860,
      "TXT_FINANCIER_NAME": "IRINJALAKUDA CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD R312"
    },
    {
      "NUM_FINANCIER_CD": 9861,
      "TXT_FINANCIER_NAME": "MANAN FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9862,
      "TXT_FINANCIER_NAME": "KARNAL BARNALA FINCAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9863,
      "TXT_FINANCIER_NAME": "SHREE GAJANANA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9864,
      "TXT_FINANCIER_NAME": "THE PADDI SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9865,
      "TXT_FINANCIER_NAME": "DYNAMIC CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9866,
      "TXT_FINANCIER_NAME": "KALPVRUKSHA CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9867,
      "TXT_FINANCIER_NAME": "A R NAGAR SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9868,
      "TXT_FINANCIER_NAME": "SURANA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 9869,
      "TXT_FINANCIER_NAME": "SHRI JAGVALLABH PARSHWANATH NAGARI SAHAKARI PATH SANSTHA MARAYDIT"
    },
    {
      "NUM_FINANCIER_CD": 9870,
      "TXT_FINANCIER_NAME": "SRI KALISADA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9871,
      "TXT_FINANCIER_NAME": "JASHAN FINLEASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9872,
      "TXT_FINANCIER_NAME": "LALIT KUMAR AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 9873,
      "TXT_FINANCIER_NAME": "SHRADHA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9874,
      "TXT_FINANCIER_NAME": "KUTHUPARAMBA CO OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9875,
      "TXT_FINANCIER_NAME": "BELWADI VYAPARI GRAMIN BIGAR SETHI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9876,
      "TXT_FINANCIER_NAME": "THE BORSAD INDUSTRIAL CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9877,
      "TXT_FINANCIER_NAME": "THE ERNAD CO OPERATIVE AGRICULTURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9878,
      "TXT_FINANCIER_NAME": "RAYTARA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9879,
      "TXT_FINANCIER_NAME": "RAJADHIRAJ ATUO FINANCE COMPANY PVT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9880,
      "TXT_FINANCIER_NAME": "SERVA SEWA VYAPAR SANKUL NAGARI SAHAKARI PATSASTHA"
    },
    {
      "NUM_FINANCIER_CD": 9881,
      "TXT_FINANCIER_NAME": "DODIYA AUTO EXCHANGE DEWAS"
    },
    {
      "NUM_FINANCIER_CD": 9882,
      "TXT_FINANCIER_NAME": "H ONE INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9883,
      "TXT_FINANCIER_NAME": "THE TAMILANDU INDUSTRIAL INVESTMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 9884,
      "TXT_FINANCIER_NAME": "PRAVEEN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9885,
      "TXT_FINANCIER_NAME": "SRI KAMADHENU FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9886,
      "TXT_FINANCIER_NAME": "KANAKADURGA GRAMEENABANK"
    },
    {
      "NUM_FINANCIER_CD": 9887,
      "TXT_FINANCIER_NAME": "RAJMATA JIJAU MAHILA GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9888,
      "TXT_FINANCIER_NAME": "MS JYOTI MOTOR HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 9889,
      "TXT_FINANCIER_NAME": "ABHYUDAYA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9890,
      "TXT_FINANCIER_NAME": "SADALGA WESTERN DIV L S M P CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9891,
      "TXT_FINANCIER_NAME": "TULSI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 9892,
      "TXT_FINANCIER_NAME": "SREE THANGAMURUGAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9893,
      "TXT_FINANCIER_NAME": "CADILA HEALTHCARE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9894,
      "TXT_FINANCIER_NAME": "BALAJI PUNCHING FINANCIES"
    },
    {
      "NUM_FINANCIER_CD": 9895,
      "TXT_FINANCIER_NAME": "BAIRAVAN LEASINGS"
    },
    {
      "NUM_FINANCIER_CD": 9896,
      "TXT_FINANCIER_NAME": "ANAD NAGARIK CO OP CRADIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 9897,
      "TXT_FINANCIER_NAME": "LONARBABA GRAMIN BIG SAH PATH"
    },
    {
      "NUM_FINANCIER_CD": 9898,
      "TXT_FINANCIER_NAME": "REGISTRAR MDU ROHTAK"
    },
    {
      "NUM_FINANCIER_CD": 9899,
      "TXT_FINANCIER_NAME": "VELLANIKARA SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9900,
      "TXT_FINANCIER_NAME": "SREE RAGHAVENDRA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 9901,
      "TXT_FINANCIER_NAME": "KALPVRAKSH MULTIPURPOSE CO OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 9902,
      "TXT_FINANCIER_NAME": "BHRAMAHSHI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9903,
      "TXT_FINANCIER_NAME": "THE KANAYANNUR CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9904,
      "TXT_FINANCIER_NAME": "MOHOL TALUKA GRAMIN BIGARSHETI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9905,
      "TXT_FINANCIER_NAME": "VELLANIKKARA SERVICE CO OP BANK LTD NO 545"
    },
    {
      "NUM_FINANCIER_CD": 9906,
      "TXT_FINANCIER_NAME": "SRI KSHETHRA DHARMASHTALA RURAL DEVELOPMENT PROJECT"
    },
    {
      "NUM_FINANCIER_CD": 9907,
      "TXT_FINANCIER_NAME": "JANACHAITANYA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9908,
      "TXT_FINANCIER_NAME": "ASHA VAIBHAV CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9909,
      "TXT_FINANCIER_NAME": "SIS FINLEASE I LTD"
    },
    {
      "NUM_FINANCIER_CD": 9910,
      "TXT_FINANCIER_NAME": "KVS LEASING AND FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 9911,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI SHIVAJIRAO KALE SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9912,
      "TXT_FINANCIER_NAME": "THE KATUKUKKE SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9913,
      "TXT_FINANCIER_NAME": "RELIANCE ENERGY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9914,
      "TXT_FINANCIER_NAME": "PUNE CONTOMENT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9915,
      "TXT_FINANCIER_NAME": "SARDAR BHILAD CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9916,
      "TXT_FINANCIER_NAME": "NARANG AUTO CONSULT"
    },
    {
      "NUM_FINANCIER_CD": 9917,
      "TXT_FINANCIER_NAME": "KRISHI AUR GRAMIN VIKASH BANK"
    },
    {
      "NUM_FINANCIER_CD": 9918,
      "TXT_FINANCIER_NAME": "POWER GRID VICTOR OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9919,
      "TXT_FINANCIER_NAME": "SBI SME CITY CREDIT CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 9920,
      "TXT_FINANCIER_NAME": "GIC HOUSING FINANACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9921,
      "TXT_FINANCIER_NAME": "VINTAGE CREDIT AND LEASING P LTD"
    },
    {
      "NUM_FINANCIER_CD": 9922,
      "TXT_FINANCIER_NAME": "SRI GANESHA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9923,
      "TXT_FINANCIER_NAME": "DOSHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9924,
      "TXT_FINANCIER_NAME": "IL AND FS LTD"
    },
    {
      "NUM_FINANCIER_CD": 9925,
      "TXT_FINANCIER_NAME": "KARNATAKA COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9926,
      "TXT_FINANCIER_NAME": "SHREE SAHPATSHRUNG GRAMIN BIGARSHEETI SAH PAT SAN MARYA"
    },
    {
      "NUM_FINANCIER_CD": 9927,
      "TXT_FINANCIER_NAME": "JAIN NAGRI SAHKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9928,
      "TXT_FINANCIER_NAME": "GOTHI GENERAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9929,
      "TXT_FINANCIER_NAME": "KANI INVESTMENTS AND CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9930,
      "TXT_FINANCIER_NAME": "THE OLD GOA URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9931,
      "TXT_FINANCIER_NAME": "THE KARNATAKA KALAL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9932,
      "TXT_FINANCIER_NAME": "GAJANAND FINANCE AND INVESTMENT PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9933,
      "TXT_FINANCIER_NAME": "LOKHVIKHAS GRAMIN BIGER SHETI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9934,
      "TXT_FINANCIER_NAME": "JOHNSON INDIA SECURITIES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 9935,
      "TXT_FINANCIER_NAME": "ASHWAMEDH GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9936,
      "TXT_FINANCIER_NAME": "RAJSTHAN NAGARI SAHAKARI PATSANSHTA"
    },
    {
      "NUM_FINANCIER_CD": 9937,
      "TXT_FINANCIER_NAME": "GLAXO SMITHKLINE ASIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9938,
      "TXT_FINANCIER_NAME": "AASRA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9939,
      "TXT_FINANCIER_NAME": "THE THASRA NAGRIK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9940,
      "TXT_FINANCIER_NAME": "B JAJODIYA FINACIAL AND MANAGMENT SE"
    },
    {
      "NUM_FINANCIER_CD": 9941,
      "TXT_FINANCIER_NAME": "DHARMVEER SAMBHAJI URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 9942,
      "TXT_FINANCIER_NAME": "KANSAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9943,
      "TXT_FINANCIER_NAME": "ALLAHBAD UP GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9944,
      "TXT_FINANCIER_NAME": "ENESS FINALEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9945,
      "TXT_FINANCIER_NAME": "THE MORAZHA KALLIASSERI SERVIC"
    },
    {
      "NUM_FINANCIER_CD": 9946,
      "TXT_FINANCIER_NAME": "GROWTH AVENUE SECURITIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 9947,
      "TXT_FINANCIER_NAME": "KAMRUP TRADING CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9948,
      "TXT_FINANCIER_NAME": "PUJA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9949,
      "TXT_FINANCIER_NAME": "KAVIRATHNA KALIDAS CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9950,
      "TXT_FINANCIER_NAME": "SISUPALGARH SERVICE CO OPERRATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 9951,
      "TXT_FINANCIER_NAME": "KARTIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9952,
      "TXT_FINANCIER_NAME": "HINGANGHAT NAGRI SAH PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9953,
      "TXT_FINANCIER_NAME": "NERE VIBHAG NAGARI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9954,
      "TXT_FINANCIER_NAME": "CHANDRAMA INVESTMENT FINANCE AND LIES"
    },
    {
      "NUM_FINANCIER_CD": 9955,
      "TXT_FINANCIER_NAME": "JHARKHAND SARKAR VITTA VIBHAG"
    },
    {
      "NUM_FINANCIER_CD": 9956,
      "TXT_FINANCIER_NAME": "MAHENDRA CHOWDHARI"
    },
    {
      "NUM_FINANCIER_CD": 9957,
      "TXT_FINANCIER_NAME": "THE NAMPONG MANMAO LAMP LTD"
    },
    {
      "NUM_FINANCIER_CD": 9958,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI TRAMBAKRAO GHUGE BHAGYASHREE GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9959,
      "TXT_FINANCIER_NAME": "SHRI KRISHNA NAGARI SAH PATH SANTHA MAR LONAND"
    },
    {
      "NUM_FINANCIER_CD": 9960,
      "TXT_FINANCIER_NAME": "SYAL FINLEASE CO"
    },
    {
      "NUM_FINANCIER_CD": 9961,
      "TXT_FINANCIER_NAME": "THE ORAN SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 9962,
      "TXT_FINANCIER_NAME": "VANDE MATARAM NAGARI SAH PATHASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9963,
      "TXT_FINANCIER_NAME": "VAYVSHAYIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9964,
      "TXT_FINANCIER_NAME": "SOUTHERN STAR CO OP U THRIFT CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9965,
      "TXT_FINANCIER_NAME": "GOMTI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 9966,
      "TXT_FINANCIER_NAME": "GREEN FINANCE CO REGD"
    },
    {
      "NUM_FINANCIER_CD": 9967,
      "TXT_FINANCIER_NAME": "MANJULA AUTO FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9968,
      "TXT_FINANCIER_NAME": "SABITA MOTOR HIREPURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 9969,
      "TXT_FINANCIER_NAME": "THE NOBLE FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9970,
      "TXT_FINANCIER_NAME": "MULLAKKODY SER CO OP RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 9971,
      "TXT_FINANCIER_NAME": "DINKAR ABA PATIL CO OP PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9972,
      "TXT_FINANCIER_NAME": "ADAT FARMERS SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 9973,
      "TXT_FINANCIER_NAME": "PRASHANT CREDIT COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9974,
      "TXT_FINANCIER_NAME": "RAMESH CHAND AUTOMOBILE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 9975,
      "TXT_FINANCIER_NAME": "SRI MUKAMBIKA VYAVASAYA SEVA SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 9976,
      "TXT_FINANCIER_NAME": "SURINDRA AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 9977,
      "TXT_FINANCIER_NAME": "CHHATTISGARH STATE POWER HOLDING CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 9978,
      "TXT_FINANCIER_NAME": "PRERANA"
    },
    {
      "NUM_FINANCIER_CD": 9979,
      "TXT_FINANCIER_NAME": "THE RASIMALAR FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 9980,
      "TXT_FINANCIER_NAME": "MANDEEP AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 9981,
      "TXT_FINANCIER_NAME": "RANIP NAGRIK SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 9982,
      "TXT_FINANCIER_NAME": "NAVARANG FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9983,
      "TXT_FINANCIER_NAME": "MAAN FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 9984,
      "TXT_FINANCIER_NAME": "BIRUR PATTANA SOWHARDA PATHINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9985,
      "TXT_FINANCIER_NAME": "KAI SAU KESHARBAI TANPURE NAGRI SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9986,
      "TXT_FINANCIER_NAME": "THE GOGHAMBA VIBHAG NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 9987,
      "TXT_FINANCIER_NAME": "SHREE SAINATH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 9988,
      "TXT_FINANCIER_NAME": "TRIMURTI NAGARI SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9989,
      "TXT_FINANCIER_NAME": "TRIMURTHI NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9990,
      "TXT_FINANCIER_NAME": "RAJSHREE SHAHU NAGARI SAH PAT SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 9991,
      "TXT_FINANCIER_NAME": "TARDAL GRAMIN BIGAR SHETI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 9992,
      "TXT_FINANCIER_NAME": "DR PRABHAKAR KORE SOUHARD SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 9993,
      "TXT_FINANCIER_NAME": "SMART AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 9994,
      "TXT_FINANCIER_NAME": "ADARSH GRAMIN SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 9995,
      "TXT_FINANCIER_NAME": "RAMBHAUJI RAUT NAGRI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 9996,
      "TXT_FINANCIER_NAME": "SNEHA SANGAMA SOWHARDHA PATHINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9997,
      "TXT_FINANCIER_NAME": "SANGAMA PATTHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 9998,
      "TXT_FINANCIER_NAME": "JANKALYAN SAKH SAHKARITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 9999,
      "TXT_FINANCIER_NAME": "TIRUPATI TIRUPATI DEVASTHANAMS"
    },
    {
      "NUM_FINANCIER_CD": 10000,
      "TXT_FINANCIER_NAME": "THE NAGRIK CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10001,
      "TXT_FINANCIER_NAME": "RAMBHAUJI RAUT NAGRI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10002,
      "TXT_FINANCIER_NAME": "SAHARA INDIA"
    },
    {
      "NUM_FINANCIER_CD": 10003,
      "TXT_FINANCIER_NAME": "RAJSTHANI NAGARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10004,
      "TXT_FINANCIER_NAME": "SHREE SADGURU HARIBUVA MAHARAJ NAGARI SAHAKARI PATSANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10005,
      "TXT_FINANCIER_NAME": "KALPDRUM GRAMIN BIGER SHETI SAH PAT SAN MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10006,
      "TXT_FINANCIER_NAME": "SHRI DATTARAJ GRAMIN BIGAR SHETI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10007,
      "TXT_FINANCIER_NAME": "THE CHIKMAGLUR DISTRICT CO OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10008,
      "TXT_FINANCIER_NAME": "PRABHAT CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10009,
      "TXT_FINANCIER_NAME": "BALDEV FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10010,
      "TXT_FINANCIER_NAME": "RAJ KUMAR GOYAL SVP"
    },
    {
      "NUM_FINANCIER_CD": 10011,
      "TXT_FINANCIER_NAME": "SHRI VISHVESHWAR SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10012,
      "TXT_FINANCIER_NAME": "M C W A F S"
    },
    {
      "NUM_FINANCIER_CD": 10013,
      "TXT_FINANCIER_NAME": "MANSI MOTOR HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 10014,
      "TXT_FINANCIER_NAME": "KSHATRAKULOTPANNA MARATHA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10015,
      "TXT_FINANCIER_NAME": "NAVDURGA VIKAS SEVA SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10016,
      "TXT_FINANCIER_NAME": "RANJEET FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10017,
      "TXT_FINANCIER_NAME": "V N CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 10018,
      "TXT_FINANCIER_NAME": "CALICUT SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10019,
      "TXT_FINANCIER_NAME": "THE ALLEPPEY COOPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10020,
      "TXT_FINANCIER_NAME": "NAGESHWAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10021,
      "TXT_FINANCIER_NAME": "DIPLAXMI NAGARI SAHAKARI PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 10022,
      "TXT_FINANCIER_NAME": "ANANTNAG COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10023,
      "TXT_FINANCIER_NAME": "NAVJEEVAN NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10024,
      "TXT_FINANCIER_NAME": "SHRI BAHUBALI SEVAKANCHI SAHAKARI PATH SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10025,
      "TXT_FINANCIER_NAME": "MEL KARMACHARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10026,
      "TXT_FINANCIER_NAME": "STATE GOVT NYAY WA VIDHI VIBHAG"
    },
    {
      "NUM_FINANCIER_CD": 10027,
      "TXT_FINANCIER_NAME": "SRI SUPRAJA ENTERPRISES REGD"
    },
    {
      "NUM_FINANCIER_CD": 10028,
      "TXT_FINANCIER_NAME": "THE PONNANI SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10029,
      "TXT_FINANCIER_NAME": "SAPTGIRI NAGRI SAHAKARI PATH SANSTHA KINWAT"
    },
    {
      "NUM_FINANCIER_CD": 10030,
      "TXT_FINANCIER_NAME": "M S MOTORS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10031,
      "TXT_FINANCIER_NAME": "PAVAN KUMAR CHORDIA"
    },
    {
      "NUM_FINANCIER_CD": 10032,
      "TXT_FINANCIER_NAME": "HARYANA TOURISM CORPN LTD"
    },
    {
      "NUM_FINANCIER_CD": 10033,
      "TXT_FINANCIER_NAME": "KAMLESH KUMAR KOTHARI"
    },
    {
      "NUM_FINANCIER_CD": 10034,
      "TXT_FINANCIER_NAME": "VIJAY JYOTHI FINANCIERS PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10035,
      "TXT_FINANCIER_NAME": "SHANTISAGAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10036,
      "TXT_FINANCIER_NAME": "SHREEKALIKA GRAMIM PATSAN PATHPEDI"
    },
    {
      "NUM_FINANCIER_CD": 10037,
      "TXT_FINANCIER_NAME": "SANDIP INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10038,
      "TXT_FINANCIER_NAME": "DPH"
    },
    {
      "NUM_FINANCIER_CD": 10039,
      "TXT_FINANCIER_NAME": "THE DIRECTOR ISAC"
    },
    {
      "NUM_FINANCIER_CD": 10040,
      "TXT_FINANCIER_NAME": "DHFL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10041,
      "TXT_FINANCIER_NAME": "SREYAS INDUSTRIAL AND TRANSPORT FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10042,
      "TXT_FINANCIER_NAME": "SRI JAY MARUTI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10043,
      "TXT_FINANCIER_NAME": "SHREE VITTHAL NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10044,
      "TXT_FINANCIER_NAME": "SHRI SAI BABA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10045,
      "TXT_FINANCIER_NAME": "RAJA HARICHANDRA GRAMIN BIGAR SHETI"
    },
    {
      "NUM_FINANCIER_CD": 10046,
      "TXT_FINANCIER_NAME": "SHREE CHATRAPATI SHIVAJI MAHARAJ NAGRI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10047,
      "TXT_FINANCIER_NAME": "SHIKARIPURA URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10048,
      "TXT_FINANCIER_NAME": "KARUNA INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 10049,
      "TXT_FINANCIER_NAME": "MADAI CO OPERATIVE RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 10050,
      "TXT_FINANCIER_NAME": "DONDAICHA NAGAR SAH PATSANSTH MARYADIT DONDAICH"
    },
    {
      "NUM_FINANCIER_CD": 10051,
      "TXT_FINANCIER_NAME": "K SUNILKUMAR BOKDIA"
    },
    {
      "NUM_FINANCIER_CD": 10052,
      "TXT_FINANCIER_NAME": "MS BHAGWATI PRASAD BAJORIA HUF SRIKAMAKHYA"
    },
    {
      "NUM_FINANCIER_CD": 10053,
      "TXT_FINANCIER_NAME": "VIKAS URBAN CREDIT SAHAKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10054,
      "TXT_FINANCIER_NAME": "THANE MAHESH NAGARI CO OP PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10055,
      "TXT_FINANCIER_NAME": "THE PERUMBAVOOR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10056,
      "TXT_FINANCIER_NAME": "URBAN BANK SHIKARIPURA"
    },
    {
      "NUM_FINANCIER_CD": 10057,
      "TXT_FINANCIER_NAME": "COMTRLLER UAS GKVK BNG 065"
    },
    {
      "NUM_FINANCIER_CD": 10058,
      "TXT_FINANCIER_NAME": "PRIYANKA JAIN"
    },
    {
      "NUM_FINANCIER_CD": 10059,
      "TXT_FINANCIER_NAME": "ARULJOTHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10060,
      "TXT_FINANCIER_NAME": "KHADAKMAL ALI NAGAR SAHAKARI PATHPADI MAR"
    },
    {
      "NUM_FINANCIER_CD": 10061,
      "TXT_FINANCIER_NAME": "KHEDA DIST CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10062,
      "TXT_FINANCIER_NAME": "KHADKMAL ALI NAGRI SAHAKARI PATHPADI MAR"
    },
    {
      "NUM_FINANCIER_CD": 10063,
      "TXT_FINANCIER_NAME": "THE KALE MARCHANT CRADIT CO OP SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10064,
      "TXT_FINANCIER_NAME": "B UMESH NAHAR AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10065,
      "TXT_FINANCIER_NAME": "HARIYAALI URBAN CO OP CR SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10066,
      "TXT_FINANCIER_NAME": "S RAMESH KUMAR DARDA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 10067,
      "TXT_FINANCIER_NAME": "PERFECT CAPITAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10068,
      "TXT_FINANCIER_NAME": "MANDYA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10069,
      "TXT_FINANCIER_NAME": "THE DHANDWAR CO OP MULTI PURPOSE AGRI SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10070,
      "TXT_FINANCIER_NAME": "BELEKERI SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10071,
      "TXT_FINANCIER_NAME": "MINISTRY OF STEEL"
    },
    {
      "NUM_FINANCIER_CD": 10072,
      "TXT_FINANCIER_NAME": "IDEA CELLULER LTD"
    },
    {
      "NUM_FINANCIER_CD": 10073,
      "TXT_FINANCIER_NAME": "MALANADU ENTERPRICES"
    },
    {
      "NUM_FINANCIER_CD": 10074,
      "TXT_FINANCIER_NAME": "TELE COMMUNICATION CONSULTANTS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10075,
      "TXT_FINANCIER_NAME": "SNEHNAGRI SAHAKARI PATHSAWANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10076,
      "TXT_FINANCIER_NAME": "SHREE LEKHA NAGRI SAHKARI ADHIKOSH"
    },
    {
      "NUM_FINANCIER_CD": 10077,
      "TXT_FINANCIER_NAME": "IJKA CO OPERATIVE AGRI AND RURAL DEVPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10078,
      "TXT_FINANCIER_NAME": "SMT USHATAI BADE GRAMIN BIGARSHETI SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 10079,
      "TXT_FINANCIER_NAME": "THE REDDI SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10080,
      "TXT_FINANCIER_NAME": "CO PARVAT MANAJI RAHANE GRAMIN BIG SHETI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10081,
      "TXT_FINANCIER_NAME": "SHREE BHAVPAR SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10082,
      "TXT_FINANCIER_NAME": "LAXMAN TUNDA PACCS"
    },
    {
      "NUM_FINANCIER_CD": 10083,
      "TXT_FINANCIER_NAME": "CHOLA MANDALAM"
    },
    {
      "NUM_FINANCIER_CD": 10084,
      "TXT_FINANCIER_NAME": "KHATRHA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10085,
      "TXT_FINANCIER_NAME": "OM PRAKASH SISODIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 10086,
      "TXT_FINANCIER_NAME": "GM SOUHARDA PATTINA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 10087,
      "TXT_FINANCIER_NAME": "GURUMAULI NAGARI SAHAKARI PATA SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10088,
      "TXT_FINANCIER_NAME": "SHREE GANESH HIRE PURCHASE CO"
    },
    {
      "NUM_FINANCIER_CD": 10089,
      "TXT_FINANCIER_NAME": "SHRI HANUMAN CREDIT SOUHARD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10090,
      "TXT_FINANCIER_NAME": "THE TIRUPPUR COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10091,
      "TXT_FINANCIER_NAME": "NADIA GRAMIN BIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 10092,
      "TXT_FINANCIER_NAME": "SHRI NATH AUTO MOBIL"
    },
    {
      "NUM_FINANCIER_CD": 10093,
      "TXT_FINANCIER_NAME": "MANAGING DIRECTOR IRCON INTERNATIONAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10094,
      "TXT_FINANCIER_NAME": "ASHISH CREDIT AND SUPPLY SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10095,
      "TXT_FINANCIER_NAME": "BAVDHAN MARCHANT CREDIT CO OPERATIVE SOC LITD"
    },
    {
      "NUM_FINANCIER_CD": 10096,
      "TXT_FINANCIER_NAME": "PUSHPANJALI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10097,
      "TXT_FINANCIER_NAME": "SHIKARIPUR URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10098,
      "TXT_FINANCIER_NAME": "METTUR THERMAL POWER STATION"
    },
    {
      "NUM_FINANCIER_CD": 10099,
      "TXT_FINANCIER_NAME": "SANJAY CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10100,
      "TXT_FINANCIER_NAME": "THE MANGALPADY PAIVALIKE URBAN CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10101,
      "TXT_FINANCIER_NAME": "THE MODASA ROAD TRANSPORT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10102,
      "TXT_FINANCIER_NAME": "TARU AGENCIES AND INVESTMENT P LTD"
    },
    {
      "NUM_FINANCIER_CD": 10103,
      "TXT_FINANCIER_NAME": "R E C LTD"
    },
    {
      "NUM_FINANCIER_CD": 10104,
      "TXT_FINANCIER_NAME": "PRIMARY TEACHERS CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10105,
      "TXT_FINANCIER_NAME": "SURABHI CO INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10106,
      "TXT_FINANCIER_NAME": "TAMILNADU POLICE HOUSING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 10107,
      "TXT_FINANCIER_NAME": "SRI JANAKIRAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10108,
      "TXT_FINANCIER_NAME": "PIMPRI CHNCHWAD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10109,
      "TXT_FINANCIER_NAME": "THE VARDHMAN NAGARIK SHARAFI SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10110,
      "TXT_FINANCIER_NAME": "CHOREPALIA S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 10111,
      "TXT_FINANCIER_NAME": "KURUMATHUR SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10112,
      "TXT_FINANCIER_NAME": "SRI SHANMUGA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10113,
      "TXT_FINANCIER_NAME": "PAWANHANS HELICOPTER LTD"
    },
    {
      "NUM_FINANCIER_CD": 10114,
      "TXT_FINANCIER_NAME": "RASHTRYA ISPAT NIGAM LIMITED VISHAKHA PATNAM ISPAT SANYANTRA"
    },
    {
      "NUM_FINANCIER_CD": 10115,
      "TXT_FINANCIER_NAME": "MATORI CHANDSHI VIVIDYA KARYAKARI SAHAKARI VIKAS SEVA SANSHTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10116,
      "TXT_FINANCIER_NAME": "SIRAGURA PRIMARY AGRICULTURAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10117,
      "TXT_FINANCIER_NAME": "SARVODAYA GRAMIN BIGER SHETI SAH PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10118,
      "TXT_FINANCIER_NAME": "HIRE PURCHASE WITH FAMILY CREDIT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10119,
      "TXT_FINANCIER_NAME": "BHARAT AGARWAL NAGARI SAHAKARI PATHA SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10120,
      "TXT_FINANCIER_NAME": "NABARUN S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 10121,
      "TXT_FINANCIER_NAME": "HUKMICHAND AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10122,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN NAGRIK SAHKARI SARAFI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10123,
      "TXT_FINANCIER_NAME": "GILDA FINANCE AND INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10124,
      "TXT_FINANCIER_NAME": "THAREHALLI KANSUR SEVA SAHAKARI SANGH"
    },
    {
      "NUM_FINANCIER_CD": 10125,
      "TXT_FINANCIER_NAME": "SRI GANESH SOWHARDA CR CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10126,
      "TXT_FINANCIER_NAME": "SHREE SAIARIHANT NAGAR SAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10127,
      "TXT_FINANCIER_NAME": "JAY BAJRANG GRAMIN BIGARSHETI SAHAKARI PATHSANSTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10128,
      "TXT_FINANCIER_NAME": "UMASA CHAVAN NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10129,
      "TXT_FINANCIER_NAME": "NAVAKARANTAKA SOUHARADA"
    },
    {
      "NUM_FINANCIER_CD": 10130,
      "TXT_FINANCIER_NAME": "JAI RAJ FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10131,
      "TXT_FINANCIER_NAME": "DHUBULIA SAMABAY KRISHI UNNAYAN SAMITEE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10132,
      "TXT_FINANCIER_NAME": "JAI AMBEY MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10133,
      "TXT_FINANCIER_NAME": "SAMBHAVNATH NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10134,
      "TXT_FINANCIER_NAME": "THE UDHAWA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10135,
      "TXT_FINANCIER_NAME": "MADAKARINAYAKA PATHINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10136,
      "TXT_FINANCIER_NAME": "THE HOSDURG P C A R D BANK"
    },
    {
      "NUM_FINANCIER_CD": 10333,
      "TXT_FINANCIER_NAME": "SHRI JOGESHWARI MERCHANT SAHAKARI PATSANSTHA MAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10334,
      "TXT_FINANCIER_NAME": "HARMAN FINANCE AND LEASING CO"
    },
    {
      "NUM_FINANCIER_CD": 10335,
      "TXT_FINANCIER_NAME": "ELECTRONICS CORPORATION OF TAMILNADU"
    },
    {
      "NUM_FINANCIER_CD": 10336,
      "TXT_FINANCIER_NAME": "VIJAY SAHAKARI PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10337,
      "TXT_FINANCIER_NAME": "SHRI SADNANA GRAMIN BIG SHE SAH PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 10338,
      "TXT_FINANCIER_NAME": "SARSWATHA SOUHARDA PATHINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10339,
      "TXT_FINANCIER_NAME": "VARUN LEASING"
    },
    {
      "NUM_FINANCIER_CD": 10340,
      "TXT_FINANCIER_NAME": "THE KHEDBRAHMA GROUP SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10341,
      "TXT_FINANCIER_NAME": "DN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 10342,
      "TXT_FINANCIER_NAME": "BSE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10343,
      "TXT_FINANCIER_NAME": "SHRINATHJI DHIRAN AND GRAHAK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10344,
      "TXT_FINANCIER_NAME": "VIKAS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10345,
      "TXT_FINANCIER_NAME": "SREE HARTHI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10346,
      "TXT_FINANCIER_NAME": "MEHTA MERCHANT NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10347,
      "TXT_FINANCIER_NAME": "VISHNU INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10348,
      "TXT_FINANCIER_NAME": "MAA KIRPA AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 10349,
      "TXT_FINANCIER_NAME": "VIKRAM TRADERS"
    },
    {
      "NUM_FINANCIER_CD": 10350,
      "TXT_FINANCIER_NAME": "DIRECTOR ISAC ON BEHALF OF PRESIDENT OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 10351,
      "TXT_FINANCIER_NAME": "FULLERTION INDIA CREDIT COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10352,
      "TXT_FINANCIER_NAME": "MUNAGOD URBAN"
    },
    {
      "NUM_FINANCIER_CD": 10353,
      "TXT_FINANCIER_NAME": "RAJ SONS"
    },
    {
      "NUM_FINANCIER_CD": 10354,
      "TXT_FINANCIER_NAME": "JASPAL AUTO DEAL"
    },
    {
      "NUM_FINANCIER_CD": 10355,
      "TXT_FINANCIER_NAME": "KAVOOR VYAVASAYA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 10356,
      "TXT_FINANCIER_NAME": "HOTEL AND SAMBHADHIT VYAVSAYIK NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10357,
      "TXT_FINANCIER_NAME": "HOTEL AND SAMBHADHIT VYAVASAYIK NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10358,
      "TXT_FINANCIER_NAME": "JANTA NAGRI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10359,
      "TXT_FINANCIER_NAME": "UTTAR GUJARAT VIJ CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10360,
      "TXT_FINANCIER_NAME": "CHANDRAPUR ZILA MADHYAVARTI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10361,
      "TXT_FINANCIER_NAME": "AMRUTVAHINI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10362,
      "TXT_FINANCIER_NAME": "PRESIDENT BHEL EDN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10363,
      "TXT_FINANCIER_NAME": "ROCKPROCESS EQUIPMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10364,
      "TXT_FINANCIER_NAME": "THE BHAWANIPATNA CENTRAL COOPARATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10365,
      "TXT_FINANCIER_NAME": "MULA KHORE NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10366,
      "TXT_FINANCIER_NAME": "M G INTERNATIONAL"
    },
    {
      "NUM_FINANCIER_CD": 10367,
      "TXT_FINANCIER_NAME": "MANGALVEDHA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10368,
      "TXT_FINANCIER_NAME": "THE CD COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10369,
      "TXT_FINANCIER_NAME": "VYASYA NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10370,
      "TXT_FINANCIER_NAME": "SHAKUNI NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10371,
      "TXT_FINANCIER_NAME": "SIKAR SAHAKARI BHOOMI VIKAS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10372,
      "TXT_FINANCIER_NAME": "GAYATHRI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10373,
      "TXT_FINANCIER_NAME": "DEUTSCHE POST BANK HOME FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10374,
      "TXT_FINANCIER_NAME": "SRI SATHYA NARAYANA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10375,
      "TXT_FINANCIER_NAME": "J D AUTO TRADING CO"
    },
    {
      "NUM_FINANCIER_CD": 10376,
      "TXT_FINANCIER_NAME": "PADMA PRIYA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10377,
      "TXT_FINANCIER_NAME": "SVS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10378,
      "TXT_FINANCIER_NAME": "BALLARESHWAR SAHAKARI PATHPEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10379,
      "TXT_FINANCIER_NAME": "NEERJA FINCAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10380,
      "TXT_FINANCIER_NAME": "JAI SHREERAM GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10381,
      "TXT_FINANCIER_NAME": "SBR PROJECTS"
    },
    {
      "NUM_FINANCIER_CD": 10382,
      "TXT_FINANCIER_NAME": "AKANSHA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10383,
      "TXT_FINANCIER_NAME": "B V FINANCE AND LEASING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10384,
      "TXT_FINANCIER_NAME": "THE MUKTSAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10385,
      "TXT_FINANCIER_NAME": "SURYABHA DEVELOPERS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10386,
      "TXT_FINANCIER_NAME": "DELAVAL CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10387,
      "TXT_FINANCIER_NAME": "VARALAKSHMI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10388,
      "TXT_FINANCIER_NAME": "JOHN FLOWER INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10389,
      "TXT_FINANCIER_NAME": "UDYAM NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10390,
      "TXT_FINANCIER_NAME": "MANGALAM FINANCE GROUP"
    },
    {
      "NUM_FINANCIER_CD": 10391,
      "TXT_FINANCIER_NAME": "MANGALWEDHA LOKMANGAL GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10392,
      "TXT_FINANCIER_NAME": "SRI PON FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10393,
      "TXT_FINANCIER_NAME": "SHRI TRIMURTI GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MRYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10394,
      "TXT_FINANCIER_NAME": "KISHANLALJI SANCHETI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10395,
      "TXT_FINANCIER_NAME": "FOR RELIANCE CONSUMER FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 10396,
      "TXT_FINANCIER_NAME": "KOLHAPUR ZILLA VAKIL SAH PAT SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 10397,
      "TXT_FINANCIER_NAME": "CHAUDHARY CHHAMB TRADING CORP"
    },
    {
      "NUM_FINANCIER_CD": 10398,
      "TXT_FINANCIER_NAME": "AMBIKA SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 10399,
      "TXT_FINANCIER_NAME": "KOTAK SECURITIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 10400,
      "TXT_FINANCIER_NAME": "THE SULTHANBATHERY CO OPRATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10401,
      "TXT_FINANCIER_NAME": "MISHRI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10402,
      "TXT_FINANCIER_NAME": "BELLAD BAGEVADI KRUSHI SEVA SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 10403,
      "TXT_FINANCIER_NAME": "KRISHNA SAKH SAHKARITA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10404,
      "TXT_FINANCIER_NAME": "BHALPAHRI TARATAND PACCS LTD"
    },
    {
      "NUM_FINANCIER_CD": 10405,
      "TXT_FINANCIER_NAME": "BRAHMARISHI COOPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10406,
      "TXT_FINANCIER_NAME": "PALRIWALA EDUCATIONAL RESEARCH AND TRAINING SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10407,
      "TXT_FINANCIER_NAME": "SAHAKARI VYAVASAYIKA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10408,
      "TXT_FINANCIER_NAME": "SURESH AUTO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10409,
      "TXT_FINANCIER_NAME": "RANVIR FINANCIAL SERVICES RED"
    },
    {
      "NUM_FINANCIER_CD": 10410,
      "TXT_FINANCIER_NAME": "BHARATHI SOUHARDA CREDIT CO OP LTD KAUP"
    },
    {
      "NUM_FINANCIER_CD": 10411,
      "TXT_FINANCIER_NAME": "CITIZAN PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10412,
      "TXT_FINANCIER_NAME": "GURU GANESH FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10413,
      "TXT_FINANCIER_NAME": "SHRI DHULDEV GRAMIN BIGAR SHETI SAHAKATI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10414,
      "TXT_FINANCIER_NAME": "DIAGEO INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10415,
      "TXT_FINANCIER_NAME": "THE CONTROLLER OFFICERS CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10416,
      "TXT_FINANCIER_NAME": "SHRI NAG PARSHWA CREDIT SOUHARDA SAH NIPPANI"
    },
    {
      "NUM_FINANCIER_CD": 10417,
      "TXT_FINANCIER_NAME": "U K COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10418,
      "TXT_FINANCIER_NAME": "NAVDIP CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10419,
      "TXT_FINANCIER_NAME": "GURU NANAK FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10420,
      "TXT_FINANCIER_NAME": "JAYHIND NAGARI SAHAKARI PATSAHSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10421,
      "TXT_FINANCIER_NAME": "SHREE KAILASH NAGARI SAHAKARI PATSANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10422,
      "TXT_FINANCIER_NAME": "ZETATEK INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 10423,
      "TXT_FINANCIER_NAME": "YUVA GRAMIN SAHAKARI PATH SANSHTA MYDH"
    },
    {
      "NUM_FINANCIER_CD": 10424,
      "TXT_FINANCIER_NAME": "SHREE GURUSIDDESHWAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10425,
      "TXT_FINANCIER_NAME": "SANKALP GRAMIN BIGAR SHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10426,
      "TXT_FINANCIER_NAME": "SHRI HONNAVADEVI MULTI PURPOSE SAHAKARI SANGH TLD"
    },
    {
      "NUM_FINANCIER_CD": 10427,
      "TXT_FINANCIER_NAME": "BANASKANTHA VIBHAGIYA ROAD TRANSPORT CRADIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10428,
      "TXT_FINANCIER_NAME": "COMMERCIAL CORPN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10429,
      "TXT_FINANCIER_NAME": "L BHARATH NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 10430,
      "TXT_FINANCIER_NAME": "OM MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10431,
      "TXT_FINANCIER_NAME": "PUKHRAJ BOTHRA"
    },
    {
      "NUM_FINANCIER_CD": 10813,
      "TXT_FINANCIER_NAME": "THE ROYAL BANK OF SCOTLAND N V"
    },
    {
      "NUM_FINANCIER_CD": 10814,
      "TXT_FINANCIER_NAME": "S S SHRIPAL ALASE KURUNDWAD URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10815,
      "TXT_FINANCIER_NAME": "VISHNU CAPITALS"
    },
    {
      "NUM_FINANCIER_CD": 10816,
      "TXT_FINANCIER_NAME": "MANINIYA BAPUSAHEB GAWADE GRAMIN BIG SHE PAT MARYADUT"
    },
    {
      "NUM_FINANCIER_CD": 10817,
      "TXT_FINANCIER_NAME": "THE RAJULA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10818,
      "TXT_FINANCIER_NAME": "A P BEVERAGES CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10819,
      "TXT_FINANCIER_NAME": "THE BORI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10820,
      "TXT_FINANCIER_NAME": "GORESHWARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10821,
      "TXT_FINANCIER_NAME": "TULJABHAWANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10822,
      "TXT_FINANCIER_NAME": "SUN INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 10823,
      "TXT_FINANCIER_NAME": "NANDKUMAR ZAWARE PATIL GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10824,
      "TXT_FINANCIER_NAME": "SHREE SANT SAWATA GRAMIN BIGAR SHETI SHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10825,
      "TXT_FINANCIER_NAME": "SURESHCHANDRA MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 10826,
      "TXT_FINANCIER_NAME": "SHRI BHAIRI BHAVANI GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA MARIYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10827,
      "TXT_FINANCIER_NAME": "JIVALA NAGARI SAHAKRI PATHSANSHTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10828,
      "TXT_FINANCIER_NAME": "AMBALAPPUZHA TALUK AIDED SECONDARY SCHOOL EMPLOYEE S CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10829,
      "TXT_FINANCIER_NAME": "SAPHALE PARISAR BIGARSHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10830,
      "TXT_FINANCIER_NAME": "SURESH RAJESH NAHAR AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10831,
      "TXT_FINANCIER_NAME": "BALKRISHNA RAMCHANDRA SHINDE GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA JATH"
    },
    {
      "NUM_FINANCIER_CD": 10832,
      "TXT_FINANCIER_NAME": "PANDARAKULAM FINANCE PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10833,
      "TXT_FINANCIER_NAME": "SHRI SAIKRUPA GRAMIN BIGAR SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10834,
      "TXT_FINANCIER_NAME": "J AND K STATE FINANCIAL COORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10835,
      "TXT_FINANCIER_NAME": "APANA MOTAR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10836,
      "TXT_FINANCIER_NAME": "THE VIJAYNAGAR NAGRIK SHARAFI SAH MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10837,
      "TXT_FINANCIER_NAME": "LAW AND LEGISLATIVE DEPARTMENT HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 10838,
      "TXT_FINANCIER_NAME": "NTPC SAIL POWER COMPANY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10839,
      "TXT_FINANCIER_NAME": "MANAKARNA SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10840,
      "TXT_FINANCIER_NAME": "GUJARAT RAJYA SAHKARI KRUSHI AND GRAMIN VIKAS BANK LTD PATAN"
    },
    {
      "NUM_FINANCIER_CD": 10841,
      "TXT_FINANCIER_NAME": "MANAKARANA SAHAKARI PATH SANSTHA MADHA"
    },
    {
      "NUM_FINANCIER_CD": 10842,
      "TXT_FINANCIER_NAME": "KOKAN DHANGARWADE NAGARI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10843,
      "TXT_FINANCIER_NAME": "PATTABIRAMANAGARA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10844,
      "TXT_FINANCIER_NAME": "VINCHURI GAWALI VIVIDHA KARYA SAHA VIKAS SEVA SANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10845,
      "TXT_FINANCIER_NAME": "SARASWATI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10846,
      "TXT_FINANCIER_NAME": "TAXTILE TRADERS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10847,
      "TXT_FINANCIER_NAME": "DAGA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10848,
      "TXT_FINANCIER_NAME": "MAHRATTA CHEMBER OF COMMERCE INDUSTRIES AND AGRICULTURE"
    },
    {
      "NUM_FINANCIER_CD": 10849,
      "TXT_FINANCIER_NAME": "C G RAJYA SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10850,
      "TXT_FINANCIER_NAME": "SISODARA VIBHAG VIVIDH KARYAKARI SAHAKARI MADLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10851,
      "TXT_FINANCIER_NAME": "KANNUR DIST EX SERV MEN CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10852,
      "TXT_FINANCIER_NAME": "JAY YOGESHWAR NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10853,
      "TXT_FINANCIER_NAME": "VYAVASAYA SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10854,
      "TXT_FINANCIER_NAME": "SHUBHAMKAROTI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10855,
      "TXT_FINANCIER_NAME": "NAGENDRA FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10856,
      "TXT_FINANCIER_NAME": "MALKAPUR NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10857,
      "TXT_FINANCIER_NAME": "DNYANESH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10858,
      "TXT_FINANCIER_NAME": "SHRINATH CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10859,
      "TXT_FINANCIER_NAME": "NOVA LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10860,
      "TXT_FINANCIER_NAME": "THE PONDICHERRY STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10861,
      "TXT_FINANCIER_NAME": "THE M K G S B CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10862,
      "TXT_FINANCIER_NAME": "SARASWATI MAHILA GRAMIN BIGARSHETI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10863,
      "TXT_FINANCIER_NAME": "MANIYADIH PRIMARY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10864,
      "TXT_FINANCIER_NAME": "SHRI KULSWAMI CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10865,
      "TXT_FINANCIER_NAME": "KANAKALOKA SOUNDARAYA CREDIT COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10866,
      "TXT_FINANCIER_NAME": "MERCHANT NAGRI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10867,
      "TXT_FINANCIER_NAME": "SRIBIR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10868,
      "TXT_FINANCIER_NAME": "BRIDGE AND ROOF COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10869,
      "TXT_FINANCIER_NAME": "SHREE SIDDESHWARA SOUHARDHA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10870,
      "TXT_FINANCIER_NAME": "SWARANA BHARATHIYA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10871,
      "TXT_FINANCIER_NAME": "OM SHANTI NAGARI SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10872,
      "TXT_FINANCIER_NAME": "THE CETHOLIC SYRIAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10873,
      "TXT_FINANCIER_NAME": "SHIVA TEXYARN LTD"
    },
    {
      "NUM_FINANCIER_CD": 10874,
      "TXT_FINANCIER_NAME": "JAMBU KUMAR MUTHU"
    },
    {
      "NUM_FINANCIER_CD": 10875,
      "TXT_FINANCIER_NAME": "SREE SREEKANTESWARA CREDIT CO OP SOCIETY LTD R"
    },
    {
      "NUM_FINANCIER_CD": 10876,
      "TXT_FINANCIER_NAME": "SHRI RAM MOTOR GENERAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10877,
      "TXT_FINANCIER_NAME": "HEENA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10878,
      "TXT_FINANCIER_NAME": "SAURASHTRA JAMNAGAR RAJKOT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10879,
      "TXT_FINANCIER_NAME": "GANDHIDHAM FINCAP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10880,
      "TXT_FINANCIER_NAME": "GOUTHAMCHAND PRAVEENCHAND"
    },
    {
      "NUM_FINANCIER_CD": 10881,
      "TXT_FINANCIER_NAME": "THE KADODARA VIBHAG NAGRIK BACHAT AND DHIRAN SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10882,
      "TXT_FINANCIER_NAME": "U P MOTOR FINANCERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 10883,
      "TXT_FINANCIER_NAME": "KODODIYAR INFRA INVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10884,
      "TXT_FINANCIER_NAME": "ALOOR HARKUR VYAVASAYA SEVA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 10885,
      "TXT_FINANCIER_NAME": "SHRI JIVHESHWAR NAGRI SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10886,
      "TXT_FINANCIER_NAME": "BORI URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10887,
      "TXT_FINANCIER_NAME": "KANAPUR CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10888,
      "TXT_FINANCIER_NAME": "SRI BILLUR GURUBASAV PATTINA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 10889,
      "TXT_FINANCIER_NAME": "SHREE RAM LINGESHWAR CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10890,
      "TXT_FINANCIER_NAME": "GAJANAN MEHTRE NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10891,
      "TXT_FINANCIER_NAME": "SREE CHARAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10892,
      "TXT_FINANCIER_NAME": "SRI HARTI PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10893,
      "TXT_FINANCIER_NAME": "VAIKOM TALUK CO OP AGRIC AND RURAL DEVEL BANK"
    },
    {
      "NUM_FINANCIER_CD": 10894,
      "TXT_FINANCIER_NAME": "AGARWAL FISCAL PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10895,
      "TXT_FINANCIER_NAME": "THE DEVIPURAKAMPA GROUP V K SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10896,
      "TXT_FINANCIER_NAME": "THE MYSORE AND CHAMARAJANAGAR DISTRICT CITY CORPORATION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10897,
      "TXT_FINANCIER_NAME": "KUNBI VIKAS SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 10898,
      "TXT_FINANCIER_NAME": "SAU SHANTABAI TARACHAND DUNGARWAL NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10899,
      "TXT_FINANCIER_NAME": "DISTRICT MANAGER MATSYAFED"
    },
    {
      "NUM_FINANCIER_CD": 10900,
      "TXT_FINANCIER_NAME": "LESSOR ALD AUTOMOTIVE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10901,
      "TXT_FINANCIER_NAME": "RUI YUVA GRAMIN SAHAKARI PATH SANSHTA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10902,
      "TXT_FINANCIER_NAME": "SAGARNADU CRDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10903,
      "TXT_FINANCIER_NAME": "MIDLAND RUBBER AND PRODUCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10432,
      "TXT_FINANCIER_NAME": "NAVCHAITANYA NAGARI SAH PAT SANSHTA MAR"
    },
    {
      "NUM_FINANCIER_CD": 10433,
      "TXT_FINANCIER_NAME": "UNDER LEASE AGREEMENT WITH SHANTI FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10434,
      "TXT_FINANCIER_NAME": "ARVIND NAG SAH PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 10435,
      "TXT_FINANCIER_NAME": "ARVIND NAGARI SAHAKARI PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10436,
      "TXT_FINANCIER_NAME": "MOULANA AZAD BIGER SHETI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10437,
      "TXT_FINANCIER_NAME": "BANTWAL CATHOLIC SAHAKARA SANGA ROYAL HOUSE"
    },
    {
      "NUM_FINANCIER_CD": 10438,
      "TXT_FINANCIER_NAME": "KODAGU SAHAKARA SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10439,
      "TXT_FINANCIER_NAME": "THE VYAVSAYIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10440,
      "TXT_FINANCIER_NAME": "CHRISTIAN MULTIPURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10441,
      "TXT_FINANCIER_NAME": "HINGULAMBIKA PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10442,
      "TXT_FINANCIER_NAME": "THE DUNGARPUR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10443,
      "TXT_FINANCIER_NAME": "KRUNAL CONSTRUCTION AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10444,
      "TXT_FINANCIER_NAME": "NAVBHARAT MULTI PURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10445,
      "TXT_FINANCIER_NAME": "THE KAIVAL CO OPP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10446,
      "TXT_FINANCIER_NAME": "ANU INVESTMENT FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10447,
      "TXT_FINANCIER_NAME": "RONAK ENTERPRIZES"
    },
    {
      "NUM_FINANCIER_CD": 10448,
      "TXT_FINANCIER_NAME": "ASHTA LOKMANYA NAGARI SAHKARI PATH SANSHTA"
    },
    {
      "NUM_FINANCIER_CD": 10449,
      "TXT_FINANCIER_NAME": "GUJRAT POLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 10450,
      "TXT_FINANCIER_NAME": "THE NASHIK JILHA MAHILA VIKAS SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10451,
      "TXT_FINANCIER_NAME": "FERROMATIK MILACRON INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10452,
      "TXT_FINANCIER_NAME": "RASMECCC"
    },
    {
      "NUM_FINANCIER_CD": 10453,
      "TXT_FINANCIER_NAME": "COMPANIAN CO OP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10454,
      "TXT_FINANCIER_NAME": "SHRI DEONADI KHORE GRAMIN BIGARSHETI SAH PATSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10455,
      "TXT_FINANCIER_NAME": "I V EMPLOYEES CONSUMER CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10456,
      "TXT_FINANCIER_NAME": "SRI SUNMATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10457,
      "TXT_FINANCIER_NAME": "BASKAR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10458,
      "TXT_FINANCIER_NAME": "SHRI RAM NAGRI SAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10459,
      "TXT_FINANCIER_NAME": "OM RAJLAXMI FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10460,
      "TXT_FINANCIER_NAME": "SHREE RUSAT CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10461,
      "TXT_FINANCIER_NAME": "MAULIK KRUPA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10462,
      "TXT_FINANCIER_NAME": "BARTER AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10463,
      "TXT_FINANCIER_NAME": "THE AMALSAD V V K S K MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 10464,
      "TXT_FINANCIER_NAME": "CHITTI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10465,
      "TXT_FINANCIER_NAME": "AMALA REAL ESTATE AND KURIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10466,
      "TXT_FINANCIER_NAME": "SUBRAMANYESHWARA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10467,
      "TXT_FINANCIER_NAME": "GODARA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10468,
      "TXT_FINANCIER_NAME": "PAVANCHAND NAHAR RATAN"
    },
    {
      "NUM_FINANCIER_CD": 10469,
      "TXT_FINANCIER_NAME": "THE PACHAGANI CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 10470,
      "TXT_FINANCIER_NAME": "JAN SAMRIDDHI CO OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10471,
      "TXT_FINANCIER_NAME": "BALAJI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10472,
      "TXT_FINANCIER_NAME": "RASHTRAMATA INDIRA GANDHI NAGARI SAH PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 10473,
      "TXT_FINANCIER_NAME": "UNIVERSAL ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 10474,
      "TXT_FINANCIER_NAME": "SHRI DURGAMATA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10475,
      "TXT_FINANCIER_NAME": "CHOLA BUSINESS SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 10476,
      "TXT_FINANCIER_NAME": "PARTH ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 10477,
      "TXT_FINANCIER_NAME": "LAHOTI MOTORS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10478,
      "TXT_FINANCIER_NAME": "MITSUBHISHI ELECTRIC AUTOMOTIVE INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10479,
      "TXT_FINANCIER_NAME": "TRANSMISSION CORPORATION OF ANDHRA PRADESH"
    },
    {
      "NUM_FINANCIER_CD": 10480,
      "TXT_FINANCIER_NAME": "THE SRIRAM FINANCE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10481,
      "TXT_FINANCIER_NAME": "SRI DHARMASTALA MANJUNATHA PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 10482,
      "TXT_FINANCIER_NAME": "NAGAMA SARCHI"
    },
    {
      "NUM_FINANCIER_CD": 10483,
      "TXT_FINANCIER_NAME": "THE PLANTATION CORPORATION OF KERALA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10484,
      "TXT_FINANCIER_NAME": "SHRIRA TRANSPORT FINANACE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10485,
      "TXT_FINANCIER_NAME": "VYAVASAYA SEVA SAHANKARA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10486,
      "TXT_FINANCIER_NAME": "SHIVRANA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10487,
      "TXT_FINANCIER_NAME": "AREVA T AND D INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10488,
      "TXT_FINANCIER_NAME": "DAPOLI GRAMIN SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10489,
      "TXT_FINANCIER_NAME": "BHUPATHI RAJU CO OPERATIVE CREDIT BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10490,
      "TXT_FINANCIER_NAME": "AABASAHEB GRAMIN BIGARSHETI SAHAKARI PATHA"
    },
    {
      "NUM_FINANCIER_CD": 10491,
      "TXT_FINANCIER_NAME": "SRI VINAYAGA CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 10492,
      "TXT_FINANCIER_NAME": "SABBAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10493,
      "TXT_FINANCIER_NAME": "THE ELURU COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10494,
      "TXT_FINANCIER_NAME": "SOWPARNIKA SOUHARADA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10495,
      "TXT_FINANCIER_NAME": "KHANDALA TALUKA SHREE HARALE VASHINAV GRAMIN BIGARSHETI SAH PAT SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10496,
      "TXT_FINANCIER_NAME": "SOWPARNIKA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10497,
      "TXT_FINANCIER_NAME": "GUNJA SAMABAY KRISHI UNNYAN SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10498,
      "TXT_FINANCIER_NAME": "BUKKASAGARA PRIMARY AGRICULTURE CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10499,
      "TXT_FINANCIER_NAME": "THE DALLA CO OP AGRI MULTIPURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10500,
      "TXT_FINANCIER_NAME": "ANNAMALAI GROUP SIVANMALAI MURUGAN MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10501,
      "TXT_FINANCIER_NAME": "VICTORIYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10502,
      "TXT_FINANCIER_NAME": "SARDA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10503,
      "TXT_FINANCIER_NAME": "JAY YOGESHWAR PATH SANSTHA BRHMANPURI"
    },
    {
      "NUM_FINANCIER_CD": 10504,
      "TXT_FINANCIER_NAME": "SHREE PATNESHWAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10505,
      "TXT_FINANCIER_NAME": "MANSI MAHILA NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10506,
      "TXT_FINANCIER_NAME": "MANIKANTA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10507,
      "TXT_FINANCIER_NAME": "SPANDANA VIVIDUDDESH SOUHARDHA SAHAKARI NIMATHA"
    },
    {
      "NUM_FINANCIER_CD": 10508,
      "TXT_FINANCIER_NAME": "SUNEET FIN MAN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10509,
      "TXT_FINANCIER_NAME": "RAMS RNTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 10510,
      "TXT_FINANCIER_NAME": "MANGALPADY SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10511,
      "TXT_FINANCIER_NAME": "SARDAR VALLABHAI PATEL NAGARI SAHAKARI PATHSANTHA MARYADIT YEOLA"
    },
    {
      "NUM_FINANCIER_CD": 10512,
      "TXT_FINANCIER_NAME": "ADHYAPAKA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10513,
      "TXT_FINANCIER_NAME": "SHRI LAXMI NURSIAH NAGRI SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 10514,
      "TXT_FINANCIER_NAME": "MEHRE GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10515,
      "TXT_FINANCIER_NAME": "VINYAKA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10516,
      "TXT_FINANCIER_NAME": "CO OPERATIVE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10517,
      "TXT_FINANCIER_NAME": "OMKAR NAGARI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10518,
      "TXT_FINANCIER_NAME": "SASIREKHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10519,
      "TXT_FINANCIER_NAME": "GURU KRIPA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10520,
      "TXT_FINANCIER_NAME": "ONGC RAJAHMUNDRY ASSET"
    },
    {
      "NUM_FINANCIER_CD": 10521,
      "TXT_FINANCIER_NAME": "ABHAYLAXMI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10522,
      "TXT_FINANCIER_NAME": "KALAS UTKARSHA SAHAKARI PATPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10523,
      "TXT_FINANCIER_NAME": "SHREE TARSADIA JANSEVA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10524,
      "TXT_FINANCIER_NAME": "SHREEKRUPA NAGARI SAHKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10525,
      "TXT_FINANCIER_NAME": "KUTTIKKATTOR CO OPERATIVE URBAN SOCIETY BANK"
    },
    {
      "NUM_FINANCIER_CD": 10526,
      "TXT_FINANCIER_NAME": "SUPREEM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10527,
      "TXT_FINANCIER_NAME": "VIVEKANAND NAGRI SAHAKARI PRATYAYA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10528,
      "TXT_FINANCIER_NAME": "SUPREEME FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10529,
      "TXT_FINANCIER_NAME": "SURJEET FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10530,
      "TXT_FINANCIER_NAME": "SANT DNYANESHWAR GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10531,
      "TXT_FINANCIER_NAME": "SHREERAM SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10532,
      "TXT_FINANCIER_NAME": "SANT DNYANESHWAR GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10533,
      "TXT_FINANCIER_NAME": "JANTA CO OPRATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10534,
      "TXT_FINANCIER_NAME": "MSSR CHITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10535,
      "TXT_FINANCIER_NAME": "SINGHATI SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10536,
      "TXT_FINANCIER_NAME": "GOVERNER OF TAMILNADU"
    },
    {
      "NUM_FINANCIER_CD": 10537,
      "TXT_FINANCIER_NAME": "JAY SITARAM NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10538,
      "TXT_FINANCIER_NAME": "SAMRAT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10539,
      "TXT_FINANCIER_NAME": "SAI VINAYAKA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 10540,
      "TXT_FINANCIER_NAME": "MUNAGOD URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10541,
      "TXT_FINANCIER_NAME": "THE MANNARKKAD CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 10542,
      "TXT_FINANCIER_NAME": "SUNNY MOTOR TRADING CORP"
    },
    {
      "NUM_FINANCIER_CD": 10543,
      "TXT_FINANCIER_NAME": "SHIVSHANKAR NAGARI SAHAKARI PATHSANTHA MARYADIT KARAD"
    },
    {
      "NUM_FINANCIER_CD": 10544,
      "TXT_FINANCIER_NAME": "THE TAMILNADU DR MGR MEDICAL UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 10545,
      "TXT_FINANCIER_NAME": "MARWAR SHARIA CO OP CREDIT AND SAVING SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10546,
      "TXT_FINANCIER_NAME": "CHANDRAPRABHU URBAN CO OP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10547,
      "TXT_FINANCIER_NAME": "SHRI NAGANATH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10548,
      "TXT_FINANCIER_NAME": "SWAMY GROUP AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10549,
      "TXT_FINANCIER_NAME": "SHRI BEERSHWAR SOIHARD CREDIT SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 10550,
      "TXT_FINANCIER_NAME": "SREE LAKSHMI DIVYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10551,
      "TXT_FINANCIER_NAME": "KAMALA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10552,
      "TXT_FINANCIER_NAME": "VIJAYSHRI GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10553,
      "TXT_FINANCIER_NAME": "C G RAJYA SHAKARI BANK MARYADITA"
    },
    {
      "NUM_FINANCIER_CD": 10554,
      "TXT_FINANCIER_NAME": "DRK INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10555,
      "TXT_FINANCIER_NAME": "MEMBER SECRETARY KARNATAKA STATE POLLUTION CONTROL"
    },
    {
      "NUM_FINANCIER_CD": 10556,
      "TXT_FINANCIER_NAME": "GURUDEO GRAMIN BIGAR SHETKI SAH PAT SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10557,
      "TXT_FINANCIER_NAME": "THE EDACHERI SERVICE CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10558,
      "TXT_FINANCIER_NAME": "HIGHRADE MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10559,
      "TXT_FINANCIER_NAME": "DHANALAXMI NAGRI SAHAKARI PATH SANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10560,
      "TXT_FINANCIER_NAME": "YASHWANTRAO NAGARI SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10561,
      "TXT_FINANCIER_NAME": "THE MATTOM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10562,
      "TXT_FINANCIER_NAME": "DHARBANDODA V K S S STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10563,
      "TXT_FINANCIER_NAME": "MAHATMA JYOTIRAO FULE GRAMIN BIGAR SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10564,
      "TXT_FINANCIER_NAME": "GUHAGAR TALUKA KUNABI NAGARI SAHAKARI PATSANSTHA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10565,
      "TXT_FINANCIER_NAME": "HEALTH DEPARTMENT JHARKHAND"
    },
    {
      "NUM_FINANCIER_CD": 10566,
      "TXT_FINANCIER_NAME": "KHULESHWER GRAMIN BIGAR SHETI SAHAKARI PATH"
    },
    {
      "NUM_FINANCIER_CD": 10567,
      "TXT_FINANCIER_NAME": "ANANDRAO CHAVAN NAGARI SAH PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10568,
      "TXT_FINANCIER_NAME": "EMMBEE MONEY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10569,
      "TXT_FINANCIER_NAME": "JAI HIRE PURCHASE COMPANY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10570,
      "TXT_FINANCIER_NAME": "THE INDIA SERCURITY PRESS EMPLOYEES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10571,
      "TXT_FINANCIER_NAME": "PANCHALINGESHWARA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10572,
      "TXT_FINANCIER_NAME": "MAHAVIR GRAMIN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10573,
      "TXT_FINANCIER_NAME": "PONNALAZHU MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10574,
      "TXT_FINANCIER_NAME": "P RAKESH KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 10575,
      "TXT_FINANCIER_NAME": "HEMARADDY MALLAMMA CREDIT CO OP SO LTD R"
    },
    {
      "NUM_FINANCIER_CD": 10576,
      "TXT_FINANCIER_NAME": "AKAANKSHA CREDIT AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10577,
      "TXT_FINANCIER_NAME": "THE GODHRA TRANSPORT CO OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10578,
      "TXT_FINANCIER_NAME": "KUMBHOJ URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10579,
      "TXT_FINANCIER_NAME": "SWA ASHOKRAO BHAU SHINDE GRAMIN BIGARSHETI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10580,
      "TXT_FINANCIER_NAME": "MANGAL CHAND AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 10581,
      "TXT_FINANCIER_NAME": "MAHALAXMI URBAN CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10582,
      "TXT_FINANCIER_NAME": "THE GOMANTAK MULTIPURPOSE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10583,
      "TXT_FINANCIER_NAME": "THE BHARATHI COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10584,
      "TXT_FINANCIER_NAME": "LATTHE SHIKSHAN SEVKANCHI SAHAKARI PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 10585,
      "TXT_FINANCIER_NAME": "SHRI SWAMIRAJ NAGARI SAHAKARI PASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10586,
      "TXT_FINANCIER_NAME": "NAVIN SUBHEDAR NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10587,
      "TXT_FINANCIER_NAME": "YUVASHAKTI NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10588,
      "TXT_FINANCIER_NAME": "HINDUTAN CO OP CREDIT SOUHARD LTD"
    },
    {
      "NUM_FINANCIER_CD": 10589,
      "TXT_FINANCIER_NAME": "SEETHA RAGHAVA SOUHARDA SHAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 10590,
      "TXT_FINANCIER_NAME": "THE KARNATAKA TAPCMS LTD"
    },
    {
      "NUM_FINANCIER_CD": 10591,
      "TXT_FINANCIER_NAME": "KOLAVALLUR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10592,
      "TXT_FINANCIER_NAME": "KODAVOOR SAHAKARI VYAVASAYIKA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10593,
      "TXT_FINANCIER_NAME": "DISHA NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10594,
      "TXT_FINANCIER_NAME": "SRI VELAVAN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10595,
      "TXT_FINANCIER_NAME": "VIJAY SHANTHI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 10596,
      "TXT_FINANCIER_NAME": "THODUPUZHA PRIMARY CO OPERATIVE AGRICULTURAL RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10597,
      "TXT_FINANCIER_NAME": "RENUKA NAGRIK SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10598,
      "TXT_FINANCIER_NAME": "AMPAR SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10599,
      "TXT_FINANCIER_NAME": "AYUSH FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10600,
      "TXT_FINANCIER_NAME": "YADNAYAWALKYA NS P SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10601,
      "TXT_FINANCIER_NAME": "SHREE AMBIKA CO OPP CREDIT SOCIETY CHIKHALI"
    },
    {
      "NUM_FINANCIER_CD": 10602,
      "TXT_FINANCIER_NAME": "TATA INSTITUTE OF SOCIAL SCIENCES"
    },
    {
      "NUM_FINANCIER_CD": 10603,
      "TXT_FINANCIER_NAME": "AMIT PAREKH FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10604,
      "TXT_FINANCIER_NAME": "SUBARNABIHAR S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 10605,
      "TXT_FINANCIER_NAME": "SHRI DHANLAXMI CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10606,
      "TXT_FINANCIER_NAME": "VARDHAMAN NAGRIPATH SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 10607,
      "TXT_FINANCIER_NAME": "ADVANCE FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 10608,
      "TXT_FINANCIER_NAME": "RED AIRPORTS AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 10609,
      "TXT_FINANCIER_NAME": "KUNAL FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10610,
      "TXT_FINANCIER_NAME": "THE KUDOL ARBUDA BACHAT AND DHIRAN SAHKARI MANDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10611,
      "TXT_FINANCIER_NAME": "TIPPU SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10612,
      "TXT_FINANCIER_NAME": "ASHOK AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 10613,
      "TXT_FINANCIER_NAME": "NAGPUR MAHILA VIKAS SAHAKARI SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10614,
      "TXT_FINANCIER_NAME": "MANDROI SEVA SAHAKARI MANDLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10615,
      "TXT_FINANCIER_NAME": "G SOMCHAND NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 10616,
      "TXT_FINANCIER_NAME": "RPCHAND HAJOJI THALE RURAL NON AGRI CCOOP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10617,
      "TXT_FINANCIER_NAME": "SURLA KOTHAMBI V K S S SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10618,
      "TXT_FINANCIER_NAME": "THE YODHA CO OPERTIVE CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10619,
      "TXT_FINANCIER_NAME": "BARNALA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10620,
      "TXT_FINANCIER_NAME": "WHITESTONE GENERAL STORE"
    },
    {
      "NUM_FINANCIER_CD": 10621,
      "TXT_FINANCIER_NAME": "SRI BANASHANKARI PATTINA SHAKARI SANGA"
    },
    {
      "NUM_FINANCIER_CD": 10622,
      "TXT_FINANCIER_NAME": "SHRI VITTHALAI BIGAR SHETI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10623,
      "TXT_FINANCIER_NAME": "SPL P B B PATNA"
    },
    {
      "NUM_FINANCIER_CD": 10624,
      "TXT_FINANCIER_NAME": "KEMWELL BIOPHARMA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10625,
      "TXT_FINANCIER_NAME": "KOYILANDY CO OP AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 10626,
      "TXT_FINANCIER_NAME": "SRI VENKATARAMANA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10627,
      "TXT_FINANCIER_NAME": "RAMLING VIVID KARYAKARI SAH VIKAS SEVA SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10628,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR DIST SECONDARY TEACHER CO CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10629,
      "TXT_FINANCIER_NAME": "VADAKARA CO OP AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 10630,
      "TXT_FINANCIER_NAME": "ALINDRA URBAN CO OP CRE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10631,
      "TXT_FINANCIER_NAME": "G BHANECHAND NAHAR AND SONS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10632,
      "TXT_FINANCIER_NAME": "SAUBHAGYA MAHILA NAGRI SAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10633,
      "TXT_FINANCIER_NAME": "SHRI BHOOTESHWARA PATTINA SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 10634,
      "TXT_FINANCIER_NAME": "CARGILL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10635,
      "TXT_FINANCIER_NAME": "VINAYAK HIRE PURCHASE CO"
    },
    {
      "NUM_FINANCIER_CD": 10636,
      "TXT_FINANCIER_NAME": "SRI AMARANARAYANA CREDIT CO OPSOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10637,
      "TXT_FINANCIER_NAME": "RCM FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10638,
      "TXT_FINANCIER_NAME": "ABHAY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10639,
      "TXT_FINANCIER_NAME": "THE NAVDEEP COOPP CREDIT SOLTD"
    },
    {
      "NUM_FINANCIER_CD": 10640,
      "TXT_FINANCIER_NAME": "RUNDH UNITED AGENT"
    },
    {
      "NUM_FINANCIER_CD": 10641,
      "TXT_FINANCIER_NAME": "DELHI INSTALMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10642,
      "TXT_FINANCIER_NAME": "PADVIDHAR NAGRI SAHAKARI PATHSANSTHA LTD RATNAGIRI"
    },
    {
      "NUM_FINANCIER_CD": 10643,
      "TXT_FINANCIER_NAME": "EXECUTIVE ENGGINER MINOR IRRIGATION DIV KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 10644,
      "TXT_FINANCIER_NAME": "VISHWAS CO OP BANK LTD NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 10645,
      "TXT_FINANCIER_NAME": "RICHA INDUSTRIES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10646,
      "TXT_FINANCIER_NAME": "DANYA VYAPARIGALA PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10647,
      "TXT_FINANCIER_NAME": "PAIPRA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10648,
      "TXT_FINANCIER_NAME": "DURGA GANESH CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10649,
      "TXT_FINANCIER_NAME": "THE TISKA USGAO URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10650,
      "TXT_FINANCIER_NAME": "OTTAPALAM PRIMARY COOPERATIVE AGRICULTURAL DEVELOPEMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 10651,
      "TXT_FINANCIER_NAME": "RAJ FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10652,
      "TXT_FINANCIER_NAME": "KRASHAK BHARTI CO OPRATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10653,
      "TXT_FINANCIER_NAME": "NESTLY INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10654,
      "TXT_FINANCIER_NAME": "MARCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10655,
      "TXT_FINANCIER_NAME": "VASANTRAJ CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10656,
      "TXT_FINANCIER_NAME": "SIDI VINAYAKA PATTANA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10657,
      "TXT_FINANCIER_NAME": "SHARMA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10658,
      "TXT_FINANCIER_NAME": "CHHATTISGARH STATE POWER GENERATION COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10659,
      "TXT_FINANCIER_NAME": "RAJARAM BAPU GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10660,
      "TXT_FINANCIER_NAME": "MAHUVA PEOPLES CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10661,
      "TXT_FINANCIER_NAME": "THE BRAHMARSHI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10662,
      "TXT_FINANCIER_NAME": "KULASEKHARAMANGALAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10663,
      "TXT_FINANCIER_NAME": "MANNIY SAHAKARI MAHRSHI SHRI GOVIND NANA SONAWANE NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10664,
      "TXT_FINANCIER_NAME": "VIRNAGAR JUTHSEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10665,
      "TXT_FINANCIER_NAME": "ORGAMINE CHEMICALS I PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10666,
      "TXT_FINANCIER_NAME": "SREE VALLI FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 10667,
      "TXT_FINANCIER_NAME": "MOBARAKPUR SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10668,
      "TXT_FINANCIER_NAME": "FULERTON INDIA CREDIT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10669,
      "TXT_FINANCIER_NAME": "SINDHUDUR ZILHA VAISHYA SAMAJ SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10670,
      "TXT_FINANCIER_NAME": "PRADHANI KRISHI SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10671,
      "TXT_FINANCIER_NAME": "BALMAER LAWRIE AND CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10672,
      "TXT_FINANCIER_NAME": "EXECUTIVE ENGINEER PWD"
    },
    {
      "NUM_FINANCIER_CD": 10673,
      "TXT_FINANCIER_NAME": "SRI DEVI AUTO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10674,
      "TXT_FINANCIER_NAME": "JINDESHA MADAR ALPASANKHYATARPATTIN SAHAKARI SANG NIMYAT"
    },
    {
      "NUM_FINANCIER_CD": 10675,
      "TXT_FINANCIER_NAME": "THE AKTA CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10676,
      "TXT_FINANCIER_NAME": "HALE MUDIGERE KRUSHI PATTHINA SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 10677,
      "TXT_FINANCIER_NAME": "SRI BRAMARAMBHA PATTIN SOUHARDHA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 10678,
      "TXT_FINANCIER_NAME": "SRI ESWARA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10679,
      "TXT_FINANCIER_NAME": "CHOLAI AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10680,
      "TXT_FINANCIER_NAME": "AGUNKUMARI S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 10681,
      "TXT_FINANCIER_NAME": "RURAL DEVELOPMENT DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 10682,
      "TXT_FINANCIER_NAME": "NATIONAL ATMOSPHERIC RESEARCH LABORATORY"
    },
    {
      "NUM_FINANCIER_CD": 10683,
      "TXT_FINANCIER_NAME": "BHAGYALAXMI CO OP CRE SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10684,
      "TXT_FINANCIER_NAME": "THE VIBHAG NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10685,
      "TXT_FINANCIER_NAME": "PUSTIKAR LAGHU VYAPARIK PRATISTHAN BACHAT EVAM SAKH SAHAKARI SAMITI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10686,
      "TXT_FINANCIER_NAME": "KUMBRA VYAVASAYA SEVA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10687,
      "TXT_FINANCIER_NAME": "J K PRIME INVESTMENT AND LEASING INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10688,
      "TXT_FINANCIER_NAME": "PRAJESH INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10689,
      "TXT_FINANCIER_NAME": "DHARAPURAM NEW KAMADHENU AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10690,
      "TXT_FINANCIER_NAME": "BIJAY FINANCE AND ISVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10691,
      "TXT_FINANCIER_NAME": "KHUSHOO FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10692,
      "TXT_FINANCIER_NAME": "THE NILDOHA SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10693,
      "TXT_FINANCIER_NAME": "CHANDRALOK FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10694,
      "TXT_FINANCIER_NAME": "AJARA ARBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10695,
      "TXT_FINANCIER_NAME": "PARASHWANTH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10696,
      "TXT_FINANCIER_NAME": "SIMAR HIRE PURCHASE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10697,
      "TXT_FINANCIER_NAME": "ADARSH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10698,
      "TXT_FINANCIER_NAME": "SHREE VIRPUR URBAN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10699,
      "TXT_FINANCIER_NAME": "VARDHAMAN URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10700,
      "TXT_FINANCIER_NAME": "KARMALI CORLIM V K S SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10701,
      "TXT_FINANCIER_NAME": "A V FINANCER S INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10702,
      "TXT_FINANCIER_NAME": "UTI COUNTRY WIDE AUTO FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10703,
      "TXT_FINANCIER_NAME": "THE HAJIPUR GAMANI SEVA MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10704,
      "TXT_FINANCIER_NAME": "VALSALA CREDIT SOUHARDA CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10705,
      "TXT_FINANCIER_NAME": "THE HARIPURA GROUP SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10706,
      "TXT_FINANCIER_NAME": "ASHOK KUMAR BHANDARI AND SONS FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10707,
      "TXT_FINANCIER_NAME": "THE JHAMKHANDI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10708,
      "TXT_FINANCIER_NAME": "SHRI SANGAMESHWARA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10709,
      "TXT_FINANCIER_NAME": "SHREE HARI LEASING"
    },
    {
      "NUM_FINANCIER_CD": 10710,
      "TXT_FINANCIER_NAME": "THE NASHIK NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10711,
      "TXT_FINANCIER_NAME": "PARASMAL GOUTAMCHAR ROONWAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10712,
      "TXT_FINANCIER_NAME": "SHRI SWETAMBER JAIN CRADIT CO OPARETIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10713,
      "TXT_FINANCIER_NAME": "GUJARAT INFRASTRUCTURE DEVLOPMENT BOARD"
    },
    {
      "NUM_FINANCIER_CD": 10714,
      "TXT_FINANCIER_NAME": "TANEJA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10715,
      "TXT_FINANCIER_NAME": "SWAMI SWARUPANAND SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10716,
      "TXT_FINANCIER_NAME": "AANANTHAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10717,
      "TXT_FINANCIER_NAME": "C R SOUHARDHA PATTINA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 10718,
      "TXT_FINANCIER_NAME": "PAWANAMAI NAGARI SAHAKARI PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10719,
      "TXT_FINANCIER_NAME": "BHILWARA AJMER KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10720,
      "TXT_FINANCIER_NAME": "THE KALLOLI URBAN CREDIT SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10721,
      "TXT_FINANCIER_NAME": "SHRIRAM TARNSPORT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10722,
      "TXT_FINANCIER_NAME": "NAGOTHANE VYAPARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10723,
      "TXT_FINANCIER_NAME": "LESSOR ARVAL INDIA PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10724,
      "TXT_FINANCIER_NAME": "NITYA CONSULTANCY"
    },
    {
      "NUM_FINANCIER_CD": 10725,
      "TXT_FINANCIER_NAME": "SRI ANDAVAR INVESTMENT GV NAIDU COM"
    },
    {
      "NUM_FINANCIER_CD": 10726,
      "TXT_FINANCIER_NAME": "M S FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10727,
      "TXT_FINANCIER_NAME": "ILKAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10728,
      "TXT_FINANCIER_NAME": "SHREE KEDARNATH NAGARI SAH PATH SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 10729,
      "TXT_FINANCIER_NAME": "MALANDU CO OP AGRI AND RURAL DEVELOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10730,
      "TXT_FINANCIER_NAME": "SHANMUGAVEL CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 10731,
      "TXT_FINANCIER_NAME": "U K FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10732,
      "TXT_FINANCIER_NAME": "PUNIT ENTERPRIES"
    },
    {
      "NUM_FINANCIER_CD": 10733,
      "TXT_FINANCIER_NAME": "SHREE HARIHARESHWARA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10734,
      "TXT_FINANCIER_NAME": "THE DAVANGERE DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10735,
      "TXT_FINANCIER_NAME": "MOHOL TALUKA VYAPARI GRAMIN BIGARSHETI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10736,
      "TXT_FINANCIER_NAME": "SHREE VASANTRAO CHOUGULE NAGARI SAH PATH SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 10737,
      "TXT_FINANCIER_NAME": "THE JEEJAMATA MULTIPURPOSE URBAN CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10738,
      "TXT_FINANCIER_NAME": "BEENADEVI S MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 10739,
      "TXT_FINANCIER_NAME": "NIGHOJ NAG SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10740,
      "TXT_FINANCIER_NAME": "SHRI NARSINHA SARSWATI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10741,
      "TXT_FINANCIER_NAME": "KARNATKA STATE POLLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 10742,
      "TXT_FINANCIER_NAME": "THE GIRISH CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10743,
      "TXT_FINANCIER_NAME": "LAND DEVLOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 10744,
      "TXT_FINANCIER_NAME": "HINDUSTAN LATEX EMPLOYEES CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10745,
      "TXT_FINANCIER_NAME": "HUDCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10746,
      "TXT_FINANCIER_NAME": "GRUH FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10747,
      "TXT_FINANCIER_NAME": "KHAMBALA SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10748,
      "TXT_FINANCIER_NAME": "SAHASRARJUNA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10749,
      "TXT_FINANCIER_NAME": "THE ACCOUNTS GENERAL EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10750,
      "TXT_FINANCIER_NAME": "SHIVSHANKAR CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10751,
      "TXT_FINANCIER_NAME": "JYOTI AAVAJ GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10752,
      "TXT_FINANCIER_NAME": "AHMADNAGAR SHAHAR SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10753,
      "TXT_FINANCIER_NAME": "SUMER FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10754,
      "TXT_FINANCIER_NAME": "BHGYASHRI NIVEDITA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10755,
      "TXT_FINANCIER_NAME": "CHITTORGARH KENDRIYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10756,
      "TXT_FINANCIER_NAME": "SASUNDOCK MATSYAGANGA CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 10757,
      "TXT_FINANCIER_NAME": "SHIVA SHREE SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10758,
      "TXT_FINANCIER_NAME": "THE DELHI CATHOLIC SYRIAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10759,
      "TXT_FINANCIER_NAME": "PANOOR CO OPERATIVE MARKANTAILE SOCITY"
    },
    {
      "NUM_FINANCIER_CD": 10760,
      "TXT_FINANCIER_NAME": "MEJA URJA NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 10761,
      "TXT_FINANCIER_NAME": "VASANTHA DEVI BAGHMAR"
    },
    {
      "NUM_FINANCIER_CD": 10762,
      "TXT_FINANCIER_NAME": "ADMOND KURIES AND LOANS P LTD"
    },
    {
      "NUM_FINANCIER_CD": 10763,
      "TXT_FINANCIER_NAME": "C A O BSNL"
    },
    {
      "NUM_FINANCIER_CD": 10764,
      "TXT_FINANCIER_NAME": "KATARIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10765,
      "TXT_FINANCIER_NAME": "KALAISELVI AUTOFINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10766,
      "TXT_FINANCIER_NAME": "MAULI NAGARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10767,
      "TXT_FINANCIER_NAME": "EAST WEST FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10768,
      "TXT_FINANCIER_NAME": "SHRI KAVIRATNA KALIDASA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10769,
      "TXT_FINANCIER_NAME": "AUTO NIRVAHAKARA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10770,
      "TXT_FINANCIER_NAME": "VARI FINANCE AND LEASING CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10771,
      "TXT_FINANCIER_NAME": "HEALTH CREDIT SOUDHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 10772,
      "TXT_FINANCIER_NAME": "THE UNITY CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10773,
      "TXT_FINANCIER_NAME": "SAHAYOG CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10774,
      "TXT_FINANCIER_NAME": "DELHI GASTRO NOMY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10775,
      "TXT_FINANCIER_NAME": "WARDHA URBAN AND RURAL COOPRATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10776,
      "TXT_FINANCIER_NAME": "DHANGANGA SVAYAMSAHAYATA GRA BIG SHETI SAH PATSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10777,
      "TXT_FINANCIER_NAME": "NAGESHWARI NAGARI SAH PAT SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10778,
      "TXT_FINANCIER_NAME": "SUDERSHAN LEASING AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10779,
      "TXT_FINANCIER_NAME": "BHARAT CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10780,
      "TXT_FINANCIER_NAME": "MALHOTRA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10781,
      "TXT_FINANCIER_NAME": "SOHAM NARGI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10782,
      "TXT_FINANCIER_NAME": "SRI NANDI PATTINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10783,
      "TXT_FINANCIER_NAME": "LANJA NAGRI SAHAKARI PATASANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10784,
      "TXT_FINANCIER_NAME": "LORRY OWMERS AND SUPPLIERS MULTI PURPOSE CO OP SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10785,
      "TXT_FINANCIER_NAME": "HAWELI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10786,
      "TXT_FINANCIER_NAME": "KARNATAKA KALAL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10787,
      "TXT_FINANCIER_NAME": "SHRIPATRAO NANASO PAWAR NAG SAH PAT SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 10788,
      "TXT_FINANCIER_NAME": "BAHUJAN COAL URJA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 10789,
      "TXT_FINANCIER_NAME": "SATYAM MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 10790,
      "TXT_FINANCIER_NAME": "THE VETTATHUR SERVICE CO OPRATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10791,
      "TXT_FINANCIER_NAME": "SHREE SAMARTH GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10792,
      "TXT_FINANCIER_NAME": "ADHYAPAKA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10793,
      "TXT_FINANCIER_NAME": "SWA SAMADHAN SWAYATT SAKH SAHAKARITA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10794,
      "TXT_FINANCIER_NAME": "DR MANIBHAI DESAI GRAMIN NON AGRICULTURE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10795,
      "TXT_FINANCIER_NAME": "THE BANK OF RAJASHTHAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 10796,
      "TXT_FINANCIER_NAME": "POTTERS COTRTAGE INDUSTRIAL CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10797,
      "TXT_FINANCIER_NAME": "POTTERS COTTAGE INDUSTRIAL CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10798,
      "TXT_FINANCIER_NAME": "SANDUR PATTANNA SOUHARDA BANK NIYAMITHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 10799,
      "TXT_FINANCIER_NAME": "KRISHK BHARTI CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10800,
      "TXT_FINANCIER_NAME": "NAGINA HIRE PURCHASE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 10801,
      "TXT_FINANCIER_NAME": "THE EZHUMATTOOR SERVICE CO OPERTTIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10802,
      "TXT_FINANCIER_NAME": "JANSEVA URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10803,
      "TXT_FINANCIER_NAME": "INDIAN OIL CARPORATION LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 10804,
      "TXT_FINANCIER_NAME": "WELLA INDIA HAIR COSMECTICS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10805,
      "TXT_FINANCIER_NAME": "CATHOLIC URBAN CO OP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10806,
      "TXT_FINANCIER_NAME": "P RAVINDER CHAJJED"
    },
    {
      "NUM_FINANCIER_CD": 10807,
      "TXT_FINANCIER_NAME": "DHANALAKSMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10808,
      "TXT_FINANCIER_NAME": "CHERUTHAYAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10809,
      "TXT_FINANCIER_NAME": "GURUKRIPA GRAMIN BIGARSHETI SAHKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10810,
      "TXT_FINANCIER_NAME": "SRI GANGA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10811,
      "TXT_FINANCIER_NAME": "G BHANECHAND NAHAR AND FAMILY AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10812,
      "TXT_FINANCIER_NAME": "AHINSHA NAGARI SAH PATH SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10235,
      "TXT_FINANCIER_NAME": "SINDHUDURG CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10236,
      "TXT_FINANCIER_NAME": "SIRI CHARAN SAUHARDHA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10237,
      "TXT_FINANCIER_NAME": "SHREE GANESH JAGRUT NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10238,
      "TXT_FINANCIER_NAME": "SHRI AMBIKA GRAMIN BIG SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10239,
      "TXT_FINANCIER_NAME": "RIFLE FACTORY CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10240,
      "TXT_FINANCIER_NAME": "RBG LEASING AND CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10241,
      "TXT_FINANCIER_NAME": "ASHOK AND CO"
    },
    {
      "NUM_FINANCIER_CD": 10242,
      "TXT_FINANCIER_NAME": "AADARSH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10243,
      "TXT_FINANCIER_NAME": "RANDHIR FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10244,
      "TXT_FINANCIER_NAME": "JAI BAJRANG NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10245,
      "TXT_FINANCIER_NAME": "SARSWATI CO OP CRIDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10246,
      "TXT_FINANCIER_NAME": "S P MUNDRA AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10247,
      "TXT_FINANCIER_NAME": "EECO BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10248,
      "TXT_FINANCIER_NAME": "THE KALRA MULTIPURPOSE"
    },
    {
      "NUM_FINANCIER_CD": 10249,
      "TXT_FINANCIER_NAME": "BALAGANAPTHY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10250,
      "TXT_FINANCIER_NAME": "SAHAYTA FINANCE LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10251,
      "TXT_FINANCIER_NAME": "SAHAYTA EINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10252,
      "TXT_FINANCIER_NAME": "DURGA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10253,
      "TXT_FINANCIER_NAME": "GANDHI SMARAKA GRAMASEVA KENDRAM"
    },
    {
      "NUM_FINANCIER_CD": 10254,
      "TXT_FINANCIER_NAME": "MAJILE SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10255,
      "TXT_FINANCIER_NAME": "RARU AGENCIES AND INVESTMENT P LTD"
    },
    {
      "NUM_FINANCIER_CD": 10256,
      "TXT_FINANCIER_NAME": "MUKTAI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10257,
      "TXT_FINANCIER_NAME": "INDU FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10258,
      "TXT_FINANCIER_NAME": "PRADEEP FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10259,
      "TXT_FINANCIER_NAME": "SAVI FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 10260,
      "TXT_FINANCIER_NAME": "AAKASHGANGA NAGRIK SAHKARI SARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10261,
      "TXT_FINANCIER_NAME": "SHRI RAM GRAMIN BIGARSHETI CO OP PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10262,
      "TXT_FINANCIER_NAME": "NAHAR INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 10263,
      "TXT_FINANCIER_NAME": "VIJAY GRAMIN BIGARSHETI SAH PTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 10264,
      "TXT_FINANCIER_NAME": "THE AMARAMBALAM SERVICE CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10265,
      "TXT_FINANCIER_NAME": "MAHAVEER CHAND NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 10266,
      "TXT_FINANCIER_NAME": "SHREE PURSHTI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10267,
      "TXT_FINANCIER_NAME": "GOVT OF TAMILNADU MOTOR CAR ADVANCE"
    },
    {
      "NUM_FINANCIER_CD": 10268,
      "TXT_FINANCIER_NAME": "KARAVALI SOUHARDA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10269,
      "TXT_FINANCIER_NAME": "DHARTI LEASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10270,
      "TXT_FINANCIER_NAME": "BALASAHEB MANE BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10271,
      "TXT_FINANCIER_NAME": "HARYANA STATE AGRO MARKETING BOARD"
    },
    {
      "NUM_FINANCIER_CD": 10272,
      "TXT_FINANCIER_NAME": "KRANTI GRAMIN BIGAR SHETI SAH PATH SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10273,
      "TXT_FINANCIER_NAME": "THRISSUR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10274,
      "TXT_FINANCIER_NAME": "THE SINGHBHUM DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10275,
      "TXT_FINANCIER_NAME": "AATHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10276,
      "TXT_FINANCIER_NAME": "AUTO FINTRADE"
    },
    {
      "NUM_FINANCIER_CD": 10277,
      "TXT_FINANCIER_NAME": "SHREE KESHAV CO OPP CREDIT SOC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10278,
      "TXT_FINANCIER_NAME": "U K CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10279,
      "TXT_FINANCIER_NAME": "THE BHAGYODAY SARAFI SAH MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10280,
      "TXT_FINANCIER_NAME": "KERALA AGRO MACHINERY CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 10281,
      "TXT_FINANCIER_NAME": "DEVAR HIPPARAGI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10282,
      "TXT_FINANCIER_NAME": "EIH LTD"
    },
    {
      "NUM_FINANCIER_CD": 10283,
      "TXT_FINANCIER_NAME": "TAMILNADU WAREHOUSING CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 10284,
      "TXT_FINANCIER_NAME": "TAMILNADU POLLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 10285,
      "TXT_FINANCIER_NAME": "THE THARUVANA SERVICE CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10286,
      "TXT_FINANCIER_NAME": "VIBHUTI FINANCERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 10287,
      "TXT_FINANCIER_NAME": "KANNUR DIST EX SERVICEMEN MULTIPURPOSE CO OP SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10288,
      "TXT_FINANCIER_NAME": "SHRI TUMSARESHWAR MAHAGANPATI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10289,
      "TXT_FINANCIER_NAME": "SUNIL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10290,
      "TXT_FINANCIER_NAME": "RADEJA COMM CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10291,
      "TXT_FINANCIER_NAME": "KURKUTTA PRIMARY AGRICULTURE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10292,
      "TXT_FINANCIER_NAME": "SHREE SHYAM FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10293,
      "TXT_FINANCIER_NAME": "RUI GRAMIN SAHAKARI PATH SANSHTA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10294,
      "TXT_FINANCIER_NAME": "NORTHERN POWER DISTRIBUTION COMPANY OF AP LTD"
    },
    {
      "NUM_FINANCIER_CD": 10295,
      "TXT_FINANCIER_NAME": "SANJAY GANDHI NAGARI SAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10296,
      "TXT_FINANCIER_NAME": "RAJMATA JIJAU NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10297,
      "TXT_FINANCIER_NAME": "THE HEBRI VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA HEBRI"
    },
    {
      "NUM_FINANCIER_CD": 10298,
      "TXT_FINANCIER_NAME": "KARKALA TALUK AGRICULTURAL PRODUCED CO OP MARKETING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10299,
      "TXT_FINANCIER_NAME": "SOMVANSHI PATHARE KSHATRIYA SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10300,
      "TXT_FINANCIER_NAME": "SHL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10301,
      "TXT_FINANCIER_NAME": "SAKSHAM AUTO"
    },
    {
      "NUM_FINANCIER_CD": 10302,
      "TXT_FINANCIER_NAME": "BHARAT SANCHAR NIGAM LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10303,
      "TXT_FINANCIER_NAME": "ACGL SHEET METAL EMPLOYEES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10304,
      "TXT_FINANCIER_NAME": "DUDH GANGA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10305,
      "TXT_FINANCIER_NAME": "URBAN SOUHARD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10306,
      "TXT_FINANCIER_NAME": "RAUNAK FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10307,
      "TXT_FINANCIER_NAME": "BAGHMAR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10308,
      "TXT_FINANCIER_NAME": "KHALSA FINANCE COM LTD"
    },
    {
      "NUM_FINANCIER_CD": 10309,
      "TXT_FINANCIER_NAME": "STATE BANK OF AHMEDABAD"
    },
    {
      "NUM_FINANCIER_CD": 10310,
      "TXT_FINANCIER_NAME": "DHOLKA DAXIN VIBHAG URBAN CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10311,
      "TXT_FINANCIER_NAME": "G D BETALA FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10312,
      "TXT_FINANCIER_NAME": "PADMABHUSHAN VASANTDADA PATIL GRAMIN BIGERSHETI SAH PATH SAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10313,
      "TXT_FINANCIER_NAME": "ATAL FINANCE COMPNAY"
    },
    {
      "NUM_FINANCIER_CD": 10314,
      "TXT_FINANCIER_NAME": "D C FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10315,
      "TXT_FINANCIER_NAME": "AMMAIAPPAR INVESMENT"
    },
    {
      "NUM_FINANCIER_CD": 10316,
      "TXT_FINANCIER_NAME": "SAI DATTA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10317,
      "TXT_FINANCIER_NAME": "SHREE RANGDASSWAMI SAHAKARI PATH PEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10318,
      "TXT_FINANCIER_NAME": "PADAMCHAND SINGHVI AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 10319,
      "TXT_FINANCIER_NAME": "ETAWAH BALLIA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 10320,
      "TXT_FINANCIER_NAME": "GOVERNOR OF ANDHRA PRADESH"
    },
    {
      "NUM_FINANCIER_CD": 10321,
      "TXT_FINANCIER_NAME": "TRIBAL CO MARKETING DEVELOPMENT FEDRATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10322,
      "TXT_FINANCIER_NAME": "GAUTAMCHAND BETALA H U F"
    },
    {
      "NUM_FINANCIER_CD": 10323,
      "TXT_FINANCIER_NAME": "ANIL KUMAR GOYAL"
    },
    {
      "NUM_FINANCIER_CD": 10324,
      "TXT_FINANCIER_NAME": "SHIVRAJ INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 10325,
      "TXT_FINANCIER_NAME": "BAGODI FINANCE LEASING R"
    },
    {
      "NUM_FINANCIER_CD": 10326,
      "TXT_FINANCIER_NAME": "UNIQUE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 10327,
      "TXT_FINANCIER_NAME": "BHARAT FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 10328,
      "TXT_FINANCIER_NAME": "LOKSEVA MULTI PURPOSE CO OP SOCIETY TLD"
    },
    {
      "NUM_FINANCIER_CD": 10329,
      "TXT_FINANCIER_NAME": "HAVELI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10330,
      "TXT_FINANCIER_NAME": "RAHURI TALUKA DOCTORS AND CHEMEST URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10331,
      "TXT_FINANCIER_NAME": "NAVIN SUBHEDAR NAG SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10332,
      "TXT_FINANCIER_NAME": "SRI RAM FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11570,
      "TXT_FINANCIER_NAME": "KODAKAD SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11571,
      "TXT_FINANCIER_NAME": "LIQUID PROPULSION SYSTEMS CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 11572,
      "TXT_FINANCIER_NAME": "NANTHI GROUP AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11573,
      "TXT_FINANCIER_NAME": "ESWARI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11574,
      "TXT_FINANCIER_NAME": "NARENDRA KUMAR CHORODIA"
    },
    {
      "NUM_FINANCIER_CD": 11575,
      "TXT_FINANCIER_NAME": "PADUPANAMBUR CO OP AGRICULTURALBANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11576,
      "TXT_FINANCIER_NAME": "THE NESARAGI URBAN CO OPERATIVE CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11577,
      "TXT_FINANCIER_NAME": "PAYYANNUR CO OP RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 11578,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER ELECTRICAL OFFICE VARAHI KPC LTD HOSANGADI UDUPI DIST"
    },
    {
      "NUM_FINANCIER_CD": 11579,
      "TXT_FINANCIER_NAME": "BHIGWAN GRAMIN BIGER SHETI SAHAKATI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11580,
      "TXT_FINANCIER_NAME": "PETLAD SOJITRA TALUKA PRIMARY TEACHERS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11581,
      "TXT_FINANCIER_NAME": "JAMMU GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11582,
      "TXT_FINANCIER_NAME": "SRI KANNIKA PARAMESJWARI SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11583,
      "TXT_FINANCIER_NAME": "GANESH MOTOR"
    },
    {
      "NUM_FINANCIER_CD": 11584,
      "TXT_FINANCIER_NAME": "PATHARDI VIVIDHA KARYAKARI SAHA VIKAS SEVA"
    },
    {
      "NUM_FINANCIER_CD": 11585,
      "TXT_FINANCIER_NAME": "LUNAWAT AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11586,
      "TXT_FINANCIER_NAME": "DAULAT NAGRI SAHKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11587,
      "TXT_FINANCIER_NAME": "BHAIRAVNATH SAHAKARI PATHSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 11588,
      "TXT_FINANCIER_NAME": "MANGALAM AUTO FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11589,
      "TXT_FINANCIER_NAME": "KALPAVRUKSHA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11590,
      "TXT_FINANCIER_NAME": "SHREE MORBI TALUKA SHIKSHAK SARAFI SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11591,
      "TXT_FINANCIER_NAME": "NIDHI KIRAN FINANCE AND INVESTMENT COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11592,
      "TXT_FINANCIER_NAME": "SHREE DATTASEVA GRAMIN BIGARSHETI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11593,
      "TXT_FINANCIER_NAME": "BHAGYAJYOTHI URBAN CR SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 11594,
      "TXT_FINANCIER_NAME": "SIMMI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 11595,
      "TXT_FINANCIER_NAME": "THE RISHIWATE URBAN CO CREDATE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11596,
      "TXT_FINANCIER_NAME": "ABDUL ASIF FREE FINANCE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11597,
      "TXT_FINANCIER_NAME": "SHINGVE VIVID KARYAKARI SAHAKARI V SEVA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11598,
      "TXT_FINANCIER_NAME": "USHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11599,
      "TXT_FINANCIER_NAME": "R PADAMCHAND BAGMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11600,
      "TXT_FINANCIER_NAME": "SRI AISWARYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11601,
      "TXT_FINANCIER_NAME": "SRI AMMAN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11602,
      "TXT_FINANCIER_NAME": "JAIKISAN GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11603,
      "TXT_FINANCIER_NAME": "MALEGAON TALUKA MATOSHREE NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11604,
      "TXT_FINANCIER_NAME": "RUDRUGUPPE VSSN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11605,
      "TXT_FINANCIER_NAME": "DADA GUJAR NAGARI SAHAKARI PATH SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 11606,
      "TXT_FINANCIER_NAME": "SAMAJSEVA GRAMIN BIGERSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11607,
      "TXT_FINANCIER_NAME": "MIRA NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11608,
      "TXT_FINANCIER_NAME": "SHREE LAXMI SAHKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11609,
      "TXT_FINANCIER_NAME": "HEDGEWAR NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11610,
      "TXT_FINANCIER_NAME": "ANITA ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 11611,
      "TXT_FINANCIER_NAME": "SRI KANKA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11612,
      "TXT_FINANCIER_NAME": "K B FINCAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11613,
      "TXT_FINANCIER_NAME": "K R FINCORP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11614,
      "TXT_FINANCIER_NAME": "SHREE DHOLESHWAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11615,
      "TXT_FINANCIER_NAME": "GOA INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11616,
      "TXT_FINANCIER_NAME": "VESUVIUS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11617,
      "TXT_FINANCIER_NAME": "KONGU CORORATION"
    },
    {
      "NUM_FINANCIER_CD": 11618,
      "TXT_FINANCIER_NAME": "KUMBHAT AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11619,
      "TXT_FINANCIER_NAME": "SUNIL KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 11620,
      "TXT_FINANCIER_NAME": "AYEE AMMAN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11621,
      "TXT_FINANCIER_NAME": "SHANKAR NAGRI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11622,
      "TXT_FINANCIER_NAME": "MARCHANTS SOUHARDA SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 11623,
      "TXT_FINANCIER_NAME": "FEROKE SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11624,
      "TXT_FINANCIER_NAME": "SHREE PATNESHWAR URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11625,
      "TXT_FINANCIER_NAME": "SRI VAIRAVINAYAKAR ADVANGES"
    },
    {
      "NUM_FINANCIER_CD": 11626,
      "TXT_FINANCIER_NAME": "GOUTHAM CHAND JAIN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11627,
      "TXT_FINANCIER_NAME": "LORD KRISHNA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 11628,
      "TXT_FINANCIER_NAME": "WOMAN WELFARE ORGANISATION"
    },
    {
      "NUM_FINANCIER_CD": 11629,
      "TXT_FINANCIER_NAME": "POWERGRID CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11630,
      "TXT_FINANCIER_NAME": "SUNNY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11631,
      "TXT_FINANCIER_NAME": "ASSOCIATION FOR VOLUNTARY ACTION"
    },
    {
      "NUM_FINANCIER_CD": 11632,
      "TXT_FINANCIER_NAME": "SUNIL KUMAR JAIN H U F"
    },
    {
      "NUM_FINANCIER_CD": 11633,
      "TXT_FINANCIER_NAME": "THE MELUKAVU SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11634,
      "TXT_FINANCIER_NAME": "SHRI BALAJI NAGRI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11635,
      "TXT_FINANCIER_NAME": "SHRI BRAHAMDEV URBAN CREDIT SOUHARD SAH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11636,
      "TXT_FINANCIER_NAME": "CHETANA NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11637,
      "TXT_FINANCIER_NAME": "DHAWALSAGAR GRAMIN BIGAR SHETI SAH PAT SANSTHA MRY GHOSARWAD"
    },
    {
      "NUM_FINANCIER_CD": 11638,
      "TXT_FINANCIER_NAME": "TRADAL GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MRY"
    },
    {
      "NUM_FINANCIER_CD": 11639,
      "TXT_FINANCIER_NAME": "NAGPUR MAHILA VIKAS COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11640,
      "TXT_FINANCIER_NAME": "PREM ENTERPRIES"
    },
    {
      "NUM_FINANCIER_CD": 11641,
      "TXT_FINANCIER_NAME": "IDUKKI SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11642,
      "TXT_FINANCIER_NAME": "THE UMA SAHKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11643,
      "TXT_FINANCIER_NAME": "UGB"
    },
    {
      "NUM_FINANCIER_CD": 11644,
      "TXT_FINANCIER_NAME": "LATE APPASO SAKHARAM SITARAM NAWARKAR NAGARI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11645,
      "TXT_FINANCIER_NAME": "SHRADDHA SABURI GRAMIN BIGAR SHETI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11646,
      "TXT_FINANCIER_NAME": "ANKIT DUGAR"
    },
    {
      "NUM_FINANCIER_CD": 11647,
      "TXT_FINANCIER_NAME": "SRI CHOWDESHWARI PATTHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11648,
      "TXT_FINANCIER_NAME": "NARGISDATTA MAHILA NAGRI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11649,
      "TXT_FINANCIER_NAME": "TTT"
    },
    {
      "NUM_FINANCIER_CD": 11650,
      "TXT_FINANCIER_NAME": "MANOKAMANA CREDIT CO OP SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11651,
      "TXT_FINANCIER_NAME": "VIMAL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11652,
      "TXT_FINANCIER_NAME": "SHISHAKTI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11653,
      "TXT_FINANCIER_NAME": "SHARADCHANDRA NAG SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11654,
      "TXT_FINANCIER_NAME": "ANANDRAO CHAVAN BIGARSHETI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11655,
      "TXT_FINANCIER_NAME": "YASHWANTRAO DATTU GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11656,
      "TXT_FINANCIER_NAME": "KNPS THE HINDUSTAN LIBERAL VIVIDODDESHAGALA SAHAKARI SANGH NI"
    },
    {
      "NUM_FINANCIER_CD": 11657,
      "TXT_FINANCIER_NAME": "SYALI LEASING AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11658,
      "TXT_FINANCIER_NAME": "DR MANIBHAI DESAI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11659,
      "TXT_FINANCIER_NAME": "S R S PROMOTERS"
    },
    {
      "NUM_FINANCIER_CD": 11660,
      "TXT_FINANCIER_NAME": "THE BHARATPUR URABAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11661,
      "TXT_FINANCIER_NAME": "R R AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11662,
      "TXT_FINANCIER_NAME": "RAJGARHIA FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11663,
      "TXT_FINANCIER_NAME": "SANT ROHIDAS LEATHER INDUSTRIES AND CHARMAKAR DEV CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 11664,
      "TXT_FINANCIER_NAME": "INDIA BULL FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11665,
      "TXT_FINANCIER_NAME": "THE KUNDI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10904,
      "TXT_FINANCIER_NAME": "SRI RAJA RAJESWARI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10905,
      "TXT_FINANCIER_NAME": "KRUNAL CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 10906,
      "TXT_FINANCIER_NAME": "PRECIASE FINANCE AND INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 10907,
      "TXT_FINANCIER_NAME": "SUNASK FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10908,
      "TXT_FINANCIER_NAME": "SIVALAYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10909,
      "TXT_FINANCIER_NAME": "UNDER SECRETARY TO GOVT HARYANA REVENUE AND DISASTER MGT DEPTT CHANDIGARH"
    },
    {
      "NUM_FINANCIER_CD": 10910,
      "TXT_FINANCIER_NAME": "UTKAL MANUFACTURING AND SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 10911,
      "TXT_FINANCIER_NAME": "SAVITRIBAI FHULE MAGASWARGIYA MAHILA GRAMIN BIGAR SAHA PAT M"
    },
    {
      "NUM_FINANCIER_CD": 10912,
      "TXT_FINANCIER_NAME": "RAJAPUR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10913,
      "TXT_FINANCIER_NAME": "ANAND HIRE PURCHASEING"
    },
    {
      "NUM_FINANCIER_CD": 10914,
      "TXT_FINANCIER_NAME": "MAHUVA VIBHAG PEOPLES CO OP CRADIT SOCIETY LTD MAHUVA"
    },
    {
      "NUM_FINANCIER_CD": 10915,
      "TXT_FINANCIER_NAME": "STATE BANK OFINDIA CHORAO"
    },
    {
      "NUM_FINANCIER_CD": 10916,
      "TXT_FINANCIER_NAME": "SIRSI URBAN SOUHARDA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 10917,
      "TXT_FINANCIER_NAME": "KAI VYANKATRAO PATIL KAVALE BIGAR SHETI SAHAKARI PAT SANSTHA MARYADIT SINDHI TQ UMRI"
    },
    {
      "NUM_FINANCIER_CD": 10918,
      "TXT_FINANCIER_NAME": "UMANAGAR DHIRAN AND GRAHAK SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10919,
      "TXT_FINANCIER_NAME": "SATPATH GRAM VIKAS SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10920,
      "TXT_FINANCIER_NAME": "THE SARVALA VIVIDH KARYAKARI SEVA SAH MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10921,
      "TXT_FINANCIER_NAME": "SHREE NIMAR SHWETAMBER JAIN CREDIT CO OPERATIVE LTD BARWAHA"
    },
    {
      "NUM_FINANCIER_CD": 10922,
      "TXT_FINANCIER_NAME": "INDIAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10923,
      "TXT_FINANCIER_NAME": "DNYANDIP GRAMIN BIG SHE SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10924,
      "TXT_FINANCIER_NAME": "DNYANDIP GRAMIN BIGAR SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10925,
      "TXT_FINANCIER_NAME": "SHREE VISHVANATH MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10926,
      "TXT_FINANCIER_NAME": "BALASIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10927,
      "TXT_FINANCIER_NAME": "SHREE BHARATNAGAR SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10928,
      "TXT_FINANCIER_NAME": "DHANLAXMI MAHILA GRAMIN BIGARSHETI"
    },
    {
      "NUM_FINANCIER_CD": 10929,
      "TXT_FINANCIER_NAME": "A V THOMAS AND CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10930,
      "TXT_FINANCIER_NAME": "SAWANTWADI SAHAKARI PATHPEDHI LTD SAWANTWADI"
    },
    {
      "NUM_FINANCIER_CD": 10931,
      "TXT_FINANCIER_NAME": "MA DR UTTAMRAO SHENDE GR BIG SHETI SAH PTS MARYA"
    },
    {
      "NUM_FINANCIER_CD": 10932,
      "TXT_FINANCIER_NAME": "CLASSIC FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10933,
      "TXT_FINANCIER_NAME": "ARIHANT AUTO INVEST"
    },
    {
      "NUM_FINANCIER_CD": 10934,
      "TXT_FINANCIER_NAME": "GUJARAT SAFAI KAMDAR VIKAS NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 10935,
      "TXT_FINANCIER_NAME": "GIRISH URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10936,
      "TXT_FINANCIER_NAME": "DCR UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 10937,
      "TXT_FINANCIER_NAME": "SHRI DNYANESHWAR URBAN CREDIT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10938,
      "TXT_FINANCIER_NAME": "OM SAI FINANCE LEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10939,
      "TXT_FINANCIER_NAME": "THE SAMTA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10940,
      "TXT_FINANCIER_NAME": "SHRI SHARANA VEERESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10941,
      "TXT_FINANCIER_NAME": "SRI CHANNA BASAVASWAMY SOUHARDHA PATTANA SHAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 10942,
      "TXT_FINANCIER_NAME": "SRI BASAVASRI NOUKARARA PATTINA SAHAKARA SANGA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 10943,
      "TXT_FINANCIER_NAME": "MORBI TALUKA SHISHAK SARAFI SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 10944,
      "TXT_FINANCIER_NAME": "GURUMACHIDEVA MULTIPURPOSE CO OP SOCIETY KUNDAPURA"
    },
    {
      "NUM_FINANCIER_CD": 10945,
      "TXT_FINANCIER_NAME": "BASAVRAJ KAPPALGUDDI SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 10946,
      "TXT_FINANCIER_NAME": "ISRO SATELITE CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 10947,
      "TXT_FINANCIER_NAME": "GOVT OF INDIA PRINTING PRESS"
    },
    {
      "NUM_FINANCIER_CD": 10948,
      "TXT_FINANCIER_NAME": "THE MANWAT URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10949,
      "TXT_FINANCIER_NAME": "KANAKA PATTINA SAHAKAR SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 10950,
      "TXT_FINANCIER_NAME": "THE AMANAT URBAN COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 10951,
      "TXT_FINANCIER_NAME": "FINE LEASING FINANCE SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 10952,
      "TXT_FINANCIER_NAME": "ASHOKA FINANCIERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 10953,
      "TXT_FINANCIER_NAME": "ASHADEVI MAHILA NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10954,
      "TXT_FINANCIER_NAME": "BHAVKESHWARI COOPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 10955,
      "TXT_FINANCIER_NAME": "SAI ARIHANT SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10956,
      "TXT_FINANCIER_NAME": "DAILATERY MARKETING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10957,
      "TXT_FINANCIER_NAME": "SMT SULUBA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 10958,
      "TXT_FINANCIER_NAME": "DHANLAXMI MAHILA NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10959,
      "TXT_FINANCIER_NAME": "GAIHAAR AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10960,
      "TXT_FINANCIER_NAME": "THAILAKSHMI FINANCIAL SERVICE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 10961,
      "TXT_FINANCIER_NAME": "SHRIRAMA TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 10962,
      "TXT_FINANCIER_NAME": "THE KASARAGOD TALUK PUBLIC SERVANTS COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10963,
      "TXT_FINANCIER_NAME": "GHITORA FINANCE INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10964,
      "TXT_FINANCIER_NAME": "PAYAL DEPARTMANTAL STORE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10965,
      "TXT_FINANCIER_NAME": "SHREE GURUCHANNA BASAVESHWARA MOTORS FINANCE AND AUTO LEASING"
    },
    {
      "NUM_FINANCIER_CD": 10966,
      "TXT_FINANCIER_NAME": "JAI MALHAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 10967,
      "TXT_FINANCIER_NAME": "THE DHAVLI URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10968,
      "TXT_FINANCIER_NAME": "N ARAVIND GAIN AUTOMOBILE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 10969,
      "TXT_FINANCIER_NAME": "AVS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 10970,
      "TXT_FINANCIER_NAME": "SHREE LAKSHMI VENKATESH CR CO SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10971,
      "TXT_FINANCIER_NAME": "INDRA KALA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10972,
      "TXT_FINANCIER_NAME": "NAGOTHANE VYAPARI SAHAKARI THESANSAHA MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 10973,
      "TXT_FINANCIER_NAME": "SUMIT AUTO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 10974,
      "TXT_FINANCIER_NAME": "ALUVA URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10975,
      "TXT_FINANCIER_NAME": "KERA AGRO MACHINERY CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 10976,
      "TXT_FINANCIER_NAME": "AU FINANCIEAR INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10977,
      "TXT_FINANCIER_NAME": "SHREE SANTH SAVATA MAHARAJ NAG SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10978,
      "TXT_FINANCIER_NAME": "RAJU GANDHI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10979,
      "TXT_FINANCIER_NAME": "MAVAL NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10980,
      "TXT_FINANCIER_NAME": "DAVIKA CENTRAL COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10981,
      "TXT_FINANCIER_NAME": "THANIYAM PANCHAYATH SERVICE CO OP BANK LTD NO R 387"
    },
    {
      "NUM_FINANCIER_CD": 10982,
      "TXT_FINANCIER_NAME": "BAJAJ SAHAKARI PATH"
    },
    {
      "NUM_FINANCIER_CD": 10983,
      "TXT_FINANCIER_NAME": "PARSHVANATH NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10984,
      "TXT_FINANCIER_NAME": "LOKVIKAS GRAMIN BIGAR SHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 10985,
      "TXT_FINANCIER_NAME": "CONCOR CREDIT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 10986,
      "TXT_FINANCIER_NAME": "JYOTI CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 10987,
      "TXT_FINANCIER_NAME": "KRISHAK BHARI COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 10988,
      "TXT_FINANCIER_NAME": "LONAVALA SAHAKRI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10989,
      "TXT_FINANCIER_NAME": "KOLLURU SHRI MOOKAMBIKA VYAVASAYA SEVA SAHAKARI BANK KOLLUR"
    },
    {
      "NUM_FINANCIER_CD": 10990,
      "TXT_FINANCIER_NAME": "ARTHSIDDHI NAGARI SAHAKARI PATHASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 10991,
      "TXT_FINANCIER_NAME": "PUNYANAGARI NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 10992,
      "TXT_FINANCIER_NAME": "GENERAL ADM AND COORDN DEPTT"
    },
    {
      "NUM_FINANCIER_CD": 10993,
      "TXT_FINANCIER_NAME": "NAGAON VIKAS SEVA SOCIETY MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 10994,
      "TXT_FINANCIER_NAME": "ALWAR SAKARI BHUMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 10995,
      "TXT_FINANCIER_NAME": "HIMALAY CREDIT SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 10996,
      "TXT_FINANCIER_NAME": "HOMMEL ETAMIC METROLOGY INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 10997,
      "TXT_FINANCIER_NAME": "PRKIYADARSHANI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 10998,
      "TXT_FINANCIER_NAME": "SHREE BHAVANI GRAMIN BIGASHETI SAHAKARI PATT SAUNSTA"
    },
    {
      "NUM_FINANCIER_CD": 10999,
      "TXT_FINANCIER_NAME": "SHAMRAO PATIL NAGARI SAH PAT SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 11000,
      "TXT_FINANCIER_NAME": "BALAGANAPATHY FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11001,
      "TXT_FINANCIER_NAME": "KISANLALJI SANCHETI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11002,
      "TXT_FINANCIER_NAME": "INFORMATION AND INVESTMENT CREDIT RATING AGENCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11003,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11004,
      "TXT_FINANCIER_NAME": "SAMRIDHI EXCURSIONS"
    },
    {
      "NUM_FINANCIER_CD": 11005,
      "TXT_FINANCIER_NAME": "GULZAR FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 11006,
      "TXT_FINANCIER_NAME": "DOABA FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 11007,
      "TXT_FINANCIER_NAME": "UNION FARMERS SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11008,
      "TXT_FINANCIER_NAME": "SHRI SAMARTH GRAMIN BIGARSHETI SAHAKARI PASANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 11009,
      "TXT_FINANCIER_NAME": "VYAVAHARA SEVA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11010,
      "TXT_FINANCIER_NAME": "THE BELLAD BAGEVADI KRUSHI SEVA SHANGA NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 11011,
      "TXT_FINANCIER_NAME": "VSSBN ANOOR BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 11012,
      "TXT_FINANCIER_NAME": "CHERAN CAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11013,
      "TXT_FINANCIER_NAME": "MAHALAXMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11014,
      "TXT_FINANCIER_NAME": "SRI LAKSHMINARAYANA SWAMY MULTIPURPOSE COOPEARTIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11015,
      "TXT_FINANCIER_NAME": "VAKHARBHAG SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11016,
      "TXT_FINANCIER_NAME": "AKILA MAHILA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11017,
      "TXT_FINANCIER_NAME": "STANDARD AUTO AGENCIES"
    },
    {
      "NUM_FINANCIER_CD": 11018,
      "TXT_FINANCIER_NAME": "SECURITY EXCHANGE BOARD OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 11019,
      "TXT_FINANCIER_NAME": "ISTRAC ISRO"
    },
    {
      "NUM_FINANCIER_CD": 11020,
      "TXT_FINANCIER_NAME": "MARS FILMS P LTD"
    },
    {
      "NUM_FINANCIER_CD": 11021,
      "TXT_FINANCIER_NAME": "TAMILNADU VETERINARY AND ANIMAL SCIENCES UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 11022,
      "TXT_FINANCIER_NAME": "SHIVASAKTHI INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 11023,
      "TXT_FINANCIER_NAME": "TAMILNADU WARE HOUSING CORPORATION INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11024,
      "TXT_FINANCIER_NAME": "EASY FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 11025,
      "TXT_FINANCIER_NAME": "SRI GOPAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11026,
      "TXT_FINANCIER_NAME": "SHIVTEJ NAGRI SAHAKARI PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11027,
      "TXT_FINANCIER_NAME": "SASRY"
    },
    {
      "NUM_FINANCIER_CD": 11028,
      "TXT_FINANCIER_NAME": "LATE GANPATRAO S PAWAR NAG SAH PATH SANSTH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11029,
      "TXT_FINANCIER_NAME": "SHRI YOGIRAJ GRA BIG SAH PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 11030,
      "TXT_FINANCIER_NAME": "THE DHANSURA GROUP SEVA SAH MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11031,
      "TXT_FINANCIER_NAME": "REGISTRAR DEENBANDHU CHHOTU RAM UNIVERSITY OF SCIENCE AND TECHNOLOGY MURTHAL"
    },
    {
      "NUM_FINANCIER_CD": 11032,
      "TXT_FINANCIER_NAME": "VIGHNAHAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11033,
      "TXT_FINANCIER_NAME": "SHIVTEJ NAGARI SAHAKARI PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11034,
      "TXT_FINANCIER_NAME": "JHUNJHUNU KENDRIYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11035,
      "TXT_FINANCIER_NAME": "DYNAMIC FINCAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11036,
      "TXT_FINANCIER_NAME": "HANUMANSAGAR URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11037,
      "TXT_FINANCIER_NAME": "KAILASWASI DEVENDRA SARDA NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11038,
      "TXT_FINANCIER_NAME": "SHRI CHITRANSH SWAYATTA SAKH SAHKARITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11039,
      "TXT_FINANCIER_NAME": "SHREE NAVANATH NAGARI SAHAKARI PATASANSTHA MARADIT"
    },
    {
      "NUM_FINANCIER_CD": 11040,
      "TXT_FINANCIER_NAME": "SURABHI CORP INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11041,
      "TXT_FINANCIER_NAME": "COCA COLA INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11042,
      "TXT_FINANCIER_NAME": "UTTAR HARYANA BIJLI VITRAN NIGAM LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11043,
      "TXT_FINANCIER_NAME": "ITEMA SPINNING MILLS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11044,
      "TXT_FINANCIER_NAME": "VIJAYALAXMI CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11045,
      "TXT_FINANCIER_NAME": "SAPTASHUNGI MATA MALILA PATASNSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11046,
      "TXT_FINANCIER_NAME": "CHHATISGARH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11047,
      "TXT_FINANCIER_NAME": "SHREE PUSHTI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11048,
      "TXT_FINANCIER_NAME": "THE SHAMALPUR SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11049,
      "TXT_FINANCIER_NAME": "LOKMANGAL MULTISTATE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11050,
      "TXT_FINANCIER_NAME": "SAPNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11051,
      "TXT_FINANCIER_NAME": "SRI SELVA GANESH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11052,
      "TXT_FINANCIER_NAME": "JHUNJHUNU SAHKARI BHOOMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11053,
      "TXT_FINANCIER_NAME": "GOVRNOR OF ANDHRA PRADESH"
    },
    {
      "NUM_FINANCIER_CD": 11054,
      "TXT_FINANCIER_NAME": "SHRENIK KUMAR L JAIN"
    },
    {
      "NUM_FINANCIER_CD": 11055,
      "TXT_FINANCIER_NAME": "SRI ANNAMMA DEVI CREDIT CO OPERATIVE SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11056,
      "TXT_FINANCIER_NAME": "TIFS CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11057,
      "TXT_FINANCIER_NAME": "ASHRAY COOP CRT STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11058,
      "TXT_FINANCIER_NAME": "MEDPAT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11059,
      "TXT_FINANCIER_NAME": "SHRISAISAHTRAARJUNNAGRI SAHPATSANSTAHMAR"
    },
    {
      "NUM_FINANCIER_CD": 11060,
      "TXT_FINANCIER_NAME": "ASHIANI FINANCIAL CONSULTANT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11061,
      "TXT_FINANCIER_NAME": "SUSHIL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11062,
      "TXT_FINANCIER_NAME": "TAPPERS SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11063,
      "TXT_FINANCIER_NAME": "SEKHON FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11064,
      "TXT_FINANCIER_NAME": "THE KAKINADA COOPERATIVE TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11065,
      "TXT_FINANCIER_NAME": "A T AND S INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11066,
      "TXT_FINANCIER_NAME": "LAXMI PARVATI GRAMIN BIGAR SHETI SAH PATH SANS LTD BAWACHI"
    },
    {
      "NUM_FINANCIER_CD": 11067,
      "TXT_FINANCIER_NAME": "SHIVCHAITANYA NAGARI SAHAKARI PATASANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11068,
      "TXT_FINANCIER_NAME": "JOINT PLANT COMMITTEE"
    },
    {
      "NUM_FINANCIER_CD": 11069,
      "TXT_FINANCIER_NAME": "THE GUNJI PRATHAMIK KRISHI PATTIN SAHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11070,
      "TXT_FINANCIER_NAME": "BHADRATHA AUTO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11071,
      "TXT_FINANCIER_NAME": "DHARMVIR SADASHIVRAO MANE PATIL GRAMIN BIGAR SHETI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11072,
      "TXT_FINANCIER_NAME": "DHARMAVIR SADASHIVRAO MANE PATIL GRAMIN BIGAR SHETI SAHAKARI PATH SANTSHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11073,
      "TXT_FINANCIER_NAME": "JAI TULJABHAWANI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11074,
      "TXT_FINANCIER_NAME": "FAIRDEAL DISTRIBUTORS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11075,
      "TXT_FINANCIER_NAME": "VAIBHAV NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11076,
      "TXT_FINANCIER_NAME": "SHRI UDAY SAHAKARI PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11077,
      "TXT_FINANCIER_NAME": "SHRI RAMLINGN NAGRI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11078,
      "TXT_FINANCIER_NAME": "MAANI BANK"
    },
    {
      "NUM_FINANCIER_CD": 11079,
      "TXT_FINANCIER_NAME": "WINCOST KURIES PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11080,
      "TXT_FINANCIER_NAME": "MARCHANT SYNDICATE GR BIG SHETI SAH CR SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11081,
      "TXT_FINANCIER_NAME": "SAINIK BHASKARRAO DURVE NANA GRAMIN BIGAR SHETI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 11082,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN SOUHARD CREDIT SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11083,
      "TXT_FINANCIER_NAME": "THE JANASEVA URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11084,
      "TXT_FINANCIER_NAME": "PARAB VAVDI SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11085,
      "TXT_FINANCIER_NAME": "YOGESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11086,
      "TXT_FINANCIER_NAME": "SINDHUDURG COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11087,
      "TXT_FINANCIER_NAME": "TATA INFOTECH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11088,
      "TXT_FINANCIER_NAME": "STARLINE CARS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11089,
      "TXT_FINANCIER_NAME": "SHRI JETAKUBA JUTH SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11090,
      "TXT_FINANCIER_NAME": "D SURESH KUMAR BOTHRA"
    },
    {
      "NUM_FINANCIER_CD": 11091,
      "TXT_FINANCIER_NAME": "MAHAVEERCHAND RATHI"
    },
    {
      "NUM_FINANCIER_CD": 11092,
      "TXT_FINANCIER_NAME": "SHREE VINAYAK MULTIPURPOSE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11093,
      "TXT_FINANCIER_NAME": "THE GUDIVADA CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11094,
      "TXT_FINANCIER_NAME": "MAHA LAXMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11095,
      "TXT_FINANCIER_NAME": "NATIONAL MINRAL DEVELOPMENT CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11096,
      "TXT_FINANCIER_NAME": "CHORODE SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11097,
      "TXT_FINANCIER_NAME": "DEPUTY DIRECT ADULT EDUCATION"
    },
    {
      "NUM_FINANCIER_CD": 11098,
      "TXT_FINANCIER_NAME": "AVM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11099,
      "TXT_FINANCIER_NAME": "NAV DOGRA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11100,
      "TXT_FINANCIER_NAME": "JANMANGAL NAGARI SAHAKARI PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 11101,
      "TXT_FINANCIER_NAME": "VISHWANATHSING BAYAS NAGARI SAHKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11102,
      "TXT_FINANCIER_NAME": "THE BHADRAN GAM NI SAHKARI SANSTHAONY KARMCHARYONY DHIRAN AND GHARAHAK SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11103,
      "TXT_FINANCIER_NAME": "SHREE VANKACCH VIBHAG KHEDUT SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11104,
      "TXT_FINANCIER_NAME": "PARASNATH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11105,
      "TXT_FINANCIER_NAME": "KARANJI SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11106,
      "TXT_FINANCIER_NAME": "PRAGJYOTISH HIRE PURCHASE CO INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11107,
      "TXT_FINANCIER_NAME": "THE PEOPLES CO OP CREDIT SOCIETY LTS"
    },
    {
      "NUM_FINANCIER_CD": 11108,
      "TXT_FINANCIER_NAME": "CHAIRMAN MMTC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11109,
      "TXT_FINANCIER_NAME": "SEWAK FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11110,
      "TXT_FINANCIER_NAME": "APANA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11111,
      "TXT_FINANCIER_NAME": "DHP LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 11112,
      "TXT_FINANCIER_NAME": "SAI GANPATI NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11113,
      "TXT_FINANCIER_NAME": "VENKATA SIVA SAI FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11114,
      "TXT_FINANCIER_NAME": "TERMINAL BALLISTICS RESEARCH LABORATORY"
    },
    {
      "NUM_FINANCIER_CD": 11115,
      "TXT_FINANCIER_NAME": "DEEPSHIKHA CO OP URBAN THRIFT AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11116,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI VASANTDADA NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11117,
      "TXT_FINANCIER_NAME": "MATOSHRI RAJMATA MAHILA NAGARI SAHARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11118,
      "TXT_FINANCIER_NAME": "SRI HANUMAGIRI SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11119,
      "TXT_FINANCIER_NAME": "THE ABHINAV COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11120,
      "TXT_FINANCIER_NAME": "THE BELGAUM URBAN CREDIT SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11121,
      "TXT_FINANCIER_NAME": "ASHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11122,
      "TXT_FINANCIER_NAME": "J DEVRAJ AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 11123,
      "TXT_FINANCIER_NAME": "GAIL GAS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11124,
      "TXT_FINANCIER_NAME": "SHREE KOTHI SEVA SAHAKARI MANDADI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11125,
      "TXT_FINANCIER_NAME": "SHARAD MULTISTATE CO OP CERDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11126,
      "TXT_FINANCIER_NAME": "INTERVET INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11127,
      "TXT_FINANCIER_NAME": "BEDADUKA FARMERS SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11128,
      "TXT_FINANCIER_NAME": "SHANTI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11129,
      "TXT_FINANCIER_NAME": "UP SAHKARI GRAM VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 11130,
      "TXT_FINANCIER_NAME": "HARYANA IRRIGATION DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 11131,
      "TXT_FINANCIER_NAME": "PAYAL PORTFOLIO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11132,
      "TXT_FINANCIER_NAME": "MGM FINANCIERS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11133,
      "TXT_FINANCIER_NAME": "SAKTHI AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11134,
      "TXT_FINANCIER_NAME": "RAVIWAR KARANJA SIDDHIVANAY NAGARI PAT SAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11135,
      "TXT_FINANCIER_NAME": "BIJAPUR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11136,
      "TXT_FINANCIER_NAME": "LASALGAON GRAMIN BIGARSHETI SAHAKARI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 11137,
      "TXT_FINANCIER_NAME": "BONGAIGAON REFINERY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11138,
      "TXT_FINANCIER_NAME": "ASSTT SOIL CONSERVATION OFFICER PANCKUKLA"
    },
    {
      "NUM_FINANCIER_CD": 11139,
      "TXT_FINANCIER_NAME": "A G MOTORS AND GENERAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11140,
      "TXT_FINANCIER_NAME": "PREMCHAND FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11141,
      "TXT_FINANCIER_NAME": "THE THALIPARAMBA PRIMERY CO OP AGRI AND RURAL DEV BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11142,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 11143,
      "TXT_FINANCIER_NAME": "BIMAL AUTO AGENCY"
    },
    {
      "NUM_FINANCIER_CD": 11144,
      "TXT_FINANCIER_NAME": "SAVITRIBAI PHULE MAGAS VARGIY MAHILA GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT MOSHI"
    },
    {
      "NUM_FINANCIER_CD": 11145,
      "TXT_FINANCIER_NAME": "SABIR GRAMIN BIGARSHETI SAHAKARI PATASANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11146,
      "TXT_FINANCIER_NAME": "SAIKRUPA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11147,
      "TXT_FINANCIER_NAME": "JUNCTION CO OP CREDIT SOCIETY WESTERN RAILWAY"
    },
    {
      "NUM_FINANCIER_CD": 11148,
      "TXT_FINANCIER_NAME": "SHREE SAI SAHASTRARJUN NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11149,
      "TXT_FINANCIER_NAME": "SHREE RANGDAS SWAMI SAHAKARI PATH PEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11150,
      "TXT_FINANCIER_NAME": "N N T LEASING AND FINANCE PVTLTD"
    },
    {
      "NUM_FINANCIER_CD": 11151,
      "TXT_FINANCIER_NAME": "SHIVAJI PATIL GRAH BIGAR SHETI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11152,
      "TXT_FINANCIER_NAME": "TULU SHREE CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11153,
      "TXT_FINANCIER_NAME": "THE VYSYA CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11154,
      "TXT_FINANCIER_NAME": "AJARA TALUKA PRATHAMIK SHIKSHAK SAH PAR SAN"
    },
    {
      "NUM_FINANCIER_CD": 11155,
      "TXT_FINANCIER_NAME": "SWARGIYA ANANDRAO BAPPU BIGARSHETI PATSANSTHA DEULGAON SAKARSHAW"
    },
    {
      "NUM_FINANCIER_CD": 11156,
      "TXT_FINANCIER_NAME": "ANIL KUMAR SATYANARAYAN AGRAWAL"
    },
    {
      "NUM_FINANCIER_CD": 11157,
      "TXT_FINANCIER_NAME": "JOTIRLING VIKAS SEAV SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11158,
      "TXT_FINANCIER_NAME": "CHATRAPATI NAGRI SAHKARI PAT"
    },
    {
      "NUM_FINANCIER_CD": 11159,
      "TXT_FINANCIER_NAME": "KHALANGJIAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11160,
      "TXT_FINANCIER_NAME": "PERUVAYAL CO OPERATIVE SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11161,
      "TXT_FINANCIER_NAME": "DATTAKRUPA NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11162,
      "TXT_FINANCIER_NAME": "SHRI T S GRAMIN BIGAR SHETI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11163,
      "TXT_FINANCIER_NAME": "BAFNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11164,
      "TXT_FINANCIER_NAME": "SRI SELVAVINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11165,
      "TXT_FINANCIER_NAME": "KHULESHWAR GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11166,
      "TXT_FINANCIER_NAME": "UNSPS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11167,
      "TXT_FINANCIER_NAME": "VISION CO OP U T C SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11168,
      "TXT_FINANCIER_NAME": "VYVASAYA SHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 11169,
      "TXT_FINANCIER_NAME": "ELECTRICITY DEPT EMPLOYEES CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11170,
      "TXT_FINANCIER_NAME": "SHREE PRATHMESH SAHKARI PATHSANTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11171,
      "TXT_FINANCIER_NAME": "OMKAR GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11172,
      "TXT_FINANCIER_NAME": "FLUID CONTROL RESEARCH INSTITUTE"
    },
    {
      "NUM_FINANCIER_CD": 11173,
      "TXT_FINANCIER_NAME": "THE CHOPADA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11174,
      "TXT_FINANCIER_NAME": "THE VAKAL KANAM RAJ CREDIT ANE GRAHAK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11175,
      "TXT_FINANCIER_NAME": "VINOD ENTEPRISE"
    },
    {
      "NUM_FINANCIER_CD": 11176,
      "TXT_FINANCIER_NAME": "CHANDAN FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11177,
      "TXT_FINANCIER_NAME": "KHARIWAL FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11178,
      "TXT_FINANCIER_NAME": "MPEB KARMACHARI P S S S MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11179,
      "TXT_FINANCIER_NAME": "THE NATIONAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11180,
      "TXT_FINANCIER_NAME": "VITTAL SOUHARDHA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11181,
      "TXT_FINANCIER_NAME": "ALLIED FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11182,
      "TXT_FINANCIER_NAME": "AMAN FINANCIER CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11183,
      "TXT_FINANCIER_NAME": "GOUTAMCHAND MUTHA"
    },
    {
      "NUM_FINANCIER_CD": 11184,
      "TXT_FINANCIER_NAME": "DASHMESH FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11185,
      "TXT_FINANCIER_NAME": "DASHMESH FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 11186,
      "TXT_FINANCIER_NAME": "MAGMA SHIRACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11187,
      "TXT_FINANCIER_NAME": "MAGMA SHARCHI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 11188,
      "TXT_FINANCIER_NAME": "SAI SHAKTI NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11189,
      "TXT_FINANCIER_NAME": "UDYOGINI MAHILA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11190,
      "TXT_FINANCIER_NAME": "MARUTI CORP FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11191,
      "TXT_FINANCIER_NAME": "DARAYASAGAR SAHAKARI PATPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11192,
      "TXT_FINANCIER_NAME": "SHRI MAHAVEER GRAMEEN SAHAKARI BIGAR SHETI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11193,
      "TXT_FINANCIER_NAME": "SHIRODA URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11194,
      "TXT_FINANCIER_NAME": "SHRI DHANALAXMI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11195,
      "TXT_FINANCIER_NAME": "AJINKYA MADHYAMIK SHIKSHAK SEVAK SAH PATS MAR"
    },
    {
      "NUM_FINANCIER_CD": 11196,
      "TXT_FINANCIER_NAME": "CHINNA PATTINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11197,
      "TXT_FINANCIER_NAME": "ANILKUMAR DUNGARWAL AUTOMOTIVE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11198,
      "TXT_FINANCIER_NAME": "THE SIKANDARPUR CO OP AGRI MP SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11199,
      "TXT_FINANCIER_NAME": "HIGHNESS KURIES AND LOANS PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11200,
      "TXT_FINANCIER_NAME": "RUPMATA URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11201,
      "TXT_FINANCIER_NAME": "SHRI JAMNABAI SARVAJANIK KANYA VIDHYALAYA STAFF CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11202,
      "TXT_FINANCIER_NAME": "VIP INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11203,
      "TXT_FINANCIER_NAME": "VEERSHAIVA URBAN CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 11204,
      "TXT_FINANCIER_NAME": "BALAJI AUTOMOTIVE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11205,
      "TXT_FINANCIER_NAME": "WAGESHWAR MAHARAJ NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11206,
      "TXT_FINANCIER_NAME": "VIJAYLAXMI NAGARI SAHAKARI PATH SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 11207,
      "TXT_FINANCIER_NAME": "TIRUPATI NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11208,
      "TXT_FINANCIER_NAME": "HAMDARD LABORATORIES"
    },
    {
      "NUM_FINANCIER_CD": 11209,
      "TXT_FINANCIER_NAME": "GORAA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11210,
      "TXT_FINANCIER_NAME": "GOUTAMCHAND PRVEENCHAND"
    },
    {
      "NUM_FINANCIER_CD": 11211,
      "TXT_FINANCIER_NAME": "T TIRUPATI DEVASTHANAMS"
    },
    {
      "NUM_FINANCIER_CD": 11212,
      "TXT_FINANCIER_NAME": "DAKSHIN GUJARAT VIG CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11213,
      "TXT_FINANCIER_NAME": "SAFAL AND CO"
    },
    {
      "NUM_FINANCIER_CD": 11214,
      "TXT_FINANCIER_NAME": "SIDDAPURA SAHAKARI VYAVASAYIKA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11215,
      "TXT_FINANCIER_NAME": "RIDHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11216,
      "TXT_FINANCIER_NAME": "THE RAGHURAJ CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11217,
      "TXT_FINANCIER_NAME": "SHREE PRAVIRA NAG SAH BANDHKAM SAHITYA VYAV PAT SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 11218,
      "TXT_FINANCIER_NAME": "BIJAYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 11219,
      "TXT_FINANCIER_NAME": "FINE MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 11220,
      "TXT_FINANCIER_NAME": "MANJUNATH ESHWAR CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11221,
      "TXT_FINANCIER_NAME": "KERALA MINERALS AND METALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11222,
      "TXT_FINANCIER_NAME": "H K FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11223,
      "TXT_FINANCIER_NAME": "SHIVSHAKTI GRAMIN BIGAR SHETI SAHAKARI PATSANSTA"
    },
    {
      "NUM_FINANCIER_CD": 11224,
      "TXT_FINANCIER_NAME": "SHANKARNARAYANA CO OP AGRICUTURAL SHANKARNARYANA"
    },
    {
      "NUM_FINANCIER_CD": 11225,
      "TXT_FINANCIER_NAME": "KAYASTHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 11226,
      "TXT_FINANCIER_NAME": "KAMDENU NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11227,
      "TXT_FINANCIER_NAME": "BANK OF TUMKUR IN INDIAH"
    },
    {
      "NUM_FINANCIER_CD": 11228,
      "TXT_FINANCIER_NAME": "SHANKARNARAYANA SAHAKARI VYAVASAHIKA BK LTD SHANKARNAYANA SHANKARNAYANA"
    },
    {
      "NUM_FINANCIER_CD": 11229,
      "TXT_FINANCIER_NAME": "JANADHAR GRAMIN BIGARSHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11230,
      "TXT_FINANCIER_NAME": "AGRESON URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11231,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF INDIA MINISTRY OF TEXTILES"
    },
    {
      "NUM_FINANCIER_CD": 11232,
      "TXT_FINANCIER_NAME": "MOODABIDRI CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11233,
      "TXT_FINANCIER_NAME": "LAXMI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11234,
      "TXT_FINANCIER_NAME": "AHILYADEVI GRAMIN B S PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11235,
      "TXT_FINANCIER_NAME": "METAL STEEL TAG CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11236,
      "TXT_FINANCIER_NAME": "SHRI NANDI CREDIT SOUHARD SAHAKARI NYMT"
    },
    {
      "NUM_FINANCIER_CD": 11237,
      "TXT_FINANCIER_NAME": "HARSIDHI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11238,
      "TXT_FINANCIER_NAME": "BHARTIY SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11239,
      "TXT_FINANCIER_NAME": "GOPAL HARI PANCHKROSHI GRAMIN BIGAR SHETI SAHAKRI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11240,
      "TXT_FINANCIER_NAME": "THE KHODIYAR CO OP CREDIT BANK LTD MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 11241,
      "TXT_FINANCIER_NAME": "THE NAVKA SARAFI SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 11242,
      "TXT_FINANCIER_NAME": "I M A VADODARA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11243,
      "TXT_FINANCIER_NAME": "PARAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11244,
      "TXT_FINANCIER_NAME": "PRASHAK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11245,
      "TXT_FINANCIER_NAME": "RAISINGH NAGAR SAHKARI BHOOMI VIKAS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11246,
      "TXT_FINANCIER_NAME": "THE PATDI SARKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11247,
      "TXT_FINANCIER_NAME": "KARUR VSYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 11248,
      "TXT_FINANCIER_NAME": "V K INVESTMENTS AND SERVICES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 11249,
      "TXT_FINANCIER_NAME": "CENTRAL GOVERNMENT CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11250,
      "TXT_FINANCIER_NAME": "THE MUTHOOT GROUP"
    },
    {
      "NUM_FINANCIER_CD": 11251,
      "TXT_FINANCIER_NAME": "MUTHU CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11252,
      "TXT_FINANCIER_NAME": "MUDAGAL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11253,
      "TXT_FINANCIER_NAME": "SRI RAJA RAJESWARI AUTO AND GENERAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11254,
      "TXT_FINANCIER_NAME": "SARSENAPATI UMABAI DABHADE NAGARI SAHAKARI PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 11255,
      "TXT_FINANCIER_NAME": "CLARIS LIFESCINECES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11256,
      "TXT_FINANCIER_NAME": "ATHISAYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11257,
      "TXT_FINANCIER_NAME": "SATYAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11258,
      "TXT_FINANCIER_NAME": "THE VETTATHUR SERVICE CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11259,
      "TXT_FINANCIER_NAME": "KRUSHI SEVA SAHAKARA SANGA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11260,
      "TXT_FINANCIER_NAME": "MANGLAM CONSULTANCY"
    },
    {
      "NUM_FINANCIER_CD": 11261,
      "TXT_FINANCIER_NAME": "SHRIDHAR NAIK NAVJIVAN NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11262,
      "TXT_FINANCIER_NAME": "CHIVA SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11263,
      "TXT_FINANCIER_NAME": "CHAIRMAN CHENNAI PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 11264,
      "TXT_FINANCIER_NAME": "SRI RAMESHWARA MULTIPURPOSE SOUHARDA CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11265,
      "TXT_FINANCIER_NAME": "CTR MANUFACTURING INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11266,
      "TXT_FINANCIER_NAME": "MS SUNITA MOTOR HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 11267,
      "TXT_FINANCIER_NAME": "DEEPALAKSHMI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11268,
      "TXT_FINANCIER_NAME": "KING LEASING AND REAL ESTATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11269,
      "TXT_FINANCIER_NAME": "VIJAYALAXMI NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11270,
      "TXT_FINANCIER_NAME": "MITSUBHISHI ELECTRICAL AUTOMOTIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11271,
      "TXT_FINANCIER_NAME": "SHREE RUDRESHWARA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11272,
      "TXT_FINANCIER_NAME": "CHIKKERUR PATTINA SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 11273,
      "TXT_FINANCIER_NAME": "DEEP INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11274,
      "TXT_FINANCIER_NAME": "SREE JAYASAKTHI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11275,
      "TXT_FINANCIER_NAME": "MANDYA CITY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11276,
      "TXT_FINANCIER_NAME": "THE ISMAILIA CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11277,
      "TXT_FINANCIER_NAME": "PATIL MULTIPURPOSE SOUHARD SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11278,
      "TXT_FINANCIER_NAME": "AWAMI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11279,
      "TXT_FINANCIER_NAME": "VINTA GRAMIN BIGARSHETI SAH PAT SAT MARY"
    },
    {
      "NUM_FINANCIER_CD": 11280,
      "TXT_FINANCIER_NAME": "BHARNEMAMA GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11281,
      "TXT_FINANCIER_NAME": "THE MANAKADU SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11282,
      "TXT_FINANCIER_NAME": "KUTTANELLUR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11283,
      "TXT_FINANCIER_NAME": "HARYANA CIVIL SECRETRIAT"
    },
    {
      "NUM_FINANCIER_CD": 11284,
      "TXT_FINANCIER_NAME": "DIGAMBAR RAOJI BAGAL SAHA PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11285,
      "TXT_FINANCIER_NAME": "SRI GURU VAPPATHINA SWAMY PATHINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11286,
      "TXT_FINANCIER_NAME": "PRESTIGE ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 11287,
      "TXT_FINANCIER_NAME": "AUTO HOUSE HOLD FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 11288,
      "TXT_FINANCIER_NAME": "FETISH IMPEX PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11289,
      "TXT_FINANCIER_NAME": "DILIP ANNA SHINDE NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11290,
      "TXT_FINANCIER_NAME": "ADITYA URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11291,
      "TXT_FINANCIER_NAME": "JIJAMATA NAGARI SAH PATSANSTH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11292,
      "TXT_FINANCIER_NAME": "SHRI VIJAY COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11293,
      "TXT_FINANCIER_NAME": "HAMDUM FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11294,
      "TXT_FINANCIER_NAME": "CHHATRAPATI NAGRIK SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11295,
      "TXT_FINANCIER_NAME": "VISNAGAR LEASE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11296,
      "TXT_FINANCIER_NAME": "LAKSHMI PATTINA SOUHARDHA SAHAKARI NIYAMITHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 11297,
      "TXT_FINANCIER_NAME": "TAMILNADU TOURISIM DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11298,
      "TXT_FINANCIER_NAME": "TUBE INVESTMENTS OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11299,
      "TXT_FINANCIER_NAME": "JAIGANESH CREDIT SOUHADA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11300,
      "TXT_FINANCIER_NAME": "ABHIYANTA SAH PAT SAN MAR SANGALI"
    },
    {
      "NUM_FINANCIER_CD": 11301,
      "TXT_FINANCIER_NAME": "ESTEEM CREDIT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11302,
      "TXT_FINANCIER_NAME": "MAHALASA URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11303,
      "TXT_FINANCIER_NAME": "ZINDESHA MADAR MINORITY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11304,
      "TXT_FINANCIER_NAME": "SANT DNYANESHWAR PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11305,
      "TXT_FINANCIER_NAME": "THE RUSHAT CREDIT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11306,
      "TXT_FINANCIER_NAME": "Y AND H PATEL FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11307,
      "TXT_FINANCIER_NAME": "SHREE HARI OM URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11308,
      "TXT_FINANCIER_NAME": "SAGAR FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11309,
      "TXT_FINANCIER_NAME": "SHRI BIRDEV NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11310,
      "TXT_FINANCIER_NAME": "THE KARANNUR SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11311,
      "TXT_FINANCIER_NAME": "SHREE RAMDEO ENTER PRISES"
    },
    {
      "NUM_FINANCIER_CD": 11312,
      "TXT_FINANCIER_NAME": "SAIL RMD BIM"
    },
    {
      "NUM_FINANCIER_CD": 11313,
      "TXT_FINANCIER_NAME": "THE MORAZHA KALLIASSERY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11314,
      "TXT_FINANCIER_NAME": "SHRI VISHWAKARMA GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA JATH"
    },
    {
      "NUM_FINANCIER_CD": 11315,
      "TXT_FINANCIER_NAME": "VIKRAM UMESH NAHAR AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11316,
      "TXT_FINANCIER_NAME": "SRI RAM UNION FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11317,
      "TXT_FINANCIER_NAME": "DGVCL"
    },
    {
      "NUM_FINANCIER_CD": 11318,
      "TXT_FINANCIER_NAME": "GAWALINAGAR NAGARI SAHAKARI PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 11319,
      "TXT_FINANCIER_NAME": "BASVESHWAR NAGARI SAHAKARI PATH SANSTHA MIRAJ"
    },
    {
      "NUM_FINANCIER_CD": 11320,
      "TXT_FINANCIER_NAME": "THE DALL CO OP AGRI MULTIPURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11321,
      "TXT_FINANCIER_NAME": "SHRI TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11322,
      "TXT_FINANCIER_NAME": "GIRDHAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11323,
      "TXT_FINANCIER_NAME": "M P VIDYUT MANDAL SAKH SAHKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11324,
      "TXT_FINANCIER_NAME": "ANICKADU REGI FARMERS SER COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11325,
      "TXT_FINANCIER_NAME": "PONNAMPET TOWN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11326,
      "TXT_FINANCIER_NAME": "RAJPURA SARASWATHA CREDIT CO OP SOCIETY LTD BAILOOR"
    },
    {
      "NUM_FINANCIER_CD": 11327,
      "TXT_FINANCIER_NAME": "CALICUT CITY CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11328,
      "TXT_FINANCIER_NAME": "PRERANA SAHKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11329,
      "TXT_FINANCIER_NAME": "C G FINANCE SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 11330,
      "TXT_FINANCIER_NAME": "KALAPPANNA AWADE ICHALKARANGI JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11331,
      "TXT_FINANCIER_NAME": "TIRUPATHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11332,
      "TXT_FINANCIER_NAME": "ALLEPPEY CARD BANK"
    },
    {
      "NUM_FINANCIER_CD": 11333,
      "TXT_FINANCIER_NAME": "THYSEN KRUPP INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11334,
      "TXT_FINANCIER_NAME": "DR C FERNANDES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11335,
      "TXT_FINANCIER_NAME": "KESHAV SMRUTI NAGARI SAHAKARI PATH MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11336,
      "TXT_FINANCIER_NAME": "THE CHORIVAD BACHAT AND DHIRAN SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11337,
      "TXT_FINANCIER_NAME": "LOKMANYA GRAMIN BIGARSHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11338,
      "TXT_FINANCIER_NAME": "SHANKAR NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11339,
      "TXT_FINANCIER_NAME": "ADRASH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11340,
      "TXT_FINANCIER_NAME": "MALU FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11341,
      "TXT_FINANCIER_NAME": "THE OTTAPPALAM PRIMARY CO OP AGRI AND RURAL DEV BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11342,
      "TXT_FINANCIER_NAME": "PREMIER AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11343,
      "TXT_FINANCIER_NAME": "THE MUNDGOD URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11344,
      "TXT_FINANCIER_NAME": "ASHVINIKUMAR URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11345,
      "TXT_FINANCIER_NAME": "SHRI DHARMASTHALA CO OP AGRICUTURAL BANK LTD DHARMASTALA"
    },
    {
      "NUM_FINANCIER_CD": 11346,
      "TXT_FINANCIER_NAME": "SHREE WARANA MAHILA SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11347,
      "TXT_FINANCIER_NAME": "BALIA ETAWAH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11348,
      "TXT_FINANCIER_NAME": "SANKHESHWAR NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11349,
      "TXT_FINANCIER_NAME": "SUVARNAYOG NAGRI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11350,
      "TXT_FINANCIER_NAME": "MRCMPU LTD"
    },
    {
      "NUM_FINANCIER_CD": 11351,
      "TXT_FINANCIER_NAME": "SNEHLATA NAGARI SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11352,
      "TXT_FINANCIER_NAME": "SENKATHIR AUTO FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11353,
      "TXT_FINANCIER_NAME": "THE PEOPLES COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11354,
      "TXT_FINANCIER_NAME": "BHOSALE LEASING AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11355,
      "TXT_FINANCIER_NAME": "SHREE GANESH GRAMIN BIG SHE SAH PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11356,
      "TXT_FINANCIER_NAME": "CHOBISI FINLEASE SREVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11357,
      "TXT_FINANCIER_NAME": "UDUPI CO OP HOUSE BUILDING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11358,
      "TXT_FINANCIER_NAME": "JAMUNALAL PAREKH FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11359,
      "TXT_FINANCIER_NAME": "SAI SAHSTRAARJUN NAGRI PAT"
    },
    {
      "NUM_FINANCIER_CD": 11360,
      "TXT_FINANCIER_NAME": "DIVINE GRACE GREEN FARMING AND MKT MULTI STATE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11361,
      "TXT_FINANCIER_NAME": "SWABHIMANI SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11362,
      "TXT_FINANCIER_NAME": "CBT EMPLOYEES PROVIDENT FUND"
    },
    {
      "NUM_FINANCIER_CD": 11363,
      "TXT_FINANCIER_NAME": "SATARA Z P PARISHAD SEVAK SAH PATH PEDHI"
    },
    {
      "NUM_FINANCIER_CD": 11364,
      "TXT_FINANCIER_NAME": "PROMISE LEASING AND FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 11365,
      "TXT_FINANCIER_NAME": "SADICHHA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11366,
      "TXT_FINANCIER_NAME": "HARYANA MOTORS REGD"
    },
    {
      "NUM_FINANCIER_CD": 11367,
      "TXT_FINANCIER_NAME": "AARTHIK VIKASH SOMITY"
    },
    {
      "NUM_FINANCIER_CD": 11368,
      "TXT_FINANCIER_NAME": "SHRI AMAR GRAMIN BIGAR SHETI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11369,
      "TXT_FINANCIER_NAME": "THE PERDALA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11370,
      "TXT_FINANCIER_NAME": "LUCKKNOW UNIVERSITY PRIMARY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11371,
      "TXT_FINANCIER_NAME": "SHREE ADITYA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11372,
      "TXT_FINANCIER_NAME": "SUMANGAL PORTFOLIO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11373,
      "TXT_FINANCIER_NAME": "LAKSHMI ABHYUDAYA CHITFUND PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11374,
      "TXT_FINANCIER_NAME": "SHREE JANTA SARAFI SAHKAR MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 11375,
      "TXT_FINANCIER_NAME": "SHRI SHIVACHIDAMBARESHWAR URBAN COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11376,
      "TXT_FINANCIER_NAME": "KANHUR PATHAR PAT SAN MAR KANHUR PATHAR"
    },
    {
      "NUM_FINANCIER_CD": 11377,
      "TXT_FINANCIER_NAME": "GODAVARI VIVDH KARYAKARI SAH VIKAS SEVA SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11378,
      "TXT_FINANCIER_NAME": "HINGRAJ CREDIT CO OPE AND GRAHAK DHIRAN MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 11379,
      "TXT_FINANCIER_NAME": "PRIFIN INVESTMENTS P LTD"
    },
    {
      "NUM_FINANCIER_CD": 11380,
      "TXT_FINANCIER_NAME": "SHRI SAI SAMARTH NAGARI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11381,
      "TXT_FINANCIER_NAME": "VIRAPULAKESHI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11382,
      "TXT_FINANCIER_NAME": "ROYAL MOTORS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11383,
      "TXT_FINANCIER_NAME": "SINDHUDURAG NAGARIK SAHAKARI PATHPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11384,
      "TXT_FINANCIER_NAME": "SINGHAL ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 11385,
      "TXT_FINANCIER_NAME": "WAGHJAI MATA MAHILA GRAMIN BIGER SHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11386,
      "TXT_FINANCIER_NAME": "SANT GADGEBABA GRAMIN BIGARSHETI SAHKARI PAT SANSTHA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11387,
      "TXT_FINANCIER_NAME": "THE RAMOL NAGRIK COOPERTIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11388,
      "TXT_FINANCIER_NAME": "APPASAHEB CHOPADE GRAMIN BIG SHETI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11389,
      "TXT_FINANCIER_NAME": "TRIUMPH PHARMACEUTICALS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11390,
      "TXT_FINANCIER_NAME": "MALA BLOCK TOWN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11391,
      "TXT_FINANCIER_NAME": "R K INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11392,
      "TXT_FINANCIER_NAME": "G BHANECHAND NAHAR AND SONS AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11393,
      "TXT_FINANCIER_NAME": "VILASRAO PATIL GR BIG SHETI SAH PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11394,
      "TXT_FINANCIER_NAME": "P K K CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 11395,
      "TXT_FINANCIER_NAME": "SHREE SALABATPURA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11396,
      "TXT_FINANCIER_NAME": "SK FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11397,
      "TXT_FINANCIER_NAME": "AVANTI FEEDS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11398,
      "TXT_FINANCIER_NAME": "BELLAD BAGEVADI KRISHI SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 11399,
      "TXT_FINANCIER_NAME": "SHRI LAXMI SARSWATI MAHILA GRAMIN BIGAR SHETI SAHKARI PATHSANTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11400,
      "TXT_FINANCIER_NAME": "THE BGP CENTRAL COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11401,
      "TXT_FINANCIER_NAME": "PARNERKAR NAGARI SAHAKARI PATSANSTH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11402,
      "TXT_FINANCIER_NAME": "DESAIGANJ NAGRI"
    },
    {
      "NUM_FINANCIER_CD": 11403,
      "TXT_FINANCIER_NAME": "THE KARON AGRI CO OP SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11404,
      "TXT_FINANCIER_NAME": "SRI AMARJOTHI INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 11405,
      "TXT_FINANCIER_NAME": "AJMANI CONSULTANCY SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 11406,
      "TXT_FINANCIER_NAME": "MAHALAXMI MAHILA CO OP CREDIT SOCIETY MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11407,
      "TXT_FINANCIER_NAME": "SHRI MARUDHAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11408,
      "TXT_FINANCIER_NAME": "S K CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11409,
      "TXT_FINANCIER_NAME": "VED AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 11410,
      "TXT_FINANCIER_NAME": "T NAVODIT CO OP URPAN TH AND CRD S L"
    },
    {
      "NUM_FINANCIER_CD": 11411,
      "TXT_FINANCIER_NAME": "VISHVESHVARAIAH CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11412,
      "TXT_FINANCIER_NAME": "SURBHI NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11413,
      "TXT_FINANCIER_NAME": "MATRUBHUMI URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11414,
      "TXT_FINANCIER_NAME": "SHREE AMARDEVI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11415,
      "TXT_FINANCIER_NAME": "DNYESHWAR MAULI G B SHETI SHA PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11416,
      "TXT_FINANCIER_NAME": "HARYANA BACKWARD CLASSES ECONOMICALLY WEAKER SECTION KALYAN NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 11417,
      "TXT_FINANCIER_NAME": "GENERAL INSURANCE CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11418,
      "TXT_FINANCIER_NAME": "SRI SELVALASHMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11419,
      "TXT_FINANCIER_NAME": "NATAJI NAIK GRAMIN BIGER SHETI SAHAKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11420,
      "TXT_FINANCIER_NAME": "GURUVAYOOR DEVASWOM BOARD"
    },
    {
      "NUM_FINANCIER_CD": 11421,
      "TXT_FINANCIER_NAME": "HARJEET ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 11422,
      "TXT_FINANCIER_NAME": "SHREE VISHAL FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 11423,
      "TXT_FINANCIER_NAME": "JATAN DURANA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11424,
      "TXT_FINANCIER_NAME": "THE IRINAVE SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11425,
      "TXT_FINANCIER_NAME": "PAGARIA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11426,
      "TXT_FINANCIER_NAME": "ADITI URBAN CO OPRETIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11427,
      "TXT_FINANCIER_NAME": "SHAHAJIRAO PATIL VIVIDH KARYAKARI SEVA SAHKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11428,
      "TXT_FINANCIER_NAME": "UMRALI SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11429,
      "TXT_FINANCIER_NAME": "THE CATHIOLIC SYRIAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11430,
      "TXT_FINANCIER_NAME": "BOTHRA HIRE PURCHASE CO"
    },
    {
      "NUM_FINANCIER_CD": 11431,
      "TXT_FINANCIER_NAME": "WAIGANGA KRISHNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11432,
      "TXT_FINANCIER_NAME": "THE CARD BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11433,
      "TXT_FINANCIER_NAME": "SRI ASWINI FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11434,
      "TXT_FINANCIER_NAME": "KADHIRAVAN AND CO"
    },
    {
      "NUM_FINANCIER_CD": 11435,
      "TXT_FINANCIER_NAME": "SHRI NATRAJ CERAMIC AND CHEMICAL INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11436,
      "TXT_FINANCIER_NAME": "THE NILAMBR TALUK CO OPERATIVE EMPLOYEES CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11437,
      "TXT_FINANCIER_NAME": "SUYOG NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11438,
      "TXT_FINANCIER_NAME": "SAMRUDHAJEEVAN NAGRI SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11439,
      "TXT_FINANCIER_NAME": "BRYDAL KURIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11440,
      "TXT_FINANCIER_NAME": "MITRA SOUHARDA CREDIT CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11441,
      "TXT_FINANCIER_NAME": "SURESH CHAND AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 11442,
      "TXT_FINANCIER_NAME": "QUANTUM SOLUTIONS INDIA"
    },
    {
      "NUM_FINANCIER_CD": 11443,
      "TXT_FINANCIER_NAME": "G M SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11444,
      "TXT_FINANCIER_NAME": "D CHETAN PRAKASH"
    },
    {
      "NUM_FINANCIER_CD": 11445,
      "TXT_FINANCIER_NAME": "ANNAI INDIA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 11446,
      "TXT_FINANCIER_NAME": "KETAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11447,
      "TXT_FINANCIER_NAME": "THE BICHOLIM MERCHANTS URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11448,
      "TXT_FINANCIER_NAME": "ANJANEYA PATHINA SAHAKARI SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11449,
      "TXT_FINANCIER_NAME": "THE AMARGADH KAMPA SEVA SAHAKARI MANDALI AND SK BANK"
    },
    {
      "NUM_FINANCIER_CD": 11450,
      "TXT_FINANCIER_NAME": "HEMAMBIKA HIRE PURCHASE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 11451,
      "TXT_FINANCIER_NAME": "SHIRAM TRANSPORT AND FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11452,
      "TXT_FINANCIER_NAME": "SANDHU FINANCE AND LEASING CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11453,
      "TXT_FINANCIER_NAME": "SRI VINAYAKA CREDIT CO OPARATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11454,
      "TXT_FINANCIER_NAME": "GALAXY FINANCIAL SER PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11455,
      "TXT_FINANCIER_NAME": "POONAM SECURITIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11456,
      "TXT_FINANCIER_NAME": "VILASRAO KORE GRAMIN SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11457,
      "TXT_FINANCIER_NAME": "YUVAK SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11458,
      "TXT_FINANCIER_NAME": "AHINSA URBAN CREDIT COOP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11459,
      "TXT_FINANCIER_NAME": "ADMINITRATIVE OFFICERS PUBLIC HEALTH PREVENTIVE MEDICINE"
    },
    {
      "NUM_FINANCIER_CD": 11460,
      "TXT_FINANCIER_NAME": "YESHWANT GRAMIN BIGARSHETI SAH PAT"
    },
    {
      "NUM_FINANCIER_CD": 11461,
      "TXT_FINANCIER_NAME": "DR NTR UNIVERSITY OF HEALTH SCIENCES"
    },
    {
      "NUM_FINANCIER_CD": 11462,
      "TXT_FINANCIER_NAME": "CHAIRMAN CUM MANAGING DIRECTOR TANGEDCO"
    },
    {
      "NUM_FINANCIER_CD": 11463,
      "TXT_FINANCIER_NAME": "SHREE VARDHMAN NAGRIK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11464,
      "TXT_FINANCIER_NAME": "ARYANANDI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11465,
      "TXT_FINANCIER_NAME": "OM DATTA CHAITANYA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11466,
      "TXT_FINANCIER_NAME": "TEHATTA SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11467,
      "TXT_FINANCIER_NAME": "CHAWDESHWARI GRAMIN BIGARSHETHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11468,
      "TXT_FINANCIER_NAME": "MORE MAWLI COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11469,
      "TXT_FINANCIER_NAME": "KARYALAY COLLECTORATE JILA ANTYAVYAVSAY SAHKARI VIKAS SAMITI"
    },
    {
      "NUM_FINANCIER_CD": 11470,
      "TXT_FINANCIER_NAME": "PRERANA NAGRI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11471,
      "TXT_FINANCIER_NAME": "KADAMBA CREDIT SOUHARDA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11472,
      "TXT_FINANCIER_NAME": "THE MALAPPATTAM SER CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11473,
      "TXT_FINANCIER_NAME": "DADA GURU ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 11474,
      "TXT_FINANCIER_NAME": "SARVODAYA GRAMIN BIGER SHETI PATH SANSTHA JATH"
    },
    {
      "NUM_FINANCIER_CD": 11475,
      "TXT_FINANCIER_NAME": "SREEVITTAL SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11476,
      "TXT_FINANCIER_NAME": "SHRIMANT BAPUSO PATIL GRAMIN BIGAR SHETI SAHAKARI PATH SANSHTA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11477,
      "TXT_FINANCIER_NAME": "MAHAJAN AUTO SALES"
    },
    {
      "NUM_FINANCIER_CD": 11478,
      "TXT_FINANCIER_NAME": "M PADMACHAND LALWANI FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11479,
      "TXT_FINANCIER_NAME": "AIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 11480,
      "TXT_FINANCIER_NAME": "WELKIN COMMERCIALS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11481,
      "TXT_FINANCIER_NAME": "SHUBH FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 11482,
      "TXT_FINANCIER_NAME": "SHREEANK FINHOLD P LTD"
    },
    {
      "NUM_FINANCIER_CD": 11483,
      "TXT_FINANCIER_NAME": "NIRMAL NAGAR GRAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11484,
      "TXT_FINANCIER_NAME": "SURABHI CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11485,
      "TXT_FINANCIER_NAME": "GGRACE LEASING CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11486,
      "TXT_FINANCIER_NAME": "MAHATMA JOTIRAO PHULE GRAMIN BIGAR SHETI SAH PATH SANSHTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11487,
      "TXT_FINANCIER_NAME": "SHREE SAINATH GRAMIN BIGGAR SHETI PANTH SAKAHARI SANSATHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11488,
      "TXT_FINANCIER_NAME": "PRATHMESH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11489,
      "TXT_FINANCIER_NAME": "SISGANJ CHITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11490,
      "TXT_FINANCIER_NAME": "IDAKINI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11491,
      "TXT_FINANCIER_NAME": "ABHIJEETDADA KADAM NADRI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11492,
      "TXT_FINANCIER_NAME": "GAYATRI BIGRA SETI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11493,
      "TXT_FINANCIER_NAME": "SAHYOG SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11494,
      "TXT_FINANCIER_NAME": "BIHARI JI MOTOR FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11495,
      "TXT_FINANCIER_NAME": "HIRANYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11496,
      "TXT_FINANCIER_NAME": "SRI VINAYAGAA BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 11497,
      "TXT_FINANCIER_NAME": "SHRI REVANSIDDESHWAR PATTAN SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 11498,
      "TXT_FINANCIER_NAME": "SHRI GANESH PRASAD SAHAKARI PATSANTHA LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 11499,
      "TXT_FINANCIER_NAME": "SHREE BALAJI SAKH SAHAKARI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11500,
      "TXT_FINANCIER_NAME": "DHUBALIA SAMABAY KRISHI UNNIYAN SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11501,
      "TXT_FINANCIER_NAME": "PATHIYOOR FARMERS SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11502,
      "TXT_FINANCIER_NAME": "PATHIYOOR FARMERS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11503,
      "TXT_FINANCIER_NAME": "IGUTHAPPA SOUHARDHA CREDIT CO OPT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11504,
      "TXT_FINANCIER_NAME": "HIRE PURCHASE WITH FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11505,
      "TXT_FINANCIER_NAME": "LIC NOMURA MS AMC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11506,
      "TXT_FINANCIER_NAME": "UNIOUN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11507,
      "TXT_FINANCIER_NAME": "THE RUDRAMALA SEVA SAH MAL AND THE S K DIST CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11508,
      "TXT_FINANCIER_NAME": "VIGNESWARA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11509,
      "TXT_FINANCIER_NAME": "THE MOOKKANNUR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11510,
      "TXT_FINANCIER_NAME": "SHRIRAOSAHEBTHORATAMRUTVAHANI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11511,
      "TXT_FINANCIER_NAME": "MAHILA GRAMIN BIGAR SHETI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11512,
      "TXT_FINANCIER_NAME": "KEO STAR SECURITIES AND CREDITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11513,
      "TXT_FINANCIER_NAME": "SRI AMBRINI VIDYESHA SOUHARDA CREDIT C OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11514,
      "TXT_FINANCIER_NAME": "HARSUL ADIVASI NEVEDYA KARYAKARI SAHAKARI SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11515,
      "TXT_FINANCIER_NAME": "MAHAVEERCHAND KANKARIA"
    },
    {
      "NUM_FINANCIER_CD": 11516,
      "TXT_FINANCIER_NAME": "KUBERALXMI TOWERS"
    },
    {
      "NUM_FINANCIER_CD": 11517,
      "TXT_FINANCIER_NAME": "SORONDAMEL S C S LTD"
    },
    {
      "NUM_FINANCIER_CD": 11518,
      "TXT_FINANCIER_NAME": "LOYAL HIRE PURCHASING C"
    },
    {
      "NUM_FINANCIER_CD": 11519,
      "TXT_FINANCIER_NAME": "THE CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11520,
      "TXT_FINANCIER_NAME": "SHREE AUTO LEASING"
    },
    {
      "NUM_FINANCIER_CD": 11521,
      "TXT_FINANCIER_NAME": "SRG SECURITIES FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11522,
      "TXT_FINANCIER_NAME": "KAVITHA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11523,
      "TXT_FINANCIER_NAME": "MAHANAGAR TELEPHONE NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 11524,
      "TXT_FINANCIER_NAME": "JIVANREKHA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11525,
      "TXT_FINANCIER_NAME": "KARJAT TALUKA NAGARI SAHAKARI PATSANTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 11526,
      "TXT_FINANCIER_NAME": "P R INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 11527,
      "TXT_FINANCIER_NAME": "THEJUS VEHICLE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11528,
      "TXT_FINANCIER_NAME": "CHINTAMANI NAGRI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11529,
      "TXT_FINANCIER_NAME": "ITNL ENSO RAIL SYSTEMS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11530,
      "TXT_FINANCIER_NAME": "JAI HIND SOUHARD PATTIN SHAKARI NIYMIT"
    },
    {
      "NUM_FINANCIER_CD": 11531,
      "TXT_FINANCIER_NAME": "PARTHAMA BANK"
    },
    {
      "NUM_FINANCIER_CD": 11532,
      "TXT_FINANCIER_NAME": "SHIVKRIPA GRAMIN BIGARSHETY SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11533,
      "TXT_FINANCIER_NAME": "VALIYAKUZHI SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11534,
      "TXT_FINANCIER_NAME": "BARTER AND LEASE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11535,
      "TXT_FINANCIER_NAME": "THE CHAK KALAN CO OP AGRI MULTI PURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11536,
      "TXT_FINANCIER_NAME": "SHREERAM SAMARTHA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11537,
      "TXT_FINANCIER_NAME": "SHRI BRAHMANATH CREDIT SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 11538,
      "TXT_FINANCIER_NAME": "VEER RANI MALLAMMA URBAN CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11539,
      "TXT_FINANCIER_NAME": "KARANJE GRAMIN BIGER SHETI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11540,
      "TXT_FINANCIER_NAME": "NARKESHARI NAGRIK SAHAKARI PATH SANSTHA BHANDARA"
    },
    {
      "NUM_FINANCIER_CD": 11541,
      "TXT_FINANCIER_NAME": "AHINSA NAGARI SAHAKARI PARSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11542,
      "TXT_FINANCIER_NAME": "SHRI DATTA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11543,
      "TXT_FINANCIER_NAME": "VARDHAMAN FINANCE CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11544,
      "TXT_FINANCIER_NAME": "RAJGARH NAGRIK SAKH SAHKARITA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11545,
      "TXT_FINANCIER_NAME": "GAURAV FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11546,
      "TXT_FINANCIER_NAME": "ALAPPUZHA GOVT SERVANTS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11547,
      "TXT_FINANCIER_NAME": "KANAN DEVAN HILLS PLANTATION CO PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11548,
      "TXT_FINANCIER_NAME": "MAJI SAINIK GRAMIN BIGER SHATI SAHAKARI PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11549,
      "TXT_FINANCIER_NAME": "A V FINANCIERS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11550,
      "TXT_FINANCIER_NAME": "SRI VINAYAGA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11551,
      "TXT_FINANCIER_NAME": "A G FINANCE AND INVESTMENT P LTD"
    },
    {
      "NUM_FINANCIER_CD": 11552,
      "TXT_FINANCIER_NAME": "SHRI SAI GANESH KRUPA GRAMIN BIGAR SHETI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11553,
      "TXT_FINANCIER_NAME": "THE CHAMIARA CO OPERATIVE AGGRICULTURE MULTIPURPOSE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11554,
      "TXT_FINANCIER_NAME": "THE AUTHOLA CO OPERATIVE AGGRICULTURE MULTIPURPOSE SERVICE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11555,
      "TXT_FINANCIER_NAME": "LAKSHYA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11556,
      "TXT_FINANCIER_NAME": "CHANDU KUMATH FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11557,
      "TXT_FINANCIER_NAME": "HASTIMAL SISODIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 11558,
      "TXT_FINANCIER_NAME": "SHRI SAISHRIJI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11559,
      "TXT_FINANCIER_NAME": "RAMAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11560,
      "TXT_FINANCIER_NAME": "HALONDI NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11561,
      "TXT_FINANCIER_NAME": "VIKRAM SARABHAI SPACE CENTER"
    },
    {
      "NUM_FINANCIER_CD": 11562,
      "TXT_FINANCIER_NAME": "PROTESTANT CRISTHA SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 11563,
      "TXT_FINANCIER_NAME": "IL AND FS MARITIME INFRASTRUCTURE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11564,
      "TXT_FINANCIER_NAME": "SHAYONA FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11565,
      "TXT_FINANCIER_NAME": "PARNEKAR NAGARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11566,
      "TXT_FINANCIER_NAME": "ESCORTS EMPLOYEES WELFARE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11567,
      "TXT_FINANCIER_NAME": "SHRI GAYATRI URBAN COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11568,
      "TXT_FINANCIER_NAME": "JAGAJYOTHI BASAVESHWARA PATTINA SOWHARDI SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11569,
      "TXT_FINANCIER_NAME": "TAMIL NADU DR MGR MEDICAL UNIVERCITY"
    },
    {
      "NUM_FINANCIER_CD": 21414,
      "TXT_FINANCIER_NAME": "BASWESHWAR NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 21404,
      "TXT_FINANCIER_NAME": "ESAF SMALL FINANCE BANK"
    },
    {
      "NUM_FINANCIER_CD": 21389,
      "TXT_FINANCIER_NAME": "SVC CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16097,
      "TXT_FINANCIER_NAME": "THE HONAVAR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16098,
      "TXT_FINANCIER_NAME": "THE FINANCIAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16099,
      "TXT_FINANCIER_NAME": "Sir M.Visvesvaraya Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16100,
      "TXT_FINANCIER_NAME": "AMERICAN EXPRESS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16101,
      "TXT_FINANCIER_NAME": "SHRI PAISAFUND SHETAKI SAH BANK LTD HUPARI"
    },
    {
      "NUM_FINANCIER_CD": 16102,
      "TXT_FINANCIER_NAME": "SIHOR MERCANTILE CO-OP. BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16103,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIDE AUTO FINANCIAL SERVICES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16104,
      "TXT_FINANCIER_NAME": "The Annapurna Mahila Co-operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16105,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16106,
      "TXT_FINANCIER_NAME": "THE FAIZ MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16107,
      "TXT_FINANCIER_NAME": "THE URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16108,
      "TXT_FINANCIER_NAME": "THE UNION CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16109,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16110,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16111,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16112,
      "TXT_FINANCIER_NAME": "NAGAUR URBAN CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16113,
      "TXT_FINANCIER_NAME": "THE NARODA NAGRIK CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16114,
      "TXT_FINANCIER_NAME": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 16115,
      "TXT_FINANCIER_NAME": "THE DECCAN MERCHANTS CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16116,
      "TXT_FINANCIER_NAME": "THE KANGRA CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16117,
      "TXT_FINANCIER_NAME": "THE SHRIRAM SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16118,
      "TXT_FINANCIER_NAME": "THE UNION CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16119,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16120,
      "TXT_FINANCIER_NAME": "THE DHANALAKSHMI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16121,
      "TXT_FINANCIER_NAME": "KASUNDIA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16122,
      "TXT_FINANCIER_NAME": "Dhandapani Finance Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16123,
      "TXT_FINANCIER_NAME": "Gujarat Commercial Co-Op. Bank Ltd. Indore"
    },
    {
      "NUM_FINANCIER_CD": 16124,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16125,
      "TXT_FINANCIER_NAME": "MANN FINANCE LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16126,
      "TXT_FINANCIER_NAME": "SATPUDA NARMADA KHESHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 16127,
      "TXT_FINANCIER_NAME": "Ghaziabad Zila Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16128,
      "TXT_FINANCIER_NAME": "THE DISTRICT CO-OP.CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16129,
      "TXT_FINANCIER_NAME": "THE JANATHA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16130,
      "TXT_FINANCIER_NAME": "THE TAMILNADU STATE APEX CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16131,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE CO OPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16132,
      "TXT_FINANCIER_NAME": "BHAGYODAYA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16133,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16134,
      "TXT_FINANCIER_NAME": "CANARA BANK HAZRATGANJ"
    },
    {
      "NUM_FINANCIER_CD": 16135,
      "TXT_FINANCIER_NAME": "JILA SAHKARI KENDRIYA BANK MARYADIT, RATLAM"
    },
    {
      "NUM_FINANCIER_CD": 16136,
      "TXT_FINANCIER_NAME": "CITIZEN CREDIT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16137,
      "TXT_FINANCIER_NAME": "THE GURGAON CENTRAL CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16138,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16139,
      "TXT_FINANCIER_NAME": "SHIVALIK MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16140,
      "TXT_FINANCIER_NAME": "THE LUDHIANA CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16141,
      "TXT_FINANCIER_NAME": "KARNATAKA BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16142,
      "TXT_FINANCIER_NAME": "THE UTTARSANDA PEOPLE S CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16143,
      "TXT_FINANCIER_NAME": "THE VIZIANAGARAM COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16144,
      "TXT_FINANCIER_NAME": "ICICI LTD"
    },
    {
      "NUM_FINANCIER_CD": 16145,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR URBAN CO-OP BANK LTD, BAGALKOT"
    },
    {
      "NUM_FINANCIER_CD": 16146,
      "TXT_FINANCIER_NAME": "JUNAGADH COMMERCIAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16147,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16148,
      "TXT_FINANCIER_NAME": "THE TAMILNADU INDUSTRIAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16149,
      "TXT_FINANCIER_NAME": "BAGHAT URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16150,
      "TXT_FINANCIER_NAME": "KARNATAKA RAJYA KAIGARIKA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 16151,
      "TXT_FINANCIER_NAME": "SREE THYAGARAJA CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16152,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16153,
      "TXT_FINANCIER_NAME": "THE MERCHANTS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16154,
      "TXT_FINANCIER_NAME": "HIMATNAGAR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16155,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16156,
      "TXT_FINANCIER_NAME": "MAJHA FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16157,
      "TXT_FINANCIER_NAME": "MAKHAN SHAH LOBANA FINANCE PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 16158,
      "TXT_FINANCIER_NAME": "MALAYALAM HIRE PURCHASE AND LEASING PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 16159,
      "TXT_FINANCIER_NAME": "MALWA (LUDHIANA) MOTOR FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16160,
      "TXT_FINANCIER_NAME": "MALWA LEASING & CREDITS (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16161,
      "TXT_FINANCIER_NAME": "MANAPPURAM GENERAL FINANCE AND LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16162,
      "TXT_FINANCIER_NAME": "MANGO SECURITIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16163,
      "TXT_FINANCIER_NAME": "MANHAR FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16164,
      "TXT_FINANCIER_NAME": "MANJIRA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16165,
      "TXT_FINANCIER_NAME": "MANO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16166,
      "TXT_FINANCIER_NAME": "MANOHAR FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 16167,
      "TXT_FINANCIER_NAME": "MANSAR FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16168,
      "TXT_FINANCIER_NAME": "MANTRANA FINLEASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16169,
      "TXT_FINANCIER_NAME": "MARICHI FINANCE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16170,
      "TXT_FINANCIER_NAME": "MARUTI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16171,
      "TXT_FINANCIER_NAME": "MAS FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16172,
      "TXT_FINANCIER_NAME": "MASTI LEASING & FINANCING CO.PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 16173,
      "TXT_FINANCIER_NAME": "MEDA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16174,
      "TXT_FINANCIER_NAME": "MGS INVESTMENTS PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 16175,
      "TXT_FINANCIER_NAME": "MICRO FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16176,
      "TXT_FINANCIER_NAME": "MIDLAND MOTOR FINANCE CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16177,
      "TXT_FINANCIER_NAME": "MITTER FINANCE CO. PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16178,
      "TXT_FINANCIER_NAME": "MODEL FINANCIAL CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 16179,
      "TXT_FINANCIER_NAME": "MOGA DEPOSITS AND ADVANCES PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16180,
      "TXT_FINANCIER_NAME": "MOGA FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16181,
      "TXT_FINANCIER_NAME": "MONT BLAC FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16182,
      "TXT_FINANCIER_NAME": "MOONSHINE FINVEST PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16183,
      "TXT_FINANCIER_NAME": "MOUNTAIN LEASING CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16184,
      "TXT_FINANCIER_NAME": "MUKAT FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16185,
      "TXT_FINANCIER_NAME": "MUKUNDA INDUSTRIAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16186,
      "TXT_FINANCIER_NAME": "MULAMOOTTIL CONSUMER CREDITS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16187,
      "TXT_FINANCIER_NAME": "MUTHOOT CAPITAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16188,
      "TXT_FINANCIER_NAME": "MUTHOOT LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16189,
      "TXT_FINANCIER_NAME": "NAHAR FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16190,
      "TXT_FINANCIER_NAME": "NAINI FINCAP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16191,
      "TXT_FINANCIER_NAME": "NALIN LEASE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16192,
      "TXT_FINANCIER_NAME": "NAMAKKAL ANDAVAR BUSINESS CREDIT LTD."
    },
    {
      "NUM_FINANCIER_CD": 16193,
      "TXT_FINANCIER_NAME": "NANDINI FINANCE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16194,
      "TXT_FINANCIER_NAME": "NATION WIDE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 17624,
      "TXT_FINANCIER_NAME": "GUJARAT ENERGY TRANSMISSION CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 17625,
      "TXT_FINANCIER_NAME": "CANARA BANK CHINCHWAD BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 17626,
      "TXT_FINANCIER_NAME": "MAGMA FIN CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 17627,
      "TXT_FINANCIER_NAME": "SRI SAKTHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17628,
      "TXT_FINANCIER_NAME": "STATE BANK OF TRAUANCORE"
    },
    {
      "NUM_FINANCIER_CD": 17629,
      "TXT_FINANCIER_NAME": "GUJARAT STATE ELECTRICITY CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 17630,
      "TXT_FINANCIER_NAME": "SRIRAM TRANSPORT FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17631,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA KARNAL"
    },
    {
      "NUM_FINANCIER_CD": 17632,
      "TXT_FINANCIER_NAME": "THE KARAD MERCHANT SAHAKARI CREDIT SANSTHA LTD,KARAD"
    },
    {
      "NUM_FINANCIER_CD": 17633,
      "TXT_FINANCIER_NAME": "SHIVAJI NAGARI SHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17634,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIME LTD."
    },
    {
      "NUM_FINANCIER_CD": 17635,
      "TXT_FINANCIER_NAME": "KOZHIKODE DISTRICT CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17636,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT CO-OP. BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17637,
      "TXT_FINANCIER_NAME": "CENTURION BANK OF PUNJAB LTD."
    },
    {
      "NUM_FINANCIER_CD": 17638,
      "TXT_FINANCIER_NAME": "GULSHAN MERCANTILE URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17639,
      "TXT_FINANCIER_NAME": "UBI"
    },
    {
      "NUM_FINANCIER_CD": 17640,
      "TXT_FINANCIER_NAME": "THE GOZARIA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17641,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17642,
      "TXT_FINANCIER_NAME": "SREE THYAGARAJA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17643,
      "TXT_FINANCIER_NAME": "THE AKOLA JANATA COMMERCIAL CO-OP.BANK LTD.,AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 17644,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD., M.G. ROAD, LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 17645,
      "TXT_FINANCIER_NAME": "SHREE SAVLI NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17646,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIME LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17647,
      "TXT_FINANCIER_NAME": "AURANGABAD"
    },
    {
      "NUM_FINANCIER_CD": 17648,
      "TXT_FINANCIER_NAME": "KERALA STATE FINANCIAL ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 17649,
      "TXT_FINANCIER_NAME": "THE DAHOD URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17650,
      "TXT_FINANCIER_NAME": "NIRMAL URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17651,
      "TXT_FINANCIER_NAME": "THE ANDHRA PRADESH STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17652,
      "TXT_FINANCIER_NAME": "NAVSARJAN INDUSTRIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17653,
      "TXT_FINANCIER_NAME": "CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17654,
      "TXT_FINANCIER_NAME": "M.S. CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17655,
      "TXT_FINANCIER_NAME": "SHIVALIK MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17656,
      "TXT_FINANCIER_NAME": "THE JALGAON DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17657,
      "TXT_FINANCIER_NAME": "THE JALGAON DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17658,
      "TXT_FINANCIER_NAME": "CHAITANYA URBAN CO-OP BANK LTD,WAI"
    },
    {
      "NUM_FINANCIER_CD": 17659,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17660,
      "TXT_FINANCIER_NAME": "THE COIMBATORE CITY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17661,
      "TXT_FINANCIER_NAME": "THE LUNAWADA PEOPLEAS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17662,
      "TXT_FINANCIER_NAME": "THE LUNAWADA PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17663,
      "TXT_FINANCIER_NAME": "THE BODELI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17664,
      "TXT_FINANCIER_NAME": "BAGHAT URBAN CO-OPERATIVE BANK LTA"
    },
    {
      "NUM_FINANCIER_CD": 17665,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIMUS LTD"
    },
    {
      "NUM_FINANCIER_CD": 17666,
      "TXT_FINANCIER_NAME": "SHRI PARSHWANATH CO.-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17667,
      "TXT_FINANCIER_NAME": "PRIYADARSHINI MAHILA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17668,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17669,
      "TXT_FINANCIER_NAME": "THE MUNICIPAL CO-OP. BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17670,
      "TXT_FINANCIER_NAME": "VISHWAKARMA NAGARI SAHAKARI BANK LTS.,"
    },
    {
      "NUM_FINANCIER_CD": 17671,
      "TXT_FINANCIER_NAME": "THE THANE BHARAT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17672,
      "TXT_FINANCIER_NAME": "PITHORAGARH ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17673,
      "TXT_FINANCIER_NAME": "THE MODASA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17674,
      "TXT_FINANCIER_NAME": "VIKAS URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17675,
      "TXT_FINANCIER_NAME": "THE NAVANIRMAN CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17676,
      "TXT_FINANCIER_NAME": "THE RAJWADE MANDAL PEOPLES CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17677,
      "TXT_FINANCIER_NAME": "THE STATE TRADING CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17678,
      "TXT_FINANCIER_NAME": "AHILYADEVI NAGARI SAHAKARI PATSANSTHA SHELGI ROAD SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17679,
      "TXT_FINANCIER_NAME": "VEERASHAIVA URBAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17680,
      "TXT_FINANCIER_NAME": "SIDDHESHWAR SAHAKARI BANK LTD SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17681,
      "TXT_FINANCIER_NAME": "IRDA"
    },
    {
      "NUM_FINANCIER_CD": 17682,
      "TXT_FINANCIER_NAME": "SHRI RAM CITY UNION FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17683,
      "TXT_FINANCIER_NAME": "MAHUVA VIBHAG CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17684,
      "TXT_FINANCIER_NAME": "DEENDAYAL NAGARI SAHAKARI PATHA SAUSTHAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17685,
      "TXT_FINANCIER_NAME": "ADARSH NAGARI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17686,
      "TXT_FINANCIER_NAME": "JANKALYAN URBAN CO OP BANK KALAM"
    },
    {
      "NUM_FINANCIER_CD": 17687,
      "TXT_FINANCIER_NAME": "BHEL EMPLOYEES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17688,
      "TXT_FINANCIER_NAME": "SHRI SHIVESHWAR NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17689,
      "TXT_FINANCIER_NAME": "SHRI SHIVESHWAR NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17690,
      "TXT_FINANCIER_NAME": "SHREE KALIKA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17691,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALA PARDI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17692,
      "TXT_FINANCIER_NAME": "RUPAYAN FINANCE AND INDUSTRIAL COPR LTD"
    },
    {
      "NUM_FINANCIER_CD": 17693,
      "TXT_FINANCIER_NAME": "SAJJAN RAJ SINGHVI AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 17694,
      "TXT_FINANCIER_NAME": "BHAIRO INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 17695,
      "TXT_FINANCIER_NAME": "NAGRIK SAHAKARIK BANK"
    },
    {
      "NUM_FINANCIER_CD": 17696,
      "TXT_FINANCIER_NAME": "STATE BANK OF PATIYALA"
    },
    {
      "NUM_FINANCIER_CD": 17697,
      "TXT_FINANCIER_NAME": "ADARSH NAGARI SAHAKARI PATSANSTHA MARYADIT ALIBAG"
    },
    {
      "NUM_FINANCIER_CD": 17698,
      "TXT_FINANCIER_NAME": "DHANLAXMI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17699,
      "TXT_FINANCIER_NAME": "AHILYADEVI NAGARI SAHAKARI PATHSANSTHA SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17700,
      "TXT_FINANCIER_NAME": "THE TWIN CITIES CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17701,
      "TXT_FINANCIER_NAME": "SATTARI URBAN CO OPERATIVE CREDIT SOCIET"
    },
    {
      "NUM_FINANCIER_CD": 17702,
      "TXT_FINANCIER_NAME": "SHRI GANESH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17703,
      "TXT_FINANCIER_NAME": "THE SOUTH KANARA DISTRICT CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17704,
      "TXT_FINANCIER_NAME": "SYNDICATE BANK DR DVG ROAD BASAVANAGUDI BANGALORE"
    },
    {
      "NUM_FINANCIER_CD": 17705,
      "TXT_FINANCIER_NAME": "MAYURAM ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 17706,
      "TXT_FINANCIER_NAME": "THE ALNAVAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17707,
      "TXT_FINANCIER_NAME": "TATA CAPITAL FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 17708,
      "TXT_FINANCIER_NAME": "SURANA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17709,
      "TXT_FINANCIER_NAME": "THE PRANTIJ NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17710,
      "TXT_FINANCIER_NAME": "CAUVERY KALPATHARU GRAMEENA BANK S S PURAM BRANCH TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 17711,
      "TXT_FINANCIER_NAME": "MAHESH MAHILA NAGARI SAHAKARI PATSAMSTHA"
    },
    {
      "NUM_FINANCIER_CD": 17712,
      "TXT_FINANCIER_NAME": "SHIVALIK MERCANTILE COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17713,
      "TXT_FINANCIER_NAME": "PORBANDAR VIBHAGIYA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17714,
      "TXT_FINANCIER_NAME": "CENTURION BANK OF PUNJAB LTD"
    },
    {
      "NUM_FINANCIER_CD": 17715,
      "TXT_FINANCIER_NAME": "CENTURIAN BANK OF PUNJAB LTD"
    },
    {
      "NUM_FINANCIER_CD": 17716,
      "TXT_FINANCIER_NAME": "GURGAON GRAMIN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17717,
      "TXT_FINANCIER_NAME": "THE RANUJ NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17718,
      "TXT_FINANCIER_NAME": "THE KHERALU NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17719,
      "TXT_FINANCIER_NAME": "THE BHAVANA RISHI CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12977,
      "TXT_FINANCIER_NAME": "ACTION CONSTRUCTION EQUIPMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12978,
      "TXT_FINANCIER_NAME": "NANDINI MILK PRODUCERS CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12979,
      "TXT_FINANCIER_NAME": "LAKSHMI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12980,
      "TXT_FINANCIER_NAME": "MATOSHRI SAU GAYAI MAHILA TALUKA SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12981,
      "TXT_FINANCIER_NAME": "THE HONNAVAR HAVYAK URBAN COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12982,
      "TXT_FINANCIER_NAME": "SHRI BHANSALI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12983,
      "TXT_FINANCIER_NAME": "THE MANDAL NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12984,
      "TXT_FINANCIER_NAME": "CHAROTAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12985,
      "TXT_FINANCIER_NAME": "HINDUSTAN LEYLAND FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 12986,
      "TXT_FINANCIER_NAME": "AZRA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12987,
      "TXT_FINANCIER_NAME": "IBM INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12988,
      "TXT_FINANCIER_NAME": "GOA UNIVERCITY EMPLOYEES CO OPERATION CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12989,
      "TXT_FINANCIER_NAME": "KANCHANGAURI MAHILA SAHAKARI PATHPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12990,
      "TXT_FINANCIER_NAME": "KANCHANGOURI MAHILA SAHAKARI PATHPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12991,
      "TXT_FINANCIER_NAME": "KOMA COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12992,
      "TXT_FINANCIER_NAME": "P G NAHAR FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12993,
      "TXT_FINANCIER_NAME": "AASHTINAGARI SAHKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12994,
      "TXT_FINANCIER_NAME": "MAHAVEERA MINORITY CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12995,
      "TXT_FINANCIER_NAME": "NANDANAM ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12996,
      "TXT_FINANCIER_NAME": "NAMOKKAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12997,
      "TXT_FINANCIER_NAME": "THE MAHELAV PEOPLES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12998,
      "TXT_FINANCIER_NAME": "SUSHILA KANWAR METHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12999,
      "TXT_FINANCIER_NAME": "A I P R CREDIT CO OP SOCICTY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13000,
      "TXT_FINANCIER_NAME": "U K SOUHARDHA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13001,
      "TXT_FINANCIER_NAME": "KOTA AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13002,
      "TXT_FINANCIER_NAME": "PATAN NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13003,
      "TXT_FINANCIER_NAME": "SHREE POORNANANDA VIVIDODDESHA SOUHARDA SAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13004,
      "TXT_FINANCIER_NAME": "SRI SEETHARAM VIVIDDODESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13005,
      "TXT_FINANCIER_NAME": "KUNDAPURA SOWHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13006,
      "TXT_FINANCIER_NAME": "FOUR SQUARE AUTO CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 13007,
      "TXT_FINANCIER_NAME": "HARISH KUMAR SURANA"
    },
    {
      "NUM_FINANCIER_CD": 13008,
      "TXT_FINANCIER_NAME": "NEW SHRADDHA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13009,
      "TXT_FINANCIER_NAME": "AADHAR MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13010,
      "TXT_FINANCIER_NAME": "SAPTHASAGARA SOURADHA CREDIT CO OPERATIVE LTD R"
    },
    {
      "NUM_FINANCIER_CD": 13011,
      "TXT_FINANCIER_NAME": "CHAITHYANA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13012,
      "TXT_FINANCIER_NAME": "SRI BALA GANAPATHY BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 13013,
      "TXT_FINANCIER_NAME": "G E CAPITAL SERVICE INDIA"
    },
    {
      "NUM_FINANCIER_CD": 13014,
      "TXT_FINANCIER_NAME": "VADGAON SARAF VYAPARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13015,
      "TXT_FINANCIER_NAME": "SOORYA CREDIT SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13016,
      "TXT_FINANCIER_NAME": "MADAPPADI SAHAKARI VYAVASAIKA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13017,
      "TXT_FINANCIER_NAME": "SHREE GURU AUTO FINANCE PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13018,
      "TXT_FINANCIER_NAME": "MADAPADI SAHAKARI VYAVASAIKA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13019,
      "TXT_FINANCIER_NAME": "THE MARDOL URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13020,
      "TXT_FINANCIER_NAME": "THE KADANNAPPALLI PANAPPUZHA"
    },
    {
      "NUM_FINANCIER_CD": 13021,
      "TXT_FINANCIER_NAME": "VANDE MATHARAM VIVIDODDESHA SAUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13022,
      "TXT_FINANCIER_NAME": "YASHASWINI MAHILA SOUHARDA CREDIT CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13023,
      "TXT_FINANCIER_NAME": "THE MANAGER LIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 13024,
      "TXT_FINANCIER_NAME": "CAPITAL PLUS FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13025,
      "TXT_FINANCIER_NAME": "SHIV KALYAN NAGARI SAHAKARI PAT MAYD"
    },
    {
      "NUM_FINANCIER_CD": 13026,
      "TXT_FINANCIER_NAME": "SAINIK PARIWAR GRAMIN BIGARSHETI SAH PATH SANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13027,
      "TXT_FINANCIER_NAME": "BCP FINANCE AND INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13028,
      "TXT_FINANCIER_NAME": "ANKITHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13029,
      "TXT_FINANCIER_NAME": "MS SYNEIX FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13030,
      "TXT_FINANCIER_NAME": "KUMARAPALM JAIN"
    },
    {
      "NUM_FINANCIER_CD": 13031,
      "TXT_FINANCIER_NAME": "H RATANCHAND NAHAR AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13032,
      "TXT_FINANCIER_NAME": "PUNYASHLOK AHILYADEVI NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13033,
      "TXT_FINANCIER_NAME": "THE SECRETARY TAMILNADU ELECTRICITY REGULATORY COMMISSION"
    },
    {
      "NUM_FINANCIER_CD": 13034,
      "TXT_FINANCIER_NAME": "YASHODEEP GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13035,
      "TXT_FINANCIER_NAME": "KARMVEER SAHAKARI PATHPDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13036,
      "TXT_FINANCIER_NAME": "SRI VASHISTA CREDIT SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13037,
      "TXT_FINANCIER_NAME": "THE GUNDUGOLANU LARGE SIZED CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13038,
      "TXT_FINANCIER_NAME": "SANKALP NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13039,
      "TXT_FINANCIER_NAME": "DHANVARSHA NAGARI SAH PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13040,
      "TXT_FINANCIER_NAME": "THE ANNADEVARAPETA LARGE SIZED CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13041,
      "TXT_FINANCIER_NAME": "SRI KALABAIRAWESHWARA MULTI PURPUSE CO OP SOCAITY"
    },
    {
      "NUM_FINANCIER_CD": 13042,
      "TXT_FINANCIER_NAME": "MATHRUSHREE PATTINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13043,
      "TXT_FINANCIER_NAME": "GUJRAT GOPALAK DEV CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13044,
      "TXT_FINANCIER_NAME": "VAINGANGA VIDARBH KONKAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 13045,
      "TXT_FINANCIER_NAME": "KADSIDESHWAR PATTAN SAH BANK NIYAM"
    },
    {
      "NUM_FINANCIER_CD": 13046,
      "TXT_FINANCIER_NAME": "SAMRUDDHI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13047,
      "TXT_FINANCIER_NAME": "THE GANGA M URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13048,
      "TXT_FINANCIER_NAME": "THE PARIYARAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13049,
      "TXT_FINANCIER_NAME": "PATIL MULTISTATE CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13050,
      "TXT_FINANCIER_NAME": "SHIVYOG NAGARI SAHAKARI PATSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 13051,
      "TXT_FINANCIER_NAME": "KARTHIKAPPALLY CARD BANK"
    },
    {
      "NUM_FINANCIER_CD": 13052,
      "TXT_FINANCIER_NAME": "PADMAPRABHA NAGARI SAHAKARI PATH SANTHA MARYADIT ASHTA"
    },
    {
      "NUM_FINANCIER_CD": 13053,
      "TXT_FINANCIER_NAME": "SHRI TULSHIBAUG NAGRI SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13054,
      "TXT_FINANCIER_NAME": "SHRI SHIVABODHRANG URBAN CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13055,
      "TXT_FINANCIER_NAME": "THE DY SECRETARY G TO GOVT HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 13056,
      "TXT_FINANCIER_NAME": "HIRIADKA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13057,
      "TXT_FINANCIER_NAME": "THE VELPUR P A C S LTD"
    },
    {
      "NUM_FINANCIER_CD": 13058,
      "TXT_FINANCIER_NAME": "SHRI SHIVAJIRAJE VYAPARI NAGARI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13059,
      "TXT_FINANCIER_NAME": "AAKAY FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13060,
      "TXT_FINANCIER_NAME": "JOMADEVI SAHAKARI PATH PEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13061,
      "TXT_FINANCIER_NAME": "THANNIRUPANTHA PRIMARY AGRICULTURAL CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13062,
      "TXT_FINANCIER_NAME": "THE BHARATH CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13063,
      "TXT_FINANCIER_NAME": "DELHI SC ST OM AND H FINANCIAL DEV CORPN LTD"
    },
    {
      "NUM_FINANCIER_CD": 13064,
      "TXT_FINANCIER_NAME": "NAVARATHNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13065,
      "TXT_FINANCIER_NAME": "S K CO OPERATIVE EMPLOYEES CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13066,
      "TXT_FINANCIER_NAME": "THE AMARPUR AGRICULTURE SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13067,
      "TXT_FINANCIER_NAME": "LESSOR NEW GANGOTRI TRAVELS"
    },
    {
      "NUM_FINANCIER_CD": 13068,
      "TXT_FINANCIER_NAME": "PRIYADARSHINI NAVHIND MAHILA MULTI PUPOSE CO OP SOCIETY LTD YELLUR"
    },
    {
      "NUM_FINANCIER_CD": 14469,
      "TXT_FINANCIER_NAME": "SHREE DHANVARSHA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14470,
      "TXT_FINANCIER_NAME": "PARRA URBAN CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14471,
      "TXT_FINANCIER_NAME": "SAKTINAGAR SAMABAYA KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14472,
      "TXT_FINANCIER_NAME": "VAIDYAKIYA VYAVASAHIK NAGARI SAH PATHSANSTHA MARYADIT VITA"
    },
    {
      "NUM_FINANCIER_CD": 14473,
      "TXT_FINANCIER_NAME": "NARAYANI MULTIPURPOSE CO OP SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14474,
      "TXT_FINANCIER_NAME": "SIDDAGANGASRI VIVIDODDESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14475,
      "TXT_FINANCIER_NAME": "DISTRICT MANAGER"
    },
    {
      "NUM_FINANCIER_CD": 14476,
      "TXT_FINANCIER_NAME": "SEVA SAHAKHARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14477,
      "TXT_FINANCIER_NAME": "GOMUNDA SKUS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14478,
      "TXT_FINANCIER_NAME": "THE SUNHARA CO OPERATIVE AGRICULTURE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14479,
      "TXT_FINANCIER_NAME": "UNITED CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14480,
      "TXT_FINANCIER_NAME": "GODAVARI NANDED MULTISTATE URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14481,
      "TXT_FINANCIER_NAME": "SRI PANCHASHAKTHI VIVIDHODDESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14482,
      "TXT_FINANCIER_NAME": "SIDDI SOWHARDA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 14483,
      "TXT_FINANCIER_NAME": "SHREE BHARATHI SOUHARDHA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 14484,
      "TXT_FINANCIER_NAME": "SHREE JANARDHANA SWAMI CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14485,
      "TXT_FINANCIER_NAME": "SWARNAKUMBHA MULTIPURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14486,
      "TXT_FINANCIER_NAME": "THE EZHOME SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14487,
      "TXT_FINANCIER_NAME": "SHRI VATESHWAR GRAMIN BIGRSHETI SAHAKARI PATASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14488,
      "TXT_FINANCIER_NAME": "VISHWAKALYAN NAGARI SAH PATSAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 14489,
      "TXT_FINANCIER_NAME": "SHRI MOOKAMBIKA VYAVASAYA SEVA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14490,
      "TXT_FINANCIER_NAME": "THE TRAVANCORE CEMENTS EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14491,
      "TXT_FINANCIER_NAME": "PADMUNJA VYAVASAYA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14492,
      "TXT_FINANCIER_NAME": "ARAHADA PRATHAMIKA KRUSHI PATTINA SAHAKARA SANGA"
    },
    {
      "NUM_FINANCIER_CD": 14493,
      "TXT_FINANCIER_NAME": "SWARGIY RAMBHAU LINGADE SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14494,
      "TXT_FINANCIER_NAME": "MAHALAXMI MAHILA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14495,
      "TXT_FINANCIER_NAME": "SHREE PANCHMUKHI GANESH NAGARI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14496,
      "TXT_FINANCIER_NAME": "SHRI RAJENDRA SURI SAKH SAH MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14497,
      "TXT_FINANCIER_NAME": "BADR BAGH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14498,
      "TXT_FINANCIER_NAME": "RAMANNAGUDEM PACS"
    },
    {
      "NUM_FINANCIER_CD": 14499,
      "TXT_FINANCIER_NAME": "SHRI VRUSHABHANATH ALPA SANKYATRA PATTINA SAHAKARI SANGH NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 14500,
      "TXT_FINANCIER_NAME": "POLALI SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14501,
      "TXT_FINANCIER_NAME": "PSS FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14502,
      "TXT_FINANCIER_NAME": "MAHARASHTRA RAJYA ITAR MAGASWARGIYA VITTA ANI VIKAS MAHAMANDAL"
    },
    {
      "NUM_FINANCIER_CD": 14503,
      "TXT_FINANCIER_NAME": "THIRUVONAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14504,
      "TXT_FINANCIER_NAME": "SRI SRINIVASA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14505,
      "TXT_FINANCIER_NAME": "DHANASHREE MULTI STATE CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14506,
      "TXT_FINANCIER_NAME": "NANDHINI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14507,
      "TXT_FINANCIER_NAME": "THE KORUMAMIDI PRIMARY AGRICULTURE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14508,
      "TXT_FINANCIER_NAME": "HIRCHAND PRASANNCHAND"
    },
    {
      "NUM_FINANCIER_CD": 14509,
      "TXT_FINANCIER_NAME": "AZAD CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14510,
      "TXT_FINANCIER_NAME": "SRI SANJEEVINI PATTINA SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14511,
      "TXT_FINANCIER_NAME": "MAHABIR GMB TRADE FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14512,
      "TXT_FINANCIER_NAME": "BABULAL KUMBATH AND SONS FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 14513,
      "TXT_FINANCIER_NAME": "PADUBIDRI SAHAKARI VYAVASAYIKA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14514,
      "TXT_FINANCIER_NAME": "BRAHMAVARA VYAVASAYA SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 14515,
      "TXT_FINANCIER_NAME": "SHREE NAV JYOTI FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 14516,
      "TXT_FINANCIER_NAME": "NUTHAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14517,
      "TXT_FINANCIER_NAME": "VAIBHAV SISODIYA"
    },
    {
      "NUM_FINANCIER_CD": 14518,
      "TXT_FINANCIER_NAME": "THE UDMA VANITHA SERVICE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14519,
      "TXT_FINANCIER_NAME": "SIN INDIA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14520,
      "TXT_FINANCIER_NAME": "BARMECHA FINANCE CORPO"
    },
    {
      "NUM_FINANCIER_CD": 14521,
      "TXT_FINANCIER_NAME": "SHARADCHANDRA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14522,
      "TXT_FINANCIER_NAME": "HOSMATTA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 14523,
      "TXT_FINANCIER_NAME": "NASHIK ZILHA SARKARI AND PARISHAD KARMACHARI SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14524,
      "TXT_FINANCIER_NAME": "SURANA HIRE PURCHASE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14525,
      "TXT_FINANCIER_NAME": "R C SURANA"
    },
    {
      "NUM_FINANCIER_CD": 14526,
      "TXT_FINANCIER_NAME": "HALAPUR PATTINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14527,
      "TXT_FINANCIER_NAME": "SAIRAM AUTO LEASING AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14528,
      "TXT_FINANCIER_NAME": "SHIVCHATRAPATI CO OP CREDIT SCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14529,
      "TXT_FINANCIER_NAME": "KIM INFRASTRUCTURE AND DEVLOPER LTD"
    },
    {
      "NUM_FINANCIER_CD": 14530,
      "TXT_FINANCIER_NAME": "KARNATAKA RAJYA KAIGARIKA VANIJYA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14531,
      "TXT_FINANCIER_NAME": "ARYAVART KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14532,
      "TXT_FINANCIER_NAME": "MAHAJAN LEASING AND FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14533,
      "TXT_FINANCIER_NAME": "KURUHINSHETTY URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14534,
      "TXT_FINANCIER_NAME": "SJVNL LTD"
    },
    {
      "NUM_FINANCIER_CD": 14535,
      "TXT_FINANCIER_NAME": "HARYANA RENEWABLE ENERGY DEVELOPMENT AGENCY HAREDA"
    },
    {
      "NUM_FINANCIER_CD": 14536,
      "TXT_FINANCIER_NAME": "HATHYADKA SAHAKARI VYAVASAYIKA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14537,
      "TXT_FINANCIER_NAME": "ANUGRAHA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14538,
      "TXT_FINANCIER_NAME": "MERCHANT NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14539,
      "TXT_FINANCIER_NAME": "ANBU FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14540,
      "TXT_FINANCIER_NAME": "SHRI SHKETHRA DHARMASTHALA GRAMAABHIVRUDDI YOJANE R"
    },
    {
      "NUM_FINANCIER_CD": 14541,
      "TXT_FINANCIER_NAME": "MUNDUR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14542,
      "TXT_FINANCIER_NAME": "SHREE NARASIMHA CREDIT SOUHARD SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 14543,
      "TXT_FINANCIER_NAME": "USMA FIN LEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14544,
      "TXT_FINANCIER_NAME": "KESHAV MOTOR"
    },
    {
      "NUM_FINANCIER_CD": 14545,
      "TXT_FINANCIER_NAME": "JALSAMPDHA VIBHAG MAHARASHTRA SHASHAN"
    },
    {
      "NUM_FINANCIER_CD": 14546,
      "TXT_FINANCIER_NAME": "SHREE KHANDESHWAR GRAMIN BIGARSHETI SAH PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 14547,
      "TXT_FINANCIER_NAME": "SRI DHURGA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14548,
      "TXT_FINANCIER_NAME": "MORAD GAM GROUP SEVA SAHKARI MANDLI LIMITED AND S K BANK"
    },
    {
      "NUM_FINANCIER_CD": 14549,
      "TXT_FINANCIER_NAME": "JAGAMER CAMPA GROUP SEVA SAHKARI MANDLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14550,
      "TXT_FINANCIER_NAME": "THE ABDASAN GROUP SEVA SAHKARI MANDLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14551,
      "TXT_FINANCIER_NAME": "MEGHANA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14552,
      "TXT_FINANCIER_NAME": "MIRAJ GROUP VIKAS SEVA SOC"
    },
    {
      "NUM_FINANCIER_CD": 14553,
      "TXT_FINANCIER_NAME": "SRI RAJALAKSHMI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14554,
      "TXT_FINANCIER_NAME": "SUVICHAR SAHAKARI SAKH SANSTHA MARYADIT INDORE"
    },
    {
      "NUM_FINANCIER_CD": 14555,
      "TXT_FINANCIER_NAME": "SRI AYYAPPA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14556,
      "TXT_FINANCIER_NAME": "HOWRAH MITRA MANDAL CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14557,
      "TXT_FINANCIER_NAME": "JEEVANASRI SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14558,
      "TXT_FINANCIER_NAME": "JANHITAM COOPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14559,
      "TXT_FINANCIER_NAME": "D C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14560,
      "TXT_FINANCIER_NAME": "THE CO OPERATIVE DEPARMENTAL EMPLOYEES CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 16195,
      "TXT_FINANCIER_NAME": "NATIONWIDE INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16196,
      "TXT_FINANCIER_NAME": "NAWANSHAHAR FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16197,
      "TXT_FINANCIER_NAME": "NCC FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16198,
      "TXT_FINANCIER_NAME": "NEELAKRISHNA LEAFINS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16199,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OPRATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16200,
      "TXT_FINANCIER_NAME": "SURENDRANAGAR PEOPLE COOP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16201,
      "TXT_FINANCIER_NAME": "THE SURAT PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16202,
      "TXT_FINANCIER_NAME": "NEYVELI LIGNITE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16203,
      "TXT_FINANCIER_NAME": "ICICI BANKING CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 16204,
      "TXT_FINANCIER_NAME": "JALGAON JANTA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16205,
      "TXT_FINANCIER_NAME": "THE CKP CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16206,
      "TXT_FINANCIER_NAME": "THE SURAT TEXTILE TRADERS CO. OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16207,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16208,
      "TXT_FINANCIER_NAME": "THE SUTEX CO. OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16209,
      "TXT_FINANCIER_NAME": "NORTH CANARA G.S.B CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16210,
      "TXT_FINANCIER_NAME": "HIMACHAL GRAMIN BANK."
    },
    {
      "NUM_FINANCIER_CD": 16211,
      "TXT_FINANCIER_NAME": "THE JANATA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16212,
      "TXT_FINANCIER_NAME": "THE CATHOLIC SYRIAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16213,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16214,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL CO-OPRETIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16215,
      "TXT_FINANCIER_NAME": "HUBLI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16216,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16217,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FINANCIAL SERVICES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16218,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16219,
      "TXT_FINANCIER_NAME": "RAJLAXMI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16220,
      "TXT_FINANCIER_NAME": "ABHYUDAYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16221,
      "TXT_FINANCIER_NAME": "KOTAK"
    },
    {
      "NUM_FINANCIER_CD": 16222,
      "TXT_FINANCIER_NAME": "THE JANAKALYAN SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16223,
      "TXT_FINANCIER_NAME": "THE MUVATTUPUZHA AGRICULTURAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16224,
      "TXT_FINANCIER_NAME": "KTDFC LTD"
    },
    {
      "NUM_FINANCIER_CD": 16225,
      "TXT_FINANCIER_NAME": "MAHARASHTRA STATE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16226,
      "TXT_FINANCIER_NAME": "AMANATH CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16227,
      "TXT_FINANCIER_NAME": "NATIONAL MERCANTILE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16228,
      "TXT_FINANCIER_NAME": "SHRI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16229,
      "TXT_FINANCIER_NAME": "MURGHARAJENDRA SAHAKARI BANK LTD MIRAJ"
    },
    {
      "NUM_FINANCIER_CD": 16230,
      "TXT_FINANCIER_NAME": "THE COIMBATORE CITY COOPERATIVE BANK LID"
    },
    {
      "NUM_FINANCIER_CD": 16231,
      "TXT_FINANCIER_NAME": "THE MAHABALESHWAR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16232,
      "TXT_FINANCIER_NAME": "THE DAVANGERE-HARIHARA URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16233,
      "TXT_FINANCIER_NAME": "THE DAVANGERE-HARIHARA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16234,
      "TXT_FINANCIER_NAME": "M S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16235,
      "TXT_FINANCIER_NAME": "SHREE BHAVNAGAR NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16236,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16237,
      "TXT_FINANCIER_NAME": "BANK MUSCAT SAOG"
    },
    {
      "NUM_FINANCIER_CD": 16238,
      "TXT_FINANCIER_NAME": "BANK MUSCAT SAOG"
    },
    {
      "NUM_FINANCIER_CD": 16239,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGARIK CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16240,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGRIK CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16241,
      "TXT_FINANCIER_NAME": "ALMORA URBAN CO-OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16242,
      "TXT_FINANCIER_NAME": "VIJAYA CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16243,
      "TXT_FINANCIER_NAME": "KERALA STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16244,
      "TXT_FINANCIER_NAME": "SRI BHARATHI CO-OP.URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16245,
      "TXT_FINANCIER_NAME": "DR BABASAHEB AMBEDKAR CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16246,
      "TXT_FINANCIER_NAME": "DR. BABASAHEB AMBEDKAR URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16247,
      "TXT_FINANCIER_NAME": "SHREE DEESA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16248,
      "TXT_FINANCIER_NAME": "THE RAJPUTANA MAHILA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16249,
      "TXT_FINANCIER_NAME": "THE RAJPUTANA MAHILA URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16250,
      "TXT_FINANCIER_NAME": "INDORE SWAYAMSIDDH MAHILA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16251,
      "TXT_FINANCIER_NAME": "SARASPUR NAGARIK CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16252,
      "TXT_FINANCIER_NAME": "BIRLA GLOBAL FIN. LTD., INDORE"
    },
    {
      "NUM_FINANCIER_CD": 16253,
      "TXT_FINANCIER_NAME": "THE BARODA CITY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16254,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16255,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16256,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16257,
      "TXT_FINANCIER_NAME": "JILA SAHAKARI KENDRIYA BANK MYDT. JHABUA"
    },
    {
      "NUM_FINANCIER_CD": 16258,
      "TXT_FINANCIER_NAME": "OUR LADY OF MILAGRES URBAN CO-OP CREDIT STY.LTD"
    },
    {
      "NUM_FINANCIER_CD": 16259,
      "TXT_FINANCIER_NAME": "THE VAISH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16260,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM MS GENERAL INSURANCE COMANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 16261,
      "TXT_FINANCIER_NAME": "VIDYANAND CO-OP BANK LTD, SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 16262,
      "TXT_FINANCIER_NAME": "CHANDRAPUR DIST CENTRAL CO.OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16263,
      "TXT_FINANCIER_NAME": "THE JAMNAGAR DIST CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16264,
      "TXT_FINANCIER_NAME": "CHETANA SAHAKARA BANK NIYAMITHA, H.O.: SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 16265,
      "TXT_FINANCIER_NAME": "CHETANA SAHAKARA BANK NIYAMITHA, H.O.:SIRSI(U.K.)"
    },
    {
      "NUM_FINANCIER_CD": 16266,
      "TXT_FINANCIER_NAME": "CHETANA SAHAKARA BANK NIYAMITHA, SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 16267,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16268,
      "TXT_FINANCIER_NAME": "SOLAPUR JANATA SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16269,
      "TXT_FINANCIER_NAME": "THE KODOLI URBAN COOP BANK LTD KODOLI"
    },
    {
      "NUM_FINANCIER_CD": 16270,
      "TXT_FINANCIER_NAME": "THE ANDHRA PRADESH STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16271,
      "TXT_FINANCIER_NAME": "THE BELGAUM DIST.REVENUE EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16272,
      "TXT_FINANCIER_NAME": "THE MAPUSA URBAN CO-OP BANK OF GOA LTD"
    },
    {
      "NUM_FINANCIER_CD": 16273,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16274,
      "TXT_FINANCIER_NAME": "SHIKSHAK SAHAKARI BANK LTD., NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 16275,
      "TXT_FINANCIER_NAME": "THE COIMBATORE DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16276,
      "TXT_FINANCIER_NAME": "BHAICHAND HIRACHAND RAISONI GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 16277,
      "TXT_FINANCIER_NAME": "RYTHARA SEVA SAHAKARA BANK (N)"
    },
    {
      "NUM_FINANCIER_CD": 16278,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16279,
      "TXT_FINANCIER_NAME": "THE VISAKHAPATNAM CO-OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16280,
      "TXT_FINANCIER_NAME": "THE PARWANOO URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16281,
      "TXT_FINANCIER_NAME": "INDEPENDENCE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16282,
      "TXT_FINANCIER_NAME": "INDEPENDENCE CO. OP. BANK LID."
    },
    {
      "NUM_FINANCIER_CD": 16283,
      "TXT_FINANCIER_NAME": "Mahindra And Mahindra financial service limited"
    },
    {
      "NUM_FINANCIER_CD": 16284,
      "TXT_FINANCIER_NAME": "SHANKARRAO MOHITE -PATIL SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16285,
      "TXT_FINANCIER_NAME": "ALWAR URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16286,
      "TXT_FINANCIER_NAME": "VISHWAKARMA SAHAKARA BANK NIYAMITHA KARKALA"
    },
    {
      "NUM_FINANCIER_CD": 16287,
      "TXT_FINANCIER_NAME": "NEW SETHI FINANCE CO (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16288,
      "TXT_FINANCIER_NAME": "NEWLINE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16289,
      "TXT_FINANCIER_NAME": "NEWLINK OVERSEAS FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16290,
      "TXT_FINANCIER_NAME": "NORTH EAST SECURITIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 17720,
      "TXT_FINANCIER_NAME": "SARAYU GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17721,
      "TXT_FINANCIER_NAME": "THE KASARGOD DISTRICT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17722,
      "TXT_FINANCIER_NAME": "AMRAVATI ZILLA MAHILA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17723,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO-OP,BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17724,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA, CHOWK"
    },
    {
      "NUM_FINANCIER_CD": 17725,
      "TXT_FINANCIER_NAME": "PALAKKAD DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17726,
      "TXT_FINANCIER_NAME": "KHALILABAD NAGAR SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17727,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT INDL.CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17728,
      "TXT_FINANCIER_NAME": "THE KODOLI URBAN CO OP BANK LTD KODOLI"
    },
    {
      "NUM_FINANCIER_CD": 17729,
      "TXT_FINANCIER_NAME": "TUMKUR VEERASHAIVA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17730,
      "TXT_FINANCIER_NAME": "THE BHARUCH DISTRICT CENTRAL CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17731,
      "TXT_FINANCIER_NAME": "THE NILAMBUR CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17732,
      "TXT_FINANCIER_NAME": "Samruddhi Co-Operative Bank Ltd.Nagpur."
    },
    {
      "NUM_FINANCIER_CD": 17733,
      "TXT_FINANCIER_NAME": "THE BHARUCH DISTRICT CENTRAL CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17734,
      "TXT_FINANCIER_NAME": "NEYYATTINKARA CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17735,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17736,
      "TXT_FINANCIER_NAME": "SHRI SATYA SAI NAGRIK SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 17737,
      "TXT_FINANCIER_NAME": "THE RAJWADE MANDAL PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17738,
      "TXT_FINANCIER_NAME": "THE KHERALU NAGARIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17739,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17740,
      "TXT_FINANCIER_NAME": "THE AP STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17741,
      "TXT_FINANCIER_NAME": "MAHAVEER CO-OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17742,
      "TXT_FINANCIER_NAME": "COSMOS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17743,
      "TXT_FINANCIER_NAME": "SHREE NAGARI SAHAKARI PATHSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 17744,
      "TXT_FINANCIER_NAME": "THE SEVA VIKAS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17745,
      "TXT_FINANCIER_NAME": "PAVANA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17746,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17747,
      "TXT_FINANCIER_NAME": "JABALPUR MAHILA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17748,
      "TXT_FINANCIER_NAME": "THE CO-OPERATIVE BANK OF RAJKOT LTD."
    },
    {
      "NUM_FINANCIER_CD": 17749,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT CO-OPERATIVE BANK LTD VAZHAKKAD"
    },
    {
      "NUM_FINANCIER_CD": 17750,
      "TXT_FINANCIER_NAME": "UTTRANCHAL STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17751,
      "TXT_FINANCIER_NAME": "THE FEDERAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17752,
      "TXT_FINANCIER_NAME": "THE LAKSHMI VILAS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17753,
      "TXT_FINANCIER_NAME": "RIGHT LEASING &ampHIRE PURCHASE CO. PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 17754,
      "TXT_FINANCIER_NAME": "RIGHT LEASING & HIRE PURCHASE CO.PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17755,
      "TXT_FINANCIER_NAME": "KOZHIKODE DISTRICT CO- OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17756,
      "TXT_FINANCIER_NAME": "PORBANDAR COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17757,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLIAM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 17758,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT.CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17759,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT.CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17760,
      "TXT_FINANCIER_NAME": "ARYAVART GRAMIN BANK LALPALPU HARDOI"
    },
    {
      "NUM_FINANCIER_CD": 17761,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY UNION FINANCE LTD BR PUNE"
    },
    {
      "NUM_FINANCIER_CD": 17762,
      "TXT_FINANCIER_NAME": "CITI CORP MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17763,
      "TXT_FINANCIER_NAME": "Patan Co operative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 17764,
      "TXT_FINANCIER_NAME": "THE STATE TRADING CORPORATION INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17765,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17766,
      "TXT_FINANCIER_NAME": "BHANDARI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 17767,
      "TXT_FINANCIER_NAME": "NANESH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17768,
      "TXT_FINANCIER_NAME": "RELIANCE INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17769,
      "TXT_FINANCIER_NAME": "RAVINDRA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 17770,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLIUM CORLTD"
    },
    {
      "NUM_FINANCIER_CD": 17771,
      "TXT_FINANCIER_NAME": "Konnagar Samabadaya Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17772,
      "TXT_FINANCIER_NAME": "POWER GRID CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17773,
      "TXT_FINANCIER_NAME": "SANMATHI PATTINA SAHAKARA SANGHA NIYAMITHA TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 17774,
      "TXT_FINANCIER_NAME": "THE MULLAKKODI CO OP RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 17775,
      "TXT_FINANCIER_NAME": "MAHESH NAGARI SAHAKARI PAT SANSTHA MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17776,
      "TXT_FINANCIER_NAME": "MHASAI MATA MAHILA PATSANSTHA LTD NIJAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 17777,
      "TXT_FINANCIER_NAME": "PEC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17778,
      "TXT_FINANCIER_NAME": "The Bardez Urban Coop Credit Society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17779,
      "TXT_FINANCIER_NAME": "the namakkal co operative urban bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 17780,
      "TXT_FINANCIER_NAME": "KOTHARI FINANCE CORP CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 17781,
      "TXT_FINANCIER_NAME": "HANAMASAGAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17782,
      "TXT_FINANCIER_NAME": "ZUARI INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17783,
      "TXT_FINANCIER_NAME": "SHREE NARAYAN GRUH COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17784,
      "TXT_FINANCIER_NAME": "CHANDIGARH STATE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17785,
      "TXT_FINANCIER_NAME": "MANVI PATTANA SOUHARDA SAHAKARI BANK NI MANVI"
    },
    {
      "NUM_FINANCIER_CD": 17786,
      "TXT_FINANCIER_NAME": "CANARA BANK RAJPUR ROAD DEHRADUN"
    },
    {
      "NUM_FINANCIER_CD": 17787,
      "TXT_FINANCIER_NAME": "BALAJI URBAN CO OPP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17788,
      "TXT_FINANCIER_NAME": "ANURADHA URBAN CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17789,
      "TXT_FINANCIER_NAME": "THE ALMEL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17790,
      "TXT_FINANCIER_NAME": "REGAL FINANCE AND INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 17791,
      "TXT_FINANCIER_NAME": "THE THANE ZILLA MADHYAWARTI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17792,
      "TXT_FINANCIER_NAME": "PRAVEEN CHORDIA"
    },
    {
      "NUM_FINANCIER_CD": 17793,
      "TXT_FINANCIER_NAME": "KUNNATHUNAD TALUK PRIMARY CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 17794,
      "TXT_FINANCIER_NAME": "BANK OF MAHARASHTRA BRANCH RITHAD"
    },
    {
      "NUM_FINANCIER_CD": 17795,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALLA PARDI PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17796,
      "TXT_FINANCIER_NAME": "SATARA DISTRICT LAWERS CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 17797,
      "TXT_FINANCIER_NAME": "TIRUPATI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17798,
      "TXT_FINANCIER_NAME": "THE VAIJAPUR MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17799,
      "TXT_FINANCIER_NAME": "CENTURION BANK OF PUNJAB LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17800,
      "TXT_FINANCIER_NAME": "SANMATI SAHAKARI BANK LTD ICHALKARANJI"
    },
    {
      "NUM_FINANCIER_CD": 17801,
      "TXT_FINANCIER_NAME": "THE HONAVAR URBAN CO-OPERATIVE BANK LTD., HONAVAR"
    },
    {
      "NUM_FINANCIER_CD": 17802,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17803,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 17804,
      "TXT_FINANCIER_NAME": "THE KARWAR URBAN CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17805,
      "TXT_FINANCIER_NAME": "CHOLAMANDLAM INVESTMENT AND FINANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17806,
      "TXT_FINANCIER_NAME": "THE JANSEVA SAHAKARI BANK (BORIVALI) LTD"
    },
    {
      "NUM_FINANCIER_CD": 17807,
      "TXT_FINANCIER_NAME": "THE MUNICIPAL CO-OP.BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17808,
      "TXT_FINANCIER_NAME": "SHRI SHIVAJI SAHAKARI BANK LIMITED,GADHINGLAJ"
    },
    {
      "NUM_FINANCIER_CD": 17809,
      "TXT_FINANCIER_NAME": "THE GUJARAT INDUSTRIAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17810,
      "TXT_FINANCIER_NAME": "BULDHANA URBAN CO-OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 17811,
      "TXT_FINANCIER_NAME": "VIKRAMADITYA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17812,
      "TXT_FINANCIER_NAME": "PRAGATI MAHILA NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17813,
      "TXT_FINANCIER_NAME": "THE SANGHAMITRACO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13069,
      "TXT_FINANCIER_NAME": "RAMCO CEMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13070,
      "TXT_FINANCIER_NAME": "VIGHNARAJA COOP CREDIT SOCIETYLTD"
    },
    {
      "NUM_FINANCIER_CD": 13071,
      "TXT_FINANCIER_NAME": "HEGDEKATTA GROUP GRAMAGALA SEVA SAHAKARI SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 13072,
      "TXT_FINANCIER_NAME": "SWARNA LAXMI CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13073,
      "TXT_FINANCIER_NAME": "ARULKUMARAN FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13074,
      "TXT_FINANCIER_NAME": "SHRI DURGADEVI NAGARIK SAHAKARI PAT"
    },
    {
      "NUM_FINANCIER_CD": 13075,
      "TXT_FINANCIER_NAME": "SHREE GANPATI AUTO CITY"
    },
    {
      "NUM_FINANCIER_CD": 13076,
      "TXT_FINANCIER_NAME": "SK AUTOMOBILE FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13077,
      "TXT_FINANCIER_NAME": "SATKAR AUTO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 13078,
      "TXT_FINANCIER_NAME": "HIRE WITH TVS CREDIT SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 13079,
      "TXT_FINANCIER_NAME": "PANCHDRAVID BRAMHAN SAHAKARI PATPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 13080,
      "TXT_FINANCIER_NAME": "THE SAHU COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13081,
      "TXT_FINANCIER_NAME": "SHRI DHARMARAJ MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13082,
      "TXT_FINANCIER_NAME": "SUNDERAM FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13083,
      "TXT_FINANCIER_NAME": "KAVITAL PATTINA SAHAKARI SANGHA NI"
    },
    {
      "NUM_FINANCIER_CD": 13084,
      "TXT_FINANCIER_NAME": "SHRI SHIVABHODHARANG URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13085,
      "TXT_FINANCIER_NAME": "ORIX LEASING AND FINANCIAL SERVICES INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13086,
      "TXT_FINANCIER_NAME": "SIRAGALAPALLI P A C C S"
    },
    {
      "NUM_FINANCIER_CD": 13087,
      "TXT_FINANCIER_NAME": "CATHOLIC CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13088,
      "TXT_FINANCIER_NAME": "NARNIMERAKA PACCS"
    },
    {
      "NUM_FINANCIER_CD": 13089,
      "TXT_FINANCIER_NAME": "D J AUTO FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 13090,
      "TXT_FINANCIER_NAME": "M M PATEL AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 13091,
      "TXT_FINANCIER_NAME": "BASAVAJYOTI PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 13092,
      "TXT_FINANCIER_NAME": "MAHARASHTRA RAJYA APANG VIKTA AND VIKAS MAHAMNDAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 13093,
      "TXT_FINANCIER_NAME": "GOURAV AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13094,
      "TXT_FINANCIER_NAME": "SATARA ZILLA NAGRIK SAHAKARI PATHSANTHA MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13095,
      "TXT_FINANCIER_NAME": "M S EXIMP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13096,
      "TXT_FINANCIER_NAME": "LAXMI TRADING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 13097,
      "TXT_FINANCIER_NAME": "N SIVASANKAR"
    },
    {
      "NUM_FINANCIER_CD": 13098,
      "TXT_FINANCIER_NAME": "THE SARSA PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13099,
      "TXT_FINANCIER_NAME": "ODIYOOR SHREE VIVIDHODDESHA SOWHARDHA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 13100,
      "TXT_FINANCIER_NAME": "I F K FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13101,
      "TXT_FINANCIER_NAME": "THE KILAYIKODE SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13102,
      "TXT_FINANCIER_NAME": "BILARA SHAHKARI BHUMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13103,
      "TXT_FINANCIER_NAME": "G K GROUPES"
    },
    {
      "NUM_FINANCIER_CD": 13104,
      "TXT_FINANCIER_NAME": "SRI SRI PEOPLES CREDIT COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13105,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN MAHARAJ CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13106,
      "TXT_FINANCIER_NAME": "SUWICHAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13107,
      "TXT_FINANCIER_NAME": "SHREE VARADHAMAN CREDIT SOUHARD SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13108,
      "TXT_FINANCIER_NAME": "TOTENFINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 13109,
      "TXT_FINANCIER_NAME": "CAPITAL HIND FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 13110,
      "TXT_FINANCIER_NAME": "AJEET KUMAR AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13111,
      "TXT_FINANCIER_NAME": "MOHAN SAI CAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13112,
      "TXT_FINANCIER_NAME": "PANAJE CO OP AGRICULTURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 13113,
      "TXT_FINANCIER_NAME": "THE BAYAR SERVICE COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 13114,
      "TXT_FINANCIER_NAME": "SAI SHANKAR CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 13115,
      "TXT_FINANCIER_NAME": "ANNAPURNA URBAN CREDIT CO OP SOCIETY LTD HINGOLI"
    },
    {
      "NUM_FINANCIER_CD": 13116,
      "TXT_FINANCIER_NAME": "SREE VISHWABANDHU CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13117,
      "TXT_FINANCIER_NAME": "SHRI DANAMMADEVI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13118,
      "TXT_FINANCIER_NAME": "VIJAYAKANT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13119,
      "TXT_FINANCIER_NAME": "SAI RAM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13120,
      "TXT_FINANCIER_NAME": "IDKIDU SERVICE COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13121,
      "TXT_FINANCIER_NAME": "PANDIT NEHARU BHAJIPALA VYAPARI NAGARI SAHAKARI PATASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13122,
      "TXT_FINANCIER_NAME": "SAPTARSHI GRAMIN BIGARSHETI SAHAKARI PATHSANSTH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13123,
      "TXT_FINANCIER_NAME": "THE SUBH LABH CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13124,
      "TXT_FINANCIER_NAME": "JAIN ENTERVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 13125,
      "TXT_FINANCIER_NAME": "PUZHAKKAL BLOCK MOTOR VEHICLE OWNERS MULTI PURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13126,
      "TXT_FINANCIER_NAME": "THE LITTLE KANCHEEPURAM CO OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13127,
      "TXT_FINANCIER_NAME": "RAJA MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13128,
      "TXT_FINANCIER_NAME": "KODAGU DAKSHINA KANNADA GOWDA SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13129,
      "TXT_FINANCIER_NAME": "THRISSUR DIST MOTOR TRANSPORT OWNERS CO OPERATIVE SOCIETY NO 856"
    },
    {
      "NUM_FINANCIER_CD": 13130,
      "TXT_FINANCIER_NAME": "ALETTY PRIMARY AGRICULTURAL CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13131,
      "TXT_FINANCIER_NAME": "THE JAI BHARAT CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13132,
      "TXT_FINANCIER_NAME": "SHREE DURGA DEVI NAGARIK SAHAKARI PATH"
    },
    {
      "NUM_FINANCIER_CD": 13133,
      "TXT_FINANCIER_NAME": "SHRI SANT GAJANAN MAHARAJ SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13134,
      "TXT_FINANCIER_NAME": "SHREE VITHAL RAKHUMAI URBAN CO OPERATIVE CR SCTY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13135,
      "TXT_FINANCIER_NAME": "C S GILL AND CO"
    },
    {
      "NUM_FINANCIER_CD": 13136,
      "TXT_FINANCIER_NAME": "P A C S LTD"
    },
    {
      "NUM_FINANCIER_CD": 13137,
      "TXT_FINANCIER_NAME": "CHELLAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13138,
      "TXT_FINANCIER_NAME": "SHRI BHAVANI GRAMIN BIGAR SHETI SAHAKARI PATHA SAUNSTHAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13139,
      "TXT_FINANCIER_NAME": "HICHAKAD SEVA SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13140,
      "TXT_FINANCIER_NAME": "HOTEL MALIKARA SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13141,
      "TXT_FINANCIER_NAME": "PARAMPARA VIVIDODDESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13142,
      "TXT_FINANCIER_NAME": "G D AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13143,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13144,
      "TXT_FINANCIER_NAME": "TELUGU AKADEMI"
    },
    {
      "NUM_FINANCIER_CD": 13145,
      "TXT_FINANCIER_NAME": "SHRI DUDHALA SEVASAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13146,
      "TXT_FINANCIER_NAME": "HUNGUND TALUKA KANAKADASARA SAUHARDA PATTIN SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 13147,
      "TXT_FINANCIER_NAME": "DIVYA JYOTHI SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13148,
      "TXT_FINANCIER_NAME": "SHRI JANNI KUMBALJAI GR BIG SHETI SAH PATH SANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 13149,
      "TXT_FINANCIER_NAME": "KANJIRAPPALLY CENTRAL SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13150,
      "TXT_FINANCIER_NAME": "INSURANCE REGULATORY AND DEVELOPMENT AUTHORITY"
    },
    {
      "NUM_FINANCIER_CD": 13151,
      "TXT_FINANCIER_NAME": "THE KUDAYATHOOR CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13152,
      "TXT_FINANCIER_NAME": "THE CHIMNA CO OP AGRI MULTIPURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13153,
      "TXT_FINANCIER_NAME": "KRUSHIBANDH AGRO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13154,
      "TXT_FINANCIER_NAME": "MERCES VIVIDH KARYAKARI SEVA SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13155,
      "TXT_FINANCIER_NAME": "THE LDH CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13156,
      "TXT_FINANCIER_NAME": "PRIHA FINLEASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13157,
      "TXT_FINANCIER_NAME": "DIRECTOR TBRL"
    },
    {
      "NUM_FINANCIER_CD": 13158,
      "TXT_FINANCIER_NAME": "NINAD NAGARI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13159,
      "TXT_FINANCIER_NAME": "C S A MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 13160,
      "TXT_FINANCIER_NAME": "ADISHAKTHI SOUHARDA SAHAKARI NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 13161,
      "TXT_FINANCIER_NAME": "YUVRAAJ ENTERPISES"
    },
    {
      "NUM_FINANCIER_CD": 13162,
      "TXT_FINANCIER_NAME": "AP GENCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13163,
      "TXT_FINANCIER_NAME": "SRI PON KUMARAN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 13164,
      "TXT_FINANCIER_NAME": "SHREE ASTAGAM VIBHAG SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13165,
      "TXT_FINANCIER_NAME": "HELP FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14561,
      "TXT_FINANCIER_NAME": "THE DOMBARI MULTIPURPOSE CO OP SOCIETY LMT"
    },
    {
      "NUM_FINANCIER_CD": 14562,
      "TXT_FINANCIER_NAME": "SHRI MALEKARANI CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14563,
      "TXT_FINANCIER_NAME": "AMMEMBALA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14564,
      "TXT_FINANCIER_NAME": "PUNACHA PRATHAMIKA VYAVASAYA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14565,
      "TXT_FINANCIER_NAME": "KONNUR URBAN MULTI PURPOSE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14566,
      "TXT_FINANCIER_NAME": "JINDAL POWER LTD"
    },
    {
      "NUM_FINANCIER_CD": 14567,
      "TXT_FINANCIER_NAME": "JAI HANUMAN VIVIDODDESHAGALA SOU SAH NYT"
    },
    {
      "NUM_FINANCIER_CD": 14568,
      "TXT_FINANCIER_NAME": "SHREE LAXMINARAYAN URBAN CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 14569,
      "TXT_FINANCIER_NAME": "SHREE SAI SAMARTH NAGRI SAHKARI PATHSANSTHA NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 14570,
      "TXT_FINANCIER_NAME": "GRAMEENA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 14571,
      "TXT_FINANCIER_NAME": "JACKSON CO OP CREDIT SO OF THE EMPL OF THE W RAIL LTD"
    },
    {
      "NUM_FINANCIER_CD": 14572,
      "TXT_FINANCIER_NAME": "SONYAMARUTI NAGARI SAH PATH SANSTHA MARYADIT KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 14573,
      "TXT_FINANCIER_NAME": "SRIRAMANAGAR PATTANA SAHAKAR BANK NI"
    },
    {
      "NUM_FINANCIER_CD": 14574,
      "TXT_FINANCIER_NAME": "GREATER BANK SCHEDULED BANK"
    },
    {
      "NUM_FINANCIER_CD": 14575,
      "TXT_FINANCIER_NAME": "BAJWA URBAN CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 14576,
      "TXT_FINANCIER_NAME": "SECRETARIAT OF THE KERALA LEGISLATURE"
    },
    {
      "NUM_FINANCIER_CD": 14577,
      "TXT_FINANCIER_NAME": "FORMERLY KNOWN AS ITNL ENSO RAIL SYSTEMS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14578,
      "TXT_FINANCIER_NAME": "THE PONANI CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14579,
      "TXT_FINANCIER_NAME": "RAJASHREE SHAHU NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14580,
      "TXT_FINANCIER_NAME": "LOKMANYA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14581,
      "TXT_FINANCIER_NAME": "KERALA GRAMMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14582,
      "TXT_FINANCIER_NAME": "THE URALUNGAL SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14583,
      "TXT_FINANCIER_NAME": "SAMPAJE VSS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14584,
      "TXT_FINANCIER_NAME": "BAJIRAOAPPA SAHAKARI BANK LTD ANKALKHOP"
    },
    {
      "NUM_FINANCIER_CD": 14585,
      "TXT_FINANCIER_NAME": "HDB FINANCE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 14586,
      "TXT_FINANCIER_NAME": "MA JAGDAMBA NAGRI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14587,
      "TXT_FINANCIER_NAME": "KESHAV MADHAV NAGARI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14588,
      "TXT_FINANCIER_NAME": "VARADA VINAYAKA CREDIT SAHAKARA SANGA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14589,
      "TXT_FINANCIER_NAME": "KRANTI SENA MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14590,
      "TXT_FINANCIER_NAME": "AGADI PRATHAMIKA KRISHI PATTIN SAHAKARI SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14591,
      "TXT_FINANCIER_NAME": "PRAVARA CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14592,
      "TXT_FINANCIER_NAME": "NEW VIDYA AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14593,
      "TXT_FINANCIER_NAME": "FARAH URBAN CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14594,
      "TXT_FINANCIER_NAME": "THE SUPERINTENDENT OF POLICE"
    },
    {
      "NUM_FINANCIER_CD": 14595,
      "TXT_FINANCIER_NAME": "GANESH FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14596,
      "TXT_FINANCIER_NAME": "PB FINGEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14597,
      "TXT_FINANCIER_NAME": "SHREE AKSHYA CREDIT SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14598,
      "TXT_FINANCIER_NAME": "MAHATMA JYOTIBA PHULE GRAMIN BIG SHETI SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14599,
      "TXT_FINANCIER_NAME": "ULI SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 14600,
      "TXT_FINANCIER_NAME": "JAIBHAWANI GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14601,
      "TXT_FINANCIER_NAME": "BANTIA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14602,
      "TXT_FINANCIER_NAME": "AMBIKA URBAN CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14603,
      "TXT_FINANCIER_NAME": "BOLAR SOWHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14604,
      "TXT_FINANCIER_NAME": "THE NELLYADY SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14605,
      "TXT_FINANCIER_NAME": "KARYASIDDI BAHOUDDESHA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14606,
      "TXT_FINANCIER_NAME": "NAVAMAITRI MULTIPUPOSE SOUHARDHA CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14607,
      "TXT_FINANCIER_NAME": "D S ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14608,
      "TXT_FINANCIER_NAME": "CHARAK VIVIDHODEHGAL SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14609,
      "TXT_FINANCIER_NAME": "SHRI SUYOG GRAMIN SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14610,
      "TXT_FINANCIER_NAME": "YV LANKA PACC SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14611,
      "TXT_FINANCIER_NAME": "KAMDHENU MAHILA MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14612,
      "TXT_FINANCIER_NAME": "THE GUNA NAGRIK SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14613,
      "TXT_FINANCIER_NAME": "KARMALI CORLIM MULTIPURPOSE CO OP"
    },
    {
      "NUM_FINANCIER_CD": 14614,
      "TXT_FINANCIER_NAME": "THE PALLI BANAL CO OPERATIVE AGRICULTURE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14615,
      "TXT_FINANCIER_NAME": "PT B D SHARMA UNIVERSITY OF HEALTH SCIENCE ROHTAK"
    },
    {
      "NUM_FINANCIER_CD": 14616,
      "TXT_FINANCIER_NAME": "SHRI VEERABHADRA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14617,
      "TXT_FINANCIER_NAME": "TULJABHAVANI URBAN MULTISTATE CO OP CREDIT SOCIETY LTD MAJALGAON"
    },
    {
      "NUM_FINANCIER_CD": 14618,
      "TXT_FINANCIER_NAME": "SARVODAY SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14619,
      "TXT_FINANCIER_NAME": "SHANTINIKETAN PATTINA SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14620,
      "TXT_FINANCIER_NAME": "SAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14621,
      "TXT_FINANCIER_NAME": "MURUD TALUKA MARCHANT CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14622,
      "TXT_FINANCIER_NAME": "PAWAN HANS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14623,
      "TXT_FINANCIER_NAME": "DHANASHRI MULTI STATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14624,
      "TXT_FINANCIER_NAME": "BELGAUM GRAMEEN MAHILA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14625,
      "TXT_FINANCIER_NAME": "AJINKYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14626,
      "TXT_FINANCIER_NAME": "VEERA MACHIDEVA VIVIDODESH SHAHAKARI SHAHAKARI SHANGHA N"
    },
    {
      "NUM_FINANCIER_CD": 14627,
      "TXT_FINANCIER_NAME": "SRI PRIYANKA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 14628,
      "TXT_FINANCIER_NAME": "SATARA ZILLA NAGRIK SAHAKARI PATHPEDHI MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14629,
      "TXT_FINANCIER_NAME": "JANASAHAKARYA SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14630,
      "TXT_FINANCIER_NAME": "SIRA VASAVI SOUHARDA PATTINA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14631,
      "TXT_FINANCIER_NAME": "SUMANTHA PATTINA SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14632,
      "TXT_FINANCIER_NAME": "KINNIGOLI VYAVASAYA SEVA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 14633,
      "TXT_FINANCIER_NAME": "VENKATA ANJANEYA AUTO AND EMPLOYEES FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14634,
      "TXT_FINANCIER_NAME": "SHRI SANMATISAGAR MULTI PURPOSE SOUHARD NYT"
    },
    {
      "NUM_FINANCIER_CD": 14635,
      "TXT_FINANCIER_NAME": "MARUTI SAHAKARI PATTINA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 14636,
      "TXT_FINANCIER_NAME": "AJINKYATARA GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14637,
      "TXT_FINANCIER_NAME": "W B S C AND S T DEVELOPMENT AND FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14638,
      "TXT_FINANCIER_NAME": "M P INVESTMENT FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 14639,
      "TXT_FINANCIER_NAME": "SRI VITTAL SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14640,
      "TXT_FINANCIER_NAME": "THE SADASHIV BACHAT AND DIRAN SHAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14641,
      "TXT_FINANCIER_NAME": "BHOLENATH LEASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14642,
      "TXT_FINANCIER_NAME": "DEEPAM INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14643,
      "TXT_FINANCIER_NAME": "SRI VITTAL SOUHARDA CREDIT CO OPERTIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14644,
      "TXT_FINANCIER_NAME": "DHOSTHI HIRE PURCHASE AND LOAN"
    },
    {
      "NUM_FINANCIER_CD": 14645,
      "TXT_FINANCIER_NAME": "PARIVAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14646,
      "TXT_FINANCIER_NAME": "URBAN WELFARE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14647,
      "TXT_FINANCIER_NAME": "SAADHU SANGAMA PATHINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14648,
      "TXT_FINANCIER_NAME": "THE JAMKHANDI CO OP CREDIT SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14649,
      "TXT_FINANCIER_NAME": "SHRI SHANTESHWAR MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14650,
      "TXT_FINANCIER_NAME": "OSWAL FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14651,
      "TXT_FINANCIER_NAME": "N S MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 14652,
      "TXT_FINANCIER_NAME": "JC INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 16291,
      "TXT_FINANCIER_NAME": "NORTH LINK FISCAL & CAPITAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16292,
      "TXT_FINANCIER_NAME": "NORTH RIDGE FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16293,
      "TXT_FINANCIER_NAME": "NORTHPOLE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16294,
      "TXT_FINANCIER_NAME": "NPR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16295,
      "TXT_FINANCIER_NAME": "OM SHRIDEVI FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 16296,
      "TXT_FINANCIER_NAME": "OVERSEAS SANMAR FINANCIAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 16297,
      "TXT_FINANCIER_NAME": "P H F LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 16298,
      "TXT_FINANCIER_NAME": "P K F FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16299,
      "TXT_FINANCIER_NAME": "P P HOLDINGS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16300,
      "TXT_FINANCIER_NAME": "PADMAVATI FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16301,
      "TXT_FINANCIER_NAME": "PAL FINLEASEVEST (INDIA) LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16302,
      "TXT_FINANCIER_NAME": "PANAJI FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16303,
      "TXT_FINANCIER_NAME": "PARV FINANCIERS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16304,
      "TXT_FINANCIER_NAME": "PEEJAY FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 16305,
      "TXT_FINANCIER_NAME": "PHF HIRE PURCHASE PVT.LTD"
    },
    {
      "NUM_FINANCIER_CD": 16306,
      "TXT_FINANCIER_NAME": "PHF INVESTMENT PVT.LTD"
    },
    {
      "NUM_FINANCIER_CD": 16307,
      "TXT_FINANCIER_NAME": "PINNACLE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16308,
      "TXT_FINANCIER_NAME": "PITAM PURA LEASING & HOUSING FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16309,
      "TXT_FINANCIER_NAME": "POLYSPIN INVESTMENTS & FINANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16310,
      "TXT_FINANCIER_NAME": "PONDICHERRY INDUSTRIAL PROMOTION DEVELOPMENT & INVESTMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16311,
      "TXT_FINANCIER_NAME": "PREM HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16312,
      "TXT_FINANCIER_NAME": "PRIVILEGE FINANCE & LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 16313,
      "TXT_FINANCIER_NAME": "PROFESSIONAL INVESTMENT & SECURITIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16314,
      "TXT_FINANCIER_NAME": "PROFICIENT LEASING & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16315,
      "TXT_FINANCIER_NAME": "PUNJAB HARYANA AUTO FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16316,
      "TXT_FINANCIER_NAME": "PUNJAB KASHMIR FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16317,
      "TXT_FINANCIER_NAME": "PUNJAB LEASING PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16318,
      "TXT_FINANCIER_NAME": "PUNJAB RELIABLE INVESTMENTS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16319,
      "TXT_FINANCIER_NAME": "RAJALAKSHMI FINANCE AND SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16320,
      "TXT_FINANCIER_NAME": "RAJATH FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16321,
      "TXT_FINANCIER_NAME": "RAJINDRA HIRE PURCHASE AND LEASING CO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 16322,
      "TXT_FINANCIER_NAME": "RAJOURI HIRE PURCHASE & FINANCE (P)LTD."
    },
    {
      "NUM_FINANCIER_CD": 16323,
      "TXT_FINANCIER_NAME": "RAKSHIT MOTOR & GENERAL FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16324,
      "TXT_FINANCIER_NAME": "RAMIR FINANCE & LEASING CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16325,
      "TXT_FINANCIER_NAME": "RANGUDYAN TRADING & INVESTMENT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 16326,
      "TXT_FINANCIER_NAME": "CITY UNION BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16327,
      "TXT_FINANCIER_NAME": "THE CITY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16328,
      "TXT_FINANCIER_NAME": "THE MALKAPUR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16329,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16330,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16331,
      "TXT_FINANCIER_NAME": "THE SURAT MERCANTILE CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16332,
      "TXT_FINANCIER_NAME": "AIR CORPORATIONS EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16333,
      "TXT_FINANCIER_NAME": "THE SURAT DISTRICT CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16334,
      "TXT_FINANCIER_NAME": "CHENNAI CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16335,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT INDL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16336,
      "TXT_FINANCIER_NAME": "NAGRIK SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16337,
      "TXT_FINANCIER_NAME": "THE SURAT TEXTILE TRADERS CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16338,
      "TXT_FINANCIER_NAME": "NUTAN NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16339,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SER LTD."
    },
    {
      "NUM_FINANCIER_CD": 16340,
      "TXT_FINANCIER_NAME": "JANSEWA URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16341,
      "TXT_FINANCIER_NAME": "THE PREMIER CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16342,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGARIK CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16343,
      "TXT_FINANCIER_NAME": "THE CO OPERATIVE BANK OF RAJKOT"
    },
    {
      "NUM_FINANCIER_CD": 16344,
      "TXT_FINANCIER_NAME": "AMERICAN EXPRESS BANK"
    },
    {
      "NUM_FINANCIER_CD": 16345,
      "TXT_FINANCIER_NAME": "INDUSIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 16346,
      "TXT_FINANCIER_NAME": "THE MARATHA MANDIR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16347,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MERCANTILE CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16348,
      "TXT_FINANCIER_NAME": "IFFCO"
    },
    {
      "NUM_FINANCIER_CD": 16349,
      "TXT_FINANCIER_NAME": "GUJARAT STATE CIVIL SUPPLIES CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 16350,
      "TXT_FINANCIER_NAME": "THE KANGRA CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16351,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16352,
      "TXT_FINANCIER_NAME": "KRIBHCO"
    },
    {
      "NUM_FINANCIER_CD": 16353,
      "TXT_FINANCIER_NAME": "THE GOA STATE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16354,
      "TXT_FINANCIER_NAME": "VIDHARBHA MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16355,
      "TXT_FINANCIER_NAME": "MAGMA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16356,
      "TXT_FINANCIER_NAME": "ERNAKULAM DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16357,
      "TXT_FINANCIER_NAME": "KOCHI REFINERIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16358,
      "TXT_FINANCIER_NAME": "THE VERAVAL MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16359,
      "TXT_FINANCIER_NAME": "Indore Paraspar Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16360,
      "TXT_FINANCIER_NAME": "THE CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16361,
      "TXT_FINANCIER_NAME": "THE BANGALORE CITY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16362,
      "TXT_FINANCIER_NAME": "THE AMMAPET URBAN CO-OP BANK LTD,SALEM"
    },
    {
      "NUM_FINANCIER_CD": 16363,
      "TXT_FINANCIER_NAME": "NUTAN NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16364,
      "TXT_FINANCIER_NAME": "COLOUR MERCHANTS CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16365,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16366,
      "TXT_FINANCIER_NAME": "GUARDIAN SOUHARDA SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 16367,
      "TXT_FINANCIER_NAME": "DHARMAVIR SAMBHAJI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16368,
      "TXT_FINANCIER_NAME": "BASSEIN CATHOLIC CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16369,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16370,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16371,
      "TXT_FINANCIER_NAME": "THE ALWAYE URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16372,
      "TXT_FINANCIER_NAME": "THE PANCHSHEEL MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16373,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 16374,
      "TXT_FINANCIER_NAME": "BHAGINI NIVEDITA SAHAKARI BANK LTD.,PUNE"
    },
    {
      "NUM_FINANCIER_CD": 16375,
      "TXT_FINANCIER_NAME": "HAVELI SAHAKARI BANK LTD. MOSHI ( PIMPRI-CHINCHWAD_"
    },
    {
      "NUM_FINANCIER_CD": 16376,
      "TXT_FINANCIER_NAME": "The Ahmedabad District Co Op Bank"
    },
    {
      "NUM_FINANCIER_CD": 16377,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16378,
      "TXT_FINANCIER_NAME": "THE JUNAGADH COMMERCIAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16379,
      "TXT_FINANCIER_NAME": "JUNAGADH VIBHAGIYA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16380,
      "TXT_FINANCIER_NAME": "SOUTH MALABAR GRAMINE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16381,
      "TXT_FINANCIER_NAME": "THE ASSOCIATE FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16382,
      "TXT_FINANCIER_NAME": "THE SHIBPUR CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16383,
      "TXT_FINANCIER_NAME": "THE YEOLA MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16384,
      "TXT_FINANCIER_NAME": "UNNATI CO OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 16385,
      "TXT_FINANCIER_NAME": "ZWIRN PRAGATI CAPFIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16386,
      "TXT_FINANCIER_NAME": "INDORE CLOTH MARKET CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16387,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 16388,
      "TXT_FINANCIER_NAME": "MEENACHIL EAST URBAN CO OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11666,
      "TXT_FINANCIER_NAME": "SAI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11667,
      "TXT_FINANCIER_NAME": "BHARANI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11668,
      "TXT_FINANCIER_NAME": "JAY AJEY INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11669,
      "TXT_FINANCIER_NAME": "S M L FINACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11670,
      "TXT_FINANCIER_NAME": "WARDHA ARBAN COOPRATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11671,
      "TXT_FINANCIER_NAME": "PAM PAC MACHINES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11672,
      "TXT_FINANCIER_NAME": "POHEGAON NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11673,
      "TXT_FINANCIER_NAME": "ABG SHIPYARD LTD"
    },
    {
      "NUM_FINANCIER_CD": 11674,
      "TXT_FINANCIER_NAME": "SRI JAI SURYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11675,
      "TXT_FINANCIER_NAME": "NEW SATNAM FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 11676,
      "TXT_FINANCIER_NAME": "THE NALUNNAKKAL SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11677,
      "TXT_FINANCIER_NAME": "SMT MADHU B KUMBATH FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11678,
      "TXT_FINANCIER_NAME": "MAGANESE ORE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11679,
      "TXT_FINANCIER_NAME": "HP SC ST DEVELOPMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 11680,
      "TXT_FINANCIER_NAME": "PREMIUM FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11681,
      "TXT_FINANCIER_NAME": "AMRUT NAGARI SAHAKARI PATSANSTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11682,
      "TXT_FINANCIER_NAME": "THE GOPINATHPATIL JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11683,
      "TXT_FINANCIER_NAME": "GURUKRUPA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11684,
      "TXT_FINANCIER_NAME": "SHUBHAN KAROTI GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11685,
      "TXT_FINANCIER_NAME": "SADASHIVRAO MOGAL GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11686,
      "TXT_FINANCIER_NAME": "VINAYAK NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11687,
      "TXT_FINANCIER_NAME": "RITHARA SEVA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11688,
      "TXT_FINANCIER_NAME": "SRISUDHA CO OPERTIVEBANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11689,
      "TXT_FINANCIER_NAME": "THE MANEKPORE V V K S MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11690,
      "TXT_FINANCIER_NAME": "S C CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 11691,
      "TXT_FINANCIER_NAME": "KAPURHOL VIKAS SEVA SAHAKARI SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11692,
      "TXT_FINANCIER_NAME": "MAN MEDICAL NAGRI SHA PATS"
    },
    {
      "NUM_FINANCIER_CD": 11693,
      "TXT_FINANCIER_NAME": "LAKSHMI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11694,
      "TXT_FINANCIER_NAME": "VISHALDEEP SAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11695,
      "TXT_FINANCIER_NAME": "REEN HIRE PURCHASE FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11696,
      "TXT_FINANCIER_NAME": "NILKANTHESHWAR NAG SAH PAT SAN"
    },
    {
      "NUM_FINANCIER_CD": 11697,
      "TXT_FINANCIER_NAME": "K D ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 11698,
      "TXT_FINANCIER_NAME": "ARUL JOTHI INVESMENT"
    },
    {
      "NUM_FINANCIER_CD": 11699,
      "TXT_FINANCIER_NAME": "THE BEDADKA FARMERS SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11700,
      "TXT_FINANCIER_NAME": "VISHWAKARMA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11701,
      "TXT_FINANCIER_NAME": "THE LAXMIPURA GROUP SEVA SAH MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11702,
      "TXT_FINANCIER_NAME": "HIRE P M ANAND KUMAR BAGMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11703,
      "TXT_FINANCIER_NAME": "SUNRISE SOUHARDA PATTINA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 11704,
      "TXT_FINANCIER_NAME": "BARKATH ALPA SANKYATHARA VIVIDODDESHA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11705,
      "TXT_FINANCIER_NAME": "DARSHAN MOTERS"
    },
    {
      "NUM_FINANCIER_CD": 11706,
      "TXT_FINANCIER_NAME": "DNMOTORS"
    },
    {
      "NUM_FINANCIER_CD": 11707,
      "TXT_FINANCIER_NAME": "DREAMZ ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 11708,
      "TXT_FINANCIER_NAME": "PITAMBAR TRADING CO"
    },
    {
      "NUM_FINANCIER_CD": 11709,
      "TXT_FINANCIER_NAME": "KIRPEKAR ENGG PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11710,
      "TXT_FINANCIER_NAME": "SANATHALI JUTH SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11711,
      "TXT_FINANCIER_NAME": "FORTPOINT AUTOMOTIVE CARS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11712,
      "TXT_FINANCIER_NAME": "SHREE LAXMIVAS SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11713,
      "TXT_FINANCIER_NAME": "STEWART HOLL INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11714,
      "TXT_FINANCIER_NAME": "P JAMBOOKUMAR JAIN6 CHANDRAPPAMUDALI STREET"
    },
    {
      "NUM_FINANCIER_CD": 11715,
      "TXT_FINANCIER_NAME": "IGGUTSAPPA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11716,
      "TXT_FINANCIER_NAME": "SHREE PORWAL SAHAKARI SAKH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11717,
      "TXT_FINANCIER_NAME": "GOVERNAMENT OF ANDHRA PRADESH DEPARTMENT OF HANDLOOMS AND TEXTILES"
    },
    {
      "NUM_FINANCIER_CD": 11718,
      "TXT_FINANCIER_NAME": "STEWART HOLL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11719,
      "TXT_FINANCIER_NAME": "GIRNAR INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 11720,
      "TXT_FINANCIER_NAME": "DEPARTMENT OF HANDLOOM AND TEXTILES GOVT OF KARNATAKA"
    },
    {
      "NUM_FINANCIER_CD": 11721,
      "TXT_FINANCIER_NAME": "NATIONAL CENT AND ANT OCEAN RESEC"
    },
    {
      "NUM_FINANCIER_CD": 11722,
      "TXT_FINANCIER_NAME": "PUSHP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11723,
      "TXT_FINANCIER_NAME": "TOTAL PETROLEUM INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11724,
      "TXT_FINANCIER_NAME": "THODUPUZHA TALUK CO OP EMP CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11725,
      "TXT_FINANCIER_NAME": "PAAVAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11726,
      "TXT_FINANCIER_NAME": "THE SIRSA CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11727,
      "TXT_FINANCIER_NAME": "JINENDRA TRADING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11728,
      "TXT_FINANCIER_NAME": "VITTAL SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 11729,
      "TXT_FINANCIER_NAME": "VINODH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11730,
      "TXT_FINANCIER_NAME": "SANSAD MARG HEAD POST OFFICE"
    },
    {
      "NUM_FINANCIER_CD": 11731,
      "TXT_FINANCIER_NAME": "KALOL TALUKA PRIMARY SCHOOL TEACHERS FINANCE CO OP SCOIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11732,
      "TXT_FINANCIER_NAME": "JAI MALHAR GRAMIN BIGARSHETI SAHAKARI PATHASANSTHA MARYADIT JEJURI"
    },
    {
      "NUM_FINANCIER_CD": 11733,
      "TXT_FINANCIER_NAME": "DYAN SAVERDHINI GRAMIN BIGAR SETI PATH SASTHA"
    },
    {
      "NUM_FINANCIER_CD": 11734,
      "TXT_FINANCIER_NAME": "THE SHIMOGA DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11735,
      "TXT_FINANCIER_NAME": "PAPPINIVATTOM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11736,
      "TXT_FINANCIER_NAME": "ARUNODAY NAGARI SAH PATH MARY"
    },
    {
      "NUM_FINANCIER_CD": 11737,
      "TXT_FINANCIER_NAME": "AJARE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11738,
      "TXT_FINANCIER_NAME": "SHRI DOLASNATH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11739,
      "TXT_FINANCIER_NAME": "SREE NARMATHA CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 11740,
      "TXT_FINANCIER_NAME": "TRINETRA RAGHVENDRA CONSTRATION LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11741,
      "TXT_FINANCIER_NAME": "SECRETARY LALIT KALA AKADEMI"
    },
    {
      "NUM_FINANCIER_CD": 11742,
      "TXT_FINANCIER_NAME": "SHRI SHRIJI CO OP CREDIT AND SUPPLY SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11743,
      "TXT_FINANCIER_NAME": "A P STATE FILM TELEVISION AND THEATRE DEVELOPMENT CORPN LTD"
    },
    {
      "NUM_FINANCIER_CD": 11744,
      "TXT_FINANCIER_NAME": "SHIV SHAKTI CO OPERATIV CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11745,
      "TXT_FINANCIER_NAME": "THE TANALUR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11746,
      "TXT_FINANCIER_NAME": "LONAWALA SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11747,
      "TXT_FINANCIER_NAME": "EKTANAGAR GRAMIN BIGAR SHETI SAHAKARI PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 11748,
      "TXT_FINANCIER_NAME": "SWAMI SWARUPANAND SAH PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11749,
      "TXT_FINANCIER_NAME": "JAGRATI NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11750,
      "TXT_FINANCIER_NAME": "BRAMHADAS GRAMIN BIG SHETI SAH PAT SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 11751,
      "TXT_FINANCIER_NAME": "GAURINANDAN NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11752,
      "TXT_FINANCIER_NAME": "YASHWANT BHAU PATIL GRAMIN BIGARSHETI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 11753,
      "TXT_FINANCIER_NAME": "PATTINA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 11754,
      "TXT_FINANCIER_NAME": "SOORYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11755,
      "TXT_FINANCIER_NAME": "VINITA KATARIYA"
    },
    {
      "NUM_FINANCIER_CD": 11756,
      "TXT_FINANCIER_NAME": "VETTIKKATTIRI SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11757,
      "TXT_FINANCIER_NAME": "DHARMVEER SAMBHAJI CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11758,
      "TXT_FINANCIER_NAME": "DEVENDRANATH NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11759,
      "TXT_FINANCIER_NAME": "PON VASAN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11760,
      "TXT_FINANCIER_NAME": "THE ERIMAYUR SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11761,
      "TXT_FINANCIER_NAME": "ARASI CREDIT INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 11762,
      "TXT_FINANCIER_NAME": "THE DEHGAM NAGARIK SAHKARI SARAFI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11763,
      "TXT_FINANCIER_NAME": "CITI POST"
    },
    {
      "NUM_FINANCIER_CD": 13166,
      "TXT_FINANCIER_NAME": "THE HUKKERI MOULTIPURPOSE CO OP CRIDIT SOCITY"
    },
    {
      "NUM_FINANCIER_CD": 13167,
      "TXT_FINANCIER_NAME": "NAVABPALEM L S C S LTD"
    },
    {
      "NUM_FINANCIER_CD": 13168,
      "TXT_FINANCIER_NAME": "THE PALAKODERU LARGE SIZED CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13169,
      "TXT_FINANCIER_NAME": "THE NAVABPALEM LARGE SIZED CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13170,
      "TXT_FINANCIER_NAME": "MULA PRAVARA NAGRI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13171,
      "TXT_FINANCIER_NAME": "CHOUNDESHWARI GRAMIN BIG SHETI SAH PATS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13172,
      "TXT_FINANCIER_NAME": "PRAVEEN CREDIT SOUHARDA CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13173,
      "TXT_FINANCIER_NAME": "SHREE SIDDHA BHAIRAVNATH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13174,
      "TXT_FINANCIER_NAME": "SHIVNERI GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13175,
      "TXT_FINANCIER_NAME": "BHIMADOLE FARMERS SERVICE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13176,
      "TXT_FINANCIER_NAME": "S S SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 13177,
      "TXT_FINANCIER_NAME": "YAMAKANMARDI R C VIVID UDDESHGAL SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13178,
      "TXT_FINANCIER_NAME": "PRAKASH HP SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 13179,
      "TXT_FINANCIER_NAME": "KUMBDAJE SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13180,
      "TXT_FINANCIER_NAME": "TUNGABHADRA PATTINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13181,
      "TXT_FINANCIER_NAME": "THE AURANGABD DISTRICT INDUSTRIAL AND URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13182,
      "TXT_FINANCIER_NAME": "BHIRAVNATH SAHAKARI PATHSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13183,
      "TXT_FINANCIER_NAME": "VADANAPPALLY KARSHAKA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13184,
      "TXT_FINANCIER_NAME": "SOMESHWAR GRAMIN BIGERSHETI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13185,
      "TXT_FINANCIER_NAME": "SOMESHWAR GRAMIN BIGERSHETI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13186,
      "TXT_FINANCIER_NAME": "SWARA MAHILA NAGARI SAHAKARI PATSANSTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13187,
      "TXT_FINANCIER_NAME": "PAISA FUND GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13188,
      "TXT_FINANCIER_NAME": "VADIVARHE VIVIDH KARYAKARI SEV"
    },
    {
      "NUM_FINANCIER_CD": 13189,
      "TXT_FINANCIER_NAME": "INDORE LOHA VYAPARI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13190,
      "TXT_FINANCIER_NAME": "SHREE SHEKH PIPARIYA SEVE SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13191,
      "TXT_FINANCIER_NAME": "GUJARAT INSECTICIDES LTD"
    },
    {
      "NUM_FINANCIER_CD": 13192,
      "TXT_FINANCIER_NAME": "AKSHAY SAKH SAHKARITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13193,
      "TXT_FINANCIER_NAME": "NISHANT MULTI STATE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13194,
      "TXT_FINANCIER_NAME": "P A C SOCIETY NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13195,
      "TXT_FINANCIER_NAME": "PRERNA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13196,
      "TXT_FINANCIER_NAME": "BHAGYESHRI NAGARI SAHAKARI PATHSANSTHA MARYADIT NAVARGAON"
    },
    {
      "NUM_FINANCIER_CD": 13197,
      "TXT_FINANCIER_NAME": "J SURESH CHAND BOKDIA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 13198,
      "TXT_FINANCIER_NAME": "THE SECRETARY TO GOVERNMENT LAW DEPT"
    },
    {
      "NUM_FINANCIER_CD": 13199,
      "TXT_FINANCIER_NAME": "UTTARAKHAND BAHUUDASHYE VITT NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 13200,
      "TXT_FINANCIER_NAME": "THE GOVERNOR OF TAMILNADU"
    },
    {
      "NUM_FINANCIER_CD": 13201,
      "TXT_FINANCIER_NAME": "SSK PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13202,
      "TXT_FINANCIER_NAME": "SSS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13203,
      "TXT_FINANCIER_NAME": "LESSOR FORTPOINT AUTOMOTIVE CARS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13204,
      "TXT_FINANCIER_NAME": "J P FINANCE AND LEASING CO"
    },
    {
      "NUM_FINANCIER_CD": 13205,
      "TXT_FINANCIER_NAME": "SRI PADMAVATI MAHILA VISVAVIDYALAYAM"
    },
    {
      "NUM_FINANCIER_CD": 13206,
      "TXT_FINANCIER_NAME": "THE ANGADIMOGAR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13207,
      "TXT_FINANCIER_NAME": "YEDAPADAVU VYAVASAYA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13208,
      "TXT_FINANCIER_NAME": "SHREE GURU BASAVA LEASING R"
    },
    {
      "NUM_FINANCIER_CD": 13209,
      "TXT_FINANCIER_NAME": "KARMAVEER GRAMIN BIGER SHETI SAHAKARI PATHSANSHTA"
    },
    {
      "NUM_FINANCIER_CD": 13210,
      "TXT_FINANCIER_NAME": "JANLAXMI GRAMIN BIGAR SHETI SAHAKARI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 13211,
      "TXT_FINANCIER_NAME": "VIJAY AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13212,
      "TXT_FINANCIER_NAME": "AARKAY FIN LEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13213,
      "TXT_FINANCIER_NAME": "SAUNDATTI URBAN COOP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13214,
      "TXT_FINANCIER_NAME": "CHAITHANYA MAHILA SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 13215,
      "TXT_FINANCIER_NAME": "COMOS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13216,
      "TXT_FINANCIER_NAME": "ARYAPU SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13217,
      "TXT_FINANCIER_NAME": "VENGANOOR CO OPERATIVE RURAL DEVELOPMENT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13218,
      "TXT_FINANCIER_NAME": "SRI NAGARESHWARA SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13219,
      "TXT_FINANCIER_NAME": "DENSO SALES INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13220,
      "TXT_FINANCIER_NAME": "PRINCIPAL DISTRICT AND SESSIONS JUDGE"
    },
    {
      "NUM_FINANCIER_CD": 13221,
      "TXT_FINANCIER_NAME": "PRAGATI KRISHNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 13222,
      "TXT_FINANCIER_NAME": "MEHER GARAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13223,
      "TXT_FINANCIER_NAME": "VEERASHAIVA SOUHARDHA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13224,
      "TXT_FINANCIER_NAME": "TIRUR MOTOR TRANSPORT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13225,
      "TXT_FINANCIER_NAME": "THE TAMBARAM CO OP URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13226,
      "TXT_FINANCIER_NAME": "STARLINE ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 13227,
      "TXT_FINANCIER_NAME": "SHRI SHIVASHAKTI MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13228,
      "TXT_FINANCIER_NAME": "UMATA NAGARIK SAHKARI DHIRAN MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13229,
      "TXT_FINANCIER_NAME": "SADVIJAI CHITS AND FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13230,
      "TXT_FINANCIER_NAME": "MS HARITWAL AUTO MOBILES"
    },
    {
      "NUM_FINANCIER_CD": 13231,
      "TXT_FINANCIER_NAME": "AMAN FINANCE CO REGD"
    },
    {
      "NUM_FINANCIER_CD": 13232,
      "TXT_FINANCIER_NAME": "GANGA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13233,
      "TXT_FINANCIER_NAME": "BLUE CROSS LABS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13234,
      "TXT_FINANCIER_NAME": "THE HARBROL COORPRATIVE AGRICULTURE SERVICE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13235,
      "TXT_FINANCIER_NAME": "AMALI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13236,
      "TXT_FINANCIER_NAME": "BANGALORE TRUF CLUB LTD"
    },
    {
      "NUM_FINANCIER_CD": 13237,
      "TXT_FINANCIER_NAME": "KRYSTAL EMPLOYEE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13238,
      "TXT_FINANCIER_NAME": "KOTAK VISWAS"
    },
    {
      "NUM_FINANCIER_CD": 13239,
      "TXT_FINANCIER_NAME": "SRI SAI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13240,
      "TXT_FINANCIER_NAME": "SHRI MAYURESHWAR GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13241,
      "TXT_FINANCIER_NAME": "V S S C ISRO"
    },
    {
      "NUM_FINANCIER_CD": 13242,
      "TXT_FINANCIER_NAME": "SRI AMARAVATHY AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13243,
      "TXT_FINANCIER_NAME": "AJITNATH CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13244,
      "TXT_FINANCIER_NAME": "RANZOR LEASING FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13245,
      "TXT_FINANCIER_NAME": "THE CHATRAPATI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13246,
      "TXT_FINANCIER_NAME": "JANAPRIYA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13247,
      "TXT_FINANCIER_NAME": "DANDELAPPA KRUSHI PATTIN SEVA SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13248,
      "TXT_FINANCIER_NAME": "SHRI DURGAMATA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13249,
      "TXT_FINANCIER_NAME": "SAMPADA LEASING"
    },
    {
      "NUM_FINANCIER_CD": 13250,
      "TXT_FINANCIER_NAME": "JANASAHKARYA SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13251,
      "TXT_FINANCIER_NAME": "SUKCHANDRAPUR SKUS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13252,
      "TXT_FINANCIER_NAME": "ITAGULI GROUP GRAMAGALA SEVA SAHAKARI SANGA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13253,
      "TXT_FINANCIER_NAME": "Prime Co-op.Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 13254,
      "TXT_FINANCIER_NAME": "THE THIRUMARAYOOR SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 13255,
      "TXT_FINANCIER_NAME": "JIVHALA MAHILA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT MALKAPUR"
    },
    {
      "NUM_FINANCIER_CD": 13256,
      "TXT_FINANCIER_NAME": "SHRI SAPTSHRUNG GRAMIN BIGGAR SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13257,
      "TXT_FINANCIER_NAME": "MATHRUSHREE VIVIDHODESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14653,
      "TXT_FINANCIER_NAME": "MADANTHYAR SEVA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14654,
      "TXT_FINANCIER_NAME": "IL AND FS RAIL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14655,
      "TXT_FINANCIER_NAME": "DIRECTOR NATIONAL INSTITUTE OF VIROLOGY"
    },
    {
      "NUM_FINANCIER_CD": 14656,
      "TXT_FINANCIER_NAME": "VANDEMATHARAM SAKH SAHAKARITA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14657,
      "TXT_FINANCIER_NAME": "SURINDRA AUTO TRADERS"
    },
    {
      "NUM_FINANCIER_CD": 14658,
      "TXT_FINANCIER_NAME": "VISHWAPRABHA NAGARI SAH PAT SANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 14659,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CORPORATION PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14660,
      "TXT_FINANCIER_NAME": "SRI SEETHARAMA VIVIDODDESHA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14661,
      "TXT_FINANCIER_NAME": "THE PANJA CO OP AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14662,
      "TXT_FINANCIER_NAME": "YOGESHWARI MULTISTATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14663,
      "TXT_FINANCIER_NAME": "VISHWAS VIVIDODDESHA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 14664,
      "TXT_FINANCIER_NAME": "ADD TRUCK COMMERCIAL P LTD"
    },
    {
      "NUM_FINANCIER_CD": 14665,
      "TXT_FINANCIER_NAME": "BHARATH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14666,
      "TXT_FINANCIER_NAME": "SUPERINTENDING ENGINEER NATIONAL HIGHWAYS"
    },
    {
      "NUM_FINANCIER_CD": 14667,
      "TXT_FINANCIER_NAME": "KAI GANPATRAO S PAWAR NAGRI SAH PATSANSTHA MRY RETHRE BK"
    },
    {
      "NUM_FINANCIER_CD": 14668,
      "TXT_FINANCIER_NAME": "SIDDAPURA VYAVASAYA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14669,
      "TXT_FINANCIER_NAME": "KAPOOR CHAND KOTECHA URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14670,
      "TXT_FINANCIER_NAME": "SRI BHOGESWARA SWAMY LARGE SIZED CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14671,
      "TXT_FINANCIER_NAME": "SRI VASAVI SOUHARDA PATTINA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14672,
      "TXT_FINANCIER_NAME": "BALIRAJA GRAMIN BIG SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14673,
      "TXT_FINANCIER_NAME": "SRI ARUPADAYAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14674,
      "TXT_FINANCIER_NAME": "KOPERGAON TALUKA AUTO RIKSHAW SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14675,
      "TXT_FINANCIER_NAME": "CHATUSHRUNGIMATA BIGARSHETI SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14676,
      "TXT_FINANCIER_NAME": "SULABHA VIVIDODDESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14677,
      "TXT_FINANCIER_NAME": "EAST COAST TRADE FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 14678,
      "TXT_FINANCIER_NAME": "SHRI KALIKA GRAMIN BIGAR SHETI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14679,
      "TXT_FINANCIER_NAME": "THE MORVA ARTHKSHAM SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14680,
      "TXT_FINANCIER_NAME": "TELANGANA NEW AND RENEWABLE ENERGY DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14681,
      "TXT_FINANCIER_NAME": "NADHASHRI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14682,
      "TXT_FINANCIER_NAME": "KALA ACADEMY GOA"
    },
    {
      "NUM_FINANCIER_CD": 14683,
      "TXT_FINANCIER_NAME": "MANDAVI KINARA NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14684,
      "TXT_FINANCIER_NAME": "THE KAROLI CO OP MP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14685,
      "TXT_FINANCIER_NAME": "MEMBER SECRETARY CMDA"
    },
    {
      "NUM_FINANCIER_CD": 14686,
      "TXT_FINANCIER_NAME": "YESHWANT MULTYSTATE CO OP CREDIT SOCY"
    },
    {
      "NUM_FINANCIER_CD": 14687,
      "TXT_FINANCIER_NAME": "YASHWANT MULTISTATE CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14688,
      "TXT_FINANCIER_NAME": "KERALA GOVERNNMENT"
    },
    {
      "NUM_FINANCIER_CD": 14689,
      "TXT_FINANCIER_NAME": "ADRIN DEPT OF SPACE"
    },
    {
      "NUM_FINANCIER_CD": 14690,
      "TXT_FINANCIER_NAME": "DHANRAJ BAFNA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 14691,
      "TXT_FINANCIER_NAME": "SUPREME SECURTIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 14692,
      "TXT_FINANCIER_NAME": "JAISINGHRAO CO OP CRADIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 14693,
      "TXT_FINANCIER_NAME": "SUSHIL KUMAR KUMBHAT HUF"
    },
    {
      "NUM_FINANCIER_CD": 14694,
      "TXT_FINANCIER_NAME": "JANAHITHA SOUHARDHA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14695,
      "TXT_FINANCIER_NAME": "THE THURAVAS GROUP SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14696,
      "TXT_FINANCIER_NAME": "THE NARATH SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14697,
      "TXT_FINANCIER_NAME": "P SUMER CHAND CHALLANI"
    },
    {
      "NUM_FINANCIER_CD": 14698,
      "TXT_FINANCIER_NAME": "SHRI SHAKTI SARAFI SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14699,
      "TXT_FINANCIER_NAME": "YUWAK KALYAN GRAMIN BIGARSHETI SAH PAT"
    },
    {
      "NUM_FINANCIER_CD": 14700,
      "TXT_FINANCIER_NAME": "SHREE JALARAM MAHILA CO OP CREDIT SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14701,
      "TXT_FINANCIER_NAME": "SHRI BHAGDESHWAR GR BIG SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14702,
      "TXT_FINANCIER_NAME": "THE SANKESHWAR SOUHARD CREDIT SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14703,
      "TXT_FINANCIER_NAME": "NAGINA MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 14704,
      "TXT_FINANCIER_NAME": "DHANASHREE MAHILA GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14705,
      "TXT_FINANCIER_NAME": "SHRI VINAYAK AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14706,
      "TXT_FINANCIER_NAME": "SRI VIGNESH INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14707,
      "TXT_FINANCIER_NAME": "SREE SUMANJALI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14708,
      "TXT_FINANCIER_NAME": "SINHGAD NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14709,
      "TXT_FINANCIER_NAME": "KISAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14710,
      "TXT_FINANCIER_NAME": "SHRI SALESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14711,
      "TXT_FINANCIER_NAME": "SHRI GOURISHANKAR MULTI PURPOSE SOUHARD SOC"
    },
    {
      "NUM_FINANCIER_CD": 14712,
      "TXT_FINANCIER_NAME": "SHREE GOURISHANKAR MULTIPURPOSE SOUHARD SOC"
    },
    {
      "NUM_FINANCIER_CD": 14713,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER GEN GSFCL TPS"
    },
    {
      "NUM_FINANCIER_CD": 14714,
      "TXT_FINANCIER_NAME": "SHREE ARBUDA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14715,
      "TXT_FINANCIER_NAME": "JANVIKAS NAGARI SAHAKARI PAT SAUNSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14716,
      "TXT_FINANCIER_NAME": "THE EKLINGJI CREDIT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14717,
      "TXT_FINANCIER_NAME": "KACHUR CREDIT COOPERATIVE SCOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14718,
      "TXT_FINANCIER_NAME": "JAN KALYAN MULTI PURPOSE CO OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 14719,
      "TXT_FINANCIER_NAME": "SRIRAMA CHITS AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14720,
      "TXT_FINANCIER_NAME": "AABASAHEB VEER GRAMIN BIGAR SHETI SAHAKARI PATSANSHTA MY BHUINJ"
    },
    {
      "NUM_FINANCIER_CD": 14721,
      "TXT_FINANCIER_NAME": "KALLADA GENERAL FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14722,
      "TXT_FINANCIER_NAME": "VARADIAM SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 14723,
      "TXT_FINANCIER_NAME": "NEERMARGA CORPORATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14724,
      "TXT_FINANCIER_NAME": "LDC MULTI CO OP CREDITED SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14725,
      "TXT_FINANCIER_NAME": "SHRI KARVEER NIVASINI NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14726,
      "TXT_FINANCIER_NAME": "NAVAJEEVAN VIVIDH UDESHGAL SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14727,
      "TXT_FINANCIER_NAME": "RUKMINI DEVI GRAMIN BIGARSHETI SAH PATH"
    },
    {
      "NUM_FINANCIER_CD": 14728,
      "TXT_FINANCIER_NAME": "AADHAR MULTI STATE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14729,
      "TXT_FINANCIER_NAME": "THE HINDUSTAN MINORITY MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14730,
      "TXT_FINANCIER_NAME": "SHREE SAI LAXMI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14731,
      "TXT_FINANCIER_NAME": "SHREE PANCHADURGA FINANCE CO R"
    },
    {
      "NUM_FINANCIER_CD": 14732,
      "TXT_FINANCIER_NAME": "SHRI GURUDEVA MULTIPURPOSE COOPERATIVE SOCIETY N"
    },
    {
      "NUM_FINANCIER_CD": 14733,
      "TXT_FINANCIER_NAME": "SRI PATHANJALI SOUHARDHA CREDIT CO OP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14734,
      "TXT_FINANCIER_NAME": "REGAL FINCORP CO"
    },
    {
      "NUM_FINANCIER_CD": 14735,
      "TXT_FINANCIER_NAME": "RAVI UDAY GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14736,
      "TXT_FINANCIER_NAME": "ADARSHA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14737,
      "TXT_FINANCIER_NAME": "THE BADOLKAMPA GROUP SEVA SAH MANDLI LTD AND S K BANK"
    },
    {
      "NUM_FINANCIER_CD": 14738,
      "TXT_FINANCIER_NAME": "SANTAJI YUWAK CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14739,
      "TXT_FINANCIER_NAME": "SHREE KRISHNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14740,
      "TXT_FINANCIER_NAME": "ADDL CHIEF ENGINEER GBPS"
    },
    {
      "NUM_FINANCIER_CD": 14741,
      "TXT_FINANCIER_NAME": "SHREE BHAGEERATHA PATHINA SAHAKARA SANGHA NEYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14742,
      "TXT_FINANCIER_NAME": "NILAM S MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 14743,
      "TXT_FINANCIER_NAME": "VEDAVYAS CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14744,
      "TXT_FINANCIER_NAME": "BPCL COCHIN REFINERY EMPLOYEES CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14745,
      "TXT_FINANCIER_NAME": "MUNDOOR VYAVASAIKA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16389,
      "TXT_FINANCIER_NAME": "THE GUJARAT INDUSTRIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16390,
      "TXT_FINANCIER_NAME": "THE HINDUSTAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16391,
      "TXT_FINANCIER_NAME": "THE DAHOD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16392,
      "TXT_FINANCIER_NAME": "MANDVI NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16393,
      "TXT_FINANCIER_NAME": "MANJEERA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 16394,
      "TXT_FINANCIER_NAME": "MUVATTUPUZHA URBAN CO.OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 16395,
      "TXT_FINANCIER_NAME": "THE GADCHIROLI DISTRICT CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16396,
      "TXT_FINANCIER_NAME": "INGVAING BANK"
    },
    {
      "NUM_FINANCIER_CD": 16397,
      "TXT_FINANCIER_NAME": "PUNJAB & MAHARASHTRA CO-OPBANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16398,
      "TXT_FINANCIER_NAME": "THE VIRAMGAM MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16399,
      "TXT_FINANCIER_NAME": "BRAHMADEODADA MANE SAHAKARI BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 16400,
      "TXT_FINANCIER_NAME": "THE SABARKANTHA DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16401,
      "TXT_FINANCIER_NAME": "NARODA INDUSTRIAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16402,
      "TXT_FINANCIER_NAME": "ING VYASYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16403,
      "TXT_FINANCIER_NAME": "JAMIA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16404,
      "TXT_FINANCIER_NAME": "THE PANDHARPUR URBAN CO-OP BANK LTD.PANDHAPUR."
    },
    {
      "NUM_FINANCIER_CD": 16405,
      "TXT_FINANCIER_NAME": "JUNAGADH JILLA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16406,
      "TXT_FINANCIER_NAME": "RATLAM MANDSAUR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 16407,
      "TXT_FINANCIER_NAME": "ADARSH BANK"
    },
    {
      "NUM_FINANCIER_CD": 16408,
      "TXT_FINANCIER_NAME": "RELIABLE AGRO ENGG. SERVICES PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16409,
      "TXT_FINANCIER_NAME": "RELIANT HIRE PURCHASE CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16410,
      "TXT_FINANCIER_NAME": "RICHLINE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16411,
      "TXT_FINANCIER_NAME": "RIVAZ FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16412,
      "TXT_FINANCIER_NAME": "RKD FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16413,
      "TXT_FINANCIER_NAME": "ROHILKHAND FINANCIERS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16414,
      "TXT_FINANCIER_NAME": "RTC FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16415,
      "TXT_FINANCIER_NAME": "S N HIRE PURCHASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16416,
      "TXT_FINANCIER_NAME": "SAGAR DEPOSITS & ADVANCES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16417,
      "TXT_FINANCIER_NAME": "SAHARA INDIA FINANCIAL CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16418,
      "TXT_FINANCIER_NAME": "SAJAN HIRE PURCHASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16419,
      "TXT_FINANCIER_NAME": "SAKTHI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16420,
      "TXT_FINANCIER_NAME": "SAMBANDAM INVESTMENT & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16421,
      "TXT_FINANCIER_NAME": "SAMRA FINANCE & PROPERTIES PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16422,
      "TXT_FINANCIER_NAME": "SAMRA LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 16423,
      "TXT_FINANCIER_NAME": "SANDHU AUTO DEPOSITS PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16424,
      "TXT_FINANCIER_NAME": "SANGEETH FINANCE (TN) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16425,
      "TXT_FINANCIER_NAME": "SANGRILA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16426,
      "TXT_FINANCIER_NAME": "SARITA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16427,
      "TXT_FINANCIER_NAME": "SARNATH FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16428,
      "TXT_FINANCIER_NAME": "SATYA PRAKASH CAPITAL INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16429,
      "TXT_FINANCIER_NAME": "SAVANDAPOOR FINANCE & LEASING CO LTD."
    },
    {
      "NUM_FINANCIER_CD": 16430,
      "TXT_FINANCIER_NAME": "SAVERA CREDITS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16431,
      "TXT_FINANCIER_NAME": "SAVERY TRANSPORT FINANCE CO PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16432,
      "TXT_FINANCIER_NAME": "SAVORIT FINANCES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16433,
      "TXT_FINANCIER_NAME": "SAWHNEY FINANCIERS JAMMU (P)LTD"
    },
    {
      "NUM_FINANCIER_CD": 16434,
      "TXT_FINANCIER_NAME": "SEA LORD HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16435,
      "TXT_FINANCIER_NAME": "SEHGAL LEASING & INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16436,
      "TXT_FINANCIER_NAME": "SEKHON MOTOR FINANCE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16437,
      "TXT_FINANCIER_NAME": "SENTINAL CAPITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 16438,
      "TXT_FINANCIER_NAME": "SEYAD SHARIAT FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16439,
      "TXT_FINANCIER_NAME": "SHABNAM LEASING & FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16440,
      "TXT_FINANCIER_NAME": "SHAKUN HOLDINGS PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16441,
      "TXT_FINANCIER_NAME": "THE HP STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16442,
      "TXT_FINANCIER_NAME": "SHEEN FINANCE & INVESTMENT INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 16443,
      "TXT_FINANCIER_NAME": "SHINE BLUE HIRE PURCHASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16444,
      "TXT_FINANCIER_NAME": "SHRACHI SECURITIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16445,
      "TXT_FINANCIER_NAME": "SHREE KALYANI LEASING & FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16446,
      "TXT_FINANCIER_NAME": "SHREE PATHRAKALI FINANCE CO LTD."
    },
    {
      "NUM_FINANCIER_CD": 16447,
      "TXT_FINANCIER_NAME": "SHREE SHESHADRI LEASING & FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 16448,
      "TXT_FINANCIER_NAME": "SHREYANS FINANCE & CAPITAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16449,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY UNION FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16450,
      "TXT_FINANCIER_NAME": "SHRIRAM INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16451,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16452,
      "TXT_FINANCIER_NAME": "SIBAR FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16453,
      "TXT_FINANCIER_NAME": "SICOM LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16454,
      "TXT_FINANCIER_NAME": "THE LAKSHMI VILAS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16455,
      "TXT_FINANCIER_NAME": "THE CITY CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16456,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT INDL. CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16457,
      "TXT_FINANCIER_NAME": "NARODA INDUSTRIAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16458,
      "TXT_FINANCIER_NAME": "THE SURAT TEXTILE TRADERS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16459,
      "TXT_FINANCIER_NAME": "AJANTHA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16460,
      "TXT_FINANCIER_NAME": "NAGARIK SAHAKARI BANK LTD., BHIWANDI"
    },
    {
      "NUM_FINANCIER_CD": 16461,
      "TXT_FINANCIER_NAME": "SADGURU NAGRIK SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 16462,
      "TXT_FINANCIER_NAME": "THE GREATER BOMBAY CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16463,
      "TXT_FINANCIER_NAME": "INDORE PARASPAR SHAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16464,
      "TXT_FINANCIER_NAME": "THE PUNJAB STATE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16465,
      "TXT_FINANCIER_NAME": "MP RAJYA SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 16466,
      "TXT_FINANCIER_NAME": "SURAT NATIONAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16467,
      "TXT_FINANCIER_NAME": "Jammu Central Co-op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16468,
      "TXT_FINANCIER_NAME": "DOMBIVLI NAGARIK SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16469,
      "TXT_FINANCIER_NAME": "BHAGYODAYA URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16470,
      "TXT_FINANCIER_NAME": "THE KUNBI SAHAKARI BANK LTD., MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 16471,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGRIK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16472,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FIN. SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16473,
      "TXT_FINANCIER_NAME": "THE CHANDIGARH STATE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16474,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16475,
      "TXT_FINANCIER_NAME": "The Alwar Central Co-Op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16476,
      "TXT_FINANCIER_NAME": "ING VYSYA BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16477,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL CO-OPRETIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16478,
      "TXT_FINANCIER_NAME": "NORTH KANARA G S B BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16479,
      "TXT_FINANCIER_NAME": "NORTH KANARA G S B CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16480,
      "TXT_FINANCIER_NAME": "THE SANTRAMPUR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16481,
      "TXT_FINANCIER_NAME": "THE UDHNA CITIZEN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16482,
      "TXT_FINANCIER_NAME": "UNITED COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 16483,
      "TXT_FINANCIER_NAME": "ING VYSYA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16484,
      "TXT_FINANCIER_NAME": "ING VYSYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 16485,
      "TXT_FINANCIER_NAME": "INDIAN FARMERS FERTILISER COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16486,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16487,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16488,
      "TXT_FINANCIER_NAME": "Rajarshi Shahu Sahakari Bank Ltd., Pune"
    },
    {
      "NUM_FINANCIER_CD": 16489,
      "TXT_FINANCIER_NAME": "NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11764,
      "TXT_FINANCIER_NAME": "SUPREME FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 11765,
      "TXT_FINANCIER_NAME": "RENEWABLE ENERGY DEPARTMENT HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 11766,
      "TXT_FINANCIER_NAME": "THE DEHGAM NAGRIK SAHKARI SARAFI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11767,
      "TXT_FINANCIER_NAME": "VISHWAS NAGARI SAHKARI PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11768,
      "TXT_FINANCIER_NAME": "THE PANCHGANI CO OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 11769,
      "TXT_FINANCIER_NAME": "MANIMAJRA MOTOR FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11770,
      "TXT_FINANCIER_NAME": "RAM KIRPA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11771,
      "TXT_FINANCIER_NAME": "ARYA VYSYA COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11772,
      "TXT_FINANCIER_NAME": "JAINA MANDALI SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11773,
      "TXT_FINANCIER_NAME": "GURU KRIPA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11774,
      "TXT_FINANCIER_NAME": "SHREE RAM CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11775,
      "TXT_FINANCIER_NAME": "L T FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11776,
      "TXT_FINANCIER_NAME": "MAHALXAMI GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11777,
      "TXT_FINANCIER_NAME": "THE CUTTACK CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11778,
      "TXT_FINANCIER_NAME": "K R FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11779,
      "TXT_FINANCIER_NAME": "VIVEK GRAMIN BIGER SHETI S P S M"
    },
    {
      "NUM_FINANCIER_CD": 11780,
      "TXT_FINANCIER_NAME": "DHULER VKSS SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11781,
      "TXT_FINANCIER_NAME": "SHRI PISEDEV CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11782,
      "TXT_FINANCIER_NAME": "RAJIV GILANI FINANCIAL SERVICES UNLIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11783,
      "TXT_FINANCIER_NAME": "THE VADAVUCODE FARMERS SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11784,
      "TXT_FINANCIER_NAME": "SHREE GAYATHRI PATTINA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11785,
      "TXT_FINANCIER_NAME": "KAVIVIRYA MOROPANT NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11786,
      "TXT_FINANCIER_NAME": "SHRI VEER CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11787,
      "TXT_FINANCIER_NAME": "THE BHAGALPUR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11788,
      "TXT_FINANCIER_NAME": "TULJA BHAVANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11789,
      "TXT_FINANCIER_NAME": "TULJABHAVANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11790,
      "TXT_FINANCIER_NAME": "NEMINATH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11791,
      "TXT_FINANCIER_NAME": "WORLD ZORASTRIAN ORGANISATION"
    },
    {
      "NUM_FINANCIER_CD": 11792,
      "TXT_FINANCIER_NAME": "SHRI AMAR GRAMIN BIGER SHETI SAH PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11793,
      "TXT_FINANCIER_NAME": "SHRI B Y PATIL NAGARI SAH PAT SANSTHA MAYDT YELANE"
    },
    {
      "NUM_FINANCIER_CD": 11794,
      "TXT_FINANCIER_NAME": "BAJAJ ALLIANZ INSURANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 11795,
      "TXT_FINANCIER_NAME": "DESHBHKTA JILAPPA RAYAPPA KHOT GRAMSEVA VIVIDH KARYAKARI SAH VIKAS SEVA SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11796,
      "TXT_FINANCIER_NAME": "KAI F J PATIL SAHKARI PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 11797,
      "TXT_FINANCIER_NAME": "THE VARDHAMAN NAGRIK SHARAFI SAHAKARI MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 11798,
      "TXT_FINANCIER_NAME": "SRUNGADRI VIVIDODDESHA SAUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11799,
      "TXT_FINANCIER_NAME": "THE VADIYAVIR BACHAT AND DHIRAN SAHAKARI MANDARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11800,
      "TXT_FINANCIER_NAME": "YOGIRAJ JANARDAN GRAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11801,
      "TXT_FINANCIER_NAME": "THE ADOOR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11802,
      "TXT_FINANCIER_NAME": "THE UZHUVA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11803,
      "TXT_FINANCIER_NAME": "SRI RAMACHANDRA PATTINA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 11804,
      "TXT_FINANCIER_NAME": "MARUTHI COUNTRYWIDE AUTO FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11805,
      "TXT_FINANCIER_NAME": "CONSORTIUM SECURITIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11806,
      "TXT_FINANCIER_NAME": "JUPITOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11807,
      "TXT_FINANCIER_NAME": "SHRI LATHI VIBHAGIY NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11808,
      "TXT_FINANCIER_NAME": "MAHESHWARI LEASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11809,
      "TXT_FINANCIER_NAME": "DHANASHREE MAHILA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11810,
      "TXT_FINANCIER_NAME": "DINDAYAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 11811,
      "TXT_FINANCIER_NAME": "BAVAHIR SAHKARI SHARAFI MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 11812,
      "TXT_FINANCIER_NAME": "THE MEHAKAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11813,
      "TXT_FINANCIER_NAME": "MANWATH URBAN CO OP BANK LTD MANWATH"
    },
    {
      "NUM_FINANCIER_CD": 11814,
      "TXT_FINANCIER_NAME": "PAL GRAMIN BIG SHETI SAH PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11815,
      "TXT_FINANCIER_NAME": "LATHI VIBHAGIYA NAGRIK SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 11816,
      "TXT_FINANCIER_NAME": "LARSEN TOUBRO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11817,
      "TXT_FINANCIER_NAME": "GOVERNOR OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 11818,
      "TXT_FINANCIER_NAME": "NON CONVENTIONAL ENERGY DEPT CORPORATE OF AP"
    },
    {
      "NUM_FINANCIER_CD": 11819,
      "TXT_FINANCIER_NAME": "J AND K GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11820,
      "TXT_FINANCIER_NAME": "NUMHEMS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11821,
      "TXT_FINANCIER_NAME": "SAU SHANTABAI TARACHAND DUNGARWAL"
    },
    {
      "NUM_FINANCIER_CD": 11822,
      "TXT_FINANCIER_NAME": "CHATRAPATHI SHIVAJI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11823,
      "TXT_FINANCIER_NAME": "FASTFIN LEASING AND CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11824,
      "TXT_FINANCIER_NAME": "SHRI GANESH NAGARI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11825,
      "TXT_FINANCIER_NAME": "SHREE DASHMESH ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 11826,
      "TXT_FINANCIER_NAME": "SRI SHANKARI PATHINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11827,
      "TXT_FINANCIER_NAME": "KADODARA VIBHAG NAGRIK BACHAT ANE DHIRAN KARNARI SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 11828,
      "TXT_FINANCIER_NAME": "SHIVRATNA GRAMIN BIGARSHETI SAH PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 11829,
      "TXT_FINANCIER_NAME": "THE NAVKAR SAHAKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11830,
      "TXT_FINANCIER_NAME": "BAMBAVADE NAG SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11831,
      "TXT_FINANCIER_NAME": "GAMBHIR HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11832,
      "TXT_FINANCIER_NAME": "THE PRESIDENT GOVT OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 11833,
      "TXT_FINANCIER_NAME": "FOR RELIANCE CONSUMER FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11834,
      "TXT_FINANCIER_NAME": "AMBICA SHROFF AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11835,
      "TXT_FINANCIER_NAME": "SHRI MARKHANDESHAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11836,
      "TXT_FINANCIER_NAME": "MACO CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11837,
      "TXT_FINANCIER_NAME": "NAMAN COMMODITTIES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 11838,
      "TXT_FINANCIER_NAME": "UNDER SECRETARY GOVT HARYANA REVENEUE DEPTT"
    },
    {
      "NUM_FINANCIER_CD": 11839,
      "TXT_FINANCIER_NAME": "SHIVRAI GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11840,
      "TXT_FINANCIER_NAME": "MANDAVI KINARA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11841,
      "TXT_FINANCIER_NAME": "YASHWANT SHIKSHAN SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11842,
      "TXT_FINANCIER_NAME": "THEJAS FINACING CO"
    },
    {
      "NUM_FINANCIER_CD": 11843,
      "TXT_FINANCIER_NAME": "SAKTHI GANAPATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11844,
      "TXT_FINANCIER_NAME": "THE LAMBI PRIMARY COOPERATIVE AGRICULTURE DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 11845,
      "TXT_FINANCIER_NAME": "TIDE WATER OIL CO INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11846,
      "TXT_FINANCIER_NAME": "JAI SHREE COOPERATIVE URBAN T AND C SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11847,
      "TXT_FINANCIER_NAME": "SHRIJI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11848,
      "TXT_FINANCIER_NAME": "LEELA KANAK AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11849,
      "TXT_FINANCIER_NAME": "THE MILLENIUM CREDIT CO OPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11850,
      "TXT_FINANCIER_NAME": "HIGNESS KURIES AND LOANS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11851,
      "TXT_FINANCIER_NAME": "SHIVALLI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11852,
      "TXT_FINANCIER_NAME": "CHAKRESHWAR NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11853,
      "TXT_FINANCIER_NAME": "KOTA AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11854,
      "TXT_FINANCIER_NAME": "THE MODASA ROAD TRANSPORT SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 11855,
      "TXT_FINANCIER_NAME": "SHIV SHRADDHA COOPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11856,
      "TXT_FINANCIER_NAME": "AAVKAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11857,
      "TXT_FINANCIER_NAME": "BHAGYALAXMI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13258,
      "TXT_FINANCIER_NAME": "CHANDRASHEKHAR GRAMIN BIGAR SHETI SAH PAT SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 13259,
      "TXT_FINANCIER_NAME": "DHANADAI NAG SAH PATH SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 13260,
      "TXT_FINANCIER_NAME": "PRATHAMIKA SAHAKARA KRUSHI MATTHU GRAMEENA ABHIVRUDDI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13261,
      "TXT_FINANCIER_NAME": "THE CHEEMA KALAN CO OPP AGRICULTURE"
    },
    {
      "NUM_FINANCIER_CD": 13262,
      "TXT_FINANCIER_NAME": "SAMRUDDHI URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13263,
      "TXT_FINANCIER_NAME": "SHANKARANARAYAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13264,
      "TXT_FINANCIER_NAME": "THE SHREE J P MULTISTATE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13265,
      "TXT_FINANCIER_NAME": "REGISTRAR DR BRAOU"
    },
    {
      "NUM_FINANCIER_CD": 13266,
      "TXT_FINANCIER_NAME": "SADHU SANGAM PATTHIN SAHAKAR SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13267,
      "TXT_FINANCIER_NAME": "POORNWADI NAGARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 13268,
      "TXT_FINANCIER_NAME": "THIRUCHENDUR MURUGAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13269,
      "TXT_FINANCIER_NAME": "THE KARNATAKA MINORITY CREDIT CO OPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13270,
      "TXT_FINANCIER_NAME": "VANASHRI NANASAHEB MAHADIK MULTI STATE URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13271,
      "TXT_FINANCIER_NAME": "VADASINOR PRAGATI SAMAJ COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13272,
      "TXT_FINANCIER_NAME": "TRCMPU LTD"
    },
    {
      "NUM_FINANCIER_CD": 13273,
      "TXT_FINANCIER_NAME": "TUMKUR CITY CREDIT SOWHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13274,
      "TXT_FINANCIER_NAME": "ATTI KODIGE KRISHI PATTINA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13275,
      "TXT_FINANCIER_NAME": "JT DIR OF ACC INST OF PUBLIC ASSTT PROVEDORIA"
    },
    {
      "NUM_FINANCIER_CD": 13276,
      "TXT_FINANCIER_NAME": "P CHANDRA INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13277,
      "TXT_FINANCIER_NAME": "SHRI SHANKARA BHARATHI VSSN"
    },
    {
      "NUM_FINANCIER_CD": 13278,
      "TXT_FINANCIER_NAME": "SVATANTRA SAINANI KAI SHRIPAD ALAS KAKA URBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 13279,
      "TXT_FINANCIER_NAME": "ATHARVA NAGRI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13280,
      "TXT_FINANCIER_NAME": "SR BR MANAGER LIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 13281,
      "TXT_FINANCIER_NAME": "NAVYUG VIKAS NAGARI SAHAKARI PATHPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13282,
      "TXT_FINANCIER_NAME": "AUTO DEAL"
    },
    {
      "NUM_FINANCIER_CD": 13283,
      "TXT_FINANCIER_NAME": "SHRI SIDDHASIRI PATTINA SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13284,
      "TXT_FINANCIER_NAME": "DR VIJAYRAW BORAVAKE MULTISTATE CO OP CREDIT SOCI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13285,
      "TXT_FINANCIER_NAME": "SHIVAPRIYA PATTINA SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 13286,
      "TXT_FINANCIER_NAME": "NEELAKANTA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13287,
      "TXT_FINANCIER_NAME": "GANGWAL AUTO DEAL AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13288,
      "TXT_FINANCIER_NAME": "THE KASARAGOD TALUK PUBLIC SERVICE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13289,
      "TXT_FINANCIER_NAME": "YASHODEEP SAHAKARI PATHPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13290,
      "TXT_FINANCIER_NAME": "SRI VETTRIVEL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13291,
      "TXT_FINANCIER_NAME": "PARAK FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13292,
      "TXT_FINANCIER_NAME": "THE CHHAT G S CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13293,
      "TXT_FINANCIER_NAME": "SRI PANCHADURGA CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13294,
      "TXT_FINANCIER_NAME": "SRI RAM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13295,
      "TXT_FINANCIER_NAME": "VASAVI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13296,
      "TXT_FINANCIER_NAME": "THE MANAGING DIRECTOR"
    },
    {
      "NUM_FINANCIER_CD": 13297,
      "TXT_FINANCIER_NAME": "SHRI HANUMAN MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13298,
      "TXT_FINANCIER_NAME": "JANAKA CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13299,
      "TXT_FINANCIER_NAME": "KOTE SHREE KOLLAPURADAMMA MAHILA PATTINA SAHAKARA SANGA NYAMINTHA"
    },
    {
      "NUM_FINANCIER_CD": 13300,
      "TXT_FINANCIER_NAME": "THE RABOUN NON AGRICULTURAL THRIFT AND CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13301,
      "TXT_FINANCIER_NAME": "SRI KUMBHESHWARA VIVIDHODHESHA SAHAKARA SANGHA N"
    },
    {
      "NUM_FINANCIER_CD": 13302,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR JILHA SHRI SANT GOROBA KAKA KUMBHAR NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13303,
      "TXT_FINANCIER_NAME": "SHRAMIKARA PATTINA SHAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13304,
      "TXT_FINANCIER_NAME": "THE KADAKAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13305,
      "TXT_FINANCIER_NAME": "CARTHIK CREDITS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13306,
      "TXT_FINANCIER_NAME": "SINNAR NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13307,
      "TXT_FINANCIER_NAME": "ANIKETHANA VIVIDODDESHA MAHILA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13308,
      "TXT_FINANCIER_NAME": "M D C C BANK"
    },
    {
      "NUM_FINANCIER_CD": 13309,
      "TXT_FINANCIER_NAME": "THE MARUTHONKARA SERVICE CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13310,
      "TXT_FINANCIER_NAME": "KANNIMALA SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13311,
      "TXT_FINANCIER_NAME": "GANESH CO OP HSC"
    },
    {
      "NUM_FINANCIER_CD": 13312,
      "TXT_FINANCIER_NAME": "GOUTHAM PRAVEEN CHAND"
    },
    {
      "NUM_FINANCIER_CD": 13313,
      "TXT_FINANCIER_NAME": "RAJADIRAJ AUTO FINANCE CO P LTD"
    },
    {
      "NUM_FINANCIER_CD": 13314,
      "TXT_FINANCIER_NAME": "SHIMLA INVESTMENT AND MANAGEMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13315,
      "TXT_FINANCIER_NAME": "SHRIRAM SAMARTH NAGARI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13316,
      "TXT_FINANCIER_NAME": "KALPATHARU CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13317,
      "TXT_FINANCIER_NAME": "VARDHAN ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 13318,
      "TXT_FINANCIER_NAME": "ABHISHEK GOYAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13319,
      "TXT_FINANCIER_NAME": "THE CHITHARA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13320,
      "TXT_FINANCIER_NAME": "TUMKUR NAVACHETHANA SOWHARDA CREDIT CO OPRATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13321,
      "TXT_FINANCIER_NAME": "BARDOLI PEOPLES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13322,
      "TXT_FINANCIER_NAME": "KULJORA SAMABAYA KRISHI UNNAYAN SAMITI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13323,
      "TXT_FINANCIER_NAME": "VAMADAPADAVU SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13324,
      "TXT_FINANCIER_NAME": "BRIHAN MUMBAI ELECTRIC SUPPLY AND TRANSPORT"
    },
    {
      "NUM_FINANCIER_CD": 13325,
      "TXT_FINANCIER_NAME": "SHRI SENTHIL MURUGAN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13326,
      "TXT_FINANCIER_NAME": "UTTARAKHAND STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13327,
      "TXT_FINANCIER_NAME": "DHANIV ADIVASI VIVIDH SAHAKARI SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13328,
      "TXT_FINANCIER_NAME": "RAGAVENTHIRA CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 13329,
      "TXT_FINANCIER_NAME": "RAGHAVA AUTO FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 13330,
      "TXT_FINANCIER_NAME": "SRI SAKTHI SIVAN ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 13331,
      "TXT_FINANCIER_NAME": "PRAVARA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13332,
      "TXT_FINANCIER_NAME": "RAVIKIRAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13333,
      "TXT_FINANCIER_NAME": "J AND K BANK SC ST BACK CLASS DEV CO"
    },
    {
      "NUM_FINANCIER_CD": 13334,
      "TXT_FINANCIER_NAME": "COOPERATIVE AGRICULTURE MULTI PURPSE SOCITY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13335,
      "TXT_FINANCIER_NAME": "HASTI FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 13336,
      "TXT_FINANCIER_NAME": "THE KUBADHAROL CAMPA SAHKARI MANDLI LIMITED AND S K BANK"
    },
    {
      "NUM_FINANCIER_CD": 13337,
      "TXT_FINANCIER_NAME": "CHORIWAD BACHAT SAHKARI MANDLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13338,
      "TXT_FINANCIER_NAME": "MACCHIMAR CO OP CREDIT SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13339,
      "TXT_FINANCIER_NAME": "THE SOLA NAGARIK BACHAT SAHAKARI MANDLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13340,
      "TXT_FINANCIER_NAME": "SHRI MAHESHWAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13341,
      "TXT_FINANCIER_NAME": "YADRAV COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13342,
      "TXT_FINANCIER_NAME": "SRI SATYA SAI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13343,
      "TXT_FINANCIER_NAME": "TAMLUK GHATAL CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13344,
      "TXT_FINANCIER_NAME": "MARUDHAR BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 13345,
      "TXT_FINANCIER_NAME": "NAVODIT VELING PRIOL V K S S STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13346,
      "TXT_FINANCIER_NAME": "KUDUBI VIVIDODDESHA SAHAKAR SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13347,
      "TXT_FINANCIER_NAME": "ZILHA MADHYAWARTI BANK"
    },
    {
      "NUM_FINANCIER_CD": 13348,
      "TXT_FINANCIER_NAME": "SHREE GRAHIT NAGARI PAT SAHAKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13349,
      "TXT_FINANCIER_NAME": "SHREE MORBI VIBHAG SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13350,
      "TXT_FINANCIER_NAME": "CENTRAL AUTO CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 14746,
      "TXT_FINANCIER_NAME": "ILANDFS TRANSPORTATION NETWORKS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14747,
      "TXT_FINANCIER_NAME": "VENKATA MARUTHI HIRE PURCHASE AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14748,
      "TXT_FINANCIER_NAME": "THE GOZARIYA PEOPLES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14749,
      "TXT_FINANCIER_NAME": "THE GOJARUYA PEOPLES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14750,
      "TXT_FINANCIER_NAME": "S CHIKKALA PACCS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14751,
      "TXT_FINANCIER_NAME": "VANDEMATHARAM CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 14752,
      "TXT_FINANCIER_NAME": "SULLIA TALUK PARISISTA VARGAGALA DODDA PRAMANADA VIVIDODDESHA SAHAKA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14753,
      "TXT_FINANCIER_NAME": "RANI CHANNAMMA PATTINA SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14754,
      "TXT_FINANCIER_NAME": "NEW SRI ADITYA AUTO FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 14755,
      "TXT_FINANCIER_NAME": "SHRI AJAPAR SEVA SHAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14756,
      "TXT_FINANCIER_NAME": "SRI CHAMUNDESHWARI PATTINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14757,
      "TXT_FINANCIER_NAME": "BHAGDESHWAR GRA BIG SHE SAH PAT MARY"
    },
    {
      "NUM_FINANCIER_CD": 14758,
      "TXT_FINANCIER_NAME": "JAI VAIBHAV LAXMI MULTISTATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14759,
      "TXT_FINANCIER_NAME": "THE DISTRICT HOSPITAL EMPLOYEES CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14760,
      "TXT_FINANCIER_NAME": "GANAPATHI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14761,
      "TXT_FINANCIER_NAME": "NAVBHARAT GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14762,
      "TXT_FINANCIER_NAME": "ANKUSH NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14763,
      "TXT_FINANCIER_NAME": "SHRI SADANAND VIVIDHODDESHAGALA SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14764,
      "TXT_FINANCIER_NAME": "CHAIRMAN AND MANAGING DIRECTOR IRCON INTERNATIONL LTD"
    },
    {
      "NUM_FINANCIER_CD": 14765,
      "TXT_FINANCIER_NAME": "GADHINGLAJ TALUKA SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14766,
      "TXT_FINANCIER_NAME": "NARIMOGARU PACS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14767,
      "TXT_FINANCIER_NAME": "SATYAMEV MAHILA MULTI PURPOSE CO OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 14768,
      "TXT_FINANCIER_NAME": "DR BR AMBEDKAR DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14769,
      "TXT_FINANCIER_NAME": "SANTKRUPA NAGARI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14770,
      "TXT_FINANCIER_NAME": "SP MAHILA UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 14771,
      "TXT_FINANCIER_NAME": "SHRI BHAWKESHWARI MULTIPURPOSE SOUHARD SAH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14772,
      "TXT_FINANCIER_NAME": "KAMALAKSHI HOUSE BUILDING CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14773,
      "TXT_FINANCIER_NAME": "ONKARMULL SHANKARLAL"
    },
    {
      "NUM_FINANCIER_CD": 14774,
      "TXT_FINANCIER_NAME": "SHRI ROKDESHWAR GRAMIN BIGAR SHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14775,
      "TXT_FINANCIER_NAME": "UNIVERSAL AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 14776,
      "TXT_FINANCIER_NAME": "RISHIK CHHALLANI"
    },
    {
      "NUM_FINANCIER_CD": 14777,
      "TXT_FINANCIER_NAME": "SHREE NAKODA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14778,
      "TXT_FINANCIER_NAME": "SOUTHERN APLS ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 14779,
      "TXT_FINANCIER_NAME": "THE WAYANAD DISTRICT DRIVERS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14780,
      "TXT_FINANCIER_NAME": "THE RAMDURG TALUKA RURAL INDUSTRIAL CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14781,
      "TXT_FINANCIER_NAME": "H K AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14782,
      "TXT_FINANCIER_NAME": "SATPATI GRAMVIKAS BIGARSHETI PATPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 14783,
      "TXT_FINANCIER_NAME": "K G ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14784,
      "TXT_FINANCIER_NAME": "ATHAVAN AUTO FINANACE"
    },
    {
      "NUM_FINANCIER_CD": 14785,
      "TXT_FINANCIER_NAME": "PRATHMIK KRUSHI PATTIN SAHAKAR SANGH NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14786,
      "TXT_FINANCIER_NAME": "ARIHANT URBAN SOHARD CREDIT SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14787,
      "TXT_FINANCIER_NAME": "CHITTUR GOVERNMENT SERVENT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14788,
      "TXT_FINANCIER_NAME": "JAVERI BANK AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14789,
      "TXT_FINANCIER_NAME": "THE GREEN CITY NAGARI PATHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14790,
      "TXT_FINANCIER_NAME": "SRI LAXMANANANDA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14791,
      "TXT_FINANCIER_NAME": "SHRI HEMAREDDY MALLAMMA VIVIDODDESHA SOUHARARDHA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 14792,
      "TXT_FINANCIER_NAME": "THE BHAGATSINGH MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14793,
      "TXT_FINANCIER_NAME": "TITAN COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14794,
      "TXT_FINANCIER_NAME": "UP POSTAL PRIMARY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14795,
      "TXT_FINANCIER_NAME": "KHAMGAON VIKAS SAHAKARI SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14796,
      "TXT_FINANCIER_NAME": "KALIYA PRIMARY AGRL CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14797,
      "TXT_FINANCIER_NAME": "TAMIR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14798,
      "TXT_FINANCIER_NAME": "SHESHADRI VIVIDHODDESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14799,
      "TXT_FINANCIER_NAME": "SRI YEDEYURU SIDDALINGESHWARA VIVIDHODHESHA SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14800,
      "TXT_FINANCIER_NAME": "SHRI YALLAMMA RENUKADEVI MULTI PURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14801,
      "TXT_FINANCIER_NAME": "JAI HANUMAN PURPOSE SOUHARD SAHAKARILTD"
    },
    {
      "NUM_FINANCIER_CD": 14802,
      "TXT_FINANCIER_NAME": "MUDOOR CA BANK"
    },
    {
      "NUM_FINANCIER_CD": 14803,
      "TXT_FINANCIER_NAME": "THE NIZAMPUR MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14804,
      "TXT_FINANCIER_NAME": "SHREE ADARSH JAIN PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14805,
      "TXT_FINANCIER_NAME": "SHREE HARALE VAISHNAV PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14806,
      "TXT_FINANCIER_NAME": "SHREE BHATGAM SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14807,
      "TXT_FINANCIER_NAME": "SHANTADURGA MULTI PURPOSE SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14808,
      "TXT_FINANCIER_NAME": "SHA MOTICHAND TARACHAND"
    },
    {
      "NUM_FINANCIER_CD": 14809,
      "TXT_FINANCIER_NAME": "SRI DHANESHWARI SOUHARDHA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14810,
      "TXT_FINANCIER_NAME": "K N P S THE HINDUSTAN MINORITY MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14811,
      "TXT_FINANCIER_NAME": "THE KODALI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14812,
      "TXT_FINANCIER_NAME": "ASHOKA NAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14813,
      "TXT_FINANCIER_NAME": "THE MEHSANA BHAGYALAXMI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14814,
      "TXT_FINANCIER_NAME": "GURUKRIPA LEASING"
    },
    {
      "NUM_FINANCIER_CD": 14815,
      "TXT_FINANCIER_NAME": "SHREE KRISHNA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14816,
      "TXT_FINANCIER_NAME": "JAI GANESH CREDIT SOUHARDA SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14817,
      "TXT_FINANCIER_NAME": "S S FINANCERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 14818,
      "TXT_FINANCIER_NAME": "RAJASTHAN STATE SAHAKARI BHUMI VIKAS BANK LTD JAIPUR"
    },
    {
      "NUM_FINANCIER_CD": 14819,
      "TXT_FINANCIER_NAME": "RASIKLAL PATEL GRAMIN SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14820,
      "TXT_FINANCIER_NAME": "SAI PRERNA GRAMIN BIGAR SHETI SAH PATHASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14821,
      "TXT_FINANCIER_NAME": "ANUJ AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14822,
      "TXT_FINANCIER_NAME": "SHRI SANGMESHWAR SOUHARDH PATTIN SAHAKARI NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 14823,
      "TXT_FINANCIER_NAME": "THE HANUMANTHNAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14824,
      "TXT_FINANCIER_NAME": "LOKMANYA NAGARI SAHKARI PATH SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14825,
      "TXT_FINANCIER_NAME": "HEMARADDY MALLAMMA CREDIT CO OP SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14826,
      "TXT_FINANCIER_NAME": "K C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14827,
      "TXT_FINANCIER_NAME": "DUMMY"
    },
    {
      "NUM_FINANCIER_CD": 14828,
      "TXT_FINANCIER_NAME": "MIZORAM COOPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14829,
      "TXT_FINANCIER_NAME": "NILESH KUMAR BOHRA"
    },
    {
      "NUM_FINANCIER_CD": 14830,
      "TXT_FINANCIER_NAME": "DHORAJI MEMON JAMAT"
    },
    {
      "NUM_FINANCIER_CD": 14831,
      "TXT_FINANCIER_NAME": "SHIV SHAKTI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14832,
      "TXT_FINANCIER_NAME": "AKANSHA SAHAKARI PATHPAEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14833,
      "TXT_FINANCIER_NAME": "S DHARMICHAND FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 14834,
      "TXT_FINANCIER_NAME": "SARDA ENERGY AND MINERALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14835,
      "TXT_FINANCIER_NAME": "KONDWASHI KANDEWADI NAG SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14836,
      "TXT_FINANCIER_NAME": "SAIPRERANA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14837,
      "TXT_FINANCIER_NAME": "PRAKESH HP SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 16490,
      "TXT_FINANCIER_NAME": "INDORE CLOTH MARKET CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16491,
      "TXT_FINANCIER_NAME": "FAIZPUR JANTA SAHKARI BANK LTD.FAIZPUR"
    },
    {
      "NUM_FINANCIER_CD": 16492,
      "TXT_FINANCIER_NAME": "THE KALOL NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16493,
      "TXT_FINANCIER_NAME": "THE JANATHA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16494,
      "TXT_FINANCIER_NAME": "VIDYA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16495,
      "TXT_FINANCIER_NAME": "PARIVARTAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16496,
      "TXT_FINANCIER_NAME": "THE SURAT MAHILA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16497,
      "TXT_FINANCIER_NAME": "KATHOR VIBHAG NAGRIK SAHKARI DHIRAN MANDLI LTD."
    },
    {
      "NUM_FINANCIER_CD": 16498,
      "TXT_FINANCIER_NAME": "COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16499,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT CO OPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16500,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR PEOPLES CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16501,
      "TXT_FINANCIER_NAME": "JAI BHAWANI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16502,
      "TXT_FINANCIER_NAME": "MUTHOOT LEASING & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16503,
      "TXT_FINANCIER_NAME": "SAMPADA SAHAKARI BANK LTD.,PUNE"
    },
    {
      "NUM_FINANCIER_CD": 16504,
      "TXT_FINANCIER_NAME": "PRIME CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16505,
      "TXT_FINANCIER_NAME": "THE NASIK JILHA MAHILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16506,
      "TXT_FINANCIER_NAME": "SHREE BHARAT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16507,
      "TXT_FINANCIER_NAME": "TEXTILE TRADERS CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16508,
      "TXT_FINANCIER_NAME": "MODEL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16509,
      "TXT_FINANCIER_NAME": "THE GOKAK URBAN CO-OP CREDIT BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16510,
      "TXT_FINANCIER_NAME": "THE AGRASEN CO-OPERATION URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16511,
      "TXT_FINANCIER_NAME": "ANYONYA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16512,
      "TXT_FINANCIER_NAME": "THE MUNICIPAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16513,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGARIK CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16514,
      "TXT_FINANCIER_NAME": "SAJAN CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16515,
      "TXT_FINANCIER_NAME": "The Achalpur Ur. Co.Op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16516,
      "TXT_FINANCIER_NAME": "THE KARNATAKA STATE CO OPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16517,
      "TXT_FINANCIER_NAME": "THE IDUKKI DIST. CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16518,
      "TXT_FINANCIER_NAME": "CITIZENCREDIT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16519,
      "TXT_FINANCIER_NAME": "JHAJJAR CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16520,
      "TXT_FINANCIER_NAME": "SRI VASAVAMBA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16521,
      "TXT_FINANCIER_NAME": "SHRI SHIDDHESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16522,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16523,
      "TXT_FINANCIER_NAME": "RAJDHANI NAGAR SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16524,
      "TXT_FINANCIER_NAME": "RATLAM MANDSAUR KSHTRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 16525,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD. SIRISI"
    },
    {
      "NUM_FINANCIER_CD": 16526,
      "TXT_FINANCIER_NAME": "BUSINESS CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16527,
      "TXT_FINANCIER_NAME": "THE DHRANGADHRA PEOPLES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16528,
      "TXT_FINANCIER_NAME": "THE MALLESWARAM COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16529,
      "TXT_FINANCIER_NAME": "THE ACCOUNTANT GENERALS OFFICE EMPLOYEE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16530,
      "TXT_FINANCIER_NAME": "THE BANGALORE CITY COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16531,
      "TXT_FINANCIER_NAME": "NATIONAL INSURANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 16532,
      "TXT_FINANCIER_NAME": "BOTHRA MOTOR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16533,
      "TXT_FINANCIER_NAME": "SINGH MOTOR HIRE PURCHASE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16534,
      "TXT_FINANCIER_NAME": "SIRSA DEPOSITS & ADVANCES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16535,
      "TXT_FINANCIER_NAME": "SIVAGAMI FINANCE & INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16536,
      "TXT_FINANCIER_NAME": "SKYLARK DEPOSITS & ADVANCES PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16537,
      "TXT_FINANCIER_NAME": "SKYLARK MOTOR & GENERAL FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16538,
      "TXT_FINANCIER_NAME": "SMK FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16539,
      "TXT_FINANCIER_NAME": "SOCIAL LEASING (INDIA) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16540,
      "TXT_FINANCIER_NAME": "SONA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16541,
      "TXT_FINANCIER_NAME": "THE HP STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16542,
      "TXT_FINANCIER_NAME": "SREE VENKATARAYA INVESTMENTS & FINANCE COMPANY (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16543,
      "TXT_FINANCIER_NAME": "SREERAGH GENERAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16544,
      "TXT_FINANCIER_NAME": "SRI RAMMMALINGESWARA LEASING & AUTOFIN PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16545,
      "TXT_FINANCIER_NAME": "SRI SRI HIRE PURCHASE AND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16546,
      "TXT_FINANCIER_NAME": "SRI VIJAYA DURGA FINANCE (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16547,
      "TXT_FINANCIER_NAME": "STANDARD FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16548,
      "TXT_FINANCIER_NAME": "STANROSE MAFATLAL INVESTMENTS AND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16549,
      "TXT_FINANCIER_NAME": "STAR INSTALMENT SUPPLY CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16550,
      "TXT_FINANCIER_NAME": "SUDERSHAN HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16551,
      "TXT_FINANCIER_NAME": "SUKH CHAIN FINANCE COMPANY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16552,
      "TXT_FINANCIER_NAME": "SUKHBANS FINANCE CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16553,
      "TXT_FINANCIER_NAME": "SUNDARAM FINANCE SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16554,
      "TXT_FINANCIER_NAME": "SURJIT HIRE PURCHASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16555,
      "TXT_FINANCIER_NAME": "SWAMI DEPOSITS & INVESTMENTS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16556,
      "TXT_FINANCIER_NAME": "SWAMI HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16557,
      "TXT_FINANCIER_NAME": "SWARAN FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16558,
      "TXT_FINANCIER_NAME": "SWIFT FINLEASE (I) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16559,
      "TXT_FINANCIER_NAME": "SYAL FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16560,
      "TXT_FINANCIER_NAME": "SYAL FINLEASE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16561,
      "TXT_FINANCIER_NAME": "TAMIL NADU BUS OPERATORS FINANCE CORPN. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16562,
      "TXT_FINANCIER_NAME": "TAMIL NADU POWER FINANCE & INFRA DEVP. CORPN LTD."
    },
    {
      "NUM_FINANCIER_CD": 16563,
      "TXT_FINANCIER_NAME": "TAMILNADU INDUSTRIAL DEVELOPMENT CORPN. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16564,
      "TXT_FINANCIER_NAME": "TAMILNADU TRANSPORT DEVP. FINANCE CORPN. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16565,
      "TXT_FINANCIER_NAME": "TATA FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16566,
      "TXT_FINANCIER_NAME": "TATA INVESTMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16567,
      "TXT_FINANCIER_NAME": "TENCO HIRE PURCHASE AND LEASING CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 16568,
      "TXT_FINANCIER_NAME": "THAKUR INVESTMENTS PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16569,
      "TXT_FINANCIER_NAME": "THAKURJI LEASING & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16570,
      "TXT_FINANCIER_NAME": "THAYER OVERSEAS FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16571,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CREDIT CORP. {1943} PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 16572,
      "TXT_FINANCIER_NAME": "ING VYSYA BANKLTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16573,
      "TXT_FINANCIER_NAME": "THE MUMBAI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16574,
      "TXT_FINANCIER_NAME": "NORTH KANARA G.S.B. CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16575,
      "TXT_FINANCIER_NAME": "GHAZIABAD ZILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16576,
      "TXT_FINANCIER_NAME": "STANDARD CHARTERED GRINDLAYS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16577,
      "TXT_FINANCIER_NAME": "SADGURU NAGRIK SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 16578,
      "TXT_FINANCIER_NAME": "THE UNITED WESTERN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16579,
      "TXT_FINANCIER_NAME": "VIJAY COMMERCIAL COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16580,
      "TXT_FINANCIER_NAME": "NAGRIK SAHKARI BANK MARYADIT GWALIOR"
    },
    {
      "NUM_FINANCIER_CD": 16581,
      "TXT_FINANCIER_NAME": "PARSHWANATH CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16582,
      "TXT_FINANCIER_NAME": "THE AURANGABAD DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16583,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16584,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE COOPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16585,
      "TXT_FINANCIER_NAME": "THE SUTEX CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16586,
      "TXT_FINANCIER_NAME": "UCO BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11859,
      "TXT_FINANCIER_NAME": "PENSOL INDUSTRIES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11860,
      "TXT_FINANCIER_NAME": "DIRECTOR OF PUBLIC HEALTH AND PREVENTH MEDICINE"
    },
    {
      "NUM_FINANCIER_CD": 11861,
      "TXT_FINANCIER_NAME": "AF GROUP INSURANCE"
    },
    {
      "NUM_FINANCIER_CD": 11862,
      "TXT_FINANCIER_NAME": "VIKRAMSINH VIKAS SEVA SANSTHA MYDT KDCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11863,
      "TXT_FINANCIER_NAME": "IRINJALAKUDA COPPERATIVE AGRICULTURAL RURAL DEVELOPMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11864,
      "TXT_FINANCIER_NAME": "THE RIDDHI SIDDHI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11865,
      "TXT_FINANCIER_NAME": "THE DABHOI PEOPLES CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11866,
      "TXT_FINANCIER_NAME": "ASSOCIATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11867,
      "TXT_FINANCIER_NAME": "NAVARATANNA MUTHA"
    },
    {
      "NUM_FINANCIER_CD": 11868,
      "TXT_FINANCIER_NAME": "BARODA URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11869,
      "TXT_FINANCIER_NAME": "GANESH NAGARI SAHAKARI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 11870,
      "TXT_FINANCIER_NAME": "MARATHA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11871,
      "TXT_FINANCIER_NAME": "ADIL AMANAT CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11872,
      "TXT_FINANCIER_NAME": "SRI MAHAVEER AUTO FINANCE MADRAS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11873,
      "TXT_FINANCIER_NAME": "AVANI LEASE FIANANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11874,
      "TXT_FINANCIER_NAME": "RAJMATA RATNAPRABHADEVI MOHITE PATIL MAHILA GRAMIN GIBER SHETI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11875,
      "TXT_FINANCIER_NAME": "VINNCO FININS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11876,
      "TXT_FINANCIER_NAME": "DHANSAMPADA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11877,
      "TXT_FINANCIER_NAME": "SUNDARGARH DISTIC CENTRAL CO OPRATIV BANK"
    },
    {
      "NUM_FINANCIER_CD": 11878,
      "TXT_FINANCIER_NAME": "PASCHIMBANGA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11879,
      "TXT_FINANCIER_NAME": "SARASWATI COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11880,
      "TXT_FINANCIER_NAME": "BHARTIYA KAPAS NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 11881,
      "TXT_FINANCIER_NAME": "MALVIYA LEASING AND FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11882,
      "TXT_FINANCIER_NAME": "AKANSHA CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11883,
      "TXT_FINANCIER_NAME": "SATYA SAI NAG SAH PAT SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 11884,
      "TXT_FINANCIER_NAME": "AMRUT GRAMIN SAHAKARI BIGAR SHETI PATH SANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11885,
      "TXT_FINANCIER_NAME": "SHRI JAI JINEDRA CR SOUHARD SAH NYT"
    },
    {
      "NUM_FINANCIER_CD": 11886,
      "TXT_FINANCIER_NAME": "SRISARASWATI CREDIT SOUHARDA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 11887,
      "TXT_FINANCIER_NAME": "CHATRAPATI NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11888,
      "TXT_FINANCIER_NAME": "THE MORAYUR SERVICE CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11889,
      "TXT_FINANCIER_NAME": "TRIPURA SCHEDULED CASTES COOPERATIVE DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 11890,
      "TXT_FINANCIER_NAME": "SHRINATH FIANCE"
    },
    {
      "NUM_FINANCIER_CD": 11891,
      "TXT_FINANCIER_NAME": "THE CHAMIARA COOPERATIVE AGRI MULTIPURPOSE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11892,
      "TXT_FINANCIER_NAME": "KODAVOOR SAHAKARI VYAVASAYIKA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11893,
      "TXT_FINANCIER_NAME": "NEMA YUTH BACHAT AND SAKH SAHKARI SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11894,
      "TXT_FINANCIER_NAME": "HIRA HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 11895,
      "TXT_FINANCIER_NAME": "JEEVA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11896,
      "TXT_FINANCIER_NAME": "HASSAN VARTHAKARA SOUHARDHA PATHINA SAHAKARA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11897,
      "TXT_FINANCIER_NAME": "SUSHIL ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 11898,
      "TXT_FINANCIER_NAME": "SOMWARPET VYAVASAYA SEVA SAHAKAR BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11899,
      "TXT_FINANCIER_NAME": "KOTA KARAMCHARI SAHKARI SAMITI"
    },
    {
      "NUM_FINANCIER_CD": 11900,
      "TXT_FINANCIER_NAME": "MA AAMDAR DADASAHEB SHAHAJI ROHMARE GRAMIN BIGARSHETI SAHKARI PATHASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11901,
      "TXT_FINANCIER_NAME": "BEGPUR S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 11902,
      "TXT_FINANCIER_NAME": "THE SHUSHIL CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11903,
      "TXT_FINANCIER_NAME": "VISHWAMITRA NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 11904,
      "TXT_FINANCIER_NAME": "UAE EXCHANGE AND FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 11905,
      "TXT_FINANCIER_NAME": "SHIVASHREE SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11906,
      "TXT_FINANCIER_NAME": "NAVLAI GRAMIN BIGARSHETI PATH SAHNSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11907,
      "TXT_FINANCIER_NAME": "RAMESH PATIL NAGARI SHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11908,
      "TXT_FINANCIER_NAME": "VASUNDARA MAHILA NAGARI SAHAKARI BANK AMBAJOGAI"
    },
    {
      "NUM_FINANCIER_CD": 11909,
      "TXT_FINANCIER_NAME": "CHATTISHGARH STATE POWER HOLD COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11910,
      "TXT_FINANCIER_NAME": "JAIN GERENAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 11911,
      "TXT_FINANCIER_NAME": "THE REGISTRAR S V UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 11912,
      "TXT_FINANCIER_NAME": "THE DEMAI NAGARIK SAHAKARI SHARAFI MANDALI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11913,
      "TXT_FINANCIER_NAME": "TELECOM DIST"
    },
    {
      "NUM_FINANCIER_CD": 11914,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI BHAUSAHEB THORAT CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11915,
      "TXT_FINANCIER_NAME": "THE DHANSOBHAVAK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11916,
      "TXT_FINANCIER_NAME": "SAMBHAJI GRAMIN BIGAR SHETHI SHAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11917,
      "TXT_FINANCIER_NAME": "AJINKYATARA NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11918,
      "TXT_FINANCIER_NAME": "KUNAL KUMAR NAHAR HUF"
    },
    {
      "NUM_FINANCIER_CD": 11919,
      "TXT_FINANCIER_NAME": "THE DELHI SWASTIK CO OPERATIVE URBAN THRIFT AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11920,
      "TXT_FINANCIER_NAME": "SHRI VEERABHADRESHWAR CREDIT SOUHARD SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11921,
      "TXT_FINANCIER_NAME": "NUMLIGARH REFINERY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11922,
      "TXT_FINANCIER_NAME": "MALATAJ CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11923,
      "TXT_FINANCIER_NAME": "SANGHIVI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11924,
      "TXT_FINANCIER_NAME": "BHARATH FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11925,
      "TXT_FINANCIER_NAME": "THE KHANAPUR PRATHAMIK KRISHI PATTIN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11926,
      "TXT_FINANCIER_NAME": "N M AUTO FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 11927,
      "TXT_FINANCIER_NAME": "ANNAPOORNA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11928,
      "TXT_FINANCIER_NAME": "HEENA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11929,
      "TXT_FINANCIER_NAME": "VIJAYALAKSHMI AUTO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11930,
      "TXT_FINANCIER_NAME": "DEERAJ INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11931,
      "TXT_FINANCIER_NAME": "CENTRAL PUBLIC WORKS DEPATMENT"
    },
    {
      "NUM_FINANCIER_CD": 11932,
      "TXT_FINANCIER_NAME": "CHAGANACHERRY URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11933,
      "TXT_FINANCIER_NAME": "THE GURUVAYUR URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 11934,
      "TXT_FINANCIER_NAME": "VIJAY NAGRI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11935,
      "TXT_FINANCIER_NAME": "THE KATLARY KARIYANA MARCHANT SAHAKARI SARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 11936,
      "TXT_FINANCIER_NAME": "THE SANDEEP CREDIT SOUHARD CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 11937,
      "TXT_FINANCIER_NAME": "RAJMATA URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 11938,
      "TXT_FINANCIER_NAME": "GANDHAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 11939,
      "TXT_FINANCIER_NAME": "LNT FINANCIER LTD"
    },
    {
      "NUM_FINANCIER_CD": 11940,
      "TXT_FINANCIER_NAME": "SYNDICATE FARMERS SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11941,
      "TXT_FINANCIER_NAME": "SAMAJA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 11942,
      "TXT_FINANCIER_NAME": "SHREE FRIENDZ CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11943,
      "TXT_FINANCIER_NAME": "SRI ARASU FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11944,
      "TXT_FINANCIER_NAME": "TRACTOR INDIA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11945,
      "TXT_FINANCIER_NAME": "ANANTKRUPA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11946,
      "TXT_FINANCIER_NAME": "SUYASH VIKAS SAKH SAHAKARITA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11947,
      "TXT_FINANCIER_NAME": "SARALA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11948,
      "TXT_FINANCIER_NAME": "SRI LALITHAMBIGAI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 11949,
      "TXT_FINANCIER_NAME": "WANKANER JAIN SOCIAL WELFARE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11950,
      "TXT_FINANCIER_NAME": "SHRI MAHAVEER SARVODAYA SAKH SEHKARITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11951,
      "TXT_FINANCIER_NAME": "SHARMA HIRE PURCHASE CORP"
    },
    {
      "NUM_FINANCIER_CD": 11952,
      "TXT_FINANCIER_NAME": "MANJUSHREE ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 13351,
      "TXT_FINANCIER_NAME": "RAJKUMARI SISODIYA FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 13352,
      "TXT_FINANCIER_NAME": "SWABHIMANI CREDIT SOWHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13353,
      "TXT_FINANCIER_NAME": "THE PAVARATTY SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13354,
      "TXT_FINANCIER_NAME": "HARE KRISHNA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13355,
      "TXT_FINANCIER_NAME": "KOLHAPUR ZILLA SANGAR SAMAJ NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 13356,
      "TXT_FINANCIER_NAME": "SREE PRAGATHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13357,
      "TXT_FINANCIER_NAME": "COMMR AND DIRECTOR OF AGRIL MARKETING"
    },
    {
      "NUM_FINANCIER_CD": 13358,
      "TXT_FINANCIER_NAME": "RO AND NIRMAAN VIVIDODDHESHA SOUHARDA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 13359,
      "TXT_FINANCIER_NAME": "CHEMINOVA INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13360,
      "TXT_FINANCIER_NAME": "SRI JAYARAMA MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 13361,
      "TXT_FINANCIER_NAME": "RAJMUDRA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13362,
      "TXT_FINANCIER_NAME": "GANRAJ GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13363,
      "TXT_FINANCIER_NAME": "VIDARBHA KONKAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 13364,
      "TXT_FINANCIER_NAME": "SALGAON SANMITRA SAHAKARI PATHPEDI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13365,
      "TXT_FINANCIER_NAME": "SHIVA PATHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13366,
      "TXT_FINANCIER_NAME": "SEYAD SHARIT FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13367,
      "TXT_FINANCIER_NAME": "EDUCATIONAL EMPLOYEES WELFARE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13368,
      "TXT_FINANCIER_NAME": "KRISHI PATTINA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13369,
      "TXT_FINANCIER_NAME": "THE R S CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13370,
      "TXT_FINANCIER_NAME": "MUNDAJE CO OP AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13371,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN GRAMEENA CO OP CR SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13372,
      "TXT_FINANCIER_NAME": "RAJESH AND CO"
    },
    {
      "NUM_FINANCIER_CD": 13373,
      "TXT_FINANCIER_NAME": "KOLLAMOGRU HARIHARA PRIMARY AGRICULTURAL CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13374,
      "TXT_FINANCIER_NAME": "THE VENUR CO OP AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13375,
      "TXT_FINANCIER_NAME": "BHAGDESHWAR GR BIG SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13376,
      "TXT_FINANCIER_NAME": "NAVODAYA FINANCE COR R"
    },
    {
      "NUM_FINANCIER_CD": 13377,
      "TXT_FINANCIER_NAME": "SRI AKKAMAHADEVI MAHILA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 13378,
      "TXT_FINANCIER_NAME": "SHANTISAGAR ALPASANKYAT PATTAN PATTIN SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13379,
      "TXT_FINANCIER_NAME": "THE PREMIUM URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13380,
      "TXT_FINANCIER_NAME": "SHREE ADINATH CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13381,
      "TXT_FINANCIER_NAME": "SITHI VINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13382,
      "TXT_FINANCIER_NAME": "BHILAI STEEL PLANT"
    },
    {
      "NUM_FINANCIER_CD": 13383,
      "TXT_FINANCIER_NAME": "SHREE DHANSOBHAVAK CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13384,
      "TXT_FINANCIER_NAME": "REAL CO OPERATIVE CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13385,
      "TXT_FINANCIER_NAME": "SRI CHANDESHWAR PATTINA SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 13386,
      "TXT_FINANCIER_NAME": "SHIVPRASAD NAGARI SAHAKARI PATH SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13387,
      "TXT_FINANCIER_NAME": "BANGALORE UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 13388,
      "TXT_FINANCIER_NAME": "SSRI SAKTHI MURUGAN INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 13389,
      "TXT_FINANCIER_NAME": "SHREE VIGHNAHARTA GRAMIN BIGARSHETI SAHKARI PATSANSTHA PIMPALGOAN BASWANT"
    },
    {
      "NUM_FINANCIER_CD": 13390,
      "TXT_FINANCIER_NAME": "PADMASHREE VIKHE PATIL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13391,
      "TXT_FINANCIER_NAME": "KRISHISEVA URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 13392,
      "TXT_FINANCIER_NAME": "THE GATEWAY HOTEL"
    },
    {
      "NUM_FINANCIER_CD": 13393,
      "TXT_FINANCIER_NAME": "SOUTHERN POWER DISTRIBUTION COMPANY OF TELANGANA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13394,
      "TXT_FINANCIER_NAME": "TELANGANA STATE SOUTHERN POWER DISTRIBUTION COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13395,
      "TXT_FINANCIER_NAME": "GANGA KAVERI VIVIDADESHA SOUDHARA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13396,
      "TXT_FINANCIER_NAME": "SANCHITHA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13397,
      "TXT_FINANCIER_NAME": "OMERGA JANATA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13398,
      "TXT_FINANCIER_NAME": "ST JOSEPH PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 13399,
      "TXT_FINANCIER_NAME": "MGA FINSERV COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13400,
      "TXT_FINANCIER_NAME": "RSLD BANK"
    },
    {
      "NUM_FINANCIER_CD": 13401,
      "TXT_FINANCIER_NAME": "ANIRUDH ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 13402,
      "TXT_FINANCIER_NAME": "THE CAMPCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13403,
      "TXT_FINANCIER_NAME": "SHRI GANESH GRAMIN BIGAR SHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13404,
      "TXT_FINANCIER_NAME": "BANTWAL TALUK RUBBER AND JENU VYAVASAYGARARA VIVIDODHESHA SAHAKARI SANGHA N"
    },
    {
      "NUM_FINANCIER_CD": 13405,
      "TXT_FINANCIER_NAME": "R PANKAJ SHANKLA AUTOMOBILE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13406,
      "TXT_FINANCIER_NAME": "SRI MOOKAMBIKA SEVA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13407,
      "TXT_FINANCIER_NAME": "UJWALA HIRE PURCHASE AND LEASING"
    },
    {
      "NUM_FINANCIER_CD": 13408,
      "TXT_FINANCIER_NAME": "RAJIV KUMAR KOTHARI H U F"
    },
    {
      "NUM_FINANCIER_CD": 13409,
      "TXT_FINANCIER_NAME": "THE BHATINDA CENTRAL CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13410,
      "TXT_FINANCIER_NAME": "KORATTY HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 13411,
      "TXT_FINANCIER_NAME": "LABH FINANCH"
    },
    {
      "NUM_FINANCIER_CD": 13412,
      "TXT_FINANCIER_NAME": "TARA RANI MAHILA SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13413,
      "TXT_FINANCIER_NAME": "VIJAYA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13414,
      "TXT_FINANCIER_NAME": "TAPI GIRNA NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13415,
      "TXT_FINANCIER_NAME": "THE CATHOLIC SYRION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13416,
      "TXT_FINANCIER_NAME": "CHANDRAKANT"
    },
    {
      "NUM_FINANCIER_CD": 13417,
      "TXT_FINANCIER_NAME": "GURUSHREE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13418,
      "TXT_FINANCIER_NAME": "MOODBIDRI CO OP SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13419,
      "TXT_FINANCIER_NAME": "SULLIA TALUQ CHRISTIAN MINORITY MULTIPURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13420,
      "TXT_FINANCIER_NAME": "JIRANODHAR CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13421,
      "TXT_FINANCIER_NAME": "THE GANGA PARAMESHWARI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13422,
      "TXT_FINANCIER_NAME": "THE H R P CO OPRATIVE CREDIT AND CONSUMERS SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13423,
      "TXT_FINANCIER_NAME": "PATIDAR SAMAJ CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13424,
      "TXT_FINANCIER_NAME": "TAMIL NADU MERCHANDILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13425,
      "TXT_FINANCIER_NAME": "KERALA GRAMA PANCHAYATH ASSOCIATION"
    },
    {
      "NUM_FINANCIER_CD": 13426,
      "TXT_FINANCIER_NAME": "SHRI VITTHAL GRAHMIN BIGAR SHETTI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13427,
      "TXT_FINANCIER_NAME": "CARRIER MEDIA"
    },
    {
      "NUM_FINANCIER_CD": 13428,
      "TXT_FINANCIER_NAME": "DVN GLC BUSINESS ASS I P LTD"
    },
    {
      "NUM_FINANCIER_CD": 13429,
      "TXT_FINANCIER_NAME": "THE KHORDHA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13430,
      "TXT_FINANCIER_NAME": "SRINIDHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13431,
      "TXT_FINANCIER_NAME": "MRF CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13432,
      "TXT_FINANCIER_NAME": "ENDOLITE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13433,
      "TXT_FINANCIER_NAME": "ICRA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13434,
      "TXT_FINANCIER_NAME": "MAHARASHTRA BANK"
    },
    {
      "NUM_FINANCIER_CD": 13435,
      "TXT_FINANCIER_NAME": "OHM RAJAMURUGANCAPITAL INVEST"
    },
    {
      "NUM_FINANCIER_CD": 13436,
      "TXT_FINANCIER_NAME": "SHRIKRISHNA INDUSTRIAL CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13437,
      "TXT_FINANCIER_NAME": "THE PROTESTANT CHRISTIAN CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13438,
      "TXT_FINANCIER_NAME": "HARYANA SUGAR FED"
    },
    {
      "NUM_FINANCIER_CD": 13439,
      "TXT_FINANCIER_NAME": "SHRIKRISHNA GRAMIN BIGARSHETH SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13440,
      "TXT_FINANCIER_NAME": "PERUMATTY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13441,
      "TXT_FINANCIER_NAME": "JAI PRAKASH NARAYAN NAGARI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13442,
      "TXT_FINANCIER_NAME": "DHARMESH INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13443,
      "TXT_FINANCIER_NAME": "BIBWEWADI NAGARIK PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13444,
      "TXT_FINANCIER_NAME": "FORTUNE MULTI PURPOSE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13445,
      "TXT_FINANCIER_NAME": "KUSUMTAI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13446,
      "TXT_FINANCIER_NAME": "SAIRAJ AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13447,
      "TXT_FINANCIER_NAME": "JAI SHREE AMBE DURGAMATA CREDIT SOUHARDHA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14838,
      "TXT_FINANCIER_NAME": "SHARAD CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14839,
      "TXT_FINANCIER_NAME": "THE MUNAVALLI URBAN CO OP CREDIT SOCIETY NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14840,
      "TXT_FINANCIER_NAME": "DIRECTOR OF HEALTH SERVICE GOVT OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 14841,
      "TXT_FINANCIER_NAME": "THE GOLDEN JUBILEE CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14842,
      "TXT_FINANCIER_NAME": "KAIRANGALA VYAVASAYA SEVA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14843,
      "TXT_FINANCIER_NAME": "KALLABETTU SERVICES CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 14844,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER OPERATION MAINTENANCE RTPP"
    },
    {
      "NUM_FINANCIER_CD": 14845,
      "TXT_FINANCIER_NAME": "JAIHIND NAGRI SAHAKARI PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 14846,
      "TXT_FINANCIER_NAME": "THE KHEDA JILLA MADYASTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14847,
      "TXT_FINANCIER_NAME": "JAIBHAVANI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14848,
      "TXT_FINANCIER_NAME": "THE NASHIK JANATA SHAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14849,
      "TXT_FINANCIER_NAME": "SHREE CHATRAPATI SHIVAJI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14850,
      "TXT_FINANCIER_NAME": "EICHER MOTORS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14851,
      "TXT_FINANCIER_NAME": "MURUDHAR INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 14852,
      "TXT_FINANCIER_NAME": "G C COMMERCIAL"
    },
    {
      "NUM_FINANCIER_CD": 14853,
      "TXT_FINANCIER_NAME": "MA SAH MAHARSHI SAHEBRAO TATHA AAPPASAHEB SATKAR VAHTUK SAH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14854,
      "TXT_FINANCIER_NAME": "MA SAHAKAR MAHARSHI SAHEBRAO SATKAR VAHTUK SAH SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14855,
      "TXT_FINANCIER_NAME": "MAHARASTRA STATE HANDICAPPED FIN AND DEV CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14856,
      "TXT_FINANCIER_NAME": "SRI SUNDARLAL SAWJI JINTUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14857,
      "TXT_FINANCIER_NAME": "SHRI SHIVA SHAKTI SOUHARDA PATTIN SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14858,
      "TXT_FINANCIER_NAME": "SRI SRI AMRUTHA KRISHNA AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14859,
      "TXT_FINANCIER_NAME": "SHRI TULAJABHAWANI NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14860,
      "TXT_FINANCIER_NAME": "THE KUNJ CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14861,
      "TXT_FINANCIER_NAME": "SRI RAJAMURUGAN ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14862,
      "TXT_FINANCIER_NAME": "MATHRUBHOOMI VIVIDODESHA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14863,
      "TXT_FINANCIER_NAME": "SHRISANGAM MULTISTATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14864,
      "TXT_FINANCIER_NAME": "STAR HEALTH AND ALLIED INSURANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14865,
      "TXT_FINANCIER_NAME": "GANPATI NAMDEV BAVANE SAH PATH SANSTHA MAR SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 14866,
      "TXT_FINANCIER_NAME": "SHOBA CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14867,
      "TXT_FINANCIER_NAME": "SHREE DATTA SAHAKARI PATHSANTHSA MRYD NAGTHANE SATARA"
    },
    {
      "NUM_FINANCIER_CD": 14868,
      "TXT_FINANCIER_NAME": "BIBAKARBATI SKUS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14869,
      "TXT_FINANCIER_NAME": "BADDY FISHERMEN DEVELOPMENT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14870,
      "TXT_FINANCIER_NAME": "NISHA INVESTMENT SOWCARPET"
    },
    {
      "NUM_FINANCIER_CD": 14871,
      "TXT_FINANCIER_NAME": "THE IRDE BETTAMPADY PRIMARY AGRICULTURE CREDIT CO OP COSIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14872,
      "TXT_FINANCIER_NAME": "PARAXEL INTERNATIONAL SERVICE INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14873,
      "TXT_FINANCIER_NAME": "SUMANTHA PATHINA SOUHARDA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14874,
      "TXT_FINANCIER_NAME": "K CITI MULTIPURPOSE SOHARDHA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 15463,
      "TXT_FINANCIER_NAME": "THE DEOLA MERCHANTS CO.OP BANK LTD. DEOLA"
    },
    {
      "NUM_FINANCIER_CD": 15464,
      "TXT_FINANCIER_NAME": "GE CAPITAL TRANSPORTATION FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 15465,
      "TXT_FINANCIER_NAME": "KADAKKAL SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15466,
      "TXT_FINANCIER_NAME": "SARDAR PATEL CO-OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15467,
      "TXT_FINANCIER_NAME": "SARDAR PATEL CO-OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15468,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM INVESTMENT & FINANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15469,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA STATE CO-OP BK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15470,
      "TXT_FINANCIER_NAME": "LALBAUG CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15471,
      "TXT_FINANCIER_NAME": "LAL BAUG CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15472,
      "TXT_FINANCIER_NAME": "KOHINOOR SAHAKARI BANK LTD., ICHALKARANJI"
    },
    {
      "NUM_FINANCIER_CD": 15473,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15474,
      "TXT_FINANCIER_NAME": "Vyaparik AU. SA. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 15475,
      "TXT_FINANCIER_NAME": "THE SUTEX CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15476,
      "TXT_FINANCIER_NAME": "CO OPERATIVE BANK OF BARODA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15477,
      "TXT_FINANCIER_NAME": "THE VANI MERCHANTS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15478,
      "TXT_FINANCIER_NAME": "THE RAYAT SEVAK CO-OP BANK LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 15479,
      "TXT_FINANCIER_NAME": "THE UNION COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15480,
      "TXT_FINANCIER_NAME": "THE BHARAT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15481,
      "TXT_FINANCIER_NAME": "New agra urban cooperation Bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 15482,
      "TXT_FINANCIER_NAME": "SANTRAGACHI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15483,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15484,
      "TXT_FINANCIER_NAME": "UNITED BANK OF INDIA HAZRATGANJ LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 15485,
      "TXT_FINANCIER_NAME": "LONAVALA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15486,
      "TXT_FINANCIER_NAME": "UNION BK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 15487,
      "TXT_FINANCIER_NAME": "THE URBAN CO OP BANK LTD.DHARANGAON."
    },
    {
      "NUM_FINANCIER_CD": 15488,
      "TXT_FINANCIER_NAME": "THE BANGALORE CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15489,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15490,
      "TXT_FINANCIER_NAME": "THE GOVERNMENT SERVANTS COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15491,
      "TXT_FINANCIER_NAME": "JOHNSON & JOHNSON LTD"
    },
    {
      "NUM_FINANCIER_CD": 15492,
      "TXT_FINANCIER_NAME": "POORNAWADI NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15493,
      "TXT_FINANCIER_NAME": "VAISH COOPERATIVE NEW BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15494,
      "TXT_FINANCIER_NAME": "RAJLAXMI URBAN CO-OP.BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15495,
      "TXT_FINANCIER_NAME": "THE SHREE SUVARNA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15496,
      "TXT_FINANCIER_NAME": "AMBAJOGAI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15497,
      "TXT_FINANCIER_NAME": "THE MALLESWARAM CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15498,
      "TXT_FINANCIER_NAME": "THE MODEL CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15499,
      "TXT_FINANCIER_NAME": "UNITED FINANCE AND INVESMENTS"
    },
    {
      "NUM_FINANCIER_CD": 15500,
      "TXT_FINANCIER_NAME": "Sri M Visveswaraya Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 15501,
      "TXT_FINANCIER_NAME": "A R T LEASING PRIVATE LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 15502,
      "TXT_FINANCIER_NAME": "ABIRAMI FINANCIAL SERVICES (INDIA) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15503,
      "TXT_FINANCIER_NAME": "ABS LEASING & FINANCE ( INDIA ) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15504,
      "TXT_FINANCIER_NAME": "ABT FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15505,
      "TXT_FINANCIER_NAME": "ACHAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15506,
      "TXT_FINANCIER_NAME": "ACORN FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15507,
      "TXT_FINANCIER_NAME": "ADARSH FINANCIERS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15508,
      "TXT_FINANCIER_NAME": "ADARSH GENERAL MOTOR FINANCERS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15509,
      "TXT_FINANCIER_NAME": "ADAYAR FINANCE & LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 15510,
      "TXT_FINANCIER_NAME": "AD-MANUM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15511,
      "TXT_FINANCIER_NAME": "ADVIT AUTO FINANCE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15512,
      "TXT_FINANCIER_NAME": "AJANTHA INSTALMENTS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15513,
      "TXT_FINANCIER_NAME": "AJMANI LEASING & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15514,
      "TXT_FINANCIER_NAME": "AKAL FINLEASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15515,
      "TXT_FINANCIER_NAME": "AL BARR FINANCE HOUSE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15516,
      "TXT_FINANCIER_NAME": "ALPHA MOTOR FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15517,
      "TXT_FINANCIER_NAME": "ALTA LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15518,
      "TXT_FINANCIER_NAME": "AMARJIT FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15519,
      "TXT_FINANCIER_NAME": "AMARPREET FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15520,
      "TXT_FINANCIER_NAME": "AMRIT HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16587,
      "TXT_FINANCIER_NAME": "RAJKOT DISTRICT COPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16588,
      "TXT_FINANCIER_NAME": "SANGLI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16589,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16590,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 16591,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16592,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16593,
      "TXT_FINANCIER_NAME": "Shree Rajkot District Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16594,
      "TXT_FINANCIER_NAME": "JUBILEE HILLS MERCANTILE CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16595,
      "TXT_FINANCIER_NAME": "SAWANTWADI URBAN COOP BANK LTD SAWANTWADI"
    },
    {
      "NUM_FINANCIER_CD": 16596,
      "TXT_FINANCIER_NAME": "ICHALKARANJI JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16597,
      "TXT_FINANCIER_NAME": "GAIL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 16598,
      "TXT_FINANCIER_NAME": "SHARAD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16599,
      "TXT_FINANCIER_NAME": "CITICORP FINANCE INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16600,
      "TXT_FINANCIER_NAME": "STATE BANK OF MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 16601,
      "TXT_FINANCIER_NAME": "THE DAVANGERE-HARIHAR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16602,
      "TXT_FINANCIER_NAME": "THE SHIRPUR PEOPLES COOP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16603,
      "TXT_FINANCIER_NAME": "SHREE MAHAVIR SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16604,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FIN.SER.LTD"
    },
    {
      "NUM_FINANCIER_CD": 16605,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SER."
    },
    {
      "NUM_FINANCIER_CD": 16606,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPLE S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16607,
      "TXT_FINANCIER_NAME": "PIMPRI CHINCHWAD SAHAKARI BANK MARYADIT,PIMPRI"
    },
    {
      "NUM_FINANCIER_CD": 16608,
      "TXT_FINANCIER_NAME": "THE MALKAPUR URBAN CO OPP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16609,
      "TXT_FINANCIER_NAME": "MALVIYA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16610,
      "TXT_FINANCIER_NAME": "SHRI ANAND NAGARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16611,
      "TXT_FINANCIER_NAME": "The Jain Sahakari Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 16612,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGARIK CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16613,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAWJI URBAN CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 16614,
      "TXT_FINANCIER_NAME": "KRISHNA MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16615,
      "TXT_FINANCIER_NAME": "THE AKOLA URBAN CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 16616,
      "TXT_FINANCIER_NAME": "ASTHA MAHILA NAGRIK SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 16617,
      "TXT_FINANCIER_NAME": "STANDARD CHARTERED GRINDLAYS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16618,
      "TXT_FINANCIER_NAME": "AKOLA URBAN CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 16619,
      "TXT_FINANCIER_NAME": "CHINATRUST COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 16620,
      "TXT_FINANCIER_NAME": "THE SHEVAPET URBAN CO-OPERATIVE BANK LTD,SALEM"
    },
    {
      "NUM_FINANCIER_CD": 16621,
      "TXT_FINANCIER_NAME": "The Ahmedabad District Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16622,
      "TXT_FINANCIER_NAME": "GMAC FINANCIAL SERVICES INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 16623,
      "TXT_FINANCIER_NAME": "Citicorp Maruti Finance Limited"
    },
    {
      "NUM_FINANCIER_CD": 16624,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIMUS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16625,
      "TXT_FINANCIER_NAME": "SUNDARAM FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16626,
      "TXT_FINANCIER_NAME": "AMERICAN EXPRESS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16627,
      "TXT_FINANCIER_NAME": "MITSUI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16628,
      "TXT_FINANCIER_NAME": "BHARAT OVERSEAS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16629,
      "TXT_FINANCIER_NAME": "BANK OF THANJAVUR LTD."
    },
    {
      "NUM_FINANCIER_CD": 16630,
      "TXT_FINANCIER_NAME": "CATHOLIC SYRIAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16631,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16632,
      "TXT_FINANCIER_NAME": "SANGLI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16633,
      "TXT_FINANCIER_NAME": "SOUTH INDIAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16634,
      "TXT_FINANCIER_NAME": "VYSYA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16635,
      "TXT_FINANCIER_NAME": "ABHYUDAYA CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16636,
      "TXT_FINANCIER_NAME": "AHMEDABAD MERCANTILE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16637,
      "TXT_FINANCIER_NAME": "DEVELOPMENT CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16638,
      "TXT_FINANCIER_NAME": "JAIN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16639,
      "TXT_FINANCIER_NAME": "KONKAN MERCANTILE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16640,
      "TXT_FINANCIER_NAME": "MADHAVPURA MERC.CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16641,
      "TXT_FINANCIER_NAME": "THE MALAD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16642,
      "TXT_FINANCIER_NAME": "MAHARASHTRA STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16643,
      "TXT_FINANCIER_NAME": "MEMON CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16644,
      "TXT_FINANCIER_NAME": "MOGAVEERA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16645,
      "TXT_FINANCIER_NAME": "SHAMRAO VITHAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16646,
      "TXT_FINANCIER_NAME": "SWASTIK JANATA SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16647,
      "TXT_FINANCIER_NAME": "APNA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16648,
      "TXT_FINANCIER_NAME": "THANE PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16649,
      "TXT_FINANCIER_NAME": "THE MARATHA MANDIR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16650,
      "TXT_FINANCIER_NAME": "PRAGJYOTISH GAONLYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16651,
      "TXT_FINANCIER_NAME": "AHMEDABAD PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16652,
      "TXT_FINANCIER_NAME": "GUJRATH STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16653,
      "TXT_FINANCIER_NAME": "KALUPUR COMMERCIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16654,
      "TXT_FINANCIER_NAME": "TEXTILE TRADERS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16655,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERC.CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16656,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16657,
      "TXT_FINANCIER_NAME": "THE INVESTMENT TRUST OF INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 16658,
      "TXT_FINANCIER_NAME": "THE NEW INDIAN INDUSTRIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16659,
      "TXT_FINANCIER_NAME": "THERMAX CAPITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 16660,
      "TXT_FINANCIER_NAME": "THIND FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16661,
      "TXT_FINANCIER_NAME": "THIRUNINDRA NARAYANAN FINANCE & INVEST. (I) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16662,
      "TXT_FINANCIER_NAME": "TIRUPATI BALAJI FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16663,
      "TXT_FINANCIER_NAME": "TODI HIRE PURCHASE & LEASE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16664,
      "TXT_FINANCIER_NAME": "TOOR FINANCE CO. PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16665,
      "TXT_FINANCIER_NAME": "TRANS ASIA AUTO & GENERAL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16666,
      "TXT_FINANCIER_NAME": "TRANS WARRANTY FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16667,
      "TXT_FINANCIER_NAME": "TRANSPEK FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16668,
      "TXT_FINANCIER_NAME": "TRANSWORLD HIREPURCHASE INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 16669,
      "TXT_FINANCIER_NAME": "TRANSWORLD LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16670,
      "TXT_FINANCIER_NAME": "TRC FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16671,
      "TXT_FINANCIER_NAME": "TREASURE TRUST PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16672,
      "TXT_FINANCIER_NAME": "TRICOM FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16673,
      "TXT_FINANCIER_NAME": "TRIDENT FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16674,
      "TXT_FINANCIER_NAME": "TRIKAAL LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16675,
      "TXT_FINANCIER_NAME": "TRUE BLUE FINLEASE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16676,
      "TXT_FINANCIER_NAME": "TRUE LINK FINANCE PVT. LTDD"
    },
    {
      "NUM_FINANCIER_CD": 16677,
      "TXT_FINANCIER_NAME": "TVS FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16678,
      "TXT_FINANCIER_NAME": "U P INSTALMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16679,
      "TXT_FINANCIER_NAME": "UJAGAR ADVANCES PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16680,
      "TXT_FINANCIER_NAME": "UMEED INVESTMENTS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16681,
      "TXT_FINANCIER_NAME": "UPASANA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16682,
      "TXT_FINANCIER_NAME": "USHA MARTIN FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16683,
      "TXT_FINANCIER_NAME": "V & C VAULTS & FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16684,
      "TXT_FINANCIER_NAME": "V V D FINANCE & LEASING LTD.,NO."
    },
    {
      "NUM_FINANCIER_CD": 16685,
      "TXT_FINANCIER_NAME": "VAIRAMS HIRE PURCHASE PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 16686,
      "TXT_FINANCIER_NAME": "VALLUVAR FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16687,
      "TXT_FINANCIER_NAME": "VANKINENI HIRE PURCHASE & LEASING PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16688,
      "TXT_FINANCIER_NAME": "VARDHAN PROPERTIES & INVESTMENT LTD."
    },
    {
      "NUM_FINANCIER_CD": 11858,
      "TXT_FINANCIER_NAME": "SHUBH SHRUSTI NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11953,
      "TXT_FINANCIER_NAME": "SRI THIMMANNANAVARA VIVIDDODDESHA SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 11954,
      "TXT_FINANCIER_NAME": "SAI SEVA GRAMIN BIGAR SHETI SAHAKATI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11955,
      "TXT_FINANCIER_NAME": "BALRAM PATSANTHA PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 11956,
      "TXT_FINANCIER_NAME": "SARVODYA NAGRI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11957,
      "TXT_FINANCIER_NAME": "JYOTI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11958,
      "TXT_FINANCIER_NAME": "BHARAT EARTH MOVERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 11959,
      "TXT_FINANCIER_NAME": "SOMAVASHA SAHASRACHANA KSHATHRIYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11960,
      "TXT_FINANCIER_NAME": "SAI GRAMIN BIGARSHETHI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11961,
      "TXT_FINANCIER_NAME": "ANIMAL HUSBANDRY DEV GOVT OF INDIA PUNE"
    },
    {
      "NUM_FINANCIER_CD": 11962,
      "TXT_FINANCIER_NAME": "THIRUVARPPU VILLAGE SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11963,
      "TXT_FINANCIER_NAME": "SANJAY MAMA SHINDE GRAMIN BIGARSHETI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11964,
      "TXT_FINANCIER_NAME": "HOSDURG PRIMARY CO OP AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 11965,
      "TXT_FINANCIER_NAME": "ANNAMALAI GROUP SIVANMALAI MURUGAN MOTER FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11966,
      "TXT_FINANCIER_NAME": "AASRA MICRO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 11967,
      "TXT_FINANCIER_NAME": "SREE MAGAL CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 11968,
      "TXT_FINANCIER_NAME": "GHANASHYAMPUR SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11969,
      "TXT_FINANCIER_NAME": "THE NEW EDUCATIONAL INSTITUTE STAFF MEMBER CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11970,
      "TXT_FINANCIER_NAME": "KURUHINASHETTI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11971,
      "TXT_FINANCIER_NAME": "FRIENDS GRP SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11972,
      "TXT_FINANCIER_NAME": "SHRI SATYANARAYAN GRAMIN BIGERSHETI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11973,
      "TXT_FINANCIER_NAME": "KAY AAR DECO BUILD PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 11974,
      "TXT_FINANCIER_NAME": "SHRI DHANALAXMI PATTIN SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 11975,
      "TXT_FINANCIER_NAME": "PANIHATI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11976,
      "TXT_FINANCIER_NAME": "JAGANNATH SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11977,
      "TXT_FINANCIER_NAME": "PANASONIC ENERGY INDIA CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 11978,
      "TXT_FINANCIER_NAME": "THE VIJAY CRADIT AND SUPPLY CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 11979,
      "TXT_FINANCIER_NAME": "THE MUNGOD URBAN CO OPRATIVE CRIDECT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11980,
      "TXT_FINANCIER_NAME": "SHRI SIDDHIVINAYAK GRAMIN BIGAR SHETI PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 11981,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN MAHARAJ GRAMIN BIGAR SHETI SAHKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 11982,
      "TXT_FINANCIER_NAME": "SHRAMIK NAGARI SAH PATH SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 11983,
      "TXT_FINANCIER_NAME": "SRI SASTHA FINANCIAL SERVICSE LTD"
    },
    {
      "NUM_FINANCIER_CD": 11984,
      "TXT_FINANCIER_NAME": "RAMESH FINANCIERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 11985,
      "TXT_FINANCIER_NAME": "GANLAXMI NAG SAH SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11986,
      "TXT_FINANCIER_NAME": "MANSAROVER URBAN CO OPRETIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11987,
      "TXT_FINANCIER_NAME": "KULSWAMI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11988,
      "TXT_FINANCIER_NAME": "DYANDEO GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11989,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU PATIL NAGARI SAHAKARI PATH SANSTH LTD ASHTA"
    },
    {
      "NUM_FINANCIER_CD": 11990,
      "TXT_FINANCIER_NAME": "B KOMARAPALAYAM CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 11991,
      "TXT_FINANCIER_NAME": "ASHIRWAD CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 11992,
      "TXT_FINANCIER_NAME": "ASHIRWAD CO OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 11993,
      "TXT_FINANCIER_NAME": "SHRI GURUDEV DATTA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 11994,
      "TXT_FINANCIER_NAME": "PARIYARAM MEDICAL COLLEGE CENTRE URBAN CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 11995,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI KISANRAO VARAL PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 11996,
      "TXT_FINANCIER_NAME": "NEW KAVERI HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 11997,
      "TXT_FINANCIER_NAME": "GOVIND URBAN CREDIT COOPRATIVE SOCIETY MARYA"
    },
    {
      "NUM_FINANCIER_CD": 11998,
      "TXT_FINANCIER_NAME": "OM SAI GRAMIN BIGAR SHETI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 11999,
      "TXT_FINANCIER_NAME": "SHRI SANTAJI MAHARAJ NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12000,
      "TXT_FINANCIER_NAME": "GOPAL KRISHNA PANCHKROSHI GRAMIN BIGAR SHETI SAHAKRI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12001,
      "TXT_FINANCIER_NAME": "PONKUNNAM SER COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 12002,
      "TXT_FINANCIER_NAME": "EKTA SAHAKARI PATHPEDI MARYADIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12003,
      "TXT_FINANCIER_NAME": "AMBERNATH JAIHIND CO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12004,
      "TXT_FINANCIER_NAME": "SHREE VYANKATESH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12005,
      "TXT_FINANCIER_NAME": "SAI BABA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12006,
      "TXT_FINANCIER_NAME": "BHASKAR RAO DURVE NANA SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12007,
      "TXT_FINANCIER_NAME": "AGASHI PATPEDI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12008,
      "TXT_FINANCIER_NAME": "PERFETTI VAN MELLE INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12009,
      "TXT_FINANCIER_NAME": "VENGOLA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12010,
      "TXT_FINANCIER_NAME": "BHARGAVI FINANCE AND ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12011,
      "TXT_FINANCIER_NAME": "DATTATRAY KALAMBE MAHARAJNAGARI SAHAKARI PATH SANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12012,
      "TXT_FINANCIER_NAME": "TATED FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12013,
      "TXT_FINANCIER_NAME": "DIRECTOR DEFENCE ELECTRONICS APPLICATIONS LABORATORY"
    },
    {
      "NUM_FINANCIER_CD": 12014,
      "TXT_FINANCIER_NAME": "S R ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12015,
      "TXT_FINANCIER_NAME": "DEEP SHIKHA CO OP U T C SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12016,
      "TXT_FINANCIER_NAME": "KANYKA PRAMESHWARI GRAMIN BIGAR SHETI SHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12017,
      "TXT_FINANCIER_NAME": "BHILWARA SAHKARI BHUMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 12018,
      "TXT_FINANCIER_NAME": "SAHKARI BHUMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12019,
      "TXT_FINANCIER_NAME": "ANKITHA CREDIT SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12020,
      "TXT_FINANCIER_NAME": "SUVARNNA JYOTHI LEASING"
    },
    {
      "NUM_FINANCIER_CD": 12021,
      "TXT_FINANCIER_NAME": "SAHKARI BHOOMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12022,
      "TXT_FINANCIER_NAME": "SHRI CHOUDESHWARI TOGATVER KSHATRIYA NAGARI SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12023,
      "TXT_FINANCIER_NAME": "KANAKALOKA SOUHARDA CREDIT COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12024,
      "TXT_FINANCIER_NAME": "PITAMBAR DEALCOM PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12025,
      "TXT_FINANCIER_NAME": "DEEPAK DEPOSIT AND ADVANCES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12026,
      "TXT_FINANCIER_NAME": "PAVAMANA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12027,
      "TXT_FINANCIER_NAME": "RAGHUNANDAN GRAMIN PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12028,
      "TXT_FINANCIER_NAME": "THE GHATAPRABHA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12029,
      "TXT_FINANCIER_NAME": "AJAY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12030,
      "TXT_FINANCIER_NAME": "SRI VEMBU NITHIYAGAM"
    },
    {
      "NUM_FINANCIER_CD": 12031,
      "TXT_FINANCIER_NAME": "ANSYS SOFTWARE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12032,
      "TXT_FINANCIER_NAME": "DOABA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12033,
      "TXT_FINANCIER_NAME": "BALIRAJA NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12034,
      "TXT_FINANCIER_NAME": "ACME A CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 12035,
      "TXT_FINANCIER_NAME": "SANTKRUPA GRAMIN BIGAR SETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12036,
      "TXT_FINANCIER_NAME": "NIMISHAMBA CREDIT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12037,
      "TXT_FINANCIER_NAME": "NIMISHAMBA CREIDT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12038,
      "TXT_FINANCIER_NAME": "VIKASH BANK"
    },
    {
      "NUM_FINANCIER_CD": 12039,
      "TXT_FINANCIER_NAME": "THE VISHWADEEP URBAN CO OP CREDIT SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12040,
      "TXT_FINANCIER_NAME": "SREE MARUTHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12041,
      "TXT_FINANCIER_NAME": "BELLE VYAVASAYA SEVA SAHAKARI SANGHA NIYAMATHA"
    },
    {
      "NUM_FINANCIER_CD": 12042,
      "TXT_FINANCIER_NAME": "RELIGARE AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13448,
      "TXT_FINANCIER_NAME": "SAI RAJ AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13449,
      "TXT_FINANCIER_NAME": "MAWANTAR PATHPEDHI KALYAN"
    },
    {
      "NUM_FINANCIER_CD": 13450,
      "TXT_FINANCIER_NAME": "PANCHAMASALI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13451,
      "TXT_FINANCIER_NAME": "AGROHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13452,
      "TXT_FINANCIER_NAME": "THE MENASI GROUP URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13453,
      "TXT_FINANCIER_NAME": "MENASI SEEMEYA GROUP GRAMAGALA SEVA SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13454,
      "TXT_FINANCIER_NAME": "SRIDEVI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13455,
      "TXT_FINANCIER_NAME": "SHRI WAGHESHWAR MAHARAJ NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13456,
      "TXT_FINANCIER_NAME": "THE NAVANAGAR SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13457,
      "TXT_FINANCIER_NAME": "SAHAKAR MAHARSHI SHIVAJIRAO TATHA DADASAHEB KALE GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13458,
      "TXT_FINANCIER_NAME": "KAR CITY"
    },
    {
      "NUM_FINANCIER_CD": 13459,
      "TXT_FINANCIER_NAME": "SHREE RAJESH RADHYSHAMJI AGRAWAL"
    },
    {
      "NUM_FINANCIER_CD": 13460,
      "TXT_FINANCIER_NAME": "JAY BHARAT SAH PATSANSTHA M LTD"
    },
    {
      "NUM_FINANCIER_CD": 13461,
      "TXT_FINANCIER_NAME": "CHARAN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 13462,
      "TXT_FINANCIER_NAME": "JAI MATA CHINTPURNI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13463,
      "TXT_FINANCIER_NAME": "IDKIDU SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13464,
      "TXT_FINANCIER_NAME": "NAVBHARAT NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13465,
      "TXT_FINANCIER_NAME": "KUNDLIKA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13466,
      "TXT_FINANCIER_NAME": "CHD DISTILLERS AND BOTTLERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13467,
      "TXT_FINANCIER_NAME": "GUJRAT NARMADA VALLET FERTILIZER AND CHEMICAL CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13468,
      "TXT_FINANCIER_NAME": "SRI SRINIVASA HP FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13469,
      "TXT_FINANCIER_NAME": "CENTRAL HIRE PURCHASE CORP"
    },
    {
      "NUM_FINANCIER_CD": 13470,
      "TXT_FINANCIER_NAME": "AMMATHI PRIMARY AGRICULTURE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13471,
      "TXT_FINANCIER_NAME": "THE NATIONAL WELFARE URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13472,
      "TXT_FINANCIER_NAME": "SUVARNARTANA MULTISTATE CO OP CREDIT SOCY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13473,
      "TXT_FINANCIER_NAME": "THE HCCS URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13474,
      "TXT_FINANCIER_NAME": "SRI SAI SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13475,
      "TXT_FINANCIER_NAME": "SINDHUDURG JILHA MADHYAVARTI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13476,
      "TXT_FINANCIER_NAME": "ASHTAVINAYAK GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13477,
      "TXT_FINANCIER_NAME": "PERUVAI VYAVASAYA SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 13478,
      "TXT_FINANCIER_NAME": "SHREE DURGADEVI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13479,
      "TXT_FINANCIER_NAME": "K GORLAMUDI LSCS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13480,
      "TXT_FINANCIER_NAME": "SHREE SWAMI SAMARTH MAHILA SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13481,
      "TXT_FINANCIER_NAME": "BELGAUM TALUKA VIVIDH KARYAKARI SAHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13482,
      "TXT_FINANCIER_NAME": "JANASEVA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13483,
      "TXT_FINANCIER_NAME": "SAIRAM MULTI STATE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13484,
      "TXT_FINANCIER_NAME": "THE COASTAL VIVIDHODDESHA SAHAKARA SANGHA N MALPE"
    },
    {
      "NUM_FINANCIER_CD": 13485,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE GRAMIN BIG SHETI SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13486,
      "TXT_FINANCIER_NAME": "SANT NIVRUTTINATH VARKARI MULTI PURPOSE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13487,
      "TXT_FINANCIER_NAME": "PSN FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13488,
      "TXT_FINANCIER_NAME": "THE WEST VIPPARRU LSCS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13489,
      "TXT_FINANCIER_NAME": "ARYACHANKYA N S P S LTD"
    },
    {
      "NUM_FINANCIER_CD": 13490,
      "TXT_FINANCIER_NAME": "SRI MAHA LAXMI TRANSPORT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13491,
      "TXT_FINANCIER_NAME": "JTI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13492,
      "TXT_FINANCIER_NAME": "VASUNDHARA AGENCIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13493,
      "TXT_FINANCIER_NAME": "THE AYIROOPPARA FARMERS SERVICES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13494,
      "TXT_FINANCIER_NAME": "SHRI ADINATH FINANCE AND LEASING CO"
    },
    {
      "NUM_FINANCIER_CD": 13495,
      "TXT_FINANCIER_NAME": "LINE BAZAR COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13496,
      "TXT_FINANCIER_NAME": "AGASTI GRAMIN BIGARSHETI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 13497,
      "TXT_FINANCIER_NAME": "VANDSE SAHAKARI VYAVASAYIKA SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13498,
      "TXT_FINANCIER_NAME": "MUTTATHARA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13499,
      "TXT_FINANCIER_NAME": "SRI BASAVESWARA VIVIDODDESHA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13500,
      "TXT_FINANCIER_NAME": "KADESHWALYA VYAVASAYA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13501,
      "TXT_FINANCIER_NAME": "MAHARAJA AGRASEN URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13502,
      "TXT_FINANCIER_NAME": "BEST CAPITAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13503,
      "TXT_FINANCIER_NAME": "RAMANAGARA DISTRIC CENTRAL CO OPERATIVE CENTRAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13504,
      "TXT_FINANCIER_NAME": "NAVRATAN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 13505,
      "TXT_FINANCIER_NAME": "FRESH AND HEALTY ENTERPRISES LTD"
    },
    {
      "NUM_FINANCIER_CD": 13506,
      "TXT_FINANCIER_NAME": "VENUGOPALA KRISHNA CREDIT CO OPERATIVE SOCIETY NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13507,
      "TXT_FINANCIER_NAME": "CHIEFECTRICAL INSPECTOR GOVT AP"
    },
    {
      "NUM_FINANCIER_CD": 13508,
      "TXT_FINANCIER_NAME": "THE MASUR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13509,
      "TXT_FINANCIER_NAME": "THE VISION CO-OP (U) T/C SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13510,
      "TXT_FINANCIER_NAME": "TRIVANDRUM DISTRICT TAXI DRIVERS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13511,
      "TXT_FINANCIER_NAME": "PRASANNA NAGRI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13512,
      "TXT_FINANCIER_NAME": "VEERSHAIAV GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13513,
      "TXT_FINANCIER_NAME": "JANUBAI GRAMIN BIGAR SHETI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13514,
      "TXT_FINANCIER_NAME": "D C C"
    },
    {
      "NUM_FINANCIER_CD": 13515,
      "TXT_FINANCIER_NAME": "FINGROWTH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13516,
      "TXT_FINANCIER_NAME": "NAPAD VANTA MERCANTILE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13517,
      "TXT_FINANCIER_NAME": "SIDDHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13518,
      "TXT_FINANCIER_NAME": "MAYURA MITRA SOUHARDHA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13519,
      "TXT_FINANCIER_NAME": "BALNADU PRATHAMIKA KRISHI PATHINA SAHAKARI SANGA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13520,
      "TXT_FINANCIER_NAME": "SAIGA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 13521,
      "TXT_FINANCIER_NAME": "THE KANARA DCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13522,
      "TXT_FINANCIER_NAME": "THYGARAJANAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13523,
      "TXT_FINANCIER_NAME": "SHRI SAI PRIYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13524,
      "TXT_FINANCIER_NAME": "SWAGATH VIVIDODDESHA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13525,
      "TXT_FINANCIER_NAME": "SNEHA VIVIDHODDESHA SOUHARDA SAHAKARI N"
    },
    {
      "NUM_FINANCIER_CD": 13526,
      "TXT_FINANCIER_NAME": "DAVANGERE NAGARA PRATHAMIKA KRUSHI PATTHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13527,
      "TXT_FINANCIER_NAME": "MAHARANI LAXMIBAI NAGARI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 13528,
      "TXT_FINANCIER_NAME": "BANGADY C A BANK"
    },
    {
      "NUM_FINANCIER_CD": 13529,
      "TXT_FINANCIER_NAME": "TELANGANA STATE CIVIL SUPPLIES CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 13530,
      "TXT_FINANCIER_NAME": "THE PONMUNDAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13531,
      "TXT_FINANCIER_NAME": "THE DEDIYASAN IND CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13532,
      "TXT_FINANCIER_NAME": "KHABIYA MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13533,
      "TXT_FINANCIER_NAME": "DULICHAND BOTHRA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 13534,
      "TXT_FINANCIER_NAME": "THE PARRA VERLA CANCA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13535,
      "TXT_FINANCIER_NAME": "THE TIVIM SIRSAIM M P SERVICE COOPT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13536,
      "TXT_FINANCIER_NAME": "SHAYAM SUNDER HUF"
    },
    {
      "NUM_FINANCIER_CD": 13537,
      "TXT_FINANCIER_NAME": "SILVER STAR FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 13538,
      "TXT_FINANCIER_NAME": "ST ALOYSIUS COLLEGE TEACHERS CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13539,
      "TXT_FINANCIER_NAME": "SHUBHAM INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13540,
      "TXT_FINANCIER_NAME": "VIJAYLAXMI VIVIDHODDESHAGALA SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14877,
      "TXT_FINANCIER_NAME": "ABHISHEK HUF"
    },
    {
      "NUM_FINANCIER_CD": 14878,
      "TXT_FINANCIER_NAME": "ABI ANDUA"
    },
    {
      "NUM_FINANCIER_CD": 14879,
      "TXT_FINANCIER_NAME": "ADARASH CO OP URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14880,
      "TXT_FINANCIER_NAME": "ADARSA LAKSHMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14881,
      "TXT_FINANCIER_NAME": "ADARSH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14882,
      "TXT_FINANCIER_NAME": "ADARSH NAGRIK SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14883,
      "TXT_FINANCIER_NAME": "ADURJEE & BROS. P LTD."
    },
    {
      "NUM_FINANCIER_CD": 14884,
      "TXT_FINANCIER_NAME": "AF-TAAB INVESTMENT COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14885,
      "TXT_FINANCIER_NAME": "AHMEDABAD DIST CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14886,
      "TXT_FINANCIER_NAME": "AJANTA MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 14887,
      "TXT_FINANCIER_NAME": "AJAY TRADING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14888,
      "TXT_FINANCIER_NAME": "AKAHND ANAND CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14889,
      "TXT_FINANCIER_NAME": "AKOLA URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 14890,
      "TXT_FINANCIER_NAME": "AKSHAYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14891,
      "TXT_FINANCIER_NAME": "ALIBAGH CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14892,
      "TXT_FINANCIER_NAME": "ALMORA ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14893,
      "TXT_FINANCIER_NAME": "AMAR AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14894,
      "TXT_FINANCIER_NAME": "AMAR FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14895,
      "TXT_FINANCIER_NAME": "AMARNATH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14896,
      "TXT_FINANCIER_NAME": "AMBARNATH JAIHIND CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14897,
      "TXT_FINANCIER_NAME": "AMBEJOGAI PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14898,
      "TXT_FINANCIER_NAME": "AMEETA AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14899,
      "TXT_FINANCIER_NAME": "AMLEHAR JADID CAS LTD"
    },
    {
      "NUM_FINANCIER_CD": 14900,
      "TXT_FINANCIER_NAME": "AMMAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14901,
      "TXT_FINANCIER_NAME": "AMPAR COOP AGRICULTURAL BANK LTD AMPAR"
    },
    {
      "NUM_FINANCIER_CD": 14902,
      "TXT_FINANCIER_NAME": "AMVALIYASAN"
    },
    {
      "NUM_FINANCIER_CD": 14903,
      "TXT_FINANCIER_NAME": "ANANDSONS AUTO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 14904,
      "TXT_FINANCIER_NAME": "ANDHRA PRASDESH INDUSTRIAL INFRASTRUCTURE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14905,
      "TXT_FINANCIER_NAME": "ANIDA JUTH VIKAS MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 14906,
      "TXT_FINANCIER_NAME": "ANUPAM MOTOR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14907,
      "TXT_FINANCIER_NAME": "AP NPDCL"
    },
    {
      "NUM_FINANCIER_CD": 14908,
      "TXT_FINANCIER_NAME": "AP POWER GENERATION CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14909,
      "TXT_FINANCIER_NAME": "AP TECHNOLOGY SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 14910,
      "TXT_FINANCIER_NAME": "AP TRANSCO"
    },
    {
      "NUM_FINANCIER_CD": 14911,
      "TXT_FINANCIER_NAME": "APCOB"
    },
    {
      "NUM_FINANCIER_CD": 14912,
      "TXT_FINANCIER_NAME": "APD CO OPERATIVE AGRICULTURE AND DEVELOPMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14913,
      "TXT_FINANCIER_NAME": "APFDC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14914,
      "TXT_FINANCIER_NAME": "APGENCO"
    },
    {
      "NUM_FINANCIER_CD": 14915,
      "TXT_FINANCIER_NAME": "APHB COLONY GUNTUR"
    },
    {
      "NUM_FINANCIER_CD": 14916,
      "TXT_FINANCIER_NAME": "APSTATE CIVIL SUPPLIES COR LTD"
    },
    {
      "NUM_FINANCIER_CD": 14917,
      "TXT_FINANCIER_NAME": "APTRANSCO VIDYUTH SOUDHA"
    },
    {
      "NUM_FINANCIER_CD": 14918,
      "TXT_FINANCIER_NAME": "ARBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 14919,
      "TXT_FINANCIER_NAME": "ARYART GRAMIN BANK RUDAULI"
    },
    {
      "NUM_FINANCIER_CD": 14920,
      "TXT_FINANCIER_NAME": "ATOMIC MINERALS DIRECTORATE FOR EXPLORATION AND RESEARCH"
    },
    {
      "NUM_FINANCIER_CD": 14921,
      "TXT_FINANCIER_NAME": "B ALMEL URBAN COOP BANK LTD ALMEL"
    },
    {
      "NUM_FINANCIER_CD": 14922,
      "TXT_FINANCIER_NAME": "B MARKANDEY CO OP SOCIETY BRANCH MANNUR"
    },
    {
      "NUM_FINANCIER_CD": 14923,
      "TXT_FINANCIER_NAME": "B. HOSAHALLI PRIMARY AGRICULTURE CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14924,
      "TXT_FINANCIER_NAME": "B.RAMESHCHAND & SONS AUTOMOBILE FINANCIERS."
    },
    {
      "NUM_FINANCIER_CD": 14925,
      "TXT_FINANCIER_NAME": "B.RAMESHCHAND SHANKLA"
    },
    {
      "NUM_FINANCIER_CD": 14926,
      "TXT_FINANCIER_NAME": "BADHRACHALAM"
    },
    {
      "NUM_FINANCIER_CD": 14927,
      "TXT_FINANCIER_NAME": "BAGHRAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14928,
      "TXT_FINANCIER_NAME": "BALAGI NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14929,
      "TXT_FINANCIER_NAME": "BALAJI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14930,
      "TXT_FINANCIER_NAME": "BALARAMAPURAM"
    },
    {
      "NUM_FINANCIER_CD": 14931,
      "TXT_FINANCIER_NAME": "BALLARD ESTATE"
    },
    {
      "NUM_FINANCIER_CD": 14932,
      "TXT_FINANCIER_NAME": "BALLIA ITAWAH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14933,
      "TXT_FINANCIER_NAME": "BALMEL URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14934,
      "TXT_FINANCIER_NAME": "BANAVARA BRANCH ARASIKERE TALUK"
    },
    {
      "NUM_FINANCIER_CD": 14935,
      "TXT_FINANCIER_NAME": "BANDA URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14936,
      "TXT_FINANCIER_NAME": "BANK OF HYDERABAD"
    },
    {
      "NUM_FINANCIER_CD": 14937,
      "TXT_FINANCIER_NAME": "BANK OF INDIA NARKAPUR"
    },
    {
      "NUM_FINANCIER_CD": 14938,
      "TXT_FINANCIER_NAME": "BANK OF INDIA PANDURANGAPURAM"
    },
    {
      "NUM_FINANCIER_CD": 14939,
      "TXT_FINANCIER_NAME": "BANK OF KURHAKESHAVPUR"
    },
    {
      "NUM_FINANCIER_CD": 14940,
      "TXT_FINANCIER_NAME": "BANKHANDI CO OP AGRICULTURE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14941,
      "TXT_FINANCIER_NAME": "BARODA U P GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14942,
      "TXT_FINANCIER_NAME": "BARODA UTTAR PARDESH PRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14943,
      "TXT_FINANCIER_NAME": "BASAVESHWAR COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14944,
      "TXT_FINANCIER_NAME": "BASAVESHWARA URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 14945,
      "TXT_FINANCIER_NAME": "BASROOR VYAVASAYA SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14946,
      "TXT_FINANCIER_NAME": "BCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 14947,
      "TXT_FINANCIER_NAME": "BELAPU CO OP AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14948,
      "TXT_FINANCIER_NAME": "BELAPU CO OPERATIVE AGRICUTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14949,
      "TXT_FINANCIER_NAME": "BELGAUM INDUSTRIAL CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14950,
      "TXT_FINANCIER_NAME": "BHAGHYANAGAR FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 14951,
      "TXT_FINANCIER_NAME": "BHAGYAODAYA SAHAKARI PATPEDI"
    },
    {
      "NUM_FINANCIER_CD": 14952,
      "TXT_FINANCIER_NAME": "BHAGYODAY URBAN COOP CREDIT AND CONSU SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14953,
      "TXT_FINANCIER_NAME": "BHANJAPUR BARIPADA EVENING BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 14954,
      "TXT_FINANCIER_NAME": "BHARDWAJ FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14955,
      "TXT_FINANCIER_NAME": "BHARTI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14956,
      "TXT_FINANCIER_NAME": "BHARTI AXA GIC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14957,
      "TXT_FINANCIER_NAME": "BHARTI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14958,
      "TXT_FINANCIER_NAME": "BHASKAR AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14959,
      "TXT_FINANCIER_NAME": "BHAVESHWARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14960,
      "TXT_FINANCIER_NAME": "BHRAMAWART COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14961,
      "TXT_FINANCIER_NAME": "BHUMIVIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14962,
      "TXT_FINANCIER_NAME": "BOKARO STEEL LTD"
    },
    {
      "NUM_FINANCIER_CD": 14963,
      "TXT_FINANCIER_NAME": "BONGIA GRAMIN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 14964,
      "TXT_FINANCIER_NAME": "BRAMANWADA SAHAKARI PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 14965,
      "TXT_FINANCIER_NAME": "C. MAHENDRA CAPITAL PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 14966,
      "TXT_FINANCIER_NAME": "CCDC BANK MUDIGERE BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 14967,
      "TXT_FINANCIER_NAME": "CENTRA"
    },
    {
      "NUM_FINANCIER_CD": 14968,
      "TXT_FINANCIER_NAME": "CHADA HIRE PURCHASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14969,
      "TXT_FINANCIER_NAME": "CHENNAYANA KOTE BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 14970,
      "TXT_FINANCIER_NAME": "CHERUKURI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14971,
      "TXT_FINANCIER_NAME": "CHHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14972,
      "TXT_FINANCIER_NAME": "CITI FINANCIAL"
    },
    {
      "NUM_FINANCIER_CD": 14973,
      "TXT_FINANCIER_NAME": "CITY BANK NA"
    },
    {
      "NUM_FINANCIER_CD": 14974,
      "TXT_FINANCIER_NAME": "CJ FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14975,
      "TXT_FINANCIER_NAME": "COCHIN PORT TRUST BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 14976,
      "TXT_FINANCIER_NAME": "CONPAI COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14977,
      "TXT_FINANCIER_NAME": "COOP BANK OF RAJKOT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14978,
      "TXT_FINANCIER_NAME": "CVPPP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14979,
      "TXT_FINANCIER_NAME": "D SUMAN"
    },
    {
      "NUM_FINANCIER_CD": 14980,
      "TXT_FINANCIER_NAME": "DABOLI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14981,
      "TXT_FINANCIER_NAME": "DAGUNANA MORE GRAMIN BIGAR SHETI SAH PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14982,
      "TXT_FINANCIER_NAME": "DAINIK NAGRIK SEHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14983,
      "TXT_FINANCIER_NAME": "DASHNESH AUTO LINE"
    },
    {
      "NUM_FINANCIER_CD": 14984,
      "TXT_FINANCIER_NAME": "DCC BANK LTD.BIDAR"
    },
    {
      "NUM_FINANCIER_CD": 14985,
      "TXT_FINANCIER_NAME": "DCD BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14986,
      "TXT_FINANCIER_NAME": "DEEWAN HOUSING FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14987,
      "TXT_FINANCIER_NAME": "DELHI METRO RAIL CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14988,
      "TXT_FINANCIER_NAME": "DEVI GAYATHRI CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14989,
      "TXT_FINANCIER_NAME": "DEVI GAYATRI CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14990,
      "TXT_FINANCIER_NAME": "DHANGURUNANAK ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14991,
      "TXT_FINANCIER_NAME": "DHANVANTRI NAGRI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14992,
      "TXT_FINANCIER_NAME": "DOB BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14993,
      "TXT_FINANCIER_NAME": "DR RAM MANOHAR LOHIYA NAGARI SAH PATSANSTHA MARYADITH"
    },
    {
      "NUM_FINANCIER_CD": 14994,
      "TXT_FINANCIER_NAME": "E D P BANK"
    },
    {
      "NUM_FINANCIER_CD": 14995,
      "TXT_FINANCIER_NAME": "ELLAQUI DEHATI BANK HUMHAMA BUDGAM"
    },
    {
      "NUM_FINANCIER_CD": 16689,
      "TXT_FINANCIER_NAME": "VASAVI FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16690,
      "TXT_FINANCIER_NAME": "VENKATADRI FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16691,
      "TXT_FINANCIER_NAME": "VIJAY HEMANT FINANCE & ESTATES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16692,
      "TXT_FINANCIER_NAME": "VIRK HIRE PURCHASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 16693,
      "TXT_FINANCIER_NAME": "VISHAL FINLEASE P. LTD. SHANTIKUNJ"
    },
    {
      "NUM_FINANCIER_CD": 16694,
      "TXT_FINANCIER_NAME": "VIVEK HIRE PURCHASE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16695,
      "TXT_FINANCIER_NAME": "VULCAN LEASING & INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 16696,
      "TXT_FINANCIER_NAME": "WALCHAND HINDUSTAN LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 16697,
      "TXT_FINANCIER_NAME": "WELLSHINE INVESTMENTS & FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16698,
      "TXT_FINANCIER_NAME": "WEST BENGAL INFRASTRUCTURE DEVELOPMENT FINANCE CORPN LTD"
    },
    {
      "NUM_FINANCIER_CD": 16699,
      "TXT_FINANCIER_NAME": "THE AKOLA JANATA COMMERCIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16700,
      "TXT_FINANCIER_NAME": "SHIVAJIRAO BHOSALE SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16701,
      "TXT_FINANCIER_NAME": "THE GOA STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16702,
      "TXT_FINANCIER_NAME": "KOLHAPUR DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16703,
      "TXT_FINANCIER_NAME": "GMAC TCFC FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16704,
      "TXT_FINANCIER_NAME": "YOUTH DEVELOPMENT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16705,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16706,
      "TXT_FINANCIER_NAME": "BHAGYODAYA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16707,
      "TXT_FINANCIER_NAME": "JALGAON JANTA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16708,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16709,
      "TXT_FINANCIER_NAME": "THE GADHINGLAJ URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16710,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16711,
      "TXT_FINANCIER_NAME": "THE RATNAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16712,
      "TXT_FINANCIER_NAME": "The Manmandir Co- Op Bank Ltd.,Vita"
    },
    {
      "NUM_FINANCIER_CD": 16713,
      "TXT_FINANCIER_NAME": "CREDIT AGRICOLE INDOSUEZ"
    },
    {
      "NUM_FINANCIER_CD": 16714,
      "TXT_FINANCIER_NAME": "THE VYASA BANK"
    },
    {
      "NUM_FINANCIER_CD": 16715,
      "TXT_FINANCIER_NAME": "THE UNION CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16716,
      "TXT_FINANCIER_NAME": "THE UNION CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16717,
      "TXT_FINANCIER_NAME": "THE STATE TRANSPORT (E) CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16718,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16719,
      "TXT_FINANCIER_NAME": "THE PATIALA CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16720,
      "TXT_FINANCIER_NAME": "GUJARAT MERCANTILE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16721,
      "TXT_FINANCIER_NAME": "GUJARAT MERCANTILE CO. OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16722,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16723,
      "TXT_FINANCIER_NAME": "BRAHMAWART COMMERCIAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16724,
      "TXT_FINANCIER_NAME": "CHOUNDESHWARI SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16725,
      "TXT_FINANCIER_NAME": "CHOUNDESHWARI SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16726,
      "TXT_FINANCIER_NAME": "PIMPRI CHINCHWAD SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16727,
      "TXT_FINANCIER_NAME": "NEW INDIA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16728,
      "TXT_FINANCIER_NAME": "THE NIRMAL URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16729,
      "TXT_FINANCIER_NAME": "CITY CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16730,
      "TXT_FINANCIER_NAME": "THE RAJWADE MANDAL PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16731,
      "TXT_FINANCIER_NAME": "SHRI KANYAKA NAGARI SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16732,
      "TXT_FINANCIER_NAME": "THE DAHANU ROAD JANATA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16733,
      "TXT_FINANCIER_NAME": "LORD KRISHNA BANK"
    },
    {
      "NUM_FINANCIER_CD": 16734,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR PEOPLE S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16735,
      "TXT_FINANCIER_NAME": "SHREE MAHESH CO- OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16736,
      "TXT_FINANCIER_NAME": "VEERASHAIVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16737,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN.SER.LTD."
    },
    {
      "NUM_FINANCIER_CD": 16738,
      "TXT_FINANCIER_NAME": "DEOLA MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16739,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN STATE INDUSTRIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16740,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16741,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FIN.SER.PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 16742,
      "TXT_FINANCIER_NAME": "THE MULGUND URBAN SOUHARDA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16743,
      "TXT_FINANCIER_NAME": "VIJAY COMMERCIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16744,
      "TXT_FINANCIER_NAME": "THE JANATH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16745,
      "TXT_FINANCIER_NAME": "THE NANDURA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16746,
      "TXT_FINANCIER_NAME": "NSPCL"
    },
    {
      "NUM_FINANCIER_CD": 16747,
      "TXT_FINANCIER_NAME": "DANDONA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16748,
      "TXT_FINANCIER_NAME": "THE BELGAUM DISTRICT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16749,
      "TXT_FINANCIER_NAME": "PARNER TALUKA SAINIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16750,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16751,
      "TXT_FINANCIER_NAME": "DREDGING CORPORATING INDIA"
    },
    {
      "NUM_FINANCIER_CD": 16752,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE CO-OP APEX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16753,
      "TXT_FINANCIER_NAME": "PATAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16754,
      "TXT_FINANCIER_NAME": "BARODA PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16755,
      "TXT_FINANCIER_NAME": "BARODA CITY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16756,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE BANK BARODA LTD"
    },
    {
      "NUM_FINANCIER_CD": 16757,
      "TXT_FINANCIER_NAME": "GUJRATH INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16758,
      "TXT_FINANCIER_NAME": "PUNE PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16759,
      "TXT_FINANCIER_NAME": "RUPEE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16760,
      "TXT_FINANCIER_NAME": "POONA CONTRACTOR CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16761,
      "TXT_FINANCIER_NAME": "PUNE ZILLA MADHYA. SAH. BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16762,
      "TXT_FINANCIER_NAME": "RATNAKAR BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16763,
      "TXT_FINANCIER_NAME": "KERALA STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16764,
      "TXT_FINANCIER_NAME": "NAINITAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16765,
      "TXT_FINANCIER_NAME": "BENARAS STATE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16766,
      "TXT_FINANCIER_NAME": "GRAIN MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16767,
      "TXT_FINANCIER_NAME": "TRIVANDRUM CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16768,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MER. CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16769,
      "TXT_FINANCIER_NAME": "RAJKOT NAGRIK SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16770,
      "TXT_FINANCIER_NAME": "APEX BANK"
    },
    {
      "NUM_FINANCIER_CD": 16771,
      "TXT_FINANCIER_NAME": "GLOBAL TRUST BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16772,
      "TXT_FINANCIER_NAME": "CREDIT LYONNAIS"
    },
    {
      "NUM_FINANCIER_CD": 16773,
      "TXT_FINANCIER_NAME": "THE SINDH MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16774,
      "TXT_FINANCIER_NAME": "BANK OF PUNJAB LTD."
    },
    {
      "NUM_FINANCIER_CD": 16775,
      "TXT_FINANCIER_NAME": "BASSEIN CATHOLIC CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16776,
      "TXT_FINANCIER_NAME": "THE BANK OF NOVA SCOTIA"
    },
    {
      "NUM_FINANCIER_CD": 16777,
      "TXT_FINANCIER_NAME": "DIAMOND JUBILEE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16778,
      "TXT_FINANCIER_NAME": "SURAT TEXTILE TRADERS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16779,
      "TXT_FINANCIER_NAME": "THE SARVODAYA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16780,
      "TXT_FINANCIER_NAME": "SURAT PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16781,
      "TXT_FINANCIER_NAME": "SURAT MAHILA NAG. SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16782,
      "TXT_FINANCIER_NAME": "SARDAR VALLABHBHAI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16783,
      "TXT_FINANCIER_NAME": "IDBI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16784,
      "TXT_FINANCIER_NAME": "DEEPAK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16785,
      "TXT_FINANCIER_NAME": "THE MYSORE SILK CLOTH MERCANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16786,
      "TXT_FINANCIER_NAME": "SIKKIM BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16787,
      "TXT_FINANCIER_NAME": "KODAK MAHINDRA PRIME LTD"
    },
    {
      "NUM_FINANCIER_CD": 16788,
      "TXT_FINANCIER_NAME": "UNITED FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12043,
      "TXT_FINANCIER_NAME": "S CHITRALEKHA SR MNGR HR"
    },
    {
      "NUM_FINANCIER_CD": 12044,
      "TXT_FINANCIER_NAME": "ALTERNATIVE INVESTMENTS AND CREDITS LTD"
    },
    {
      "NUM_FINANCIER_CD": 12045,
      "TXT_FINANCIER_NAME": "THE ROHTAK CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12046,
      "TXT_FINANCIER_NAME": "HOTEL INDUSTRIALIST CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12047,
      "TXT_FINANCIER_NAME": "MAHANKALI NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12048,
      "TXT_FINANCIER_NAME": "JANATA GRAMIN BIGAR SHETI SAH PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12049,
      "TXT_FINANCIER_NAME": "BHARGAVA FINANCE AND LEASING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 12050,
      "TXT_FINANCIER_NAME": "SREE NANDHADEVI FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12051,
      "TXT_FINANCIER_NAME": "ARUL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12052,
      "TXT_FINANCIER_NAME": "PRIYADARSHINI NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12053,
      "TXT_FINANCIER_NAME": "SHRI SAI SIDDHESHWAR NAGRIK SAHAKARI PATHPEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12054,
      "TXT_FINANCIER_NAME": "SVS BANK"
    },
    {
      "NUM_FINANCIER_CD": 12055,
      "TXT_FINANCIER_NAME": "NPCIL"
    },
    {
      "NUM_FINANCIER_CD": 12056,
      "TXT_FINANCIER_NAME": "KRISHI AND KRISHI KAIGARIKA UTPADAKAR VIVIDODDESH SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12057,
      "TXT_FINANCIER_NAME": "ANAND RUSHIJI SAHAKARI PATASANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12058,
      "TXT_FINANCIER_NAME": "ANMOL FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 12059,
      "TXT_FINANCIER_NAME": "RISHAB FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12060,
      "TXT_FINANCIER_NAME": "RAVI FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12061,
      "TXT_FINANCIER_NAME": "REENU ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 12062,
      "TXT_FINANCIER_NAME": "THE SONIKAMPA GROUP SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12063,
      "TXT_FINANCIER_NAME": "THE ANNSAHEB SAVANT CO OP URBAN BANK MAHAD LTD"
    },
    {
      "NUM_FINANCIER_CD": 12064,
      "TXT_FINANCIER_NAME": "NAKODA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12065,
      "TXT_FINANCIER_NAME": "KAI ANNASAHEB LONARI NAGAR SAHAKARI PATHASANTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12066,
      "TXT_FINANCIER_NAME": "T JAYARAMAN HIRE PURCHASE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12067,
      "TXT_FINANCIER_NAME": "MAHALAXMI SOUHARDHA COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12068,
      "TXT_FINANCIER_NAME": "SHRI JUNI NAVI SANKLI SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12069,
      "TXT_FINANCIER_NAME": "NANDINI CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12070,
      "TXT_FINANCIER_NAME": "SRI VISHWABANDHU CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12071,
      "TXT_FINANCIER_NAME": "SRI SAI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12072,
      "TXT_FINANCIER_NAME": "SHRI ASHTAGAM VIBHAG SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12073,
      "TXT_FINANCIER_NAME": "BALU AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12074,
      "TXT_FINANCIER_NAME": "L PRASANCHAND KOTHARI AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 12075,
      "TXT_FINANCIER_NAME": "OMKAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12076,
      "TXT_FINANCIER_NAME": "NAVODAYA SC MULTIPURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12077,
      "TXT_FINANCIER_NAME": "PARAKH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12078,
      "TXT_FINANCIER_NAME": "LAXMI BALASAHEB NAGARI SAHAKARI PATSANSTHA LTD CHIPLUN"
    },
    {
      "NUM_FINANCIER_CD": 12079,
      "TXT_FINANCIER_NAME": "DATTATRAYA GRAMIN AND NAGRARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12080,
      "TXT_FINANCIER_NAME": "KHERADI GROUP SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12081,
      "TXT_FINANCIER_NAME": "RAJADHANI FINANCIAL AND INVESTEMENT CORP"
    },
    {
      "NUM_FINANCIER_CD": 12082,
      "TXT_FINANCIER_NAME": "SHRI HANUMAN GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12083,
      "TXT_FINANCIER_NAME": "SHAKUNTALAM INVESTMENT AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 12084,
      "TXT_FINANCIER_NAME": "BABULAL JAIN FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 12085,
      "TXT_FINANCIER_NAME": "DR KY GADEKAR DHANVATORI NAGARI SAHAKARI PAT SAN MARY"
    },
    {
      "NUM_FINANCIER_CD": 12086,
      "TXT_FINANCIER_NAME": "BHAUSAHEB SAKHARAM FARGADE GRAMIN BIGAR SHETI SAHAKARI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 12087,
      "TXT_FINANCIER_NAME": "DASS EIN CATHALIC CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12088,
      "TXT_FINANCIER_NAME": "PARAMOUNT FINANCIAL ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 12089,
      "TXT_FINANCIER_NAME": "HENA INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12090,
      "TXT_FINANCIER_NAME": "MONEY FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 12091,
      "TXT_FINANCIER_NAME": "DINDAYAL NAGARI SAHAKARI PATHSAUNSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12092,
      "TXT_FINANCIER_NAME": "FULTERTON INDIA CREDIT COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12093,
      "TXT_FINANCIER_NAME": "PROMISE LEASING AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12094,
      "TXT_FINANCIER_NAME": "HARDIK AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12095,
      "TXT_FINANCIER_NAME": "KRUPA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12096,
      "TXT_FINANCIER_NAME": "SHRI ACHLESHWAR FIN CAP S LTD"
    },
    {
      "NUM_FINANCIER_CD": 12097,
      "TXT_FINANCIER_NAME": "THE F2K CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12098,
      "TXT_FINANCIER_NAME": "DEEPTI FIN LEASE"
    },
    {
      "NUM_FINANCIER_CD": 12099,
      "TXT_FINANCIER_NAME": "THE MUGU SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12100,
      "TXT_FINANCIER_NAME": "KIRAN GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12101,
      "TXT_FINANCIER_NAME": "PRAKASHCHAND JAIN NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12102,
      "TXT_FINANCIER_NAME": "YASH NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12103,
      "TXT_FINANCIER_NAME": "G G CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 12104,
      "TXT_FINANCIER_NAME": "JYOTI KRANTI PATHSANTHA MAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12105,
      "TXT_FINANCIER_NAME": "ETAWAH DISTRICT COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12106,
      "TXT_FINANCIER_NAME": "SANGEETHA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 12107,
      "TXT_FINANCIER_NAME": "MANDAVI NAGRIK SAHAKARI BNAK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12108,
      "TXT_FINANCIER_NAME": "SHRI BALIRAJA KURKUNDESHWAR BIGAR SHETI SAH PATH SANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 12109,
      "TXT_FINANCIER_NAME": "THE PEOPLES SAHAKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12110,
      "TXT_FINANCIER_NAME": "KODIMATHA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12111,
      "TXT_FINANCIER_NAME": "GURJAT LEASING AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12112,
      "TXT_FINANCIER_NAME": "D DEVARAJ URS BACKWORD CLASSES DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12113,
      "TXT_FINANCIER_NAME": "P B FINVEST PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12114,
      "TXT_FINANCIER_NAME": "AUTO LEASE AND HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 12115,
      "TXT_FINANCIER_NAME": "SIKANDER PUR CO OP AGRI MULTI PURPOSE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12116,
      "TXT_FINANCIER_NAME": "SHRAMJIVI NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12117,
      "TXT_FINANCIER_NAME": "RATNATRAYA URBAN CREDIT SOUHARD SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12118,
      "TXT_FINANCIER_NAME": "MUTHOLY EAST SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12119,
      "TXT_FINANCIER_NAME": "THE MERCANTILE AUTO CO OPERATIVE MULTIPURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12120,
      "TXT_FINANCIER_NAME": "SENT MILAGRICE SAVHARD CO OPRETIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12121,
      "TXT_FINANCIER_NAME": "THE ARYAPURAM COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12122,
      "TXT_FINANCIER_NAME": "THE PRAGATICREDIT AND SAHAKARI DHIRAN SANGH MANDALILTD"
    },
    {
      "NUM_FINANCIER_CD": 12123,
      "TXT_FINANCIER_NAME": "SREENITHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12124,
      "TXT_FINANCIER_NAME": "SHRI SHANSAGAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12125,
      "TXT_FINANCIER_NAME": "PANANCHERY SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12126,
      "TXT_FINANCIER_NAME": "SHRI YASHWANT NAGARI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12127,
      "TXT_FINANCIER_NAME": "PRAGATI GRAMIN BIG SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12128,
      "TXT_FINANCIER_NAME": "DHARAM CHAND CHALLANI"
    },
    {
      "NUM_FINANCIER_CD": 12129,
      "TXT_FINANCIER_NAME": "SHRI B Y PATIL NAGARI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12130,
      "TXT_FINANCIER_NAME": "DESHBHAKTAJILAPA RAYAPPA KHOT GRAMSEVA VIVIDH KARYAKARI SAH VIKAS SEVA SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12131,
      "TXT_FINANCIER_NAME": "SAJJAN RAJ C"
    },
    {
      "NUM_FINANCIER_CD": 12132,
      "TXT_FINANCIER_NAME": "SRI CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12133,
      "TXT_FINANCIER_NAME": "SHRI SANTRAM CO OP CRE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12134,
      "TXT_FINANCIER_NAME": "ALIPURDUAR COOPERATIVE AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12135,
      "TXT_FINANCIER_NAME": "THE MAHARASTRA NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13541,
      "TXT_FINANCIER_NAME": "MEWAR FINLEASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13542,
      "TXT_FINANCIER_NAME": "THE MORVA VIBAGH NAGRIK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13543,
      "TXT_FINANCIER_NAME": "TITAN TIMEPRODUCTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13544,
      "TXT_FINANCIER_NAME": "SANGAMNER TALUKA VIT UTPADKANCHI NAAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13545,
      "TXT_FINANCIER_NAME": "SMT VIMALABAI PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 13546,
      "TXT_FINANCIER_NAME": "SHRI RAMALINGESHWAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13547,
      "TXT_FINANCIER_NAME": "SHRI ASHTAVINAYAK MULTI PURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13548,
      "TXT_FINANCIER_NAME": "MAA JAGDAMBA ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 13549,
      "TXT_FINANCIER_NAME": "BELGAON MARATHI MITRA MANDAL NAG SAH PATSANSTRA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 13550,
      "TXT_FINANCIER_NAME": "GAYATRI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 13551,
      "TXT_FINANCIER_NAME": "SRI VIGNESHWARA M S C P LTD"
    },
    {
      "NUM_FINANCIER_CD": 13552,
      "TXT_FINANCIER_NAME": "GOVERMENT OF JHARKHAND FINANCE DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13553,
      "TXT_FINANCIER_NAME": "VINOD KUMAR KHABIYA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 13554,
      "TXT_FINANCIER_NAME": "PAKISTAN BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 13555,
      "TXT_FINANCIER_NAME": "SARASWATHI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13556,
      "TXT_FINANCIER_NAME": "P DHILIP KUMAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13557,
      "TXT_FINANCIER_NAME": "SHIVA VIVIDODDESHA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13558,
      "TXT_FINANCIER_NAME": "SHIVA VIVIDIDDESHA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13559,
      "TXT_FINANCIER_NAME": "AMIT GUNDECHA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13560,
      "TXT_FINANCIER_NAME": "SBI GENERAL INSURANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13561,
      "TXT_FINANCIER_NAME": "PANVEL JANATA SAHAKARI PATHPEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13562,
      "TXT_FINANCIER_NAME": "PANVEL JANTA SAHKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13563,
      "TXT_FINANCIER_NAME": "NAVKAR SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 13564,
      "TXT_FINANCIER_NAME": "SRI BANASHANKARI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13565,
      "TXT_FINANCIER_NAME": "ASHOK FIN CORP"
    },
    {
      "NUM_FINANCIER_CD": 13566,
      "TXT_FINANCIER_NAME": "THE AMANAT CO OP CREDIT SOCITEY NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13567,
      "TXT_FINANCIER_NAME": "CHAMELI DEVI PUBLIC SCHOOL"
    },
    {
      "NUM_FINANCIER_CD": 13568,
      "TXT_FINANCIER_NAME": "SHRI MANGALNATH MUL CO OP CREDIT SCO LTD AURANGABAD"
    },
    {
      "NUM_FINANCIER_CD": 13569,
      "TXT_FINANCIER_NAME": "JAI KALI MATA MAHILA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13570,
      "TXT_FINANCIER_NAME": "SUMAN DEVI SURANA"
    },
    {
      "NUM_FINANCIER_CD": 13571,
      "TXT_FINANCIER_NAME": "ALANKARPUR S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 13572,
      "TXT_FINANCIER_NAME": "DHBVN"
    },
    {
      "NUM_FINANCIER_CD": 13573,
      "TXT_FINANCIER_NAME": "SAHAKARMITRA MADHUKAR SAHAKARI PATHPEDHI MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 13574,
      "TXT_FINANCIER_NAME": "SRI MAHALAXMI FINANCES"
    },
    {
      "NUM_FINANCIER_CD": 13575,
      "TXT_FINANCIER_NAME": "KAHNOOR PATHAR MULTISTATE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 13576,
      "TXT_FINANCIER_NAME": "SRI NAMAGIRI LAKSHMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13577,
      "TXT_FINANCIER_NAME": "JAIN OSWAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13578,
      "TXT_FINANCIER_NAME": "LAXMI FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 13579,
      "TXT_FINANCIER_NAME": "ROTARY CLUB OF CENTRAL CALCUTTA TRUST"
    },
    {
      "NUM_FINANCIER_CD": 13580,
      "TXT_FINANCIER_NAME": "EX ARMY MULTI PURPOSE CO OP SOCIETY MUTAGA"
    },
    {
      "NUM_FINANCIER_CD": 13581,
      "TXT_FINANCIER_NAME": "SHRI GOPAL KRISHNA BIGARSHETI SAHAKARI PATASANSTHA LTD DAPOLI"
    },
    {
      "NUM_FINANCIER_CD": 13582,
      "TXT_FINANCIER_NAME": "JANAI MAHILA SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13583,
      "TXT_FINANCIER_NAME": "SRI ABHAYANJANEYA SWAMY VIVIDDODDESHA SOWHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13584,
      "TXT_FINANCIER_NAME": "THE BHAINI KALAN COOP AGRI MULTIPURPOSE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13585,
      "TXT_FINANCIER_NAME": "PRAJAKTA NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13586,
      "TXT_FINANCIER_NAME": "SAHNI FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 13587,
      "TXT_FINANCIER_NAME": "NILKANTH CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13588,
      "TXT_FINANCIER_NAME": "PARSHVANATH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13589,
      "TXT_FINANCIER_NAME": "THE SASTHAN CO OP AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13590,
      "TXT_FINANCIER_NAME": "GAYATRI DUDHUTPADAK GRAMIN BIG SHE SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13591,
      "TXT_FINANCIER_NAME": "SNEHA SIDDAGANGA VIVIDODESHA SOWHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13592,
      "TXT_FINANCIER_NAME": "THE NAV CHAITANYA MULTI PURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13593,
      "TXT_FINANCIER_NAME": "SHRI GAYATRI URBAN CREDIT SOUHARD SAHAKARI NIYMIT HATTARGI"
    },
    {
      "NUM_FINANCIER_CD": 13594,
      "TXT_FINANCIER_NAME": "SHRI SWABHIMAN SAMARTH MULTIPURPOSE CO OP SOC LTD YADUR"
    },
    {
      "NUM_FINANCIER_CD": 13595,
      "TXT_FINANCIER_NAME": "CAPITAL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 13596,
      "TXT_FINANCIER_NAME": "K GOKAVARAM PACS"
    },
    {
      "NUM_FINANCIER_CD": 13597,
      "TXT_FINANCIER_NAME": "HARUGAR GROUP GRAMAGAL SEVA SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13598,
      "TXT_FINANCIER_NAME": "THE NANGAL JARIALAN HALER CAS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13599,
      "TXT_FINANCIER_NAME": "SANKET CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13600,
      "TXT_FINANCIER_NAME": "MAHARASTHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 13601,
      "TXT_FINANCIER_NAME": "THE MULLAKANDI CO OPERATIVE RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13602,
      "TXT_FINANCIER_NAME": "B RAMESHCHAND SHANKLA AUTOMOBILE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 13603,
      "TXT_FINANCIER_NAME": "S K SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 13604,
      "TXT_FINANCIER_NAME": "RITESH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13605,
      "TXT_FINANCIER_NAME": "SWA SAU SHAILEJA DHADAGE NAGARI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13606,
      "TXT_FINANCIER_NAME": "SCFS COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13607,
      "TXT_FINANCIER_NAME": "ARYA VYSYA SRI KANNIKAPARAMESHWARI CO OPRATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13608,
      "TXT_FINANCIER_NAME": "BETTAGERE PRIMARY AGRICULTURAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13609,
      "TXT_FINANCIER_NAME": "N K K SOUHARDA CREDIT CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13610,
      "TXT_FINANCIER_NAME": "HARYANA SEED DEV CROP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13611,
      "TXT_FINANCIER_NAME": "SHRI KALIDAS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13612,
      "TXT_FINANCIER_NAME": "KASHINATH ANNA CHARATI GRAMIN BIGAR SHETI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13613,
      "TXT_FINANCIER_NAME": "GLORIA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13614,
      "TXT_FINANCIER_NAME": "BALVEER CREDIT SOCIETY COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13615,
      "TXT_FINANCIER_NAME": "SRI KONGU THIRUMAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13616,
      "TXT_FINANCIER_NAME": "JAY AMBE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13617,
      "TXT_FINANCIER_NAME": "BELTHANGADY CONSUMERS CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13618,
      "TXT_FINANCIER_NAME": "SHALIWAHAN NAGRI SAHKARI PATSANSTHA MARYADIT PAITHAN"
    },
    {
      "NUM_FINANCIER_CD": 13619,
      "TXT_FINANCIER_NAME": "SOWHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13620,
      "TXT_FINANCIER_NAME": "UDUPI TQ AGRICULTURAL PRODUCE CO OP MARKETING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13621,
      "TXT_FINANCIER_NAME": "DURGADEVI NAGARIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 13622,
      "TXT_FINANCIER_NAME": "SHRI BALAJI AUTOFIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13623,
      "TXT_FINANCIER_NAME": "GIRAN AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13624,
      "TXT_FINANCIER_NAME": "SHRI VYANKETESH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13625,
      "TXT_FINANCIER_NAME": "RATHOD FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13626,
      "TXT_FINANCIER_NAME": "HITLALLI DHODDA PRAMANADA PRATHAMIKA PATTINA VYAVASAY SAHAKARISAGHA NIYAMITA HITLALLI"
    },
    {
      "NUM_FINANCIER_CD": 13627,
      "TXT_FINANCIER_NAME": "INDIA DIVISIONAL OFFICE AJJARKAD"
    },
    {
      "NUM_FINANCIER_CD": 13628,
      "TXT_FINANCIER_NAME": "I U C C A"
    },
    {
      "NUM_FINANCIER_CD": 13629,
      "TXT_FINANCIER_NAME": "THE JASVANTPURA GROUP SEVA SAHKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 13630,
      "TXT_FINANCIER_NAME": "THE AMARANAT CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13631,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAOJI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13632,
      "TXT_FINANCIER_NAME": "KUVEMPU P A C S HIREKODIGE"
    },
    {
      "NUM_FINANCIER_CD": 13633,
      "TXT_FINANCIER_NAME": "VIMALCHAND NARESHKUMAR HUF"
    },
    {
      "NUM_FINANCIER_CD": 13634,
      "TXT_FINANCIER_NAME": "TATTISARA GROUP GRAMAGALA SEVA SAHAKARI SANGH"
    },
    {
      "NUM_FINANCIER_CD": 14996,
      "TXT_FINANCIER_NAME": "ERNAKUAM DISTRICT CO OPERATIVE BANK PERMBAVOOR"
    },
    {
      "NUM_FINANCIER_CD": 14997,
      "TXT_FINANCIER_NAME": "EX-SERVICEMAN HIRE PURCHASE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14998,
      "TXT_FINANCIER_NAME": "FARMOUR SERVICE COPARATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14999,
      "TXT_FINANCIER_NAME": "FAVOUR OF BARODA RAJASTHAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15000,
      "TXT_FINANCIER_NAME": "FORT POINT AUTOMOTIVECARS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15001,
      "TXT_FINANCIER_NAME": "FORTUNE FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15002,
      "TXT_FINANCIER_NAME": "G E COUNTRYWIDE CFSL"
    },
    {
      "NUM_FINANCIER_CD": 15003,
      "TXT_FINANCIER_NAME": "G E COUNTRYWIDE CONSUMER"
    },
    {
      "NUM_FINANCIER_CD": 15004,
      "TXT_FINANCIER_NAME": "G S AUTOMOBILE"
    },
    {
      "NUM_FINANCIER_CD": 15005,
      "TXT_FINANCIER_NAME": "G S CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15006,
      "TXT_FINANCIER_NAME": "G S K C H LTD"
    },
    {
      "NUM_FINANCIER_CD": 15007,
      "TXT_FINANCIER_NAME": "G. D. TRADING & AGENCIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15008,
      "TXT_FINANCIER_NAME": "GAMHARIA LAMPS D M B DINDALI ADITYAPUR"
    },
    {
      "NUM_FINANCIER_CD": 15009,
      "TXT_FINANCIER_NAME": "GNFC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15010,
      "TXT_FINANCIER_NAME": "GOBRAPOTA KHANPUR S.K.U.S. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15011,
      "TXT_FINANCIER_NAME": "GOEL FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 15012,
      "TXT_FINANCIER_NAME": "GOJARIA NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15013,
      "TXT_FINANCIER_NAME": "GOKHIWARE VILLAGE VASAI"
    },
    {
      "NUM_FINANCIER_CD": 15014,
      "TXT_FINANCIER_NAME": "GOODRICK GROUP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15015,
      "TXT_FINANCIER_NAME": "GSB COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15016,
      "TXT_FINANCIER_NAME": "GSECL KLTPS"
    },
    {
      "NUM_FINANCIER_CD": 15017,
      "TXT_FINANCIER_NAME": "GSECL SIKKA"
    },
    {
      "NUM_FINANCIER_CD": 15018,
      "TXT_FINANCIER_NAME": "GSFC LTD"
    },
    {
      "NUM_FINANCIER_CD": 15019,
      "TXT_FINANCIER_NAME": "GUDHA GORJI"
    },
    {
      "NUM_FINANCIER_CD": 15020,
      "TXT_FINANCIER_NAME": "GUNJAN ARORA"
    },
    {
      "NUM_FINANCIER_CD": 15021,
      "TXT_FINANCIER_NAME": "H P MAGMA FIN CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 15022,
      "TXT_FINANCIER_NAME": "H U D C O"
    },
    {
      "NUM_FINANCIER_CD": 15023,
      "TXT_FINANCIER_NAME": "HBD FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15024,
      "TXT_FINANCIER_NAME": "HCL COMNET SYSTEMS SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15025,
      "TXT_FINANCIER_NAME": "HDS FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15026,
      "TXT_FINANCIER_NAME": "HI-KLASS TRADING & INVESTMENT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15027,
      "TXT_FINANCIER_NAME": "HIMATSINGKA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15028,
      "TXT_FINANCIER_NAME": "HINBUJA LEYLAND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15029,
      "TXT_FINANCIER_NAME": "HINDAUN CITY"
    },
    {
      "NUM_FINANCIER_CD": 15030,
      "TXT_FINANCIER_NAME": "HINDAVI CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 15031,
      "TXT_FINANCIER_NAME": "HINDAVI MULTIPURPOSE SOUHARD SHAHKARI SUBHASH CHANDRA NAGAR BELGAUM"
    },
    {
      "NUM_FINANCIER_CD": 15032,
      "TXT_FINANCIER_NAME": "HINDURSTAN PETROLEUM CORP LTD BAHADURGARH"
    },
    {
      "NUM_FINANCIER_CD": 15033,
      "TXT_FINANCIER_NAME": "HINUSTAN PETROLEUM CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 15034,
      "TXT_FINANCIER_NAME": "HI-TECH CORPORATE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15035,
      "TXT_FINANCIER_NAME": "HONGKONG SHANGHAI BANKING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 15036,
      "TXT_FINANCIER_NAME": "HPAU PALAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 15037,
      "TXT_FINANCIER_NAME": "HPGCL HARYANA POWER GENERATION CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 15038,
      "TXT_FINANCIER_NAME": "HVGHF"
    },
    {
      "NUM_FINANCIER_CD": 15039,
      "TXT_FINANCIER_NAME": "HVPNL"
    },
    {
      "NUM_FINANCIER_CD": 15040,
      "TXT_FINANCIER_NAME": "IDER NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15041,
      "TXT_FINANCIER_NAME": "IFK FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15042,
      "TXT_FINANCIER_NAME": "IMA BARODA COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15043,
      "TXT_FINANCIER_NAME": "INCORPORATION OF BANK CLAUSE"
    },
    {
      "NUM_FINANCIER_CD": 15044,
      "TXT_FINANCIER_NAME": "INDIAN AUDIT AND ACCOUNT DEPARMENT"
    },
    {
      "NUM_FINANCIER_CD": 15045,
      "TXT_FINANCIER_NAME": "INDIAN FARMER FERTILIZER COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15046,
      "TXT_FINANCIER_NAME": "INDIAN MATCH FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15047,
      "TXT_FINANCIER_NAME": "INDIAN MEDICAL ASSOCIATION"
    },
    {
      "NUM_FINANCIER_CD": 15048,
      "TXT_FINANCIER_NAME": "INDIAN RARE EARTHS LTD UDYOGAMANDAL"
    },
    {
      "NUM_FINANCIER_CD": 15049,
      "TXT_FINANCIER_NAME": "INDIAN TONERS AND DEVELOPERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15050,
      "TXT_FINANCIER_NAME": "INDO - EURO SECURITIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15051,
      "TXT_FINANCIER_NAME": "INDO-CITY INFOTECH LTD"
    },
    {
      "NUM_FINANCIER_CD": 15052,
      "TXT_FINANCIER_NAME": "INDO-EURO INVESTMENTS COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15053,
      "TXT_FINANCIER_NAME": "INDRAKALADRI FINANCE VIJAYAWADA"
    },
    {
      "NUM_FINANCIER_CD": 15054,
      "TXT_FINANCIER_NAME": "INSILCO LTD GAJRAULA"
    },
    {
      "NUM_FINANCIER_CD": 15055,
      "TXT_FINANCIER_NAME": "INSTITUE FOR PLASMA REASEACH CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 15056,
      "TXT_FINANCIER_NAME": "INSURANCE SOFTWARE SANGAM COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15057,
      "TXT_FINANCIER_NAME": "ISLAMIC WELFARE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15058,
      "TXT_FINANCIER_NAME": "ISSCOAONLA"
    },
    {
      "NUM_FINANCIER_CD": 15059,
      "TXT_FINANCIER_NAME": "ISTPP"
    },
    {
      "NUM_FINANCIER_CD": 15060,
      "TXT_FINANCIER_NAME": "J K STATE COOPERATIVE BANK BEMINA"
    },
    {
      "NUM_FINANCIER_CD": 15061,
      "TXT_FINANCIER_NAME": "J D & CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15062,
      "TXT_FINANCIER_NAME": "J.K PRIME INVESTMENT & LEASING INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15063,
      "TXT_FINANCIER_NAME": "JAG MEET ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 15064,
      "TXT_FINANCIER_NAME": "JAIN UDYOG LTD"
    },
    {
      "NUM_FINANCIER_CD": 15065,
      "TXT_FINANCIER_NAME": "JANARAKSHA SOUHARDA PATTINA SAHAKARI NI ."
    },
    {
      "NUM_FINANCIER_CD": 15066,
      "TXT_FINANCIER_NAME": "JANESVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15067,
      "TXT_FINANCIER_NAME": "JANTA NAGARI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15068,
      "TXT_FINANCIER_NAME": "JAVLI SAHAKARI PATPEDHI MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 15069,
      "TXT_FINANCIER_NAME": "JDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 15070,
      "TXT_FINANCIER_NAME": "JIGOJ NAGARI SAHAKARI PATHSANTHA MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15071,
      "TXT_FINANCIER_NAME": "JNPT TOWNSHIP MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 15072,
      "TXT_FINANCIER_NAME": "K KAMAL CHAND KOTHARI"
    },
    {
      "NUM_FINANCIER_CD": 15073,
      "TXT_FINANCIER_NAME": "K N S B LTD"
    },
    {
      "NUM_FINANCIER_CD": 15074,
      "TXT_FINANCIER_NAME": "K.L.S. FINANCE & INVESTMENTS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15075,
      "TXT_FINANCIER_NAME": "KAMALAJADEVI GRAMIN BIGER SHETI SAH PATH MYDT"
    },
    {
      "NUM_FINANCIER_CD": 15076,
      "TXT_FINANCIER_NAME": "KAMANCHAL NAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15077,
      "TXT_FINANCIER_NAME": "KANAKLATA MAHILA URBAN COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15078,
      "TXT_FINANCIER_NAME": "KAN-RATAN INVEATMENTS & FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15079,
      "TXT_FINANCIER_NAME": "KANURPATHAR MULTISTATE CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 15080,
      "TXT_FINANCIER_NAME": "KARANGHAT CO OP AGRICULTURE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15081,
      "TXT_FINANCIER_NAME": "KARIMIA HOUSING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15082,
      "TXT_FINANCIER_NAME": "KARUR VYSYAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 15083,
      "TXT_FINANCIER_NAME": "KATRIYA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15084,
      "TXT_FINANCIER_NAME": "KENEDA BANK PORBANDAR BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15085,
      "TXT_FINANCIER_NAME": "KESAVADASAPURAM"
    },
    {
      "NUM_FINANCIER_CD": 15086,
      "TXT_FINANCIER_NAME": "KGSC BANK"
    },
    {
      "NUM_FINANCIER_CD": 15087,
      "TXT_FINANCIER_NAME": "KODUGALLOOR CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15088,
      "TXT_FINANCIER_NAME": "KOHLAPUR DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15089,
      "TXT_FINANCIER_NAME": "KOPARGOAN TALUKA AUTO RIKSHA SAHAKARI PATASANTHA"
    },
    {
      "NUM_FINANCIER_CD": 15090,
      "TXT_FINANCIER_NAME": "KSBCDC LTD"
    },
    {
      "NUM_FINANCIER_CD": 15091,
      "TXT_FINANCIER_NAME": "KSCARD BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15092,
      "TXT_FINANCIER_NAME": "KSFE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15093,
      "TXT_FINANCIER_NAME": "KUMBADAJE SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15094,
      "TXT_FINANCIER_NAME": "KUMTA URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15095,
      "TXT_FINANCIER_NAME": "KUNNATHUNAD TALUK PRIMARY COOPERATIVE AGRICULTURE RURAL DEVELOPMENT BANK."
    },
    {
      "NUM_FINANCIER_CD": 15096,
      "TXT_FINANCIER_NAME": "KURUMBUR PRIMARY AGRI COOP CREDIT SOCEITY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15097,
      "TXT_FINANCIER_NAME": "KURUR VAISHYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 15098,
      "TXT_FINANCIER_NAME": "KVG BANK"
    },
    {
      "NUM_FINANCIER_CD": 15099,
      "TXT_FINANCIER_NAME": "L G ELECTRONICS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15100,
      "TXT_FINANCIER_NAME": "LANDT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15101,
      "TXT_FINANCIER_NAME": "LAXMANANADA MULTI PURPOSE CO OP SOCIETY MORGANSGATE"
    },
    {
      "NUM_FINANCIER_CD": 15102,
      "TXT_FINANCIER_NAME": "LDR BANK"
    },
    {
      "NUM_FINANCIER_CD": 15103,
      "TXT_FINANCIER_NAME": "LONGPI DEHANGI RURAL BANK BOKAJAN"
    },
    {
      "NUM_FINANCIER_CD": 15104,
      "TXT_FINANCIER_NAME": "LUIT H P CO"
    },
    {
      "NUM_FINANCIER_CD": 15105,
      "TXT_FINANCIER_NAME": "LUNGPI DIHANGI RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 15106,
      "TXT_FINANCIER_NAME": "M AND M FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15107,
      "TXT_FINANCIER_NAME": "M AND M FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15108,
      "TXT_FINANCIER_NAME": "M D U ROHTAK"
    },
    {
      "NUM_FINANCIER_CD": 15109,
      "TXT_FINANCIER_NAME": "M KAMAL CHAND KOTHARI"
    },
    {
      "NUM_FINANCIER_CD": 15110,
      "TXT_FINANCIER_NAME": "M S T C LTD"
    },
    {
      "NUM_FINANCIER_CD": 15111,
      "TXT_FINANCIER_NAME": "M.D MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 15112,
      "TXT_FINANCIER_NAME": "MADHYAHNAPU VARI GUDEM P A C S NO W G 704"
    },
    {
      "NUM_FINANCIER_CD": 15113,
      "TXT_FINANCIER_NAME": "MADHYAHNAPUVARI GUDEM P A C S NO W G 704"
    },
    {
      "NUM_FINANCIER_CD": 16789,
      "TXT_FINANCIER_NAME": "WISEMAN FINANCE PRIVATE LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 16790,
      "TXT_FINANCIER_NAME": "WORD PORTFOLIO FINANCE & LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 16791,
      "TXT_FINANCIER_NAME": "YASHIKA LEASING & FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16792,
      "TXT_FINANCIER_NAME": "YOGINDERA FINANCE LIMITEDA"
    },
    {
      "NUM_FINANCIER_CD": 16793,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16794,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16795,
      "TXT_FINANCIER_NAME": "ARUNACHAL PRADESH STATE INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16796,
      "TXT_FINANCIER_NAME": "DELHI FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16797,
      "TXT_FINANCIER_NAME": "ECONOMIC DEVELOPMENT CORPORATION OF DADRA & NAGAR HAVELLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16798,
      "TXT_FINANCIER_NAME": "ECONOMIC DEVELOPMENT CORPORATION OF GOA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16799,
      "TXT_FINANCIER_NAME": "GUJARAT INDUSTRIAL INVESTMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16800,
      "TXT_FINANCIER_NAME": "GUJARAT STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16801,
      "TXT_FINANCIER_NAME": "JAMMU & KASHMIR INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16802,
      "TXT_FINANCIER_NAME": "JAMMU & KASHMIR STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16803,
      "TXT_FINANCIER_NAME": "JF ELECTRA ADVISORS (INDIA) LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16804,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16805,
      "TXT_FINANCIER_NAME": "KERALA FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16806,
      "TXT_FINANCIER_NAME": "KERALA STATE INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16807,
      "TXT_FINANCIER_NAME": "MADHYA PRADESH STATE INDUSTRIAL DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16808,
      "TXT_FINANCIER_NAME": "MAHARASHTRA STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16809,
      "TXT_FINANCIER_NAME": "OMNIBUS INDUSTRIAL DEVELOPMENT CORPORATION OF DADRA & NAGAR HAVELLI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16810,
      "TXT_FINANCIER_NAME": "PONDICHERRY INDUSTRIAL PROMOTION, DEVELOPMENT & INVESTMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16811,
      "TXT_FINANCIER_NAME": "RAJASTHAN FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16812,
      "TXT_FINANCIER_NAME": "SIKKIM INDUSTRIAL DEVELOPMENT & INVESTMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 16813,
      "TXT_FINANCIER_NAME": "SMALL INDUSTRIES DEVELOPMENT BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 16814,
      "TXT_FINANCIER_NAME": "TAMIL NADU INDUSTRIAL DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16815,
      "TXT_FINANCIER_NAME": "THE PRADESHIYA INDUSTRIAL & INVESTMENT CORPORATION OF UTTAR PRADESH LTD"
    },
    {
      "NUM_FINANCIER_CD": 16816,
      "TXT_FINANCIER_NAME": "UNIT TRUST OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 16817,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH STATE INDUSTRIAL DEVELOPMENT CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16818,
      "TXT_FINANCIER_NAME": "Life Insurance Corporation of India"
    },
    {
      "NUM_FINANCIER_CD": 16819,
      "TXT_FINANCIER_NAME": "Associates India Financial Services Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16820,
      "TXT_FINANCIER_NAME": "Numaligarh Refinary Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16821,
      "TXT_FINANCIER_NAME": "INDIAN OIL CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16822,
      "TXT_FINANCIER_NAME": "Gas Authority Of India Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16823,
      "TXT_FINANCIER_NAME": "SHRI KANYAKA NAGARI SAHA. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16824,
      "TXT_FINANCIER_NAME": "SHRI KANYAKA NAGARI SAHA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16825,
      "TXT_FINANCIER_NAME": "THE PRAGATI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16826,
      "TXT_FINANCIER_NAME": "CHOUNDESHWARI SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16827,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH CO OPERATVIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16828,
      "TXT_FINANCIER_NAME": "ANNASAHEB KARALE JANATA SAHAKARI BANK LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 16829,
      "TXT_FINANCIER_NAME": "THE TASGAON URBAN CO-OP BANK LTD TASGAON BRANCH TASGAON"
    },
    {
      "NUM_FINANCIER_CD": 16830,
      "TXT_FINANCIER_NAME": "MAHANAGAR NAGRIK SAHAKARI BANK MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16831,
      "TXT_FINANCIER_NAME": "THE MEHSANA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16832,
      "TXT_FINANCIER_NAME": "TUMKUR GRAIN MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16833,
      "TXT_FINANCIER_NAME": "THE GUJARAT STATE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16834,
      "TXT_FINANCIER_NAME": "THE NAGPUR MAHANAGAR PALIKA KARMACHARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16835,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16836,
      "TXT_FINANCIER_NAME": "KOLHAPUR MAHILA SAHAKARI BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 16837,
      "TXT_FINANCIER_NAME": "AHMEDABAD MERCANTILE CO BANK"
    },
    {
      "NUM_FINANCIER_CD": 16838,
      "TXT_FINANCIER_NAME": "THE GADHINGLAJ URBAN COOP BANK LTD GADHINGLAJ"
    },
    {
      "NUM_FINANCIER_CD": 16839,
      "TXT_FINANCIER_NAME": "THE CITIZENS URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16840,
      "TXT_FINANCIER_NAME": "THE KOLHAPUR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16841,
      "TXT_FINANCIER_NAME": "KERALA STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16842,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 16843,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES FINANCIAL SERVICSES LTD, NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 16844,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16845,
      "TXT_FINANCIER_NAME": "JALGAON JANTA SAHKARI BANK LTD,.JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 16846,
      "TXT_FINANCIER_NAME": "Jalgaon Janta Sahakari Bank Ltd.Jalgaon"
    },
    {
      "NUM_FINANCIER_CD": 16847,
      "TXT_FINANCIER_NAME": "THE UNITED CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16848,
      "TXT_FINANCIER_NAME": "THE KAPURTHALA CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16849,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16850,
      "TXT_FINANCIER_NAME": "MAKARPURA INDUSTRIAL ESTATE CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16851,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16852,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16853,
      "TXT_FINANCIER_NAME": "THE YAVATMAL URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16854,
      "TXT_FINANCIER_NAME": "MANSAROVAR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16855,
      "TXT_FINANCIER_NAME": "SANMITRA MAHILA NAGARI SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 16856,
      "TXT_FINANCIER_NAME": "SANJIVANI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16857,
      "TXT_FINANCIER_NAME": "TAMILNAD MERCANTILE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16858,
      "TXT_FINANCIER_NAME": "UMIYA URBAN CO-OPRATIVE BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 16859,
      "TXT_FINANCIER_NAME": "DEOGIRI NAGARI SAHAKARI BANK LTD.AURANGABAD"
    },
    {
      "NUM_FINANCIER_CD": 16860,
      "TXT_FINANCIER_NAME": "THE KURUNDWAD URBAN CO-OP BANK LTD KURUNDWAD"
    },
    {
      "NUM_FINANCIER_CD": 16861,
      "TXT_FINANCIER_NAME": "THE PANCHKULA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16862,
      "TXT_FINANCIER_NAME": "SHIKSHAK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16863,
      "TXT_FINANCIER_NAME": "CHETANA SAHAKARI BANK NIYAMITA LTD, SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 16864,
      "TXT_FINANCIER_NAME": "CHETANA SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 16865,
      "TXT_FINANCIER_NAME": "The Parsik Janata Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16866,
      "TXT_FINANCIER_NAME": "CITI FINANCIAL CONSUMER FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 16867,
      "TXT_FINANCIER_NAME": "THE KHEDA PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16868,
      "TXT_FINANCIER_NAME": "THE SARANGPUR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16869,
      "TXT_FINANCIER_NAME": "THE YADAGIRI LAKSHMI NARSIMHA SWAMY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16870,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR PEOPLE S CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16871,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16872,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LTD,BHUBANESWAR"
    },
    {
      "NUM_FINANCIER_CD": 16873,
      "TXT_FINANCIER_NAME": "GMAC FINANCIAL SERVICES INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16874,
      "TXT_FINANCIER_NAME": "NARODA INDUSTRIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16875,
      "TXT_FINANCIER_NAME": "BHAVANI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16876,
      "TXT_FINANCIER_NAME": "SUVARNA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16877,
      "TXT_FINANCIER_NAME": "RESERVE BANK EMPLOYEES COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16878,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR BANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 12136,
      "TXT_FINANCIER_NAME": "JAI MALHAR NAGARI SAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12137,
      "TXT_FINANCIER_NAME": "MURASU FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12138,
      "TXT_FINANCIER_NAME": "SHRI MAHABALESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12139,
      "TXT_FINANCIER_NAME": "CHERTHALA COOP AGRI AND RURAL DEVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12140,
      "TXT_FINANCIER_NAME": "KUMAWAT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12141,
      "TXT_FINANCIER_NAME": "BASANT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12142,
      "TXT_FINANCIER_NAME": "LAHARI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12143,
      "TXT_FINANCIER_NAME": "NAMESH CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12144,
      "TXT_FINANCIER_NAME": "THE SHRADHA CREDIT SOUHARD SAHAKARI NYT"
    },
    {
      "NUM_FINANCIER_CD": 12145,
      "TXT_FINANCIER_NAME": "AET LABORATORIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12146,
      "TXT_FINANCIER_NAME": "DEEPAK DEPOSIT ADV PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12147,
      "TXT_FINANCIER_NAME": "MORNA WARANA SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12148,
      "TXT_FINANCIER_NAME": "RUDRAKSHA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 12149,
      "TXT_FINANCIER_NAME": "DHANASIVAH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12150,
      "TXT_FINANCIER_NAME": "JAIBHAVANI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12151,
      "TXT_FINANCIER_NAME": "SAKTHI VINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12152,
      "TXT_FINANCIER_NAME": "TULSI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 12153,
      "TXT_FINANCIER_NAME": "SANT NAMDEV NAGARI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12154,
      "TXT_FINANCIER_NAME": "JAI MUNI SAHKARI PATPEDHI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12155,
      "TXT_FINANCIER_NAME": "MALANADU CRDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12156,
      "TXT_FINANCIER_NAME": "SHRI ADINATH MULTIPURPOSE SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12157,
      "TXT_FINANCIER_NAME": "TAMIL NAD MERCANTILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12158,
      "TXT_FINANCIER_NAME": "PATNA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12159,
      "TXT_FINANCIER_NAME": "HITTLAHALLI DODDA PRAMANANDA PRATHAMIKA PATTINA VYAVASAYA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 12160,
      "TXT_FINANCIER_NAME": "MANSI MAHILA NAGARI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12161,
      "TXT_FINANCIER_NAME": "JAGATGURU TUKARAM MAHARAJ NAGRI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12162,
      "TXT_FINANCIER_NAME": "THE PROGRESSIVE MULTI PUPPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12163,
      "TXT_FINANCIER_NAME": "TUTICORIN PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 12164,
      "TXT_FINANCIER_NAME": "PATHIYOOR SERVICE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12165,
      "TXT_FINANCIER_NAME": "BAID LEASING AND FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12166,
      "TXT_FINANCIER_NAME": "NAMDEV BAPU KADAM GRAMIN BIGAR SHETI SAHAKARI PATH SANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12167,
      "TXT_FINANCIER_NAME": "GURPAL AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 12168,
      "TXT_FINANCIER_NAME": "VIDARBHA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 12169,
      "TXT_FINANCIER_NAME": "LOKNETE VIJAYMAMA AHER VIVIDH KARYAKARI SEVA SAHAKARI SOCIETY MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12170,
      "TXT_FINANCIER_NAME": "SRI LAXMI SARASWATHI CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12171,
      "TXT_FINANCIER_NAME": "MOORTHEDARARA SEVA SAHAKARA SANGHA HIRGANA"
    },
    {
      "NUM_FINANCIER_CD": 12172,
      "TXT_FINANCIER_NAME": "QUEPEM CITIZEN URBAN COOP CRT STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12173,
      "TXT_FINANCIER_NAME": "KARSHIKA VIKASANA BANK"
    },
    {
      "NUM_FINANCIER_CD": 12174,
      "TXT_FINANCIER_NAME": "THE DHARAMPETH MAHILA MULTISTATE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12175,
      "TXT_FINANCIER_NAME": "SRI VASAVI VARDHAKARA SOUNDRDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 12176,
      "TXT_FINANCIER_NAME": "MANIPUR STATE WOMENS COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12177,
      "TXT_FINANCIER_NAME": "ADARSH MAHILA MERCANTILE CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12178,
      "TXT_FINANCIER_NAME": "COASTAL GUJARAT POWER LTD"
    },
    {
      "NUM_FINANCIER_CD": 12179,
      "TXT_FINANCIER_NAME": "THE VAJEPURAKAMPA SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12180,
      "TXT_FINANCIER_NAME": "THE KODUVALLY SERVICE CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12181,
      "TXT_FINANCIER_NAME": "STATE INDUSTRIES PROMOTION CORP OF TAMILNADU LTD"
    },
    {
      "NUM_FINANCIER_CD": 12182,
      "TXT_FINANCIER_NAME": "ILKAL URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 12183,
      "TXT_FINANCIER_NAME": "KARASSERI SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 12184,
      "TXT_FINANCIER_NAME": "SHREE DEONADI KHOREGRAMIN BIGARSHETI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12185,
      "TXT_FINANCIER_NAME": "AHMEDABAD VEGETABLE GENERAL COMMISSION AGENTS CO OP CREDIT AND SUPPLY SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12186,
      "TXT_FINANCIER_NAME": "KARANATAKA STATE FINANCIAL CORP"
    },
    {
      "NUM_FINANCIER_CD": 12187,
      "TXT_FINANCIER_NAME": "SEVEN SEAS SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 12188,
      "TXT_FINANCIER_NAME": "JANABAI GRAMIN BIGER SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12189,
      "TXT_FINANCIER_NAME": "CHANDRAPRABHA JAIN NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12190,
      "TXT_FINANCIER_NAME": "VIGNAHARTA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12191,
      "TXT_FINANCIER_NAME": "SAINIK COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12192,
      "TXT_FINANCIER_NAME": "YUVA UTKARSHA CO OP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12193,
      "TXT_FINANCIER_NAME": "SHRI MADHAVRAO PAHILWAN JADHAV GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12194,
      "TXT_FINANCIER_NAME": "SHREE SAINATH NAGARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12195,
      "TXT_FINANCIER_NAME": "SANTH GAJANAN MAHARAJ GRAMIN"
    },
    {
      "NUM_FINANCIER_CD": 12196,
      "TXT_FINANCIER_NAME": "SAMRUDHA JEEVAN NAGARI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12197,
      "TXT_FINANCIER_NAME": "THE JAJAMULTIPURPUS AGRICULTUR SERVICE SOCIETYLTD"
    },
    {
      "NUM_FINANCIER_CD": 12198,
      "TXT_FINANCIER_NAME": "THE ANKLAV MERCANTILE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12199,
      "TXT_FINANCIER_NAME": "SIVANMALAI MURUGAN MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12200,
      "TXT_FINANCIER_NAME": "VIKRAM NAGARI SAHAKARI PAT SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 12201,
      "TXT_FINANCIER_NAME": "N PARASHMALL PAGARIA"
    },
    {
      "NUM_FINANCIER_CD": 12202,
      "TXT_FINANCIER_NAME": "LAJAI SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12203,
      "TXT_FINANCIER_NAME": "SISCO KURIES AND LOANS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12204,
      "TXT_FINANCIER_NAME": "SITARA AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12205,
      "TXT_FINANCIER_NAME": "THE KUZHIMANNA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12206,
      "TXT_FINANCIER_NAME": "MADHAVNATH GARAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12207,
      "TXT_FINANCIER_NAME": "SHREE DANTALESWAR GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12208,
      "TXT_FINANCIER_NAME": "KOTAK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12209,
      "TXT_FINANCIER_NAME": "CHANDRAMA INVESTMENT FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 12210,
      "TXT_FINANCIER_NAME": "GOMTESH NAGARI SAHAKARI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 12211,
      "TXT_FINANCIER_NAME": "JAY GANESH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12212,
      "TXT_FINANCIER_NAME": "THE VE CHOKANDI KAMPA GROUP SEVA SAH MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 12213,
      "TXT_FINANCIER_NAME": "VJ FINANCE AND INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12214,
      "TXT_FINANCIER_NAME": "JAYSINGPUR KIRANA BHUSADRI VYAPARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12215,
      "TXT_FINANCIER_NAME": "GANDHI DHAM CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12216,
      "TXT_FINANCIER_NAME": "MAA SAHEB NAGARI SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12217,
      "TXT_FINANCIER_NAME": "KARMVEER GOVIND BAPU PATIL GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12218,
      "TXT_FINANCIER_NAME": "RAJENDAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12219,
      "TXT_FINANCIER_NAME": "SHARADRAO PAWAR NAGARI SAHAKARI PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 12220,
      "TXT_FINANCIER_NAME": "TEMATHANI SMALL TRADERS CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12221,
      "TXT_FINANCIER_NAME": "SHRI PURUSHOTTAM SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12222,
      "TXT_FINANCIER_NAME": "SERICULTURISTS CUM FARMER SERVICE CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 12223,
      "TXT_FINANCIER_NAME": "NOKIA BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 12224,
      "TXT_FINANCIER_NAME": "GURU NARASIMHA VSS"
    },
    {
      "NUM_FINANCIER_CD": 12225,
      "TXT_FINANCIER_NAME": "ASTHA SAKH SAHAKARITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12226,
      "TXT_FINANCIER_NAME": "SRI KANNIKA PARAMESHWARI PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12227,
      "TXT_FINANCIER_NAME": "GANDHIBAG URBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 12228,
      "TXT_FINANCIER_NAME": "UTTRAKHAND CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13635,
      "TXT_FINANCIER_NAME": "GANAPATHI PRIMARY AGRICULTURE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13636,
      "TXT_FINANCIER_NAME": "SHRI MARUTI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13637,
      "TXT_FINANCIER_NAME": "SHIVCHHAYA NAGARI SAHAKARI PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13638,
      "TXT_FINANCIER_NAME": "DOMBIVALI NAGRI SAH PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13639,
      "TXT_FINANCIER_NAME": "DATTA DIGAMBAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13640,
      "TXT_FINANCIER_NAME": "DEPUTY DIRECTOR"
    },
    {
      "NUM_FINANCIER_CD": 13641,
      "TXT_FINANCIER_NAME": "YADWAD ARABAN CO OPERATIVE CREDIT SOCIETY YADWAD"
    },
    {
      "NUM_FINANCIER_CD": 13642,
      "TXT_FINANCIER_NAME": "TELLAPUDI P A C C S"
    },
    {
      "NUM_FINANCIER_CD": 13643,
      "TXT_FINANCIER_NAME": "SRI HARA VEVEDODESHA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13644,
      "TXT_FINANCIER_NAME": "NAVA TUNGABHADRA VIVIDOODESHA SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13645,
      "TXT_FINANCIER_NAME": "SWARN JYOTI INVESTMENT P LTD"
    },
    {
      "NUM_FINANCIER_CD": 13646,
      "TXT_FINANCIER_NAME": "BASAVA SAUARD CREDIT SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13647,
      "TXT_FINANCIER_NAME": "ANANT GRAMIN BIGAR SHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13648,
      "TXT_FINANCIER_NAME": "SHRI VINAYAKA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13649,
      "TXT_FINANCIER_NAME": "RAMKRISHNA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13650,
      "TXT_FINANCIER_NAME": "SHIRDI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13651,
      "TXT_FINANCIER_NAME": "LOKNETE SHIVAJIRAO ALIS BABASAHEB PATIL GRAMIN BIGAR SHETI SAH PATH SANS MAR CHIKURDE"
    },
    {
      "NUM_FINANCIER_CD": 13652,
      "TXT_FINANCIER_NAME": "MORTHANA DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13653,
      "TXT_FINANCIER_NAME": "AJIT MAL SHIV CHAND RAIPUR"
    },
    {
      "NUM_FINANCIER_CD": 13654,
      "TXT_FINANCIER_NAME": "SIDDHIVINAYAK ZILLA NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13655,
      "TXT_FINANCIER_NAME": "SOCIAL MUTUAL BENEFITS CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13656,
      "TXT_FINANCIER_NAME": "SHRI DURGADEVI MAHILA URBAN CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13657,
      "TXT_FINANCIER_NAME": "SHRI DHANLAXMI PATTIN SAHAKAR SANGH NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 13658,
      "TXT_FINANCIER_NAME": "THE FARMERS SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13659,
      "TXT_FINANCIER_NAME": "SHREE LAKSHMI SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13660,
      "TXT_FINANCIER_NAME": "A N V FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13661,
      "TXT_FINANCIER_NAME": "NORTHERN POWER DISTRIBUTION COMPANY OF TELANGANA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13662,
      "TXT_FINANCIER_NAME": "SHRI JAI HIND NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13663,
      "TXT_FINANCIER_NAME": "SULLIA TALUK PARISISTA VARGAGALA DODDA PRAMANADA VIVIDODDESHA SAHAKA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13664,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13665,
      "TXT_FINANCIER_NAME": "THE NAVODIT CO OP UTC SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13666,
      "TXT_FINANCIER_NAME": "SHRI WARANA VIDYAMANDIR AND SHRI WARANA VIDYALAY SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 13667,
      "TXT_FINANCIER_NAME": "TIMES BUSINESS SOLUTIONS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13668,
      "TXT_FINANCIER_NAME": "AIR FRANCE"
    },
    {
      "NUM_FINANCIER_CD": 13669,
      "TXT_FINANCIER_NAME": "P CHANDRA KANWAR BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 13670,
      "TXT_FINANCIER_NAME": "ELANTAS BECK INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13671,
      "TXT_FINANCIER_NAME": "MANGALA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13672,
      "TXT_FINANCIER_NAME": "CHOLOMANDALAM INVESTMENT AND FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13673,
      "TXT_FINANCIER_NAME": "HATBASANTAPUR SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13674,
      "TXT_FINANCIER_NAME": "S SAMPATH RAJ FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 13675,
      "TXT_FINANCIER_NAME": "THE NIRMARGA SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13676,
      "TXT_FINANCIER_NAME": "H CHANDMAL AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13677,
      "TXT_FINANCIER_NAME": "GANGOTRI VSSNI"
    },
    {
      "NUM_FINANCIER_CD": 13678,
      "TXT_FINANCIER_NAME": "N POOJADEVI BOKDIA"
    },
    {
      "NUM_FINANCIER_CD": 13679,
      "TXT_FINANCIER_NAME": "SAIL REFRACTORIES UNIT BOKARO"
    },
    {
      "NUM_FINANCIER_CD": 13680,
      "TXT_FINANCIER_NAME": "GANNESH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13681,
      "TXT_FINANCIER_NAME": "MATHA ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 13682,
      "TXT_FINANCIER_NAME": "SHREE AMBICA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13683,
      "TXT_FINANCIER_NAME": "AKSHAYA FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 13684,
      "TXT_FINANCIER_NAME": "OASIS CAPITAL SERV PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13685,
      "TXT_FINANCIER_NAME": "SHRI BRHAMADEV UR CR SOU SAH NIY"
    },
    {
      "NUM_FINANCIER_CD": 13686,
      "TXT_FINANCIER_NAME": "SRI JAGADGURU MURUGA RAJENDRA SOUHARDHA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13687,
      "TXT_FINANCIER_NAME": "BOMBAY PORT TRUST"
    },
    {
      "NUM_FINANCIER_CD": 13688,
      "TXT_FINANCIER_NAME": "HOSKOTE TOWN SERICULTURISTS FARMERS SERVICE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13689,
      "TXT_FINANCIER_NAME": "SRI VISHNU VILAS SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13690,
      "TXT_FINANCIER_NAME": "THE RISHIWAT URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13691,
      "TXT_FINANCIER_NAME": "POTHNAL PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 13692,
      "TXT_FINANCIER_NAME": "MAHAVIR MULTI STATE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13693,
      "TXT_FINANCIER_NAME": "R K MOTORS AND GENERAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13694,
      "TXT_FINANCIER_NAME": "SREE BALAJI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 13695,
      "TXT_FINANCIER_NAME": "M PADMACHAND LALWANI"
    },
    {
      "NUM_FINANCIER_CD": 13696,
      "TXT_FINANCIER_NAME": "NISHA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13697,
      "TXT_FINANCIER_NAME": "AAGASHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13698,
      "TXT_FINANCIER_NAME": "ALANKARU PRIMARY AGRICULTURAL CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13699,
      "TXT_FINANCIER_NAME": "IMPHAL FINANCE CO OP THRIFT AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13700,
      "TXT_FINANCIER_NAME": "SUMIT JAIN"
    },
    {
      "NUM_FINANCIER_CD": 13701,
      "TXT_FINANCIER_NAME": "ARTH NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13702,
      "TXT_FINANCIER_NAME": "JAVALI SAHAKARI PATHPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13703,
      "TXT_FINANCIER_NAME": "ULTIMATE SOLUTIONS"
    },
    {
      "NUM_FINANCIER_CD": 13704,
      "TXT_FINANCIER_NAME": "YASHASHRI GRAMIN BIGARSHETI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13705,
      "TXT_FINANCIER_NAME": "MOTICHAND CHORDIA"
    },
    {
      "NUM_FINANCIER_CD": 13706,
      "TXT_FINANCIER_NAME": "SHRI DURGA MAHILA CO OPERATIVE CREDIT VIVIDODDESHAGALA SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13707,
      "TXT_FINANCIER_NAME": "SRI GURU OPPOTHINA SWAMY PATHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13708,
      "TXT_FINANCIER_NAME": "AKSHATA NAGARI SAH PATHSANSTHA MARYADAT"
    },
    {
      "NUM_FINANCIER_CD": 13709,
      "TXT_FINANCIER_NAME": "DALJEET LEASING AND CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13710,
      "TXT_FINANCIER_NAME": "SARTHAK CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13711,
      "TXT_FINANCIER_NAME": "SHRI YUGPRABHAV SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13712,
      "TXT_FINANCIER_NAME": "LOKMANGAL NAGARI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13713,
      "TXT_FINANCIER_NAME": "ICRA ONLINE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13714,
      "TXT_FINANCIER_NAME": "BASAVASHREE PATTINA SAHAKARI SOUHARDA SANGA NI"
    },
    {
      "NUM_FINANCIER_CD": 13715,
      "TXT_FINANCIER_NAME": "S I DEVELOPMENT BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 13716,
      "TXT_FINANCIER_NAME": "SRI KANAKADAS PATTAINA SAHAKAR SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13717,
      "TXT_FINANCIER_NAME": "SRI THENAMBIGAI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13718,
      "TXT_FINANCIER_NAME": "SURYA KIRANA PATTINA SOUHARDA"
    },
    {
      "NUM_FINANCIER_CD": 13719,
      "TXT_FINANCIER_NAME": "KAMALA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13720,
      "TXT_FINANCIER_NAME": "HI LOGISTICS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13721,
      "TXT_FINANCIER_NAME": "PRIYA FINVEST"
    },
    {
      "NUM_FINANCIER_CD": 13722,
      "TXT_FINANCIER_NAME": "THE PONDA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13723,
      "TXT_FINANCIER_NAME": "THE NAVA NAGAR CO OPP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13724,
      "TXT_FINANCIER_NAME": "NANDANAGAR SAKH SAHKARI MYDT BANK"
    },
    {
      "NUM_FINANCIER_CD": 13725,
      "TXT_FINANCIER_NAME": "LOVE KUSH CAR BAZAR"
    },
    {
      "NUM_FINANCIER_CD": 13726,
      "TXT_FINANCIER_NAME": "THE SANGALI SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13727,
      "TXT_FINANCIER_NAME": "ASHWINI KUMAR URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13728,
      "TXT_FINANCIER_NAME": "SRI CHANNESH PATTINA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15114,
      "TXT_FINANCIER_NAME": "MAGMA SIN CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 15115,
      "TXT_FINANCIER_NAME": "MAH AND MAH FINANCIAL SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 15116,
      "TXT_FINANCIER_NAME": "MAHANTAPPA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15117,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15118,
      "TXT_FINANCIER_NAME": "MAHAVAIRAB COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15119,
      "TXT_FINANCIER_NAME": "MAHAVEERA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15120,
      "TXT_FINANCIER_NAME": "MAHAVIR MULTISTATE CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15121,
      "TXT_FINANCIER_NAME": "MAHENDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15122,
      "TXT_FINANCIER_NAME": "MAHESH SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15123,
      "TXT_FINANCIER_NAME": "MAHILA BRANCH SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 15124,
      "TXT_FINANCIER_NAME": "MAHILA MARCHAND NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15125,
      "TXT_FINANCIER_NAME": "MAHILA VIKAS NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15126,
      "TXT_FINANCIER_NAME": "MAHINDER SINGH BAGGA"
    },
    {
      "NUM_FINANCIER_CD": 15127,
      "TXT_FINANCIER_NAME": "MAJITHA ROAD AMRITSAR"
    },
    {
      "NUM_FINANCIER_CD": 15128,
      "TXT_FINANCIER_NAME": "MALAI DEVI GRAMIN PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15129,
      "TXT_FINANCIER_NAME": "MALANAD COOPERATIVE AGRICULTURAL RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 15130,
      "TXT_FINANCIER_NAME": "MALANADU COOPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15131,
      "TXT_FINANCIER_NAME": "MALWA LEASING AND CREDITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15132,
      "TXT_FINANCIER_NAME": "MANANAJE VYAVASAYA SEVA SHAKARI BANK MANANJE"
    },
    {
      "NUM_FINANCIER_CD": 15133,
      "TXT_FINANCIER_NAME": "MANAPPARAI"
    },
    {
      "NUM_FINANCIER_CD": 15134,
      "TXT_FINANCIER_NAME": "MANDI BOARD RAIPUR"
    },
    {
      "NUM_FINANCIER_CD": 15135,
      "TXT_FINANCIER_NAME": "MANEKPORE VIVIDH KARYAKARI SAHKARI KHEDUT MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15136,
      "TXT_FINANCIER_NAME": "MANGAL DEEP JAIN"
    },
    {
      "NUM_FINANCIER_CD": 15137,
      "TXT_FINANCIER_NAME": "MANGALAPURAM"
    },
    {
      "NUM_FINANCIER_CD": 15138,
      "TXT_FINANCIER_NAME": "MANIDHAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15139,
      "TXT_FINANCIER_NAME": "MANMOHANAUTO FINANCIAL SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 15140,
      "TXT_FINANCIER_NAME": "MANPREET CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 15141,
      "TXT_FINANCIER_NAME": "MANPREET ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 15142,
      "TXT_FINANCIER_NAME": "MARKHANDE MAHILA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15143,
      "TXT_FINANCIER_NAME": "MAULI KRUPA CO OP CREDIT SOCLTD"
    },
    {
      "NUM_FINANCIER_CD": 15144,
      "TXT_FINANCIER_NAME": "MDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 15145,
      "TXT_FINANCIER_NAME": "ME PADMA SAI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15146,
      "TXT_FINANCIER_NAME": "MEHMEDABAD URBAN PEOPLE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15147,
      "TXT_FINANCIER_NAME": "MERCHANTS LIBERAL COOP BANK LTD APMC"
    },
    {
      "NUM_FINANCIER_CD": 15148,
      "TXT_FINANCIER_NAME": "MESSERS JYOTI TIMBER"
    },
    {
      "NUM_FINANCIER_CD": 15149,
      "TXT_FINANCIER_NAME": "MINT-PLUS FINLEASE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15150,
      "TXT_FINANCIER_NAME": "MIRIYALAGUDAM"
    },
    {
      "NUM_FINANCIER_CD": 15151,
      "TXT_FINANCIER_NAME": "MOHANSEWA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15152,
      "TXT_FINANCIER_NAME": "MONALI ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 15153,
      "TXT_FINANCIER_NAME": "MOOLCHAND MERCHANTILE URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15154,
      "TXT_FINANCIER_NAME": "MOSC MMH"
    },
    {
      "NUM_FINANCIER_CD": 15155,
      "TXT_FINANCIER_NAME": "MP KGB"
    },
    {
      "NUM_FINANCIER_CD": 15156,
      "TXT_FINANCIER_NAME": "MRPL"
    },
    {
      "NUM_FINANCIER_CD": 15157,
      "TXT_FINANCIER_NAME": "MS AJMANI CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 15158,
      "TXT_FINANCIER_NAME": "MS ANJANI CONSULTANCY SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 15159,
      "TXT_FINANCIER_NAME": "MS CHOLLAMANDALAM DBS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15160,
      "TXT_FINANCIER_NAME": "MS GIRDHAR MOTOR AND GENERAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 15161,
      "TXT_FINANCIER_NAME": "MS INDIA BULLS FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15162,
      "TXT_FINANCIER_NAME": "MS JAMMU SAI HOLDING HIRE PURCHASE PVT LTD B/O 44/D NEHRU MARKET JAMMU"
    },
    {
      "NUM_FINANCIER_CD": 15163,
      "TXT_FINANCIER_NAME": "MS JAY BHOLENATH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15164,
      "TXT_FINANCIER_NAME": "MS LAKSHMI INVESTORS AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 15165,
      "TXT_FINANCIER_NAME": "MS MUTHUMANI CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 15166,
      "TXT_FINANCIER_NAME": "MS NAVBHARAT OIL MILLS"
    },
    {
      "NUM_FINANCIER_CD": 15167,
      "TXT_FINANCIER_NAME": "MS NILESH FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 15168,
      "TXT_FINANCIER_NAME": "MS SHRI SAI AUTO FINANCE R GOKAK"
    },
    {
      "NUM_FINANCIER_CD": 15169,
      "TXT_FINANCIER_NAME": "MS. KARVY FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15170,
      "TXT_FINANCIER_NAME": "MSD FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 15171,
      "TXT_FINANCIER_NAME": "MUKKAMALA P A C S LTD NO 905H"
    },
    {
      "NUM_FINANCIER_CD": 15172,
      "TXT_FINANCIER_NAME": "MUPPATHADAM SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15173,
      "TXT_FINANCIER_NAME": "MUTHOOTH CAPITAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15174,
      "TXT_FINANCIER_NAME": "N G I F DELHI"
    },
    {
      "NUM_FINANCIER_CD": 15175,
      "TXT_FINANCIER_NAME": "N H P C LTD"
    },
    {
      "NUM_FINANCIER_CD": 15176,
      "TXT_FINANCIER_NAME": "N.D. FISCAL SERVICES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15177,
      "TXT_FINANCIER_NAME": "N.N. FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15178,
      "TXT_FINANCIER_NAME": "NAIGAON SHRAMIK SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15179,
      "TXT_FINANCIER_NAME": "NALUNAKKAL SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15180,
      "TXT_FINANCIER_NAME": "NANDASAN"
    },
    {
      "NUM_FINANCIER_CD": 15181,
      "TXT_FINANCIER_NAME": "NASHIK MERCHANTILE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15182,
      "TXT_FINANCIER_NAME": "NATHDWARA"
    },
    {
      "NUM_FINANCIER_CD": 15183,
      "TXT_FINANCIER_NAME": "NAVAHIND MULTIPURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15184,
      "TXT_FINANCIER_NAME": "NAVDURGA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15185,
      "TXT_FINANCIER_NAME": "NAVHIND CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15186,
      "TXT_FINANCIER_NAME": "NAVHIND MULTIPURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15187,
      "TXT_FINANCIER_NAME": "NBARD U PHEIT KHARMIHPEN BUILDING KHARMALKI DHANKETI"
    },
    {
      "NUM_FINANCIER_CD": 15188,
      "TXT_FINANCIER_NAME": "NDCC BANK PACS MORTHAD"
    },
    {
      "NUM_FINANCIER_CD": 15189,
      "TXT_FINANCIER_NAME": "NEEACHAL GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 15190,
      "TXT_FINANCIER_NAME": "NERLDC POWER GRID CORPORATION OF INDIA LTD SHILLONG"
    },
    {
      "NUM_FINANCIER_CD": 15191,
      "TXT_FINANCIER_NAME": "NETAGI MULTI PURPOSE CO OP SOCIETY LTD YELLUR BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15192,
      "TXT_FINANCIER_NAME": "NEW RENUABLE ENERGY DEVELOPMENT CORP OF AP"
    },
    {
      "NUM_FINANCIER_CD": 15193,
      "TXT_FINANCIER_NAME": "NEW NAVHIND MULTI PURPOSE MULTI STATE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15194,
      "TXT_FINANCIER_NAME": "NIGOJ NAGARI SAHAKARI PATHSANTHA MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15195,
      "TXT_FINANCIER_NAME": "NILANCHAL GRAMYA BANK ODAGAON"
    },
    {
      "NUM_FINANCIER_CD": 15196,
      "TXT_FINANCIER_NAME": "NIR-RATHI FINANCE PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 15197,
      "TXT_FINANCIER_NAME": "NKGSB CO OP BANK SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 15198,
      "TXT_FINANCIER_NAME": "NKGSP CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15199,
      "TXT_FINANCIER_NAME": "NOVAL COOPRATIVE BANK LTD NOIDA"
    },
    {
      "NUM_FINANCIER_CD": 15200,
      "TXT_FINANCIER_NAME": "NPOL THRIKKAKARA"
    },
    {
      "NUM_FINANCIER_CD": 15201,
      "TXT_FINANCIER_NAME": "NRC BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15202,
      "TXT_FINANCIER_NAME": "NRL"
    },
    {
      "NUM_FINANCIER_CD": 15203,
      "TXT_FINANCIER_NAME": "NSTL"
    },
    {
      "NUM_FINANCIER_CD": 15204,
      "TXT_FINANCIER_NAME": "O N G C"
    },
    {
      "NUM_FINANCIER_CD": 15205,
      "TXT_FINANCIER_NAME": "OBC KIMS KIIT CAMPUS"
    },
    {
      "NUM_FINANCIER_CD": 15206,
      "TXT_FINANCIER_NAME": "OLAM EXPORTS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15207,
      "TXT_FINANCIER_NAME": "OM GORAI N S P MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15208,
      "TXT_FINANCIER_NAME": "OM SAI NAGRIK SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15209,
      "TXT_FINANCIER_NAME": "OM SHREERAM SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15210,
      "TXT_FINANCIER_NAME": "OMKAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15211,
      "TXT_FINANCIER_NAME": "ONSHREERAM SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15212,
      "TXT_FINANCIER_NAME": "OPERATIVE SOCIETY ILKAL)"
    },
    {
      "NUM_FINANCIER_CD": 15213,
      "TXT_FINANCIER_NAME": "ORENTAL BANK OF COMMERCE"
    },
    {
      "NUM_FINANCIER_CD": 15214,
      "TXT_FINANCIER_NAME": "P. H. F. FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15215,
      "TXT_FINANCIER_NAME": "P. H. F. LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 15216,
      "TXT_FINANCIER_NAME": "P. K. F. FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15217,
      "TXT_FINANCIER_NAME": "P. SUMATHIBAI"
    },
    {
      "NUM_FINANCIER_CD": 15218,
      "TXT_FINANCIER_NAME": "P.H.F HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15219,
      "TXT_FINANCIER_NAME": "P.H.F. INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15220,
      "TXT_FINANCIER_NAME": "PADMINI COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15221,
      "TXT_FINANCIER_NAME": "PAINEER ARBON COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15222,
      "TXT_FINANCIER_NAME": "PANANCHARY SERVICE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15223,
      "TXT_FINANCIER_NAME": "PANDIYAN GRAMA BANK"
    },
    {
      "NUM_FINANCIER_CD": 15224,
      "TXT_FINANCIER_NAME": "PARASHRAM MAHARAJ BIGAR SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15225,
      "TXT_FINANCIER_NAME": "PARDIP FINANACE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 15226,
      "TXT_FINANCIER_NAME": "PARIVARA CR CO SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 15227,
      "TXT_FINANCIER_NAME": "PARLI VAIJNATH"
    },
    {
      "NUM_FINANCIER_CD": 15228,
      "TXT_FINANCIER_NAME": "PARTIK FIANNACE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 15229,
      "TXT_FINANCIER_NAME": "PARVITIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 16879,
      "TXT_FINANCIER_NAME": "THE COIMBATORE CITY COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16880,
      "TXT_FINANCIER_NAME": "TUMKUR GRAIN MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16881,
      "TXT_FINANCIER_NAME": "SRI BASAVESWAR URBAN CO-OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 16882,
      "TXT_FINANCIER_NAME": "INDORE PREMIER CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16883,
      "TXT_FINANCIER_NAME": "PARASPARA SAHAYAK CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16884,
      "TXT_FINANCIER_NAME": "VYAPARIK AUDHYOGIK SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16885,
      "TXT_FINANCIER_NAME": "THE UDHANA CITIZEN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16886,
      "TXT_FINANCIER_NAME": "SRI GOKARNANATH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16887,
      "TXT_FINANCIER_NAME": "THE MANGALORE CO-OP TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16888,
      "TXT_FINANCIER_NAME": "JULLUNDER CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16889,
      "TXT_FINANCIER_NAME": "CITIZEN URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16890,
      "TXT_FINANCIER_NAME": "NAGARIA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16891,
      "TXT_FINANCIER_NAME": "NARODA NAGRIK CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16892,
      "TXT_FINANCIER_NAME": "MADURAI DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16893,
      "TXT_FINANCIER_NAME": "HIMACHAL PRADESH STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16894,
      "TXT_FINANCIER_NAME": "SHRI CHHANI NAG. SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16895,
      "TXT_FINANCIER_NAME": "SHRI KRISHNA SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16896,
      "TXT_FINANCIER_NAME": "BARODA MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16897,
      "TXT_FINANCIER_NAME": "MAHESANA SAMAJ CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16898,
      "TXT_FINANCIER_NAME": "ALAVI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16899,
      "TXT_FINANCIER_NAME": "MADURA SOURASHTRA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16900,
      "TXT_FINANCIER_NAME": "PUNJAB AND MAHARASHTRA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16901,
      "TXT_FINANCIER_NAME": "AKOLA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16902,
      "TXT_FINANCIER_NAME": "STATE BANK OF MAURITIUS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16903,
      "TXT_FINANCIER_NAME": "AMRITSAR CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16904,
      "TXT_FINANCIER_NAME": "GANESH BANK OF KURUNDWAD LTD"
    },
    {
      "NUM_FINANCIER_CD": 16905,
      "TXT_FINANCIER_NAME": "VISHWESHWAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16906,
      "TXT_FINANCIER_NAME": "ASTHA MAHILA NAGRIK SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16907,
      "TXT_FINANCIER_NAME": "MAHANAGAR NAGRIK SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16908,
      "TXT_FINANCIER_NAME": "ABHINAV SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16909,
      "TXT_FINANCIER_NAME": "JAIPUR NAGAUR AANCHLIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 16910,
      "TXT_FINANCIER_NAME": "SHRAMIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16911,
      "TXT_FINANCIER_NAME": "CHANDIGARH STATE CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16912,
      "TXT_FINANCIER_NAME": "SUVARNAYUG SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16913,
      "TXT_FINANCIER_NAME": "BHARATI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16914,
      "TXT_FINANCIER_NAME": "JIJAMATA MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16915,
      "TXT_FINANCIER_NAME": "SHREE SADGURU JANGALI MAHARAJ SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16916,
      "TXT_FINANCIER_NAME": "ROPAR CENTRAL CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16917,
      "TXT_FINANCIER_NAME": "KRISHNA MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16918,
      "TXT_FINANCIER_NAME": "AKOLA JANATA COMMERCIAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16919,
      "TXT_FINANCIER_NAME": "SHRIRAM URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16920,
      "TXT_FINANCIER_NAME": "NASIK MERCHANTS CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16921,
      "TXT_FINANCIER_NAME": "NASIK JILHA MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16922,
      "TXT_FINANCIER_NAME": "GODAVARI URBAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16923,
      "TXT_FINANCIER_NAME": "NASIK DISTRICT INDUSTRIAL & MERCANTILE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16924,
      "TXT_FINANCIER_NAME": "TRICHUR URBAN CO-0P. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16925,
      "TXT_FINANCIER_NAME": "ICHALKARANJI JANATA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16926,
      "TXT_FINANCIER_NAME": "KOLHAPUR DISTRICT CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16927,
      "TXT_FINANCIER_NAME": "THE TASGAON URBAN CO OP BANK LTD TASGAON"
    },
    {
      "NUM_FINANCIER_CD": 16928,
      "TXT_FINANCIER_NAME": "JAIN FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 16929,
      "TXT_FINANCIER_NAME": "The Mehsana Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 16930,
      "TXT_FINANCIER_NAME": "Ranuj Nagrik Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16931,
      "TXT_FINANCIER_NAME": "The Sabarkantha Gandhinagar gramin Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16932,
      "TXT_FINANCIER_NAME": "The Banaskantha District Central Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 16933,
      "TXT_FINANCIER_NAME": "United India Insurance Co ltd"
    },
    {
      "NUM_FINANCIER_CD": 16934,
      "TXT_FINANCIER_NAME": "NEYVELI LIGNITE CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16935,
      "TXT_FINANCIER_NAME": "NEYVELI LIGNITE CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16936,
      "TXT_FINANCIER_NAME": "GAS AUTHORITY OF INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16937,
      "TXT_FINANCIER_NAME": "SAMRUDDHI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16938,
      "TXT_FINANCIER_NAME": "Gujarat Industrial Co.Op. Bank"
    },
    {
      "NUM_FINANCIER_CD": 16939,
      "TXT_FINANCIER_NAME": "The Associates India Financial services Private Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16940,
      "TXT_FINANCIER_NAME": "Airport Authority of India"
    },
    {
      "NUM_FINANCIER_CD": 16941,
      "TXT_FINANCIER_NAME": "GOVERNOR OF HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 16942,
      "TXT_FINANCIER_NAME": "GE CAPITAL TRANSPORTATION FIN SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 16943,
      "TXT_FINANCIER_NAME": "GLOBAL TRUST BANK"
    },
    {
      "NUM_FINANCIER_CD": 16944,
      "TXT_FINANCIER_NAME": "LOKVIKAS NAGARI SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16945,
      "TXT_FINANCIER_NAME": "CITY UNION BANK"
    },
    {
      "NUM_FINANCIER_CD": 16946,
      "TXT_FINANCIER_NAME": "THE BANK OF RAJASTHAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 16947,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16948,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16949,
      "TXT_FINANCIER_NAME": "UTI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16950,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 16951,
      "TXT_FINANCIER_NAME": "GAUTAM SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 16952,
      "TXT_FINANCIER_NAME": "KARAD URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16953,
      "TXT_FINANCIER_NAME": "KARAD URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16954,
      "TXT_FINANCIER_NAME": "INDIAN FARMERS FERTILIZER CO-OPERATIVE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16955,
      "TXT_FINANCIER_NAME": "SHREE DEESA NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16956,
      "TXT_FINANCIER_NAME": "THE LAKSHMI GENERAL FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16957,
      "TXT_FINANCIER_NAME": "MANAPPURAM GENERAL FINANCE & LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 16958,
      "TXT_FINANCIER_NAME": "DELHI STATE TAXI OPERATOR COOPERATIVE THRIFT CREDIT AND SERVICE SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 16959,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DIST.CO OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16960,
      "TXT_FINANCIER_NAME": "THE KARNAL CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16961,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD.,KOLKATA,PNB & ALL OTHER CONSORTIUM BANK"
    },
    {
      "NUM_FINANCIER_CD": 16962,
      "TXT_FINANCIER_NAME": "GANDHI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 16963,
      "TXT_FINANCIER_NAME": "VASANTDADA SHETKARI SAHAKARI BANK LTD SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 16964,
      "TXT_FINANCIER_NAME": "THE KHAMGAON URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 16965,
      "TXT_FINANCIER_NAME": "KARUR VYSYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 16966,
      "TXT_FINANCIER_NAME": "NUTAN NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16967,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16968,
      "TXT_FINANCIER_NAME": "THE VISAKHAPATNAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16969,
      "TXT_FINANCIER_NAME": "THE NAVNIRMAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16970,
      "TXT_FINANCIER_NAME": "Shivalik Mercantile Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16971,
      "TXT_FINANCIER_NAME": "CATHOLIC SYRIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 16972,
      "TXT_FINANCIER_NAME": "JAIN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16973,
      "TXT_FINANCIER_NAME": "COLOUR MERCHANTS CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16974,
      "TXT_FINANCIER_NAME": "THE JODHPUR CENTRAL CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16975,
      "TXT_FINANCIER_NAME": "THE KOPARGAON PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16976,
      "TXT_FINANCIER_NAME": "KOKAN MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12230,
      "TXT_FINANCIER_NAME": "SHRI KAKASAHEB HIRALAL MAGANLAL CHOUDHARI NAGARI SAHAKARI PAT SHASHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12231,
      "TXT_FINANCIER_NAME": "SHRI KAKASHAHEB HIRALAL MAGANLAL CHOUDHARI NAGARI SAHAKARI PATSHUSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12232,
      "TXT_FINANCIER_NAME": "SHRI KAKASAHEB HIRALAL MAGANLAL CHOUDHARI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12233,
      "TXT_FINANCIER_NAME": "SHRI KAKASHAHEB HIRALAL MAGANLAL CHOUDHARI NAGARI SAHAKARI PATSHAUSTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12234,
      "TXT_FINANCIER_NAME": "NEW RISSAM TRADING CORP"
    },
    {
      "NUM_FINANCIER_CD": 12235,
      "TXT_FINANCIER_NAME": "THE VINAYAK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12236,
      "TXT_FINANCIER_NAME": "MUTHAS FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12237,
      "TXT_FINANCIER_NAME": "DILATORY MARKETING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12238,
      "TXT_FINANCIER_NAME": "SHIVKRUPA BIGAR SHETI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12239,
      "TXT_FINANCIER_NAME": "MALAPURAM SERVICE CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12240,
      "TXT_FINANCIER_NAME": "VIVEKANAND AKRUSHAK SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12241,
      "TXT_FINANCIER_NAME": "DELWADA SEVA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12242,
      "TXT_FINANCIER_NAME": "THE BUNDI URBAN CORPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12243,
      "TXT_FINANCIER_NAME": "SARVADA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12244,
      "TXT_FINANCIER_NAME": "MONARK LEASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12245,
      "TXT_FINANCIER_NAME": "THAI ARUL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12246,
      "TXT_FINANCIER_NAME": "THE HP MINORITIES FINANCE DEVELOPMENT CORRPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12247,
      "TXT_FINANCIER_NAME": "S B N LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12248,
      "TXT_FINANCIER_NAME": "DEEPALAKSHMI FINANACE"
    },
    {
      "NUM_FINANCIER_CD": 12249,
      "TXT_FINANCIER_NAME": "RAJ INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12250,
      "TXT_FINANCIER_NAME": "MANIPUR WOMENS COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12251,
      "TXT_FINANCIER_NAME": "ANOPCHAND JAIN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12252,
      "TXT_FINANCIER_NAME": "SALAMATH CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12253,
      "TXT_FINANCIER_NAME": "MAHAVEER NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 12254,
      "TXT_FINANCIER_NAME": "STAR FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 12255,
      "TXT_FINANCIER_NAME": "THE AKSHAYA CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12256,
      "TXT_FINANCIER_NAME": "UNION LOAN POINT"
    },
    {
      "NUM_FINANCIER_CD": 12257,
      "TXT_FINANCIER_NAME": "NITHYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12258,
      "TXT_FINANCIER_NAME": "S J P ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12259,
      "TXT_FINANCIER_NAME": "PRECISE FINANCE AND INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12260,
      "TXT_FINANCIER_NAME": "RAVINDRA MULTIPURPOSE CO OP SO LTD KUKKUNDOOR"
    },
    {
      "NUM_FINANCIER_CD": 12261,
      "TXT_FINANCIER_NAME": "MOULANA AZAD GRAMIN BIGAR SEHETI SAH PATH SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12262,
      "TXT_FINANCIER_NAME": "THE CO OP BANK OF MEHSANA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12263,
      "TXT_FINANCIER_NAME": "MOULANA AZAD GRAMIN BIGER SHETI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12264,
      "TXT_FINANCIER_NAME": "NAVJEEVAN MULTI STATE CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12265,
      "TXT_FINANCIER_NAME": "SRI KRISHNA PATHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12266,
      "TXT_FINANCIER_NAME": "JINESHWAR NAGARI SAHAKARI PATH SANSTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 12267,
      "TXT_FINANCIER_NAME": "THE INDORE CITZEN SAK SAKHRITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12268,
      "TXT_FINANCIER_NAME": "EKM COOP AGRI AND RURAL DEV BANK"
    },
    {
      "NUM_FINANCIER_CD": 12269,
      "TXT_FINANCIER_NAME": "EKM COOP AGRL AND RURAL DEV BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12270,
      "TXT_FINANCIER_NAME": "GUJRAT RAJYA SAHKARI KRUSHI AND GRAMIN VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12271,
      "TXT_FINANCIER_NAME": "JANATA NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12272,
      "TXT_FINANCIER_NAME": "SRI MAHAGANAPATHI SOUHARDA BATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12273,
      "TXT_FINANCIER_NAME": "PIONEER PROJECTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12274,
      "TXT_FINANCIER_NAME": "MAVDI ADIVASI VIVIDH KARYAKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12275,
      "TXT_FINANCIER_NAME": "SHRISHTI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 12276,
      "TXT_FINANCIER_NAME": "SHREE BALAJI SOUHARDA PATTIN SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12277,
      "TXT_FINANCIER_NAME": "VYTHIRI PRADAMIKA SAHAGARANA KARSHIGA VIGASANA BANK"
    },
    {
      "NUM_FINANCIER_CD": 12278,
      "TXT_FINANCIER_NAME": "SHREE BALAJI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12279,
      "TXT_FINANCIER_NAME": "RAJGURU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12280,
      "TXT_FINANCIER_NAME": "PARAMESHWARI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12281,
      "TXT_FINANCIER_NAME": "ANANTHAM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12282,
      "TXT_FINANCIER_NAME": "VYAVSAYIK EVAM AUDHYOGIK SAHAHARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 12283,
      "TXT_FINANCIER_NAME": "PERIYASAMY CREDIT INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12284,
      "TXT_FINANCIER_NAME": "SMT GAWARADEVI FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 12285,
      "TXT_FINANCIER_NAME": "NAGARGAON GR V K S SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12286,
      "TXT_FINANCIER_NAME": "SASTHAMCOTTA CO OP AGRI AND RURAL DEVEL BANK"
    },
    {
      "NUM_FINANCIER_CD": 12287,
      "TXT_FINANCIER_NAME": "SAMTANI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12288,
      "TXT_FINANCIER_NAME": "RIDHI SIDHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12289,
      "TXT_FINANCIER_NAME": "AVANTI FEEDS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12290,
      "TXT_FINANCIER_NAME": "ANDAMAN N NICOBAR STATE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12291,
      "TXT_FINANCIER_NAME": "ARYA VYSYA SRIRAMA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12292,
      "TXT_FINANCIER_NAME": "SHRI GHADGENATH GR BIR SHETI SAH PATS LTD"
    },
    {
      "NUM_FINANCIER_CD": 12293,
      "TXT_FINANCIER_NAME": "JAI JAWAN NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12294,
      "TXT_FINANCIER_NAME": "CENTRAL SER CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12295,
      "TXT_FINANCIER_NAME": "TALEGAON NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12296,
      "TXT_FINANCIER_NAME": "SIRANJEEVE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12297,
      "TXT_FINANCIER_NAME": "B D CREDITS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12298,
      "TXT_FINANCIER_NAME": "J D MOTOR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12299,
      "TXT_FINANCIER_NAME": "THE DHAVALI URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12300,
      "TXT_FINANCIER_NAME": "SIDDIVINAYAK SOWHARDA PATTINA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 12301,
      "TXT_FINANCIER_NAME": "NAGAR SAMABAI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12302,
      "TXT_FINANCIER_NAME": "KIRAN GRAMIN PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12303,
      "TXT_FINANCIER_NAME": "SANGLI ZILLA MAJI SAINIK NAGARI SAH PATHSANSTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 12304,
      "TXT_FINANCIER_NAME": "RAJE SAMBHAJI GRAMIN BIGAR SHETI SAH PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12305,
      "TXT_FINANCIER_NAME": "HIRANAND GOPALDASS"
    },
    {
      "NUM_FINANCIER_CD": 12306,
      "TXT_FINANCIER_NAME": "PUBLIC FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12307,
      "TXT_FINANCIER_NAME": "SHREE TULJA BHAVANI GRAMIN BIGAR SHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12308,
      "TXT_FINANCIER_NAME": "SAHANA MULTIPURPOSE SOUHARDHA COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12309,
      "TXT_FINANCIER_NAME": "PALLAVAN GARMA BANK"
    },
    {
      "NUM_FINANCIER_CD": 12310,
      "TXT_FINANCIER_NAME": "DANDELAPPA KRUSHI PATTIN SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 12311,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12312,
      "TXT_FINANCIER_NAME": "SHRI DOODH GANGA COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12313,
      "TXT_FINANCIER_NAME": "SHRI BHIMA SHANKAR NAGARI PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12314,
      "TXT_FINANCIER_NAME": "AWARNA LAKMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12315,
      "TXT_FINANCIER_NAME": "DHAKERKHERI MINI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12316,
      "TXT_FINANCIER_NAME": "AYODHYA NAGARI SAHAKARI PAT SANSTHA MYDH"
    },
    {
      "NUM_FINANCIER_CD": 12317,
      "TXT_FINANCIER_NAME": "AB BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12318,
      "TXT_FINANCIER_NAME": "THE KALLUMALA AGRICULTURAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12319,
      "TXT_FINANCIER_NAME": "INDIA SECURITY PRESS EMPLOYES CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12320,
      "TXT_FINANCIER_NAME": "SRI RENUKA URBAN CO OPERATIVE CREDIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 12321,
      "TXT_FINANCIER_NAME": "HEMARADDI MALLAMMA SOUHARDHA PATTINA SAHAKAHRI NI"
    },
    {
      "NUM_FINANCIER_CD": 12322,
      "TXT_FINANCIER_NAME": "KAVERI NITHIYAGAM"
    },
    {
      "NUM_FINANCIER_CD": 12323,
      "TXT_FINANCIER_NAME": "AMBIKA GRAMIN BIGARSHETI GRAMIN PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13729,
      "TXT_FINANCIER_NAME": "AMBEVANI VIVIDH KARYAKARI VIKAS SEVA SAHAKARI SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13730,
      "TXT_FINANCIER_NAME": "D HOSAHALLI CO OPRATIVE SOCIETY LTD PALGUNI"
    },
    {
      "NUM_FINANCIER_CD": 13731,
      "TXT_FINANCIER_NAME": "SAI KRUPA GRAMIN BIGAR SHETI SAHKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13732,
      "TXT_FINANCIER_NAME": "COMPANIONS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13733,
      "TXT_FINANCIER_NAME": "KUBERALAXMI TOWERS"
    },
    {
      "NUM_FINANCIER_CD": 13734,
      "TXT_FINANCIER_NAME": "SHREE VEMANA SOUHARDA CREDIT CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13735,
      "TXT_FINANCIER_NAME": "BHAGYASHRI NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13736,
      "TXT_FINANCIER_NAME": "EX ENGINEER TR GETCO"
    },
    {
      "NUM_FINANCIER_CD": 13737,
      "TXT_FINANCIER_NAME": "PANKAJ AND CO AUTOMOBILE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 13738,
      "TXT_FINANCIER_NAME": "BALLALESHWAR SHAKARI PETHPEDHI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13739,
      "TXT_FINANCIER_NAME": "JANAPRIYA SOUHARDA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13740,
      "TXT_FINANCIER_NAME": "JEETENDRA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13741,
      "TXT_FINANCIER_NAME": "AGL FINANCE AND UDYOG"
    },
    {
      "NUM_FINANCIER_CD": 13742,
      "TXT_FINANCIER_NAME": "FINE AUTO LEASING AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13743,
      "TXT_FINANCIER_NAME": "SHARAVTI CREDIT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13744,
      "TXT_FINANCIER_NAME": "VISHWAKARMA SUVARANAKAR SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 13745,
      "TXT_FINANCIER_NAME": "IRINJALAKUDA SOCIAL WELFARE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13746,
      "TXT_FINANCIER_NAME": "BRAHMANAGAR SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13747,
      "TXT_FINANCIER_NAME": "SRI GURUDEVA VIVIDODDESHA SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13748,
      "TXT_FINANCIER_NAME": "KANIFNATH GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13749,
      "TXT_FINANCIER_NAME": "NETT APP INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13750,
      "TXT_FINANCIER_NAME": "VISHWAKARMA SUVARANAKAR SAH PAT SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 13751,
      "TXT_FINANCIER_NAME": "AMRUTHA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13752,
      "TXT_FINANCIER_NAME": "NATIONAL SCHEDULE CASTES FINANCE AND DEVELOPMENT CORP"
    },
    {
      "NUM_FINANCIER_CD": 13753,
      "TXT_FINANCIER_NAME": "MUMBAI OVERSEAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 13754,
      "TXT_FINANCIER_NAME": "AATHMASHAKTHI VIVIDODESHA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13755,
      "TXT_FINANCIER_NAME": "VIJAYA RAITHRA SEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13756,
      "TXT_FINANCIER_NAME": "EXIM BANK"
    },
    {
      "NUM_FINANCIER_CD": 13757,
      "TXT_FINANCIER_NAME": "SHRI VINAYAK UR CO OP CR SOC NIY"
    },
    {
      "NUM_FINANCIER_CD": 13758,
      "TXT_FINANCIER_NAME": "AJINKYA UTTAMRAO AUTADE MULTI STATE CO OP CREADIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13759,
      "TXT_FINANCIER_NAME": "AMAR HOLDINGS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13760,
      "TXT_FINANCIER_NAME": "DHOLKA NAGARIK BACHAT SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13761,
      "TXT_FINANCIER_NAME": "SHRI OMKAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13762,
      "TXT_FINANCIER_NAME": "BASAVESHWAR VIVIDODDESH SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13763,
      "TXT_FINANCIER_NAME": "MAAS FINANCE SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13764,
      "TXT_FINANCIER_NAME": "VIVEKANANDA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13765,
      "TXT_FINANCIER_NAME": "JANKALYAN MULTISTATE CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13766,
      "TXT_FINANCIER_NAME": "DHANSHRI URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13767,
      "TXT_FINANCIER_NAME": "LAXMI VISHNU GRAMIN SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13768,
      "TXT_FINANCIER_NAME": "THE PALLACHAR SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13769,
      "TXT_FINANCIER_NAME": "MAHESH GRAMIN BIGAR SHETI SAHAKARI BANK PRIVAT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13770,
      "TXT_FINANCIER_NAME": "SUBATHU URBAN CO OP NATCS LTD"
    },
    {
      "NUM_FINANCIER_CD": 13771,
      "TXT_FINANCIER_NAME": "ABC LEATHERS"
    },
    {
      "NUM_FINANCIER_CD": 13772,
      "TXT_FINANCIER_NAME": "TANYA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 13773,
      "TXT_FINANCIER_NAME": "THE CHATTANCHAL CO OP URBAN SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13774,
      "TXT_FINANCIER_NAME": "HARI OM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13775,
      "TXT_FINANCIER_NAME": "OKKALIGA SAMUDAYA PATTHINA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 13776,
      "TXT_FINANCIER_NAME": "SURIYA AND CO BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 13777,
      "TXT_FINANCIER_NAME": "THE SANQUELIM URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13778,
      "TXT_FINANCIER_NAME": "NARIMAN POINT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 13779,
      "TXT_FINANCIER_NAME": "ESHWAR NAGARI SAHKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13780,
      "TXT_FINANCIER_NAME": "DATTAGURU CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13781,
      "TXT_FINANCIER_NAME": "CENTRAL MADHYAPRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 13782,
      "TXT_FINANCIER_NAME": "GAJRAJ MOTOR AND GENERAL FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13783,
      "TXT_FINANCIER_NAME": "SHRI PAREVAVEER CO OPERATIVE CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13784,
      "TXT_FINANCIER_NAME": "MARAVANTHE BADAKERE VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13785,
      "TXT_FINANCIER_NAME": "KALANJA BALILA PRIMARY AGRICULTURE CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13786,
      "TXT_FINANCIER_NAME": "JANSEVAK SAHAKARI PATHPEDHI MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 13787,
      "TXT_FINANCIER_NAME": "SHASHANK KUMBHAT"
    },
    {
      "NUM_FINANCIER_CD": 13788,
      "TXT_FINANCIER_NAME": "MYSORE ZILL MAHILA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13789,
      "TXT_FINANCIER_NAME": "THE WAINGANGA GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13790,
      "TXT_FINANCIER_NAME": "PANNA LAL PRAVESH KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 13791,
      "TXT_FINANCIER_NAME": "RAHUL URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13792,
      "TXT_FINANCIER_NAME": "UTI INVESTMENT ADVISORY SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 13793,
      "TXT_FINANCIER_NAME": "THE ATHOULA CORPORATIVE AGGRICULTURE MULTIPURPOSE SERVICE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13794,
      "TXT_FINANCIER_NAME": "JIVALA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13795,
      "TXT_FINANCIER_NAME": "YESHODA MALTISTATE CO OP CREDIT SOCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13796,
      "TXT_FINANCIER_NAME": "SINGER FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13797,
      "TXT_FINANCIER_NAME": "PRABHAT MULTI STATE CO OP CREDIT SOCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13798,
      "TXT_FINANCIER_NAME": "SUMANTHA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13799,
      "TXT_FINANCIER_NAME": "ASHWINI CREDIT SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13800,
      "TXT_FINANCIER_NAME": "GEO S V P L FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13801,
      "TXT_FINANCIER_NAME": "SHRI SOMESHWAR PATTIN SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13802,
      "TXT_FINANCIER_NAME": "THE GOKAK TALUKA PRIMARY CO OP AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13803,
      "TXT_FINANCIER_NAME": "NAGAR NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13804,
      "TXT_FINANCIER_NAME": "OMEGA LEASING AND INVESTEMENTS"
    },
    {
      "NUM_FINANCIER_CD": 13805,
      "TXT_FINANCIER_NAME": "PRASAN FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13806,
      "TXT_FINANCIER_NAME": "RANBAXY LABORATORIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 13807,
      "TXT_FINANCIER_NAME": "JAI BHOJALRAM SHARAFI SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13808,
      "TXT_FINANCIER_NAME": "VIKRAMS FINANCIAL SREVICE"
    },
    {
      "NUM_FINANCIER_CD": 13809,
      "TXT_FINANCIER_NAME": "AMIRTHA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13810,
      "TXT_FINANCIER_NAME": "BHRAMAPURI UR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13811,
      "TXT_FINANCIER_NAME": "SHRI KAMALJADEVI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13812,
      "TXT_FINANCIER_NAME": "THE ADARSH SAHAKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13813,
      "TXT_FINANCIER_NAME": "SANDESH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13814,
      "TXT_FINANCIER_NAME": "SEWA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 13815,
      "TXT_FINANCIER_NAME": "DHIRNOJ NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13816,
      "TXT_FINANCIER_NAME": "THE ATHOULA CO OP AGRI MULTIPURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13817,
      "TXT_FINANCIER_NAME": "RAGHUVANSI SHARAFI SAHAKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 13818,
      "TXT_FINANCIER_NAME": "JANSHANTI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13819,
      "TXT_FINANCIER_NAME": "SATPATTI GRAMVIKAS SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13820,
      "TXT_FINANCIER_NAME": "PRAVEEN CREDIT SOWHARDA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13821,
      "TXT_FINANCIER_NAME": "ADARSHA VIVIDHODDESHA SAHAKARA SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13822,
      "TXT_FINANCIER_NAME": "GLOBAL CAPITAL CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 15230,
      "TXT_FINANCIER_NAME": "PBS BR PATHANAMTHITTA"
    },
    {
      "NUM_FINANCIER_CD": 15231,
      "TXT_FINANCIER_NAME": "PEERUMEDU TALUK CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15232,
      "TXT_FINANCIER_NAME": "PEZHAKKAPPILLY GENERAL MERCHANTS CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15233,
      "TXT_FINANCIER_NAME": "PFEIFFER VACCUM INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15234,
      "TXT_FINANCIER_NAME": "PIRSAL GRAMIN BIGARSHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15235,
      "TXT_FINANCIER_NAME": "PNB JHARIA"
    },
    {
      "NUM_FINANCIER_CD": 15236,
      "TXT_FINANCIER_NAME": "POSOCO"
    },
    {
      "NUM_FINANCIER_CD": 15237,
      "TXT_FINANCIER_NAME": "PRAGAIT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15238,
      "TXT_FINANCIER_NAME": "PRAVASIS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15239,
      "TXT_FINANCIER_NAME": "PSB ADOOR BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15240,
      "TXT_FINANCIER_NAME": "PUNE MERCHANT SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15241,
      "TXT_FINANCIER_NAME": "PUSHPENDRA KUMAR JAIN"
    },
    {
      "NUM_FINANCIER_CD": 15242,
      "TXT_FINANCIER_NAME": "R I B MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 15243,
      "TXT_FINANCIER_NAME": "R RAMESH CHAND"
    },
    {
      "NUM_FINANCIER_CD": 15244,
      "TXT_FINANCIER_NAME": "R.D FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15245,
      "TXT_FINANCIER_NAME": "R.D. FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15246,
      "TXT_FINANCIER_NAME": "R.RAHEJA INVESTMENTS PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15247,
      "TXT_FINANCIER_NAME": "RADHE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15248,
      "TXT_FINANCIER_NAME": "RAJ CREDITS INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 15249,
      "TXT_FINANCIER_NAME": "RAJKAMAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15250,
      "TXT_FINANCIER_NAME": "RAJSHRI SHAHU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15251,
      "TXT_FINANCIER_NAME": "RAJYOG NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15252,
      "TXT_FINANCIER_NAME": "RAM GARHIA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15253,
      "TXT_FINANCIER_NAME": "RELIANCES CONSUMER FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15254,
      "TXT_FINANCIER_NAME": "RHC HOLDING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15255,
      "TXT_FINANCIER_NAME": "RMSSI PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15256,
      "TXT_FINANCIER_NAME": "ROMSY ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 15257,
      "TXT_FINANCIER_NAME": "RUBBY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15258,
      "TXT_FINANCIER_NAME": "S L N FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15259,
      "TXT_FINANCIER_NAME": "S. C. F. FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15260,
      "TXT_FINANCIER_NAME": "SABARKANTHAGANDHINAGAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15261,
      "TXT_FINANCIER_NAME": "SADDICHHA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15262,
      "TXT_FINANCIER_NAME": "SADICHCHA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15263,
      "TXT_FINANCIER_NAME": "SAFESTAR SOUHARDA SAHAKARI NIYAMITA BRANCH KARWAR"
    },
    {
      "NUM_FINANCIER_CD": 15264,
      "TXT_FINANCIER_NAME": "SAHASTRA ARJUN NAGARI SAHKARI PATFEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15265,
      "TXT_FINANCIER_NAME": "SAJJANRAJ SINGHVI"
    },
    {
      "NUM_FINANCIER_CD": 15266,
      "TXT_FINANCIER_NAME": "SAMINTRA GRAMIN BIGARSHETI SAH PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 15267,
      "TXT_FINANCIER_NAME": "SAMPADA SAHA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15268,
      "TXT_FINANCIER_NAME": "SANJIWANI CREDIT COOPRATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15269,
      "TXT_FINANCIER_NAME": "SARAKARI NOUKARAR SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15270,
      "TXT_FINANCIER_NAME": "SARVADA MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15271,
      "TXT_FINANCIER_NAME": "SARVE HARAYANA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15272,
      "TXT_FINANCIER_NAME": "SAWATAMALI NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15273,
      "TXT_FINANCIER_NAME": "SAYAR CHAND KATARIYA"
    },
    {
      "NUM_FINANCIER_CD": 15274,
      "TXT_FINANCIER_NAME": "SBT SME BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15275,
      "TXT_FINANCIER_NAME": "SDM COLLEGE BRANCH HASSAN"
    },
    {
      "NUM_FINANCIER_CD": 15276,
      "TXT_FINANCIER_NAME": "SE O AND M AP TRANSCO"
    },
    {
      "NUM_FINANCIER_CD": 15277,
      "TXT_FINANCIER_NAME": "SEENA PARISAR NAGARI SAH PATSANSTHA MARYADIT MIRAJGAON"
    },
    {
      "NUM_FINANCIER_CD": 15278,
      "TXT_FINANCIER_NAME": "SEENA PARISAR NAGARI SAH. PATSANSTHA MARYADIT MIRAJGAON"
    },
    {
      "NUM_FINANCIER_CD": 15279,
      "TXT_FINANCIER_NAME": "SENBAGAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15280,
      "TXT_FINANCIER_NAME": "SHADIMARG PULWAMA"
    },
    {
      "NUM_FINANCIER_CD": 15281,
      "TXT_FINANCIER_NAME": "SHAHIBABAD RAJENDRA NAGAR GHAZIABAD"
    },
    {
      "NUM_FINANCIER_CD": 15282,
      "TXT_FINANCIER_NAME": "SHAMANROOPJEE MAHENDRA"
    },
    {
      "NUM_FINANCIER_CD": 15283,
      "TXT_FINANCIER_NAME": "SHANKSESHWAR INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 15284,
      "TXT_FINANCIER_NAME": "SHANMUKH MOTORS AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15285,
      "TXT_FINANCIER_NAME": "SHANSHA AND LUHARI"
    },
    {
      "NUM_FINANCIER_CD": 15286,
      "TXT_FINANCIER_NAME": "SHANTAPPANA MIRJI UR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15287,
      "TXT_FINANCIER_NAME": "SHARAVATHI CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15288,
      "TXT_FINANCIER_NAME": "SHIGGOAN URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15289,
      "TXT_FINANCIER_NAME": "SHIRSOLI SAHAKARI PATHPEDHI LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 15290,
      "TXT_FINANCIER_NAME": "SHIVASHARANE HEMARADDI MALLAMMA PATT SAH SANGH NYT"
    },
    {
      "NUM_FINANCIER_CD": 15291,
      "TXT_FINANCIER_NAME": "SHIVSAGAM VIVIDODDESHAGALA SOUH SAHA NIY"
    },
    {
      "NUM_FINANCIER_CD": 15292,
      "TXT_FINANCIER_NAME": "SHIVSAHGAM VIVIDODDESHAGALA SOUGH SAHA NIY"
    },
    {
      "NUM_FINANCIER_CD": 15293,
      "TXT_FINANCIER_NAME": "SHREE BEELUR GURUBASAVA PATTINA SAHAKAR SANGHA NIYAMITBAGALKOT"
    },
    {
      "NUM_FINANCIER_CD": 15294,
      "TXT_FINANCIER_NAME": "SHREE BHABHAR NAGARIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15295,
      "TXT_FINANCIER_NAME": "SHREE MANJUNATH MULTI PURPOSE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15296,
      "TXT_FINANCIER_NAME": "SHREE SAI DATTA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15297,
      "TXT_FINANCIER_NAME": "SHREE SAMARTHA NAGARI SAHAKARI PATSANTH"
    },
    {
      "NUM_FINANCIER_CD": 15298,
      "TXT_FINANCIER_NAME": "SHREE SARASWATI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15299,
      "TXT_FINANCIER_NAME": "SHREE VIJALPORE VIBHAG S S MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15300,
      "TXT_FINANCIER_NAME": "SHRI RENUKACHARYA PATTINA SAHAKARI SANGH NYT"
    },
    {
      "NUM_FINANCIER_CD": 15301,
      "TXT_FINANCIER_NAME": "SHRI VASAVI SOUHARADA PATTHINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 15302,
      "TXT_FINANCIER_NAME": "SHRIBIR FINANCE NEW DELHI"
    },
    {
      "NUM_FINANCIER_CD": 15303,
      "TXT_FINANCIER_NAME": "SIHORI NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15304,
      "TXT_FINANCIER_NAME": "SIMHACHALAM"
    },
    {
      "NUM_FINANCIER_CD": 15305,
      "TXT_FINANCIER_NAME": "SIMPSON AND GENERAL FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 15306,
      "TXT_FINANCIER_NAME": "SINGH MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 15307,
      "TXT_FINANCIER_NAME": "SIR ANJANEYA VIVIDODESHA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15308,
      "TXT_FINANCIER_NAME": "SIR C R R COLLEGE BRANCH ELURU"
    },
    {
      "NUM_FINANCIER_CD": 15309,
      "TXT_FINANCIER_NAME": "SIRA TALUK SRI KANAKA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15310,
      "TXT_FINANCIER_NAME": "SIRISI URBAN CO OPPARTIVE BANK SHIRALI"
    },
    {
      "NUM_FINANCIER_CD": 15311,
      "TXT_FINANCIER_NAME": "SJVN LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15312,
      "TXT_FINANCIER_NAME": "SONI MARIECHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15313,
      "TXT_FINANCIER_NAME": "SRI GOURISHANKAR SAUHARDA PATTIN SAHAKARI NIYAMITA HULGERI COMPLEX"
    },
    {
      "NUM_FINANCIER_CD": 15314,
      "TXT_FINANCIER_NAME": "SRI GUBERAN INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 15315,
      "TXT_FINANCIER_NAME": "SRI INDU FINANCE NAMAKKAL"
    },
    {
      "NUM_FINANCIER_CD": 15316,
      "TXT_FINANCIER_NAME": "SSMS URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15317,
      "TXT_FINANCIER_NAME": "ST MILAGRIS CRIDIT CO OPERATIVE SOCIETY SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 15318,
      "TXT_FINANCIER_NAME": "ST THOMAS COLLEGE STAFF CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15319,
      "TXT_FINANCIER_NAME": "ST.ALOYSIUS COLLEGE TEACHERS CR.CO.OP.SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15320,
      "TXT_FINANCIER_NAME": "STAR-RITE SECURITIES TRUST LTD"
    },
    {
      "NUM_FINANCIER_CD": 15321,
      "TXT_FINANCIER_NAME": "STATE BANK OF KARNATAKA"
    },
    {
      "NUM_FINANCIER_CD": 15322,
      "TXT_FINANCIER_NAME": "STATE BANK OF KRICHCO"
    },
    {
      "NUM_FINANCIER_CD": 15323,
      "TXT_FINANCIER_NAME": "STATE BANK OF RAMPURA PHUL"
    },
    {
      "NUM_FINANCIER_CD": 15324,
      "TXT_FINANCIER_NAME": "STOCK HOLDING NAVAL GROUP"
    },
    {
      "NUM_FINANCIER_CD": 15325,
      "TXT_FINANCIER_NAME": "STOCK HOLDING PARSIK JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15326,
      "TXT_FINANCIER_NAME": "SUBBARAO PET BRANCK TPGUDEM"
    },
    {
      "NUM_FINANCIER_CD": 15327,
      "TXT_FINANCIER_NAME": "SYNDICATE RYATARA SEVA SAHAKARI SANGA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15328,
      "TXT_FINANCIER_NAME": "SYNDICATION BANK BAGHOLA"
    },
    {
      "NUM_FINANCIER_CD": 15329,
      "TXT_FINANCIER_NAME": "T H D C I L KOTESHWAR THERI"
    },
    {
      "NUM_FINANCIER_CD": 15330,
      "TXT_FINANCIER_NAME": "T H D C INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15331,
      "TXT_FINANCIER_NAME": "T N PURA"
    },
    {
      "NUM_FINANCIER_CD": 15332,
      "TXT_FINANCIER_NAME": "T.W. BHOJWANI PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15333,
      "TXT_FINANCIER_NAME": "TADINADA"
    },
    {
      "NUM_FINANCIER_CD": 15334,
      "TXT_FINANCIER_NAME": "TAKHTRAJ JAIN AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 15335,
      "TXT_FINANCIER_NAME": "TALWAR MOBILES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15336,
      "TXT_FINANCIER_NAME": "TCIL"
    },
    {
      "NUM_FINANCIER_CD": 15337,
      "TXT_FINANCIER_NAME": "TECHNOVALLY"
    },
    {
      "NUM_FINANCIER_CD": 15338,
      "TXT_FINANCIER_NAME": "THE"
    },
    {
      "NUM_FINANCIER_CD": 15339,
      "TXT_FINANCIER_NAME": "THE BANASKATHA MERCANTILE COOP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15340,
      "TXT_FINANCIER_NAME": "THE AIROORPPARA FARMERS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15341,
      "TXT_FINANCIER_NAME": "THE AMLEHAR CO OP AGRI SER SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 16977,
      "TXT_FINANCIER_NAME": "MANINAGAR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16978,
      "TXT_FINANCIER_NAME": "THE RAILWAY EMPLOYEES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16979,
      "TXT_FINANCIER_NAME": "Defence Accounts Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 16980,
      "TXT_FINANCIER_NAME": "THE SARVODAYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16981,
      "TXT_FINANCIER_NAME": "INTEGRATED FINANCE CO.LTD & UTI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16982,
      "TXT_FINANCIER_NAME": "AMBAJOGAI PEOPLE S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16983,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 16984,
      "TXT_FINANCIER_NAME": "THE MADHAVPURA MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16985,
      "TXT_FINANCIER_NAME": "Parmatma Ek Sewak Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16986,
      "TXT_FINANCIER_NAME": "Nagpur Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 16987,
      "TXT_FINANCIER_NAME": "AGRASEN URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16988,
      "TXT_FINANCIER_NAME": "DWARKADAS MANTRI NAGARI SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16989,
      "TXT_FINANCIER_NAME": "THE AURANGABAD DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16990,
      "TXT_FINANCIER_NAME": "MAHILA UTKARSH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16991,
      "TXT_FINANCIER_NAME": "MAHILA UTKARSH NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16992,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE COOPERATIVE APEX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16993,
      "TXT_FINANCIER_NAME": "THE JHAGADIA VIKAS BACHAT DHIRAN KARNARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 16994,
      "TXT_FINANCIER_NAME": "SRI BASAVESWAR URBAN CO-OP BANK LTD, BAGALKOT"
    },
    {
      "NUM_FINANCIER_CD": 16995,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN LOKSEVA SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 16996,
      "TXT_FINANCIER_NAME": "THE AURANGABAD DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16997,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR URBAN CO-OP BANK LTD., BAGALKOT"
    },
    {
      "NUM_FINANCIER_CD": 16998,
      "TXT_FINANCIER_NAME": "THE MANSA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16999,
      "TXT_FINANCIER_NAME": "BALOTRA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17000,
      "TXT_FINANCIER_NAME": "THE KOTTAYAM DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17001,
      "TXT_FINANCIER_NAME": "THE HYDERABAD DIST. CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17002,
      "TXT_FINANCIER_NAME": "SHISHAK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17003,
      "TXT_FINANCIER_NAME": "KAMAL AUTO FINANCE LTD & BOR LTD"
    },
    {
      "NUM_FINANCIER_CD": 17004,
      "TXT_FINANCIER_NAME": "THE PATIALA CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17005,
      "TXT_FINANCIER_NAME": "VYAPARI SAHAKARI BANK MARYADIT."
    },
    {
      "NUM_FINANCIER_CD": 17006,
      "TXT_FINANCIER_NAME": "NOIDA COMMERCIAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17007,
      "TXT_FINANCIER_NAME": "LOKSEVA SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17008,
      "TXT_FINANCIER_NAME": "SANGLI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17009,
      "TXT_FINANCIER_NAME": "THE MAHILA URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17010,
      "TXT_FINANCIER_NAME": "THE SALEM DISTRICT CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17011,
      "TXT_FINANCIER_NAME": "CITICORP FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17012,
      "TXT_FINANCIER_NAME": "THE SABARKANTHA DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17013,
      "TXT_FINANCIER_NAME": "SHRI SIDDHESHWAR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17014,
      "TXT_FINANCIER_NAME": "ING VYSA BANK"
    },
    {
      "NUM_FINANCIER_CD": 17015,
      "TXT_FINANCIER_NAME": "ING VYSA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17016,
      "TXT_FINANCIER_NAME": "CITI FINANCIAL CONSUMER FIN INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17017,
      "TXT_FINANCIER_NAME": "JIVAJI SAHAKARI BANK LTD ICHALKRANJI"
    },
    {
      "NUM_FINANCIER_CD": 17018,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK LTD SATARA"
    },
    {
      "NUM_FINANCIER_CD": 17019,
      "TXT_FINANCIER_NAME": "SUGAN FINSTOCK PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17020,
      "TXT_FINANCIER_NAME": "INDORE PARASPARA SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17021,
      "TXT_FINANCIER_NAME": "CHAUNDESHWARI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17022,
      "TXT_FINANCIER_NAME": "SHRIPATRAO DADA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17023,
      "TXT_FINANCIER_NAME": "KOLHAPUR URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17024,
      "TXT_FINANCIER_NAME": "GADHINGLAJ URBAN C0-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17025,
      "TXT_FINANCIER_NAME": "THE ICHALKARANJI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17026,
      "TXT_FINANCIER_NAME": "SHRI VEERSHAIV CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17027,
      "TXT_FINANCIER_NAME": "THE TAMILNADU INDUSTRIAL CO-OP. BK. LTD"
    },
    {
      "NUM_FINANCIER_CD": 17028,
      "TXT_FINANCIER_NAME": "BICHOLIM URBAN C0-0P. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17029,
      "TXT_FINANCIER_NAME": "GOAN PEOPLES URBAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17030,
      "TXT_FINANCIER_NAME": "MAPUSA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17031,
      "TXT_FINANCIER_NAME": "FINANCIAL CO-OPEQRTIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17032,
      "TXT_FINANCIER_NAME": "APNI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17033,
      "TXT_FINANCIER_NAME": "BAVLA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17034,
      "TXT_FINANCIER_NAME": "SRI SATYA SAI NAGRIK SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17035,
      "TXT_FINANCIER_NAME": "STERLING URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17036,
      "TXT_FINANCIER_NAME": "MADURAI URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17037,
      "TXT_FINANCIER_NAME": "SAMATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17038,
      "TXT_FINANCIER_NAME": "KUKARWADA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17039,
      "TXT_FINANCIER_NAME": "AKHANDA ANAND CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17040,
      "TXT_FINANCIER_NAME": "THE ANDHRA BANK EMPLOYEES CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17041,
      "TXT_FINANCIER_NAME": "RAJKOT MAHILA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17042,
      "TXT_FINANCIER_NAME": "Ambala District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17043,
      "TXT_FINANCIER_NAME": "Bhiwani District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17044,
      "TXT_FINANCIER_NAME": "Faridabad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17045,
      "TXT_FINANCIER_NAME": "Jind District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17046,
      "TXT_FINANCIER_NAME": "Kaithal District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17047,
      "TXT_FINANCIER_NAME": "Karnal District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17048,
      "TXT_FINANCIER_NAME": "Mahendragarh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17049,
      "TXT_FINANCIER_NAME": "Panipat District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17050,
      "TXT_FINANCIER_NAME": "Rewari District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17051,
      "TXT_FINANCIER_NAME": "Rohtak District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17052,
      "TXT_FINANCIER_NAME": "Sonepat District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17053,
      "TXT_FINANCIER_NAME": "Yamuna Nagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17054,
      "TXT_FINANCIER_NAME": "Jogindra District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17055,
      "TXT_FINANCIER_NAME": "Jammu and Kashmir State Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17056,
      "TXT_FINANCIER_NAME": "Anantnag District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17057,
      "TXT_FINANCIER_NAME": "Baramullah District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17058,
      "TXT_FINANCIER_NAME": "Amritsar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17059,
      "TXT_FINANCIER_NAME": "Fatehgarh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17060,
      "TXT_FINANCIER_NAME": "Fazilka District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17061,
      "TXT_FINANCIER_NAME": "Gurudaspur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17062,
      "TXT_FINANCIER_NAME": "Jalandar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17063,
      "TXT_FINANCIER_NAME": "Mansa District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17064,
      "TXT_FINANCIER_NAME": "Muktsar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17065,
      "TXT_FINANCIER_NAME": "Patiala District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17066,
      "TXT_FINANCIER_NAME": "Sangrur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17067,
      "TXT_FINANCIER_NAME": "Tarn Taran District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 12229,
      "TXT_FINANCIER_NAME": "SUVARNA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12324,
      "TXT_FINANCIER_NAME": "AMBIKA BIGAERSHETI GRAMIN PATH SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12325,
      "TXT_FINANCIER_NAME": "BHILA DAJI PAWAR GRAMIN BIGARSHETI SAHAKARI PATASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12326,
      "TXT_FINANCIER_NAME": "MATOSHREE MAHILA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12327,
      "TXT_FINANCIER_NAME": "SHREE BHIMASHAMKAR NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12328,
      "TXT_FINANCIER_NAME": "A P INDUSTRIAL INFRASTRUCTURE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 12329,
      "TXT_FINANCIER_NAME": "VIRJI CO FIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12330,
      "TXT_FINANCIER_NAME": "THE STATE CITIZEN SAKH SAHKATRITA MARYADIT DABRA"
    },
    {
      "NUM_FINANCIER_CD": 12331,
      "TXT_FINANCIER_NAME": "VIJAYRAJ NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12332,
      "TXT_FINANCIER_NAME": "GAMBID LEASING FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12333,
      "TXT_FINANCIER_NAME": "AHAMADNAGAR JILLA MARATHA SEVA NAGARI SAH PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 12334,
      "TXT_FINANCIER_NAME": "SHREE HADIYANA SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12335,
      "TXT_FINANCIER_NAME": "ONKAR AISHWARYA NAGARI SAH PATH SAN MAR"
    },
    {
      "NUM_FINANCIER_CD": 12336,
      "TXT_FINANCIER_NAME": "SRIKARPAGA VINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12337,
      "TXT_FINANCIER_NAME": "RHODIA SPECIALITY CHEMICALS INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12338,
      "TXT_FINANCIER_NAME": "RAVI AUTO"
    },
    {
      "NUM_FINANCIER_CD": 12339,
      "TXT_FINANCIER_NAME": "SRI KSHETHRA DHARMASTHALA RURAL DEVALOPMENT PROJECT"
    },
    {
      "NUM_FINANCIER_CD": 12340,
      "TXT_FINANCIER_NAME": "SRI VIGNESHWARA GRAMANTHARA PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12341,
      "TXT_FINANCIER_NAME": "GROZ BECKERT ASIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12342,
      "TXT_FINANCIER_NAME": "SAHNI MOTOR FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12343,
      "TXT_FINANCIER_NAME": "SHRAMIK NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12344,
      "TXT_FINANCIER_NAME": "POINGUINIM URBAN CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12345,
      "TXT_FINANCIER_NAME": "CORDOMAM MARCHANT CO OP"
    },
    {
      "NUM_FINANCIER_CD": 12346,
      "TXT_FINANCIER_NAME": "THE SATYAPRAKASH CO OPP CREDIT SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12347,
      "TXT_FINANCIER_NAME": "SHRI KOLBASWAMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12348,
      "TXT_FINANCIER_NAME": "RAVI FINANCE AND LEASING CO"
    },
    {
      "NUM_FINANCIER_CD": 12349,
      "TXT_FINANCIER_NAME": "SHIVA MULTIPURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12350,
      "TXT_FINANCIER_NAME": "KARIVALI NAGARI SAHAKARI PATH MARYD BHIWANDI"
    },
    {
      "NUM_FINANCIER_CD": 12351,
      "TXT_FINANCIER_NAME": "RADHAKRISHNA SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12352,
      "TXT_FINANCIER_NAME": "HAMSA CHAITNAYA SOUHARDA CREDIT COPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12353,
      "TXT_FINANCIER_NAME": "BAITUL NASAR CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12354,
      "TXT_FINANCIER_NAME": "GURU FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12355,
      "TXT_FINANCIER_NAME": "KAMDHENU CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12356,
      "TXT_FINANCIER_NAME": "THE SAKTHI PRIMARY AGRI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12357,
      "TXT_FINANCIER_NAME": "SRI PONNMANI FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 12358,
      "TXT_FINANCIER_NAME": "SHREE GOPAL KRISHNA PATSANSTHA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12359,
      "TXT_FINANCIER_NAME": "RAHATA MERCHANT NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12360,
      "TXT_FINANCIER_NAME": "SUREKAH RAJESH NAHAR AUTOMOBILE FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 12361,
      "TXT_FINANCIER_NAME": "SHRI JALARAM MERCANTILE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12362,
      "TXT_FINANCIER_NAME": "ARIHANT FANANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 12363,
      "TXT_FINANCIER_NAME": "QUANTUM"
    },
    {
      "NUM_FINANCIER_CD": 12364,
      "TXT_FINANCIER_NAME": "SHREE JETAKUBA JUTH SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12365,
      "TXT_FINANCIER_NAME": "THE MANAKADU SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12366,
      "TXT_FINANCIER_NAME": "SHRI VISHNU BAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12367,
      "TXT_FINANCIER_NAME": "SRI VENUGOPALAKRISHNA CREDIT CO OP SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12368,
      "TXT_FINANCIER_NAME": "MONICKA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12369,
      "TXT_FINANCIER_NAME": "BISHNUPUR ROWTARA PRIMARY AGRICULTURE CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12370,
      "TXT_FINANCIER_NAME": "MAHALAKSHMI SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12371,
      "TXT_FINANCIER_NAME": "VIKAS NAGARI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12372,
      "TXT_FINANCIER_NAME": "P K SUTARIA"
    },
    {
      "NUM_FINANCIER_CD": 12373,
      "TXT_FINANCIER_NAME": "SHIV PRERNA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12374,
      "TXT_FINANCIER_NAME": "TRIBAL CO OPERATIVE MARKETING DEVELOPMENT OF INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12375,
      "TXT_FINANCIER_NAME": "JAN SAMRIDDI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12376,
      "TXT_FINANCIER_NAME": "C L CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12377,
      "TXT_FINANCIER_NAME": "SHREE VIJAY SHANTI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12378,
      "TXT_FINANCIER_NAME": "UDAYA RAVI CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12379,
      "TXT_FINANCIER_NAME": "HARYANA SPORTS AND YOUTH WELFARE DEPTT"
    },
    {
      "NUM_FINANCIER_CD": 12380,
      "TXT_FINANCIER_NAME": "SRI SIVALAYAM CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 12381,
      "TXT_FINANCIER_NAME": "THE AIDED SCHOOL TEACHERS CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12382,
      "TXT_FINANCIER_NAME": "SHRI RIDDHI SIDDHI SAHAKARI PATHSANSTHA LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 12383,
      "TXT_FINANCIER_NAME": "MANJUSHREE MAHILA GARMIN PATH SANTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 12384,
      "TXT_FINANCIER_NAME": "SEVA AUTOMOTIVE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12385,
      "TXT_FINANCIER_NAME": "SHRI MOUNESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12386,
      "TXT_FINANCIER_NAME": "JOHN FOWLER INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12387,
      "TXT_FINANCIER_NAME": "VYAVASAYA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12388,
      "TXT_FINANCIER_NAME": "KAI SWA SE SHAMRAO PATIL NAGARI PAT SANSTHA UNDALE"
    },
    {
      "NUM_FINANCIER_CD": 12389,
      "TXT_FINANCIER_NAME": "SBIPBB DABRA CHOWK"
    },
    {
      "NUM_FINANCIER_CD": 12390,
      "TXT_FINANCIER_NAME": "THE BHATAGRAM URBAN CO OP CREDIT SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12391,
      "TXT_FINANCIER_NAME": "SAVITRI BAI FULE MAHILA GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12392,
      "TXT_FINANCIER_NAME": "NASIK JILHA PADHAVIDHAR NAGARI SAHKARI PATH SANSTHA MARYADIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12393,
      "TXT_FINANCIER_NAME": "NATIONAL DAIRY DEVELOPMENT BOARD"
    },
    {
      "NUM_FINANCIER_CD": 12394,
      "TXT_FINANCIER_NAME": "THE PRAGATI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12395,
      "TXT_FINANCIER_NAME": "SADGURU DNYNESHWAR MAVULI PAT SANT MAR"
    },
    {
      "NUM_FINANCIER_CD": 12396,
      "TXT_FINANCIER_NAME": "PATRAKAR NAGRIK GRIMIN BIGAR SHETI SAHAKARI PATHSANTH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12397,
      "TXT_FINANCIER_NAME": "TOWN VIVEDODDESHA SAHAKARI BHANDARA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12398,
      "TXT_FINANCIER_NAME": "SRI VEER TRANSPORT CO OP CREDIT SERVICE SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12399,
      "TXT_FINANCIER_NAME": "SREE SHANTHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12400,
      "TXT_FINANCIER_NAME": "SHREE SANALA SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12401,
      "TXT_FINANCIER_NAME": "SUCO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12402,
      "TXT_FINANCIER_NAME": "THE KOUJALAGI URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12403,
      "TXT_FINANCIER_NAME": "SHREE VIJAYA DURGA CO OP SERVICE SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12404,
      "TXT_FINANCIER_NAME": "THIRUNELLY SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12405,
      "TXT_FINANCIER_NAME": "THE CENTRAL SERVICES CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12406,
      "TXT_FINANCIER_NAME": "BAPUJIBUVA NAGARI SAHAKARI PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 12407,
      "TXT_FINANCIER_NAME": "SHREE SHANTI SURI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12408,
      "TXT_FINANCIER_NAME": "PARDI JUTH SEVA SAHKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 12409,
      "TXT_FINANCIER_NAME": "OZAR VIVIDH KARYAKARI SAHAKARI SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12410,
      "TXT_FINANCIER_NAME": "LOKNETE KANHAIYALALJI NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12411,
      "TXT_FINANCIER_NAME": "TAMEER CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12412,
      "TXT_FINANCIER_NAME": "BRAMHIN HITWARDHINI GRAMIN BIGAR SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12413,
      "TXT_FINANCIER_NAME": "SHREE VEERAMAHESHWAR PATTINA SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 12414,
      "TXT_FINANCIER_NAME": "NORTH KANARA GRAMIN SCHEDULE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13823,
      "TXT_FINANCIER_NAME": "SEEY KEY AUTO FIN"
    },
    {
      "NUM_FINANCIER_CD": 13824,
      "TXT_FINANCIER_NAME": "SHRI SIDDHIVINAYAK MULTISTATE CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13825,
      "TXT_FINANCIER_NAME": "THE DIRECTOR I S R O SATELLITE"
    },
    {
      "NUM_FINANCIER_CD": 13826,
      "TXT_FINANCIER_NAME": "SINDHANUR PATTINA SOUHARDA SAHAKARINIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13827,
      "TXT_FINANCIER_NAME": "SREE RANGANATHA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13828,
      "TXT_FINANCIER_NAME": "SHREE CHEERUMBA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13829,
      "TXT_FINANCIER_NAME": "THE GOVT EMPLOYEE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13830,
      "TXT_FINANCIER_NAME": "THE TOTAGARS RURAL CO OP AGRICULTURAL CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13831,
      "TXT_FINANCIER_NAME": "THE THALIPARAMBA CO OPERATIVE AGRI AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13832,
      "TXT_FINANCIER_NAME": "KURUHINSHETTI URBAN CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 13833,
      "TXT_FINANCIER_NAME": "KSHETRAPAL FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13834,
      "TXT_FINANCIER_NAME": "SINDHUDURG BANK"
    },
    {
      "NUM_FINANCIER_CD": 13835,
      "TXT_FINANCIER_NAME": "HEERA CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 13836,
      "TXT_FINANCIER_NAME": "SRI HEMADRI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13837,
      "TXT_FINANCIER_NAME": "S SHANTHI LAL AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13838,
      "TXT_FINANCIER_NAME": "UDAYGIRI MULTISTATE CO OP CREDIT SOCIETY LTD UDGIR"
    },
    {
      "NUM_FINANCIER_CD": 13839,
      "TXT_FINANCIER_NAME": "ANANT NAGARI GRAMIN BIGARSHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13840,
      "TXT_FINANCIER_NAME": "JAI BHAVANI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13841,
      "TXT_FINANCIER_NAME": "SARADA AUTOMOBILES"
    },
    {
      "NUM_FINANCIER_CD": 13842,
      "TXT_FINANCIER_NAME": "SHRI MANGALA JYOTHI VIVIDODDESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13843,
      "TXT_FINANCIER_NAME": "SHREE SATOBA MAHARAJ GRAMIN BIGARSHETI SAHAKARI PATSANSTHAMARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13844,
      "TXT_FINANCIER_NAME": "BIDHAN CHANDRA KRISHI VISWAVIDYALAYA EMPLOYEES CO OPERATIVE CREDIT AND CONSUMERS SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13845,
      "TXT_FINANCIER_NAME": "JINDESHA MADAR J M MINORITY CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13846,
      "TXT_FINANCIER_NAME": "VIHAN VIBHAG CREDIT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13847,
      "TXT_FINANCIER_NAME": "KALPAVRAKSH MULTIPURPOSE COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13848,
      "TXT_FINANCIER_NAME": "THE JAMKHNADI MINORITY URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13849,
      "TXT_FINANCIER_NAME": "SRI DATTA SAI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13850,
      "TXT_FINANCIER_NAME": "CHANDAN TRANSPORT FINANCE AND INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13851,
      "TXT_FINANCIER_NAME": "SHREE SANTHSENA NABHIK SAHAKARI PATHSANSNTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13852,
      "TXT_FINANCIER_NAME": "SHEELAPUR VIVIDH KARYAKARI SAHAKARI VIKAS SEVA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13853,
      "TXT_FINANCIER_NAME": "SHREE SANT DNYANESHWAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13854,
      "TXT_FINANCIER_NAME": "DR HEGDEWAR NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13855,
      "TXT_FINANCIER_NAME": "UTTARPARA KOTRUNG MUNICIPALITY"
    },
    {
      "NUM_FINANCIER_CD": 13856,
      "TXT_FINANCIER_NAME": "P CHANDRA INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 13857,
      "TXT_FINANCIER_NAME": "SHREE SHEKH PIPARIYA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13858,
      "TXT_FINANCIER_NAME": "SITHARAM APPARTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 13859,
      "TXT_FINANCIER_NAME": "GUJARAT THAKOR AND KOLI VIKAS NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 13860,
      "TXT_FINANCIER_NAME": "THE KARUNAGAPALLY CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13861,
      "TXT_FINANCIER_NAME": "NAYAK MARATHA SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13862,
      "TXT_FINANCIER_NAME": "GRAMVIKAS GR BIG SHETI SAH PAT"
    },
    {
      "NUM_FINANCIER_CD": 13863,
      "TXT_FINANCIER_NAME": "JAGAN NATH DIN DAYAL FIN CO"
    },
    {
      "NUM_FINANCIER_CD": 13864,
      "TXT_FINANCIER_NAME": "MANIADIH PRIMARY COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13865,
      "TXT_FINANCIER_NAME": "INDUSTRIAL TRANING DEPTT HARYANA CHD"
    },
    {
      "NUM_FINANCIER_CD": 13866,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13867,
      "TXT_FINANCIER_NAME": "SHRI SARAVANA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13868,
      "TXT_FINANCIER_NAME": "YELLAPUR ARBAN COOP SOCIERTY"
    },
    {
      "NUM_FINANCIER_CD": 13869,
      "TXT_FINANCIER_NAME": "ST MILAGRES CREDIT SOUHARDHA COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13870,
      "TXT_FINANCIER_NAME": "SRI SAI CHANNABASAVESHWAR SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 13871,
      "TXT_FINANCIER_NAME": "ALNAVAR CREDIT SOUHARDHA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13872,
      "TXT_FINANCIER_NAME": "GOVT OF JHARKHAND"
    },
    {
      "NUM_FINANCIER_CD": 13873,
      "TXT_FINANCIER_NAME": "CHORIWAD BACHAT SAHKARI MANDLI LIMITED AND S K BANK"
    },
    {
      "NUM_FINANCIER_CD": 13874,
      "TXT_FINANCIER_NAME": "THE ABDASAN GROUP SEVA SAHKARI MANDLI LIMITED AND SK BANK"
    },
    {
      "NUM_FINANCIER_CD": 13875,
      "TXT_FINANCIER_NAME": "JAGAMER KAMPA GROUP SEVA SAHKARI MANDLI LIMITED AND S K BANK"
    },
    {
      "NUM_FINANCIER_CD": 13876,
      "TXT_FINANCIER_NAME": "LALKRUSHNA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13877,
      "TXT_FINANCIER_NAME": "SAI SRINIVASA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13878,
      "TXT_FINANCIER_NAME": "SRI CHENDUR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13879,
      "TXT_FINANCIER_NAME": "CHIVAJIRAO BHOSALE SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13880,
      "TXT_FINANCIER_NAME": "NEELAKANTESHWARA SOUHARDA PATTINA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 13881,
      "TXT_FINANCIER_NAME": "SANGAM FINANCE AND TRADING CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13882,
      "TXT_FINANCIER_NAME": "SANGRUL SHIKSHAN SEVAKANCHI GURUVARYA D D ASGAONKAR SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13883,
      "TXT_FINANCIER_NAME": "NTP SHREENITHYA SOUHARDHA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13884,
      "TXT_FINANCIER_NAME": "JANSEVA CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13885,
      "TXT_FINANCIER_NAME": "INDIAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13886,
      "TXT_FINANCIER_NAME": "BANNUR RAITHARA SEVA SAHAKATI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13887,
      "TXT_FINANCIER_NAME": "WAINGANGA NAGARIK SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13888,
      "TXT_FINANCIER_NAME": "LINGASUGURU TALUKO RADDER PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 13889,
      "TXT_FINANCIER_NAME": "SHRI LINGAVADHOOT PATTINA SOUHARDA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13890,
      "TXT_FINANCIER_NAME": "MORNA GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13891,
      "TXT_FINANCIER_NAME": "NANDANAGAR SAKH SAHAKARITHA MYDT INDORE"
    },
    {
      "NUM_FINANCIER_CD": 13892,
      "TXT_FINANCIER_NAME": "TRIPATI URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 13893,
      "TXT_FINANCIER_NAME": "THE DESAIPURA GROUP SEVA SAH MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 13894,
      "TXT_FINANCIER_NAME": "VASHU DEV ASSOCIATE"
    },
    {
      "NUM_FINANCIER_CD": 13895,
      "TXT_FINANCIER_NAME": "PUJAN INVESTMENT AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13896,
      "TXT_FINANCIER_NAME": "THE LDH CENTRALCO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13897,
      "TXT_FINANCIER_NAME": "ISHWAR CO OP CREDIT SOCIETYLTD"
    },
    {
      "NUM_FINANCIER_CD": 13898,
      "TXT_FINANCIER_NAME": "MAG FINSERVE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13899,
      "TXT_FINANCIER_NAME": "SRI JAYASAKTHI FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 13900,
      "TXT_FINANCIER_NAME": "DIFFUSION ENGINNERS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13901,
      "TXT_FINANCIER_NAME": "DYANDEEP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13902,
      "TXT_FINANCIER_NAME": "S D FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 13903,
      "TXT_FINANCIER_NAME": "KARNATAKA MILK FRDERATION"
    },
    {
      "NUM_FINANCIER_CD": 13904,
      "TXT_FINANCIER_NAME": "NETAJI MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13905,
      "TXT_FINANCIER_NAME": "HICHKAD SEVA SAHAKARI SANG NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 13906,
      "TXT_FINANCIER_NAME": "SHREE VIJAYLAXIMI SOUHARDA COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 13907,
      "TXT_FINANCIER_NAME": "S MALA DHARBARIA"
    },
    {
      "NUM_FINANCIER_CD": 13908,
      "TXT_FINANCIER_NAME": "DOODHGANGA NAGRI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13909,
      "TXT_FINANCIER_NAME": "SRI DR PUTTARAJ SOUHARDHA PATTIN SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 13910,
      "TXT_FINANCIER_NAME": "BELANI COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 13911,
      "TXT_FINANCIER_NAME": "KOME KORAWADY VSSN KUMBHASHI"
    },
    {
      "NUM_FINANCIER_CD": 13912,
      "TXT_FINANCIER_NAME": "SHREE VEER MULTIPURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13913,
      "TXT_FINANCIER_NAME": "PENDRAWAL GROUP"
    },
    {
      "NUM_FINANCIER_CD": 13914,
      "TXT_FINANCIER_NAME": "SHREE RAVALANATH KALMESHWAR MULTIPURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15342,
      "TXT_FINANCIER_NAME": "THE ANANTNAG CENTRAL COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15343,
      "TXT_FINANCIER_NAME": "THE ANICKADU REGIONAL FARMERS SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15344,
      "TXT_FINANCIER_NAME": "THE B D C C BANK"
    },
    {
      "NUM_FINANCIER_CD": 15345,
      "TXT_FINANCIER_NAME": "THE BADAGABETTU CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15346,
      "TXT_FINANCIER_NAME": "THE BADOLI VIBHAG NAGRIK SAHKARI SHARAFI MANDALI LTD."
    },
    {
      "NUM_FINANCIER_CD": 15347,
      "TXT_FINANCIER_NAME": "THE BDCC BANK"
    },
    {
      "NUM_FINANCIER_CD": 15348,
      "TXT_FINANCIER_NAME": "THE BELGAUM ZILLA RANI CHANNAMMA MAHILA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15349,
      "TXT_FINANCIER_NAME": "THE BHAGAT URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15350,
      "TXT_FINANCIER_NAME": "THE BHAGAT URBAN COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15351,
      "TXT_FINANCIER_NAME": "THE C D COOP CREDIT SOCIETY LTD C O NERCHOWK"
    },
    {
      "NUM_FINANCIER_CD": 15352,
      "TXT_FINANCIER_NAME": "THE CHARTED SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 15353,
      "TXT_FINANCIER_NAME": "THE DADHOL AS COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15354,
      "TXT_FINANCIER_NAME": "THE DEOLA MERCHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15355,
      "TXT_FINANCIER_NAME": "THE DEOLALI VYAPARI SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15356,
      "TXT_FINANCIER_NAME": "THE DHARAMSAL MAHANTAN CO OP AGRI SOCIETY DHARAMSAL"
    },
    {
      "NUM_FINANCIER_CD": 15357,
      "TXT_FINANCIER_NAME": "THE DHARAMSALA MAHANTAN CO OP AGRI SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15358,
      "TXT_FINANCIER_NAME": "THE EKTA COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15359,
      "TXT_FINANCIER_NAME": "THE ERANAKULAM DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15360,
      "TXT_FINANCIER_NAME": "THE GOPALGANJ CENTRAL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15361,
      "TXT_FINANCIER_NAME": "THE GUJRAT STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15362,
      "TXT_FINANCIER_NAME": "THE GUJRAT STATE CO OP HOUSING FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 15363,
      "TXT_FINANCIER_NAME": "THE HABROL CO OP AGRI SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15364,
      "TXT_FINANCIER_NAME": "THE HAMBOLI COOP SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15365,
      "TXT_FINANCIER_NAME": "THE HARIJ NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15366,
      "TXT_FINANCIER_NAME": "THE HRP URBAN COOP CR AND CONSUR"
    },
    {
      "NUM_FINANCIER_CD": 15367,
      "TXT_FINANCIER_NAME": "THE HS STAFF CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15368,
      "TXT_FINANCIER_NAME": "THE IDEAR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15369,
      "TXT_FINANCIER_NAME": "THE IDER NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15370,
      "TXT_FINANCIER_NAME": "THE JAYAMBE NAGRIK SHARAFI SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 15371,
      "TXT_FINANCIER_NAME": "THE KAMUTHI COOPERATIVE URBAN BANK LTD KAMUTHI BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15372,
      "TXT_FINANCIER_NAME": "THE KANJIRAPPALLY CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15373,
      "TXT_FINANCIER_NAME": "THE KARIMIA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15374,
      "TXT_FINANCIER_NAME": "THE KARUMALLOOR SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15375,
      "TXT_FINANCIER_NAME": "THE KARUMALLOOR SERVICE COOPERATIVE BANK LTD.NO.737"
    },
    {
      "NUM_FINANCIER_CD": 15376,
      "TXT_FINANCIER_NAME": "THE KATOHAR KHURD AGGRI CO OPP SOCIETY KATOHAR KHURD"
    },
    {
      "NUM_FINANCIER_CD": 15377,
      "TXT_FINANCIER_NAME": "THE KENDRAPADA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15378,
      "TXT_FINANCIER_NAME": "THE KHHATRI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15379,
      "TXT_FINANCIER_NAME": "THE KILIMANOOR CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 15380,
      "TXT_FINANCIER_NAME": "THE KODUR CO OPERATIVE RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 15381,
      "TXT_FINANCIER_NAME": "THE KONARK URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15382,
      "TXT_FINANCIER_NAME": "THE KSC APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15383,
      "TXT_FINANCIER_NAME": "THE KSC APEX CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15384,
      "TXT_FINANCIER_NAME": "THE KSFE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15385,
      "TXT_FINANCIER_NAME": "THE MACCHIMAR COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15386,
      "TXT_FINANCIER_NAME": "THE MAHIDHARPURA URBAN COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 15387,
      "TXT_FINANCIER_NAME": "THE MALAPURAM DISTRICT CO OPERATIVE MOTOR VEHICLES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15388,
      "TXT_FINANCIER_NAME": "THE MAN MANDIR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15389,
      "TXT_FINANCIER_NAME": "THE MARANGATTUPILLY SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15390,
      "TXT_FINANCIER_NAME": "THE MAYYANAD SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15391,
      "TXT_FINANCIER_NAME": "THE MUBARAK COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15392,
      "TXT_FINANCIER_NAME": "THE MUBARIKPUR AGRICULTURE COOP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15393,
      "TXT_FINANCIER_NAME": "THE NAGAUR CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15394,
      "TXT_FINANCIER_NAME": "THE NAGROTA BAGWAN CO OPERATIVE NON AGRICULTURE TH AND CR SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15395,
      "TXT_FINANCIER_NAME": "THE NAKODAR HINDU URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15396,
      "TXT_FINANCIER_NAME": "THE NAV NIRMAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15397,
      "TXT_FINANCIER_NAME": "THE NIPHAD ARBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15398,
      "TXT_FINANCIER_NAME": "THE PARAGPUR COOP AGRI MP SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15399,
      "TXT_FINANCIER_NAME": "THE PARSHWANATH COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15400,
      "TXT_FINANCIER_NAME": "THE PARWOON URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15401,
      "TXT_FINANCIER_NAME": "THE PATLIPUTRA CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15402,
      "TXT_FINANCIER_NAME": "THE PIONEER URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15403,
      "TXT_FINANCIER_NAME": "THE PRINTER HOUSE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15404,
      "TXT_FINANCIER_NAME": "THE RANCHIKHUNTI CENTRAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15405,
      "TXT_FINANCIER_NAME": "THE RATANAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15406,
      "TXT_FINANCIER_NAME": "THE RELIANCE CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 15407,
      "TXT_FINANCIER_NAME": "THE SANKARI CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15408,
      "TXT_FINANCIER_NAME": "THE SENIOR DIVISIONAL MANAGER LIC OF INDIA JEVAN PRAKASH PATTOM"
    },
    {
      "NUM_FINANCIER_CD": 15409,
      "TXT_FINANCIER_NAME": "THE SHIPPING CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15410,
      "TXT_FINANCIER_NAME": "THE SHREENATH COOPERATIVE SOCIETY MODASA"
    },
    {
      "NUM_FINANCIER_CD": 15411,
      "TXT_FINANCIER_NAME": "THE SILK CITY URBAN COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15412,
      "TXT_FINANCIER_NAME": "THE SIRAGALAPALLI PACCS LTD 437F"
    },
    {
      "NUM_FINANCIER_CD": 15413,
      "TXT_FINANCIER_NAME": "THE TABBA CO OP AGRI SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15414,
      "TXT_FINANCIER_NAME": "THE THIRUVILLA EAST CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15415,
      "TXT_FINANCIER_NAME": "THE UNJHA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15416,
      "TXT_FINANCIER_NAME": "THE VENKATASWAMY NAIDU PACC SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15417,
      "TXT_FINANCIER_NAME": "THE VIRANGAM MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15418,
      "TXT_FINANCIER_NAME": "THIRUKOCHI MOTOR VEHICLE OWNERS CREDIT CO OP SOCIE"
    },
    {
      "NUM_FINANCIER_CD": 15419,
      "TXT_FINANCIER_NAME": "THIRUKOCHI MOTOR VEHICLE OWNERS CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15420,
      "TXT_FINANCIER_NAME": "THORLEBAJIRAO PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15421,
      "TXT_FINANCIER_NAME": "THOTTAPPALLY"
    },
    {
      "NUM_FINANCIER_CD": 15422,
      "TXT_FINANCIER_NAME": "THURAVOOR BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15423,
      "TXT_FINANCIER_NAME": "TIRURANGADI TALUK AUTO AND TAXI DRIVERS COOP SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15424,
      "TXT_FINANCIER_NAME": "TORRENT PHARMACUTICALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15425,
      "TXT_FINANCIER_NAME": "TRPTHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 15426,
      "TXT_FINANCIER_NAME": "TUMKUR MAHALAKSHMI CREDIT SOUHARA CO-OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15427,
      "TXT_FINANCIER_NAME": "U FLEX LTD"
    },
    {
      "NUM_FINANCIER_CD": 15428,
      "TXT_FINANCIER_NAME": "U S N DISTRICT COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15429,
      "TXT_FINANCIER_NAME": "U.HASTHIMAL"
    },
    {
      "NUM_FINANCIER_CD": 15430,
      "TXT_FINANCIER_NAME": "UBKG BANK"
    },
    {
      "NUM_FINANCIER_CD": 15431,
      "TXT_FINANCIER_NAME": "UBKGB"
    },
    {
      "NUM_FINANCIER_CD": 15432,
      "TXT_FINANCIER_NAME": "UDALPUR"
    },
    {
      "NUM_FINANCIER_CD": 15433,
      "TXT_FINANCIER_NAME": "UDIT INV. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15434,
      "TXT_FINANCIER_NAME": "UDYOGNAGAR COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15435,
      "TXT_FINANCIER_NAME": "UGVCL"
    },
    {
      "NUM_FINANCIER_CD": 15436,
      "TXT_FINANCIER_NAME": "UK CO OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15437,
      "TXT_FINANCIER_NAME": "UNITRON FINLEAS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15438,
      "TXT_FINANCIER_NAME": "UTTAMRAO DHIKLE G B S PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15439,
      "TXT_FINANCIER_NAME": "V G LEASING FINANACE"
    },
    {
      "NUM_FINANCIER_CD": 15440,
      "TXT_FINANCIER_NAME": "VARDHAMAN M CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15441,
      "TXT_FINANCIER_NAME": "VARDVINAYAK COOP CREDIT SOC LTD."
    },
    {
      "NUM_FINANCIER_CD": 15442,
      "TXT_FINANCIER_NAME": "VENKATRAMANA SEVA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15443,
      "TXT_FINANCIER_NAME": "VENU TAI CHAWHAN SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17068,
      "TXT_FINANCIER_NAME": "Ajmer District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17069,
      "TXT_FINANCIER_NAME": "Alwar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17070,
      "TXT_FINANCIER_NAME": "Banswara District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17071,
      "TXT_FINANCIER_NAME": "Barmer District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17072,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWARA PATTINA SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 17073,
      "TXT_FINANCIER_NAME": "GUJARAT GAS CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 17074,
      "TXT_FINANCIER_NAME": "THE UDAIPUR URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17075,
      "TXT_FINANCIER_NAME": "BICHOLIM URBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17076,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17077,
      "TXT_FINANCIER_NAME": "STANDARD CHARTERED GRINDLAYS"
    },
    {
      "NUM_FINANCIER_CD": 17078,
      "TXT_FINANCIER_NAME": "ABN AMRO BANK N.V."
    },
    {
      "NUM_FINANCIER_CD": 17079,
      "TXT_FINANCIER_NAME": "BOMBAY MERCHANTILE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17080,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17081,
      "TXT_FINANCIER_NAME": "THE URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17082,
      "TXT_FINANCIER_NAME": "UTTAR BANGA KHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17083,
      "TXT_FINANCIER_NAME": "NHPC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17084,
      "TXT_FINANCIER_NAME": "GAS AUTHORITY OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17085,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17086,
      "TXT_FINANCIER_NAME": "NUTAN NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17087,
      "TXT_FINANCIER_NAME": "BHAGYODAYA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17088,
      "TXT_FINANCIER_NAME": "INDIAN OIL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 17089,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLIUM CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 17090,
      "TXT_FINANCIER_NAME": "INDIAN OIL CORP. LTD."
    },
    {
      "NUM_FINANCIER_CD": 17091,
      "TXT_FINANCIER_NAME": "PRESIDENT OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 17092,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. LTD."
    },
    {
      "NUM_FINANCIER_CD": 17093,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 17094,
      "TXT_FINANCIER_NAME": "GLOBAL TRUST BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17095,
      "TXT_FINANCIER_NAME": "Punjab Kashmir Finance Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17096,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17097,
      "TXT_FINANCIER_NAME": "GOLDEN FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 17098,
      "TXT_FINANCIER_NAME": "SARASWAT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17099,
      "TXT_FINANCIER_NAME": "THE UNITED WESTERN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17100,
      "TXT_FINANCIER_NAME": "RAOSAHEB DESHMUKH NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17101,
      "TXT_FINANCIER_NAME": "BHAVNAGAR DISTRICT CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17102,
      "TXT_FINANCIER_NAME": "THE CHIKHLI URBAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17103,
      "TXT_FINANCIER_NAME": "THE VITA MERCHANTS CO-OP BANK LTD VITA"
    },
    {
      "NUM_FINANCIER_CD": 17104,
      "TXT_FINANCIER_NAME": "BHARATI SAHAKARI BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 17105,
      "TXT_FINANCIER_NAME": "SIHOR NAGARIK SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17106,
      "TXT_FINANCIER_NAME": "ICHALKARANJI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17107,
      "TXT_FINANCIER_NAME": "THE MUNICIPAL CO OP BANK LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 17108,
      "TXT_FINANCIER_NAME": "MAHAMEDHA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17109,
      "TXT_FINANCIER_NAME": "THE HIMACHAL PRADESH STATE CO-OPRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17110,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGARIK CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17111,
      "TXT_FINANCIER_NAME": "GUJARAT AMBUJA CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17112,
      "TXT_FINANCIER_NAME": "SHREE BOTAD MERCANTILE CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17113,
      "TXT_FINANCIER_NAME": "BOMBAY MERCHANTILE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17114,
      "TXT_FINANCIER_NAME": "THE CHITNAVISPURA SAHA.BANK"
    },
    {
      "NUM_FINANCIER_CD": 17115,
      "TXT_FINANCIER_NAME": "MAHA MEDHA URBAN CO- OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17116,
      "TXT_FINANCIER_NAME": "VERAVAL MERCANTILE COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17117,
      "TXT_FINANCIER_NAME": "ICICI BANKING CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 17118,
      "TXT_FINANCIER_NAME": "NAVJEEVAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17119,
      "TXT_FINANCIER_NAME": "SARVODAYA COMMERCIAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17120,
      "TXT_FINANCIER_NAME": "SHRI PANCHGANGA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17121,
      "TXT_FINANCIER_NAME": "MAHILA UTKARSH NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17122,
      "TXT_FINANCIER_NAME": "BULDANA URBAN CO-OP CREDIT SOCY.LTD."
    },
    {
      "NUM_FINANCIER_CD": 17123,
      "TXT_FINANCIER_NAME": "THE JODHPUR CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17124,
      "TXT_FINANCIER_NAME": "DCB BANK"
    },
    {
      "NUM_FINANCIER_CD": 17125,
      "TXT_FINANCIER_NAME": "THE KOLHAPUR MARATHA CO-OP BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17126,
      "TXT_FINANCIER_NAME": "JAYSINGPUR-UDGAON CO-OP BANK LTD,JAYSINGPUR"
    },
    {
      "NUM_FINANCIER_CD": 17127,
      "TXT_FINANCIER_NAME": "idpi bank"
    },
    {
      "NUM_FINANCIER_CD": 17128,
      "TXT_FINANCIER_NAME": "THE RATNAKAR BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17129,
      "TXT_FINANCIER_NAME": "THE SATARA DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17130,
      "TXT_FINANCIER_NAME": "THE MEHSANA MAHILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17131,
      "TXT_FINANCIER_NAME": "MARATHA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17132,
      "TXT_FINANCIER_NAME": "THE ARYA VAISHYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17133,
      "TXT_FINANCIER_NAME": "THE KHAIRA MAJJA CO OP AGRI MULTI PURPOSE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17134,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17135,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRAIL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17136,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17137,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRIAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17138,
      "TXT_FINANCIER_NAME": "THE YAVATMAL URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17139,
      "TXT_FINANCIER_NAME": "THE MADHAVPURA MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17140,
      "TXT_FINANCIER_NAME": "ADARSH MAHILA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17141,
      "TXT_FINANCIER_NAME": "SOLAPUR JANATA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17142,
      "TXT_FINANCIER_NAME": "DISTRICT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17143,
      "TXT_FINANCIER_NAME": "SRI BASAVESHWAR SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 17144,
      "TXT_FINANCIER_NAME": "PATAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17145,
      "TXT_FINANCIER_NAME": "PATAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17146,
      "TXT_FINANCIER_NAME": "THE KARNAL CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17147,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OP.BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17148,
      "TXT_FINANCIER_NAME": "ORIX AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17149,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17150,
      "TXT_FINANCIER_NAME": "GUJARAT AMBUJA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17151,
      "TXT_FINANCIER_NAME": "ASHOK LEYLAND FINANCE - DIVISON OF INDUSIND BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17152,
      "TXT_FINANCIER_NAME": "KOTTAYAM CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17153,
      "TXT_FINANCIER_NAME": "THE BAGALKOT URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17154,
      "TXT_FINANCIER_NAME": "Dr. Babasaheb Ambedkar Urban co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17155,
      "TXT_FINANCIER_NAME": "THE MUMBAI DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17156,
      "TXT_FINANCIER_NAME": "IDUKKI DISTRICT COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17157,
      "TXT_FINANCIER_NAME": "SARVODAYA COMMERCIAL CO-OP. BANK LTD. MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 17158,
      "TXT_FINANCIER_NAME": "ABHYUDAYA CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17159,
      "TXT_FINANCIER_NAME": "INDIAN OIL CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 17160,
      "TXT_FINANCIER_NAME": "THE ICHALKARANJI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17161,
      "TXT_FINANCIER_NAME": "THE PERLA SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17162,
      "TXT_FINANCIER_NAME": "Bhilwara District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17163,
      "TXT_FINANCIER_NAME": "Bundi District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 12415,
      "TXT_FINANCIER_NAME": "RAJMATA JIJAI NAGARI SAH PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12416,
      "TXT_FINANCIER_NAME": "STONE SHIPPERS KOTA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12417,
      "TXT_FINANCIER_NAME": "THE SADALGA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12418,
      "TXT_FINANCIER_NAME": "RELINCE CONSUMER FINANACE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12419,
      "TXT_FINANCIER_NAME": "GRACE HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 12420,
      "TXT_FINANCIER_NAME": "THE GUJRATHI URBAN CO OP CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12421,
      "TXT_FINANCIER_NAME": "LUXMI NARAYAN FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 12422,
      "TXT_FINANCIER_NAME": "SRI AGNI BANNIRAYA SWAMY PATHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12423,
      "TXT_FINANCIER_NAME": "MAHAVEERAMINORITYCREDITCOOPERATIVESOCIETYLTD"
    },
    {
      "NUM_FINANCIER_CD": 12424,
      "TXT_FINANCIER_NAME": "BRAHMAN HITWARDHINI GRAMIN BIGAR SHETI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12425,
      "TXT_FINANCIER_NAME": "VIVEKANAND NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12426,
      "TXT_FINANCIER_NAME": "GREEN MERCHANT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12427,
      "TXT_FINANCIER_NAME": "DHABANDODA V K S S SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12428,
      "TXT_FINANCIER_NAME": "KESHAVASMRUTI NAGARI SAHAKARI PATHPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12429,
      "TXT_FINANCIER_NAME": "INDIRA GANDHI NATIONAL CENTRE FOR THE ARTS"
    },
    {
      "NUM_FINANCIER_CD": 12430,
      "TXT_FINANCIER_NAME": "ACGL BBD EMPLOYEE COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12431,
      "TXT_FINANCIER_NAME": "RAJPAL AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12432,
      "TXT_FINANCIER_NAME": "PARASHWANATH FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12433,
      "TXT_FINANCIER_NAME": "NAVLAI GRAMIN NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12434,
      "TXT_FINANCIER_NAME": "DNYAMURTI B J KOLASE PATIL GRAMIN BIGAR SHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12435,
      "TXT_FINANCIER_NAME": "RAJENDRA SURI SAHKARI SAKH SANSTHA MYDT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12436,
      "TXT_FINANCIER_NAME": "SHREE GANESH TITWALA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12437,
      "TXT_FINANCIER_NAME": "SRI BANASHANKARI PATHINA SHAKARI SANGA"
    },
    {
      "NUM_FINANCIER_CD": 12438,
      "TXT_FINANCIER_NAME": "VANGALAM ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 12439,
      "TXT_FINANCIER_NAME": "EMBEE MONEY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12440,
      "TXT_FINANCIER_NAME": "SHRI TUMSARSHWAR MAHAGANPATI SAHAKARI SANTHA MARYADIT TUMSAR"
    },
    {
      "NUM_FINANCIER_CD": 12441,
      "TXT_FINANCIER_NAME": "THE SHERPUR KHURD C A M S LTD"
    },
    {
      "NUM_FINANCIER_CD": 12442,
      "TXT_FINANCIER_NAME": "MAHESHWARI NAGARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12443,
      "TXT_FINANCIER_NAME": "RISO TRAVELS"
    },
    {
      "NUM_FINANCIER_CD": 12444,
      "TXT_FINANCIER_NAME": "DHANVARDHINI GRAMIN BIGARSHTI SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12445,
      "TXT_FINANCIER_NAME": "KV FIN PVT"
    },
    {
      "NUM_FINANCIER_CD": 12446,
      "TXT_FINANCIER_NAME": "SWAN SILK P LTD"
    },
    {
      "NUM_FINANCIER_CD": 12447,
      "TXT_FINANCIER_NAME": "HIRALAL KAKA NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12448,
      "TXT_FINANCIER_NAME": "NAVJIVAN SHARAFI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 12449,
      "TXT_FINANCIER_NAME": "KHANDESH VIDHARBH NAGRI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12450,
      "TXT_FINANCIER_NAME": "THE CITIZENS CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12451,
      "TXT_FINANCIER_NAME": "ASPINWALL AND CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12452,
      "TXT_FINANCIER_NAME": "SRI BHARANI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12453,
      "TXT_FINANCIER_NAME": "RAVIVAR KARANJA SIDHHIVINAYAK NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12454,
      "TXT_FINANCIER_NAME": "SRI KANYAKA PARAMESHWARI VIVIDODDESHA SAHAKARA SANGHA NIAYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12455,
      "TXT_FINANCIER_NAME": "SHREYAS NAGARI SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12456,
      "TXT_FINANCIER_NAME": "ANTAR VYAVSAI SAHKARI VIKAS SAMITI"
    },
    {
      "NUM_FINANCIER_CD": 12457,
      "TXT_FINANCIER_NAME": "ESS AAY FINANCE CORPN REGD"
    },
    {
      "NUM_FINANCIER_CD": 12458,
      "TXT_FINANCIER_NAME": "MUKESH ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 12459,
      "TXT_FINANCIER_NAME": "RAJESH MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12460,
      "TXT_FINANCIER_NAME": "KRISHNA TRADING"
    },
    {
      "NUM_FINANCIER_CD": 12461,
      "TXT_FINANCIER_NAME": "JAI MARUTHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12462,
      "TXT_FINANCIER_NAME": "MARATHA COOPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12463,
      "TXT_FINANCIER_NAME": "FUCHS LUBRICANTS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12464,
      "TXT_FINANCIER_NAME": "ABUDHABI COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 12465,
      "TXT_FINANCIER_NAME": "MICRO LABS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12466,
      "TXT_FINANCIER_NAME": "ERUMAD PRIMARY AGRI CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12467,
      "TXT_FINANCIER_NAME": "LOKAMANYA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12468,
      "TXT_FINANCIER_NAME": "GUBBI TOWN FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12469,
      "TXT_FINANCIER_NAME": "PARAS JAIN GRAMIN BIGAR SHETI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12470,
      "TXT_FINANCIER_NAME": "DADA DEV FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12471,
      "TXT_FINANCIER_NAME": "THE TERDAL URBAN SOUHARD CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 12472,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI GRAH SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12473,
      "TXT_FINANCIER_NAME": "THE THASRA VEPARI MANDALCO OP CRE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12474,
      "TXT_FINANCIER_NAME": "SHRI GURU MAHILA URBAN SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 12475,
      "TXT_FINANCIER_NAME": "KODOLI VYAPARI GRAMIN SAHAKARI PAT SANSTHA MYDH"
    },
    {
      "NUM_FINANCIER_CD": 12476,
      "TXT_FINANCIER_NAME": "SRI RAMANJANEYA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12477,
      "TXT_FINANCIER_NAME": "KAAK FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12478,
      "TXT_FINANCIER_NAME": "ALL INDIA INSTITUTE OF SPEACH AND HEARING"
    },
    {
      "NUM_FINANCIER_CD": 12479,
      "TXT_FINANCIER_NAME": "SHIRAVAL VIKAS NIM NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12480,
      "TXT_FINANCIER_NAME": "SHREE VASATRAO CHOUGULE NAGARI SAH PAT SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 12481,
      "TXT_FINANCIER_NAME": "INFORMATION AND INVESTMENT CREDIT RATING ACCENCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12482,
      "TXT_FINANCIER_NAME": "MHAISHAL VYAPARI AND VYAVSAIK GRAMNI BIGER SHETI CO PATH SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 12483,
      "TXT_FINANCIER_NAME": "THE ANGADI NAGARIK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12484,
      "TXT_FINANCIER_NAME": "A KRISHNARAO BHEGADE NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12485,
      "TXT_FINANCIER_NAME": "VASANTHAMALLI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12486,
      "TXT_FINANCIER_NAME": "SAHAY SALES AND MARKETING LTD"
    },
    {
      "NUM_FINANCIER_CD": 12487,
      "TXT_FINANCIER_NAME": "CHAMPALAL PS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12488,
      "TXT_FINANCIER_NAME": "SHRAMIK EKTA NAG SAH PAT SANTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 12489,
      "TXT_FINANCIER_NAME": "HINDVIJAY NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12490,
      "TXT_FINANCIER_NAME": "SRI VASAVI PATTINA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 12491,
      "TXT_FINANCIER_NAME": "TARANG FINANCIERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 12492,
      "TXT_FINANCIER_NAME": "LAXMI BACHAT SARAFI SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12493,
      "TXT_FINANCIER_NAME": "THE SHRAMJIVI VIVIDHALAKSHI DHIRAN AND G S MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12494,
      "TXT_FINANCIER_NAME": "THE BHANDARA MAHILA NAGRIKSAHKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12495,
      "TXT_FINANCIER_NAME": "BRIGHT LEASING AND HIRE PURCHASE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12496,
      "TXT_FINANCIER_NAME": "VEE ESS CAPFIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12497,
      "TXT_FINANCIER_NAME": "SUNIL FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12498,
      "TXT_FINANCIER_NAME": "MANDARTHY SERVICE CO OP BANK LTD MANDARTHY"
    },
    {
      "NUM_FINANCIER_CD": 12499,
      "TXT_FINANCIER_NAME": "SOUTH EAST CENTRAL RAILWAY"
    },
    {
      "NUM_FINANCIER_CD": 12500,
      "TXT_FINANCIER_NAME": "SARODAYA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12501,
      "TXT_FINANCIER_NAME": "JAI BHAVANI NAGRI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12502,
      "TXT_FINANCIER_NAME": "KOVAI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12503,
      "TXT_FINANCIER_NAME": "NITESH FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12504,
      "TXT_FINANCIER_NAME": "TUFANGANJ HEALTH EMPLOYEES CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12505,
      "TXT_FINANCIER_NAME": "SNEHASAGAR SOUHARDHA CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12506,
      "TXT_FINANCIER_NAME": "SHARADA NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12507,
      "TXT_FINANCIER_NAME": "PATHARDI VIKAS SEVA SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12508,
      "TXT_FINANCIER_NAME": "THE BELGAUM MERCHANTS COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13915,
      "TXT_FINANCIER_NAME": "THE KADANNAPPALLI PANAPPUZHASERVICE CO OP BANK LTD VILAYANCODE"
    },
    {
      "NUM_FINANCIER_CD": 13916,
      "TXT_FINANCIER_NAME": "UPPINAPATTANA GRAMEENA SEVA SAHAKARI SANGHA NIYAMT KATGAL"
    },
    {
      "NUM_FINANCIER_CD": 13917,
      "TXT_FINANCIER_NAME": "THE GOA KAPILA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13918,
      "TXT_FINANCIER_NAME": "THE GOA KAPILA MCOSL"
    },
    {
      "NUM_FINANCIER_CD": 13919,
      "TXT_FINANCIER_NAME": "NOVARTIS FISCAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 13920,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER GEN G S E C L TPS UKAI"
    },
    {
      "NUM_FINANCIER_CD": 13921,
      "TXT_FINANCIER_NAME": "SATYANARAYAN MANGILAL MAHESHWARI"
    },
    {
      "NUM_FINANCIER_CD": 13922,
      "TXT_FINANCIER_NAME": "P L D BANK"
    },
    {
      "NUM_FINANCIER_CD": 13923,
      "TXT_FINANCIER_NAME": "SOUTHERN POWER DISTRIBUTION CO OPERATIVE TELANGANA LTD"
    },
    {
      "NUM_FINANCIER_CD": 13924,
      "TXT_FINANCIER_NAME": "LESSOR TATA CAPITAL FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 13925,
      "TXT_FINANCIER_NAME": "KARNATAKA TRADE PROMOTION ORGANISATION"
    },
    {
      "NUM_FINANCIER_CD": 13926,
      "TXT_FINANCIER_NAME": "NEW CHINNA MALAI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13927,
      "TXT_FINANCIER_NAME": "SHREE VINAYAK MULTI PURPOSE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 13928,
      "TXT_FINANCIER_NAME": "THE BAGAWAN MINORITY CO OPP CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 13929,
      "TXT_FINANCIER_NAME": "CHINTAMANRAO MORMARE GRAMIN BIG SAH PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 13930,
      "TXT_FINANCIER_NAME": "SHREE SHAKTHI AUTO FIN"
    },
    {
      "NUM_FINANCIER_CD": 13931,
      "TXT_FINANCIER_NAME": "CHAMPAVATI GRAMIN BIGERSETI SAHKARI PATHSANSTHA MRYDT"
    },
    {
      "NUM_FINANCIER_CD": 13932,
      "TXT_FINANCIER_NAME": "SHRI NARSOBA GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13933,
      "TXT_FINANCIER_NAME": "MITRA FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 13934,
      "TXT_FINANCIER_NAME": "KARNATAKA JUDICIAL OFFICERS CO OPERATIVE SOCITY"
    },
    {
      "NUM_FINANCIER_CD": 13935,
      "TXT_FINANCIER_NAME": "SHRILAL BAHADUR SHASTRI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13936,
      "TXT_FINANCIER_NAME": "SHIV RATAN SAHAKARI PATHPADI MARIADIT"
    },
    {
      "NUM_FINANCIER_CD": 13937,
      "TXT_FINANCIER_NAME": "VISHWESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13938,
      "TXT_FINANCIER_NAME": "PIRSAI GRAMIN BIGARSHETI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13939,
      "TXT_FINANCIER_NAME": "VAN GROUP V K S S STY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13940,
      "TXT_FINANCIER_NAME": "S N K AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13941,
      "TXT_FINANCIER_NAME": "MAX AUTOS BARNALA"
    },
    {
      "NUM_FINANCIER_CD": 13942,
      "TXT_FINANCIER_NAME": "JYOTI KRANTI CO OPPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 13943,
      "TXT_FINANCIER_NAME": "B HOSAHALLI PRATHAMIKA KRISHI PATTHINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13944,
      "TXT_FINANCIER_NAME": "DADMA SEVA SAHKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 13945,
      "TXT_FINANCIER_NAME": "VIJAYAKUMARI"
    },
    {
      "NUM_FINANCIER_CD": 13946,
      "TXT_FINANCIER_NAME": "CHADHA HIRE PURCHASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 13947,
      "TXT_FINANCIER_NAME": "HPGB"
    },
    {
      "NUM_FINANCIER_CD": 13948,
      "TXT_FINANCIER_NAME": "SUNIL KUMAR KUMBHAT"
    },
    {
      "NUM_FINANCIER_CD": 13949,
      "TXT_FINANCIER_NAME": "D PADAMCHAND AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 13950,
      "TXT_FINANCIER_NAME": "ADARSH SEVA SHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13951,
      "TXT_FINANCIER_NAME": "JAGRATI NAGRI SAHAKARI PATASANSTHA MARYADIT PARALI V"
    },
    {
      "NUM_FINANCIER_CD": 13952,
      "TXT_FINANCIER_NAME": "R MAHAVEER CHAND BAGMAR"
    },
    {
      "NUM_FINANCIER_CD": 13953,
      "TXT_FINANCIER_NAME": "ATTIKODIGE PRIMARY KRISHI PATTINA SAHAKARI SANGH N"
    },
    {
      "NUM_FINANCIER_CD": 13954,
      "TXT_FINANCIER_NAME": "PARAS JAIN BIGAR GRAMIN SHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13955,
      "TXT_FINANCIER_NAME": "FRIENDS AUTO CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 13956,
      "TXT_FINANCIER_NAME": "AADESHWAR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 13957,
      "TXT_FINANCIER_NAME": "AUTO TRADING CO"
    },
    {
      "NUM_FINANCIER_CD": 13958,
      "TXT_FINANCIER_NAME": "MEDINIPUR SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13959,
      "TXT_FINANCIER_NAME": "SHREE WAWAGESHWAR MAHARAJ NAGARI SAH PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 13960,
      "TXT_FINANCIER_NAME": "JAYA SOWHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13961,
      "TXT_FINANCIER_NAME": "BHUVANJYOTI CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 13962,
      "TXT_FINANCIER_NAME": "SHRI HAJAPAR SEWA SHAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13963,
      "TXT_FINANCIER_NAME": "ADITYA SISODIYA"
    },
    {
      "NUM_FINANCIER_CD": 13964,
      "TXT_FINANCIER_NAME": "SRI SHANTHVEERESHWARA SOUHARDACREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13965,
      "TXT_FINANCIER_NAME": "THE ARBUDA BACHAT AND DHIRAN SAH MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 13966,
      "TXT_FINANCIER_NAME": "RUBI CREDIT CO OPERATIVE SOCIETY LTD AJMER"
    },
    {
      "NUM_FINANCIER_CD": 13967,
      "TXT_FINANCIER_NAME": "RAMANGULI GROUP SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13968,
      "TXT_FINANCIER_NAME": "JYOTI AAVAJ GRAMIN BIGARSHETI SAHKARI PATANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13969,
      "TXT_FINANCIER_NAME": "VEERARANI CHANNAMMA MAHILA SOUHARDHA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13970,
      "TXT_FINANCIER_NAME": "THE HISAR DISTT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13971,
      "TXT_FINANCIER_NAME": "BHARTIYA SINDHU SAHAKARI PAT SANSTHA AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 13972,
      "TXT_FINANCIER_NAME": "CRODA CHEMICALS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 13973,
      "TXT_FINANCIER_NAME": "SHREE SHEKH PIPARIYA SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 13974,
      "TXT_FINANCIER_NAME": "SIROHI SAHAKARI BHUMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13975,
      "TXT_FINANCIER_NAME": "PRESIDENT SCL SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13976,
      "TXT_FINANCIER_NAME": "SURENDRA KUMAR CHORDIA"
    },
    {
      "NUM_FINANCIER_CD": 13977,
      "TXT_FINANCIER_NAME": "NREDCAP"
    },
    {
      "NUM_FINANCIER_CD": 13978,
      "TXT_FINANCIER_NAME": "NELLIKARU VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13979,
      "TXT_FINANCIER_NAME": "NAVARATNA NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13980,
      "TXT_FINANCIER_NAME": "VIJAYRAJ BHANDARI HUF"
    },
    {
      "NUM_FINANCIER_CD": 13981,
      "TXT_FINANCIER_NAME": "MILATH CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 13982,
      "TXT_FINANCIER_NAME": "GUJARAT SEHGDULED CASTE DEVLOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13983,
      "TXT_FINANCIER_NAME": "NEW VIJAYADASHMI FINANCE LEASING AND INV CORP R"
    },
    {
      "NUM_FINANCIER_CD": 13984,
      "TXT_FINANCIER_NAME": "THE KORANGRAPADY CO OP AGRL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 13985,
      "TXT_FINANCIER_NAME": "TJSB SHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13986,
      "TXT_FINANCIER_NAME": "AMDAR SITARAM GHANDAT MAMA G B SHETI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 13987,
      "TXT_FINANCIER_NAME": "GANAPATI VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13988,
      "TXT_FINANCIER_NAME": "SRINIVASA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 13989,
      "TXT_FINANCIER_NAME": "THE GULBARGA AND YADGIR DIST CO OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 13990,
      "TXT_FINANCIER_NAME": "V V S SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 13991,
      "TXT_FINANCIER_NAME": "MOORTHEDARARA SEVA SAHAKARI SANGHA NIYAMITHA ALANKARU"
    },
    {
      "NUM_FINANCIER_CD": 13992,
      "TXT_FINANCIER_NAME": "ALLWYN CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 13993,
      "TXT_FINANCIER_NAME": "SHRE GANESH MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 13994,
      "TXT_FINANCIER_NAME": "BYRAMADA PRIMARY AGRICULTURE CO OPRATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 13995,
      "TXT_FINANCIER_NAME": "SREE MAHALINGESWARA CREDIT CO OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 13996,
      "TXT_FINANCIER_NAME": "ANDARSHUL NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 13997,
      "TXT_FINANCIER_NAME": "GOA LEGISLATIVE ASSEMBLY"
    },
    {
      "NUM_FINANCIER_CD": 13998,
      "TXT_FINANCIER_NAME": "DIVYAJYOTHI VIVIDHODDESHA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 13999,
      "TXT_FINANCIER_NAME": "GUNORE GRAMIN BIGARSHETI SAHKARI PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14000,
      "TXT_FINANCIER_NAME": "THE SANTARAMPUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14001,
      "TXT_FINANCIER_NAME": "M D ENGINEERS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14002,
      "TXT_FINANCIER_NAME": "KARESHWAR GRAMIN BIGARSHETI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14003,
      "TXT_FINANCIER_NAME": "RAJ RAJYA SAHAKARI BHOOMI VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14004,
      "TXT_FINANCIER_NAME": "SHRI SAI SAKH SAHKARITA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14005,
      "TXT_FINANCIER_NAME": "VIHITGAON VIVIDH KARYAKARI SAH VIKAS SEVA SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14006,
      "TXT_FINANCIER_NAME": "SHREYA TRADERS"
    },
    {
      "NUM_FINANCIER_CD": 14007,
      "TXT_FINANCIER_NAME": "JANTA CREDIT CO OP SOC"
    },
    {
      "NUM_FINANCIER_CD": 14008,
      "TXT_FINANCIER_NAME": "JINESHWAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15444,
      "TXT_FINANCIER_NAME": "VENUTAI CHAVAN SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15445,
      "TXT_FINANCIER_NAME": "VETTACKAL SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15446,
      "TXT_FINANCIER_NAME": "VIJAYAPUR SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 15447,
      "TXT_FINANCIER_NAME": "VIKAS HIRE PURCHASE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15448,
      "TXT_FINANCIER_NAME": "VIKAS VIVIDODDESHA SAHAKARI SANGHA GURUVAYANAKERE"
    },
    {
      "NUM_FINANCIER_CD": 15449,
      "TXT_FINANCIER_NAME": "VIKHAYAT SECURITIES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 15450,
      "TXT_FINANCIER_NAME": "VISHAKHAPATANAM CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15451,
      "TXT_FINANCIER_NAME": "VISHAL JAIN"
    },
    {
      "NUM_FINANCIER_CD": 15452,
      "TXT_FINANCIER_NAME": "VISHAL PURANDAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15453,
      "TXT_FINANCIER_NAME": "VISHWABHARATI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15454,
      "TXT_FINANCIER_NAME": "VISHWACHETAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15455,
      "TXT_FINANCIER_NAME": "VOLKSWAGON FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15456,
      "TXT_FINANCIER_NAME": "WAIN GANGA KRUSHNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15457,
      "TXT_FINANCIER_NAME": "WAKHAR BHAG NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 15458,
      "TXT_FINANCIER_NAME": "WEDNESDAY MARKET BRANCH BHIMAVARAM"
    },
    {
      "NUM_FINANCIER_CD": 15459,
      "TXT_FINANCIER_NAME": "WESTRON DIVISION LAMP SOCIETY SADALGA"
    },
    {
      "NUM_FINANCIER_CD": 15460,
      "TXT_FINANCIER_NAME": "YADAGERE SOUHARDA CREDIT CO OP NIYAMITHA KALASA BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 15461,
      "TXT_FINANCIER_NAME": "YEDURULANKA I POLAVARAM MANDAL"
    },
    {
      "NUM_FINANCIER_CD": 15462,
      "TXT_FINANCIER_NAME": "YOGYATA ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 15521,
      "TXT_FINANCIER_NAME": "AMROHA TRADE FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 15522,
      "TXT_FINANCIER_NAME": "ANMOL FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15523,
      "TXT_FINANCIER_NAME": "ANNAPOORNA FINVEST LTD."
    },
    {
      "NUM_FINANCIER_CD": 15524,
      "TXT_FINANCIER_NAME": "ANUFINA INVESTMENT AND LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 15525,
      "TXT_FINANCIER_NAME": "ANUPAMA FINANCE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 15526,
      "TXT_FINANCIER_NAME": "ANUVISH FINANCE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15527,
      "TXT_FINANCIER_NAME": "APEEJAY FINANCE GROUP LTD"
    },
    {
      "NUM_FINANCIER_CD": 15528,
      "TXT_FINANCIER_NAME": "APNA FINANCE (INDIA) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15529,
      "TXT_FINANCIER_NAME": "AQUILA FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15530,
      "TXT_FINANCIER_NAME": "ARMAN LEASE AND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15531,
      "TXT_FINANCIER_NAME": "ASHOK LEYLAND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15532,
      "TXT_FINANCIER_NAME": "AURAM LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15533,
      "TXT_FINANCIER_NAME": "AUTOMOBILE GENERAL FINANCE COMPANY (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15534,
      "TXT_FINANCIER_NAME": "BABA FARID INVESTMENT PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15535,
      "TXT_FINANCIER_NAME": "BAITHUL ISLAM FIN & INVESTMENT (INDIA) PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15536,
      "TXT_FINANCIER_NAME": "BAJAJ AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15537,
      "TXT_FINANCIER_NAME": "BALAJI INSTALMENTS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15538,
      "TXT_FINANCIER_NAME": "BALAJI INSTALMENTS SUPPLY (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15539,
      "TXT_FINANCIER_NAME": "BALAJI FUND GROWTH LTD."
    },
    {
      "NUM_FINANCIER_CD": 15540,
      "TXT_FINANCIER_NAME": "BANSAL MOTOR FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15541,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE CO-OP. APEX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15542,
      "TXT_FINANCIER_NAME": "ONGC"
    },
    {
      "NUM_FINANCIER_CD": 15543,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15544,
      "TXT_FINANCIER_NAME": "POWER GRID CORP. OF INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 15545,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO.OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15546,
      "TXT_FINANCIER_NAME": "MAHENDRA & MAHENDRA FINANCIAL SERVICES LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15547,
      "TXT_FINANCIER_NAME": "SHRI ADINATH CO.OP.SAVING & CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 15548,
      "TXT_FINANCIER_NAME": "THE PUNJAB STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15549,
      "TXT_FINANCIER_NAME": "THE SHEVAPET URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15550,
      "TXT_FINANCIER_NAME": "THE BANK OF RAJASTHAN LTD."
    },
    {
      "NUM_FINANCIER_CD": 15551,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALA PARDI PEOPLES CO.OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 15552,
      "TXT_FINANCIER_NAME": "THE MANEKCHOWK CO-OPERATIVE BANK TLD"
    },
    {
      "NUM_FINANCIER_CD": 15553,
      "TXT_FINANCIER_NAME": "AMARNATH CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15554,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FINANCE SER P LTD"
    },
    {
      "NUM_FINANCIER_CD": 15555,
      "TXT_FINANCIER_NAME": "THE RAILWAY EMPLOYEES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15556,
      "TXT_FINANCIER_NAME": "THE UNION CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15557,
      "TXT_FINANCIER_NAME": "DEVELOPMENT CREDIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 15558,
      "TXT_FINANCIER_NAME": "THE JAMMU & KASHMIR BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15559,
      "TXT_FINANCIER_NAME": "SHREE BHAILALBHAI CONTRACTOR SMARAK CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15560,
      "TXT_FINANCIER_NAME": "POORNAWADI NAG.SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15561,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FINANCIAL SERVICES PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 15562,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15563,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SERVICES P LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15564,
      "TXT_FINANCIER_NAME": "POORNAWADI NAGRIK S. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15565,
      "TXT_FINANCIER_NAME": "NEYVELI LIGNITE CORPORN LTD"
    },
    {
      "NUM_FINANCIER_CD": 15566,
      "TXT_FINANCIER_NAME": "Citizen Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 15567,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15568,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15569,
      "TXT_FINANCIER_NAME": "THE JAMNAGAR PEOPLES CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15570,
      "TXT_FINANCIER_NAME": "UTI BANK"
    },
    {
      "NUM_FINANCIER_CD": 15571,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLEUM CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 15572,
      "TXT_FINANCIER_NAME": "TUMKUR GRAIN MERCHANTS CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15573,
      "TXT_FINANCIER_NAME": "SHREE VARDHMAN SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15574,
      "TXT_FINANCIER_NAME": "LEASE PLAN INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 15575,
      "TXT_FINANCIER_NAME": "THE JAMMU & KASHMIR BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15576,
      "TXT_FINANCIER_NAME": "THE KANGRA CENTRAL COOP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15577,
      "TXT_FINANCIER_NAME": "THE ANAND MERCANTILE CO-OP. BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15578,
      "TXT_FINANCIER_NAME": "THE AKOLA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15579,
      "TXT_FINANCIER_NAME": "THE HONGKONG AND SHANGHAI CORPORATION LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 15580,
      "TXT_FINANCIER_NAME": "THE VAISH CO-OPERATIVE NEW BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15581,
      "TXT_FINANCIER_NAME": "JANAKALYAN CO-OP BANK LTD. NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 15582,
      "TXT_FINANCIER_NAME": "THE MADHAVPURA MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15583,
      "TXT_FINANCIER_NAME": "THE VITA MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15584,
      "TXT_FINANCIER_NAME": "SHALINI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15585,
      "TXT_FINANCIER_NAME": "THE NARODA NAGARIK CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15586,
      "TXT_FINANCIER_NAME": "LAXMI VISHNU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15587,
      "TXT_FINANCIER_NAME": "MANDVI CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15588,
      "TXT_FINANCIER_NAME": "THE SOUTH INDIAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15589,
      "TXT_FINANCIER_NAME": "THE UNITED CO OPP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15590,
      "TXT_FINANCIER_NAME": "ANYONYA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15591,
      "TXT_FINANCIER_NAME": "THE GANESH BANK OF KURUNDWAD"
    },
    {
      "NUM_FINANCIER_CD": 15592,
      "TXT_FINANCIER_NAME": "THE NAVNIRMAN CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15593,
      "TXT_FINANCIER_NAME": "NOBLE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15594,
      "TXT_FINANCIER_NAME": "STERLING URBAN COOP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15595,
      "TXT_FINANCIER_NAME": "THE GANESH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15596,
      "TXT_FINANCIER_NAME": "JALORE NAGARIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15597,
      "TXT_FINANCIER_NAME": "KHATTRI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15598,
      "TXT_FINANCIER_NAME": "MAHAVEER CO OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15599,
      "TXT_FINANCIER_NAME": "LALBAUG CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15600,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15601,
      "TXT_FINANCIER_NAME": "KANKARIA MANINAGAR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17164,
      "TXT_FINANCIER_NAME": "Churu District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17165,
      "TXT_FINANCIER_NAME": "Jaipur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17166,
      "TXT_FINANCIER_NAME": "Jaisalmer District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17167,
      "TXT_FINANCIER_NAME": "Jalore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17168,
      "TXT_FINANCIER_NAME": "Jhalawar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17169,
      "TXT_FINANCIER_NAME": "Kota District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17170,
      "TXT_FINANCIER_NAME": "Nagaur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17171,
      "TXT_FINANCIER_NAME": "Pali District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17172,
      "TXT_FINANCIER_NAME": "Sawai Madhopur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17173,
      "TXT_FINANCIER_NAME": "Sirohi District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17174,
      "TXT_FINANCIER_NAME": "Udaipur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17175,
      "TXT_FINANCIER_NAME": "Amreli District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17176,
      "TXT_FINANCIER_NAME": "Bhavanagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17177,
      "TXT_FINANCIER_NAME": "Bharuch District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17178,
      "TXT_FINANCIER_NAME": "Junagadh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17179,
      "TXT_FINANCIER_NAME": "Kodinar Tlk District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17180,
      "TXT_FINANCIER_NAME": "Kuchch District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17181,
      "TXT_FINANCIER_NAME": "Mehsana District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17182,
      "TXT_FINANCIER_NAME": "Rajkot District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17183,
      "TXT_FINANCIER_NAME": "Valsad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17184,
      "TXT_FINANCIER_NAME": "Madhya Pradesh State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17185,
      "TXT_FINANCIER_NAME": "Maharashtra State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17186,
      "TXT_FINANCIER_NAME": "Akola District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17187,
      "TXT_FINANCIER_NAME": "Amrawati District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17188,
      "TXT_FINANCIER_NAME": "Bhandara District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17189,
      "TXT_FINANCIER_NAME": "Bid District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17190,
      "TXT_FINANCIER_NAME": "Chadrapur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17191,
      "TXT_FINANCIER_NAME": "Gadchiroli District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17192,
      "TXT_FINANCIER_NAME": "Jalna District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17193,
      "TXT_FINANCIER_NAME": "Nanded District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17194,
      "TXT_FINANCIER_NAME": "Parbhani District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17195,
      "TXT_FINANCIER_NAME": "Pune District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17196,
      "TXT_FINANCIER_NAME": "Ratnagiri District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17197,
      "TXT_FINANCIER_NAME": "Sangli District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17198,
      "TXT_FINANCIER_NAME": "Sindhudurg District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17199,
      "TXT_FINANCIER_NAME": "Solapur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17200,
      "TXT_FINANCIER_NAME": "Thane District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17201,
      "TXT_FINANCIER_NAME": "Wardha District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17202,
      "TXT_FINANCIER_NAME": "Arunachal Pradesh State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17203,
      "TXT_FINANCIER_NAME": "Assam State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17204,
      "TXT_FINANCIER_NAME": "Shibsagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17205,
      "TXT_FINANCIER_NAME": "Angul District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17206,
      "TXT_FINANCIER_NAME": "Aska District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17207,
      "TXT_FINANCIER_NAME": "Banki District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17208,
      "TXT_FINANCIER_NAME": "Bolangir District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17209,
      "TXT_FINANCIER_NAME": "Boudh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17210,
      "TXT_FINANCIER_NAME": "Cuttack District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17211,
      "TXT_FINANCIER_NAME": "Keonjhar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17212,
      "TXT_FINANCIER_NAME": "Khurda District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17213,
      "TXT_FINANCIER_NAME": "Mayurbhanj District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17214,
      "TXT_FINANCIER_NAME": "Sambalpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17215,
      "TXT_FINANCIER_NAME": "Tripura State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17216,
      "TXT_FINANCIER_NAME": "Balageria District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17217,
      "TXT_FINANCIER_NAME": "Balurghat District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17218,
      "TXT_FINANCIER_NAME": "THE BALASORE BHADRAK CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17219,
      "TXT_FINANCIER_NAME": "SHREE INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 17220,
      "TXT_FINANCIER_NAME": "JOGINDRA CENTRAL CO_OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17221,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO_OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17222,
      "TXT_FINANCIER_NAME": "THE KURMANCHAL NAGAR SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17223,
      "TXT_FINANCIER_NAME": "janata sahakari bank ltd. pune br.ratnagiri"
    },
    {
      "NUM_FINANCIER_CD": 17224,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLEUM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 17225,
      "TXT_FINANCIER_NAME": "THIRUVANANTHAPURAM DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17226,
      "TXT_FINANCIER_NAME": "DURGAPUR STEEL PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17227,
      "TXT_FINANCIER_NAME": "UNITED MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17228,
      "TXT_FINANCIER_NAME": "UTI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17229,
      "TXT_FINANCIER_NAME": "UTI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17230,
      "TXT_FINANCIER_NAME": "LEASE PLAN INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17231,
      "TXT_FINANCIER_NAME": "CEEJAY FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17232,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD &ampPNB&ampALL CONSORTIUM MEMBER BANKS"
    },
    {
      "NUM_FINANCIER_CD": 17233,
      "TXT_FINANCIER_NAME": "STATE BANK OF SAURASTRA"
    },
    {
      "NUM_FINANCIER_CD": 17234,
      "TXT_FINANCIER_NAME": "SARASWAT CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17235,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 17236,
      "TXT_FINANCIER_NAME": "JAMNAGAR PEOPLES CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17237,
      "TXT_FINANCIER_NAME": "The United Western Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17238,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17239,
      "TXT_FINANCIER_NAME": "LAXMI MAHILA NAGRIK SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17240,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17241,
      "TXT_FINANCIER_NAME": "Integral Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17242,
      "TXT_FINANCIER_NAME": "AMRELI JILLA MADHYASTH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17243,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGRIK CO OP BANK LAT"
    },
    {
      "NUM_FINANCIER_CD": 17244,
      "TXT_FINANCIER_NAME": "SHREE SADGURU JANGALI MAHARAJ SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17245,
      "TXT_FINANCIER_NAME": "THE UDHNA CITIZEN CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17246,
      "TXT_FINANCIER_NAME": "INTEGRATED FINANCE CO LTD., & UTI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17247,
      "TXT_FINANCIER_NAME": "SHRI MAHILA SEWA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17248,
      "TXT_FINANCIER_NAME": "TEXTILE TRADERS CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17249,
      "TXT_FINANCIER_NAME": "NARODA INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17250,
      "TXT_FINANCIER_NAME": "SARDAR VALLABHBHAI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 12509,
      "TXT_FINANCIER_NAME": "K C INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12510,
      "TXT_FINANCIER_NAME": "THE SAHYADRI MULTI STATE COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12511,
      "TXT_FINANCIER_NAME": "M P STATE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12512,
      "TXT_FINANCIER_NAME": "PRIYANKA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12513,
      "TXT_FINANCIER_NAME": "BHADRA FINANCE AND LEASE"
    },
    {
      "NUM_FINANCIER_CD": 12514,
      "TXT_FINANCIER_NAME": "CHOUNDESHWARY NAGARI SAHAKARI PATH SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 12515,
      "TXT_FINANCIER_NAME": "MUDUR VYAVASAYA SAHAKARI SEVA BANK"
    },
    {
      "NUM_FINANCIER_CD": 12516,
      "TXT_FINANCIER_NAME": "SOUKARYA MULTIPURPOSE SOUHARDA SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12517,
      "TXT_FINANCIER_NAME": "SHRINIDHI URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12518,
      "TXT_FINANCIER_NAME": "AZED VEHICLE FINANCE HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 12519,
      "TXT_FINANCIER_NAME": "SAVALI GRAMIN BIGERSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12520,
      "TXT_FINANCIER_NAME": "SHREE SHANDIP ARBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12521,
      "TXT_FINANCIER_NAME": "RAJ CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12522,
      "TXT_FINANCIER_NAME": "SINDHUDURG CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12523,
      "TXT_FINANCIER_NAME": "OM SHIVSHAKTI NAGRI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12524,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA SARAFI SAHAKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 12525,
      "TXT_FINANCIER_NAME": "MD ENGINEERS I LTD"
    },
    {
      "NUM_FINANCIER_CD": 12526,
      "TXT_FINANCIER_NAME": "GAYATRI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12527,
      "TXT_FINANCIER_NAME": "AGRASEN NAGRI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12528,
      "TXT_FINANCIER_NAME": "THE MANATHANA SER CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12529,
      "TXT_FINANCIER_NAME": "SRI SIVA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12530,
      "TXT_FINANCIER_NAME": "SHRI GHOGHAVADAR JUTH SEVA SAHKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 12531,
      "TXT_FINANCIER_NAME": "GERANRAL ADMINISTRATION AND COORDN DEPT"
    },
    {
      "NUM_FINANCIER_CD": 12532,
      "TXT_FINANCIER_NAME": "SAI CHINTAN NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12533,
      "TXT_FINANCIER_NAME": "SHREE SANT DNYANDEV MAULI NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12534,
      "TXT_FINANCIER_NAME": "GRACE LEASING CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12535,
      "TXT_FINANCIER_NAME": "BANASH URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12536,
      "TXT_FINANCIER_NAME": "JANATA S K U S LTD"
    },
    {
      "NUM_FINANCIER_CD": 12537,
      "TXT_FINANCIER_NAME": "DAHANU JANTA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12538,
      "TXT_FINANCIER_NAME": "RANDHAWA TRANSPORT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12539,
      "TXT_FINANCIER_NAME": "GANGULI TOWN SOUHARDA CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12540,
      "TXT_FINANCIER_NAME": "RUNGTA COLLEGE OF ENGINEERING AND TECHNOLOGY"
    },
    {
      "NUM_FINANCIER_CD": 12541,
      "TXT_FINANCIER_NAME": "BALAJEE ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 12542,
      "TXT_FINANCIER_NAME": "BHORUKA POWER CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12543,
      "TXT_FINANCIER_NAME": "MISHRI TRADE CREDITES"
    },
    {
      "NUM_FINANCIER_CD": 12544,
      "TXT_FINANCIER_NAME": "THE SANGROOR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12545,
      "TXT_FINANCIER_NAME": "GARHA CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12546,
      "TXT_FINANCIER_NAME": "MADHI SURALI NAGRIK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12547,
      "TXT_FINANCIER_NAME": "KRUTHIKA SOUHARDA PATTINA SAHOKARNI NIYAMITA HOSPET"
    },
    {
      "NUM_FINANCIER_CD": 12548,
      "TXT_FINANCIER_NAME": "THE SMRUTI MULTI PURPOSE COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12549,
      "TXT_FINANCIER_NAME": "SHRI JAGADAMBA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 12550,
      "TXT_FINANCIER_NAME": "MIDNAPORE CO OP AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12551,
      "TXT_FINANCIER_NAME": "KALPATARU CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12552,
      "TXT_FINANCIER_NAME": "SALIM ALI CENTRE FOR ORNITHOLOGY NATURAL HISTORY"
    },
    {
      "NUM_FINANCIER_CD": 12553,
      "TXT_FINANCIER_NAME": "S S FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 12554,
      "TXT_FINANCIER_NAME": "YUVA KOKAN SAHKARI PATPEDHI MARAYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12555,
      "TXT_FINANCIER_NAME": "MANOJ MOTOR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12556,
      "TXT_FINANCIER_NAME": "KHODIYAR INFRA INVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12557,
      "TXT_FINANCIER_NAME": "VAISYA UB COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12558,
      "TXT_FINANCIER_NAME": "SHRI SANT NAGEBABA GRAMIN BIGARSHETI SAHAKARI PATSANSHTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12559,
      "TXT_FINANCIER_NAME": "ASHIQ FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12560,
      "TXT_FINANCIER_NAME": "IDBI RETAIL ASSET CENTRE"
    },
    {
      "NUM_FINANCIER_CD": 12561,
      "TXT_FINANCIER_NAME": "SAIDHAN NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12562,
      "TXT_FINANCIER_NAME": "KHIWESARA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12563,
      "TXT_FINANCIER_NAME": "KAMAL FINANCE SOWCARPET CHENNAI INDIAN BANK SOWCARPET CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 12564,
      "TXT_FINANCIER_NAME": "DIYVA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12565,
      "TXT_FINANCIER_NAME": "SHRI JALARAM MER CO OP CRE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12566,
      "TXT_FINANCIER_NAME": "TJCNAT AND C SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12567,
      "TXT_FINANCIER_NAME": "SETHI INVESTMENT COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 12568,
      "TXT_FINANCIER_NAME": "SURESH AUTOMOBILES"
    },
    {
      "NUM_FINANCIER_CD": 12569,
      "TXT_FINANCIER_NAME": "DIGANTHA INVESTMENT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12570,
      "TXT_FINANCIER_NAME": "SHIMOGA DISTRICT CO OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12571,
      "TXT_FINANCIER_NAME": "SHREE MAHADESHWAR SAHAKARI PATPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 12572,
      "TXT_FINANCIER_NAME": "DHARMVEER SAMBHAJI GRAMIN BIGARSHETI SAH PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 12573,
      "TXT_FINANCIER_NAME": "JILA ANTYASAIE SAHAKARI SAMITI"
    },
    {
      "NUM_FINANCIER_CD": 12574,
      "TXT_FINANCIER_NAME": "BHARTI SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12575,
      "TXT_FINANCIER_NAME": "GURJA CO OP THIRIT CREDITY SOCIATY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12576,
      "TXT_FINANCIER_NAME": "JAI BHAVANI MAHILA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12577,
      "TXT_FINANCIER_NAME": "SHRI HARA VIVIDODHESHA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12578,
      "TXT_FINANCIER_NAME": "VIRCHOW CHEMICALS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12579,
      "TXT_FINANCIER_NAME": "KRUSHANAI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12580,
      "TXT_FINANCIER_NAME": "SHRI NANDI PATTINA SAHAKARA SANGH NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12581,
      "TXT_FINANCIER_NAME": "KODANAD SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12582,
      "TXT_FINANCIER_NAME": "SWARAJ NAGARI SAHAKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12583,
      "TXT_FINANCIER_NAME": "ARUN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12584,
      "TXT_FINANCIER_NAME": "PRAFUL H BHANDARI"
    },
    {
      "NUM_FINANCIER_CD": 12585,
      "TXT_FINANCIER_NAME": "SHREE GANESH GRAMIN BI SHE SAH PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12586,
      "TXT_FINANCIER_NAME": "SHREE GANESH GRAMIN BIGAR SHETI SAHAKARI PATH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12587,
      "TXT_FINANCIER_NAME": "R A FINANCE AND LEASING COMPANY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12588,
      "TXT_FINANCIER_NAME": "SHANTINATHNAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12589,
      "TXT_FINANCIER_NAME": "HILL VIEW FINVEST P LTD"
    },
    {
      "NUM_FINANCIER_CD": 12590,
      "TXT_FINANCIER_NAME": "REDDY SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 12591,
      "TXT_FINANCIER_NAME": "RAMARAJA KSHATRIYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12592,
      "TXT_FINANCIER_NAME": "GAUTAM CHAND AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 12593,
      "TXT_FINANCIER_NAME": "THE MANDARTHY SERVICE CO OP BANK LTD MANDARTHY"
    },
    {
      "NUM_FINANCIER_CD": 12594,
      "TXT_FINANCIER_NAME": "THE MEENACHIL AGRI AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12595,
      "TXT_FINANCIER_NAME": "SRIVARI FINANCE AND LEASING COMPANY P LTD"
    },
    {
      "NUM_FINANCIER_CD": 12596,
      "TXT_FINANCIER_NAME": "SEVEN STAR HIRE PURCHASING"
    },
    {
      "NUM_FINANCIER_CD": 12597,
      "TXT_FINANCIER_NAME": "SHRI SANT DYANESHWAR NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12598,
      "TXT_FINANCIER_NAME": "THE BHAGYALAXMI MAHILA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12599,
      "TXT_FINANCIER_NAME": "WINSOME TEXTILE INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 12600,
      "TXT_FINANCIER_NAME": "SANTHAL PARGANAS GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 12601,
      "TXT_FINANCIER_NAME": "SHRI SIDDHIVINAYAK AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12602,
      "TXT_FINANCIER_NAME": "VKRAM FIN LEASE"
    },
    {
      "NUM_FINANCIER_CD": 12603,
      "TXT_FINANCIER_NAME": "ASHIRVAD NAGRI SAH BANK"
    },
    {
      "NUM_FINANCIER_CD": 12604,
      "TXT_FINANCIER_NAME": "JIVANDHARA NAG SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14009,
      "TXT_FINANCIER_NAME": "BANTWAL VARTHAKARA VIVIDODESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14010,
      "TXT_FINANCIER_NAME": "KUTCHI OSWALMUTUALLY AIDED CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14011,
      "TXT_FINANCIER_NAME": "SITHARAM V S S S"
    },
    {
      "NUM_FINANCIER_CD": 14012,
      "TXT_FINANCIER_NAME": "SHRI MURGENDRA SWAMI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14013,
      "TXT_FINANCIER_NAME": "SHRI DUDHGANGA CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14014,
      "TXT_FINANCIER_NAME": "B B R AND R D C C BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14015,
      "TXT_FINANCIER_NAME": "BILARA SAHAKARI BHOOMI DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14016,
      "TXT_FINANCIER_NAME": "MS COMFORT ENGINEERS"
    },
    {
      "NUM_FINANCIER_CD": 14017,
      "TXT_FINANCIER_NAME": "SHREE MODHESHWARI GRAHK SAHKARI BHANDAR LTD"
    },
    {
      "NUM_FINANCIER_CD": 14018,
      "TXT_FINANCIER_NAME": "ANUSUCHIT JATI VIKAS CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14019,
      "TXT_FINANCIER_NAME": "GUJRAT ANUSUCHIT JATI VIKAS CORORATION"
    },
    {
      "NUM_FINANCIER_CD": 14020,
      "TXT_FINANCIER_NAME": "SKE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14021,
      "TXT_FINANCIER_NAME": "KOCHI TALUK CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPEMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 14022,
      "TXT_FINANCIER_NAME": "NAVACHETHANA CHITS CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14023,
      "TXT_FINANCIER_NAME": "ABI INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 14024,
      "TXT_FINANCIER_NAME": "THE CHACHRARI MULTIPURPOSE CO OPERATIVE AGRI SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14025,
      "TXT_FINANCIER_NAME": "KUSHAL JAISWAL"
    },
    {
      "NUM_FINANCIER_CD": 14026,
      "TXT_FINANCIER_NAME": "PARAPPANANGADI AUTORICKSHAW DRIVERS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14027,
      "TXT_FINANCIER_NAME": "SRI NEELAKANTESHWAR PATTINA SAHAKAR SANGH NI"
    },
    {
      "NUM_FINANCIER_CD": 14028,
      "TXT_FINANCIER_NAME": "BANASKANTHA DIS CO OP MILK PRODHCERIS UNION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14029,
      "TXT_FINANCIER_NAME": "RAYTHARA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14030,
      "TXT_FINANCIER_NAME": "GURU SAUHARDHA CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14031,
      "TXT_FINANCIER_NAME": "THE SALEMPUR MASANDA CAMP S S LTD"
    },
    {
      "NUM_FINANCIER_CD": 14032,
      "TXT_FINANCIER_NAME": "MACHINDRANATH MULTI STATE CO OP CREDIT SOCIETY LTD BR SHIRUR"
    },
    {
      "NUM_FINANCIER_CD": 14033,
      "TXT_FINANCIER_NAME": "JAIHIND SAHKARI PAT SANSTHA MARYA AMGAON"
    },
    {
      "NUM_FINANCIER_CD": 14034,
      "TXT_FINANCIER_NAME": "CHETANA PATTINA SOUHARDA SAHAKARI NIYAMITHA TAVARAGERA"
    },
    {
      "NUM_FINANCIER_CD": 14035,
      "TXT_FINANCIER_NAME": "THRISSUR DT AUTO CONSULTANT SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14036,
      "TXT_FINANCIER_NAME": "AAKASH HAYER PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 14037,
      "TXT_FINANCIER_NAME": "THE MADHUR SERVICE CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14038,
      "TXT_FINANCIER_NAME": "GIRIJA GRAMIN BIGSHETI SHANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14039,
      "TXT_FINANCIER_NAME": "SHRI MATA BHAVANI PATTIN SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14040,
      "TXT_FINANCIER_NAME": "AKOLAMERCHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14041,
      "TXT_FINANCIER_NAME": "PREMSUKH GARG AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 14042,
      "TXT_FINANCIER_NAME": "SHRI SEVASAKHTI SAHAKARI PATH MARYDT"
    },
    {
      "NUM_FINANCIER_CD": 14043,
      "TXT_FINANCIER_NAME": "YASHASWI MAHILA URBAN CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14044,
      "TXT_FINANCIER_NAME": "AATMASHAKTI GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14045,
      "TXT_FINANCIER_NAME": "GOYAL FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14046,
      "TXT_FINANCIER_NAME": "GOURISHANKAR SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14047,
      "TXT_FINANCIER_NAME": "AU FINANCERS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14048,
      "TXT_FINANCIER_NAME": "MAHADEVA VIVIDHODHESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14049,
      "TXT_FINANCIER_NAME": "SOUTH CANARA CO OP EMPLOYEES CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14050,
      "TXT_FINANCIER_NAME": "SREE BHRAMARAMBA PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 14051,
      "TXT_FINANCIER_NAME": "THE MORAD GROUP SEVA SAHAKARI MANDALI AND SK BANK"
    },
    {
      "NUM_FINANCIER_CD": 14052,
      "TXT_FINANCIER_NAME": "SRI SHANKAR VEERABADRESHWARA SOUHARDHA SAHAKARA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14053,
      "TXT_FINANCIER_NAME": "MAHASATI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14054,
      "TXT_FINANCIER_NAME": "THE MANAGING PARTNER LAKSHMI ENTERPRISES NANDIKKARA"
    },
    {
      "NUM_FINANCIER_CD": 14055,
      "TXT_FINANCIER_NAME": "THE MATODA GROUP SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14056,
      "TXT_FINANCIER_NAME": "UTTRAKHAND STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14057,
      "TXT_FINANCIER_NAME": "PMC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14058,
      "TXT_FINANCIER_NAME": "SRI ARUL VINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14059,
      "TXT_FINANCIER_NAME": "SURAKSHA SOUHARDA COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14060,
      "TXT_FINANCIER_NAME": "KANAKA MAHILA CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14061,
      "TXT_FINANCIER_NAME": "DHARMANATH GRAMIN BIGAR SHETI SAH PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14062,
      "TXT_FINANCIER_NAME": "SELVA GANPATHY INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 14063,
      "TXT_FINANCIER_NAME": "VASAI TALUKA MASEMARI SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14064,
      "TXT_FINANCIER_NAME": "LAXMI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14065,
      "TXT_FINANCIER_NAME": "SHREE SWARNA GANAPATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14066,
      "TXT_FINANCIER_NAME": "SHREE LAXMANANDA V S S LTD"
    },
    {
      "NUM_FINANCIER_CD": 14067,
      "TXT_FINANCIER_NAME": "SEYED SHARIAT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14068,
      "TXT_FINANCIER_NAME": "DAIVAJNA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14069,
      "TXT_FINANCIER_NAME": "BHAVSAR NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14070,
      "TXT_FINANCIER_NAME": "ELLORA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14071,
      "TXT_FINANCIER_NAME": "RAIGAD DARSHAN NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14072,
      "TXT_FINANCIER_NAME": "STATE GOVERNMENT"
    },
    {
      "NUM_FINANCIER_CD": 14073,
      "TXT_FINANCIER_NAME": "MANGANGA GRAMIN BIGARSHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14074,
      "TXT_FINANCIER_NAME": "PUNE NAGARI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14075,
      "TXT_FINANCIER_NAME": "UMA MAHESWARA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14076,
      "TXT_FINANCIER_NAME": "BILINELE CO OP AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14077,
      "TXT_FINANCIER_NAME": "R N FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14078,
      "TXT_FINANCIER_NAME": "I U C A A"
    },
    {
      "NUM_FINANCIER_CD": 14079,
      "TXT_FINANCIER_NAME": "MANGALORE SAUHARDA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 14080,
      "TXT_FINANCIER_NAME": "SHRI GURU BASAVA LEASING"
    },
    {
      "NUM_FINANCIER_CD": 14081,
      "TXT_FINANCIER_NAME": "THE KADANNAMANNA SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14082,
      "TXT_FINANCIER_NAME": "VIJAY REAL PROMOTERS AND HOME DEVELOPERS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14083,
      "TXT_FINANCIER_NAME": "B R AMBEDKUR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14084,
      "TXT_FINANCIER_NAME": "THE SUNMARG ALPA SANKHATARA CO OP CREDIT SOCIETY NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14085,
      "TXT_FINANCIER_NAME": "MAHABIR GMB TRADEFIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14086,
      "TXT_FINANCIER_NAME": "SASSOON DOCKS MASTYA GANGA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14087,
      "TXT_FINANCIER_NAME": "THE MORWANNAGRIK CO OPP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14088,
      "TXT_FINANCIER_NAME": "SHIVCHAITANYA NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14089,
      "TXT_FINANCIER_NAME": "THE TANDALIYA GROUP SEVA SAH MAN LTD AND S K DIST CEN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 14090,
      "TXT_FINANCIER_NAME": "MSEB ENG CO OP CONSUMER SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14091,
      "TXT_FINANCIER_NAME": "GOVT OF KERALA"
    },
    {
      "NUM_FINANCIER_CD": 14092,
      "TXT_FINANCIER_NAME": "MAGADI KEMPEGOWDA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14093,
      "TXT_FINANCIER_NAME": "SRI KARPAGA VINAYAGAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14094,
      "TXT_FINANCIER_NAME": "BAHUJANHITAY NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14095,
      "TXT_FINANCIER_NAME": "SINDHURG MUILTI PURPOSE CO OP SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14096,
      "TXT_FINANCIER_NAME": "KRISHNAMAI BIGAR SHETI SAHKARI PATSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 14097,
      "TXT_FINANCIER_NAME": "ADRSHA MULTIPROSE CO OP SOCITY"
    },
    {
      "NUM_FINANCIER_CD": 14098,
      "TXT_FINANCIER_NAME": "ZILLZ KINDRA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 14099,
      "TXT_FINANCIER_NAME": "NIGOTWADI BIGAR SHETI GRAMIN SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14100,
      "TXT_FINANCIER_NAME": "SRI REVANASIDDESHWAR CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14101,
      "TXT_FINANCIER_NAME": "JAGADGURU TUKARAM MAHARAJ NAGRI SAHAKARI PATH SANSTHA MARYADIT BASMAT"
    },
    {
      "NUM_FINANCIER_CD": 15602,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPLES CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15603,
      "TXT_FINANCIER_NAME": "THE GRAIN MERCHANTS CO OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 15604,
      "TXT_FINANCIER_NAME": "VIDISHA -BHOPAL KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15605,
      "TXT_FINANCIER_NAME": "SHRI SATYAVIJAY SAHAKARI BANK LTD,KUNDAL"
    },
    {
      "NUM_FINANCIER_CD": 15606,
      "TXT_FINANCIER_NAME": "RAJGURUNAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15607,
      "TXT_FINANCIER_NAME": "BARDOLI VIBHAG GRAM VIKASH CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15608,
      "TXT_FINANCIER_NAME": "JOTIRLING NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15609,
      "TXT_FINANCIER_NAME": "JANASEVA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15610,
      "TXT_FINANCIER_NAME": "THIRUVANANTHAPURAM DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15611,
      "TXT_FINANCIER_NAME": "BANASKANTHA MER.CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15612,
      "TXT_FINANCIER_NAME": "LOKVIAKS NAGARI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15613,
      "TXT_FINANCIER_NAME": "CHANGANACHERRY NORTH SER.CO.OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 15614,
      "TXT_FINANCIER_NAME": "GAUTAM SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15615,
      "TXT_FINANCIER_NAME": "THE JANALAXMI CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15616,
      "TXT_FINANCIER_NAME": "THE JANALAXMI CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15617,
      "TXT_FINANCIER_NAME": "KARUR-VYSYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 15618,
      "TXT_FINANCIER_NAME": "KARUR-VYSYA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15619,
      "TXT_FINANCIER_NAME": "MAHESH URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15620,
      "TXT_FINANCIER_NAME": "SHREE BASAVESHWAR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15621,
      "TXT_FINANCIER_NAME": "THE THRISSUR DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15622,
      "TXT_FINANCIER_NAME": "HINDUSTAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15623,
      "TXT_FINANCIER_NAME": "THE LORD KRISHNA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15624,
      "TXT_FINANCIER_NAME": "DEOGIRI NAGARI SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15625,
      "TXT_FINANCIER_NAME": "MAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 15626,
      "TXT_FINANCIER_NAME": "THE MAHILA VIKAS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15627,
      "TXT_FINANCIER_NAME": "THE A.P. VARDHAMAN (MAHILA) CO-OPERATIVA URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15628,
      "TXT_FINANCIER_NAME": "THE RAILWAY EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15629,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15630,
      "TXT_FINANCIER_NAME": "STATE TRADING CORP OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 15631,
      "TXT_FINANCIER_NAME": "GMAC FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15632,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINACIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15633,
      "TXT_FINANCIER_NAME": "THE AMRITSAR CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15634,
      "TXT_FINANCIER_NAME": "ARJUN URBAN CO-OP BANK LTD,SOLAPUR."
    },
    {
      "NUM_FINANCIER_CD": 15635,
      "TXT_FINANCIER_NAME": "NAGRIK SAHAKARI BANK MARYADIT GWALIOR"
    },
    {
      "NUM_FINANCIER_CD": 15636,
      "TXT_FINANCIER_NAME": "THE CHANDGAD URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15637,
      "TXT_FINANCIER_NAME": "MALABAR FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 15638,
      "TXT_FINANCIER_NAME": "BASIST LEASING & FINANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15639,
      "TXT_FINANCIER_NAME": "BEERU BROTHERS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15640,
      "TXT_FINANCIER_NAME": "BELL LEASING & HIRE PURCHASE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15641,
      "TXT_FINANCIER_NAME": "BERAR FINANCE LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 15642,
      "TXT_FINANCIER_NAME": "BHADANA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15643,
      "TXT_FINANCIER_NAME": "BHAGWAN HIRE PURCHASE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15644,
      "TXT_FINANCIER_NAME": "BHAGWAN INSTALMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15645,
      "TXT_FINANCIER_NAME": "BHASKAR NARAYAN FINANCE CO. (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15646,
      "TXT_FINANCIER_NAME": "BIKANER COMMERCIAL CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 15647,
      "TXT_FINANCIER_NAME": "BINDIYA CAPITAL & SECURITIES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15648,
      "TXT_FINANCIER_NAME": "BIRLA GLOBAL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15649,
      "TXT_FINANCIER_NAME": "BLACK DIAMOND FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15650,
      "TXT_FINANCIER_NAME": "BLUE CHIP FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15651,
      "TXT_FINANCIER_NAME": "BLUE JAY FINLEASE (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15652,
      "TXT_FINANCIER_NAME": "BLUE LINE FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15653,
      "TXT_FINANCIER_NAME": "BOOH FINANCE CO. PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15654,
      "TXT_FINANCIER_NAME": "BRIJENDRA HIRE PURCHASE & FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15655,
      "TXT_FINANCIER_NAME": "BUSYBEE FINANCE & INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15656,
      "TXT_FINANCIER_NAME": "CALOREX INVESTMENT & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15657,
      "TXT_FINANCIER_NAME": "CANBANK FACTORS LTD NO."
    },
    {
      "NUM_FINANCIER_CD": 15658,
      "TXT_FINANCIER_NAME": "CAPITAL FORTUNES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15659,
      "TXT_FINANCIER_NAME": "CARTHIC CREDITS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15660,
      "TXT_FINANCIER_NAME": "CEEJAY FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15661,
      "TXT_FINANCIER_NAME": "CHAMBERS EQUITY & FINANCIAL CO.LTD."
    },
    {
      "NUM_FINANCIER_CD": 15662,
      "TXT_FINANCIER_NAME": "CHATHA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15663,
      "TXT_FINANCIER_NAME": "CHATHA FINANCIERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15664,
      "TXT_FINANCIER_NAME": "CHHAJER FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15665,
      "TXT_FINANCIER_NAME": "CHINMAY FINLEASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15666,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM INVESTMENT & FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 15667,
      "TXT_FINANCIER_NAME": "CHPL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15668,
      "TXT_FINANCIER_NAME": "CHUKKAPALLI EQUIPMENT LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15669,
      "TXT_FINANCIER_NAME": "CONCORD MARKETING & FINANCIERS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15670,
      "TXT_FINANCIER_NAME": "CRYSTAL CAPITAL CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 15671,
      "TXT_FINANCIER_NAME": "CRYSTAL CREDITS CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 15672,
      "TXT_FINANCIER_NAME": "D H P LEASING PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15673,
      "TXT_FINANCIER_NAME": "D S BRAR FINANCE CO. PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15674,
      "TXT_FINANCIER_NAME": "DADADEV FINANCE & LEASING PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15675,
      "TXT_FINANCIER_NAME": "DAKSUM FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15676,
      "TXT_FINANCIER_NAME": "DANI CAPITAL AND INVESTMENTS COMPANY P LTD."
    },
    {
      "NUM_FINANCIER_CD": 15677,
      "TXT_FINANCIER_NAME": "DANI FINANCE AND INVESTMENTS COMPANY P LTD."
    },
    {
      "NUM_FINANCIER_CD": 15678,
      "TXT_FINANCIER_NAME": "DANI FINLEASE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15679,
      "TXT_FINANCIER_NAME": "DANI HOLDING AND TRADING COMPANY P LTD."
    },
    {
      "NUM_FINANCIER_CD": 15680,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FINANCIAL SERVICES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15681,
      "TXT_FINANCIER_NAME": "KRISHAK BHARTI COPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15682,
      "TXT_FINANCIER_NAME": "KRISHAK BHARTI COOPERATIVE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15683,
      "TXT_FINANCIER_NAME": "TAMILNAD MERCANTILE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15684,
      "TXT_FINANCIER_NAME": "THE UNION COMMERCIAL CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15685,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15686,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD & PNB & ALL CONSORTIUM MEMBER BANKS"
    },
    {
      "NUM_FINANCIER_CD": 15687,
      "TXT_FINANCIER_NAME": "UNITED CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15688,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FIN SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15689,
      "TXT_FINANCIER_NAME": "SHRI VINAYAK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15690,
      "TXT_FINANCIER_NAME": "SHRI SWAMI SAMARTH B.S.S.P.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 15691,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPLE S CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15692,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FIN.SER. (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15693,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15694,
      "TXT_FINANCIER_NAME": "GHAZIABAD URBAN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15695,
      "TXT_FINANCIER_NAME": "JANTA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15696,
      "TXT_FINANCIER_NAME": "JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15697,
      "TXT_FINANCIER_NAME": "GHAZIABAD URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15698,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15699,
      "TXT_FINANCIER_NAME": "UNITED AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 15700,
      "TXT_FINANCIER_NAME": "THE JAMNAGAR MAHILA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15701,
      "TXT_FINANCIER_NAME": "THE JAMNAGAR MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17251,
      "TXT_FINANCIER_NAME": "SHIRALA TALUKA URBAN CO-OP BANK LTD,SHIRALA"
    },
    {
      "NUM_FINANCIER_CD": 17252,
      "TXT_FINANCIER_NAME": "SHRI BALBHIM CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17253,
      "TXT_FINANCIER_NAME": "CITIZEN CREDIT COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17254,
      "TXT_FINANCIER_NAME": "THE SACHIN INDUSTRIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17255,
      "TXT_FINANCIER_NAME": "THE SACHIN INDUSTRIAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17256,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR NAGRIC SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17257,
      "TXT_FINANCIER_NAME": "SADGURU NAGRIK SAHKARI MYDT."
    },
    {
      "NUM_FINANCIER_CD": 17258,
      "TXT_FINANCIER_NAME": "THE JAIPUR CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17259,
      "TXT_FINANCIER_NAME": "JANKALYAN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17260,
      "TXT_FINANCIER_NAME": "Mahindra & Mahindra Finance Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17261,
      "TXT_FINANCIER_NAME": "THE BAVLA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17262,
      "TXT_FINANCIER_NAME": "THE KARAD JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17263,
      "TXT_FINANCIER_NAME": "KARAD URBAN CO-OP BANK LTD,KARAD"
    },
    {
      "NUM_FINANCIER_CD": 17264,
      "TXT_FINANCIER_NAME": "NAVJIVAN NAGARI SAHAKARI BANK MARYADIT,PUNE"
    },
    {
      "NUM_FINANCIER_CD": 17265,
      "TXT_FINANCIER_NAME": "THE GOA STATE CO-OP BANLK"
    },
    {
      "NUM_FINANCIER_CD": 17266,
      "TXT_FINANCIER_NAME": "KARNALA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17267,
      "TXT_FINANCIER_NAME": "INDORE SWAYAMSIDDH MAHILA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17268,
      "TXT_FINANCIER_NAME": "ING VYSYA"
    },
    {
      "NUM_FINANCIER_CD": 17269,
      "TXT_FINANCIER_NAME": "ICHALKARANJI MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17270,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17271,
      "TXT_FINANCIER_NAME": "JANATHA SEVA CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17272,
      "TXT_FINANCIER_NAME": "MAHINDRA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17273,
      "TXT_FINANCIER_NAME": "SHREE BHARAT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17274,
      "TXT_FINANCIER_NAME": "THE NAVODAYA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17275,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17276,
      "TXT_FINANCIER_NAME": "GUJARAT NARMADA VALLEY FERTILIZER CO:"
    },
    {
      "NUM_FINANCIER_CD": 17277,
      "TXT_FINANCIER_NAME": "THE VADGAON URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17278,
      "TXT_FINANCIER_NAME": "DEVIKA URBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17279,
      "TXT_FINANCIER_NAME": "ADITYA NAGARI SAHAKARI PATHSANSTHA MRDT"
    },
    {
      "NUM_FINANCIER_CD": 17280,
      "TXT_FINANCIER_NAME": "INDIAN OVERSEAS BANK, JUHU"
    },
    {
      "NUM_FINANCIER_CD": 17281,
      "TXT_FINANCIER_NAME": "STATE TRANSPORT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17282,
      "TXT_FINANCIER_NAME": "SHUSHRUTI SOUHADRA SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 17283,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17284,
      "TXT_FINANCIER_NAME": "JAYSINGPUR UDGAON SAHAKARI BANK LTD, JAYSINGPUR"
    },
    {
      "NUM_FINANCIER_CD": 17285,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17286,
      "TXT_FINANCIER_NAME": "THE CHEMBUR NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17287,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO -OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 17288,
      "TXT_FINANCIER_NAME": "THE MALEGAON MERCHANTS CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17289,
      "TXT_FINANCIER_NAME": "THE KODUNGALLUR TOWN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17290,
      "TXT_FINANCIER_NAME": "MAHENDRA AND MAHENDRA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17291,
      "TXT_FINANCIER_NAME": "BHARAT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17292,
      "TXT_FINANCIER_NAME": "WARDHAMAN URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17293,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRY WIDE AUTO FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17294,
      "TXT_FINANCIER_NAME": "NEW AGRA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17295,
      "TXT_FINANCIER_NAME": "THE SHOLAPUR DISTRICT CENTRAL CO-OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 17296,
      "TXT_FINANCIER_NAME": "ING-VYSYA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17297,
      "TXT_FINANCIER_NAME": "CANDOLIM URBAN CO-OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17298,
      "TXT_FINANCIER_NAME": "Innovative Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17299,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGRIK CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17300,
      "TXT_FINANCIER_NAME": "AKOLA JANTA URBAN CO. OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17301,
      "TXT_FINANCIER_NAME": "STATE BANK OF TRABANCORE"
    },
    {
      "NUM_FINANCIER_CD": 17302,
      "TXT_FINANCIER_NAME": "AMAN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17303,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE COOPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17304,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17305,
      "TXT_FINANCIER_NAME": "SAMARTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17306,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17307,
      "TXT_FINANCIER_NAME": "CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 17308,
      "TXT_FINANCIER_NAME": "Birbhum District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17309,
      "TXT_FINANCIER_NAME": "Burdwan District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17310,
      "TXT_FINANCIER_NAME": "Darjeeling District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17311,
      "TXT_FINANCIER_NAME": "Hooghly District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17312,
      "TXT_FINANCIER_NAME": "Howrah District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17313,
      "TXT_FINANCIER_NAME": "Jalpaiguri District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17314,
      "TXT_FINANCIER_NAME": "Malda District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17315,
      "TXT_FINANCIER_NAME": "Mugberia District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17316,
      "TXT_FINANCIER_NAME": "Nadia District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17317,
      "TXT_FINANCIER_NAME": "Purulia District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17318,
      "TXT_FINANCIER_NAME": "The Bank Employees Cooperative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17319,
      "TXT_FINANCIER_NAME": "Adilabad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17320,
      "TXT_FINANCIER_NAME": "Anantpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17321,
      "TXT_FINANCIER_NAME": "Chittoor District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17322,
      "TXT_FINANCIER_NAME": "Eluru District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17323,
      "TXT_FINANCIER_NAME": "Guntur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17324,
      "TXT_FINANCIER_NAME": "Kurnool District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17325,
      "TXT_FINANCIER_NAME": "Mehbubnagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17326,
      "TXT_FINANCIER_NAME": "Nellore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17327,
      "TXT_FINANCIER_NAME": "Prakasam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17328,
      "TXT_FINANCIER_NAME": "Srikakulam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17329,
      "TXT_FINANCIER_NAME": "Warangal District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17330,
      "TXT_FINANCIER_NAME": "Bangalore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17331,
      "TXT_FINANCIER_NAME": "Belgaum District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17332,
      "TXT_FINANCIER_NAME": "Bellary District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17333,
      "TXT_FINANCIER_NAME": "Bidar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17334,
      "TXT_FINANCIER_NAME": "Bijapur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17335,
      "TXT_FINANCIER_NAME": "Chickamagalur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17336,
      "TXT_FINANCIER_NAME": "Chitradurga District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17337,
      "TXT_FINANCIER_NAME": "Gulbarga District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17338,
      "TXT_FINANCIER_NAME": "Karnatak District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17339,
      "TXT_FINANCIER_NAME": "Kodagu District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17340,
      "TXT_FINANCIER_NAME": "Kolar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 12606,
      "TXT_FINANCIER_NAME": "MUKTAI GRAMIN BIGAR SHETI SAH PAT"
    },
    {
      "NUM_FINANCIER_CD": 12607,
      "TXT_FINANCIER_NAME": "THE KERI URBAN CO OPERATIVE CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12608,
      "TXT_FINANCIER_NAME": "DEVIKULAM TALUK CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12609,
      "TXT_FINANCIER_NAME": "G BHANE CHAND NAHAR AND SONS FIN"
    },
    {
      "NUM_FINANCIER_CD": 12610,
      "TXT_FINANCIER_NAME": "G C FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12611,
      "TXT_FINANCIER_NAME": "DMK JAWALI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12612,
      "TXT_FINANCIER_NAME": "HP FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12613,
      "TXT_FINANCIER_NAME": "J S FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12614,
      "TXT_FINANCIER_NAME": "DEV FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12615,
      "TXT_FINANCIER_NAME": "SURABHI NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12616,
      "TXT_FINANCIER_NAME": "THE SHUBH LABH CO OP CRE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12617,
      "TXT_FINANCIER_NAME": "SHREE GANESH PARASPER SAHAKARI PATAPEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12618,
      "TXT_FINANCIER_NAME": "THE JANATHA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12619,
      "TXT_FINANCIER_NAME": "J AND K BANK"
    },
    {
      "NUM_FINANCIER_CD": 12620,
      "TXT_FINANCIER_NAME": "BHEDSHI URBAN CO OP CREDIT SOCEITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12621,
      "TXT_FINANCIER_NAME": "CHHATTISGARH NISHAKTJAN VITT AND FIN NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 12622,
      "TXT_FINANCIER_NAME": "JAY HANUMAN NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12623,
      "TXT_FINANCIER_NAME": "AJMER CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12624,
      "TXT_FINANCIER_NAME": "PRAGJYOTISH HIER PURCHASE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12625,
      "TXT_FINANCIER_NAME": "BOTHRA FINSTOCK PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12626,
      "TXT_FINANCIER_NAME": "ANDAVAR GROUP FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12627,
      "TXT_FINANCIER_NAME": "RAJA FINLEASE"
    },
    {
      "NUM_FINANCIER_CD": 12628,
      "TXT_FINANCIER_NAME": "SAPTHARSHI SOUHARDHA PATTHINA SAHAKARA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12629,
      "TXT_FINANCIER_NAME": "SAKTHI ABIRAMI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12630,
      "TXT_FINANCIER_NAME": "THE CALICUT TOWN SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12631,
      "TXT_FINANCIER_NAME": "HEMALATHA CHHALLANI"
    },
    {
      "NUM_FINANCIER_CD": 12632,
      "TXT_FINANCIER_NAME": "MUMBAI TAXI ASS CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12633,
      "TXT_FINANCIER_NAME": "SHRI RATNAPPA KUMBHAR NAGAR NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12634,
      "TXT_FINANCIER_NAME": "SUNDERLAL SAWJI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12635,
      "TXT_FINANCIER_NAME": "MALAYALA MANORAMA CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12636,
      "TXT_FINANCIER_NAME": "THE VADAYAR SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12637,
      "TXT_FINANCIER_NAME": "RESHMI HIRE PURCHASE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12638,
      "TXT_FINANCIER_NAME": "SHRI MARIKAMBA MAHILA CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12639,
      "TXT_FINANCIER_NAME": "AJIT MOTORS HIRE PURCHASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12640,
      "TXT_FINANCIER_NAME": "MAHILA VIKAS SAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12641,
      "TXT_FINANCIER_NAME": "VETTIKAVALA SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12642,
      "TXT_FINANCIER_NAME": "SUN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12643,
      "TXT_FINANCIER_NAME": "FOSROC CHEMICALS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12644,
      "TXT_FINANCIER_NAME": "C G STATE MANDI BOARD"
    },
    {
      "NUM_FINANCIER_CD": 12645,
      "TXT_FINANCIER_NAME": "DIAMOND FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 12646,
      "TXT_FINANCIER_NAME": "SRI NANDI PATHINA CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12647,
      "TXT_FINANCIER_NAME": "PUNCHA SAMABAY KRISHI UNNAYAN SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12648,
      "TXT_FINANCIER_NAME": "UDUPI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12649,
      "TXT_FINANCIER_NAME": "MAPAL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12650,
      "TXT_FINANCIER_NAME": "CHANDRASHEKHAR GARMIN BIGAR SHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12651,
      "TXT_FINANCIER_NAME": "SIDDHIRAJNATH GRAMIN BIGER SHETI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12652,
      "TXT_FINANCIER_NAME": "BHARAT ALUMINIUM COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12653,
      "TXT_FINANCIER_NAME": "SHRI SAMARTH BHANDARI NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12654,
      "TXT_FINANCIER_NAME": "SAHYOG URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12655,
      "TXT_FINANCIER_NAME": "THE RADHU MERCANTILE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12656,
      "TXT_FINANCIER_NAME": "AISHWARYA MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12657,
      "TXT_FINANCIER_NAME": "AMARJEET SINGH ROOP SINGH CHAWLA"
    },
    {
      "NUM_FINANCIER_CD": 12658,
      "TXT_FINANCIER_NAME": "NATIONAL COUNCIL FOR SCIENCE MUSEUM"
    },
    {
      "NUM_FINANCIER_CD": 12659,
      "TXT_FINANCIER_NAME": "MAHATMA GANDHI URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12660,
      "TXT_FINANCIER_NAME": "SHRI AGRASEN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12661,
      "TXT_FINANCIER_NAME": "UDUPI TALUK PARISHISTA VARGAGALA DODDA PARAMANADA VIVIDHODDESHA SAHAKARA SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12662,
      "TXT_FINANCIER_NAME": "RAMCO GLOBAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12663,
      "TXT_FINANCIER_NAME": "SHRAMIK RAMDAS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12664,
      "TXT_FINANCIER_NAME": "KUSHAL FINANCIERS INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 12665,
      "TXT_FINANCIER_NAME": "GAJANAND FINANCE AND INVESTMENT P LTD"
    },
    {
      "NUM_FINANCIER_CD": 12666,
      "TXT_FINANCIER_NAME": "MAHAVEER GRMIN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12667,
      "TXT_FINANCIER_NAME": "SHRI GAYATRI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12668,
      "TXT_FINANCIER_NAME": "HIMMATSINGKA MOTOR WORKS LTD"
    },
    {
      "NUM_FINANCIER_CD": 12669,
      "TXT_FINANCIER_NAME": "SAI PRERANA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12670,
      "TXT_FINANCIER_NAME": "VISHWACHETAN CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12671,
      "TXT_FINANCIER_NAME": "ADITYA AND CO"
    },
    {
      "NUM_FINANCIER_CD": 12672,
      "TXT_FINANCIER_NAME": "LENYADRI SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12673,
      "TXT_FINANCIER_NAME": "NAVCHAITANYA NAGARI SAHKARI PATH SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12674,
      "TXT_FINANCIER_NAME": "SHRI YASHODHAN NAGARI SAHKARI PATH SANSHTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12675,
      "TXT_FINANCIER_NAME": "CHATRAPATI NAGARI SAHKARI PATH SANSHTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12676,
      "TXT_FINANCIER_NAME": "BRAMHADAS GRAMIN BIG SHETI SAH PATH SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12677,
      "TXT_FINANCIER_NAME": "SMART AUTO LINKS"
    },
    {
      "NUM_FINANCIER_CD": 12678,
      "TXT_FINANCIER_NAME": "SAHA INSTITUTE OF NUCLEAR PHYSICS"
    },
    {
      "NUM_FINANCIER_CD": 12679,
      "TXT_FINANCIER_NAME": "SRINIVASAPURAM PACS"
    },
    {
      "NUM_FINANCIER_CD": 12680,
      "TXT_FINANCIER_NAME": "VISHVAPRABHA NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12681,
      "TXT_FINANCIER_NAME": "DNYANSAGAR NAGARI SAHAKARI P LTD"
    },
    {
      "NUM_FINANCIER_CD": 12682,
      "TXT_FINANCIER_NAME": "VIDARBH KONKAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 12683,
      "TXT_FINANCIER_NAME": "LOKAKARIA MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12684,
      "TXT_FINANCIER_NAME": "TRIBAL MULTIPURPOSE CO OP CRD SCTY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12685,
      "TXT_FINANCIER_NAME": "SAVITHA SAUHARDA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 12686,
      "TXT_FINANCIER_NAME": "SHRI GRAHIT NAGARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12687,
      "TXT_FINANCIER_NAME": "KARNATAKA RAJYA VIDHYUTH GUTHIGEDARARA SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12688,
      "TXT_FINANCIER_NAME": "SHREE SHIVAYOGI CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12689,
      "TXT_FINANCIER_NAME": "SHREE VENKTESH NAG SAH PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 12690,
      "TXT_FINANCIER_NAME": "HANI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12691,
      "TXT_FINANCIER_NAME": "JAI SHARADAMBA SOUHARDA CREDIT SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12692,
      "TXT_FINANCIER_NAME": "PARAG MILK SEVKANCHI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12693,
      "TXT_FINANCIER_NAME": "SHRI BALGANAPTI MULTIPURPOSE SOUHARD SAHAKARI NIYAMIT BELAGAVI"
    },
    {
      "NUM_FINANCIER_CD": 12694,
      "TXT_FINANCIER_NAME": "SHREE AVDHOOT NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12695,
      "TXT_FINANCIER_NAME": "UNITED SAKH SAHAKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12696,
      "TXT_FINANCIER_NAME": "NARIMOGARU PRIMARY AGRICULTURE CREDIT CO OP SOCIETY LTG"
    },
    {
      "NUM_FINANCIER_CD": 12697,
      "TXT_FINANCIER_NAME": "METRO MULTISTATE URBAN CO OPP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12698,
      "TXT_FINANCIER_NAME": "BRHAMLING MULTI PURPOSE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12699,
      "TXT_FINANCIER_NAME": "ASHOKRAO BHAU SHINDE GRAMIN BIGARSHETI SAHAKARI PATHASANSTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14102,
      "TXT_FINANCIER_NAME": "PRINCIPAL ACCOUNTANT GENERAL"
    },
    {
      "NUM_FINANCIER_CD": 14103,
      "TXT_FINANCIER_NAME": "GOPALKA CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14104,
      "TXT_FINANCIER_NAME": "SRI KALESHWAR PATTINA SAHAKAR SANGH"
    },
    {
      "NUM_FINANCIER_CD": 14105,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA CO OP CREDIT SPCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14106,
      "TXT_FINANCIER_NAME": "HATHUR PRIMARY AGRICULTURAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14107,
      "TXT_FINANCIER_NAME": "AMASEBAIL VYAVASAYA SEVA SAHAKARI SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14108,
      "TXT_FINANCIER_NAME": "SRI DURGA VYAVASAYA SEVA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 14109,
      "TXT_FINANCIER_NAME": "SHUBHAM NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14110,
      "TXT_FINANCIER_NAME": "SHRI MAHESHWARI SAHAKARI PEDI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14111,
      "TXT_FINANCIER_NAME": "GOWRI DEEPAM FINANCE AND INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 14112,
      "TXT_FINANCIER_NAME": "ARCHANA WO ANKIT GOYAL NEEMUCH"
    },
    {
      "NUM_FINANCIER_CD": 14113,
      "TXT_FINANCIER_NAME": "HALDHARI VIKAS CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14114,
      "TXT_FINANCIER_NAME": "ISRO SAC"
    },
    {
      "NUM_FINANCIER_CD": 14115,
      "TXT_FINANCIER_NAME": "SHAHIR ANNABHAU SATHE MAHAMANDAL"
    },
    {
      "NUM_FINANCIER_CD": 14116,
      "TXT_FINANCIER_NAME": "H L A INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14117,
      "TXT_FINANCIER_NAME": "THE NALLAJERLA LARGE SIZED CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14118,
      "TXT_FINANCIER_NAME": "MANGAON GRAMIN BIGARSHETI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14119,
      "TXT_FINANCIER_NAME": "SHRI KSHETHRA DHARMASTHALA RURAL DEVELOPMENT PROJECT UDUPI TALUK"
    },
    {
      "NUM_FINANCIER_CD": 14120,
      "TXT_FINANCIER_NAME": "SRI RAMA SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14121,
      "TXT_FINANCIER_NAME": "SRI KRISHAN CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14122,
      "TXT_FINANCIER_NAME": "DHANVANTARI GRAMIN BIGERSHETI SAH PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14123,
      "TXT_FINANCIER_NAME": "SHREE VIRDHAVAL JAGDALE SAH MOTAR VAHTUK SAN MARYA"
    },
    {
      "NUM_FINANCIER_CD": 14124,
      "TXT_FINANCIER_NAME": "BELVANDI VYAPARI GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14125,
      "TXT_FINANCIER_NAME": "SREE VENKATESWARA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14126,
      "TXT_FINANCIER_NAME": "RANZUNZAR CO OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 14127,
      "TXT_FINANCIER_NAME": "THE KAMAVARAPUKOTA LARGE SIZED CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14128,
      "TXT_FINANCIER_NAME": "KOLANCHERY HIRE PURCHASING"
    },
    {
      "NUM_FINANCIER_CD": 14129,
      "TXT_FINANCIER_NAME": "LAXMIDEVI GRAMIN BIGAR SHETI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14130,
      "TXT_FINANCIER_NAME": "RAMLINGESHWAR CRIDIT CO OP SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14131,
      "TXT_FINANCIER_NAME": "VIJAYASHRI MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14132,
      "TXT_FINANCIER_NAME": "THE KOMMUGUDEM LARGE SIZED CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14133,
      "TXT_FINANCIER_NAME": "CO OPERATIVE SOCIETY BANK"
    },
    {
      "NUM_FINANCIER_CD": 14134,
      "TXT_FINANCIER_NAME": "THE SRUNGAVRUKSHAM L S C S LTD"
    },
    {
      "NUM_FINANCIER_CD": 14135,
      "TXT_FINANCIER_NAME": "PADMASHREE SHAHEER SABALE SAH PATSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 14136,
      "TXT_FINANCIER_NAME": "BELLARY URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 14137,
      "TXT_FINANCIER_NAME": "THE CUNCOLIM URBAN COOP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14138,
      "TXT_FINANCIER_NAME": "THE PREMIER URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14139,
      "TXT_FINANCIER_NAME": "MAATRUKA MAC SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14140,
      "TXT_FINANCIER_NAME": "SWAMI SAMARTH MULTISTATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14141,
      "TXT_FINANCIER_NAME": "CARRIER MIDEA INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14142,
      "TXT_FINANCIER_NAME": "JANSAMRIDDHI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14143,
      "TXT_FINANCIER_NAME": "TS TRANSCO LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14144,
      "TXT_FINANCIER_NAME": "SHRI MAHALINGESHWAR CREDIT SOU SAHAKARI NY"
    },
    {
      "NUM_FINANCIER_CD": 14145,
      "TXT_FINANCIER_NAME": "SHRI PANCHACHARYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14146,
      "TXT_FINANCIER_NAME": "SRI GURUNARASIMHA VIVIDODDESHA SOWHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14147,
      "TXT_FINANCIER_NAME": "BELLARE PRARTHAMIKA KRISHI PATTINA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14148,
      "TXT_FINANCIER_NAME": "THE VALAPAD SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14149,
      "TXT_FINANCIER_NAME": "SHRI SARDAR PATEL CREDIT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14150,
      "TXT_FINANCIER_NAME": "DIRECTOR FOR ENSIC SCIENCES DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 14151,
      "TXT_FINANCIER_NAME": "SRI ASHTALAKSHMI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14152,
      "TXT_FINANCIER_NAME": "METHALA SERVICE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14153,
      "TXT_FINANCIER_NAME": "JANSEVA GRAMIN SHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14154,
      "TXT_FINANCIER_NAME": "THE VAGHESHWARI SAHKARI SARAFI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14155,
      "TXT_FINANCIER_NAME": "MULTI ARC INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14156,
      "TXT_FINANCIER_NAME": "SHRI PANDURANG GR BIG SHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14157,
      "TXT_FINANCIER_NAME": "NARAVI VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14158,
      "TXT_FINANCIER_NAME": "SHUBHAM ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 14159,
      "TXT_FINANCIER_NAME": "SHRI KULPATI SACHIV SHRI HEMCHANDRACHARYA UTTAR GUJARAT UNIVERCITY"
    },
    {
      "NUM_FINANCIER_CD": 14160,
      "TXT_FINANCIER_NAME": "RAIGAD JANATA NAGRI SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14161,
      "TXT_FINANCIER_NAME": "RAVINDERA MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14162,
      "TXT_FINANCIER_NAME": "VAIBHAV FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 14163,
      "TXT_FINANCIER_NAME": "PALANI ANDAVAR SWAMY AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14164,
      "TXT_FINANCIER_NAME": "THE GURAVAIGUDEM PRIMARY AGRICULTURE CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14165,
      "TXT_FINANCIER_NAME": "SRI SEETARAMA SWAMY VARI LARGE SIZE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14166,
      "TXT_FINANCIER_NAME": "KANAVARAM LARGE SIZED COOPERATIVE CREATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14167,
      "TXT_FINANCIER_NAME": "THE KANSA PEOPLES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14168,
      "TXT_FINANCIER_NAME": "NEELADRI PATTINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14169,
      "TXT_FINANCIER_NAME": "SHREE SADANAND SOUHARDA PATTINA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14170,
      "TXT_FINANCIER_NAME": "CHIRUMBA BHAGAVATHI CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14171,
      "TXT_FINANCIER_NAME": "SRI DHARMASTHALA MANJUNATHA PATTINA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 14172,
      "TXT_FINANCIER_NAME": "SHRI VIRUPAKSHESHWARA PATTINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14173,
      "TXT_FINANCIER_NAME": "NAYAN NAGRI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14174,
      "TXT_FINANCIER_NAME": "SHIVSPHURTI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14175,
      "TXT_FINANCIER_NAME": "KACHHARA FINANCE AND AUTO DEAL"
    },
    {
      "NUM_FINANCIER_CD": 14176,
      "TXT_FINANCIER_NAME": "TELANGANA STATE POWER GENERATION CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14177,
      "TXT_FINANCIER_NAME": "TELANGANA STSTE POWER GENERATION CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14178,
      "TXT_FINANCIER_NAME": "MOHADI BUDRUK VIVIDH KARYAKARI SAHAKARI SEVA SANSTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14179,
      "TXT_FINANCIER_NAME": "BANNANJE VIVIDODDESHA SAHAKARI MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14180,
      "TXT_FINANCIER_NAME": "ARORA FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 14181,
      "TXT_FINANCIER_NAME": "MANINALKOOR SEVA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 14182,
      "TXT_FINANCIER_NAME": "MRS NEETA GOYAL NEEMACH"
    },
    {
      "NUM_FINANCIER_CD": 14183,
      "TXT_FINANCIER_NAME": "NAREBAIL GROUP GRAMAGALA SEVA SAHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 14184,
      "TXT_FINANCIER_NAME": "BARODA GRAHMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14185,
      "TXT_FINANCIER_NAME": "UNIVERSITY OF MADRAS"
    },
    {
      "NUM_FINANCIER_CD": 14186,
      "TXT_FINANCIER_NAME": "THE INCOME TAX DEPT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14187,
      "TXT_FINANCIER_NAME": "SHRI RAMRAO AHER NAGARI SAHKARI PATSANSTHA MARYADIR DEOLA"
    },
    {
      "NUM_FINANCIER_CD": 14188,
      "TXT_FINANCIER_NAME": "RANDHAWA HIRE PURCHASE FINANCE CORPN"
    },
    {
      "NUM_FINANCIER_CD": 14189,
      "TXT_FINANCIER_NAME": "SANSKAR URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14190,
      "TXT_FINANCIER_NAME": "SHRI LAXMI MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14191,
      "TXT_FINANCIER_NAME": "VIKRAM FINANCES AND INVESTEMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15702,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15703,
      "TXT_FINANCIER_NAME": "GANESH BANK OF KURUNDWAD"
    },
    {
      "NUM_FINANCIER_CD": 15704,
      "TXT_FINANCIER_NAME": "Indore Cloth Market Co-Op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 15705,
      "TXT_FINANCIER_NAME": "RATNAKAR BANK LTD.MAIN BR.SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 15706,
      "TXT_FINANCIER_NAME": "MAHINDRA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15707,
      "TXT_FINANCIER_NAME": "DCB DEVELOPMENT CREDIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 15708,
      "TXT_FINANCIER_NAME": "RAJKOT COMMERCIAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15709,
      "TXT_FINANCIER_NAME": "NANDED DISTRICT CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15710,
      "TXT_FINANCIER_NAME": "GMAC TCFC FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15711,
      "TXT_FINANCIER_NAME": "SHREE RAMESHWAR NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 15712,
      "TXT_FINANCIER_NAME": "KEDARNATH URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15713,
      "TXT_FINANCIER_NAME": "THE VERAVAL PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15714,
      "TXT_FINANCIER_NAME": "THE RAJAJINAGAR CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15715,
      "TXT_FINANCIER_NAME": "THE CATHOLIC SYRIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15716,
      "TXT_FINANCIER_NAME": "THE DELHI STATE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15717,
      "TXT_FINANCIER_NAME": "SARASWAT CO-OP BANK LTD,KANKAVLI"
    },
    {
      "NUM_FINANCIER_CD": 15718,
      "TXT_FINANCIER_NAME": "THE UMRETH URBAN CO-OPERATIVE BANK LTD. UMRETH"
    },
    {
      "NUM_FINANCIER_CD": 15719,
      "TXT_FINANCIER_NAME": "THE UMRETH URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15720,
      "TXT_FINANCIER_NAME": "SHREE SHARADA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15721,
      "TXT_FINANCIER_NAME": "DURG RAJNANDGAON GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15722,
      "TXT_FINANCIER_NAME": "THE SANKHEDA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15723,
      "TXT_FINANCIER_NAME": "bombay mercantile co operative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 15724,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15725,
      "TXT_FINANCIER_NAME": "RAJASHRI SHAHU SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15726,
      "TXT_FINANCIER_NAME": "Indore Premier Co-Op. Bank Ltd., Indore"
    },
    {
      "NUM_FINANCIER_CD": 15727,
      "TXT_FINANCIER_NAME": "SANGOLA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15728,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15729,
      "TXT_FINANCIER_NAME": "GODAVARI LAXMI CO-OP BANK LTD.JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 15730,
      "TXT_FINANCIER_NAME": "THE KHATTRI CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15731,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15732,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15733,
      "TXT_FINANCIER_NAME": "GHIYA MAMODIYA FIN& INVESTMENT PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 15734,
      "TXT_FINANCIER_NAME": "THE ACCOUNTANT GENERALS OFFICE EMPLOYEES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15735,
      "TXT_FINANCIER_NAME": "AIR CORPORATIONS EMPLOYEES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15736,
      "TXT_FINANCIER_NAME": "THE CITIZENS COOOERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15737,
      "TXT_FINANCIER_NAME": "THE CITIZEN COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15738,
      "TXT_FINANCIER_NAME": "B N P PARIBAS"
    },
    {
      "NUM_FINANCIER_CD": 15739,
      "TXT_FINANCIER_NAME": "SEVEN HILLS CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15740,
      "TXT_FINANCIER_NAME": "SHREE VARDHAMAN SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15741,
      "TXT_FINANCIER_NAME": "DEVELOPMENT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15742,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15743,
      "TXT_FINANCIER_NAME": "SEVEN HILLS CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15744,
      "TXT_FINANCIER_NAME": "RAJADHANI CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15745,
      "TXT_FINANCIER_NAME": "SWAMI SAMARTH SAHAKARI BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 15746,
      "TXT_FINANCIER_NAME": "SRI GAYATRI CO-OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15747,
      "TXT_FINANCIER_NAME": "MAHESH URBAN CO-OPBANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 15748,
      "TXT_FINANCIER_NAME": "THE PRESIDENT OF INDIA, GOVT. OF INDIA, DOS,ISRO SDSC"
    },
    {
      "NUM_FINANCIER_CD": 15749,
      "TXT_FINANCIER_NAME": "SREE CHARAN SOUHARDA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15750,
      "TXT_FINANCIER_NAME": "THE KHERALU NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15751,
      "TXT_FINANCIER_NAME": "SOCIETE GENERALE, A Scheduled Commercial Bank"
    },
    {
      "NUM_FINANCIER_CD": 15752,
      "TXT_FINANCIER_NAME": "ING VYSYA BANL LTD"
    },
    {
      "NUM_FINANCIER_CD": 15753,
      "TXT_FINANCIER_NAME": "SREE CHARAN SOUHARDA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15754,
      "TXT_FINANCIER_NAME": "PUNE PEOPLES COOP"
    },
    {
      "NUM_FINANCIER_CD": 15755,
      "TXT_FINANCIER_NAME": "THE JALGAON DISTRICT CENTRAL CO OPP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15756,
      "TXT_FINANCIER_NAME": "THE AMRAVATI MERCHANTS CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 15757,
      "TXT_FINANCIER_NAME": "VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15758,
      "TXT_FINANCIER_NAME": "AMMAPET URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15759,
      "TXT_FINANCIER_NAME": "NILKANTH URBAN CO-OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 15760,
      "TXT_FINANCIER_NAME": "IndusInd Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 15761,
      "TXT_FINANCIER_NAME": "SHRI BHARAT URBAN CO-OP BANK LTD,JAYSINGPUR"
    },
    {
      "NUM_FINANCIER_CD": 15762,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OP. BANK LTD.MEHSANA(N.G)"
    },
    {
      "NUM_FINANCIER_CD": 15763,
      "TXT_FINANCIER_NAME": "UNITED INDIA INSURANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15764,
      "TXT_FINANCIER_NAME": "DEWAS SHAJAPUR SHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15765,
      "TXT_FINANCIER_NAME": "SARADHA FINANCIAL"
    },
    {
      "NUM_FINANCIER_CD": 15766,
      "TXT_FINANCIER_NAME": "DHARAMVIR SAMBHAJI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15767,
      "TXT_FINANCIER_NAME": "DCL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15768,
      "TXT_FINANCIER_NAME": "DECCAN FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15769,
      "TXT_FINANCIER_NAME": "DECCAN LEAFIN SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15770,
      "TXT_FINANCIER_NAME": "DEEP DHALLEKE AUTO FINANCIERS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15771,
      "TXT_FINANCIER_NAME": "DEEP FINCAP PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15772,
      "TXT_FINANCIER_NAME": "DEEP MOTOR FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15773,
      "TXT_FINANCIER_NAME": "DEEPIKA LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15774,
      "TXT_FINANCIER_NAME": "DELHI PUNJAB FINANCE CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15775,
      "TXT_FINANCIER_NAME": "DELUXE LEASING PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15776,
      "TXT_FINANCIER_NAME": "DEVIKA MOTOR FINANCE (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15777,
      "TXT_FINANCIER_NAME": "DEWAN MOTORS INVESTMENT & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15778,
      "TXT_FINANCIER_NAME": "DFL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15779,
      "TXT_FINANCIER_NAME": "DHANALAXMI MOTORS FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 15780,
      "TXT_FINANCIER_NAME": "DHANDAPANI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15781,
      "TXT_FINANCIER_NAME": "DHARANI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15782,
      "TXT_FINANCIER_NAME": "DIAMOND FINCAP PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15783,
      "TXT_FINANCIER_NAME": "DIGANTA INVESTMENTS & FINANCE CO. PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15784,
      "TXT_FINANCIER_NAME": "DIGNITY MOTOR FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15785,
      "TXT_FINANCIER_NAME": "DINESH COMMERCIAL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15786,
      "TXT_FINANCIER_NAME": "DIPRAN FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15787,
      "TXT_FINANCIER_NAME": "DOVE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15788,
      "TXT_FINANCIER_NAME": "ELCON FINLEASE AND INDUSTRIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15789,
      "TXT_FINANCIER_NAME": "ELECTRONICA LEASING & FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15790,
      "TXT_FINANCIER_NAME": "ENNOBLE LEASING ( INDIA) LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15791,
      "TXT_FINANCIER_NAME": "ENNOBLE INDIA SAVINGS & INVESTMENT CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15792,
      "TXT_FINANCIER_NAME": "ESCORTS FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15793,
      "TXT_FINANCIER_NAME": "Nagpur Nagrik Sahkari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 15794,
      "TXT_FINANCIER_NAME": "EWART INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15795,
      "TXT_FINANCIER_NAME": "FINCAP FINANCIAL CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15796,
      "TXT_FINANCIER_NAME": "FIRST LEASING COMPANY OF INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 15797,
      "TXT_FINANCIER_NAME": "FIVE STAR BUSINESS CREDITS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15798,
      "TXT_FINANCIER_NAME": "FORBES CAMPBELL HOLDINGS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15799,
      "TXT_FINANCIER_NAME": "FORTIS FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15800,
      "TXT_FINANCIER_NAME": "FORTRESS FINANCIAL SERVICES LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 17341,
      "TXT_FINANCIER_NAME": "Mandya District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17342,
      "TXT_FINANCIER_NAME": "Mysore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17343,
      "TXT_FINANCIER_NAME": "Raichur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17344,
      "TXT_FINANCIER_NAME": "Tumkur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17345,
      "TXT_FINANCIER_NAME": "Alappuzha District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17346,
      "TXT_FINANCIER_NAME": "Ernakulam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17347,
      "TXT_FINANCIER_NAME": "Kannur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17348,
      "TXT_FINANCIER_NAME": "Kasargod District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17349,
      "TXT_FINANCIER_NAME": "Kottayam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17350,
      "TXT_FINANCIER_NAME": "Kozhikode District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17351,
      "TXT_FINANCIER_NAME": "Palakkad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17352,
      "TXT_FINANCIER_NAME": "Pattanamthitta District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17353,
      "TXT_FINANCIER_NAME": "Trissur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17354,
      "TXT_FINANCIER_NAME": "Waynad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17355,
      "TXT_FINANCIER_NAME": "Coimbatore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17356,
      "TXT_FINANCIER_NAME": "Dharampuri District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17357,
      "TXT_FINANCIER_NAME": "Kanyakumari District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17358,
      "TXT_FINANCIER_NAME": "Nilgiris District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17359,
      "TXT_FINANCIER_NAME": "Salem District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17360,
      "TXT_FINANCIER_NAME": "Tiruchirapalli District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17361,
      "TXT_FINANCIER_NAME": "Tirunelveli District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17362,
      "TXT_FINANCIER_NAME": "Vellore District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17363,
      "TXT_FINANCIER_NAME": "Sivagangai District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17364,
      "TXT_FINANCIER_NAME": "Andaman and Nicobar Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17365,
      "TXT_FINANCIER_NAME": "SKYLARK LEASING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17366,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17367,
      "TXT_FINANCIER_NAME": "The Thane Janata Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17368,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE BANK OF BARODA LTD."
    },
    {
      "NUM_FINANCIER_CD": 17369,
      "TXT_FINANCIER_NAME": "ABN AMRO BANK N V"
    },
    {
      "NUM_FINANCIER_CD": 17370,
      "TXT_FINANCIER_NAME": "THE NAVNIRMAN CO OPERATIOVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17371,
      "TXT_FINANCIER_NAME": "NUCLEAR POWER CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17372,
      "TXT_FINANCIER_NAME": "INDIAN OIL CORPORATION LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17373,
      "TXT_FINANCIER_NAME": "INDIAN OIL CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 17374,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SERVICES PVT.LTD"
    },
    {
      "NUM_FINANCIER_CD": 17375,
      "TXT_FINANCIER_NAME": "UNITED MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17376,
      "TXT_FINANCIER_NAME": "The Maharashtra Mantralaya & Allied Offices Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17377,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17378,
      "TXT_FINANCIER_NAME": "Development Credti Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17379,
      "TXT_FINANCIER_NAME": "DEVELOPMENT CREDIT BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17380,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD PNB & OTHER MEMBER BANKS IN CONSORTIUM"
    },
    {
      "NUM_FINANCIER_CD": 17381,
      "TXT_FINANCIER_NAME": "KUBER CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17382,
      "TXT_FINANCIER_NAME": "KARAD URBAN CO-OP.BANK BR.KARAD"
    },
    {
      "NUM_FINANCIER_CD": 17383,
      "TXT_FINANCIER_NAME": "DEOGIRI NAGARI SAH.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17384,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17385,
      "TXT_FINANCIER_NAME": "INDORE UJJAIN KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17386,
      "TXT_FINANCIER_NAME": "ORIENTAL INSURANCE COMPANY LTD."
    },
    {
      "NUM_FINANCIER_CD": 17387,
      "TXT_FINANCIER_NAME": "Manipur State Co-Operative Bank,"
    },
    {
      "NUM_FINANCIER_CD": 17388,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALA PERDI PEOPLES CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17389,
      "TXT_FINANCIER_NAME": "LIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 17390,
      "TXT_FINANCIER_NAME": "JANTA SAHAKARI BANK LTD., SHIRUR"
    },
    {
      "NUM_FINANCIER_CD": 17391,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17392,
      "TXT_FINANCIER_NAME": "THE NAVNIRMAN CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17393,
      "TXT_FINANCIER_NAME": "BHOOMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 17394,
      "TXT_FINANCIER_NAME": "BARDOLI JANTA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17395,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DIST CO OPP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17396,
      "TXT_FINANCIER_NAME": "THE KALYAN JANATA SAHAKARI BANK LTD KALYAN"
    },
    {
      "NUM_FINANCIER_CD": 17397,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLEUM LTD"
    },
    {
      "NUM_FINANCIER_CD": 17398,
      "TXT_FINANCIER_NAME": "SIDDHARTH SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17399,
      "TXT_FINANCIER_NAME": "UNITED COMMERCIAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17400,
      "TXT_FINANCIER_NAME": "CIFCO"
    },
    {
      "NUM_FINANCIER_CD": 17401,
      "TXT_FINANCIER_NAME": "SHREE BHAVNAGAR MERCANTILE CO-OP. BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17402,
      "TXT_FINANCIER_NAME": "THE GUJARAT INDUSTRIAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17403,
      "TXT_FINANCIER_NAME": "THE VIJAPUR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17404,
      "TXT_FINANCIER_NAME": "THE CHERPALCHERI CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17405,
      "TXT_FINANCIER_NAME": "JAIN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17406,
      "TXT_FINANCIER_NAME": "THE UNION CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17407,
      "TXT_FINANCIER_NAME": "PUSAD URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17408,
      "TXT_FINANCIER_NAME": "ETAH URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17409,
      "TXT_FINANCIER_NAME": "NYAYAMITRA SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 17410,
      "TXT_FINANCIER_NAME": "Etah Urban Co-Operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 17411,
      "TXT_FINANCIER_NAME": "ANNASAHEB MAGAR SAHAKARI BANK MARYADIT, BHOSARI"
    },
    {
      "NUM_FINANCIER_CD": 17412,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK LTD,SALEM"
    },
    {
      "NUM_FINANCIER_CD": 17413,
      "TXT_FINANCIER_NAME": "SREI INTERNATION FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 17414,
      "TXT_FINANCIER_NAME": "APANI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17415,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17416,
      "TXT_FINANCIER_NAME": "The Swaraj Urban Co-op. Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 17417,
      "TXT_FINANCIER_NAME": "PRESIDENT OF INDIA BANK"
    },
    {
      "NUM_FINANCIER_CD": 17418,
      "TXT_FINANCIER_NAME": "THE PIMPALGAON MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17419,
      "TXT_FINANCIER_NAME": "WANA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17420,
      "TXT_FINANCIER_NAME": "The Vishweshwar Sahakari Bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 17421,
      "TXT_FINANCIER_NAME": "KRISHNA SAHAKARI BANK LTD RETHARE BUDRUK"
    },
    {
      "NUM_FINANCIER_CD": 17422,
      "TXT_FINANCIER_NAME": "PUNE SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17423,
      "TXT_FINANCIER_NAME": "THE JAMMU & KASHMIR BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17424,
      "TXT_FINANCIER_NAME": "THE AURANGABAD DIST.INDS.& URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17425,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17426,
      "TXT_FINANCIER_NAME": "THE NAVAL DOCKYARD CO-OP. BANK. LTD"
    },
    {
      "NUM_FINANCIER_CD": 17427,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPLES CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17428,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17429,
      "TXT_FINANCIER_NAME": "GUJARAT MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17430,
      "TXT_FINANCIER_NAME": "CANARA BANK SWAROOP NAGAR"
    },
    {
      "NUM_FINANCIER_CD": 17431,
      "TXT_FINANCIER_NAME": "SINDHUDURG SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17432,
      "TXT_FINANCIER_NAME": "SOUTH MALABAR GRAMMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17433,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA SRATE CO.OP.BABK LTD."
    },
    {
      "NUM_FINANCIER_CD": 12605,
      "TXT_FINANCIER_NAME": "ATHANI HIRE PURCHASING CO"
    },
    {
      "NUM_FINANCIER_CD": 12700,
      "TXT_FINANCIER_NAME": "TAREHALLI KANSUR SERVICE COOP SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12701,
      "TXT_FINANCIER_NAME": "VAIBHAV LEASING"
    },
    {
      "NUM_FINANCIER_CD": 12702,
      "TXT_FINANCIER_NAME": "JYOTHI PATTINA SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 12703,
      "TXT_FINANCIER_NAME": "DIPAK AABA SALUNKHE GRAMIN BIGAR SHETI PATH SANSHTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12704,
      "TXT_FINANCIER_NAME": "THE DIRECTOR FORENSIC SCIENCES DEPARTMENT"
    },
    {
      "NUM_FINANCIER_CD": 12705,
      "TXT_FINANCIER_NAME": "KENDRAPARA CREDIT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12706,
      "TXT_FINANCIER_NAME": "UNNATI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12707,
      "TXT_FINANCIER_NAME": "SWARNA GRAMEENA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12708,
      "TXT_FINANCIER_NAME": "SHRI GURUPOORNANANDA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12709,
      "TXT_FINANCIER_NAME": "THE CHARADA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12710,
      "TXT_FINANCIER_NAME": "M P E B KARMACHARI PRA SAHA SAKH ST"
    },
    {
      "NUM_FINANCIER_CD": 12711,
      "TXT_FINANCIER_NAME": "RAJTORAN NAGARI SAHAKARI CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12712,
      "TXT_FINANCIER_NAME": "A P PATIL SARVODAYA GRAMIN SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12713,
      "TXT_FINANCIER_NAME": "HLPC CORP"
    },
    {
      "NUM_FINANCIER_CD": 12714,
      "TXT_FINANCIER_NAME": "OFFICE OF DIRECTOR SECONDARY EDUCATION HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 12715,
      "TXT_FINANCIER_NAME": "MAHAKALI GRAMIN BIGER SHETI NAGRI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12716,
      "TXT_FINANCIER_NAME": "VIJAYA LAXMI FINANCE AND INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 12717,
      "TXT_FINANCIER_NAME": "NEMINATH NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12718,
      "TXT_FINANCIER_NAME": "RAJA BANK"
    },
    {
      "NUM_FINANCIER_CD": 12719,
      "TXT_FINANCIER_NAME": "DURGA MAHILA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12720,
      "TXT_FINANCIER_NAME": "BHEL CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12721,
      "TXT_FINANCIER_NAME": "PRATHAMIK KRISHI PATTIN SAH SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12722,
      "TXT_FINANCIER_NAME": "PUNACHA SEVA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12723,
      "TXT_FINANCIER_NAME": "J R FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12724,
      "TXT_FINANCIER_NAME": "MAMANI INVESTO PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12725,
      "TXT_FINANCIER_NAME": "INDRAYANI MAHILA GRAMIN BIGERSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12726,
      "TXT_FINANCIER_NAME": "BHARTIYAM MALTI STATE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12727,
      "TXT_FINANCIER_NAME": "ADARSH SEVA SHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12728,
      "TXT_FINANCIER_NAME": "THE VISHEWARA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12729,
      "TXT_FINANCIER_NAME": "NARIMOGARU VYAVASAYA SEVA SAHAKARI SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12730,
      "TXT_FINANCIER_NAME": "THE PISSURLEM URBAN CO OP CREDIT SOCIETY LIMITED HONDA SATTARI"
    },
    {
      "NUM_FINANCIER_CD": 12731,
      "TXT_FINANCIER_NAME": "TOWN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12732,
      "TXT_FINANCIER_NAME": "KAIPUNJAL FISHERMENS PM CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12733,
      "TXT_FINANCIER_NAME": "SHREE GANESH CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12734,
      "TXT_FINANCIER_NAME": "SRI LAKSHMI GAYATHRI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12735,
      "TXT_FINANCIER_NAME": "THE A B E CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12736,
      "TXT_FINANCIER_NAME": "IFCTCSL"
    },
    {
      "NUM_FINANCIER_CD": 12737,
      "TXT_FINANCIER_NAME": "V N FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12738,
      "TXT_FINANCIER_NAME": "KARMVEER BAHURAO PATIL MULTI STATE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12739,
      "TXT_FINANCIER_NAME": "SHRI GANPATI NAG SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12740,
      "TXT_FINANCIER_NAME": "KADIYALI JUTH SEVA SAHKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 12741,
      "TXT_FINANCIER_NAME": "TARIKAAL LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12742,
      "TXT_FINANCIER_NAME": "FINVENT FINANCE AND INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12743,
      "TXT_FINANCIER_NAME": "MAHANTESHWARI MAHILA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12744,
      "TXT_FINANCIER_NAME": "SHRI YOGIRAJ JANARDAN GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12745,
      "TXT_FINANCIER_NAME": "SHIROLI BK KRISHAK SEVA SAHA SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12746,
      "TXT_FINANCIER_NAME": "DELHI SC/ST/OBC/ MINORITIES & HANDICAPPED FINANCE & DEVELOPMENT CORPORATION (DSFDC)"
    },
    {
      "NUM_FINANCIER_CD": 12747,
      "TXT_FINANCIER_NAME": "THE DHARISANA"
    },
    {
      "NUM_FINANCIER_CD": 12748,
      "TXT_FINANCIER_NAME": "MONEY WORLD FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12749,
      "TXT_FINANCIER_NAME": "ACG ASSOCIATED CAPSULES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12750,
      "TXT_FINANCIER_NAME": "C BYREGOWDA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12751,
      "TXT_FINANCIER_NAME": "M S CHADHA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 12752,
      "TXT_FINANCIER_NAME": "SOLAPUR SIDHHESWAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12753,
      "TXT_FINANCIER_NAME": "S K GOLDSMITHS INDUSTRIAL CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12754,
      "TXT_FINANCIER_NAME": "THE RAMCO CEMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 12755,
      "TXT_FINANCIER_NAME": "SHRI PADMAVATI GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12756,
      "TXT_FINANCIER_NAME": "SHRI BHADRESHWAR GRAMIN BIGAR SHETI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12757,
      "TXT_FINANCIER_NAME": "G S HIRE PURCHASE FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12758,
      "TXT_FINANCIER_NAME": "KERALASREE COOPERATIVE THRIFT AND CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12759,
      "TXT_FINANCIER_NAME": "MASON CLUB CREDIT CO OPETATIVE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12760,
      "TXT_FINANCIER_NAME": "BAROJ SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12761,
      "TXT_FINANCIER_NAME": "ANANDHA SAGAR INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12762,
      "TXT_FINANCIER_NAME": "TEMANTHANI SMALL TRADERS CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12763,
      "TXT_FINANCIER_NAME": "SAMANMAYA VIVIDODESHA CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12764,
      "TXT_FINANCIER_NAME": "KRUSHI VIDNYAN PRADHYAPAK SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12765,
      "TXT_FINANCIER_NAME": "SHREE AHMEDNAGAR JILHA JAIN OSWAL PANCHAYAT NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12766,
      "TXT_FINANCIER_NAME": "SHRI VISHWANATHSINGH BAYAS NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12767,
      "TXT_FINANCIER_NAME": "RENUKA MATA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12768,
      "TXT_FINANCIER_NAME": "SRI MAHESHWARA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12769,
      "TXT_FINANCIER_NAME": "KEDILA SEVA SAHAKARI SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12770,
      "TXT_FINANCIER_NAME": "THE T V MALAI DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12771,
      "TXT_FINANCIER_NAME": "KARMAYOGI GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12772,
      "TXT_FINANCIER_NAME": "AMAN NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12773,
      "TXT_FINANCIER_NAME": "THE SAURASTRA GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12774,
      "TXT_FINANCIER_NAME": "SHREE SAI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12775,
      "TXT_FINANCIER_NAME": "SRI VASAVIMAATHA VIVIDODDESHA SOUHARDHA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 12776,
      "TXT_FINANCIER_NAME": "JANATHA PATTINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12777,
      "TXT_FINANCIER_NAME": "SREE AMBICA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12778,
      "TXT_FINANCIER_NAME": "MAHADEO NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12779,
      "TXT_FINANCIER_NAME": "KAVALAPADUR PRIMARY AGRICULTURAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12780,
      "TXT_FINANCIER_NAME": "HY GUGALE MERCHENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12781,
      "TXT_FINANCIER_NAME": "SRI DURGA VIVIDODESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12782,
      "TXT_FINANCIER_NAME": "RAJHANS GRAMIN BIGAR SHETI SAHA PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12783,
      "TXT_FINANCIER_NAME": "SHREENIDHI SOUDHARDHA PATTINA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12784,
      "TXT_FINANCIER_NAME": "B HOSAHALLI PRIMARY AGRICULTURAL CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12785,
      "TXT_FINANCIER_NAME": "BRANCH MANAGER LIC OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 12786,
      "TXT_FINANCIER_NAME": "VAKKALIGARA SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12787,
      "TXT_FINANCIER_NAME": "NEW AISWARYA MOTOR CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 12788,
      "TXT_FINANCIER_NAME": "POORNANANDA CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12789,
      "TXT_FINANCIER_NAME": "KAMARAJ CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14192,
      "TXT_FINANCIER_NAME": "JARANDESHWAR GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT KHED MALAVI"
    },
    {
      "NUM_FINANCIER_CD": 14193,
      "TXT_FINANCIER_NAME": "THE AKKI ALUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14194,
      "TXT_FINANCIER_NAME": "THE KANNAMBRA CO OPERTIVE SERVICE BANK"
    },
    {
      "NUM_FINANCIER_CD": 14195,
      "TXT_FINANCIER_NAME": "S K WHEELS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14196,
      "TXT_FINANCIER_NAME": "JALNA SUNDERLAL SAVAJI BANK"
    },
    {
      "NUM_FINANCIER_CD": 14197,
      "TXT_FINANCIER_NAME": "BANNANJE GRAHAKA VIVIDODHESHA SAHAKARI SANGA"
    },
    {
      "NUM_FINANCIER_CD": 14198,
      "TXT_FINANCIER_NAME": "SATOSH NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 14199,
      "TXT_FINANCIER_NAME": "SRI RAJA GANAPATHI AUTO INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 14200,
      "TXT_FINANCIER_NAME": "PURNA GRAMIN BEGAR SHETI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14201,
      "TXT_FINANCIER_NAME": "SHRI SIVA SHAKTI SOUHARDA PATTIN SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14202,
      "TXT_FINANCIER_NAME": "VAISHYA MULTIPURPOSE CO OP SOCIETY LTD BELGAUM"
    },
    {
      "NUM_FINANCIER_CD": 14203,
      "TXT_FINANCIER_NAME": "ANNAMANADA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14204,
      "TXT_FINANCIER_NAME": "THE BELGAUM VYAPARI MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14205,
      "TXT_FINANCIER_NAME": "THE CHEEMA KALAN CO OPP AGRICULTURE MULTIPURPOSE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14206,
      "TXT_FINANCIER_NAME": "EXECUTIVE ENGINEER GOSIKHURD DAM DIVISION WAHI PAUNI"
    },
    {
      "NUM_FINANCIER_CD": 14207,
      "TXT_FINANCIER_NAME": "VISHWA BANDHU PATHINA SAHAKARA SANGA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14208,
      "TXT_FINANCIER_NAME": "SHREE PATILBABA BIGAR SHETI SAHAKARI PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14209,
      "TXT_FINANCIER_NAME": "NEELAM AUTO CONSULTANTS"
    },
    {
      "NUM_FINANCIER_CD": 14210,
      "TXT_FINANCIER_NAME": "KOLTIGE SAHAKARI VYAVASAHIKA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 14211,
      "TXT_FINANCIER_NAME": "KATWA KALNA COOPERATIVE AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14212,
      "TXT_FINANCIER_NAME": "YAMAKANMARDI ARIHANT MULTIPURPOSE CO OP CREDIT SOCIETY NI"
    },
    {
      "NUM_FINANCIER_CD": 14213,
      "TXT_FINANCIER_NAME": "JIVHESHWAR NAGARI SAHAKARI PAT SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 14214,
      "TXT_FINANCIER_NAME": "NK GSB CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14215,
      "TXT_FINANCIER_NAME": "CORPORATIVE AGRICULTURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 14216,
      "TXT_FINANCIER_NAME": "RAJGARHIA COMMERCIAL CO"
    },
    {
      "NUM_FINANCIER_CD": 14217,
      "TXT_FINANCIER_NAME": "BAPUSO SHINDE NAGARI SAHAKARI PATH SANTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 14218,
      "TXT_FINANCIER_NAME": "MALAIKA MULTI STATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14219,
      "TXT_FINANCIER_NAME": "PAREXEL INTERNATIONAL SERVICES INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14220,
      "TXT_FINANCIER_NAME": "KAJEKAR CO OPERATIVE AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14221,
      "TXT_FINANCIER_NAME": "N S FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 14222,
      "TXT_FINANCIER_NAME": "MORYA NAGRIK PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14223,
      "TXT_FINANCIER_NAME": "MANE MASTER CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14224,
      "TXT_FINANCIER_NAME": "EKTA NAGARI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14225,
      "TXT_FINANCIER_NAME": "RUBY FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 14226,
      "TXT_FINANCIER_NAME": "CHOROMANDALAM INVESTMENT AND FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14227,
      "TXT_FINANCIER_NAME": "THE ROHTAK COOP MILK PRODUCERS UNION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14228,
      "TXT_FINANCIER_NAME": "S R NOVA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14229,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAN INVESTMENT AND FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14230,
      "TXT_FINANCIER_NAME": "RATNAGIRI GAS AND POWER PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14231,
      "TXT_FINANCIER_NAME": "SHRI BAGASARA NAGRIK SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14232,
      "TXT_FINANCIER_NAME": "THE VARDHMAN NAGARIK SAHAKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14233,
      "TXT_FINANCIER_NAME": "BALASINOR ROAD TRANSPORT CO OP SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14234,
      "TXT_FINANCIER_NAME": "INCHNAL VIVIDH KARYAKARI SAH VIKAS SEVA SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14235,
      "TXT_FINANCIER_NAME": "NELLUR KEMRAJE PRIMARY AGRI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14236,
      "TXT_FINANCIER_NAME": "INDIRA AND JAI FOUNDATION"
    },
    {
      "NUM_FINANCIER_CD": 14237,
      "TXT_FINANCIER_NAME": "SANJAY KUMAR SURANA"
    },
    {
      "NUM_FINANCIER_CD": 14238,
      "TXT_FINANCIER_NAME": "SHRIDHAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14239,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER CED DEPT OF SPACE"
    },
    {
      "NUM_FINANCIER_CD": 14240,
      "TXT_FINANCIER_NAME": "SRI THARANA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14241,
      "TXT_FINANCIER_NAME": "PARAPPANANGADI MOTOR TRANSPORT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14242,
      "TXT_FINANCIER_NAME": "KAMLA ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14243,
      "TXT_FINANCIER_NAME": "TUMKUR URBAN MULTI PURPOSE SOUHARDHA CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14244,
      "TXT_FINANCIER_NAME": "MANJEET FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14245,
      "TXT_FINANCIER_NAME": "VIJAY NAGRI SAHAKARI PAT SANSTHA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14246,
      "TXT_FINANCIER_NAME": "SRI CHOWDESWARI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14247,
      "TXT_FINANCIER_NAME": "INSURANCE INSTITUTE OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 14248,
      "TXT_FINANCIER_NAME": "IFCO CO OP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14249,
      "TXT_FINANCIER_NAME": "KALLUR CHITS AND FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 14250,
      "TXT_FINANCIER_NAME": "MILLAT MINORITIES CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14251,
      "TXT_FINANCIER_NAME": "SHRI SEVALAL MAHARAJ CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14252,
      "TXT_FINANCIER_NAME": "SHRI SAMBHAJIRAJE THORAT GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14253,
      "TXT_FINANCIER_NAME": "SHRI SAMARTH NAGARI SAH PATH SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 14254,
      "TXT_FINANCIER_NAME": "UMTA NAGARIK DHIRAN MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 14255,
      "TXT_FINANCIER_NAME": "SHRI VIROBA MAHARAJ GRM BIG SHETI SAH PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14256,
      "TXT_FINANCIER_NAME": "THE RADHUMERCABTILE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14257,
      "TXT_FINANCIER_NAME": "SHRI VEERBHADRESHWAR VIVIDH UDDESHAGAL SOUHARD SAHAKARI NIYMIT"
    },
    {
      "NUM_FINANCIER_CD": 14258,
      "TXT_FINANCIER_NAME": "VIKRAM FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14259,
      "TXT_FINANCIER_NAME": "YASHODA CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14260,
      "TXT_FINANCIER_NAME": "SRI GOPALKRISHNA VIVIDODDESHA SOWHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14261,
      "TXT_FINANCIER_NAME": "SARTHAK MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14262,
      "TXT_FINANCIER_NAME": "VIKRAMA SIMHAPURI UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 14263,
      "TXT_FINANCIER_NAME": "LALITA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14264,
      "TXT_FINANCIER_NAME": "LEKSHMI VILASAM BANK"
    },
    {
      "NUM_FINANCIER_CD": 14265,
      "TXT_FINANCIER_NAME": "HCBL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14266,
      "TXT_FINANCIER_NAME": "DHYANDEEP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14267,
      "TXT_FINANCIER_NAME": "VELAVAN INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14268,
      "TXT_FINANCIER_NAME": "SAI GOKUL MOTORS R"
    },
    {
      "NUM_FINANCIER_CD": 14269,
      "TXT_FINANCIER_NAME": "SRI SAKTHISIVAN ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14270,
      "TXT_FINANCIER_NAME": "GAWLI NAGAR NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14271,
      "TXT_FINANCIER_NAME": "DHARMASTHALA GRAMA ABHIVRIDHI YOJANE"
    },
    {
      "NUM_FINANCIER_CD": 14272,
      "TXT_FINANCIER_NAME": "THE KARYARA CO OP AGRI SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14273,
      "TXT_FINANCIER_NAME": "THE MERCHANT BANK"
    },
    {
      "NUM_FINANCIER_CD": 14274,
      "TXT_FINANCIER_NAME": "SRI YERRISWAMY CREDIT SOUHARD SHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14275,
      "TXT_FINANCIER_NAME": "SRI GURUKULA VIVIDODDESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14276,
      "TXT_FINANCIER_NAME": "SANIVARAPUPETA LARGE SIZED CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14277,
      "TXT_FINANCIER_NAME": "MEHIA SYNDICATE SHANTHI NIKETAN"
    },
    {
      "NUM_FINANCIER_CD": 14278,
      "TXT_FINANCIER_NAME": "SRI SANGAMESHWAR PRATHAMIKA KRISHI PATTAINA SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14279,
      "TXT_FINANCIER_NAME": "THE S S K CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14280,
      "TXT_FINANCIER_NAME": "SHRI JYOTIRLING MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14281,
      "TXT_FINANCIER_NAME": "KAVRADY CO OPERATIVE AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14282,
      "TXT_FINANCIER_NAME": "BARANI CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14283,
      "TXT_FINANCIER_NAME": "HEMAREDDY MALLAMMA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15801,
      "TXT_FINANCIER_NAME": "FORWARD SECURITIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15802,
      "TXT_FINANCIER_NAME": "GALADA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15803,
      "TXT_FINANCIER_NAME": "GANGA GENERAL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15804,
      "TXT_FINANCIER_NAME": "GARNET FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15805,
      "TXT_FINANCIER_NAME": "GAYAPRASAD HIRALAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 15806,
      "TXT_FINANCIER_NAME": "GILADA FINANCE & INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 15807,
      "TXT_FINANCIER_NAME": "GOBIND FINANCE & PROPERTIES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15808,
      "TXT_FINANCIER_NAME": "GOKUL FINANCE & INVESTMENT COMPANY LTD."
    },
    {
      "NUM_FINANCIER_CD": 15809,
      "TXT_FINANCIER_NAME": "GONDWANA LEASING & FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 15810,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SERVICES PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 15811,
      "TXT_FINANCIER_NAME": "THE BHUJ COMMERCIAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15812,
      "TXT_FINANCIER_NAME": "THE BATHINDA CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15813,
      "TXT_FINANCIER_NAME": "THE BATHINDA CENTRAL CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15814,
      "TXT_FINANCIER_NAME": "BIRLA GLOBAL ASSETS & FINANCE CO.LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 15815,
      "TXT_FINANCIER_NAME": "THE SURAT PEOPLES CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15816,
      "TXT_FINANCIER_NAME": "MADGAUM URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15817,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR SHAHAR SAHAKARI BANK LTD., ANR."
    },
    {
      "NUM_FINANCIER_CD": 15818,
      "TXT_FINANCIER_NAME": "MUTHOOT LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 15819,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15820,
      "TXT_FINANCIER_NAME": "JANSEVA NAGARI SAH.BANK LTD., BHOOM"
    },
    {
      "NUM_FINANCIER_CD": 15821,
      "TXT_FINANCIER_NAME": "B N P PARIBAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 15822,
      "TXT_FINANCIER_NAME": "RAJKOT NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15823,
      "TXT_FINANCIER_NAME": "SHREE KADI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15824,
      "TXT_FINANCIER_NAME": "HINDUSTAN FINANCE CO."
    },
    {
      "NUM_FINANCIER_CD": 15825,
      "TXT_FINANCIER_NAME": "NARODA INDUSTRIAL CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15826,
      "TXT_FINANCIER_NAME": "SOUTH INDIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15827,
      "TXT_FINANCIER_NAME": "SHREYAS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15828,
      "TXT_FINANCIER_NAME": "THE CO-OPERATIVE BANK OF RAJKOT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15829,
      "TXT_FINANCIER_NAME": "DURGAPUR STEEL PEOPLES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15830,
      "TXT_FINANCIER_NAME": "GE CAPITAL TRANSPORATION FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15831,
      "TXT_FINANCIER_NAME": "GE CAPITAL TRANSPORTATION FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15832,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIMUS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15833,
      "TXT_FINANCIER_NAME": "SHREE BHARAT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15834,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15835,
      "TXT_FINANCIER_NAME": "JAMKHED MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15836,
      "TXT_FINANCIER_NAME": "HARYANA POWER GENERATION CORP."
    },
    {
      "NUM_FINANCIER_CD": 15837,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR URBAN CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15838,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15839,
      "TXT_FINANCIER_NAME": "THE KALYAN JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15840,
      "TXT_FINANCIER_NAME": "SHANKARRAO MOHITE PATIL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15841,
      "TXT_FINANCIER_NAME": "SHRI LAXMIKRUPA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15842,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15843,
      "TXT_FINANCIER_NAME": "MUZAFFARNAGAR KSHETREYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 15844,
      "TXT_FINANCIER_NAME": "PARASPAR SAHAYAK CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15845,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES FINANCIAL SERVICES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 15846,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15847,
      "TXT_FINANCIER_NAME": "THE SECUNDERABAD CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15848,
      "TXT_FINANCIER_NAME": "SHRI SHIVAYOGI MURUGHENDRA SWAMI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15849,
      "TXT_FINANCIER_NAME": "THE HIREKERUR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15850,
      "TXT_FINANCIER_NAME": "THE BAILHONGAL URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15851,
      "TXT_FINANCIER_NAME": "THE UNITED WESTERN BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 15852,
      "TXT_FINANCIER_NAME": "SHRI SHIDDHESHWAR CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15853,
      "TXT_FINANCIER_NAME": "MANASA CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15854,
      "TXT_FINANCIER_NAME": "THE ZOROASTRIAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15855,
      "TXT_FINANCIER_NAME": "THE MUDHOL URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15856,
      "TXT_FINANCIER_NAME": "RENDAL SAHAKARI BANK LTD RENDAL"
    },
    {
      "NUM_FINANCIER_CD": 15857,
      "TXT_FINANCIER_NAME": "THE DHANERA MERCANTILE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15858,
      "TXT_FINANCIER_NAME": "THE RADDI SAHAKAR BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 15859,
      "TXT_FINANCIER_NAME": "THE NAINITAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15860,
      "TXT_FINANCIER_NAME": "SAMATHA MAHILA CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15861,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15862,
      "TXT_FINANCIER_NAME": "THE JALGAON DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15863,
      "TXT_FINANCIER_NAME": "SHREE SADGURU JANGLI MAHARAJ SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15864,
      "TXT_FINANCIER_NAME": "THE JAGRUTI CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15865,
      "TXT_FINANCIER_NAME": "THE KURMANCHAL NAGAR SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15866,
      "TXT_FINANCIER_NAME": "Lakshmi General Finance Limited, Chennai"
    },
    {
      "NUM_FINANCIER_CD": 15867,
      "TXT_FINANCIER_NAME": "SANJIVANI NAGARI SAHAKRI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 15868,
      "TXT_FINANCIER_NAME": "STATE BANK OF SAURASHTRA."
    },
    {
      "NUM_FINANCIER_CD": 15869,
      "TXT_FINANCIER_NAME": "THE JANALAXMI CO-OP BANK LTD.NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 15870,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15871,
      "TXT_FINANCIER_NAME": "THE SONEPAT URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15872,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO- OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 15873,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO-OP BANK GHUMARWIN"
    },
    {
      "NUM_FINANCIER_CD": 15874,
      "TXT_FINANCIER_NAME": "BRAMHAPURI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15875,
      "TXT_FINANCIER_NAME": "THE VAIKOM URBAN CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15876,
      "TXT_FINANCIER_NAME": "BISHAN DASS WADHWA & CO"
    },
    {
      "NUM_FINANCIER_CD": 15877,
      "TXT_FINANCIER_NAME": "THE DISTRICT CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15878,
      "TXT_FINANCIER_NAME": "SHRI SIDDHESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15879,
      "TXT_FINANCIER_NAME": "RESERVE BANK EMPLOYEES CO-OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15880,
      "TXT_FINANCIER_NAME": "ANAND URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15881,
      "TXT_FINANCIER_NAME": "THE DISTRICT CO-OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15882,
      "TXT_FINANCIER_NAME": "STATE TRANSPORT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15883,
      "TXT_FINANCIER_NAME": "THE KHAMGAON URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15884,
      "TXT_FINANCIER_NAME": "YADAGIRI LAKSHMI NARSIMHA SWAMY CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15885,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15886,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT INDL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15887,
      "TXT_FINANCIER_NAME": "DOMBIVLI NAGARI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15888,
      "TXT_FINANCIER_NAME": "RAJKOT PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15889,
      "TXT_FINANCIER_NAME": "KCCB"
    },
    {
      "NUM_FINANCIER_CD": 15890,
      "TXT_FINANCIER_NAME": "CHIPLUN URBAN CO-OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 15891,
      "TXT_FINANCIER_NAME": "kangra central co-op bank bhota"
    },
    {
      "NUM_FINANCIER_CD": 15892,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO BANK"
    },
    {
      "NUM_FINANCIER_CD": 15893,
      "TXT_FINANCIER_NAME": "THE MODASA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15894,
      "TXT_FINANCIER_NAME": "THE HOTEL INDUSTRIES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15895,
      "TXT_FINANCIER_NAME": "GOPALKA AUTO FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15896,
      "TXT_FINANCIER_NAME": "GOVE INVESTMENT & FINANCE CO LTD."
    },
    {
      "NUM_FINANCIER_CD": 15897,
      "TXT_FINANCIER_NAME": "GTP FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17434,
      "TXT_FINANCIER_NAME": "SRI SUDHA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17435,
      "TXT_FINANCIER_NAME": "STATE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17436,
      "TXT_FINANCIER_NAME": "Ashok Leyland Finance (A Division Of Indusind Bank)"
    },
    {
      "NUM_FINANCIER_CD": 17437,
      "TXT_FINANCIER_NAME": "UNITED INDIA INSURANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17438,
      "TXT_FINANCIER_NAME": "SHRI MAHAVIR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17439,
      "TXT_FINANCIER_NAME": "THE NEEDS OF LIFE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17440,
      "TXT_FINANCIER_NAME": "THE THODUPUZHA URBAN CO-OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 17441,
      "TXT_FINANCIER_NAME": "THE LIBERAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17442,
      "TXT_FINANCIER_NAME": "sardar bhiladwalla pardi people co op bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 17443,
      "TXT_FINANCIER_NAME": "KODAGU DISTRICT CO OPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17444,
      "TXT_FINANCIER_NAME": "JANATHA SEVA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17445,
      "TXT_FINANCIER_NAME": "THE BANASKANTHA ROAD TRAN SPORT MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 17446,
      "TXT_FINANCIER_NAME": "SRI KANYAKAPARAMESHWARI COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17447,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO OPT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17448,
      "TXT_FINANCIER_NAME": "BHATPARA NAIHATI CO OPARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17449,
      "TXT_FINANCIER_NAME": "THE JAMMU AND KASHMIR BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17450,
      "TXT_FINANCIER_NAME": "INSTITUTE FOR PLASMA RESEARCH"
    },
    {
      "NUM_FINANCIER_CD": 17451,
      "TXT_FINANCIER_NAME": "ISHER FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17452,
      "TXT_FINANCIER_NAME": "BHUMI VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 17453,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH GEN CO"
    },
    {
      "NUM_FINANCIER_CD": 17454,
      "TXT_FINANCIER_NAME": "MALAPPURAM DIST CO OP MOTORS VEHICHLES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17455,
      "TXT_FINANCIER_NAME": "THE MUDALGI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17456,
      "TXT_FINANCIER_NAME": "UTI ASSET MANAGEMENT CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17457,
      "TXT_FINANCIER_NAME": "JANHIT NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17458,
      "TXT_FINANCIER_NAME": "VIKAS URBAN CO OPERATIVE BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 17459,
      "TXT_FINANCIER_NAME": "CHITANAVISPURA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17460,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17461,
      "TXT_FINANCIER_NAME": "THE JAMMU AND KASHMIR BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17462,
      "TXT_FINANCIER_NAME": "THE GREATER BOMBAY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17463,
      "TXT_FINANCIER_NAME": "BALITIKURI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17464,
      "TXT_FINANCIER_NAME": "The Nagpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17465,
      "TXT_FINANCIER_NAME": "GOVERNMENT SERVANT S CO OPERATIVE SOCIETY LTD NO 146"
    },
    {
      "NUM_FINANCIER_CD": 17466,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT CO- OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17467,
      "TXT_FINANCIER_NAME": "NILAMBUR CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17468,
      "TXT_FINANCIER_NAME": "JANSEVA NAGARI SAHAKARI BANK LTD. BHOOM"
    },
    {
      "NUM_FINANCIER_CD": 17469,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. LTD"
    },
    {
      "NUM_FINANCIER_CD": 17470,
      "TXT_FINANCIER_NAME": "THE ICHALKARANJI MERCHANTS` CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17471,
      "TXT_FINANCIER_NAME": "THE ALNAVAR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17472,
      "TXT_FINANCIER_NAME": "icic"
    },
    {
      "NUM_FINANCIER_CD": 17473,
      "TXT_FINANCIER_NAME": "THE ANJANGAON SURJI NAGARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17474,
      "TXT_FINANCIER_NAME": "TEHRI GARHWAL ZILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17475,
      "TXT_FINANCIER_NAME": "SADHANA SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17476,
      "TXT_FINANCIER_NAME": "THE ING VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17477,
      "TXT_FINANCIER_NAME": "DHAKURIA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17478,
      "TXT_FINANCIER_NAME": "BHAUSAHEB BIRAJDAR NAGRI SAHAKARI BANK LTD.OMERGA"
    },
    {
      "NUM_FINANCIER_CD": 17479,
      "TXT_FINANCIER_NAME": "WALCHANDNAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17480,
      "TXT_FINANCIER_NAME": "SHRI VEERSHAIV CO-0PERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17481,
      "TXT_FINANCIER_NAME": "THE MANMANDIR CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17482,
      "TXT_FINANCIER_NAME": "THE MULLAKKODI CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17483,
      "TXT_FINANCIER_NAME": "THE MULLAKKODI CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17484,
      "TXT_FINANCIER_NAME": "SREE BANASHANKARI MAHILA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17485,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 17486,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OPERATIVE BANK LTD.MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 17487,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17488,
      "TXT_FINANCIER_NAME": "AJINKYATARA SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17489,
      "TXT_FINANCIER_NAME": "INDIRA SHRAMIK MAHILA NAGARI SAHAKARI BANK NIY.SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17490,
      "TXT_FINANCIER_NAME": "KURMANCHAL NAGAR SHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17491,
      "TXT_FINANCIER_NAME": "HOUSING AND URBAN DEVELOPMENT CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 17492,
      "TXT_FINANCIER_NAME": "THE DELHI STATE COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17493,
      "TXT_FINANCIER_NAME": "THE STATE TRANSPORT (E)CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17494,
      "TXT_FINANCIER_NAME": "UJJAIN AUDHYOGIK VIKAS NAGRIK SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 17495,
      "TXT_FINANCIER_NAME": "THE DHARMAPURI DIST.CENTRAL CO-OP.BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 17496,
      "TXT_FINANCIER_NAME": "KARNATAKA VIKAS GRAMEENA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17497,
      "TXT_FINANCIER_NAME": "KURMANCHAL NAGAR CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17498,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17499,
      "TXT_FINANCIER_NAME": "VEERASHAIVA SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17500,
      "TXT_FINANCIER_NAME": "BALITIKURI CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17501,
      "TXT_FINANCIER_NAME": "POST OFFICE SAVING BANK MATHURA CITY"
    },
    {
      "NUM_FINANCIER_CD": 17502,
      "TXT_FINANCIER_NAME": "VIJAYA BANK."
    },
    {
      "NUM_FINANCIER_CD": 17503,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17504,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR MER. CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17505,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17506,
      "TXT_FINANCIER_NAME": "SHRI CHHATRAPATI SHIVAJI MAHARAJ PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 17507,
      "TXT_FINANCIER_NAME": "BAIRAVI FINANCE AND INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17508,
      "TXT_FINANCIER_NAME": "BELLAD BAGEWADI URBAN SOUHARD SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 17509,
      "TXT_FINANCIER_NAME": "SHRI RAM CITY UNION FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 17510,
      "TXT_FINANCIER_NAME": "THE VAIJAPUR MERCHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17511,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIDE AUTO FINCIAL SERVICE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 17512,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIED AUTO FINCIAL SERVICE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 17513,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17514,
      "TXT_FINANCIER_NAME": "VIJYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17515,
      "TXT_FINANCIER_NAME": "SHREE MADHI SURALI VIBHAG NAGRIK SAHAKARI DHIRAN MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 17516,
      "TXT_FINANCIER_NAME": "ADARSH NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17517,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY UNION FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 17518,
      "TXT_FINANCIER_NAME": "IRCON INTERNATIONAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 17519,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE BIGAR SHETI SAHAKARI PAT SANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 17520,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE BIGAR SHETI SAHAKARI PAT SANTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 17521,
      "TXT_FINANCIER_NAME": "GLAXO SMITH KLINE CONSUMER HEALTH CARE"
    },
    {
      "NUM_FINANCIER_CD": 17522,
      "TXT_FINANCIER_NAME": "SRI TIRUMALA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17523,
      "TXT_FINANCIER_NAME": "Chittorgarh Urban cooperative bank limited"
    },
    {
      "NUM_FINANCIER_CD": 17524,
      "TXT_FINANCIER_NAME": "PIMPRI CHINCHWAD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17525,
      "TXT_FINANCIER_NAME": "ANYOANYA SAHAYAK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 17526,
      "TXT_FINANCIER_NAME": "ASTRAZENECA PHARMA INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17527,
      "TXT_FINANCIER_NAME": "CHAMPAVATI URBAN COOP BANK LTD BEED"
    },
    {
      "NUM_FINANCIER_CD": 12790,
      "TXT_FINANCIER_NAME": "CHAVAKAD PRIMARY CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12791,
      "TXT_FINANCIER_NAME": "M S E B OFFICERS CO OPERATIVE CREDIT SOCIERTY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12792,
      "TXT_FINANCIER_NAME": "MAHATMA JYOTI BA FULE SAKH SAHAKARITA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 12793,
      "TXT_FINANCIER_NAME": "SHREE JENUKAL SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12794,
      "TXT_FINANCIER_NAME": "AMAN CHOTE VYAPARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12795,
      "TXT_FINANCIER_NAME": "TOLANI MARITANE INSTITUTE"
    },
    {
      "NUM_FINANCIER_CD": 12796,
      "TXT_FINANCIER_NAME": "CLOTH MARKET MER CREDIT SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12797,
      "TXT_FINANCIER_NAME": "SHREE TULJABHAWANI MAHILA NAGRI SAHAKARI PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12798,
      "TXT_FINANCIER_NAME": "THE BAJPE VYAVASAYA SEVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12799,
      "TXT_FINANCIER_NAME": "VANITHA SURANA"
    },
    {
      "NUM_FINANCIER_CD": 12800,
      "TXT_FINANCIER_NAME": "KIRPEKAR ENGINEERING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12801,
      "TXT_FINANCIER_NAME": "VAHANA FINCORP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12802,
      "TXT_FINANCIER_NAME": "THE LAKSHMI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12803,
      "TXT_FINANCIER_NAME": "SHUBHLAXMI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12804,
      "TXT_FINANCIER_NAME": "SIR M VISVESVARAYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12805,
      "TXT_FINANCIER_NAME": "THE SOLAPUR DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12806,
      "TXT_FINANCIER_NAME": "ANKITA S MEHTA"
    },
    {
      "NUM_FINANCIER_CD": 12807,
      "TXT_FINANCIER_NAME": "NIRMAN MULTI STATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12808,
      "TXT_FINANCIER_NAME": "THE PUTTUR CO OPERATIVE TOWN BANK"
    },
    {
      "NUM_FINANCIER_CD": 12809,
      "TXT_FINANCIER_NAME": "BHAGYA SRI FINANCE COR"
    },
    {
      "NUM_FINANCIER_CD": 12810,
      "TXT_FINANCIER_NAME": "LAKSHMI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12811,
      "TXT_FINANCIER_NAME": "MS PUSHPA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 12812,
      "TXT_FINANCIER_NAME": "THE KRANTIVEER SANGOLLI RAYANNA URBAN CO OPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12813,
      "TXT_FINANCIER_NAME": "HARA VIVIDHODDESHA SOUHARDA SAHAKARI NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 12814,
      "TXT_FINANCIER_NAME": "PADMUNJA PRARHAMIKA KRISHI PATTINA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12815,
      "TXT_FINANCIER_NAME": "PRAKI NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12816,
      "TXT_FINANCIER_NAME": "MALLAVARAM P A C C S LTD"
    },
    {
      "NUM_FINANCIER_CD": 12817,
      "TXT_FINANCIER_NAME": "SADGURU DATTATRAY MAHARAJ CHOURE PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12818,
      "TXT_FINANCIER_NAME": "THE NEW INDIA MULTIPURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12819,
      "TXT_FINANCIER_NAME": "SRI MARUTHI CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12820,
      "TXT_FINANCIER_NAME": "THE VIR TRANSPORT CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12821,
      "TXT_FINANCIER_NAME": "THE KALAVALAPLLI PACS LTD"
    },
    {
      "NUM_FINANCIER_CD": 12822,
      "TXT_FINANCIER_NAME": "SHRI ARUNODAY MULTI STATE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12823,
      "TXT_FINANCIER_NAME": "GUJARAT GAS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12824,
      "TXT_FINANCIER_NAME": "JAYASHANKAR KALKUNDRIKAR MULTI PURPOSE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12825,
      "TXT_FINANCIER_NAME": "AMRUTKUMBH GRAMIN BIGARSHETI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12826,
      "TXT_FINANCIER_NAME": "SAI NAGRIK PATHSANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 12827,
      "TXT_FINANCIER_NAME": "PRIMARY MODULES INDIA"
    },
    {
      "NUM_FINANCIER_CD": 12828,
      "TXT_FINANCIER_NAME": "PADMAVATI SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 12829,
      "TXT_FINANCIER_NAME": "SANCHAR GRAMIN BIGAR SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12830,
      "TXT_FINANCIER_NAME": "AVINASH FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12831,
      "TXT_FINANCIER_NAME": "PANCHASHEELA URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12832,
      "TXT_FINANCIER_NAME": "BABASAHEB DESHMUKH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12833,
      "TXT_FINANCIER_NAME": "SINA PARISAR BIGAR NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12834,
      "TXT_FINANCIER_NAME": "BALASAHEB DESHMUKH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12835,
      "TXT_FINANCIER_NAME": "BROADA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 12836,
      "TXT_FINANCIER_NAME": "BALJEET LEASING AND CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12837,
      "TXT_FINANCIER_NAME": "THE KUTHER CO OPERATIVE AGRICULTURE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12838,
      "TXT_FINANCIER_NAME": "THE VAKKOM FARMERS SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12839,
      "TXT_FINANCIER_NAME": "KURUDATTA URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12840,
      "TXT_FINANCIER_NAME": "SHARADA SAHAKARI NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12841,
      "TXT_FINANCIER_NAME": "PATADI NAGARIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 12842,
      "TXT_FINANCIER_NAME": "DNYNESHWAR MAULI GARMIN BIGAR SHETI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 12843,
      "TXT_FINANCIER_NAME": "GNANA SHALE SOUHARDA CREDIT COPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12844,
      "TXT_FINANCIER_NAME": "BAKSHI AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 12845,
      "TXT_FINANCIER_NAME": "HOSAMATA PRIMARY AGRICULTURE CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12846,
      "TXT_FINANCIER_NAME": "AKASH GANGA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12847,
      "TXT_FINANCIER_NAME": "HICHKADA SAVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12848,
      "TXT_FINANCIER_NAME": "JAIVELAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12849,
      "TXT_FINANCIER_NAME": "KIRAN TRADING CORP"
    },
    {
      "NUM_FINANCIER_CD": 12850,
      "TXT_FINANCIER_NAME": "PIONEER AUTO AND CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12851,
      "TXT_FINANCIER_NAME": "KIOCL LTD"
    },
    {
      "NUM_FINANCIER_CD": 12852,
      "TXT_FINANCIER_NAME": "YASHODA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12853,
      "TXT_FINANCIER_NAME": "THE KANNAPURAM SERVICE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12854,
      "TXT_FINANCIER_NAME": "SRI SIVANMALAI ANDAVAR AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12855,
      "TXT_FINANCIER_NAME": "BARAMATI NAGRI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12856,
      "TXT_FINANCIER_NAME": "M D PAWAR PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12857,
      "TXT_FINANCIER_NAME": "KUMBARARA GUDI KAIKARIKA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 12858,
      "TXT_FINANCIER_NAME": "SHRI GURU POORNANANDA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12859,
      "TXT_FINANCIER_NAME": "THE GUJARATHI URB CO OP CRE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 12860,
      "TXT_FINANCIER_NAME": "SHRIJI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12861,
      "TXT_FINANCIER_NAME": "B BIPIN KUMBATH FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12862,
      "TXT_FINANCIER_NAME": "SATHYA NARAYANA VIVIDODESHA SAHAKARI SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12863,
      "TXT_FINANCIER_NAME": "RAJASTHAN ST SC FINANCE DEV CORPRATION"
    },
    {
      "NUM_FINANCIER_CD": 12864,
      "TXT_FINANCIER_NAME": "N B R ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 12865,
      "TXT_FINANCIER_NAME": "DHAN SHREE MOTORS FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 12866,
      "TXT_FINANCIER_NAME": "BELUVAI CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12867,
      "TXT_FINANCIER_NAME": "SHREE DAIVAJNA SUVARNAKARAR MULTIPURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12868,
      "TXT_FINANCIER_NAME": "MAHARASHTRA REMOTE SENSING APPLICATION CENTER"
    },
    {
      "NUM_FINANCIER_CD": 12869,
      "TXT_FINANCIER_NAME": "TERNA NAGARI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12870,
      "TXT_FINANCIER_NAME": "SRI GAYATRI PATTINA SOUHARDA SAHARDA NI"
    },
    {
      "NUM_FINANCIER_CD": 12871,
      "TXT_FINANCIER_NAME": "THE SIKANDARPUR CAMPS SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12872,
      "TXT_FINANCIER_NAME": "INSTITUTE OF BANKING PERSONNEL SELECTION"
    },
    {
      "NUM_FINANCIER_CD": 12873,
      "TXT_FINANCIER_NAME": "THE PAVAGADA SOUHARDA MULTIPURPOSE CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12874,
      "TXT_FINANCIER_NAME": "THE MUTTATHODY SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12875,
      "TXT_FINANCIER_NAME": "SRI MURUGA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12876,
      "TXT_FINANCIER_NAME": "SMT JATAN SURANA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12877,
      "TXT_FINANCIER_NAME": "ABC FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12878,
      "TXT_FINANCIER_NAME": "C G JANKALYAN MITAVAYAYI SAKH SWAYAT SAHKARITA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12879,
      "TXT_FINANCIER_NAME": "JAYA KRISHNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12880,
      "TXT_FINANCIER_NAME": "THE KOODALI PUBLIC SERVANTS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12881,
      "TXT_FINANCIER_NAME": "MARATHA VIBHAG NAGARI SAHAKARI PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 12882,
      "TXT_FINANCIER_NAME": "SHRI GOTENIRA JANNIMATA BIGERSHITE SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14284,
      "TXT_FINANCIER_NAME": "GURU TEGH BAHADUR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14285,
      "TXT_FINANCIER_NAME": "THE KEIVAL CO OP CRIDET SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14286,
      "TXT_FINANCIER_NAME": "NAYGAON GRAMINBIGARSHEETI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14287,
      "TXT_FINANCIER_NAME": "SHETTIGERI PRIMARY AGRICULTURAL CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14288,
      "TXT_FINANCIER_NAME": "APOORVA SOUHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14289,
      "TXT_FINANCIER_NAME": "BHEDSGAON NAGARI SAHKARI PATH SANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 14290,
      "TXT_FINANCIER_NAME": "SIRI SANJEEVINI PATTINA SOUHARDA SAHAKARI NIYAMITHA BANK"
    },
    {
      "NUM_FINANCIER_CD": 14291,
      "TXT_FINANCIER_NAME": "GOA UNIVERSITY EMPLOYEES CO OPERATION CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14292,
      "TXT_FINANCIER_NAME": "PUSPAWANTI NAGARI SAHAKARI PATASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14293,
      "TXT_FINANCIER_NAME": "MAASAHEB GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14294,
      "TXT_FINANCIER_NAME": "RUPARATHI"
    },
    {
      "NUM_FINANCIER_CD": 14295,
      "TXT_FINANCIER_NAME": "K R L FINLEASE PVTLTD"
    },
    {
      "NUM_FINANCIER_CD": 14296,
      "TXT_FINANCIER_NAME": "PARYAS MULTIPURPOSE CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 14297,
      "TXT_FINANCIER_NAME": "PUNJAB MERCHANTILE CREDIT SAHKARITA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14298,
      "TXT_FINANCIER_NAME": "KUMARAVEL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14299,
      "TXT_FINANCIER_NAME": "INDIRA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14300,
      "TXT_FINANCIER_NAME": "SULEMANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14301,
      "TXT_FINANCIER_NAME": "ANNAI AISHWAREYAM FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14302,
      "TXT_FINANCIER_NAME": "VISION IKNOWLEDGE SOLUTIONS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14303,
      "TXT_FINANCIER_NAME": "VISION IKNOWLEDGE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14304,
      "TXT_FINANCIER_NAME": "SHRI SHANTESHWAR VIVIDODDESHAGALA SAHAKARI SANDHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14305,
      "TXT_FINANCIER_NAME": "CHOTI DEVI NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 14306,
      "TXT_FINANCIER_NAME": "AP ELEC REGULATORY COMMISION"
    },
    {
      "NUM_FINANCIER_CD": 14307,
      "TXT_FINANCIER_NAME": "SRI KUMARESHWAR PATTIN SAHAKARI SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14308,
      "TXT_FINANCIER_NAME": "SRI VAISHNAVI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14309,
      "TXT_FINANCIER_NAME": "SHRI CHOUDESHWARI GR BI SA PA MAR"
    },
    {
      "NUM_FINANCIER_CD": 14310,
      "TXT_FINANCIER_NAME": "THE BHIWANI CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 14311,
      "TXT_FINANCIER_NAME": "OM GURUDEV NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14312,
      "TXT_FINANCIER_NAME": "JAI GANESH CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14313,
      "TXT_FINANCIER_NAME": "ANUGRAHA VIVIDODDESHA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 14314,
      "TXT_FINANCIER_NAME": "BHAGWATI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14315,
      "TXT_FINANCIER_NAME": "METRO MOTORS AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14316,
      "TXT_FINANCIER_NAME": "ASHOKA HIRE PURCHASE CO"
    },
    {
      "NUM_FINANCIER_CD": 14317,
      "TXT_FINANCIER_NAME": "THE TADIKALAPUDI LSCS"
    },
    {
      "NUM_FINANCIER_CD": 14318,
      "TXT_FINANCIER_NAME": "P SHANTILAL JAIN"
    },
    {
      "NUM_FINANCIER_CD": 14319,
      "TXT_FINANCIER_NAME": "GSCD CROP"
    },
    {
      "NUM_FINANCIER_CD": 14320,
      "TXT_FINANCIER_NAME": "NETAJI CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14321,
      "TXT_FINANCIER_NAME": "JINARAL URBAN CREDIT SOUHARD SAHAKARI NIYAMIT JINALAL"
    },
    {
      "NUM_FINANCIER_CD": 14322,
      "TXT_FINANCIER_NAME": "THE KHIMANA VIBHAG NAGRIK SHARAFI SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14323,
      "TXT_FINANCIER_NAME": "ORBIT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14324,
      "TXT_FINANCIER_NAME": "EXECUTIVE ENGINEER IRRIGATION RESEARCH DIVISION PUNE 1"
    },
    {
      "NUM_FINANCIER_CD": 14325,
      "TXT_FINANCIER_NAME": "PRAGATI MULTIPURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14326,
      "TXT_FINANCIER_NAME": "GRAMIN BACHAT BANK SEWA SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 14327,
      "TXT_FINANCIER_NAME": "MULANUR PRIYA LAKSHMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14328,
      "TXT_FINANCIER_NAME": "SHREE VARADVINAYAK NAGARI SAH PATHSANSTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14329,
      "TXT_FINANCIER_NAME": "PUNE ZILHA SARVJANIK BANDKAM KARMCHARI SAH PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14330,
      "TXT_FINANCIER_NAME": "NAGANUR URBAN CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14331,
      "TXT_FINANCIER_NAME": "STATE JAWAHAR BAL BHAVAN"
    },
    {
      "NUM_FINANCIER_CD": 14332,
      "TXT_FINANCIER_NAME": "OKKALLGA SAMUDAYA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14333,
      "TXT_FINANCIER_NAME": "YEDAMANGALA PRATHAMIKA KRISHI PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14334,
      "TXT_FINANCIER_NAME": "THE ROMAN CATHOLIC DIOCESE OF AGRA PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14335,
      "TXT_FINANCIER_NAME": "THE NANDOD TALUKA TEACHERS CO OP CR SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14336,
      "TXT_FINANCIER_NAME": "TELANGANA STATE POLLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 14337,
      "TXT_FINANCIER_NAME": "SHRI MARUTI COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14338,
      "TXT_FINANCIER_NAME": "UPPER ASSAM FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 14339,
      "TXT_FINANCIER_NAME": "SHRI MURUGHENDRA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14340,
      "TXT_FINANCIER_NAME": "CHAKAN NAGARI SAHAKARI PATHSANSTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14341,
      "TXT_FINANCIER_NAME": "TRINITY CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14342,
      "TXT_FINANCIER_NAME": "SRI RAMAKRISHNA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14343,
      "TXT_FINANCIER_NAME": "POOMANGALAM SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14344,
      "TXT_FINANCIER_NAME": "RAYAT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14345,
      "TXT_FINANCIER_NAME": "STATE BANK OFF INDORE"
    },
    {
      "NUM_FINANCIER_CD": 14346,
      "TXT_FINANCIER_NAME": "THE KABILPORE PEOPLES CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14347,
      "TXT_FINANCIER_NAME": "KARNATAK SOUHARD PATTIN SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14348,
      "TXT_FINANCIER_NAME": "MAHESHWAR NAGRI SAHKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14349,
      "TXT_FINANCIER_NAME": "SHIVALAYA NAGARI SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14350,
      "TXT_FINANCIER_NAME": "THE TANDALIYA KAMPA GROUP SEVA SAH MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 14351,
      "TXT_FINANCIER_NAME": "RELIANCE CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14352,
      "TXT_FINANCIER_NAME": "EXCELLENT CARS"
    },
    {
      "NUM_FINANCIER_CD": 14353,
      "TXT_FINANCIER_NAME": "PETRONET CCK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14354,
      "TXT_FINANCIER_NAME": "ASHUTOSH SECURITIES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14355,
      "TXT_FINANCIER_NAME": "MEENAKSHI JAIN"
    },
    {
      "NUM_FINANCIER_CD": 14356,
      "TXT_FINANCIER_NAME": "YENEKAL PRIMARY AGRICULTURE CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14357,
      "TXT_FINANCIER_NAME": "LOBO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14358,
      "TXT_FINANCIER_NAME": "SABARKANTHA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14359,
      "TXT_FINANCIER_NAME": "A NARAYANASWAMY"
    },
    {
      "NUM_FINANCIER_CD": 14360,
      "TXT_FINANCIER_NAME": "BAJAJ FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14361,
      "TXT_FINANCIER_NAME": "NATIONAL HANDICAPPED FINANCE DEVELOPMENT AND CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14362,
      "TXT_FINANCIER_NAME": "RAKSHAK NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14363,
      "TXT_FINANCIER_NAME": "DR MANIBHAI DESAI GRAMIN BIGARSHETI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14364,
      "TXT_FINANCIER_NAME": "ECOMARK GEN FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 14365,
      "TXT_FINANCIER_NAME": "KAMBADAKONE RAITHA SEVA SAHAKARI SANGA"
    },
    {
      "NUM_FINANCIER_CD": 14366,
      "TXT_FINANCIER_NAME": "AMBALPADY SOWHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14367,
      "TXT_FINANCIER_NAME": "SHIVNERI PATH PEDHI"
    },
    {
      "NUM_FINANCIER_CD": 14368,
      "TXT_FINANCIER_NAME": "HINDUJA LEYAND FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14369,
      "TXT_FINANCIER_NAME": "NATIONAL INSTITUTE OF DESIGN"
    },
    {
      "NUM_FINANCIER_CD": 14370,
      "TXT_FINANCIER_NAME": "DULICHAND FINANCE AND LEASING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14371,
      "TXT_FINANCIER_NAME": "SRI KIRTHI CHANDRA FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14372,
      "TXT_FINANCIER_NAME": "CHHATRAPATI RAJSHRI SHAHU MAHARAJ NAGRI SAH PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14373,
      "TXT_FINANCIER_NAME": "SMR LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14374,
      "TXT_FINANCIER_NAME": "SHRI PANDURANG KRUPA GRAMIN BIGAR SHETI SAHAKARI PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14375,
      "TXT_FINANCIER_NAME": "HASSANA JILLA SAHAKARA KENDRA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14376,
      "TXT_FINANCIER_NAME": "DIFFUSION ENG LTD"
    },
    {
      "NUM_FINANCIER_CD": 14377,
      "TXT_FINANCIER_NAME": "KOYILANDY COOPERATIVE ARDB LTD"
    },
    {
      "NUM_FINANCIER_CD": 14378,
      "TXT_FINANCIER_NAME": "KOTHAMANGALAM TALUK AGRICULTURAL RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15898,
      "TXT_FINANCIER_NAME": "GUJARAT LEASE FINANCING LTD."
    },
    {
      "NUM_FINANCIER_CD": 15899,
      "TXT_FINANCIER_NAME": "GUJARAT STATE FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15900,
      "TXT_FINANCIER_NAME": "GUPTA INSTALMENT SUPPLY (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15901,
      "TXT_FINANCIER_NAME": "H R D FINCAP LTD"
    },
    {
      "NUM_FINANCIER_CD": 15902,
      "TXT_FINANCIER_NAME": "HALLMARK LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15903,
      "TXT_FINANCIER_NAME": "HARINDER HIRE PURCHASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15904,
      "TXT_FINANCIER_NAME": "HARINDER INVESTMENTS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15905,
      "TXT_FINANCIER_NAME": "HARINDER LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 15906,
      "TXT_FINANCIER_NAME": "HARSOLIA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15907,
      "TXT_FINANCIER_NAME": "HARYANA FINANCE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15908,
      "TXT_FINANCIER_NAME": "HARYANA STATE INDUSTRIAL DEVELOPMENT CORP."
    },
    {
      "NUM_FINANCIER_CD": 15909,
      "TXT_FINANCIER_NAME": "HASTAH FINANCES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15910,
      "TXT_FINANCIER_NAME": "HERITAGE FINLEASE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15911,
      "TXT_FINANCIER_NAME": "HERO FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15912,
      "TXT_FINANCIER_NAME": "HIM SIKHAR SAVING & FINANCE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15913,
      "TXT_FINANCIER_NAME": "ICICI SECURITIES AND FINANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15914,
      "TXT_FINANCIER_NAME": "IIT CAPITAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15915,
      "TXT_FINANCIER_NAME": "IKF FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15916,
      "TXT_FINANCIER_NAME": "IMPACT LEASING PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 15917,
      "TXT_FINANCIER_NAME": "INDIA CEMENTS CAPITAL & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15918,
      "TXT_FINANCIER_NAME": "INDIA LEASE DEVELOPMENT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15919,
      "TXT_FINANCIER_NAME": "INDIAN FINANCE AND FACTORS LTD."
    },
    {
      "NUM_FINANCIER_CD": 15920,
      "TXT_FINANCIER_NAME": "INDOCOUNT FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15921,
      "TXT_FINANCIER_NAME": "INFRASTRUCTURE LEASING & FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 15922,
      "TXT_FINANCIER_NAME": "INTEGRATED FINANCE CO LTD."
    },
    {
      "NUM_FINANCIER_CD": 15923,
      "TXT_FINANCIER_NAME": "INTERACT LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15924,
      "TXT_FINANCIER_NAME": "ISHAN FINLEASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15925,
      "TXT_FINANCIER_NAME": "ISLAND LEASING PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15926,
      "TXT_FINANCIER_NAME": "JAI BABA FINANCE CO. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15927,
      "TXT_FINANCIER_NAME": "JAIKA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15928,
      "TXT_FINANCIER_NAME": "JAIN MOTOR FINANCE & LEASING PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15929,
      "TXT_FINANCIER_NAME": "JALANDHAR CARGO ADVANCES (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 15930,
      "TXT_FINANCIER_NAME": "JARYAL MOTOR FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 15931,
      "TXT_FINANCIER_NAME": "JASSI HIRE PURCHASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15932,
      "TXT_FINANCIER_NAME": "JAY CEE FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 15933,
      "TXT_FINANCIER_NAME": "JAY GANESH INSTALMENTS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 15934,
      "TXT_FINANCIER_NAME": "JEBA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 15935,
      "TXT_FINANCIER_NAME": "JHAVERI CREDITS AND CAPITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 15936,
      "TXT_FINANCIER_NAME": "KANKARIA MANINAGAR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15937,
      "TXT_FINANCIER_NAME": "THE NARODA NAGRIK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15938,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO-OPRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15939,
      "TXT_FINANCIER_NAME": "THE NAVNIRMAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15940,
      "TXT_FINANCIER_NAME": "DAHANU ROAD JANATA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15941,
      "TXT_FINANCIER_NAME": "MARUTI UDYOG LTD."
    },
    {
      "NUM_FINANCIER_CD": 15942,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15943,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15944,
      "TXT_FINANCIER_NAME": "SUNDARAM FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15945,
      "TXT_FINANCIER_NAME": "THE KANGRA CENTRAL COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 15946,
      "TXT_FINANCIER_NAME": "Todi Investors"
    },
    {
      "NUM_FINANCIER_CD": 15947,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD, PNB AND ALL CONSORTIUM MEMBERS BANK"
    },
    {
      "NUM_FINANCIER_CD": 15948,
      "TXT_FINANCIER_NAME": "NANITAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15949,
      "TXT_FINANCIER_NAME": "South Indian Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 15950,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15951,
      "TXT_FINANCIER_NAME": "THE CHANDIGARH STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15952,
      "TXT_FINANCIER_NAME": "THE RATNAKAR BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15953,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15954,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15955,
      "TXT_FINANCIER_NAME": "NUTAN NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15956,
      "TXT_FINANCIER_NAME": "MAHINDRA FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 15957,
      "TXT_FINANCIER_NAME": "KANNUR DISTRICT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15958,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15959,
      "TXT_FINANCIER_NAME": "THE URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15960,
      "TXT_FINANCIER_NAME": "VAISHALI URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15961,
      "TXT_FINANCIER_NAME": "THE LAKSHMI VILAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15962,
      "TXT_FINANCIER_NAME": "THE NAINITAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15963,
      "TXT_FINANCIER_NAME": "THE SURAT PEOPLES CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15964,
      "TXT_FINANCIER_NAME": "THE SURAT PEOPLES CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15965,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15966,
      "TXT_FINANCIER_NAME": "KALBHAIRAVNATH PATHSANSTHA YAWAT"
    },
    {
      "NUM_FINANCIER_CD": 15967,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15968,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OPERATIVE BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 15969,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 15970,
      "TXT_FINANCIER_NAME": "SOPANKAKA SAHAKARI BANK LIMITED,SASWAD"
    },
    {
      "NUM_FINANCIER_CD": 15971,
      "TXT_FINANCIER_NAME": "THE JALGAON PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15972,
      "TXT_FINANCIER_NAME": "SARVODAYA COMMERCIAL CO-OPERATIVE BANK LTD. MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 15973,
      "TXT_FINANCIER_NAME": "Tamilnad Mercantile Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 15974,
      "TXT_FINANCIER_NAME": "SHREE MAHESH CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15975,
      "TXT_FINANCIER_NAME": "HUTATMA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15976,
      "TXT_FINANCIER_NAME": "BASSEIN CATHOLIC CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15977,
      "TXT_FINANCIER_NAME": "THE VYANKATESHWARA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15978,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO- OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15979,
      "TXT_FINANCIER_NAME": "THE AMRAVATI PEOPLES CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15980,
      "TXT_FINANCIER_NAME": "THE YAVATMAL UR. CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15981,
      "TXT_FINANCIER_NAME": "THE KHAMGAON UR. CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15982,
      "TXT_FINANCIER_NAME": "AKOLA JANTA COMM. CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15983,
      "TXT_FINANCIER_NAME": "SIDDHARTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15984,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OPERATIVE BANK LIMITED HARIDWAR"
    },
    {
      "NUM_FINANCIER_CD": 15985,
      "TXT_FINANCIER_NAME": "ASSOCIATE FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 15986,
      "TXT_FINANCIER_NAME": "The Grain Merchants Co-op Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 15987,
      "TXT_FINANCIER_NAME": "THE SARDARGUNJ MERCANTILE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15988,
      "TXT_FINANCIER_NAME": "THE JAMMU AND KASHMIR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15989,
      "TXT_FINANCIER_NAME": "FORD CREDIT KOTAK MAHINDRA LTD."
    },
    {
      "NUM_FINANCIER_CD": 15990,
      "TXT_FINANCIER_NAME": "Sree Anjaneya Co-op Bank Ltd.\\"
    },
    {
      "NUM_FINANCIER_CD": 15991,
      "TXT_FINANCIER_NAME": "Sree Anjaneya Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 15992,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAWJI UR.CO.OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 15993,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 15994,
      "TXT_FINANCIER_NAME": "BANK OFBARODA"
    },
    {
      "NUM_FINANCIER_CD": 15995,
      "TXT_FINANCIER_NAME": "SHRI CHHATRAPATI RAJARSHI SHAHU URBAN CO-OP BANK LTD.,BEED"
    },
    {
      "NUM_FINANCIER_CD": 15996,
      "TXT_FINANCIER_NAME": "POWER GRID CORPN OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 17528,
      "TXT_FINANCIER_NAME": "THE MEGHALAYA CO OPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17529,
      "TXT_FINANCIER_NAME": "INDIAN OVERSEAS BANK NOIDA"
    },
    {
      "NUM_FINANCIER_CD": 17530,
      "TXT_FINANCIER_NAME": "AGRIM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17531,
      "TXT_FINANCIER_NAME": "SRIRAM TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 17532,
      "TXT_FINANCIER_NAME": "THE CARDAMOM MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17533,
      "TXT_FINANCIER_NAME": "GRAIN MERCHANTS PATTINA SAHAKARA SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 17534,
      "TXT_FINANCIER_NAME": "SANAWAD NAGRIK SAHAKARI BANK MARYADIT SANAWAD"
    },
    {
      "NUM_FINANCIER_CD": 17535,
      "TXT_FINANCIER_NAME": "THE JAMKHANDI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17536,
      "TXT_FINANCIER_NAME": "SRI BASAVASHREE NOUKARARA PATTINA SAHAKARA SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 17537,
      "TXT_FINANCIER_NAME": "PANDIT DINDAYAL UPADHAYA GRAMIN BIGAR SHETI SAH PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 17538,
      "TXT_FINANCIER_NAME": "PUNE CANTONMENT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17539,
      "TXT_FINANCIER_NAME": "VIDHARBHA KSHETRIYA GRAMIN BANK AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 17540,
      "TXT_FINANCIER_NAME": "SILK CITY URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17541,
      "TXT_FINANCIER_NAME": "YASHWANT NAGARI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17542,
      "TXT_FINANCIER_NAME": "DR JAIPRAKASH MUNDADA URCOOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17543,
      "TXT_FINANCIER_NAME": "DR JAIPRAKASH MUNDADA UR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17544,
      "TXT_FINANCIER_NAME": "KERALA STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17545,
      "TXT_FINANCIER_NAME": "THE MULLAKODI CO OP RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 17546,
      "TXT_FINANCIER_NAME": "THE BODELI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17547,
      "TXT_FINANCIER_NAME": "THE CHANDWAD MERCHANTS CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17548,
      "TXT_FINANCIER_NAME": "ABHYUDAYA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17549,
      "TXT_FINANCIER_NAME": "NAGNATH URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17550,
      "TXT_FINANCIER_NAME": "NAGNATH URBAN CO-OP BANK LTD HINGOLI"
    },
    {
      "NUM_FINANCIER_CD": 17551,
      "TXT_FINANCIER_NAME": "PEOPLES CO-OPERATIVE BANK LTD.,DHOLKA."
    },
    {
      "NUM_FINANCIER_CD": 17552,
      "TXT_FINANCIER_NAME": "ING VYASA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17553,
      "TXT_FINANCIER_NAME": "THE AKOLA URBAN CO-OP. BANK LTD., AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 17554,
      "TXT_FINANCIER_NAME": "ANAND MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17555,
      "TXT_FINANCIER_NAME": "GMAC TCFC FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17556,
      "TXT_FINANCIER_NAME": "MAHARANA PRATAP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17557,
      "TXT_FINANCIER_NAME": "SUMERPUR MERCANTILE URBAN COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17558,
      "TXT_FINANCIER_NAME": "THRISSUR DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17559,
      "TXT_FINANCIER_NAME": "THE SHIGGAON URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17560,
      "TXT_FINANCIER_NAME": "KASUNDIA CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17561,
      "TXT_FINANCIER_NAME": "THE ZOROASTRIAN COOPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17562,
      "TXT_FINANCIER_NAME": "THE JALGAON DISTRICT CENTRAL CO.OPP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17563,
      "TXT_FINANCIER_NAME": "DEOGIRI NAGARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17564,
      "TXT_FINANCIER_NAME": "THE JALANDHAR CENTRAL COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17565,
      "TXT_FINANCIER_NAME": "THE KASARGOD DISTRICT CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17566,
      "TXT_FINANCIER_NAME": "SHRI SIDDHESHWAR CO OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 17567,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA, ASHOK MARG"
    },
    {
      "NUM_FINANCIER_CD": 17568,
      "TXT_FINANCIER_NAME": "SRI BASAVESHWAR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17569,
      "TXT_FINANCIER_NAME": "YES BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17570,
      "TXT_FINANCIER_NAME": "THE BIJAPUR MAHALAXMI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17571,
      "TXT_FINANCIER_NAME": "SHRI SHANTAPPANNA MIRAJI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17572,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17573,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 17574,
      "TXT_FINANCIER_NAME": "THE JAMKHANDI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17575,
      "TXT_FINANCIER_NAME": "THE AMRAVATI ZILLA PARISHAD SHIKSHAK SAHKARI BANK MARYADIT, AMRAVATI"
    },
    {
      "NUM_FINANCIER_CD": 17576,
      "TXT_FINANCIER_NAME": "SIKAR KENDRIYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17577,
      "TXT_FINANCIER_NAME": "Jammu and Kashmir"
    },
    {
      "NUM_FINANCIER_CD": 17578,
      "TXT_FINANCIER_NAME": "STANDARD CHARTERED BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 17579,
      "TXT_FINANCIER_NAME": "BHARAT OVERSEAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 17580,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM DBS FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 17581,
      "TXT_FINANCIER_NAME": "THE SATARA DISTRICT MERCHANT CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17582,
      "TXT_FINANCIER_NAME": "WAYANAD DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17583,
      "TXT_FINANCIER_NAME": "JAWAHAR SAHAKARI BANK LTD HUPARI"
    },
    {
      "NUM_FINANCIER_CD": 17584,
      "TXT_FINANCIER_NAME": "THE UTTARPARA COOPERATIVE BANK LIMITED, UTTARPARA"
    },
    {
      "NUM_FINANCIER_CD": 17585,
      "TXT_FINANCIER_NAME": "THE SARVODAYA SAHAKARI BANK LTD.MODASA"
    },
    {
      "NUM_FINANCIER_CD": 17586,
      "TXT_FINANCIER_NAME": "NAGARIK SAMABAY BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17587,
      "TXT_FINANCIER_NAME": "THE BANK EMPLOYEES CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17588,
      "TXT_FINANCIER_NAME": "THE BANK EMPLOYEES CO-OPEARATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17589,
      "TXT_FINANCIER_NAME": "NAINITAL DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17590,
      "TXT_FINANCIER_NAME": "ING-VAISYA"
    },
    {
      "NUM_FINANCIER_CD": 17591,
      "TXT_FINANCIER_NAME": "THE SEVALIA URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17592,
      "TXT_FINANCIER_NAME": "THE ACHALPUR URBAN CO-OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 17593,
      "TXT_FINANCIER_NAME": "THE HONAVAR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17594,
      "TXT_FINANCIER_NAME": "BAILHONGAL URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17595,
      "TXT_FINANCIER_NAME": "THE VIZIANAGARAM CO-OPERATIVE URBEN BANK TD"
    },
    {
      "NUM_FINANCIER_CD": 17596,
      "TXT_FINANCIER_NAME": "MATHURA ZILA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17597,
      "TXT_FINANCIER_NAME": "DHANASHREE URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17598,
      "TXT_FINANCIER_NAME": "KOTAK MAHENDA PRIM LTD."
    },
    {
      "NUM_FINANCIER_CD": 17599,
      "TXT_FINANCIER_NAME": "THE SANTRAGACHI CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17600,
      "TXT_FINANCIER_NAME": "SHALIBHADRA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17601,
      "TXT_FINANCIER_NAME": "THE KOSAMBA MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17602,
      "TXT_FINANCIER_NAME": "STC OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17603,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM FINANCE AND INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 17604,
      "TXT_FINANCIER_NAME": "A U Financiers India Pvt Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17605,
      "TXT_FINANCIER_NAME": "KONE ELEVATOR INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17606,
      "TXT_FINANCIER_NAME": "ALD AUTOMOTIVE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17607,
      "TXT_FINANCIER_NAME": "COCHIN SHIPYARD LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17608,
      "TXT_FINANCIER_NAME": "magma shrachi finance ltd"
    },
    {
      "NUM_FINANCIER_CD": 17609,
      "TXT_FINANCIER_NAME": "THE MALLAPUR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17610,
      "TXT_FINANCIER_NAME": "SHIVSHAKTI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17611,
      "TXT_FINANCIER_NAME": "KADIRUR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17612,
      "TXT_FINANCIER_NAME": "THE KADIRUR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17613,
      "TXT_FINANCIER_NAME": "godfrey phillips india ltd"
    },
    {
      "NUM_FINANCIER_CD": 17614,
      "TXT_FINANCIER_NAME": "SHIVA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 17615,
      "TXT_FINANCIER_NAME": "MAHAVEER CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 17616,
      "TXT_FINANCIER_NAME": "THE VALSAD MAHILA NAGRIK SAHAKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17617,
      "TXT_FINANCIER_NAME": "D H FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17618,
      "TXT_FINANCIER_NAME": "JAYSINGPUR VYAPARI NAGARI SAH PATH SANS MARYA"
    },
    {
      "NUM_FINANCIER_CD": 17619,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17620,
      "TXT_FINANCIER_NAME": "THE NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17621,
      "TXT_FINANCIER_NAME": "BIR MOTOR FINANCE AND LEASING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 17622,
      "TXT_FINANCIER_NAME": "DWARKADAS MANTRI NAGARI SAHAKARE BANK LTD BR AMBEJOGAI"
    },
    {
      "NUM_FINANCIER_CD": 17623,
      "TXT_FINANCIER_NAME": "SHIVA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12883,
      "TXT_FINANCIER_NAME": "TS TRANSCO VIDYUTH SOUDHA HYDERABAD"
    },
    {
      "NUM_FINANCIER_CD": 12884,
      "TXT_FINANCIER_NAME": "SAHAKAR URBAN CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12885,
      "TXT_FINANCIER_NAME": "WARWAND VIVIDH KARYAKARI SEVA SAHKARI SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12886,
      "TXT_FINANCIER_NAME": "THE NIZAR SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12887,
      "TXT_FINANCIER_NAME": "SHRI BHANSALI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 12888,
      "TXT_FINANCIER_NAME": "DYANESHWAR MAULI SAHAKARI PATH PEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12889,
      "TXT_FINANCIER_NAME": "YOGA NANDINI AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 12890,
      "TXT_FINANCIER_NAME": "ARIHANT MULTI ST CO OP CR SO LTD"
    },
    {
      "NUM_FINANCIER_CD": 12891,
      "TXT_FINANCIER_NAME": "CANARA BANK OVERSEAS"
    },
    {
      "NUM_FINANCIER_CD": 12892,
      "TXT_FINANCIER_NAME": "ROYAL FINANCIAL SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 12893,
      "TXT_FINANCIER_NAME": "BHERUNATH INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12894,
      "TXT_FINANCIER_NAME": "PRASHANT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12895,
      "TXT_FINANCIER_NAME": "ARAVALI POWER COMPANY PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12896,
      "TXT_FINANCIER_NAME": "ZAMIA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12897,
      "TXT_FINANCIER_NAME": "HAR MILAP MOTORS AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12898,
      "TXT_FINANCIER_NAME": "AMRELI JILA MADHYASTH SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 12899,
      "TXT_FINANCIER_NAME": "JAN KALYAN PRATHMIK KRUSHI PATTIN SAHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 12900,
      "TXT_FINANCIER_NAME": "OM SRI SAI BALAJI CREDIT SOUHARDHA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12901,
      "TXT_FINANCIER_NAME": "ANJANEYA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12902,
      "TXT_FINANCIER_NAME": "PARTH INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12903,
      "TXT_FINANCIER_NAME": "GURUPURA VYAVASAYA SEVA SAHAKARI BANK N"
    },
    {
      "NUM_FINANCIER_CD": 12904,
      "TXT_FINANCIER_NAME": "PARAMAHAMSA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12905,
      "TXT_FINANCIER_NAME": "SNEHA SAGARA SOUHARDHA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12906,
      "TXT_FINANCIER_NAME": "KERALA STATE WOMENS DEVELOPEMENT CORPORTATION"
    },
    {
      "NUM_FINANCIER_CD": 12907,
      "TXT_FINANCIER_NAME": "SUPRAJA SOUHARDHA CREDIT CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12908,
      "TXT_FINANCIER_NAME": "KAMADHENU MAHILA MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12909,
      "TXT_FINANCIER_NAME": "THE HELLAR CO OP AGRI MULTIPURPOSE"
    },
    {
      "NUM_FINANCIER_CD": 12910,
      "TXT_FINANCIER_NAME": "ILKAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12911,
      "TXT_FINANCIER_NAME": "SHRI GANESH MULTI PURPOSE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12912,
      "TXT_FINANCIER_NAME": "VITTHAL RUKMINI SAHAKARI PATHPHEDI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12913,
      "TXT_FINANCIER_NAME": "JAYSHANKAR KALKUNDRIKAR MULTI PURPOSE SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12914,
      "TXT_FINANCIER_NAME": "SRI GAYATHRI PATTINA SAHAKARA SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 12915,
      "TXT_FINANCIER_NAME": "SAI GANESH SOUHARDA CR CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12916,
      "TXT_FINANCIER_NAME": "KONGU AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12917,
      "TXT_FINANCIER_NAME": "KANRATAN INVESTMENTS AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12918,
      "TXT_FINANCIER_NAME": "HARIPRIYA CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12919,
      "TXT_FINANCIER_NAME": "DAYANAND URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12920,
      "TXT_FINANCIER_NAME": "PANDITPUR SAMABAY KRISHI UNNAYAN SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12921,
      "TXT_FINANCIER_NAME": "THE DHARGAL URBAN CO OPERATIVE CREDIT SOCEITY"
    },
    {
      "NUM_FINANCIER_CD": 12922,
      "TXT_FINANCIER_NAME": "PANCHMAHAL TELECOM CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12923,
      "TXT_FINANCIER_NAME": "UMIYA URBAN COOPERATIVE BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12924,
      "TXT_FINANCIER_NAME": "SANYUKT SAHAYAK SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12925,
      "TXT_FINANCIER_NAME": "MANGAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12926,
      "TXT_FINANCIER_NAME": "LIONS NAGRI SHAKARI PAT MAR"
    },
    {
      "NUM_FINANCIER_CD": 12927,
      "TXT_FINANCIER_NAME": "THE YAMUNA BACHAT DHIRAN SAH MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12928,
      "TXT_FINANCIER_NAME": "THE VANTADA SUKA GROUP VI KA SE SAH MAN"
    },
    {
      "NUM_FINANCIER_CD": 12929,
      "TXT_FINANCIER_NAME": "THE CHICHANO GROUP SEVA SAH MAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 12930,
      "TXT_FINANCIER_NAME": "BHUASAHEB BIRAJDAR N S B LTD LOHARA"
    },
    {
      "NUM_FINANCIER_CD": 12931,
      "TXT_FINANCIER_NAME": "DAYAL BAGH MAHILA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 12932,
      "TXT_FINANCIER_NAME": "BURDWAN COOPERATIVE AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12933,
      "TXT_FINANCIER_NAME": "THE VELGUEM URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12934,
      "TXT_FINANCIER_NAME": "GARSIYAN NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12935,
      "TXT_FINANCIER_NAME": "RAJAN AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 12936,
      "TXT_FINANCIER_NAME": "SAI AADARSH MULTI STATE CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 12937,
      "TXT_FINANCIER_NAME": "JILA SAHKARI MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 12938,
      "TXT_FINANCIER_NAME": "MURUGAN CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 12939,
      "TXT_FINANCIER_NAME": "SHRI PADMAVATI MATA MULTI STATE CO OP CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12940,
      "TXT_FINANCIER_NAME": "SWARIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12941,
      "TXT_FINANCIER_NAME": "VALLI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12942,
      "TXT_FINANCIER_NAME": "INDUS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12943,
      "TXT_FINANCIER_NAME": "SHRI SHANESHWAR GRAMIN BIAGAR SHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12944,
      "TXT_FINANCIER_NAME": "R M CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12945,
      "TXT_FINANCIER_NAME": "HIRE PURCHASE WITH SUNDARAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 12946,
      "TXT_FINANCIER_NAME": "TULJA BHAVANI NAGARI SAHKARI PATHPEDI"
    },
    {
      "NUM_FINANCIER_CD": 12947,
      "TXT_FINANCIER_NAME": "MANJESHWAR SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 12948,
      "TXT_FINANCIER_NAME": "C JABAR CHAND BOKDIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 12949,
      "TXT_FINANCIER_NAME": "SREE SREEKANTESWARA CREDIT CO OP SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12950,
      "TXT_FINANCIER_NAME": "PANDURANG GRAMIN BIGARSHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 12951,
      "TXT_FINANCIER_NAME": "VIKAS AUTO CREDIT"
    },
    {
      "NUM_FINANCIER_CD": 12952,
      "TXT_FINANCIER_NAME": "SAMBHAV INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 12953,
      "TXT_FINANCIER_NAME": "SATISH CHAND SISODIYA"
    },
    {
      "NUM_FINANCIER_CD": 12954,
      "TXT_FINANCIER_NAME": "SHIVDHAN GRAMIN BIGERSHETI SAHKARI PATPEDI MARYDT"
    },
    {
      "NUM_FINANCIER_CD": 12955,
      "TXT_FINANCIER_NAME": "BAJPE AIRPORT TOURIST AND TAXI DRIVERS OWNERS MULTIPURPOSE CO OPERATIVE SOCIETY LTD BANK"
    },
    {
      "NUM_FINANCIER_CD": 12956,
      "TXT_FINANCIER_NAME": "MAHAVEER CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12957,
      "TXT_FINANCIER_NAME": "CHANDANAPPALLY SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 12958,
      "TXT_FINANCIER_NAME": "UJWALA JYOTHI HIRE PURCHASE FINANCE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 12959,
      "TXT_FINANCIER_NAME": "KSARA CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 12960,
      "TXT_FINANCIER_NAME": "TUNGA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12961,
      "TXT_FINANCIER_NAME": "THE HATKESHWAR BACHAT ANE DHIRAN KARNARI SHAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12962,
      "TXT_FINANCIER_NAME": "SHRI GUDALESHWAR GRAMIN BIGAR SHETI SAH PAT SANSTHA GUDAL"
    },
    {
      "NUM_FINANCIER_CD": 12963,
      "TXT_FINANCIER_NAME": "ISHANI CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 12964,
      "TXT_FINANCIER_NAME": "TULJAI FIN"
    },
    {
      "NUM_FINANCIER_CD": 12965,
      "TXT_FINANCIER_NAME": "WORLD WIDE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12966,
      "TXT_FINANCIER_NAME": "A U FINANCIER LTD"
    },
    {
      "NUM_FINANCIER_CD": 12967,
      "TXT_FINANCIER_NAME": "RAITARA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 12968,
      "TXT_FINANCIER_NAME": "UPPINANGADY CO OPERATIVE AGRICULTURAL SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12969,
      "TXT_FINANCIER_NAME": "SHIVSAMARTH SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 12970,
      "TXT_FINANCIER_NAME": "SRI MODI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 12971,
      "TXT_FINANCIER_NAME": "PORORA SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 12972,
      "TXT_FINANCIER_NAME": "UTKARSHA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 12973,
      "TXT_FINANCIER_NAME": "KCCF EMPLOYEES CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12974,
      "TXT_FINANCIER_NAME": "MUNDUR AGRICULTURAL SERVICE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12975,
      "TXT_FINANCIER_NAME": "SABUJ SAMABAY KRISHI UNNAYAN SAMITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 12976,
      "TXT_FINANCIER_NAME": "SHREE CHONDESHWAR VIDYUT CHALIT MAGGAL NEKAR UTPADAKAR SAHAKARI SANGH"
    },
    {
      "NUM_FINANCIER_CD": 14379,
      "TXT_FINANCIER_NAME": "JAYDEV INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14380,
      "TXT_FINANCIER_NAME": "NUZIVEEDU SEEDS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14381,
      "TXT_FINANCIER_NAME": "RAJTORAN GRAMIN BIGARSHETI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14382,
      "TXT_FINANCIER_NAME": "AUROFIN URBAN COOP CREDIT SOCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14383,
      "TXT_FINANCIER_NAME": "SARITA ENTERPRISES AUTOMOBILES FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14384,
      "TXT_FINANCIER_NAME": "SRI VELU TRANSPORT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14385,
      "TXT_FINANCIER_NAME": "THE BANGALORE BANGALORE RURAL AND RAMANAGARA DISTRICT COOP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14386,
      "TXT_FINANCIER_NAME": "HASSAN CO OPERATIVE SOCEITY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14387,
      "TXT_FINANCIER_NAME": "MAHAVEERA VIVIDHODHESHA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14388,
      "TXT_FINANCIER_NAME": "CRODA INDIA COMPANY PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 14389,
      "TXT_FINANCIER_NAME": "THE POKHI GRAM SERVICE CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14390,
      "TXT_FINANCIER_NAME": "SHRI BIROBA MAHARAJ SAHAKARI PAT SAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14391,
      "TXT_FINANCIER_NAME": "DEEPAK GAUD"
    },
    {
      "NUM_FINANCIER_CD": 14392,
      "TXT_FINANCIER_NAME": "KUNTAGANI GROUP GRAMAGALA SEVA SAHAKARI SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 14393,
      "TXT_FINANCIER_NAME": "SAMRUDHA JEEVAN MULTI STATE MULTI PURPOSE CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14394,
      "TXT_FINANCIER_NAME": "SHREE KRISHNA LEASING"
    },
    {
      "NUM_FINANCIER_CD": 14395,
      "TXT_FINANCIER_NAME": "KRISHNANAGAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14396,
      "TXT_FINANCIER_NAME": "THE SULLIA CO OPERATIVE AGRICULTURE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14397,
      "TXT_FINANCIER_NAME": "THE KULTHAM CO OP MULTIPURPOSE AGRI SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14398,
      "TXT_FINANCIER_NAME": "MUMBAI TAXI ASSOCIATIONS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14399,
      "TXT_FINANCIER_NAME": "ZTEK CONSULTING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14400,
      "TXT_FINANCIER_NAME": "KERELA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 14401,
      "TXT_FINANCIER_NAME": "SHREE BHAUSAHEB THORAT AMRUTVAHINI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14402,
      "TXT_FINANCIER_NAME": "SRI THIRUMURUGAN MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14403,
      "TXT_FINANCIER_NAME": "SHREE MANGAI MULIPURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14404,
      "TXT_FINANCIER_NAME": "MUDUR VYAVASAYA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14405,
      "TXT_FINANCIER_NAME": "SHREE MANGAI MULTIPURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 14406,
      "TXT_FINANCIER_NAME": "RAIGAD ZILLA PARISHAD KARMACHARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 14407,
      "TXT_FINANCIER_NAME": "CHIEF ENGINEER CONSTRUCTION"
    },
    {
      "NUM_FINANCIER_CD": 14408,
      "TXT_FINANCIER_NAME": "NAMDEV FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14409,
      "TXT_FINANCIER_NAME": "KALBURGI AND YADGIR DCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14410,
      "TXT_FINANCIER_NAME": "ZEN LEFIN PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14411,
      "TXT_FINANCIER_NAME": "LAXMI SARASWATI PATTINA SAHAKARA SANGHA NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14412,
      "TXT_FINANCIER_NAME": "HAGARIBOMMANAHALLI TALUK SAHAKARA KRUSHI MATTU GRAMIN ABHIVRUDDI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 14413,
      "TXT_FINANCIER_NAME": "SHRI RAM MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14414,
      "TXT_FINANCIER_NAME": "THE PARAS CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14415,
      "TXT_FINANCIER_NAME": "CIDCO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14416,
      "TXT_FINANCIER_NAME": "THE CHICHANO GROUP SEVA SEVA SAH MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14417,
      "TXT_FINANCIER_NAME": "NTPC HYDRO LTD"
    },
    {
      "NUM_FINANCIER_CD": 14418,
      "TXT_FINANCIER_NAME": "THE AJAY CO OPP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14419,
      "TXT_FINANCIER_NAME": "THE SARDAR CO OPP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14420,
      "TXT_FINANCIER_NAME": "EKTARPUR MADANMOHAN BARHJANA KALYAN SAMABY KRISHI UNNAYAN SAMITI LTD"
    },
    {
      "NUM_FINANCIER_CD": 14421,
      "TXT_FINANCIER_NAME": "JASPREET SINGH BANGA"
    },
    {
      "NUM_FINANCIER_CD": 14422,
      "TXT_FINANCIER_NAME": "SHRI GANESH SAKH SAH MAYDT"
    },
    {
      "NUM_FINANCIER_CD": 14423,
      "TXT_FINANCIER_NAME": "SHREE CHINTAMANI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14424,
      "TXT_FINANCIER_NAME": "NIDHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 14425,
      "TXT_FINANCIER_NAME": "MAHEHSWAR MULTI STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14426,
      "TXT_FINANCIER_NAME": "THE YASHWANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14427,
      "TXT_FINANCIER_NAME": "DHARASUR MARDINI NAGARI SAHAKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14428,
      "TXT_FINANCIER_NAME": "COMPTROLLER KARNATAKA VETERINARY ANIMAL AND FISHERIES SCIENCE UNIVERSITY BIDAR"
    },
    {
      "NUM_FINANCIER_CD": 14429,
      "TXT_FINANCIER_NAME": "SHREE KALOL LAD CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14430,
      "TXT_FINANCIER_NAME": "PATHANAPURAM PRIMARY CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 14431,
      "TXT_FINANCIER_NAME": "THE VIJAY ARBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14432,
      "TXT_FINANCIER_NAME": "THE ORISSA MINERALS DEVELOPMENT COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14433,
      "TXT_FINANCIER_NAME": "KHANIVALI ADIWASI V K SEVA SAH SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 14434,
      "TXT_FINANCIER_NAME": "SRI SIVA JYOTHI AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 14435,
      "TXT_FINANCIER_NAME": "SHRIGALAVAMAHARSHICREDITSOUDHARDSHAKARILTD"
    },
    {
      "NUM_FINANCIER_CD": 14436,
      "TXT_FINANCIER_NAME": "PERADY CO OP AGRICULTURAL BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14437,
      "TXT_FINANCIER_NAME": "HOSABETTU CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14438,
      "TXT_FINANCIER_NAME": "GOPALKRISHA CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14439,
      "TXT_FINANCIER_NAME": "CHARESHWAR GRAMIN BIGERSHETI SAH PATH MARYA"
    },
    {
      "NUM_FINANCIER_CD": 14440,
      "TXT_FINANCIER_NAME": "GOPAL KRISHNA CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14441,
      "TXT_FINANCIER_NAME": "GANIGA SOUHARDHA PATTHINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14442,
      "TXT_FINANCIER_NAME": "CHAKRESHWAR GRAMIN BIGERSHETI SAH PATH MARYA"
    },
    {
      "NUM_FINANCIER_CD": 14443,
      "TXT_FINANCIER_NAME": "MONEY PLANT MULTIPURPOSE SOUHARDA CO OP NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 14444,
      "TXT_FINANCIER_NAME": "THE UNAGATLA LARGE SIZED CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14445,
      "TXT_FINANCIER_NAME": "AHALYA MONEY EXCHANGE AND FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14446,
      "TXT_FINANCIER_NAME": "R MOHAN LAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 14447,
      "TXT_FINANCIER_NAME": "ST JOSEPH SADHUJANA SANGHAM"
    },
    {
      "NUM_FINANCIER_CD": 14448,
      "TXT_FINANCIER_NAME": "DHANSHRI URBAN CO OP BANK LTD ATHANI"
    },
    {
      "NUM_FINANCIER_CD": 14449,
      "TXT_FINANCIER_NAME": "KERAL STATE HANDICAPPED PERSONS WELFARE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 14450,
      "TXT_FINANCIER_NAME": "SADU SANGAMMA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14451,
      "TXT_FINANCIER_NAME": "KULESHWAR GRAMIN PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14452,
      "TXT_FINANCIER_NAME": "SHRIMATI PREMLAKAKI CHAVAN GRAMIN BIGERSHETI SAHAKRI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14453,
      "TXT_FINANCIER_NAME": "BANAHATTI HATAGAR CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14454,
      "TXT_FINANCIER_NAME": "SRI RAMAPRIYA SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14455,
      "TXT_FINANCIER_NAME": "KANIKA INTERNATIONAL PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14456,
      "TXT_FINANCIER_NAME": "ATUL NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 14457,
      "TXT_FINANCIER_NAME": "MANJUNATHA SOUHARDA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 14458,
      "TXT_FINANCIER_NAME": "LEKSHMI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 14459,
      "TXT_FINANCIER_NAME": "UDUPI JOGI SAMAJA VIVIDODDESHA SAHAKARA SANGHA SAHAKARA LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 14460,
      "TXT_FINANCIER_NAME": "MUKTA PATTINA SOUDHARDA SAHAKARINI"
    },
    {
      "NUM_FINANCIER_CD": 14461,
      "TXT_FINANCIER_NAME": "SINDHUDURG MUILTI PURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14462,
      "TXT_FINANCIER_NAME": "THE KOPALLE LSCS"
    },
    {
      "NUM_FINANCIER_CD": 14463,
      "TXT_FINANCIER_NAME": "SINDHUDURG MUIL PURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14464,
      "TXT_FINANCIER_NAME": "ADARSH MUIL PURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 14465,
      "TXT_FINANCIER_NAME": "WEST BENGAL MINORITIES DEVELOPMENT AND FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 14466,
      "TXT_FINANCIER_NAME": "TOPINKATTI SHREE MAHALAXMI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 14467,
      "TXT_FINANCIER_NAME": "DENSO INTERNATIONAL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 14468,
      "TXT_FINANCIER_NAME": "NEW COCHIN HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 15997,
      "TXT_FINANCIER_NAME": "AURANGABAD JALNA GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 15998,
      "TXT_FINANCIER_NAME": "HUTATMA SAHAKARI BANK LTD,WALWA"
    },
    {
      "NUM_FINANCIER_CD": 15999,
      "TXT_FINANCIER_NAME": "SHREE SAMARTH SAHAKARI BANK LTD; NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 16000,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SAHAKARI BANK LTD, PETH DIST-SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 16001,
      "TXT_FINANCIER_NAME": "YES BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16002,
      "TXT_FINANCIER_NAME": "BHAVANI URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16003,
      "TXT_FINANCIER_NAME": "ICHALKARANJI JANATA SAHAKARI BANK LTD.(SCHEDULED BANK)"
    },
    {
      "NUM_FINANCIER_CD": 16004,
      "TXT_FINANCIER_NAME": "THE AGRASEN CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16005,
      "TXT_FINANCIER_NAME": "RAMRAJYA SAHAKARI BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 16006,
      "TXT_FINANCIER_NAME": "ASHOK LEYLAND FINANCE A DIVISION OF INDUSIND BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16007,
      "TXT_FINANCIER_NAME": "SRI SHARADAMBA CO.OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16008,
      "TXT_FINANCIER_NAME": "MAHANAGAR NAGRIK SAHKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 16009,
      "TXT_FINANCIER_NAME": "JANSEWA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16010,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16011,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16012,
      "TXT_FINANCIER_NAME": "THE SANTRAMPUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16013,
      "TXT_FINANCIER_NAME": "THE SANAND URBAN CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16014,
      "TXT_FINANCIER_NAME": "SREENIDHI SOUHARDA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 16015,
      "TXT_FINANCIER_NAME": "JAMIA CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16016,
      "TXT_FINANCIER_NAME": "THE VITA MERCHANTS CO-OPERATIVE BANK LTD.VITA,DIST.SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 16017,
      "TXT_FINANCIER_NAME": "THE EZHUKONE SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16018,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT INDL.CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16019,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLEUM CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16020,
      "TXT_FINANCIER_NAME": "GOODLUCK ADVANCES & FINLEASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16021,
      "TXT_FINANCIER_NAME": "JIWAN JYOTI DEPOSITS & ADVANCES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16022,
      "TXT_FINANCIER_NAME": "JOHAL INVESTMENT PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16023,
      "TXT_FINANCIER_NAME": "KABSONS FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16024,
      "TXT_FINANCIER_NAME": "KAILASH AUTO FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16025,
      "TXT_FINANCIER_NAME": "KALYAN FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16026,
      "TXT_FINANCIER_NAME": "KAMAL AUTO FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16027,
      "TXT_FINANCIER_NAME": "KAMOD COMMERCIAL & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16028,
      "TXT_FINANCIER_NAME": "KANAK INSTALMENTS (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16029,
      "TXT_FINANCIER_NAME": "KANAKADURGA LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16030,
      "TXT_FINANCIER_NAME": "KARTAR LEASING PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16031,
      "TXT_FINANCIER_NAME": "KARUR GAYATHRI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16032,
      "TXT_FINANCIER_NAME": "KARVY CONSULTANTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16033,
      "TXT_FINANCIER_NAME": "KASTURI CAPITAL & FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16034,
      "TXT_FINANCIER_NAME": "KAVERI CREDITS ( INDIA) PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16035,
      "TXT_FINANCIER_NAME": "KAY ESS HIRE PURCHASE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16036,
      "TXT_FINANCIER_NAME": "KESAVAYA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16037,
      "TXT_FINANCIER_NAME": "KEY CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 16038,
      "TXT_FINANCIER_NAME": "KFIC FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16039,
      "TXT_FINANCIER_NAME": "KGK FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16040,
      "TXT_FINANCIER_NAME": "KHIWASARA FINANCE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16041,
      "TXT_FINANCIER_NAME": "KINETIC FINCAP LTD."
    },
    {
      "NUM_FINANCIER_CD": 16042,
      "TXT_FINANCIER_NAME": "KISSAN FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16043,
      "TXT_FINANCIER_NAME": "KJMC FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 16044,
      "TXT_FINANCIER_NAME": "KOGTA FINANCIAL (INDIA) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16045,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16046,
      "TXT_FINANCIER_NAME": "KOTHARI SAFE DEPOSITS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16047,
      "TXT_FINANCIER_NAME": "KULDIP FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16048,
      "TXT_FINANCIER_NAME": "L & T FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16049,
      "TXT_FINANCIER_NAME": "LAILA FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16050,
      "TXT_FINANCIER_NAME": "LAKSHMI GENERAL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16051,
      "TXT_FINANCIER_NAME": "LAKSHMI SARASWATHI MOTOR & GENERAL FINANCE (PVT) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16052,
      "TXT_FINANCIER_NAME": "LAMINA LEASING & FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 16053,
      "TXT_FINANCIER_NAME": "LEXICON FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 16054,
      "TXT_FINANCIER_NAME": "LFC HIRE PURCHASE COMPANY PVT.LTD."
    },
    {
      "NUM_FINANCIER_CD": 16055,
      "TXT_FINANCIER_NAME": "LIBRA FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 16056,
      "TXT_FINANCIER_NAME": "LIBRA LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 16057,
      "TXT_FINANCIER_NAME": "LSP FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16058,
      "TXT_FINANCIER_NAME": "LYCA FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16059,
      "TXT_FINANCIER_NAME": "MADAN FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16060,
      "TXT_FINANCIER_NAME": "MADRAS CREDIT AND INVESTMENTS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16061,
      "TXT_FINANCIER_NAME": "MAHAAN HIRE PURCHASE & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 16062,
      "TXT_FINANCIER_NAME": "MAHAVEER FINANCE INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 16063,
      "TXT_FINANCIER_NAME": "MAHAVEER LEAFIN & HOLDINGS LTD."
    },
    {
      "NUM_FINANCIER_CD": 16064,
      "TXT_FINANCIER_NAME": "MAHENDRA INSTALMENT SUPPLY (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 16065,
      "TXT_FINANCIER_NAME": "INDUSLAND BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16066,
      "TXT_FINANCIER_NAME": "INFOSYS TECHNOLOGIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 16067,
      "TXT_FINANCIER_NAME": "NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16068,
      "TXT_FINANCIER_NAME": "THE BANK OF RAJASTHAN LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16069,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE CO-OPERATIVE APEX BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16070,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16071,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO COP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16072,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16073,
      "TXT_FINANCIER_NAME": "COLOUR MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16074,
      "TXT_FINANCIER_NAME": "NORTH KANARA G.S.B. CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16075,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16076,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD, HP TO PNB AND ALL CONSORTIUM MEMBERS BANK"
    },
    {
      "NUM_FINANCIER_CD": 16077,
      "TXT_FINANCIER_NAME": "THE SURAT MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16078,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16079,
      "TXT_FINANCIER_NAME": "ING VYSYA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 16080,
      "TXT_FINANCIER_NAME": "THE PANCHKULA CENTRAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16081,
      "TXT_FINANCIER_NAME": "THE VISAKHAPATNAM CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16082,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FIN. SER. (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 16083,
      "TXT_FINANCIER_NAME": "CITIZENCREDIT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16084,
      "TXT_FINANCIER_NAME": "THE VYSYA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16085,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 16086,
      "TXT_FINANCIER_NAME": "THE ABHINAV SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16087,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16088,
      "TXT_FINANCIER_NAME": "THE NUTAN NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 16089,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16090,
      "TXT_FINANCIER_NAME": "INTEGRAL URBAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16091,
      "TXT_FINANCIER_NAME": "INTEGRAL URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16092,
      "TXT_FINANCIER_NAME": "ICICI BANKING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 16093,
      "TXT_FINANCIER_NAME": "DOMBIVLI NAGARI SAHAKARI BANK LTD DOMBIVLI"
    },
    {
      "NUM_FINANCIER_CD": 16094,
      "TXT_FINANCIER_NAME": "THE CITIZEN CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 16095,
      "TXT_FINANCIER_NAME": "SANMITRA MAHILA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 16096,
      "TXT_FINANCIER_NAME": "YASHWANT SAHAKARI BANK LTD MIRAJ"
    },
    {
      "NUM_FINANCIER_CD": 19554,
      "TXT_FINANCIER_NAME": "THE MAPUSA URBAN CO-OP BANK LTD BANK OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 19555,
      "TXT_FINANCIER_NAME": "THE MATTANCHERRY SARUAJANIK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19556,
      "TXT_FINANCIER_NAME": "THE MATTANCHERRY SARVAJANIK CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19557,
      "TXT_FINANCIER_NAME": "THE MEHMADABAD URBAN PEOPLES CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19558,
      "TXT_FINANCIER_NAME": "THE MEHSANA JILLA PANCHAYAT KARMACHARI CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19559,
      "TXT_FINANCIER_NAME": "The Mehsana Nagrik Sahkari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19560,
      "TXT_FINANCIER_NAME": "THE MEHSANA NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19561,
      "TXT_FINANCIER_NAME": "THE MEHSANA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19562,
      "TXT_FINANCIER_NAME": "THE MUNICIPAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19563,
      "TXT_FINANCIER_NAME": "THE MUNICIPAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19564,
      "TXT_FINANCIER_NAME": "THE NAGPUR MAHILA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19565,
      "TXT_FINANCIER_NAME": "MOTOR INDUSTRIES COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19566,
      "TXT_FINANCIER_NAME": "GUJARAT NARMADA VALLEY FARTILIZERS CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19567,
      "TXT_FINANCIER_NAME": "Sound finance pvt ltd"
    },
    {
      "NUM_FINANCIER_CD": 19568,
      "TXT_FINANCIER_NAME": "VARDHMAN FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 19569,
      "TXT_FINANCIER_NAME": "NASIK MERCHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19570,
      "TXT_FINANCIER_NAME": "PARAS FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19571,
      "TXT_FINANCIER_NAME": "SHARAD FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19572,
      "TXT_FINANCIER_NAME": "GAUTAM FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 19573,
      "TXT_FINANCIER_NAME": "Mahindra & Mahindra Fin. Ser. Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19574,
      "TXT_FINANCIER_NAME": "MAHINDRA FINANCIAL SERVICES LTD, CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 19575,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA F.S.LTD."
    },
    {
      "NUM_FINANCIER_CD": 19576,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN SER LTD A/C"
    },
    {
      "NUM_FINANCIER_CD": 19577,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19578,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN.SER.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19579,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19580,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19581,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 19582,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINC. SER. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19583,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN.SER.LTD.CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 19584,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19585,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINCIAL SERVICES LIMTED"
    },
    {
      "NUM_FINANCIER_CD": 19586,
      "TXT_FINANCIER_NAME": "Mahindra & Mahindra Fin Ser Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19587,
      "TXT_FINANCIER_NAME": "Mahindra & Mahindra Financial Service Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19588,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SERVICES LTD. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19589,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN.LTD"
    },
    {
      "NUM_FINANCIER_CD": 19590,
      "TXT_FINANCIER_NAME": "MAHINDRA FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19591,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SER. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19592,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINACIAL SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 19593,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SER. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19594,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN.SER.LTD"
    },
    {
      "NUM_FINANCIER_CD": 19595,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES INDIA LMITED"
    },
    {
      "NUM_FINANCIER_CD": 19596,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FIN SER LTD NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 19597,
      "TXT_FINANCIER_NAME": "KARNATKA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19598,
      "TXT_FINANCIER_NAME": "South Indian Bank Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 19599,
      "TXT_FINANCIER_NAME": "UNITED WESTERN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19600,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH SAHKARI GRAM VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19601,
      "TXT_FINANCIER_NAME": "VEERASHAIVA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19602,
      "TXT_FINANCIER_NAME": "NATIONAL HOUSING BANK"
    },
    {
      "NUM_FINANCIER_CD": 19603,
      "TXT_FINANCIER_NAME": "SHIVNERI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19604,
      "TXT_FINANCIER_NAME": "MORESHWAR NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 19605,
      "TXT_FINANCIER_NAME": "SREI INFRASTRUCTUR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19606,
      "TXT_FINANCIER_NAME": "STATE BANK OF MYSORE."
    },
    {
      "NUM_FINANCIER_CD": 19607,
      "TXT_FINANCIER_NAME": "state bank of mysore,"
    },
    {
      "NUM_FINANCIER_CD": 19608,
      "TXT_FINANCIER_NAME": "UCO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19609,
      "TXT_FINANCIER_NAME": "UNION BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 19610,
      "TXT_FINANCIER_NAME": "UNION BANK OF INDIA, ALAMBAGH"
    },
    {
      "NUM_FINANCIER_CD": 19611,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH GRMEENA VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19612,
      "TXT_FINANCIER_NAME": "ARAVALI KSHETRIVA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19613,
      "TXT_FINANCIER_NAME": "ARAVALI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19614,
      "TXT_FINANCIER_NAME": "AWADH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19615,
      "TXT_FINANCIER_NAME": "BARODA PURVI UTTAR PRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19616,
      "TXT_FINANCIER_NAME": "BARODA WESTERN U P GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19617,
      "TXT_FINANCIER_NAME": "DALMIA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19618,
      "TXT_FINANCIER_NAME": "SRI MAHARSHI VALMIKI CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 19619,
      "TXT_FINANCIER_NAME": "CENTRAL GODAVARI KRUSHAK SEVA KENDRA"
    },
    {
      "NUM_FINANCIER_CD": 19620,
      "TXT_FINANCIER_NAME": "BHAGYODAYA URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19621,
      "TXT_FINANCIER_NAME": "Manikpur Urban Co op Credit Society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19622,
      "TXT_FINANCIER_NAME": "KOTHARI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19623,
      "TXT_FINANCIER_NAME": "THE BANGALORE CITY COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19624,
      "TXT_FINANCIER_NAME": "BHAGYASHREE GRAMEEN BIGARSHETI SAHAKARI PATSA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19625,
      "TXT_FINANCIER_NAME": "SEVEN HILLS CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19626,
      "TXT_FINANCIER_NAME": "SHIVAJIRAO BHOSALE SAHAKHARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19627,
      "TXT_FINANCIER_NAME": "SHIVALIK MERCANTILE CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19628,
      "TXT_FINANCIER_NAME": "SHRAMIK SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19629,
      "TXT_FINANCIER_NAME": "SHREE BASAVESHWARA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 19630,
      "TXT_FINANCIER_NAME": "SHREE BHARAT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19631,
      "TXT_FINANCIER_NAME": "SHREE DHAMAN VIBHAG VIVIDH KARYAKARI SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 19632,
      "TXT_FINANCIER_NAME": "SHREE DHARATI CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19633,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN URBAN CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19634,
      "TXT_FINANCIER_NAME": "Shree Laxmi Mahila Sahkari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19635,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19636,
      "TXT_FINANCIER_NAME": "SHREE MAHESH CO.OP. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19637,
      "TXT_FINANCIER_NAME": "SHREE MAHESH SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19638,
      "TXT_FINANCIER_NAME": "DET NORSKE VERITAS AS MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 19639,
      "TXT_FINANCIER_NAME": "MAHUVA VIBHAG CO CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19640,
      "TXT_FINANCIER_NAME": "BRM LEASE AND CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19641,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19642,
      "TXT_FINANCIER_NAME": "KALPATARU INVESTMENTS AND FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 19643,
      "TXT_FINANCIER_NAME": "SAHU DHAN LAXMI FINANCE REG"
    },
    {
      "NUM_FINANCIER_CD": 19644,
      "TXT_FINANCIER_NAME": "THE JANTA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19645,
      "TXT_FINANCIER_NAME": "THE NANDURA URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19646,
      "TXT_FINANCIER_NAME": "THE NARODA NAGRIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19647,
      "TXT_FINANCIER_NAME": "THE NARODA NAGRIK CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19648,
      "TXT_FINANCIER_NAME": "THE NEW AGRA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19649,
      "TXT_FINANCIER_NAME": "THE NIPHAD URBAN CO- OP. BANK LTD. NIPHAD"
    },
    {
      "NUM_FINANCIER_CD": 19650,
      "TXT_FINANCIER_NAME": "THE ORISSA STATE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 21085,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI GRAMIN BIGARSHETI SAHAKARI PATASANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 21086,
      "TXT_FINANCIER_NAME": "TVS CREDIT SERVECES LTD"
    },
    {
      "NUM_FINANCIER_CD": 21087,
      "TXT_FINANCIER_NAME": "STANDARD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21088,
      "TXT_FINANCIER_NAME": "JUMBO FINVEST INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21089,
      "TXT_FINANCIER_NAME": "BUSSAN AUTO FINANCE INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21090,
      "TXT_FINANCIER_NAME": "AJMANI INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21091,
      "TXT_FINANCIER_NAME": "BALLALESHWAR SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21092,
      "TXT_FINANCIER_NAME": "PADMAVATI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21093,
      "TXT_FINANCIER_NAME": "NADAPURAM CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21094,
      "TXT_FINANCIER_NAME": "ASHA VAIBHAV CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21095,
      "TXT_FINANCIER_NAME": "SINDHUDURG DIST VISHYASAMAJ SAHAKARI PATH SAUSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21096,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH EASTERN POWER DISTRIBUTION COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21097,
      "TXT_FINANCIER_NAME": "PIMPRI CHINCHWAD NAGARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 21098,
      "TXT_FINANCIER_NAME": "JANSEVA SAHAKARI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21099,
      "TXT_FINANCIER_NAME": "PUNJAB MERCANTILE CREDIT SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 21100,
      "TXT_FINANCIER_NAME": "KOTA MAHILA NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21101,
      "TXT_FINANCIER_NAME": "VIDHARBHA KONKAN GRAMIN BANK BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 21102,
      "TXT_FINANCIER_NAME": "FUNDAMENTAL FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21103,
      "TXT_FINANCIER_NAME": "SRI LAXMI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21104,
      "TXT_FINANCIER_NAME": "ERNAKULAM DISTRICTPOLICE CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21105,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21106,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR PATTANA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21107,
      "TXT_FINANCIER_NAME": "MUKTADEVI GRAMIN BIGARSHETI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21108,
      "TXT_FINANCIER_NAME": "SMALL INDUSTRIES DEVELOPMENT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 21109,
      "TXT_FINANCIER_NAME": "SRI VIJAYARAYA SANGAMESHWARA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 21110,
      "TXT_FINANCIER_NAME": "MAHAVEER FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 21111,
      "TXT_FINANCIER_NAME": "GULSAN FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21112,
      "TXT_FINANCIER_NAME": "THE BIG KANCHEEPURAM CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21113,
      "TXT_FINANCIER_NAME": "SRI VIJAYALAKSHMI BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 21114,
      "TXT_FINANCIER_NAME": "SHREE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21115,
      "TXT_FINANCIER_NAME": "KANHUR PATHAR MULTISTATE CO OP CR SOCI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21116,
      "TXT_FINANCIER_NAME": "R D FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21117,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM INVESTMENT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21118,
      "TXT_FINANCIER_NAME": "JAI GURUDEV FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21119,
      "TXT_FINANCIER_NAME": "RYTHARA SEVA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 21120,
      "TXT_FINANCIER_NAME": "SKF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21121,
      "TXT_FINANCIER_NAME": "BHARTIYA MAHILA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21122,
      "TXT_FINANCIER_NAME": "GURU GANESH INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 21123,
      "TXT_FINANCIER_NAME": "SHRI SIDDESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21124,
      "TXT_FINANCIER_NAME": "K RAJ FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 21125,
      "TXT_FINANCIER_NAME": "THE KADAKKAL SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21126,
      "TXT_FINANCIER_NAME": "JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 21127,
      "TXT_FINANCIER_NAME": "BOBBY ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 21128,
      "TXT_FINANCIER_NAME": "GRAND MOTOR AND FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 21129,
      "TXT_FINANCIER_NAME": "SRI GANGADHARESHWARA SOUHARDA PATTINA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 21130,
      "TXT_FINANCIER_NAME": "THE GOA STATE COOP HOUSING FINANCE AND FEDERATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21131,
      "TXT_FINANCIER_NAME": "SHAH INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 21132,
      "TXT_FINANCIER_NAME": "PRAGATHI KRISHNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21133,
      "TXT_FINANCIER_NAME": "PNB RETAIL ASSET BRANCH GREEN PARK NW DELHI"
    },
    {
      "NUM_FINANCIER_CD": 21134,
      "TXT_FINANCIER_NAME": "TAVARAGERA PATTANA SOUHARDA SAHAKAR BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21135,
      "TXT_FINANCIER_NAME": "SUMERPUR MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21136,
      "TXT_FINANCIER_NAME": "THE CHHAPI NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21137,
      "TXT_FINANCIER_NAME": "SANGLI TIMBER MERCHANT NAGARI SAH PATHSA"
    },
    {
      "NUM_FINANCIER_CD": 21138,
      "TXT_FINANCIER_NAME": "MUTHOOT VEHICLES FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21139,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH NORTHERN POWER DISTRIBUTION CELL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21140,
      "TXT_FINANCIER_NAME": "SHIVJYOT GRAMEENA SAHAKARI PATSANSTHA LTD TURMURI"
    },
    {
      "NUM_FINANCIER_CD": 21141,
      "TXT_FINANCIER_NAME": "LEASE PLAN INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21142,
      "TXT_FINANCIER_NAME": "THE INSTITUTE OF CHARTERD ACCOUNTS OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 21143,
      "TXT_FINANCIER_NAME": "AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 21144,
      "TXT_FINANCIER_NAME": "THE THANE DISTRICT G S PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21145,
      "TXT_FINANCIER_NAME": "ONKAR TRANSFINS"
    },
    {
      "NUM_FINANCIER_CD": 21146,
      "TXT_FINANCIER_NAME": "KRUSHNAI SAHAKARI PATSANSTHA MYD WAI"
    },
    {
      "NUM_FINANCIER_CD": 21147,
      "TXT_FINANCIER_NAME": "SURAJ FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 21148,
      "TXT_FINANCIER_NAME": "MANGANGA SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21149,
      "TXT_FINANCIER_NAME": "ANNAPURNA NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21150,
      "TXT_FINANCIER_NAME": "SRI KANYAKAPARAMESHWARI CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21151,
      "TXT_FINANCIER_NAME": "THE KARNATAKA STATE CO OPERTIVE AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21152,
      "TXT_FINANCIER_NAME": "MANGALAM ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 21153,
      "TXT_FINANCIER_NAME": "GORESHWAR GRAMIN MULTI STATE CO OPERATIVE CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21154,
      "TXT_FINANCIER_NAME": "MUTHOOT VEHICLES AND ASSET FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21155,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21156,
      "TXT_FINANCIER_NAME": "SHIVSHAKTI URBAN CO OP BANK LTD BARSHI DIST SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 21157,
      "TXT_FINANCIER_NAME": "PURVANCHAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 21158,
      "TXT_FINANCIER_NAME": "SHRI SIDDHALING SWAMIJI CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21159,
      "TXT_FINANCIER_NAME": "N P R FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21160,
      "TXT_FINANCIER_NAME": "SHRI VARDHAMAN URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 21161,
      "TXT_FINANCIER_NAME": "PANDIT DINDAYAL NAGRI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21162,
      "TXT_FINANCIER_NAME": "SHREE KARNI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21163,
      "TXT_FINANCIER_NAME": "JOLARPET CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21164,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR MULTIPURPOSE SOUHARD SAH NYT BELGAVI"
    },
    {
      "NUM_FINANCIER_CD": 21165,
      "TXT_FINANCIER_NAME": "SHREE BHUVANENDRA SWAMIJI VIVIDHODESHA SAUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 21166,
      "TXT_FINANCIER_NAME": "SUSHIL SAHAKARI PATASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21167,
      "TXT_FINANCIER_NAME": "KANTOLIYA JUTH SEVA SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21168,
      "TXT_FINANCIER_NAME": "CAPITAL FIRST LTD"
    },
    {
      "NUM_FINANCIER_CD": 21169,
      "TXT_FINANCIER_NAME": "THE PRAGPUR CO OP AGRI MULTIPURPOSE SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21170,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT CO OP MILK PRODUCERS UNION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21171,
      "TXT_FINANCIER_NAME": "JIJAMATA MAHILA NAGARI BIGARSHETI SHAKARI PATHSANSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 21172,
      "TXT_FINANCIER_NAME": "SRI VENKATESWARA LARGE SIZED CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21173,
      "TXT_FINANCIER_NAME": "THE WARDHA DISTRICT ASHIRWAD MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21174,
      "TXT_FINANCIER_NAME": "CHENNAI METROPOLITAN WATER SUPPLY AND SEWERAGE BOARD"
    },
    {
      "NUM_FINANCIER_CD": 21175,
      "TXT_FINANCIER_NAME": "WHITEFIELD CAPITAL AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 21176,
      "TXT_FINANCIER_NAME": "S MAHENDRA KUMAR NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 21177,
      "TXT_FINANCIER_NAME": "BHARATIYA MAHILA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21178,
      "TXT_FINANCIER_NAME": "SRI SURYA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18104,
      "TXT_FINANCIER_NAME": "KASHIPUR URBAN CO-OPERTIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18105,
      "TXT_FINANCIER_NAME": "PITHORAGARH ZILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18106,
      "TXT_FINANCIER_NAME": "RAICHUR DISTRICT CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18107,
      "TXT_FINANCIER_NAME": "MOHOL URBAN CO-OP BANK LTD,MOHOL"
    },
    {
      "NUM_FINANCIER_CD": 18108,
      "TXT_FINANCIER_NAME": "JALGAON JANTA BANK"
    },
    {
      "NUM_FINANCIER_CD": 18109,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN STATE CO- OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18110,
      "TXT_FINANCIER_NAME": "THE CO-OPERATIVE BANK OF MEHSANA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18111,
      "TXT_FINANCIER_NAME": "GE MONEY"
    },
    {
      "NUM_FINANCIER_CD": 18112,
      "TXT_FINANCIER_NAME": "GE MONEY FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 18113,
      "TXT_FINANCIER_NAME": "KOZHIKODE DISTRICT CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18114,
      "TXT_FINANCIER_NAME": "THE KHANAPUR CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18115,
      "TXT_FINANCIER_NAME": "STATE BANK OF HYDERABAD,"
    },
    {
      "NUM_FINANCIER_CD": 18116,
      "TXT_FINANCIER_NAME": "KDCC BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18117,
      "TXT_FINANCIER_NAME": "KALLAPPANNA AWADE ICHALKARANJI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18118,
      "TXT_FINANCIER_NAME": "KALLAPPANNA AWADE ICHALKARANJI JANTA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18119,
      "TXT_FINANCIER_NAME": "THE PADIYOTCHAL SERVICE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18120,
      "TXT_FINANCIER_NAME": "THE AMRAVATI ZILLA PARISHAD SHIKSHAK SAHAKARI BANK LTD. AMRAVTI"
    },
    {
      "NUM_FINANCIER_CD": 18121,
      "TXT_FINANCIER_NAME": "THE BEED DISTRICT CENTRAL CO-OP.BANK LED.BEED"
    },
    {
      "NUM_FINANCIER_CD": 18122,
      "TXT_FINANCIER_NAME": "The Beed District Central Bank Ltd .Beed"
    },
    {
      "NUM_FINANCIER_CD": 18123,
      "TXT_FINANCIER_NAME": "THE HALOL MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18124,
      "TXT_FINANCIER_NAME": "UNION BANK OF INDIA."
    },
    {
      "NUM_FINANCIER_CD": 18125,
      "TXT_FINANCIER_NAME": "Magma Shrachi Fin.Ltd"
    },
    {
      "NUM_FINANCIER_CD": 18126,
      "TXT_FINANCIER_NAME": "TML FINANCIAL SECVICE LTD."
    },
    {
      "NUM_FINANCIER_CD": 18127,
      "TXT_FINANCIER_NAME": "SARDARGUNJ MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18128,
      "TXT_FINANCIER_NAME": "VIMAL CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18129,
      "TXT_FINANCIER_NAME": "ENGINEERS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18130,
      "TXT_FINANCIER_NAME": "DISTT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18131,
      "TXT_FINANCIER_NAME": "NOBLE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18132,
      "TXT_FINANCIER_NAME": "Udaipur Mahila Urban Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 18133,
      "TXT_FINANCIER_NAME": "SATTARI URBAN COOPERATIVE CREDIT SOCIETY LTD VALPOI"
    },
    {
      "NUM_FINANCIER_CD": 18134,
      "TXT_FINANCIER_NAME": "UTTARANCHAL GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18135,
      "TXT_FINANCIER_NAME": "SAIPEM INDIA PROJECT SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18136,
      "TXT_FINANCIER_NAME": "SAIPEM INDIA PROJECTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 18137,
      "TXT_FINANCIER_NAME": "ING VYSYA LIFE INSURANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18138,
      "TXT_FINANCIER_NAME": "SHRI MAHALAXMI NAGARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18139,
      "TXT_FINANCIER_NAME": "PRAGATHI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18140,
      "TXT_FINANCIER_NAME": "THE SOMAVAMSHA SAHASRARJUNA KSHATRIYA CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18141,
      "TXT_FINANCIER_NAME": "BURDWAN CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18142,
      "TXT_FINANCIER_NAME": "THE BARDEZ URBAN COOPT CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18143,
      "TXT_FINANCIER_NAME": "GAUTAM FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 18144,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18145,
      "TXT_FINANCIER_NAME": "KOTAK MAHENDRA PRIME LTD"
    },
    {
      "NUM_FINANCIER_CD": 18146,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRIAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18147,
      "TXT_FINANCIER_NAME": "THE PUNJAB STATE COOPERATIVE AGRICULTURE DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18148,
      "TXT_FINANCIER_NAME": "State Bank of India RACPC Lucknow"
    },
    {
      "NUM_FINANCIER_CD": 18149,
      "TXT_FINANCIER_NAME": "KEMPEGOWDA PATTANA SOWHARDHA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 18150,
      "TXT_FINANCIER_NAME": "THE HOSPET CO OPERATVIE CITY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18151,
      "TXT_FINANCIER_NAME": "ORIENTAL BANK OF COMMERCE VYSIAL STREET COIMBATORE"
    },
    {
      "NUM_FINANCIER_CD": 18152,
      "TXT_FINANCIER_NAME": "BARCLAYS BANK PCS"
    },
    {
      "NUM_FINANCIER_CD": 18153,
      "TXT_FINANCIER_NAME": "LESSOR ORIX AUTO INFRASTRUCTURE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18154,
      "TXT_FINANCIER_NAME": "SHREE BASAVESHWAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18155,
      "TXT_FINANCIER_NAME": "HIMACHAL MITRA MANDAL COOP"
    },
    {
      "NUM_FINANCIER_CD": 18156,
      "TXT_FINANCIER_NAME": "SHRI ANAND CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18157,
      "TXT_FINANCIER_NAME": "THE CO OPERATIVE BANK OF MEHSANA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18158,
      "TXT_FINANCIER_NAME": "JANSAGAR NAGARI SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18159,
      "TXT_FINANCIER_NAME": "SHREE KRUPA"
    },
    {
      "NUM_FINANCIER_CD": 18160,
      "TXT_FINANCIER_NAME": "CORPORATION BANK EHPL 050037"
    },
    {
      "NUM_FINANCIER_CD": 18161,
      "TXT_FINANCIER_NAME": "SHREE KALIKAMATA GRAMIN"
    },
    {
      "NUM_FINANCIER_CD": 18162,
      "TXT_FINANCIER_NAME": "DHANLAXMI CREDIT COOPSOCIETY LTDUNJHA"
    },
    {
      "NUM_FINANCIER_CD": 18163,
      "TXT_FINANCIER_NAME": "THE GAUHATI CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18164,
      "TXT_FINANCIER_NAME": "NAKODA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18165,
      "TXT_FINANCIER_NAME": "DEOGIRI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18166,
      "TXT_FINANCIER_NAME": "THE BROACH DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18167,
      "TXT_FINANCIER_NAME": "THE BARMER CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18168,
      "TXT_FINANCIER_NAME": "THE GADHINGLAJ URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18169,
      "TXT_FINANCIER_NAME": "THE NAVANIRMAN CO-OPEARATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18170,
      "TXT_FINANCIER_NAME": "NIRMAL URBAN CO-OP. BANK LTD. NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 18171,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR NAGARIK CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18172,
      "TXT_FINANCIER_NAME": "KERALA TRANSPORT DEV.FIN.CORPN,TRIVANDRUM"
    },
    {
      "NUM_FINANCIER_CD": 18173,
      "TXT_FINANCIER_NAME": "KERALA TRANSPORT DEV.FIN.CORPN"
    },
    {
      "NUM_FINANCIER_CD": 18174,
      "TXT_FINANCIER_NAME": "THE SANTRAMPUR URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18175,
      "TXT_FINANCIER_NAME": "NTPC LTD"
    },
    {
      "NUM_FINANCIER_CD": 18176,
      "TXT_FINANCIER_NAME": "ALMORA URBAN CO-OPEARTIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18177,
      "TXT_FINANCIER_NAME": "THE TIRUR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18178,
      "TXT_FINANCIER_NAME": "SAMARTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18179,
      "TXT_FINANCIER_NAME": "KASHIPUR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18180,
      "TXT_FINANCIER_NAME": "BANK OF TOKYO MITSUBISHI UFJ LTD"
    },
    {
      "NUM_FINANCIER_CD": 18181,
      "TXT_FINANCIER_NAME": "GONDAL NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18182,
      "TXT_FINANCIER_NAME": "URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18183,
      "TXT_FINANCIER_NAME": "AKHAND ANAND CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18184,
      "TXT_FINANCIER_NAME": "VIKAS URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18185,
      "TXT_FINANCIER_NAME": "DELHI NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18186,
      "TXT_FINANCIER_NAME": "ANNASAHEB PATIL URABAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18187,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD AND PNB AND CONSORTIUM BANK"
    },
    {
      "NUM_FINANCIER_CD": 18188,
      "TXT_FINANCIER_NAME": "KODIYATHOOR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18189,
      "TXT_FINANCIER_NAME": "BHEL EMPLOYEES COP"
    },
    {
      "NUM_FINANCIER_CD": 18190,
      "TXT_FINANCIER_NAME": "THE HONAVAR URBAN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18191,
      "TXT_FINANCIER_NAME": "CENTRAL BANK OF INDIA."
    },
    {
      "NUM_FINANCIER_CD": 18192,
      "TXT_FINANCIER_NAME": "THE KOTTAYAM DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18193,
      "TXT_FINANCIER_NAME": "PUNJAB STATE CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18194,
      "TXT_FINANCIER_NAME": "AMALGAMATED PLANTATIONS PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18195,
      "TXT_FINANCIER_NAME": "CHENNAI CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18196,
      "TXT_FINANCIER_NAME": "RAVI COMMERCIAL URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18197,
      "TXT_FINANCIER_NAME": "THE GRAIN MERCHANTS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18198,
      "TXT_FINANCIER_NAME": "MAGMA SHARACHI FIN. LTD"
    },
    {
      "NUM_FINANCIER_CD": 18199,
      "TXT_FINANCIER_NAME": "shree sadguru jagali maharaj sahakari bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 18200,
      "TXT_FINANCIER_NAME": "THE MAHARAJA CO-OP.URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19651,
      "TXT_FINANCIER_NAME": "JEEVAN VIKAS NAGRI SAHAKARI PATHPEDHI NIRMAL BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 19652,
      "TXT_FINANCIER_NAME": "THE DADASAHEB RAMRAO PATIL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19653,
      "TXT_FINANCIER_NAME": "G GOUTHAMCHAND NAHAR AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 19654,
      "TXT_FINANCIER_NAME": "Mahindra Finance"
    },
    {
      "NUM_FINANCIER_CD": 19655,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL LEASING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19656,
      "TXT_FINANCIER_NAME": "MAHINDRA FINANCE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19657,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19658,
      "TXT_FINANCIER_NAME": "Mahindra And Mahindra Financial Service Limited Mumbai"
    },
    {
      "NUM_FINANCIER_CD": 19659,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINAICAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19660,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19661,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD.S"
    },
    {
      "NUM_FINANCIER_CD": 19662,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19663,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCE SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19664,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SEVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19665,
      "TXT_FINANCIER_NAME": "Maruti Countrywide Auto Financial Services Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19666,
      "TXT_FINANCIER_NAME": "MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19667,
      "TXT_FINANCIER_NAME": "MIDLAND FINANCIERS (DOABA) PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19668,
      "TXT_FINANCIER_NAME": "MULAMOOTTIL LEASING & HIRE PURCHASE PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 19669,
      "TXT_FINANCIER_NAME": "MUTHOOT LEASING AND FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 19670,
      "TXT_FINANCIER_NAME": "NANDAMBAKKAM FINANCE CO PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19671,
      "TXT_FINANCIER_NAME": "NARANG LEASING AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19672,
      "TXT_FINANCIER_NAME": "DELHI STATE TAXI OPERATORS CO OP THRIFT CREDIT SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19673,
      "TXT_FINANCIER_NAME": "RAKESH CREDITS LTD"
    },
    {
      "NUM_FINANCIER_CD": 19674,
      "TXT_FINANCIER_NAME": "NAVNATH GRAMIN BIGARSHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19675,
      "TXT_FINANCIER_NAME": "CENTRAL GODAVARI KRUSHAK SEVA SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19676,
      "TXT_FINANCIER_NAME": "ICHALKARANJI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19677,
      "TXT_FINANCIER_NAME": "DENA GUJRAT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19678,
      "TXT_FINANCIER_NAME": "Dewas Shajapur Kshetriya Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 19679,
      "TXT_FINANCIER_NAME": "Etah Gramin Bank"
    },
    {
      "NUM_FINANCIER_CD": 19680,
      "TXT_FINANCIER_NAME": "GRAMIN KSHETRIYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19681,
      "TXT_FINANCIER_NAME": "JAIPUR NAGAUR AANCHALIK GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19682,
      "TXT_FINANCIER_NAME": "Karnatak Vikas Grameen Bank"
    },
    {
      "NUM_FINANCIER_CD": 19683,
      "TXT_FINANCIER_NAME": "KARNATAKA VIKAS GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19684,
      "TXT_FINANCIER_NAME": "THE HOTEL INDUSTRIALIST CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19685,
      "TXT_FINANCIER_NAME": "SAINATH NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19686,
      "TXT_FINANCIER_NAME": "THE SINDAGI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19687,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH SHEKARI GRAM VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19688,
      "TXT_FINANCIER_NAME": "UJJAIN PARASPAR SAHAKARIK BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 19689,
      "TXT_FINANCIER_NAME": "SHUBH LAXMI MAHILA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19690,
      "TXT_FINANCIER_NAME": "THE HUBLI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19691,
      "TXT_FINANCIER_NAME": "MOHINI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19692,
      "TXT_FINANCIER_NAME": "Shree Rajkot Dist. Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 19693,
      "TXT_FINANCIER_NAME": "Shree Sadguru Jangli Maharaj Sahakari Bank ltd., Chinchwad"
    },
    {
      "NUM_FINANCIER_CD": 19694,
      "TXT_FINANCIER_NAME": "SHREE SAMARTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19695,
      "TXT_FINANCIER_NAME": "SHREE SHARADA SAHAKARI BANK LTD., PUNE"
    },
    {
      "NUM_FINANCIER_CD": 19696,
      "TXT_FINANCIER_NAME": "SHREE SUVARNA SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19697,
      "TXT_FINANCIER_NAME": "SHREE TIRUPATI NAGARI SAHAKARI PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 19698,
      "TXT_FINANCIER_NAME": "Shree Warana Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19699,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19700,
      "TXT_FINANCIER_NAME": "SHRI BALBHIM CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19701,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 19702,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19703,
      "TXT_FINANCIER_NAME": "SHRI BHARAT URBAN COOP BANK LTD JAYSINGPUR"
    },
    {
      "NUM_FINANCIER_CD": 19704,
      "TXT_FINANCIER_NAME": "SHRI CHHATRAPATI RAJARSHI SHAHU URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19705,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 19706,
      "TXT_FINANCIER_NAME": "MADHYA PRADESH VIDHYUT MANDAL PARASPAR SAHKARI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19707,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19708,
      "TXT_FINANCIER_NAME": "BHIMASHANKAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19709,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA COMMERCIAL BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 19710,
      "TXT_FINANCIER_NAME": "ASSAM HIRE PURCHASE CO P LTD"
    },
    {
      "NUM_FINANCIER_CD": 19711,
      "TXT_FINANCIER_NAME": "THE PADIYOTCHAL SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19712,
      "TXT_FINANCIER_NAME": "THE PADRA NAGAR NAGRIK SAHAKRAI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19713,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19714,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19715,
      "TXT_FINANCIER_NAME": "THE PALI CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19716,
      "TXT_FINANCIER_NAME": "THE PANCHKULA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19717,
      "TXT_FINANCIER_NAME": "THE PANCHSHEEL MERCANTILE CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19718,
      "TXT_FINANCIER_NAME": "THE PANDHARPUR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19719,
      "TXT_FINANCIER_NAME": "The Panipat Urban Co-op Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19720,
      "TXT_FINANCIER_NAME": "THE PARBHANI PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19721,
      "TXT_FINANCIER_NAME": "THE PARSIK JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19722,
      "TXT_FINANCIER_NAME": "THE PATIALA CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19723,
      "TXT_FINANCIER_NAME": "THE PEN CO OP URBAN BANK LTD PEN RAIGAD"
    },
    {
      "NUM_FINANCIER_CD": 19724,
      "TXT_FINANCIER_NAME": "SHRI RAM TRANPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19725,
      "TXT_FINANCIER_NAME": "THE KANGRA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19726,
      "TXT_FINANCIER_NAME": "KUNBI SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 19727,
      "TXT_FINANCIER_NAME": "BILASPUR RAIPUR KSHETRIA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19728,
      "TXT_FINANCIER_NAME": "WINDPIPE FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19729,
      "TXT_FINANCIER_NAME": "BHARAT ELECTRONICS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19730,
      "TXT_FINANCIER_NAME": "HINDUSTAN ORGANIC CHEMICALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 19731,
      "TXT_FINANCIER_NAME": "NELCAST FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19732,
      "TXT_FINANCIER_NAME": "P C A and R D BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19733,
      "TXT_FINANCIER_NAME": "PKF FINANCE LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19734,
      "TXT_FINANCIER_NAME": "POST OFFICE SAVING BANK"
    },
    {
      "NUM_FINANCIER_CD": 19735,
      "TXT_FINANCIER_NAME": "POST OFFICE SAVINGS BANK."
    },
    {
      "NUM_FINANCIER_CD": 19736,
      "TXT_FINANCIER_NAME": "POWER FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19737,
      "TXT_FINANCIER_NAME": "PURBANCHAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19738,
      "TXT_FINANCIER_NAME": "RAJDHANI LEASING & INDUSTRIES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19739,
      "TXT_FINANCIER_NAME": "RAMAYAN AUTO FINANCIERS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 19740,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19741,
      "TXT_FINANCIER_NAME": "RISK CAPITAL AND TECHNOLOGY FINANCE CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 19742,
      "TXT_FINANCIER_NAME": "SANGHVI INVESTMENTS & LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 19743,
      "TXT_FINANCIER_NAME": "SATIN CREDITCARE NETWORK LTD.(FORMERLY SATIN LEASING & FINANCE LTD)"
    },
    {
      "NUM_FINANCIER_CD": 19744,
      "TXT_FINANCIER_NAME": "SEL VAGANPATHY LEASING & FINANCE PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 21179,
      "TXT_FINANCIER_NAME": "THE ACCOUNTANT GENERAL A AND E AP TELANGA HYD"
    },
    {
      "NUM_FINANCIER_CD": 21180,
      "TXT_FINANCIER_NAME": "THE GOA SHIPYARD EMPLOYEES COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21181,
      "TXT_FINANCIER_NAME": "SRINIVASA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21182,
      "TXT_FINANCIER_NAME": "BARDOLI JANTA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 21183,
      "TXT_FINANCIER_NAME": "MOKSHA JAIN"
    },
    {
      "NUM_FINANCIER_CD": 21184,
      "TXT_FINANCIER_NAME": "NORTH INDIA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21185,
      "TXT_FINANCIER_NAME": "PRIMARY LAND DELELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 21186,
      "TXT_FINANCIER_NAME": "RAJMATA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21187,
      "TXT_FINANCIER_NAME": "HONHAR INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 21188,
      "TXT_FINANCIER_NAME": "SREE BANASHANKARI MAHILLA CO OPREATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 21189,
      "TXT_FINANCIER_NAME": "SRI VENKATESHWARA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 21190,
      "TXT_FINANCIER_NAME": "THE BANK OF TOKYO MITSUBISHI UFJ LTD"
    },
    {
      "NUM_FINANCIER_CD": 21191,
      "TXT_FINANCIER_NAME": "WARANGAL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21192,
      "TXT_FINANCIER_NAME": "INDIA INFOLINE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21193,
      "TXT_FINANCIER_NAME": "SATTARI URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 21194,
      "TXT_FINANCIER_NAME": "GUJARAT GOPALAK VIKAS NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 21195,
      "TXT_FINANCIER_NAME": "VIKRAM FINANCE AND SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 21196,
      "TXT_FINANCIER_NAME": "KALBHAIRAVNATH SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21197,
      "TXT_FINANCIER_NAME": "SHIV FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21198,
      "TXT_FINANCIER_NAME": "JANGRA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21199,
      "TXT_FINANCIER_NAME": "SHRI BASAVANNA MAHADEV CO OP CR SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 21200,
      "TXT_FINANCIER_NAME": "VASANTRAO NAIK NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21201,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21202,
      "TXT_FINANCIER_NAME": "THE APNA BANKING SERVICE AND CONSULTANCY CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21203,
      "TXT_FINANCIER_NAME": "DEPUTY SECRETARY G TO GOVT HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 21204,
      "TXT_FINANCIER_NAME": "TALEDA CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21205,
      "TXT_FINANCIER_NAME": "RAJARSHI SHAHU GOVERNMENT SERVANTS COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21206,
      "TXT_FINANCIER_NAME": "FINE MOTORS FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21207,
      "TXT_FINANCIER_NAME": "SRI PARSHWANATHA SWAMY ALPASANKYATHARA SAHAKARA SANGHA"
    },
    {
      "NUM_FINANCIER_CD": 21208,
      "TXT_FINANCIER_NAME": "MALOO FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 21209,
      "TXT_FINANCIER_NAME": "AU FINANCE INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21210,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLIUM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21211,
      "TXT_FINANCIER_NAME": "BAJRANG INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21212,
      "TXT_FINANCIER_NAME": "SHREE SUBRAMANYA SAHAKARI SANGA"
    },
    {
      "NUM_FINANCIER_CD": 21213,
      "TXT_FINANCIER_NAME": "AMGOORIE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21214,
      "TXT_FINANCIER_NAME": "MADHUGANGA NAGRI PATH SAHKARI SANSTHA MARYADIT MOHPA"
    },
    {
      "NUM_FINANCIER_CD": 21215,
      "TXT_FINANCIER_NAME": "JAMSHEDPUR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21216,
      "TXT_FINANCIER_NAME": "VIJAYA PATHINA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 21217,
      "TXT_FINANCIER_NAME": "AGRICULTURE INSURANCE COMPANY OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21218,
      "TXT_FINANCIER_NAME": "PIONEER FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21219,
      "TXT_FINANCIER_NAME": "NATARAJ FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21220,
      "TXT_FINANCIER_NAME": "VIGHNAHAR SAHAKARI PATHPRDE MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21221,
      "TXT_FINANCIER_NAME": "AGRO INDUS FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 21222,
      "TXT_FINANCIER_NAME": "RAYAT SAHAKARI PATHPEDHI LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 21223,
      "TXT_FINANCIER_NAME": "VASAI TALUKA SAGRI MASEMARI SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21224,
      "TXT_FINANCIER_NAME": "UTTRAKHAND GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21225,
      "TXT_FINANCIER_NAME": "SHRI MAHALAXMI MAHILA SHIROL TALUKA NAGARI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21226,
      "TXT_FINANCIER_NAME": "KARMAVEER BHAURAO PATIL MULTISTATE COOP CR SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 21227,
      "TXT_FINANCIER_NAME": "THE TAMIL NADU CIRCLE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21228,
      "TXT_FINANCIER_NAME": "SHREE PANCH PIPLA JUTH SEVA SAHKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21229,
      "TXT_FINANCIER_NAME": "TATA CAPITAL FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 21230,
      "TXT_FINANCIER_NAME": "SRI TIRUMALA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21231,
      "TXT_FINANCIER_NAME": "VINOD GUNDECHA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 21232,
      "TXT_FINANCIER_NAME": "KOLENCHERY HIRE PURCHASING"
    },
    {
      "NUM_FINANCIER_CD": 21233,
      "TXT_FINANCIER_NAME": "YOGIRAJ NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 21234,
      "TXT_FINANCIER_NAME": "THE DHANLAXMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21235,
      "TXT_FINANCIER_NAME": "SRI VENKATA SAI FINANACE"
    },
    {
      "NUM_FINANCIER_CD": 21236,
      "TXT_FINANCIER_NAME": "JANPRIYA SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21237,
      "TXT_FINANCIER_NAME": "TJSB SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21238,
      "TXT_FINANCIER_NAME": "YASH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21239,
      "TXT_FINANCIER_NAME": "THE BULDHANA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21240,
      "TXT_FINANCIER_NAME": "SHREE DADVI SEVA SAHKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 21241,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 21242,
      "TXT_FINANCIER_NAME": "LOKKALYAN NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21243,
      "TXT_FINANCIER_NAME": "SRI KEERTHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21244,
      "TXT_FINANCIER_NAME": "BINDU INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 21245,
      "TXT_FINANCIER_NAME": "ARVAL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21246,
      "TXT_FINANCIER_NAME": "IMA VADODARA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21247,
      "TXT_FINANCIER_NAME": "AJEETMAL LODHA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 21248,
      "TXT_FINANCIER_NAME": "TVS CREDIT SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 21249,
      "TXT_FINANCIER_NAME": "DR BABASAHEB AMBEDKAR NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21250,
      "TXT_FINANCIER_NAME": "THE BIG KANCHEEPURAM CO OP TOWN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21251,
      "TXT_FINANCIER_NAME": "SAWATA MAHARAJ SAHA BIG PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21252,
      "TXT_FINANCIER_NAME": "FAIRDEAL CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21253,
      "TXT_FINANCIER_NAME": "SURYODAY NAGARI SAHAKARI PAT SANTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 21254,
      "TXT_FINANCIER_NAME": "UTKAL GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21255,
      "TXT_FINANCIER_NAME": "MADHESHWARI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21256,
      "TXT_FINANCIER_NAME": "SWATANTRADEO ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 21257,
      "TXT_FINANCIER_NAME": "JANSHANTI SAHAKARI PATSANSTHA MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 21258,
      "TXT_FINANCIER_NAME": "KANKARIA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 21259,
      "TXT_FINANCIER_NAME": "MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21260,
      "TXT_FINANCIER_NAME": "THE TEXTILE MANUFACTURERS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21261,
      "TXT_FINANCIER_NAME": "LAXMI INDIA FINLEASE CAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21262,
      "TXT_FINANCIER_NAME": "HIMACHAL PRADESH GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21263,
      "TXT_FINANCIER_NAME": "EQUITAS FINANCE PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21264,
      "TXT_FINANCIER_NAME": "SUCO SOUHARDA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 21265,
      "TXT_FINANCIER_NAME": "SUMANGAL FINANCE AND INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 21266,
      "TXT_FINANCIER_NAME": "THE ACCOUNTANT GENARAIS OFFICE EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21267,
      "TXT_FINANCIER_NAME": "GURU BAKHSHISH FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 21268,
      "TXT_FINANCIER_NAME": "KAVERI GRAMEENA VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 21269,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI VIVIDODHESHAGALA SOUHARDHA SARAHAKI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21270,
      "TXT_FINANCIER_NAME": "DHANALAXMI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21271,
      "TXT_FINANCIER_NAME": "SHIVAJIRAO BHOSALE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21272,
      "TXT_FINANCIER_NAME": "MAHAYOGI VEMANA SOUHARDA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 21273,
      "TXT_FINANCIER_NAME": "THE MAHILA URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21274,
      "TXT_FINANCIER_NAME": "SHANGARI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21275,
      "TXT_FINANCIER_NAME": "TRANSPORT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18103,
      "TXT_FINANCIER_NAME": "BHEL EMPLOYEES CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18201,
      "TXT_FINANCIER_NAME": "THE MAHARAJA CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18202,
      "TXT_FINANCIER_NAME": "KUPWAD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18203,
      "TXT_FINANCIER_NAME": "BHAGYODAYA FRIENDS URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18204,
      "TXT_FINANCIER_NAME": "THE HISAR DISTRICT CENTRAL CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18205,
      "TXT_FINANCIER_NAME": "THE MUSLIM CO OPERATIVE BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18206,
      "TXT_FINANCIER_NAME": "THE SHIBPUR CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18207,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI MAHILA NAGARI SHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18208,
      "TXT_FINANCIER_NAME": "THE JAMMU & KASHMIR BANK"
    },
    {
      "NUM_FINANCIER_CD": 18209,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18210,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY UNION FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18211,
      "TXT_FINANCIER_NAME": "KARNATAKA VIKAS GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18212,
      "TXT_FINANCIER_NAME": "THE NORTH MALABAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18213,
      "TXT_FINANCIER_NAME": "KANHOOR PATHAR SAHAKARI PATH SANTHA"
    },
    {
      "NUM_FINANCIER_CD": 18214,
      "TXT_FINANCIER_NAME": "PUNJAB E SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 18215,
      "TXT_FINANCIER_NAME": "CHIKMAGALUR KODAGU GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 18216,
      "TXT_FINANCIER_NAME": "VSP STEEL PLANT"
    },
    {
      "NUM_FINANCIER_CD": 18217,
      "TXT_FINANCIER_NAME": "SWAMI SAMARTH SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18218,
      "TXT_FINANCIER_NAME": "THE SHIMOGA ARECANUT MANDI MERCHANTS COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18219,
      "TXT_FINANCIER_NAME": "TUMKUR MERCHANTS SOUHARDA CREDIT COOPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18220,
      "TXT_FINANCIER_NAME": "PUNJAB AND MAHARASHTRA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18221,
      "TXT_FINANCIER_NAME": "CO OPERATIVE AGRICULTURAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18222,
      "TXT_FINANCIER_NAME": "KARNATAKA MINARITIES DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18223,
      "TXT_FINANCIER_NAME": "NAVAL GROUP INSURANCE FUND"
    },
    {
      "NUM_FINANCIER_CD": 18224,
      "TXT_FINANCIER_NAME": "ENGINEERS INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18225,
      "TXT_FINANCIER_NAME": "SRI PARSHWANATHA SAHAKARA BANK NIYAMITHA ASHOK NAGAR TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 18226,
      "TXT_FINANCIER_NAME": "SREERAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18227,
      "TXT_FINANCIER_NAME": "SRI LAXMANANDA MULTIPURPOSE CO OP SOCIETY LTD JEPPU"
    },
    {
      "NUM_FINANCIER_CD": 18228,
      "TXT_FINANCIER_NAME": "ERODE DISTRICT CENTRAL COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18229,
      "TXT_FINANCIER_NAME": "CENTRAL CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18230,
      "TXT_FINANCIER_NAME": "DAHOD MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18231,
      "TXT_FINANCIER_NAME": "TUMKUR VEERASHAIVA CO OP BANK LTD NELAMANGALA BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 18232,
      "TXT_FINANCIER_NAME": "THE STATE TRADING CORPORATION OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18233,
      "TXT_FINANCIER_NAME": "THE CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18234,
      "TXT_FINANCIER_NAME": "THE UNA PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18235,
      "TXT_FINANCIER_NAME": "THE JOGINDRA CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18236,
      "TXT_FINANCIER_NAME": "Mahindra & Mahindra Financial service Limited"
    },
    {
      "NUM_FINANCIER_CD": 18237,
      "TXT_FINANCIER_NAME": "THE THANE SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18238,
      "TXT_FINANCIER_NAME": "THE KAIRA DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18239,
      "TXT_FINANCIER_NAME": "ALAPPUZHA DISTRICT CO-OPEARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18240,
      "TXT_FINANCIER_NAME": "BALLY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18241,
      "TXT_FINANCIER_NAME": "NAVJIVAN NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18242,
      "TXT_FINANCIER_NAME": "THE HALOL URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18243,
      "TXT_FINANCIER_NAME": "CHIPLUN URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18244,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18245,
      "TXT_FINANCIER_NAME": "BALLY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18246,
      "TXT_FINANCIER_NAME": "TUMKUR GRAIN MERCHANTA CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18247,
      "TXT_FINANCIER_NAME": "INTEGRATED FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 18248,
      "TXT_FINANCIER_NAME": "BHAVANI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18249,
      "TXT_FINANCIER_NAME": "DISTRICT CENTRAL CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18250,
      "TXT_FINANCIER_NAME": "MANVI PATTANA SOUHARDA SAHAKARI BANK NI"
    },
    {
      "NUM_FINANCIER_CD": 18251,
      "TXT_FINANCIER_NAME": "THE VISAKHAPATNAM CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18252,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18253,
      "TXT_FINANCIER_NAME": "VIJAYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 18254,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18255,
      "TXT_FINANCIER_NAME": "THE NANITAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18256,
      "TXT_FINANCIER_NAME": "AGIF"
    },
    {
      "NUM_FINANCIER_CD": 18257,
      "TXT_FINANCIER_NAME": "CITI UNION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18258,
      "TXT_FINANCIER_NAME": "KOTAKK MAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 18259,
      "TXT_FINANCIER_NAME": "SONBHADRA NAGAR SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18260,
      "TXT_FINANCIER_NAME": "ALMORA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18261,
      "TXT_FINANCIER_NAME": "THE AZHIKODE SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18262,
      "TXT_FINANCIER_NAME": "THE MUMBAI DISTRICT CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18263,
      "TXT_FINANCIER_NAME": "VIKAS SOUHARDA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18264,
      "TXT_FINANCIER_NAME": "NUTAN SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18265,
      "TXT_FINANCIER_NAME": "THE JALGAON PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18266,
      "TXT_FINANCIER_NAME": "THE BHADARAN PEOPLES CO-OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 18267,
      "TXT_FINANCIER_NAME": "THE BHADARAN PEOPLES CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18268,
      "TXT_FINANCIER_NAME": "THE MATTANCHERRY MAHAJANIK CO-OP. URBAN BANK LTD., NO 665"
    },
    {
      "NUM_FINANCIER_CD": 18269,
      "TXT_FINANCIER_NAME": "AGRA ZILA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18270,
      "TXT_FINANCIER_NAME": "MAHALAKSHMI CO-OPERATIVE BANK LTD, UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 18271,
      "TXT_FINANCIER_NAME": "THE BHARUCH DISTRICT CENTRAL CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18272,
      "TXT_FINANCIER_NAME": "UNION BANK"
    },
    {
      "NUM_FINANCIER_CD": 18273,
      "TXT_FINANCIER_NAME": "CITY UNION BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 18274,
      "TXT_FINANCIER_NAME": "KURMANCHAL NAGAR SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18275,
      "TXT_FINANCIER_NAME": "DENA BANK."
    },
    {
      "NUM_FINANCIER_CD": 18276,
      "TXT_FINANCIER_NAME": "THE TAMIL NADU CIRCLE POSTAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18277,
      "TXT_FINANCIER_NAME": "THE ALMORA URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18278,
      "TXT_FINANCIER_NAME": "THE ALMORA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18279,
      "TXT_FINANCIER_NAME": "BHARAT URBAN CO-OP. BANK LTD.,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18280,
      "TXT_FINANCIER_NAME": "THE SECUNDERABAD MERCANTILE CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18281,
      "TXT_FINANCIER_NAME": "MAHISMATI NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18282,
      "TXT_FINANCIER_NAME": "SARASPUR NAGARIK CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18283,
      "TXT_FINANCIER_NAME": "THE KHAMGAON URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18284,
      "TXT_FINANCIER_NAME": "FEROKE CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18285,
      "TXT_FINANCIER_NAME": "ABHINANDAN URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18286,
      "TXT_FINANCIER_NAME": "VIKRAMADITYA NAGRIK SAH. BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 18287,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAWJI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18288,
      "TXT_FINANCIER_NAME": "PUNE PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18289,
      "TXT_FINANCIER_NAME": "GANDHIBAG SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18290,
      "TXT_FINANCIER_NAME": "JIJAMATA MAHILA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18291,
      "TXT_FINANCIER_NAME": "BELGAUM ZILLA RANI CHENNAMMA MAHILA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 18292,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18293,
      "TXT_FINANCIER_NAME": "Magma Shrachi FinLtd"
    },
    {
      "NUM_FINANCIER_CD": 18294,
      "TXT_FINANCIER_NAME": "TAMIL NADU INDUSTRIAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18295,
      "TXT_FINANCIER_NAME": "corporation bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 18296,
      "TXT_FINANCIER_NAME": "STATE BANK OF MODASA."
    },
    {
      "NUM_FINANCIER_CD": 18297,
      "TXT_FINANCIER_NAME": "MANOJ AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19745,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY UNION FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19746,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCE COLTD"
    },
    {
      "NUM_FINANCIER_CD": 19747,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19748,
      "TXT_FINANCIER_NAME": "SHREE RAM TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19749,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN TRACTOR MACHINERY"
    },
    {
      "NUM_FINANCIER_CD": 19750,
      "TXT_FINANCIER_NAME": "Kamakhia Finance and Leasing Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19751,
      "TXT_FINANCIER_NAME": "ONKAR INVESTO"
    },
    {
      "NUM_FINANCIER_CD": 19752,
      "TXT_FINANCIER_NAME": "THE GANGA MERCANTILE URBAN CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19753,
      "TXT_FINANCIER_NAME": "KAMAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 19754,
      "TXT_FINANCIER_NAME": "SHRI JAIN CHAKAN GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19755,
      "TXT_FINANCIER_NAME": "SHRIMANT THORLE BAJIRAO PESHVE NAGARI SAHKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19756,
      "TXT_FINANCIER_NAME": "MAHABHAIRAB CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19757,
      "TXT_FINANCIER_NAME": "SHREE SWASTIK MOTOR FIN P LTD"
    },
    {
      "NUM_FINANCIER_CD": 19758,
      "TXT_FINANCIER_NAME": "KORAPUT PANCHABATI GRAMYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19759,
      "TXT_FINANCIER_NAME": "MAHARASHTRA GODAVARI GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19760,
      "TXT_FINANCIER_NAME": "NATIONAL BANK FOR AGRICULTRUE AND RULER DEVELOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 19761,
      "TXT_FINANCIER_NAME": "NATIONAL BANK FOR AGRICULTURE & RURAL DEVELOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 19762,
      "TXT_FINANCIER_NAME": "PANCHMAHAL VADODARA GRAMIN BANK."
    },
    {
      "NUM_FINANCIER_CD": 19763,
      "TXT_FINANCIER_NAME": "VIMAL ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 19764,
      "TXT_FINANCIER_NAME": "FLUENT INDIA PVTLTD"
    },
    {
      "NUM_FINANCIER_CD": 19765,
      "TXT_FINANCIER_NAME": "GAMBIT LEASING AND FIN P LTD"
    },
    {
      "NUM_FINANCIER_CD": 19766,
      "TXT_FINANCIER_NAME": "PROCTER AND GAMBLE HOME PRODUCTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 19767,
      "TXT_FINANCIER_NAME": "JAY TULJABHAVANI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19768,
      "TXT_FINANCIER_NAME": "CITI FINANCIAL CONSUMER FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19769,
      "TXT_FINANCIER_NAME": "HIMACHAL MITRA MANDAL COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 19770,
      "TXT_FINANCIER_NAME": "TATA MOTORS FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19771,
      "TXT_FINANCIER_NAME": "THE PRAGPUR CO OPERATIVE AGRICULTURE MULTIPURPOSE SERVICES SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19772,
      "TXT_FINANCIER_NAME": "SURYA FINLEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19773,
      "TXT_FINANCIER_NAME": "THE MULLAKKODI CO OP RURAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19774,
      "TXT_FINANCIER_NAME": "SHRI KRISHNA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19775,
      "TXT_FINANCIER_NAME": "SHRI MAHALAXMI CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19776,
      "TXT_FINANCIER_NAME": "SHRI PARSHWANATH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19777,
      "TXT_FINANCIER_NAME": "SHRI SATYAVIJAY SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19778,
      "TXT_FINANCIER_NAME": "SHRI SHIVABODHARANG URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19779,
      "TXT_FINANCIER_NAME": "SHRI SHIVAYOGI MURUGHENDRA SWAMI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19780,
      "TXT_FINANCIER_NAME": "SHRI SHIVNERI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19781,
      "TXT_FINANCIER_NAME": "SHRI VEERSHAIV CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19782,
      "TXT_FINANCIER_NAME": "SHRI VEERSHAIV CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19783,
      "TXT_FINANCIER_NAME": "SHRI WARANA SAH. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19784,
      "TXT_FINANCIER_NAME": "SHRI YASHWANT SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19785,
      "TXT_FINANCIER_NAME": "SHRIMANT MALOJIRAJE SAH BANK"
    },
    {
      "NUM_FINANCIER_CD": 19786,
      "TXT_FINANCIER_NAME": "INDIA CEMENTS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19787,
      "TXT_FINANCIER_NAME": "THE UDUPI TALUK PROTESTANT CHRISTITAN CREDIT CO OP SOCIETY LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 19788,
      "TXT_FINANCIER_NAME": "S A S NAGAR CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19789,
      "TXT_FINANCIER_NAME": "THE KHEDA DISTRICT OP OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19790,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH STATE HOUSING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19791,
      "TXT_FINANCIER_NAME": "MADRAS CEMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 19792,
      "TXT_FINANCIER_NAME": "KOTHARI AUTO FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 19793,
      "TXT_FINANCIER_NAME": "RELIANCE CONSUMER FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19794,
      "TXT_FINANCIER_NAME": "THE PRITISANGAM SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19795,
      "TXT_FINANCIER_NAME": "THE PUNE POST AND TELECOM CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19796,
      "TXT_FINANCIER_NAME": "THE QUEPEM URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19797,
      "TXT_FINANCIER_NAME": "The Raj Laxmi Mahila Urban Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19798,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN STATE INDUSTRIAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19799,
      "TXT_FINANCIER_NAME": "THE RAJPUTANA MAHILA URBAN CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19800,
      "TXT_FINANCIER_NAME": "THE RANDER PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19801,
      "TXT_FINANCIER_NAME": "THE SAHYADRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19802,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO -OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19803,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19804,
      "TXT_FINANCIER_NAME": "THE SARVODAYA NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19805,
      "TXT_FINANCIER_NAME": "THE SATARA DISTRICT MERCHANT CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19806,
      "TXT_FINANCIER_NAME": "THE SAVANUR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19807,
      "TXT_FINANCIER_NAME": "KHARDAH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19808,
      "TXT_FINANCIER_NAME": "SHRI GURU RAMALINGESHWAR PATINA SAHAKARI SANGH"
    },
    {
      "NUM_FINANCIER_CD": 19809,
      "TXT_FINANCIER_NAME": "THE HAVERI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19810,
      "TXT_FINANCIER_NAME": "SWASTIK FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 19811,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWARA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19812,
      "TXT_FINANCIER_NAME": "CITY CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 19813,
      "TXT_FINANCIER_NAME": "Srei International Finance Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19814,
      "TXT_FINANCIER_NAME": "SRI RAGHAVENDRA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19815,
      "TXT_FINANCIER_NAME": "SRI VIJAYARAM HIRE PURCHASE & LSG FINANCE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19816,
      "TXT_FINANCIER_NAME": "SUGAL FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19817,
      "TXT_FINANCIER_NAME": "SUNDRAM FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19818,
      "TXT_FINANCIER_NAME": "LESSOR SUNDARAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19819,
      "TXT_FINANCIER_NAME": "SUNDARAM FINANCE LIMITED BANGALORE"
    },
    {
      "NUM_FINANCIER_CD": 19820,
      "TXT_FINANCIER_NAME": "ROYAL SUNDARAM"
    },
    {
      "NUM_FINANCIER_CD": 19821,
      "TXT_FINANCIER_NAME": "SURANA FINANCIAL CORPORATION (INDIA) LTD."
    },
    {
      "NUM_FINANCIER_CD": 19822,
      "TXT_FINANCIER_NAME": "TAMILNADU MERCANTILE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19823,
      "TXT_FINANCIER_NAME": "TATA MOTORS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19824,
      "TXT_FINANCIER_NAME": "TCI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19825,
      "TXT_FINANCIER_NAME": "THE FEDERAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19826,
      "TXT_FINANCIER_NAME": "THE ASSOCIATES INDIA FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19827,
      "TXT_FINANCIER_NAME": "THE FEDERAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19828,
      "TXT_FINANCIER_NAME": "THE THANE JANATA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19829,
      "TXT_FINANCIER_NAME": "THE UNITED WESTERN BANK LTD-IDBI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19830,
      "TXT_FINANCIER_NAME": "TML FINANCIAL SER. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19831,
      "TXT_FINANCIER_NAME": "TML FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19832,
      "TXT_FINANCIER_NAME": "UMEED LEASING & FINANCE PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 19833,
      "TXT_FINANCIER_NAME": "V A P FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19834,
      "TXT_FINANCIER_NAME": "THE JANTA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19835,
      "TXT_FINANCIER_NAME": "JANATHA SEVA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19836,
      "TXT_FINANCIER_NAME": "VASU SENNIAPPA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19837,
      "TXT_FINANCIER_NAME": "RESMI HIRE PURCHASE COMPANY PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19838,
      "TXT_FINANCIER_NAME": "PRAGATHI GRAMINA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19839,
      "TXT_FINANCIER_NAME": "REWA SIDHI GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19840,
      "TXT_FINANCIER_NAME": "SARVA U.P. GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19841,
      "TXT_FINANCIER_NAME": "SARVA UP GRAMINE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19842,
      "TXT_FINANCIER_NAME": "SAURASTRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21276,
      "TXT_FINANCIER_NAME": "SHRI SHIVABODHARANGA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21277,
      "TXT_FINANCIER_NAME": "PRINCE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21278,
      "TXT_FINANCIER_NAME": "STATE BANK OF HARYANA"
    },
    {
      "NUM_FINANCIER_CD": 21279,
      "TXT_FINANCIER_NAME": "ATHMASHAKTHI MULTIPURPOSE CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 21280,
      "TXT_FINANCIER_NAME": "DATT DIGAMBAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21281,
      "TXT_FINANCIER_NAME": "SHREE SEVANI SEVA SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21282,
      "TXT_FINANCIER_NAME": "HIRACHAND PRASANNACHAND AUTOMOTIVE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 21283,
      "TXT_FINANCIER_NAME": "SHRI MATHAJI VIVIDDODDESHA SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 21284,
      "TXT_FINANCIER_NAME": "ADITYA ANAGHA MULTI STATE CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21285,
      "TXT_FINANCIER_NAME": "CITY AUTO CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 21286,
      "TXT_FINANCIER_NAME": "SUPREME CITY AUTO INVETMENTS"
    },
    {
      "NUM_FINANCIER_CD": 21287,
      "TXT_FINANCIER_NAME": "THE OJHAR MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21288,
      "TXT_FINANCIER_NAME": "SHIVANJALI NAGARI SAH PATH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21289,
      "TXT_FINANCIER_NAME": "SAMPAT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21290,
      "TXT_FINANCIER_NAME": "SHRI PARSHWANATH CRD SOUHARD SAHAKARI NYT SHAMANEWADI"
    },
    {
      "NUM_FINANCIER_CD": 21291,
      "TXT_FINANCIER_NAME": "SRI SUBRAMANYESHWARA CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21292,
      "TXT_FINANCIER_NAME": "JANKALYAN SAH PATHPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21293,
      "TXT_FINANCIER_NAME": "NANESH FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21294,
      "TXT_FINANCIER_NAME": "ANUGRAHA HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 21295,
      "TXT_FINANCIER_NAME": "KEMPEGOWDA PATTANA SOUHARDA SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 21296,
      "TXT_FINANCIER_NAME": "TOYOTA FINANCIAL SERVICES INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21297,
      "TXT_FINANCIER_NAME": "SHRI MAHALAXMI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21298,
      "TXT_FINANCIER_NAME": "RASHTRIYA ISPAT NIGAM LIMITED VISAKHAPATNAM STEEL PLANT"
    },
    {
      "NUM_FINANCIER_CD": 21299,
      "TXT_FINANCIER_NAME": "THE CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21300,
      "TXT_FINANCIER_NAME": "BHAICHAND HIRACHAND RAISONI MULTI STATE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21301,
      "TXT_FINANCIER_NAME": "CITY FORT FINVEST LTD"
    },
    {
      "NUM_FINANCIER_CD": 21302,
      "TXT_FINANCIER_NAME": "THAKAR INVESTMENT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21303,
      "TXT_FINANCIER_NAME": "VASANTRAO NAIK MAHAMANDAL"
    },
    {
      "NUM_FINANCIER_CD": 21304,
      "TXT_FINANCIER_NAME": "SHRI SARASWATHI CREDIT SOWHARDA SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21305,
      "TXT_FINANCIER_NAME": "PRERNA SAHAKARI PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21306,
      "TXT_FINANCIER_NAME": "BOTHRA TRADING CO"
    },
    {
      "NUM_FINANCIER_CD": 21307,
      "TXT_FINANCIER_NAME": "SHIVKRUPA SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21308,
      "TXT_FINANCIER_NAME": "SHRI SIDDHESHWAR SOUHARD SAHAKARI NIYMIT SIDNAL"
    },
    {
      "NUM_FINANCIER_CD": 21309,
      "TXT_FINANCIER_NAME": "THE BISHNUPUR TOWN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21310,
      "TXT_FINANCIER_NAME": "FRIENDS HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 21311,
      "TXT_FINANCIER_NAME": "GRAMIN BANK OF ARYA VART"
    },
    {
      "NUM_FINANCIER_CD": 21312,
      "TXT_FINANCIER_NAME": "THE KARNATAKA STATE APEX CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21313,
      "TXT_FINANCIER_NAME": "DR B R AMBEDKAR OPEN UNIVERSITY"
    },
    {
      "NUM_FINANCIER_CD": 21314,
      "TXT_FINANCIER_NAME": "D N MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 21315,
      "TXT_FINANCIER_NAME": "GURUDATTA MULTI STATE CO OP CREDIT SOC"
    },
    {
      "NUM_FINANCIER_CD": 21316,
      "TXT_FINANCIER_NAME": "USB FINANCIAL CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21317,
      "TXT_FINANCIER_NAME": "HARINDER HIRE PURCHASE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21318,
      "TXT_FINANCIER_NAME": "SRI MALLIKARJUNA CO OP SOC N VANDARU"
    },
    {
      "NUM_FINANCIER_CD": 21319,
      "TXT_FINANCIER_NAME": "A U FINANCIERS INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21320,
      "TXT_FINANCIER_NAME": "TERA MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 21321,
      "TXT_FINANCIER_NAME": "APANA FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21322,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN MUILTIPURPOSE SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21323,
      "TXT_FINANCIER_NAME": "RESERVE BANK OF SCOTTLAND"
    },
    {
      "NUM_FINANCIER_CD": 21324,
      "TXT_FINANCIER_NAME": "MAHARASHTRA STATE HANDICAPPED FINANCE AND DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21325,
      "TXT_FINANCIER_NAME": "THE SOUTH KANARA BEE KEEPERS CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21326,
      "TXT_FINANCIER_NAME": "KERALA STATE BACKWARD CLASSES DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21327,
      "TXT_FINANCIER_NAME": "MAHAKALI SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 21328,
      "TXT_FINANCIER_NAME": "AU FINANCIERS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21329,
      "TXT_FINANCIER_NAME": "SRI GAVISIDDESHWARA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21330,
      "TXT_FINANCIER_NAME": "THE DIRECTOR VSSC"
    },
    {
      "NUM_FINANCIER_CD": 21331,
      "TXT_FINANCIER_NAME": "RAMANAGARAM URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21332,
      "TXT_FINANCIER_NAME": "VISHNU SAI FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21333,
      "TXT_FINANCIER_NAME": "SHIVKRUPA NAGARI SAHA PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21334,
      "TXT_FINANCIER_NAME": "SHRI SIDDHESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21335,
      "TXT_FINANCIER_NAME": "DALAL SECURITIES AND INVESTMENTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21336,
      "TXT_FINANCIER_NAME": "SBICAP TRUSTEE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21337,
      "TXT_FINANCIER_NAME": "VISAKHA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21338,
      "TXT_FINANCIER_NAME": "THE INCOME TAX DEPARTMENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21339,
      "TXT_FINANCIER_NAME": "RAJASTHAN MARUDHAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21340,
      "TXT_FINANCIER_NAME": "SRI SAI AUTO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21341,
      "TXT_FINANCIER_NAME": "THE RANGA REDDY CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21342,
      "TXT_FINANCIER_NAME": "MANGLAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21343,
      "TXT_FINANCIER_NAME": "CUTTACK CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21344,
      "TXT_FINANCIER_NAME": "THE KOTA CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21345,
      "TXT_FINANCIER_NAME": "GUPTA FINANCE CO REGD"
    },
    {
      "NUM_FINANCIER_CD": 21346,
      "TXT_FINANCIER_NAME": "THE KARAN URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21347,
      "TXT_FINANCIER_NAME": "UMIYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21348,
      "TXT_FINANCIER_NAME": "KHATAV TALUKA CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 21349,
      "TXT_FINANCIER_NAME": "DR HEDGEWAR GRAMIN BIGAR SHETI SAH PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21350,
      "TXT_FINANCIER_NAME": "SUBHASH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21351,
      "TXT_FINANCIER_NAME": "ROHIT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21352,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH CENTRAL POWER DISTRIBUTION COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21353,
      "TXT_FINANCIER_NAME": "SHRI MAHATMA BASAVESHWAR CREDIT SOUHARD SAH NYT"
    },
    {
      "NUM_FINANCIER_CD": 21354,
      "TXT_FINANCIER_NAME": "GBR FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21355,
      "TXT_FINANCIER_NAME": "GREWAL MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21356,
      "TXT_FINANCIER_NAME": "HINDUSTAN MOTOR CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 21357,
      "TXT_FINANCIER_NAME": "SHRIRAM FINANCE CORPORATION P LTD"
    },
    {
      "NUM_FINANCIER_CD": 21358,
      "TXT_FINANCIER_NAME": "TEACHERS CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21359,
      "TXT_FINANCIER_NAME": "BPCL KOCHI REFINERY EMPLOYEES CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21360,
      "TXT_FINANCIER_NAME": "THE PANCHMAHAL DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21361,
      "TXT_FINANCIER_NAME": "KARMAVEER BHAURAO PATIL NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21362,
      "TXT_FINANCIER_NAME": "PAWANRAJE LOKSAMRUDHI MULTISTATE CO PUNE"
    },
    {
      "NUM_FINANCIER_CD": 21363,
      "TXT_FINANCIER_NAME": "ALANG MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 21364,
      "TXT_FINANCIER_NAME": "NAVBHARAT URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21365,
      "TXT_FINANCIER_NAME": "UNITED FINANCE INDIA"
    },
    {
      "NUM_FINANCIER_CD": 21366,
      "TXT_FINANCIER_NAME": "THE COMPTROLLER KVAFSU"
    },
    {
      "NUM_FINANCIER_CD": 21367,
      "TXT_FINANCIER_NAME": "SUNBEAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21368,
      "TXT_FINANCIER_NAME": "TIRUPATI CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21369,
      "TXT_FINANCIER_NAME": "APNA MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21370,
      "TXT_FINANCIER_NAME": "RAJ RAJESHWARI MAHILA NAGRIK SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 21371,
      "TXT_FINANCIER_NAME": "VIDHARBHA KOKAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 21372,
      "TXT_FINANCIER_NAME": "THE PRIMARY AGRICULTURAL CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18298,
      "TXT_FINANCIER_NAME": "ANNASAHEB NALE NAGRI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18299,
      "TXT_FINANCIER_NAME": "jamia co operative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 18300,
      "TXT_FINANCIER_NAME": "Nagaland State Cooperative Bank"
    },
    {
      "NUM_FINANCIER_CD": 18301,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18302,
      "TXT_FINANCIER_NAME": "STEEL AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18303,
      "TXT_FINANCIER_NAME": "Dr Annasaheb Chougule Urban Coop Bank"
    },
    {
      "NUM_FINANCIER_CD": 18304,
      "TXT_FINANCIER_NAME": "THE FARIDKOT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18305,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK LTD SAHAKAR NAGAR BR PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18306,
      "TXT_FINANCIER_NAME": "THE CHENNIMALAI CO OP URBANK BANK"
    },
    {
      "NUM_FINANCIER_CD": 18307,
      "TXT_FINANCIER_NAME": "MAHESH NAGARI SAHAKARI PATSANSTHA SHIRUR TAJBAND"
    },
    {
      "NUM_FINANCIER_CD": 18308,
      "TXT_FINANCIER_NAME": "ANANT NAGARI SAH PATHASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18309,
      "TXT_FINANCIER_NAME": "GOVERNMENT EMPLOYEE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18310,
      "TXT_FINANCIER_NAME": "GOVERNMENT EMPLOYEE COOPERATIVE BANK LTD NO"
    },
    {
      "NUM_FINANCIER_CD": 18311,
      "TXT_FINANCIER_NAME": "DUGAR FINANCE AND INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 18312,
      "TXT_FINANCIER_NAME": "THE COSMOS CO OPERATIVE BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18313,
      "TXT_FINANCIER_NAME": "PRAGATI NAGARI SAH PATH SANSTHA MARYADIT KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18314,
      "TXT_FINANCIER_NAME": "URBAN COOPERATIVE CRDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18315,
      "TXT_FINANCIER_NAME": "NIDHI KRUPA"
    },
    {
      "NUM_FINANCIER_CD": 18316,
      "TXT_FINANCIER_NAME": "KARMAVEER BHAURAO PATIL ZILLA SAH PATH SANSTHA MARYADIT JAYSINGPUR"
    },
    {
      "NUM_FINANCIER_CD": 18317,
      "TXT_FINANCIER_NAME": "THE RANDHEJA COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18318,
      "TXT_FINANCIER_NAME": "THE CHANDIGARH STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18319,
      "TXT_FINANCIER_NAME": "THE TRICHUR URBAN C-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18320,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OP. BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 18321,
      "TXT_FINANCIER_NAME": "LILUAH CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18322,
      "TXT_FINANCIER_NAME": "BOMBAY MERCANTILE CO-OP BANK LTIMITED,SOLAPUR."
    },
    {
      "NUM_FINANCIER_CD": 18323,
      "TXT_FINANCIER_NAME": "TAMIL NADU STATE APEX CO OPORATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18324,
      "TXT_FINANCIER_NAME": "PUNJAB & MAHARASHTRA Co-op. Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18325,
      "TXT_FINANCIER_NAME": "SHRI JANATA SAHAKARI BANK LTD,HALOL"
    },
    {
      "NUM_FINANCIER_CD": 18326,
      "TXT_FINANCIER_NAME": "BHARAT HEAVY ELECTRICALS EMPLOYEES CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18327,
      "TXT_FINANCIER_NAME": "PUNJAB & MAHARASHTRA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18328,
      "TXT_FINANCIER_NAME": "THE BANK OF TOKYO MITSUBISHI LTD."
    },
    {
      "NUM_FINANCIER_CD": 18329,
      "TXT_FINANCIER_NAME": "THE COIMBATORE DISTRICT CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18330,
      "TXT_FINANCIER_NAME": "PIONEER URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18331,
      "TXT_FINANCIER_NAME": "THE DAHOD URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18332,
      "TXT_FINANCIER_NAME": "BIJAPUR ZILLA SARAKARI NAUKARAR SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 18333,
      "TXT_FINANCIER_NAME": "Thane Bharat Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18334,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT.CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18335,
      "TXT_FINANCIER_NAME": "NANITAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18336,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALA PARDI PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18337,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR NAGRIK CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18338,
      "TXT_FINANCIER_NAME": "SARDARGANJ MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18339,
      "TXT_FINANCIER_NAME": "THE NAVAKALYAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18340,
      "TXT_FINANCIER_NAME": "THE NAINITAL ALMORA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18341,
      "TXT_FINANCIER_NAME": "THE URBAN CO-OPERATIVE BANK LTD., BHANDARA."
    },
    {
      "NUM_FINANCIER_CD": 18342,
      "TXT_FINANCIER_NAME": "The Merchants Co Op. Bank Ltd Dhule"
    },
    {
      "NUM_FINANCIER_CD": 18343,
      "TXT_FINANCIER_NAME": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18344,
      "TXT_FINANCIER_NAME": "ST JOSEPH CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18345,
      "TXT_FINANCIER_NAME": "SANGHVI INVESTMENT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18346,
      "TXT_FINANCIER_NAME": "HARIHARESHWAR SAHAKARI BANK LTD.,WAI"
    },
    {
      "NUM_FINANCIER_CD": 18347,
      "TXT_FINANCIER_NAME": "BHARAT HEAVY ELECTRICALS EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18348,
      "TXT_FINANCIER_NAME": "MUTHOOT LEASING &ampFINANACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18349,
      "TXT_FINANCIER_NAME": "THE CHIKHLI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18350,
      "TXT_FINANCIER_NAME": "THE CARDAMOM MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18351,
      "TXT_FINANCIER_NAME": "THE SATARA DISTRICT CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18352,
      "TXT_FINANCIER_NAME": "THE KURMANCHAL NAGAR SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18353,
      "TXT_FINANCIER_NAME": "KARNATAKA RAJYA KAIGARIKA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18354,
      "TXT_FINANCIER_NAME": "THE AKOT URBAN CO-OPERATIVE BANK LTD.AKOT."
    },
    {
      "NUM_FINANCIER_CD": 18355,
      "TXT_FINANCIER_NAME": "ETAWAH URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18356,
      "TXT_FINANCIER_NAME": "COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18357,
      "TXT_FINANCIER_NAME": "INGVYSYA"
    },
    {
      "NUM_FINANCIER_CD": 18358,
      "TXT_FINANCIER_NAME": "THE NEW AGRA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18359,
      "TXT_FINANCIER_NAME": "KODAK MAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 18360,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIMICE"
    },
    {
      "NUM_FINANCIER_CD": 18361,
      "TXT_FINANCIER_NAME": "PUNE DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18362,
      "TXT_FINANCIER_NAME": "GE COUNTRYWIDE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18363,
      "TXT_FINANCIER_NAME": "HARDOI URBAN CO-OPARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18364,
      "TXT_FINANCIER_NAME": "BHARAT PETROLEUM CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18365,
      "TXT_FINANCIER_NAME": "JALORE CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18366,
      "TXT_FINANCIER_NAME": "SRI AMBA BHAVANI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18367,
      "TXT_FINANCIER_NAME": "JIJAMATA MAHILA SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18368,
      "TXT_FINANCIER_NAME": "THE COMPTROLLERS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18369,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LIMITES"
    },
    {
      "NUM_FINANCIER_CD": 18370,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18371,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 18372,
      "TXT_FINANCIER_NAME": "THE THANE JANTA SARKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18373,
      "TXT_FINANCIER_NAME": "THE RAJLAXMI MAHILA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18374,
      "TXT_FINANCIER_NAME": "THE PHALTAN URBAN CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18375,
      "TXT_FINANCIER_NAME": "THE NAGPUR MAHANAGARPALIKA KARMACHARI SAHAKARI BANK LTD. NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 18376,
      "TXT_FINANCIER_NAME": "INDAPUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18377,
      "TXT_FINANCIER_NAME": "THE SATARA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18378,
      "TXT_FINANCIER_NAME": "SHREE BHARAT CO-OP.BANK"
    },
    {
      "NUM_FINANCIER_CD": 18379,
      "TXT_FINANCIER_NAME": "SHARAD NAGARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18380,
      "TXT_FINANCIER_NAME": "THE A.P.JANATA CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18381,
      "TXT_FINANCIER_NAME": "PUNE DISTRICT CENTRAL CO OP BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18382,
      "TXT_FINANCIER_NAME": "THE VARACHHA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18383,
      "TXT_FINANCIER_NAME": "MEGHALAYA RURAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 18384,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 18385,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18386,
      "TXT_FINANCIER_NAME": "THE CITIZEN CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18387,
      "TXT_FINANCIER_NAME": "VIKRAMADITYA NAGRIK SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 18388,
      "TXT_FINANCIER_NAME": "BELLAD BAGEWADI URBAN CO-OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18389,
      "TXT_FINANCIER_NAME": "DURGAPUR MAHILA CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18390,
      "TXT_FINANCIER_NAME": "INTEGRAL URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18391,
      "TXT_FINANCIER_NAME": "HARYANA STATE CO OP SUPPLY MKTNG FED LTD"
    },
    {
      "NUM_FINANCIER_CD": 19843,
      "TXT_FINANCIER_NAME": "SOLAPUR GRAMIN BANK ,SOLAPUR."
    },
    {
      "NUM_FINANCIER_CD": 19844,
      "TXT_FINANCIER_NAME": "SINGHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 19845,
      "TXT_FINANCIER_NAME": "SHREE ANK FINHOLD PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19846,
      "TXT_FINANCIER_NAME": "BHILWARA MAHILA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19847,
      "TXT_FINANCIER_NAME": "NAGAR VIKAS SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 19848,
      "TXT_FINANCIER_NAME": "KAMMAVARI CREDIT COOPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19849,
      "TXT_FINANCIER_NAME": "KERALA STATE FINANCIAL ETERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 19850,
      "TXT_FINANCIER_NAME": "VASANTDADA PATIL NAGRI SAH PATH PUNE"
    },
    {
      "NUM_FINANCIER_CD": 19851,
      "TXT_FINANCIER_NAME": "SHRIRAM URBAN CO-OPERATIVE BANK LTD.NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 19852,
      "TXT_FINANCIER_NAME": "SHRIRAMPUR PEOPLE CO-OP BANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 19853,
      "TXT_FINANCIER_NAME": "SIDDHANATH NAGARI SAHAKRI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19854,
      "TXT_FINANCIER_NAME": "Sikar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19855,
      "TXT_FINANCIER_NAME": "Sikkim State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19856,
      "TXT_FINANCIER_NAME": "Sirsa District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19857,
      "TXT_FINANCIER_NAME": "SOLAPUR JANATA COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19858,
      "TXT_FINANCIER_NAME": "SOLAPUR JANATA SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19859,
      "TXT_FINANCIER_NAME": "SOLAPUR JANATA SAHAKARI BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 19860,
      "TXT_FINANCIER_NAME": "SOLAPUR JANATA SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19861,
      "TXT_FINANCIER_NAME": "SOLAPUR NAGARI AUDYOGIK SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 19862,
      "TXT_FINANCIER_NAME": "SOLAPUR SIDDHESHWAR SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19863,
      "TXT_FINANCIER_NAME": "SREE NARAYANA GURU CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19864,
      "TXT_FINANCIER_NAME": "KESHAR FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19865,
      "TXT_FINANCIER_NAME": "SAIBABA NAGARI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19866,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI MAHILA NAGARI SAHAKARI PATASANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19867,
      "TXT_FINANCIER_NAME": "THE SEVA VIKAS C0-0P. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19868,
      "TXT_FINANCIER_NAME": "THE SEVALIA URBAN CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19869,
      "TXT_FINANCIER_NAME": "THE SEVALIA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19870,
      "TXT_FINANCIER_NAME": "THE SEVALIA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19871,
      "TXT_FINANCIER_NAME": "THE SHAM RAO VITALCO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19872,
      "TXT_FINANCIER_NAME": "THE SHAMRAO CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19873,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAK COOP BK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19874,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19875,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19876,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19877,
      "TXT_FINANCIER_NAME": "THE SHIMLA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19878,
      "TXT_FINANCIER_NAME": "THE SHIRPUR MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19879,
      "TXT_FINANCIER_NAME": "THE SHREE SUVARNA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19880,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19881,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19882,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 19883,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19884,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD. SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 19885,
      "TXT_FINANCIER_NAME": "THE SOLAPUR SOCIAL URBAN CO.-OP. BANK LTD.SOLAPUR."
    },
    {
      "NUM_FINANCIER_CD": 19886,
      "TXT_FINANCIER_NAME": "THE SOUTH INDIAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19887,
      "TXT_FINANCIER_NAME": "THE STATE TRANSPORT (E) CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19888,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH STEELS"
    },
    {
      "NUM_FINANCIER_CD": 19889,
      "TXT_FINANCIER_NAME": "UJJAIN PARASPAR SAHKARI MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 19890,
      "TXT_FINANCIER_NAME": "UJJAIN PARASPAR SAHKARI MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 19891,
      "TXT_FINANCIER_NAME": "KAMMAVARI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19892,
      "TXT_FINANCIER_NAME": "THE MANGALORE CATHOLIC CO OP BANK LTD KUNDAPURA"
    },
    {
      "NUM_FINANCIER_CD": 19893,
      "TXT_FINANCIER_NAME": "V K FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19894,
      "TXT_FINANCIER_NAME": "VARINDER FINANCE (P) LTD."
    },
    {
      "NUM_FINANCIER_CD": 19895,
      "TXT_FINANCIER_NAME": "VIJAY HIRE PURCHASE & LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 19896,
      "TXT_FINANCIER_NAME": "WALL STREET FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19897,
      "TXT_FINANCIER_NAME": "WEST BENGAL FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19898,
      "TXT_FINANCIER_NAME": "yash bank"
    },
    {
      "NUM_FINANCIER_CD": 19899,
      "TXT_FINANCIER_NAME": "AIR FORCE GROUP INSURANCE FUND"
    },
    {
      "NUM_FINANCIER_CD": 19900,
      "TXT_FINANCIER_NAME": "Air Force Group Insurance Society"
    },
    {
      "NUM_FINANCIER_CD": 19901,
      "TXT_FINANCIER_NAME": "AMBICA INSTALMENTS (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 19902,
      "TXT_FINANCIER_NAME": "APIIC LTD"
    },
    {
      "NUM_FINANCIER_CD": 19903,
      "TXT_FINANCIER_NAME": "CENTURION BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19904,
      "TXT_FINANCIER_NAME": "VIJAYKANT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19905,
      "TXT_FINANCIER_NAME": "STATE TRADING CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19906,
      "TXT_FINANCIER_NAME": "THE KARNATAKA VIKAS GRAMEENA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19907,
      "TXT_FINANCIER_NAME": "VIMAL HIRE PURCHASE INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 19908,
      "TXT_FINANCIER_NAME": "SURABHI AND CO INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 19909,
      "TXT_FINANCIER_NAME": "SAI PRERNA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19910,
      "TXT_FINANCIER_NAME": "THE INDUSTRIAL CO OPEARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19911,
      "TXT_FINANCIER_NAME": "AMEER URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19912,
      "TXT_FINANCIER_NAME": "ICICIBANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 19913,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIDE AUTO FIN SPL"
    },
    {
      "NUM_FINANCIER_CD": 19914,
      "TXT_FINANCIER_NAME": "ICICI LOMBARD GENERAL INSURANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19915,
      "TXT_FINANCIER_NAME": "SANMITRA URBAN CO PO BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 19916,
      "TXT_FINANCIER_NAME": "SRI GANAPATHI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19917,
      "TXT_FINANCIER_NAME": "SHRI VEERSHAIV NON AGRICULTURAL SAHKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19918,
      "TXT_FINANCIER_NAME": "HASSAN DISTRICT COOPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19919,
      "TXT_FINANCIER_NAME": "KANAYANNUR COOPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 19920,
      "TXT_FINANCIER_NAME": "BOTHRA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 19921,
      "TXT_FINANCIER_NAME": "SANGLI VAIBHAV COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19922,
      "TXT_FINANCIER_NAME": "PRAGATHI CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19923,
      "TXT_FINANCIER_NAME": "SRI SATYA SAI NAGRIK SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 19924,
      "TXT_FINANCIER_NAME": "SRI SHARADA MAHILA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19925,
      "TXT_FINANCIER_NAME": "STATE TRANSPORT CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19926,
      "TXT_FINANCIER_NAME": "STERLING URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19927,
      "TXT_FINANCIER_NAME": "STERLING URBAN COOP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19928,
      "TXT_FINANCIER_NAME": "SUDHA CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19929,
      "TXT_FINANCIER_NAME": "SULEIMANI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19930,
      "TXT_FINANCIER_NAME": "SUNDARLAL SAWJI UR.CO.OP. BANK LTD. JINTUR"
    },
    {
      "NUM_FINANCIER_CD": 19931,
      "TXT_FINANCIER_NAME": "SURAT MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19932,
      "TXT_FINANCIER_NAME": "SURAT NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19933,
      "TXT_FINANCIER_NAME": "SURAT NATIONAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19934,
      "TXT_FINANCIER_NAME": "Surendranagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19935,
      "TXT_FINANCIER_NAME": "BHILWARA URBAN CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19936,
      "TXT_FINANCIER_NAME": "SANGLI TIMBER MERCHANTNAGARI PATH SANSTHA SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 19937,
      "TXT_FINANCIER_NAME": "DELHI STATE TAXI OPERATORS CO OP THRIFT CREDIT AND SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19938,
      "TXT_FINANCIER_NAME": "SRI CHANNABASAVA PATTINA SOUHARDA SAHAKARI"
    },
    {
      "NUM_FINANCIER_CD": 21373,
      "TXT_FINANCIER_NAME": "ANIL AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21374,
      "TXT_FINANCIER_NAME": "FORTUNE INTEGRATED ASSETS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21375,
      "TXT_FINANCIER_NAME": "MONIKA BHANDARI FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 21376,
      "TXT_FINANCIER_NAME": "GOA HOUSING BOARD"
    },
    {
      "NUM_FINANCIER_CD": 21377,
      "TXT_FINANCIER_NAME": "BOTHRA FINLEASE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21381,
      "TXT_FINANCIER_NAME": "TELANGANA STATE CO OPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18393,
      "TXT_FINANCIER_NAME": "STOCK HOLDING CORPORATIN OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18394,
      "TXT_FINANCIER_NAME": "THE MUDALGI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18395,
      "TXT_FINANCIER_NAME": "SHREENATH CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18396,
      "TXT_FINANCIER_NAME": "SRI BALAJI FINANCE CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 18397,
      "TXT_FINANCIER_NAME": "TAMILNAD MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18398,
      "TXT_FINANCIER_NAME": "DENA BANK WANKANER"
    },
    {
      "NUM_FINANCIER_CD": 18399,
      "TXT_FINANCIER_NAME": "THE BELGAUM DISTRICT VEGETABLE GROWERS PURCHASERS AND SELLERS COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18400,
      "TXT_FINANCIER_NAME": "THE DAVANAGERE HARIHAR URBAN SAHAKARA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18401,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN COOPERAT"
    },
    {
      "NUM_FINANCIER_CD": 18402,
      "TXT_FINANCIER_NAME": "THE JANATA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18403,
      "TXT_FINANCIER_NAME": "THE JANATA COOPERATIVE CREDIT SOCIETY LTD BRANCH KUMTA"
    },
    {
      "NUM_FINANCIER_CD": 18404,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN COOPERATIVE BANK LTD BR KUNDAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18405,
      "TXT_FINANCIER_NAME": "THANE BHARAT SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18406,
      "TXT_FINANCIER_NAME": "THE PADRA NAGAR NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18407,
      "TXT_FINANCIER_NAME": "JAWAHARLAL NEHRU PORT TRUST NHAVA SHEVA"
    },
    {
      "NUM_FINANCIER_CD": 18408,
      "TXT_FINANCIER_NAME": "SHRI SHARADA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18409,
      "TXT_FINANCIER_NAME": "NORTH KANARA GS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18410,
      "TXT_FINANCIER_NAME": "THE UTTARPARA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18411,
      "TXT_FINANCIER_NAME": "THE HAVERI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18412,
      "TXT_FINANCIER_NAME": "MAHILA CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18413,
      "TXT_FINANCIER_NAME": "THE AKOLA URBAN CO_OP.BANK LTD.,AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 18414,
      "TXT_FINANCIER_NAME": "UTI BANK LTD HAZRATGANJ"
    },
    {
      "NUM_FINANCIER_CD": 18415,
      "TXT_FINANCIER_NAME": "SHREE ARIHANT CO-OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18416,
      "TXT_FINANCIER_NAME": "THE SEVA VIKAS CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18417,
      "TXT_FINANCIER_NAME": "MAHALAKSHMI CO-OPERATIVE BANK LTD., UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 18418,
      "TXT_FINANCIER_NAME": "THE JANSEVA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18419,
      "TXT_FINANCIER_NAME": "THE SARASWAT BANK"
    },
    {
      "NUM_FINANCIER_CD": 18420,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 18421,
      "TXT_FINANCIER_NAME": "SAIL BSP"
    },
    {
      "NUM_FINANCIER_CD": 18422,
      "TXT_FINANCIER_NAME": "SHRI VEER PULIKESHI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18423,
      "TXT_FINANCIER_NAME": "PARSHWANATH CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18424,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGRIK CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18425,
      "TXT_FINANCIER_NAME": "THE BANK OF TOKYO MITSUBISHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 18426,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18427,
      "TXT_FINANCIER_NAME": "THE MEHSANA NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18428,
      "TXT_FINANCIER_NAME": "KOTAK MAHENDRA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18429,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18430,
      "TXT_FINANCIER_NAME": "HADOTI KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18431,
      "TXT_FINANCIER_NAME": "THE JHALOD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18432,
      "TXT_FINANCIER_NAME": "JANAKALYAN SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18433,
      "TXT_FINANCIER_NAME": "PUNJAB & MAHARASHTRA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18434,
      "TXT_FINANCIER_NAME": "SHRI VINAYAK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18435,
      "TXT_FINANCIER_NAME": "PAVITHRA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18436,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18437,
      "TXT_FINANCIER_NAME": "ARIHANT URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18438,
      "TXT_FINANCIER_NAME": "CENTRAL BANK OF INDIA, CANTONMENT ROAD"
    },
    {
      "NUM_FINANCIER_CD": 18439,
      "TXT_FINANCIER_NAME": "DOMBIVLI NAGARI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18440,
      "TXT_FINANCIER_NAME": "SHRI VYANKATESH CO-OP. BANK LTD., MALEGAON"
    },
    {
      "NUM_FINANCIER_CD": 18441,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN.LTD."
    },
    {
      "NUM_FINANCIER_CD": 18442,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDERA FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 18443,
      "TXT_FINANCIER_NAME": "THE ZOROASTRIAN CO - OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18444,
      "TXT_FINANCIER_NAME": "MAHESH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18445,
      "TXT_FINANCIER_NAME": "THE THANE JANATA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18446,
      "TXT_FINANCIER_NAME": "THE KURMANCHAL NAGAR SAHIKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18447,
      "TXT_FINANCIER_NAME": "JANTA SAHAKARI BANK LTD.PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18448,
      "TXT_FINANCIER_NAME": "SANGOLA URBAN CO-OP.BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 18449,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPERATIVE BANK LTD. NOIDA"
    },
    {
      "NUM_FINANCIER_CD": 18450,
      "TXT_FINANCIER_NAME": "INDIA LEASE DEV. LTD."
    },
    {
      "NUM_FINANCIER_CD": 18451,
      "TXT_FINANCIER_NAME": "JILA SAHKARI KENDRIYA BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18452,
      "TXT_FINANCIER_NAME": "THE CHANDIGARH STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18453,
      "TXT_FINANCIER_NAME": "THE HANGAL URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18454,
      "TXT_FINANCIER_NAME": "THE PALAKKAD DISTRICT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18455,
      "TXT_FINANCIER_NAME": "THE MEENACHIL EAST URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18456,
      "TXT_FINANCIER_NAME": "DNYANDEEP CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18457,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO-OPERATIVE BANK LTD. ANKOLA"
    },
    {
      "NUM_FINANCIER_CD": 18458,
      "TXT_FINANCIER_NAME": "ANKOLA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18459,
      "TXT_FINANCIER_NAME": "THE BHUJ MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18460,
      "TXT_FINANCIER_NAME": "PARSIK JANATA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18461,
      "TXT_FINANCIER_NAME": "BARODA FINCAP LTD"
    },
    {
      "NUM_FINANCIER_CD": 18462,
      "TXT_FINANCIER_NAME": "SARDARGANJ MERCANTILE CO.PO.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18463,
      "TXT_FINANCIER_NAME": "Sardarganj mercantile co.op bank ltd."
    },
    {
      "NUM_FINANCIER_CD": 18464,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18465,
      "TXT_FINANCIER_NAME": "PUNJAB NATIONAL BANK,"
    },
    {
      "NUM_FINANCIER_CD": 18466,
      "TXT_FINANCIER_NAME": "THE SAHYADRI SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18467,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR MERCHANTS CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18468,
      "TXT_FINANCIER_NAME": "THE COMPTROLLERS OFFICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18469,
      "TXT_FINANCIER_NAME": "JANTA COMMERCIAL CO OPP BANK KHAMGAON."
    },
    {
      "NUM_FINANCIER_CD": 18470,
      "TXT_FINANCIER_NAME": "Dharmavir Sambhaji Urban Co-op. Bank Ltd. Pimpri-Chinchwad"
    },
    {
      "NUM_FINANCIER_CD": 18471,
      "TXT_FINANCIER_NAME": "TATA CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 18472,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18473,
      "TXT_FINANCIER_NAME": "HINDUSTAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18474,
      "TXT_FINANCIER_NAME": "KERALA MERCANTILE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18475,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18476,
      "TXT_FINANCIER_NAME": "THE THANE JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18477,
      "TXT_FINANCIER_NAME": "VASANTDADA NAGARI SAHAKARI .BANK.LTD"
    },
    {
      "NUM_FINANCIER_CD": 18478,
      "TXT_FINANCIER_NAME": "VASANTDADA NAGARI SAHAKARI BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 18479,
      "TXT_FINANCIER_NAME": "VASANTDADA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18480,
      "TXT_FINANCIER_NAME": "KARAMANA CO-OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18481,
      "TXT_FINANCIER_NAME": "THE FEDERAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 18482,
      "TXT_FINANCIER_NAME": "MAHESH URBAN CO-BANK"
    },
    {
      "NUM_FINANCIER_CD": 18483,
      "TXT_FINANCIER_NAME": "MAHESH URBAN CO- BANK"
    },
    {
      "NUM_FINANCIER_CD": 18484,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL"
    },
    {
      "NUM_FINANCIER_CD": 18485,
      "TXT_FINANCIER_NAME": "ING VASYA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18486,
      "TXT_FINANCIER_NAME": "MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 18487,
      "TXT_FINANCIER_NAME": "THE BANTRA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18488,
      "TXT_FINANCIER_NAME": "CHANDRA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18489,
      "TXT_FINANCIER_NAME": "SIDDAGANGA URBAN CO OP BANK LTD TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 18490,
      "TXT_FINANCIER_NAME": "ARIHANT GRAMIN BIGAR SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18491,
      "TXT_FINANCIER_NAME": "THE GANDHIDHAM CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19939,
      "TXT_FINANCIER_NAME": "THE STATE TRANSPORT (E) CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19940,
      "TXT_FINANCIER_NAME": "THE SURAT DISTRICT CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19941,
      "TXT_FINANCIER_NAME": "THE SURAT MAHILA NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19942,
      "TXT_FINANCIER_NAME": "THE SURAT NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19943,
      "TXT_FINANCIER_NAME": "THE SURAT PEOPLES CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19944,
      "TXT_FINANCIER_NAME": "THE SURAT PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19945,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR DISTRICT CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19946,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR PEOPLES CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19947,
      "TXT_FINANCIER_NAME": "THE SUVIKAS PEOPLES CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19948,
      "TXT_FINANCIER_NAME": "The Swasakthi Mercantile Co-operative Urban Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19949,
      "TXT_FINANCIER_NAME": "THE TAMILNADU CIRCLE POSTAL CO - OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19950,
      "TXT_FINANCIER_NAME": "THE TAMILNADU INDUSTRIAL CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19951,
      "TXT_FINANCIER_NAME": "THE TAMILNADU STATE APEX CO-OPEATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19952,
      "TXT_FINANCIER_NAME": "THE THANE BHARAT SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 19953,
      "TXT_FINANCIER_NAME": "THE THANE DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19954,
      "TXT_FINANCIER_NAME": "THE THANE JANATA SAHAKATI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19955,
      "TXT_FINANCIER_NAME": "THE THANE JANTA SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 19956,
      "TXT_FINANCIER_NAME": "JAIN GENERAL FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 19957,
      "TXT_FINANCIER_NAME": "THE NABAPALLI COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19958,
      "TXT_FINANCIER_NAME": "CHATRAPATI NASHIK JILHA NAGARI SAHAKARI PATHASANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19959,
      "TXT_FINANCIER_NAME": "SINDHANUR URBAN SOUHARDHA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19960,
      "TXT_FINANCIER_NAME": "VEER MOTOR FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19961,
      "TXT_FINANCIER_NAME": "LAKSHMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19962,
      "TXT_FINANCIER_NAME": "THE RAJPIPLA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19963,
      "TXT_FINANCIER_NAME": "THE AGRASEN NAGARI SAHAKARI BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 19964,
      "TXT_FINANCIER_NAME": "UJJAIN PARASPAR SAHAKARI BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 19965,
      "TXT_FINANCIER_NAME": "HOUSING DEVELOPMENT FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19966,
      "TXT_FINANCIER_NAME": "BANGANGA NAGARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19967,
      "TXT_FINANCIER_NAME": "SHIVALIK MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19968,
      "TXT_FINANCIER_NAME": "Army Group Insurance Fund"
    },
    {
      "NUM_FINANCIER_CD": 19969,
      "TXT_FINANCIER_NAME": "Associates India Fin Ser Ltd.,"
    },
    {
      "NUM_FINANCIER_CD": 19970,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FIN SER PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19971,
      "TXT_FINANCIER_NAME": "ASSOCIATES INDIA FIN SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19972,
      "TXT_FINANCIER_NAME": "AVON CAPITAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19973,
      "TXT_FINANCIER_NAME": "BALAJI INDOFIN (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 19974,
      "TXT_FINANCIER_NAME": "BANK OF MYSORE"
    },
    {
      "NUM_FINANCIER_CD": 19975,
      "TXT_FINANCIER_NAME": "BARING PRIVATE EQUITY PARTNERS (India) LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19976,
      "TXT_FINANCIER_NAME": "BHARAT PETROLEUM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19977,
      "TXT_FINANCIER_NAME": "BISHAN DASS WADHWA AND CO"
    },
    {
      "NUM_FINANCIER_CD": 19978,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19979,
      "TXT_FINANCIER_NAME": "ICICI"
    },
    {
      "NUM_FINANCIER_CD": 19980,
      "TXT_FINANCIER_NAME": "ALLAHBAD BANK"
    },
    {
      "NUM_FINANCIER_CD": 19981,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH STEELS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19982,
      "TXT_FINANCIER_NAME": "MITTAL FINSEC PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19983,
      "TXT_FINANCIER_NAME": "AHILYADEVI NAGARI SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19984,
      "TXT_FINANCIER_NAME": "DATTATRAYA MAHARAJ KALAMBE JAOLI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19985,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIDE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19986,
      "TXT_FINANCIER_NAME": "MAND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19987,
      "TXT_FINANCIER_NAME": "KAKASAHEB THORAT GRAMIN BIGER SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19988,
      "TXT_FINANCIER_NAME": "The South Indian Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19989,
      "TXT_FINANCIER_NAME": "THE SOUTH INDIAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19990,
      "TXT_FINANCIER_NAME": "BANK OF BARODA,"
    },
    {
      "NUM_FINANCIER_CD": 19991,
      "TXT_FINANCIER_NAME": "THE MANGALORE CATHOLIC CO OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 19992,
      "TXT_FINANCIER_NAME": "KANNUR PRIMARY CO OP AGRICULTURE RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19993,
      "TXT_FINANCIER_NAME": "IL AND FS TRANSPOTRATION NETWORK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19994,
      "TXT_FINANCIER_NAME": "PONNANI CO OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19995,
      "TXT_FINANCIER_NAME": "BOMBAY STOCK EXCHANGE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19996,
      "TXT_FINANCIER_NAME": "CITY CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 19997,
      "TXT_FINANCIER_NAME": "AKOLA MERCHANT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19998,
      "TXT_FINANCIER_NAME": "CHHALLANI MAHAVEER CHAND"
    },
    {
      "NUM_FINANCIER_CD": 19999,
      "TXT_FINANCIER_NAME": "BANGANGA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20000,
      "TXT_FINANCIER_NAME": "THE BAYAD NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20001,
      "TXT_FINANCIER_NAME": "SUVARNA LAXMI SAHAKARI PATAPEDDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20002,
      "TXT_FINANCIER_NAME": "SUVARNA MANGAL MAHILA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20003,
      "TXT_FINANCIER_NAME": "TAMILNADU STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20004,
      "TXT_FINANCIER_NAME": "Tamluk Ghata District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20005,
      "TXT_FINANCIER_NAME": "TELLICHERRY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20006,
      "TXT_FINANCIER_NAME": "TEXTILE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20007,
      "TXT_FINANCIER_NAME": "TEXTILE TRADERS CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20008,
      "TXT_FINANCIER_NAME": "TEXTILE TRADERS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20009,
      "TXT_FINANCIER_NAME": "THANE ZILLA MADHYAWARTI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20010,
      "TXT_FINANCIER_NAME": "THASRA PEOPLES CO.OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20011,
      "TXT_FINANCIER_NAME": "THASRA PEOPLES CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20012,
      "TXT_FINANCIER_NAME": "THE A P STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20013,
      "TXT_FINANCIER_NAME": "THE A.P. VARDHAMAN(MAHILA) CO-OPEARATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20014,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DIST CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20015,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20016,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20017,
      "TXT_FINANCIER_NAME": "THE KALOL URBAN CO OP"
    },
    {
      "NUM_FINANCIER_CD": 20018,
      "TXT_FINANCIER_NAME": "A P TRANSCO"
    },
    {
      "NUM_FINANCIER_CD": 20019,
      "TXT_FINANCIER_NAME": "NOIDA COMMERCIAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20020,
      "TXT_FINANCIER_NAME": "THE UNION COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20021,
      "TXT_FINANCIER_NAME": "THE URBAN CO OP BANK LTD. DHARANGAON"
    },
    {
      "NUM_FINANCIER_CD": 20022,
      "TXT_FINANCIER_NAME": "THE VADGAON URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20023,
      "TXT_FINANCIER_NAME": "THE VAIDYANATH URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20024,
      "TXT_FINANCIER_NAME": "THE VALLABH VIDHYANAGAR COMMERCIAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20025,
      "TXT_FINANCIER_NAME": "THE VARDHMAN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20026,
      "TXT_FINANCIER_NAME": "THE VEPAR UDHYOG VIKAS SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20027,
      "TXT_FINANCIER_NAME": "THE VERAVAL PEOPLES CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20028,
      "TXT_FINANCIER_NAME": "THE VISHWESHWAR SAHAKARI BANK LTD;PUNE"
    },
    {
      "NUM_FINANCIER_CD": 20029,
      "TXT_FINANCIER_NAME": "THE VISHWESHWAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20030,
      "TXT_FINANCIER_NAME": "THE CANNANORE CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20031,
      "TXT_FINANCIER_NAME": "COMPEER FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20032,
      "TXT_FINANCIER_NAME": "BRD SECURITIES LTD."
    },
    {
      "NUM_FINANCIER_CD": 20033,
      "TXT_FINANCIER_NAME": "CAPITAL TRUST LTD"
    },
    {
      "NUM_FINANCIER_CD": 20034,
      "TXT_FINANCIER_NAME": "CHAMBAL FERTILIZERS AND CHEMICALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 18392,
      "TXT_FINANCIER_NAME": "SRI RAM TRANSPORT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18492,
      "TXT_FINANCIER_NAME": "SRIRAM CITI UNION FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18493,
      "TXT_FINANCIER_NAME": "AU FINANCIERS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18494,
      "TXT_FINANCIER_NAME": "BANK OF INDIA BRANCH GHAZIABAD"
    },
    {
      "NUM_FINANCIER_CD": 18495,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18496,
      "TXT_FINANCIER_NAME": "SEYAD SHARIAT FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18497,
      "TXT_FINANCIER_NAME": "THE URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18498,
      "TXT_FINANCIER_NAME": "TUMKUR MERCHANTS SOUHARDA CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18499,
      "TXT_FINANCIER_NAME": "HARIHARESHWAR SAHAKARI BANK LTD WAI"
    },
    {
      "NUM_FINANCIER_CD": 18500,
      "TXT_FINANCIER_NAME": "OIL AND NATURAL GAS CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18501,
      "TXT_FINANCIER_NAME": "The Punjab State Coop Agricultural Dev Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 18502,
      "TXT_FINANCIER_NAME": "THE PUNJAB STATE COOPERATIVE AGRICULTURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18503,
      "TXT_FINANCIER_NAME": "WARTSILA INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18504,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN NAGARRI SAAH PATHMARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 18505,
      "TXT_FINANCIER_NAME": "DHANWANTARI NAGARI SAHAKARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18506,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF GOA"
    },
    {
      "NUM_FINANCIER_CD": 18507,
      "TXT_FINANCIER_NAME": "THE ACCOUNTANT GENERALS OFFICE EMPLOYEES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18508,
      "TXT_FINANCIER_NAME": "THE JANATHA CO OP CREDIT SOCIETY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18509,
      "TXT_FINANCIER_NAME": "SUVARNA LAXMI SAHAKARI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18510,
      "TXT_FINANCIER_NAME": "GUJARAT STATE CO OP AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18511,
      "TXT_FINANCIER_NAME": "ADHAR SAHAKARI PATHPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 18512,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURAL CREDIT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18513,
      "TXT_FINANCIER_NAME": "BALAJI FINANCE CO REGD"
    },
    {
      "NUM_FINANCIER_CD": 18514,
      "TXT_FINANCIER_NAME": "THE GHATAL PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18515,
      "TXT_FINANCIER_NAME": "ST JOSEPH CO OPERATIVE CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 18516,
      "TXT_FINANCIER_NAME": "JOKHIRAM FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18517,
      "TXT_FINANCIER_NAME": "BARANAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18518,
      "TXT_FINANCIER_NAME": "THE RAIGAD DISTRICT CENTRAL CO-OP BANK LTD.PEN"
    },
    {
      "NUM_FINANCIER_CD": 18519,
      "TXT_FINANCIER_NAME": "INDIAN OVERSEAS BANK , CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 18520,
      "TXT_FINANCIER_NAME": "THE KADUTHURUTHY URBAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18521,
      "TXT_FINANCIER_NAME": "THE AMRAVATI MERCHANT CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18522,
      "TXT_FINANCIER_NAME": "SHRI BALAJI URBAN CO.OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18523,
      "TXT_FINANCIER_NAME": "THE ALWAYE URBAN CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18524,
      "TXT_FINANCIER_NAME": "BHAICHAND HIRACHAND RAISONI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18525,
      "TXT_FINANCIER_NAME": "THANE DISTRICT MADHYAWATI BANK"
    },
    {
      "NUM_FINANCIER_CD": 18526,
      "TXT_FINANCIER_NAME": "THE SATANA MERCHANTS CO-OP. BANK LID., SATANA"
    },
    {
      "NUM_FINANCIER_CD": 18527,
      "TXT_FINANCIER_NAME": "BHARAT PETROLEUM CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 18528,
      "TXT_FINANCIER_NAME": "JALGAON JANATA SAH. BANK LTD. JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 18529,
      "TXT_FINANCIER_NAME": "TRICHY SRI AMMAN FINANCE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 18530,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR PEOPLE;S CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18531,
      "TXT_FINANCIER_NAME": "THE SURENDRANAGAR PEOPLES CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18532,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18533,
      "TXT_FINANCIER_NAME": "ICCI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18534,
      "TXT_FINANCIER_NAME": "The Chitnavispura Friend s Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18535,
      "TXT_FINANCIER_NAME": "PRAGATI NAGRIK SAHAKARI BANK MYD."
    },
    {
      "NUM_FINANCIER_CD": 18536,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18537,
      "TXT_FINANCIER_NAME": "URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18538,
      "TXT_FINANCIER_NAME": "THE KOSAMBA MERCANTILE CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18539,
      "TXT_FINANCIER_NAME": "THE GADCHIROLI DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18540,
      "TXT_FINANCIER_NAME": "KURMANCHAL NAGAR SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18541,
      "TXT_FINANCIER_NAME": "THE MULGUND URBAN SOURHARAD CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18542,
      "TXT_FINANCIER_NAME": "THE MULGUND URBAN SOUHARDA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18543,
      "TXT_FINANCIER_NAME": "BRAHMADEODADA MANE SAHAKARI BANK LTD.,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18544,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18545,
      "TXT_FINANCIER_NAME": "BHAGYODAYA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18546,
      "TXT_FINANCIER_NAME": "SREE CHARAN SOUHARDHA CO-OPARETIVE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18547,
      "TXT_FINANCIER_NAME": "THE THANE DIST. CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18548,
      "TXT_FINANCIER_NAME": "THE KHAMGAON URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18549,
      "TXT_FINANCIER_NAME": "NAGARI SAHAKARI BANK LTD KODOLI"
    },
    {
      "NUM_FINANCIER_CD": 18550,
      "TXT_FINANCIER_NAME": "IDBI LTD"
    },
    {
      "NUM_FINANCIER_CD": 18551,
      "TXT_FINANCIER_NAME": "THE ANAND MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18552,
      "TXT_FINANCIER_NAME": "KUMBHI KASARI SAH BANK LTD KUDITRE"
    },
    {
      "NUM_FINANCIER_CD": 18553,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18554,
      "TXT_FINANCIER_NAME": "KALLAPPANA AWADE ICHALKARANJI JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18555,
      "TXT_FINANCIER_NAME": "THE SHAHADA PEOPLES CO-OP BANK LTD., SHAHADA"
    },
    {
      "NUM_FINANCIER_CD": 18556,
      "TXT_FINANCIER_NAME": "RAMGARHIA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18557,
      "TXT_FINANCIER_NAME": "THE NAVAPUR MERCANTILE CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18558,
      "TXT_FINANCIER_NAME": "THE SABARKANTHA DISTRICT CENTRAL CO-OP.BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 18559,
      "TXT_FINANCIER_NAME": "THE KHEDBRAHMA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18560,
      "TXT_FINANCIER_NAME": "SANGLI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18561,
      "TXT_FINANCIER_NAME": "ASSOCIATE FINANCE SERVICES PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 18562,
      "TXT_FINANCIER_NAME": "TATA MOTORS FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18563,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18564,
      "TXT_FINANCIER_NAME": "GUARDIAN SOUHARDA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 18565,
      "TXT_FINANCIER_NAME": "THE ISLAMPUR URBAN CO-OP. BANK LTD., ISLAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 18566,
      "TXT_FINANCIER_NAME": "APEPDCL"
    },
    {
      "NUM_FINANCIER_CD": 18567,
      "TXT_FINANCIER_NAME": "ING SYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18568,
      "TXT_FINANCIER_NAME": "THE GANDHIDHAM MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18569,
      "TXT_FINANCIER_NAME": "THE ING VYSYA BANK LIMITED.,"
    },
    {
      "NUM_FINANCIER_CD": 18570,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 18571,
      "TXT_FINANCIER_NAME": "TATA CAPITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 18572,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL LTD. BRANCH-MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 18573,
      "TXT_FINANCIER_NAME": "Standard Chartered"
    },
    {
      "NUM_FINANCIER_CD": 18574,
      "TXT_FINANCIER_NAME": "THE NAVAL DOCKYARD CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18575,
      "TXT_FINANCIER_NAME": "PREM FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18576,
      "TXT_FINANCIER_NAME": "BHEL EMPLOYEES CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18577,
      "TXT_FINANCIER_NAME": "SHRI RAM TRANSPORT FINANCE COLTD"
    },
    {
      "NUM_FINANCIER_CD": 18578,
      "TXT_FINANCIER_NAME": "CHENNAI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 18579,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FIN SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 18580,
      "TXT_FINANCIER_NAME": "CITICORP MARUTI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18581,
      "TXT_FINANCIER_NAME": "MAHARASHTRA STATE OBC FINANCE DEVELOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 18582,
      "TXT_FINANCIER_NAME": "FULLERTON INDIA CREDIT COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18583,
      "TXT_FINANCIER_NAME": "THE SETU CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18584,
      "TXT_FINANCIER_NAME": "SRI GAYATHRI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18585,
      "TXT_FINANCIER_NAME": "SHIVKRUPA SAHAKARI PAT PEDI LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 20035,
      "TXT_FINANCIER_NAME": "CHENNAI PETROLEUM CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20036,
      "TXT_FINANCIER_NAME": "CMC LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20037,
      "TXT_FINANCIER_NAME": "BALAJI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20038,
      "TXT_FINANCIER_NAME": "SHREE MAHILA CREDIT SOUHARD SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20039,
      "TXT_FINANCIER_NAME": "LOKNETE SHANKARAO BALAJI WAJI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MATYADIT SINNAR"
    },
    {
      "NUM_FINANCIER_CD": 20040,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCE COLTD"
    },
    {
      "NUM_FINANCIER_CD": 20041,
      "TXT_FINANCIER_NAME": "THE CHANASMA COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20042,
      "TXT_FINANCIER_NAME": "PUNIA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20043,
      "TXT_FINANCIER_NAME": "THE MAHINDRA AND MAHINDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20044,
      "TXT_FINANCIER_NAME": "THE GOKAK URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20045,
      "TXT_FINANCIER_NAME": "TUMSARESHWAR MAHAGANPATI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20046,
      "TXT_FINANCIER_NAME": "DURGA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20047,
      "TXT_FINANCIER_NAME": "THE EASTERN RAILWAY EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20048,
      "TXT_FINANCIER_NAME": "URGENT FINVEST LTD"
    },
    {
      "NUM_FINANCIER_CD": 20049,
      "TXT_FINANCIER_NAME": "THE NAV JEEVAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20050,
      "TXT_FINANCIER_NAME": "KADUTHURUTHY URBAN COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20051,
      "TXT_FINANCIER_NAME": "ARYABRAT GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 20052,
      "TXT_FINANCIER_NAME": "RAJASTHAN TRACTOR MACHINERY"
    },
    {
      "NUM_FINANCIER_CD": 20053,
      "TXT_FINANCIER_NAME": "AMIT FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20054,
      "TXT_FINANCIER_NAME": "THE REVDANDA CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20055,
      "TXT_FINANCIER_NAME": "THANJAVUR COMMERCEPVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20056,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPLES CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20057,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20058,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20059,
      "TXT_FINANCIER_NAME": "THE AJARA URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20060,
      "TXT_FINANCIER_NAME": "THE AKOLA JANATA COMMERCIAL. CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20061,
      "TXT_FINANCIER_NAME": "THE AKOLA URBAN CO-OP BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 20062,
      "TXT_FINANCIER_NAME": "THE AMRAVATI DIST. CENTRAL CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 20063,
      "TXT_FINANCIER_NAME": "THE AMRAVATI DISTRICT CENTRAL COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20064,
      "TXT_FINANCIER_NAME": "THE AMRAVATI PEOPLES CO. OP. BANK"
    },
    {
      "NUM_FINANCIER_CD": 20065,
      "TXT_FINANCIER_NAME": "THE ANDHRA PRADESH STATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20066,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDHYOGNAGAR CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20067,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20068,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20069,
      "TXT_FINANCIER_NAME": "THE ANKLESHWAR UDYOGNAGAR CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20070,
      "TXT_FINANCIER_NAME": "THE AP STATE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20071,
      "TXT_FINANCIER_NAME": "VITA URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20072,
      "TXT_FINANCIER_NAME": "HARYANA POWER GENERATION CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20073,
      "TXT_FINANCIER_NAME": "YASHWANTRAO CHAVAN GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20074,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDORE AMBIKAPUR"
    },
    {
      "NUM_FINANCIER_CD": 20075,
      "TXT_FINANCIER_NAME": "THE DECCAN MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20076,
      "TXT_FINANCIER_NAME": "The Bank Of Rajasthan Ltd"
    },
    {
      "NUM_FINANCIER_CD": 20077,
      "TXT_FINANCIER_NAME": "GENESIS FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20078,
      "TXT_FINANCIER_NAME": "PINTURAJ INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 20079,
      "TXT_FINANCIER_NAME": "REPCO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20080,
      "TXT_FINANCIER_NAME": "NASHIK ROAD NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20081,
      "TXT_FINANCIER_NAME": "THE VIZIANAGARAM COOPERATIVE URBEN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20082,
      "TXT_FINANCIER_NAME": "THE VYANKATESHWARA SAHAKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 20083,
      "TXT_FINANCIER_NAME": "THE WAGHODIA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20084,
      "TXT_FINANCIER_NAME": "THE WAI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20085,
      "TXT_FINANCIER_NAME": "THE WEST BENGAL STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20086,
      "TXT_FINANCIER_NAME": "THE WEST BENGAL STATE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20087,
      "TXT_FINANCIER_NAME": "THE YAMUNA NAGAR CENTRAL CO-POERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20088,
      "TXT_FINANCIER_NAME": "THE YARAGATTI URBAN CO-OP CREDIT BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 20089,
      "TXT_FINANCIER_NAME": "THODUPUZHA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20090,
      "TXT_FINANCIER_NAME": "Thoothukudi District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20091,
      "TXT_FINANCIER_NAME": "TIRUMALA CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20092,
      "TXT_FINANCIER_NAME": "TIRUPATI URBAN CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20093,
      "TXT_FINANCIER_NAME": "Tiruvannamalai District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20094,
      "TXT_FINANCIER_NAME": "Tonk District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20095,
      "TXT_FINANCIER_NAME": "TRIPURA STATE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20096,
      "TXT_FINANCIER_NAME": "TRIVANDRUM CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20097,
      "TXT_FINANCIER_NAME": "TUMKUR VEERASHAIVA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20098,
      "TXT_FINANCIER_NAME": "TURA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20099,
      "TXT_FINANCIER_NAME": "UDAIPUR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20100,
      "TXT_FINANCIER_NAME": "UDYAM VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20101,
      "TXT_FINANCIER_NAME": "NAVARATHNA JAIN CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20102,
      "TXT_FINANCIER_NAME": "GUJARAT NARMADA VALLEY FERTILIZER CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20103,
      "TXT_FINANCIER_NAME": "CMD ENGINEERS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20104,
      "TXT_FINANCIER_NAME": "COCHIN SHIPYARD LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20105,
      "TXT_FINANCIER_NAME": "CONTAINER CORPORATION OF I LTD"
    },
    {
      "NUM_FINANCIER_CD": 20106,
      "TXT_FINANCIER_NAME": "CONTAINER CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20107,
      "TXT_FINANCIER_NAME": "DANI ENTERPRISES P LTD."
    },
    {
      "NUM_FINANCIER_CD": 20108,
      "TXT_FINANCIER_NAME": "DEEP FINVEST PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 20109,
      "TXT_FINANCIER_NAME": "DET NORSKE VERITAS AS"
    },
    {
      "NUM_FINANCIER_CD": 20110,
      "TXT_FINANCIER_NAME": "DHEERAJ INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20111,
      "TXT_FINANCIER_NAME": "SRIRAM TRANSPORT FINANCE CO LTD BANGALORE"
    },
    {
      "NUM_FINANCIER_CD": 20112,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20113,
      "TXT_FINANCIER_NAME": "PUSAD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20114,
      "TXT_FINANCIER_NAME": "SIGMA ALDRICH CHEMICALS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20115,
      "TXT_FINANCIER_NAME": "STATE BANK OF NOVA SCOTIA"
    },
    {
      "NUM_FINANCIER_CD": 20116,
      "TXT_FINANCIER_NAME": "THE BELGAUM DIST CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20117,
      "TXT_FINANCIER_NAME": "KASUNDIA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20118,
      "TXT_FINANCIER_NAME": "ANDHRAPRADESH MAHESH CO-OP URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20119,
      "TXT_FINANCIER_NAME": "Bankura District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20120,
      "TXT_FINANCIER_NAME": "BHAGINI NIVEDITA SHA.BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20121,
      "TXT_FINANCIER_NAME": "Bhatinda District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20122,
      "TXT_FINANCIER_NAME": "Bhawani Patna District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20123,
      "TXT_FINANCIER_NAME": "Bikaner District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20124,
      "TXT_FINANCIER_NAME": "Buldhana District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20125,
      "TXT_FINANCIER_NAME": "Chansma Nagrik Sahakari Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20126,
      "TXT_FINANCIER_NAME": "CHENNAI CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20127,
      "TXT_FINANCIER_NAME": "CHITANAVISPURA FRIENDS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20128,
      "TXT_FINANCIER_NAME": "COLOUR MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18586,
      "TXT_FINANCIER_NAME": "The Sangrur Central Cooperative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 18587,
      "TXT_FINANCIER_NAME": "SAI GOKUL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18588,
      "TXT_FINANCIER_NAME": "BAPOOJI PATTINA SOUHARAD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 18589,
      "TXT_FINANCIER_NAME": "AKKAMAHADEVI MAHILA NAGARI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18590,
      "TXT_FINANCIER_NAME": "VASAI VIKAS SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18591,
      "TXT_FINANCIER_NAME": "CHERUTHAZHAM SERVICE CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18592,
      "TXT_FINANCIER_NAME": "THODUPUZHA URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18593,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA LUL DOUNG UTTARAKHAND"
    },
    {
      "NUM_FINANCIER_CD": 18594,
      "TXT_FINANCIER_NAME": "STEEL AUTHORITY OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18595,
      "TXT_FINANCIER_NAME": "KANAKA PATTANA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 18596,
      "TXT_FINANCIER_NAME": "THE SATTARI URBAN COOPERATIVE CREDIT SOCIETY VALPOI"
    },
    {
      "NUM_FINANCIER_CD": 18597,
      "TXT_FINANCIER_NAME": "REGIONAL EXECUTIVE DIRECTOR"
    },
    {
      "NUM_FINANCIER_CD": 18598,
      "TXT_FINANCIER_NAME": "REGIONAL EXECUTIVE DIRECTOR AIRPORT AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18599,
      "TXT_FINANCIER_NAME": "GUJARAT INDUSTRIAL POWER CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18600,
      "TXT_FINANCIER_NAME": "OCHANTHURUTH SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18601,
      "TXT_FINANCIER_NAME": "THE ANAND MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18602,
      "TXT_FINANCIER_NAME": "HINDUSTAN CO-OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18603,
      "TXT_FINANCIER_NAME": "SHRI BASVESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18604,
      "TXT_FINANCIER_NAME": "ING-VYSYA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18605,
      "TXT_FINANCIER_NAME": "INDUS IND BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18606,
      "TXT_FINANCIER_NAME": "THE LUNAWADA PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18607,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18608,
      "TXT_FINANCIER_NAME": "THE NAVAKALYAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18609,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SER. LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 18610,
      "TXT_FINANCIER_NAME": "Shrikrishna Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18611,
      "TXT_FINANCIER_NAME": "BALASINOR NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18612,
      "TXT_FINANCIER_NAME": "THE SEVALIA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18613,
      "TXT_FINANCIER_NAME": "THE BANK EMPLOYEES CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18614,
      "TXT_FINANCIER_NAME": "NABARD BANK"
    },
    {
      "NUM_FINANCIER_CD": 18615,
      "TXT_FINANCIER_NAME": "THE GANDHINAGAR NAGARIK CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18616,
      "TXT_FINANCIER_NAME": "BHEL EMPLOYEES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18617,
      "TXT_FINANCIER_NAME": "VYSYA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18618,
      "TXT_FINANCIER_NAME": "MATHURA ZILA SAHKARI BANK LTD. MATHURA"
    },
    {
      "NUM_FINANCIER_CD": 18619,
      "TXT_FINANCIER_NAME": "NAVABHARAT CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18620,
      "TXT_FINANCIER_NAME": "THE DECCAN URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18621,
      "TXT_FINANCIER_NAME": "THE MATTANCHERRY MAHAJANIK CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18622,
      "TXT_FINANCIER_NAME": "NAGINA URBAN CO,OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18623,
      "TXT_FINANCIER_NAME": "maruti countrywide"
    },
    {
      "NUM_FINANCIER_CD": 18624,
      "TXT_FINANCIER_NAME": "MAHARANA PRATAP CO-OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18625,
      "TXT_FINANCIER_NAME": "PRAVARA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18626,
      "TXT_FINANCIER_NAME": "THE BELGAUM DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18627,
      "TXT_FINANCIER_NAME": "GMAC FINANCIAL SERVICES INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 18628,
      "TXT_FINANCIER_NAME": "THE BAGHAT URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18629,
      "TXT_FINANCIER_NAME": "THE KALOL URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18630,
      "TXT_FINANCIER_NAME": "Shajapur Nagrik sahkari Bank Mydt."
    },
    {
      "NUM_FINANCIER_CD": 18631,
      "TXT_FINANCIER_NAME": "SIRCILLA CO OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18632,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18633,
      "TXT_FINANCIER_NAME": "THE FEDERAL BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 18634,
      "TXT_FINANCIER_NAME": "SHRI YASHWANT SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18635,
      "TXT_FINANCIER_NAME": "NAINITAL DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18636,
      "TXT_FINANCIER_NAME": "ORIENTAL BANK OF COMMERCE,"
    },
    {
      "NUM_FINANCIER_CD": 18637,
      "TXT_FINANCIER_NAME": "THE BULDANA DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18638,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO-OPERATIVE BANK LTD., ANKOLA"
    },
    {
      "NUM_FINANCIER_CD": 18639,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SAHAKARI BANK LTD., PETH"
    },
    {
      "NUM_FINANCIER_CD": 18640,
      "TXT_FINANCIER_NAME": "ASSAM GRAMIN VIKASH BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18641,
      "TXT_FINANCIER_NAME": "SHREE BARIA NAGRIK BANK"
    },
    {
      "NUM_FINANCIER_CD": 18642,
      "TXT_FINANCIER_NAME": "THE GODHRA URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18643,
      "TXT_FINANCIER_NAME": "THE KAPADWANJ PEOPLES CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18644,
      "TXT_FINANCIER_NAME": "THE HALOL MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18645,
      "TXT_FINANCIER_NAME": "PUNJAB NATIONAL BANK."
    },
    {
      "NUM_FINANCIER_CD": 18646,
      "TXT_FINANCIER_NAME": "KOTEK MAHINDRA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18647,
      "TXT_FINANCIER_NAME": "THE SAHYADRI SAHAKARI BANK LTD;MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 18648,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FIN.SER.LTD."
    },
    {
      "NUM_FINANCIER_CD": 18649,
      "TXT_FINANCIER_NAME": "THE ABHINAV SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18650,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SERV. LTD."
    },
    {
      "NUM_FINANCIER_CD": 18651,
      "TXT_FINANCIER_NAME": "DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18652,
      "TXT_FINANCIER_NAME": "The Thidanadu Service Co-Operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18653,
      "TXT_FINANCIER_NAME": "KARNATAKA CO OP MILK PRODUCER FEDERATION"
    },
    {
      "NUM_FINANCIER_CD": 18654,
      "TXT_FINANCIER_NAME": "JALGAON JANATA SAH.BANK LTD.,JALGAON"
    },
    {
      "NUM_FINANCIER_CD": 18655,
      "TXT_FINANCIER_NAME": "WARDHA NAGARI SAHAKARI ADHIKOSH (BANK) MYDT: WARDH"
    },
    {
      "NUM_FINANCIER_CD": 18656,
      "TXT_FINANCIER_NAME": "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LTD.( SCHEDULED BANK )"
    },
    {
      "NUM_FINANCIER_CD": 18657,
      "TXT_FINANCIER_NAME": "NAVSARJAN INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18658,
      "TXT_FINANCIER_NAME": "HARYANA GRAMIN KASHTRIYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 18659,
      "TXT_FINANCIER_NAME": "SREE ANJANEYA CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18660,
      "TXT_FINANCIER_NAME": "BASSEIN CATHOLIC CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18661,
      "TXT_FINANCIER_NAME": "THE MADGAUM URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18662,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM SERVICE CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18663,
      "TXT_FINANCIER_NAME": "THE WASHIM URBAN CO-OP.BANK LTD; WASHIM"
    },
    {
      "NUM_FINANCIER_CD": 18664,
      "TXT_FINANCIER_NAME": "RANGA REDDY CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18665,
      "TXT_FINANCIER_NAME": "YAVATMAL DIST.CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18666,
      "TXT_FINANCIER_NAME": "YAVATMAL DIST. CENTRAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18667,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18668,
      "TXT_FINANCIER_NAME": "SHRI SIDDESHWAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18669,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA SAHAKARI BANK LTD NANDURBAR"
    },
    {
      "NUM_FINANCIER_CD": 18670,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18671,
      "TXT_FINANCIER_NAME": "PUNJAB AND MAHARASHTRA CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18672,
      "TXT_FINANCIER_NAME": "sundaram finance ltd"
    },
    {
      "NUM_FINANCIER_CD": 18673,
      "TXT_FINANCIER_NAME": "AGRIM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18674,
      "TXT_FINANCIER_NAME": "SRI BASAVESHWARA CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18675,
      "TXT_FINANCIER_NAME": "ANAND NAGARI SAHAKARI PATSNTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18676,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PURIME LTD"
    },
    {
      "NUM_FINANCIER_CD": 18677,
      "TXT_FINANCIER_NAME": "JAMIA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18678,
      "TXT_FINANCIER_NAME": "SARDARGANJ MERCANTILE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18679,
      "TXT_FINANCIER_NAME": "PUNJAB AND SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 18680,
      "TXT_FINANCIER_NAME": "GOOD LUCK FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18681,
      "TXT_FINANCIER_NAME": "vimal finance"
    },
    {
      "NUM_FINANCIER_CD": 20129,
      "TXT_FINANCIER_NAME": "COMMERCIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20130,
      "TXT_FINANCIER_NAME": "Cuddappah District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20131,
      "TXT_FINANCIER_NAME": "DECAN MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20132,
      "TXT_FINANCIER_NAME": "DELHI NAGARIK SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20133,
      "TXT_FINANCIER_NAME": "Dhule District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20134,
      "TXT_FINANCIER_NAME": "Dindigul District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20135,
      "TXT_FINANCIER_NAME": "DISTRICT CENTRAL CO OPERATIVE BANK LTD BIJAPUR"
    },
    {
      "NUM_FINANCIER_CD": 20136,
      "TXT_FINANCIER_NAME": "DOON VALLEY URBAN CO-OP.(SC/ST) BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20137,
      "TXT_FINANCIER_NAME": "Erode District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20138,
      "TXT_FINANCIER_NAME": "Faridkot District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20139,
      "TXT_FINANCIER_NAME": "Ferozepur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20140,
      "TXT_FINANCIER_NAME": "GANDHIBAG SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20141,
      "TXT_FINANCIER_NAME": "Ganganagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20142,
      "TXT_FINANCIER_NAME": "GUJRATH INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20143,
      "TXT_FINANCIER_NAME": "Gurgaon District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 20144,
      "TXT_FINANCIER_NAME": "HANUMANTHANAGAR CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20145,
      "TXT_FINANCIER_NAME": "SHREE KALBHAIRAVNATH GRAMIN BIGGAR SHETI SAHKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20146,
      "TXT_FINANCIER_NAME": "SHIVSAHYADRI SAHAKARI PAT PEDHI"
    },
    {
      "NUM_FINANCIER_CD": 20147,
      "TXT_FINANCIER_NAME": "BHAIRAVNATH GRAMIN BIGAR SHETI SAHAKARI PATHSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 20148,
      "TXT_FINANCIER_NAME": "ARMY GROUP INSURANCE FUND"
    },
    {
      "NUM_FINANCIER_CD": 20149,
      "TXT_FINANCIER_NAME": "MOHAN FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20150,
      "TXT_FINANCIER_NAME": "THE BAGHAT URBAN CO_OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20151,
      "TXT_FINANCIER_NAME": "THE BAILHONGAL MERCHANTS CO-OP., BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 20152,
      "TXT_FINANCIER_NAME": "THE BANASKANTHA DISTRICT CENTRAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20153,
      "TXT_FINANCIER_NAME": "THE BANASKANTHA MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20154,
      "TXT_FINANCIER_NAME": "THE BARDOLI NAGRIK SAHAKARI BANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 20155,
      "TXT_FINANCIER_NAME": "THE BARODA CENTRAL CO OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20156,
      "TXT_FINANCIER_NAME": "THE BAVLA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20157,
      "TXT_FINANCIER_NAME": "THE BECHRAJI NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20158,
      "TXT_FINANCIER_NAME": "The Beed District Centtral Co-Op.Bank Ltd.,Beed"
    },
    {
      "NUM_FINANCIER_CD": 20159,
      "TXT_FINANCIER_NAME": "THE BELGAUM DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20160,
      "TXT_FINANCIER_NAME": "THE BELGAUM DISTRICT REVENUR EMPLOYEES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20161,
      "TXT_FINANCIER_NAME": "THE BELGAUM INDUSTRIAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20162,
      "TXT_FINANCIER_NAME": "THE BELGAUM INDUSTRIAL COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20163,
      "TXT_FINANCIER_NAME": "THE BELGAUM PIONEER URBAN CO-OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20164,
      "TXT_FINANCIER_NAME": "THE BHABHAR VIBHAG NAGRIK SAHAKARI BANK LIT."
    },
    {
      "NUM_FINANCIER_CD": 20165,
      "TXT_FINANCIER_NAME": "MAULI KRUPA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20166,
      "TXT_FINANCIER_NAME": "EKTA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20167,
      "TXT_FINANCIER_NAME": "SINCERE AUTO STORES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20168,
      "TXT_FINANCIER_NAME": "INDIA AUTO SALES"
    },
    {
      "NUM_FINANCIER_CD": 20169,
      "TXT_FINANCIER_NAME": "ANSHU LEASING PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20170,
      "TXT_FINANCIER_NAME": "SAMARTH URBAN COOPERATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20171,
      "TXT_FINANCIER_NAME": "SHILPI LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20172,
      "TXT_FINANCIER_NAME": "ADARNIYA P D PATIL SAHEB SAH BANK"
    },
    {
      "NUM_FINANCIER_CD": 20173,
      "TXT_FINANCIER_NAME": "ARIHANT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20174,
      "TXT_FINANCIER_NAME": "DEEP HIRE PURCHASE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20175,
      "TXT_FINANCIER_NAME": "CAPITAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20176,
      "TXT_FINANCIER_NAME": "MIYASAHEB GRAMIN BIGAR SHETI SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20177,
      "TXT_FINANCIER_NAME": "THE SARDAR PATEL CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20178,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR DISTRICT PRIMARY TEACHERS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20179,
      "TXT_FINANCIER_NAME": "SHRI PRABHULINGESHWAR URBAN COOPERATIVE CREDIT SOCIETY NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20180,
      "TXT_FINANCIER_NAME": "SRISHTI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 20181,
      "TXT_FINANCIER_NAME": "SANT SOPANKAKA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20182,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20183,
      "TXT_FINANCIER_NAME": "UNITED INDIA INSURANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20184,
      "TXT_FINANCIER_NAME": "SHRI SANT SAVATA MAHARAJ NAG SAH PATSAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 20185,
      "TXT_FINANCIER_NAME": "SHRI BHUMI CREDIT CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20186,
      "TXT_FINANCIER_NAME": "PANDIT DINDAYAL BIGARSHETI GRAMIN SAHAKARI PATSANSTHA MARYADHI"
    },
    {
      "NUM_FINANCIER_CD": 20187,
      "TXT_FINANCIER_NAME": "THE BIJNOR URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20188,
      "TXT_FINANCIER_NAME": "B UMESHCHAND NAHAR SON AUTOMOB"
    },
    {
      "NUM_FINANCIER_CD": 20189,
      "TXT_FINANCIER_NAME": "VARDHAMAN MAHILA COOPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20190,
      "TXT_FINANCIER_NAME": "DARYASAGAR SAHAKARI PATHPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20191,
      "TXT_FINANCIER_NAME": "POWER SYSTEM OPERATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20192,
      "TXT_FINANCIER_NAME": "VOITH TURBO PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20193,
      "TXT_FINANCIER_NAME": "MOHAN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 20194,
      "TXT_FINANCIER_NAME": "SUMERPUR MERCANTILE URBAN COOPPARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20195,
      "TXT_FINANCIER_NAME": "RAJHANS MOTOR INVESTMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 20196,
      "TXT_FINANCIER_NAME": "TEVA API INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20197,
      "TXT_FINANCIER_NAME": "SHRI JAGADGURU MOUNESHWAR PATTIN SAHAKARI SANGHA NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20198,
      "TXT_FINANCIER_NAME": "BHEDASGAON NAGARI SAH PAT SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20199,
      "TXT_FINANCIER_NAME": "BOKARO POWER SUPPLY CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20200,
      "TXT_FINANCIER_NAME": "THE ALLEPPEY URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20201,
      "TXT_FINANCIER_NAME": "VYTHIRI PRIMARY CO OP AGRICULTURAL AND RURAL DEVP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20202,
      "TXT_FINANCIER_NAME": "BALAJI MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 20203,
      "TXT_FINANCIER_NAME": "MARUTI GRAMIN BIGARSHETI SAHAKARI PAATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20204,
      "TXT_FINANCIER_NAME": "THE KENDRAPARA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20205,
      "TXT_FINANCIER_NAME": "ADHINATH ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20206,
      "TXT_FINANCIER_NAME": "SHRI DHOKESHWAR G B SHETI SAH PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20207,
      "TXT_FINANCIER_NAME": "THE JAWAHAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20208,
      "TXT_FINANCIER_NAME": "KRISHNA URBAN CO OPRATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20209,
      "TXT_FINANCIER_NAME": "YASHWANT NAGARI SAHAKARI PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 20210,
      "TXT_FINANCIER_NAME": "PRATAP SHREE FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20211,
      "TXT_FINANCIER_NAME": "SHREEJI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20212,
      "TXT_FINANCIER_NAME": "JIJAMATA MAHILA NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20213,
      "TXT_FINANCIER_NAME": "SHIVAJI NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20214,
      "TXT_FINANCIER_NAME": "SHREE DURGA FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20215,
      "TXT_FINANCIER_NAME": "APPASAHEB BIRNALE SAHAKARI BANK LTD DUDHGAON"
    },
    {
      "NUM_FINANCIER_CD": 20216,
      "TXT_FINANCIER_NAME": "HIMGIRI HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20217,
      "TXT_FINANCIER_NAME": "AMIT AUTO FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20218,
      "TXT_FINANCIER_NAME": "SAMBHAVNATH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20219,
      "TXT_FINANCIER_NAME": "RAJ SINGH AND COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20220,
      "TXT_FINANCIER_NAME": "SRI KESHAVA PATHINA SAHAKARA SANGHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20221,
      "TXT_FINANCIER_NAME": "BINDIYA CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 20222,
      "TXT_FINANCIER_NAME": "DNR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18682,
      "TXT_FINANCIER_NAME": "JIJAU COMMERCIAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18683,
      "TXT_FINANCIER_NAME": "SHRIRAM CITY UNION FINANCE LTD BR PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18684,
      "TXT_FINANCIER_NAME": "VIJAY FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18685,
      "TXT_FINANCIER_NAME": "Khetan Credit Corporation"
    },
    {
      "NUM_FINANCIER_CD": 18686,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 18687,
      "TXT_FINANCIER_NAME": "janatha seva cooperative bank limited"
    },
    {
      "NUM_FINANCIER_CD": 18688,
      "TXT_FINANCIER_NAME": "BRAHMAVAR AGRICULTURAL SERVICE COOP BANK LTD BRAHMAVAR"
    },
    {
      "NUM_FINANCIER_CD": 18689,
      "TXT_FINANCIER_NAME": "MARUTI SUZUKI INDIA LTD GURGAON"
    },
    {
      "NUM_FINANCIER_CD": 18690,
      "TXT_FINANCIER_NAME": "VARUN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18691,
      "TXT_FINANCIER_NAME": "GOKUL URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18692,
      "TXT_FINANCIER_NAME": "SHRINATH GRAMIN BIGARSHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18693,
      "TXT_FINANCIER_NAME": "The Udaipur Mahila Urban Cooperative Bank Limited"
    },
    {
      "NUM_FINANCIER_CD": 18694,
      "TXT_FINANCIER_NAME": "DAHOD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18695,
      "TXT_FINANCIER_NAME": "DEENDAYAL NAGARI SAHAKARI PATHSAUNSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18696,
      "TXT_FINANCIER_NAME": "SHAKUMBRI MOTOR AND GENERAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18697,
      "TXT_FINANCIER_NAME": "WIPRO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18698,
      "TXT_FINANCIER_NAME": "Pebco Finance"
    },
    {
      "NUM_FINANCIER_CD": 18699,
      "TXT_FINANCIER_NAME": "DOMBIVALI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18700,
      "TXT_FINANCIER_NAME": "CIBA SPECIALITY CHEMICALS (I) LTD"
    },
    {
      "NUM_FINANCIER_CD": 18701,
      "TXT_FINANCIER_NAME": "OSMANABAD JANATA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18702,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA STATE CO-OP.BANK LTD.MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 18703,
      "TXT_FINANCIER_NAME": "PAWANA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18704,
      "TXT_FINANCIER_NAME": "THE CAMBAY HINDU MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18705,
      "TXT_FINANCIER_NAME": "BIJNOR URBAN CO-OPERATIVE BANL"
    },
    {
      "NUM_FINANCIER_CD": 18706,
      "TXT_FINANCIER_NAME": "CHAMOLI ZILA SAHKARI BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 18707,
      "TXT_FINANCIER_NAME": "HARYANA STATE CO-OPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18708,
      "TXT_FINANCIER_NAME": "THE LUDHIANA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18709,
      "TXT_FINANCIER_NAME": "THE BORAL UNION CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18710,
      "TXT_FINANCIER_NAME": "THE DISTRICT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18711,
      "TXT_FINANCIER_NAME": "CENTURIAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18712,
      "TXT_FINANCIER_NAME": "ING VYSYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 18713,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18714,
      "TXT_FINANCIER_NAME": "Sardar Bhiladwalla Pardi Peoples Co-Op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18715,
      "TXT_FINANCIER_NAME": "THE MULGUND URBAN SOUHARDA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18716,
      "TXT_FINANCIER_NAME": "THE ZOROASTRIAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18717,
      "TXT_FINANCIER_NAME": "DENA JI BANK"
    },
    {
      "NUM_FINANCIER_CD": 18718,
      "TXT_FINANCIER_NAME": "THE HALOL MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18719,
      "TXT_FINANCIER_NAME": "SHREE SIDDHIVINAYAK NAGARI SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18720,
      "TXT_FINANCIER_NAME": "NISHIGANDHA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18721,
      "TXT_FINANCIER_NAME": "ANAND MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18722,
      "TXT_FINANCIER_NAME": "THE JAMMU & KASHMIR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18723,
      "TXT_FINANCIER_NAME": "BANK OF AMERICAN"
    },
    {
      "NUM_FINANCIER_CD": 18724,
      "TXT_FINANCIER_NAME": "DOON VALLEY URBAN CO.-OP. (SC/ST)BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18725,
      "TXT_FINANCIER_NAME": "BHARTIYA STATE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18726,
      "TXT_FINANCIER_NAME": "SARASWAT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18727,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIME LIMTED"
    },
    {
      "NUM_FINANCIER_CD": 18728,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM DBS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18729,
      "TXT_FINANCIER_NAME": "GUJARAT MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18730,
      "TXT_FINANCIER_NAME": "DAPOLI URBAN CO-OP BANK LTD.,DAPOLI"
    },
    {
      "NUM_FINANCIER_CD": 18731,
      "TXT_FINANCIER_NAME": "KRUSHISEVA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18732,
      "TXT_FINANCIER_NAME": "SHREE BASAVESHWARA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 18733,
      "TXT_FINANCIER_NAME": "NORTHERN RAILWAY PRIMARY CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18734,
      "TXT_FINANCIER_NAME": "SHREEJI BHATIA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18735,
      "TXT_FINANCIER_NAME": "SHREE BOTAD MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18736,
      "TXT_FINANCIER_NAME": "THE SARASWAT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18737,
      "TXT_FINANCIER_NAME": "SYNDICATE RYATHARA SEVA SAHAKARI BANK N."
    },
    {
      "NUM_FINANCIER_CD": 18738,
      "TXT_FINANCIER_NAME": "THE JAMBUSAR PEOPLES CO. OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18739,
      "TXT_FINANCIER_NAME": "THE KODINAR TALUKA CO-OPERATIVE BANKING UNION LTD."
    },
    {
      "NUM_FINANCIER_CD": 18740,
      "TXT_FINANCIER_NAME": "THE INDUSTRIAL DEVELOPMENT BANK OF INDIA LTD."
    },
    {
      "NUM_FINANCIER_CD": 18741,
      "TXT_FINANCIER_NAME": "THE A.P. VARDHAMAN (MAHILA) CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18742,
      "TXT_FINANCIER_NAME": "STATE BANK OF BIKANER AND JAIPUR."
    },
    {
      "NUM_FINANCIER_CD": 18743,
      "TXT_FINANCIER_NAME": "JANSEVA NAGARI SAHAKARI BANK MYDT.BHOOM."
    },
    {
      "NUM_FINANCIER_CD": 18744,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT LIMTED"
    },
    {
      "NUM_FINANCIER_CD": 18745,
      "TXT_FINANCIER_NAME": "CHAMBAL GWALIOR KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18746,
      "TXT_FINANCIER_NAME": "THE SUTEX COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18747,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18748,
      "TXT_FINANCIER_NAME": "SHRI RAM TRANSPORT FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18749,
      "TXT_FINANCIER_NAME": "JANASEVA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18750,
      "TXT_FINANCIER_NAME": "SANMITRA URBAN COOP BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 18751,
      "TXT_FINANCIER_NAME": "THE GODHRA URBAN COOP"
    },
    {
      "NUM_FINANCIER_CD": 18752,
      "TXT_FINANCIER_NAME": "JANATA URBAN CO OP BANK LTD WAI"
    },
    {
      "NUM_FINANCIER_CD": 18753,
      "TXT_FINANCIER_NAME": "MADURAI DISTRICT CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18754,
      "TXT_FINANCIER_NAME": "MAHESH URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18755,
      "TXT_FINANCIER_NAME": "THE ORISSA STATE COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18756,
      "TXT_FINANCIER_NAME": "B H E EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18757,
      "TXT_FINANCIER_NAME": "THE BARDEZ URBAN COOPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18758,
      "TXT_FINANCIER_NAME": "FRATERNAL CREDITFINSER LTD"
    },
    {
      "NUM_FINANCIER_CD": 18759,
      "TXT_FINANCIER_NAME": "THE BARDEZ URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18760,
      "TXT_FINANCIER_NAME": "BARDEZ URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18761,
      "TXT_FINANCIER_NAME": "THE BARDEZ URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18762,
      "TXT_FINANCIER_NAME": "BHARAT PETROLEUM CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 18763,
      "TXT_FINANCIER_NAME": "Navanagara Urban Co-operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 18764,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD BR MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 18765,
      "TXT_FINANCIER_NAME": "PUNJAB GRAMIN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 18766,
      "TXT_FINANCIER_NAME": "AJANTA INSTALMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 18767,
      "TXT_FINANCIER_NAME": "SHRI VYANKATESH CO OP BANK LTD MALEGAON"
    },
    {
      "NUM_FINANCIER_CD": 18768,
      "TXT_FINANCIER_NAME": "GMAC FINANCE SERVICES INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18769,
      "TXT_FINANCIER_NAME": "THE AMRAVATI DISTRICT CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18770,
      "TXT_FINANCIER_NAME": "SIDDAGANGA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18771,
      "TXT_FINANCIER_NAME": "GODFREY PHILLIPS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18772,
      "TXT_FINANCIER_NAME": "THE SURAT DISTRICT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18773,
      "TXT_FINANCIER_NAME": "OZAR NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18774,
      "TXT_FINANCIER_NAME": "INDIAN RENEWABLE ENERGY DEVELOPMENT AGENCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18775,
      "TXT_FINANCIER_NAME": "VIJAY BANK"
    },
    {
      "NUM_FINANCIER_CD": 18776,
      "TXT_FINANCIER_NAME": "NAVAL GROUP INSURANCE"
    },
    {
      "NUM_FINANCIER_CD": 18777,
      "TXT_FINANCIER_NAME": "SHIVAM AUTO FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 18778,
      "TXT_FINANCIER_NAME": "SECURITIES AND EXCHANGE BOARD OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18779,
      "TXT_FINANCIER_NAME": "PARAGON FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20223,
      "TXT_FINANCIER_NAME": "MULA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20224,
      "TXT_FINANCIER_NAME": "MAHARASHTRA NAGARI SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20225,
      "TXT_FINANCIER_NAME": "NUMALIGARH REFINERY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20226,
      "TXT_FINANCIER_NAME": "JUHIE INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20227,
      "TXT_FINANCIER_NAME": "PHF LEASING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20228,
      "TXT_FINANCIER_NAME": "HARINDER INVESTMENTS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20229,
      "TXT_FINANCIER_NAME": "TATTISAR GROUP SEVA SAHAKARI SANGH LTD"
    },
    {
      "NUM_FINANCIER_CD": 20230,
      "TXT_FINANCIER_NAME": "SBI FUNDS MANAGEMENT PRIVATE LIMIED"
    },
    {
      "NUM_FINANCIER_CD": 20231,
      "TXT_FINANCIER_NAME": "KESRIYA FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 20232,
      "TXT_FINANCIER_NAME": "VEERACHARYA BABASAHEB KUCHANURE GRAMIN BIGER SHETI SAH PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20233,
      "TXT_FINANCIER_NAME": "DHAN LAXMI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20234,
      "TXT_FINANCIER_NAME": "SRI VENKATA RAMANA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20235,
      "TXT_FINANCIER_NAME": "NARMADA MALWA KSHETRIYA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 20236,
      "TXT_FINANCIER_NAME": "FINANCE UDYOG"
    },
    {
      "NUM_FINANCIER_CD": 20237,
      "TXT_FINANCIER_NAME": "THE AHMEDNAGAR JAIN OSWAL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20238,
      "TXT_FINANCIER_NAME": "SIR M VISHWESHWAR VIVIDH UDDESHAGAL SAUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20239,
      "TXT_FINANCIER_NAME": "SHRI MAHALAXMI PATTAN SAHAKARA BANK"
    },
    {
      "NUM_FINANCIER_CD": 20240,
      "TXT_FINANCIER_NAME": "NAVSANDESH SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20241,
      "TXT_FINANCIER_NAME": "SHRI BHAGYALAXMI PATTINA SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20242,
      "TXT_FINANCIER_NAME": "THE TARAPUR MAHILA UTKARSH CREDIT AND CON CO OP SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20243,
      "TXT_FINANCIER_NAME": "ASHIRWAD CO OP CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20244,
      "TXT_FINANCIER_NAME": "THE LADOL MULTIPURPOSE CO OP SOCI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20245,
      "TXT_FINANCIER_NAME": "ASHWANI KUMAR URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20246,
      "TXT_FINANCIER_NAME": "HAVELI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20247,
      "TXT_FINANCIER_NAME": "THE FATEHGARH SAHIB CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20248,
      "TXT_FINANCIER_NAME": "JILA SAHAKARI KRASHI AND GRAMIN VIKAS BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20249,
      "TXT_FINANCIER_NAME": "KHUSBU AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20250,
      "TXT_FINANCIER_NAME": "SHRI LAXMI NARAYAN NAGARI SAHAKARI PATASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20251,
      "TXT_FINANCIER_NAME": "OM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20252,
      "TXT_FINANCIER_NAME": "PRAKASH FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20253,
      "TXT_FINANCIER_NAME": "PRATHAMIK SHIKSHAK SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20254,
      "TXT_FINANCIER_NAME": "THE HOSDURG SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20255,
      "TXT_FINANCIER_NAME": "S D ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20256,
      "TXT_FINANCIER_NAME": "SATARA DISTRICT CENTRAL SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 20257,
      "TXT_FINANCIER_NAME": "JAISONS INVESTMENT AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20258,
      "TXT_FINANCIER_NAME": "LESSOR RELIANCE CAPITAL LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20259,
      "TXT_FINANCIER_NAME": "LIFE INSURANCE COMPANY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 20260,
      "TXT_FINANCIER_NAME": "PRADEEP KUMAR DUNDARWAL FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 20261,
      "TXT_FINANCIER_NAME": "LESSOR INFRASTRUCTURE LEASING AND FIN SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 20262,
      "TXT_FINANCIER_NAME": "JAIN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 20263,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM INVESTMENTAND FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20264,
      "TXT_FINANCIER_NAME": "THE ARKONAM CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20265,
      "TXT_FINANCIER_NAME": "MEENACHIL EAST URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20266,
      "TXT_FINANCIER_NAME": "NATIONAL HYDROELCTRIC POWER CORP"
    },
    {
      "NUM_FINANCIER_CD": 20267,
      "TXT_FINANCIER_NAME": "SHRI VIJAY MAHANTESH CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20268,
      "TXT_FINANCIER_NAME": "DEEPAK FINANCE AND CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 20269,
      "TXT_FINANCIER_NAME": "MEENACHIL EAST URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20270,
      "TXT_FINANCIER_NAME": "BALAGERIA CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20271,
      "TXT_FINANCIER_NAME": "SHROFF CAPITAL AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20272,
      "TXT_FINANCIER_NAME": "KERALA STATE CO OPERATIVE HOUSING FEDERATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20273,
      "TXT_FINANCIER_NAME": "SIDDHI VINAYAK SOUHARDH PATTINA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20274,
      "TXT_FINANCIER_NAME": "DATTA DIGAMBER CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20275,
      "TXT_FINANCIER_NAME": "ALAPPUZHA DISTRIC POLICE EMPLOYEES SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20276,
      "TXT_FINANCIER_NAME": "SATYAM CREDIT COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20277,
      "TXT_FINANCIER_NAME": "MANGOTRA MOTOR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20278,
      "TXT_FINANCIER_NAME": "SHRI BHAUSAHEB THORAT SAH BANK LMD"
    },
    {
      "NUM_FINANCIER_CD": 20279,
      "TXT_FINANCIER_NAME": "MSK MOTOR CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 20280,
      "TXT_FINANCIER_NAME": "SHRI CHATRAPATI SHIVAJI MAHARAJ SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20281,
      "TXT_FINANCIER_NAME": "SHRI SAINATH NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20282,
      "TXT_FINANCIER_NAME": "RAGHOJI FINANCE AND LEASING GULBARGA"
    },
    {
      "NUM_FINANCIER_CD": 20283,
      "TXT_FINANCIER_NAME": "THE TRIVANDRUM CO OPERATIVE AGRICUL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20284,
      "TXT_FINANCIER_NAME": "K B FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20285,
      "TXT_FINANCIER_NAME": "AMBICA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20286,
      "TXT_FINANCIER_NAME": "JAI HANUMAN AUTO FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 20287,
      "TXT_FINANCIER_NAME": "THE KHEDA DISTRICT CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20288,
      "TXT_FINANCIER_NAME": "MANJUSHREE INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20289,
      "TXT_FINANCIER_NAME": "SARAS URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20290,
      "TXT_FINANCIER_NAME": "RAJIV GANDHI GRAMIN BIG SHETI SAH PAT SAN"
    },
    {
      "NUM_FINANCIER_CD": 20291,
      "TXT_FINANCIER_NAME": "THE HIRIYUR URBAN CO OP BQANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20292,
      "TXT_FINANCIER_NAME": "THE SANGHAMITRA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20293,
      "TXT_FINANCIER_NAME": "ANANT KRUPA GRAMIN BIGER SHETI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20294,
      "TXT_FINANCIER_NAME": "WESTERN INDIA TRANSPORT FINANCE CO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20295,
      "TXT_FINANCIER_NAME": "SHRI SHYAM AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 20296,
      "TXT_FINANCIER_NAME": "HINDUJA LEYLAND FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20297,
      "TXT_FINANCIER_NAME": "UTTAM CHAND ARORA LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20298,
      "TXT_FINANCIER_NAME": "GRASIM INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20299,
      "TXT_FINANCIER_NAME": "COMMISSIONER OF HORTICULTURE GOVT OF AP"
    },
    {
      "NUM_FINANCIER_CD": 20300,
      "TXT_FINANCIER_NAME": "ST JOSEPH CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20301,
      "TXT_FINANCIER_NAME": "ASIJA SECURITIES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20302,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR URBAN CREDIT SOUHARD SAHAKARI NYT"
    },
    {
      "NUM_FINANCIER_CD": 20303,
      "TXT_FINANCIER_NAME": "YASHIK FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20304,
      "TXT_FINANCIER_NAME": "THE KOPARGAON PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20305,
      "TXT_FINANCIER_NAME": "SHRI MALLIKARJUN CREDIT SOUHARD SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20306,
      "TXT_FINANCIER_NAME": "MOHOL NAGARI SAHAKARI PATH PURAWATHA SANSTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 20307,
      "TXT_FINANCIER_NAME": "THE KURUKSHETRA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20308,
      "TXT_FINANCIER_NAME": "PNB HOUSING FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20309,
      "TXT_FINANCIER_NAME": "GHANSHYAM LEASE FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20310,
      "TXT_FINANCIER_NAME": "SHRI SIDDHALINGESWAR MULTIPURPOSE SOUHARD SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20311,
      "TXT_FINANCIER_NAME": "ADARSH KRISHI SAHAKARI KHAREDI VIKRI PRAKRIYA SAUNSTHA MARYADIT PARVE BR"
    },
    {
      "NUM_FINANCIER_CD": 20312,
      "TXT_FINANCIER_NAME": "SRI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20313,
      "TXT_FINANCIER_NAME": "CHENNAI PETROLEUM CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20314,
      "TXT_FINANCIER_NAME": "THE SUVARNA CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 20315,
      "TXT_FINANCIER_NAME": "THE RAIPUR URBAN MERCANTILE CO OPRETIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20316,
      "TXT_FINANCIER_NAME": "SRI VENKATESWARA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20317,
      "TXT_FINANCIER_NAME": "THE PRESIDENT OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18781,
      "TXT_FINANCIER_NAME": "COMPEER LEASING AND INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18782,
      "TXT_FINANCIER_NAME": "SHREE DHAMAN VIVIDH KARYAKARI SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 18783,
      "TXT_FINANCIER_NAME": "MANINAGAR CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18784,
      "TXT_FINANCIER_NAME": "SHRI KADASIDDESHWAR PATTAN SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 18785,
      "TXT_FINANCIER_NAME": "KUTCHI OSWAL MUTULLAY AIDED CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18786,
      "TXT_FINANCIER_NAME": "SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18787,
      "TXT_FINANCIER_NAME": "PRAVEEN CAPITAL PVT LD"
    },
    {
      "NUM_FINANCIER_CD": 18788,
      "TXT_FINANCIER_NAME": "PRAVEEN CAPITAL PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18789,
      "TXT_FINANCIER_NAME": "PUNE DISTRICT CENTRAL CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18790,
      "TXT_FINANCIER_NAME": "GMAC FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 18791,
      "TXT_FINANCIER_NAME": "MANICKPUR URBAN CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18792,
      "TXT_FINANCIER_NAME": "MALENADU CREDIT COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18793,
      "TXT_FINANCIER_NAME": "THE GOA STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18794,
      "TXT_FINANCIER_NAME": "THE JANATA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18795,
      "TXT_FINANCIER_NAME": "KUMBHI KASARI SAHAKARI BANK LTD KUDITRE"
    },
    {
      "NUM_FINANCIER_CD": 18796,
      "TXT_FINANCIER_NAME": "THE BANASKANTHA DIST. CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18797,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DT. CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18798,
      "TXT_FINANCIER_NAME": "SARASWAT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18799,
      "TXT_FINANCIER_NAME": "OCHANTHURUTH SERVICE CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18800,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD., SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 18801,
      "TXT_FINANCIER_NAME": "THE IDAR NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18802,
      "TXT_FINANCIER_NAME": "THE IDAR NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18803,
      "TXT_FINANCIER_NAME": "KARNATAKA VIKAS GRAMMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18804,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18805,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD.,SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 18806,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18807,
      "TXT_FINANCIER_NAME": "THE BULDANA DISTRICT CENTRAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18808,
      "TXT_FINANCIER_NAME": "THE HONGKONG AND SHANGAI BANKING CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18809,
      "TXT_FINANCIER_NAME": "NISHANT SAHAKARI GRAMIN PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18810,
      "TXT_FINANCIER_NAME": "SWAMI SAMARTH SAHAKARI BANK LTD.,AKKALKOT."
    },
    {
      "NUM_FINANCIER_CD": 18811,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIDE AUTO FINANCIAL SERVICES PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 18812,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD., HAZRATGANJ"
    },
    {
      "NUM_FINANCIER_CD": 18813,
      "TXT_FINANCIER_NAME": "JAYLAKSHMI CREDIT COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18814,
      "TXT_FINANCIER_NAME": "INTERNATIONAL TRACTORS LTD"
    },
    {
      "NUM_FINANCIER_CD": 18815,
      "TXT_FINANCIER_NAME": "CENTRAL GODAVARI KRUSHAK SAHAKARI SANSHTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18816,
      "TXT_FINANCIER_NAME": "THE KALYAN JANATA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18817,
      "TXT_FINANCIER_NAME": "BHAVANI URBAN CO-OPERATIVE BANK LTD GEORAI"
    },
    {
      "NUM_FINANCIER_CD": 18818,
      "TXT_FINANCIER_NAME": "TAMILNADU MERCANDILE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18819,
      "TXT_FINANCIER_NAME": "THE DAHOD URBAN CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18820,
      "TXT_FINANCIER_NAME": "MALVIYA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18821,
      "TXT_FINANCIER_NAME": "ALMORA URBAN CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18822,
      "TXT_FINANCIER_NAME": "DOON VALLEY URBAN CO-OP. (SC/ST) BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18823,
      "TXT_FINANCIER_NAME": "THE VISAKHAPATNAM COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18824,
      "TXT_FINANCIER_NAME": "KARNATAKA KSHATRIYA MARATHA PARISHATH CREDIT CO-OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18825,
      "TXT_FINANCIER_NAME": "BULDHANA URBAN CO-OP BANK LTD.BULDHNA"
    },
    {
      "NUM_FINANCIER_CD": 18826,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 18827,
      "TXT_FINANCIER_NAME": "I C I C I BANK LIMITAD."
    },
    {
      "NUM_FINANCIER_CD": 18828,
      "TXT_FINANCIER_NAME": "THE ANAND MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18829,
      "TXT_FINANCIER_NAME": "Ashok leyland finance (a division of Indus Ind bank)"
    },
    {
      "NUM_FINANCIER_CD": 18830,
      "TXT_FINANCIER_NAME": "THE PANDHARPUR URBAN CO-OP BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18831,
      "TXT_FINANCIER_NAME": "THE JHALOD URBAN CO. OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18832,
      "TXT_FINANCIER_NAME": "INDIAN OVERSEAS BANK,"
    },
    {
      "NUM_FINANCIER_CD": 18833,
      "TXT_FINANCIER_NAME": "AIRPORTS AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18834,
      "TXT_FINANCIER_NAME": "NANDANI SAHAKARI BANK LTD NANDANI"
    },
    {
      "NUM_FINANCIER_CD": 18835,
      "TXT_FINANCIER_NAME": "THE SANGAMNER MERCHANTS CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18836,
      "TXT_FINANCIER_NAME": "ZILA SAHKARI BANK LTD. GARHWAL"
    },
    {
      "NUM_FINANCIER_CD": 18837,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDORE,"
    },
    {
      "NUM_FINANCIER_CD": 18838,
      "TXT_FINANCIER_NAME": "NAINITAL ALMORA KHETRA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18839,
      "TXT_FINANCIER_NAME": "THE KAIRA DISTRICT CENTRAL CO OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18840,
      "TXT_FINANCIER_NAME": "Shri Laxmi Sahakari Bank Ltd. Mhaisal"
    },
    {
      "NUM_FINANCIER_CD": 18841,
      "TXT_FINANCIER_NAME": "SRI GAJANANA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18842,
      "TXT_FINANCIER_NAME": "SHRI SHIVABODHARANG URBAN CREDIT COOP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18843,
      "TXT_FINANCIER_NAME": "SHREE SADGURU CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18844,
      "TXT_FINANCIER_NAME": "DISTT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18845,
      "TXT_FINANCIER_NAME": "RAJAPURA SARASWATHA CREDIT CO OP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18846,
      "TXT_FINANCIER_NAME": "P M FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18847,
      "TXT_FINANCIER_NAME": "TITAN INDUSTRIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18848,
      "TXT_FINANCIER_NAME": "COLGATE PALMOLIVE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18849,
      "TXT_FINANCIER_NAME": "Shimoga Arecanut Mandi Merchant Co op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 18850,
      "TXT_FINANCIER_NAME": "RASHTRIYA ISPAT NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 18851,
      "TXT_FINANCIER_NAME": "VIJYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 18852,
      "TXT_FINANCIER_NAME": "THE UDAIPUR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18853,
      "TXT_FINANCIER_NAME": "CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18854,
      "TXT_FINANCIER_NAME": "JAMMU CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18855,
      "TXT_FINANCIER_NAME": "VISAKHAPATNAM STEELPLANT"
    },
    {
      "NUM_FINANCIER_CD": 18856,
      "TXT_FINANCIER_NAME": "ORIX AUTO INFRASTRUCTURE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18857,
      "TXT_FINANCIER_NAME": "AHMEDNAGAR MERCHANT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18858,
      "TXT_FINANCIER_NAME": "GHIYA MAMODIYA FIN AND INVESTMENT CONS P LTD"
    },
    {
      "NUM_FINANCIER_CD": 18859,
      "TXT_FINANCIER_NAME": "icic bank"
    },
    {
      "NUM_FINANCIER_CD": 18860,
      "TXT_FINANCIER_NAME": "Kanhoor pathar path satha"
    },
    {
      "NUM_FINANCIER_CD": 18861,
      "TXT_FINANCIER_NAME": "Shubham nagari sahkari path santha"
    },
    {
      "NUM_FINANCIER_CD": 18862,
      "TXT_FINANCIER_NAME": "MANIPUR STATE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18863,
      "TXT_FINANCIER_NAME": "REGIONAL EXECUTIVE DIRECTOR CHENNAI AAI"
    },
    {
      "NUM_FINANCIER_CD": 18864,
      "TXT_FINANCIER_NAME": "KERALA STATE FINANCIAL ETERPRISES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18865,
      "TXT_FINANCIER_NAME": "SHREE KALIKAMATA GRAMIN BIGAR SHETI SAHAKARI PATHSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18866,
      "TXT_FINANCIER_NAME": "KARNATAKA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18867,
      "TXT_FINANCIER_NAME": "THE YAMUNA NAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18868,
      "TXT_FINANCIER_NAME": "SHREE MADHI SURALI VIBHAG NAGRIK SAHAKARI DHIRAN MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 18869,
      "TXT_FINANCIER_NAME": "THE COSMOS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18870,
      "TXT_FINANCIER_NAME": "NAGALAND STATE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18871,
      "TXT_FINANCIER_NAME": "GMAC TH CENTURY FINANCE CORPPN LTD"
    },
    {
      "NUM_FINANCIER_CD": 18872,
      "TXT_FINANCIER_NAME": "THE NABADWIP CO OPERATIVE CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18873,
      "TXT_FINANCIER_NAME": "G MAC FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 18874,
      "TXT_FINANCIER_NAME": "REGIONAL EXECUTIVE DIRECTOR AAI"
    },
    {
      "NUM_FINANCIER_CD": 18875,
      "TXT_FINANCIER_NAME": "CAN FIN HOMES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20318,
      "TXT_FINANCIER_NAME": "POOJYA SHRI MAHANTAPPA CO OP SOCITY NIGAM"
    },
    {
      "NUM_FINANCIER_CD": 20319,
      "TXT_FINANCIER_NAME": "ANJANI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20320,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI NAGARI SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20321,
      "TXT_FINANCIER_NAME": "HINDUJA LEYLAND FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20322,
      "TXT_FINANCIER_NAME": "MAHALAXMI GRAMIN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20323,
      "TXT_FINANCIER_NAME": "SHRI BHAIRAVNATH MULTISTATE CO OP CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20324,
      "TXT_FINANCIER_NAME": "SETHI LEASING AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20325,
      "TXT_FINANCIER_NAME": "CHAITANYA MAHILA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20326,
      "TXT_FINANCIER_NAME": "SHRI VEER PULIKESHI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20327,
      "TXT_FINANCIER_NAME": "ANOOPCHAND JAIN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20328,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20329,
      "TXT_FINANCIER_NAME": "HDB FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20330,
      "TXT_FINANCIER_NAME": "SINDHUDURG DISTRICT VYSYA SAMAJ SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20331,
      "TXT_FINANCIER_NAME": "SINDHUDURG DISTRICT VAISHYA SAMAJ SAH PAT MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20332,
      "TXT_FINANCIER_NAME": "MAJHA INVESTMENTS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20333,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20334,
      "TXT_FINANCIER_NAME": "SHRI CHATRAPATI RAJARSHI SAHU URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20335,
      "TXT_FINANCIER_NAME": "CHAKAN GRAMIN BIGAR SHETI SAHAKARI PATHSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20336,
      "TXT_FINANCIER_NAME": "SHRI RUKMINI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20337,
      "TXT_FINANCIER_NAME": "SHRI SAINATH GRAMIN BIGER SETHI PAT SAHKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20338,
      "TXT_FINANCIER_NAME": "THE HP STATE CO OP AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20339,
      "TXT_FINANCIER_NAME": "HARSH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20340,
      "TXT_FINANCIER_NAME": "MAMASAHEB KHANDAGE NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20341,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURAL CO OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20342,
      "TXT_FINANCIER_NAME": "ADARSH CREDIT SOUHARD SAHAKARI LTD SOUNDATTI"
    },
    {
      "NUM_FINANCIER_CD": 20343,
      "TXT_FINANCIER_NAME": "SATLUJ GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 20344,
      "TXT_FINANCIER_NAME": "GUJARAT URJA VIKAS NIGAM LTD"
    },
    {
      "NUM_FINANCIER_CD": 20345,
      "TXT_FINANCIER_NAME": "SAMARTH SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20346,
      "TXT_FINANCIER_NAME": "VANCHAL FINVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20347,
      "TXT_FINANCIER_NAME": "GIRIRAJ GRAMIN BIGARE SAHAKARI PATH SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20348,
      "TXT_FINANCIER_NAME": "DNYANDEEP CO OP CREDIT SOCIETY LTD MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 20349,
      "TXT_FINANCIER_NAME": "VAISHYA NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20350,
      "TXT_FINANCIER_NAME": "YASHWANTRAO CHAVAN WARANA MAHAVIDHYALAY SEVAK PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20351,
      "TXT_FINANCIER_NAME": "VASAVI VIVIDODDESHA SAHAKARA SANGA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20352,
      "TXT_FINANCIER_NAME": "SHREE GURUDATTA GRAMIN BIGARSHETI SAH PATH MAR"
    },
    {
      "NUM_FINANCIER_CD": 20353,
      "TXT_FINANCIER_NAME": "R B ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20354,
      "TXT_FINANCIER_NAME": "KUMARPAL AND CO"
    },
    {
      "NUM_FINANCIER_CD": 20355,
      "TXT_FINANCIER_NAME": "ECO STAR SECURITIES AND CREDITS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20356,
      "TXT_FINANCIER_NAME": "PUKHRAJ DUGHAR"
    },
    {
      "NUM_FINANCIER_CD": 20357,
      "TXT_FINANCIER_NAME": "SAMRUDDA SOUHARDA CREDIT CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 20358,
      "TXT_FINANCIER_NAME": "MADAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20359,
      "TXT_FINANCIER_NAME": "THDC INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20360,
      "TXT_FINANCIER_NAME": "THE UDAIPUR MAHILA SAMRIDDHI URBAN CO OPERATIV BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20361,
      "TXT_FINANCIER_NAME": "POTHANICAD FARMERS COP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20362,
      "TXT_FINANCIER_NAME": "MAHABHAIRAB CO OPERATIVE URBAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20363,
      "TXT_FINANCIER_NAME": "SHRI ARIHANT MERCHANT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20364,
      "TXT_FINANCIER_NAME": "NAVAPURA URBAN CO OP CREDIT AND CONSUMER SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20365,
      "TXT_FINANCIER_NAME": "SAMARTH URBAN COOPRATIVE CREDIT SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20366,
      "TXT_FINANCIER_NAME": "RINI FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 20367,
      "TXT_FINANCIER_NAME": "EXCELLENT AGRO PRODUCTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20368,
      "TXT_FINANCIER_NAME": "SANCHETI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20369,
      "TXT_FINANCIER_NAME": "SATARA CHEMIST NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20370,
      "TXT_FINANCIER_NAME": "MAHESH URBAN CO OP CREDIT SCTY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20371,
      "TXT_FINANCIER_NAME": "SRI KRISHNA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20372,
      "TXT_FINANCIER_NAME": "KASHMIR MERCANTILE COOPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20373,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI PATTAN SAHAKAR BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20374,
      "TXT_FINANCIER_NAME": "VISHWAKALYAN SAHAKARA BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20375,
      "TXT_FINANCIER_NAME": "SRI BALAMURUGAN AND CO BANKERS"
    },
    {
      "NUM_FINANCIER_CD": 20376,
      "TXT_FINANCIER_NAME": "THE NATIONAL CREDIT CO SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20377,
      "TXT_FINANCIER_NAME": "UP SAHAKARI GRAM VIKAS BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20378,
      "TXT_FINANCIER_NAME": "BALASORE BHADRAK CREDIT CO OPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 20379,
      "TXT_FINANCIER_NAME": "SINGHMAN FINEX PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20380,
      "TXT_FINANCIER_NAME": "PREMIER FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20381,
      "TXT_FINANCIER_NAME": "MANGALORE REFINARY PETROCHEMICALS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20382,
      "TXT_FINANCIER_NAME": "THE KARNATAKA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20383,
      "TXT_FINANCIER_NAME": "BAJAJ FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20384,
      "TXT_FINANCIER_NAME": "JAYANT ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20385,
      "TXT_FINANCIER_NAME": "INDIAN INSTITUTE OF FOREIGN TRADE"
    },
    {
      "NUM_FINANCIER_CD": 20386,
      "TXT_FINANCIER_NAME": "WADALA VIVIDHA KARYAKARI VIKAS SEVA SAHAKARI SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20387,
      "TXT_FINANCIER_NAME": "NAVKAR INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20388,
      "TXT_FINANCIER_NAME": "SRI BALAMURUGAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20389,
      "TXT_FINANCIER_NAME": "OHM MURUGA FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20390,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH POWER GENERATION CORP"
    },
    {
      "NUM_FINANCIER_CD": 20391,
      "TXT_FINANCIER_NAME": "VISNAGAR MALVAHAN VYVHAR SAHKARI MANDALI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20392,
      "TXT_FINANCIER_NAME": "THE TJSB SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20393,
      "TXT_FINANCIER_NAME": "THE BAIDYABATI SHEORAPHULI COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20394,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20395,
      "TXT_FINANCIER_NAME": "THE COOPERATIVE CITY BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20396,
      "TXT_FINANCIER_NAME": "THE PRIME CO OPERATIVE BANK PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20397,
      "TXT_FINANCIER_NAME": "PRIMARY CO OP AGRI DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20398,
      "TXT_FINANCIER_NAME": "UNIVERSAL FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 20399,
      "TXT_FINANCIER_NAME": "PRAGATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20400,
      "TXT_FINANCIER_NAME": "ANSHUL AUTO FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 20401,
      "TXT_FINANCIER_NAME": "SHESHADRIPURAM CREDIT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20402,
      "TXT_FINANCIER_NAME": "B D INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20403,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MULTI PURPOSE CO OP SOCY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20404,
      "TXT_FINANCIER_NAME": "DEEPAK FINANCE AND LEASING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20405,
      "TXT_FINANCIER_NAME": "ONGC VIDESH LTD"
    },
    {
      "NUM_FINANCIER_CD": 20406,
      "TXT_FINANCIER_NAME": "BMW INDIA FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20407,
      "TXT_FINANCIER_NAME": "THE SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20408,
      "TXT_FINANCIER_NAME": "GEE CEE ADVANCE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20409,
      "TXT_FINANCIER_NAME": "M P SANGHVI INVESTMENT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20410,
      "TXT_FINANCIER_NAME": "LAXMI ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 20411,
      "TXT_FINANCIER_NAME": "BINDRA FININVEST PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20412,
      "TXT_FINANCIER_NAME": "STATE BANK OF MAHARASHTRA"
    },
    {
      "NUM_FINANCIER_CD": 20413,
      "TXT_FINANCIER_NAME": "SAI AUTOMOBILES"
    },
    {
      "NUM_FINANCIER_CD": 20414,
      "TXT_FINANCIER_NAME": "SEHORE NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20415,
      "TXT_FINANCIER_NAME": "NAVKAR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18780,
      "TXT_FINANCIER_NAME": "GUJARAT AGRO INDUSTRIES CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18876,
      "TXT_FINANCIER_NAME": "QUEPEM URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18877,
      "TXT_FINANCIER_NAME": "FINANCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18878,
      "TXT_FINANCIER_NAME": "Mahesh Sahakari Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 18879,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 18880,
      "TXT_FINANCIER_NAME": "COMPUTER SCIENCES CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18881,
      "TXT_FINANCIER_NAME": "SHRI MAHANT SHIVAYOGI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18882,
      "TXT_FINANCIER_NAME": "CITI CORP FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18883,
      "TXT_FINANCIER_NAME": "AIRPORT AUTHORITY OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18884,
      "TXT_FINANCIER_NAME": "State Bank of India Main Branch Karnal"
    },
    {
      "NUM_FINANCIER_CD": 18885,
      "TXT_FINANCIER_NAME": "BHILWARA URBAN COPRATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18886,
      "TXT_FINANCIER_NAME": "VITTHAL NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18887,
      "TXT_FINANCIER_NAME": "RATANCHAND SHAH SAHAKARI BANK LTD MANGALWEDHA"
    },
    {
      "NUM_FINANCIER_CD": 18888,
      "TXT_FINANCIER_NAME": "THE DELHI STATE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18889,
      "TXT_FINANCIER_NAME": "PARAVUR COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18890,
      "TXT_FINANCIER_NAME": "GORESHWAR GRAMIN BIGARSHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18891,
      "TXT_FINANCIER_NAME": "YOUTH DEVELOPMENT COOP BANK LTD KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18892,
      "TXT_FINANCIER_NAME": "DOSTHI HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 18893,
      "TXT_FINANCIER_NAME": "ECONOMIC DEVELOPMENT CORPORATION OF DAMAN & DIU LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18894,
      "TXT_FINANCIER_NAME": "ELEGANT FINVEST PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 18895,
      "TXT_FINANCIER_NAME": "ENGINEERING PROJECTS I LTD"
    },
    {
      "NUM_FINANCIER_CD": 18896,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18897,
      "TXT_FINANCIER_NAME": "Gas Authority of India Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18898,
      "TXT_FINANCIER_NAME": "DELHI STATE TAXI OPERATORS CO OP THRIFT CREDIT AND SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18899,
      "TXT_FINANCIER_NAME": "OSMANABAD JANATA SAHAKAARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18900,
      "TXT_FINANCIER_NAME": "SHRI BASVESHWAR CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18901,
      "TXT_FINANCIER_NAME": "ALMORA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18902,
      "TXT_FINANCIER_NAME": "SHREE SUBHLAXMI CO OP CREDIT AND CONSUMERS SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 18903,
      "TXT_FINANCIER_NAME": "MARKETYARD COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18904,
      "TXT_FINANCIER_NAME": "VOLTAS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18905,
      "TXT_FINANCIER_NAME": "CITI CORP MARUTI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 18906,
      "TXT_FINANCIER_NAME": "DAUSA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18907,
      "TXT_FINANCIER_NAME": "HYDERABAD MUTUAL BENEFIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18908,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURAL CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18909,
      "TXT_FINANCIER_NAME": "SHRI RAM TRANSPORT FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 18910,
      "TXT_FINANCIER_NAME": "BALASINOR VIKAS CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 18911,
      "TXT_FINANCIER_NAME": "THE RAJPUTANA MAHILA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18912,
      "TXT_FINANCIER_NAME": "THE GAYATRI CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18913,
      "TXT_FINANCIER_NAME": "HARYANA STATE CO.OP.APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18914,
      "TXT_FINANCIER_NAME": "Hassan District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18915,
      "TXT_FINANCIER_NAME": "Hoshiarpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18916,
      "TXT_FINANCIER_NAME": "Idukki District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18917,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18918,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18919,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18920,
      "TXT_FINANCIER_NAME": "INDIAN MERCANTILE CO-OPRETIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18921,
      "TXT_FINANCIER_NAME": "INDORE CLOTH MARKET CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18922,
      "TXT_FINANCIER_NAME": "Jalgaon District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18923,
      "TXT_FINANCIER_NAME": "JALGAON MERCHANT SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18924,
      "TXT_FINANCIER_NAME": "JALNA MERCHANTS CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18925,
      "TXT_FINANCIER_NAME": "Jammu Central Co-operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 18926,
      "TXT_FINANCIER_NAME": "Jammu District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18927,
      "TXT_FINANCIER_NAME": "Jamnagar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18928,
      "TXT_FINANCIER_NAME": "JANAKALYAN SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18929,
      "TXT_FINANCIER_NAME": "JANASEVA SAHAKARI BANK LTD.,PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18930,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK LTD AJARA"
    },
    {
      "NUM_FINANCIER_CD": 18931,
      "TXT_FINANCIER_NAME": "JANKALYAN SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18932,
      "TXT_FINANCIER_NAME": "JHARNESHWAR NAG.SAH.BANK MYDT"
    },
    {
      "NUM_FINANCIER_CD": 18933,
      "TXT_FINANCIER_NAME": "JIVAN COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18934,
      "TXT_FINANCIER_NAME": "Jodhpur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18935,
      "TXT_FINANCIER_NAME": "KANGRA CENTRAL CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18936,
      "TXT_FINANCIER_NAME": "kangra central co-op bank ltd hamirpur"
    },
    {
      "NUM_FINANCIER_CD": 18937,
      "TXT_FINANCIER_NAME": "SHRI SEVAGIRI GRAMIN BIGARSHETI SAHAKARI PATSHANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18938,
      "TXT_FINANCIER_NAME": "JUNEJA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 18939,
      "TXT_FINANCIER_NAME": "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18940,
      "TXT_FINANCIER_NAME": "DNYANDEEP CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18941,
      "TXT_FINANCIER_NAME": "THE GUJARAT STATE CO OP AGRICULTURE AND RURAL DEVLOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18942,
      "TXT_FINANCIER_NAME": "THE KOZHIKODE DIST CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18943,
      "TXT_FINANCIER_NAME": "THE BHADGAON PEOPLES CO-0PP BANK LTD.BHADGAOB."
    },
    {
      "NUM_FINANCIER_CD": 18944,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18945,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO-OPERAATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18946,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18947,
      "TXT_FINANCIER_NAME": "THE BHAGYODAYA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18948,
      "TXT_FINANCIER_NAME": "THE BHANDARA DISTRICT CENTRAL CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18949,
      "TXT_FINANCIER_NAME": "THE BHANDARA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18950,
      "TXT_FINANCIER_NAME": "THE BHARUCH DISTRICT CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18951,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18952,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18953,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18954,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18955,
      "TXT_FINANCIER_NAME": "VIDHARBHA KSHETRYA GRAMIN BA NK"
    },
    {
      "NUM_FINANCIER_CD": 18956,
      "TXT_FINANCIER_NAME": "THE SABARKANTHA DISTRICT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18957,
      "TXT_FINANCIER_NAME": "ORIX AUTO AND BUSINESS SOLUTIONS LTD"
    },
    {
      "NUM_FINANCIER_CD": 18958,
      "TXT_FINANCIER_NAME": "UMA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18959,
      "TXT_FINANCIER_NAME": "UMIYA URBAN CO-OPERATIVE BANK MYDT.,NAGPUR"
    },
    {
      "NUM_FINANCIER_CD": 18960,
      "TXT_FINANCIER_NAME": "UNION CO-BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18961,
      "TXT_FINANCIER_NAME": "UNION CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18962,
      "TXT_FINANCIER_NAME": "Unjha Nagrik Sahkari Bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 18963,
      "TXT_FINANCIER_NAME": "URBAN CO-OPERATIVE BANK,ROURKELA"
    },
    {
      "NUM_FINANCIER_CD": 18964,
      "TXT_FINANCIER_NAME": "Uttar Kannad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18965,
      "TXT_FINANCIER_NAME": "UTTAR PRADESH COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18966,
      "TXT_FINANCIER_NAME": "Vadodara District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18967,
      "TXT_FINANCIER_NAME": "VAISHALI URBAN CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18968,
      "TXT_FINANCIER_NAME": "VAISHYA NAGARI SAHAKARI PAT SANSTHA MARYADIT KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 20416,
      "TXT_FINANCIER_NAME": "LOKMANYA MULTIPURPOSE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20417,
      "TXT_FINANCIER_NAME": "THE MEHSANA NAGRIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20418,
      "TXT_FINANCIER_NAME": "ANAND SONS AUTO FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20419,
      "TXT_FINANCIER_NAME": "SHREE RAM CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20420,
      "TXT_FINANCIER_NAME": "BAGHMAR CARE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20421,
      "TXT_FINANCIER_NAME": "GROVER AUTO DEAL"
    },
    {
      "NUM_FINANCIER_CD": 20422,
      "TXT_FINANCIER_NAME": "RAJALAKSHMI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20423,
      "TXT_FINANCIER_NAME": "VEERACHARYA BABASAHEB KUCHNURE DISTRICT NAGARI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20424,
      "TXT_FINANCIER_NAME": "SAHYOG ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20425,
      "TXT_FINANCIER_NAME": "NARMADA MALWA GRAMEEN BANK"
    },
    {
      "NUM_FINANCIER_CD": 20426,
      "TXT_FINANCIER_NAME": "THE INDUSTRIAL CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20427,
      "TXT_FINANCIER_NAME": "SHREE AGRASEN CO OP URBAN T AND C SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20428,
      "TXT_FINANCIER_NAME": "DEEPSHIKHA COOPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 20429,
      "TXT_FINANCIER_NAME": "THE GUNTUR CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20430,
      "TXT_FINANCIER_NAME": "GOPINATH PATIL PARSIK JANATA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20431,
      "TXT_FINANCIER_NAME": "YESHWANT URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20432,
      "TXT_FINANCIER_NAME": "MOTOR FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20433,
      "TXT_FINANCIER_NAME": "SUKHDHAM FINANCE COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20434,
      "TXT_FINANCIER_NAME": "D S INTEGRATED FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20435,
      "TXT_FINANCIER_NAME": "ESBEE GROWTH FUND PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20436,
      "TXT_FINANCIER_NAME": "MOHOL NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20437,
      "TXT_FINANCIER_NAME": "FATIMA NAGAR CO OPERATIVE BANBK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20438,
      "TXT_FINANCIER_NAME": "WALTON FINANCE PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20439,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH BEVERAGES CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20440,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE POLLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 20441,
      "TXT_FINANCIER_NAME": "DHARMAVEER SAMBHAJI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20442,
      "TXT_FINANCIER_NAME": "NISHANT SAHAKARI PAT PURUTHA SANTSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20443,
      "TXT_FINANCIER_NAME": "MUDGAL URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20444,
      "TXT_FINANCIER_NAME": "LALITH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20445,
      "TXT_FINANCIER_NAME": "RAJARSHI SHAHU NAGARI SAHAKARI PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20446,
      "TXT_FINANCIER_NAME": "KUSALAVA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20447,
      "TXT_FINANCIER_NAME": "TARUN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20448,
      "TXT_FINANCIER_NAME": "PRATHAMIKA KRUSHI SAHAKARA SANGHA NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 20449,
      "TXT_FINANCIER_NAME": "SHRI CHATRAPATI SHIVAJI URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20450,
      "TXT_FINANCIER_NAME": "DHARMAVEER SAMBHAJI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20451,
      "TXT_FINANCIER_NAME": "ARIHANT ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20452,
      "TXT_FINANCIER_NAME": "LONAVALA SAHAKARI CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20453,
      "TXT_FINANCIER_NAME": "PALLAVAN GRAMA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20454,
      "TXT_FINANCIER_NAME": "TOURISM FINANCE CORP OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20455,
      "TXT_FINANCIER_NAME": "KAMAL MAHILA NAGARI SAH PATHPETHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20456,
      "TXT_FINANCIER_NAME": "SHRI GURUDATTA NAG SAH PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20457,
      "TXT_FINANCIER_NAME": "ZENSAR TECHNOLOGIES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20458,
      "TXT_FINANCIER_NAME": "SIDDHARTH ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20459,
      "TXT_FINANCIER_NAME": "PUNE MUNICIPAL CORPORATION SERVANTS CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20460,
      "TXT_FINANCIER_NAME": "MAULI GRAMIN BIGERSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20461,
      "TXT_FINANCIER_NAME": "KARUNA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20462,
      "TXT_FINANCIER_NAME": "SRI DURGAPARAMESWARI SOURDHA CREDIT CO OPERATIVE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20463,
      "TXT_FINANCIER_NAME": "SHRI SWAMI SAMARTH SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20464,
      "TXT_FINANCIER_NAME": "INDIABULLS FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20465,
      "TXT_FINANCIER_NAME": "T BALAJI ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 20466,
      "TXT_FINANCIER_NAME": "THE PRESIDENT OF INDIA GOVT OF INDIA MCA"
    },
    {
      "NUM_FINANCIER_CD": 20467,
      "TXT_FINANCIER_NAME": "NAHATA MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20468,
      "TXT_FINANCIER_NAME": "DAYALBAGH MAHILA CO OPRATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20469,
      "TXT_FINANCIER_NAME": "ADITYA LEASING CORPORTION"
    },
    {
      "NUM_FINANCIER_CD": 20470,
      "TXT_FINANCIER_NAME": "DPGC FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20471,
      "TXT_FINANCIER_NAME": "ENGINEERING PROJECTS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20472,
      "TXT_FINANCIER_NAME": "CENTRAL POWER RESEARCH INSTITUTE"
    },
    {
      "NUM_FINANCIER_CD": 20473,
      "TXT_FINANCIER_NAME": "SHREE VIR TRANSPORT CO OP CREDIT SERVICE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20474,
      "TXT_FINANCIER_NAME": "THE KOSAMBA PURVA VIBHAG CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20475,
      "TXT_FINANCIER_NAME": "SAHYOG NAGARI SAHAKARI PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20476,
      "TXT_FINANCIER_NAME": "SUGAT SAHAKARI PATHSANSTHA MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 20477,
      "TXT_FINANCIER_NAME": "DISTRICT CO OPERATIVE CENTRAL BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20478,
      "TXT_FINANCIER_NAME": "THE CHITNAVISPURA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20479,
      "TXT_FINANCIER_NAME": "KARMEGAM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20480,
      "TXT_FINANCIER_NAME": "KALA RATAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20481,
      "TXT_FINANCIER_NAME": "SANTOSH MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 20482,
      "TXT_FINANCIER_NAME": "FAMILY CREDIT INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20483,
      "TXT_FINANCIER_NAME": "SINDHUDURG DISTRICT KIRANA VYAPARI PATHSANSTHA LTD SINDHUDURG"
    },
    {
      "NUM_FINANCIER_CD": 20484,
      "TXT_FINANCIER_NAME": "DURGAPUR STEEL PLANT SAIL"
    },
    {
      "NUM_FINANCIER_CD": 20485,
      "TXT_FINANCIER_NAME": "SRI PALANI ANDAVAR AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20486,
      "TXT_FINANCIER_NAME": "SHREE VENKATESHWARA AUTO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20487,
      "TXT_FINANCIER_NAME": "THE GOVERNMENT SERVENTS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20488,
      "TXT_FINANCIER_NAME": "MAYUR GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20489,
      "TXT_FINANCIER_NAME": "THE VEPAR VIKAS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20490,
      "TXT_FINANCIER_NAME": "SRI BASAVESHWARA PATTINA SAHAKARA SANGA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20491,
      "TXT_FINANCIER_NAME": "THE BALAJI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20492,
      "TXT_FINANCIER_NAME": "THE AZAD CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20493,
      "TXT_FINANCIER_NAME": "RAILWAY EMPLOYEES CO OPERATIVE BANKING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20494,
      "TXT_FINANCIER_NAME": "ARIHANT INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 20495,
      "TXT_FINANCIER_NAME": "KARMVEER GRAMIN BIGER SHETI SAHAKARI PATH SANSTA MADT"
    },
    {
      "NUM_FINANCIER_CD": 20496,
      "TXT_FINANCIER_NAME": "PAYANGADI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20497,
      "TXT_FINANCIER_NAME": "STEEL AUTHORITY OF INDIA LTDSALEM STEEL PLANT"
    },
    {
      "NUM_FINANCIER_CD": 20498,
      "TXT_FINANCIER_NAME": "FUTURE CAPITAL FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20499,
      "TXT_FINANCIER_NAME": "PRATHAMIKA KRUSHI PATHINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20500,
      "TXT_FINANCIER_NAME": "PRERANA GRAMIN BIGAR SHETI SAHAKARI PATSANTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 20501,
      "TXT_FINANCIER_NAME": "PURNA ANJANEYA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20502,
      "TXT_FINANCIER_NAME": "VIDHYA BHARTI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20503,
      "TXT_FINANCIER_NAME": "SHRI LAXMI NARAYAN GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20504,
      "TXT_FINANCIER_NAME": "RATANSHRI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20505,
      "TXT_FINANCIER_NAME": "SWAMI VIVEKANAND URBAN CREDIT SOUHARD SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20506,
      "TXT_FINANCIER_NAME": "PARTAP SHREE FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20507,
      "TXT_FINANCIER_NAME": "JANKALYAN URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20508,
      "TXT_FINANCIER_NAME": "SHRI MAHANT SHIVAYOGI URBAN CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20509,
      "TXT_FINANCIER_NAME": "ASHOK SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20510,
      "TXT_FINANCIER_NAME": "KAMAL NAGARI SAHAKARI PATH SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18969,
      "TXT_FINANCIER_NAME": "VALAPATTANAM SERVICE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18970,
      "TXT_FINANCIER_NAME": "VALSAD DISTRICT CENTRAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18971,
      "TXT_FINANCIER_NAME": "VASAI JANTA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18972,
      "TXT_FINANCIER_NAME": "VASAI PRAGATI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 18973,
      "TXT_FINANCIER_NAME": "GUJARAT NARMADA VALLEY FARTILIZERS COLTD"
    },
    {
      "NUM_FINANCIER_CD": 18974,
      "TXT_FINANCIER_NAME": "KANARA SARASWAT CREDIT SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 18975,
      "TXT_FINANCIER_NAME": "H L SHANTILAL JAIN FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 18976,
      "TXT_FINANCIER_NAME": "THE MANMANDIR CO OP BANK LTD VITA"
    },
    {
      "NUM_FINANCIER_CD": 18977,
      "TXT_FINANCIER_NAME": "MANJEERA GRAMEENA VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 18978,
      "TXT_FINANCIER_NAME": "UNITED INDIA CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18979,
      "TXT_FINANCIER_NAME": "UNITED INDIA COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18980,
      "TXT_FINANCIER_NAME": "IFFCO AONLA UNIT"
    },
    {
      "NUM_FINANCIER_CD": 18981,
      "TXT_FINANCIER_NAME": "PROCTER AND GAMBLE H AND H CARE LTD"
    },
    {
      "NUM_FINANCIER_CD": 18982,
      "TXT_FINANCIER_NAME": "CHITRADURGA DISTRICT CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18983,
      "TXT_FINANCIER_NAME": "SRI MAHALAKSHMI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18984,
      "TXT_FINANCIER_NAME": "KHATRA FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18985,
      "TXT_FINANCIER_NAME": "AP GENCO"
    },
    {
      "NUM_FINANCIER_CD": 18986,
      "TXT_FINANCIER_NAME": "Mahoba urban cooperative bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 18987,
      "TXT_FINANCIER_NAME": "Kangra District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18988,
      "TXT_FINANCIER_NAME": "KANKARIA MANINAGAR NAG.SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18989,
      "TXT_FINANCIER_NAME": "KANNUR DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18990,
      "TXT_FINANCIER_NAME": "KARMALA URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18991,
      "TXT_FINANCIER_NAME": "KARNATAKA INDUSTRIAL CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18992,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE IND CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18993,
      "TXT_FINANCIER_NAME": "KAVITA URBAN CO-OPERATIVE BANK LTD,SOLAPUR"
    },
    {
      "NUM_FINANCIER_CD": 18994,
      "TXT_FINANCIER_NAME": "KERALA TRANSPORT DEVOLOPMENT FINANCE CO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 18995,
      "TXT_FINANCIER_NAME": "Khammam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18996,
      "TXT_FINANCIER_NAME": "Kheda District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18997,
      "TXT_FINANCIER_NAME": "Kollam District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18998,
      "TXT_FINANCIER_NAME": "Koraput District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 18999,
      "TXT_FINANCIER_NAME": "Krishna District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19000,
      "TXT_FINANCIER_NAME": "KURMANCHAL NAGAR SAHKAR BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19001,
      "TXT_FINANCIER_NAME": "Latur District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19002,
      "TXT_FINANCIER_NAME": "SHRI RAM FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 19003,
      "TXT_FINANCIER_NAME": "THE SOUTH INDIAN BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19004,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO-OPEARATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19005,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19006,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19007,
      "TXT_FINANCIER_NAME": "THE BHAVANA RISHI CO-OP.URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19008,
      "TXT_FINANCIER_NAME": "THE BHUJ MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19009,
      "TXT_FINANCIER_NAME": "THE BHUJ MERCANTILE CO.OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19010,
      "TXT_FINANCIER_NAME": "THE BHUJ MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19011,
      "TXT_FINANCIER_NAME": "THE BHUSAWAL PEOPLES CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19012,
      "TXT_FINANCIER_NAME": "THE BIJNOR URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19013,
      "TXT_FINANCIER_NAME": "THE BODELI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19014,
      "TXT_FINANCIER_NAME": "THE BORAL UNION COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19015,
      "TXT_FINANCIER_NAME": "THE BORIAVI PEOPLE S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19016,
      "TXT_FINANCIER_NAME": "THE BOTAD PEOPLES CO- OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19017,
      "TXT_FINANCIER_NAME": "THE BROACH DISTRICT CENTRAL CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19018,
      "TXT_FINANCIER_NAME": "THE BUSINESS CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19019,
      "TXT_FINANCIER_NAME": "THE CATHOLIC CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19020,
      "TXT_FINANCIER_NAME": "THE CHAITANYA CO-OP.URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19021,
      "TXT_FINANCIER_NAME": "THE CHALISGAON PEOPLES CO-OP BANK LTD.CHALISGAON"
    },
    {
      "NUM_FINANCIER_CD": 19022,
      "TXT_FINANCIER_NAME": "THE CHANASMA COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19023,
      "TXT_FINANCIER_NAME": "THE CHANASMA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19024,
      "TXT_FINANCIER_NAME": "THE RASIPURAM CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19025,
      "TXT_FINANCIER_NAME": "DR BABASAHEB AMBEDKAR NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19026,
      "TXT_FINANCIER_NAME": "AJIT FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19027,
      "TXT_FINANCIER_NAME": "MASS FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19028,
      "TXT_FINANCIER_NAME": "VASAVI CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19029,
      "TXT_FINANCIER_NAME": "VEPAR UDHYOG VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19030,
      "TXT_FINANCIER_NAME": "VEPAR UDHYOG VIKAS SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19031,
      "TXT_FINANCIER_NAME": "VIKAS URBAN CO-OPERATIVE BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 19032,
      "TXT_FINANCIER_NAME": "VPK URBAN CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19033,
      "TXT_FINANCIER_NAME": "VYSYA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19034,
      "TXT_FINANCIER_NAME": "VYSYA CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19035,
      "TXT_FINANCIER_NAME": "WARDHA NAGARI SAHAKARI ADHIKOSH (BANK) MYDT.,WARDHA"
    },
    {
      "NUM_FINANCIER_CD": 19036,
      "TXT_FINANCIER_NAME": "WARDHAMAN URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19037,
      "TXT_FINANCIER_NAME": "YAVAL PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19038,
      "TXT_FINANCIER_NAME": "Yavatmal District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19039,
      "TXT_FINANCIER_NAME": "YOUTH DEVELOPMENT CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19040,
      "TXT_FINANCIER_NAME": "ZILA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19041,
      "TXT_FINANCIER_NAME": "ZILA SAHKARI BANK LTD. LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 19042,
      "TXT_FINANCIER_NAME": "ABU DHABI COMMERCIAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 19043,
      "TXT_FINANCIER_NAME": "ABU DHABI COMMERCIAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19044,
      "TXT_FINANCIER_NAME": "ANTWERP DIAMOND BANK NV"
    },
    {
      "NUM_FINANCIER_CD": 19045,
      "TXT_FINANCIER_NAME": "ARAB BANGLADESH BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19046,
      "TXT_FINANCIER_NAME": "JILA SAHAKARI KENDRIYA BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19047,
      "TXT_FINANCIER_NAME": "WADA NAGRIK SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19048,
      "TXT_FINANCIER_NAME": "PADMAVATHI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 19049,
      "TXT_FINANCIER_NAME": "DHANDAPANI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19050,
      "TXT_FINANCIER_NAME": "MAGMA FINCORP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19051,
      "TXT_FINANCIER_NAME": "INDIA TRADE PROMOTION ORGANISATION"
    },
    {
      "NUM_FINANCIER_CD": 19052,
      "TXT_FINANCIER_NAME": "HONEY PORTFOLIO PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19053,
      "TXT_FINANCIER_NAME": "UNIVERSITY OF RAJSTHAN"
    },
    {
      "NUM_FINANCIER_CD": 19054,
      "TXT_FINANCIER_NAME": "THE SARVODAYA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19055,
      "TXT_FINANCIER_NAME": "SHRI KANYAKA NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19056,
      "TXT_FINANCIER_NAME": "MAHARASHTRA GODAVARI GRAMIN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19057,
      "TXT_FINANCIER_NAME": "THE KUTTIATTOOR PANCHAYATH SER CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19058,
      "TXT_FINANCIER_NAME": "THE KUTTIATTOOR PANCHAYATH SER CO BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19059,
      "TXT_FINANCIER_NAME": "PARAMOUNT ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 19060,
      "TXT_FINANCIER_NAME": "LESSOR KOTAK MAHINDRA PRIME LTD"
    },
    {
      "NUM_FINANCIER_CD": 19061,
      "TXT_FINANCIER_NAME": "SHRI PARSHWANATH GRAMIN BIG SHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19062,
      "TXT_FINANCIER_NAME": "ROYAL FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 19063,
      "TXT_FINANCIER_NAME": "UNIVERSITY OF RAJASTHAN"
    },
    {
      "NUM_FINANCIER_CD": 19064,
      "TXT_FINANCIER_NAME": "MUTHOOT VEHICLE LOANS"
    },
    {
      "NUM_FINANCIER_CD": 20511,
      "TXT_FINANCIER_NAME": "WARDHA ZILLA PARISHAD EMPLOYEES URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20512,
      "TXT_FINANCIER_NAME": "DR HEDGEWAR GRAMIN BIG SHETI PAT SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 20513,
      "TXT_FINANCIER_NAME": "MSTC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20514,
      "TXT_FINANCIER_NAME": "ABHINANDAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20515,
      "TXT_FINANCIER_NAME": "DOST FINCAP PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20516,
      "TXT_FINANCIER_NAME": "VADAKKENCHERRY CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20517,
      "TXT_FINANCIER_NAME": "HARI KISHAN SINGHAL"
    },
    {
      "NUM_FINANCIER_CD": 20518,
      "TXT_FINANCIER_NAME": "VADAKKENCHERRY CO OPERATIVE SERVICES BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20519,
      "TXT_FINANCIER_NAME": "AMRIT INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20520,
      "TXT_FINANCIER_NAME": "DADA GURU CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20521,
      "TXT_FINANCIER_NAME": "VIDYASAGAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20522,
      "TXT_FINANCIER_NAME": "BHANDARI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20523,
      "TXT_FINANCIER_NAME": "SWAMI VIVEKANAND GRAMIN BIGAR SHETI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20524,
      "TXT_FINANCIER_NAME": "THE PALGHAT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20525,
      "TXT_FINANCIER_NAME": "SBI RACPC"
    },
    {
      "NUM_FINANCIER_CD": 20526,
      "TXT_FINANCIER_NAME": "SHRI MAHALAXMI GRAMIN SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20527,
      "TXT_FINANCIER_NAME": "MAA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20528,
      "TXT_FINANCIER_NAME": "SHRI BHIMASHANKAR NAGARI SAHAKARI PATHSANSTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20529,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIM LTD"
    },
    {
      "NUM_FINANCIER_CD": 20530,
      "TXT_FINANCIER_NAME": "CHIKMAGALUR DISTRICT GRADUATE CREDIT CO OPPERATIVE SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 20531,
      "TXT_FINANCIER_NAME": "A U FINANCERS INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20532,
      "TXT_FINANCIER_NAME": "G INTERNATIONAL"
    },
    {
      "NUM_FINANCIER_CD": 20533,
      "TXT_FINANCIER_NAME": "SOUBHAGYA MAHILA SOUHARDHA S BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20534,
      "TXT_FINANCIER_NAME": "VITA URBAN CO OP CREDIT SOC LTD VITA"
    },
    {
      "NUM_FINANCIER_CD": 20535,
      "TXT_FINANCIER_NAME": "SHRI CHANDRAPRABHA JAIN NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20536,
      "TXT_FINANCIER_NAME": "DURGA FINANCE INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20537,
      "TXT_FINANCIER_NAME": "D C ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20538,
      "TXT_FINANCIER_NAME": "THE BANK OF RAJASTHAN"
    },
    {
      "NUM_FINANCIER_CD": 20539,
      "TXT_FINANCIER_NAME": "THE ANNASAHEB SAWANT CO OP URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20540,
      "TXT_FINANCIER_NAME": "SHREE SARDAR PATEL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20541,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20542,
      "TXT_FINANCIER_NAME": "SANMATI GRAMIN BIGAR SHETI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20543,
      "TXT_FINANCIER_NAME": "SAIKRUPA SAHAKARI PATH PEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20544,
      "TXT_FINANCIER_NAME": "THE MOTI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20545,
      "TXT_FINANCIER_NAME": "MOTOR CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20546,
      "TXT_FINANCIER_NAME": "LESSOR RELIGARE FINVEST LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20547,
      "TXT_FINANCIER_NAME": "GOPINATH PATIL PARSIK JANATA SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20548,
      "TXT_FINANCIER_NAME": "SATYASHODHAK SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20549,
      "TXT_FINANCIER_NAME": "CHIKMAGALUR JILLA MAILA SAHAKARA BANK N"
    },
    {
      "NUM_FINANCIER_CD": 20550,
      "TXT_FINANCIER_NAME": "MAHALAKSHMIPURA SRI VASAVI CREDIT CO OPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20551,
      "TXT_FINANCIER_NAME": "MANTHA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20552,
      "TXT_FINANCIER_NAME": "MITUSHI TRADERS AND FINANCIERS P LTD"
    },
    {
      "NUM_FINANCIER_CD": 20553,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL CONSUMER PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20554,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20555,
      "TXT_FINANCIER_NAME": "THE TEXTILE MANUFACTURER S CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20556,
      "TXT_FINANCIER_NAME": "LIFE INSURANCE CORPORATION OF INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20557,
      "TXT_FINANCIER_NAME": "YUVAK SAHAKARI PATHSAUSHTA MARYADIT VASAI"
    },
    {
      "NUM_FINANCIER_CD": 20558,
      "TXT_FINANCIER_NAME": "THE MERCANTILE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20559,
      "TXT_FINANCIER_NAME": "KERALA STATE COOPERATIVE AGRICULTURE AND DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 20560,
      "TXT_FINANCIER_NAME": "SANDHYA MOTORS AND FINANCERS"
    },
    {
      "NUM_FINANCIER_CD": 20561,
      "TXT_FINANCIER_NAME": "K K FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20562,
      "TXT_FINANCIER_NAME": "THE AGRASEN NAGRI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20563,
      "TXT_FINANCIER_NAME": "RAJARSHI SHAHU TALUKA GRAMIN NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20564,
      "TXT_FINANCIER_NAME": "S VASANTHI DEVI DUGAR FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 20565,
      "TXT_FINANCIER_NAME": "KMS FINLEASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20566,
      "TXT_FINANCIER_NAME": "LOKHIT URBAN COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20567,
      "TXT_FINANCIER_NAME": "BETALA MOTOR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20568,
      "TXT_FINANCIER_NAME": "KUBER GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20569,
      "TXT_FINANCIER_NAME": "RELIANCE CONSUMER FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20570,
      "TXT_FINANCIER_NAME": "THE BANASKANTHA MADHYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20571,
      "TXT_FINANCIER_NAME": "SAPTASHRUNGI MAHILA NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20572,
      "TXT_FINANCIER_NAME": "SRI VASAVAMBA CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20573,
      "TXT_FINANCIER_NAME": "SHRI BEERESHWAR SOUHARD CREDIT SAHAKARI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20574,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20575,
      "TXT_FINANCIER_NAME": "WESTERN INDIA TRANSORT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20576,
      "TXT_FINANCIER_NAME": "SHREE PURUSHOTTAM SAH PATSANSTHA LTD LONKHEDA"
    },
    {
      "NUM_FINANCIER_CD": 20577,
      "TXT_FINANCIER_NAME": "LODHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20578,
      "TXT_FINANCIER_NAME": "SHRI BASVESHWAR VYAPARI NAGRI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20579,
      "TXT_FINANCIER_NAME": "RANIGANJ CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20580,
      "TXT_FINANCIER_NAME": "HINDUJA LEYLAND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20581,
      "TXT_FINANCIER_NAME": "MAHALAKSHMI INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20582,
      "TXT_FINANCIER_NAME": "SAFE FINLEASE PVT LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20583,
      "TXT_FINANCIER_NAME": "GE MONEY FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20584,
      "TXT_FINANCIER_NAME": "POOJA FINELEASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20585,
      "TXT_FINANCIER_NAME": "PRAMOD MOTOR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20586,
      "TXT_FINANCIER_NAME": "BHADVAN SAHAKARI PATAPEDI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20587,
      "TXT_FINANCIER_NAME": "TUMKUR PATTANA SAHAKARA BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 20588,
      "TXT_FINANCIER_NAME": "THE HP STATE CO OP SCHEDULE CASTE AND SCHEDULE TRIBE DEVELOPMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 20589,
      "TXT_FINANCIER_NAME": "ADESHWAR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20590,
      "TXT_FINANCIER_NAME": "THE HANUMANTHA NAGAR SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20591,
      "TXT_FINANCIER_NAME": "PADAM FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20592,
      "TXT_FINANCIER_NAME": "SRI CHANNABASAVA SWAMY SOUHARDA PATTINA SAHAKARI BANK NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 20593,
      "TXT_FINANCIER_NAME": "SHUBH LAXMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20594,
      "TXT_FINANCIER_NAME": "SUKHBANS FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20595,
      "TXT_FINANCIER_NAME": "DHANSHRI NAGARI SAHAKARI PATHA SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20596,
      "TXT_FINANCIER_NAME": "SHIVSHAKTI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20597,
      "TXT_FINANCIER_NAME": "KANYAKA PARAMESHWARI SOUHARDA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 20598,
      "TXT_FINANCIER_NAME": "SUNIL INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20599,
      "TXT_FINANCIER_NAME": "VINCO FININS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20600,
      "TXT_FINANCIER_NAME": "NAVSANDESH SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20601,
      "TXT_FINANCIER_NAME": "TAMILNADU MERCANTILE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20602,
      "TXT_FINANCIER_NAME": "SWAMI VIVEKANAND NAG SAH PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20603,
      "TXT_FINANCIER_NAME": "TUMKUR DISTRICT GRADUATES CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20604,
      "TXT_FINANCIER_NAME": "THE NAVJIVAN SAHAKARI SHARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20605,
      "TXT_FINANCIER_NAME": "SIROHI HOME PRODUCTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19065,
      "TXT_FINANCIER_NAME": "GUNAWANT CHAND KHARIWAL SOWCARPET"
    },
    {
      "NUM_FINANCIER_CD": 19066,
      "TXT_FINANCIER_NAME": "GANESH SAHAKARI CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19067,
      "TXT_FINANCIER_NAME": "AD MANUM FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19068,
      "TXT_FINANCIER_NAME": "THE SATHAMBA PEOPLE S CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19069,
      "TXT_FINANCIER_NAME": "SHAKUNTLAM SECURITIES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19070,
      "TXT_FINANCIER_NAME": "CHORDIA CAPITAL MARKET LTD"
    },
    {
      "NUM_FINANCIER_CD": 19071,
      "TXT_FINANCIER_NAME": "NISHANT SAHAKARI GRAMIN PATPURWATHA SANSTHA LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 19072,
      "TXT_FINANCIER_NAME": "INFRASTRUCTURE LEASING AND FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19073,
      "TXT_FINANCIER_NAME": "ECO STAR SECURITIES AND CREDIT PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19074,
      "TXT_FINANCIER_NAME": "LOKMANYA CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19075,
      "TXT_FINANCIER_NAME": "LOKMANYA CO-OP CREDIT SOCIETY LTD."
    },
    {
      "NUM_FINANCIER_CD": 19076,
      "TXT_FINANCIER_NAME": "LOKVIKAS NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19077,
      "TXT_FINANCIER_NAME": "LONAVALA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19078,
      "TXT_FINANCIER_NAME": "LUCKNOW URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19079,
      "TXT_FINANCIER_NAME": "LUCKNOW URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19080,
      "TXT_FINANCIER_NAME": "LUCKNOW URBAN CO-OPERATRATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19081,
      "TXT_FINANCIER_NAME": "LUDHIANA CENTRAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19082,
      "TXT_FINANCIER_NAME": "M.S. CO-OPERATIVE BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 19083,
      "TXT_FINANCIER_NAME": "MADGAUM URBAN CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19084,
      "TXT_FINANCIER_NAME": "MAHAJAN FINANCER CO.,"
    },
    {
      "NUM_FINANCIER_CD": 19085,
      "TXT_FINANCIER_NAME": "MAHALAKSHMI CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19086,
      "TXT_FINANCIER_NAME": "MAHALAKSHMI CO-OPERATIVE BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 19087,
      "TXT_FINANCIER_NAME": "MAHATMA FULE DIST UR. CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19088,
      "TXT_FINANCIER_NAME": "MAHATMA PHULE BIGAR SHETI SAHAKARI PATSANTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 19089,
      "TXT_FINANCIER_NAME": "MAHESH SAHAKARI BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 19090,
      "TXT_FINANCIER_NAME": "HDB FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19091,
      "TXT_FINANCIER_NAME": "SHREE KUBER AUTO FINANCE CORPORTION"
    },
    {
      "NUM_FINANCIER_CD": 19092,
      "TXT_FINANCIER_NAME": "PAPPINIVATTAM SER COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19093,
      "TXT_FINANCIER_NAME": "INDUSTRIAL DEVELOPMENT NAGARI PATHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19094,
      "TXT_FINANCIER_NAME": "SHRI RAM CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19095,
      "TXT_FINANCIER_NAME": "SARASWATHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19096,
      "TXT_FINANCIER_NAME": "THE CHIKHLI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19097,
      "TXT_FINANCIER_NAME": "THE CITIZEN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19098,
      "TXT_FINANCIER_NAME": "THE CITIZEN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19099,
      "TXT_FINANCIER_NAME": "THE CKP CO-OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19100,
      "TXT_FINANCIER_NAME": "The Co-operative bank of Mehsana"
    },
    {
      "NUM_FINANCIER_CD": 19101,
      "TXT_FINANCIER_NAME": "THE CO-OPERATIVE BANK OF MEHSANA LTD,MEHSANA"
    },
    {
      "NUM_FINANCIER_CD": 19102,
      "TXT_FINANCIER_NAME": "THE DAHOD MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19103,
      "TXT_FINANCIER_NAME": "THE DAVANGERE URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19104,
      "TXT_FINANCIER_NAME": "THE DAVANGERE URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19105,
      "TXT_FINANCIER_NAME": "THE DELHI STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19106,
      "TXT_FINANCIER_NAME": "THE DELHI STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19107,
      "TXT_FINANCIER_NAME": "THE DELHI STATE CO-OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19108,
      "TXT_FINANCIER_NAME": "LOHEGAON NAGARI SAH PATSANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 19109,
      "TXT_FINANCIER_NAME": "SARASWAT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19110,
      "TXT_FINANCIER_NAME": "Mahindra And Mahindra Financial Service Limited Singhana"
    },
    {
      "NUM_FINANCIER_CD": 19111,
      "TXT_FINANCIER_NAME": "KRISHAK BHARATI CO OP LTD"
    },
    {
      "NUM_FINANCIER_CD": 19112,
      "TXT_FINANCIER_NAME": "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19113,
      "TXT_FINANCIER_NAME": "BRAHMAVAR AGRICULTURAL SER COOP BANK BRAHMAVAR"
    },
    {
      "NUM_FINANCIER_CD": 19114,
      "TXT_FINANCIER_NAME": "BANK OF AMERICA"
    },
    {
      "NUM_FINANCIER_CD": 19115,
      "TXT_FINANCIER_NAME": "BANK OF BAHRAIN & KUWAIT B.S.C."
    },
    {
      "NUM_FINANCIER_CD": 19116,
      "TXT_FINANCIER_NAME": "BANK OF BAHRAIN& KUWAIT B.S.C"
    },
    {
      "NUM_FINANCIER_CD": 19117,
      "TXT_FINANCIER_NAME": "BANQUE INDOSUEZ"
    },
    {
      "NUM_FINANCIER_CD": 19118,
      "TXT_FINANCIER_NAME": "BARCLAYS BANK P L C"
    },
    {
      "NUM_FINANCIER_CD": 19119,
      "TXT_FINANCIER_NAME": "BARCLAYS BANK PLC"
    },
    {
      "NUM_FINANCIER_CD": 19120,
      "TXT_FINANCIER_NAME": "CITI BANK"
    },
    {
      "NUM_FINANCIER_CD": 19121,
      "TXT_FINANCIER_NAME": "CITY BANK"
    },
    {
      "NUM_FINANCIER_CD": 19122,
      "TXT_FINANCIER_NAME": "KRUNG THAI BANK PUBLIC COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19123,
      "TXT_FINANCIER_NAME": "OMAN INTERNATIONAL BANK"
    },
    {
      "NUM_FINANCIER_CD": 19124,
      "TXT_FINANCIER_NAME": "STANDARD CHARTERED GRINDLAYS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19125,
      "TXT_FINANCIER_NAME": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 19126,
      "TXT_FINANCIER_NAME": "Amrit Finance Company"
    },
    {
      "NUM_FINANCIER_CD": 19127,
      "TXT_FINANCIER_NAME": "ANAND LAKSHMI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19128,
      "TXT_FINANCIER_NAME": "ANUKRITI LEASING & EXPORTS PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19129,
      "TXT_FINANCIER_NAME": "ANZ Grindlays Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19130,
      "TXT_FINANCIER_NAME": "APPLE FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19131,
      "TXT_FINANCIER_NAME": "ARIHANT FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 19132,
      "TXT_FINANCIER_NAME": "ASSALAM FINANCIAL & INVESTMENT COMPANY [P] LTD."
    },
    {
      "NUM_FINANCIER_CD": 19133,
      "TXT_FINANCIER_NAME": "AU FINANCE INDIA P LTD"
    },
    {
      "NUM_FINANCIER_CD": 19134,
      "TXT_FINANCIER_NAME": "BABA FINANCE PVT LTD."
    },
    {
      "NUM_FINANCIER_CD": 19135,
      "TXT_FINANCIER_NAME": "BAIRAVI FINANCE AND INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 19136,
      "TXT_FINANCIER_NAME": "BANSAL CREDITS LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19137,
      "TXT_FINANCIER_NAME": "THE HARYANA STATE CO OP AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19138,
      "TXT_FINANCIER_NAME": "HINDUSTAN PETROLEUM CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 19139,
      "TXT_FINANCIER_NAME": "SHRI LAXMI NIMNAGARI PATPURVATHA PATSANSHTA LTD KHANDALA"
    },
    {
      "NUM_FINANCIER_CD": 19140,
      "TXT_FINANCIER_NAME": "MAHAVEER KRUPA"
    },
    {
      "NUM_FINANCIER_CD": 19141,
      "TXT_FINANCIER_NAME": "SHANKAR NAGARI SAHAKARI BANK LTD NANDED"
    },
    {
      "NUM_FINANCIER_CD": 19142,
      "TXT_FINANCIER_NAME": "SHREE GIRIRAJ FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 19143,
      "TXT_FINANCIER_NAME": "ICICI BANK LIMTED"
    },
    {
      "NUM_FINANCIER_CD": 19144,
      "TXT_FINANCIER_NAME": "KANNUR PRIMARY CO OP AGRIL AND RUL DEVP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19145,
      "TXT_FINANCIER_NAME": "KANNUR PRIMARY CO OP AGRIL RUREL DEVELOPEMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 19146,
      "TXT_FINANCIER_NAME": "KANNUR PRIMARY CO OP AGRIL AND RURAL DEVELOPMENT BANK"
    },
    {
      "NUM_FINANCIER_CD": 19147,
      "TXT_FINANCIER_NAME": "THE THIRUVALLA EAST CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19148,
      "TXT_FINANCIER_NAME": "IMPERIAL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19149,
      "TXT_FINANCIER_NAME": "THE BARDOLI NAGRIK SAHAKARI DHIRAN MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 19150,
      "TXT_FINANCIER_NAME": "ANAND NAGARI SAHAKARI PATSNSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19151,
      "TXT_FINANCIER_NAME": "ANAND NAGARI SAHAKARI PATSNSTHA MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 19152,
      "TXT_FINANCIER_NAME": "IMPERIAL URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19153,
      "TXT_FINANCIER_NAME": "ANAND NAGARI SAHA PATS MARYDIT"
    },
    {
      "NUM_FINANCIER_CD": 19154,
      "TXT_FINANCIER_NAME": "KARMVEER BHAURAO PATIL NAGRI SHA PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19155,
      "TXT_FINANCIER_NAME": "A U FINANCIERS INDIA P LTD"
    },
    {
      "NUM_FINANCIER_CD": 19156,
      "TXT_FINANCIER_NAME": "SHREE BHARAT COOPBANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19157,
      "TXT_FINANCIER_NAME": "URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19158,
      "TXT_FINANCIER_NAME": "LALA URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19159,
      "TXT_FINANCIER_NAME": "THE KARWAR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19160,
      "TXT_FINANCIER_NAME": "UFLEX LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19161,
      "TXT_FINANCIER_NAME": "MAHILA UTKARSH NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19162,
      "TXT_FINANCIER_NAME": "MAHILA UTKARSH NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20606,
      "TXT_FINANCIER_NAME": "THE PACHHAPUR URBAN COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20607,
      "TXT_FINANCIER_NAME": "INDIAN INSTITUTE OF MANAGMENT"
    },
    {
      "NUM_FINANCIER_CD": 20608,
      "TXT_FINANCIER_NAME": "PATDI NAGRIK SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 20609,
      "TXT_FINANCIER_NAME": "SATYAJIT GRAMIN BIG SHETI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20610,
      "TXT_FINANCIER_NAME": "RAJENDRA INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 20611,
      "TXT_FINANCIER_NAME": "THE COMMERCIAL CREDIT CORPORATION PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20612,
      "TXT_FINANCIER_NAME": "CHHALLANI FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 20613,
      "TXT_FINANCIER_NAME": "REGISTRAR KURUKSHETR UNIVERSITY KURUKSHETRA"
    },
    {
      "NUM_FINANCIER_CD": 20614,
      "TXT_FINANCIER_NAME": "SHRI SIDDHESHWAR CREDIT SOUHARD SAH LTD"
    },
    {
      "NUM_FINANCIER_CD": 20615,
      "TXT_FINANCIER_NAME": "VIKHYAT SECURITES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20616,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRIAL AND INFRASTRUCTURA DEVELOPMENT CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20617,
      "TXT_FINANCIER_NAME": "HARISH FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20618,
      "TXT_FINANCIER_NAME": "THE KUTTIYATTOOR PANCHAYATH SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20619,
      "TXT_FINANCIER_NAME": "S PADAMCHAND"
    },
    {
      "NUM_FINANCIER_CD": 20620,
      "TXT_FINANCIER_NAME": "DEOGIRI NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20621,
      "TXT_FINANCIER_NAME": "SETHIA GLOBAL FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20622,
      "TXT_FINANCIER_NAME": "VARUN CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20623,
      "TXT_FINANCIER_NAME": "MARLECHA INVESTMENTS"
    },
    {
      "NUM_FINANCIER_CD": 20624,
      "TXT_FINANCIER_NAME": "SIKAR URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20625,
      "TXT_FINANCIER_NAME": "SHIVAM FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20626,
      "TXT_FINANCIER_NAME": "PRATAP FINCON PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20627,
      "TXT_FINANCIER_NAME": "BARTER LEASING AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20628,
      "TXT_FINANCIER_NAME": "RBA FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20629,
      "TXT_FINANCIER_NAME": "THE THANE DISTRICT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20630,
      "TXT_FINANCIER_NAME": "THE MAHEMDABAD URBAN PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20631,
      "TXT_FINANCIER_NAME": "BIR MOTORS AND FINANCIAL SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 20632,
      "TXT_FINANCIER_NAME": "BANARAS MERCANTILE COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20633,
      "TXT_FINANCIER_NAME": "SEVABHAVI BRAMHIN SAHAKARI PATASANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20634,
      "TXT_FINANCIER_NAME": "CHOLA MANDALAM FINANCE AND INVESTMENT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20635,
      "TXT_FINANCIER_NAME": "TRANCITY FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 20636,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA RASECC"
    },
    {
      "NUM_FINANCIER_CD": 20637,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH SOUTHERN POWER DISTRIBUTION COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20638,
      "TXT_FINANCIER_NAME": "VRUNDAVAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20639,
      "TXT_FINANCIER_NAME": "DINESH AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20640,
      "TXT_FINANCIER_NAME": "UPKAR FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20641,
      "TXT_FINANCIER_NAME": "HERO FINCORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 20642,
      "TXT_FINANCIER_NAME": "HARYANA SCHEDULED CASTES FINANCE AND DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20643,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 20644,
      "TXT_FINANCIER_NAME": "GUJARAT NARMADA VALLEY FERTLULZERS COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20645,
      "TXT_FINANCIER_NAME": "CHATTISGARH RAJYA SAHAKARI MARYADIT BANK"
    },
    {
      "NUM_FINANCIER_CD": 20646,
      "TXT_FINANCIER_NAME": "KANHUR PATHAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20647,
      "TXT_FINANCIER_NAME": "KNSHU SECURITIES"
    },
    {
      "NUM_FINANCIER_CD": 20648,
      "TXT_FINANCIER_NAME": "SHRI GANGA GRAMIN BIGAR SHETI SAH PAT SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20649,
      "TXT_FINANCIER_NAME": "SHREE LAXMI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20650,
      "TXT_FINANCIER_NAME": "HINDUJA LEYLAND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20651,
      "TXT_FINANCIER_NAME": "JILA SAHKARI KENDRIYA BANK MYDT SHAJAPUR"
    },
    {
      "NUM_FINANCIER_CD": 20652,
      "TXT_FINANCIER_NAME": "SHAKTI MOTOR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20653,
      "TXT_FINANCIER_NAME": "THE PONDICHERRY CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20654,
      "TXT_FINANCIER_NAME": "SHREE BAPUPURA JOOTH CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20655,
      "TXT_FINANCIER_NAME": "OM SHUBHAM INVESTMENT AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20656,
      "TXT_FINANCIER_NAME": "ARVIND SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20657,
      "TXT_FINANCIER_NAME": "RUCHI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20658,
      "TXT_FINANCIER_NAME": "HADAGALI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20659,
      "TXT_FINANCIER_NAME": "SC ST DEVELOPMENT CO"
    },
    {
      "NUM_FINANCIER_CD": 20660,
      "TXT_FINANCIER_NAME": "BOTHRA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20661,
      "TXT_FINANCIER_NAME": "RAOL TRADING COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20662,
      "TXT_FINANCIER_NAME": "M A TRADING AND FINANCING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20663,
      "TXT_FINANCIER_NAME": "CHOLA MANDALAM INVESTMENT AND FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20664,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURE CO OPERATIVE"
    },
    {
      "NUM_FINANCIER_CD": 20665,
      "TXT_FINANCIER_NAME": "ACG SONS FINANCIERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20666,
      "TXT_FINANCIER_NAME": "MANGAL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20667,
      "TXT_FINANCIER_NAME": "SHRI LAXMINARAYAN NAG SAH PATSANTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20668,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20669,
      "TXT_FINANCIER_NAME": "MAHAYOGI VEMANA SOUHARDA PATTINA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20670,
      "TXT_FINANCIER_NAME": "UTTAMRAO DHIKALE GRMIN BIG SHETI SAH PAT SANTHA MARY"
    },
    {
      "NUM_FINANCIER_CD": 20671,
      "TXT_FINANCIER_NAME": "THE MARATHA URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20672,
      "TXT_FINANCIER_NAME": "GILLETE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20673,
      "TXT_FINANCIER_NAME": "THE KALNA TOWN CREDIT CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20674,
      "TXT_FINANCIER_NAME": "S R FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20675,
      "TXT_FINANCIER_NAME": "BHAGYODAYA SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20676,
      "TXT_FINANCIER_NAME": "SRI KANYAKAPARAMESHWARI SOUHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20677,
      "TXT_FINANCIER_NAME": "JANKALYAN SAH PATH SANTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 20678,
      "TXT_FINANCIER_NAME": "TIRUPATI MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20679,
      "TXT_FINANCIER_NAME": "SAI PRERNA CO OPERATIVE SOCIETY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20680,
      "TXT_FINANCIER_NAME": "VAIBHAV ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20681,
      "TXT_FINANCIER_NAME": "GIRIRAJ GRAMIN BIGERSHETI SAHAKARI PATSANSTHA MARYADIT PADGHA"
    },
    {
      "NUM_FINANCIER_CD": 20682,
      "TXT_FINANCIER_NAME": "PUNAM PROJECTS PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20683,
      "TXT_FINANCIER_NAME": "JAGRUT YUVAK CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 20684,
      "TXT_FINANCIER_NAME": "KDS LEASING AND FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20685,
      "TXT_FINANCIER_NAME": "S K FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20686,
      "TXT_FINANCIER_NAME": "MAGMA FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 20687,
      "TXT_FINANCIER_NAME": "SHREE BASAVESHWARA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20688,
      "TXT_FINANCIER_NAME": "SARVAJANIK CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20689,
      "TXT_FINANCIER_NAME": "NABAGRAM PEOPLES CO OPERATIVE CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20690,
      "TXT_FINANCIER_NAME": "AJIT NAGARI SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20691,
      "TXT_FINANCIER_NAME": "SIDARATH SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 20692,
      "TXT_FINANCIER_NAME": "SHRI BHAGYALAXMI GRAMIN BIGHAR SHETI SAH PATH SANS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20693,
      "TXT_FINANCIER_NAME": "ANDAVAR GROUP AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20694,
      "TXT_FINANCIER_NAME": "SHREE BIRADEV FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20695,
      "TXT_FINANCIER_NAME": "I K F FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20696,
      "TXT_FINANCIER_NAME": "SKT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20697,
      "TXT_FINANCIER_NAME": "SHANKARRAO MOHITE PATIL NAGARI SAHKARI PATSANSTHA MARYADIT BARLONI"
    },
    {
      "NUM_FINANCIER_CD": 20698,
      "TXT_FINANCIER_NAME": "SHRI MALLIKARJUN CO OP CREDIT SOCIETY NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 20699,
      "TXT_FINANCIER_NAME": "HARYANA STATE ELECTRONIC DEVELOPMENT CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 20700,
      "TXT_FINANCIER_NAME": "KALYANSAGAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20701,
      "TXT_FINANCIER_NAME": "SUKHCHAIN HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20702,
      "TXT_FINANCIER_NAME": "SHREE BASAVESHWARA NAGARI SAH PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20703,
      "TXT_FINANCIER_NAME": "RAHUL HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 19163,
      "TXT_FINANCIER_NAME": "Malappuram District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19164,
      "TXT_FINANCIER_NAME": "MALAPPURAM DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19165,
      "TXT_FINANCIER_NAME": "MALAPPURAM DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19166,
      "TXT_FINANCIER_NAME": "Manipur State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19167,
      "TXT_FINANCIER_NAME": "MANIPUR STATE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19168,
      "TXT_FINANCIER_NAME": "MANMANDIR NAGARI SAHAKARI PATSANSTHA MARYADIT, VITA"
    },
    {
      "NUM_FINANCIER_CD": 19169,
      "TXT_FINANCIER_NAME": "MANSA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19170,
      "TXT_FINANCIER_NAME": "Medak District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19171,
      "TXT_FINANCIER_NAME": "MEENACHIL EAST URBAN CO OPORATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19172,
      "TXT_FINANCIER_NAME": "Meghalaya State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19173,
      "TXT_FINANCIER_NAME": "MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19174,
      "TXT_FINANCIER_NAME": "METROPOLITAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19175,
      "TXT_FINANCIER_NAME": "Moga District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19176,
      "TXT_FINANCIER_NAME": "MUMBAI MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19177,
      "TXT_FINANCIER_NAME": "MUZAFFARNAGAR DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19178,
      "TXT_FINANCIER_NAME": "Nagaland State Central Co-oprative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19179,
      "TXT_FINANCIER_NAME": "NAGARIK SAHAKARI BANK LTD BHIWANDI"
    },
    {
      "NUM_FINANCIER_CD": 19180,
      "TXT_FINANCIER_NAME": "NAGPUR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19181,
      "TXT_FINANCIER_NAME": "Nalgonda District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19182,
      "TXT_FINANCIER_NAME": "NANDED MERCHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19183,
      "TXT_FINANCIER_NAME": "sri Bhagavathi Co Op Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19184,
      "TXT_FINANCIER_NAME": "THE GOA STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19185,
      "TXT_FINANCIER_NAME": "COCHIN SHIPYARD LTD"
    },
    {
      "NUM_FINANCIER_CD": 19186,
      "TXT_FINANCIER_NAME": "JANATHA SEVA CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19187,
      "TXT_FINANCIER_NAME": "SUVIDHA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19188,
      "TXT_FINANCIER_NAME": "NORTHERN RAILWAY PRIMARY COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19189,
      "TXT_FINANCIER_NAME": "THE ERNAKULAM DISTRICT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19190,
      "TXT_FINANCIER_NAME": "THE FARIDABAD CENTRAL COPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19191,
      "TXT_FINANCIER_NAME": "THE GODHRA CITY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19192,
      "TXT_FINANCIER_NAME": "THE GODHRA CITY CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19193,
      "TXT_FINANCIER_NAME": "THE GRAIN MERCHANTS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19194,
      "TXT_FINANCIER_NAME": "THE GREATER BOMBAY CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19195,
      "TXT_FINANCIER_NAME": "THE GUJARAT INDUSTRIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19196,
      "TXT_FINANCIER_NAME": "THE GUJARAT STATE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19197,
      "TXT_FINANCIER_NAME": "THE HIMACHAL PRADESH STATE COOPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19198,
      "TXT_FINANCIER_NAME": "THE HONAVAR URBAN CO-OP BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19199,
      "TXT_FINANCIER_NAME": "THE HOTEL INDUSTRIALISTS CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19200,
      "TXT_FINANCIER_NAME": "THE HYDERABAD DIST CO-OP CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19201,
      "TXT_FINANCIER_NAME": "THE HYDERABAD DIST. CO-OP CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19202,
      "TXT_FINANCIER_NAME": "SETHIYA FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19203,
      "TXT_FINANCIER_NAME": "NARODA FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19204,
      "TXT_FINANCIER_NAME": "DIXIT FINANCIAL ASSOCIATE"
    },
    {
      "NUM_FINANCIER_CD": 19205,
      "TXT_FINANCIER_NAME": "THE JANATA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19206,
      "TXT_FINANCIER_NAME": "LAXMI FINANCE SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 19207,
      "TXT_FINANCIER_NAME": "SUGAN INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 19208,
      "TXT_FINANCIER_NAME": "RATANCHAND SHAH SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19209,
      "TXT_FINANCIER_NAME": "GULAB INVESTMENTS AUTOMOTIVE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 19210,
      "TXT_FINANCIER_NAME": "MAGMA FINANCE CORPRATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19211,
      "TXT_FINANCIER_NAME": "THE SINDAGI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19212,
      "TXT_FINANCIER_NAME": "DHANWANTRI NAGARI SAHAKARI PATSANSTHA MARYADIT SATARA"
    },
    {
      "NUM_FINANCIER_CD": 19213,
      "TXT_FINANCIER_NAME": "CENTRAL BANK OF PUNJAB"
    },
    {
      "NUM_FINANCIER_CD": 19214,
      "TXT_FINANCIER_NAME": "CHAROTAR NAGARIK BANK LTD.ANAND"
    },
    {
      "NUM_FINANCIER_CD": 19215,
      "TXT_FINANCIER_NAME": "CHEMM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19216,
      "TXT_FINANCIER_NAME": "CHITRAKOOT MOTOR FINANCE (P) LTD"
    },
    {
      "NUM_FINANCIER_CD": 19217,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM INVESMENT AND FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 19218,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM DBS FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19219,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM DBS FIN CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19220,
      "TXT_FINANCIER_NAME": "CITI FINANCIAL CONSUMER FINANCE INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19221,
      "TXT_FINANCIER_NAME": "CITI FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 19222,
      "TXT_FINANCIER_NAME": "CONSORTIUM FINANCE & LEASING LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19223,
      "TXT_FINANCIER_NAME": "COSMOS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19224,
      "TXT_FINANCIER_NAME": "DANESH MOTOR FINANCE COMPANY PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19225,
      "TXT_FINANCIER_NAME": "DIAMOND FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 19226,
      "TXT_FINANCIER_NAME": "EMPIRE SECURITIES & CAPITAL LTD."
    },
    {
      "NUM_FINANCIER_CD": 19227,
      "TXT_FINANCIER_NAME": "EVER TRUST FINANCE (INDIA) LTD."
    },
    {
      "NUM_FINANCIER_CD": 19228,
      "TXT_FINANCIER_NAME": "FOURSQUARE LEASING & FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19229,
      "TXT_FINANCIER_NAME": "SAHAYOG URBAN CO OPP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19230,
      "TXT_FINANCIER_NAME": "THE BICHOLIM URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19231,
      "TXT_FINANCIER_NAME": "THE BHANDARA URBAN CO OPERATIVE BANK LTD BHANDARA"
    },
    {
      "NUM_FINANCIER_CD": 19232,
      "TXT_FINANCIER_NAME": "SIR M VISHWESHWARAYA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19233,
      "TXT_FINANCIER_NAME": "LESSOR GE CAPITAL TRANS FIN SER LTD"
    },
    {
      "NUM_FINANCIER_CD": 19234,
      "TXT_FINANCIER_NAME": "THE URBAN CO OP BANK LTD SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 19235,
      "TXT_FINANCIER_NAME": "SANGLI VAIBHAV CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19236,
      "TXT_FINANCIER_NAME": "NAKODA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19237,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF GUJARAT"
    },
    {
      "NUM_FINANCIER_CD": 19238,
      "TXT_FINANCIER_NAME": "GUJARAT STATE ELECTRICITY CORPORATION LTD SSHEP"
    },
    {
      "NUM_FINANCIER_CD": 19239,
      "TXT_FINANCIER_NAME": "GUNAWANT CHAND KHARIWAL"
    },
    {
      "NUM_FINANCIER_CD": 19240,
      "TXT_FINANCIER_NAME": "IBP COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19241,
      "TXT_FINANCIER_NAME": "JINDAL FINCAP LTD"
    },
    {
      "NUM_FINANCIER_CD": 19242,
      "TXT_FINANCIER_NAME": "KARNATAKA STATE INDUSTRIAL INVESTMENT & DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19243,
      "TXT_FINANCIER_NAME": "LAXMAN LEAFIN LTD."
    },
    {
      "NUM_FINANCIER_CD": 19244,
      "TXT_FINANCIER_NAME": "LIFE INSURANCE CORPORATION OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 19245,
      "TXT_FINANCIER_NAME": "MAHARASHTRA STATE OBC FIN DEVELOPMENT"
    },
    {
      "NUM_FINANCIER_CD": 19246,
      "TXT_FINANCIER_NAME": "MAJHA DOABA CONSULTANTS & FINVEST PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19247,
      "TXT_FINANCIER_NAME": "NATIONAL THERMAL POWER CORPORATION Ltd./FSTPS."
    },
    {
      "NUM_FINANCIER_CD": 19248,
      "TXT_FINANCIER_NAME": "SHREE KALMESHWAR COOP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19249,
      "TXT_FINANCIER_NAME": "THE DHARMAJ PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19250,
      "TXT_FINANCIER_NAME": "SHRI AGRASEN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19251,
      "TXT_FINANCIER_NAME": "DUTTA FINANCIERS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19252,
      "TXT_FINANCIER_NAME": "UTI AMC LTD"
    },
    {
      "NUM_FINANCIER_CD": 19253,
      "TXT_FINANCIER_NAME": "SUVARNA LAXMI SAHAKARI PATPEDDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19254,
      "TXT_FINANCIER_NAME": "TATA ENGINEERING AND LOCOMOTIVE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19255,
      "TXT_FINANCIER_NAME": "BANK OF KARNATAKA"
    },
    {
      "NUM_FINANCIER_CD": 19256,
      "TXT_FINANCIER_NAME": "GULSHAN FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 19257,
      "TXT_FINANCIER_NAME": "VISHAL JUNNAR SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19258,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRYWIDE AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19259,
      "TXT_FINANCIER_NAME": "OUR LADY OF MILAGRES URBAN COOP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20704,
      "TXT_FINANCIER_NAME": "PRIYA FINANCIAL SERVICES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 20705,
      "TXT_FINANCIER_NAME": "GUJARAT STATE FERFILLIZERS AND CHEMICELS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20706,
      "TXT_FINANCIER_NAME": "PRAGATI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20707,
      "TXT_FINANCIER_NAME": "SRI VIJAYA MAHANTESH CORPORATION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20708,
      "TXT_FINANCIER_NAME": "THE RAIGAD DISTRICT CENTRAL CO OPERATIVE BANK LTD ALIBAG"
    },
    {
      "NUM_FINANCIER_CD": 20709,
      "TXT_FINANCIER_NAME": "DHARA MOTOR FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20710,
      "TXT_FINANCIER_NAME": "NATIONAL INSURANCE EMPLOYEES CO OP CREDIT AND BANKING SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20711,
      "TXT_FINANCIER_NAME": "VISHNU PRIYA AUTO FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20712,
      "TXT_FINANCIER_NAME": "URBAN CO OPERATIVE CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20713,
      "TXT_FINANCIER_NAME": "NALBARI URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20714,
      "TXT_FINANCIER_NAME": "SHRI RAVALNATH GRAMIN BIGAR SHETI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20715,
      "TXT_FINANCIER_NAME": "TAMILNADU ELECTRICITY BOARD"
    },
    {
      "NUM_FINANCIER_CD": 20716,
      "TXT_FINANCIER_NAME": "CENTRAL BANK OF COMMERCE"
    },
    {
      "NUM_FINANCIER_CD": 20717,
      "TXT_FINANCIER_NAME": "SHRI MAHAVEER MULTIPURPOSE SOUHARD SAHAKARI LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20718,
      "TXT_FINANCIER_NAME": "KODACHADRI PATTINA SOWHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20719,
      "TXT_FINANCIER_NAME": "SHIVDAULAT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20720,
      "TXT_FINANCIER_NAME": "CHOUDHRY FINANCE AND INVESTMENTS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20721,
      "TXT_FINANCIER_NAME": "SHREE GURUDATTA NAGARI SAHAKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20722,
      "TXT_FINANCIER_NAME": "MADHU GANGA PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20723,
      "TXT_FINANCIER_NAME": "SHRI MAHATMA BASAWESHWAR CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20724,
      "TXT_FINANCIER_NAME": "SHRI MAHATMA BASVESHWAR COOP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20725,
      "TXT_FINANCIER_NAME": "JALARAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20726,
      "TXT_FINANCIER_NAME": "FRATERNAL CREDIT AND FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20727,
      "TXT_FINANCIER_NAME": "SREE RAJATH ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20728,
      "TXT_FINANCIER_NAME": "FRATERNAL CREDIT AND FINANCIAL SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20729,
      "TXT_FINANCIER_NAME": "SURENDRA FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20730,
      "TXT_FINANCIER_NAME": "SHREE DHOKESHWAR GRAMIN BIGARSHETI SAHKARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20731,
      "TXT_FINANCIER_NAME": "SUJAL INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 20732,
      "TXT_FINANCIER_NAME": "THE SUVARNA NAGARI SAHAKARI PATHSANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 20733,
      "TXT_FINANCIER_NAME": "NAMOKAR ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20734,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO OPRATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20735,
      "TXT_FINANCIER_NAME": "MAULI KRUPA GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20736,
      "TXT_FINANCIER_NAME": "SANGALI VAIBHAV CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20737,
      "TXT_FINANCIER_NAME": "SHRIRAM TRANSPORT FIN CO"
    },
    {
      "NUM_FINANCIER_CD": 20738,
      "TXT_FINANCIER_NAME": "SHRINATH TRADING CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20739,
      "TXT_FINANCIER_NAME": "KONOKLOTA MAHILA URBAN CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20740,
      "TXT_FINANCIER_NAME": "AMAR FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20741,
      "TXT_FINANCIER_NAME": "ENNEM MOTOR CREDIT PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20742,
      "TXT_FINANCIER_NAME": "NIGHOTWADI GRAMIN BIGARSHETI SAHAKARI PATSANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20743,
      "TXT_FINANCIER_NAME": "UPHAR FINVEST LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20744,
      "TXT_FINANCIER_NAME": "OAIS AUTO FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20745,
      "TXT_FINANCIER_NAME": "TRAVANCORE FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 20746,
      "TXT_FINANCIER_NAME": "FUTURE CAPITAL HOLDINGS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20747,
      "TXT_FINANCIER_NAME": "SUN MOTORS"
    },
    {
      "NUM_FINANCIER_CD": 20748,
      "TXT_FINANCIER_NAME": "SHARADA GRAMIN BIGAR SHETI SAHAKARI PATSANTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20749,
      "TXT_FINANCIER_NAME": "ARIHANT AUTOMOBILES"
    },
    {
      "NUM_FINANCIER_CD": 20750,
      "TXT_FINANCIER_NAME": "POWER SYSTEM OPERATION CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20751,
      "TXT_FINANCIER_NAME": "PRIYADARSHINI URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20752,
      "TXT_FINANCIER_NAME": "LOHAGAON NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20753,
      "TXT_FINANCIER_NAME": "JAI SHRI SHYAM AUTO DEALS"
    },
    {
      "NUM_FINANCIER_CD": 20754,
      "TXT_FINANCIER_NAME": "KAI DEVENDRA SARDA NAGRI SAHAKARI PATSANSTHA LTD TRIMBAKESHWAR"
    },
    {
      "NUM_FINANCIER_CD": 20755,
      "TXT_FINANCIER_NAME": "RELIANCE MONEY"
    },
    {
      "NUM_FINANCIER_CD": 20756,
      "TXT_FINANCIER_NAME": "SHRI CHANDRAPRABH JAIN NAGRI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20757,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI RAMALI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20758,
      "TXT_FINANCIER_NAME": "SHREE SUBHLAXMI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20759,
      "TXT_FINANCIER_NAME": "MAHABHAIRAB CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20760,
      "TXT_FINANCIER_NAME": "LOKAPAVANI MAHILA SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20761,
      "TXT_FINANCIER_NAME": "SHRI SARASWATHI CREDIT SOWHARDA SAHAKARI NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20762,
      "TXT_FINANCIER_NAME": "THE COMPTROLLERS OFFICE CO OPERATOVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20763,
      "TXT_FINANCIER_NAME": "SHATABDI MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20764,
      "TXT_FINANCIER_NAME": "A P FOREST DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20765,
      "TXT_FINANCIER_NAME": "MANJU DEVI BAGHMAR FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 20766,
      "TXT_FINANCIER_NAME": "THE ERODE CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20767,
      "TXT_FINANCIER_NAME": "DEVELOPMENT CREDIT AND CONSUMER CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20768,
      "TXT_FINANCIER_NAME": "VARDHAMAN NAGARI SAHAKARI PATSANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20769,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20770,
      "TXT_FINANCIER_NAME": "SARVODAY GRAMIN BIGER SHETI PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20771,
      "TXT_FINANCIER_NAME": "A U FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20772,
      "TXT_FINANCIER_NAME": "SRI SEETHARAGHAVA SOUHARDA SAHAKAR BANK NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 20773,
      "TXT_FINANCIER_NAME": "PADAM AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20774,
      "TXT_FINANCIER_NAME": "MAHARASHTRA NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20775,
      "TXT_FINANCIER_NAME": "MAHARASHTRA NAGARI SAHAKARI PAT SANTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20776,
      "TXT_FINANCIER_NAME": "THE PARASPAR SAHAYAK SAHAKARI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20777,
      "TXT_FINANCIER_NAME": "SHRI RAM TRANSPORT CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20778,
      "TXT_FINANCIER_NAME": "ESSKAY AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20779,
      "TXT_FINANCIER_NAME": "DALIMBE BELEGARAR PSS NIYAIT KALADAGI"
    },
    {
      "NUM_FINANCIER_CD": 20780,
      "TXT_FINANCIER_NAME": "DALIMBE BELEGARAR P S S NIYAMIT KALADGI"
    },
    {
      "NUM_FINANCIER_CD": 20781,
      "TXT_FINANCIER_NAME": "ORANGE CITY CREDIT CAPITAL PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20782,
      "TXT_FINANCIER_NAME": "INDIA BULLS FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20783,
      "TXT_FINANCIER_NAME": "NIGHOJ NAGARI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20784,
      "TXT_FINANCIER_NAME": "THE SALAL SARVODAYA NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20785,
      "TXT_FINANCIER_NAME": "SAI BABA URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20786,
      "TXT_FINANCIER_NAME": "BANSAL CREDITS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20787,
      "TXT_FINANCIER_NAME": "VISHNAGAR FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20788,
      "TXT_FINANCIER_NAME": "SIDVIK LEASING PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20789,
      "TXT_FINANCIER_NAME": "SHREE GANESH ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20790,
      "TXT_FINANCIER_NAME": "THE BIJNOR URBAN CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20791,
      "TXT_FINANCIER_NAME": "LESSOR SUNDARAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20792,
      "TXT_FINANCIER_NAME": "VARDHMAN MOTOR FINANCE CO"
    },
    {
      "NUM_FINANCIER_CD": 20793,
      "TXT_FINANCIER_NAME": "SADHANA SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20794,
      "TXT_FINANCIER_NAME": "THE DHINOJ NAGRIK SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20795,
      "TXT_FINANCIER_NAME": "MALGANGA NAGARI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20796,
      "TXT_FINANCIER_NAME": "SREI EQUIPMENT FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20797,
      "TXT_FINANCIER_NAME": "INSTITUTE OF PUBLIC ASSISTANCE PROBEDORIA"
    },
    {
      "NUM_FINANCIER_CD": 20798,
      "TXT_FINANCIER_NAME": "PRATHMA BANK"
    },
    {
      "NUM_FINANCIER_CD": 17815,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17816,
      "TXT_FINANCIER_NAME": "CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17817,
      "TXT_FINANCIER_NAME": "VYSYA CO-OPERATIVE ADARSH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17818,
      "TXT_FINANCIER_NAME": "THE SANGLI BANK LIMITED-ICICI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17819,
      "TXT_FINANCIER_NAME": "Citizen Co operative Bank"
    },
    {
      "NUM_FINANCIER_CD": 17820,
      "TXT_FINANCIER_NAME": "SADALGA URBAN SOUHARDA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17821,
      "TXT_FINANCIER_NAME": "RATNAGIRI SINDHUDURG GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17822,
      "TXT_FINANCIER_NAME": "RAJAPUR URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17823,
      "TXT_FINANCIER_NAME": "TAMIL NADU STATE APEX CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17824,
      "TXT_FINANCIER_NAME": "GE COUNTRYWIDE CONSUMER FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 17825,
      "TXT_FINANCIER_NAME": "THE HUKERI URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17826,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM DBS FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17827,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIES ESTATE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17828,
      "TXT_FINANCIER_NAME": "MAHILA MERCHANT NAG.SAH. PTS.,KARAD"
    },
    {
      "NUM_FINANCIER_CD": 17829,
      "TXT_FINANCIER_NAME": "THE GODHRA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17830,
      "TXT_FINANCIER_NAME": "KODAK MAHINDRA BANK"
    },
    {
      "NUM_FINANCIER_CD": 17831,
      "TXT_FINANCIER_NAME": "FIROZABAD DISTRICT COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17832,
      "TXT_FINANCIER_NAME": "UDHAM SINGH NAGAR DISTT CO. BANK"
    },
    {
      "NUM_FINANCIER_CD": 17833,
      "TXT_FINANCIER_NAME": "TML FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17834,
      "TXT_FINANCIER_NAME": "SHRI VIJAY MAHANTESH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17835,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17836,
      "TXT_FINANCIER_NAME": "Punjab & Maharshtra Co-op.Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17837,
      "TXT_FINANCIER_NAME": "HUKKERI URBAN CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 17838,
      "TXT_FINANCIER_NAME": "SYNDICATE BANK."
    },
    {
      "NUM_FINANCIER_CD": 17839,
      "TXT_FINANCIER_NAME": "PRATHAMIK KRUSHI PATTANA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 17840,
      "TXT_FINANCIER_NAME": "SUNDARAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17841,
      "TXT_FINANCIER_NAME": "JALGAON JANATA SAH BANK LTDJALGAON"
    },
    {
      "NUM_FINANCIER_CD": 17842,
      "TXT_FINANCIER_NAME": "BARDEZ URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17843,
      "TXT_FINANCIER_NAME": "THE NAMAKKAL CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17844,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17845,
      "TXT_FINANCIER_NAME": "SRIRAM TRANSPORT FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17846,
      "TXT_FINANCIER_NAME": "THE KARNATAKA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17847,
      "TXT_FINANCIER_NAME": "GOVERNMENT OF TAMILNADU"
    },
    {
      "NUM_FINANCIER_CD": 17848,
      "TXT_FINANCIER_NAME": "SHRI BHAIRAVNATH NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 17849,
      "TXT_FINANCIER_NAME": "BARDEZ URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17850,
      "TXT_FINANCIER_NAME": "THE BHADARAN PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17851,
      "TXT_FINANCIER_NAME": "GUJARAT STATE FARTILIZER AND CHEMICAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 17852,
      "TXT_FINANCIER_NAME": "HERO HONDA MOTORS LTD"
    },
    {
      "NUM_FINANCIER_CD": 17853,
      "TXT_FINANCIER_NAME": "INDIAN FARMERS FERTILISER CO OPERATIVE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17854,
      "TXT_FINANCIER_NAME": "MAHENDRA AND MAHENDRA FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17855,
      "TXT_FINANCIER_NAME": "chitrakoot motor finance ltd"
    },
    {
      "NUM_FINANCIER_CD": 17856,
      "TXT_FINANCIER_NAME": "NORTH EASTERN DEVELOPMENT FINANCE CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17857,
      "TXT_FINANCIER_NAME": "MERCHANTS CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17858,
      "TXT_FINANCIER_NAME": "PAWAN HANS HELICOPTERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 17859,
      "TXT_FINANCIER_NAME": "KADODRA VIBHAG NAGRIK BACHAT AND SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 17860,
      "TXT_FINANCIER_NAME": "Punjab And Sind Bank"
    },
    {
      "NUM_FINANCIER_CD": 17861,
      "TXT_FINANCIER_NAME": "THE TAMILNADU INDUSTRIAL INVESTMENT CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17862,
      "TXT_FINANCIER_NAME": "THYAGARAYANAGAR CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17863,
      "TXT_FINANCIER_NAME": "THE SAS NAGAR CENTRAL COPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17864,
      "TXT_FINANCIER_NAME": "THE KANGRA CENTRAL COPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17865,
      "TXT_FINANCIER_NAME": "BHADRADRI CO OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17866,
      "TXT_FINANCIER_NAME": "Shree Marudhar Investment"
    },
    {
      "NUM_FINANCIER_CD": 17867,
      "TXT_FINANCIER_NAME": "OZAR NAGARI SAHAKARI PAT SANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 17868,
      "TXT_FINANCIER_NAME": "HUBLI SARAKU SAGANIKEDARAR SAHAKARI PATTINA SANGH NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 17869,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALLA PARDI PEOPLE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17870,
      "TXT_FINANCIER_NAME": "CATHOLIC CREDIT COOP SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 17871,
      "TXT_FINANCIER_NAME": "HARYANA STATE INDUSTRIAL AND INFRASTRUCTURE DEVELOPMENT CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 17872,
      "TXT_FINANCIER_NAME": "ANURADHA URBAN CO OP BANK LTD CHIKHLI"
    },
    {
      "NUM_FINANCIER_CD": 17873,
      "TXT_FINANCIER_NAME": "PUNJAB SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 17874,
      "TXT_FINANCIER_NAME": "MALKAPUR URBAN CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 17875,
      "TXT_FINANCIER_NAME": "KERALA TRANSPORT DEVELOPMENT FINANCE CORPORTATION"
    },
    {
      "NUM_FINANCIER_CD": 17876,
      "TXT_FINANCIER_NAME": "ADHYAPAKA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17877,
      "TXT_FINANCIER_NAME": "SHRI VEER PULIKESHI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17878,
      "TXT_FINANCIER_NAME": "KARNATKA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17879,
      "TXT_FINANCIER_NAME": "INDIAN INSTITUTE OF TECHNOLOGY"
    },
    {
      "NUM_FINANCIER_CD": 17880,
      "TXT_FINANCIER_NAME": "THE KERALA STATE FINANCIAL ENTERPRISES LTD"
    },
    {
      "NUM_FINANCIER_CD": 17881,
      "TXT_FINANCIER_NAME": "KATAVUL FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17882,
      "TXT_FINANCIER_NAME": "Dr. Babasaheb Ambedkar Urban Co-Operative Bank Ltd., Nagpur"
    },
    {
      "NUM_FINANCIER_CD": 17883,
      "TXT_FINANCIER_NAME": "SYNDICATE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17884,
      "TXT_FINANCIER_NAME": "THE HOTEL INDUSTRIALISTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17885,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SOUHARDA SAHAKARI BANK LTD.SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 17886,
      "TXT_FINANCIER_NAME": "AMBAJOGAI PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17887,
      "TXT_FINANCIER_NAME": "AZHIKODE SERVICE CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17888,
      "TXT_FINANCIER_NAME": "BARODA DISTRICT INDL. CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17889,
      "TXT_FINANCIER_NAME": "THE WEST BENGAL STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17890,
      "TXT_FINANCIER_NAME": "JALNA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17891,
      "TXT_FINANCIER_NAME": "THE MERCHANTS URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17892,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN COOP BANK LTD KARAD"
    },
    {
      "NUM_FINANCIER_CD": 17893,
      "TXT_FINANCIER_NAME": "THE DAVANGERE URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17894,
      "TXT_FINANCIER_NAME": "THE BHATKAL URBAN CO-OPERATIVE BANK LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 17895,
      "TXT_FINANCIER_NAME": "THE MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17896,
      "TXT_FINANCIER_NAME": "THE JANATA CO-OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17897,
      "TXT_FINANCIER_NAME": "SHRACHI INFRASTRUCTURE FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17898,
      "TXT_FINANCIER_NAME": "SHREE MAHALAXMI MERCANTILE CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17899,
      "TXT_FINANCIER_NAME": "NUTAN NAGRIK SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17900,
      "TXT_FINANCIER_NAME": "NAVSARJAN INDUSTRIAL CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17901,
      "TXT_FINANCIER_NAME": "BIJAPUR SAHAKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17902,
      "TXT_FINANCIER_NAME": "THE GANDHIDHAM CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17903,
      "TXT_FINANCIER_NAME": "THE HYDERABAD DIST CO-OP CENTRALBANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17904,
      "TXT_FINANCIER_NAME": "KEEZHALLUR SERVICE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17905,
      "TXT_FINANCIER_NAME": "VITHAI GRAMIN BIGARSHETI SAHKARI PATASANSTHA, MADHEVADGAON"
    },
    {
      "NUM_FINANCIER_CD": 17906,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FIN.SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 17907,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN NAG.SAH.PAT.SAN."
    },
    {
      "NUM_FINANCIER_CD": 17908,
      "TXT_FINANCIER_NAME": "KERALA STATE CO-OP AGRI & RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17909,
      "TXT_FINANCIER_NAME": "CANARA BANK."
    },
    {
      "NUM_FINANCIER_CD": 19260,
      "TXT_FINANCIER_NAME": "THE NASIK ROAD DEOLALI VYAPARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19261,
      "TXT_FINANCIER_NAME": "AB MAURI INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19262,
      "TXT_FINANCIER_NAME": "VASUNDHARA MAHILA NAGRI SAHAKARI BANK AMBAJOGAI"
    },
    {
      "NUM_FINANCIER_CD": 19263,
      "TXT_FINANCIER_NAME": "SETHI LEASING AND FINACE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 19264,
      "TXT_FINANCIER_NAME": "NASIK DISTRICT CENTRAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19265,
      "TXT_FINANCIER_NAME": "NASIK JILHA MAHILA VIKAS SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19266,
      "TXT_FINANCIER_NAME": "NASIK ROAD DEOLALI VYAPARI SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19267,
      "TXT_FINANCIER_NAME": "NAVSARJAN INDUSTRIAL CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19268,
      "TXT_FINANCIER_NAME": "Nayagarh District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19269,
      "TXT_FINANCIER_NAME": "Nizamabad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19270,
      "TXT_FINANCIER_NAME": "NORTH KANARA G S B CO-OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19271,
      "TXT_FINANCIER_NAME": "PALUS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19272,
      "TXT_FINANCIER_NAME": "PANCHSHEEL MERCANTILE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19273,
      "TXT_FINANCIER_NAME": "SOUND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19274,
      "TXT_FINANCIER_NAME": "SHREE SHARADA SAHAKARI NAGARI PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19275,
      "TXT_FINANCIER_NAME": "NATIONAL INSURANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19276,
      "TXT_FINANCIER_NAME": "VIJAYA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19277,
      "TXT_FINANCIER_NAME": "TELECOMUNICATION CONSULTANTS INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19278,
      "TXT_FINANCIER_NAME": "BANK MUSCAT SAOG"
    },
    {
      "NUM_FINANCIER_CD": 19279,
      "TXT_FINANCIER_NAME": "CHITTORGARH URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19280,
      "TXT_FINANCIER_NAME": "THE HYDERABAD DIST.CO-OP CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19281,
      "TXT_FINANCIER_NAME": "THE IDAR NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19282,
      "TXT_FINANCIER_NAME": "THE IDUKKI DISTRICT CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19283,
      "TXT_FINANCIER_NAME": "THE INDUSTRIAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19284,
      "TXT_FINANCIER_NAME": "THE IRINJALAKUDA TOWN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19285,
      "TXT_FINANCIER_NAME": "THE ISLAMPUR URBAN CO-OP BANK LTD ISLAMPUR"
    },
    {
      "NUM_FINANCIER_CD": 19286,
      "TXT_FINANCIER_NAME": "THE JALGAON PEOPLES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19287,
      "TXT_FINANCIER_NAME": "THE JALNA PEOPLES CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19288,
      "TXT_FINANCIER_NAME": "THE JANALAXMI CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19289,
      "TXT_FINANCIER_NAME": "THE JANATA COMMERCIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19290,
      "TXT_FINANCIER_NAME": "THE JANATA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19291,
      "TXT_FINANCIER_NAME": "THE JANATH CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19292,
      "TXT_FINANCIER_NAME": "THE JANATHA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19293,
      "TXT_FINANCIER_NAME": "THE JANTA CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19294,
      "TXT_FINANCIER_NAME": "THE JODHPUR CENTRAL COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19295,
      "TXT_FINANCIER_NAME": "THE JODHPUR CENTRAL COOPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19296,
      "TXT_FINANCIER_NAME": "THE KADODRA VIBHAG NAGRIK BACAT AND SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 19297,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO OP BANK LTE"
    },
    {
      "NUM_FINANCIER_CD": 19298,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19299,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19300,
      "TXT_FINANCIER_NAME": "THE KANARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD., SIRSI"
    },
    {
      "NUM_FINANCIER_CD": 19301,
      "TXT_FINANCIER_NAME": "DENA BNK"
    },
    {
      "NUM_FINANCIER_CD": 19302,
      "TXT_FINANCIER_NAME": "THE SURAT DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19303,
      "TXT_FINANCIER_NAME": "THE REGIONAL EXECUTIVE DIRECTOR"
    },
    {
      "NUM_FINANCIER_CD": 19304,
      "TXT_FINANCIER_NAME": "MADHYA PRADESH VIDHYUT MANDAL KARMCHARI PARASPAR SAHKARI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19305,
      "TXT_FINANCIER_NAME": "SHREE RAM CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19306,
      "TXT_FINANCIER_NAME": "S V FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19307,
      "TXT_FINANCIER_NAME": "IMPHAL URBAN CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19308,
      "TXT_FINANCIER_NAME": "SWARNA BHARATHI SHANKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 19309,
      "TXT_FINANCIER_NAME": "M PRAKASH JAIN HUF"
    },
    {
      "NUM_FINANCIER_CD": 19310,
      "TXT_FINANCIER_NAME": "GE CAPITAL TRANSPORTATION"
    },
    {
      "NUM_FINANCIER_CD": 19311,
      "TXT_FINANCIER_NAME": "GE MONEY FINANCIAL SERVICE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19312,
      "TXT_FINANCIER_NAME": "GE MONEY FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19313,
      "TXT_FINANCIER_NAME": "GENERAL POST OFFICE SAVINGS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19314,
      "TXT_FINANCIER_NAME": "GLOBAL TRUST BANK LTD., BOMBAY"
    },
    {
      "NUM_FINANCIER_CD": 19315,
      "TXT_FINANCIER_NAME": "GMAC FINANCIAL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19316,
      "TXT_FINANCIER_NAME": "GN VASAVI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19317,
      "TXT_FINANCIER_NAME": "GOLI FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19318,
      "TXT_FINANCIER_NAME": "HERO HONDA FINLEASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19319,
      "TXT_FINANCIER_NAME": "HIM SIKHAR SAVIND AND FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 19320,
      "TXT_FINANCIER_NAME": "INDO ASIAN FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19321,
      "TXT_FINANCIER_NAME": "INTEGRATED FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19322,
      "TXT_FINANCIER_NAME": "INTEGRATED FINANCE COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19323,
      "TXT_FINANCIER_NAME": "INTER STATE FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19324,
      "TXT_FINANCIER_NAME": "JAGAT LEASING PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19325,
      "TXT_FINANCIER_NAME": "JAIPUR CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19326,
      "TXT_FINANCIER_NAME": "JAMMU AND KASHMIR STATE FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19327,
      "TXT_FINANCIER_NAME": "JAYABHARAT CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19328,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19329,
      "TXT_FINANCIER_NAME": "THE DADASAHEB RAMRAO PATIL CO OP BANK LTD PIMPALNER"
    },
    {
      "NUM_FINANCIER_CD": 19330,
      "TXT_FINANCIER_NAME": "VASAI JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19331,
      "TXT_FINANCIER_NAME": "NICCO UCO ALLIANCE CREDIT LTD"
    },
    {
      "NUM_FINANCIER_CD": 19332,
      "TXT_FINANCIER_NAME": "NORTH KANARA GSB CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 19333,
      "TXT_FINANCIER_NAME": "OMNIBUS INDUSTRIAL DEVELOPMENT CORPORATION OF DAMAN & DIU LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19334,
      "TXT_FINANCIER_NAME": "RATTAN HIRE PURCHASE PVT. LTD"
    },
    {
      "NUM_FINANCIER_CD": 19335,
      "TXT_FINANCIER_NAME": "REGIONAL EXECUTIVE DIRECTOR CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 19336,
      "TXT_FINANCIER_NAME": "SHREE BALAJI FINVEST LTD"
    },
    {
      "NUM_FINANCIER_CD": 19337,
      "TXT_FINANCIER_NAME": "Shree Marudhar Investment"
    },
    {
      "NUM_FINANCIER_CD": 19338,
      "TXT_FINANCIER_NAME": "SHRI SWAMI SAMARTH BIGAR S.S.P.S.LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19339,
      "TXT_FINANCIER_NAME": "SIDHU INVESTMENT PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19340,
      "TXT_FINANCIER_NAME": "TAMIL NADU INDUSTRIAL INVESTMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19341,
      "TXT_FINANCIER_NAME": "TATA TEA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19342,
      "TXT_FINANCIER_NAME": "VIGFIN HOLDINGS PVT. LTD."
    },
    {
      "NUM_FINANCIER_CD": 19343,
      "TXT_FINANCIER_NAME": "WEST BENGAL INDUSTRIAL DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19344,
      "TXT_FINANCIER_NAME": "YES"
    },
    {
      "NUM_FINANCIER_CD": 19345,
      "TXT_FINANCIER_NAME": "ICICI BANK LTD, GOMTI NAGAR"
    },
    {
      "NUM_FINANCIER_CD": 19346,
      "TXT_FINANCIER_NAME": "ICICI BANK LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 19347,
      "TXT_FINANCIER_NAME": "IndusIndBank"
    },
    {
      "NUM_FINANCIER_CD": 19348,
      "TXT_FINANCIER_NAME": "ING VYSYS BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19349,
      "TXT_FINANCIER_NAME": "KARNATKA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19350,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANKLTD"
    },
    {
      "NUM_FINANCIER_CD": 19351,
      "TXT_FINANCIER_NAME": "THE BHAVASAR KSHATRIYA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19352,
      "TXT_FINANCIER_NAME": "PARSIK JANATA SAH.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19353,
      "TXT_FINANCIER_NAME": "PAVANA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19354,
      "TXT_FINANCIER_NAME": "PERUMBAVOOR SERVICE CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 19355,
      "TXT_FINANCIER_NAME": "PITHORAGARH ZILA SEHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19356,
      "TXT_FINANCIER_NAME": "PRATHAMIK SHIKSHAK SAHAKARI BANK LTD.,SATARA"
    },
    {
      "NUM_FINANCIER_CD": 19357,
      "TXT_FINANCIER_NAME": "PRERNA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19358,
      "TXT_FINANCIER_NAME": "PRIYADARSHANI NAGAR SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 20799,
      "TXT_FINANCIER_NAME": "SOMESHWAR NAGARI SAHAKARI PATSANTHA"
    },
    {
      "NUM_FINANCIER_CD": 20800,
      "TXT_FINANCIER_NAME": "MARATHWADA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20801,
      "TXT_FINANCIER_NAME": "DAKSHINA KANNADA JILLA MURTHEYARA SAHAKARI SANGA"
    },
    {
      "NUM_FINANCIER_CD": 20802,
      "TXT_FINANCIER_NAME": "OIL AND NATURAL GAS CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20803,
      "TXT_FINANCIER_NAME": "INDRAYANI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20804,
      "TXT_FINANCIER_NAME": "PUNE CONTONMENT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20805,
      "TXT_FINANCIER_NAME": "THE MAHANTESH URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20806,
      "TXT_FINANCIER_NAME": "SHRIMANT THORLE BAJIRAO NAGARI SAHAKARI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20807,
      "TXT_FINANCIER_NAME": "NOVELTY FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20808,
      "TXT_FINANCIER_NAME": "JEEVAN VIKAS NAGRI SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20809,
      "TXT_FINANCIER_NAME": "SRI VENKATESHWARA CONSULTANTS"
    },
    {
      "NUM_FINANCIER_CD": 20810,
      "TXT_FINANCIER_NAME": "MAHARASHTRA SCOOTERS EMPLOYEE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 20811,
      "TXT_FINANCIER_NAME": "SRINIDHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20812,
      "TXT_FINANCIER_NAME": "S K FINANCE AND LEASING CO"
    },
    {
      "NUM_FINANCIER_CD": 20813,
      "TXT_FINANCIER_NAME": "AJIT NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20814,
      "TXT_FINANCIER_NAME": "SHRI MAHESH URBAN CO OP BANK AURANGABAD LTD AURANGABAD"
    },
    {
      "NUM_FINANCIER_CD": 20815,
      "TXT_FINANCIER_NAME": "ESS KAY AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20816,
      "TXT_FINANCIER_NAME": "SANGHVI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20817,
      "TXT_FINANCIER_NAME": "LAXMINARAYAN NAGARI SAG PAT SAN LTD"
    },
    {
      "NUM_FINANCIER_CD": 20818,
      "TXT_FINANCIER_NAME": "VYAPARI NAGARI SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 20819,
      "TXT_FINANCIER_NAME": "DREDGING CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20820,
      "TXT_FINANCIER_NAME": "SANGAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20821,
      "TXT_FINANCIER_NAME": "SHREE LAXMAN NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20822,
      "TXT_FINANCIER_NAME": "DNYANDEEP CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20823,
      "TXT_FINANCIER_NAME": "PRINCE AUTO DEAL"
    },
    {
      "NUM_FINANCIER_CD": 20824,
      "TXT_FINANCIER_NAME": "GOYAL ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20825,
      "TXT_FINANCIER_NAME": "SHREE GAJANAN MAHARAJ GRAMIN BIGARSHETI SAHAKARI PATASANSTHA MARYA"
    },
    {
      "NUM_FINANCIER_CD": 20826,
      "TXT_FINANCIER_NAME": "VYAPARI NAGARI GRAHMIN BIGAR SHETI SAH PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20827,
      "TXT_FINANCIER_NAME": "MANAPPURAM ASSET FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20828,
      "TXT_FINANCIER_NAME": "RELIGARE FINVEST LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20829,
      "TXT_FINANCIER_NAME": "KENDRIYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20830,
      "TXT_FINANCIER_NAME": "MALAPPURAM DIST CO OP MOTOR VEHICLES LTD"
    },
    {
      "NUM_FINANCIER_CD": 20831,
      "TXT_FINANCIER_NAME": "SHRI SWAMI SAMARTH NAGARI SAHAKARI PATHASANTH MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20832,
      "TXT_FINANCIER_NAME": "EAST DELHI FINLEASE CO P LTD"
    },
    {
      "NUM_FINANCIER_CD": 20833,
      "TXT_FINANCIER_NAME": "NARINDER SINGH AND SONS PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20834,
      "TXT_FINANCIER_NAME": "SHREE GANESH FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20835,
      "TXT_FINANCIER_NAME": "THE NAGARIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20836,
      "TXT_FINANCIER_NAME": "SIR M VISHWESHWARAIAH CO OPPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20837,
      "TXT_FINANCIER_NAME": "THE NANDED MERCHANTS CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20838,
      "TXT_FINANCIER_NAME": "SRI VENKATA SAI AUTO FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 20839,
      "TXT_FINANCIER_NAME": "UTI AMC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20840,
      "TXT_FINANCIER_NAME": "SRI NAVALADIYAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20841,
      "TXT_FINANCIER_NAME": "SHUBHAM LEASING AND FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20842,
      "TXT_FINANCIER_NAME": "KURDI VIVIDH KARYAKARI SAHAKARI SEVA SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20843,
      "TXT_FINANCIER_NAME": "KUSHAL INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 20844,
      "TXT_FINANCIER_NAME": "SHETKARI GRAMIN BIGHAR SHETI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20845,
      "TXT_FINANCIER_NAME": "THAI CREDITS"
    },
    {
      "NUM_FINANCIER_CD": 20846,
      "TXT_FINANCIER_NAME": "DNYANDEEP GRAMIN BIGARSHETI SAH PAT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20847,
      "TXT_FINANCIER_NAME": "DELHI STATE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20848,
      "TXT_FINANCIER_NAME": "RAWALNATH GRAMIN BIGARSHETI CO OP PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20849,
      "TXT_FINANCIER_NAME": "DHOLKA DAKSHIN VIBHAG URBAN CREDIT SAHAKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 20850,
      "TXT_FINANCIER_NAME": "SHRI SHIVSHAKTI GRAMIN BIGARSHETI SAHAKARI PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20851,
      "TXT_FINANCIER_NAME": "SHRIRAM TRADE FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20852,
      "TXT_FINANCIER_NAME": "COAL CITY URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20853,
      "TXT_FINANCIER_NAME": "INDIAN HIRE PURCHASE"
    },
    {
      "NUM_FINANCIER_CD": 20854,
      "TXT_FINANCIER_NAME": "VASANTDADA PATIL NAGARI SAH PAT SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20855,
      "TXT_FINANCIER_NAME": "NAGRIK SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20856,
      "TXT_FINANCIER_NAME": "VADAKKENCHERRY CO OPERATIVE SERVICE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20857,
      "TXT_FINANCIER_NAME": "SHREE AMBICA CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20858,
      "TXT_FINANCIER_NAME": "THE CHHATTISGARH URBAN CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20859,
      "TXT_FINANCIER_NAME": "SHRI CHATRAPATI SHIVAJI MAHARAJ SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 20860,
      "TXT_FINANCIER_NAME": "VARDHMAN CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20861,
      "TXT_FINANCIER_NAME": "KARIVELLUR SERV CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20862,
      "TXT_FINANCIER_NAME": "MAHESH MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20863,
      "TXT_FINANCIER_NAME": "SHRI LAKSHMI FINANCIER"
    },
    {
      "NUM_FINANCIER_CD": 20864,
      "TXT_FINANCIER_NAME": "DURGAPUR STEEL PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20865,
      "TXT_FINANCIER_NAME": "POOJYA SHRI MAHANTAPPA CO OP SOCIETY NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 20866,
      "TXT_FINANCIER_NAME": "GAYATRI FINANCERS REGD"
    },
    {
      "NUM_FINANCIER_CD": 20867,
      "TXT_FINANCIER_NAME": "THE SAMARTH URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20868,
      "TXT_FINANCIER_NAME": "CHOLA MANDALAM DBS FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20869,
      "TXT_FINANCIER_NAME": "HIMACHAL MOTOR AND GENERAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20870,
      "TXT_FINANCIER_NAME": "THE TAPINDU URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20871,
      "TXT_FINANCIER_NAME": "GUJRAT STATE FERTILLIZERS AND DHEMICELS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20872,
      "TXT_FINANCIER_NAME": "JPMORGAN CHASE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20873,
      "TXT_FINANCIER_NAME": "INDIAN INSTITUTE OF BANKING AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20874,
      "TXT_FINANCIER_NAME": "AJINKYATARA MAHILA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20875,
      "TXT_FINANCIER_NAME": "MATOSHRI MAHILA NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20876,
      "TXT_FINANCIER_NAME": "SWATANTRYA SENANI SHRIPAL ALASE KAKA KURUNDWAD URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20877,
      "TXT_FINANCIER_NAME": "VISHARAD AUTOMOBILE FINANCIERS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20878,
      "TXT_FINANCIER_NAME": "THE JOGINDRA CENTRAL CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20879,
      "TXT_FINANCIER_NAME": "KARWAR NAGARI SAHAKARI PATSANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20880,
      "TXT_FINANCIER_NAME": "UNITARA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20881,
      "TXT_FINANCIER_NAME": "ADARSH KRISHI SAHAKARI KVP SAUNSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20882,
      "TXT_FINANCIER_NAME": "BHONSALE LEASING FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 20883,
      "TXT_FINANCIER_NAME": "SHRI EQUIPMENT FIN P LTD"
    },
    {
      "NUM_FINANCIER_CD": 20884,
      "TXT_FINANCIER_NAME": "SAMRUDDHA JEEVAN NAGARI SAH PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20885,
      "TXT_FINANCIER_NAME": "THE KUMTA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20886,
      "TXT_FINANCIER_NAME": "TOTAL OIL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20887,
      "TXT_FINANCIER_NAME": "NATIONAL URBAN CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20888,
      "TXT_FINANCIER_NAME": "NIRAJ MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20889,
      "TXT_FINANCIER_NAME": "KRISHNA KOYANA NAG SAH PAT SANSTA"
    },
    {
      "NUM_FINANCIER_CD": 20890,
      "TXT_FINANCIER_NAME": "CHHATRAPATI SHAHU GRAMIN BIGAR SHETI SAH PATH SAN MAR VARANAGE PADALI"
    },
    {
      "NUM_FINANCIER_CD": 20891,
      "TXT_FINANCIER_NAME": "THE MAHALINGPUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20892,
      "TXT_FINANCIER_NAME": "GAYATRI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20893,
      "TXT_FINANCIER_NAME": "ONKAR MULL SHANKAR LAL"
    },
    {
      "NUM_FINANCIER_CD": 17910,
      "TXT_FINANCIER_NAME": "RAJGURUNAGAR SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17911,
      "TXT_FINANCIER_NAME": "THE KARUR VYSYA BANK LYD"
    },
    {
      "NUM_FINANCIER_CD": 17912,
      "TXT_FINANCIER_NAME": "JILA SAHAKARI KENDRIYA BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 17913,
      "TXT_FINANCIER_NAME": "THE JANTA CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17914,
      "TXT_FINANCIER_NAME": "UNITED INDIA INSURANCE CO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 17915,
      "TXT_FINANCIER_NAME": "THE BORAL UNION CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17916,
      "TXT_FINANCIER_NAME": "RESERVE BANK EMPLOYEES CO-OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17917,
      "TXT_FINANCIER_NAME": "SHRI PARSHWANATH NAG.SAH.PTS.MRY.KARAD"
    },
    {
      "NUM_FINANCIER_CD": 17918,
      "TXT_FINANCIER_NAME": "KDCC"
    },
    {
      "NUM_FINANCIER_CD": 17919,
      "TXT_FINANCIER_NAME": "THE KARNATAKA CENTRAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17920,
      "TXT_FINANCIER_NAME": "SHREE VYAS DHANVARSHA SHAHAKAI BANK LTD.YAWAL"
    },
    {
      "NUM_FINANCIER_CD": 17921,
      "TXT_FINANCIER_NAME": "THE ODE URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17922,
      "TXT_FINANCIER_NAME": "SARDARGANJ MERCANTILE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17923,
      "TXT_FINANCIER_NAME": "THE KALUPUR COMMERCIAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17924,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD DISTRICT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17925,
      "TXT_FINANCIER_NAME": "THE AHMEDABAD MERCANTILE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17926,
      "TXT_FINANCIER_NAME": "RELIANCE CAPITAL LTD"
    },
    {
      "NUM_FINANCIER_CD": 17927,
      "TXT_FINANCIER_NAME": "THE CALICUT CO OPERATIVE URBAN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17928,
      "TXT_FINANCIER_NAME": "JILA SAHAKARI KENDRIYA BANK MYDT.MANDLA"
    },
    {
      "NUM_FINANCIER_CD": 17929,
      "TXT_FINANCIER_NAME": "KOZHIKODE DISTRICT CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 17930,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17931,
      "TXT_FINANCIER_NAME": "JAI HIND CO OPERATIVE BANK LTD, AMBERNATH"
    },
    {
      "NUM_FINANCIER_CD": 17932,
      "TXT_FINANCIER_NAME": "THE KARMALA URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17933,
      "TXT_FINANCIER_NAME": "RAMGARHIA CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17934,
      "TXT_FINANCIER_NAME": "COMMERCIAL CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17935,
      "TXT_FINANCIER_NAME": "KARNATAKA POWER TRANSMISSION CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 17936,
      "TXT_FINANCIER_NAME": "united commercial bank"
    },
    {
      "NUM_FINANCIER_CD": 17937,
      "TXT_FINANCIER_NAME": "AHILYADEVI NAGARI SAHAKARI PATSANSTHA BR SHELGI"
    },
    {
      "NUM_FINANCIER_CD": 17938,
      "TXT_FINANCIER_NAME": "SONBHADRA NAGAR SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17939,
      "TXT_FINANCIER_NAME": "Khetan Credit Corporation"
    },
    {
      "NUM_FINANCIER_CD": 17940,
      "TXT_FINANCIER_NAME": "THE URBAN COOPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17941,
      "TXT_FINANCIER_NAME": "VIMAL HIRE PURCHASE AND INVESTMENT"
    },
    {
      "NUM_FINANCIER_CD": 17942,
      "TXT_FINANCIER_NAME": "Orient Resins Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17943,
      "TXT_FINANCIER_NAME": "Kothari Investment"
    },
    {
      "NUM_FINANCIER_CD": 17944,
      "TXT_FINANCIER_NAME": "VISVESHVARAYA GRAMEENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 17945,
      "TXT_FINANCIER_NAME": "VYAVSAYIK SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17946,
      "TXT_FINANCIER_NAME": "THE PUNJAB STATE CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17947,
      "TXT_FINANCIER_NAME": "SRI VENKATESWARA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 17948,
      "TXT_FINANCIER_NAME": "Jath Urban Co Operative Bank Ltd"
    },
    {
      "NUM_FINANCIER_CD": 17949,
      "TXT_FINANCIER_NAME": "GREENLAND FINANCE AND LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17950,
      "TXT_FINANCIER_NAME": "THE JANTA SAHAKARI SARAFI MANDALI LTD"
    },
    {
      "NUM_FINANCIER_CD": 17951,
      "TXT_FINANCIER_NAME": "NATIONAL ALUMINIUM COMPANY LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17952,
      "TXT_FINANCIER_NAME": "CSEB"
    },
    {
      "NUM_FINANCIER_CD": 17953,
      "TXT_FINANCIER_NAME": "CANARA BANK ASHOK MARG JAIPUR"
    },
    {
      "NUM_FINANCIER_CD": 17954,
      "TXT_FINANCIER_NAME": "SOLAPUR SIDDHESHWAR SAH BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17955,
      "TXT_FINANCIER_NAME": "RAMGARHIA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17956,
      "TXT_FINANCIER_NAME": "VITTHAL NAGARI SAHKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17957,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM D B S FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 17958,
      "TXT_FINANCIER_NAME": "CHAMBAL GWALIOR KSHETRIYA GRAMNI BANK"
    },
    {
      "NUM_FINANCIER_CD": 17959,
      "TXT_FINANCIER_NAME": "JIJAMATA MAHILA URBAN COOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17960,
      "TXT_FINANCIER_NAME": "DNYANDEEP CO OP CREDIT SOCITY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17961,
      "TXT_FINANCIER_NAME": "CHAKAN GRAMIN BIGARSHETI PATH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17962,
      "TXT_FINANCIER_NAME": "Vardhman Nagari Sah Path Sansths Maryadit"
    },
    {
      "NUM_FINANCIER_CD": 17963,
      "TXT_FINANCIER_NAME": "MAGMA FINCORP LIMITTED"
    },
    {
      "NUM_FINANCIER_CD": 17964,
      "TXT_FINANCIER_NAME": "THE KURMANCHAL NAGAR SHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17965,
      "TXT_FINANCIER_NAME": "Mahindra and Mahindra Financial Services Limited"
    },
    {
      "NUM_FINANCIER_CD": 17966,
      "TXT_FINANCIER_NAME": "AFGIS"
    },
    {
      "NUM_FINANCIER_CD": 17967,
      "TXT_FINANCIER_NAME": "SAKTHI FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17968,
      "TXT_FINANCIER_NAME": "shree vardhaman gramin sah path sanstha maryadit"
    },
    {
      "NUM_FINANCIER_CD": 17969,
      "TXT_FINANCIER_NAME": "SHRI KEDARESHWAR NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17970,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 17971,
      "TXT_FINANCIER_NAME": "CANARA BANK S S PURAM TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 17972,
      "TXT_FINANCIER_NAME": "SURABHI HIRE PURCHASING COMPANY PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 17973,
      "TXT_FINANCIER_NAME": "UTTAR BIHAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 17974,
      "TXT_FINANCIER_NAME": "THE UTTARPARA CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 17975,
      "TXT_FINANCIER_NAME": "VIDHYABHARTI CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 17976,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17977,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SAHAKARI BANK TRADE CENTRE KOLHAPUR"
    },
    {
      "NUM_FINANCIER_CD": 17978,
      "TXT_FINANCIER_NAME": "CONTAI CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17979,
      "TXT_FINANCIER_NAME": "BANK OF RAJASTHAN LTD."
    },
    {
      "NUM_FINANCIER_CD": 17980,
      "TXT_FINANCIER_NAME": "SHREERAM KAPUS SAHAKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 17981,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIME LTD"
    },
    {
      "NUM_FINANCIER_CD": 17982,
      "TXT_FINANCIER_NAME": "THE CO-OPERATIVE BANK OF MEHSANA LTD."
    },
    {
      "NUM_FINANCIER_CD": 17983,
      "TXT_FINANCIER_NAME": "THE MANGALWEDHA URBAN CO-OPERATIVE BANK LTD.,MANGALWEDHA"
    },
    {
      "NUM_FINANCIER_CD": 17984,
      "TXT_FINANCIER_NAME": "THE SHAMRAO VITHAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17985,
      "TXT_FINANCIER_NAME": "CENTURION BANK OF PUNJAB LTD"
    },
    {
      "NUM_FINANCIER_CD": 17986,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17987,
      "TXT_FINANCIER_NAME": "THE UNITED WESTERN BANK LTD,SATARA"
    },
    {
      "NUM_FINANCIER_CD": 17988,
      "TXT_FINANCIER_NAME": "CENTURION BANK OF PUNJAB LIMITD"
    },
    {
      "NUM_FINANCIER_CD": 17989,
      "TXT_FINANCIER_NAME": "THE KURUNDWAD URBAN CO OP BANK LTD ;"
    },
    {
      "NUM_FINANCIER_CD": 17990,
      "TXT_FINANCIER_NAME": "DAUND URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 17991,
      "TXT_FINANCIER_NAME": "PRIME CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17992,
      "TXT_FINANCIER_NAME": "PUNE MERCHANTS CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17993,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FIN. SERV. LTD"
    },
    {
      "NUM_FINANCIER_CD": 17994,
      "TXT_FINANCIER_NAME": "TELLICHERRY CO-OPERATIVE TOWN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 17995,
      "TXT_FINANCIER_NAME": "KERALA TRANSPORT FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 17996,
      "TXT_FINANCIER_NAME": "BANK OF BARODA."
    },
    {
      "NUM_FINANCIER_CD": 17997,
      "TXT_FINANCIER_NAME": "Shrachi Infrastructure Finance Ltd."
    },
    {
      "NUM_FINANCIER_CD": 17998,
      "TXT_FINANCIER_NAME": "JANATA SAHAKARI BANK LTD,AJARA"
    },
    {
      "NUM_FINANCIER_CD": 17999,
      "TXT_FINANCIER_NAME": "THE BICHOLIM URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18000,
      "TXT_FINANCIER_NAME": "SYNDICATE BANK UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 18001,
      "TXT_FINANCIER_NAME": "SMRITI NAGRIK SAHKARI BANK MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18002,
      "TXT_FINANCIER_NAME": "THE SANTRAMPUR URBAN CO-OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18003,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA."
    },
    {
      "NUM_FINANCIER_CD": 18004,
      "TXT_FINANCIER_NAME": "MADHYA PRADESH STATE SAHAKARI BANK MYDT."
    },
    {
      "NUM_FINANCIER_CD": 18005,
      "TXT_FINANCIER_NAME": "AJINKYATARA MAH.SAH.BANK"
    },
    {
      "NUM_FINANCIER_CD": 18006,
      "TXT_FINANCIER_NAME": "THE SAHEBRAO DESHMUKH CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18007,
      "TXT_FINANCIER_NAME": "SULTHAN BATHERY CO-OPERATIVE URBAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19359,
      "TXT_FINANCIER_NAME": "PROGRESSIVE MERCANTILE CO.OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19360,
      "TXT_FINANCIER_NAME": "PROGRESSIVE URBAN CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19361,
      "TXT_FINANCIER_NAME": "PUNE MERCHANTS CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19362,
      "TXT_FINANCIER_NAME": "PUNE MERCHANTS CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19363,
      "TXT_FINANCIER_NAME": "PUNJAB CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19364,
      "TXT_FINANCIER_NAME": "PUNJAB STATE CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19365,
      "TXT_FINANCIER_NAME": "INDIRA MAHILA SAHAKARI CO OP BANK LTD NANDURBAR"
    },
    {
      "NUM_FINANCIER_CD": 19366,
      "TXT_FINANCIER_NAME": "OSMANABAD JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19367,
      "TXT_FINANCIER_NAME": "BELGAUM DISTRICT CENTRAL CO OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19368,
      "TXT_FINANCIER_NAME": "SAMATA GRAMIN BIGARSHETI SAHKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19369,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA COMMERCIAL BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 19370,
      "TXT_FINANCIER_NAME": "SHRI GAJANAN NAGARI SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19371,
      "TXT_FINANCIER_NAME": "THE GOVERNMENT EMPLOYEE CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19372,
      "TXT_FINANCIER_NAME": "AB MAURI INDIA PVT LTDD"
    },
    {
      "NUM_FINANCIER_CD": 19373,
      "TXT_FINANCIER_NAME": "THE KARAD JANATA SAHAKARI BANK LTD,KARAD."
    },
    {
      "NUM_FINANCIER_CD": 19374,
      "TXT_FINANCIER_NAME": "THE KARAD MERCHANT SAHAHKARI CREDIT SANSTHA LTD,KARAD"
    },
    {
      "NUM_FINANCIER_CD": 19375,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19376,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP BANK LTD KARAD"
    },
    {
      "NUM_FINANCIER_CD": 19377,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP BANK LTD,KARAD"
    },
    {
      "NUM_FINANCIER_CD": 19378,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP BANK LTD.KARAD"
    },
    {
      "NUM_FINANCIER_CD": 19379,
      "TXT_FINANCIER_NAME": "THE KARAD URBAN CO-OP.BANK LTD KARAD."
    },
    {
      "NUM_FINANCIER_CD": 19380,
      "TXT_FINANCIER_NAME": "THE KARNATAKA STATE COOPERATIVE APEX BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19381,
      "TXT_FINANCIER_NAME": "THE KARWAR URBAN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19382,
      "TXT_FINANCIER_NAME": "THE KHAMBHAT NAGRIK SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19383,
      "TXT_FINANCIER_NAME": "THE KHEDA PEOPLES CO-OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19384,
      "TXT_FINANCIER_NAME": "THE KODOLI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19385,
      "TXT_FINANCIER_NAME": "THE KODUNGALLUR TOWN CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19386,
      "TXT_FINANCIER_NAME": "KARNATAKA POWER CORPORATION LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19387,
      "TXT_FINANCIER_NAME": "DHANSAMPADA NAGARI SAH PATH SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19388,
      "TXT_FINANCIER_NAME": "THE RAJSAMAND URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19389,
      "TXT_FINANCIER_NAME": "MEHSANA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 19390,
      "TXT_FINANCIER_NAME": "RAJLAXMI NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 19391,
      "TXT_FINANCIER_NAME": "THE DISTRICT CO OPRATIVE BANK LTD LAKHIMPUR KHIRI"
    },
    {
      "NUM_FINANCIER_CD": 19392,
      "TXT_FINANCIER_NAME": "SRI PARSHWANATHA SAHAKARA BANK NIYAMITHA ASHOKANAGAR"
    },
    {
      "NUM_FINANCIER_CD": 19393,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA OVERSEAS BRANCH"
    },
    {
      "NUM_FINANCIER_CD": 19394,
      "TXT_FINANCIER_NAME": "YASHWANT GRAMIN BIGARSHETI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19395,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA PRIMES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19396,
      "TXT_FINANCIER_NAME": "KOTAK MAHINDRA"
    },
    {
      "NUM_FINANCIER_CD": 19397,
      "TXT_FINANCIER_NAME": "KRISHNA FINANCER"
    },
    {
      "NUM_FINANCIER_CD": 19398,
      "TXT_FINANCIER_NAME": "KURMANCHAL BANK NANITAL"
    },
    {
      "NUM_FINANCIER_CD": 19399,
      "TXT_FINANCIER_NAME": "LABHAM FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19400,
      "TXT_FINANCIER_NAME": "LAKSHMI FINANCE & INDUSTRIAL CORPORATION LTD."
    },
    {
      "NUM_FINANCIER_CD": 19401,
      "TXT_FINANCIER_NAME": "LAKSHMI GENERAL FINACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19402,
      "TXT_FINANCIER_NAME": "LAKSHMI GENERAL FINANACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19403,
      "TXT_FINANCIER_NAME": "LAKSHMI GENERAL FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19404,
      "TXT_FINANCIER_NAME": "LAKSHMI GENERAL FINANCE LTD,CHENNAI"
    },
    {
      "NUM_FINANCIER_CD": 19405,
      "TXT_FINANCIER_NAME": "LAKSHMI GENERAL FINANCE LTD.,"
    },
    {
      "NUM_FINANCIER_CD": 19406,
      "TXT_FINANCIER_NAME": "LEASE PLAN INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19407,
      "TXT_FINANCIER_NAME": "LESSOR ORIX AUTO FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19408,
      "TXT_FINANCIER_NAME": "LIC HOUSING FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19409,
      "TXT_FINANCIER_NAME": "LION FINANCIAL SERVICES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19410,
      "TXT_FINANCIER_NAME": "LORD KRISHNA BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19411,
      "TXT_FINANCIER_NAME": "LORD KRISHNA BANK LTD ( CBOP)"
    },
    {
      "NUM_FINANCIER_CD": 19412,
      "TXT_FINANCIER_NAME": "LORD KRISHNA BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19413,
      "TXT_FINANCIER_NAME": "KAUSHIK SECURITIES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19414,
      "TXT_FINANCIER_NAME": "SHREE KALIKAMATA GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 19415,
      "TXT_FINANCIER_NAME": "BOARD OF TRUSTEES OF PORT OF MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 19416,
      "TXT_FINANCIER_NAME": "LIONS CLUB OF BOMBAY"
    },
    {
      "NUM_FINANCIER_CD": 19417,
      "TXT_FINANCIER_NAME": "MUTHOOT VEHICLE AND ASSET FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19418,
      "TXT_FINANCIER_NAME": "THE YAMUNA NAGAR CENTRAL CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19419,
      "TXT_FINANCIER_NAME": "SAROJA UMESH NAHAR"
    },
    {
      "NUM_FINANCIER_CD": 19420,
      "TXT_FINANCIER_NAME": "AROODH JYOTI PATTANA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 19421,
      "TXT_FINANCIER_NAME": "LAKSHMI VILAS BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19422,
      "TXT_FINANCIER_NAME": "PARUR CENTRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19423,
      "TXT_FINANCIER_NAME": "THE CATHOLIC SYRIAN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19424,
      "TXT_FINANCIER_NAME": "THE KARNATAKA BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19425,
      "TXT_FINANCIER_NAME": "BANK OF MAHARASHTRA."
    },
    {
      "NUM_FINANCIER_CD": 19426,
      "TXT_FINANCIER_NAME": "CANARA BANK ASHOK MARG"
    },
    {
      "NUM_FINANCIER_CD": 19427,
      "TXT_FINANCIER_NAME": "CORPORATION BANK."
    },
    {
      "NUM_FINANCIER_CD": 19428,
      "TXT_FINANCIER_NAME": "CORPORATION BANK,"
    },
    {
      "NUM_FINANCIER_CD": 19429,
      "TXT_FINANCIER_NAME": "BAREILLY CORPORATION BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19430,
      "TXT_FINANCIER_NAME": "THE MANDVI MERCANTILE CORPORATION BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19431,
      "TXT_FINANCIER_NAME": "DENA BANK"
    },
    {
      "NUM_FINANCIER_CD": 19432,
      "TXT_FINANCIER_NAME": "I D B I BANK"
    },
    {
      "NUM_FINANCIER_CD": 19433,
      "TXT_FINANCIER_NAME": "I D B I BANK"
    },
    {
      "NUM_FINANCIER_CD": 19434,
      "TXT_FINANCIER_NAME": "I D B I"
    },
    {
      "NUM_FINANCIER_CD": 19435,
      "TXT_FINANCIER_NAME": "I D B I LTD"
    },
    {
      "NUM_FINANCIER_CD": 19436,
      "TXT_FINANCIER_NAME": "INDUSTRIAL DEVELOPMENT BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 19437,
      "TXT_FINANCIER_NAME": "INDUSTRIAL DEVELOPMENT BANK OF INDIA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19438,
      "TXT_FINANCIER_NAME": "ORIENTAL BANK OF COMMERCE GREATER NOIDA"
    },
    {
      "NUM_FINANCIER_CD": 19439,
      "TXT_FINANCIER_NAME": "PUNJAB AND SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 19440,
      "TXT_FINANCIER_NAME": "SRI SHIVAYOGI PATHINA SAHAKARA SANGA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 19441,
      "TXT_FINANCIER_NAME": "SHREE VARDHAMAN NAGARI SAH PATHSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19442,
      "TXT_FINANCIER_NAME": "SARDARGUNJ MERCANTILE CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19443,
      "TXT_FINANCIER_NAME": "BANGANGA NAGARI SAH PAT SAN MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19444,
      "TXT_FINANCIER_NAME": "PURASAWALKAM CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19445,
      "TXT_FINANCIER_NAME": "Raigad District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19446,
      "TXT_FINANCIER_NAME": "RAIGAD SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19447,
      "TXT_FINANCIER_NAME": "Raiganj District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19448,
      "TXT_FINANCIER_NAME": "RAJARAMBAPU SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19449,
      "TXT_FINANCIER_NAME": "RAJDHANI NAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19450,
      "TXT_FINANCIER_NAME": "RAJKOT NAGRIK SAHAKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19451,
      "TXT_FINANCIER_NAME": "RAJKOT PEOPLES CO-OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19452,
      "TXT_FINANCIER_NAME": "RAMPUR ZILA SAHKARI BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19453,
      "TXT_FINANCIER_NAME": "RANI LAXMIBAI URBAN CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19454,
      "TXT_FINANCIER_NAME": "RESERVE BANK EMPLOYEES CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19455,
      "TXT_FINANCIER_NAME": "RIGHT LEASING & HIRE PURCHASE CO.LTD"
    },
    {
      "NUM_FINANCIER_CD": 19456,
      "TXT_FINANCIER_NAME": "Ropar District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19457,
      "TXT_FINANCIER_NAME": "RUSHIKA MAHILA NAGRIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20894,
      "TXT_FINANCIER_NAME": "LESSOR LEASE PLAN INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20895,
      "TXT_FINANCIER_NAME": "CHATRAPATI SAMBHAJI MAHARAJ SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20896,
      "TXT_FINANCIER_NAME": "KOTHARI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20897,
      "TXT_FINANCIER_NAME": "PARNER GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 20898,
      "TXT_FINANCIER_NAME": "JAMBUT GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20899,
      "TXT_FINANCIER_NAME": "MANISH FINANCIAL SERVICE"
    },
    {
      "NUM_FINANCIER_CD": 20900,
      "TXT_FINANCIER_NAME": "SHRI SHIHORI NAGARIK CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20901,
      "TXT_FINANCIER_NAME": "BHAGINI NIVEDITA CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20902,
      "TXT_FINANCIER_NAME": "CHENNAI METROPOLITAN DEVELOPMENT AUTHORITY"
    },
    {
      "NUM_FINANCIER_CD": 20903,
      "TXT_FINANCIER_NAME": "SHIVAM ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 20904,
      "TXT_FINANCIER_NAME": "SHIMSHA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20905,
      "TXT_FINANCIER_NAME": "SARDAR SINGH NAGRIK SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 20906,
      "TXT_FINANCIER_NAME": "VISHNU AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20907,
      "TXT_FINANCIER_NAME": "DHARMAJ PEOPLES CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20908,
      "TXT_FINANCIER_NAME": "THE TAMILNADU INDUSTRAIL COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20909,
      "TXT_FINANCIER_NAME": "JAIN AUTO AND FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20910,
      "TXT_FINANCIER_NAME": "BALAJI FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20911,
      "TXT_FINANCIER_NAME": "MARUTI GRAMIN BIGARSHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20912,
      "TXT_FINANCIER_NAME": "BALDEV FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20913,
      "TXT_FINANCIER_NAME": "SHRI SIDDHALINGESHWAR MULTIPURPOSE SOUHARD SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20914,
      "TXT_FINANCIER_NAME": "PATIDAR NAGARI SAHAKARI PATHSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20915,
      "TXT_FINANCIER_NAME": "SUKH CHAIN HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20916,
      "TXT_FINANCIER_NAME": "SHREE LAD CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20917,
      "TXT_FINANCIER_NAME": "SAWAI MADHOPUR KENDRIYA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20918,
      "TXT_FINANCIER_NAME": "JAI LAXMI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20919,
      "TXT_FINANCIER_NAME": "SHREE KHERVA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20920,
      "TXT_FINANCIER_NAME": "ULTRA FINANCE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20921,
      "TXT_FINANCIER_NAME": "RESERVE BANK EMPLOYESS CO OP BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20922,
      "TXT_FINANCIER_NAME": "LUBRIZOL INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20923,
      "TXT_FINANCIER_NAME": "THE COMPTROLLER UNIVERSITY OF AGRIL SCIENCE"
    },
    {
      "NUM_FINANCIER_CD": 20924,
      "TXT_FINANCIER_NAME": "IREDA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20925,
      "TXT_FINANCIER_NAME": "HEUBACH COLOUR PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20926,
      "TXT_FINANCIER_NAME": "SHUBH CREDIT FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20927,
      "TXT_FINANCIER_NAME": "KUMUTHAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20928,
      "TXT_FINANCIER_NAME": "VICKY BHANDARI"
    },
    {
      "NUM_FINANCIER_CD": 20929,
      "TXT_FINANCIER_NAME": "HIMGIRI AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20930,
      "TXT_FINANCIER_NAME": "NEW BAKROL BUJRANG SEVA SAHAKARI MANDLI"
    },
    {
      "NUM_FINANCIER_CD": 20931,
      "TXT_FINANCIER_NAME": "ADHAR SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20932,
      "TXT_FINANCIER_NAME": "THE MOGAVEERA CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20933,
      "TXT_FINANCIER_NAME": "THE BUNDI CENTRAL CO OPRETIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20934,
      "TXT_FINANCIER_NAME": "PRATAP SHREE FINANCIAL CO"
    },
    {
      "NUM_FINANCIER_CD": 20935,
      "TXT_FINANCIER_NAME": "THE CENTRAL CO OPRATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20936,
      "TXT_FINANCIER_NAME": "THE FATEHGARH SAHIB CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20937,
      "TXT_FINANCIER_NAME": "SHIPPING CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20938,
      "TXT_FINANCIER_NAME": "THE LIMBASI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20939,
      "TXT_FINANCIER_NAME": "AP POLLUTION CONTROL BOARD"
    },
    {
      "NUM_FINANCIER_CD": 20940,
      "TXT_FINANCIER_NAME": "NORTH EAST ENTERPRISES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20941,
      "TXT_FINANCIER_NAME": "GOVIND AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20942,
      "TXT_FINANCIER_NAME": "SARVA UP GRAMIN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 20943,
      "TXT_FINANCIER_NAME": "GENERAL FINANCE AND HIRE PURCHASE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20944,
      "TXT_FINANCIER_NAME": "THE KOTTAYAM CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20945,
      "TXT_FINANCIER_NAME": "UNITED FINANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 20946,
      "TXT_FINANCIER_NAME": "CHOLAMANDALAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20947,
      "TXT_FINANCIER_NAME": "THE HOSDURG SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20948,
      "TXT_FINANCIER_NAME": "D Y PATIL SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20949,
      "TXT_FINANCIER_NAME": "BHATPARA NAIHATI COOPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20950,
      "TXT_FINANCIER_NAME": "SHAKUNI GANESH NAGARI SAHKARI PATH SANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20951,
      "TXT_FINANCIER_NAME": "REPCO HOME FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20952,
      "TXT_FINANCIER_NAME": "RANAGHAT PEOPLES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20953,
      "TXT_FINANCIER_NAME": "SIDDHI VINAYAK ENTERPRISE"
    },
    {
      "NUM_FINANCIER_CD": 20954,
      "TXT_FINANCIER_NAME": "THE PANDHARPUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20955,
      "TXT_FINANCIER_NAME": "SAMRUDDHA JEEVAN MULTI STATE MULTI PURPOSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20956,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA MANTRALAY AND ALLIED OFFICES CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 20957,
      "TXT_FINANCIER_NAME": "NAVKAR ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 20958,
      "TXT_FINANCIER_NAME": "THE BARAMULLA CENTRAL CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20959,
      "TXT_FINANCIER_NAME": "MAHALAXMI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20960,
      "TXT_FINANCIER_NAME": "MANOJ KUMAR JAIN AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20961,
      "TXT_FINANCIER_NAME": "SUBHASH FINANCE SYNDICATE"
    },
    {
      "NUM_FINANCIER_CD": 20962,
      "TXT_FINANCIER_NAME": "THE KERALA STATE CO OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 20963,
      "TXT_FINANCIER_NAME": "SHARAD NAGARI SAHAKARI PATSANSHTA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20964,
      "TXT_FINANCIER_NAME": "TOP GAIN FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20965,
      "TXT_FINANCIER_NAME": "RAJGARHIA AUTO FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20966,
      "TXT_FINANCIER_NAME": "HINDUJA FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 20967,
      "TXT_FINANCIER_NAME": "UTI ASSET MANAGEMENT COMPANY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20968,
      "TXT_FINANCIER_NAME": "SHRI JAGADGURU PANCHACHARYAHA VIVEDODDESHAGALA SAHAKARI SANGHA NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 20969,
      "TXT_FINANCIER_NAME": "SHRI SHARANABASAVESHWAR CREDIT SOUHARD SAHAKAR NIYAMIT HALINGALI"
    },
    {
      "NUM_FINANCIER_CD": 20970,
      "TXT_FINANCIER_NAME": "PAWAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20971,
      "TXT_FINANCIER_NAME": "SHRI BASAVESHWAR PATTIN SAHAKARA SANGHA NI"
    },
    {
      "NUM_FINANCIER_CD": 20972,
      "TXT_FINANCIER_NAME": "VASANTRAO PATIL KAKADE GRAMIN BIGER SHETI SAHKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 20973,
      "TXT_FINANCIER_NAME": "FINANCIAL SERVICES TEAM"
    },
    {
      "NUM_FINANCIER_CD": 20974,
      "TXT_FINANCIER_NAME": "RASHTRASANT TUKDOJI MAHARAJ URBAN CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 20975,
      "TXT_FINANCIER_NAME": "ANDHRA PRADESH TECHNOLOGY SERVICES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 20976,
      "TXT_FINANCIER_NAME": "ARIHANT CREDIT CORPORATION SOWCARPET"
    },
    {
      "NUM_FINANCIER_CD": 20977,
      "TXT_FINANCIER_NAME": "SATYANARAYAN KAMAL KUMAR"
    },
    {
      "NUM_FINANCIER_CD": 20978,
      "TXT_FINANCIER_NAME": "HOWRAH DISTRICT CO OPERATIVE AGRICULTURE AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20979,
      "TXT_FINANCIER_NAME": "THE UDGIR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20980,
      "TXT_FINANCIER_NAME": "GE CAPITAL SERVICES INDIA"
    },
    {
      "NUM_FINANCIER_CD": 20981,
      "TXT_FINANCIER_NAME": "THE ACCOUNTANT GENERALS OFFICE EMPLOYEES CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20982,
      "TXT_FINANCIER_NAME": "SHREE LAXMI CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20983,
      "TXT_FINANCIER_NAME": "VIDHARBHA KONKAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 20984,
      "TXT_FINANCIER_NAME": "TRAVELON SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 20985,
      "TXT_FINANCIER_NAME": "DELHI CO OPERATIVE HOUSING FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 20986,
      "TXT_FINANCIER_NAME": "CHHATTISGARH HANDICAPED FINANCE AND DEVELOPMENT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20987,
      "TXT_FINANCIER_NAME": "KETHAKI SANGAMESHWARA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 20988,
      "TXT_FINANCIER_NAME": "MADANI CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20989,
      "TXT_FINANCIER_NAME": "HARVINDER MOTOR FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 18008,
      "TXT_FINANCIER_NAME": "MADHESHWARI URBAN DEVELOPMENT CO-OP BANK LTD,MADHA"
    },
    {
      "NUM_FINANCIER_CD": 18009,
      "TXT_FINANCIER_NAME": "KERALA STATE INDUSTRIAL ENTERPRISES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18010,
      "TXT_FINANCIER_NAME": "KERALA STATE INDUSTRIAL ENTERPRISES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18011,
      "TXT_FINANCIER_NAME": "the haryana state co-operative apex bank ltd"
    },
    {
      "NUM_FINANCIER_CD": 18012,
      "TXT_FINANCIER_NAME": "THE YARGATTI URBAN CO-OP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18013,
      "TXT_FINANCIER_NAME": "THE MAHAVEER CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18014,
      "TXT_FINANCIER_NAME": "DHULE VIKAS SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18015,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE. LTD"
    },
    {
      "NUM_FINANCIER_CD": 18016,
      "TXT_FINANCIER_NAME": "UCO BANK."
    },
    {
      "NUM_FINANCIER_CD": 18017,
      "TXT_FINANCIER_NAME": "NKGSB CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18018,
      "TXT_FINANCIER_NAME": "THE MAYANI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18019,
      "TXT_FINANCIER_NAME": "ASHOK LEYLAND FINANCE - DIVISION OF INDUSIND BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18020,
      "TXT_FINANCIER_NAME": "CITI CORP FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18021,
      "TXT_FINANCIER_NAME": "JIJAU COMMERCIAL CO-OPERATIVE BANK LID"
    },
    {
      "NUM_FINANCIER_CD": 18022,
      "TXT_FINANCIER_NAME": "THE JANALAXMI CO OP.BANK LTD.NASHIK"
    },
    {
      "NUM_FINANCIER_CD": 18023,
      "TXT_FINANCIER_NAME": "THE GODHRA CITY CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18024,
      "TXT_FINANCIER_NAME": "INDORE PARASPAR SAHAKATI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18025,
      "TXT_FINANCIER_NAME": "THE RADDI SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 18026,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FIN.LTD."
    },
    {
      "NUM_FINANCIER_CD": 18027,
      "TXT_FINANCIER_NAME": "THE SOUTH MALABAR GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18028,
      "TXT_FINANCIER_NAME": "MAHILA CO OP NAGARIK BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18029,
      "TXT_FINANCIER_NAME": "MANDYA DISTRICT CO-OPERATIVE BANK"
    },
    {
      "NUM_FINANCIER_CD": 18030,
      "TXT_FINANCIER_NAME": "LOKNETE SHANKARRAO BALAJI WAJE GRAMIN BIGARSETI SAH.PAT.MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18031,
      "TXT_FINANCIER_NAME": "PUNE PEOPLES CO OP BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18032,
      "TXT_FINANCIER_NAME": "HARYANA STATE INDL AND INFRASTRUCTURE DEV CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 18033,
      "TXT_FINANCIER_NAME": "mahindra and mahindra fin ltd"
    },
    {
      "NUM_FINANCIER_CD": 18034,
      "TXT_FINANCIER_NAME": "PUNE URBAN CO OPERATIVE BANK LTD PUNE"
    },
    {
      "NUM_FINANCIER_CD": 18035,
      "TXT_FINANCIER_NAME": "SOLAPUR NAGARI AUDYOGIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18036,
      "TXT_FINANCIER_NAME": "CITI CORP FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18037,
      "TXT_FINANCIER_NAME": "SHREE SHRAMDEEP ARBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18038,
      "TXT_FINANCIER_NAME": "ANSHU LEASING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 18039,
      "TXT_FINANCIER_NAME": "MALAPPURAM DIST CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 18040,
      "TXT_FINANCIER_NAME": "SIR M VISHWESHWARAIAH SAHAKAR BANK NIYAMITHA GULBARGA"
    },
    {
      "NUM_FINANCIER_CD": 18041,
      "TXT_FINANCIER_NAME": "STOCK HOLDING CORPORATION OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18042,
      "TXT_FINANCIER_NAME": "ARIHANT CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18043,
      "TXT_FINANCIER_NAME": "KADIRUR SERVICE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18044,
      "TXT_FINANCIER_NAME": "CITICORP MARUTI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 18045,
      "TXT_FINANCIER_NAME": "GANDHINAGAR NAGCOOP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18046,
      "TXT_FINANCIER_NAME": "JANHIT NAGARI SAHAKARI PATSANATHA"
    },
    {
      "NUM_FINANCIER_CD": 18047,
      "TXT_FINANCIER_NAME": "MARUTI COUNTRY A F S LTD"
    },
    {
      "NUM_FINANCIER_CD": 18048,
      "TXT_FINANCIER_NAME": "PRATHAMIK SHIKSHAK SAHAKRI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18049,
      "TXT_FINANCIER_NAME": "Pune District Central Co OpBank Ltd Pune"
    },
    {
      "NUM_FINANCIER_CD": 18050,
      "TXT_FINANCIER_NAME": "KURMANCHAL NAGAR SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18051,
      "TXT_FINANCIER_NAME": "ANAND NAGARIK CO OP CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 18052,
      "TXT_FINANCIER_NAME": "THE KERALA STATE FINANCIAL ENTERPRISES LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 18053,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA BATAWADI BRANCH TUMKUR"
    },
    {
      "NUM_FINANCIER_CD": 18054,
      "TXT_FINANCIER_NAME": "AWANTHI ENTERPRISES"
    },
    {
      "NUM_FINANCIER_CD": 18055,
      "TXT_FINANCIER_NAME": "LESSOR ORIX AUTO INFRASTRUCTURE SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 18056,
      "TXT_FINANCIER_NAME": "THE RAJASTHAN GRAMIN BANK"
    },
    {
      "NUM_FINANCIER_CD": 18057,
      "TXT_FINANCIER_NAME": "Himachal Mitra Mandal Co operative Credit Society Ltd"
    },
    {
      "NUM_FINANCIER_CD": 18058,
      "TXT_FINANCIER_NAME": "ADARSHA PATTANA SOUHARDHA SAHAKARI BANK NYIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 18059,
      "TXT_FINANCIER_NAME": "MARUTI SUZUKI INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18060,
      "TXT_FINANCIER_NAME": "SRI RAM TRANSPORT FINANCE CO LTD UDUPI"
    },
    {
      "NUM_FINANCIER_CD": 18061,
      "TXT_FINANCIER_NAME": "SRIRAM TRANSPORT FINANCE CO LTD SHIMOGA"
    },
    {
      "NUM_FINANCIER_CD": 18062,
      "TXT_FINANCIER_NAME": "INSTITUTE OF CHARETERED ACCOUNTANTS OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 18063,
      "TXT_FINANCIER_NAME": "POWER GRID CORP OF INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 18064,
      "TXT_FINANCIER_NAME": "THE MYSORE MERCHANT CO OPERATIVE BANKI"
    },
    {
      "NUM_FINANCIER_CD": 18065,
      "TXT_FINANCIER_NAME": "GOVERDHAN FINANCIERS"
    },
    {
      "NUM_FINANCIER_CD": 18066,
      "TXT_FINANCIER_NAME": "BANK OF MAHARASTRA"
    },
    {
      "NUM_FINANCIER_CD": 18067,
      "TXT_FINANCIER_NAME": "VIKAS URBAN CO OP BANK NIYAMITH"
    },
    {
      "NUM_FINANCIER_CD": 18068,
      "TXT_FINANCIER_NAME": "BANK OF BAROBA"
    },
    {
      "NUM_FINANCIER_CD": 18069,
      "TXT_FINANCIER_NAME": "THE ANKOLA URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18070,
      "TXT_FINANCIER_NAME": "KARAD MERCHANT CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18071,
      "TXT_FINANCIER_NAME": "SANT NARHARI MAHARAJ NAGARI SAHAKARI PAT SANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 18072,
      "TXT_FINANCIER_NAME": "DADASAHEB Dr.N.M.KABRE NAG.SAH. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18073,
      "TXT_FINANCIER_NAME": "CITIZEN CO-OPERATIVE BANK LTD.NOIDA"
    },
    {
      "NUM_FINANCIER_CD": 18074,
      "TXT_FINANCIER_NAME": "KARNATAKA VIKAS GRAMEEN BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18075,
      "TXT_FINANCIER_NAME": "BHAICHAND HIRACHAND RAISONI PATPEDHI."
    },
    {
      "NUM_FINANCIER_CD": 18076,
      "TXT_FINANCIER_NAME": "TIRUPATI URBAN CO. OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18077,
      "TXT_FINANCIER_NAME": "THE KOKAN PRANT SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18078,
      "TXT_FINANCIER_NAME": "THE GODHRA CITY CO.OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18079,
      "TXT_FINANCIER_NAME": "JAYSINGPUR-UDGAON SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18080,
      "TXT_FINANCIER_NAME": "BULDANA URBAN CO. OP CREDIT SOCY. LTD. BULDANA"
    },
    {
      "NUM_FINANCIER_CD": 18081,
      "TXT_FINANCIER_NAME": "BULDANA URBAN CO OP CREDIT SOCY LTD BULDANA"
    },
    {
      "NUM_FINANCIER_CD": 18082,
      "TXT_FINANCIER_NAME": "NAVABHARAT CO OP URBAN BANK LYD"
    },
    {
      "NUM_FINANCIER_CD": 18083,
      "TXT_FINANCIER_NAME": "JILA SAHKARI KENDRIYA BANK MARYADIT MANDLA"
    },
    {
      "NUM_FINANCIER_CD": 18084,
      "TXT_FINANCIER_NAME": "ASHOK LEYLAND FINANACE"
    },
    {
      "NUM_FINANCIER_CD": 18085,
      "TXT_FINANCIER_NAME": "THE LUNAWADA NAGRIK SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18086,
      "TXT_FINANCIER_NAME": "BANK OF INDIA."
    },
    {
      "NUM_FINANCIER_CD": 18087,
      "TXT_FINANCIER_NAME": "SHREE SARDAR PATEL CO. OP. CREDIT SOC. LTD."
    },
    {
      "NUM_FINANCIER_CD": 18088,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINANCE SERVICE LTD."
    },
    {
      "NUM_FINANCIER_CD": 18089,
      "TXT_FINANCIER_NAME": "THE DISTRICT COOPERATIVE CENTRAL BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18090,
      "TXT_FINANCIER_NAME": "MANGAON NAGARI SAHAKARI PATSANSTHA MAARYADIT MANGAON"
    },
    {
      "NUM_FINANCIER_CD": 18091,
      "TXT_FINANCIER_NAME": "CENTURION BANK OF PUNJAB LIMITED."
    },
    {
      "NUM_FINANCIER_CD": 18092,
      "TXT_FINANCIER_NAME": "ARIHANT GRAMIN BIGRSHETI SAHAKARI PATHANSATHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 18093,
      "TXT_FINANCIER_NAME": "THE RADHASOAMI URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18094,
      "TXT_FINANCIER_NAME": "THE TUNGABHADRA CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 18095,
      "TXT_FINANCIER_NAME": "GULABCHAND PAGARIA AND SONS"
    },
    {
      "NUM_FINANCIER_CD": 18096,
      "TXT_FINANCIER_NAME": "SRI PADMAVATHI AUTO FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 18097,
      "TXT_FINANCIER_NAME": "BANDA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 18098,
      "TXT_FINANCIER_NAME": "SARJERAODADA NAIK SHIRALA SAHAKARI BANK LTD SHIRALA"
    },
    {
      "NUM_FINANCIER_CD": 18099,
      "TXT_FINANCIER_NAME": "SIDDHESHWAR URBAN CO-OP.BANK MARYADIT ."
    },
    {
      "NUM_FINANCIER_CD": 18100,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 18101,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT"
    },
    {
      "NUM_FINANCIER_CD": 18102,
      "TXT_FINANCIER_NAME": "MARUTI UDYOG LTD"
    },
    {
      "NUM_FINANCIER_CD": 19458,
      "TXT_FINANCIER_NAME": "SACHIN INDUSTRIAL CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19459,
      "TXT_FINANCIER_NAME": "SADALGA URBAN SOUHARDA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 19460,
      "TXT_FINANCIER_NAME": "SADGURU NAGRIK SAHAKARI BANK MGDT"
    },
    {
      "NUM_FINANCIER_CD": 19461,
      "TXT_FINANCIER_NAME": "SAINIK SAHAKARI BANK LTD., SATARA."
    },
    {
      "NUM_FINANCIER_CD": 19462,
      "TXT_FINANCIER_NAME": "LOKMANYA MULTIPURPOPSE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19463,
      "TXT_FINANCIER_NAME": "MANICKPUR URBAN CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 19464,
      "TXT_FINANCIER_NAME": "SHREE AGRASEN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19465,
      "TXT_FINANCIER_NAME": "DEWAN HOUSING FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19466,
      "TXT_FINANCIER_NAME": "KONE ELEVATOR INDIA PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19467,
      "TXT_FINANCIER_NAME": "The Kottayam Co Operative Urban Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19468,
      "TXT_FINANCIER_NAME": "THE KOUJALGI URBAN COOP CREDIT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19469,
      "TXT_FINANCIER_NAME": "THE KOZHIKODE DISTRICT CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19470,
      "TXT_FINANCIER_NAME": "THE KURMANCHAL NAGAR SAHKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19471,
      "TXT_FINANCIER_NAME": "THE LASALGAON MERCHANTS CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19472,
      "TXT_FINANCIER_NAME": "THE LATUR URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19473,
      "TXT_FINANCIER_NAME": "THE MADHAVPURA MERCANTILE CO.OP.BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19474,
      "TXT_FINANCIER_NAME": "THE MAHABALESHWAR URBAN CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19475,
      "TXT_FINANCIER_NAME": "THE MAHANAGAR CO-OPERATIVE URBAN BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19476,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA STATE CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19477,
      "TXT_FINANCIER_NAME": "THE MAHARASHTRA STATE CO-OPERATIVE BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19478,
      "TXT_FINANCIER_NAME": "THE MAHILA SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19479,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO OP BANK LTD,"
    },
    {
      "NUM_FINANCIER_CD": 19480,
      "TXT_FINANCIER_NAME": "THE MAKARPURA INDUSTRIAL ESTATE CO.OP.BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19481,
      "TXT_FINANCIER_NAME": "THE MALAPPURAM DISTRICT CO-OPERATIVE BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19482,
      "TXT_FINANCIER_NAME": "YASHOMANDIR SAH PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 19483,
      "TXT_FINANCIER_NAME": "SARVODAY KHEDUT SEVA SAHKARI MANDLI LTD"
    },
    {
      "NUM_FINANCIER_CD": 19484,
      "TXT_FINANCIER_NAME": "THE BIHAR STATE CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19485,
      "TXT_FINANCIER_NAME": "VISHAL JUNNAR SAHAKARI PATPEDHI LTD"
    },
    {
      "NUM_FINANCIER_CD": 19486,
      "TXT_FINANCIER_NAME": "THE NASIK DISTRICT CENTRAL COOPERATIVE BANK LTD NASIK"
    },
    {
      "NUM_FINANCIER_CD": 19487,
      "TXT_FINANCIER_NAME": "MADHYA PRADESH FINANCIAL CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 19488,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD."
    },
    {
      "NUM_FINANCIER_CD": 19489,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD & PNB & ALL MEMBER BANKS OF CONSORTIUM"
    },
    {
      "NUM_FINANCIER_CD": 19490,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 19491,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD.,KOLKATA,PNB, & ALL OTHER CONSORTIUM BANK"
    },
    {
      "NUM_FINANCIER_CD": 19492,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANACE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19493,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD PNB & ALL OTHER CONS BANK"
    },
    {
      "NUM_FINANCIER_CD": 19494,
      "TXT_FINANCIER_NAME": "MAGMA FINCORP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19495,
      "TXT_FINANCIER_NAME": "MAGMA FINANCE CORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 19496,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19497,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LTD AND PNB AND OCMB"
    },
    {
      "NUM_FINANCIER_CD": 19498,
      "TXT_FINANCIER_NAME": "MAGMA SHARACHI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 19499,
      "TXT_FINANCIER_NAME": "magma shrachi finance"
    },
    {
      "NUM_FINANCIER_CD": 19500,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FIN LTD"
    },
    {
      "NUM_FINANCIER_CD": 19501,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD PNB AND ALL OTHERS CONST. BANKS"
    },
    {
      "NUM_FINANCIER_CD": 19502,
      "TXT_FINANCIER_NAME": "MAGMA SHARACHI FINANCE LTD."
    },
    {
      "NUM_FINANCIER_CD": 19503,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 19504,
      "TXT_FINANCIER_NAME": "MAGMA LEASING LTD AND PNB AND ALL MEMBERS CONSORTIUM BANK"
    },
    {
      "NUM_FINANCIER_CD": 19505,
      "TXT_FINANCIER_NAME": "MAGMA FINCORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 19506,
      "TXT_FINANCIER_NAME": "MAGMA SHRACHI FINANCE LTD PNB AND ALL O C M B"
    },
    {
      "NUM_FINANCIER_CD": 19507,
      "TXT_FINANCIER_NAME": "MAHAJAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 19508,
      "TXT_FINANCIER_NAME": "MAHINDRA & MAHINDRA FINANCIAL SERVISES LTD."
    },
    {
      "NUM_FINANCIER_CD": 19509,
      "TXT_FINANCIER_NAME": "FEDRAL BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19510,
      "TXT_FINANCIER_NAME": "THE DHULE AND NANDURBAR CENTRAL CO OP BANK LTD DHULE"
    },
    {
      "NUM_FINANCIER_CD": 19511,
      "TXT_FINANCIER_NAME": "RURAL ELECTRIFICATION CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 19512,
      "TXT_FINANCIER_NAME": "Shriram Finance Pvt Ltd"
    },
    {
      "NUM_FINANCIER_CD": 19513,
      "TXT_FINANCIER_NAME": "GAUTAM FINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 19514,
      "TXT_FINANCIER_NAME": "P SANGEETHA DEVI"
    },
    {
      "NUM_FINANCIER_CD": 19515,
      "TXT_FINANCIER_NAME": "YES BANK"
    },
    {
      "NUM_FINANCIER_CD": 19516,
      "TXT_FINANCIER_NAME": "PUNJAB AND SINDH BANK"
    },
    {
      "NUM_FINANCIER_CD": 19517,
      "TXT_FINANCIER_NAME": "PUNJAB NATIONAL AND SIND BANK"
    },
    {
      "NUM_FINANCIER_CD": 19518,
      "TXT_FINANCIER_NAME": "PUNJAB NATIONAL BANK GILTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 19519,
      "TXT_FINANCIER_NAME": "PUNJAB NATIONAL BANK,"
    },
    {
      "NUM_FINANCIER_CD": 19520,
      "TXT_FINANCIER_NAME": "PUNJAB NATIONAL BANK H A L LUCKNOW"
    },
    {
      "NUM_FINANCIER_CD": 19521,
      "TXT_FINANCIER_NAME": "STATE BANK OF BIKANER & JAIPUR"
    },
    {
      "NUM_FINANCIER_CD": 19522,
      "TXT_FINANCIER_NAME": "STATE BANK OF BIKANER ANE JAIPUR"
    },
    {
      "NUM_FINANCIER_CD": 19523,
      "TXT_FINANCIER_NAME": "STAT BANK OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 19524,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA PERSONNAL"
    },
    {
      "NUM_FINANCIER_CD": 19525,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA BR WASHIM"
    },
    {
      "NUM_FINANCIER_CD": 19526,
      "TXT_FINANCIER_NAME": "State Bank of India Grain Market Karnal"
    },
    {
      "NUM_FINANCIER_CD": 19527,
      "TXT_FINANCIER_NAME": "STATE BANK OF INDIA SWARGASHRAM RISHIKESH"
    },
    {
      "NUM_FINANCIER_CD": 19528,
      "TXT_FINANCIER_NAME": "SRINIDHI SOUHARDHA SAHAKARI BANK NIYAMITHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 19529,
      "TXT_FINANCIER_NAME": "CENTRAL GODAVARI KRUSHAK SEVA SAH SANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19530,
      "TXT_FINANCIER_NAME": "UNITED INDIA INSURANCE COMPANY"
    },
    {
      "NUM_FINANCIER_CD": 19531,
      "TXT_FINANCIER_NAME": "MAGMA FINCORP LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 19532,
      "TXT_FINANCIER_NAME": "SANGLI DISTRICT PRIMARY TEACHERS CO-OPERATIVE BANK LIMITED,SANGLI"
    },
    {
      "NUM_FINANCIER_CD": 19533,
      "TXT_FINANCIER_NAME": "SANGLI URBAN CO -OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19534,
      "TXT_FINANCIER_NAME": "SANGOLA URBAN CO-OP. BANK LTD. SANGOLA"
    },
    {
      "NUM_FINANCIER_CD": 19535,
      "TXT_FINANCIER_NAME": "SANMITRA MAHILA URBAN CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19536,
      "TXT_FINANCIER_NAME": "SANMITRA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19537,
      "TXT_FINANCIER_NAME": "SANMITRA URBAN CO OP BANK LTD AKOLA"
    },
    {
      "NUM_FINANCIER_CD": 19538,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK CO-OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19539,
      "TXT_FINANCIER_NAME": "SARASPUR NAGRIK SAH. BANK"
    },
    {
      "NUM_FINANCIER_CD": 19540,
      "TXT_FINANCIER_NAME": "SARASWAT CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19541,
      "TXT_FINANCIER_NAME": "SARDAR BHILADWALLA PARDI PEOPLES CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19542,
      "TXT_FINANCIER_NAME": "SARDARGANJ MERCANTILE CO-OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 19543,
      "TXT_FINANCIER_NAME": "SARDARGANJ MERCANTILE CO-OP. BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19544,
      "TXT_FINANCIER_NAME": "SATANA MERCHANTS CO-OP BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19545,
      "TXT_FINANCIER_NAME": "Satara District Central Co-operative Bank Ltd."
    },
    {
      "NUM_FINANCIER_CD": 19546,
      "TXT_FINANCIER_NAME": "SATGURU NAGRIK SAHKARI BANK"
    },
    {
      "NUM_FINANCIER_CD": 19547,
      "TXT_FINANCIER_NAME": "SATTARI URBAN CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 19548,
      "TXT_FINANCIER_NAME": "SENAPATI BAPAT NAGRI SAHAKRI P S LTD ANAGAR"
    },
    {
      "NUM_FINANCIER_CD": 19549,
      "TXT_FINANCIER_NAME": "SHIVRAJ SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 19550,
      "TXT_FINANCIER_NAME": "THE SIRSI URBAN SAHAKARI BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 19551,
      "TXT_FINANCIER_NAME": "THE MANDAL NAGARIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19552,
      "TXT_FINANCIER_NAME": "THE MANDVI NAGRIK SAHAKARI BANK LTD."
    },
    {
      "NUM_FINANCIER_CD": 19553,
      "TXT_FINANCIER_NAME": "THE MANMAD URBAN CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20990,
      "TXT_FINANCIER_NAME": "THE MANGALORE CATHOLIC CO OPERATIVE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 20991,
      "TXT_FINANCIER_NAME": "GEO VPL FINANCE P LTD"
    },
    {
      "NUM_FINANCIER_CD": 20992,
      "TXT_FINANCIER_NAME": "YOGAKSHEMAM LOANS LTD"
    },
    {
      "NUM_FINANCIER_CD": 20993,
      "TXT_FINANCIER_NAME": "OM GORAI NAGARI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 20994,
      "TXT_FINANCIER_NAME": "VISHAL JANSEVA SAHAKARI PATH SANTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 20995,
      "TXT_FINANCIER_NAME": "SUPRITENDENT OF POLICE"
    },
    {
      "NUM_FINANCIER_CD": 20996,
      "TXT_FINANCIER_NAME": "VIJAYA LAKSHMI FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 20997,
      "TXT_FINANCIER_NAME": "SUVIDHA CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 20998,
      "TXT_FINANCIER_NAME": "NATIONAL INSURANCE ACADMY"
    },
    {
      "NUM_FINANCIER_CD": 20999,
      "TXT_FINANCIER_NAME": "KHUSHBOO FIN LEASE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21000,
      "TXT_FINANCIER_NAME": "SHRI CHATRAPATI RAJARAM TIMBER SAH PAT SANSTHA MYDT"
    },
    {
      "NUM_FINANCIER_CD": 21001,
      "TXT_FINANCIER_NAME": "VASAVI VIVIDODDESHA SOUHARDA SAHAKARI NI"
    },
    {
      "NUM_FINANCIER_CD": 21002,
      "TXT_FINANCIER_NAME": "SHRIRAM GRAMIN BIGARSHETI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 21003,
      "TXT_FINANCIER_NAME": "NANDOL SEVA SAHKARI MANDALI"
    },
    {
      "NUM_FINANCIER_CD": 21004,
      "TXT_FINANCIER_NAME": "TAXIMENS FINANCE AND LEASING LTD"
    },
    {
      "NUM_FINANCIER_CD": 21005,
      "TXT_FINANCIER_NAME": "AMALGAM"
    },
    {
      "NUM_FINANCIER_CD": 21006,
      "TXT_FINANCIER_NAME": "KUMAR GRAMIN BIGER SHETI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 21007,
      "TXT_FINANCIER_NAME": "FIRSTRAND BANK LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21008,
      "TXT_FINANCIER_NAME": "SHREE OM FINANCE INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21009,
      "TXT_FINANCIER_NAME": "OM FINANCE SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 21010,
      "TXT_FINANCIER_NAME": "NARLAPUR FINANCE AND INVESTMENTS LTD"
    },
    {
      "NUM_FINANCIER_CD": 21011,
      "TXT_FINANCIER_NAME": "SHREE KALBHAIRAV NAGRIK SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21012,
      "TXT_FINANCIER_NAME": "MALEMALLESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21013,
      "TXT_FINANCIER_NAME": "YASHODA SAHAKARI PATPEDHI MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21014,
      "TXT_FINANCIER_NAME": "SHRI PARSHURAM SAH NAGRI PATH"
    },
    {
      "NUM_FINANCIER_CD": 21015,
      "TXT_FINANCIER_NAME": "SAMATA SAHAKARI PATSANSTHA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21016,
      "TXT_FINANCIER_NAME": "SANTHOSH FINANCE CORP"
    },
    {
      "NUM_FINANCIER_CD": 21017,
      "TXT_FINANCIER_NAME": "GOKUL MOTOR FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21018,
      "TXT_FINANCIER_NAME": "KITTUR CHANNAMMA SOUHARDHA PATTINA SAHAKARI NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21019,
      "TXT_FINANCIER_NAME": "GUJARAT STATE CO OP AGRI AND RURAL DEVLOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21020,
      "TXT_FINANCIER_NAME": "ARMY GROUP INSURANCE"
    },
    {
      "NUM_FINANCIER_CD": 21021,
      "TXT_FINANCIER_NAME": "THE KERALA STATE MINORITIES DEVELOPMENT FINANCE CORPORATION LTD"
    },
    {
      "NUM_FINANCIER_CD": 21022,
      "TXT_FINANCIER_NAME": "SHARDA GRAMIN BIGAR SHETI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21023,
      "TXT_FINANCIER_NAME": "NEELKANTH CO OP CREDIT SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 21024,
      "TXT_FINANCIER_NAME": "SHRI LAXMIDEVI VIVIDODDESHAGALA SOUHARDHA SAHAKARI NIYAMIT HOSUR"
    },
    {
      "NUM_FINANCIER_CD": 21025,
      "TXT_FINANCIER_NAME": "SHREE KAMREJ VIBHAG NAGRIK BACHAT AND DHIRAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21026,
      "TXT_FINANCIER_NAME": "MAKINO INDIA PRIVATE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21027,
      "TXT_FINANCIER_NAME": "PRATHAMIKA KRUSHI PATTINA SAHAKARI BANK NIYAMIT"
    },
    {
      "NUM_FINANCIER_CD": 21028,
      "TXT_FINANCIER_NAME": "PTC INDIA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21029,
      "TXT_FINANCIER_NAME": "PARISHRAM CREDIT CO OPP SOC"
    },
    {
      "NUM_FINANCIER_CD": 21030,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURE CO OPERATIVE CREDIT SOCIETY"
    },
    {
      "NUM_FINANCIER_CD": 21031,
      "TXT_FINANCIER_NAME": "DAIMLER FINANCIAL SERVICES INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21032,
      "TXT_FINANCIER_NAME": "SRI LAKSHMI SARASWATHI FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21033,
      "TXT_FINANCIER_NAME": "COLGATE GLOBAL BUSINESS SERVICES PRIVATE LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21034,
      "TXT_FINANCIER_NAME": "CITI FISCAL SERVICES P LTD"
    },
    {
      "NUM_FINANCIER_CD": 21035,
      "TXT_FINANCIER_NAME": "INDUSTRIAL CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21036,
      "TXT_FINANCIER_NAME": "PARAVUR TALUK CO OPERATIVE AGRICULTURAL AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21037,
      "TXT_FINANCIER_NAME": "MIZORAM URBAN COOPERATIVE DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21038,
      "TXT_FINANCIER_NAME": "GLOBAL ASSETS LEASING AND FINANCE PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21039,
      "TXT_FINANCIER_NAME": "DNYANDEEP CO OP PATHSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 21040,
      "TXT_FINANCIER_NAME": "FAIRDEAL FINANCE CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21041,
      "TXT_FINANCIER_NAME": "PADMA PUJITHA AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21042,
      "TXT_FINANCIER_NAME": "SRI ANANTHAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21043,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURAL CREDIT CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21044,
      "TXT_FINANCIER_NAME": "INDIA INFRASTRUCTURE FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 21045,
      "TXT_FINANCIER_NAME": "SINDGI URBAN CO OP BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21046,
      "TXT_FINANCIER_NAME": "BANKURA DIST CO OP AGRI AND RURAL DEVELOPMENT BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21047,
      "TXT_FINANCIER_NAME": "SAMATA NAGARI SAHAKARI PATSANSTHA LIMITED"
    },
    {
      "NUM_FINANCIER_CD": 21048,
      "TXT_FINANCIER_NAME": "ELLORA GOLD AND CHITS"
    },
    {
      "NUM_FINANCIER_CD": 21049,
      "TXT_FINANCIER_NAME": "RIIDHII KRUPA"
    },
    {
      "NUM_FINANCIER_CD": 21050,
      "TXT_FINANCIER_NAME": "PRIMARY AGRICULTURE CO OP SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21051,
      "TXT_FINANCIER_NAME": "BHANDUP SAHAKARI PAT SANSTHA MARYADIT MUMBAI"
    },
    {
      "NUM_FINANCIER_CD": 21052,
      "TXT_FINANCIER_NAME": "LAXMI INVESTOR"
    },
    {
      "NUM_FINANCIER_CD": 21053,
      "TXT_FINANCIER_NAME": "MERCHANTS CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21054,
      "TXT_FINANCIER_NAME": "SHRI PARSHWANATH MINORITY SOUHARD CO OP CREDIT LDT TAMADADDI"
    },
    {
      "NUM_FINANCIER_CD": 21055,
      "TXT_FINANCIER_NAME": "KARVY FINANCIAL SERVICES LTD"
    },
    {
      "NUM_FINANCIER_CD": 21056,
      "TXT_FINANCIER_NAME": "THE BHARAT CO OPERATIVE TC SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21057,
      "TXT_FINANCIER_NAME": "ALANG CREDITS AND HOLDING PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21058,
      "TXT_FINANCIER_NAME": "SRI GOKARNANATHESWARA CO OP BANK"
    },
    {
      "NUM_FINANCIER_CD": 21059,
      "TXT_FINANCIER_NAME": "THE INSTITUTE OF CHARTERED ACCOUNTANTS OF INDIA"
    },
    {
      "NUM_FINANCIER_CD": 21060,
      "TXT_FINANCIER_NAME": "SHUBHAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21061,
      "TXT_FINANCIER_NAME": "GRAMEEN VIKAS BANK"
    },
    {
      "NUM_FINANCIER_CD": 21062,
      "TXT_FINANCIER_NAME": "SHREE KAMLESHWAR CO OP CREDIT SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21063,
      "TXT_FINANCIER_NAME": "PEE JAY FINANCE CO LTD"
    },
    {
      "NUM_FINANCIER_CD": 21064,
      "TXT_FINANCIER_NAME": "MALAPPURAM DISTRICT COPERATIVE MOTOR VEHICLE LIMTED NOM12 UPHILL MALAPPURAM"
    },
    {
      "NUM_FINANCIER_CD": 21065,
      "TXT_FINANCIER_NAME": "DHARAM FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21066,
      "TXT_FINANCIER_NAME": "SRI NATHI AUTO FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21067,
      "TXT_FINANCIER_NAME": "GADHINGLAJ VYAPARI NAGARI SAH PAT SANSTHA MAR"
    },
    {
      "NUM_FINANCIER_CD": 21068,
      "TXT_FINANCIER_NAME": "DELHI CO OPERATIVE HOUSINGFINANCE LTD"
    },
    {
      "NUM_FINANCIER_CD": 21069,
      "TXT_FINANCIER_NAME": "GE CAPITAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21070,
      "TXT_FINANCIER_NAME": "LUBRIZOL INDIA PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21071,
      "TXT_FINANCIER_NAME": "AWANTHI ASSOCIATES"
    },
    {
      "NUM_FINANCIER_CD": 21072,
      "TXT_FINANCIER_NAME": "MAHINDRA AND MAHINDRA FINCORP LTD"
    },
    {
      "NUM_FINANCIER_CD": 21073,
      "TXT_FINANCIER_NAME": "NAHAR FINANCIAL SERVICES"
    },
    {
      "NUM_FINANCIER_CD": 21074,
      "TXT_FINANCIER_NAME": "SHRI PARSHURAM NAG SAH PATH SANSTA LTD"
    },
    {
      "NUM_FINANCIER_CD": 21075,
      "TXT_FINANCIER_NAME": "JANALAKSHMI FINANCIAL SERVICES PVT LTD"
    },
    {
      "NUM_FINANCIER_CD": 21076,
      "TXT_FINANCIER_NAME": "SRI KUMARAN FINANCE"
    },
    {
      "NUM_FINANCIER_CD": 21077,
      "TXT_FINANCIER_NAME": "SHREE VEER TRANSPORT OPARETOR CO OP CREDIT AND SERVICE SOC LTD"
    },
    {
      "NUM_FINANCIER_CD": 21078,
      "TXT_FINANCIER_NAME": "JANAKALYAN SAHAKARI PATHPEDHI"
    },
    {
      "NUM_FINANCIER_CD": 21079,
      "TXT_FINANCIER_NAME": "SHREE KULSWAMI DUDH UTPADAK GRAMIN BIGERSHETHI SAHAKARI PATSANSTHA MARYADIT"
    },
    {
      "NUM_FINANCIER_CD": 21080,
      "TXT_FINANCIER_NAME": "VISHWAKARMA SAHAKARA BANK NIYAMITHA"
    },
    {
      "NUM_FINANCIER_CD": 21081,
      "TXT_FINANCIER_NAME": "SRI CHANNABASAVA PATTINA SOUHARDHA SAHAKARI NIYAMITA"
    },
    {
      "NUM_FINANCIER_CD": 21082,
      "TXT_FINANCIER_NAME": "NATIONAL CREDIT CORPORATION"
    },
    {
      "NUM_FINANCIER_CD": 21083,
      "TXT_FINANCIER_NAME": "SUBHASH BAGHMAR"
    },
    {
      "NUM_FINANCIER_CD": 21084,
      "TXT_FINANCIER_NAME": "OMERGA JANATA SAHAKARI BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21390,
      "TXT_FINANCIER_NAME": "EQUITAS SMALL FINANCE BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21400,
      "TXT_FINANCIER_NAME": "ADARSH CREDIT CO OPERATIVE SOCIETY LTD"
    },
    {
      "NUM_FINANCIER_CD": 21395,
      "TXT_FINANCIER_NAME": "UJJIVAN SMALL FINANCE BANK"
    },
    {
      "NUM_FINANCIER_CD": 21397,
      "TXT_FINANCIER_NAME": "AASHIRWAD NAGARI SAHAKARI PATSANSTHA"
    },
    {
      "NUM_FINANCIER_CD": 17814,
      "TXT_FINANCIER_NAME": "THE KOSAMBA MERCANTILE CO.OP. BANK LTD"
    },
    {
      "NUM_FINANCIER_CD": 21398,
      "TXT_FINANCIER_NAME": "ACC BANK"
    },
    {
      "NUM_FINANCIER_CD": 21486,
      "TXT_FINANCIER_NAME": "HEHI - EQUITAS BANK"
    }
  ]