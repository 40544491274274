import { HttpClient } from "@angular/common/http";
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import * as moment from 'moment';
import { constant } from '../constant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { FormHandlerService } from '../form-handler.service';

declare var $: any;

@Component({
  selector: 'app-pet-insurance',
  templateUrl: './pet-insurance.component.html',
  styleUrls: ['./pet-insurance.component.css']
})
export class PetInsuranceComponent implements OnInit {

  petInsurancePlanData: any[] = [];
  petInsuranceCoverageData: any[] = [];
  petFaqData: any[] = [];

  petData: any = {
    status: 1,
    pet_type: "pet_insurance",
  };
  modalRef: BsModalRef;
  @ViewChild('successmodal', { static: true }) successmodal: BsModalRef
  getQuoteBtn = false;
  isOpen = false;
  f: string;
  base_url: string;
  //util = new Utils;
  formData: any = {}; // Define formData to store form data
  phoneNumber: string = ''; // Define phoneNumber to store the phone number

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ngxLoader: NgxUiLoaderService,
    private formHandlerService: FormHandlerService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, 600);
    this.base_url = environment.api_endpoint;
    this.f = this.route.snapshot.url[0].path;
    this.loadPetData();
  }

  loadPetData() {
    this.http
      .get("assets/companyjson/accordionData.json")
      .subscribe((res: any) => {
        this.petInsurancePlanData = res.petInsurancePlan;
        this.petInsuranceCoverageData = res.petInsuranceCoverage;
        this.petFaqData = res.petFaq;
      });
  }

  petSubmit() {
    var regex = constant.emailvalidateregex;
    var mobilevalidateregex = constant.mobilevalidateregex;
    if (!this.petData.pet_user_name) {
      this.toastr.error("Please enter your name", "Error");
    } else if (!this.petData.pet_user_email) {
      this.toastr.error("Please enter your valid email", "Error");
    } else if (!regex.test(this.petData.pet_user_email)) {
      this.toastr.error("Please enter a valid email address", "Error");
    } else if (!mobilevalidateregex.test(this.petData.pet_user_mobile)) {
      this.toastr.error("Please enter your valid mobile number", "Error");
    } else {
        // This is a temporary alert functionality for the forms
        this.formData = {
          "name": this.petData.pet_user_name,
          "phoneNumber": this.petData.pet_user_mobile,
          "email": this.petData.pet_user_email
        }

        this.formHandlerService.handleFormSubmission(this.formData, 'Pet Insurance');
        return;
      this.getQuoteBtn = true;
      const url = `/insuranceRoute/addpetinsurance/`;
      this.commonService.post(url, this.petData).subscribe(res => {
        //console.log(res)
        this.modalRef = this.modalService.show(this.successmodal, { class: 'common-popup' });
        //this.util.successDialog("Thank You Showing Interest.We Will get Back To You Soon","Success");
        this.getQuoteBtn = false;
        this.petData.pet_user_name = '';
        this.petData.pet_user_email = '';
        this.petData.pet_user_mobile = '';
        this.petData.journy_start_date = '';
      }, err => {
        this.getQuoteBtn = false;
        const errorMessage = err && err.message || 'Something goes wrong';
        this.toastr.error(errorMessage, 'Error');
      })
    }
  }

}
