import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CareEnviroment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { CommonService } from './common.service'
import { tap } from  'rxjs/operators';
import { switchMap } from  'rxjs/operators';
import { catchError } from  'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class CareToken {
  token: any
  kycUrl:string
  constructor(private http: HttpClient,
    private commonService:CommonService ) {}

  sessionId: string;
  generateToken(appID): Observable<any> {
        const api = CareEnviroment.careTokenUrl;
        const timestamp = Date.now().toString();

        const headers = new HttpHeaders({
            appId:appID ,
            signature: CareEnviroment.Signature,
            timestamp: timestamp,
            applicationCD: CareEnviroment.applicationCD,
            "Content-Type": "application/json",
        });

        const body = {
            partnerTokenGeneratorInputIO: {
                partnerId: CareEnviroment.TokenpartnerId,
                securityKey: CareEnviroment.securityKey,
            },
        };

        return this.http.post(api, body, { headers }).pipe(
            tap((response: any) => {
                if (response.responseData.status === "1" && response.responseData.message === "Success") {
                    this.token = response.partnerTokenGeneratorInputIO.listOfToken;
                    this.sessionId = response.partnerTokenGeneratorInputIO.sessionId;
                }
            }),
            switchMap((response: any) => {
                const tokenValue = response.partnerTokenGeneratorInputIO.listOfToken[1].tokenValue;
                return this.encryptToke(tokenValue);
            }),
            catchError((error) => {
                console.error("Error occurred in token generation:", error);
                return throwError(error);
            })
        ); 
    }

    careKycVerification(kycData: any , idProofType: string): Observable<any> {
        const kycPayloadUrl  =  '/payloads/addPayloads'
        if( idProofType === 'PAN') {
            this.kycUrl = CareEnviroment.carePanKycUrl
        } else if ( idProofType === 'AADHAAR'){
            this.kycUrl = CareEnviroment.careAadharKycUrl
        }
        return this.generateToken(CareEnviroment.PreProposalappId).pipe(
            switchMap((encryptedToken: any) => {
                let headers = new HttpHeaders({
                    appId: CareEnviroment.PreProposalappId,
                    signature: CareEnviroment.Signature,
                    timestamp: Date.now().toString(),
                    applicationCD: CareEnviroment.applicationCD,
                    sessionId: this.sessionId,
                    tokenId: encryptedToken.encryptedText,
                    "Content-Type": "application/json",
                });
                const savePayloads = '/payloads/addPayloads'
                const payloadData = {
                    "g_id": localStorage.getItem('g_id'),
                    "transaction_id":  localStorage.getItem('platform'),
                    "user_id": localStorage.getItem('getquoteuserID'),
                    'kyc_payload':kycData
                  }
                this.commonService.post(savePayloads,payloadData).subscribe()

                return this.http.post(this.kycUrl, kycData, { headers });
            }),
            catchError((error) => {
                console.error("Error occurred during KYC process:", error);
                return throwError(error);
            })
        );
    }

 private encryptToke(token):Observable <any> {
    const data = {
      token : token
    }
    const api = '/tokenEncryption/encrypt'
   return this.commonService.post(api, data)
 }
}



