import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  modalRef: BsModalRef;
  leader: any = {};
  leaders = [];
  directors = [];
  members = [{
    name: 'Harshit Jain',
    pic: './assets/images/leaders/harshit.png',
    cat: 'A',
    intro:'Harshit Jain, our Principal PO and CEO, epitomizes leadership',
    designation: 'CEO & Principal Officer',
    desc: `Harshit Jain, our Principal PO and CEO, epitomizes leadership by transforming visions into reality through his profound expertise. As the Principal Officer of Trinity Reinsurance Brokers Limited, his role inspired the company's name. Harshit holds a fellowship from the Insurance Institute of India and an MBA in Insurance. With 15 years of experience managing direct and retail business segments, he handles complex insurance matters with ease. He is now also spearheading initiatives at ThePolicyMall, showcasing his continued dedication to excellence in the insurance industry.’`
  }, {
    name: 'Akhilesh Jain',
    pic: './assets/images/leaders/akhilesh.png',
    cat: 'A',
    intro:'With a solid 15-year track record in both the insurance and reinsurance sectors',
    designation: 'Vice Chairman',
    desc: `With a solid 15-year track record in both the insurance and reinsurance sectors, Akhilesh Jain's expertise stands as a testament to his abilities. His vast experience in overseeing insurance and brokerage operations positions him as the go-to individual within our team. Akhilesh is a holder of a postgraduate degree in Management, supplemented by studies in International Business at the University of Birmingham, England. Currently, He assumes the role of the new Vice Chairman at ThePolicyMall.`
  }];

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    this.leaders = this.members.filter(e=>e.cat == 'B');
    this.directors = this.members.filter(e=>e.cat == 'A');
    $("html, body").animate({ scrollTop: 0 }, 600);

    setTimeout(() => {
      $('.carousel-leaders').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: false,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 2
          }
        }
      });

      $('.carousel-directors').owlCarousel({
        loop: true,
        autoplay: false,
        margin: 10,
        nav: true,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 2
          }
        }
      })
    }, 100);

    
    // $('.carousel-awards').owlCarousel({
    //   loop: true,
    //   margin: 20,
    //   autoplay: true,
    //   nav: true,
    //   dots: false,
    //   responsive: {
    //     0: {
    //       items: 2
    //     },
    //     600: {
    //       items: 2
    //     },
    //     1000: {
    //       items: 4
    //     }
    //   }
    // })
   

  }

  openModal(template: TemplateRef<any>, leader) {
    this.leader = leader;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg common-popup' });
  }

}
