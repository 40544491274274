export const careAdvantage_HeartRelation = [
    {
      "Relationcode": "SELF",
      "RelationDescription": "SELF",
      "": ""
    },
    {
      "Relationcode": "SPSE",
      "RelationDescription": "SPOUSE",
      "": ""
    },
    {
      "Relationcode": "SONM",
      "RelationDescription": "SON",
      "": ""
    },
    {
      "Relationcode": "UDTR",
      "RelationDescription": "DAUGHTER",
      "": ""
    },
    {
      "Relationcode": "FATH",
      "RelationDescription": "FATHER",
      "": ""
    },
    {
      "Relationcode": "MOTH",
      "RelationDescription": "MOTHER",
      "": ""
    }
  ]
export const careFreedomRelation = [
    {
      "Relationcode": "BOTH",
      "RelationDescription": "BROTHER",
      "": ""
    },
    {
      "Relationcode": "COUS",
      "RelationDescription": "COUSIN",
      "": ""
    },
    {
      "Relationcode": "DLAW",
      "RelationDescription": "DAUGHTER IN LAW",
      "": ""
    },
    {
      "Relationcode": "FATH",
      "RelationDescription": "FATHER",
      "": ""
    },
    {
      "Relationcode": "FLAW",
      "RelationDescription": "FATHER IN LAW",
      "": ""
    },
    {
      "Relationcode": "GDAU",
      "RelationDescription": "GRAND DAUGHTER",
      "": ""
    },
    {
      "Relationcode": "GFAT",
      "RelationDescription": "GRAND FATHER",
      "": ""
    },
    {
      "Relationcode": "GMOT",
      "RelationDescription": "GRAND MOTHER",
      "": ""
    },
    {
      "Relationcode": "GSON",
      "RelationDescription": "GRAND SON",
      "": ""
    },
    {
      "Relationcode": "MLAW",
      "RelationDescription": "MOTHER IN LAW",
      "": ""
    },
    {
      "Relationcode": "MMBR",
      "RelationDescription": "SISTER IN LAW",
      "": ""
    },
    {
      "Relationcode": "MOTH",
      "RelationDescription": "MOTHER",
      "": ""
    },
    {
      "Relationcode": "NEPH",
      "RelationDescription": "NEPHEW",
      "": ""
    },
    {
      "Relationcode": "NIEC",
      "RelationDescription": "NIECE",
      "": ""
    },
    {
      "Relationcode": "SELF",
      "RelationDescription": "SELF- PRIMARY MEMBER",
      "": ""
    },
    {
      "Relationcode": "SIST",
      "RelationDescription": "SISTER",
      "": ""
    },
    {
      "Relationcode": "SLAW",
      "RelationDescription": "SON IN LAW",
      "": ""
    },
    {
      "Relationcode": "SONM",
      "RelationDescription": "SON",
      "": ""
    },
    {
      "Relationcode": "SPSE",
      "RelationDescription": "SPOUSE",
      "": ""
    },
    {
      "Relationcode": "UDTR",
      "RelationDescription": "DAUGHTER",
      "": ""
    }
  ]
