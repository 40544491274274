import { relationships } from "./icicirelationships";
import { icici_medical_questions } from "./icici-medical-questions";
import { icici_add_ons, befitAddonIds } from "./iciciAddons";
import { icici_befit_pincodes } from "./icici-befit-pincodes";
import { icici_occupation } from "./icici-occupations";

// Configurations for ICICI 
const icici_member_count = {
  maxAdults: 6,
  maxChildren: 5,
  maxTotalMembers: 11
};

export { icici_member_count, icici_medical_questions, relationships, icici_add_ons, icici_befit_pincodes, icici_occupation, befitAddonIds };
