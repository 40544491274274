import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { CareerComponent } from './career/career.component';
import { CommercialComponent } from './commercial/commercial.component';
import { ComplaintHandlingComponent } from './complaint-handling/complaint-handling.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { ContactComponent } from './contact/contact.component';
import { CorporateComponent } from './corporate/corporate.component';
import { FailedComponent } from './failed/failed.component';
import { FourwheelerComponent } from './fourwheeler/fourwheeler.component';
import { HdfcthankyouComponent } from './hdfcthankyou/hdfcthankyou.component';
import { HealthComponent } from './health/health.component';
import { HomeComponent } from './home/home.component';
import { LegalAndAdminPoliciesComponent } from './legal-and-admin-policies/legal-and-admin-policies.component';
import { ListedPlansComponent } from './listed-plans/listed-plans.component';
import { NstpComponent } from './nstp/nstp.component';
import { PagenotfountComponent } from './pagenotfount/pagenotfount.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PolicyCompareComponent } from './policy-compare/policy-compare.component';
import { PolicypurchasedComponent } from './policypurchased/policypurchased.component';
import { PrivecypolicyComponent } from './privecypolicy/privecypolicy.component';
import { ProposalComponent } from './proposal/proposal.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { QuotesComponent } from './quotes/quotes.component';
import { ReligarePaymentComponent } from './religare-payment/religare-payment.component';
import { SupportComponent } from './support/support.component';
import { TermComponent } from './term/term.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { TravelComponent } from './travel/travel.component';
import { TwowheelProposalComponent } from './twowheel-proposal/twowheel-proposal.component';
import { HealthQuoteComponent } from './health-quote/health-quote.component';
import { IciciPaymentSuccessComponent} from './icici-payment-success/icici-payment-success.component'

import { TwowheelerComponent } from './twowheeler/twowheeler.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { ChildComponent} from './child/child.component';
import { InvestmentComponent } from './investment/investment.component';
import { PensionComponent } from './pension/pension.component';
import { CriticalIllnessComponent } from './critical-illness/critical-illness.component';
import { BlogComponent } from './blog/blog.component'
import { ArticleComponent } from './article/article.component'
import { AllFaqComponent } from './all-faq/all-faq.component';
import { PetInsuranceComponent } from './pet-insurance/pet-insurance.component'

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'Compare & Purchase Insurance Online - Health, Term, Life, Motor | ThePolicyMall', description: 'Explore and buy insurance plans online for health, term, life, travel and motor coverage. Find the best options tailored to your needs.' }},
  //{ path: 'about', component: AboutComponent, data: { title: 'About Title' } },
  { path: 'about', component: AboutComponent },
  { path: 'health', component: HealthComponent},
  { path: 'health-quote', component: HealthQuoteComponent},
  { path: 'health-insurance', component: HealthComponent, data: { title: 'Health Insurance - Compare and Buy Best Medical Insurance Plan Online - The Policy Mall', description: 'Find the best health insurance policies for you and your family at The Policy Mall. Compare and buy online to save time and money. Compare and buy online health insurance plans, get free quotes and online support from The Policy Mall. Explore features, renewal options and claim process of various health insurance plans.' }},
  { path: 'two-wheeler', component: TwowheelerComponent },
  { path: 'two-wheeler-proposal', component: TwowheelProposalComponent },
  { path: 'two-wheeler-insurance', component: TwowheelerComponent, data: { title: 'Compare and Buy Online Bike Insurance Plans -  The Policy Mall', description: 'Compare and Buy Online Two-wheeler Insurance, Get free Quotes and Online Support from thepolicymall. Discover here  Two wheeler Insurance Premium, Benefits, Features, Renewal and Claim Process' }},
  { path: 'four-wheeler', component: FourwheelerComponent },
  { path: 'four-wheeler-insurance', component: FourwheelerComponent, data: { title: 'Compare and Buy Best Car Insurance Online in India – The Policy Mall', description: 'Get affordable car insurance quotes online at thepolicymall.com. Compare rates from top insurance companies and save money on your auto insurance today.' } },
  { path: 'commercial-vehicle', component: CommercialComponent },
  { path: 'commercial-vehicle-insurance', component: CommercialComponent },
  { path: 'corporate', component: CorporateComponent },
  { path: 'corporate-insurance', component: CorporateComponent },
  { path: 'travel', component: TravelComponent },
  { path: 'travel-insurance', component: TravelComponent, data: { title: 'Travel Insurance - Compare and Buy Travel Policy Online in 2024 - The Policy Mall', description: 'Compare and buy travel insurance policies online easily and hassle-free with The Policy Mall. Get instant advisory and assistance while filing travel insurance claims.'} },
  { path: 'term', component: TermComponent },
  { path: 'term-insurance', component: TermComponent, data: { title: 'Term Insurance - Compare and Buy online Term insurance plans - The Policy Mall', description: 'Buy an affordable term insurance plan online and secure your loved ones financial future. Compare insurance quotes, apply online, and get coverage without any medical tests. Protect your family with the best term life insurance benefits.' } },
  { path: 'quotes', component: QuotesComponent },
  { path: 'proposal', component: ProposalComponent },
  { path: 'compare', component: PolicyCompareComponent },
  { path: 'career', component: CareerComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'care/payment-success', component: ReligarePaymentComponent },
  { path: 'star/payment-success', component: PaymentSuccessComponent },
  { path: 'hdfc/payment-success', component: HdfcthankyouComponent },
  { path: 'purchase', component: PurchaseComponent },
  { path: 'abhi/thankyou', component: ThankyouComponent },
  { path: 'legal-and-admin-policies', component: LegalAndAdminPoliciesComponent },
  { path: 'terms_and_conditions', component: PrivecypolicyComponent },
  { path: 'privacy_policy', component: PrivecypolicyComponent },
  { path: 'complaint', component: ComplaintComponent },
  { path: 'not-found', component: PagenotfountComponent },
  { path: 'failed', component: FailedComponent },
  { path: 'listed-plans', component: ListedPlansComponent },
  { path: 'userprofile/dashboard', component: UserprofileComponent },
  { path: 'userprofile/policy_searches', component: UserprofileComponent },
  { path: 'userprofile/policy_purchased', component: PolicypurchasedComponent },
  { path: 'complaint-handling-process', component: ComplaintHandlingComponent },
  { path: 'nstp', component: NstpComponent },
  { path: 'support', component: SupportComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path : 'child', component:ChildComponent },
  { path : 'investment', component:InvestmentComponent },
  { path : 'critical-illness', component: CriticalIllnessComponent },
  { path : 'pension', component:PensionComponent },
  { path: 'blog/:slug', component: BlogComponent },
  {path: 'icici/payment', component: IciciPaymentSuccessComponent},
  { path: 'blogs', component: ArticleComponent },
  { path: 'all-faq', component: AllFaqComponent },
  { path: 'pet-insurance', component: PetInsuranceComponent },
  { path: 'payment-success', component: HdfcthankyouComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
