import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {PaymentTaggingClass , PaymentGateway} from '../services/icici.service'
import { constant } from '../constant';
import Utils from '../utils';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const FileSaver = require('file-saver');
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-icici-payment-success',
  templateUrl: './icici-payment-success.component.html',
  styleUrls: ['./icici-payment-success.component.css']
})
export class IciciPaymentSuccessComponent implements OnInit {

  QuoteNumber: string;
  downloadCertificate= false;
  policyNo: string
  proposalNum: string;
  util = new Utils;
  session_id: string;
  plan_id: string;
  premium_id: string;
  company_id: string;
  company_special_id: string;
  policyStatus = 'processing...'
  policyNumber :any
  plandetail: any = {};
  proposal: any = {
    address: {},
    nominee: {}
  };
  txnStatus: boolean;
  occupation = [];
  lifestyleList = [];
  currentYr: number;
  body: any = {
    test: 'ABC'
  };
  PolCreationRespons: any = {}
  receipt: any = {}
  transactionData: any;
  private tokenEndpoint = '/icici_token/generate-jwt-token';
  private policyCertificate = '/api_icici/common/ilservices/misc/v2/generic/policycertificate';
  loading: boolean;
 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private paymenttag: PaymentTaggingClass,
    private http:HttpClient,
    private paymentgateway: PaymentGateway
    
  ) { }

  ngOnInit() {
    const policyAfterpayment = '/userPlanTransactionRoute/responseAfterPolicyPurchase'
    const addTransaction = '/transaction/addTransaction'
    const updateTransaction = '/transaction/updateTransaction'
    this.startLoading();
    this.route.queryParams.subscribe(params => {
      this.transactionData = params;
    });

    this.proposal =  JSON.parse(localStorage.getItem('proposal_Proposer'))
    let paymentadtapayload = this.createTransactionPayload(this.transactionData) 
  this.commonService.post(addTransaction,paymentadtapayload).subscribe(res=>{
    console.log("response", res)
  })
  if ( this.transactionData.Success === "True") {
    this.plandetail.transaction_amount = this.transactionData.Amount

    let successPayload = this.createTransactionPayload( this.transactionData)

    this.commonService.post(updateTransaction, successPayload).subscribe(res=>{
      console.log("response", res)
    })
    this.paymenttag.paymenttagging(this.transactionData).subscribe(
      (tagresponse) => {
      const payload= this.payloadForSavePurchase(tagresponse, this.transactionData)
    
       this.commonService.post(policyAfterpayment,payload).subscribe(res => {
        
    }, err => {
        //console.log("Error", err);

    })
      this.stopLoading();
      },
      (error) => {
        this.toastr.error(error, 'Error');
        this.stopLoading( );
      }
    );
      this.txnStatus = true;
      
   
  }else{

    this.paymentgateway.paymentVerification(this.transactionData.TransactionId).then((response: any)=>{
      if(response.Status === 0){
        this.plandetail.transaction_amount = this.transactionData.Amount
        this.txnStatus = true;
        this.paymenttag.paymenttagging(response).subscribe(
          (tagresponse) => {
          const payload= this.payloadForSavePurchase(tagresponse, response )
           this.commonService.post(policyAfterpayment,payload).subscribe(res => {
            
        }, err => {
            //console.log("Error", err);
    
        })
          this.stopLoading();
          },
          (error) => {
            this.toastr.error(error, 'Error');
            this.stopLoading( );
          }
        );
      }else{
        this.txnStatus = false;
        this.stopLoading( );
      }
    }).catch(error=>{
      console.log("found err",error)
      this.stopLoading( );
    })
   
    this.stopLoading( );
   
  }


  }

  createTransactionPayload(data){
    const payload =
      {
        "g_id": localStorage.getItem('g_id'),
        "Amount": data.Amount,
        "AuthCode": data.AuthCode,
        "GatewayId": data.GatewayId,
        "GatewayName": data.GatewayName,
        "MerchantId": data.MerchantId,
        "PGTransactionId": data.PGTransactionId,
        "Success": data.Success === "True",
        "TransactionId": data.TransactionId,
        "UserId": localStorage.getItem('getquoteuserID'),
        "Company": "ICICI"
      }
    
    return payload
  }

  downloadPDF(): void {
  this.downloadCertificate= true;
    this.startLoading();
    this.pdfDownloadUthenticate().pipe(
      switchMap((accessToken: string) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        });
        const proposal = JSON.parse(localStorage.getItem('ICICIPROPOSAL'));
        const payload = {
          CorrelationId: proposal.correlationId,
          policyNo: this.policyNo,
          customerId: proposal.customerId
        };
  
        return this.http.post(this.policyCertificate, payload, { headers, responseType: 'blob' });
      })
    ).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'policy_certificate.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      this.stopLoading();
    }, error => {
     
      this.toastr.error('Download error');
      this.stopLoading();
    });
  }
  
  pdfDownloadUthenticate(): Observable<string> {
    const requestData = new URLSearchParams({
      grant_type: environment.user_details.grantType,
      client_id: environment.user_details.clientId,
      client_secret: environment.user_details.clientSecret,
      username: environment.user_details.username,
      password: environment.user_details.password,
      scope: 'esbpolicypdf',
    });
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  
    return this.http.post<any>(this.tokenEndpoint, requestData.toString(), { headers })
      .pipe(
        map(data => data.access_token)
      );
  }

  startLoading() {
    this.loading = true;
  }



  stopLoading() {
    this.loading = false;
  }
  payloadForSavePurchase(apiresponse, transactionData){

    this.plandetail.TxRefNo = transactionData.TransactionId;
    const currentDateTime = moment();
    const txnDate = currentDateTime.format('YYYY-MM-DD');
    const txnTime = currentDateTime.format('HH:mm:ss'); 
    const proposal_no = JSON.parse(localStorage.getItem('ICICIPROPOSAL'))
    this.QuoteNumber = apiresponse.paymentTagResponse.paymentTagResponseList[0].coverNoteNo;
    this.policyNo = apiresponse.paymentTagResponse.paymentTagResponseList[0].policyNo;
    const payload = {
      "amount": transactionData.Amount,
      "TxRefNo": transactionData.TransactionId,
      "currency": "INR",
      "paymentMode": "",
      "pgRespCode": transactionData.PGTransactionId,
      "TxMsg": "Success",
      "TxStatus": "Success",
      "txnDateTime":  currentDateTime.toISOString(),
      "txnDate":txnDate,
      "txnTime":txnTime,
      "signature": "",
      "policyNo": this.policyNo,
      "QuoteNumber": this.QuoteNumber,
      "QuoteId": proposal_no.proposalNumber
  }
  
  return payload;

  }

}
