export const UwPpmcMaster = [
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442355,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442356,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442357,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442364,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442368,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442370,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442372,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442373,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442374,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442376,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442377,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442378,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442379,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442388,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442393,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442397,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442402,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442416,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442423,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442424,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442426,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442428,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442429,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442430,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442431,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442433,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442435,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5960,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442459,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442463,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442466,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442469,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442474,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442477,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442478,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442479,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442481,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442482,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442483,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442486,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442488,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441172,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6060,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5882,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5885,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5888,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5895,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5900,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5902,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5904,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5906,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5550,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5590,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5614,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5657,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5675,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5711,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5728,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5751,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5758,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5777,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5874,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6015,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6041,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6092,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6093,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6094,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6095,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6096,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6098,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6099,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6101,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6104,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6298,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6354,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2467,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2471,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2490,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2522,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2553,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2556,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2569,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2578,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2581,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2622,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2651,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2653,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2656,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2657,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2658,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2662,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2663,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2678,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2681,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2694,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2695,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2708,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2710,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2711,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2714,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2719,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2731,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2768,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2855,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2858,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2860,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2861,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2862,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2871,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2883,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2889,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2953,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2968,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2989,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2995,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3010,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3011,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3012,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3013,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3038,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3080,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3081,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3094,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3098,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3106,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5975,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442594,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442596,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442598,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442600,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442604,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442605,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442606,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442607,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442608,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442609,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442610,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442611,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442612,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442613,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442614,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442615,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442616,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441183,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442197,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442206,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442231,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442232,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442237,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442243,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442266,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442381,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442384,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442385,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442386,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442389,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442390,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442391,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442392,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442394,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442403,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442404,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442405,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3000,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441114,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441148,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441179,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441367,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442071,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442090,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442195,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442207,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442252,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442407,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442410,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442413,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442414,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442415,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4034,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442437,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5325,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6472,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442448,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442471,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442472,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442473,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442490,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442492,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442493,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442495,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442496,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442499,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442505,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442509,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442510,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442515,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442517,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442520,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442521,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442524,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442529,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442530,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442532,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442533,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442534,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442535,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442537,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442539,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442541,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441115,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441147,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441178,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442065,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442119,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442137,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442186,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442190,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442208,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442223,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442224,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442226,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442227,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442228,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442236,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442238,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442267,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442269,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442270,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442271,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442290,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442292,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442294,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442306,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442307,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442308,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442324,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443042,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443045,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443120,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442224,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443123,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443125,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443126,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443128,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443129,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443130,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443131,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443132,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443134,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443136,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443137,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443138,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443143,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443147,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443148,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443149,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443150,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443151,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443152,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443154,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443155,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443156,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443157,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443158,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443159,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443164,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443165,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443171,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443172,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443173,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443174,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443177,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443178,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443185,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441144,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441175,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442621,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442623,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442625,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442633,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442635,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442638,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442639,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442797,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442811,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443124,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443135,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443139,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443140,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443142,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443144,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443145,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443146,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443153,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443160,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443161,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443162,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443163,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443167,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443168,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443169,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443170,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443179,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443180,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443181,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443182,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443184,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443186,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443187,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443188,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443189,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443190,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443191,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443192,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443247,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444057,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444058,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441112,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441150,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441181,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4050,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4054,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4061,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4064,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4081,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4089,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4096,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4097,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4099,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4101,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4102,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4107,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4117,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4118,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4122,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4128,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4134,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4141,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4142,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4144,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4158,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4171,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4172,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4190,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4192,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4196,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4210,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2056,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2060,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2069,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2075,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2076,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2078,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2082,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2090,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2092,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2093,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2095,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2099,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2100,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2101,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2102,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2109,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2121,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2123,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2129,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2142,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2145,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2148,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2151,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2162,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2163,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2166,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2170,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2171,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2173,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2174,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2175,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2176,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2182,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2183,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2263,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2266,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2270,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2351,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2395,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2493,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2554,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2604,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2616,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2617,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2624,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2740,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2742,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2746,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2747,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2748,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2749,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2796,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2797,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2799,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2800,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2801,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2813,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2823,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2846,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2863,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2916,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2934,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2938,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2940,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2944,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2954,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2956,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2979,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2981,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2982,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3083,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3088,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2738,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2741,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2745,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2750,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2804,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2825,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2987,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442328,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1933,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442726,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442751,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442760,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442761,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442763,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442764,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442768,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442771,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442774,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442775,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442776,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5765,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5766,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6401,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 36809,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424310,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424372,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 854,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1474,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2136,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2184,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2319,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2399,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2408,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2638,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2764,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2787,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2867,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2906,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3226,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3236,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3238,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3278,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3387,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3589,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3614,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3753,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3783,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3801,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3911,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3963,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4005,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4058,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4531,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4551,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4609,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4716,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4717,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4720,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5074,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5197,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5237,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5284,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5420,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5437,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5444,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5503,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5700,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5783,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5817,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5959,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6276,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6380,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6381,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424337,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 206,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1728,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1863,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1963,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1964,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1998,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2343,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2426,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425567,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2544,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2666,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2912,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3034,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3072,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3240,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5722,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5858,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5995,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6007,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3485,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3515,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3526,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1015,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3531,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3550,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3561,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425643,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1028,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1049,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1063,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1066,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1073,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1083,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1085,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1103,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 822,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1142,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1144,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1148,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1183,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1751,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1203,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1220,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1233,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1244,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1288,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3652,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6323,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1374,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425662,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1384,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1511,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 833,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 941,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1444,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1107,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1447,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1486,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1504,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1081,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424370,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424379,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424380,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424394,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424395,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424396,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 699,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 700,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 701,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 704,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1277,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1290,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1450,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1518,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1519,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1683,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1790,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1808,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1830,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2014,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425535,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3054,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3063,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3092,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3095,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3158,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3166,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6283,
      "NUM_STATE_CD": 163
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4900,
      "NUM_STATE_CD": 164
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424400,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5665,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5774,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5946,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3169,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3170,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3477,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3524,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3548,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3656,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3704,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3733,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3774,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3781,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4020,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4108,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4163,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4279,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4308,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4451,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4484,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4528,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4557,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4687,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4738,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4748,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4751,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4800,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4929,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4930,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4934,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4964,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5000,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5040,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5228,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5240,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5268,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5277,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5297,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5468,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5568,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5589,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5687,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5737,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5764,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425874,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425891,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3089,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4765,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2520,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5780,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6312,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424322,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2098,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5778,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424450,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424387,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5478,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6144,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424304,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4130,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4883,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441100,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441102,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441143,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441158,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441161,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441174,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441194,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441200,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442162,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442178,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442196,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442211,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442253,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442299,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442319,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442320,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442321,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4180,
      "NUM_STATE_CD": 164
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6243,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4278,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5233,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424451,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2249,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4239,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3617,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424438,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3179,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4143,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2062,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4219,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5573,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3842,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4016,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425545,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425565,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425843,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425855,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425862,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425864,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425866,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425867,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425877,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425879,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425880,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425881,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425882,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425883,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425884,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425885,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425886,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425894,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425895,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425896,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425902,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440334,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 690,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1767,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4087,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5149,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5312,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3234,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5171,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1864,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442335,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442338,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2707,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442401,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442409,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442418,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442419,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442422,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442434,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442436,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442439,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442442,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442443,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442444,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442445,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442446,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442449,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441142,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441193,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442101,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442106,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442139,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442188,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442189,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442191,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442192,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5905,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442198,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442212,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442268,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442349,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440928,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424429,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 302,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424433,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424434,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424437,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 333,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 334,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 337,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5579,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5580,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5581,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5592,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5594,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5618,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5642,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5644,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5685,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5714,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5716,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5720,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5721,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5726,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5759,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5762,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5763,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5767,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5769,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5772,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5786,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5791,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5792,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5801,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5812,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5837,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5880,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 450,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 451,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 452,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 453,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 454,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 456,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 458,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 459,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 791,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 851,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 852,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 906,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 969,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 977,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 998,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1064,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1117,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1118,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1120,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1124,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1215,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1239,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1255,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1325,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1397,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1434,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1435,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1468,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441005,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5887,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5015,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5803,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5200,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440626,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4291,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1269,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2970,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4561,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3483,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4511,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6100,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2369,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2501,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425535,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442222,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442313,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1143,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425529,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425547,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440176,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425723,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1882,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1676,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1402,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2152,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5534,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5535,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5577,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5670,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5770,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5842,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5960,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5996,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6056,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6097,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6563,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424358,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4047,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4094,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425466,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425467,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425480,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425496,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425506,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425563,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425568,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425599,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425613,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425653,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425654,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425664,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440078,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6155,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6156,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6172,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1631,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1632,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1633,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1634,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1635,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1636,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1772,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1787,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1870,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1935,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1949,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1952,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1986,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1987,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2034,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2063,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2073,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2074,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2085,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2096,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2105,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2118,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2190,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2209,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2220,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2232,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2239,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2245,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2250,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2268,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2269,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2277,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2281,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2294,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2296,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2308,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2320,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2324,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2331,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2334,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2340,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2347,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2362,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2370,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2374,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2384,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2385,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2406,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2417,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2419,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2421,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2454,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2465,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 434,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 437,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1893,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2513,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2818,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3184,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3663,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3967,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424399,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3340,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3667,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3542,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4543,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440989,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440999,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441004,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441010,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441016,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441073,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3331,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3950,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4459,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4572,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4885,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4998,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5251,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5308,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5458,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1515,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 904,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1733,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2447,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5566,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6569,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3048,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3231,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3706,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4191,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4488,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1513,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 682,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425838,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425617,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425626,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425660,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425666,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440013,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440237,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440323,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440613,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440625,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440641,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440990,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440998,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441011,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441058,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 817,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442332,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442333,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1212,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1679,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425842,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425821,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425834,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425851,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425860,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 438,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3935,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4711,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4877,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4944,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5026,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5112,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425676,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440311,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5414,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5508,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5526,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5530,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6341,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5666,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5688,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425680,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5799,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4111,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5854,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442556,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 680,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6040,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1357,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6090,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4220,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6133,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6142,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6145,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4329,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6199,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6227,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1146,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6272,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6411,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6489,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 671,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425719,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424373,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424398,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424442,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424445,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1441,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442507,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441103,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441376,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442079,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442081,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442153,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442175,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3493,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3786,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4406,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425577,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425608,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425631,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425644,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440137,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440381,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440431,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440621,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440987,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440994,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441008,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442214,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442255,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442297,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442298,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442300,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442301,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442316,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442318,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442322,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442330,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442337,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 462,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 144,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 168,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 619,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 621,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1495,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1774,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2022,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2051,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2201,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2202,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2237,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2293,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2345,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2393,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2545,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2597,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2606,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2607,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2608,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2652,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2707,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2730,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2828,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2931,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3021,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3031,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3043,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3060,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3068,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3069,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3079,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3284,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3300,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3415,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3437,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3494,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3615,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3724,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3731,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3952,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4140,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4206,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4243,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4268,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4330,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4343,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4356,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4798,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4801,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4808,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4809,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4810,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4811,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4817,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4864,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4869,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4870,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4873,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4875,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4882,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442975,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4895,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4898,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4901,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4902,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4912,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4913,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4927,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4218,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3459,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3462,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3463,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3464,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3471,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3474,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3513,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3523,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3530,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3566,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3613,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3643,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3644,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3647,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3648,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3675,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3678,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3684,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442412,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442417,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442420,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442470,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442475,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442484,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442531,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442538,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442540,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442547,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442551,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442552,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442563,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442568,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442588,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442591,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442592,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442595,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442597,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442601,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442602,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442603,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442617,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442618,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442619,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5084,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5781,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5993,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5107,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5472,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4392,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4625,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1208,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3654,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6261,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1370,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2508,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3405,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6124,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6202,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424263,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4214,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425551,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425554,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425556,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425587,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425668,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425798,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425799,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441014,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441048,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3411,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3421,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3422,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3428,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3429,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3430,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3431,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3433,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3435,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3436,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3441,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3443,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3444,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3466,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3488,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6085,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6086,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6088,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6089,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6105,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6107,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6108,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6111,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6112,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6118,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6123,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6126,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6130,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6134,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6136,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6139,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6140,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6146,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6157,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6158,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6160,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6161,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6163,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6166,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6167,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6168,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6169,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6173,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6174,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6177,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6178,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6179,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6180,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6182,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6183,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6190,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6192,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6193,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6208,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6210,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6212,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6213,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6215,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6222,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6236,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6237,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6251,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6280,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6310,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6335,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6349,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6357,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6370,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6400,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6469,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6500,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6533,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6566,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6574,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6575,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 388446,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424288,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4121,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4123,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4149,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4184,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4333,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4429,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4457,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4532,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4588,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4610,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4684,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4780,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4816,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4910,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4073,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4155,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4301,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2248,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2462,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2894,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6137,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6165,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6181,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6211,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6274,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6532,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424296,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2980,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3061,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3358,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4019,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4334,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3736,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4758,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5291,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5517,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5713,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6368,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424300,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1984,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2486,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3225,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3605,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4240,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5170,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5474,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6304,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1859,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2583,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5547,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2463,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5782,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2303,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3263,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4878,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3915,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5369,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1273,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5967,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4290,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5005,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1642,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6115,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3206,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6119,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5742,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2936,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3333,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1122,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1874,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5507,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1680,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1867,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2618,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2771,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425793,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5331,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 190,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 678,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425796,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1145,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2784,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2909,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3014,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3187,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3339,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 860,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1119,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1467,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1755,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1996,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2130,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5415,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5832,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6128,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424269,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440289,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425708,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425709,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425710,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425711,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425730,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425732,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425737,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425739,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425744,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425745,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425487,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425507,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425518,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425672,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425682,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425751,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425752,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425754,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425757,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425761,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425762,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425763,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425764,
      "NUM_STATE_CD": 192
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425765,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425767,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425769,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425770,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425771,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425776,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425777,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425780,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425781,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425782,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425785,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425786,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425787,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425788,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425791,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425794,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425795,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4710,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425804,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425816,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425819,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425822,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425828,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442930,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425844,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425845,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425847,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425848,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425849,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425852,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425854,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425857,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425858,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425859,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425861,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425868,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425869,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425870,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425871,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1732,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425876,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425887,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425888,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425892,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425893,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425897,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425900,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425911,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440184,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425492,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425498,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425508,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425513,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425586,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4872,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440222,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424435,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3785,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3838,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3876,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4000,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4057,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2122,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2165,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2264,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2737,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2798,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2941,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6135,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6523,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425528,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425573,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425579,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440131,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440152,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1895,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440300,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440380,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1678,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440610,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440381,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440988,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441000,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441009,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441015,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1639,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2066,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2375,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2637,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2770,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2973,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3241,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3587,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3740,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4971,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5307,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5660,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2636,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2781,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3019,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3418,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3594,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3890,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4022,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4692,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5221,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5370,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6458,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424448,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1292,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425530,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425574,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425661,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440116,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5909,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5910,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5912,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5913,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5915,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5917,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5919,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5922,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5924,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5926,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5927,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5931,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5932,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5935,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5936,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5937,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5939,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5940,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5941,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5942,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5944,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5947,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5948,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3090,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5957,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5968,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5983,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5987,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5988,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5991,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6029,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6030,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6046,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6048,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6072,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6073,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6075,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4928,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4931,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4933,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4941,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4950,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4952,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4953,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4957,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4958,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441192,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442038,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442069,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442116,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442135,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442152,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442157,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442171,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442173,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442213,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442254,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442293,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442295,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442296,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442302,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442303,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442309,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442310,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442311,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442312,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442314,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442315,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442317,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442323,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442325,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442326,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442327,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442329,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442334,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442489,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442491,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3033,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3947,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5587,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2859,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4868,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3102,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4655,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6010,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 772,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2634,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4119,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4643,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5116,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5531,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3239,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4371,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425575,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425578,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425594,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425640,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425649,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440348,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440628,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1396,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1508,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5823,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5694,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5816,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6047,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2468,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2582,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2660,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2709,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2817,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2865,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2974,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3025,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3130,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3257,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425561,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425658,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425663,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425669,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425671,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425673,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425904,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425907,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440204,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4959,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4962,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4967,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4970,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4976,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4990,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4991,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5001,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5003,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5004,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5008,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5038,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5042,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5055,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5070,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5073,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5078,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5080,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5081,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5083,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5090,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5091,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5093,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5094,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5098,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5099,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5102,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5103,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5105,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5106,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5110,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5134,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5157,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5160,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5161,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5162,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5167,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5168,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5169,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5174,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5175,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5187,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5189,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5190,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5191,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5193,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5194,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5209,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5225,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5229,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5230,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5232,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5241,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5332,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5358,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5392,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5399,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 247,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 767,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 820,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 902,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 943,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 981,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 997,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1003,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1010,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1030,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1221,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1305,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1306,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1332,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440280,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440293,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440306,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440329,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440346,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440386,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440437,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425873,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440614,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440627,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440991,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440997,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441049,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3353,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3440,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3570,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3640,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3739,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3861,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3929,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 455,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 971,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1121,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1219,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1395,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1469,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1659,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1872,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2042,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2243,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2327,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2418,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425543,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441157,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441171,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441191,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441238,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441314,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442115,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442176,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442202,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442218,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442233,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442234,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442247,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442508,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442511,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442512,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442513,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442514,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442516,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442519,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442522,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442523,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442526,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442527,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442528,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442536,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442542,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442544,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442545,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442546,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442549,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442550,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442554,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442557,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442558,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442559,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442561,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442564,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442567,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441099,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441139,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441156,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441170,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441190,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442039,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442077,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442103,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442109,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442111,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442125,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442142,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442164,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442184,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442777,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442778,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442779,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442780,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442795,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442798,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442800,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442807,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442808,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442809,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442810,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442885,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442894,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442904,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442907,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442908,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444148,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425736,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441050,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444127,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1423,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444198,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444143,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444169,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444159,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2996,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444133,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444122,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1470,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4024,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1912,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425621,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1403,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 956,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5050,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4830,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424440,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1918,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1141,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5920,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5457,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2379,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4772,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425615,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4774,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440304,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442382,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1580,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4296,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441018,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2832,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3473,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4126,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4595,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4918,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5227,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5813,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5899,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5916,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5945,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5079,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5176,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5211,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4499,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4556,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4663,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4723,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4776,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 492,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425527,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425532,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425910,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440318,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425912,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 504,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 516,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1151,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1254,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1409,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1533,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2333,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5954,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 435,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4712,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5030,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5335,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6151,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1507,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1404,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6154,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6302,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3091,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3100,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3103,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3108,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3143,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3434,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3454,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3472,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3641,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3642,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3653,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3750,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3760,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3769,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4033,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4042,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4048,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4059,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4075,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4098,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4104,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4169,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4422,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4428,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4439,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4444,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4474,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4512,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4586,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4605,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4734,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4805,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4815,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4827,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4880,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4940,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4947,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4955,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4960,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4993,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5017,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5045,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5056,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5066,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5082,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5100,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5137,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5177,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5212,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5226,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5296,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5881,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424330,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424351,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424357,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424359,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424362,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424364,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424428,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4697,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4699,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4708,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4719,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4721,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4728,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4729,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425605,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425616,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425622,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425659,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440316,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440358,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440367,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440382,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440993,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441002,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441007,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441013,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441056,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1760,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1836,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440444,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6188,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6241,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6321,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6474,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424331,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4896,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3708,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3860,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4156,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2144,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2793,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5029,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5798,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 741,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2040,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2086,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3168,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4768,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424335,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3677,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425755,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425853,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2444,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425865,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440404,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4440,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2733,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441098,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441107,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441126,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441136,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441138,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441155,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441167,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441187,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441197,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441366,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441127,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441135,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442066,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442078,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442080,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441108,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441154,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441165,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442082,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442091,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442092,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442094,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442114,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442117,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4731,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4737,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4757,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4762,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4766,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4767,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4787,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4792,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4793,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4797,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5949,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5953,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6034,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6129,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6147,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6148,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6159,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6186,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6214,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6329,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6346,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6479,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6499,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6525,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6567,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424297,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424316,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424323,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424324,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424325,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6495,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6496,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6497,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6536,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6561,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424286,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424353,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424356,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424374,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424377,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424454,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 194,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 205,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 489,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 490,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 493,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 495,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 496,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 506,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 509,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 512,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 513,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 514,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 517,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 937,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 990,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1084,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1087,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1096,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1150,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1153,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1155,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1156,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1169,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1209,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1210,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1216,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1256,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1323,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1348,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1367,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1375,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1380,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1408,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1439,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1454,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3887,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425482,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 828,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3903,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3920,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1505,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3922,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3937,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 679,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1446,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1289,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3980,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3987,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441083,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4010,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 502,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424371,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425678,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4027,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1479,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1887,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1791,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4039,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 199,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 503,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4065,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 887,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1485,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4074,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6406,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4085,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4086,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 491,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4109,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4110,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424333,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1849,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1259,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4173,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 705,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441064,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4194,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4197,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4198,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4200,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4205,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4207,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4212,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6407,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4216,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1587,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4225,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4229,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4231,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424449,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442881,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4246,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4249,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4256,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424405,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442365,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441085,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4265,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441063,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441062,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4267,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4269,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4270,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4272,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4274,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4276,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4282,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4284,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4287,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4288,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4289,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1406,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1844,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4297,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4303,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4305,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5894,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1906,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5896,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5897,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5898,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425471,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5903,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5918,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5923,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5925,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5928,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5930,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1102,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5933,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 747,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5934,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1545,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425856,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5952,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 515,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5956,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5969,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 622,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5971,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424407,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5976,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5977,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5978,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5979,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5980,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1890,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5985,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444126,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1542,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5989,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6320,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5990,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425863,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5997,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5999,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6002,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6003,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6005,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6014,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 484,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6019,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6020,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6024,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6026,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6032,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 485,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424458,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6052,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 809,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1475,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444124,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6067,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6068,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1193,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424408,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441061,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6076,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4411,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4701,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4786,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5693,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6071,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424282,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1478,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1481,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1527,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1530,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1532,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1534,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1535,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1540,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1543,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1544,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1640,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1649,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1686,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1687,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1688,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1804,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1805,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1806,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1898,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2065,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2116,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2178,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2179,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2312,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2623,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2725,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3576,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3609,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3689,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3713,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3723,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3747,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3748,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3814,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3891,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3893,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3924,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4026,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4046,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4051,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4062,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4069,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4090,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4103,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4114,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4115,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4116,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4124,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4125,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4127,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442141,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442158,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442165,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442172,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442179,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442181,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442183,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442185,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442187,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442193,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442194,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442199,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442200,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442201,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442203,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442210,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442215,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442216,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442217,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442219,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442220,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442221,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442229,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6224,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441109,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441132,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441153,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441160,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441195,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441262,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442093,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442155,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442168,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442169,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442204,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424348,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424376,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1365,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2013,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3230,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2726,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3053,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3369,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3692,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 447,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 995,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1436,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2046,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2360,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425524,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425595,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1517,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425611,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425620,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425636,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425652,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440402,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5326,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2424,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5311,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5586,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6501,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424299,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4150,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4157,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4170,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4185,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4187,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4366,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4577,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4579,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4597,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4598,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4602,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4606,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4615,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4616,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4732,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4741,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4803,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4978,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5147,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5186,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5359,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5463,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5549,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5599,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5608,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5630,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5699,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5712,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5736,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5760,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5787,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5943,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5955,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5974,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5981,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6021,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6102,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6185,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6218,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6233,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6259,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6260,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6263,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6265,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6398,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6446,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6478,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6494,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 843,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 895,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 932,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1002,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1047,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1113,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1230,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1265,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1407,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1526,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1656,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1758,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1811,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1823,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1833,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1834,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1841,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1932,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1937,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1971,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1993,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2064,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2072,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2077,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2110,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2147,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2153,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2199,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2225,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2252,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2254,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2317,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2322,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2330,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2381,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2382,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2420,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2427,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2430,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2487,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2516,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2563,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2585,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2644,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2646,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2672,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2702,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2703,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2704,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2782,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2783,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2792,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2810,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2812,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2877,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2903,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2939,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2942,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3044,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3059,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3155,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3167,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3212,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3277,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3289,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3303,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3308,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3311,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3320,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3334,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3361,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3377,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3398,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3399,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3400,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3401,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424346,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424393,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4195,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4435,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4894,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4527,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5394,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1929,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2192,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2772,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3093,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3356,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3592,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4342,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2763,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3223,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3914,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5048,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5245,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440232,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440290,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1347,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4349,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4618,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4843,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4985,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5121,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5274,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442240,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442249,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442250,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442256,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442257,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442258,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442411,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4409,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442462,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442464,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442467,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442468,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442476,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442480,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442485,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442487,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442497,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442498,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442500,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442501,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442451,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442452,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442454,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442455,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442456,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442457,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442458,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442460,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442502,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442503,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442504,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3668,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3685,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3694,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3718,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3728,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3758,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3780,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3782,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3812,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3837,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3885,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3886,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3888,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3894,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3905,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3913,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3917,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 445,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 449,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6008,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6452,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424264,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424265,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424266,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424267,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1684,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1685,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1777,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1789,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1798,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1933,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2224,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2276,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2279,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2355,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2363,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2380,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2394,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2437,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2456,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2527,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2533,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2686,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2690,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2718,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2761,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2807,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2878,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2886,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2925,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2997,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3066,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3071,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3161,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3253,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3267,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3406,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3478,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3481,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3527,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3547,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3552,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3572,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3588,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3616,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3626,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3660,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3693,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3756,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3857,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3872,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3889,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3961,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4007,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4129,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4174,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4189,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4203,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4227,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4266,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4363,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4415,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4453,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4486,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4509,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4585,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3575,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3619,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4621,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4672,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4837,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4840,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4893,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4939,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5014,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5043,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5159,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5281,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5368,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5387,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5441,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5514,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5528,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5595,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5661,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5681,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5682,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5696,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5706,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2577,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2590,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2627,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2641,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2680,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2684,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2691,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2713,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2830,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2854,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2872,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2930,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2958,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3075,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3145,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3152,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3310,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3392,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3519,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3629,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3712,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3779,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3835,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3867,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3896,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3927,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3942,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3943,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3956,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4018,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4053,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4093,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4232,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4357,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4590,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4820,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4825,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4831,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4915,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4994,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5141,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5155,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5181,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5301,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2679,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424463,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2697,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2700,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2701,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2706,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2716,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2721,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2735,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2736,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 497,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 193,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1858,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5049,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5051,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5059,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5065,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5069,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5071,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5072,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5075,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5085,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424425,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5755,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5756,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5757,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5761,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5768,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5773,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1712,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5784,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5789,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5793,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5796,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5802,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1742,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 249,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 687,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 814,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5805,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5807,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5814,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5815,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5820,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5822,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 794,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5829,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5830,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424459,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5839,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5841,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5843,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5846,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5848,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3778,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1880,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1405,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3790,
      "NUM_STATE_CD": 164
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 391800,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3797,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3800,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6461,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3810,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3811,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1657,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 697,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3825,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 232,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 862,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3831,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3850,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5087,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441084,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5088,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5089,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5092,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5095,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5096,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5101,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1445,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5115,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5118,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5119,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5120,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5127,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5130,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5131,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1589,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 709,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5148,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 300,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5158,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424444,
      "NUM_STATE_CD": 164
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6568,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5163,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5164,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5165,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 685,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 778,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424401,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5179,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6426,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5185,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5188,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5192,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5199,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5204,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5208,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5210,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 224,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5217,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425485,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5239,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5243,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1677,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5248,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5252,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 347,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5261,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5264,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5265,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5267,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440928,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5270,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5271,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5273,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 719,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 671,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1115,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 896,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5286,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5849,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5852,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5859,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5866,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5870,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5872,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5875,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5877,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1779,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5889,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5893,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3863,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1531,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425801,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425802,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425803,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425808,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425809,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425811,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425812,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425813,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425814,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425831,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425832,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425833,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425837,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425839,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425846,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440024,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440053,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440349,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440992,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440996,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441006,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425850,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441012,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441021,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441045,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441059,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3511,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4199,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5203,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5501,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424365,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 223,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425536,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425537,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425542,
      "NUM_STATE_CD": 192
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1798,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1027,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425560,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425603,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3687,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3697,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3699,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3700,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3701,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3703,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3720,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3721,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3725,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3727,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3730,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3732,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3735,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3744,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3746,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3752,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3755,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3759,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3761,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3762,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3767,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3791,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3792,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3793,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3794,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3808,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3822,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3823,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3824,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3826,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3827,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3839,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3853,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3858,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3859,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3865,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3866,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3869,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3873,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3874,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5406,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5408,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5409,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5467,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5494,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5496,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5498,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5518,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5545,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5546,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5548,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5565,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5567,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5570,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5572,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5575,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5576,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4275,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4280,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4281,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4293,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4294,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4295,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4364,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4365,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4367,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4369,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4376,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4377,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4380,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4381,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4382,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4383,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4385,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4389,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4405,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4407,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4408,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4427,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4438,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4441,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4442,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4443,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4455,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4456,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4479,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4480,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4483,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4497,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4498,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4500,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4502,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4503,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4504,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4505,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4513,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4522,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4533,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4553,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4555,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4581,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4589,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4591,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4593,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4630,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4633,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4634,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4638,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4654,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4668,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4696,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 897,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 837,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2933,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2985,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3018,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3137,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3172,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3186,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3297,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3302,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3468,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3501,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3525,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3583,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3597,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3716,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3784,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3787,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3798,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3879,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3895,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3910,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3928,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3968,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3969,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3972,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4003,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4011,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4558,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4559,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4788,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4852,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4859,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4935,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5046,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5324,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5456,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5511,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5533,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2922,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3503,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3979,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4704,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5879,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6277,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6472,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6492,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1871,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6284,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1797,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1829,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2141,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2146,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2262,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2278,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2288,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2295,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2313,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2338,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2341,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2397,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2416,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2422,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2469,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5808,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5831,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5861,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443251,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6084,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6087,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1538,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6109,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6110,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6116,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6117,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 183,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6121,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424419,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6131,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 672,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6138,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 383377,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 688,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6150,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6162,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6176,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1865,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6184,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 750,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6194,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6197,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 947,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6201,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6204,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6207,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442979,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6217,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425495,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425499,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425501,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425522,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425562,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425584,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425607,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425609,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425612,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425614,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425623,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425624,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425628,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425638,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425646,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425651,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425657,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425670,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425679,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425681,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425691,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425692,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425696,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425700,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425701,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425702,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425703,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425704,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425705,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425707,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3099,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3101,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3104,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3107,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3109,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3113,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3117,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3119,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3120,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3121,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3173,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3182,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3188,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3193,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3254,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3261,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3291,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3304,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3313,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3364,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3381,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3382,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3407,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3439,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3442,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3453,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5114,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5146,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5154,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5224,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5341,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5378,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5465,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5491,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5543,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5689,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5730,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4485,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5734,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5818,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5961,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6004,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6035,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6065,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6195,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6198,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6219,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6487,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 159,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 170,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 172,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1270,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1848,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1852,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1862,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1873,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1876,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1877,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1899,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1902,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1903,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1921,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1922,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1925,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1930,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1931,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1936,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1940,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1969,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1973,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1975,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1976,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1977,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1978,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1979,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1991,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1999,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2000,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2015,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2017,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2024,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2038,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2041,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2047,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2048,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2050,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1346,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1428,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1581,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1582,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1586,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1588,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1645,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1724,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1727,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1744,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1745,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1750,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1756,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1765,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1816,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1965,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2080,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2135,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2227,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2233,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2257,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2358,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2388,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2401,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2403,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2410,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2496,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2510,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2536,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2540,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2557,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2595,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2669,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2751,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2753,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2767,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2773,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2824,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2887,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 708,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4752,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4753,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4756,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4761,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4763,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4770,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4775,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 462,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 683,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4782,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4783,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1782,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4790,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4794,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441003,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4799,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4802,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 179,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4818,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4819,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424400,
      "NUM_STATE_CD": 158
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4823,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441279,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 188,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1734,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1502,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1743,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1753,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1821,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1911,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1913,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1914,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2070,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2071,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2222,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2256,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2271,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2402,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2480,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 803,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1503,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442235,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2673,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2693,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2705,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2754,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2329,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2788,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1072,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1014,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425559,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2452,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2474,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4975,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4983,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5011,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5013,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5019,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5060,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 575,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2946,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2949,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 677,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 681,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440257,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1358,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1022,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425601,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3004,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 749,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3007,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3008,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425602,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3027,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442118,
      "NUM_STATE_CD": 192
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3177,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3286,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3307,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440063,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3372,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 879,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 916,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4651,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 870,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4652,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1778,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4657,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4659,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4661,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4662,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1448,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1879,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4676,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1327,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4842,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4691,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4694,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 298,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4714,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4715,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 928,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4722,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4730,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4740,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442432,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4743,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4745,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3410,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3414,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3417,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3423,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3424,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3426,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3427,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 345,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 432,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 187,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3446,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1851,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3449,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1340,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 499,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1884,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3469,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1088,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6399,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1881,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3490,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424355,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 152,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1591,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3510,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1007,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3521,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1229,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 446,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3529,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3535,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 743,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3538,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3539,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3544,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3557,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3569,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3578,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3581,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 703,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 813,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3585,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 942,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3595,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1157,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1449,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3608,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 153,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3611,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 146,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441017,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3628,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441069,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425650,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 804,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3650,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3651,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443141,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3658,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442155,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1831,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3666,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3670,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 222,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3681,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3686,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3698,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3702,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 881,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1536,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3711,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3717,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6328,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441168,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6459,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3742,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1541,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3754,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 500,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3775,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5290,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5292,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5295,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5725,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 689,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441275,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5992,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 850,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6028,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6036,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424278,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3141,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3497,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 651,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3982,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2971,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2977,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2499,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3006,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 917,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1356,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3077,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3085,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3149,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3159,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 774,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440603,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3301,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2601,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3351,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3357,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3450,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2620,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1379,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 779,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2667,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2670,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2671,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3690,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3705,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3770,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3789,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3854,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3899,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1339,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1249,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2762,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2765,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4247,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2780,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4311,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2827,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1514,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4332,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4348,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4355,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2857,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4507,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4535,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 944,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4565,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1506,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4705,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2873,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1016,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1338,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5982,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5985,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6069,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6070,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6191,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6202,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6254,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6291,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6299,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6331,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6377,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6378,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6431,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6432,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6483,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6562,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6581,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4481,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 89263,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424273,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424275,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424276,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424305,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424313,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424334,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424338,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424341,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424342,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424344,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424345,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424347,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424349,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424350,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424361,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424363,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424369,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1005,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4834,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4835,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4838,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1869,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4841,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 351,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6419,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4846,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4853,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424426,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4857,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4861,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4871,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4886,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4897,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4903,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4907,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4914,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4916,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4924,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4926,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 507,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 508,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424452,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 282,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 800,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4948,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4949,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4951,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4956,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1682,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4961,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4966,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 692,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4974,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 698,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4992,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4995,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424406,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5006,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5007,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5010,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5021,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5024,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5031,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5035,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5036,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 888,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1915,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1926,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1868,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1934,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 980,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1889,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 173,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1953,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 250,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1960,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1974,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1981,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6294,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1994,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 251,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2001,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2002,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2007,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2012,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6528,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1018,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6220,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6221,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442245,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1080,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6226,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6228,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6230,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1335,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6232,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442097,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1192,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6245,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6247,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1905,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1895,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424409,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424411,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441053,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424424,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6253,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6255,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 694,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424443,
      "NUM_STATE_CD": 164
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6262,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6264,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6267,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6270,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6278,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2645,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2682,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1799,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1800,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2230,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2743,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3562,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4340,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4477,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4844,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4845,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4847,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5374,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5629,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6252,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424268,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424343,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424453,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424455,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424456,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 991,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2539,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2890,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3425,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3958,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3959,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4639,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4640,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5449,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5450,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5907,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6000,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6122,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6275,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6503,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2834,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1888,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5348,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5361,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5797,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5825,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5850,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5873,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6027,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6325,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6366,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6470,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6475,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424385,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 739,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1114,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1139,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1291,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1452,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1453,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1523,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2398,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2400,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2436,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2640,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2752,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3074,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3125,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3132,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3144,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3348,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3370,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3385,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3390,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3470,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3627,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3688,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3751,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3773,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3802,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4257,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4304,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2028,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2035,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 827,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2049,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 174,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2052,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2055,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2058,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2059,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1676,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441044,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1402,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 992,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2084,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1228,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425533,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1904,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2103,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2104,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2107,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2117,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2119,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2124,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441199,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 891,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2127,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2134,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2137,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2140,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1326,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2152,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2158,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2167,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2189,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2207,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2210,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2221,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 335,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 853,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2234,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2235,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2236,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2251,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441060,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 967,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3020,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3024,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442154,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3030,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3035,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3039,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1882,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1522,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3073,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 825,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 807,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424272,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424403,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424404,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441054,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3105,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3110,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 293,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 696,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3129,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6386,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3148,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 342,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3162,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424446,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3174,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 676,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3192,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1878,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3205,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5951,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3213,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3216,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1812,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3232,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1861,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442075,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3255,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3258,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3266,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3268,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3272,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3274,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3283,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444168,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3285,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3290,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1900,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425619,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3309,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3312,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3314,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 343,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3323,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3324,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3326,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3328,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 608,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3329,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3338,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 145,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3345,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3347,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1432,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3362,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1584,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1171,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 344,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 894,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 738,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3409,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2265,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 220,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2274,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2283,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2289,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1303,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 155,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2728,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5272,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5279,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5294,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5487,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5522,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1850,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5559,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1248,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2744,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441323,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2785,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2803,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2805,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425477,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2808,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 340,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2819,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 253,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2820,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2821,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424464,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2838,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 246,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1843,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2841,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2844,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1619,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2847,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2848,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3404,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3604,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3977,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4147,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4193,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4221,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4222,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4244,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442881,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4547,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4700,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4725,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4833,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4876,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5172,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3342,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3492,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3502,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3532,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3610,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3904,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3990,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4032,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4234,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4341,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4646,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4836,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4920,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5123,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5884,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6307,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6403,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 218,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 675,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441341,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2869,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2870,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2884,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 770,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2900,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 858,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 744,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2908,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 986,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2915,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424301,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2919,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2943,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2947,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2952,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424427,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 737,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2988,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1892,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2990,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 341,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3000,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3001,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1802,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3005,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1488,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3009,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424402,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425718,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4458,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4462,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4466,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4473,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4475,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4478,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425720,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4481,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4482,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 350,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4506,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4510,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424319,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4518,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4525,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 963,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4548,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4549,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 637,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4552,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 911,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4564,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4567,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4568,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4569,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4574,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4575,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4576,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4578,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1516,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4596,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 147,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4603,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4613,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4622,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1771,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4628,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1152,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4635,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4636,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 156,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4641,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4644,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4726,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4747,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4858,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4973,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4986,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4987,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4988,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5206,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5263,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 691,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5421,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5538,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5727,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5844,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6022,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6042,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6061,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6402,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6529,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424274,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424390,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 217,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 219,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 760,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1245,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2113,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2133,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2302,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2438,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2440,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1917,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2610,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2628,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2720,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2794,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2802,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2831,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2836,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424351,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2896,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3932,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3936,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3938,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3945,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3975,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4017,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4023,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4036,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4052,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4112,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4120,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4136,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4139,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4160,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4277,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4313,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4315,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4318,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4394,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4418,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4433,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4496,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4526,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4619,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4642,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4645,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4648,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4759,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4764,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4789,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4813,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4821,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4850,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4851,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4881,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4923,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4936,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424439,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424441,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2016,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3171,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3715,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3734,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4159,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5334,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5413,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5739,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5869,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6416,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4309,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4310,
      "NUM_STATE_CD": 164
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 818,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4312,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442070,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4320,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4331,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4337,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4352,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425706,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424386,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1168,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4362,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4372,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4375,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1394,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4390,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4391,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4393,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4396,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4399,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4402,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 346,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4404,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4409,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 601,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4417,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4436,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4446,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4447,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4449,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4450,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2316,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 439,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2321,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 819,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2332,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2336,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1158,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2342,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2346,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1783,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444131,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1371,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2352,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 221,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 243,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2367,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2368,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2373,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2377,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2392,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2411,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2413,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 519,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2423,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 336,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2425,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2441,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2446,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2453,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1459,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425567,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2459,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 244,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 670,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2492,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 673,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2498,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2502,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 252,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6332,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 693,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424413,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2523,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1086,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5061,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5153,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5183,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5195,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5214,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5219,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5250,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5252,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5252,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5260,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5285,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5302,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5345,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5400,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5410,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5422,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5429,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5431,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5499,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5502,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5525,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1509,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1510,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440601,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1512,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1653,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425517,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1947,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1681,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1730,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1731,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2528,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2529,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 674,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 338,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2541,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6373,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 339,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 245,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2555,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2560,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2579,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1276,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6374,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2599,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2602,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2603,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 736,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2609,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2612,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2615,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2621,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6477,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2625,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 357,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 984,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2642,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4425,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 617,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1637,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1013,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3396,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3408,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3416,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425634,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3486,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3487,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3496,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3512,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3541,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3567,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3568,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3570,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3574,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3582,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3593,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3600,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3620,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3664,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425510,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425569,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425627,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425699,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425728,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425742,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425753,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425760,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425775,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425783,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425792,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425806,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442177,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442180,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442205,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442251,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442543,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442560,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442566,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442569,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442570,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442571,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442572,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442573,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442574,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442575,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442576,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442577,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442578,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442579,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442580,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442581,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442582,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442584,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442585,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442586,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442587,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442589,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442590,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442593,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442880,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442882,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442883,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442884,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442886,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442887,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442888,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442889,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442890,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442891,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442892,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442893,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442900,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442901,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442903,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442906,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442909,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442910,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442912,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2529,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442918,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442920,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442925,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442932,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442933,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442937,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442938,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442939,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442940,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442941,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441111,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441113,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441149,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441151,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441213,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442727,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442735,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442752,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442753,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442758,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442767,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442812,
      "NUM_STATE_CD": 163
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425889,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442905,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442913,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442914,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442916,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442926,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5347,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442963,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442965,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442973,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442974,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442978,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442982,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442983,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442984,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443035,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443036,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443037,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443039,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443043,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443044,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443046,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443047,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443048,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443049,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443050,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443055,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441116,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441146,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441177,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442911,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442976,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440442,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440624,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440179,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440963,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3828,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4100,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4649,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4997,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5231,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424378,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5593,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442331,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442336,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442440,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2455,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441351,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442773,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442772,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4448,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4307,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 366,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5310,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 227,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442288,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2880,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1770,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442624,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424277,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2284,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442518,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442280,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442277,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443121,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442278,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442283,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442281,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441343,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6395,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5628,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1832,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5744,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1386,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 498,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442427,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442395,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3551,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5515,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6462,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 975,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1353,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441350,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1726,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442583,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442425,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442447,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441348,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441205,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442927,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424417,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 227,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 842,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1853,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 99999060,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442494,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2511,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 354,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1630,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442959,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442657,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442291,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442289,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442272,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442656,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443133,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442274,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442182,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442279,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442352,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424431,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 505,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441347,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441346,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441349,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4076,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6531,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1847,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4215,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 358,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442786,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 358,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 358,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3287,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 468,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 561,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441342,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3078,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1388,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1048,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5283,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424421,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6315,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441051,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3116,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442399,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424430,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6427,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 79511,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443122,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441345,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442747,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442659,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 182,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442304,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5247,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442282,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442285,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443027,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443127,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3287,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 745,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424412,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 99999060,
      "NUM_STATE_CD": 192
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1809,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441223,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425538,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441041,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1433,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440711,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442095,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5674,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2097,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425903,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 527,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 148,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 574,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441352,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 329,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 356,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5819,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425683,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442159,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440630,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3224,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 154,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441239,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2558,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1754,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441182,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425472,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2282,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425637,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442441,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441145,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442136,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442146,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442209,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442225,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442230,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442239,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442241,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442242,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442244,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442246,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442248,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442348,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442351,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6204,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442245,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442354,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442358,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442359,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442360,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442361,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442362,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442363,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442366,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442367,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442369,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442371,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442375,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442380,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442383,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442387,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442396,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442398,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441137,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442620,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442627,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442628,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442629,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442630,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442631,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442632,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442634,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442636,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442637,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442640,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442641,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442642,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442643,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442644,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442645,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442646,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442647,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442648,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442649,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442650,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442651,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442652,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442653,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442654,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442658,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3628,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442660,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442661,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442663,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442728,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442731,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442732,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442736,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442738,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442739,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442740,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442741,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442742,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442743,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442745,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442746,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3263,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442748,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442749,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442750,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441129,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441133,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441184,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5684,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5860,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4938,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5104,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4452,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4632,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6456,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1154,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1528,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4084,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425468,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425486,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425493,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425494,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425503,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 684,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425520,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440355,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440383,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440447,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440036,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4550,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440960,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440967,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441055,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441057,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5865,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424360,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425509,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425516,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425523,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425590,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440058,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440132,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440249,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440274,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440279,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440305,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440356,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440471,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440604,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440609,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440612,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440640,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3764,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4637,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440338,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440473,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 472,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1006,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2020,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2613,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3533,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6187,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6297,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2696,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3438,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4909,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3683,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3763,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3875,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4063,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4179,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2108,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5667,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5886,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5901,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5911,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5921,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5929,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5938,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5950,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5958,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5984,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6001,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6066,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4937,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4954,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4968,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5002,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5057,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5086,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5109,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5166,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5201,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5242,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5363,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5470,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5520,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5571,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4292,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4374,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4384,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4420,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4464,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4501,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4541,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4594,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4718,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4733,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4791,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6125,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6327,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6564,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424457,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 494,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 511,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 936,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1293,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1389,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1529,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1537,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1650,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2732,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3729,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4028,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4113,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4153,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4261,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4582,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4656,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5053,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5738,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5748,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6045,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 841,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 950,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1387,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1807,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1939,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2120,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2309,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2614,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2778,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2885,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3189,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3355,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3402,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3432,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3460,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6127,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6164,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6175,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6189,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6209,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6322,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6485,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 386956,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4145,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4326,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 462,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 763,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1037,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440964,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 618,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2366,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2549,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2619,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3003,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3051,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3276,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3944,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4242,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4804,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4826,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4879,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4250,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3489,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442730,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442733,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442737,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442796,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442803,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442895,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442902,
      "NUM_STATE_CD": 153
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442917,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442919,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442921,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442922,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442923,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442924,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442928,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442929,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442931,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442935,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442936,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442942,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442956,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442957,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442958,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442960,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442961,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442962,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442964,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442966,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442967,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442968,
      "NUM_STATE_CD": 154
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442969,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442970,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442971,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442972,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442975,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442980,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442981,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443034,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443038,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443040,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443041,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443051,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443052,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443053,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443054,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442729,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442754,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442756,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442759,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442762,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442765,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442766,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442769,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442781,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442782,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442783,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442784,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442785,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442787,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442788,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442789,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442790,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442791,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442792,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442793,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442794,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442799,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442801,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442802,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442804,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442805,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2814,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3114,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5018,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6250,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 884,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1968,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2727,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3506,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4675,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440920,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441052,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425572,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425591,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425592,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425641,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425648,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425655,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425684,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425685,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425686,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425687,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425689,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425694,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425695,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425712,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425713,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425714,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425715,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425717,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4911,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425721,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425722,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425726,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440213,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440337,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440353,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2882,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5389,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425526,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425596,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425597,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425632,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425633,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425634,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425635,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425647,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425665,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1401,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425898,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425899,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440114,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440211,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440351,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440606,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440622,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3877,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3881,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3883,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3898,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3993,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3995,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3996,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3997,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3998,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3999,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4021,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4030,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4035,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4037,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4040,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4041,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4045,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3646,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3696,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3719,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3745,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3766,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3809,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3849,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3870,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3994,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4025,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4049,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4071,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4106,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4137,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4178,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2061,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2094,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2106,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2143,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2164,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2177,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2537,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2739,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2795,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2955,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3087,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3112,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3178,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3325,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3458,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5139,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5327,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5536,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 281,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1916,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1920,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1942,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1980,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2039,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1354,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1585,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1711,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2025,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2461,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2633,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2910,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3076,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3412,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3771,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3897,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4814,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5519,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5741,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3743,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440188,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6350,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442453,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442991,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442622,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424423,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2517,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 539,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442755,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442350,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442347,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 598,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2962,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443030,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442599,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442770,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 526,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441347,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442806,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3330,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441355,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441086,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1875,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5205,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441152,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425772,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2282,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 976,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2057,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 154,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 764,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442553,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5819,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4038,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2391,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1945,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442734,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2816,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442406,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425472,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5247,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425473,
      "NUM_STATE_CD": 192
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 478,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 176,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424423,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1853,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4755,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3868,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442450,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2429,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441982,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 478,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444208,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6578,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2226,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1020,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 363,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 149,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 600,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3757,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2391,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442548,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425576,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424414,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442096,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6033,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1883,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 192,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442626,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 229,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442662,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 201,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441019,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2816,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3045,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443176,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3757,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1945,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 597,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3829,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 821,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1775,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441067,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6405,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5516,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442107,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441343,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3045,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4067,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443010,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 201,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5283,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3516,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6493,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442934,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441349,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3518,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 543,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1846,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442276,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442824,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4400,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441351,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442525,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442744,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2917,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 581,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2676,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1847,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1780,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442286,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 525,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424421,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 366,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1166,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441343,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2685,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 523,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 523,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 523,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1857,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 974,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3388,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442400,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 979,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 534,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1883,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4034,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4044,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4350,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 449,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 781,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4388,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4698,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442327,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4982,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1539,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5016,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 510,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2259,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2275,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5304,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1308,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5306,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1194,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1250,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5322,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 740,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6282,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5325,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5328,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5329,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 912,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5337,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5344,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5347,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 930,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5352,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5353,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5355,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5356,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5357,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5362,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424317,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5373,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5375,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 691,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5385,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1815,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424416,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1044,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5393,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5417,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5418,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5423,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5424,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5425,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5428,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5432,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5438,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5445,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5448,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5454,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5460,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 302,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5466,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1776,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5468,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5469,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 592,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5475,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 771,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5482,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1794,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5488,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5495,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5497,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 457,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424418,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5537,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5552,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5553,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5555,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5556,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1021,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5562,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5569,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424461,
      "NUM_STATE_CD": 150
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444167,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5588,
      "NUM_STATE_CD": 152
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5601,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6442,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5611,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5615,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5620,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1590,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5621,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5624,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5626,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1885,
      "NUM_STATE_CD": 151
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 348,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5641,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5643,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1181,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5645,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425820,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5648,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 620,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5653,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1017,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5656,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5658,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1012,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5668,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5669,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1123,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 436,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425470,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5673,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5676,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5677,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5686,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5698,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442110,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5707,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 933,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1763,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5715,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1894,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5723,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5724,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424354,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1866,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5732,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5733,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1341,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5740,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5745,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 769,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5749,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3123,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3140,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3154,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3165,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3197,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3208,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3217,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3227,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3243,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3259,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3260,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3294,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3295,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3322,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3373,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3374,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3375,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3389,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5416,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5426,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5434,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5436,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5443,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5461,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5464,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5473,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5485,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5523,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5527,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5539,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5602,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5627,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5628,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5651,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5663,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5664,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5701,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5771,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5824,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5883,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5966,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6016,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6062,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6273,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6315,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6326,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6339,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6340,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6396,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6418,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6441,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6486,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6490,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6531,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6535,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6565,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 409668,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424352,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424420,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424421,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424422,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425474,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425576,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440021,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440710,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441038,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441039,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441042,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441043,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441046,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441047,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441066,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441182,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442131,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442134,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442143,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442985,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442986,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442987,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442988,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442989,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442990,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442991,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442992,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442993,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442994,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442995,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442996,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442997,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442998,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442999,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443000,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443001,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443002,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443003,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443004,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443005,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443006,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443007,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443008,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443009,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443010,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443011,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443012,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443013,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443014,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443015,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443016,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443017,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443018,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443019,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443020,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443021,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443022,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443023,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443024,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443025,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443026,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443027,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443028,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443029,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443030,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443031,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443032,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443033,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442659,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 201,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 227,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 228,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 229,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 230,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 231,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 233,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 234,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 236,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 237,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 239,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 240,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 359,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 463,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 707,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 832,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 838,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 844,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 903,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 931,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 979,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1043,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1138,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1140,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1177,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1179,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1218,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1301,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1302,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1322,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1324,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1471,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1477,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1648,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1706,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1710,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1817,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1956,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2054,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2229,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2531,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2897,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2927,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2928,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3142,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3330,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3517,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3682,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3918,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4554,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5068,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5319,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5402,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5407,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5790,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5853,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6309,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6388,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6408,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6425,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424328,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440931,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442943,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442944,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442945,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442946,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442947,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442948,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442949,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442950,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442951,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442952,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442953,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442954,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442955,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 443248,
      "NUM_STATE_CD": 69
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425472,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441041,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 192,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6405,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6462,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442662,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1853,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1875,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2021,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2097,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2284,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2286,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2676,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2880,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2962,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3078,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3224,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3491,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 470,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 599,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 581,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5800,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 363,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1770,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 425515,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 602,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424465,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 602,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442461,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442878,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442725,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442655,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442465,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442562,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 600,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6338,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 231,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 538,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 182,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 354,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2021,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 79511,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3078,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424460,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441349,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441347,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 227,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442819,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442438,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 596,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 836,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 557,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4273,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442555,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5384,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1846,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1166,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443166,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5516,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1792,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 366,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443010,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1992,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424436,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 551,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 203,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441348,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2287,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2391,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 468,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425474,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1388,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443018,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 599,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442994,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442998,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2455,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5122,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 544,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 366,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1020,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441205,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1809,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424417,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1847,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 885,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 871,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 598,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1857,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2282,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 939,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1700,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442518,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443019,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 558,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 356,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 539,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425589,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1813,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 99999060,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3554,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442275,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424460,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 979,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5310,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3465,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 477,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5384,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441204,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3710,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 599,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4755,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441347,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 580,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443012,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2455,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3848,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 764,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 352,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 358,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443069,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 979,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443066,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1386,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2874,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 79511,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 62
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443012,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 475,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 469,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442773,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 544,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6443,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 148,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5247,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441043,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1314,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1314,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 974,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1301,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3330,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1456,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6350,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 329,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5819,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3365,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 793,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441043,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 764,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443193,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441343,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4186,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 522,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5395,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441343,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 525,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441343,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2835,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3346,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5276,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 148,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1166,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442915,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1726,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 599,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1857,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 505,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442818,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5298,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1135,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5485,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442988,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424460,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442421,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 597,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 745,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442562,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1809,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 525,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6350,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 149,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1093,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1388,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1550,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441351,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1773,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1897,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2241,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6033,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443102,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3233,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1388,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424277,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442997,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 505,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3868,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1330,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 874,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 574,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443012,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443102,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 359,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2021,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 477,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 229,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443099,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1825,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1845,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1243,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1020,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1020,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3391,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 182,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1773,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1847,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 181,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424410,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1786,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442849,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 811,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1853,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 974,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 589,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3491,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 181,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 597,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 842,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3565,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2057,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2455,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2286,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3388,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442525,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443068,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1218,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6315,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 598,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3327,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3233,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443090,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4138,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 596,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442583,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442714,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424462,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441351,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3330,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 527,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 527,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2214,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4682,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1846,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 557,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 176,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 599,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 885,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3078,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 5205,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 182,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1786,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442108,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 557,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4682,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 581,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2287,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 580,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443183,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442105,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442991,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3966,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441349,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 203,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2511,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 557,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 574,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6350,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6315,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 440472,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1050,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 474,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1655,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441920,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2676,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3518,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 474,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 79511,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6531,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441347,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 764,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 356,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2874,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442273,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 875,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442833,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 231,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1897,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 79511,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425474,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 875,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1726,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 477,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5402,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6033,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442104,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 522,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441347,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6315,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4398,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 149,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6388,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2676,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 477,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 367,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 842,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 476,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1775,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443014,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6319,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3233,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 474,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424410,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4495,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 558,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4186,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 976,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 409668,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442997,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6315,
      "NUM_STATE_CD": 155
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4822,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 6531,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 650,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 444212,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 578,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2589,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4398,
      "NUM_STATE_CD": 165
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 974,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441342,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1388,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 4056,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442288,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 182,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1361,
      "NUM_STATE_CD": 54
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3379,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3379,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 939,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 154,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2282,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 352,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 478,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 464,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3757,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1050,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 523,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1726,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1550,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1729,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 558,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1860,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 442757,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 181,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 329,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 581,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441349,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 535,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2587,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 3346,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 124
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 58
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 821,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 574,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 356,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3045,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 474,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 525,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1729,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424328,
      "NUM_STATE_CD": 127
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 231,
      "NUM_STATE_CD": 132
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2226,
      "NUM_STATE_CD": 146
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 836,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 875,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1809,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5963,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1330,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443175,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442814,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443109,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 181,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 441173,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2284,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1992,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 354,
      "NUM_STATE_CD": 138
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425474,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441036,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 589,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5651,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 2816,
      "NUM_STATE_CD": 59
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 793,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 233,
      "NUM_STATE_CD": 67
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 443166,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 356,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 433,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 72
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 434
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 1846,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "SOUTHEAST",
      "NUM_CITYDISTRICT_CD": 360,
      "NUM_STATE_CD": 147
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 179,
      "NUM_STATE_CD": 74
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3551,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3757,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3868,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4056,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4076,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4307,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5283,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5298,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5515,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5516,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5674,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5800,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5819,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6287,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6350,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424423,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424430,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424432,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424465,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425515,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425683,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425772,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440630,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441019,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441152,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441173,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441355,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 441920,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442088,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442124,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442159,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442272,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442273,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442274,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442275,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442276,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442277,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442278,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442279,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442280,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442281,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442282,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442283,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442285,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442286,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442288,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442289,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442291,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442304,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442656,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442657,
      "NUM_STATE_CD": 144
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1729,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1845,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1846,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1847,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1857,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2111,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2205,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2315,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2723,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2929,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2991,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3250,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3612,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3832,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4091,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4138,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4398,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4674,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4680,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4860,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4932,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4999,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5032,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5388,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5529,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5584,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5794,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5908,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5972,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6289,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6337,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6352,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6409,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6430,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6437,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6473,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6506,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6510,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6511,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6518,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424447,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440929,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442145,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442664,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442665,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442666,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442667,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442668,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442669,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442670,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442671,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442672,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442673,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442674,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442675,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442676,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442677,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442678,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442679,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442680,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442681,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442682,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442683,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442684,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442685,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442686,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442687,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442688,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442689,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442690,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442691,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442693,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442694,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442695,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442696,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442697,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442698,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442699,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442700,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442701,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442702,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442703,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442704,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442705,
      "NUM_STATE_CD": 148
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1883,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1896,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1897,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1962,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2057,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2208,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2305,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2314,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2339,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2404,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2734,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3045,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3176,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3360,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3805,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3848,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4135,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4164,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4856,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5052,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5202,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5205,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5276,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5300,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5360,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5471,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5650,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5747,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5795,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6033,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6435,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442144,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442707,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442708,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442709,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442712,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442713,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442714,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442719,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442720,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442721,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442723,
      "NUM_STATE_CD": 149
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 99999060,
      "NUM_STATE_CD": 160
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 686,
      "NUM_STATE_CD": 162
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424436,
      "NUM_STATE_CD": 162
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425473,
      "NUM_STATE_CD": 192
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 148,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 149,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 164,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 181,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 203,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 468,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 522,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 523,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 525,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 526,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 527,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 528,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 529,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 530,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 531,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 532,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 533,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 534,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 535,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 536,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 537,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 538,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 539,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 541,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 542,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 543,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 544,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 546,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 549,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 550,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 551,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 553,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 554,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 555,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 556,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 557,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 558,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 559,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 561,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 563,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 565,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 567,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 568,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 569,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 570,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 572,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 573,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 574,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 576,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 578,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 580,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 581,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 745,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 811,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 815,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 914,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 975,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1029,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1050,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1082,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1092,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1093,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1108,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1149,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1176,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1197,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1201,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1238,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1274,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1285,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1298,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1299,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1320,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1383,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1385,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1417,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1419,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1456,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1480,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1482,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1557,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1558,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1562,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1565,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1568,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1573,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1574,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1694,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1695,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1696,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1700,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1702,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1786,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1813,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1945,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1957,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2019,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2214,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2386,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2524,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2568,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2779,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2911,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2986,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3026,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3160,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3183,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3185,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3219,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3287,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3315,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3343,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3565,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3630,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3655,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3676,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3679,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3710,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3795,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3815,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3852,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4001,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4067,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4253,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4260,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4373,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4410,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4424,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4437,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4514,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4563,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4624,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4658,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4746,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4755,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5025,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5216,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5223,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5259,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5288,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5381,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5540,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5561,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5600,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5804,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5833,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5855,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5868,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6031,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6051,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6120,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6268,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6303,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6383,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6421,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424287,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424289,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 424388,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425566,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425570,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425581,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425589,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425734,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425735,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 425817,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 440420,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442163,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442166,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442167,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442174,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442263,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442264,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442265,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442342,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442828,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442829,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442830,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442831,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442832,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442833,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442834,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442835,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442836,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442837,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442838,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442839,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442840,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442841,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442842,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442843,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442844,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442845,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442846,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442847,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442848,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442849,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442850,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442851,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442852,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442853,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442854,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442856,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442857,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442858,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442859,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442860,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442861,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442862,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442863,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442864,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442865,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442866,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442867,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442868,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442869,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442870,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442871,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442872,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442873,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442874,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442875,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442876,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442877,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442878,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442879,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 444210,
      "NUM_STATE_CD": 55
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 154,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 182,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1809,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 6390,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 442959,
      "NUM_STATE_CD": 56
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 176,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 198,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 200,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 329,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 330,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 352,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 354,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 355,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 356,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 358,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 360,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 361,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 363,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 365,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 366,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 367,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 368,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 469,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 650,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 764,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 842,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 874,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 885,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 939,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 951,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 974,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1020,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1036,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1312,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1313,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1314,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1330,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1333,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1355,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1364,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1388,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1484,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1628,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1630,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1655,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1770,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1792,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1801,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1832,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1961,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1985,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 1990,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2026,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2045,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2088,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2089,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2149,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2154,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2238,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2241,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2282,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2287,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2326,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2328,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2344,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2391,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2479,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2511,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2517,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2542,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2543,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2552,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2558,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2564,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2566,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2573,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2576,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2587,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2589,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2596,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2630,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2655,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2685,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2687,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2758,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2766,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2816,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2835,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2842,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2868,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2898,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2917,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2961,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 2967,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3065,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3115,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3116,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3118,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3127,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3135,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3198,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3203,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3228,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3233,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3244,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3327,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3341,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3346,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3359,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3365,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3379,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3388,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3391,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3455,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3456,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3516,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3518,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3520,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3546,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3554,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3555,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3577,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3649,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3661,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3707,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3765,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3777,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3806,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3829,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3834,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3902,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3907,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3916,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3960,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3964,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3966,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 3989,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4004,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4055,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4072,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4151,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4186,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4202,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4215,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4273,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4286,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4319,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4321,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4324,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4325,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4339,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4360,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4426,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4448,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4468,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4491,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4495,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4520,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4536,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4566,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4583,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4629,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4665,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4685,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4688,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4724,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4727,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4781,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4822,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4888,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 4917,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5047,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5054,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5122,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5178,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5213,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5222,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5247,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5316,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5339,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5343,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5384,
      "NUM_STATE_CD": 65
    },
    {
      "LOCATION_CATEGORY": "NCRWEST",
      "NUM_CITYDISTRICT_CD": 5395,
      "NUM_STATE_CD": 65
    }
  ]