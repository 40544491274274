import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.css']
})
export class AccordianComponent implements OnInit {

  @Input() items: { title: string, body: string }[] = [];
  @Input() id: string;

  activePanel: string | null = null;


  togglePanel(panelId: string): void {
    setTimeout(() => {
      if (this.activePanel === panelId) {
        this.activePanel = null;
      } else {
        this.activePanel = panelId;
      }
    }, 100);
  }

  constructor() { }

  ngOnInit() {
  }

}
