import { Component, OnInit,TemplateRef  } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { constant } from '../constant';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $:any;

@Component({
  selector: 'app-satisfied-client',
  templateUrl: './satisfied-client.component.html',
  styleUrls: ['./satisfied-client.component.css']
})
export class SatisfiedClientComponent implements OnInit {

  modalRef: BsModalRef;
  testimonals = [];
  base_url: string;
  review_length = 63;
  reviewdetail:any = {}

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService
  ) { }


  ngOnInit() {
    this.base_url = constant.api_endpoint;
    this.getTestimonials()
  }

  getTestimonials() {
    this.ngxLoader.start();
    // const url = `/testimonialRoute/list/1/0/1/?search_key=`;
    // this.commonService.get(url).subscribe(res => {
      //console.log(res)
      const hardcodedData = [
        {
          customer_name: "Santosh Prasad Kushawaha",
          designation:"Avp- accounts and finance",
          review:"The Policy Mall provided exceptional service, tailored solutions, and guided me through the process with professionalism. Highly recommend for reliable insurance services.",
          reviewlength: 100,
          file_path: "/assets/images/customer/customer1.jpeg" 
        },
        {
          customer_name: "Kuldeep Singh",
          designation:"Manager Finance and Accounts",
          review: "Highly recommend! Expert advice & stress-free experience.",
          reviewlength: 100,
          file_path: "/assets/images/customer/customer2.jpeg"
        
        },
        {
          customer_name: "Rohit Kumar",
          designation:"Trinity Group",
          review: "Outstanding service! The Policy Mall provided personalized solutions with expertise and patience. Highly recommend for trustworthy insurance services.",
          reviewlength: 100,
          file_path: "/assets/images/customer/customer3.jpeg" 
         
        },
      ];
      
      this.ngxLoader.stop()
      
    this.testimonals = hardcodedData.map(el => ({
      customer_name: el.customer_name,
      designation:el.designation,
      review: el.review,
      reviewlength: el.review.length,
      file_path: el.file_path
    }));
      setTimeout(() => {
        //----- Owl Carousel for satisfied client---------
        $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 10,
          nav: false,
          dots: true,
          autoplay: true,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            1000: {
              items: 3
            }
          }
        })
        // ------Owl carousel-----------
      }, 100);

    // }, err => {
    //   this.ngxLoader.stop()
    //   const errorMessage = err && err.message || 'Something goes wrong';
    //   this.toastr.error(errorMessage, 'Error');
    // })
  }

  openModal(template: TemplateRef<any>,review) {
    this.reviewdetail = review;
    this.modalRef = this.modalService.show(template,{ class: 'modal-md common-popup' });
  }

}
