import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FAQComponent implements OnInit {
  faq: any[] = [];

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.loadFaqdata();
  }

  loadFaqdata() {
    this.http
      .get("assets/companyjson/accordionData.json")
      .subscribe((res: any) => {
        this.faq = res.faqAccordionItems;
      });
  }

  redirectToAllFAQ(): void {
    this.router.navigate(['/all-faq']);
  }
  
}
