export const careFreedomQuestions = [
    {
      "question_set_code": "HEDCFLEAFONE",
      "question_code": "H102",
      "question_title": "Cancer"
    },
    {
      "question_set_code": "HEDCFLEAFTWO",
      "question_code": "H103",
      "question_title": "Any cardiovascular/Heart Disease,incl. but not limited to Coronary artery disease/Rheumatic heart disease/ Heart failure/Bypass Grafting/Angioplasty"
    },
    {
      "question_set_code": "HEDCFLEAFTHREE",
      "question_code": "H104",
      "question_title": "Hypertension / High Blood Pressure"
    },
    {
      "question_set_code": "HEDCFLEAFFOUR",
      "question_code": "H105",
      "question_title": "Any Respiratory disease/Disease of Lungs, Pleura and airway including but not limited to Asthma/Tuberculosis/Pleural effusion/Bronchitis/ Emphysema"
    },
    {
      "question_set_code": "HEDCFLEAFFIVE",
      "question_code": "H106",
      "question_title": "Any disorders of the endocrine system including but not limited to Pituitary / Parathyroid / adrenal gland disorders"
    },
    {
      "question_set_code": "HEDCFLEAFSIX",
      "question_code": "H107",
      "question_title": "Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes associated with blindness or chronic foot ulcer"
    },
    {
      "question_set_code": "HEDCFLEAFSEVEN",
      "question_code": "H108",
      "question_title": "Any Neuromuscular,muscles or nervous system, disorder or Psychiatric disorders incl. but not limited to Motor Neuron Disease, Muscular dystrophies"
    },
    {
      "question_set_code": "HEDCFLEAFEIGHT",
      "question_code": "H109",
      "question_title": "Chronic Pancreatitis or Chronic Liver disease including but not limited to Cirrhosis / Hepatitis B or C / Willson’s disease"
    },
    {
      "question_set_code": "HEDCFLEAFNINE",
      "question_code": "H110",
      "question_title": "Any chronic Kidney Disease"
    },
    {
      "question_set_code": "HEDCFLEAFTEN",
      "question_code": "H111",
      "question_title": "Any disorders of Blood/Immunity incl. but not limited to bleeding or clotting disorders, Systemic Lupus Erythematosus, Rheumatoid Arthritis"
    },
    {
      "question_set_code": "HEDCFLEAFELEVEN",
      "question_code": "H112",
      "question_title": "Have You smoked, consumed alcohol, or chewed tobacco, ghutka, paan or used any recreational drugs?If Yes, provide the frequency & amount consumed"
    },
    {
      "question_set_code": "HEDCFLEAFTWELVE",
      "question_code": "H113",
      "question_title": "Any other disease / health adversity / condition / treatment not mentioned above?"
    },
    {
      "question_set_code": "HEDCFLEAFTHIRTEEN",
      "question_code": "H114",
      "question_title": "Has any of the Insured been hosp. or has been under any prolonged treatment for illness or undergone surgery other than for childbirth/minor injuries?"
    },
    {
      "question_set_code": "HEDCFLEAFFOURTEEN",
      "question_code": "H115",
      "question_title": "Has any of the Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries?"
    },
    {
      "question_set_code": "CFLEAFFIFTEEN",
      "question_code": "AddInfo",
      "question_title": "If Your response is yes to any of the following questions, please specify details of the same in the additional information section."
    }
  ]