import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { noop, Observable, Observer, of } from "rxjs";
import { map, switchMap, tap, debounceTime } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { constant } from "../constant";
import { CommonService } from "../services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LegalAndAdminPoliciesComponent } from "../legal-and-admin-policies/legal-and-admin-policies.component";
import { AuthService } from "../services/auth.service";
import { TabDirective } from "ngx-bootstrap/tabs";
import { Options } from "ng5-slider";
import { Timestamp } from "rxjs/internal/operators/timestamp";
import { FormHandlerService } from "../form-handler.service";

declare var $: any;

interface Disease {
  value: string;
  label: string;
}

@Component({
  selector: "app-health",
  templateUrl: "./health.component.html",
  styleUrls: ["./health.component.css"],
})
export class HealthComponent implements OnInit {
  cities = [];
  bsModalRef: BsModalRef;
  tnc = false;
  tncf = false;
  tncp = false;
  search: string;
  search_Parents: string;
  search_Family: string;
  errorMessage: string;
  otherdisease = true;
  otherdisease5666;
  suggestions$: Observable<any[]>;
  city: string;
  f: string;

  individual: any = {
    type: 'I',
    gender: '0',
    dob: '',
    age: '',
    adult_number: 1,
    child_number: '0',
    userName: '',
    emailId: '',
    city_id: '', 
    contactNumber: '',
    ped: '0',
    from_pincode: '',
    to_pincode: '',
    coverAmount:'',
    RelationshipWithApplicant:'SELF',
    tenure:'',
    diseaseSelection: [] as any[],
    nowDiseaseSelection: undefined,
  };
  individualStep2 = false;
  familyData: any = {
    type: 'F',
    gender: '1',
    DOB: '',
    coverAmount:'',
    adult_number: 1,
    child_number: 1,
    pincode:'',
    userName: '',
    emailId: '',
    city_id: '',
    contactNumber: '',
    ped: '0',
    tenure:''
  };
  familyStep2 = false;

  parentData: any = {
    type: 'P',
    gender: '0',
    DOB: '',
    coverAmount:'',
    adult_number: 1,
    child_number: '0',
    pincode:'',
    userName: '',
    emailId: '',
    city_id: '',
    contactNumber: '',
    ped: '0',
    tenure:''
  }
 construct : string
  totalMember: {
    form: any; "membertype": "1a","DateOfBirth": "",memberTypeDef: "Insured Member 2", "relations": '' ,value: string;
}[] = [];
  parentStep2 = false;
  getQuoteBtn = false;
  base_url: string;
  age = [];
  formData: any = {}; // Define formData to store form data
  phoneNumber: string = ""; // Define phoneNumber to store the phone number

  diseases: Disease[] = [
    { value: "61", label: "Diabetes" },
    { value: "14", label: "Heart Disease" },
    { value: "60", label: "Hypertension" },
    { value: "66", label: "Thyroid" },
    { value: "64", label: "Asthma" },
    { value: "69", label: "Any Other diseases" },
  ];



  // selectedDiseases: { [key: string]: boolean } = {
  //   '61': false, // Diabetes
  //   '14': false, // Heart Disease
  //   '60': false, // Hypertension
  //   '66': false, // Thyroid
  //   '64': false, // Asthma
  //   '69': false  // Any Other diseases
  // };
  
  // presntData : { [key: string]: boolean } = {
  //   '61': true, // Diabetes
  //   '14': true, // Heart Disease
  //   '60': true, // Hypertension
  //   '66': true, // Thyroid
  //   '64': true, // Asthma
  //   '69': true  // Any Other diseases
  // };

  selectedDiseases: { [key: string]: boolean } = {};

  presntData: { [key: string]: boolean } = {
    "61": true, // Diabetes
    "14": true, // Heart Disease
    "60": true, // Hypertension
    "66": true, // Thyroid
    "64": true, // Asthma
    "69": true, // Any Other diseases
  };

  items: string[] = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  items_Parents: string[] = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  items_Family: string[] = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  filteredItems: string[] = [];
  filteredItems_Family: string[] = [];

  filteredItems_Parents: string[] = [];

  searchTerm: string = "";

  searchTerm_Parents: string = "";

  searchTerm_Family: string = '';
  isAdult: boolean;

  healthData: any[]= [];
  healthInsuranceData: any[]= [];
  healthRenewalData: any[]= [];
  healthClaimData: any[]= [];
  private savePayload = '/payloads/addPayloads'
 private getUserUrl = '/loginSignupRoute/getUserDetails/'
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private http: HttpClient,
    private _location: Location,
    private authService: AuthService,
    private ngxLoader: NgxUiLoaderService,
    private formHandlerService: FormHandlerService
  ) {}

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, 600);
    this.base_url = environment.api_endpoint;
    const individualFrm = JSON.parse(localStorage.getItem("userData1"));
    const familyFrm = JSON.parse(localStorage.getItem("userData2"));
    const parentFrm = JSON.parse(localStorage.getItem("userData3"));
    this.f = this.route.snapshot.url[0].path;

    if (individualFrm) {
      this.individual = individualFrm;
      this.individual.city_id = "";
      this.individual.diseaseSelection = [];
    }
    if (familyFrm) {
      this.familyData = familyFrm;
      this.familyData.city_id = "";
    }
    if (parentFrm) {
      this.parentData = parentFrm;
      this.parentData.city_id = "";
    }
    this.individual.ped = "0";
    this.familyData.ped = "0";
    this.parentData.ped = "0";

    for (let x = 18; x <= 90; x++) {
      this.age.push(x);
    }

    $(".tab_slider").owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      center: true,
      autoplay: true,
      rewind: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 3,
        },
      },
    });

    this.initializeSelectedDiseases();
    this.loadHealthdata();
  }

  initializeSelectedDiseases(): void {
    for (const disease of this.diseases) {
      this.selectedDiseases[disease.value] = this.presntData[disease.value] || false;

    }
  }

  loadHealthdata(){
    this.http.get("assets/companyjson/accordionData.json").subscribe((res:any)=>{
      this.healthData = res.medicalInsurance;
      this.healthInsuranceData = res.healthInsurancePremium;
      this.healthRenewalData = res.healthInsuranceRenewal;
      this.healthClaimData = res.healthInsuranceClaim;
    })
  }

  onTabSelect(data: TabDirective) {
    this.search = ''
    if (data.heading == 'Family') {
      this.familyData.city_id = ''
    } else if (data.heading == 'Individual') {
      this.individual.city_id = ''
    } else if (data.heading == 'Parents') {
      this.parentData.city_id = ''
    }
  }

  hasdisease(v, type) {
    if (type === "I") {
      this.individual.ped = v;
    } else if (type === "F") {
      this.familyData.ped = v;
    } else {
      this.parentData.ped = v;
    }
  }

  onSelect(e, type) {
    if (type === "I") {
      this.individual.city_name = e.item.city_name;
      this.individual.city_id = e.item.id;
      this.individual.state_id = e.item.state_id;
      this.individual.pincode = e.item.pincode;
    } else if (type === "F") {
      this.familyData.city_name = e.item.city_name;
      this.familyData.city_id = e.item.id;
      this.familyData.state_id = e.item.state_id;
      this.familyData.from_pincode = e.item.from_pincode;
      this.familyData.to_pincode = e.item.to_pincode;
    } else {
      this.parentData.city_name = e.item.city_name;
      this.parentData.city_id = e.item.id;
      this.parentData.state_id = e.item.state_id;
      this.parentData.from_pincode = e.item.from_pincode;
      this.parentData.to_pincode = e.item.to_pincode;
    }
    this.city = e.item.city_name;
  }

  setGender(gen, type) {
    if (type === "I") {
      this.individual.gender = gen;
    } else if (type === "F") {
      this.familyData.gender = gen;
    } else {
      this.parentData.gender = gen;
    }
  }

  onCheckboxChange(value: string) {
    const currentDate = new Date();

    // Ensure that diseaseSelection is an array
    if (!Array.isArray(this.individual.diseaseSelection)) {
      this.individual.diseaseSelection = [];
    }

    // Check if the disease is already in the selection
    const existingDisease = this.individual.diseaseSelection.find(
      (item) => item.DiseaseID === value
    );

    // If the disease is not already in the selection, add it
    if (!existingDisease) {
      this.individual.diseaseSelection.push({
        DiseaseID: value,
        SufferingSince: `${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${currentDate.getFullYear()}`,
      });
    } else {
      // If the disease is already in the selection, you might want to remove it (toggle behavior)
      const index = this.individual.diseaseSelection.indexOf(existingDisease);
      this.individual.diseaseSelection.splice(index, 1);
    }
  }


  getFamilyAdult(no:any) {
    this.familyData.adult_number = no
  }

  getParentAdult(no:any) {

    this.parentData.adult_number = no
  }

  getFamilyChild(no:any) {
    this.familyData.child_number = no
    
  }

  async getQuotes(type: string) {
    var regex_mobile = constant.mobilevalidateregex;
    var regex = constant.emailvalidateregex;
    var namearr = this.familyData.userName.split(" ");
    if (type === 'F') {
      if (!regex_mobile.test(this.familyData.contactNumber)) {
        this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
        return false;
      } 
       else if (this.familyData.userName == '') {
        this.toastr.error("Please enter your full name", "Error");
      } else if (namearr.length === 1) {
        this.toastr.error("Enter your lastname with a space sperator", "Error");
      } else if (namearr.length > 3) {
        this.toastr.error("Only firstname middlename and lastname are allowed", "Error");
      } else if (this.familyData.age == '' || parseInt(this.familyData.age) < 18) {
        this.toastr.error("Please enter your age which should greater than 18", "Error");
      } else if (this.familyData.pincode == '') {
        this.toastr.error("Please enter your pincode", "Error");
      } else if (this.familyData.emailId == '') {
        this.toastr.error("Please enter your valid email", "Error");
      } else if (!regex.test(this.familyData.emailId)) {
        this.toastr.error("Please enter a valid email address", "Error");
      }else if (this.familyData.pincode =='' || this.familyData.pincode.length != 6){
        this.toastr.error("Please enter valid pincode", "Error");
      } else if (this.familyData.tenure==''){
        this.toastr.error("please select Tenure")
      } else if (this.familyData.coverAmount ==''){
         this.toastr.error("please select coverage Amount")
      }
      else {
        this.familyStep2 = true;
    }
    const construct = this.commonService.relationValue(this.familyData.adult_number, this.familyData.child_number);
    this.totalMember = this.commonService.relations().filter(el => el.value == construct);
    console.log("total member", this.totalMember)
     localStorage.setItem('fname', namearr[0]);
     const payload = this.userEnquiryPayload(this.familyData.userName, this.familyData.contactNumber, this.familyData.emailId, this.familyData.coverAmount, this.familyData.tenure, this.familyData.pincode, this.familyData.gender)
     this.commonService.post(this.savePayload, payload).subscribe()

  }  else  {
    var namearr = this.parentData.userName.split(" ");
      if (this.parentData.userName == '') {
        this.toastr.error("Please enter your full name", "Error");
      } else if (namearr.length === 1) {
        this.toastr.error("Enter your lastname with a space sperator", "Error");
      } else if (namearr.length > 3) {
        this.toastr.error("Only firstname middlename and lastname are allowed", "Error");
      } else if (this.parentData.emailId == '') {
        this.toastr.error("Please enter your valid email", "Error");
      } else if (!regex.test(this.parentData.emailId)) {
        this.toastr.error("Please enter a valid email address ", "Error");
      } else if (this.parentData.coverAmount==''){
          this.toastr.error("please select the coverage amount");
      }else if (this.parentData.pincode =='' || this.parentData.pincode.length != 6){
        this.toastr.error("Please enter valid pincode", "Error");
      }else if (!regex_mobile.test(this.parentData.contactNumber)) {
        this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
      }   
      else if (this.parentData.tenure==''){
        this.toastr.error("please select Tenure");
      }
       else {
        this.parentStep2 = true;
      }
      const construct = this.commonService.relationValue(this.parentData.adult_number, this.parentData.child_number);
      this.parentData.construct =construct
      this.totalMember = this.commonService.relations().filter(el => el.value == construct);
      console.log("total member", this.totalMember)
      const payload = this.userEnquiryPayload(this.parentData.userName, this.parentData.contactNumber,this.parentData.emailId,this.parentData.coverAmount, this.parentData.tenure,this.parentData.pincode,  this.parentData.gender)
      this.commonService.post(this.savePayload, payload).subscribe()
     
    }
  }

  goback(type) {
    if (type === "I") {
      this.individualStep2 = false;
    } else if (type === "F") {
      this.familyStep2 = false;
    } else {
      this.parentStep2 = false;
    }
  }

  async individualSubmit() {
    localStorage.removeItem('insured_member_detail_with_addon')
    if(localStorage.getItem('tab')==='F'){
      localStorage.clear()
    }
    var regex = constant.emailvalidateregex;
    var regex_mobile = constant.mobilevalidateregex;
    var namearr = this.individual.userName.split(" ");
    const dob = new Date(this.individual.dob);
      const today = new Date();
      
      this.individual.age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      const dayDifference = today.getDate() - dob.getDate()
      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        this.individual.age--
      }
 
    if (this.individual.userName == '') {
      this.toastr.error("Please enter your full name", "Error");
    } else if (namearr.length === 1) {
      this.toastr.error("Enter your lastname with a space sperator", "Error");
    } else if (namearr.length > 3) {
      this.toastr.error(
        "Only firstname middlename and lastname are allowed",
        "Error"
      );
    } else if (this.individual.emailId == "") {
      this.toastr.error("Please enter your email address", "Error");
    } else if (!regex.test(this.individual.emailId)) {
      this.toastr.error("Please enter a valid email address", "Error");
    } else if   (!this.individual.dob) {
      this.toastr.error("Please enter member's DOB", "Error");
    } else if (this.individual.age < 18 || this.individual.age > 65) {
      this.toastr.error("Member must be at least 18 years old and no more than 65 years old", "Error");
  } else if (this.individual.pincode == '') {
        this.toastr.error("Please enter your pincode", "Error");
      } else if (!regex_mobile.test(this.individual.contactNumber)) {
        this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
      } else if (this.individual.coverAmount===" "){
        this.toastr.error("please select cover amount");
      } else if (this.individual.tenure === ''){
        this.toastr.error("please select tenure")
      }
    
    else {
      this.getQuoteBtn = true;
      this.individual.diseaseSelection = this.individual.diseaseSelection.filter(disease => disease.DiseaseID !== '69');
      localStorage.removeItem('userData2');
      this.individual.construct = '1a';
      const construct = this.commonService.relationValue(this.individual.adult_number, this.individual.child_number)
      this.totalMember = this.commonService.relations().filter(el => el.value == construct);
      this.individual.construct = construct
      localStorage.setItem('userData1', JSON.stringify(this.individual));
      localStorage.setItem('userData2Insured', JSON.stringify(this.totalMember));
      localStorage.setItem('fname', namearr[0]);
      localStorage.setItem('tab', 'I');

      const payload = await this.userEnquiryPayload(this.individual.userName, this.individual.contactNumber,this.individual.emailId, this.individual.coverAmount, this.individual.tenure, this.individual.pincode, this.individual.gender)
      this.commonService.post(this.savePayload, payload).subscribe()
      if (!Array.isArray(this.individual.diseaseSelection)) {
        this.individual.diseaseSelection = [];
      }
      this.router.navigate(["/health-quote"]); // Replace 'new-page' with the actual route path
    }
  }

  familySubmit() {
    let hasError = false;
    localStorage.removeItem('insured_member_detail_with_addon')
    const tabValue = localStorage.getItem('tab');
    const userData2 = localStorage.getItem('userData2');
    if(tabValue==='I' || userData2 && JSON.parse(userData2).construct !== this.totalMember[0].value){
      localStorage.clear()
    }
    this.totalMember[0].form.forEach(element => {
      if (element.DateOfBirth === '') {
          this.toastr.error("DateOfBirth is required", "Error");
          hasError = true;
      } else if (!element.hasOwnProperty('relation') || element.relation === '') {
          this.toastr.error("Relation is required", "Error");
          hasError = true;
      }
  });
  
    if (hasError) {
        return; 
    }
      this.getQuoteBtn = false;
      localStorage.removeItem('userData2');
      const construct = this.commonService.relationValue(this.familyData.adult_number, this.familyData.child_number);
      const url = `/premiumRoute/livequotes?page=1&order_by=1&search`;
      this.familyData.construct = construct;
      localStorage.setItem('userData2Insured', JSON.stringify(this.totalMember));
      if(this.familyStep2){
        localStorage.setItem('tab', 'F');
        localStorage.setItem('userData2', JSON.stringify(this.familyData));
      }
      if (this.parentStep2){    
        localStorage.setItem('tab', 'F');
        localStorage.setItem('userData2', JSON.stringify(this.parentData));
      }
    
      localStorage.removeItem('userData1');
    
  
      this.router.navigate(['/health-quote']); // Replace 'new-page' with the actual route path
    
  }

  filterItems() {
    this.filteredItems = this.items.filter((item) =>
      item.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  selectItem(item: string) {
    this.searchTerm = item;
    this.individual.city_id = item;
    this.filteredItems = [];
    this.search = item;
  }

  filterItems_Parents() {
    this.filteredItems_Parents = this.items.filter((item) =>
      item.toLowerCase().includes(this.search_Parents.toLowerCase())
    );
  }

  selectItem_Parents(item: string) {
    this.search_Parents = item;
    this.parentData.city_id = item;
    this.filteredItems_Parents = [];
    this.search_Parents = item;
  }

  filterItems_Family() {
    this.filteredItems_Family = this.items.filter((item) =>
      item.toLowerCase().includes(this.search_Family.toLowerCase())
    );
  }

  selectItem_Family(item: string) {
    this.search_Family = item;
    this.familyData.city_id = item;
    this.filteredItems_Family = [];
    this.search_Family = item;
  }


  onCheckboxChange1() {
    window.alert("You need to specify details");
    this.otherdisease = true;
  }


  back() {
    this._location.back();
  }

  validateAge(adult: any): string | null {
    if (!adult.DateOfBirth) {
        return null;
    }
    const today = new Date();
    const birthDate = new Date(adult.DateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if (adult.memberTypeDef.startsWith('Insured Member')) {
      if (age < 18 || age > 65) {
          return "Age must be between 18 and 65 for insured members";
      }
  }
  
    if (adult.memberTypeDef.startsWith('Dependent Child') && age >= 25) {
        return "Age must be less than 25 for dependent children";
    }
    return null;
}


  opentncmodal() {
    this.bsModalRef = this.modalService.show(LegalAndAdminPoliciesComponent, {
      class: "modal-lg",
    });
  }

  async getUsers(contactNumber: any, name: any, email: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        let userId = "";
        const signUpuserUrl = '/loginSignupRoute/signup';
        
        this.commonService.get(this.getUserUrl + `${contactNumber}`).subscribe(async response => {
            if (response.error.errorMessage === "Success" && response.error.errorCode === 200 && response.data[0]) {
                userId = response.data[0].userId;
                localStorage.setItem('getquoteuserID', userId);
                localStorage.setItem('g_id', response.data[0].g_id);
                resolve();
            } else {
                const nameParts = name.split(" ");
                const userData = {
                    "userName": name,
                    "password": "",
                    "contactNumber": contactNumber,
                    "emailId": email,
                    "userType": "customer",
                    "first_name": nameParts[0],
                    "last_name": nameParts[1]
                };
                
                this.commonService.post(signUpuserUrl, userData).subscribe((response) => {
                    if (response.error.errorCode === 200) {
                        localStorage.setItem('getquoteuserID', response.data.userId);
                        this.commonService.get(this.getUserUrl + `${contactNumber}`).subscribe((response) => {
                            console.log("response after sign up", response);
                            resolve();
                        });
                    } else {
                        reject(new Error('Error during sign up'));
                    }
                }, error => {
                    console.error("Error during sign up:", error);
                    reject(error);
                });
            }
        }, error => {
            console.error("Error fetching user details:", error);
            reject(error);
        });
    });
}


  async userEnquiryPayload(name: any, mobile: any, email: any, coverAmount: any, tenure: any, pincode: any, gender: any) {
    await this.getUsers(mobile, name, email);

    let userId = "";
    let g_id;

    try {
        const response = await this.commonService.get(this.getUserUrl + `${mobile}`).toPromise();
        if (response.error.errorMessage === "Success" && response.error.errorCode === 200 && response.data[0]) {
            userId = response.data[0].userId;
            g_id = response.data[0].g_id;
            localStorage.setItem('getquoteuserID', userId);
            localStorage.setItem('g_id', g_id);
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
    }
    
    return {
        "g_id": g_id,
        "user_id": userId,
        "transaction_id":null,
        'insurance_type': 'Health',
        'enquiry_payload': {
            'name': name,
            'email': mobile,
            'Mobile': email,
            "coverAmount": coverAmount,
            "premiumYear": tenure,
            'pincode': pincode,
            "gender": gender,
        }
    };
  }

}

