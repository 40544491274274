import { Component, OnInit } from '@angular/core';
import { constant } from '../constant';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';


declare var $: any

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {

    var sync1 = $(".slider");
    var sync2 = $(".navigation-thumbs");

    var thumbnailItemClass = '.owl-item';

    var slides = sync1.owlCarousel({
      video: true,
      startPosition: 1,
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 6000,
      autoplayHoverPause: false,
      nav: false,
      dots: false
    }).on('changed.owl.carousel', syncPosition);

    function syncPosition(el) {
      var $owl_slider = $(this).data('owl.carousel');
      var loop = $owl_slider.options.loop;
      var current;
      if (loop) {
        var count = el.item.count - 1;
        current = Math.round(el.item.index - (el.item.count / 2) - .5);
        if (current < 0) {
          current = count;
        }
        if (current > count) {
          current = 0;
        }
      } else {
        current = el.item.index;
      }

      var owl_thumbnail = sync2.data('owl.carousel');
      var itemClass = "." + owl_thumbnail.options.itemClass;


      var thumbnailCurrentItem = sync2
        .find(itemClass)
        .removeClass("synced")
        .eq(current);

      thumbnailCurrentItem.addClass('synced');

      if (!thumbnailCurrentItem.hasClass('active')) {
        var duration = 300;
        sync2.trigger('to.owl.carousel', [current, duration, true]);
      }
    }
    var thumbs = sync2.owlCarousel({
      startPosition: 1,
      items: 3,
      loop: false,
      autoplay: false,
      nav: false,
      margin: 30,
      dots: false,

      animateOut: 'slideOutUp',
      animateIn: 'slideInUp',
      onInitialized: function (e) {
        var thumbnailCurrentItem = $(e.target).find(thumbnailItemClass).eq(this._current);
        thumbnailCurrentItem.addClass('synced');
      },
    })
      .on('click', thumbnailItemClass, function (e) {
        e.preventDefault();
        var duration = 300;
        var itemIndex = $(e.target).parents(thumbnailItemClass).index();
        sync1.trigger('to.owl.carousel', [itemIndex, duration, true]);
      }).on("changed.owl.carousel", function (el) {
        var number = el.item.index;
        var $owl_slider = sync1.data('owl.carousel');
        $owl_slider.to(number, 100, true);
      });

    $('.carousel-speak').owlCarousel({
      loop: true,
      margin: 10,
      autoplay: true,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 2
        }
      }
    })
    $("html, body").animate({ scrollTop: 0 }, 600);
  }

  cvFile: File | null = null;
  submit() {
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const contactNumber = (document.getElementById('contactNumber') as HTMLInputElement).value;
    const description = (document.getElementById('description') as HTMLTextAreaElement).value;
   // Check if CV file is selected
   if (!this.cvFile) {
    this.toastr.error('Please select a CV file', 'Error');
    return;
    }

    // Assuming you want to do some basic validation on the client-side
    if (!name || !email || !contactNumber || !description) {
      this.toastr.error('Please fill in all fields', 'Error');
      return;
    }
  
    // Assuming you want to validate the email format
    const regexEmail = constant.emailvalidateregex;
    if (!regexEmail.test(email)) {
      this.toastr.error('Please enter a valid email address', 'Error');
      return;
    }
  
    // Assuming you want to validate the mobile number format
    const regexMobile = constant.mobilevalidateregex;
    if (!regexMobile.test(contactNumber)) {
      this.toastr.error('Please enter a valid 10 digit mobile number', 'Error');
      return;
    }
  
    // Assuming you want to upload CV through a separate API or handle it differently
  
    // Prepare the data for submission
    const url = `/careerRoute/add`;

    const formData: FormData = new FormData();
    formData.append('customer_name', name);
    formData.append('reg_email_id', email);
    formData.append('contact_no', contactNumber);
    formData.append('description', description);
    formData.append('cvFile', this.cvFile);
  
    // Make the HTTP POST request
    this.commonService.post(url, formData).subscribe(
      res => {
        // Clear the form fields after successful submission
        (document.getElementById('name') as HTMLInputElement).value = '';
        (document.getElementById('email') as HTMLInputElement).value = '';
        (document.getElementById('contactNumber') as HTMLInputElement).value = '';
        (document.getElementById('description') as HTMLTextAreaElement).value = '';
  
        // Show success message
        this.toastr.success('Your complaint has been submitted successfully!', 'Success');
      },
      err => {
        // Handle errors
        const errorMessage = err && err.message || 'Something went wrong';
        this.toastr.error(errorMessage, 'Error');
      }
    );
  }

  handleFileInput(event: any) {
    this.cvFile = event.target.files[0];
}
  
}
