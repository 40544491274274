const relationships = [
    {
      "key": "SPOUSE",
      "value": "Spouse"
    },
    {
      "key": "SON",
      "value": "Son"
    },
    {
      "key": "DAUGHTER",
      "value": "Daughter"
    },
    {
      "key": "FATHER",
      "value": "Father"
    },
    {
      "key": "MOTHER",
      "value": "Mother"
    },
    {
      "key": "BROTHER",
      "value": "Brother"
    },
    {
      "key": "SISTER",
      "value": "Sister"
    },
    {
      "key": "GRAND FATHER",
      "value": "Grand Father"
    },
    {
      "key": "GRAND MOTHER",
      "value": "Grand Mother"
    },
    {
      "key": "GRAND SON",
      "value": "Grand Son"
    },
    {
      "key": "GRAND DAUGHTER",
      "value": "Grand Daughter"
    },
    {
      "key": "FATHER IN LAW",
      "value": "Father In Law"
    },
    {
      "key": "MOTHER IN LAW",
      "value": "Mother In Law"
    },
    {
      "key": "SISTER IN LAW",
      "value": "Sister In Law"
    },
    {
      "key": "BROTHER IN LAW",
      "value": "Brother In Law"
    },
    {
      "key": "SON IN LAW",
      "value": "Son In Law"
    },
    {
      "key": "DAUGHTER IN LAW",
      "value": "Daughter In Law"
    }
  ]

export { relationships };

  