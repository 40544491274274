export const profession  =[
    {
      "Codedescription": "Businessman",
      "OCCUPATION": "BUSINESSMAN"
    },
    {
      "Codedescription": "Carpentor",
      "OCCUPATION": "CARPENTOR"
    },
    {
      "Codedescription": "Defence",
      "OCCUPATION": "DEFENCE"
    },
    {
      "Codedescription": "Farmer",
      "OCCUPATION": "FARMER"
    },
    {
      "Codedescription": "Housemaker",
      "OCCUPATION": "HOUSEMAKER/HOME_MAKER"
    },
    {
      "Codedescription": "Housewife",
      "OCCUPATION": "HOUSEWIFE"
    },
    {
      "Codedescription": "Mason",
      "OCCUPATION": "MASON"
    },
    {
      "Codedescription": "Private Job",
      "OCCUPATION": "PRIVATE_JOB"
    },
    {
      "Codedescription": "Public Job",
      "OCCUPATION": "PUBLIC_JOB"
    },
    {
      "Codedescription": "Teacher",
      "OCCUPATION": "TEACHER"
    },
    {
      "Codedescription": "Student",
      "OCCUPATION": "STUDENT"
    },
    {
      "Codedescription": "Salaried",
      "OCCUPATION": "SALARIED"
    },
    {
      "Codedescription": "Retired",
      "OCCUPATION": "RETIRED"
    },
    {
      "Codedescription": "Unemployed",
      "OCCUPATION": "UNEMPLOYED"
    },
    {
      "Codedescription": "Self Employed",
      "OCCUPATION": "SELF_EMPLOYED"
    }
  ]