import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PincodeDetails {
  constructor(private http: HttpClient) {}

  async getPincode(pincode: string): Promise<any> {
    const url = `https://api.postalpincode.in/pincode/${pincode}`;
    try {
      const response = await this.http.get(url).toPromise();

      return response;
    } catch (error) {
      console.error("Error fetching pincode data", error);
      throw new Error("Error fetching pincode data");
    }
  }
}
