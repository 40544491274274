export const AdvantageSumInsured =  [
    {
      "ProductID": 10001107,
      "sumInsured": "033",
      "sumInsuredvalue": "25L",
      "coverType": "INDIVIDUAL"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "034",
      "sumInsuredvalue": "25L",
      "coverType": "FAMILYFLOATER"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "035",
      "sumInsuredvalue": "50L",
      "coverType": "INDIVIDUAL"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "036",
      "sumInsuredvalue": "50L",
      "coverType": "FAMILYFLOATER"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "039",
      "sumInsuredvalue": "1Cr",
      "coverType": "INDIVIDUAL"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "040",
      "sumInsuredvalue": "1Cr",
      "coverType": "FAMILYFLOATER"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "041",
      "sumInsuredvalue": "2Cr",
      "coverType": "INDIVIDUAL"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "042",
      "sumInsuredvalue": "2Cr",
      "coverType": "FAMILYFLOATER"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "043",
      "sumInsuredvalue": "3Cr",
      "coverType": "INDIVIDUAL"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "044",
      "sumInsuredvalue": "3Cr",
      "coverType": "FAMILYFLOATER"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "045",
      "sumInsuredvalue": "6Cr",
      "coverType": "INDIVIDUAL"
    },
    {
      "ProductID": 10001107,
      "sumInsured": "046",
      "sumInsuredvalue": "6Cr",
      "coverType": "FAMILYFLOATER"
    }
  ]

export const FreedomSumInsured = [
    {
      "sumInsured": "013",
      "sumInsuredvalue": "3L",
      "ProductID":12001002,
      "coverType": "INDIVIDUAL"
    },
    {
      "sumInsured": "037",
      "sumInsuredvalue": "5L",
      "ProductID":12001002,
      "coverType": "INDIVIDUAL"
    },
    {
      "sumInsured": "014",
      "sumInsuredvalue": "3L",
      "ProductID":12001002,
      "coverType": "FAMILYFLOATER"
    },
    {
      "sumInsured": "038",
      "sumInsuredvalue": "5L",
      "ProductID":12001002,
      "coverType": "FAMILYFLOATER"
    },
    {
      "sumInsured": "001",
      "sumInsuredvalue": "7L",
      "ProductID":12001003,
      "coverType": "INDIVIDUAL"
    },
    {
      "sumInsured": "002",
      "sumInsuredvalue": "7L",
      "ProductID":12001003,
      "coverType": "FAMILYFLOATER"
    },
    {
      "sumInsured": "003",
      "sumInsuredvalue": "10L",
      "ProductID":12001003,
      "coverType": "INDIVIDUAL"
    },
    {
      "sumInsured": "004",
      "sumInsuredvalue": "10L",
      "ProductID":12001003,
      "coverType": "FAMILYFLOATER"
    }
  ]

export const heartSumInsure = [
  {
    "sumInsured": "003",
    "sumInsuredvalue": "3L",
    "ProductID":12001006,
    "coverType": "INDIVIDUAL"
  },
  {
    "sumInsured": "004",
    "sumInsuredvalue": "3L",
    "ProductID":12001006,
    "coverType": "FAMILYFLOATER"
  },
  {
    "sumInsured": "007",
    "sumInsuredvalue": "5L",
    "ProductID":12001006,
    "coverType": "INDIVIDUAL"
  },
  {
    "sumInsured": "008",
    "sumInsuredvalue": "5L",
    "ProductID":12001006,
    "coverType": "FAMILYFLOATER"
  },
  {
    "sumInsured": "009",
    "sumInsuredvalue": "7L",
    "ProductID":12001006,
    "coverType": "INDIVIDUAL"
  },
  {
    "sumInsured": "010",
    "sumInsuredvalue": "7L",
    "ProductID":12001006,
    "coverType": "FAMILYFLOATER"
  },
  {
    "sumInsured": "011",
    "sumInsuredvalue": "10L",
    "ProductID":12001006,
    "coverType": "INDIVIDUAL"
  },
  {
    "sumInsured": "012",
    "sumInsuredvalue": "10L",
    "ProductID":12001006,
    "coverType": "FAMILYFLOATER"
  }
]

